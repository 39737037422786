import React from "react";
import moment from "moment";
import { Button, Card } from "react-bootstrap";
import { Separator, OrderText } from "../components/Base";
import { KTUtil } from "../../_metronic/_assets/js/components/util";
import { RESERVATIONS_TAB_LIST } from "../pages/client-admin/PerStoreReservations";

const DisplayTime = ({ reservation_date = "", reservation_time = "" }) => {
  const date = moment(reservation_date).format("MMM D, YYYY");
  const time = moment(reservation_time, "HH:mm").format("hh:mm A");
  return <>{`${date} ${time}`}</>;
};

export const ACTION_BTN_COLOR = {
  accepted: "purple",
  completed: "primary",
  canceled: "danger",
  no_show: "outline-danger",
};

export default class ReservationCard extends React.Component {
  render() {
    const {
      reservation = {},
      listName = "",
      handleOnActionClick,
      btnLoading,
      showQuickLook,
      user,
    } = this.props;
    const { store_reservation } = reservation;
    const {
      reservation_number = "",
      reservation_date = "",
      reservation_time = "",
      reservation_status = "new",
      guest_count = 0,
    } = reservation;

    const ALLOWED_ROLES = ["store_admin", "console_user", "demo_user"];

    return (
      <Card className="mv-1rem cursor-pointer">
        <Card.Body>
          <div
            className="d-flex flex-row justify-content-space-between"
            onClick={showQuickLook}
          >
            <div className="d-flex">
              <OrderText
                value={
                  <>
                    {store_reservation.reservation_type === "table"
                      ? "Reservation"
                      : "Event"}{" "}
                    #{reservation_number}
                  </>
                }
                underline={true}
                style={{ justifyContent: "flex-start" }}
              />
            </div>
            <div
              className={`text-${
                reservation_status === "new" ? "danger" : "purple"
              } text-uppercase font-weight-bolder font-pickup-sm-2 d-flex`}
              style={{ justifyContent: "flex-end" }}
            >
              {reservation_status}
            </div>
          </div>
          <Separator solid />
          <div
            className="mv-1rem cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              handleOnActionClick(e);
            }}
            data-id={reservation._id}
            data-value={`${
              reservation_status === "new" ? "accepted" : "completed"
            }`}
          >
            <div className="d-flex justify-content-space-between">
              <OrderText
                label={
                  <>
                    {store_reservation.reservation_type === "table"
                      ? "Reservation"
                      : "Event"}{" "}
                    Name:
                  </>
                }
                value={
                  <>{store_reservation && store_reservation.reservation_name}</>
                }
              />
            </div>
            <div className="order-card-details">
              <OrderText label={<>Guest Count:</>} value={<>{guest_count}</>} />
              <OrderText
                label={<>Date:</>}
                value={
                  <DisplayTime
                    reservation_date={reservation_date}
                    reservation_time={reservation_time}
                  />
                }
              />
            </div>
          </div>
          {ALLOWED_ROLES.includes(user.iam_role) &&
            listName !== RESERVATIONS_TAB_LIST.COMPLETED && (
              <>
                <Separator solid />
                <div className="d-flex flex-row justify-content-end mv-1rem">
                  <div
                    className={`d-flex ${
                      KTUtil.isMobileDevice() ? "flex-column" : "flex-row"
                    }`}
                  >
                    {listName === RESERVATIONS_TAB_LIST.UPCOMING &&
                    reservation_status === "accepted" ? null : (
                      <Button
                        variant={
                          ACTION_BTN_COLOR[
                            reservation_status === "new"
                              ? "accepted"
                              : "completed"
                          ]
                        }
                        style={{
                          ...(KTUtil.isMobileDevice() && {
                            width: "100%",
                            marginBottom: 5,
                          }),
                        }}
                        className={"font-weight-bolder text-uppercase mr-5"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOnActionClick(e);
                        }}
                        data-value={`${
                          reservation_status === "new"
                            ? "accepted"
                            : "completed"
                        }`}
                        data-id={reservation._id}
                        disabled={btnLoading}
                      >
                        {reservation_status === "new" ? "accept" : "complete"}
                      </Button>
                    )}
                    <Button
                      variant={
                        listName === RESERVATIONS_TAB_LIST.UPCOMING ||
                        reservation_status === "new"
                          ? ACTION_BTN_COLOR.canceled
                          : ACTION_BTN_COLOR.no_show
                      }
                      style={{
                        ...(KTUtil.isMobileDevice() && {
                          width: "100%",
                          marginBottom: 5,
                        }),
                      }}
                      className={"font-weight-bolder text-uppercase"}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOnActionClick(e);
                      }}
                      data-value={`${
                        listName === RESERVATIONS_TAB_LIST.UPCOMING ||
                        reservation_status === "new"
                          ? "canceled"
                          : "no-show"
                      }`}
                      data-id={reservation._id}
                      disabled={btnLoading}
                    >
                      {listName === RESERVATIONS_TAB_LIST.UPCOMING ||
                      reservation_status === "new"
                        ? "cancel"
                        : "mark as no show"}
                    </Button>
                  </div>
                </div>
              </>
            )}
        </Card.Body>
      </Card>
    );
  }
}
