import React from "react";
import { Button, Col, Row } from "react-bootstrap";

import styled from "styled-components";
import { getResizedImage } from "../../helpers";
import StoreListItem from "../../partials/StoreListItem";

export default function PreviewHomepage(props) {
  const isBrand = props.data.brand_url !== undefined;
  return <PreviewMain {...props} isBrandWebsite={isBrand} />;
}

const sampleStore = {
  name: "Store Name",
  contact_number: "01234567890",
  location: "Store location",
  store_hours: new Array(7).fill(0).map((_, i) => {
    return {
      day: `${i + 1}`,
      opening: "09:00",
      closing: "18:00",
      isOpen: true,
    };
  }),
};

class PreviewMain extends React.Component {
  render() {
    const {
      data = {},
      preview_theme = {},
      isBrandWebsite = false,
    } = this.props;
    const {
      background_color,
      highlighted_text_color,
      homepage_button_color,
      homepage_button_text_color,
      homepage_highlighted_text_color,
      homepage_icon_color,
      link_text_color,
      text_color,
    } = preview_theme;

    const HighlightedText = styled.b`
      color: ${homepage_highlighted_text_color !== ""
        ? homepage_highlighted_text_color
        : highlighted_text_color};
    `;

    const {
      brand_logo,
      name,
      brand = {},
      brand_website_bg,
      date_updated,
      date_created,
      store_website_bg,
      group_type = "single",
      group_url,
      website_bg,
      group_logo,
    } = data;

    const fulfillmentButtonStyle = {
      backgroundColor: homepage_button_color,
      borderColor: homepage_button_color,
      color: homepage_button_text_color,
      cursor: "default",
    };

    const large_image = getResizedImage(
      isBrandWebsite ? brand_website_bg : store_website_bg,
      "large",
      date_updated || date_created,
    );

    const brand_bg =
      group_type === "multi" && !isBrandWebsite && group_url !== undefined
        ? website_bg
        : group_type !== "multi" && isBrandWebsite
        ? brand_website_bg
        : store_website_bg;

    const page_logo =
      group_type === "multi" && !isBrandWebsite && group_url !== undefined
        ? group_logo
        : group_type !== "multi" && isBrandWebsite
        ? brand_logo
        : brand.brand_logo;

    return (
      <>
        <Row>
          <Col>
            <div
              className="css-qwibvh homepage-preview-container"
              style={{
                color: text_color,
                display: "flex",
                maxHeight: `${isBrandWebsite ? "70vh" : "72vh"}`,
              }}
            >
              <div
                className="css-1ucwpv3 homepage-preview-panel"
                style={{
                  minHeight: "50vh",
                  maxHeight: `${isBrandWebsite ? "70vh" : "80vh"}`,
                  width: brand_bg !== "" ? "100%" : "51%",
                }}
              >
                <section
                  className="css-luxby8 homepage-preview-sec"
                  style={{ backgroundColor: background_color }}
                >
                  {page_logo && page_logo !== "" && (
                    <div className={`text-left`}>
                      <img
                        src={page_logo}
                        alt={name}
                        className="homepage-logo mr-2"
                      />
                    </div>
                  )}

                  <div className="mv-1rem">Your brand intro</div>
                  {!isBrandWebsite && (
                    <div style={{ margin: "1.5rem 0" }}>
                      <StoreListItem
                        isPreview
                        store={sampleStore}
                        store_hours_display={"branded-website-today"}
                        hideName={true}
                        custom_color={homepage_icon_color}
                        custom_link_color={link_text_color}
                      />
                    </div>
                  )}

                  <div className="css-1sh12zw">
                    <div className="mv-1rem">
                      <HighlightedText>Highlighted Text</HighlightedText> Lorem
                      ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum gravida congue nunc, quis accumsan nulla
                      fringilla vitae.
                    </div>
                  </div>
                  <div className="font-weight-bolder">Order Type</div>
                  <div
                    data-testid="fulfillmentOptions"
                    className="css-znampd mv-1rem"
                  >
                    <Button
                      style={fulfillmentButtonStyle}
                      className="btn mr-5 mt-5"
                      onClick={null}
                    >
                      <i
                        style={{ color: homepage_button_text_color }}
                        className="fas fa-shopping-bag"
                      ></i>
                      ORDER NOW
                    </Button>
                  </div>
                  <div className="mv-1rem font-weight-bolder">
                    Powered by{" "}
                    <a
                      href="https://www.pickup.ph"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="text-primary">Pickup.ph</span>
                    </a>
                  </div>
                </section>
              </div>
              <div
                className="css-1e4rxnh homepage-preview-img"
                style={{
                  minHeight: "50vh",
                  maxHeight: `${isBrandWebsite ? "70vh" : "72vh"}`,
                  position: "initial",
                  display: "flex",
                }}
              >
                <div data-testid="landingImage" className="css-10klw3m">
                  {brand_bg !== "" ? (
                    <img
                      src={large_image}
                      className="css-9cywm4"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      alt="background"
                      onError={(ev) => {
                        ev.target.onError = null;
                        ev.target.src = brand_bg;
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
