module.exports = {
  pu: {
    development: process.env.REACT_APP_API_URL || "http://localhost:5007/",
    production: process.env.REACT_APP_API_URL,
  },
  socket: {
    development:
      process.env.REACT_APP_API_SOCKET_URL || "http://localhost:5007/",
    production: process.env.REACT_APP_API_SOCKET_URL,
  },
};

// console.log(process.env.REACT_APP_API_URL)
