import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Block, KTLink, Label } from "../components/Base";
import { GetSubmitClassList } from "../helpers";

function getBtnVariant(condition = false) {
  return `btn btn-icon btn-${condition ? "primary" : "outline-primary"}`;
}

export default class LogoFormDiv extends React.Component {
  constructor(props) {
    super(props);

    const { website_content = {} } = this.props.data,
      { brand_logo_orientation = "" } = website_content;

    this.state = {
      brand_logo_orientation,
    };

    this.onClickOrientationChange = this.onClickOrientationChange.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  onClickOrientationChange = (brand_logo_orientation = "") => {
    this.setState({ brand_logo_orientation });
  };

  onClickSubmit = () => {
    let { data = {} } = this.props,
      { website_content = {} } = data,
      { brand_logo_orientation = "" } = this.state;
    website_content.brand_logo_orientation = brand_logo_orientation;

    this.props.onSubmitWebsiteContentForm(website_content, (isSubmitting) => {
      this.setState({ isSubmitting });
    });
  };

  render() {
    const {
      data = {},
      fields: { logo = data.group_url ? "group_logo" : "brand_logo" },
    } = this.props;
    const title = data.group_url
      ? "Group Logo"
      : data.store_url
      ? "Store Logo"
      : "Brand Logo";
    const { brand_logo_orientation = "", isSubmitting = false } = this.state;
    const brand_logo_url = data.hasOwnProperty(logo) ? data[logo] : "";

    return (
      <>
        <Card className="card-custom gutter-b">
          <Card.Header>
            <Card.Title>{title}</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Block
                  // title={<><span className="va-middle">brand logo</span> {(brand_logo_url !== "") ? <button type="button" className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle" onClick={() => { this.props.setState({ showUploadBrandLogoModal: true, uploadImageMode: "brand_logo" })}}><i className="far fa-edit text-primary"/></button>: <></> }</>}
                  content={
                    <>
                      <Label
                        text={
                          <>
                            <span className="va-middle">{title}</span>{" "}
                            {brand_logo_url && brand_logo_url !== "" ? (
                              <button
                                type="button"
                                className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                onClick={() => {
                                  this.props.setState({
                                    showUploadBrandLogoModal: true,
                                    uploadImageMode: data.group_logo
                                      ? "group_logo"
                                      : "brand_logo",
                                  });
                                }}
                              >
                                <i className="far fa-edit text-primary" />
                              </button>
                            ) : (
                              <></>
                            )}
                          </>
                        }
                      />
                      <div className="mv-1rem">
                        {brand_logo_url && brand_logo_url !== "" ? (
                          <>
                            <img
                              src={brand_logo_url}
                              alt="logo"
                              className="bw-logo mr-2"
                            />
                          </>
                        ) : (
                          <>
                            <p>
                              <span>
                                {data.group_url ? "Group" : "Store"} doesn't
                                have a logo.
                              </span>{" "}
                              <KTLink
                                text={"Do you want to add one?"}
                                onClick={() => {
                                  this.props.setState({
                                    showUploadBrandLogoModal: true,
                                    uploadImageMode: data.group_url
                                      ? "group_logo"
                                      : "brand_logo",
                                  });
                                }}
                              />
                            </p>
                          </>
                        )}
                      </div>
                    </>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Block
                  content={
                    <>
                      <Label text="Alignment" />
                      <div className="mv-1rem">
                        <div className="btn-group" role="group">
                          <button
                            type="button"
                            className={getBtnVariant(
                              brand_logo_orientation === "left",
                            )}
                            onClick={() => {
                              this.onClickOrientationChange(
                                "left",
                                "brand_logo_orientation",
                              );
                            }}
                          >
                            <i className="fas fa-align-left"></i>
                          </button>
                          <button
                            type="button"
                            className={getBtnVariant(
                              brand_logo_orientation === "center",
                            )}
                            onClick={() => {
                              this.onClickOrientationChange(
                                "center",
                                "brand_logo_orientation",
                              );
                            }}
                          >
                            <i className="fas fa-align-center"></i>
                          </button>
                          <button
                            type="button"
                            className={getBtnVariant(
                              brand_logo_orientation === "right",
                            )}
                            onClick={() => {
                              this.onClickOrientationChange(
                                "right",
                                "brand_logo_orientation",
                              );
                            }}
                          >
                            <i className="fas fa-align-right"></i>
                          </button>
                        </div>
                      </div>
                    </>
                  }
                />
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <Button
              variant="primary"
              className={GetSubmitClassList(isSubmitting, "")}
              onClick={this.onClickSubmit}
            >
              Save Changes
            </Button>
          </Card.Footer>
        </Card>
      </>
    );
  }
}
