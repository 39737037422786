import React from "react";
import moment from "moment";
import { useParams, Redirect } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import core from "../../../vendors/core-api";
import APIComponent from "../../../components/API";
import Datatable from "react-data-table-component";
import { isEmpty } from "lodash";
import {
  DANGER_COLOR,
  DB_DATE_FORMAT,
  PRIMARY_COLOR,
} from "../../../constants";
import { Button, Row } from "react-bootstrap";
import PacmanLoading from "../../../components/widgets/PacmanLoading/PacmanLoading";
import { SVGIcon } from "../../../components/Base";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { capitalizeFirstLetter } from "../../../helpers";

const MySwal = withReactContent(Swal);

export default function Page(props) {
  const { store_id = "" } = useParams();
  return <MainComponent id={store_id} {...props} />;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: true,
      redirectTo: "",
      brand_name: "Brands",
      store: {},
      groupName: "",
      title: "Concierge Carts",
    };

    this.api = core("pu").get();
    this.data_url = `/store/${props.id}/concierge-carts`;
  }

  async getData() {
    if (this.data_url !== "") {
      this._isMounted && this.setState({ data_status: "fetching" });
      this.api &&
        this.api
          .get(this.data_url)
          .then(({ data }) => {
            this.setState({
              store: data.store,
              data: data.exclusive_carts,
              limit: data.limit,
              data_status: "fetched",
              groupName: data.store.brand.client.name,
            });
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });
    } else {
      this.afterComponentDidMount();
    }
  }

  async onCartDelete(cart_id) {
    const { store } = this.state;
    if (!isEmpty(cart_id)) {
      this.api &&
        this.api
          .put(
            {
              url: `/store/${this.props.id}/delete-concierge-cart/${cart_id}`,
            },
            {
              headers: {
                Authorization: `Basic ${window.btoa(store.brand.brand_url)}`,
              },
              baseURL: this.api.getBaseURL(),
            },
          )
          .then(({ data }) => {
            if (data === "expired") {
              MySwal.fire({
                icon: "success",
                title: "Success!",
                text: "Successfully deleted cart!",
                confirmButtonColor: PRIMARY_COLOR,
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result && result.isConfirmed) {
                  this.getData();
                }
              });
            }
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });
    }
  }

  render() {
    const { data_status, data, redirectTo, store, groupName } = this.state;

    return (
      <>
        {redirectTo !== "" ? <Redirect to={redirectTo} /> : <></>}
        {data_status === "fetching" && (
          <div className="d-flex justify-content-center align-items-center h-100">
            <PacmanLoading removeBackgroundColor />
          </div>
        )}
        {data_status !== "fetching" && (
          <>
            <div className="row">
              <div className="col-md-9 col-sm-12">
                <Breadcrumbs>
                  <Breadcrumbs.Item text={groupName} />
                  <Breadcrumbs.Item
                    text="Concierge Carts"
                    link={`/concierge-carts`}
                  />
                  <Breadcrumbs.Item
                    text={store.name}
                    link="#!"
                    active={"true"}
                  />
                </Breadcrumbs>
              </div>
            </div>
            <Row className="d-flex p-4 justify-content-space-between align-items-center">
              <h1>Concierge Carts</h1>
              <Button
                className=""
                style={{ top: 0, right: 0 }}
                onClick={() =>
                  this.props.history.push(
                    `/stores/${this.props.id}/create-concierge`,
                  )
                }
              >
                + New
              </Button>
            </Row>
            <div className="d-flex flex-column">
              <div style={{ overflowX: "auto" }}>
                <>
                  <Datatable
                    columns={[
                      {
                        name: "Cart Alias",
                        selector: (row) => {
                          return row._id;
                        },
                        format: (row) => {
                          const { order_details } = row;
                          const order = JSON.parse(order_details);

                          return (
                            <span className="font-weight-bold">
                              {capitalizeFirstLetter(order.cart_alias)}
                            </span>
                          );
                        },
                        sortable: true,
                      },
                      {
                        name: "Items",
                        center: true,
                        selector: (row) => {
                          return row.cart_details;
                        },
                        format: (row) => {
                          const { cart_details } = row;
                          const items = cart_details.item_info
                            .map(({ name }) => name)
                            .join(", ");
                          return (
                            <span className={"text-capitalize"}>
                              {items.length > 0 ? items : "--"}
                            </span>
                          );
                        },
                        sortable: true,
                        style: "font-weight-bold",
                      },
                      {
                        name: "Expiration Date",
                        center: true,
                        selector: (row) => {
                          const { order_details } = row;
                          const details = JSON.parse(order_details);
                          const date_now = moment(new Date()).format(
                            DB_DATE_FORMAT,
                          );
                          return isEmpty(details.exp_date)
                            ? "No time limit"
                            : date_now ===
                              moment(details.exp_date).format(DB_DATE_FORMAT)
                            ? "Today"
                            : moment(details.exp_date).format(
                                "MMM D, YYYY hh:mm A",
                              );
                        },
                      },
                      {
                        name: "Status",
                        center: true,
                        selector: (row) => {
                          return row.status;
                        },
                        format: (row) => {
                          const { order_details } = row;
                          const details = JSON.parse(order_details);
                          const date_now = moment(new Date()).format(
                            DB_DATE_FORMAT,
                          );

                          const isCartExpired =
                            date_now >
                              moment(details.exp_date).format(DB_DATE_FORMAT) ||
                            row.status === "expired";

                          return (
                            <span
                              style={{ width: 70 }}
                              className={`badge badge-pill text-capitalize badge-${
                                isCartExpired
                                  ? "danger"
                                  : row.status === "new" && !isCartExpired
                                  ? "primary"
                                  : "light"
                              }`}
                            >
                              {isCartExpired ? "expired" : row.status}
                            </span>
                          );
                        },
                        sortable: false,
                      },
                      {
                        name: "Date Created",
                        center: true,
                        selector: (row) => {
                          return moment(row.date_created).format(
                            "MMM D, YYYY hh:mm A",
                          );
                        },
                        sortable: true,
                      },
                      {
                        name: "Actions",
                        center: true,

                        selector: (row) => {
                          return row.status;
                        },
                        format: (row) => {
                          const { order_details } = row;
                          const details = JSON.parse(order_details);
                          const date_now = moment(new Date()).format(
                            DB_DATE_FORMAT,
                          );

                          const isCartExpired =
                            date_now >
                              moment(details.exp_date).format(DB_DATE_FORMAT) ||
                            row.status === "expired" ||
                            row.status === "converted";

                          return (
                            <div>
                              <Button
                                disabled={isCartExpired}
                                style={{ marginTop: 5, marginRight: -5 }}
                                variant="link"
                                className="btn-icon btn-sm"
                                onClick={() => {
                                  this.setState({
                                    redirectTo: `/stores/${store._id}/create-concierge?cart_id=${row._id}`,
                                  });
                                }}
                              >
                                <SVGIcon
                                  icon={"Design/Edit"}
                                  title={"Edit Cart"}
                                />
                              </Button>

                              <Button
                                variant="link"
                                className="btn-icon btn-sm"
                                onClick={() => {
                                  MySwal.fire({
                                    icon: "warning",
                                    title: "Warning!",
                                    text: "Are you sure you want to delete this cart?",
                                    showCancelButton: true,
                                    confirmButtonColor: DANGER_COLOR,
                                    confirmButtonText: "Delete",
                                  }).then((result) => {
                                    if (result && result.isConfirmed) {
                                      this.onCartDelete(row._id);
                                    }
                                  });
                                }}
                              >
                                <SVGIcon
                                  icon={"General/Trash"}
                                  title={"Delete Cart"}
                                />
                              </Button>
                            </div>
                          );
                        },
                      },
                    ]}
                    data={data}
                    pagination
                    noHeader
                    highlightOnHover
                  />
                </>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
