import React from "react";
import APIComponent from "../components/API";
import moment from "moment";
import {
  DB_DATE_FORMAT,
  DB_HOURS_FORMAT,
  DISPLAY_DATE_FORMAT,
  DISPLAY_HOURS_FORMAT,
} from "../constants";
import PaginationBar from "../components/PaginationBar";
import PerfectScrollbar from "react-perfect-scrollbar";
function getVariant(activity_code) {
  let variant = "primary";

  if (
    activity_code === "cancelled_full_refund_email" ||
    activity_code === "cancelled_partial_refund_email"
  )
    variant = "danger";

  return variant;
}

function getText(activity_code) {
  let text = activity_code;

  if (activity_code === "new_order") text = "placed order.";
  else if (
    activity_code === "accept_order_email" ||
    activity_code === "accept_order_console"
  )
    text = "accepted order.";
  else if (
    activity_code === "cancelled_full_refund_email" ||
    activity_code === "cancelled_full_refund_console"
  )
    text = "cancelled order and is subject to full refund.";
  else if (
    activity_code === "cancelled_partial_refund_email" ||
    activity_code === "cancelled_partial_refund_console"
  )
    text = "cancelled order and is subject to partial refund.";
  else if (activity_code === "cancelled_no_refund_console")
    text = "cancelled order and is subject to no refund.";
  else if (activity_code === "order_ready") text = "marked order as ready.";
  else if (activity_code === "order_completed")
    text = "marked order as completed.";
  else if (activity_code === "order_out_for_delivery")
    text = "marked order as out for delivery.";
  else if (activity_code === "order_cancelled_no_show")
    text = "marked order as no show.";
  else if (activity_code === "issue_refund") text = "issued a refund.";
  else if (activity_code === "completed_full_refund")
    text = "issued a full refund.";
  else if (activity_code === "completed_partial_refund")
    text = "issued a partial refund.";
  else if (activity_code === "order_moved") text = "moved order to queue.";
  else if (activity_code === "change_password") text = "password changed.";
  else if (activity_code === "update_user") text = `updated`;
  else if (activity_code === "cancel_delivery_console")
    text = "delivery cancelled";
  else if (activity_code === "update_order_schedule")
    text = "updated order schedule";
  else if (activity_code === "add_item") text = `added menu item:`;
  else if (activity_code === "update_item") text = `updated menu item:`;
  else if (activity_code === "add_category") text = `added menu category:`;
  else if (activity_code === "update_category") text = `updated menu category:`;
  else if (activity_code === "update_store") text = `updated store settings:`;
  else if (activity_code === "update_marketplace")
    text = `updated marketplace settings:`;
  else if (activity_code === "update_brand") text = `updated brand settings:`;
  else if (activity_code === "promos") text = `promos:`;
  else if (activity_code === "move_order_queue") text = `moved order to queue.`;
  else if (activity_code === "add_store_reservation")
    text = `added store reservation:`;
  else if (activity_code === "update_store_reservation")
    text = `updated store reservation:`;
  else if (activity_code === "admin_tools") text = `update from admin tools:`;
  else if (activity_code === "update_order_dispute")
    text = `updated order dispute note.`;
  return text;
}

function displayDetails(activity_code = "", details = "") {
  let text = details;

  if (activity_code === "update_order_schedule") {
    if (details !== "") {
      details = JSON.parse(details);
      text =
        "from " +
        moment(details.old.order_date, DB_DATE_FORMAT).format(
          DISPLAY_DATE_FORMAT,
        ) +
        " " +
        moment(details.old.order_time, DB_HOURS_FORMAT).format(
          DISPLAY_HOURS_FORMAT,
        ) +
        " to " +
        moment(details.new.order_date, DB_DATE_FORMAT).format(
          DISPLAY_DATE_FORMAT,
        ) +
        " " +
        moment(details.new.order_time, DB_HOURS_FORMAT).format(
          DISPLAY_HOURS_FORMAT,
        );
    }
  }

  return text;
}

// const filtered_activities = ["update_brand"];
const filtered_activities = [""];

export default class Activities extends APIComponent {
  constructor(props) {
    super(props);

    const { collection = "", identifier = "", api } = this.props;

    this.api = api;
    this.data_url =
      collection !== "" && identifier !== ""
        ? `/activities/c/${collection}/${identifier}/1`
        : "";
  }

  handleSelectPage = (pageNum = 1, pagesArr = []) => {
    let page = pageNum;
    if (page > pagesArr.length) {
      page = pagesArr.length;
    }
    let tempUrl = this.data_url.substring(0, this.data_url.length - 2);
    tempUrl = `${tempUrl}/${page}`;
    this.setState({ page }, async () => {
      const result = await this.api.get(tempUrl);
      // console.log("newpage", result);
      this.setState({ data: result.data });
    });
  };

  render() {
    const { data_status = "", data = {}, page = 1, limit = 20 } = this.state,
      isLoading = data_status !== "fetched";
    const { updatedLogs = [], maxHeight = "60vh" } = this.props;
    const logsArr = updatedLogs.length !== 0 ? updatedLogs : data.docs;
    let pagesArr = [];
    for (let i = 0; i < data.totalPages; i++) {
      pagesArr.push(i);
    }

    return isLoading ? (
      <></>
    ) : (
      <>
        <PerfectScrollbar
          options={{ wheelPropagation: false }}
          className="scroll"
          style={{ maxHeight }}
        >
          {logsArr.map((activity, index) => {
            const {
              created_by = {},
              created_by_guest = "",
              activity_code = "",
              icon = "",
              date_created = "",
              details = "",
            } = activity;
            return filtered_activities.includes(activity_code) ? (
              <></>
            ) : (
              <div
                className="d-flex align-items-center justify-content-between mb-10"
                key={index}
              >
                <div className="d-flex align-items-center mr-2">
                  <div className="symbol symbol-40 mr-3 flex-shrink-0">
                    <div className="symbol-label">
                      <span>
                        <i
                          className={`${icon} text-${getVariant(
                            activity_code,
                          )}`}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <div>
                    <div
                      href="#"
                      className="text-dark-75 text-hover-primary font-weight-bolder text-break"
                    >
                      {activity_code === "order_moved" ? (
                        <>System </>
                      ) : (
                        <span>
                          {created_by !== null
                            ? `${created_by.first_name} ${created_by.last_name}`
                            : created_by_guest !== null ||
                              created_by_guest !== ""
                            ? created_by_guest
                            : ""}{" "}
                        </span>
                      )}
                      <span>
                        {getText(activity_code)}{" "}
                        {details !== null
                          ? displayDetails(activity_code, details)
                          : " "}
                      </span>
                    </div>
                    <div className="font-size-sm text-muted font-weight-bold mt-1">
                      <span className="text-uppercase">
                        {moment(date_created).format("DD/MM/YYYY hh:mm A")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </PerfectScrollbar>
        {logsArr.length > 0 && (
          <div className="position-sticky" style={{ left: 0 }}>
            <PaginationBar
              pagesArr={pagesArr}
              totalResults={data.totalDocs}
              rows={limit}
              pageNum={page}
              limit={limit}
              onSelectPage={this.handleSelectPage}
            />
          </div>
        )}
      </>
    );
  }
}
