import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Block, EditIcon, Label } from "../components/Base";
import {
  generateDayAdvancedPreorderSettingsDisplay,
  generateOffDatesDisplay,
  // generatePickupPhLink,
  generatePreorderSettingsDisplay,
  generateStoreHoursDisplay,
} from "../helpers";
import { KTLink } from "../components/Base";
import { MapComponent } from "../components/widgets/GMapsWidget";
export default class StoreSettingsDiv extends React.Component {
  render() {
    const { data = {}, state = {} } = this.props;

    return (
      <>
        <Row>
          <Col>
            <Block
              content={
                <>
                  <Label
                    text={
                      <>
                        <span className="va-middle text-capitalize">
                          store name
                        </span>
                        <EditIcon
                          onClick={() => {
                            this.props.showFormModal("name");
                          }}
                        />
                      </>
                    }
                  />
                  <div>{data.name}</div>
                </>
              }
            />
          </Col>
          <Col>
            <Block
              content={
                <>
                  <Label
                    text={
                      <>
                        <span className="va-middle">Store URL</span>
                        {!data.store_url && (
                          <EditIcon
                            onClick={() => {
                              this.props.showFormModal("store_url");
                            }}
                          />
                        )}
                      </>
                    }
                  />
                  <div>
                    {data.store_url ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`//${data.brand.brand_url}${process.env.REACT_APP_DOMAIN}/${data.store_url}`}
                      >
                        {data.brand.brand_url}
                        {process.env.REACT_APP_DOMAIN}/{data.store_url}
                      </a>
                    ) : (
                      "Not set"
                    )}
                  </div>
                </>
              }
            />
          </Col>
        </Row>

        <Row>
          <Col sm={6} xs={12}>
            <Block
              content={
                <>
                  <Label
                    text={
                      <>
                        <span className="va-middle text-capitalize">
                          store hours
                        </span>
                        <EditIcon
                          onClick={() => {
                            this.props.setState({ showStoreHoursModal: true });
                          }}
                        />
                      </>
                    }
                  />
                  <div>
                    {generateStoreHoursDisplay({
                      store_hours: data.store_hours,
                    })}
                  </div>
                </>
              }
            />
          </Col>
          <Col sm={6} xs={12}>
            <Block
              content={
                <>
                  <Label
                    text={
                      <>
                        <span className="va-middle text-capitalize">
                          contact number
                        </span>
                        <EditIcon
                          onClick={() => {
                            this.props.showFormModal("contact_number");
                          }}
                        />
                      </>
                    }
                  />
                  <div>{data.contact_number}</div>
                </>
              }
            />
          </Col>
        </Row>

        <Row>
          <Col sm={6} xs={12}>
            <Block
              content={
                <>
                  <Label
                    text={
                      <>
                        <span className="va-middle text-capitalize">
                          pre order settings
                        </span>
                        <EditIcon
                          onClick={() => {
                            this.props.setState({
                              showInAdvancedPreOrderModal: true,
                            });
                          }}
                        />
                      </>
                    }
                  />
                  <div>{generateDayAdvancedPreorderSettingsDisplay(data)}</div>
                </>
              }
            />
          </Col>
          <Col sm={6} xs={12}>
            <Block
              content={
                <>
                  <Label
                    text={
                      <>
                        <span className="va-middle text-capitalize">
                          prep time default settings
                        </span>
                        <EditIcon
                          onClick={() => {
                            this.props.setState({
                              showPreorderSettingsModal: true,
                            });
                          }}
                        />
                      </>
                    }
                  />
                  <div>{generatePreorderSettingsDisplay(data)}</div>
                </>
              }
            />
          </Col>
        </Row>

        <Row>
          <Col sm={6} xs={12}>
            <Block
              content={
                <>
                  <Label
                    text={
                      <>
                        <span className="va-middle text-capitalize">tips</span>
                        <EditIcon
                          onClick={() => {
                            this.props.showFormModal("is_accepting_tips");
                          }}
                        />
                      </>
                    }
                  />
                  <div>
                    {data.is_accepting_tips
                      ? "Tips are enabled."
                      : "Tips are disabled."}
                  </div>
                </>
              }
            />
          </Col>
          <Col sm={6} xs={12}>
            <Block
              content={
                <>
                  <Label
                    text={
                      <>
                        <span className="va-middle text-capitalize">
                          off dates
                        </span>
                        <EditIcon
                          onClick={() => {
                            this.props.setState({ showOffDatesModal: true });
                          }}
                        />
                      </>
                    }
                  />
                  <div>{generateOffDatesDisplay(data.off_dates)}</div>
                </>
              }
            />
          </Col>
        </Row>

        <Row>
          <Col sm={6} xs={12}>
            <Block
              content={
                <>
                  <Label
                    text={
                      <>
                        <span className="va-middle text-capitalize">
                          location
                        </span>
                        <EditIcon
                          onClick={() => {
                            this.props.showFormModal("location");
                          }}
                        />
                      </>
                    }
                  />
                  <div>{data.location}</div>
                </>
              }
            />
          </Col>
          <Col sm={6} xs={12}>
            {data.apiclient_names.length !== 0 && (
              <Block
                content={
                  <>
                    <Label
                      text={
                        <>
                          <span className="va-middle text-capitalize">
                            partner apps
                          </span>
                        </>
                      }
                    />
                    <div>
                      {data.apiclient_names
                        ? data.apiclient_names.toString()
                        : "No partner apps."}
                    </div>
                  </>
                }
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12}>
            <div className="d-flex justify-content-between w-100">
              <Label
                style={{ marginTop: 15 }}
                text={
                  <>
                    <span className="va-middle text-capitalize">
                      Address Pin
                      {data.hasOwnProperty("geocode_location") ? (
                        state.showMapComponent === false ? (
                          <EditIcon
                            onClick={() =>
                              this.props.setState({
                                showMapComponent: true,
                                showGeoAddress: false,
                              })
                            }
                          />
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}{" "}
                    </span>
                  </>
                }
              />
            </div>

            {state.showMapComponent ? (
              <>
                <MapComponent
                  state={state}
                  height="400px"
                  zoom={state.values.zoomValue}
                  data={data}
                  api={this.props.api}
                  tooltipDisplay={false}
                />
                <div className="d-flex justify-content-end">
                  <Button
                    variant="danger"
                    onClick={() => {
                      this.props.setState({
                        values: {
                          ...state.values,
                          delivery_address: data.geocode_address_string,
                        },
                        showMapComponent: false,
                        showGeoAddress: data.hasOwnProperty("geocode_location"),
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    className="ml-1"
                    onClick={this.props.saveAddress}
                  >
                    Save Address
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
            {state.showGeoAddress ? (
              <>
                <div> {data.geocode_address_string}</div>
              </>
            ) : state.showMapComponent === false ? (
              <p>
                You don't have pin location address for the store visibility{" "}
                <KTLink
                  text={<>Do you want to add one?</>}
                  onClick={() =>
                    this.props.setState({
                      showMapComponent: true,
                      showGeoAddress: false,
                    })
                  }
                />{" "}
              </p>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </>
    );
  }
}
