import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
// import helpertexts from "../../helpertexts";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import {
  Block,
  FormItem,
  HeaderTextWithActions,
  InfoAlertHelper,
} from "../../components/Base";
import { Button, Card, Form, Modal } from "react-bootstrap";

import moment from "moment";
import ProSourceForm from "../../components/ProSourceForm";
import { GetSubmitClassList } from "../../helpers";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PRIMARY_COLOR } from "../../constants";

const MySwal = withReactContent(Swal);

export default function Page() {
  const { id = "" } = useParams(),
    { user } = useSelector((state) => state.auth);

  return <MainComponent id={id} user={user} />;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "My Account",
      showEditCompanyNameModal: false,
      showEditCompanyAddressModal: false,
      mountModals: true,
    };

    this.api = core("pu").get();
    this.data_url = "/util/page-check?page=my-account";

    this.onSubmitCompanyDetails = this.onSubmitCompanyDetails.bind(this);
  }

  onSubmitCompanyDetails = (field = "name", values, setSubmitting) => {
    const { client = {} } = this.props.user;

    values.field = field;
    this.api
      .put({
        url: `/client/${client._id}`,
        data: { data: window.btoa(JSON.stringify(values)) },
      })
      .then(({ data }) => {
        const { result = "ok", approval_number = "" } = data;
        if (result === "ok") {
          MySwal.fire({
            icon: "success",
            title: "Approval #" + approval_number,
            text: "You have successfully submitted this request for approval. We will notify you via email once this request is updated. Thank you!",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
          });

          this.setState({
            showEditCompanyNameModal: false,
            showEditCompanyAddressModal: false,
          });

          // to clear the last values on the modals
          setTimeout(() => {
            this.setState({ mountModals: false });
          }, 50);
          setTimeout(() => {
            this.setState({ mountModals: true });
          }, 50);
        } else {
          MySwal.fire({
            icon: "error",
            title: "Oooops!",
            text: "You have entered an incorrect password. Please enter your correct password to continue with this request.",
          });
        }
      })
      .catch((err) => {
        this.handleError();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  render() {
    const { client = {}, subs = {} } = this.props.user,
      { mountModals = false } = this.state;

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <HeaderTextWithActions title={this.state.title} />

        {mountModals ? (
          <>
            <EditCompanyNameModal
              show={this.state.showEditCompanyNameModal}
              onHide={() => {
                this.setState({ showEditCompanyNameModal: false });
              }}
              values={client}
              onSubmit={this.onSubmitCompanyDetails}
            />

            <EditCompanyAddressModal
              show={this.state.showEditCompanyAddressModal}
              onHide={() => {
                this.setState({ showEditCompanyAddressModal: false });
              }}
              values={client}
              onSubmit={this.onSubmitCompanyDetails}
            />
          </>
        ) : (
          <></>
        )}

        <Card className="card-custom mv-1rem">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Account Information</h3>
            </div>
          </div>
          <Card.Body>
            <InfoAlertHelper
              text={
                "You can update your company name and address here, however for legal purposes, it will be sent for approval before it takes effect."
              }
            />
            <Block
              title={
                <>
                  <span className="va-middle">company name</span>
                  <button
                    type="button"
                    className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                    onClick={() => {
                      this.setState({ showEditCompanyNameModal: true });
                    }}
                  >
                    <i className="far fa-edit text-primary" />
                  </button>
                </>
              }
              content={<>{client.name}</>}
            />
            <Block
              title={
                <>
                  <span className="va-middle">company address</span>
                  <button
                    type="button"
                    className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                    onClick={() => {
                      this.setState({ showEditCompanyAddressModal: true });
                    }}
                  >
                    <i className="far fa-edit text-primary" />
                  </button>
                </>
              }
              content={<>{client.address}</>}
            />
            <Block
              title="Date Started"
              content={<>{moment(client.date_created).format("MMM-DD-YYYY")}</>}
            />
          </Card.Body>
        </Card>

        <Card className="card-custom mv-1rem">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">My Subscription</h3>
            </div>
          </div>
          <Card.Body>
            <Block
              title="Subscription Plan"
              content={
                <>
                  <span className="text-capitalize">{subs.name}</span>
                </>
              }
            />
            <Block
              title="Date Subscribed"
              content={<>{moment(subs.date_created).format("MMM-DD-YYYY")}</>}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}

class EditCompanyNameModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { name = "" } = this.props.values;

    this.state = {
      values: {
        name,
        confirm_password: "",
        reason: "",
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      file: null,
    };

    this.validate = { required: ["name", "confirm_password", "reason"] };
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit("name", values, setSubmitting);
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      },
      { show, onHide } = this.props;

    return (
      <>
        <Modal show={show} onHide={onHide} centered>
          <Modal.Header>
            <Modal.Title>Edit company name</Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              <i aria-hidden="true" className="ki ki-close"></i>
            </button>
          </Modal.Header>
          <Form onSubmit={this.handleOnSubmit}>
            <Modal.Body>
              <FormItem
                label={"Company Name"}
                name={"name"}
                inputProps={{
                  name: "name",
                  placeholder: "Company Name",
                  value: this.state.values["name"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"text"}
                showRequired={true}
                normal
              />

              <FormItem
                label={"Reason"}
                name={"reason"}
                inputProps={{
                  name: "reason",
                  placeholder:
                    "Please provide a reason on why you are changing this detail...",
                  value: this.state.values["reason"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"textarea"}
                showRequired={true}
                normal
              />

              <FormItem
                label={"Enter your password to continue"}
                name={"confirm_password"}
                inputProps={{
                  name: "confirm_password",
                  placeholder: "Password",
                  value: this.state.values["confirm_password"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"password"}
                showRequired={true}
                normal
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                className={GetSubmitClassList(this.state.isSubmitting, "")}
              >
                Save changes
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

class EditCompanyAddressModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { address = "" } = this.props.values;

    this.state = {
      values: {
        address,
        confirm_password: "",
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      file: null,
    };

    this.validate = { required: ["address", "confirm_password"] };
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit("address", values, setSubmitting);
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      },
      { show, onHide } = this.props;

    return (
      <>
        <Modal show={show} onHide={onHide} centered>
          <Modal.Header>
            <Modal.Title>Edit company address</Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              <i aria-hidden="true" className="ki ki-close"></i>
            </button>
          </Modal.Header>
          <Form onSubmit={this.handleOnSubmit}>
            <Modal.Body>
              <FormItem
                label={"Company Address"}
                name={"address"}
                inputProps={{
                  name: "address",
                  placeholder: "Company Address",
                  value: this.state.values["address"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"text"}
                showRequired={true}
                normal
              />

              <FormItem
                label={"Enter your password to continue"}
                name={"confirm_password"}
                inputProps={{
                  name: "confirm_password",
                  placeholder: "Password",
                  value: this.state.values["confirm_password"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"password"}
                showRequired={true}
                normal
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                className={GetSubmitClassList(this.state.isSubmitting, "")}
              >
                Save changes
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
