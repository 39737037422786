import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { Redirect, Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Datatable from "react-data-table-component";
// import helpertexts from "../../helpertexts";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import Breadcrumbs from "../../components/Breadcrumbs";
import { HeaderTextWithActions } from "../../components/Base";
import moment from "moment";
import { Row, Col, Card } from "react-bootstrap";
import { generatePickupPhLink } from "../../helpers";

export default function Page() {
  const { group_id = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  return <MainComponent id={group_id} user={user} />;
}

function Table({ data, group_id = "", progressPending = false }) {
  const [searchText, setSearchText] = React.useState("");
  const filteredItems =
    searchText !== ""
      ? data.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        })
      : data;

  return (
    <>
      <Datatable
        columns={[
          {
            name: "Brand Name",
            selector: (row) => {
              return row.name;
            },
            format: (row) => {
              return (
                <Link
                  to={`/admin/groups/${group_id}/brands/${row._id}`}
                  className="font-weight-bolder text-primary"
                >
                  {row.name}
                </Link>
              );
            },
            sortable: true,
            style: "font-weight-bold",
          },
          {
            name: "Brand URL",
            selector: (row) => {
              return row.brand_url;
            },
            format: (row) => {
              return (
                <a href={generatePickupPhLink(row.brand_url)}>
                  {generatePickupPhLink(row.brand_url, false)}
                </a>
              );
            },
          },
          {
            name: "Date Created",
            selector: (row) => {
              return moment(row.date_created).format("MMM D, YYYY hh:mm A");
            },
          },
        ]}
        data={filteredItems}
        pagination
        noHeader
        highlightOnHover
        subHeader
        subHeaderWrap={false}
        subHeaderComponent={
          <>
            <Row>
              <Col>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="flaticon-search"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </>
        }
        progressPending={progressPending}
      />
    </>
  );
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Groups",
    };

    this.api = core("pu").get();
    this.data_url = "/client/" + this.props.id + "/brands";
  }

  handleOnDataFetched = () => {
    let { data = {} } = this.state;
    data.brands = data.brands.map((item) => {
      item.id = item._id;
      return item;
    });
    this.setState({ data, title: data.name + " ≫ Brands" });
  };

  render() {
    const { data = [], data_status = "" } = this.state,
      isLoading = data_status !== "fetched";

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <HeaderTextWithActions
          title={"Brands"}
          className="mb-8"
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item text="Groups" link={`/admin/groups`} />
              <Breadcrumbs.Item
                text={data.name}
                link={`/admin/groups/${this.props.id}`}
              />
              <Breadcrumbs.Item text={"Brands"} link={null} />
            </Breadcrumbs>
          }
        />

        <Card>
          <Card.Body>
            <Table
              data={data.brands}
              group_id={this.props.id}
              progressPending={isLoading}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}
