import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import {
  WarningAlert,
  Label,
  RequiredAsterisk,
  StoreHasDelivery,
  storeHasCurbside,
  storeHasMealPlan,
  storeHasPickupActive,
} from "../../components/Base";
import OrderDateDropdown from "./OrderDateDropdown";
import OrderTimeDropDown from "./OrderTimeDropdown";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DANGER_SWAL_COLOR } from "../../constants";
const MySwal = withReactContent(Swal);

export default class OrderDetailsForm extends ProSourceForm {
  constructor(props) {
    super(props);

    const { values = {}, brand = {}, order_type = "pickup" } = this.props,
      { stores = [] } = brand;

    let {
      store = "",
      order_date = "",
      order_time = "",
      selectedUrl = "",
      order_subtype = "",
      meal_plan_type = "",
    } = values;

    let selected_store = {},
      isStoreOpenForOrders = true;

    if (store === "") {
      if (stores.length === 1) {
        selected_store = stores[0];

        if (order_type === "delivery") {
          for (const st of stores) {
            if (StoreHasDelivery(st)) {
              selected_store = st;
              break;
            }
          }
        }

        if (order_type === "pickup" && order_subtype === "curbside_pickup") {
          for (const st of stores) {
            if (storeHasCurbside(st)) {
              selected_store = st;
              break;
            }
          }
        }

        if (order_type === "delivery" && order_subtype === "meal_plan") {
          for (const st of stores) {
            if (storeHasMealPlan(st)) {
              selected_store = st;
              break;
            }
          }
        }

        store = selected_store._id;
        selectedUrl = selected_store.store_url;
      }
    }

    this.state = {
      values: {
        store,
        selectedUrl,
        order_date,
        order_time,
        order_type,
        order_subtype,
        meal_plan_type,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      date_options: [],
      time_options: [],
      selected_store,
      store_time_options: {},
      isStoreOpenForOrders,
      redirectTo: "",
    };

    this.validate = {
      required: ["order_date", "order_time", "store"],
    };
  }

  onChange = (e) => {
    let name = e.target.name,
      value = e.currentTarget.value;
    let { values = {} } = this.state;

    if (name === "order_date") {
      this.setState({ mountOrderTimeDropdown: false, values });
      setTimeout(() => {
        this.setState({ mountOrderTimeDropdown: true });
      }, 50);
    } else if (name === "store") {
      if (value !== "") {
        const { stores = [] } = this.props.brand;
        const selected_store = stores.find((s) => s._id === value);
        values.selectedUrl = `${selected_store.store_url}`;
        this.setState(
          {
            mountOrderDateDropdown: false,
            values,
            selected_store,
            isStoreOpenForOrders: selected_store.is_store_open_for_orders,
          },
          () => {
            this.setState({ mountOrderDateDropdown: true });
          },
        );
      } else {
        values.order_date = "";
        values.order_time = "";
        this.setState({
          mountOrderDateDropdown: false,
          mountOrderTimeDropdown: false,
          values,
          selected_store: {},
        });
        setTimeout(() => {
          this.setState({
            mountOrderDateDropdown: true,
            mountOrderTimeDropdown: true,
          });
        }, 50);
      }
    }
  };

  onSubmit = (values, setSubmitting) => {
    if (!values.selectedUrl) {
      const { stores = [] } = this.props.brand,
        { selected_store = {} } = values;
      if (Object.keys(selected_store).length > 0) {
        values.selectedUrl = `${selected_store.store_url}`;
      } else {
        values.selected_store = stores[0];
        if (
          values.order_subtype === "meal_plan" &&
          values.selected_store.isMealPlanActive === true
        ) {
          values.selectedUrl = `${stores[0].store_url}`;
        } else {
          values.selectedUrl = `${stores[0].store_url}`;
          MySwal.fire({
            icon: "error",
            title: "Ooops!",
            text: "Meal Plan fulfillment is not turned on in this store. If you wish to continue ordering, please contact the store.",
            confirmButtonColor: DANGER_SWAL_COLOR,
            confirmButtonText: "Back to Home",
          }).then(() => {
            window.location.href = "/";
          });
        }
      }
    }

    this.props.onSubmit(values, setSubmitting);
  };

  handleOnClick3DayMealPlan = () => {
    const values = this.state;
    values.order_subtype = "meal_plan";
    values.meal_plan_type = "3DayMealPlan";
    this.setState({ values });
  };

  handleOnClick5DayMealPlan = () => {
    const values = this.state;
    values.order_subtype = "meal_plan";
    values.meal_plan_type = "5DayMealPlan";
    this.setState({ values });
  };

  render() {
    const {
      show = false,
      onHide,
      brand = {},
      brand_url = "",
      website_theme = {},
    } = this.props;
    const { stores = [] } = brand;
    const {
      values = {},
      errors = {},
      selected_store = {},
      mountOrderTimeDropdown = false,
      mountOrderDateDropdown = true,
    } = this.state;

    const inputActions = {
      onChange: this.handleOnChange,
      onBlur: this.handleOnBlur,
    };

    const {
      button_text_color = "",
      button_color = "",
      homepage_button_color = "",
      homepage_button_text_color = "",
    } = website_theme;

    const isHomePage = Boolean(window.location.pathname === "/");

    const buttonStyle = {
      borderColor: isHomePage ? homepage_button_color : button_color,
      backgroundColor: isHomePage ? homepage_button_color : button_color,
      color: isHomePage ? homepage_button_text_color : button_text_color,
    };

    const isOpenForOrders = this.state.isStoreOpenForOrders;

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            {!isOpenForOrders && (
              <WarningAlert
                message={
                  <>Sorry, this store is closed. Please select another one.</>
                }
              />
            )}
            <Form.Group>
              <Label
                text={
                  <>
                    <span className="text-capitalize">store location</span>{" "}
                    {stores.length > 1 && <RequiredAsterisk />}
                  </>
                }
              />
              {stores.length > 1 ? (
                <>
                  <Form.Control
                    as="select"
                    value={values["store"]}
                    name="store"
                    {...inputActions}
                  >
                    <option value="">Select store location</option>
                    {stores.map((store, index) => {
                      const noDelivery =
                        this.props.order_type === "delivery" &&
                        this.props.order_subtype === "delivery" &&
                        !StoreHasDelivery(store);
                      const noCurbside =
                        this.props.order_type === "pickup" &&
                        this.props.order_subtype === "curbside_pickup" &&
                        !storeHasCurbside(store);
                      const noMealPlan =
                        this.props.order_type === "delivery" &&
                        this.props.order_subtype === "meal_plan" &&
                        !storeHasMealPlan(store);
                      const noPickup =
                        this.props.order_type === "pickup" &&
                        this.props.order_subtype === "pickup" &&
                        !storeHasPickupActive(store);
                      return (
                        <option
                          value={store._id}
                          key={index}
                          disabled={
                            noDelivery || noCurbside || noMealPlan || noPickup
                          }
                        >
                          {store.name} {noDelivery && "(Delivery Unavailable)"}{" "}
                          {noCurbside && "(Curbside Pickup Unavailable)"}{" "}
                          {noMealPlan && "(Meal Plan Unavailable)"}{" "}
                          {noPickup && "(Pickup Unavailable)"}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <div className="fv-plugins-message-container">
                    {errors.hasOwnProperty("store") && (
                      <div className="fv-help-block">{errors["store"]}</div>
                    )}
                  </div>
                </>
              ) : (
                <p>{stores?.[0]?.name || ""}</p>
              )}
            </Form.Group>

            {isOpenForOrders && this.props.order_subtype !== "meal_plan" ? (
              <Row>
                <Col>
                  {mountOrderDateDropdown && (
                    <OrderDateDropdown
                      brand_url={brand_url}
                      store={selected_store}
                      inputActions={inputActions}
                      hasError={errors.hasOwnProperty("order_date")}
                      error={errors["order_date"]}
                      order_type={values["order_type"]}
                      value={values["order_date"]}
                      setOrderDate={(order_date) => {
                        let { values = {} } = this.state;
                        values.order_date = order_date;
                        this.setState({ values });
                      }}
                    />
                  )}
                </Col>
                <Col>
                  {mountOrderTimeDropdown && (
                    <OrderTimeDropDown
                      brand_url={brand_url}
                      store={selected_store}
                      inputActions={inputActions}
                      order_date={values["order_date"]}
                      hasError={errors.hasOwnProperty("order_time")}
                      error={errors["order_time"]}
                      order_type={values["order_type"]}
                      value={values["order_time"]}
                    />
                  )}
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.order_subtype === "meal_plan" && (
              <>
                <Row>
                  <Col className="d-flex flex-column align-items-center">
                    {selected_store.mealPlanLabels &&
                      selected_store.mealPlanLabels.hasOwnProperty(
                        "3DayMealPlanTop",
                      ) && (
                        <div className="mt-2">
                          {selected_store.mealPlanLabels["3DayMealPlanTop"]}
                        </div>
                      )}
                    <Button
                      style={{
                        height: "70px",
                        width: "100px",
                        borderColor: "transparent",
                        backgroundColor:
                          this.state.values.meal_plan_type === "3DayMealPlan"
                            ? buttonStyle.backgroundColor
                            : "lightgray",
                      }}
                      className="btn mt-3"
                      onClick={() => this.handleOnClick3DayMealPlan()}
                    >
                      <i
                        style={{ color: button_text_color }}
                        className="far fa-calendar-check mr-3"
                      ></i>
                      3 DAY MEAL PLAN
                    </Button>
                    {selected_store.mealPlanLabels &&
                      selected_store.mealPlanLabels.hasOwnProperty(
                        "3DayMealPlanBtm",
                      ) && (
                        <div className="mt-2">
                          {selected_store.mealPlanLabels["3DayMealPlanBtm"]}
                        </div>
                      )}
                  </Col>
                  <Col className="d-flex flex-column align-items-center">
                    {selected_store.mealPlanLabels &&
                      selected_store.mealPlanLabels.hasOwnProperty(
                        "5DayMealPlanTop",
                      ) && (
                        <div className="mt-2">
                          {selected_store.mealPlanLabels["5DayMealPlanTop"]}
                        </div>
                      )}
                    <Button
                      style={{
                        height: "70px",
                        width: "100px",
                        borderColor: "transparent",
                        backgroundColor:
                          this.state.values.meal_plan_type === "5DayMealPlan"
                            ? buttonStyle.backgroundColor
                            : "lightgray",
                      }}
                      className="btn mt-3"
                      onClick={() => this.handleOnClick5DayMealPlan()}
                    >
                      <i
                        style={{ color: button_text_color }}
                        className="far fa-calendar-check mr-3"
                      ></i>
                      5 DAY MEAL PLAN
                    </Button>
                    {selected_store.mealPlanLabels &&
                      selected_store.mealPlanLabels.hasOwnProperty(
                        "5DayMealPlanBtm",
                      ) && (
                        <div className="mt-2">
                          {selected_store.mealPlanLabels["5DayMealPlanBtm"]}
                        </div>
                      )}
                  </Col>
                </Row>
              </>
            )}
          </Modal.Body>

          {isOpenForOrders && (
            <Modal.Footer>
              <Button
                variant="outline-primary"
                className="br-0 btn-block font-weight-bolder"
                type="submit"
                style={buttonStyle}
              >
                Proceed
              </Button>
            </Modal.Footer>
          )}
        </Form>
      </Modal>
    );
  }
}
