import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect, Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import helpertexts from "../helpertexts";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import Breadcrumbs from "../../components/Breadcrumbs";
import StoreCard from "../../components/widgets/StoreCard";
import SelectionModal from "../../components/widgets/SelectionModal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Button } from "react-bootstrap";
import { SVGIcon } from "../../../app/components/Base";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { isMobile } from "react-device-detect";
import { ROLES, PRIMARY_COLOR } from "../../constants";

const MySwal = withReactContent(Swal);

export default function Page() {
  const { brand_url = "", mode = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  return (
    <MainComponent id={brand_url} user={user} webview={mode === "webview"} />
  );
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Stores",
      brands: [],
      filteredBrands: [],
      groupName: "Group",
      copyLoadingId: false,
      showAddModal: false,
      searchKeyword: "",
    };

    this.api = core("pu").get();
    this.getStores();
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.brands !== this.state.brands) {
      this.triggerSearch();
    }
  }

  getStores = async () => {
    try {
      this.setState({ showSplashScreen: true });
      const result = await this.api.get("/brand/get-stores");
      if (result.data) {
        const groupName = result.data.groupName;
        const group_url = result.data.group_url;
        const group_website_bg = result.data.group_website_bg;
        const group_website_theme = result.data.group_website_theme;
        const group_website_content = result.data.group_website_content;
        const group_logo = result.data.group_logo;
        const brands = result.data.brands;
        // console.log(brands);
        // const brands = result.data.brands.filter(item => item.brand_url !== group_url);
        // const group = result.data.brands.filter(item => item.brand_url === group_url);
        this.setState({
          brands,
          filteredBrands: brands,
          groupName,
          group_url,
          group_website_bg,
          group_website_theme,
          group_website_content,
          group_logo,
          // group,
        });
      }
      this.setState({ showSplashScreen: false });
    } catch (error) {
      console.log(error);
    }
  };

  createCopy = async (brandId, storeId) => {
    try {
      this.setState({ copyLoadingId: storeId });
      const result = await this.api.post({
        url: `/brand/copy-store`,
        data: { brandId, storeId },
      });
      if (result.data) {
        this.setState({ brands: result.data });
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "Store copied successfully",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          timer: 1000,
        });
      }
    } catch (error) {
      MySwal.fire({
        icon: "error",
        ...error.response.data,
      });
    }
    this.setState({ copyLoadingId: false });
  };

  search = (evt) => {
    evt.persist();
    this.setState({ searchKeyword: evt.target.value }, () => {
      this.triggerSearch();
    });
  };

  triggerSearch = () => {
    if (this.state.searchKeyword) {
      this.setState((prevState) => {
        const filteredBrands = prevState.brands.filter((brand) => {
          let valid = false;
          if (
            brand.name
              .toLowerCase()
              .indexOf(this.state.searchKeyword.toLowerCase()) > -1
          ) {
            valid = true;
          }
          const validStores = brand.stores.filter((store) =>
            store.hasOwnProperty("name")
              ? store.name
                  .toLowerCase()
                  .indexOf(this.state.searchKeyword.toLowerCase()) > -1
              : null,
          );
          if (validStores.length > 0) {
            valid = true;
          }
          return valid;
        });
        return { filteredBrands };
      });
    } else {
      this.setState((prevState) => {
        const filteredBrands = [...prevState.brands];
        return { filteredBrands };
      });
    }
  };

  render() {
    const isLoading = this.state.brands.length === 0;
    const brands = [...this.state.filteredBrands];
    const isMulti = this.props.user.group_type === "multi";
    const role = this.props.user.iam_role;
    const adminRole = ROLES[role] < ROLES.store_admin;
    const { group_url, groupName } = this.state;
    // console.log(this.state);
    return (
      <>
        <TitleHelmet title={this.state.title} />
        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}
        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}
        {this.props.webview && isMulti ? (
          <div className="row">
            <div
              className={`${
                adminRole ? "col-md-11 col-sm-6" : "col-md-12 col-sm-12"
              }`}
            >
              <input
                type="text"
                placeholder="Search..."
                className="form-control"
                onChange={this.search}
              />
            </div>
            {adminRole ? (
              <div className="col-md-1 col-sm-6">
                <Button
                  color="primary"
                  className="float-right"
                  onClick={() => {
                    this.setState({ showAddModal: true });
                  }}
                >
                  Add
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className="row">
            <div className="col-md-9 col-sm-12">
              <Breadcrumbs>
                <Breadcrumbs.Item text={this.state.groupName} />
                <Breadcrumbs.Item
                  text="Stores"
                  link={`/stores`}
                  active="true"
                />
              </Breadcrumbs>
            </div>
            {isMulti ? (
              <>
                <div className="col-md-2 col-sm-6">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="form-control"
                    onChange={this.search}
                  />
                </div>
                {adminRole ? (
                  <div className="col-md-1 col-sm-6">
                    {isMobile ? <br /> : null}
                    <Button
                      color="primary"
                      className="float-right"
                      onClick={() => {
                        this.setState({ showAddModal: true });
                      }}
                    >
                      Add
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : null}
          </div>
        )}

        <br />
        <br />

        {!isLoading && group_url !== "" && role === "group_admin" && (
          <div>
            <span className="pl-3">
              <span>
                <span className="display-4 font-weight-bolder">
                  {group_url !== "" ? groupName : null}
                </span>
                <div className="float-right mt-3">
                  <Link
                    className="text-muted font-weight-bolder"
                    to={`${this.props.webview ? "/webview" : ""}/groups/${
                      this.state.group_url
                    }.pickup.ph/settings`}
                  >
                    <SVGIcon
                      icon="General/Settings-2"
                      title="Settings"
                      variant="gray"
                    />{" "}
                    Group Settings
                  </Link>
                </div>
              </span>
            </span>
          </div>
        )}

        {!isLoading && (
          <Accordion
            allowMultipleExpanded={true}
            allowZeroExpanded={true}
            preExpanded={["acc-0"]}
          >
            {brands &&
              brands.map((brand, index) => {
                return (
                  <div key={index}>
                    <AccordionItem uuid={`acc-${index}`}>
                      <AccordionItemHeading>
                        <AccordionItemButton style={{ paddingBottom: 0 }}>
                          <span className="pl-3">
                            <span>
                              <span className="display-4 font-weight-bolder">
                                {brand.name}
                              </span>
                              <div className="mt-1 ml-10">
                                <Link
                                  className="font-weight-bolder"
                                  to={`${
                                    this.props.webview ? "/webview" : ""
                                  }/brands/${
                                    brand.brand_url
                                  }.pickup.ph/settings`}
                                  style={{
                                    color: "#8950FC",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  <SVGIcon
                                    className="svg-icon-md mr-1 "
                                    icon="General/Settings-2"
                                    title={"Brand Settings"}
                                    variant="info"
                                  />{" "}
                                  Brand Settings
                                </Link>
                              </div>
                            </span>
                          </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="pl-sm-8">
                          {brand.stores.map((store, index) => {
                            return store._id ? (
                              <StoreCard
                                key={index}
                                menu_link={`${
                                  this.props.webview ? "/webview" : ""
                                }/stores/${store._id}/menu`}
                                create_cart_link={`${
                                  this.props.webview ? "/webview" : ""
                                }/stores/${store._id}/create-concierge`}
                                settings_link={`${
                                  this.props.webview ? "/webview" : ""
                                }/stores/${store._id}/settings`}
                                {...store}
                                createCopy={() =>
                                  this.createCopy(brand._id, store._id)
                                }
                                copyLoading={
                                  this.state.copyLoadingId === store._id
                                }
                                createCopyAllowed={isMulti && adminRole}
                                store_id={store._id}
                                brand_url={brand.brand_url}
                              />
                            ) : null;
                          })}
                          <br />
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <br />
                  </div>
                );
              })}
          </Accordion>
        )}
        <SelectionModal
          show={this.state.showAddModal}
          onHide={() => {
            this.setState({ showAddModal: false });
          }}
          title={<>What do you want to add?</>}
          options={[
            {
              title: "Brand",
              icon: "Shopping/Price1",
              desc: "",
              onClick: () => {
                this.setState({ showAddModal: false });
              },
              to: `${this.props.webview ? "/webview" : ""}/stores/new-brand`,
            },
            {
              title: "Store",
              icon: "Shopping/Cart1",
              desc: "",
              onClick: () => {
                this.setState({ showAddModal: false });
              },
              to: `${this.props.webview ? "/webview" : ""}/stores/new-store`,
            },
          ]}
        />
      </>
    );
  }
}
