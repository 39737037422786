import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FormItem, InfoAlertHelper } from "../components/Base";
import ProSourceForm from "../components/ProSourceForm";
import { GetSubmitClassList, deliveryPartnerHost } from "../helpers";

export default class AddDeliveryKeysModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { data = {} } = this.props,
      { keys = {} } = data,
      { apikey = "", secret = "", host = "", name = "" } = keys;

    this.state = {
      values: {
        apikey,
        secret,
        host,
        name,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };
  }

  onSubmit = (values, setSubmitting) => {
    // this.state.values["host"]
    // this.state.values["apikey"]
    // this.state.values["secret"]
    // console.log("adddeliverykeysmodal onsubmit values: ", values)
    if (values.name === "") {
      values.host = "";
    }
    values.host = values.host.trim();
    values.apikey = values.apikey.trim();
    values.secret = values.secret.trim();
    // console.log("adddeliverykeysmodal onsubmit new values: ", values)
    this.props.onSubmit(values, setSubmitting);
  };
  selectOnChange = (event) => {
    const target = event.target,
      value = target.value,
      name = target.name;
    let { values } = this.state;

    values[name] = value;
    values["host"] = deliveryPartnerHost(value);
    this.setState({
      values: values,
    });

    this.setStateKey("touched", name, "true");
    this.validateForm();

    // checks if afterOnChange function exists
    this.onChange && this.onChange(event);
  };

  selectOnChangeMarket = (event) => {
    const target = event.target,
      value = target.value,
      name = target.name;
    let { values } = this.state;

    values[name] = value;
    this.setState({
      values: values,
    });

    this.setStateKey("touched", name, "true");
    this.validateForm();

    // checks if afterOnChange function exists
    this.onChange && this.onChange(event);
  };

  render() {
    const { show, onHide } = this.props;
    const { values } = this.state;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>Add Delivery Keys</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            <InfoAlertHelper
              text={
                "Your deliverys keys are given to you by your respective Delivery Partner, You can only get your live API keys once your account is activated. , adding same partner will overwrite the previously saved Keys"
              }
            />
            <FormItem
              label={"Partner"}
              name={"name"}
              customFormControl
              custom={
                <>
                  <Form.Control
                    as="select"
                    name="name"
                    className="text-capitalize"
                    value={values.name}
                    onChange={this.selectOnChange}
                  >
                    <option value="">Select type</option>
                    <option value="lalamove">Lalamove</option>
                    <option value="mrspeedy">Mr Speedy</option>
                    <option value="grab">Grab</option>
                  </Form.Control>
                </>
              }
              inputActions={inputActions}
              actions={actions}
              showRequired={false}
              normal
            />

            <FormItem
              normal
              label={"Host"}
              name={"host"}
              inputProps={{
                name: "host",
                placeholder: "Public Key",
                value: this.state.values["host"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
            />
            {values.name === "lalamove" ? (
              <FormItem
                label={"Market Code"}
                name={"market_code"}
                customFormControl
                custom={
                  <>
                    <Form.Control
                      as="select"
                      name="market_code"
                      className="text-capitalize"
                      value={values.market_code}
                      onChange={this.selectOnChangeMarket}
                    >
                      <option value="">Select type</option>
                      <option value="PH_CEB">PH_CEB</option>
                      <option value="PH_MNL">PH_MNL</option>
                      <option value="PH_PAM">PH_PAM</option>
                    </Form.Control>
                  </>
                }
                inputActions={inputActions}
                actions={actions}
                showRequired={false}
                normal
              />
            ) : (
              <></>
            )}
            <FormItem
              normal
              label={values.name === "grab" ? "CLIENT KEY" : "API KEY"}
              name={"apikey"}
              inputProps={{
                name: "apikey",
                placeholder: values.name === "grab" ? "CLIENT KEY" : "API KEY",
                value: this.state.values["apikey"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
            />
            {values.name === "lalamove" || values.name === "grab" ? (
              <FormItem
                normal
                label={"Secret Key"}
                name={"secret"}
                inputProps={{
                  name: "secret",
                  placeholder: "SECRET KEY",
                  value: this.state.values["secret"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"text"}
                showRequired={true}
              />
            ) : (
              <></>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="primary"
              className={GetSubmitClassList(this.state.isSubmitting, "")}
              disabled={this.state.isSubmitting ? "disabled" : null}
            >
              Add Delivery Partner
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
