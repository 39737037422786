import { PRIMARY_COLOR } from "./constants";

export function setTheme(website_theme = {}) {
  const {
    text_color = "#464E5F",
    highlighted_text_color = PRIMARY_COLOR,
    background_color = "white",
    link_text_color = PRIMARY_COLOR,
    icon_color = PRIMARY_COLOR,
    button_text_color = "white",
    button_color = PRIMARY_COLOR,
    top_navigation_button_color = PRIMARY_COLOR,
    top_navigation_button_text_color = "#464E5F",
    category_header_text_color = PRIMARY_COLOR,
    homepage_icon_color = PRIMARY_COLOR,
    homepage_button_color = PRIMARY_COLOR,
    homepage_button_text_color = "white",
    homepage_highlighted_text_color = PRIMARY_COLOR,
  } = website_theme;

  return {
    text_color,
    highlighted_text_color,
    background_color,
    link_text_color,
    icon_color,
    button_text_color,
    button_color,
    top_navigation_button_color,
    top_navigation_button_text_color,
    category_header_text_color,
    homepage_icon_color,
    homepage_button_color,
    homepage_button_text_color,
    homepage_highlighted_text_color,
  };
}
