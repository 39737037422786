import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Datatable from "react-data-table-component";
// import helpertexts from "../../helpertexts";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import Breadcrumbs from "../../components/Breadcrumbs";
import { HeaderTextWithActions, LoadingDiv } from "../../components/Base";
import moment from "moment";
import { Row, Col, Card } from "react-bootstrap";
import { roleDisplay } from "../../helpers";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";

export default function Page() {
  const { group_id = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  return <MainComponent id={group_id} user={user} />;
}

function Table({ data, progressPending = false }) {
  const [searchText, setSearchText] = React.useState("");
  const filteredItems =
    searchText !== ""
      ? data.filter((item) => {
          let name = item.first_name + " " + item.last_name;
          return name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
        })
      : data;

  return (
    <>
      <Datatable
        columns={[
          {
            name: "Name",
            selector: (row) => {
              return row.first_name + " " + row.last_name;
            },
            sortable: true,
          },
          {
            name: "Email",
            selector: (row) => {
              return row.email;
            },
            sortable: true,
          },
          {
            name: "Role",
            selector: (row) => {
              return roleDisplay(row.iam_role);
            },
            format: (row) => {
              return (
                <span className="text-capitalize">
                  {" "}
                  {roleDisplay(row.iam_role)}{" "}
                </span>
              );
            },
            sortable: true,
          },
          {
            name: "Date Created",
            selector: (row) => {
              return moment(row.date_created).format("MMM D, YYYY hh:mm A");
            },
          },
        ]}
        data={filteredItems}
        pagination
        noHeader
        highlightOnHover
        subHeader
        subHeaderWrap={false}
        subHeaderComponent={
          <>
            <Row>
              <Col>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="flaticon-search"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </>
        }
        progressPending={progressPending}
      />
    </>
  );
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Groups",
    };

    this.api = core("pu").get();
    this.data_url = "/client/" + this.props.id + "/users";
  }

  handleOnDataFetched = () => {
    let { data = {} } = this.state;
    data.users = data.users.map((item) => {
      item.id = item._id;
      return item;
    });
    this.setState({ data, title: data.name + " ≫ Users" });
  };

  render() {
    const { data = [], data_status = "" } = this.state,
      isLoading = data_status !== "fetched";

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <HeaderTextWithActions
          title={"Users"}
          className="mb-8"
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item text="Groups" link={`/admin/groups`} />
              <Breadcrumbs.Item
                text={data.name}
                link={`/admin/groups/${this.props.id}`}
              />
              <Breadcrumbs.Item text={"Users"} link={null} />
            </Breadcrumbs>
          }
        />

        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          <Card>
            <Card.Body>
              <Table
                data={data.users}
                group_id={this.props.id}
                progressPending={isLoading}
              />
            </Card.Body>
          </Card>
        )}
      </>
    );
  }
}
