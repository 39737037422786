export default function () {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      register();
    });
  }
}

function register() {
  navigator.serviceWorker
    .register("worker.js", {
      scope: ".",
    })
    .then(
      function (registration) {
        // Registration was successful
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope,
        );
      },
      function (err) {
        // registration failed :(
        console.log("ServiceWorker registration failed: ", err);
      },
    );
}
