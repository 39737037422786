import React from "react";
import { Helmet } from "react-helmet";
import { WEBSITE_NAME } from "../constants";

export default class TitleHelmet extends React.Component {
  render() {
    const { showWebsiteName = true, title = "" } = this.props;

    return (
      <Helmet>
        <title>
          {title} {showWebsiteName ? " | " + WEBSITE_NAME : ""}{" "}
        </title>
      </Helmet>
    );
  }
}
