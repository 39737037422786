import React from "react";
import moment from "moment";
import { Row, Col } from "react-bootstrap";

import { toPesoAmount } from "../../helpers";
import AreaWidget from "../../components/widgets/AreaWidget";
import ItemRanking from "../../components/widgets/ItemRanking";
import { DB_DATE_FORMAT } from "../../constants";
import SalesByFulfillmentCard from "./SalesByFulfillmentCard";
import CustomerMix from "./CustomerMix";
import CardList, { CardListByReferrer } from "./CardList";

const AnalyticsGrid = (props) => {
  const { data = {}, params = {} } = props;

  const { totalSales = 0 } = data;

  const cardListItemImgStyling = { maxHeight: "3rem" };

  return (
    <>
      <div className="">
        <Row className="align-items-stretch">
          <Col sm={12} lg={8} className="analytics-card">
            <SalesByFulfillmentCard params={params} />
          </Col>
          <Col sm={12} lg={4} className="px-0 px-lg-4">
            <AreaWidget
              className="card card-custom px-0 mt-2 mt-lg-0 total-sales-for-today"
              id="total-sales-for-today"
              header={"Total Sales for"}
              displayData={totalSales}
              view={"item-sold"}
              seriesName="Item Sold"
              seriesAttr="total"
              params={params}
              height={290}
              hasDropDown={false}
              customOptions={{
                stroke: {
                  show: true,
                  curve: "smooth",
                  lineCap: "round",
                  width: 0,
                },
                tooltip: {
                  y: {
                    formatter: function (
                      value,
                      { series, seriesIndex, dataPointIndex, w },
                    ) {
                      return value + " items";
                    },
                  },
                  custom: function ({
                    series,
                    seriesIndex,
                    dataPointIndex,
                    w,
                  }) {
                    let categoryLabel =
                      w.globals.categoryLabels[dataPointIndex];
                    categoryLabel =
                      categoryLabel < 10 ? "0" + categoryLabel : categoryLabel; // expects  2 digits or moment will return an invalid date

                    const header = moment(
                      `${moment().format(DB_DATE_FORMAT)} ${categoryLabel}`,
                    ).format("ha");

                    return `
                        <div class="barContainer">
                            <h5 class="barHeader">${header}</h5>

                            <div class="barContent">
                                ${w.globals.seriesNames.reduce(
                                  (acc, cur, i) => {
                                    return `${acc}  <div class="barSeriesContent">
                                        <span class="barBadge" style="background-color: ${w.globals.colors[i]}"></span>
                                        <span>${cur}</span>
                                        <span class='barData'>${series[i][dataPointIndex]} items</span>
                                    </div>`;
                                  },
                                  "",
                                )}
                            </div>
                        </div>
                    `;
                  },
                },
                colors: ["#50b585", "#795ecc", "#f54962"],
              }}
            />
          </Col>
        </Row>

        <Row className="mt-2">
          <Col xs={12} lg={4} className="analytics-card mt-2 p-0 pr-lg-2">
            <ItemRanking
              className="card h-100"
              header="Popular Items"
              params={{ ...params, limit: 4 }}
            >
              <h4 className="pt-8 px-8">Popular Items</h4>
            </ItemRanking>
          </Col>
          <Col xs={12} lg={4} className="analytics-card mt-2 p-0 pl-lg-2">
            <ItemRanking
              className="h-100"
              header="Least Popular Items"
              params={{ ...params, limit: 4, sort: 1 }}
            >
              <h4 className="pt-8 px-8">Least Popular Items</h4>
            </ItemRanking>
          </Col>
          {/* <Col xs={12} lg={4} className="px-0 px-lg-4 overflow-hidden rounded"> */}
          <Col xs={12} lg={4} className="analytics-card mt-2 p-0 px-lg-4">
            <CustomerMix params={params} data_url="/dashboard/customer-mix" />
          </Col>
        </Row>

        <Row className="mt-2">
          <Col
            xs={12}
            lg={4}
            className="d-flex flex-column align-items-stretch analytics-card mt-2 p-0 pr-lg-2"
          >
            <CardList
              params={{ ...params, isTotalVisible: true }}
              className="card card-custom"
              header={"Sales by Payment Type"}
              mapFunction={(item) => (
                <li
                  className="d-flex justify-content-between align-items-center py-1 px-2"
                  key={item._id}
                >
                  <img
                    style={cardListItemImgStyling}
                    className="img-fluid mx-auto"
                    src={`/media/logos/payment/${item._id}.png`}
                    alt={item._id}
                  />
                  <span>{toPesoAmount(item.sales)}</span>
                </li>
              )}
            />
          </Col>
          <Col xs={12} lg={4} className="analytics-card p-0 pl-lg-2 mt-2">
            <CardList
              params={params}
              className="card card-custom"
              header={"Payment Type by Usage"}
              mapFunction={(item, idx, items) => (
                <li
                  className="d-flex justify-content-between align-items-center py-1 px-2"
                  key={item._id}
                >
                  <img
                    style={cardListItemImgStyling}
                    className="img-fluid mx-auto"
                    src={`/media/logos/payment/${item._id}.png`}
                    alt={item._id}
                  />
                  <span>
                    {(
                      (item.instance /
                        items.reduce((a, b) => a + b.instance, 0)) *
                      100
                    ).toFixed(2)}
                    %
                  </span>
                </li>
              )}
            />
          </Col>
          <Col
            xs={12}
            lg={4}
            className="px-0 px-lg-4 overflow-hidden rounded mt-2"
          >
            <CardListByReferrer
              params={{ ...params, isTotalVisible: true }}
              className="card card-custom"
              header={"Sales by Source"}
              mapFunction={(item) => (
                <li
                  className="d-flex justify-content-between align-items-center py-1 px-2"
                  key={item._id}
                >
                  <span>{item._id}</span>
                  <span>{toPesoAmount(item.sales)}</span>
                </li>
              )}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AnalyticsGrid;
