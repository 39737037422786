import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  Separator,
  Section,
  DFlex,
  DisplayTime,
  ThirdPartyName,
  DisplayPhone,
  SecondaryHelperAlert,
  OrderTotalDetails,
} from "../components/Base";
import {
  GetSubmitClassList,
  toPesoAmount,
  computeTotalCartAmount,
} from "../helpers";
import ProSourceForm from "../components/ProSourceForm";
import PerfectScrollbar from "react-perfect-scrollbar";

import "react-perfect-scrollbar/dist/css/styles.css";
import { PRE_ORDER_TO_ORDER_QUEUE_TIMER_DEFAULT } from "../constants";

function generateOptions(pre_order_to_order_queue_timer, is_asap) {
  let options = is_asap ? [] : [0];

  if (pre_order_to_order_queue_timer >= 10) {
    options = is_asap
      ? options.concat([
          pre_order_to_order_queue_timer,
          pre_order_to_order_queue_timer + 10,
          pre_order_to_order_queue_timer + 20,
          pre_order_to_order_queue_timer + 30,
        ])
      : options.concat([
          pre_order_to_order_queue_timer + 10,
          pre_order_to_order_queue_timer + 20,
          pre_order_to_order_queue_timer + 30,
          pre_order_to_order_queue_timer + 40,
        ]);
  } else {
    options = is_asap
      ? options.concat([
          pre_order_to_order_queue_timer,
          pre_order_to_order_queue_timer + 5,
          pre_order_to_order_queue_timer + 10,
          pre_order_to_order_queue_timer + 15,
        ])
      : options.concat([
          pre_order_to_order_queue_timer + 5,
          pre_order_to_order_queue_timer + 10,
          pre_order_to_order_queue_timer + 15,
          pre_order_to_order_queue_timer + 20,
        ]);
  }

  return options;
}

export default class AcceptOrderModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { order = {}, store = {} } = this.props,
      { is_asap = false, order_type } = order,
      {
        pre_order_to_order_queue_timer = PRE_ORDER_TO_ORDER_QUEUE_TIMER_DEFAULT,
      } = store;

    let options = generateOptions(pre_order_to_order_queue_timer, is_asap);

    this.state = {
      values: {
        minutes: options.length > 0 ? options[0] : "",
        is_asap,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      options,
    };
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const { show, onHide, order = {}, display_buttons } = this.props,
      { values = {}, isSubmitting = false, options = [] } = this.state;

    const {
      transaction_number = "",
      is_asap = false,
      guest_customer = {},
      order_time = "",
      order_details = [],
      order_queue = false,
      order_type,
      pickup_details,
      notes = "",
    } = order;
    const {
      first_name = "",
      last_name = "",
      contact_number = "",
    } = guest_customer;
    const is_delivery = order_type === "delivery";
    const inputActions = {
      onChange: this.handleOnChange,
      onBlur: this.handleOnBlur,
    };
    // console.log("accept order modal order: ", order);
    // console.log("accept order modal order.delivery: ", order.delivery)

    return (
      <Modal show={show} onHide={onHide} centered>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            <PerfectScrollbar>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHide}
              >
                <i aria-hidden="true" className="ki ki-close"></i>
              </button>
              <div className="d-flex flex-row justify-content-space-between mv-1rem text-uppercase font-weight-bolder font-order-section-header">
                <div>#{transaction_number}</div>
                <div>order details</div>
                {/* <div>{moment(order_time, DB_HOURS_FORMAT).format(TIME_OPTION_DISPLAY_FORMAT)}</div> */}
                <div>
                  <DisplayTime order_time={order_time} />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-space-between mv-1rem text-uppercase font-weight-bolder font-order-section-header">
                <div>
                  <div className="text-danger">
                    {first_name} {last_name}
                  </div>
                  <ThirdPartyName
                    orderType={order_type}
                    pickupDetails={pickup_details}
                  />
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td>phone:</td>
                      <td>
                        <DisplayPhone
                          pickupDetails={pickup_details}
                          mainPhone={contact_number}
                        />
                      </td>
                    </tr>
                    {order_type === "third_party_pickup" ? (
                      <tr className="font-pickup-sm-2 font-weight-bolder">
                        <td className="text-label text-right">c/o: </td>
                        <td>
                          <DisplayPhone
                            orderType={order_type}
                            pickupDetails={pickup_details}
                            mainPhone={contact_number}
                          />
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
              <Separator solid />
              <Section
                header={
                  <>
                    <div className="font-weight-bolder">Ordered Items</div>
                  </>
                }
              />
              <div className="order-details modal-scrollable">
                {order_details.map(
                  ({ item = {}, qty = 0, price = 0, extras = [] }, index) => {
                    return (
                      <div
                        className="item d-flex align-items-center mv-1rem inactive-link"
                        key={index}
                      >
                        <div className="item-photo-wrapper symbol symbol-40 symbol-2by3 flex-shrink-0 mr-4">
                          <div
                            className="symbol-label"
                            style={{
                              backgroundImage: `url(${item.image_url})`,
                            }}
                          ></div>
                        </div>
                        <div className="item-text-wrapper d-flex flex-column flex-grow-1">
                          <div className="font-weight-bolder">
                            {qty} x {item.name}
                          </div>
                          {extras.map(({ extra_id = {} }, index) => {
                            return <div key={index}>+ {extra_id.name}</div>;
                          })}
                        </div>
                        <div className="item-actions-wrapper d-flex align-items-center">
                          <span className="font-weight-bolder">
                            {" "}
                            {toPesoAmount(price * qty)}{" "}
                          </span>
                        </div>
                      </div>
                    );
                  },
                )}
                {notes ? (
                  <div className="item d-flex flex-wrap align-items-center my-5 inactive-link font-pickup-sm-2">
                    <div className="item-text-wrapper d-flex flex-column flex-grow-1">
                      <div className="font-weight-bolder">NOTES:</div>
                      <div className="item-actions-wrapper d-flex align-items-center break-lines">
                        {notes}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              <Separator solid />
              <OrderTotalDetails order={order} />

              {order_queue && display_buttons ? (
                <>
                  {
                    <>
                      {is_asap ? (
                        <>
                          <p>
                            Please select the total minutes to prepare this
                            order.
                          </p>
                        </>
                      ) : (
                        <p>
                          Please select adjustment time to prepare this order.
                        </p>
                      )}

                      <Form.Group>
                        <Form.Control
                          as="select"
                          name="minutes"
                          value={values["minutes"]}
                          {...inputActions}
                        >
                          {options.map((o, i) => {
                            return o === 0 ? (
                              <option value={o} key={i}>
                                No adjustments
                              </option>
                            ) : (
                              <option value={o} key={i}>
                                {o} minutes
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                      {is_delivery && is_asap && (
                        <SecondaryHelperAlert
                          message={
                            <>
                              Rider scheduled arrival will be determined based
                              on the selected preparation time.
                            </>
                          }
                        />
                      )}
                    </>
                  }
                </>
              ) : (
                <></>
              )}
            </PerfectScrollbar>
          </Modal.Body>
          {order_queue && display_buttons ? (
            <>
              <Modal.Footer>
                <Button
                  type="submit"
                  variant="purple"
                  className={GetSubmitClassList(
                    isSubmitting,
                    "btn-block text-uppercase font-weight-bold",
                  )}
                  disabled={isSubmitting ? "disabled" : null}
                >
                  Accept
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <></>
          )}
        </Form>
      </Modal>
    );
  }
}
