import React from "react";
import { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

import APIComponent from "../../components/API";
import { FormItem } from "../../components/Base";
import { fetchData } from "../../helpers";
import core from "../../vendors/core-api";

const ALLOWED_ROLES = [
  "group_admin",
  "brand_admin",
  // "store_admin",
  // "console_user",
  // "console_user_dispatch",
  // "console_user_counter",
];

const ALLOWED_SCOPES = [
  // "group",
  "brand",
];

export default function DisplayPromoBrandsDropdown(props) {
  // if (props.iam_role === "brand_admin") {
  // if (ALLOWED_ROLES.includes(props.scope)) {
  if (props.mode === "view" || props.mode === "copy") {
    if (props.brands.length) {
      return <DisplayPromoBrandsDropdownAPI {...props} />;
    } else {
      if (ALLOWED_SCOPES.includes(props.scope)) {
        return <DisplayPromoBrandsDropdownAPI {...props} />;
      } else {
        return <></>;
      }
    }
  } else if (props.mode === "new") {
    if (ALLOWED_SCOPES.includes(props.scope)) {
      return <DisplayPromoBrandsDropdownAPI {...props} />;
    } else {
      return <></>;
    }
  }
  // if (!props.mode && props.iam_role === "brand_admin") {
  //   return <DisplayPromoBrandsDropdownAPI {...props} />;
  // } else {
  //   return <></>;
  // }
}

class DisplayPromoBrandsDropdownAPI extends APIComponent {
  constructor(props) {
    super(props);

    this.api = core("pu").get();
    this.data_url = "/settings/get-brand-options";
  }

  async getData() {
    if (this.data_url !== "") {
      // const { user = {}, brands = [] } = this.props,
      //   { iam_role = "" } = user,
      //   showPreselected = iam_role === "brand_admin" || "group_admin";

      const { user = {}, brands = [], iam_role = "" } = this.props,
        // { client: iam_role = "" } = user,
        // showPreselected = iam_role === "brand_admin" || "group_admin";
        showPreselected = ALLOWED_ROLES.includes(iam_role);

      if (this.props.iam_role.includes("console_user")) return;

      this._isMounted && this.setState({ data_status: "fetching" });
      this.api &&
        this.api
          .get(this.data_url)
          .then(({ data }) => {
            const { data: responseData } = data;
            // console.log("responseData: ", responseData);

            this._isMounted &&
              this.setState({
                data: responseData,
                data_status: "fetched",
                preselectedBrands: showPreselected
                  ? responseData.filter(({ _id = "" }) => {
                      return brands.includes(_id);
                    })
                  : [],
              });
            this.handleOnDataFetched();

            if (this.use_display_data) {
              this.setDisplayData();
            }
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });
    } else {
      this.afterComponentDidMount();
    }
  }

  // async componentDidMount() {
  //   this._isMounted = true;
  //   await this.getData();

  //   const brandNames = this.state.data.map((item) => {
  //     item._id.includes(this.props.brands.map((item) => item));
  //     return item.name;
  //   });
  //   console.log(
  //     "displaybrandsdropdown componentdidmount brandNames: ",
  //     brandNames
  //   );
  // }

  componentDidUpdate() {
    // console.log("displaybrandsdropdown componentDidUpdate state: ", this.state);
    // console.log("displaybrandsdropdown componentDidUpdate props: ", this.props);
  }

  render() {
    const { data_status = "", data = [], preselectedBrands = [] } = this.state,
      isLoading = data_status !== "fetched";
    const { brands } = this.props;
    return (
      <>
        {isLoading ? (
          <></>
        ) : (
          <>
            <FormItem
              customFormControl
              label={"Brands:"}
              className={"form-control-solid"}
              actions={this.props.actions}
              showRequired={true}
              name="brands"
              multiple={true}
              custom={
                <>
                  <Choices
                    data={data}
                    preselectedBrands={preselectedBrands}
                    {...this.props}
                  />
                </>
              }
            />
          </>
        )}
      </>
    );
  }
}

const Choices = ({ data, action, preselectedBrands, onBlur, disabled }) => {
  const [multiSelections, setMultiSelections] = useState(preselectedBrands);

  return (
    <Typeahead
      id={"brands-selector"}
      placeholder={"Brands"}
      disabled={disabled}
      options={data}
      onChange={(selected) => {
        setMultiSelections(selected);
        action(selected);
      }}
      selected={multiSelections}
      multiple={true}
      // multiple
      labelKey="name"
      onBlur={() => {
        onBlur(multiSelections);
      }}
    />
  );
};
