import * as firebase from "firebase/app";
import "firebase/messaging";
// let {
//   REACT_APP_apiKey,
//   REACT_APP_authDomain,
//   REACT_APP_projectId,
//   REACT_APP_storageBucket,
//   REACT_APP_messagingSenderId,
//   REACT_APP_appId,
//   REACT_APP_measurementId= "",
//   REACT_APP_usePublicVapidKey

// } = process.env;

const config = {
  apiKey: "AIzaSyCkOax2iMgO4Y--WkQodwRkyk56d7Lg90w",
  authDomain: "pickupphbeta.firebaseapp.com",
  projectId: "pickupphbeta",
  storageBucket: "pickupphbeta.appspot.com",
  messagingSenderId: "682238353409",
  appId: "1:682238353409:web:187530ca5b664f1ae46000",
  measurementId: "G-TKGBMPDKWL",
};
// const config ={
//   apiKey: REACT_APP_apiKey,
//   authDomain: REACT_APP_authDomain,
//   projectId: REACT_APP_projectId,
//   storageBucket: REACT_APP_storageBucket,
//   messagingSenderId:REACT_APP_messagingSenderId,
//   appId: REACT_APP_appId,
//   measurementId: REACT_APP_measurementId
// }
const isMessagingSupported = () => {
  return firebase.messaging.isSupported();
};

let messaging = {};
const firebaseApp = firebase.initializeApp(config);
if (isMessagingSupported()) {
  messaging = firebaseApp.messaging();

  messaging.usePublicVapidKey(
    // Project Settings => Cloud Messaging => Web Push certificates
    // "BEJxSMqs6Bwyw3r_HZiTLYORFr3xNXqdVnjdz4WaDSq3L_rva4hDjvgQ3zwflaXMQmWV_RMTtRnm1Dcgr1YDpQY"
    "BDEjVWCkoJhvgpEb0F1LmEqXf1lBQRlbhKLz5mQLDQ2ZquEeEaTdThnlLyY12Wy2EWu4YQbNmGrI514YdyPwLW0",
  );
}
const removeToken = () => {
  if (isMessagingSupported()) {
    messaging
      .deleteToken()
      .then((resp) => {
        return;
      })
      .catch((err) => {
        console.error(err);
        return;
      });
  }
};

export { messaging, isMessagingSupported, removeToken };
