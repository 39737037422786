import React from "react";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import AnalyticsGrid from "../../components/dashboard/AnalyticsGrid";
import { DB_DATE_FORMAT, DATERANGE_LIST } from "../../constants";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";

export default function Page() {
  const { user } = useSelector((state) => state.auth);
  return <MainComponent id={user.stores[0]} user={user} />;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Store Analytics",
      date: moment().format(DB_DATE_FORMAT),
      selectedBrand: { id: props.id, attr: "store" },
    };

    this.api = core("pu").get();
    // this.data_url = `/brand/${props.user.brand._id}/analytics`;
    this.data_url = `/store/${props.id}/analytics`;

    this.handleDateRangeClick.bind(this);
  }

  handleDateRangeClick = async (e, date) => {
    let statCardDataRange = "";
    const dropdownText = e.currentTarget.textContent || "day";

    if (
      dropdownText === "This Week" ||
      dropdownText === "Last Week" ||
      dropdownText === "Next Week"
    ) {
      statCardDataRange = "week";
    } else if (dropdownText === "This Month" || dropdownText === "Last Month") {
      statCardDataRange = "month";
    } else if (dropdownText === "This Year" || dropdownText === "Last Year") {
      statCardDataRange = "year";
    } else if (dropdownText === "All-time") {
      statCardDataRange = "allTime";
    } else {
      statCardDataRange = "day";
    }

    this.setState({
      statCardQuery: statCardDataRange,
      date,
      data_status: "fetching",
    });
    setTimeout(() => {
      this.getData();
    }, 500);
  };

  handleChangeSelectedStore = async (item) => {
    this.setState({
      selectedBrand: item,
      data_status: "fetching",
    });
    setTimeout(() => {
      this.getData();
    }, 500);
  };

  async getData() {
    this._isMounted && this.setState({ data_status: "fetching" });
    let date = this.state.date || moment().format(DB_DATE_FORMAT);
    let statCardRange = this.state.statCardQuery || "day";
    // const storeId = this.props.user.brand._id;

    const getContent = () => {
      return new Promise((resolve, reject) => {
        this.api
          .get(
            `/store/${this.props.id}/analytics?dateRange=${statCardRange}&date=${date}`,
          )
          .then((data) => resolve(data.data))
          .catch((err) => reject(err));
      });
    };

    try {
      const content = await getContent();

      this._isMounted &&
        this.setState({
          data: {
            ...content,
          },
          data_status: "fetched",
          order_fetch_status: "fetched",
        });

      this.handleOnDataFetched();
      if (this.use_display_data) {
        this.setDisplayData();
      }
    } catch (error) {
      this._isMounted && this.setState({ error: error, showSwalError: true });
      this.handleOnDataFetchError();
    }
  }

  render() {
    // const brandId = this.props.user.brand._id;
    const storeId = this.props.id;

    const {
        data = {},
        data_status = "",
        selectedBrand = {},
        statCardQuery = "day",
        date = moment().format(DB_DATE_FORMAT),
      } = this.state,
      isLoading = data_status !== "fetched";

    const params = {
      date,
      brands: this.props.brands,
      role: this.props.role,
      dateRange: statCardQuery,
      id: selectedBrand.id || "",
      attr: selectedBrand.attr || "store",
    };

    function dropdownText(dateRange, date) {
      const isCurrent = moment(moment().format(DB_DATE_FORMAT)).isSame(
        date,
        dateRange,
      );

      const prefix = isCurrent ? "This" : "Last";

      // if(dateRange=== 'week') return `${prefix} Week`
      if (dateRange === "week") {
        if (
          moment(moment().add(1, "weeks").format(DB_DATE_FORMAT)).isSame(date)
        ) {
          return "Next Week";
        }

        return `${prefix} Week`;
      }

      if (dateRange === "month") return `${prefix} Month`;
      if (dateRange === "year") return `${prefix} Year`;
      if (dateRange === "allTime") return "All-time";
      return `${isCurrent ? "Today" : "Yesterday"}`;
    }

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        {/* <HeaderTextWithActions title={this.state.title} /> */}

        {/* <ComingSoonDiv/> */}

        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          // <div className="container-fluid d-flex flex-column justify-content-between">
          <div className="">
            <div className="d-flex justify-content-end w-100 mb-4">
              <Dropdown className="ml-4 align-self-end rounded-top">
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  {dropdownText(statCardQuery, date)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {DATERANGE_LIST.map((item) => (
                    <Dropdown.Item
                      onClick={(e) => this.handleDateRangeClick(e, item.date)}
                      key={item.name}
                    >
                      {item.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <AnalyticsGrid
              itemSoldURL={`/store/${storeId}/analytics/item-sold?dateRange=${statCardQuery}&date=${date}&id=${selectedBrand.id}`}
              selectedBrand={selectedBrand}
              data={data}
              statCardQuery={statCardQuery}
              date={date}
              params={params}
            />
          </div>
        )}
      </>
    );
  }
}
