import React from "react";
import { validatePhoneNumber } from "../helpers/phoneValidation";
import { PASSWORD_LENGTH } from "../constants";
/**
 * Form handler in React
 *
 * Basically mimicks Formik, but by using `extends` instead of being in a `render()` component.
 * This handles the form change out of the box, but to make this form useful, validation wise
 * you can overwrite `validateForm` in your form class or add validation rules in `this.validate`
 */

export default class ProSourceForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      values: {},
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {};

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);

    this.handleFormValidation = this.handleFormValidation.bind(this);
    this.handleFeedbackError = this.handleFeedbackError.bind(this);
    this.handleValidationClass = this.handleValidationClass.bind(this);

    this.setSubmitting = this.setSubmitting.bind(this);
    this.setStateKey = this.setStateKey.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.setAllFieldsToTouched = this.setAllFieldsToTouched.bind(this);

    this.resetForm = this.resetForm.bind(this);
    this.handleUnmount = this.handleUnmount.bind(this);

    this.validateForm = this.validateForm.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validatePhoneNumber = this.validatePhoneNumber.bind(this);

    this.getError = this.getError.bind(this);
    this.getFields = this.getFields.bind(this);
    this.getStateKeyValue = this.getStateKeyValue.bind(this);

    this.isFormOk = this.isFormOk.bind(this);
    this.isTouched = this.isTouched.bind(this);

    this.addError = this.addError.bind(this);
    this.removeError = this.removeError.bind(this);

    this.addErrors = this.addErrors.bind(this);
    this.removeErrors = this.removeErrors.bind(this);

    this.submitForm = this.submitForm.bind(this);
  }

  /**
   * handleOnSubmit - handles the submit function from the <Form>
   * @param {*} event
   */
  async handleOnSubmit(event) {
    event.preventDefault(); // prevents the normal submit event which is a post request

    await this.setAllFieldsToTouched();
    await this.validateForm();
    if (this.isFormOk()) {
      this.setSubmitting();
      this.onSubmit && this.onSubmit(this.state.values, this.setSubmitting);
    }
  }

  handleOnChange = (event) => {
    const target = event.target,
      value = target.value,
      name = target.name;
    let { values, errors } = this.state,
      state = {};
    values[name] = value;
    if (name === "contact_number") {
      const regEx = /[a-zA-Z]/g;
      const validRegx = regEx.test(String(value));

      const valid = validatePhoneNumber(value);

      if (!valid || validRegx) {
        errors.contact_number =
          "Please enter a valid contact number. (ex.09123456789)";
      } else {
        delete errors.contact_number;
      }
      state.errors = errors;
    }

    if (name === "password") {
      if (value.trim().length < PASSWORD_LENGTH) {
        errors.password = "Password must be at least 6 characters long";
      } else {
        delete errors.password;
      }
      state.errors = errors;
    }

    this.setState({
      values: values,
      state: state,
    });

    this.setStateKey("touched", name, true);
    this.validateForm();

    // checks if afterOnChange function exists
    this.onChange && this.onChange(event);
  };

  handleOnBlur = (event) => {
    const target = event.target,
      name = target.name;
    this.setStateKey("touched", name, true);
    this.validateForm();

    // checks if afterOnBlur function exists
    this.onBlur && this.onBlur(event);
  };

  handleUnmount = (name) => {
    this.setStateKey("touched", name, false);
  };

  isFormOk = () => {
    const errors = this.handleFormValidation();

    return Object.keys(errors).length === 0;
  };

  isTouched = (name) => {
    return this.getStateKeyValue("touched", name);
  };

  handleFormValidation = () => {
    let { errors = {}, values = {} } = this.state;

    const { required = [] } = this.validate;

    for (var r in required) {
      const name = required[r],
        value = values[name];
      if ((value === "" || +value === 0) && this.isTouched(name)) {
        errors[name] = "This field is required";
      } else if (errors[name] === "This field is required") {
        delete errors[name];
      }
    }

    return errors;
  };

  validateForm = async () => {
    const errors = this.handleFormValidation();
    this.setState({ errors: errors });
    return errors;
  };

  setSubmitting = (state = true) => {
    this.setState({ isSubmitting: state });
  };

  setStateKey = (statekey, key, value) => {
    let k = this.state[statekey];
    k[key] = value;

    let params = {};
    params[statekey] = k;
    this.setState(params);
  };

  setFieldValue = (field, value) => {
    this.setStateKey("values", field, value);
  };

  setAllFieldsToTouched = async () => {
    const keys = this.getFields();
    let params = {};
    for (var key in keys) params[keys[key]] = true;
    this.setState({ touched: params });
  };

  getStateKeyValue = (statekey, key) => {
    return this.state[statekey] && this.state[statekey][key];
  };

  getError = (name) => {
    const { errors } = this.state;
    return errors && errors[name] ? errors[name] : "";
  };

  getFields = () => {
    return Object.keys(this.state.values);
  };

  resetForm = () => {
    var fields = this.getFields();
    let form = {};

    for (var i = 0; i < fields.length; i++) form[fields[i]] = "";

    this.setState({ values: form });
  };

  handleFeedbackError = (field_name = "", type = "helper-text") => {
    const { errors = {} } = this.state;
    if (type === "helper-text") {
      return errors.hasOwnProperty(field_name) ? (
        <div className="invalid-feedback">{errors[field_name]}</div>
      ) : (
        <></>
      );
    } else if (type === "input") {
      return errors.hasOwnProperty(field_name) ? "is-invalid" : "";
    } else if (type === "error-text") {
      return errors[field_name];
    }
  };

  handleValidationClass = (field_name = "") => {
    // return 'validated' className once field_name is in touched
    // usage: <FormGroup className={this.handleValidationClass(field_name)}/>
    const { touched = {} } = this.state;
    return touched.hasOwnProperty(field_name) ? "validated" : "";
  };

  validateEmail(email) {
    var re =
      /^(([^À-ž<>()[\]\\.,;:+-\s@!?#$%^&'*/={}`~|"]+([\.\+\-][^À-ž<>()[\]\\.,;:+-\s@!?#$%^&'*/={}`~|"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validatePhoneNumber(contact_number = "", append = true) {
    // append = true, will append "+63" on `contact_number`

    if (append) contact_number = "+63" + contact_number;

    var re = /^(09|\+639)\d{9}$/;
    return re.test(String(contact_number));
  }

  async submitForm() {
    // without event
    await this.setAllFieldsToTouched();

    if (this.isFormOk()) {
      this.setSubmitting();
      this.onSubmit && this.onSubmit(this.state.values, this.setSubmitting);
    }
  }

  addError(field = "", message = "") {
    let { errors = {} } = this.state;
    errors[field] = message;
    this.setState({ errors });
  }

  removeError(field = "") {
    let { errors = {} } = this.state;
    if (errors.hasOwnProperty(field)) {
      delete errors[field];
    }
    this.setState({ errors });
  }

  addErrors(errorsArr = []) {
    /** [{ field, message }] */
    let { errors = {} } = this.state;
    errorsArr.forEach(({ field, message }) => {
      errors[field] = message;
    });
    this.setState({ errors });
  }

  removeErrors(fields = []) {
    let { errors = {} } = this.state;
    for (var i = 0; i < fields.length; i++) {
      if (errors.hasOwnProperty(fields[i])) delete errors[fields[i]];
    }
    this.setState({ errors });
  }
}
