import React from "react";

// import APIComponent from "../../components/API";
// import core from "../../vendors/core-api";
import { useParams, Redirect, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { ContentRoute } from "../../../_metronic/layout";
// import TitleHelmet from "../../partials/TitleHelmet";
// import { BrowserView } from "react-device-detect";
// import { Col, Nav, Row, Tab, Card } from "react-bootstrap";
import UserSettingsDiv from "../../partials/UserSettingsDiv";
// import { HeaderText } from "../../components/Base";
// import { Card, Col, Nav, Row, Tab } from "react-bootstrap";

// const MENUS = {
//     main: '',
//     users: 'users'
// };

export default function Page({ history }) {
  const { user } = useSelector((state) => state.auth),
    { mode = "" } = useParams();
  return (
    <MainComponent user={user} history={history} webview={mode === "webview"} />
  );
}

function MainComponent(props) {
  return (
    <Switch>
      <ContentRoute path={`${props.webview ? "/webview" : ""}/settings/users`}>
        <UserSettingsDiv history={props.history} webview={props.webview} />
      </ContentRoute>
      <Redirect to={`${props.webview ? "/webview" : ""}/settings/users`} />
    </Switch>
  );
}

// class MainComponent extends APIComponent {
//     // constructor(props) {
//     //     super(props);

//         // let id = this.props.history.location.pathname.split('/').pop();

//         // this.state = {
//         //     showSplashScreen: false,
//         //     redirectTo: "",
//         //     title: "Settings",
//         //     activeMenuKey: "users",
//         //     id: (id !== "settings" && id !== "users") ? id : ""
//         // };

//         // this.api = core("pu").get();
//         // this.data_url = "";

//         // this.getHelperText = this.getHelperText.bind(this);

//         // this.setActiveMenu = this.setActiveMenu.bind(this);
//     // }

//     // afterComponentDidMount = () => {
//     //     this.setActiveMenu("users");
//     // }

//     // setActiveMenu = (activeMenuKey) => {
//     //     const { webview = false } = this.props;

//     //     this.setState({ activeMenuKey });
//     //     if(webview) {
//     //         this.props.history.push(`/webview/settings/${activeMenuKey !== MENUS.main ? activeMenuKey : ''}`)
//     //     }
//     //     else {
//     //         this.props.history.push(`/settings/${activeMenuKey !== MENUS.main ? activeMenuKey : ''}`)
//     //     }
//     // }

//     render () {
//         return <>
//             {/* <TitleHelmet title={this.state.title}/>

//             { this.state.showSplashScreen ? <LayoutSplashScreen/> : <></> }

//             { this.state.redirectTo !== "" ? <Redirect to={this.state.redirectTo}/> : <></>  } */}

//             {/* <HeaderText title={"Settings"} /> */}

//             {/* <Tab.Container id="left-tabs-example" defaultActiveKey={MENUS.users} activeKey={this.state.activeMenuKey}>
//                 <Row>
//                     <Col sm={3}>
//                         <Card className="card-custom">
//                             <Card.Body>
//                                 <Nav variant="pills" className="flex-column">
//                                     <Nav.Item>
//                                         <Nav.Link eventKey={MENUS.users}  onClick={() => this.setActiveMenu(MENUS.users)}>User Settings</Nav.Link>
//                                     </Nav.Item>
//                                 </Nav>
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                     <Col sm={9}>
//                         <Tab.Content className="general-bg-color">
//                             <Tab.Pane eventKey={MENUS.users}>
//                                 <UserSettingsDiv history={this.props.history}/>
//                             </Tab.Pane>
//                         </Tab.Content>
//                     </Col>
//                 </Row>
//             </Tab.Container> */}
//             <Switch>
//                 <ContentRoute path="/settings/users">
//                     <UserSettingsDiv history={this.props.history} webview={this.props.webview}/>
//                 </ContentRoute>
//             </Switch>
//         </>
//     }
// }
