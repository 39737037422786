import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Block, EditIcon, FormItem, Label } from "../components/Base";
import { GetSubmitClassList, generatePickupPhLink } from "../helpers";
import ProSourceForm from "../components/ProSourceForm";
import { BRAND_PRODUCT_TYPES } from "../constants";

export default class StoreSettingsDiv extends ProSourceForm {
  constructor(props) {
    super(props);
    const { data = {} } = props;
    const { product_type = "" } = data;
    this.state = {
      values: {
        product_type:
          product_type === "" ? BRAND_PRODUCT_TYPES[0] : product_type,
      },
      isSubmitting: false,
      isEdit: false,
      didValueChanged: !product_type,
    };
  }

  handleOnChange = (e) => {
    const target = e.target,
      value = target.value;
    const { data } = this.props;
    const { values = {} } = this.state;

    values.product_type = value;

    this.setState({
      values,
      didValueChanged: value !== data.product_type,
    });
  };

  onSubmit = (values, setSubmitting) => {
    const { didValueChanged } = this.state;
    if (didValueChanged) {
      this.props.onSubmit(values, setSubmitting);
    }
  };

  render() {
    const { data = {}, user = {} } = this.props;
    const { isEdit, isSubmitting, didValueChanged } = this.state;
    const inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      },
      actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      };

    return (
      <>
        <Row>
          <Col>
            <Block
              content={
                <>
                  <Label
                    text={
                      data.group_url !== undefined ? (
                        <>
                          <span className="va-middle text-capitalize">
                            group name
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="va-middle text-capitalize">
                            brand name
                          </span>
                        </>
                      )
                    }
                  />
                  <div>{data.name}</div>
                </>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Block
              content={
                <>
                  <Label
                    text={
                      data.group_url !== undefined ? (
                        <>
                          <span className="va-middle text-capitalize">
                            group url
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="va-middle text-capitalize">
                            brand url
                          </span>
                        </>
                      )
                    }
                  />
                  <div>
                    {data.group_url !== undefined
                      ? generatePickupPhLink(data.group_url, false)
                      : generatePickupPhLink(data.brand_url, false)}
                  </div>
                </>
              }
            />
          </Col>
        </Row>
        {!data.group_url && (
          <Row style={{ marginTop: -10 }}>
            <Col>
              <Block
                content={
                  <>
                    <Label
                      text={
                        <div style={{ marginBottom: -5 }}>
                          <span className="va-middle text-capitalize">
                            brand product type{" "}
                          </span>
                          {user && user.iam_role === "brand_admin" && (
                            <EditIcon
                              onClick={() =>
                                this.setState({ isEdit: !this.state.isEdit })
                              }
                            />
                          )}
                        </div>
                      }
                    />
                    {isEdit && (
                      <Form onSubmit={this.handleOnSubmit}>
                        <Row className="ml-0">
                          <FormItem
                            style={{ width: "20%" }}
                            noLabel
                            label={"Brand Product Type"}
                            name={"product_type"}
                            customFormControl
                            custom={
                              <>
                                <Form.Control
                                  as="select"
                                  name={"product_type"}
                                  custom="true"
                                  {...inputActions}
                                  className="text-capitalize"
                                  value={this.state.values.product_type}
                                >
                                  {BRAND_PRODUCT_TYPES.map((label) => {
                                    return (
                                      <option value={label}>{label}</option>
                                    );
                                  })}
                                </Form.Control>
                              </>
                            }
                            inputActions={inputActions}
                            actions={actions}
                            showRequired={false}
                            normal
                          />
                          <div>
                            <Button
                              type="submit"
                              variant="primary ml-4"
                              className={GetSubmitClassList(isSubmitting, "")}
                              disabled={!didValueChanged || isSubmitting}
                            >
                              Save Changes
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    )}
                    {!isEdit && (
                      <div className="va-middle text-capitalize">
                        {data.product_type || "-"}
                      </div>
                    )}
                  </>
                }
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
}
