import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { Redirect } from "react-router-dom";
import TitleHelmet from "../../partials/TitleHelmet";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import PageCenter from "../../components/PageCenter";
import { Card, Button, Modal, Form } from "react-bootstrap";
import { Block, Separator, ContactUs, FormItem } from "../../components/Base";
import { GetSubmitClassList } from "../../helpers";

import qs from "qs";
import ProSourceForm from "../../components/ProSourceForm";

export default function Page(props) {
  const { token = "" } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  return <MainComponent token={token} />;
}

function getTitle(data) {
  let title = "";

  if (data.action === "accept_client_update_details")
    title = "Approve Approval Request #" + data.approval.approval_number;
  else if (data.action === "reject_accept_client_update_details")
    title = "Reject Approval Request #" + data.approval.approval_number;
  return title;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      showSuccessScreen: false,
      redirectTo: "",
      title: "Action",
    };

    this.api = core("pu").get();

    this.handleOnClickActionBtn = this.handleOnClickActionBtn.bind(this);
    this.executeAction = this.executeAction.bind(this);
    this.onSubmitNotes = this.onSubmitNotes.bind(this);
  }

  async getData() {
    const { token = "" } = this.props;

    this._isMounted && this.setState({ data_status: "fetching" });
    this.api &&
      this.api
        .post({ url: "/external/approvals/validate-action", data: { token } })
        .then(({ data }) => {
          this._isMounted &&
            this.setState({
              title: getTitle(data),
              data,
              data_status: "fetched",
              token,
            });
          this.handleOnDataFetched();
        })
        .catch((error) => {
          this._isMounted &&
            this.setState({
              error: error,
              showSwalError: true,
              showSuccessScreen: false,
              showDataDiv: false,
            });
          this.handleOnDataFetchError();
        });
  }

  handleOnDataFetched = () => {
    let { data = {} } = this.state,
      { approval = {} } = data;

    data.approval.details = JSON.parse(approval.details);
    if (approval.status !== "new") {
      this.setState({ showOrderNotNew: true });
    }

    this.setState({ data });

    this.handleOnClickActionBtn();
  };

  handleOnDataFetchError = () => {
    /** overwrite default */
  };

  handleOnClickActionBtn = () => {
    const { data = {} } = this.state;

    if (data.action === "accept_client_update_details") {
      this.setState({ showAcceptModal: true });
    } else if (data.action === "reject_accept_client_update_details") {
      this.setState({ showRejectModal: true });
    }
  };

  executeAction = (values = {}, setSubmitting = () => {}) => {
    const { token = "" } = this.state,
      { notes = "" } = values;
    this.setState({ btnLoading: true });
    this.api
      .post({
        url: "/external/approvals/execute-action",
        data: { token, notes },
      })
      .then(({ data }) => {
        if (data.result === "ok") {
          setTimeout(() => {
            this.setState({
              showSwalError: false,
              showSuccessScreen: true,
              showDataDiv: false,
              showAcceptModal: false,
              showRejectModal: false,
            });
          }, 1000);
        }
      })
      .catch(() => {
        this.handleOnDataFetchError();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  onSubmitNotes = (values, setSubmitting) => {
    this.executeAction(values, setSubmitting);
  };

  render() {
    const {
        data_status = "",
        data = {},
        showSwalError = false,
        showRejectModal = false,
        showSuccessScreen = false,
        showDataDiv = true,
        btnLoading = false,
        showOrderNotNew = false,
        showAcceptModal = false,
      } = this.state,
      isLoading = data_status !== "fetched";

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}
        <PageCenter>
          {showSuccessScreen ? (
            <>
              <Card className="card-border-top-primary card-custom">
                <Card.Body>
                  <div className="display-4 font-weight-bolder gutter-b">
                    Success!
                  </div>
                  <div>Changes has been successfully saved.</div>
                </Card.Body>
              </Card>
            </>
          ) : (
            <></>
          )}
          {showSwalError ? (
            <>
              <Card className="card-border-top-primary card-custom">
                <Card.Body>
                  <div className="display-4 font-weight-bolder gutter-b">
                    Oooops!{" "}
                  </div>
                  <div>This seems to be an invalid action request.</div>
                  <div>
                    <ContactUs />
                  </div>
                </Card.Body>
              </Card>
            </>
          ) : (
            <></>
          )}

          {showDataDiv ? (
            isLoading ? (
              <Card className="card-border-top-primary card-custom">
                <Card.Body>
                  <div className="mb-10">
                    <p className="text-center">
                      We're currently validating your request...
                    </p>
                  </div>
                </Card.Body>
              </Card>
            ) : showOrderNotNew ? (
              <Card className="card-border-top-primary card-custom">
                <Card.Body>
                  <div className="display-4 font-weight-bolder gutter-b">
                    Oooops!{" "}
                  </div>
                  <div>
                    It seems like this approval request has been updated.
                  </div>
                  <div>
                    <ContactUs />
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <>
                <RejectModal
                  show={showRejectModal}
                  onHide={() => {
                    this.setState({ showRejectModal: false });
                  }}
                  onSubmit={this.executeAction}
                  btnLoading={btnLoading}
                  title={
                    <>
                      Reject Approval Request #{data.approval.approval_number}
                    </>
                  }
                />

                <AcceptModal
                  title={
                    <>
                      Accept Approval Request #{data.approval.approval_number}
                    </>
                  }
                  show={showAcceptModal}
                  onHide={() => {
                    this.setState({ showAcceptModal: false });
                  }}
                  onClickApprove={() => {
                    this.executeAction();
                  }}
                  btnLoading={btnLoading}
                />
                <div>
                  <Card className="card-border-top-primary card-custom">
                    <Card.Body>
                      <div className="display-4 font-weight-bolder mb-2">
                        Approval Request #{data.approval.approval_number}
                      </div>

                      <Separator solid border={"2"} />

                      <Block
                        title={<>request details</>}
                        content={
                          <>
                            <Block
                              content={
                                <>
                                  <p>
                                    Field: Company {data.approval.details.field}
                                  </p>
                                  <p>Old Value: {data.approval.details.old}</p>
                                  <p>New Value: {data.approval.details.new}</p>
                                </>
                              }
                            />
                          </>
                        }
                      />

                      <Button
                        variant={
                          data.action === "accept_client_update_details"
                            ? "primary"
                            : "danger"
                        }
                        className={GetSubmitClassList(
                          btnLoading,
                          "btn-lg btn-block text-uppercase",
                        )}
                        onClick={this.handleOnClickActionBtn}
                        disabled={btnLoading ? "disabled" : null}
                      >
                        {data.action === "accept_client_update_details"
                          ? "approve"
                          : "reject"}
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
              </>
            )
          ) : (
            <></>
          )}
        </PageCenter>
      </>
    );
  }
}

class AcceptModal extends React.Component {
  render() {
    const { show, onHide, title, btnLoading, onClickApprove } = this.props;

    return (
      <Modal show={show} onHide={onHide} centered size={"sm"}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to approve this request?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className={GetSubmitClassList(btnLoading, "")}
            onClick={onClickApprove}
          >
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class RejectModal extends ProSourceForm {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        notes: "",
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      file: null,
    };

    this.validate = { required: ["reason"] };
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };
  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      },
      { show, onHide, title, btnLoading } = this.props;

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            <FormItem
              label={"Reason"}
              name={"notes"}
              inputProps={{
                name: "notes",
                placeholder:
                  "Please provide a reason on why you are rejecting this approval..",
                value: this.state.values["notes"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"textarea"}
              showRequired={true}
              normal
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              type="submit"
              className={GetSubmitClassList(btnLoading, "")}
            >
              Reject
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
