import React, { useState } from "react";
import ProSourceForm from "../../components/ProSourceForm";
import {
  isObjectEmpty,
  generateDefaultStoreHours,
  GetDataFromEvent,
  GetSubmitClassList,
  generateOffDatesDisplay,
  generateStoreHoursDisplay,
  debounce,
} from "../../helpers";
import {
  FormItem,
  InfoModal,
  Label,
  RequiredAsterisk,
} from "../../components/Base";
import HelperText from "../../helpertexts";
import { Modal, Form, Col, Row, Button, Spinner } from "react-bootstrap";
import moment from "moment";
import { EditOffDatesTable } from "../StoreSetup";
import SetStoreHoursModal from "../SetStoreHoursModal";
import core from "../../vendors/core-api";
import { MapComponent } from "../../components/widgets/GMapsWidget";
import { validatePhoneNumber } from "../../helpers/phoneValidation";
import { isEmpty } from "lodash";
import { Typeahead } from "react-bootstrap-typeahead";
import { STORE_AND_SEARCH_TAGS } from "../../constants";

export default class StoreForm extends ProSourceForm {
  constructor(props) {
    super(props);

    const { brand = {}, store = {} } = this.props;

    const hasBrand = !isObjectEmpty(brand),
      hasStore = !isObjectEmpty(store);

    const store_name = hasStore ? store.name : brand.name,
      location = hasStore ? store.location : "",
      contact_number = hasStore ? store.contact_number : "",
      store_hours = hasStore ? store.store_hours : generateDefaultStoreHours(),
      off_dates = hasStore ? store.off_dates : [],
      store_url = hasStore ? store.store_url : "",
      brand_url = isEmpty(this.state.brand)
        ? this.props.user.brand_urls[0]
        : this.state.brand.brand_url,
      brand_id = isEmpty(this.state.brand)
        ? this.props.user.brands[0]
        : this.state.brand._id,
      delivery_address = hasStore ? store.geocode_address_string : "",
      search_tags = hasStore ? store.search_tags : [];

    this.state = {
      values: {
        name: store_name,
        location,
        contact_number,
        store_hours,
        off_dates,
        store_url,
        brand_url,
        brand_id,
        mapPosition: { lat: "", lng: "" },
        markerPosition: { lat: "", lng: "" },
        currentPosition: { lat: "", lng: "" },
        delivery_address,
        zoomValue: 18,
        search_tags,
      },
      showMapComponent: false,
      errors: {
        name: "",
        store_url: "",
        location: "",
        contact_number: "",
        store_hours: "",
        off_dates: "",
      },
      touched: {
        name: "",
        store_url: "",
        location: "",
        contact_number: "",
        store_hours: "",
        off_dates: "",
      },
      isSubmitting: false,
      showStoreHoursModal: false,
      showOffDatesModal: false,
      hasBrand,
      brand,
      storeUrl: { loading: false, status: "", message: "" },
      selectedBrandURL: "",
    };

    this.validate = {
      required: ["name", "location", "contact_number", "store_url"],
    };

    this.setToTrue = this.setToTrue.bind(this);
    this.setToFalse = this.setToFalse.bind(this);

    this.handleOnChangeStoreHrs = this.handleOnChangeStoreHrs.bind(this);
    this.handleOnSelectAddDate = this.handleOnSelectAddDate.bind(this);
    this.handleOnSelectDeleteDate = this.handleOnSelectDeleteDate.bind(this);
    this.hasRestrictedChar = this.hasRestrictedChar.bind(this);
    this.checkBrandURL = debounce(this.checkBrandURL.bind(this), 500);
    this.api = core("pu").get();
    this.blockInvalidChars = this.blockInvalidChars.bind(this);
  }

  validateForm = () => {
    let isValid = true;
    for (const key of [
      "name",
      "store_url",
      "location",
      "contact_number",
      "delivery_address",
    ]) {
      const value = this.state.values[key] ? this.state.values[key].trim() : "";
      if (!value) {
        this.setState((prevState) => {
          return {
            errors: { ...prevState.errors, [key]: "This field is required." },
          };
        });
        isValid = false;
      } else {
        this.setState((prevState) => {
          return {
            errors: { ...prevState.errors, [key]: "" },
          };
        });
      }
    }
    if (this.state.showMapComponent) {
      this.setState((prevState) => {
        return {
          errors: {
            ...prevState.errors,
            delivery_address: "Address Pin is required to be set",
          },
        };
      });
      isValid = false;
    }
    return isValid;
  };
  setToTrue = (key) => {
    let state = {};
    state[key] = true;
    this.setState(state);
  };

  setToFalse = (key) => {
    let state = {};
    state[key] = false;
    this.setState(state);
  };

  handleOnChangeStoreHrs = (e) => {
    const name = GetDataFromEvent(e, "data-name"),
      index = GetDataFromEvent(e, "data-index"),
      value = e.target.value,
      checked = e.target.checked;

    let { values = {} } = this.state;

    values.store_hours = values.store_hours.map((sh, i) => {
      if (i === parseInt(index)) {
        if (name === "isOpen") sh[name] = checked;
        else sh[name] = value;
      }

      return sh;
    });

    this.setState({ values });
  };

  handleOnSelectAddDate = (date, setShowAddForm) => {
    let { values = {} } = this.state;
    values.off_dates.push(moment(date).format("YYYY-MM-DD"));
    this.setState({ values });

    setShowAddForm(false);
  };

  handleOnSelectDeleteDate = (date) => {
    let { values = {} } = this.state;
    values.off_dates = values.off_dates.filter((off_date) => off_date !== date);
    this.setState({ values });
  };

  hasRestrictedChar = (value = "") => {
    const valSplit = value.split("");
    const restrictedCharsSplit = ["$", "."];
    let isInvalid = false;

    for (const char of restrictedCharsSplit) {
      if (valSplit.includes(char)) {
        isInvalid = true;
        break;
      }
    }

    return isInvalid;
  };

  handleOnChangeStoreName = (event) => {
    const target = event.target,
      value = target.value,
      name = target.name;
    let { values = {}, errors = {} } = this.state;

    values[name] = value;

    if (this.hasRestrictedChar(value)) {
      errors.name = '"$" and "." is not allowed';

      return this.setState({ errors });
    } else {
      delete errors["name"];
    }

    this.setState({
      values: values,
    });

    this.setStateKey("touched", name, "true");
    this.validateForm();

    // checks if afterOnChange function exists
    this.onChange && this.onChange(event);
  };

  onChange = (event) => {
    const target = event.target,
      checked = target.checked,
      name = target.name;
    let { values = {} } = this.state,
      state = {};

    if (name === "is_accepting_preorders") {
      values[name] = checked;
      state.values = values;
    }

    if (name === "store_url") {
      this.setState((prevState) => {
        return {
          storeUrl: {
            ...prevState.storeUrl,
            loading: Boolean(values[name]),
            status: "",
            message: "",
          },
        };
      });
      this.checkBrandURL();
    }
    this.setState(state);
  };

  onInputChange = (event) => {
    event.persist();
    this.setState(
      (prevState) => {
        return {
          values: {
            ...prevState.values,
            [event.target.name]: event.target.value,
          },
          touched: { ...prevState.touched, [event.target.name]: true },
        };
      },
      function () {
        if (
          event.target.name === "name" &&
          this.hasRestrictedChar(event.target.value)
        ) {
          return this.setState((prevState) => {
            return {
              errors: {
                ...prevState.errors,
                [event.target.name]: '"$" and "." is not allowed',
              },
            };
          });
        }
        if (!this.state.values[event.target.name]) {
          this.setState((prevState) => {
            return {
              errors: {
                ...prevState.errors,
                [event.target.name]: "This field is required.",
              },
            };
          });
        } else {
          this.setState((prevState) => {
            return {
              errors: { ...prevState.errors, [event.target.name]: "" },
            };
          });

          if (event.target.name === "contact_number") {
            const inputValue = event.target.value;
            const regEx = /[a-zA-Z]/g;
            const validRegx = regEx.test(String(inputValue));
            const valid = validatePhoneNumber(inputValue);

            if (!valid || validRegx) {
              this.setState((prevState) => {
                return {
                  errors: {
                    ...prevState.errors,
                    contact_number:
                      "Please enter a valid contact number. (ex.09123456789)",
                  },
                };
              });
            } else {
              this.setState((prevState) => {
                return {
                  errors: { ...prevState.errors, [event.target.name]: "" },
                };
              });
            }
          }
        }
      },
    );

    if (event.target.name === "store_url" && event.target.value.trim()) {
      this.setState((prevState) => {
        return {
          storeUrl: {
            ...prevState.storeUrl,
            loading: Boolean(event.target.value),
            status: "",
            message: "",
          },
        };
      });
      this.checkBrandURL();
    }
  };

  checkBrandURL = async () => {
    try {
      if (this.state.values.store_url) {
        if (/\s/.test(this.state.values.store_url)) {
          this.setState((prevState) => {
            return {
              errors: {
                ...prevState.errors,
                store_url: "Spaces are not allowed.",
              },
            };
          });
          this.setState((prevState) => {
            return {
              storeUrl: { ...prevState.storeUrl, loading: false },
            };
          });
          // throw new Error("Spaces are not allowed.");
        } else {
          const { values = {} } = this.state;
          const result = await this.api.post({
            url: "/brand/" + values.brand_id + "/store-url-check",
            data: { store_url: this.state.values.store_url.trim() },
          });

          if (result.status === 200) {
            this.setState((prevState) => {
              delete prevState.errors.store_url;
              return {
                storeUrl: {
                  ...prevState.storeUrl,
                  status: "OK",
                  message: "URL is available.",
                },
              };
            });
          }
          this.setState((prevState) => {
            return {
              storeUrl: { ...prevState.storeUrl, loading: false },
            };
          });
        }
      }
    } catch (error) {
      if (error.response.data.code === "DUPLICATE_BRAND_URL") {
        this.setState((prevState) => {
          return {
            storeUrl: {
              ...prevState.storeUrl,
              loading: false,
              message: "",
              status: "",
            },
          };
        });
        this.setState((prevState) => {
          return {
            errors: { ...prevState.errors, store_url: "URL is already taken." },
          };
        });
      } else if (error.response.data.code === "INVALID_URL") {
        this.setState((prevState) => {
          return {
            storeUrl: {
              ...prevState.storeUrl,
              loading: false,
              message: "",
              status: "",
            },
          };
        });
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              store_url:
                "Only lowercase letters, numbers, dash (-) and underscores (_) symbols are allowed.",
            },
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            errors: { ...prevState.errors, store_url: error.message },
            storeUrl: { ...prevState.storeUrl, loading: false },
          };
        });
      }
    }
  };

  blockInvalidChars = (e) => {
    const regex = /^[~@$^*()_+=[\]{}|\\/`'",.?!:; <>%&]*$/;
    if (regex.test(e.key)) {
      e.preventDefault();
    }
  };

  onSubmit = (values, setSubmitting) => {
    // console.log(values);
    this.validateForm();
    this.props.onSubmit(values, setSubmitting);
  };

  async handleOnSubmit(event) {
    event.preventDefault(); // prevents the normal submit event which is a post request
    this.setAllFieldsToTouched();
    const isValid = this.validateForm();
    if (isValid) {
      this.setSubmitting();
      this.onSubmit && this.onSubmit(this.state.values, this.setSubmitting);
    }
  }

  render() {
    const actions = {
      handleFeedbackError: this.handleFeedbackError,
      isTouched: this.isTouched,
    };
    // inputActions = {
    //   onChange: this.handleOnChange,
    //   onBlur: this.handleOnBlur,
    // };

    const { saveButtonText = <span>Save Changes</span> } = this.props;
    const { values } = this.state;

    return (
      <>
        {/* <Modal show={this.state.showStoreHoursModal} onHide={() => { this.setState({ showStoreHoursModal: false }) }} centered size="lg">
                    <Modal.Header>
                        <Modal.Title>Set Store Hours</Modal.Title>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ showStoreHoursModal: false }) }}>
                            <i aria-hidden="true" className="ki ki-close"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <EditStoreHoursTable 
                            store_hours={this.state.values["store_hours"]}
                            actions={{
                                onChange: this.handleOnChangeStoreHrs
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => { this.setToFalse("showStoreHoursModal"); }}>Save Changes</Button>
                    </Modal.Footer>
                </Modal> */}

        <SetStoreHoursModal
          show={this.state.showStoreHoursModal}
          onHide={() => {
            this.setState({ showStoreHoursModal: false });
          }}
          data={this.state.values}
          onSubmit={(formValues, setSubmitting) => {
            setSubmitting(false);
            let { values = {} } = this.state;
            values.store_hours = formValues["store_hours"];
            this.setState({ values, showStoreHoursModal: false });
          }}
        />

        <Modal
          show={this.state.showOffDatesModal}
          onHide={() => {
            /** do nothing */
          }}
          centered
        >
          <Modal.Header>
            <Modal.Title>Set Off Dates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditOffDatesTable
              off_dates={this.state.values["off_dates"]}
              actions={{
                onSelectAddDate: this.handleOnSelectAddDate,
                onSelectDeleteDate: this.handleOnSelectDeleteDate,
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                this.setToFalse("showOffDatesModal");
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Form
          className="fv-plugins-bootstrap fv-plugins-framework"
          onSubmit={this.handleOnSubmit}
        >
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Label text="Store Name" /> <RequiredAsterisk />
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Store Name"
                  value={this.state.values.name}
                  className={
                    this.state.errors.name
                      ? "is-invalid"
                      : this.state.touched.name
                      ? "is-valid"
                      : ""
                  }
                  onChange={this.onInputChange}
                />
                <div className="fv-plugins-message-container">
                  {this.state.errors.name && (
                    <div className="fv-help-block">
                      {this.state.errors.name}
                    </div>
                  )}
                </div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Label text="Store URL" /> <RequiredAsterisk />
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      {this.state.values.brand_url}
                      {process.env.REACT_APP_DOMAIN}/
                    </span>
                  </div>
                  <Form.Control
                    type="text"
                    name="store_url"
                    placeholder="Store URL"
                    value={this.state.values.store_url}
                    className={`${
                      this.state.errors.store_url ? "is-invalid" : ""
                    } ${this.state.storeUrl.status === "OK" ? "is-valid" : ""}`}
                    onChange={this.onInputChange}
                    disabled={this.state.values.brand === "" ? "disabled" : ""}
                  />
                  {this.state.storeUrl.loading && (
                    <div className="mt-2 ml-3">
                      <Spinner animation="border" size="sm" />
                    </div>
                  )}
                </div>
                {this.state.errors.store_url && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {this.state.errors.store_url}
                    </div>
                  </div>
                )}
                {this.state.storeUrl.status === "OK" && (
                  <div className="fv-plugins-message-container">
                    <div className="text-success">
                      {this.state.storeUrl.message}
                    </div>
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Label text="Location" /> <RequiredAsterisk />{" "}
                <Form.Control
                  type="text"
                  name="location"
                  placeholder="Location"
                  value={this.state.values.location}
                  className={
                    this.state.errors.location
                      ? "is-invalid"
                      : this.state.touched.location
                      ? "is-valid"
                      : ""
                  }
                  onChange={this.onInputChange}
                />
                <div className="fv-plugins-message-container ml-1">
                  {this.state.errors.location && (
                    <div className="fv-help-block">
                      {this.state.errors.location}
                    </div>
                  )}
                </div>
                <span>{HelperText["getting-started"].store_location}</span>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Label text="Contact Number" /> <RequiredAsterisk />
                <Form.Control
                  type="text"
                  name="contact_number"
                  placeholder="Contact Number"
                  value={this.state.values.contact_number}
                  className={
                    this.state.errors.contact_number
                      ? "is-invalid"
                      : this.state.touched.contact_number
                      ? "is-valid"
                      : ""
                  }
                  onChange={this.onInputChange}
                />
                <div className="fv-plugins-message-container ml-1">
                  {this.state.errors.contact_number && (
                    <div className="fv-help-block">
                      {this.state.errors.contact_number}
                    </div>
                  )}
                </div>
                <span>
                  {HelperText["getting-started"].store_contact_number}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12}>
              <Form.Group>
                <Label
                  text={
                    <>
                      <span className="mr-2">
                        Address Pin <RequiredAsterisk />
                      </span>
                      <InfoModal
                        title="Address Pin"
                        body={HelperText["getting-started"].address_pin}
                      />
                    </>
                  }
                />
                <div className="fv-plugins-message-container ml-1">
                  {this.state.errors.delivery_address && (
                    <div className="fv-help-block">
                      {this.state.errors.delivery_address}
                    </div>
                  )}
                </div>
                {this.state.showMapComponent ? (
                  <>
                    <MapComponent
                      state={this.state}
                      height="400px"
                      zoom={this.state.values.zoomValue}
                      api={this.api}
                      tooltipDisplay={false}
                    />
                    <div className="d-flex justify-content-end">
                      <Button
                        variant="danger"
                        onClick={() =>
                          this.setState({
                            values: {
                              ...this.state.values,
                              delivery_address: "",
                            },
                            showMapComponent: false,
                          })
                        }
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        className="ml-1"
                        onClick={() => {
                          this.setState({
                            showMapComponent: false,
                          });
                          this.setState((prevState) => {
                            return {
                              errors: {
                                ...prevState.errors,
                                delivery_address: "",
                              },
                            };
                          });
                        }}
                      >
                        Set Address Pin
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      {this.state.values.delivery_address === ""
                        ? "Store doesn't have an Address Pin."
                        : this.state.values.delivery_address}{" "}
                      <button
                        type="button"
                        className="btn btn-link px-0"
                        onClick={() =>
                          this.setState({ showMapComponent: true })
                        }
                      >
                        Edit
                      </button>
                    </div>
                  </>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <FormItem
                customFormControl
                label={
                  <>
                    <span className="mr-2">Store Hours</span>
                    <InfoModal
                      title="Store Hours"
                      body={
                        <>{this.props.actions.getHelperText("store_hours")}</>
                      }
                    />
                  </>
                }
                name={"store_hours"}
                custom={
                  <div>
                    <p className="mb-0">
                      {generateStoreHoursDisplay({
                        store_hours: this.state.values["store_hours"],
                      })}{" "}
                      <button
                        type="button"
                        className="btn btn-link px-0"
                        onClick={() => {
                          this.setToTrue("showStoreHoursModal");
                        }}
                      >
                        Edit
                      </button>
                    </p>
                  </div>
                }
                actions={actions}
              />
            </Col>
            <Col sm={6}>
              <FormItem
                customFormControl
                label={
                  <>
                    <span className="mr-2">Off Dates</span>
                    <InfoModal
                      title="Off Dates"
                      body={
                        <>
                          {this.props.actions.getHelperText("store_off_dates")}
                        </>
                      }
                    />
                  </>
                }
                name={"off_dates"}
                custom={
                  <div>
                    <p className="mb-0">
                      {generateOffDatesDisplay(this.state.values["off_dates"])}{" "}
                      <button
                        type="button"
                        className="btn btn-link px-0"
                        onClick={() => {
                          this.setToTrue("showOffDatesModal");
                        }}
                      >
                        Edit
                      </button>
                    </p>
                  </div>
                }
                actions={actions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem
                label={"Search Tags"}
                name={"search_tags"}
                customFormControl
                className={"form-control-solid"}
                actions={actions}
                showRequired={false}
                custom={
                  <>
                    <Choices
                      searchTagsList={STORE_AND_SEARCH_TAGS}
                      preselected={values.search_tags}
                      data={values}
                      multiple={true}
                      disabled={false}
                      blockInvalidChars={this.blockInvalidChars}
                      actions
                      {...this.props}
                    />
                  </>
                }
                helperText={
                  <span className="text-muted" style={{ fontSize: "0.9rem" }}>
                    Only the pickup team can edit this. If you wish to change
                    this, please contact{" "}
                    <a href="mailto:hello@pickup.ph">hello@pickup.ph</a> or your
                    administrator.
                  </span>
                }
              />
            </Col>
          </Row>
          <button
            type="submit"
            disabled={this.state.isSubmitting}
            className={GetSubmitClassList(
              this.state.isSubmitting,
              `btn btn-primary font-weight-bold px-9 py-4 my-3`,
            )}
          >
            {saveButtonText}
          </button>
        </Form>
      </>
    );
  }
}

const Choices = ({
  searchTagsList,
  data,
  multiple,
  disabled,
  preselected,
  blockInvalidChars,
}) => {
  const [multiSelections, setMultiSelections] = useState(preselected);
  return (
    <Typeahead
      id={"search-tags"}
      placeholder={"Search Tags"}
      onChange={(selected) => {
        if (selected !== undefined) {
          const newSelected = selected.map((tag) => {
            return typeof tag === "string" ? tag : tag.name;
          });
          setMultiSelections(newSelected);
          data.search_tags = newSelected;
        }
      }}
      options={searchTagsList}
      selected={multiSelections}
      multiple={multiple}
      disabled={disabled}
      labelKey="name"
      allowNew={true}
      onKeyDown={(e) => blockInvalidChars(e)}
      newSelectionPrefix={"New Search Tag: "}
      emptyLabel={
        <div style={{ whiteSpace: "break-spaces" }}>
          No matches found - try adding a new tag by typing one
        </div>
      }
    />
  );
};
