import React from "react";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import GoogleAnalyticsGrid from "../../components/dashboard/GoogleAnalyticsGrid";
import { DB_DATE_FORMAT } from "../../constants";
import GroupDropdown from "../../components/dashboard/GroupDropdown";
import useGoogleAnalyticsRedirect from "../../hooks/useGoogleAnalyticsRedirect";

import PacmanLoading from "../../components/widgets/PacmanLoading/PacmanLoading";
import { InfoAlertHelper } from "../../components/Base";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";

const getIdsAndAttr = function (props, selectedView = {}) {
  const { role = "", brands = [], stores = [] } = props;
  const { id = "", attr = "brand" } = selectedView;

  if (role === "brand_admin") return { id: id || brands[0], attr };
  if (role === "store_admin" || role === "console_user")
    return { id: id || stores[0], attr: "store" };

  return { id: id || brands, attr: attr || "brand" };
};

export default function Page() {
  const { mode = "" } = useParams(),
    {
      user,
      user: { stores = [], brands = [], iam_role = "" },
    } = useSelector((state) => state.auth);
  // console.log("multibrandgoogleanalytics page state: ", useSelector(state => state))
  useGoogleAnalyticsRedirect(user, mode);

  return (
    <MainComponent
      id={user.client_id}
      user={user}
      stores={stores}
      brands={brands}
      role={iam_role}
    />
  );
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Group Analytics",
      selectedDateRange: "Today",
      statCardQuery: "day",
      // selectedFilter: {id: '', name: 'All'},
      date: moment().format(DB_DATE_FORMAT),
    };

    this.api = core("pu").get();
    this.data_url = `/googleanalytics`;

    this.handleDateRangeClick.bind(this);
  }

  handleDateRangeClick = async (e, date = moment().format(DB_DATE_FORMAT)) => {
    let statCardDataRange = "";
    const dropdownText = e.currentTarget.textContent || "day";

    if (dropdownText === "This Week" || dropdownText === "Last Week") {
      statCardDataRange = "week";
    } else if (dropdownText === "This Month" || dropdownText === "Last Month") {
      statCardDataRange = "month";
    } else if (dropdownText === "This Year" || dropdownText === "Last Year") {
      statCardDataRange = "year";
    } else if (dropdownText === "All-time") {
      statCardDataRange = "allTime";
    } else {
      statCardDataRange = "day";
    }

    this.setState({
      selectedDateRange: dropdownText,
      statCardQuery: statCardDataRange,
      date,
      data_status: "fetching",
    });
    setTimeout(() => {
      this.getData();
    }, 500);
  };

  handleChangeSelectedFilter = async (item) => {
    this.setState({
      selectedFilter: item,
      data_status: "fetching",
    });
    setTimeout(() => {
      this.getData();
    }, 500);
  };

  async getData() {
    this._isMounted && this.setState({ data_status: "fetching" });
    let date = this.state.date || moment().format(DB_DATE_FORMAT);
    let statCardRange = this.state.statCardQuery || "day";
    let view = this.state.selectedFilter || getIdsAndAttr(this.props);

    const getContent = () => {
      const { role = "", brands = [], stores = [] } = this.props;
      const { id = "", attr = "" } = getIdsAndAttr(this.props, view);
      // console.log("multibrandgoogleanalytics brands: ", brands)

      return new Promise((resolve, reject) => {
        this.api
          .post({
            url: "/dashboard/analytics",
            data: {
              role: role,
              brands:
                role === "store_admin" || role === "console_user"
                  ? stores
                  : brands,
              id: id || view.id,
              attr: attr || view.attr,
              date,
              dateRange: statCardRange,
            },
          })
          .then((data) => resolve(data.data))
          .catch((err) => reject(err));
      });
    };

    try {
      // const hasRollup = this.props.role === 'group_admin';
      const { role = "" } = this.props;
      const hasRollup = ["group_admin", "accounting"].includes(role);
      const defaultAttr =
        role === "store_admin" || role === "console_user" ? "store" : "brand";
      const content = await getContent();
      // console.log("multibrandgoogleanalytics content: ", content)
      const defaultSelectedFilter = !hasRollup
        ? {
            // ...header[0],
            ...content.allBrands[0],
            attr: defaultAttr,
          }
        : {
            name: "All",
            id: "",
            attr: defaultAttr,
          };

      this._isMounted &&
        this.setState({
          data: {
            ...content,
          },
          data_status: "fetched",
          order_fetch_status: "fetched",
          // selectedFilter: this.state.selectedFilter|| {...getIdsAndAttr(this.props, view)},
          // selectedFilter: this.state.selectedFilter|| {...content.allBrands[0]},
          selectedFilter: this.state.selectedFilter || defaultSelectedFilter,
        });

      this.handleOnDataFetched();
      if (this.use_display_data) {
        this.setDisplayData();
      }
    } catch (error) {
      this._isMounted && this.setState({ error: error, showSwalError: true });
      this.handleOnDataFetchError();
    }
  }

  render() {
    // console.log("multibrandgoogleanalytics state: ", this.state)
    // console.log("multibrandgoogleanalytics props: ", this.props)
    // const defaultAttr = this.props. role ==='store_admin'? 'store': 'brand';
    const { role = "", brands } = this.props;
    const hasRollup = ["group_admin", "accounting"].includes(role);
    // const hasRollup = this.props.role === 'group_admin';
    const {
        data = {},
        data_status = "",
        selectedFilter = {},
        selectedDateRange = {},
        statCardQuery = "day",
        date = moment().format(DB_DATE_FORMAT),
      } = this.state,
      isLoading = data_status !== "fetched";
    const { allBrands = [] } = data;
    // console.log("multibrandgoogleanalytics props: ", this.props)
    // console.log("multibrandgoogleanalytics state: ", this.state)

    const allIds = allBrands.map(({ id }) => id);

    function dropdownText(dateRange, date) {
      const isCurrent = date === moment().format(DB_DATE_FORMAT);
      const prefix = isCurrent ? "This" : "Last";

      if (dateRange === "week") return `${prefix} Week`;
      if (dateRange === "month") return `${prefix} Month`;
      if (dateRange === "year") return `${prefix} Year`;
      if (dateRange === "allTime") return "All-time";
      return `${isCurrent ? "Today" : "Yesterday"}`;
    }

    const dateRangeList = [
      { date: moment().format(DB_DATE_FORMAT), name: "Today" },
      {
        date: moment(moment().subtract(1, "days")).format(DB_DATE_FORMAT),
        name: "Yesterday",
      },
      { date: moment().format(DB_DATE_FORMAT), name: "This Week" },
      {
        date: moment(moment().subtract(1, "weeks")).format(DB_DATE_FORMAT),
        name: "Last Week",
      },
      { date: moment().format(DB_DATE_FORMAT), name: "This Month" },
      {
        date: moment(moment().subtract(1, "months")).format(DB_DATE_FORMAT),
        name: "Last Month",
      },
      { date: moment().format(DB_DATE_FORMAT), name: "This Year" },
      {
        date: moment(moment().subtract(1, "year")).format(DB_DATE_FORMAT),
        name: "Last Year",
      },
      { date: moment().format(DB_DATE_FORMAT), name: "All-time" },
    ];

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          <div className="">
            <InfoAlertHelper
              text={
                "The data displayed below is from the Google Analytics 4 and it may take 24 to 48 hours to refresh."
              }
            />

            <div className="d-flex justify-content-end w-100 mb-4">
              {role !== "console_user" ? (
                <>
                  <GroupDropdown
                    items={allBrands}
                    onSelectView={this.handleChangeSelectedFilter}
                    selectedView={selectedFilter}
                    hasRollup={hasRollup}
                  />
                </>
              ) : (
                <></>
              )}

              <Dropdown className="align-self-end ml-4">
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  {dropdownText(statCardQuery, date)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {dateRangeList.map((item) => (
                    <Dropdown.Item
                      onClick={(e) => this.handleDateRangeClick(e, item.date)}
                      key={item.name}
                    >
                      {item.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <GoogleAnalyticsGrid
              // itemSoldURL = {`/group/${this.props.id}/item-sold?date=${date}&dateRange=${statCardQuery}&id=${selectedFilter.id}&attr=${selectedFilter.attr}`}
              // itemSoldURL = {`/dashboard/analytics/item-sold?date=${date}&dateRange=${statCardQuery}&id=${JSON.stringify(selectedFilter.id||allIds)}&attr=${selectedFilter.attr|| 'brand'}`}
              selectedFilter={selectedFilter}
              selectedDateRange={selectedDateRange}
              data={data}
              // statCardQuery= {statCardQuery}
              date={date}
              brands={brands}
            />
          </div>
        )}
      </>
    );
  }
}
