import React, { useCallback } from "react";

import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";

import { InfoTooltip } from "../Base";
import EmptyData from "../EmptyData";
import useFetch from "../../hooks/useFetch";
import { GRAPH_COLORS } from "../../constants";
import PacmanWrapper from "../dashboard/PacmanWrapper";
import { statPieGraphProps } from "../../helpers";

const StatDonutGraph = (props) => {
  const {
    className = "",
    header = "",
    height = 250,
    params = {},
    data_url = "",
    customOptions = {},
    children,
  } = props;

  const reqObj = {
    url: data_url,
    // url: "/dashboard/category/item-sold",
    method: "post",
    body: params,
  };

  const dataFormatter = useCallback((data) => {
    const { category_list = [], item_sold_by_category = [] } = data;

    return statPieGraphProps(category_list, item_sold_by_category);
  }, []);

  const { data, isLoading } = useFetch(reqObj, { dataFormatter, args: [] });

  const options = {
    chart: {
      // MAKE 'id' FIELD A PROP WHEN ANOTHER GRAPH COMPONENT USES THIS IN THE FUTURE
      id: "statPie",
      title: props.title,
      type: "donut",
      height: 300,
      events: {
        legendClick: function (chartContext, seriesIndex, config) {
          const { series } = config.globals;

          let item = false;
          if (config.globals.deleteItems) {
            item = config.globals.deleteItems.find(
              (el) => el.index === seriesIndex,
            );
          }

          if (item) {
            const { series } = item;
            config.globals.series.splice(seriesIndex, 1, series);
            config.globals.deleteItems = config.globals.deleteItems.filter(
              (delItem) => delItem.index !== seriesIndex,
            );
            ApexCharts.exec("statPie", "updateSeries", config.globals.series);
          } else {
            let deleteItem = series.splice(seriesIndex, 1, 0);
            deleteItem = { index: seriesIndex, series: deleteItem[0] };
            config.globals.deleteItems = config.globals.deleteItems
              ? [...config.globals.deleteItems, deleteItem]
              : [deleteItem];

            ApexCharts.exec("statPie", "updateSeries", config.globals.series);
          }

          const container = document.querySelector(".item-sold-by-category");
          const selectedLegend = container.querySelectorAll(
            ".apexcharts-legend-series",
          );

          config.globals.deleteItems.forEach((el) => {
            selectedLegend[el.index].classList.toggle("opacity-35");
          });
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value, { config, globals, seriesIndex }) {
          return value;
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: props.labels,
    colors: GRAPH_COLORS,
    plotOptions: {
      pie: {
        donut: {
          size: "90%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              offsetY: -5,
            },
            value: {
              show: true,
              fontSize: "16px",
              color: undefined,
              offsetY: +5,
              formatter: function (val) {
                // const total = props.series.reduce(
                const total = data.pieSeries.reduce(
                  (total, item) => total + item,
                  0,
                );
                const displayValue = ((val / total) * 100).toFixed(2) || 0;

                return `${displayValue}%`;
              },
            },
            total: {
              show: true,
              label: "Total",
              color: "#ffa500",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
      onItemClick: {
        toggleDataSeries: false,
      },
    },
  };

  if (isLoading || data === null) {
    return <PacmanWrapper />;
  }

  return (
    <div className={`card card-custom bg-white ${className}`}>
      {/* Header */}
      {header && (
        <div className="card-header border-0 bg-primary py-5">
          <h3 className="card-title font-weight-bolder text-white">
            {header}
            {params.id &&
              InfoTooltip(
                "Previously deleted items/categories will be under 'Archive' category",
                "white",
              )}
          </h3>
        </div>
      )}

      {children}
      {/* Body */}
      <div className="card-body align-self-stretch">
        {data.pieSeries.length <= 0 ? (
          <EmptyData className="text-center mt-12" />
        ) : (
          <Chart
            options={{ ...options, ...customOptions, labels: data.pieLabels }}
            series={data.pieSeries}
            height={height}
            type="donut"
          />
        )}
      </div>
    </div>
  );
};

export default StatDonutGraph;
