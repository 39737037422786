import React from "react";
import { useSelector } from "react-redux";
import { Humanize } from "../../helpers";

function generateOptions(roles = []) {
  return (
    <>
      <option value="">Select Role</option>
      {roles.map((role) => {
        return <option value={role}>{Humanize(role)}</option>;
      })}
    </>
  );
}

export default function DisplayRolesDropdown({}) {
  const { user } = useSelector((state) => state.auth),
    { iam_role = "" } = user;

  if (iam_role === "group_admin") {
    return generateOptions([
      "group_admin",
      "brand_admin",
      "store_admin",
      "accounting",
      "console_user",
      "console_user_dispatch",
      "console_user_counter",
    ]);
  } else if (iam_role === "accounting") {
    return generateOptions(["accounting"]);
  } else if (iam_role === "brand_admin") {
    return generateOptions([
      "brand_admin",
      "store_admin",
      "console_user",
      "console_user_dispatch",
      "console_user_counter",
    ]);
  } else if (iam_role === "store_admin") {
    return generateOptions([
      "store_admin",
      "console_user",
      "console_user_dispatch",
      "console_user_counter",
    ]);
  } else {
    return <></>;
  }
}
