import React from "react";
import { Link } from "react-router-dom";
import {
  AnimateDiv,
  Block,
  OrderTimeRender,
  SVGIcon,
} from "../../components/Base";
import styled from "styled-components";
import { getMaxItemsInMealPlan } from "../../helpers/branded-website";
import DisplayOrderDateWithCalendarIcon from "../DisplayOrderDateWithCalendarIcon";
import TrackOrderDetailsItem from "./TrackOrderDetailsItem";

/**
 * Track Page - Meal Plan Schedule Page
 * @param {Object} props.data
 * @param {Object} props.website_theme
 * @returns
 */
export default function TrackMealPlanSchedulePage(props) {
  const { data = {}, website_theme = {} } = props;

  const ColoredIcon = styled.span`
    svg g [fill] {
      fill: ${website_theme.icon_color};
    }
  `;

  return (
    props.data.order.isMealPlan && (
      <AnimateDiv>
        <div className="track-page-width">
          <div
            className="cursor-pointer"
            onClick={props.actions.onClickBackToHome}
            style={{ color: website_theme.link_text_color }}
          >
            <ColoredIcon>
              <SVGIcon
                icon={"Navigation/Arrow-left"}
                title="Back to home"
                variant="custom"
              />
            </ColoredIcon>{" "}
            Back
          </div>
          <Block
            title={<>meal plan schedule</>}
            content={
              <div style={{ color: website_theme.text_color }}>
                <div className="mt-2">
                  <span
                    className="font-weight-bolder mr-2"
                    style={{
                      color: website_theme.highlighted_text_color,
                      textTransform: "uppercase",
                    }}
                  >
                    {getMaxItemsInMealPlan(props.data.order.meal_plan_type)} day
                    meal plan
                  </span>
                </div>
                <div>
                  <span className="font-weight-bold mr-2">
                    Dispatch Time:{" "}
                    <OrderTimeRender
                      order_time={props.data.order.order_time}
                      noDate={true}
                    />
                  </span>
                </div>
                {data.mealPlanSchedule.map((order, index) => (
                  <div className="mv-1rem">
                    <a
                      href={`/track?token=${order._id}`}
                      style={{
                        color: website_theme.link_text_color,
                        textDecoration: "underline",
                      }}
                    >
                      <div className="font-weight-boldest text-uppercase mb-1">
                        Order #{order.transaction_number}
                      </div>
                    </a>
                    <DisplayOrderDateWithCalendarIcon
                      order_date={order.order_date}
                      style={{ marginTop: "2px" }}
                    />
                    <DisplayOrderInMealPlanSchedule
                      order={order}
                      key={index}
                      color={website_theme.highlighted_text_color}
                    />
                  </div>
                ))}
              </div>
            }
          />
        </div>
      </AnimateDiv>
    )
  );
}

const DisplayOrderInMealPlanSchedule = (props) => {
  return props.order.order_details.map((item, index) => (
    <TrackOrderDetailsItem
      key={index}
      item={item}
      isMealPlan={true}
      color={props.color}
      status={props.order.status}
      showOrderStatus={true}
    />
  ));
};
