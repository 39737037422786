import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function MenuImage({ src, backupSrc, alt }) {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && (
        <SkeletonTheme color="#cccccc" highlightColor="#f2f2f2">
          <Skeleton width={90} height={57} className="skel" />
        </SkeletonTheme>
      )}
      <LazyLoadImage
        className="menu-image"
        style={
          loading ? { position: "absolute", top: "0px", left: "0px" } : null
        }
        src={src}
        alt={alt}
        onError={(ev) => {
          ev.target.onError = null;
          ev.target.src = backupSrc;
        }}
        afterLoad={() => setLoading(false)}
      />
    </>
  );
}
