import React from "react";
import Skeleton from "react-loading-skeleton";

import APIComponent from "../API";
import StatCard from "./StatCard";
import core from "../../vendors/core-api";
import { get } from "object-path";

//// IMPORTANT
// use date, dateRange props
// IF method is GET
// ELSEIF  method is POST use apiOptions
class StatCardApi extends APIComponent {
  constructor(props) {
    super(props);
    const isPostMethod = props.apiMethod === "post";
    const dateRangeOpt = isPostMethod
      ? props.apiOptions.dateRange
      : props.dateRange;
    const dateOpt = isPostMethod ? props.apiOptions.date : props.date;

    this.data_url = `${props.data_url}?dateRange=${dateRangeOpt}&date=${dateOpt}`;
    this.api = core("pu").get();
    this.apiMethod = props.apiMethod || "get";
    this.apiOptions = props.apiOptions || {};
  }

  async getData() {
    try {
      let data;

      if (this.data_url !== "" && this.api && this._isMounted) {
        this.setState({ data_status: "fetching" });

        if (this.apiMethod === "get") {
          data = await this.api[this.apiMethod](this.data_url);
        } else {
          data = await this.api[this.apiMethod]({
            url: this.data_url,
            data: this.apiOptions,
          });
        }

        this.setState({
          data: data.data,
          data_status: "fetched",
        });
      }
    } catch (error) {
      this._isMounted && this.setState({ error: error, showSwalError: true });
      this.handleOnDataFetchError();
    }
  }

  render() {
    const { data = [], data_status } = this.state;
    const {
      title = "",
      icon = "Media/Repeat",
      variant = "white",
      statFormatter = (stat) => stat, // function to format stat data
    } = this.props;

    const isLoading = data_status !== "fetched";

    return (
      <StatCard
        variant={variant}
        icon={icon}
        title={title}
        stat={isLoading ? <Skeleton /> : statFormatter(data.value)}
      />
    );
  }
}

export default StatCardApi;
