import React from "react";

import {
  Autocomplete,
  InfoWindow,
  GoogleMap,
  Libraries,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";

import Geocode from "react-geocode";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { SecondaryHelperAlert } from "../../components/Base";
const { REACT_APP_GMAPS_KEYS, REACT_APP_GMAPS_URL } = process.env;

export class MapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.props.state, map: null };
    this.api = this.props.api;
    this.source = this.props.source || "settings";
    this.data = this.props.data;
  }

  setMapLoading = (status) => {
    if (this.source === "checkout") this.props.setMapLoading(status);
  };
  logUsage = (rtype = "") => {
    const {
      brand: { _id: brand_id },
      store: { _id: store_id },
    } = this.data;
    try {
      this.api.get(
        `/branded-website/gmaps/log-activity/?brand=${brand_id}&store=${store_id}&rtype=${rtype}`,
      );
    } catch (err) {
      console.error(err);
    }
  };
  localgetLatLng = (data) => {
    return {
      lat: data.geometry.location.lat(),
      lng: data.geometry.location.lng(),
    };
  };
  getGeocode = async (address) => {
    const encoded_address = encodeURI(address);
    const {
      data: { results },
    } = await this.api.post({
      url: "/branded-website/gmaps/geocode",
      data: { address: encoded_address },
    });
    return results;
  };

  getReverseGeocode = async (lat, lng) => {
    const {
      data: { results },
    } = await this.api.post({
      url: "/branded-website/gmaps/reverse_geocode",
      data: { lat, lng },
    });
    return results;
  };

  getCity = (gmapsResult) => {
    // console.log("getCity results: ", gmapsResult)
    let address_components = gmapsResult.address_components;
    // console.log("getCity gmapsResult address_components: ", address_components)
    let types = address_components.find((item) =>
      item.types.includes("locality"),
    );

    // console.log("getCity gmapsResult types: ", types)
    let city = types.long_name;
    // console.log("getCity gmapsResult city: ", city)
    return city;
  };

  handleChange = (address) => {
    let { values = {} } = this.state;
    values["delivery_address"] = address ? address : "";
    this.setState({
      values: values,
    });
  };
  handleSelect = async (
    autocomplete_address_string,
    placesData,
    setMapVisible,
    setCenter,
    setMarker,
    setAddressString,
  ) => {
    const latLng = this.localgetLatLng(placesData);
    const city = this.getCity(placesData);
    setCenter(latLng);
    setMarker(latLng);
    setAddressString(autocomplete_address_string);
    setMapVisible(true);

    this.mapPanTo(latLng);
    this.onPlacesSelect(latLng, autocomplete_address_string, city);
    document.activeElement.blur();
  };
  mapPanTo = (latLng) => {
    const { map } = this.state;
    if (!map) return;
    map.panTo(latLng);
  };
  onPlacesSelect = async (latLng, address = "", city = "") => {
    try {
      let newLat = latLng.lat,
        newLng = latLng.lng;
      let { values = {} } = this.state;
      values["delivery_address"] = address ? address : "";
      values["delivery_city"] = city ? city : "";
      values["currentPosition"] = {
        lat: newLat,
        lng: newLng,
      };
      values["mapPosition"] = {
        lat: newLat,
        lng: newLng,
      };
      values["markerPosition"] = {
        lat: newLat,
        lng: newLng,
      };

      this.setState({
        values: values,
        address: address ? address : "",
      });
      if (this.source !== "settings") this.logUsage();
    } catch (err) {
      console.error(err);
    }
  };
  geoCodeSelect = async (latLng, setAddressString) => {
    try {
      let newLat = latLng.lat,
        newLng = latLng.lng;
      this.setMapLoading(true);
      const response = await this.getReverseGeocode(newLat, newLng);
      this.setMapLoading(false);
      const address = response[0].formatted_address;
      const city = this.getCity(response[0]);
      setAddressString(address);
      let { values = {} } = this.state;
      values["delivery_address"] = address ? address : "";
      values["delivery_city"] = city ? city : "";
      values["currentPosition"] = {
        lat: newLat,
        lng: newLng,
      };
      values["mapPosition"] = {
        lat: newLat,
        lng: newLng,
      };
      values["markerPosition"] = {
        lat: newLat,
        lng: newLng,
      };
      this.setState({
        values: values,
        address: address ? address : "",
      });
      if (this.source !== "settings") this.logUsage();
    } catch (err) {
      console.log(err);
    }
  };
  componentDidMount() {
    Geocode.setApiKey(REACT_APP_GMAPS_KEYS);
    // Geocode.setApiKey("AIzaSyDGe5vjL8wBmilLzoJ0jNIwe9SAuH2xS_0");
    Geocode.enableDebug();
  }

  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.address !== nextState.address) {
      return false;
    } else if (
      this.state.values["currentPosition"].lat ===
      nextState.values["currentPosition"].lat
    ) {
      return false;
    }
  }
  render() {
    const BaseComponent = () => {
      const [center, setCenter] = React.useState({
        lat: this.state.values["mapPosition"].lat,
        lng: this.state.values["mapPosition"].lng,
      });
      const [marker, setMarker] = React.useState({
        lat: this.state.values["mapPosition"].lat,
        lng: this.state.values["mapPosition"].lng,
      });
      const [info, setInfo] = React.useState({
        lat: this.state.values["mapPosition"].lat + 0.00025,
        lng: this.state.values["mapPosition"].lng,
      });
      const [mapVisible, setMapVisible] = React.useState(false);
      const [addressString, setAddressString] = React.useState(
        this.state.values["delivery_address"],
      );

      let renderMap = "";

      if (this.state.values["currentPosition"].lat !== undefined) {
        renderMap = (
          <div>
            <AsyncSearchBox
              addressString={addressString}
              setAddressString={setAddressString}
              setMapVisible={setMapVisible}
              setMarker={setMarker}
              setCenter={setCenter}
            />

            {mapVisible && (
              <div>
                {/* <APIProvider apiKey={REACT_APP_GMAPS_KEYS}> */}
                <GeneralMap
                  tooltipDisplay={this.props.tooltipDisplay}
                  center={center}
                  info={info}
                  setInfo={setInfo}
                  marker={marker}
                  setMarker={setMarker}
                  setCenter={setCenter}
                  addressString={addressString}
                  setAddressString={setAddressString}
                  googleMapURL={REACT_APP_GMAPS_URL}
                />
                {/* </APIProvider> */}
                {this.source === "checkout" && (
                  <SecondaryHelperAlert
                    message={
                      <>
                        Your rider will deliver to the pinned location, you can
                        move pin or edit written address for a more accurate
                        location.
                      </>
                    }
                  />
                )}
              </div>
            )}
          </div>
        );
      } else {
        renderMap = <div style={{ height: this.props.height }} />;
      }
      return renderMap;
    };

    const AsyncSearchBox = (props) => {
      const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
      } = usePlacesService({
        apiKey: REACT_APP_GMAPS_KEYS,
        options: {
          fields: "formatted_address",
          componentRestrictions: { country: "PH" },
          types: ["geocode", "establishment"],
        },
      });

      return (
        <>
          <div>
            <div
              className={
                isPlacePredictionsLoading ? "spinner spinner-right" : ""
              }
            >
              <input
                value={props.addressString || ""}
                name="delivery_address"
                placeholder="Delivery Address"
                className="form-control"
                onBlur={() => {
                  if (placePredictions.length === 1) {
                    const suggestion = placePredictions[0];
                    placesService.getDetails(
                      {
                        placeId: suggestion.place_id,
                      },
                      (value) => {
                        var autocomplete_address_string =
                          suggestion.description;
                        getPlacePredictions({ input: "" });
                        this.handleSelect(
                          autocomplete_address_string,
                          value,
                          props.setMapVisible,
                          props.setCenter,
                          props.setMarker,
                          props.setAddressString,
                        );
                      },
                    );
                  }
                }}
                onChange={(event) => {
                  const value = event.target.value;
                  getPlacePredictions({ input: value });
                  props.setAddressString(value);
                }}
              />
            </div>
            <div className="mt-4">
              <div
                style={{ backgroundColor: "#ffffff" }}
                id="suggestion-col"
                className="ml-2 mr-2"
              >
                {placePredictions.map((suggestion, i) => {
                  const className = "suggestion-item";
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  const suggestionText = suggestion.description.replace(
                    new RegExp(`${props.addressString}`, "gi"),
                    `<span style="font-weight:bold;text-transform:capitalize">${props.addressString}</span>`,
                  );
                  return (
                    <div
                      key={i}
                      className={className}
                      style={style}
                      onClick={() => {
                        placesService.getDetails(
                          {
                            placeId: suggestion.place_id,
                          },
                          (value) => {
                            var autocomplete_address_string =
                              suggestion.description;
                            getPlacePredictions({ input: "" });
                            this.handleSelect(
                              autocomplete_address_string,
                              value,
                              props.setMapVisible,
                              props.setCenter,
                              props.setMarker,
                              props.setAddressString,
                            );
                          },
                        );
                      }}
                    >
                      <div className="mb-4">
                        <div
                          id={i === 0 ? "suggestion_first_option" : ""}
                          dangerouslySetInnerHTML={{
                            __html: suggestionText,
                          }}
                        />
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      );
    };

    const GeneralMap = (props) => {
      const [infoVisible, setInfoVisible] = React.useState(
        props.tooltipDisplay,
      );

      const onMapLoad = (map) => {
        this.setState({ map: map });
      };

      const onDrag = () => {
        const { map } = this.state;
        const center = map.getCenter().toJSON();
        props.setMarker(center);
      };
      const handleDragEnd = () => {
        setInfoVisible(false);
        this.geoCodeSelect(props.marker, props.setAddressString);
      };
      const markerRef = React.createRef();
      return (
        <GoogleMap
          mapContainerStyle={{ height: "400px" }}
          zoom={this.props.zoom}
          center={props.center}
          onDrag={onDrag}
          options={{
            draggable: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            zoomControl: true,
            scrollwheel: false,
            disableDoubleClickZoom: false,
          }}
          onBoundsChanged={onDrag}
          onDragEnd={handleDragEnd}
          onLoad={onMapLoad}
        >
          <Marker position={props.marker} draggable={false} />
          {infoVisible && (
            <InfoWindow
              position={props.info}
              anchor={markerRef}
              options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
            >
              <div
                style={{
                  backgroundColor: "#525252",
                  color: "#fff",
                  padding: "10px",
                  borderRadius: "5px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  fontSize: "10",
                }}
              >
                Move pin to edit location
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      );
    };
    return (
      <BaseComponent
        state={this.props.state}
        validateDelivery={this.props.validateDelivery}
        height="400px"
        zoom={this.props.state.values["zoomValue"]}
      />
    );
  }
}
class GMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
    };
  }
  render() {
    return (
      <MapComponent
        center={this.props.center}
        state={this.state}
        height={this.props.height}
        zoom={this.props.zoom}
      />
    );
  }
}

export default GMap;
