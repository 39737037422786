import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { FormItem, InfoTooltip, Label } from "../../components/Base";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  GetDataFromEvent,
  GetSubmitClassList,
  Humanize,
  disableCheckbox,
} from "../../helpers";
import { CONSOLE_USERS, PAGES_PRIVILEGES, STORE_USERS } from "../../constants";
import DisplayBrandsDropdown from "./DisplayBrandsDropdown";
import DisplayStoresDropdown from "./DisplayStoresDropdown";
import DisplayRolesDropdown from "./DisplayRolesDropdown";
import { getBrands } from "./helpers";
import ht from "../../helpertexts";

export default class UserForm extends ProSourceForm {
  constructor(props) {
    super(props);

    const { user = {} } = this.props,
      {
        first_name = "",
        last_name = "",
        contact_number = "",
        email = "",
        iam_role = "",
        active = true,
        privileges = [],
        order_email_notification = false,
        refund_email_notification = false,
      } = user;

    this.state = {
      values: {
        first_name,
        last_name,
        contact_number,
        email,
        iam_role,
        active,
        privileges,
        order_email_notification,
        refund_email_notification,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      checkingEmail: false,
    };

    this.validate = {
      required: ["first_name", "last_name", "email", "iam_role"],
    };

    this.handleOnClickDeleteBtn = this.handleOnClickDeleteBtn.bind(this);
    this.onChangePrivilege = this.onChangePrivilege.bind(this);
    this.handleOnSelectBrands = this.handleOnSelectBrands.bind(this);
    this.handleOnSelectStores = this.handleOnSelectStores.bind(this);
    this.handleOnBlurBrands = this.handleOnBlurBrands.bind(this);
    this.handleOnBlurStores = this.handleOnBlurStores.bind(this);
  }

  onChangePrivilege = (event) => {
    const target = event.target,
      checked = target.checked,
      value = GetDataFromEvent(event, "data-value");
    let { values = {} } = this.state;

    if (checked) {
      values.privileges.push(value);
    } else {
      values.privileges = values.privileges.filter((privilege) => {
        return privilege !== value;
      });
    }

    this.setState({ values });
  };

  onChange = (event) => {
    const target = event.target,
      checked = target.checked,
      name = target.name,
      value = target.value;

    let { values = {}, errors = {} } = this.state,
      state = {};

    if (
      name === "order_email_notification" ||
      name === "refund_email_notification"
    ) {
      values[name] = checked;
      state.values = values;
    } else if (name === "email" && value !== "") {
      const valid = this.validateEmail(value);
      if (!valid) {
        errors.email = "Please enter a valid email";
      } else {
        delete errors.email;
      }

      state.errors = errors;
    } else if (name === "iam_role") {
      if (
        value === "group_admin" ||
        value === "brand_admin" ||
        value === "store_admin" ||
        value === "accounting"
      ) {
        values["privileges"] = PAGES_PRIVILEGES;
      } else if (CONSOLE_USERS.includes(value)) {
        values["privileges"] = ["home", "menu", "orders", "help"];
      } else {
        values["privileges"] = [];
      }

      state.values = values;
      state.mountDropdown = false;
    }

    this.setState(state);
    this.removeErrors(["brands", "stores"]);

    setTimeout(() => {
      this.setState({ mountDropdown: true });
    }, 50);
  };

  onBlur = (event) => {
    const target = event.target,
      name = target.name,
      value = target.value;
    let { errors = {} } = this.state;

    if (name === "email" && value !== "") {
      this.setState({ checkingEmail: true });
      this.props.api
        .post({
          url: "/external/users/validate",
          data: { email: value },
        })
        .then(({ data }) => {
          this.setState({ checkingEmail: false });
          const { existing = false } = data;
          if (existing) {
            errors["email"] = "Email is already taken.";
          } else {
            if (errors["email"] === "Email is already taken.") {
              // check first if it's the existing email error before deleting
              delete errors["email"];
            }
          }

          this.setState({ errors });
        })
        .catch(() => {
          this.props.handleError();
        })
        .finally(() => {
          this.setState({ checkingEmail: false });
        });
    }
  };

  onSubmit = (values, setSubmitting) => {
    const { iam_role = "", brands = [], stores = [] } = values;
    if (iam_role === "brand_admin" && brands.length === 0) {
      this.addError("brands", "This field is required");
      setSubmitting(false);
    } else if (STORE_USERS.includes(iam_role) && stores.length === 0) {
      this.addError("stores", "This field is required");
      setSubmitting(false);
    } else {
      this.props.onSubmit(values, setSubmitting);
    }
  };

  handleOnClickDeleteBtn = () => {
    this.props.onClickDeleteBtn && this.props.onClickDeleteBtn();
  };

  handleOnSelectBrands = (brands) => {
    let { values = {} } = this.state;
    values.brands = brands.map(({ _id = "" }) => {
      return _id;
    });
    this.setState({ values });
  };

  handleOnSelectStores = (stores) => {
    let { values = {} } = this.state;
    values.stores = stores.map(({ _id = "" }) => {
      return _id;
    });

    if (CONSOLE_USERS.includes(values.iam_role)) {
      // Only single store can be assigned to this roles
      values.stores = [values.stores[0]];
    }

    values.brands = getBrands(stores);

    if (values.brands.length > 1) {
      this.addError("stores", "You can only add stores from same brand");
    } else {
      this.removeError("stores");
    }

    this.setState({ values });
  };

  handleOnBlurBrands = (selectedBrands) => {
    if (selectedBrands.length === 0) {
      this.addError("brands", "This field is required");
    } else {
      this.removeError("brands");
    }
  };

  handleOnBlurStores = (selectedStores) => {
    const brands = getBrands(selectedStores);

    if (selectedStores.length === 0) {
      this.addError("stores", "This field is required");
    } else if (brands.length > 1) {
      this.addError("stores", "You can only add stores from same brand");
    } else {
      this.removeError("stores");
    }
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      },
      { submitButton = "Save Changes", showBackButton = false } = this.props,
      { checkingEmail = false, values = {}, mountDropdown = true } = this.state,
      { privileges = [] } = values;

    const isClientAdmin = this.props.hasOwnProperty("user")
        ? this.props.user.hasOwnProperty("iam_role") &&
          this.props.user.iam_role === "group_admin"
        : false,
      isActive = values.active;

    return (
      <Form onSubmit={this.handleOnSubmit}>
        <Row>
          <Col>
            <FormItem
              label={"First Name:"}
              name={"first_name"}
              inputProps={{
                name: "first_name",
                placeholder: "First Name",
                value: this.state.values["first_name"],
                disabled: !isActive ? "disabled" : "",
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
            />
          </Col>
          <Col>
            <FormItem
              label={"Last Name:"}
              name={"last_name"}
              inputProps={{
                name: "last_name",
                placeholder: "Last Name",
                value: this.state.values["last_name"],
                disabled: !isActive ? "disabled" : "",
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <FormItem
              label={"Email:"}
              name={"email"}
              inputProps={{
                name: "email",
                placeholder: "Email",
                value: this.state.values["email"],
                disabled: !isActive ? "disabled" : "",
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
              helperText={
                checkingEmail ? (
                  <>
                    <span className="text-success">
                      Checking email availability...
                    </span>
                  </>
                ) : (
                  <></>
                )
              }
            />
          </Col>
          <Col>
            <FormItem
              label={"Contact Number:"}
              name={"contact_number"}
              inputProps={{
                name: "contact_number",
                placeholder: "Contact Number",
                value: this.state.values["contact_number"],
                disabled: !isActive ? "disabled" : "",
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              normal
            />
          </Col>
        </Row>

        <hr />

        <Row>
          <Col>
            <FormItem
              label={<>Role:{InfoTooltip(ht.users.roles)}</>}
              name={"iam_role"}
              actions={actions}
              showRequired={true}
              normal
              customFormControl
              custom={
                <>
                  <Form.Control
                    as="select"
                    value={this.state.values["iam_role"]}
                    {...inputActions}
                    disabled={!isActive ? "disabled" : ""}
                    name="iam_role"
                    className="text-capitalize"
                  >
                    <DisplayRolesDropdown
                      value={this.state.values["iam_role"]}
                    />
                  </Form.Control>
                </>
              }
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <DisplayBrandsDropdown
              iam_role={this.state.values["iam_role"]}
              action={this.handleOnSelectBrands}
              actions={actions}
              onBlur={this.handleOnBlurBrands}
            />
            {mountDropdown ? (
              <DisplayStoresDropdown
                iam_role={this.state.values["iam_role"]}
                action={this.handleOnSelectStores}
                actions={actions}
                onBlur={this.handleOnBlurStores}
              />
            ) : (
              <>Loading...</>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <Label
                text={<>Privileges:{InfoTooltip(ht.users.privileges)}</>}
              />
              <div className="checkbox-list">
                {PAGES_PRIVILEGES.map((page = "", index) => {
                  return (
                    <label
                      key={index}
                      className={`checkbox text-capitalize${
                        disableCheckbox(this.state.values["iam_role"], page)
                          ? " checkbox-disabled"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        data-value={page}
                        name={`page-${page}`}
                        onChange={this.onChangePrivilege}
                        checked={
                          privileges.indexOf(page) !== -1 ? "checked" : ""
                        }
                        disabled={
                          disableCheckbox(this.state.values["iam_role"], page)
                            ? "disabled"
                            : ""
                        }
                      />{" "}
                      {Humanize(page)}
                      <span></span>
                    </label>
                  );
                })}
              </div>
            </Form.Group>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col>
            <div>
              <div className="form-group row mv-1rem">
                <label className="col-5 col-sm-5 col-xl-5 col-form-label font-weight-bolder text-dark mr-2">
                  Order Emails:{InfoTooltip(ht.users.order_email_notification)}
                </label>
                <div className="col-4">
                  <span className="switch switch-outline switch-icon switch-primary">
                    <label>
                      <input
                        type="checkbox"
                        name="order_email_notification"
                        checked={
                          this.state.values.order_email_notification
                            ? "checked"
                            : null
                        }
                        disabled={!isActive ? "disabled" : ""}
                        {...inputActions}
                      />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div>
              <div className="form-group row mv-1rem">
                <label className="col-5 col-sm-5 col-xl-5 col-form-label font-weight-bolder text-dark mr-2">
                  Refund Emails:
                  {InfoTooltip(ht.users.refund_email_notification)}
                </label>
                <div className="col-4">
                  <span className="switch switch-outline switch-icon switch-primary">
                    <label>
                      <input
                        type="checkbox"
                        name="refund_email_notification"
                        checked={
                          this.state.values.refund_email_notification
                            ? "checked"
                            : null
                        }
                        disabled={!isActive ? "disabled" : ""}
                        {...inputActions}
                      />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div className="d-flex flex-row justify-content-space-between">
          <div>
            {showBackButton ? (
              <Button
                type="button"
                variant="secondary"
                onClick={this.props.onClickBackBtn}
                className={GetSubmitClassList(
                  this.state.isSubmitting,
                  `btn btn-primary font-weight-bold px-9 py-4 my-3`,
                )}
              >
                <span>Back</span>
              </Button>
            ) : (
              <></>
            )}
          </div>
          <div className="text-right">
            {this.props.showDeleteButton && !isClientAdmin ? (
              <button
                type="button"
                disabled={this.props.deleteLoading}
                className={GetSubmitClassList(
                  this.props.deleteLoading,
                  "btn btn-danger font-weight-bold px-9 py-4 my-3 mr-2",
                )}
                onClick={this.props.handleOnClickDeleteBtn}
              >
                <span>Mark as {isActive ? <>inactive</> : <>active</>}</span>
              </button>
            ) : (
              <></>
            )}
            {isActive ? (
              <button
                type="submit"
                disabled={this.state.isSubmitting || checkingEmail}
                className={GetSubmitClassList(
                  this.state.isSubmitting,
                  `btn btn-primary font-weight-bold px-9 py-4 my-3`,
                )}
              >
                <span>{submitButton}</span>
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Form>
    );
  }
}
