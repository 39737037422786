import React from "react";

import StatCard from "../widgets/StatCard";
import { toPesoAmount, formatPromoName } from "../../helpers";
import { Row, Col } from "react-bootstrap";
import Chart from "react-apexcharts";
import { GRAPH_COLORS } from "../../constants";

const PromoDash = (props) => {
  const promos = props.data;
  const totalRevenue = promos.reduce((acc, cur) => acc + cur.totalRevenue, 0);
  const totalOrders = promos.reduce((acc, cur) => acc + cur.promoUsed, 0);
  const totalActivePromo = promos.reduce(
    (count, obj) => (obj.totalRevenue > 0 ? count + 1 : count),
    0,
  );

  const sortedData = promos.sort((a, b) => b.promoUsed - a.promoUsed);

  const groupByType = promos.reduce((acc, data) => {
    acc[data.promo_type] = acc[data.promo_type] || {
      promo_type: data.promo_type,
      promoUsed: 0,
    };
    acc[data.promo_type].promoUsed += data.promoUsed;
    return acc;
  }, {});

  // Take the top 5 promotions and group the rest as "Others"
  const top5Data = sortedData.slice(0, 5);
  const top5DataWithRevenue = top5Data.filter((obj) => obj.totalRevenue > 0);
  const topPromoOptions = {
    colors: GRAPH_COLORS,
    plotOptions: {
      bar: {
        distributed: true, // this line is mandatory
      },
    },
    xaxis: {
      categories: top5DataWithRevenue.map((data) => data.promo_code),
      labels: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: "Promo Usage",
      },
    },

    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  const topPromoSeries = [
    {
      name: "Orders",
      data: top5DataWithRevenue.map((data) => data.promoUsed),
    },
  ];
  const byTypeValues = Object.values(groupByType);
  const byTypeSeries = byTypeValues.map((data) => data.promoUsed);

  const PieOptions = {
    chart: {
      // MAKE 'id' FIELD A PROP WHEN ANOTHER GRAPH COMPONENT USES THIS IN THE FUTURE
      id: "statPie",
      type: "donut",
      height: 300,
    },

    tooltip: {
      y: {
        formatter: function (value, { config, globals, seriesIndex }) {
          return value;
        },
      },
    },

    labels: byTypeValues.map((data) => formatPromoName(data)),
    colors: GRAPH_COLORS,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: "Orders",
              color: "#1BC5BD",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
      onItemClick: {
        toggleDataSeries: false,
      },
    },
  };
  return (
    <>
      <Row>
        <Col xs={12} sm={4}>
          <StatCard
            icon={"Shopping/Money"}
            variant="white"
            title={"Revenue from promos"}
            stat={toPesoAmount(totalRevenue)}
          />
        </Col>
        <Col xs={12} sm={4}>
          <StatCard
            icon={"Weather/Suset1"}
            variant="white"
            title={"Promo  Usages from Orders"}
            stat={totalOrders}
          />
        </Col>
        <Col xs={12} sm={4}>
          <StatCard
            icon={"Weather/Suset1"}
            variant="white"
            title={"Promos with revenue"}
            stat={totalActivePromo}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <div className={`card card-custom bg-white card-stretch gutter-b`}>
            <h4 className="card-spacer">Promo Type Usage</h4>
            <Chart
              options={PieOptions}
              series={byTypeSeries}
              type="donut"
              height={400}
            />
          </div>
        </Col>
        <Col xs={12} sm={6}>
          <div className={`card card-custom bg-white card-stretch gutter-b`}>
            <h4 className="card-spacer">{props.statCardText} Top 5 Promo</h4>
            <Chart
              options={topPromoOptions}
              series={topPromoSeries}
              type="bar"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PromoDash;
