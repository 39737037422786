import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import { Button, Card, Form } from "react-bootstrap";
import { FormItem, HeaderTextWithActions2, Label } from "../../components/Base";
import ProSourceForm from "../../components/ProSourceForm";
import ImageUpload from "../../components/ImageUpload";
import { PRIMARY_COLOR } from "../../constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { GetSubmitClassList } from "../../helpers";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { debounce, isEmpty } from "lodash";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const MySwal = withReactContent(Swal);

export default function Page() {
  const { id = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  return <MainComponent id={id} user={user} />;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Feedback",
      mountForm: true,
    };

    this.api = core("pu").get();
    this.data_url = "/util/page-check?page=feedback";

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = (values, setSubmitting) => {
    this.api
      .post({ url: "/util/feedback", data: values })
      .then(({ data }) => {
        MySwal.fire({
          icon: "success",
          title: "Message sent!",
          text: "We have received your message. Please give some time for our team to respond. Thank you.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
        });
        this.setState({ mountForm: false });
        setTimeout(() => {
          this.setState({ mountForm: true });
        }, 50);
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  render() {
    const { mountForm = false } = this.state;
    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <HeaderTextWithActions2 title={this.state.title} />

        {mountForm ? (
          <ContactUsForm
            {...this.props}
            onSubmit={this.onSubmit}
            orderList={this.state.data}
            api={this.api}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
}

class ContactUsForm extends ProSourceForm {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        subject_line: "",
        relating_to: "",
        notes: "",
        attachment: "",
        file_name: "",
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      orderList: [],
      isListLoading: false,
      searchText: "",
    };

    this.validate = {
      required: ["subject_line", "relating_to", "notes"],
    };

    this.fileapi = core("puf").get();
    this.onClickUploadFile = this.onClickUploadFile.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleError = this.handleError.bind(this);
    this.fileUploadRef = React.createRef();
    this.onOrderSearch = this.onOrderSearch.bind(this);
    this.api = this.props.api;
  }

  onClickUploadFile = (e) => {
    e.preventDefault();

    this.setState({ mountFile: false });

    setTimeout(() => {
      this.setState({ mountFile: true });
      this.fileUploadRef.current.click();
    }, 50);
  };

  uploadFile = (file, setFilePath, setSubmitting) => {
    const formData = new FormData();
    formData.append("file", file);

    this.fileapi
      .post({
        url: "/util/upload-to-aws/brands",
        data: formData,
      })
      .then(({ data }) => {
        const { filePath = "" } = data;
        setFilePath(filePath);
      })
      .catch(() => {
        this.handleError();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  handleError = (error) => {
    if (error === "size-too-much") {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `File is too big! Maximum upload size is 200KB.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else if (error === "unsupported-file-extension") {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `File type unsupported. Please upload PNG, JPG and JPEG files only.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `Something went wrong`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };

  onSubmit = (values, setSubmitting) => {
    if (this.state.file) {
      this.uploadFile(
        this.state.file,
        (filePath) => {
          values.attachment = filePath;
          this.setState({ values });
          setSubmitting(true);
          this.props.onSubmit(values, setSubmitting);
        },
        setSubmitting,
      );
    } else {
      this.props.onSubmit(values, setSubmitting);
    }
  };

  onOrderSearch = (value) => {
    this.api &&
      this.api
        .get(`/orders/search?key=transaction_number&query=${value}`)
        .then((data) => {
          this.setState({
            isListLoading: false,
            orderList: data.data,
          });
        })
        .catch((error) => {
          this.setState({
            error: error,
            showSwalError: true,
            isListLoading: false,
          });
          this.handleOnDataFetchError();
        });
  };

  handleOnSearchChange = debounce(() => {
    this.onOrderSearch(this.state.searchText);
  }, 500);

  render() {
    const { orderList, isListLoading, searchText } = this.state;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <>
        <Card>
          <Card.Body>
            <Form onSubmit={this.handleOnSubmit}>
              <FormItem
                label={"Subject Line"}
                name={"subject_line"}
                inputProps={{
                  name: "subject_line",
                  placeholder: "Subject Line",
                  value: this.state.values["subject_line"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"text"}
                showRequired={true}
                normal
              />

              <FormItem
                label={"Relating To"}
                name={"relating_to"}
                customFormControl
                custom={
                  <>
                    <Form.Control
                      as="select"
                      name={"relating_to"}
                      custom
                      {...inputActions}
                    >
                      <option value="">Select type</option>
                      <option value="Report a bug">Report a bug</option>
                      <option value="Suggest a feature">
                        Suggest a feature
                      </option>
                      <option value="Ask a question">Ask a question</option>
                    </Form.Control>
                  </>
                }
                inputActions={inputActions}
                actions={actions}
                showRequired={true}
                normal
              />

              {this.state.values.relating_to === "Report a bug" ? (
                <div className="row">
                  <div className="col-md-3 col-xs-12">
                    <FormItem
                      customFormControl
                      label={"Order Number"}
                      name={"order_number"}
                      custom={
                        <>
                          <AsyncTypeahead
                            name={"order_number"}
                            id={"order-number-search-input"}
                            placeholder={`Search`}
                            options={orderList}
                            onSearch={(searchText) => {
                              this.setState({
                                searchText,
                                isListLoading: true,
                              });
                              this.handleOnSearchChange();
                            }}
                            labelKey="transaction_number"
                            isLoading={isListLoading}
                            isInvalid={
                              !isListLoading &&
                              !isEmpty(searchText) &&
                              orderList.length === 0
                            }
                            onChange={(order) => {
                              const { values } = this.state;
                              values["order_number"] =
                                order[0].transaction_number;
                              this.setState({ values });
                            }}
                          />
                        </>
                      }
                      actions={actions}
                    />
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <FormItem
                      label={"Date of Occurrence"}
                      name={"occurrence_date"}
                      inputProps={{
                        name: "occurrence_date",
                        placeholder: "Date of Occurrence",
                        value: this.state.values["occurrence_date"] || "",
                      }}
                      inputActions={inputActions}
                      actions={actions}
                      type={"date"}
                      normal
                      max={new Date().toISOString().slice(0, 10)}
                    />
                  </div>
                  <div className="col-md-3 col-xs-12">
                    <FormItem
                      label={"Time"}
                      name={"occurrence_time"}
                      actions={actions}
                      className="form-control"
                      customFormControl
                      custom={
                        <div>
                          <DatePicker
                            inputClass="form-time-picker"
                            disableDayPicker
                            format="hh:mm A"
                            plugins={[<TimePicker hideSeconds />]}
                            onChange={(date) => {
                              const { values } = this.state;
                              values["occurrence_time"] =
                                date.format("hh:mm A");
                              this.setState({ values });
                            }}
                          />
                        </div>
                      }
                    />
                  </div>
                </div>
              ) : null}

              <FormItem
                label={"Notes"}
                name={"notes"}
                inputProps={{
                  name: "notes",
                  placeholder: "Notes",
                  value: this.state.values["notes"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"textarea"}
                showRequired={true}
                normal
              />

              <div className="mv-1rem">
                <Label
                  text={
                    <>
                      <span> Attachment </span>
                    </>
                  }
                />

                {this.state.values.file_name !== "" ? (
                  <div style={{ margin: "3px 0" }}>
                    Attached: {this.state.values.file_name}
                  </div>
                ) : (
                  <></>
                )}

                <ImageUpload
                  useResize={true}
                  onError={this.handleError}
                  onChange={(file) => {
                    let { values = {} } = this.state;
                    values.file_name = file.name;

                    this.setState({ file, values });
                  }}
                  showUploadIcon={false}
                  showUploadButton={true}
                />
              </div>

              <Button
                variant="primary"
                type="submit"
                disabled={this.state.isSubmitting ? "disabled" : ""}
                className={GetSubmitClassList(this.state.isSubmitting, "")}
              >
                Send Message
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </>
    );
  }
}
