import React from "react";
import { Modal, Form } from "react-bootstrap";
import { FormItem, KTLink } from "../components/Base";
import ProSourceForm from "../components/ProSourceForm";
import { GetSubmitClassList } from "../helpers";

// import helpertexts from "../helpertexts";

export default class PreorderSettingsModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { data = {} } = this.props,
      {
        pre_order_to_order_queue_timer = 0,
        minutes_allow_order_before_opening = 0,
      } = data;

    this.state = {
      values: {
        pre_order_to_order_queue_timer,
        minutes_allow_order_before_opening,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };
  }

  onSubmit = (values, setSubmitting) => {
    values.pre_order_to_order_queue_timer =
      values.pre_order_to_order_queue_timer === ""
        ? 0
        : parseInt(values.pre_order_to_order_queue_timer);
    values.minutes_allow_order_before_opening =
      values.minutes_allow_order_before_opening === ""
        ? 0
        : parseInt(values.minutes_allow_order_before_opening);

    this.props.onSubmit(values, setSubmitting);
  };

  handleOnChange = (event) => {
    const target = event.target,
      value = target.value,
      name = target.name;
    let { values, errors } = this.state,
      state = {};
    values[name] = value;

    values[name] = value;
    if (name === "pre_order_to_order_queue_timer") {
      if (value > 1440) {
        errors[name] = "Value must be less than or equal to 1440 (24 hours)";
      } else if (value < 1) {
        errors[name] = "Value must be more than or equal to 1";
      } else {
        delete errors.pre_order_to_order_queue_timer;
      }
    }

    if (name === "minutes_allow_order_before_opening") {
      if (value < 0) {
        errors[name] = "Value must be more than or equal to 0";
      } else {
        delete errors.minutes_allow_order_before_opening;
      }
    }

    this.setState({
      values: values,
      state: state,
    });

    this.setStateKey("touched", name, "true");
    this.validateForm();

    // checks if afterOnChange function exists
    this.onChange && this.onChange(event);
  };

  render() {
    const {
        preOrders = [],
        show,
        onHide,
        viewOrdersAffected,
        data = {},
      } = this.props,
      { isSubmitting = false } = this.state,
      { is_accepting_in_advanced_orders = false } = data;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>Prep Time Default Settings</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>

        {/* <Form onSubmit={preOrders.length < 1 ? this.handleOnSubmit : null}> */}
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            {/* {preOrders.length > 0 && (
              <div
                className="alert alert-custom alert-notice alert-light-warning fade show mb-5  animate__animated animate__fadeIn"
                role="alert"
              >
                <div className="alert-text">
                  There are {preOrders.length} upcoming orders, you can't change
                  this settings for now. , if you wish to proceed on the change
                  please contact
                  <a
                    href={`mailto:hello@pickup.ph`}
                    target={"_blank"}
                    className="navi-item"
                  >
                    <span className="navi-link p-0 pb-2">
                      <span className="navi-text text-muted text-hover-primary">
                        {" "}
                        hello@pickup.ph
                      </span>
                    </span>
                  </a>
                </div>
                <KTLink text={<>View Orders</>} onClick={viewOrdersAffected} />
              </div>
            )} */}

            <FormItem
              normal
              label={
                <>
                  <span>Upcoming Queue to Order Queue Timer</span>
                </>
              }
              name={"pre_order_to_order_queue_timer"}
              inputProps={{
                name: "pre_order_to_order_queue_timer",
                placeholder: "0",
                value: this.state.values["pre_order_to_order_queue_timer"],
                min: 1,
                max: 1440,
              }}
              inputActions={inputActions}
              actions={actions}
              type={"number"}
              helperText={
                "How many minutes before the pickup time should the pre-order move to the order queue? (Recommendation: 20 minutes)"
              }
            />
            <FormItem
              normal
              label={
                <>
                  <span>
                    How many minutes before opening hours can a customer order?
                  </span>
                </>
              }
              name={"minutes_allow_order_before_opening"}
              inputProps={{
                name: "minutes_allow_order_before_opening",
                placeholder: "0",
                value: this.state.values["minutes_allow_order_before_opening"],
                disabled: is_accepting_in_advanced_orders ? "disabled" : "",
              }}
              inputActions={inputActions}
              actions={actions}
              type={"number"}
              helperText={
                "Set your preferred minutes before opening (Recommendation: 30 minutes)"
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              // disabled={preOrders.length > 0 ? true : isSubmitting}
              disabled={isSubmitting}
              className={GetSubmitClassList(
                isSubmitting,
                `btn btn-primary font-weight-bold`,
              )}
            >
              {isSubmitting ? "Saving changes..." : "Save changes"}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
