import axios from "axios";
import core from "../../../vendors/core-api/";
import { setStorage } from "../../../../_metronic/_helpers";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export function login(username, password, webview_mode = false) {
  return new Promise((resolve, reject) => {
    core("iam")
      .get()
      .login(username, password, webview_mode, (err, data) => {
        if (err) reject(err);
        else {
          const {
            data: { token = "" },
          } = data;
          core().setAuthToken(token);
          setStorage("PRK", token);
          resolve(data);
        }
      });
  });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return core("iam").get().requestPassword(email);
}

export function getUserByToken() {
  return core("iam").get().me();
}
