import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PRIMARY_COLOR } from "../../constants";
const MySwal = withReactContent(Swal);

export default function ApplyToAllField({
  changes,
  storeCount,
  setApplyToAll,
  method,
}) {
  storeCount--;
  const storeCountText =
    storeCount === 1
      ? `${storeCount} other store`
      : `${storeCount} other stores`;
  const [applied, setApplied] = useState(false);

  const toggleApplied = (bool = "") => {
    if (!bool) {
      setApplied(!applied);
    } else {
      setApplied(bool);
    }
  };

  const replaceChars = (name) => {
    let newName = name;
    if (name === "item_limit_per_day") {
      newName = "Daily Limit";
    } else if (name === "item_limit_per_time_slot_batch") {
      newName = "Per Time Slot Limit";
    } else if (name === "visible") {
      newName = "Show in store";
    } else if (name === "isVisibleOnOffDate") {
      newName = "Show in store even if it's unavailable for today";
    } else if (name === "isUnavailableToday") {
      newName = "Mark as unavailable for today";
    } else if (name === "isMealPlan") {
      newName = "Meal Plan Item";
    } else if (name === "isBulk") {
      newName = "Bulk Item";
    }
    newName = newName.replaceAll("_", " ");
    return newName;
  };

  useEffect(() => {
    const showConfirmation = () => {
      let changesList = "";
      for (const { name, value } of changes) {
        changesList += `<li><strong style="text-transform: capitalize;">${replaceChars(
          name,
        )}:</strong> <span style="text-transform: capitalize;">${value}</span></li>`;
      }

      MySwal.fire({
        icon: "info",
        title: `${storeCountText} affected`,
        html: changesList
          ? method === "edit"
            ? `
                            <p>By checking this option, you are applying the following changes to all of your stores:</p>
                            <ul style="text-align: left;">
                                ${changesList}
                            </ul>
                        `
            : `<p>By checking this option, you are adding this item to all of your stores.</p>`
          : "",
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Yes, apply",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result) {
          if (result.isConfirmed) {
            toggleApplied(true);
          } else {
            toggleApplied(false);
          }
        } else {
          toggleApplied(false);
        }
      });
    };

    if (applied) {
      showConfirmation();
      setApplyToAll(true);
    } else {
      setApplyToAll(false);
    }
  }, [applied]);

  useEffect(() => {
    if (applied) {
      setApplied(false);
    }
  }, [changes]);

  return storeCount > 0 && changes.length > 0 ? (
    <div>
      <div>
        <div className="d-flex">
          <div>
            <span className="switch switch-outline switch-icon switch-primary">
              <label>
                <input
                  type="checkbox"
                  name="applyToAll"
                  checked={applied}
                  onChange={() => {
                    toggleApplied();
                  }}
                />
                <span></span>
              </label>
            </span>
          </div>
          <div className="pt-2 ml-2">
            <label>
              {method === "add" ? "Add item" : "Apply changes"} to all (
              {storeCountText})
            </label>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
