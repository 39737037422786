import React from "react";
import "./PacmanLoading.css";

const PacmanLoading = () => {
  return (
    <>
      <div className="loadingio-spinner-bean-eater-ba63ynqescj">
        <div className="ldio-48qah01n87u">
          <div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PacmanLoading;
