import React from "react";
import { AnimateDiv } from "../../components/Base";
import { Card } from "react-bootstrap";
import BrandWebsite from "../../partials/BrandWebsite";
import PageCenter from "../../components/PageCenter";
import TitleHelmet from "../../partials/TitleHelmet";
import { Redirect } from "react-router-dom";
import serviceWorker from "../../modules/Firebase/serviceWorkerInit";
import qs from "qs";
import { setTheme } from "../../theme";
import axios from "axios";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const instance = axios.create({});
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => Promise.reject(err),
);

serviceWorker();

export default function ExternalTrackPage(props) {
  const { token = "" } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  return <MainComponent token={token} {...props} />;
}

class MainComponent extends BrandWebsite {
  async componentDidMount() {
    const { token = "" } = this.props;
    if (token !== "") {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ page: "trackingPage", reservation_id: token }),
        );
      } else if (window !== window.parent) {
        window.parent.postMessage(
          JSON.stringify({ page: "trackingPage", reservation_id: token }),
        );
      }
    }
  }

  async getData() {
    this.initiateData();
  }

  initiateData() {
    const { token = "" } = this.props,
      baseURL = this.api.getBaseURL(),
      { brand_url = "" } = this.state;
    this._isMounted && this.setState({ data_status: "fetching" });

    this.setState({
      //   data: data.data,
      data_status: "fetched",
    });
    instance
      .post(
        `/branded-website/brands/external-track`,
        {
          data: {
            reservation_id: token,
          },
        },
        {
          headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
          baseURL,
        },
      )
      .then(async (data) => {
        const { order = {} } = data.data,
          { brand = {} } = order,
          { website_theme = "" } = brand;

        const state = {
          website_theme: setTheme(
            website_theme !== "" ? JSON.parse(website_theme) : {},
          ),
        };

        this._isMounted &&
          this.setState({
            renderModals: false,
            data: data.data,
            data_status: "fetched",
            ...state,
          });
        // this.runMinuteChecker(data);
        this.setTitle();
        // console.log("track data: ", data);
      })
      .catch((error) => {
        console.log(error);
        this._isMounted && this.setState({ error: error, showSwalError: true });
        // this.handleOnDataFetchError();
      });
  }
  render = () => {
    const {
        data = {},
        data_status = "",
        showHomepage = true,
        showOrderDetailsPage = false,
        showMealPlanSchedulePage = false,
        showShareModal = false,
        onCopyToClipboardSuccessMessage = "",
        website_theme = {},
      } = this.state,
      //   isLoading = data_status !== "fetched";
      isLoading = false;

    const { highlighted_text_color = "" } = website_theme;

    return (
      <>
        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} push={true} />
        ) : (
          <></>
        )}

        <TitleHelmet title={this.state.title} showWebsiteName={false} />
        {isLoading ? (
          <LayoutSplashScreen />
        ) : (
          <PageCenter>
            <AnimateDiv>
              <Card
                className="card-border-top-primary card-custom"
                style={{ borderTopColor: highlighted_text_color }}
              >
                Success Payment
              </Card>
            </AnimateDiv>
          </PageCenter>
        )}
      </>
    );
  };
}
