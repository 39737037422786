/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../_metronic/layout";
import { toPesoAmount } from "../../helpers";

export function StatsWidget11(props) {
  const {
    id = "",
    className = "",
    header = "",
    customOptions = {},
    displayData = "",
    dateRange = "day",
    height = "100%",
    theme = "primary",
    series = [],
    seriesName = "",
    categories = [],
    hasDropDown = false,
    dateStrings = [],
    onDateRangeClick,
    comingSoon = false,
    // children
  } = props;

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500",
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200",
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300",
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success",
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success",
      ),

      colorsThemeBaseInfo: objectPath.get(
        uiService.config,
        "js.colors.theme.base.info",
      ),
      colorsThemeLightInfo: objectPath.get(
        uiService.config,
        "js.colors.theme.light.info",
      ),
      colorsThemeBasePrimary: objectPath.get(
        uiService.config,
        "js.colors.theme.base.primary",
      ),
      colorsThemeLightPrimary: objectPath.get(
        uiService.config,
        "js.colors.theme.light.primary",
      ),

      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById(id);

    if (!element) {
      return;
    }

    const options = getChartOption(
      {
        layoutProps,
        displayData,
        series,
        customOptions,
        height,
        categories,
        seriesName,
        dateRange,
      },
      theme,
    );
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [
    layoutProps,
    id,
    theme,
    series,
    categories,
    seriesName,
    dateRange,
    height,
    customOptions,
    displayData,
  ]);

  return (
    // <div className={`card card-custom ${className}`}>
    <div className={`card card-custom ${className}`}>
      <div className=" d-flex flex-column p-0">
        <div className="d-flex flex-column align-items-center justify-content-between px-8 pt-8 pb-0 flex-grow-1">
          {hasDropDown ? (
            <>
              <h4 className="d-flex align-items-center w-100 text-left">
                {header}
                {hasDropDown ? (
                  <Dropdown className="d-inline-block ml-2">
                    <Dropdown.Toggle
                      variant="white"
                      className="border-1 border-dark-75 font-weight-bold"
                    >
                      {dateStrings.find((el) => el.dateRange === dateRange)
                        .current || "Today"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu onSelect={onDateRangeClick}>
                      {dateStrings.map((item) => (
                        <Dropdown.Item
                          key={item.dateRange}
                          eventKey={item.dateRange}
                          onSelect={onDateRangeClick}
                        >
                          {item.current}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  dateRange
                )}
              </h4>
            </>
          ) : (
            <h4 className="d-block w-100 text-left">{header}</h4>
          )}

          {/* <h3 className={`text-${theme} d-block mt-4 w-100 pr-8 text-center`}>{toPesoAmount(displayData)}</h3> */}
        </div>
        {comingSoon ? (
          <div className="d-flex justify-content-center align-items-center">
            <span className="label label-sm label-warning label-pill label-inline text-uppercase d-inline-block mx-auto mt-12 mb-4">
              Coming Soon
            </span>
          </div>
        ) : (
          <>
            <div
              id={id}
              className="card-rounded-bottom mb-4"
              style={{ height: "150px" }}
            ></div>
            <span
              className="mx-auto mb-4"
              style={{
                marginTop: "-2rem",
                fontSize: ".75rem",
              }}
            >
              Hour
            </span>
          </>
        )}
      </div>
    </div>
  );
}

function getChartOption(
  {
    header = "",
    layoutProps = {},
    displayData = "",
    customOptions = {},
    series = [],
    seriesName = "",
    categories = [],
    height = 350,
    dateRange = "day",
  },
  theme = "primary",
) {
  const options = {
    series: series,
    chart: {
      height: height,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    title: {
      text: toPesoAmount(displayData || 0),
      align: "center",
      margin: 10,
      offsetX: 0,
      offsetY: 40,
      floating: false,
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: undefined,
        // color:  '#FFA800'
        color: "#14C49B",
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "right",
      offsetY: -55,
      offsetX: -55,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      categories: categories,
      position: "bottom",
      offsetX: 0,
      offsetY: 0,
      floating: false,
      labels: {
        show: true,
        style: {
          color: undefined,
          fontSize: "8px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          // cssClass: '',
        },
        hideOverlappingLabels: true,
        rotateAlways: false,
        rotate: 0,
      },
    },
    yaxis: {
      title: {
        text: seriesName,
        style: {
          color: undefined,
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-title",
        },
      },
      labels: {
        show: true,
        style: {
          color: undefined,
          fontSize: "9px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          // cssClass: '',
        },
        hideOverlappingLabels: true,
        rotateAlways: false,
        rotate: 0,
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },

    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "round",
      width: 0,
    },

    fill: {
      opacity: 0.5,
      type: "solid",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 100],
        colorStops: [],
      },
    },
  };

  return { ...options, ...customOptions };
}
