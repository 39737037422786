import React, { useEffect, useState } from "react";
import { Card, Col } from "react-bootstrap";
import { Label } from "../../../components/Base";
import { isEmpty } from "lodash";

export const ServiceChargeContainer = ({
  onToggleSwitch,
  serviceCharge = {},
}) => {
  const [selected, setSelected] = useState(false);
  const [selectedServiceCharge, setSelectedServiceCharge] =
    useState("amount_charge");
  const [value, setValue] = useState("0");

  useEffect(() => {
    if (!isEmpty(serviceCharge)) {
      setSelected(true);
      setSelectedServiceCharge(serviceCharge.type);
      setValue(serviceCharge.value);
    }
  }, [serviceCharge]);

  const handleToggleSwitch = (
    checked = false,
    type = "amount_charge",
    value = "0",
  ) => {
    setSelected(checked);
    setSelectedServiceCharge(checked ? type : "amount_charge");
    setValue(checked ? value : 0);
    onToggleSwitch(checked, type, value);
  };

  return (
    <>
      <Card.Title className="mb-4">
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex flex-row align-items-center justify-content-space-between ml-8 mt-8 mr-8 mb-0">
            <h3 className="font-weight-bolder pb-0">
              <i className="fas fa-percent mr-2" style={{ color: "#14C49B" }} />
              Service Charge
            </h3>
            <div className="d-flex align-items-center">
              <span className="switch switch-outline switch-icon switch-primary switch-md">
                <label>
                  <input
                    checked={selected}
                    type="checkbox"
                    name="isServiceChargeActive"
                    onChange={(e) => {
                      handleToggleSwitch(
                        e.target.checked,
                        selectedServiceCharge,
                        value,
                      );
                      setSelected(!selected);
                    }}
                  />
                  <span></span>
                </label>
              </span>
            </div>
          </div>
        </div>
      </Card.Title>
      {selected && (
        <Card.Body className="px-8 pt-0">
          <Col className="p-0">
            <Label text="Charge by" style={{ marginBottom: "-0.5px" }} />
            <div>
              <div className="btn-group w-100" role="group">
                <button
                  type="button"
                  className={`btn btn-sm btn-${
                    selectedServiceCharge === "amount_charge"
                      ? "primary"
                      : "outline-primary"
                  } font-weight-bold `}
                  value={"amount_charge"}
                  onClick={(e) =>
                    handleToggleSwitch(selected, e.target.value, value)
                  }
                >
                  Amount
                </button>
                <button
                  type="button"
                  className={`btn btn-sm btn-${
                    selectedServiceCharge === "percent_charge"
                      ? "primary"
                      : "outline-primary"
                  } font-weight-bold`}
                  value={"percent_charge"}
                  onClick={(e) =>
                    handleToggleSwitch(selected, e.target.value, value)
                  }
                >
                  Percent
                </button>
              </div>
            </div>
          </Col>
          <Col className="px-0 py-4">
            <Label text="Value" style={{ marginBottom: "-0.5px" }} />
            <div>
              <input
                type="number"
                name="service_charge"
                value={value}
                className="form-control"
                onChange={(e) => {
                  e.preventDefault();
                  handleToggleSwitch(
                    selected,
                    selectedServiceCharge,
                    e.target.value,
                  );
                }}
              />
            </div>
          </Col>
        </Card.Body>
      )}
    </>
  );
};
