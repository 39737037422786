import React, { useMemo } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl, checkIsActive } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";

const HIDE_SAMPLE = true;

export function Topbar(props) {
  const {
    type = "admin",
    preview_token = "",
    website_theme = {},
    store_url = ""
  } = props;
  const uiService = useHtmlClassService();

  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  const {
    top_navigation_button_color = "",
    top_navigation_button_text_color = "",
  } = website_theme;

  const textColor = { color: top_navigation_button_text_color },
    borderColor = { borderColor: top_navigation_button_color };

  const store_prefix = store_url !== "" ? `/${store_url}` : "";

  return (
    <div className={`topbar`}>
      {HIDE_SAMPLE ? (
        <></>
      ) : (
        <>
          {layoutProps.viewSearchDisplay && <SearchDropdown />}

          {layoutProps.viewNotificationsDisplay && (
            <UserNotificationsDropdown />
          )}

          {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />}

          {layoutProps.viewCartDisplay && <MyCartDropdown />}

          {layoutProps.viewQuickPanelDisplay && (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
            >
              <div
                className="topbar-item"
                data-toggle="tooltip"
                title="Quick panel"
                data-placement="right"
              >
                <div
                  className="btn btn-icon btn-clean btn-lg mr-1"
                  id="kt_quick_panel_toggle"
                >
                  <span className="svg-icon svg-icon-xl svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Layout/Layout-4-blocks.svg"
                      )}
                    />
                  </span>
                </div>
              </div>
            </OverlayTrigger>
          )}

          {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}
        </>
      )}

      {type === "admin" ? (
        <>{layoutProps.viewUserDisplay && <QuickUserToggler />}</>
      ) : (
        <></>
      )}

      {type === "branded-website" ? (
        <>
          <div className="topbar-item">
            <div className="header-menu header-menu-layout-default">
              <ul className="menu-nav">
                {props.showMenuItems ? (
                  <>
                    <li
                      className={`menu-item menu-item-rel ${getMenuItemActive(
                        "/about"
                      )}`}
                    >
                      <NavLink
                        className="menu-link menu-toggle"
                        to={
                           preview_token !== ""
                            ? `${store_prefix}/about?preview_token=${preview_token}`
                            : `${store_prefix}/about`
                        }
                        style={{ 
                          border: `1px solid ${borderColor.borderColor}`,
                          cursor:  "pointer",
                        }}
                      >
                        <span className="menu-text" style={textColor}>
                          About
                        </span>
                        <i className="menu-arrow"></i>
                      </NavLink>
                    </li>
                    <li
                      className={`menu-item menu-item-rel ${getMenuItemActive(
                        "/menu"
                      )}`}
                    >
                      <NavLink
                        className="menu-link menu-toggle"
                        to={
                          preview_token !== ""
                            ? `${store_prefix}/menu?preview_token=${preview_token}`
                            : `${store_prefix}/menu`
                        }
                        style={{ 
                          border: `1px solid ${borderColor.borderColor}`, 
                          cursor: "pointer",
                        }}
                      >
                        <span className="menu-text" style={textColor}>
                          Menu
                        </span>
                        <i className="menu-arrow"></i>
                      </NavLink>
                    </li>
                    <li className="menu-item menu-item-rel">
                      <div
                        className="menu-link menu-toggle menu-toggle--cart"
                        onClick={props.onClickCart}
                        style={{
                          ...(borderColor),
                          cursor: "pointer",
                        }}
                      >
                        {/* <span className="menu-text">Cart</span> */}
                        <span className="menu-text" style={textColor}>
                          Basket
                        </span>
                        {props.cart_counter > 0 ? (
                          <span className="label label-danger ml-2">
                            {props.cart_counter}
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
