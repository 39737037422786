import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import ProSourceForm from "../components/ProSourceForm";
import { Modal, Form } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";

import { DB_DATE_FORMAT } from "../constants";

class MealPlanListModal extends ProSourceForm {
  constructor(props) {
    super(props);
    this.state = { url: "" };
  }

  handleOnClickItem = (itemUrl, newHistory) => {
    let { url } = this.state;
    url = itemUrl;
    console.log("click menu plan item url: ", itemUrl);
    this.setState({ url });
    console.log("click menu plan item state: ", this.state);
    newHistory.push(itemUrl);
    // history.push('/orders/61ef69e31842813998ed2910')
  };

  render() {
    const { show = false, onHide, mealPlanOrderData } = this.props;
    // console.log('delivery address modal props: ', this.props)
    const data = mealPlanOrderData.sort(
      (a, b) => Number(a.meal_plan_index) - Number(b.meal_plan_index),
    );
    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>Meal Plan List</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form>
          <Modal.Body>
            {data.map((item) => {
              return (
                <MealPlanItemCard
                  key={item._id}
                  id={item._id}
                  transaction_number={item.transaction_number}
                  item_image_url={item.order_details[0].item.image_url}
                  item_name={item.order_details[0].item.name}
                  extras={item.order_details[0].extras}
                  order_date={item.order_date}
                  status={item.status}
                  url={this.state.url}
                  handleOnClickItem={this.handleOnClickItem}
                  history={this.props.history}
                />
              );
            })}
          </Modal.Body>
        </Form>
      </Modal>
    );
  }
}

const MealPlanItemCard = (props) => {
  // const history = useHistory();
  // history.push('/')
  const itemUrl = `/orders/${props.id}`;
  // console.log("mealplanlistmodal mealplanitemcard url: ", url)
  return (
    <div
      style={{ width: "100%" }}
      className="d-flex flex-column w-100"
      // onClick={() => props.handleOnClickItem(itemUrl, props.history)}
      // onClick={() => props.history.push(itemUrl)}
    >
      {/* <Link to={itemUrl} > */}
      {/* <a href={itemUrl} className="text-dark-75 mb-2"> */}
      <div className="d-flex flex-row align-items-center justify-content-around mt-4 mb-4 ml-2">
        <div className="item-photo-wrapper symbol symbol-65 symbol-2by3 flex-shrink-0 mr-4">
          <div
            className="symbol-label"
            style={{ backgroundImage: `url(${props.item_image_url})` }}
          ></div>
        </div>
        <div className="d-flex flex-row flex-fill justify-content-around mt-0">
          <div className="flex-fill align-self-start">
            <div className="font-weight-boldest">
              <a href={itemUrl} className="text-dark-75 mb-2">
                <span
                  style={{ textDecoration: "underline" }}
                  className="va-middle"
                >{`Order # ${props.transaction_number}`}</span>
              </a>
            </div>
            <div className="font-weight-bold">
              <span className="va-middle mr-2">
                <i className="fa fa-calendar-check font-weight-bold" />
              </span>
              <span className="va-middle">
                {moment(props.order_date, DB_DATE_FORMAT).format(
                  "ddd, MMM D, YYYY",
                )}
              </span>
            </div>
            <div className="font-weight-bolder text-uppercase">
              <span className="va-middle">{`${props.item_name}`}</span>
            </div>
            {props.extras ? (
              <div>
                {props.extras.map((item) => {
                  return (
                    <div className="font-weight-bold text-uppercase text-muted">
                      <span className="va-middle">{`+ ${item.extra_id.name}`}</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div
            // style={{color: props.status==="completed"? "#9E50EF": "red"}}
            style={{
              color: props.status.includes("completed") ? "#9E50EF" : "red",
            }}
            className="d-flex flex-row align-items-center align-self-center pr-10 text-uppercase"
          >
            <div className="font-weight-bold">
              {/* <span className="va-middle">{`${props.status==="completed"?"completed":"not completed"}`}</span> */}
              <span className="va-middle">{`${
                props.status.includes("completed")
                  ? "completed"
                  : "not completed"
              }`}</span>
            </div>
          </div>
        </div>
      </div>
      {/* </a> */}
      {/* </Link> */}
    </div>
  );
};

export default withRouter(MealPlanListModal);
