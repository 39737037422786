import React from "react";
import { Button } from "react-bootstrap";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const isSizeTooMuch = (file) => {
  return file.size / 1000 > 200;
};

export default class FileUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      fileName: "",
      mountFile: true,
      name: props.name,
      isLoading: false,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClickUpload = this.handleOnClickUpload.bind(this);
    this.handleOnClickRemove = this.handleOnClickRemove.bind(this);
    this.handleOnError = this.handleOnError.bind(this);
    this.dataURItoBlob = this.dataURItoBlob.bind(this);

    this.fileUploadRef = React.createRef();
  }

  handleOnClickUpload = (event) => {
    event.preventDefault();

    this.setState({ mountFile: false });

    setTimeout(() => {
      this.setState({ mountFile: true });
      this.fileUploadRef.current.click();
    }, 50);
  };

  dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  handleOnChange = async (event) => {
    const { name = "", entityName = "" } = this.props;
    const file = event.target.files[0];
    let uploadName = `${entityName}_${name}`;
    // uploadName= uploadName.replace(' ', '_');
    uploadName = uploadName.split(" ").join("_");

    if (file) {
      this.setState(
        {
          file: null,
          fileName: "",
          loading: true,
        },
        async () => {
          await this.props.uploadFile(
            file,
            async (filePath) => {
              await this.props.inputActions.onChange({
                target: { value: filePath, name },
              });
              this.setState({ file, fileName: file.name, loading: false });
            },
            uploadName,
          );
        },
      );
    } else {
      this.handleOnError("unsupported-file-extension");
    }
  };

  handleOnClickRemove = () => {
    this.setState({ preview: "", file: null, fileName: "" });
    this.props.onRemove && this.props.onRemove();
    this.props.inputActions.onChange({
      target: { value: "", name: this.props.name },
    });
  };

  handleOnError = (err) => {
    this.props.onError && this.props.onError(err);
  };

  render() {
    const { name = "", header = "" } = this.props;
    // const isTouched = this.props.actions.isTouched(this.props.name);
    // const error = this.props.actions.handleFeedbackError(this.props.name, "error-text"), hasError = error !== undefined;
    const error = this.props.actions.handleFeedbackError(
      this.props.name,
      "error-text",
    );

    return (
      <>
        <div style={{ marginBottom: "50px" }}>
          <div className="d-none">
            {this.state.mountFile ? (
              <input
                type="file"
                name={name}
                ref={this.fileUploadRef}
                onChange={this.handleOnChange}
              />
            ) : (
              <> </>
            )}
          </div>

          <h5 className="mt-8">{header}</h5>
          <Button
            variant="primary"
            onClick={this.handleOnClickUpload}
            className="btn-sm"
          >
            Upload
          </Button>

          {this.state.loading && (
            <span className="ml-4 spinner spinner-sm spinner-primary" />
          )}

          {this.state.fileName && (
            <span className="text-dark-75 ml-2">
              {this.state.fileName}
              {/* <button className="btn btn-white border border-danger text-danger rounded-circle p-0">x</button> */}
              <button
                // className="btn btn-danger py-1 px-2 bg-white border border-danger text-danger font-size-xs rounded ml-2"
                className="btn text-danger font-size-xs rounded ml-1"
                onClick={this.handleOnClickRemove}
                disabled={this.state.loading}
              >
                <i className="far fa-window-close icon-xl text-danger" />
              </button>
            </span>
          )}

          {this.props.label && this.props.label}
          <div className="fv-plugins-message-container">
            {error !== "" ? (
              <div className="fv-help-block">{error}</div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
}
