import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FormItem, InfoAlertHelper } from "../components/Base";
import ProSourceForm from "../components/ProSourceForm";
import { GetSubmitClassList } from "../helpers";

export default class EditPaymentGatewayModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { data = {} } = this.props,
      { keys = {} } = data,
      { public_key = "", secret_key = "" } = keys;

    this.state = {
      values: {
        public_key: public_key.trim(),
        secret_key: secret_key.trim(),
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };
  }

  onSubmit = (values, setSubmitting) => {
    values["public_key"] = values["public_key"].trim();
    values["secret_key"] = values["secret_key"].trim();
    this.props.onSubmit(values, setSubmitting);
  };

  componentDidUpdate() {
    // console.log("editpaymentgatewaymodal state: ", this.state)
    // console.log("editpaymentgatewaymodal props: ", this.props)
  }

  render() {
    const { show, onHide } = this.props;

    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>Edit PayMongo Keys</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            <InfoAlertHelper
              text={
                "Your PayMongo keys are given to you by PayMongo and is located on your PayMongo dashboard. You can only get your live API keys once your account is activated."
              }
            />

            <FormItem
              normal
              label={"Public Key"}
              name={"public_key"}
              inputProps={{
                name: "public_key",
                placeholder: "Public Key",
                value: this.state.values["public_key"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
            />

            <FormItem
              normal
              label={"Secret Key"}
              name={"secret_key"}
              inputProps={{
                name: "secret_key",
                placeholder: "Secret Key",
                value: this.state.values["secret_key"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="primary"
              className={GetSubmitClassList(this.state.isSubmitting, "")}
              disabled={
                this.state.values.public_key == "" ||
                this.state.values.secret_key == ""
                  ? true
                  : this.state.isSubmitting
                  ? "disabled"
                  : null
              }
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
