import React from "react";
import { Switch } from "react-router";
import { ContentRoute } from "../../../_metronic/layout";
// import PerUser from "../pages/client-admin/PerUser";
// import NewUserPage from "../pages/client-admin/NewUserPage";
import PromosList from "./PromosList";
import NewPromoPage from "./NewPromoPage";
import PromoDetailsPage from "./PromoDetails";
import PromoCopyPage from "./PromoCopy";

export default function PromosDiv(props) {
  return (
    <>
      <Switch>
        <ContentRoute path={`${props.webview ? "/webview" : ""}/promos/new`}>
          <NewPromoPage {...props} />
        </ContentRoute>
        <ContentRoute
          path={`${props.webview ? "/webview" : ""}/promos/:id/copy`}
        >
          <PromoCopyPage {...props} />
        </ContentRoute>
        <ContentRoute path={`${props.webview ? "/webview" : ""}/promos/:id`}>
          <PromoDetailsPage {...props} />
        </ContentRoute>
        <ContentRoute path={`${props.webview ? "/webview" : ""}/promos`}>
          <PromosList {...props} />
        </ContentRoute>
      </Switch>
    </>
  );
}
