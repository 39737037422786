import React from "react";
import "./styles.css";

export default function Group({ data }) {
  const brands = data.brands;

  return (
    <div>
      <br />
      <h3 className="text-center text-gray">Our Brands</h3>
      {brands.map((brand) =>
        brand.brand_url && brand.website_status === "public" ? (
          <div key={brand._id}>
            <div className="brand-logo-div-image-container">
              <a
                href={
                  brand.brand_url !== "preview"
                    ? `https://${brand.brand_url}${process.env.REACT_APP_DOMAIN}/`
                    : null
                }
                target="_blank"
              >
                <img
                  src={brand.brand_logo}
                  className="brand-logo-div-image"
                  alt=""
                />
              </a>
            </div>
            <hr />
          </div>
        ) : null,
      )}
      <br />
    </div>
  );
}
