import React, { Component } from "react";
import moment from "moment";
import { Row, Col, Dropdown } from "react-bootstrap";
import {
  AnimateDiv,
  HeaderText,
  KTLink,
  IconAlertAction,
} from "../../components/Base";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import { useSelector } from "react-redux";
import { generatePickupPhLink } from "../../helpers";

// import DateRangeDropdown from "../../components/dashboard/DateRangeDropdown";
import GroupDropdown from "../../components/dashboard/GroupDropdown";
import Row1 from "../../components/dashboard/Row1";
import Row2 from "../../components/dashboard/Row2";
import Row3 from "../../components/dashboard/Row3";
import { DB_DATE_FORMAT, DATERANGE_LIST, PRIMARY_COLOR } from "../../constants";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

export default function Page() {
  const { brand_url = "", mode = "" } = useParams(),
    { user } = useSelector((state) => state.auth);

  return (
    <MainComponent id={brand_url} user={user} webview={mode === "webview"} />
  );
}

class MainComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: true,
      redirectTo: "",
      brand_name: "Brands",
    };

    this.api = core("pu").get();

    this.getLoggedInUser = this.getLoggedInUser.bind(this);
  }

  getLoggedInUser = (field = "fullname") => {
    const { user = {} } = this.props;
    if (field === "fullname") {
      return user.first_name + " " + user.last_name;
    } else {
      return user[field];
    }
  };

  render() {
    const actions = {
      getLoggedInUser: this.getLoggedInUser,
      setBrandName: (brand_name = "") => {
        this.setState({ brand_name });
      },
      setSplashScreen: (showSplashScreen = true) => {
        this.setState({ showSplashScreen });
      },
      setRedirectTo: (redirectTo = "") => {
        this.setState({ redirectTo });
      },
    };

    return (
      <>
        <TitleHelmet title={this.state.brand_name} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <BrandComponent
          webview={this.props.webview}
          brand={this.props.id}
          store={this.props.user.store}
          api={this.api}
          actions={actions}
          role={this.props.user.role}
          brands={this.props.user.brands}
        />
      </>
    );
  }
}

class BrandComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.data_url = "/brand/u/" + this.props.brand;
    this.api = this.props.api;
    this.statCardQuery = "day";

    this.handleDateRangeClick.bind(this);
    this.handleChangeSelectedBrand.bind(this);
  }
  handleDateRangeClick = async (e, date) => {
    let statCardDataRange;
    const dropdownText = e.currentTarget.textContent || "day";

    if (
      dropdownText === "This Week" ||
      dropdownText === "Last Week" ||
      dropdownText === "Next Week"
    ) {
      statCardDataRange = "week";
    } else if (dropdownText === "This Month" || dropdownText === "Last Month") {
      statCardDataRange = "month";
    } else if (dropdownText === "This Year" || dropdownText === "Last Year") {
      statCardDataRange = "year";
    } else if (dropdownText === "All-time") {
      statCardDataRange = "allTime";
    } else {
      statCardDataRange = "day";
    }

    this.setState({
      statCardQuery: statCardDataRange,
      data_status: "fetching",
      date,
    });

    setTimeout(() => {
      this.getData();
    }, 500);
  };

  handleChangeSelectedBrand = async (item) => {
    this.setState(
      {
        selectedBrand: item,
        data_status: "fetching",
      },
      () => {
        this.getData();
      },
    );
  };

  async getData() {
    this._isMounted && this.setState({ data_status: "fetching" });
    let date = this.state.date || moment().format(DB_DATE_FORMAT);
    let statCardRange = this.state.statCardQuery || "day";
    let view = this.state.selectedBrand || { id: "", name: "All" };

    const getBrandInfo = () => {
      return new Promise((resolve, reject) => {
        this.api
          .get(`/brand/u/${this.props.brand}`)
          .then((data) => resolve(data.data))
          .catch((err) => reject(err));
      });
    };

    const getStats = (
      rowNo,
      statCardRange = "day",
      date = moment().format(DB_DATE_FORMAT),
    ) => {
      return new Promise((resolve, reject) => {
        this.api
          .get(
            `/brand/u/${this.props.brand}/stats/${rowNo}?stat_cards=${statCardRange}&date=${date}&view=${view.id}&attr=${view.attr}`,
          )
          .then((data) => resolve(data.data))
          .catch((err) => reject(err));
      });
    };

    try {
      const brandInfo = await getBrandInfo();

      const rows = await Promise.all([
        getStats("header", statCardRange, date),
        getStats("row1", statCardRange, date),
      ]);
      const [header, row1] = rows;

      this._isMounted &&
        this.setState({
          data: {
            ...brandInfo,
            header,
            row1,
            stats: rows,
          },
          data_status: "fetched",
          order_fetch_status: "fetched",
        });

      this.handleOnDataFetched();
      if (this.use_display_data) {
        this.setDisplayData();
      }
      this.props.actions.setSplashScreen(false);
    } catch (error) {
      this._isMounted && this.setState({ error: error, showSwalError: true });
      this.handleOnDataFetchError();
    }
  }

  handlePublishBtn = () => {
    const { data } = this.state;
    const { website_status } = data;
    if (website_status === "public") {
      this.props.actions.setRedirectTo(
        "/brands/" + this.props.brand + "/settings/status",
      );
    } else {
      this.api
        .get(`/brand/${data._id}/is-valid-for-public-website`)
        .then(({ data }) => {
          const { result = "", valid = false } = data;
          if (result === "ok" && valid) {
            this.props.actions.setRedirectTo(
              "/brands/" + this.props.brand + "/settings/status",
            );
          } else {
            MySwal.fire({
              icon: "error",
              title: "Oooops!",
              text: "You can't set your brand website to public if you don't have any public stores.",
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "Go to Stores",
            }).then((result) => {
              if (result.value) {
                this.props.actions.setRedirectTo("/stores");
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    const {
        data_status = "",
        data = {},
        statCardQuery = "day",
        date = moment().format(DB_DATE_FORMAT),
        // selectedBrand = { name: "All", id: "", attr: "brand" },
        selectedBrand = {},
      } = this.state,
      isLoading = data_status !== "fetched";
    const { header, row1 } = data;

    function dropdownText(dateRange, date) {
      const isCurrent = moment(moment().format(DB_DATE_FORMAT)).isSame(
        date,
        dateRange,
      );
      const prefix = isCurrent ? "This" : "Last";

      // if(dateRange=== 'week')
      if (dateRange === "week") {
        if (
          moment(moment().add(1, "weeks").format(DB_DATE_FORMAT)).isSame(date)
        ) {
          return "Next Week";
        }

        return `${prefix} Week`;
      }
      if (dateRange === "month") return `${prefix} Month`;
      if (dateRange === "year") return `${prefix} Year`;
      if (dateRange === "allTime") return "All-time";
      // return 'Today'
      return `${isCurrent ? "Today" : "Yesterday"}`;
    }

    return (
      <>
        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          <AnimateDiv>
            {/* <div className="d-flex align-items-end"> */}
            <div className="d-flex align-items-stretch align-items-md-end flex-column flex-md-row">
              <HeaderText
                title={
                  <>
                    <span>{data.name} </span>{" "}
                    <span className="label label-primary label-inline font-weight-bolder text-uppercase va-middle ">
                      {data.website_status}
                    </span>
                  </>
                }
                subtitle={
                  <p>
                    View{" "}
                    <KTLink
                      text={generatePickupPhLink(data.brand_url, false)}
                      link={generatePickupPhLink(data.brand_url)}
                      external
                    />
                  </p>
                }
              />

              {/* <div className='d-flex align-items-center ml-auto rounded-top p-4 pb-0 bg-white'> */}
              <div className="d-flex justify-content-end p-4 pb-0 ml-0 ml-md-auto bg-white rounded-top">
                <GroupDropdown
                  items={header}
                  hasRollup={false}
                  attr={"brand"}
                  onSelectView={this.handleChangeSelectedBrand}
                  selectedView={selectedBrand.name ? selectedBrand : header[0]}
                  className={"mr-2"}
                />

                <Dropdown className="">
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {dropdownText(statCardQuery, date)}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {DATERANGE_LIST.map((item) => (
                      <Dropdown.Item
                        onClick={(e) => this.handleDateRangeClick(e, item.date)}
                        key={item.name}
                      >
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            {data.website_status === "private" ? (
              <Row>
                <Col>
                  <IconAlertAction
                    className="alert alert-custom alert-white alert-shadow fade show"
                    text={
                      <>
                        Hi {this.props.actions.getLoggedInUser("first_name")},{" "}
                        <KTLink
                          text={generatePickupPhLink(data.brand_url, false)}
                          link={generatePickupPhLink(data.brand_url)}
                          external
                        />{" "}
                        is not viewable to the public. Do you want to publish
                        your website now?
                      </>
                    }
                    variant="danger"
                    icon="General/Lock"
                    actionText={"publish"}
                    actionOnClick={this.handlePublishBtn}
                  />
                </Col>
              </Row>
            ) : (
              <></>
            )}

            <div className="container bg-white py-4 px4 rounded rounded-right-0 rounded-bottom">
              <Row1
                brands={this.props.brands}
                date={date}
                row1={row1}
                statCardQuery={statCardQuery}
                statLink={`${this.props.webview ? "/webview" : ""}/brands/${
                  this.props.brand
                }${
                  selectedBrand.attr === "store"
                    ? `/stores/${selectedBrand.id}`
                    : ""
                }/reports`}
                view={selectedBrand.id}
                attr={selectedBrand.attr}
              />

              <Row2
                params={{
                  date,
                  dateRange: statCardQuery,
                  id: selectedBrand.id,
                  attr: selectedBrand.attr,
                }}
                header={`Sold Items by ${
                  !selectedBrand.id ? "Brand" : "Category"
                }`}
              />
            </div>

            <Row3
              statCardQuery={statCardQuery}
              date={date}
              params={{
                date,
                dateRange: statCardQuery,
                id: selectedBrand.id,
                attr: selectedBrand.attr,
              }}
              dropdownText={dropdownText}
              selectedBrand={selectedBrand}
            />
          </AnimateDiv>
        )}
      </>
    );
  }
}
