import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { InfoTooltip, Label } from "../../components/Base";
import { checkIfCanUpdateField, GetSubmitClassList } from "../../helpers";
import { useSelector } from "react-redux";
import ht from "../../helpertexts";

function DisplayActiveStatus({ isActive }) {
  return <>{isActive ? <>active</> : <>inactive</>}</>;
}

export default function UserStatusForm(props) {
  const { user } = useSelector((state) => state.auth);
  return <UserStatusFormClass {...props} currentUser={user} />;
}

class UserStatusFormClass extends ProSourceForm {
  constructor(props) {
    super(props);

    const { user = {} } = this.props,
      { active = true } = user;

    this.state = {
      values: {
        active,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      checkingEmail: false,
    };
  }

  onChange = (event) => {
    const target = event.target,
      checked = target.checked,
      name = target.name,
      value = target.value;

    let { values = {}, errors = {} } = this.state,
      state = {};

    if (name === "is_accounting") {
      values[name] = checked;
      state.values = values;
    } else if (name === "email" && value !== "") {
      const valid = this.validateEmail(value);
      if (!valid) {
        errors.email = "Please enter a valid email";
      } else {
        delete errors.email;
      }

      state.errors = errors;
    }

    this.setState(state);
  };

  onBlur = (event) => {
    const target = event.target,
      name = target.name,
      value = target.value;
    let { errors = {} } = this.state;

    if (name === "email" && value !== "") {
      this.setState({ checkingEmail: true });
      this.props.api
        .post({
          url: "/external/users/validate",
          data: { email: value },
        })
        .then(({ data }) => {
          this.setState({ checkingEmail: false });
          const { existing = false } = data;
          if (existing) {
            errors["email"] = "Email is already taken.";
          } else {
            delete errors["email"];
          }

          this.setState({ errors });
        })
        .catch(() => {
          this.props.handleError();
        })
        .finally(() => {
          this.setState({ checkingEmail: false });
        });
    }
  };

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const { values = {} } = this.state,
      isActive = values.active;

    return (
      <>
        <Label text={<>User Status:{InfoTooltip(ht.users.status)}</>} />
        {checkIfCanUpdateField(this.props.user, this.props.currentUser) ? (
          <div>
            <button
              type="button"
              disabled={this.props.deleteLoading}
              className={GetSubmitClassList(
                this.props.deleteLoading,
                "btn btn-danger font-weight-bold px-9 py-4 my-3 mr-2",
              )}
              onClick={this.props.handleOnClickDeleteBtn}
            >
              <span>
                Mark as <DisplayActiveStatus isActive={!isActive} />
              </span>
            </button>
          </div>
        ) : (
          <div>
            This user is <DisplayActiveStatus isActive={isActive} />.
          </div>
        )}
      </>
    );
  }
}
