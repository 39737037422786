import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { Block, Label } from "../../components/Base";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { GetSubmitClassList } from "../../helpers";
import PreviewHomepage from "../../pages/branded-website/PreviewHomepage";
import rgbHex from "rgb-hex";
import hexRgb from "hex-rgb";
import ColorPicker from "../../components/ColorPicker";

export default class ThemeColorsForm extends ProSourceForm {
  constructor(props) {
    super(props);

    const { values = {} } = this.props,
      {
        text_color = "",
        highlighted_text_color = "",
        button_color = "",
        button_text_color = "",
        link_text_color = "",
        background_color = "",
        icon_color = "",
        top_navigation_button_color = "",
        top_navigation_button_text_color = "",
        category_header_text_color = "",
        homepage_icon_color = "",
        homepage_button_color = "",
        homepage_button_text_color = "",
        homepage_highlighted_text_color = "",
      } = values;

    this.state = {
      values: {
        text_color,
        highlighted_text_color,
        button_color,
        button_text_color,
        link_text_color,
        background_color,
        icon_color,
        top_navigation_button_color,
        top_navigation_button_text_color,
        category_header_text_color,
        homepage_icon_color,
        homepage_button_color,
        homepage_button_text_color,
        homepage_highlighted_text_color,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      selected_color: "#FFFFFF",
      background: hexRgb("#FFFFFF"),
    };

    this.validate = {};

    this.openColorPicker = this.openColorPicker.bind(this);
    this.setSelectedColorToValue = this.setSelectedColorToValue.bind(this);
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  openColorPicker = (selected_name) => {
    const { values = {} } = this.state;
    this.setState({ mountModal: false });
    const selectedColor =
      values[selected_name] === "" ? "#FFFFFF" : values[selected_name];
    const { red, green, blue, alpha } = hexRgb(selectedColor);

    setTimeout(() => {
      this.setState({
        mountModal: true,
        showModal: true,
        selected_name,
        selected_color: selectedColor,
        background: {
          a: alpha,
          r: red,
          g: green,
          b: blue,
        },
      });
    }, 50);
  };

  setSelectedColorToValue = (e) => {
    e.preventDefault();
    let { values = {}, selected_color = "", selected_name = "" } = this.state;
    values[selected_name] = selected_color;
    this.setState({ values, showModal: false });
  };

  handleChangeComplete = (color) => {
    this.setState({
      selected_color:
        color.rgb.a === 1
          ? color.hex
          : "#" + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a),
      background: color.rgb,
    });
  };

  onResetClick = () => {
    const theme_colors = [
      "text_color",
      "highlighted_text_color",
      "button_color",
      "button_text_color",
      "link_text_color",
      "background_color",
      "icon_color",
      "top_navigation_button_color",
      "top_navigation_button_text_color",
      "category_header_text_color",
      "homepage_icon_color",
      "homepage_button_color",
      "homepage_button_text_color",
      "homepage_highlighted_text_color",
    ];
    const reset_state = {};
    theme_colors.forEach((color) => {
      reset_state[color] = this.props.values[color]
        ? this.props.values[color]
        : "";
    });
    this.setState({
      values: reset_state,
    });
  };

  render() {
    const { showModal = false, mountModal = false, background } = this.state;
    const { data } = this.props;
    const { brand_url, group_type, group_url } = data;
    const isBrandWebsite = brand_url !== undefined;
    const isGroupSettings = group_type === "multi" && group_url !== undefined;

    return (
      <>
        {mountModal ? (
          <Modal
            size="sm"
            centered
            show={showModal}
            onHide={() => {
              this.setState({ showModal: false });
            }}
          >
            <Modal.Header>
              <Modal.Title>Pick Color</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <ColorPicker
                color={background}
                onChangeComplete={this.handleChangeComplete}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={this.setSelectedColorToValue}>
                Select
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <></>
        )}

        <Form onSubmit={this.handleOnSubmit}>
          {/* theme colours */}
          <Card className="card-custom gutter-b">
            {!isGroupSettings && (
              <>
                <Card.Header>
                  <Card.Title>Theme Colours</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    {/** former theme icon colour */}
                    <Col xs={12} md={6}>
                      <Block
                        content={
                          <div className="mv-1rem">
                            <Label
                              text={
                                <>
                                  <span className="va-middle text-capitalize">
                                    theme primary colour
                                  </span>{" "}
                                  <button
                                    type="button"
                                    className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                    onClick={() => {
                                      this.openColorPicker("icon_color");
                                    }}
                                  >
                                    <i className="far fa-edit text-primary" />
                                  </button>
                                </>
                              }
                            />
                            <div>
                              <div
                                style={{
                                  border: "1px black solid",
                                  marginRight: "2px",
                                  height: "1rem",
                                  width: "1rem",
                                  display: "inline-block",
                                  backgroundColor:
                                    this.state.values["icon_color"],
                                }}
                              ></div>
                              <div style={{ display: "inline-block" }}>
                                {this.state.values["icon_color"]}{" "}
                              </div>
                            </div>
                          </div>
                        }
                      />
                    </Col>
                    {/** brand - theme highlighted text colour */}
                    {isBrandWebsite && (
                      <Col xs={12} md={6}>
                        <Block
                          content={
                            <div className="mv-1rem">
                              <Label
                                text={
                                  <>
                                    <span className="va-middle text-capitalize">
                                      theme highlighted text colour
                                    </span>{" "}
                                    <button
                                      type="button"
                                      className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                      onClick={() => {
                                        this.openColorPicker(
                                          "highlighted_text_color",
                                        );
                                      }}
                                    >
                                      <i className="far fa-edit text-primary" />
                                    </button>
                                  </>
                                }
                              />
                              <div>
                                <div
                                  style={{
                                    border: "1px black solid",
                                    marginRight: "2px",
                                    height: "1rem",
                                    width: "1rem",
                                    display: "inline-block",
                                    backgroundColor:
                                      this.state.values[
                                        "highlighted_text_color"
                                      ],
                                  }}
                                ></div>
                                <div style={{ display: "inline-block" }}>
                                  {this.state.values["highlighted_text_color"]}{" "}
                                </div>
                              </div>
                            </div>
                          }
                        />
                      </Col>
                    )}
                    {/** theme button colour */}
                    {!isBrandWebsite && (
                      <Col xs={12} md={6}>
                        <Block
                          content={
                            <div className="mv-1rem">
                              <Label
                                text={
                                  <>
                                    <span className="va-middle text-capitalize">
                                      theme button colour
                                    </span>{" "}
                                    <button
                                      type="button"
                                      className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                      onClick={() => {
                                        this.openColorPicker("button_color");
                                      }}
                                    >
                                      <i className="far fa-edit text-primary" />
                                    </button>
                                  </>
                                }
                              />
                              <div>
                                <div
                                  style={{
                                    border: "1px black solid",
                                    marginRight: "2px",
                                    height: "1rem",
                                    width: "1rem",
                                    display: "inline-block",
                                    backgroundColor:
                                      this.state.values["button_color"],
                                  }}
                                ></div>
                                <div style={{ display: "inline-block" }}>
                                  {this.state.values["button_color"]}{" "}
                                </div>
                              </div>
                            </div>
                          }
                        />
                      </Col>
                    )}
                    {/** theme button text colour */}
                    {!isBrandWebsite && (
                      <Col xs={12} md={6}>
                        <Block
                          content={
                            <div className="mv-1rem">
                              <Label
                                text={
                                  <>
                                    <span className="va-middle text-capitalize">
                                      theme button text colour
                                    </span>{" "}
                                    <button
                                      type="button"
                                      className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                      onClick={() => {
                                        this.openColorPicker(
                                          "button_text_color",
                                        );
                                      }}
                                    >
                                      <i className="far fa-edit text-primary" />
                                    </button>
                                  </>
                                }
                              />
                              <div>
                                <div
                                  style={{
                                    border: "1px black solid",
                                    marginRight: "2px",
                                    height: "1rem",
                                    width: "1rem",
                                    display: "inline-block",
                                    backgroundColor:
                                      this.state.values["button_text_color"],
                                  }}
                                ></div>
                                <div style={{ display: "inline-block" }}>
                                  {this.state.values["button_text_color"]}{" "}
                                </div>
                              </div>
                            </div>
                          }
                        />
                      </Col>
                    )}
                    {isBrandWebsite && (
                      <>
                        {/** brand - theme button colour */}
                        <Col xs={12} md={6}>
                          <Block
                            content={
                              <div className="mv-1rem">
                                <Label
                                  text={
                                    <>
                                      <span className="va-middle text-capitalize">
                                        theme button colour
                                      </span>{" "}
                                      <button
                                        type="button"
                                        className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                        onClick={() => {
                                          this.openColorPicker("button_color");
                                        }}
                                      >
                                        <i className="far fa-edit text-primary" />
                                      </button>
                                    </>
                                  }
                                />
                                <div>
                                  <div
                                    style={{
                                      border: "1px black solid",
                                      marginRight: "2px",
                                      height: "1rem",
                                      width: "1rem",
                                      display: "inline-block",
                                      backgroundColor:
                                        this.state.values["button_color"],
                                    }}
                                  ></div>
                                  <div style={{ display: "inline-block" }}>
                                    {this.state.values["button_color"]}{" "}
                                  </div>
                                </div>
                              </div>
                            }
                          />
                        </Col>
                        {/** brand - theme button text colour */}
                        <Col xs={12} md={6}>
                          <Block
                            content={
                              <div className="mv-1rem">
                                <Label
                                  text={
                                    <>
                                      <span className="va-middle text-capitalize">
                                        theme button text colour
                                      </span>{" "}
                                      <button
                                        type="button"
                                        className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                        onClick={() => {
                                          this.openColorPicker(
                                            "button_text_color",
                                          );
                                        }}
                                      >
                                        <i className="far fa-edit text-primary" />
                                      </button>
                                    </>
                                  }
                                />
                                <div>
                                  <div
                                    style={{
                                      border: "1px black solid",
                                      marginRight: "2px",
                                      height: "1rem",
                                      width: "1rem",
                                      display: "inline-block",
                                      backgroundColor:
                                        this.state.values["button_text_color"],
                                    }}
                                  ></div>
                                  <div style={{ display: "inline-block" }}>
                                    {this.state.values["button_text_color"]}{" "}
                                  </div>
                                </div>
                              </div>
                            }
                          />
                        </Col>
                      </>
                    )}
                    {/** category theme colour */}
                    <Col xs={12} md={6}>
                      <Block
                        content={
                          <div className="mv-1rem">
                            <Label
                              text={
                                <>
                                  <span className="va-middle text-capitalize">
                                    category header colour
                                  </span>{" "}
                                  <button
                                    type="button"
                                    className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                    onClick={() => {
                                      this.openColorPicker(
                                        "category_header_text_color",
                                      );
                                    }}
                                  >
                                    <i className="far fa-edit text-primary" />
                                  </button>
                                </>
                              }
                            />
                            <div>
                              <div
                                style={{
                                  border: "1px black solid",
                                  marginRight: "2px",
                                  height: "1rem",
                                  width: "1rem",
                                  display: "inline-block",
                                  backgroundColor:
                                    this.state.values[
                                      "category_header_text_color"
                                    ],
                                }}
                              ></div>
                              <div style={{ display: "inline-block" }}>
                                {
                                  this.state.values[
                                    "category_header_text_color"
                                  ]
                                }{" "}
                              </div>
                            </div>
                          </div>
                        }
                      />
                    </Col>
                  </Row>
                  {/** theme button colour */}
                </Card.Body>
              </>
            )}
            {/* homepage colours */}
            <Card.Header>
              <Card.Title>Homepage Colours</Card.Title>
            </Card.Header>
            <Card.Body style={{ margin: 0 }}>
              <Row>
                <fieldset
                  className="homepage-preview"
                  style={{
                    border: "1.5px solid",
                    width: "100%",
                    height: `${isBrandWebsite ? "75vh" : "77vh"}`,
                  }}
                >
                  <legend
                    style={{ width: 100, textAlign: "center", fontSize: 16 }}
                  >
                    <Label
                      text={
                        <>
                          <span className="va-middle text-capitalize">
                            preview
                          </span>{" "}
                        </>
                      }
                    />
                  </legend>
                  <Col style={{ marginTop: -10, padding: 0 }}>
                    <Block
                      content={
                        <div>
                          <div>
                            <PreviewHomepage
                              {...this.props}
                              isPreviewHomePage
                              preview_theme={this.state.values}
                            />
                          </div>
                        </div>
                      }
                    />
                  </Col>
                </fieldset>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Block
                    content={
                      <div className="mv-1rem">
                        <Label
                          text={
                            <>
                              <span className="va-middle text-capitalize">
                                homepage text colour
                              </span>{" "}
                              <button
                                type="button"
                                className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                onClick={() => {
                                  this.openColorPicker("text_color");
                                }}
                              >
                                <i className="far fa-edit text-primary" />
                              </button>
                            </>
                          }
                        />
                        <div>
                          <div
                            style={{
                              border: "1px black solid",
                              marginRight: "2px",
                              height: "1rem",
                              width: "1rem",
                              display: "inline-block",
                              backgroundColor: this.state.values["text_color"],
                            }}
                          ></div>
                          <div style={{ display: "inline-block" }}>
                            {this.state.values["text_color"]}{" "}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Block
                    content={
                      <div className="mv-1rem">
                        <Label
                          text={
                            <>
                              <span className="va-middle text-capitalize">
                                homepage button colour
                              </span>{" "}
                              <button
                                type="button"
                                className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                onClick={() => {
                                  this.openColorPicker("homepage_button_color");
                                }}
                              >
                                <i className="far fa-edit text-primary" />
                              </button>
                            </>
                          }
                        />
                        <div>
                          <div
                            style={{
                              border: "1px black solid",
                              marginRight: "2px",
                              height: "1rem",
                              width: "1rem",
                              display: "inline-block",
                              backgroundColor:
                                this.state.values["homepage_button_color"],
                            }}
                          ></div>
                          <div style={{ display: "inline-block" }}>
                            {this.state.values["homepage_button_color"]}{" "}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Block
                    content={
                      <div className="mv-1rem">
                        <Label
                          text={
                            <>
                              <span className="va-middle text-capitalize">
                                homepage button text colour
                              </span>{" "}
                              <button
                                type="button"
                                className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                onClick={() => {
                                  this.openColorPicker(
                                    "homepage_button_text_color",
                                  );
                                }}
                              >
                                <i className="far fa-edit text-primary" />
                              </button>
                            </>
                          }
                        />
                        <div>
                          <div
                            style={{
                              border: "1px black solid",
                              marginRight: "2px",
                              height: "1rem",
                              width: "1rem",
                              display: "inline-block",
                              backgroundColor:
                                this.state.values["homepage_button_text_color"],
                            }}
                          ></div>
                          <div style={{ display: "inline-block" }}>
                            {this.state.values["homepage_button_text_color"]}{" "}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Col>
                {/* <Col xs={12} md={6}>
                  <Block
                    content={
                      <div className="mv-1rem">
                        <Label
                          text={
                            <>
                              <span className="va-middle text-capitalize">
                                homepage icon colour
                              </span>{" "}
                              <button
                                type="button"
                                className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                onClick={() => {
                                  this.openColorPicker("homepage_icon_color");
                                }}
                              >
                                <i className="far fa-edit text-primary" />
                              </button>
                            </>
                          }
                        />
                        <div>
                          <div
                            style={{
                              border: "1px black solid",
                              marginRight: "2px",
                              height: "1rem",
                              width: "1rem",
                              display: "inline-block",
                              backgroundColor:
                                this.state.values["homepage_icon_color"],
                            }}
                          ></div>
                          <div style={{ display: "inline-block" }}>
                            {this.state.values["homepage_icon_color"]}{" "}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Col> */}
                <Col xs={12} md={6}>
                  <Block
                    content={
                      <div className="mv-1rem">
                        <Label
                          text={
                            <>
                              <span className="va-middle text-capitalize">
                                homepage background colour
                              </span>{" "}
                              <button
                                type="button"
                                className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                onClick={() => {
                                  this.openColorPicker("background_color");
                                }}
                              >
                                <i className="far fa-edit text-primary" />
                              </button>
                            </>
                          }
                        />

                        <div>
                          <div
                            style={{
                              border: "1px black solid",
                              marginRight: "2px",
                              height: "1rem",
                              width: "1rem",
                              display: "inline-block",
                              backgroundColor:
                                this.state.values["background_color"],
                            }}
                          ></div>
                          <div style={{ display: "inline-block" }}>
                            {this.state.values["background_color"]}{" "}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Col>
                {/* <Col xs={12} md={6}>
                            <Block
                                content={
                                    <div className="mv-1rem">
                                        <Label text={<><span className="va-middle text-capitalize">homepage text color</span>  <button type="button" className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle" onClick={() => { this.openColorPicker("homepage_text_color")}}><i className="far fa-edit text-primary"/></button></>}/>
                                        <div>
                                            <div style={{ border: '1px black solid', marginRight: '2px', height: '1rem', width: '1rem', display: 'inline-block', backgroundColor: this.state.values["homepage_text_color"]}}></div><div style={{display: 'inline-block'}}>{this.state.values["homepage_text_color"]} </div>
                                        </div>
                                    </div>
                                }
                            />
                        </Col> */}
                <Col xs={12} md={6}>
                  <Block
                    content={
                      <div className="mv-1rem">
                        <Label
                          text={
                            <>
                              <span className="va-middle text-capitalize">
                                homepage highlighted text colour
                              </span>{" "}
                              <button
                                type="button"
                                className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                onClick={() => {
                                  this.openColorPicker(
                                    "homepage_highlighted_text_color",
                                  );
                                }}
                              >
                                <i className="far fa-edit text-primary" />
                              </button>
                            </>
                          }
                        />
                        <div>
                          <div
                            style={{
                              border: "1px black solid",
                              marginRight: "2px",
                              height: "1rem",
                              width: "1rem",
                              display: "inline-block",
                              backgroundColor:
                                this.state.values[
                                  "homepage_highlighted_text_color"
                                ],
                            }}
                          ></div>
                          <div style={{ display: "inline-block" }}>
                            {
                              this.state.values[
                                "homepage_highlighted_text_color"
                              ]
                            }{" "}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Block
                    content={
                      <div className="mv-1rem">
                        <Label
                          text={
                            <>
                              <span className="va-middle text-capitalize">
                                homepage icon colour
                              </span>{" "}
                              <button
                                type="button"
                                className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                onClick={() => {
                                  this.openColorPicker("homepage_icon_color");
                                }}
                              >
                                <i className="far fa-edit text-primary" />
                              </button>
                            </>
                          }
                        />
                        <div>
                          <div
                            style={{
                              border: "1px black solid",
                              marginRight: "2px",
                              height: "1rem",
                              width: "1rem",
                              display: "inline-block",
                              backgroundColor:
                                this.state.values["homepage_icon_color"],
                            }}
                          ></div>
                          <div style={{ display: "inline-block" }}>
                            {this.state.values["homepage_icon_color"]}{" "}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Col>
                {!isBrandWebsite && (
                  <Col xs={12} md={6}>
                    <Block
                      content={
                        <div className="mv-1rem">
                          <Label
                            text={
                              <>
                                <span className="va-middle text-capitalize">
                                  homepage link colour
                                </span>{" "}
                                <button
                                  type="button"
                                  className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                  onClick={() => {
                                    this.openColorPicker("link_text_color");
                                  }}
                                >
                                  <i className="far fa-edit text-primary" />
                                </button>
                              </>
                            }
                          />
                          <div>
                            <div
                              style={{
                                border: "1px black solid",
                                marginRight: "2px",
                                height: "1rem",
                                width: "1rem",
                                display: "inline-block",
                                backgroundColor:
                                  this.state.values["link_text_color"],
                              }}
                            ></div>
                            <div style={{ display: "inline-block" }}>
                              {this.state.values["link_text_color"]}{" "}
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </Col>
                )}
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button
                variant="primary"
                type="submit"
                className={GetSubmitClassList(this.state.isSubmitting, "")}
                style={{ marginRight: 10 }}
              >
                Save Changes
              </Button>
              <Button
                variant="primary"
                type="button"
                className={GetSubmitClassList(this.state.isSubmitting, "")}
                onClick={this.onResetClick}
              >
                Reset
              </Button>
            </Card.Footer>
          </Card>
        </Form>
      </>
    );
  }
}
