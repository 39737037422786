import React from "react";
import Chart from "react-apexcharts";
import EmptyData from "../../EmptyData";
import { GRAPH_COLORS } from "../../../constants";
import moment from "moment";
import { DB_DATE_FORMAT } from "../../../constants";
import { fetchData } from "../../../helpers";
import PacmanLoading from "../PacmanLoading/PacmanLoading";
import { getDateRange, getNameFilterArray } from "./GAHelpers";

import { InfoTooltip } from "../../Base";
import responsiveChartOption from "./pieChartResponsiveOption.json";

export default class BasketAbandons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
      customOptions: {},
      date: moment().format(DB_DATE_FORMAT),
      chartData: [],
      isDataLoading: false,
    };
  }

  async componentDidMount() {
    const { title, data, selectedFilter, selectedDateRange } = this.props;
    let filter = selectedFilter;
    // console.log("pageviewsbydevice filter: ", filter)
    let allBrands = data.allBrands;
    let filterArray = [];
    let filterName = "";
    let dateRange = {};
    let allBrandStores = data.allBrandStores;

    this.setState({ isDataLoading: true });
    filterName = filter.attr ? filter.attr : "brand";
    allBrands = allBrandStores ? allBrandStores : allBrands;
    filterArray = getNameFilterArray(filter, allBrands);
    // console.log("basketabandons filterArray: ", filterArray)
    dateRange = getDateRange(selectedDateRange);

    const dateStart = dateRange.dateStart;
    const dateEnd = dateRange.dateEnd;

    let apiUrl = "/googleanalytics/event_count/basket_abandons";
    let apiParams = {
      dateStart: dateStart, //"7daysAgo",
      dateEnd: dateEnd, //"today",
      filterName: filterName,
      filterArray: filterArray,
    };
    // console.log("basketabandons apiParams: ", apiParams)
    let response = (await fetchData("post", apiUrl, apiParams)).data;
    // console.log("basketabandons response: ", response)
    // let newArr = getArrayTopXOthers(response, 3)
    // console.log("basketabandons newArr: ", newArr)
    let chartData = response;
    let chartSeries = chartData.map(({ value }) => Number(value));
    let chartValueTotal = chartSeries.reduce((a, b) => a + b, 0);
    let chartLabels = chartData.map(({ name, value }) => {
      if (name.length > 25) {
        return (
          name.slice(25, 50) +
          "... - " +
          ((Number(value) / chartValueTotal) * 100).toFixed(2) +
          "%"
        );
      } else {
        return (
          name +
          " - " +
          ((Number(value) / chartValueTotal) * 100).toFixed(2) +
          "%"
        );
      }
    });
    this.setState({ isDataLoading: false });

    // console.log("pageviewsbytitle chartLabels: ", chartLabels)
    // console.log("pageviewsbytitle chartSeries: ", chartSeries)
    let options = {
      chart: {
        // MAKE 'id' FIELD A PROP WHEN ANOTHER GRAPH COMPONENT USES THIS IN THE FUTURE
        id: "statPie",
        title: title,
        type: "donut",
        height: 500,
        events: {
          // legendClick: function(chartContext, seriesIndex, config) {
          //     const {series} = config.globals;
          //     let item = false
          //     if(config.globals.deleteItems){
          //         item= config.globals.deleteItems.find(el => el.index === seriesIndex)
          //     }
          //     if(item) {
          //         const {series} = item;
          //         config.globals.series.splice(seriesIndex, 1, series);
          //         config.globals.deleteItems= config.globals.deleteItems.filter(delItem => delItem.index !== seriesIndex);
          //         ApexCharts.exec('statPie', 'updateSeries', config.globals.series);
          //     }else{
          //         let deleteItem = series.splice(seriesIndex ,1, 0);
          //         deleteItem = {index: seriesIndex,series: deleteItem[0]};
          //         config.globals.deleteItems = config.globals.deleteItems? [...config.globals.deleteItems, deleteItem]: [deleteItem];
          //         ApexCharts.exec('statPie', 'updateSeries', config.globals.series);
          //     }
          //     const container = document.querySelector('.item-sold-by-category');
          //     const selectedLegend = container.querySelectorAll('.apexcharts-legend-series');
          //     config.globals.deleteItems.forEach((el) => {
          //         selectedLegend[el.index].classList.toggle('opacity-35')
          //     })
          // }
        },
      },
      tooltip: {
        y: {
          formatter: function (value, { config, globals, seriesIndex }) {
            return value;
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: chartLabels,
      colors: GRAPH_COLORS,
      responsive: responsiveChartOption,
      plotOptions: {
        pie: {
          offsetX: 110,
          donut: {
            size: "70%",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "22px",
                offsetY: -5,
              },
              value: {
                show: true,
                fontSize: "16px",
                color: GRAPH_COLORS, //undefined,
                offsetY: +5,
                // formatter: function (val) {
                //     const total = chartSeries.reduce((total, item) => total+ item, 0)
                //     return ((val/total).toFixed(2)*100 || 0)+'%'
                // }
              },
              total: {
                show: true,
                label: "Total Baskets",
                color: "#ffa500",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
      legend: {
        show: true,
        width: 200,
        position: "left",
        floating: true,
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    };

    let customOptions = {
      colors: ["#50b585", "#795ecc"],
      tooltip: {
        y: {
          formatter: function (value, { config, globals, seriesIndex }) {
            const total = config.series.reduce((total, cur) => total + cur, 0);

            return `${value} orders, (${
              ((value / total) * 100).toFixed(1) || 0
            }%)`;
          },
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "90%",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "22px",
                offsetY: -5,
              },
              value: {
                show: true,
                fontSize: "16px",
                color: undefined,
                offsetY: +5,
              },
              total: {
                show: true,
                label: "Total Page Views",
                color: "#ffa500",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
    };

    this.setState({ chartData, options, customOptions });
    // console.log("componentdidmount state: ", this.state)
  }

  render() {
    const { className = "", header = "", height = 250, children } = this.props;
    const { isDataLoading } = this.state;
    // console.log("basketabandons state: ", this.state)
    // console.log("basketabandons props: ", this.props)
    // console.log("pageviewsbydevice chartData: ", this.state.chartData)
    let chartSeries = this.state.chartData.map(({ value }) => Number(value));
    // console.log("chartseries: ", chartSeries)
    return (
      <>
        <div
          className={`card card-custom bg-white ${className} d-flex flex-column align-items-center`}
        >
          {isDataLoading ? (
            <>
              <div className="d-flex justify-content-center align-items-center h-100">
                {/* <h1>Loading...</h1> */}
                <PacmanLoading />
              </div>
            </>
          ) : (
            <>
              <h4 className="card-spacer align-self-start">
                {this.props.title}{" "}
                <span>
                  {InfoTooltip(
                    "Abandoned baskets are when users put items in their carts but do not place the order.",
                  )}
                </span>
              </h4>
              {/* Header */}
              {header && (
                <div className="card-header border-0 bg-primary py-5">
                  <h3 className="card-title font-weight-bolder text-white">
                    {header}
                  </h3>
                </div>
              )}

              {children}
              {/* Body */}
              <div className="card-body align-self-stretch">
                {chartSeries.length <= 0 ? (
                  <EmptyData className="text-center mt-12" />
                ) : (
                  <Chart
                    options={{ ...this.state.options, ...this.customOptions }}
                    series={chartSeries}
                    height={height}
                    type="donut"
                  />
                )}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
