import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { FormItem } from "../../../components/Base";
import { Card, Form } from "react-bootstrap";
import { GetSubmitClassList } from "../../../helpers";
import ProSourceForm from "../../../components/ProSourceForm";
import API from "../../../components/API";

import core from "../../../vendors/core-api/";
import queryString from "querystring";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DANGER_COLOR, PRIMARY_COLOR } from "../../../constants";
import LoginCenter from "../../../components/LoginCenter";
import TitleHelmet from "../../../partials/TitleHelmet";

const MySwal = withReactContent(Swal);

class CreatePassword extends API {
  constructor(props) {
    super(props);

    this.state = {
      redirectToLogin: false,
    };

    this.api = core("iam").get();
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  async getData() {
    core().resetAuthToken();
    this._isMounted && this.setState({ data_status: "fetching" });

    const search = queryString.parse(this.props.location.search);
    core().setAuthToken(search["?token"]);
    this.api
      .post({ url: "/external/reset-password/validate" })
      .then(({ data }) => {
        this._isMounted &&
          this.setState({
            data: data,
            data_status: "fetched",
          });
        this.handleOnDataFetched();
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: "This link has expired.",
          confirmButtonColor: DANGER_COLOR,
          confirmButtonText: "Back to Home",
        }).then(() => {
          this.setState({ redirectToLogin: true });
        });
      });
  }

  handleOnSubmit = (values, setSubmitting) => {
    this.api
      .post({
        url: "/external/reset-password",
        data: values,
      })
      .then(() => {
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "Password successfully changed.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Back to Home",
        }).then(() => {
          this.setState({ redirectToLogin: true });
        });
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: "This password reset link has expired.",
          confirmButtonColor: DANGER_COLOR,
          confirmButtonText: "Back to Home",
        }).then(() => {
          this.setState({ redirectToLogin: true });
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  render() {
    const { data_status = "", data = {} } = this.state,
      isLoading = data_status !== "fetched";
    return (
      <>
        {this.state.redirectToLogin ? <Redirect to={"/auth/login"} /> : <></>}
        <TitleHelmet title="Get Started" />
        <LoginCenter>
          <LoginCenter.Form>
            {isLoading ? (
              <Card className="card-border-top-primary card-custom">
                <Card.Body>
                  <div className="mb-10">
                    <p className="text-center">
                      We're currently validating your request...
                    </p>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <Card className="card-border-top-primary card-custom">
                <Card.Body>
                  <div className="mb-10">
                    <h3 className="font-size-h1">Welcome!</h3>
                    <p>Enter your new password for your Pickup.PH account.</p>
                  </div>
                  <ForgotPasswordForm
                    data={data}
                    onSubmit={this.handleOnSubmit}
                  />
                </Card.Body>
              </Card>
            )}
          </LoginCenter.Form>
        </LoginCenter>
      </>
    );
  }
}

class ForgotPasswordForm extends ProSourceForm {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        new_password: "",
        confirm_new_password: "",
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {
      required: ["new_password", "confirm_new_password"],
    };
  }

  onChange = () => {
    let {
      values: { new_password = "", confirm_new_password = "" },
      errors = {},
    } = this.state;

    if (confirm_new_password !== "") {
      if (new_password !== confirm_new_password) {
        errors["confirm_new_password"] = "Passwords do not match";
      } else {
        delete errors["confirm_new_password"];
      }
    }

    this.setState({ errors });
  };

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <Form
        className="fv-plugins-bootstrap fv-plugins-framework"
        onSubmit={this.handleOnSubmit}
      >
        <FormItem
          label={"New Password"}
          name={"new_password"}
          inputProps={{
            name: "new_password",
            placeholder: "New Password",
            value: this.state.values["new_password"],
          }}
          inputActions={inputActions}
          actions={actions}
          type={"password"}
          solid
        />
        <FormItem
          label={"Confirm New Password"}
          name={"confirm_new_password"}
          inputProps={{
            name: "confirm_new_password",
            placeholder: "Confirm New Password",
            value: this.state.values["confirm_new_password"],
          }}
          inputActions={inputActions}
          actions={actions}
          type={"password"}
          solid
        />

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            className={GetSubmitClassList(
              this.state.isSubmitting,
              "btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4",
            )}
            disabled={this.state.isSubmitting}
          >
            Save Changes
          </button>
          <Link to={"/auth/login"}>
            <button className="btn btn-secondary font-weight-bold px-9 py-4 my-3 mx-4">
              Cancel
            </button>
          </Link>
        </div>
      </Form>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(CreatePassword));
