import React from "react";
import ProSourceForm from "../components/ProSourceForm";
import { Button, Card, Row, Col } from "react-bootstrap";
import {
  Block,
  FormItem,
  InfoAlertHelper,
  InfoTooltip,
} from "../components/Base";
import { PRIMARY_COLOR, DANGER_COLOR } from "../constants";
import { fetchData, GetSubmitClassList } from "../helpers";
import core from "../vendors/core-api/";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PacmanLoading from "../components/widgets/PacmanLoading/PacmanLoading";
import QRDetailsModal from "./QRDetailsModal";
import ServiceChargeForm from "./forms/ServiceChargeForm";
import { isEmpty } from "lodash";

const MySwal = withReactContent(Swal);
const SERVICE_CHARGE_LIMIT = 50;
// export default class DineInSettingsDiv extends React.Component {
export default class DineInSettingsDiv extends ProSourceForm {
  constructor(props) {
    super(props);
    this.api = core("pu").get();
    this.state = {
      isActive: this.props.store.isDineInActive ? true : false,
      store: this.props.store ? this.props.store : "",
      storeTables: [],
      isLoading: false,
      showQRDetailsModal: false,
      selectedTable: {},
      values: {
        modifyTableValue: 0,
      },
      maxTables: 50,
      maxTablesPerAdd: 10,
      errors: {},
      touched: {},
      isServiceChargeSaveDisabled: true,
      isSubmitting: false,
      isStoreOpen: false,
    };
  }

  async componentDidMount() {
    // console.log("dineinsettingsdiv componentdidmount state: ", this.state)
    // console.log("dineinsettingsdiv componentdidmount props: ", this.props)
    let isLoading = true;
    let isStoreOpen = true;
    this.setState({ isLoading });
    // let { data: { store_tables: storeTables } } = await this.api.get(`/branded-website/stores/${this.props.store._id}/tables`)
    // isLoading = false
    // this.setState({ storeTables, isLoading })
    let storeTables = {};
    try {
      let { data } = await this.api.get(
        `/branded-website/stores/${this.props.store._id}/tables`,
      );
      storeTables = data.store_tables;
      isStoreOpen = data.is_store_hours_open;
    } catch (error) {
      console.log(error);
    } finally {
      isLoading = false;
      this.setState({ storeTables, isLoading, isStoreOpen });
    }
    // console.log("dineinsettingsdiv storeTables: ", storeTables)
  }

  componentDidUpdate(_prevProps, prevState) {
    if (
      !prevState.isServiceChargeSaveDisabled &&
      prevState.isServiceChargeSaveDisabled
    ) {
      this.setState({ isServiceChargeSaveDisabled: false });
    }
  }
  handleQRCodeClick = (item) => {
    // this.state.selectedTable = item
    // console.log("dineinsettingsdiv handleQRCodeClick item: ", item)
    let selectedTable = item;
    let showQRDetailsModal = true;
    this.setState({ selectedTable, showQRDetailsModal });
  };

  handleAddTables = async (modifyTableValue) => {
    // console.log("dineinsettingsdiv handleAddTables click: ", modifyTableValue)
    // let maxTables = 50
    // let maxTablesPerAdd = 10
    const { maxTables, maxTablesPerAdd } = this.state;
    if (!this.state.store.store_url) {
      // check if store has a store_url (copied stores don't have default store_urls)
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `Please set your store url first before adding dine-in tables.`,
        confirmButtonColor: DANGER_COLOR,
        confirmButtonText: "Ok",
        // timer: 1000
      });
      return;
    }
    if (Number(modifyTableValue) > maxTablesPerAdd) {
      // limit max tables per add
      // console.log("dineinsettingsdiv handleAddTables modifyTableValue: ", Number(modifyTableValue) + this.state.storeTables.length)
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `Please add your tables at most ${maxTablesPerAdd} at a time.`,
        confirmButtonColor: DANGER_COLOR,
        confirmButtonText: "Ok",
        // timer: 1000
      });
      return;
    }
    if (Number(modifyTableValue) + this.state.storeTables.length > maxTables) {
      // limit max tables
      // console.log("dineinsettingsdiv handleAddTables modifyTableValue: ", Number(modifyTableValue) + this.state.storeTables.length)
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `Tables are currently capped at ${maxTables}.`,
        confirmButtonColor: DANGER_COLOR,
        confirmButtonText: "Ok",
        // timer: 1000
      });
      return;
    }
    if (!modifyTableValue || Number(modifyTableValue) < 1) {
      // check for blank table value or negatives
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: "Please specify the number of tables and try again.",
        confirmButtonColor: DANGER_COLOR,
        confirmButtonText: "Ok",
        // timer: 1000
      });
      return;
    }
    let storeId = this.state.store._id;
    let apiParams = {
      store: storeId,
      additionalTables: modifyTableValue,
    };
    let isLoading = true;
    this.setState({ isLoading });
    const { data } = await fetchData(
      "post",
      `/store/add-store-tables`,
      apiParams,
    );
    let storeTables = data.result;
    isLoading = false;
    this.setState({ storeTables, isLoading });
    if (data.result) {
      MySwal.fire({
        icon: "success",
        title: "Success!",
        text: "You have successfully saved your changes.",
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Ok",
        timer: 1000,
      });
    } else {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: "Something went wrong. Please try again.",
        confirmButtonColor: DANGER_COLOR,
        confirmButtonText: "Ok",
        // timer: 1000
      });
    }
    // console.log("dineinsettingsdiv handleAddTables data: ", data)
  };

  handleRemoveTables = async (modifyTableValue) => {
    // console.log("dineinsettingsdiv handleRemoveTables click: ", modifyTableValue)
    if (modifyTableValue > this.state.storeTables.length) {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: "Removed tables should not be greater than the current tables.",
        confirmButtonColor: DANGER_COLOR,
        confirmButtonText: "Ok",
        // timer: 1000
      });
      return;
    }
    if (!modifyTableValue || Number(modifyTableValue) < 1) {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: "Please specify the number of tables and try again.",
        confirmButtonColor: DANGER_COLOR,
        confirmButtonText: "Ok",
        // timer: 1000
      });
      return;
    }
    let storeId = this.state.store._id;
    let apiParams = {
      store: storeId,
      removeTables: modifyTableValue,
    };
    let isLoading = true;
    this.setState({ isLoading });
    const { data } = await fetchData(
      "put",
      `/store/remove-store-tables`,
      apiParams,
    );
    isLoading = false;
    let storeTables = data.result;
    this.setState({ storeTables, isLoading });
    if (data.result) {
      MySwal.fire({
        icon: "success",
        title: "Success!",
        text: "You have successfully saved your changes.",
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Ok",
        timer: 1000,
      });
    } else {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: "Something went wrong. Please try again.",
        confirmButtonColor: DANGER_COLOR,
        confirmButtonText: "Ok",
        // timer: 1000
      });
    }
    // console.log("dineinsettingsdiv handleRemoveTables data: ", data)
  };

  handleTableStatus = async (item, index) => {
    const { storeTables } = this.state;
    const store_id = this.state.store._id;
    let available = item.available;
    available = !available;
    // console.log("handleTableStatus: ", item.tableNumber, available, index)
    storeTables[index].available = available;
    // let isLoading = true
    // this.setState({ isLoading })
    const { data } = await fetchData("put", `store/tables/${item._id}`, {
      available,
      store_id,
    });
    // console.log("handleTableStatus data: ", data)
    if (data.result === "ok") {
      MySwal.fire({
        icon: "success",
        title: "Success!",
        text: "You have successfully saved your changes.",
        confirmButtonColor: PRIMARY_COLOR,
        // "confirmButtonText": "Ok",
        // showConfirmButton: false,
        timer: 1000,
      });
    }
    // isLoading = false
    this.setState({
      storeTables,
      // isLoading
    });
  };

  handleToggleDineInStatus = (isChecked) => {
    let values = {};
    // console.log("props: ", this.props)
    // console.log("state: ", this.state)
    // console.log("isChecked: ", isChecked)
    const { store } = this.state;
    isChecked = !isChecked;
    this.setState({ isActive: isChecked });
    store.isDineInActive = isChecked;
    this.setState({ store });
    // console.log("state: ", this.state)
    // console.log("isDineInActive: ", store.isDineInActive)

    values["store_id"] = store._id;
    values["isActive"] = store.isDineInActive;
    this.api
      .put({
        url: "/store/allow-dinein",
        data: values,
      })
      .then(({ data }) => {
        if (data.result === "ok") {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            // timer: 1000
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const {
      isActive,
      storeTables = [],
      maxTables,
      maxTablesPerAdd,
      isServiceChargeActive = false,
      isSubmitting = false,
      store,
      isStoreOpen,
    } = this.state;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <>
        {this.state.showQRDetailsModal ? (
          <>
            <QRDetailsModal
              show={this.state.showQRDetailsModal}
              onHide={() => this.setState({ showQRDetailsModal: false })}
              data={this.state.selectedTable}
            />
          </>
        ) : (
          <></>
        )}
        <Card className="card-custom gutter-b">
          <Card.Header>
            <Card.Title>Dine-in Settings</Card.Title>
          </Card.Header>
          <Card.Body>
            <div>
              <InfoAlertHelper
                text={
                  "You can manage your dine-in service availability in your Dine-in Settings"
                }
              />
              <Block
                content={
                  <>
                    <Card className="d-flex justify-content-between w-100 mb-4">
                      <Card.Body>
                        <div
                          style={{ transform: "scale(1.2)" }}
                          className="row d-flex align-items-center"
                        >
                          <span className="switch switch-outline switch-icon switch-primary py-1 m-0 ml-10 pl-10">
                            <label>
                              <input
                                type="checkbox"
                                name="isActive"
                                // checked={isActive ? "checked" : null}
                                defaultChecked={isActive}
                                // disabled={store.name? "" : "disabled"}
                                onChange={() =>
                                  this.handleToggleDineInStatus(isActive)
                                }
                              />
                              <span></span>
                            </label>
                          </span>
                          <label className="text-dark font-weight-bolder text-uppercase py-4 pl-3 pr-0">
                            Allow Dine-in Orders
                          </label>
                          {InfoTooltip(
                            "Turning this off will remove the dine-in option for your customers.",
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                    <ServiceChargeForm store={store} order_type={"dine_in"} />
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col xs={12} md={5} lg={6}>
                            <FormItem
                              className="mt-4"
                              label={
                                <>
                                  Add / Remove tables{" "}
                                  {InfoTooltip(
                                    `Tables are capped at ${maxTables}. Please add max ${maxTablesPerAdd} tables at a time.`,
                                  )}
                                </>
                              }
                              name={"modifyTableValue"}
                              inputProps={{
                                name: "modifyTableValue",
                                placeholder: "0",
                                value: this.state.values["modifyTableValue"],
                              }}
                              inputActions={inputActions}
                              actions={actions}
                              type={"number"}
                              // showRequired={true}
                              normal
                            />
                          </Col>
                          <Col xs={12} md={7} lg={6} className="d-flex">
                            <Button
                              variant="primary"
                              className="align-self-center"
                              // className={GetSubmitClassList(this.state.isSubmitting, "")}
                              onClick={() =>
                                this.handleAddTables(
                                  this.state.values.modifyTableValue,
                                )
                              }
                              // disabled = {this.isSaveChangesDisabled()}
                            >
                              Add Tables
                            </Button>
                            <Button
                              variant="danger"
                              className="align-self-center ml-4"
                              // className={GetSubmitClassList(this.state.isSubmitting, "")}
                              onClick={() =>
                                this.handleRemoveTables(
                                  this.state.values.modifyTableValue,
                                )
                              }
                              // disabled = {this.isSaveChangesDisabled()}
                            >
                              Remove Tables
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="d-flex justify-content-between w-100 mt-4">
                      <Card.Header className="font-weight-boldest">
                        {storeTables.length > 0 ? storeTables.length : ""}{" "}
                        Tables
                      </Card.Header>
                      <Card.Body>
                        {this.state.isLoading ? (
                          <>
                            <div className="h-100 w-100 d-flex flex-column align-items-center">
                              <PacmanLoading />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="d-flex flex-row align-items-center justify-content-around flex-wrap overflow-auto h-50">
                              {storeTables
                                .sort((a, b) => a.tableNumber - b.tableNumber)
                                .map((item, index) => {
                                  return (
                                    <Card className="mt-4 ml-4" key={item._id}>
                                      <Card.Body>
                                        <div className="d-flex flex-column align-items-center">
                                          <img
                                            src={item.qrCode}
                                            alt={item._id}
                                            style={{
                                              width: "5.5rem",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              this.handleQRCodeClick(item)
                                            }
                                          />
                                          <a
                                            href={isStoreOpen ? item.url : "#"}
                                            onClick={async (e) => {
                                              if (!isStoreOpen) {
                                                e.preventDefault();
                                                await MySwal.fire({
                                                  icon: "error",
                                                  title: "Ooops!",
                                                  text: "This store is currently closed and not available for dine-in.",
                                                  confirmButtonColor:
                                                    DANGER_COLOR,
                                                  confirmButtonText: "Ok",
                                                });
                                              }
                                            }}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            Table {item.tableNumber}
                                          </a>
                                          <div
                                            style={{ transform: "scale(0.8)" }}
                                            className="row d-flex align-items-center"
                                          >
                                            <label className="text-dark font-weight-bolder text-uppercase">
                                              Available
                                            </label>
                                            <span className="switch switch-outline switch-icon switch-primary py-1 m-0 ml-2">
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  name="isTableAvailable"
                                                  // checked={item.available ? "checked" : null}
                                                  defaultChecked={
                                                    item.available
                                                  }
                                                  // disabled={store.name? "" : "disabled"}
                                                  onChange={() =>
                                                    this.handleTableStatus(
                                                      item,
                                                      index,
                                                    )
                                                  }
                                                />
                                                <span></span>
                                              </label>
                                            </span>
                                            {/* <label className="text-dark font-weight-bolder text-uppercase">Available</label> */}
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  );
                                })}
                            </div>
                          </>
                        )}
                      </Card.Body>
                    </Card>
                  </>
                }
              ></Block>
            </div>
          </Card.Body>
        </Card>
      </>
    );
  }
}
