import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { Modal, Form, Button, Card, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import {
  fetchData,
  getToolTitleFromToolName,
  removeStringSpaces,
  showConfirmationSwal,
} from "../../helpers";
import ConfirmPasswordModal from "../../partials/ConfirmPasswordModal";
import { FormItem } from "../../components/Base";

class AdminToolsBrandToolModal extends ProSourceForm {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        old_brand_name: "",
        new_brand_name: "",
        old_brand_url: "",
        new_brand_url: "",
        is_multi_store_active:
          this.props.data &&
          this.props.data.client &&
          this.props.data.client.group_type === "multi"
            ? true
            : false,
        delete_brand_note: "",
        restore_brand_note: "",
      },
      url: "",
      showPasswordConfirmModal: false,
      errors: [],
      touched: {},
      redirectTo: "",
      isSubmitting: false,
      isChecking: false,
    };
  }

  handleOnClickItem = (itemUrl, newHistory) => {
    let { url } = this.state;
    url = itemUrl;
    this.setState({ url });
    newHistory.push(itemUrl);
  };

  handleClearForms = () => {
    const { values } = this.state;
    values.old_brand_name = "";
    values.new_brand_name = "";
    values.old_brand_url = "";
    values.new_brand_url = "";
    values.is_multi_store_active =
      this.props.data &&
      this.props.data.client &&
      this.props.data.client.group_type === "multi"
        ? true
        : false;
    values.delete_brand_note = "";
    values.restore_brand_note = "";
    this.setState({ values });
  };

  handleCloseModal = () => {
    this.props.onHide();
    this.handleClearForms();
  };

  handleToggleMultiStore = () => {
    const { values } = this.state;
    values.is_multi_store_active = !values.is_multi_store_active;
    this.setState({ values });
  };

  handleSwitchToolConfirm = async (values, setSubmitting) => {
    const { toolName } = this.props;
    switch (toolName) {
      case "change_brand_name":
        this.handleOnConfirmChangeBrandName(values, setSubmitting);
        break;
      case "change_brand_url":
        this.handleOnConfirmChangeBrandUrl(values, setSubmitting);
        break;
      case "activate_multi_store":
        this.handleOnConfirmActivateMultistore(values, setSubmitting);
        break;
      case "delete_brand":
        this.handleOnConfirmDeleteBrand(values, setSubmitting);
        break;
      case "restore_brand":
        this.handleOnConfirmRestoreBrand(values, setSubmitting);
        break;
      default:
        console.log("no tool selected");
    }
  };

  handleOnConfirmChangeBrandName = async (values, setSubmitting) => {
    const { new_brand_name } = this.state.values;
    const { data = [] } = this.props;
    values.old_brand_name = data.name;
    values.new_brand_name = new_brand_name;
    const response = await fetchData(
      "put",
      `/admintools/brands/${data._id}/change_brand_name`,
      { values },
    );
    const successMessage = "Brand Name Updated Successfully";
    showConfirmationSwal(response, successMessage);
    values.confirm_password = "";
    this.setState({ showPasswordConfirmModal: false });
    if (response.data.result !== "error") {
      // this.props.onHide();
      this.handleCloseModal();
      this.props.handleRefreshBrand();
      this.handleClearForms();
    }
    setSubmitting(false);
  };

  handleOnConfirmChangeBrandUrl = async (values, setSubmitting) => {
    const { new_brand_url } = this.state.values;
    const { data = [] } = this.props;
    values.old_brand_url = data.brand_url;
    values.new_brand_url = new_brand_url;
    const response = await fetchData(
      "put",
      `/admintools/brands/${data._id}/change_brand_url`,
      { values },
    );
    const successMessage = "Brand Url Updated Successfully";
    showConfirmationSwal(response, successMessage);
    values.confirm_password = "";
    this.setState({ showPasswordConfirmModal: false });
    if (response.data.result !== "error") {
      // this.props.onHide();
      this.handleCloseModal();
      this.props.handleRefreshBrand();
      this.handleClearForms();
    }
    setSubmitting(false);
  };

  handleOnConfirmActivateMultistore = async (values, setSubmitting) => {
    const { is_multi_store_active } = this.state.values;
    const { data = [] } = this.props;
    values.is_multi_store_active = is_multi_store_active;
    const response = await fetchData(
      "put",
      `/admintools/brands/${data._id}/activate_multi_store`,
      { values },
    );
    const successMessage = "Multistore Activated Successfully";
    showConfirmationSwal(response, successMessage);
    values.confirm_password = "";
    this.setState({ showPasswordConfirmModal: false });
    if (response.data.result !== "error") {
      // this.props.onHide();
      this.handleCloseModal();
      this.props.handleRefreshBrand();
      this.handleClearForms();
    }
    setSubmitting(false);
  };

  handleOnConfirmDeleteBrand = async (values, setSubmitting) => {
    const { delete_brand_note } = this.state.values;
    values.delete_brand_note = delete_brand_note;
    const { data = [] } = this.props;
    const response = await fetchData(
      "post",
      `/admintools/brands/${data._id}/delete_brand`,
      { values },
    );
    const successMessage = "Brand Deleted Successfully";
    showConfirmationSwal(response, successMessage);
    values.confirm_password = "";
    this.setState({ showPasswordConfirmModal: false });
    if (response.data.result !== "error") {
      // this.props.onHide();
      this.handleCloseModal();
      this.props.handleRefreshBrand();
      this.handleClearForms();
    }
    setSubmitting(false);
  };

  handleOnConfirmRestoreBrand = async (values, setSubmitting) => {
    const { restore_brand_note } = this.state.values;
    values.restore_brand_note = restore_brand_note;
    const { data = [] } = this.props;
    const response = await fetchData(
      "put",
      `/admintools/brands/${data._id}/restore_brand`,
      { values },
    );
    const successMessage = "Brand Restored Successfully";
    showConfirmationSwal(response, successMessage);
    values.confirm_password = "";
    this.setState({ showPasswordConfirmModal: false });
    if (response.data.result !== "error") {
      // this.props.onHide();
      this.handleCloseModal();
      this.props.handleRefreshBrand();
      this.handleClearForms();
    }
    setSubmitting(false);
  };

  isAlreadyMultiStore = () => {
    const isAlreadyMultiStore =
      this.props.data &&
      this.props.data.client &&
      this.props.data.client.group_type === "multi"
        ? true
        : false;
    return isAlreadyMultiStore;
  };

  isToggleMultiStoreDisabled = () => {
    const { values } = this.state;
    const isAlreadyMultiStore = this.isAlreadyMultiStore();
    if (isAlreadyMultiStore === true) return true;
    return false;
  };

  isProceedButtonDisabled = () => {
    const { toolName } = this.props;
    const { values } = this.state;

    if (toolName === "change_brand_name") {
      if (!values.new_brand_name) return true;
    }
    if (toolName === "change_brand_url") {
      if (!values.new_brand_url) return true;
    }
    if (toolName === "activate_multi_store") {
      return this.isToggleMultiStoreDisabled();
    }
    if (toolName === "delete_brand") {
      if (!values.delete_brand_note) return true;
    }
    if (toolName === "restore_brand") {
      if (!values.restore_brand_note) return true;
    }
    return false;
  };

  setConfirmMessageFromToolName = (toolName) => {
    const { values } = this.state;
    switch (toolName) {
      case "change_brand_name":
        return (
          <>
            <span>{`You are changing the brand name to `}</span>
            <span className="font-weight-bolder">{values.new_brand_name}.</span>
          </>
        );
      case "change_brand_url":
        return (
          <>
            <span>{`You are changing the brand url to `}</span>
            <span className="font-weight-bolder">{values.new_brand_url}.</span>
          </>
        );
      case "activate_multi_store":
        return `You are changing this to a multistore.`;
      case "delete_brand":
        return `You are deleting this brand.`;
      case "restore_brand":
        return `You are restoring this brand.`;
      default:
        return `${toolName}`;
    }
  };

  onChange = (event) => {
    const name = event.target.name,
      value = event.target.value,
      { values = {}, touched = {}, errors = {} } = this.state;

    if (name === "new_brand_url") {
      if (value.search(/[^-a-z0-9_]/) !== -1) {
        values.new_brand_url = removeStringSpaces(
          value.replace(/[^-a-z0-9_]/g, ""),
        );
        this.setState(
          { values, touched, nameIsValid: false, nameIsInvalid: false },
          () => {
            // this.onBlurName(value);
          },
        );
      } else {
        errors.name =
          "New brand url should only contain lower-case alphanumeric characters, '_', and '-'.";
        this.setState({
          touched,
          errors,
        });
      }
    }
  };

  componentDidMount() {
    this.handleClearForms();
  }

  render() {
    const { show = false, onHide, toolName, data = [] } = this.props;
    const { showPasswordConfirmModal, values } = this.state;
    const isMultiStoreActive =
      this.props.data &&
      this.props.data.client &&
      this.props.data.client.group_type === "multi"
        ? true
        : false;
    // const isMultiStoreActive = values.is_multi_store_active;

    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    onsubmit = (e) => {
      e.preventDefault();
      this.setState({ showPasswordConfirmModal: true });
    };

    return (
      <>
        <ConfirmPasswordModal
          show={showPasswordConfirmModal}
          onHide={() => {
            this.setState({ showPasswordConfirmModal: false });
          }}
          body={
            <>
              <p className="text-dark">
                {this.setConfirmMessageFromToolName(toolName)}
              </p>
            </>
          }
          onSubmit={this.handleSwitchToolConfirm}
        />

        <Modal
          show={show}
          onHide={this.handleCloseModal}
          className="modal-content-xl"
          centered
        >
          <Modal.Header>
            <Modal.Title>{getToolTitleFromToolName(toolName)}</Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleCloseModal}
            >
              <i aria-hidden="true" className="ki ki-close"></i>
            </button>
          </Modal.Header>
          <Form onSubmit={this.onSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  {toolName === "restore_brand" ? (
                    <>
                      <DeletedBrandDataForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  ) : (
                    <>
                      <BrandDataForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  )}
                </Col>
                <Col>
                  {toolName === "change_brand_name" ? (
                    <>
                      <ChangeBrandNameForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {toolName === "change_brand_url" ? (
                    <>
                      <ChangeBrandURLForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {toolName === "activate_multi_store" ? (
                    <>
                      <AllowMultistoreForm
                        isMultiStoreActive={isMultiStoreActive}
                        isToggleMultiStoreDisabled={this.isToggleMultiStoreDisabled()}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                        handleToggleMultiStore={this.handleToggleMultiStore}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {toolName === "delete_brand" ? (
                    <>
                      <DeleteBrandForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {toolName === "restore_brand" ? (
                    <>
                      <RestoreBrandForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <Card className="border card-custom mt-4">
                    <Card.Title className="pb-0 mb-0"></Card.Title>
                    <Card.Body>
                      <Button
                        type="submit"
                        className="btn-block text-uppercase font-weight-bold"
                        disabled={this.isProceedButtonDisabled()}
                      >
                        Proceed
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </>
    );
  }
}

class BrandDataForm extends AdminToolsBrandToolModal {
  render() {
    const { data, values, inputActions, actions } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body className="d-flex flex-column align-items-start">
            <img
              className="align-self-center mb-4"
              src={data.brand_logo}
              alt={data.name}
              style={{
                width: "5.5rem",
              }}
            />
            <div>
              <span className="text-muted">Brand Name: </span>
              <span className="font-weight-bolder">{data.name}</span>
            </div>
            <div>
              <span className="text-muted">Brand URL: </span>
              <span className="font-weight-bolder">{data.brand_url}</span>
            </div>
            <div>
              <span className="text-muted">Address: </span>
              <span className="font-weight-bolder">
                {data.client && data.client.address ? data.client.address : "-"}
              </span>
            </div>
            <div>
              <span className="text-muted">Group Status: </span>
              <span className="font-weight-bolder">
                {data.client && data.client.group_type === "multi"
                  ? "Multi Store"
                  : "Single Store"}
              </span>
            </div>
          </Card.Body>
        </Card>
      </>
    );
  }
}

class DeletedBrandDataForm extends AdminToolsBrandToolModal {
  render() {
    const { data } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body className="d-flex flex-column align-items-start">
            <div>
              <span className="text-muted">Brand Name: </span>
              <span className="font-weight-bolder">{data.origin_key}</span>
            </div>
            <div>
              <span className="text-muted">Brand ID: </span>
              <span className="font-weight-bolder">{data.origin_id}</span>
            </div>
            <div>
              <span className="text-muted">Date Deleted: </span>
              <span className="font-weight-bolder">{data.date_created}</span>
            </div>
          </Card.Body>
        </Card>
      </>
    );
  }
}

class ChangeBrandNameForm extends AdminToolsBrandToolModal {
  render() {
    const { data, values, inputActions, actions } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body>
            <FormItem
              label={"Old Brand Name"}
              name={"old_brand_name"}
              inputProps={{
                name: "old_brand_name",
                // placeholder: "Old Brand Name",
                // value: values["old_brand_name"],
                value: data.name,
                disabled: true,
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              normal
            />
            <FormItem
              label={"New Brand Name"}
              name={"new_brand_name"}
              inputProps={{
                name: "new_brand_name",
                placeholder: "New Brand Name",
                value: values["new_brand_name"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}

class ChangeBrandURLForm extends AdminToolsBrandToolModal {
  render() {
    const { data, values, inputActions, actions } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body>
            <FormItem
              label={"Old Brand Url"}
              name={"old_brand_url"}
              inputProps={{
                name: "old_brand_url",
                // placeholder: "Old Brand Url",
                // value: values["old_brand_url"],
                value: data.brand_url,
                disabled: true,
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              normal
            />
            <FormItem
              label={"New Brand Url"}
              name={"new_brand_url"}
              inputProps={{
                name: "new_brand_url",
                placeholder: "New Brand Url",
                value: values["new_brand_url"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
            />
            <div className="mt-0 small font-italic text-muted">{`New brand url should only contain lower-case alphanumeric characters, '_', and '-'.`}</div>
          </Card.Body>
        </Card>
      </>
    );
  }
}

class AllowMultistoreForm extends AdminToolsBrandToolModal {
  render() {
    const {
      isMultiStoreActive,
      handleToggleMultiStore,
      isToggleMultiStoreDisabled,
    } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body>
            <div className="d-flex flex-column align-items-center justify-contents-space-around">
              {isMultiStoreActive ? (
                <label className="text-danger font-weight-boldest text-uppercase">
                  Multistore is already activated.
                </label>
              ) : (
                <label className="text-dark font-weight-boldest text-uppercase">
                  Click proceed to activate multistore.
                </label>
              )}
              <div className="small font-italic">
                *Multistores can't go back to being single stores.
              </div>
            </div>
          </Card.Body>
        </Card>
      </>
    );
  }
}

class DeleteBrandForm extends AdminToolsBrandToolModal {
  render() {
    const { data, values, inputActions, actions } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body>
            <FormItem
              label={"Note"}
              name={"delete_brand_note"}
              inputProps={{
                name: "delete_brand_note",
                placeholder: "Note",
                value: values["delete_brand_note"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}

class RestoreBrandForm extends AdminToolsBrandToolModal {
  render() {
    const { data, values, inputActions, actions } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body>
            <FormItem
              label={"Note"}
              name={"restore_brand_note"}
              inputProps={{
                name: "restore_brand_note",
                placeholder: "Note",
                value: values["restore_brand_note"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default withRouter(AdminToolsBrandToolModal);
