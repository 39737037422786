import React from "react";

import APIComponent from "../components/API";
import core from "../vendors/core-api";
// import helpertexts from "helpertexts";
import { Block, IconAlert, InfoAlertHelper, Label } from "../components/Base";
import {
  GetSubmitClassList,
  getFirstXLetters,
  getLastXLetters,
} from "../helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PRIMARY_COLOR } from "../constants";
import EditPaymentGatewayModal from "./EditPaymentGatewayModal";
import { Button, Card } from "react-bootstrap";
import PacmanWrapper from "../components/dashboard/PacmanWrapper";
import moment from "moment";

const MySwal = withReactContent(Swal);
const LAST_PAYMONGO_TEST = "LAST_PAYMONGO_KEY_TEST";

export default class PaymentSettingsDiv extends React.Component {
  render() {
    const { data = {}, showEditPaymentGatewayModal = false } = this.props,
      hasPaymentGateway =
        data.payment_gateway !== "" && data.payment_gateway !== null;
    return (
      <>
        <Card className="card-custom gutter-b">
          <Card.Header>
            <Card.Title>Payment Settings</Card.Title>
          </Card.Header>
          <Card.Body>
            {hasPaymentGateway ? (
              <InfoAlertHelper
                text={
                  "You can manage your available methods of payment and PayMongo keys in your payment settings."
                }
              />
            ) : (
              <IconAlert
                text={
                  "Oooops! Looks like you haven't setup your payment details. Please send us a message on support@pickup.ph regarding your PayMongo keys and your desired payment methods so we can set this up for you. Thank you!"
                }
                icon={"Code/Warning-2"}
              />
            )}

            <Block
              content={
                <>
                  <Label
                    text={
                      <>
                        <span className="va-middle text-capitalize">
                          payment methods
                        </span>
                        {data.payment_methods.length > 0 ? (
                          <button
                            type="button"
                            className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                            onClick={() => {
                              this.props.setState({
                                showEditPaymentMethodsModal: true,
                              });
                            }}
                          >
                            <i className="far fa-edit text-primary" />
                          </button>
                        ) : (
                          <></>
                        )}
                      </>
                    }
                  />
                  <div className="mv-1rem">
                    {data.payment_methods.length > 0 ? (
                      <div className="text-dark-60 m-0 font-weight-bold">
                        {data.payment_methods
                          .map((payment_method = "") => {
                            payment_method =
                              payment_method === "grab_pay"
                                ? "grab pay"
                                : payment_method;
                            return payment_method.toUpperCase();
                          })
                          .join(", ")}
                      </div>
                    ) : (
                      <p>You haven't setup your payment method.</p>
                    )}
                  </div>
                </>
              }
            />

            {/** add checker if payment gateway exists */}

            {/* <Block
                    title={<>
                        <span className="va-middle">paymongo keys</span> 
                        {
                            (hasPaymentGateway) ? 
                                <button type="button" className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle" onClick={() => { this.props.setState({ showEditPaymentGatewayModal: true })}}><i className="far fa-edit text-primary"/></button> : <></>
                        }
                    
                    </>}
                    content={
                        
                    }
                /> */}

            <Block
              content={
                <>
                  <Label
                    text={
                      <>
                        <span className="va-middle text-capitalize">
                          paymongo keys
                        </span>
                        {hasPaymentGateway ? (
                          <button
                            type="button"
                            className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                            onClick={() => {
                              this.props.setState({
                                showEditPaymentGatewayModal: true,
                              });
                            }}
                          >
                            <i className="far fa-edit text-primary" />
                          </button>
                        ) : (
                          <></>
                        )}
                      </>
                    }
                  />
                  {hasPaymentGateway ? (
                    <PaymentGatewayComponent
                      id={data.payment_gateway}
                      brand={data.brand._id}
                      store={data._id}
                      showEditPaymentGatewayModal={showEditPaymentGatewayModal}
                      hideEditPaymentGatewayModal={() => {
                        this.props.setState({
                          showEditPaymentGatewayModal: false,
                        });
                      }}
                      refreshUserRedux={this.props.refreshUserRedux}
                    />
                  ) : (
                    <p>You haven't setup your Paymongo keys.</p>
                  )}

                  <FillInPaymongoFormButton />
                </>
              }
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}

class FillInPaymongoFormButton extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.api = core("pu").get();
    this.data_url = `/payment-gateways/check-if-paymongo-form-is-filled`;
  }

  handleOnDataFetched = () => {
    // console.log(this.state.data);
    const { data = {} } = this.state,
      { signup_form = "" } = data;
    this.setState({ show: signup_form === "" });
  };

  render() {
    return <></>;
    // return this.state.show ? <Link to="/paymongo-form"><Button variant="primary">Fill in PayMongo form</Button></Link> : <></>
  }
}

class PaymentGatewayComponent extends APIComponent {
  constructor(props) {
    super(props);

    const { id = "" } = this.props;

    this.api = core("pu").get();
    this.data_url = id !== "" ? `/payment-gateways/${id}` : "";
    this.onSubmitUpdateKeys = this.onSubmitUpdateKeys.bind(this);
  }

  async getData() {
    if (this.data_url !== "") {
      this._isMounted && this.setState({ data_status: "fetching" });
      this.api &&
        this.api
          .get(this.data_url)
          .then((data) => {
            this._isMounted &&
              this.setState({
                data: data.data,
              });
            this.handleOnDataFetched();

            if (this.use_display_data) {
              this.setDisplayData();
            }
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });
    }
  }

  handleOnDataFetched = () => {
    let { data = {} } = this.state;

    if (data.hasOwnProperty("keys"))
      data.keys = JSON.parse(window.atob(data.keys));

    this.setState({ data, data_status: "fetched" });
  };

  canTestPaymongoKeys = () => {
    const lastKeyTest = localStorage.getItem(LAST_PAYMONGO_TEST);
    const currentTime = moment();
    return lastKeyTest === null
      ? true
      : currentTime.diff(lastKeyTest, "seconds") >= 60;
  };

  testPaymongoKeys = async (values) => {
    const { brand, store } = this.props;
    this.setState({ isTestingKeys: true });
    values["brand"] = brand;
    values["store"] = store;
    const { data } = await this.api.post({
      url: `payments/test-paymongo-keys/`,
      data: {
        keys: window.btoa(JSON.stringify(values)),
      },
    });
    return data;
  };

  submitPaymongoKeys = (values, setSubmitting) => {
    this.api
      .put({
        url: "/payment-gateways/" + this.props.id,
        data: { keys: window.btoa(JSON.stringify(values)) },
      })
      .then(({ data }) => {
        if (data.result === "ok") {
          setSubmitting(false);
          this.props.hideEditPaymentGatewayModal();
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            timer: 1000,
          });

          this.getData();
          this.props.refreshUserRedux();
        }
      })
      .catch((error) => {
        console.log(error);
        setSubmitting(false);
      });
  };

  onSubmitUpdateKeys = async (values, setSubmitting) => {
    const { public_key, secret_key } = values;
    if (public_key !== "" || secret_key !== "") {
      if (this.canTestPaymongoKeys()) {
        const data = await this.testPaymongoKeys(values);
        if (data) {
          const { result } = data;
          this.setState({ isTestingKeys: false });
          setSubmitting(false);
          if (result !== "success") {
            localStorage.setItem(LAST_PAYMONGO_TEST, new Date());
            MySwal.fire({
              icon: "warning",
              title: "Ooops!",
              text: "Sorry, your Paymongo keys are unauthorized.",
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "Ok",
              timer: 3000,
            });
          } else {
            this.submitPaymongoKeys(values, setSubmitting);
          }
        }
      } else {
        setSubmitting(false);
        MySwal.fire({
          icon: "warning",
          title: "Key test exceeded",
          text: "Please try again later",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          timer: 3000,
        });
      }
    } else {
      this.submitPaymongoKeys(values, setSubmitting);
    }
  };

  onClickTestKeys = async () => {
    if (this.canTestPaymongoKeys()) {
      const { data } = this.state;
      const {
        keys: { public_key, secret_key },
      } = data;
      const paymongo_data = await this.testPaymongoKeys({
        public_key,
        secret_key,
      });
      if (paymongo_data) {
        localStorage.setItem(LAST_PAYMONGO_TEST, new Date());
        const { result } = paymongo_data;
        const isAuthorized = result === "success";
        this.setState({ isTestingKeys: false });
        MySwal.fire({
          icon: isAuthorized ? "success" : "warning",
          title: isAuthorized ? "Success!" : "Ooops!",
          text: isAuthorized
            ? "Paymongo Keys are authorized."
            : "Sorry, your Paymongo keys are unauthorized.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          timer: 3000,
        });
      }
    } else {
      MySwal.fire({
        icon: "warning",
        title: "Key test exceeded",
        text: "Please try again later",
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Ok",
        timer: 3000,
      });
    }
  };

  render() {
    const { data = {}, data_status = "", isTestingKeys = false } = this.state,
      isLoading = data_status !== "fetched",
      { showEditPaymentGatewayModal = false } = this.props;

    return (
      <>
        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          <>
            {showEditPaymentGatewayModal && (
              <EditPaymentGatewayModal
                show={showEditPaymentGatewayModal}
                onHide={this.props.hideEditPaymentGatewayModal}
                data={data}
                onSubmit={this.onSubmitUpdateKeys}
              />
            )}
            <Block
              content={
                <>
                  <div className="mb-5">
                    <div className="text-dark-60 m-0 font-weight-bold">
                      <div className="font-weight-bolder text-dark-75 font-size-lg mb-1 text-uppercase d-none">
                        {data.retrieved_payment_gateway.medium}
                      </div>
                      Public Key:{" "}
                      <>
                        {getFirstXLetters(data.keys.public_key, 7)}...
                        {getLastXLetters(data.keys.public_key, 4)}{" "}
                      </>
                      <br />
                      Secret Key:{" "}
                      <>
                        {getFirstXLetters(data.keys.secret_key, 7)}...
                        {getLastXLetters(data.keys.secret_key, 4)}{" "}
                      </>
                    </div>
                  </div>
                  <div>
                    <Button
                      variant="primary"
                      onClick={this.onClickTestKeys}
                      disabled={
                        isTestingKeys ||
                        data.keys.public_key === "" ||
                        data.keys.secret_key === ""
                      }
                      className={GetSubmitClassList(isTestingKeys, "")}
                    >
                      Test Paymongo Keys
                    </Button>
                  </div>
                </>
              }
            />
          </>
        )}
      </>
    );
  }
}
