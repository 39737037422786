import React from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import { ErrorAlert } from "../../components/Base";
import ProSourceForm from "../../components/ProSourceForm";
import OrderDateDropdown from "./OrderDateDropdown";
import OrderTimeDropDown from "./OrderTimeDropdown";

export default class LimitsModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { order_date = "", order_time = "" } = this.props;

    this.state = {
      values: {
        order_date,
        order_time,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };
  }

  onChange = (e) => {
    let name = e.target.name;
    let { values = {} } = this.state;

    if (name === "order_date") {
      values.order_time = "";
      this.setState({ mountOrderTimeDropdown: false, values });
      setTimeout(() => {
        this.setState({ mountOrderTimeDropdown: true });
      }, 50);
    }
  };

  onSubmit = (values, setSubmitting) => {
    this.setState({ isSubmitting: true });
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const {
      show = false,
      onHide,
      brand_url = "",
      selected_store = {},
      showErrorAlert = false,
      errorAlertText = "",
      showIfStoreClosed = false,
      showDateOptionDropwdown = true,
      submitText = "Proceed",
      showActionButton = false,
    } = this.props;

    const {
      values = {},
      errors = {},
      mountOrderDateDropdown = showDateOptionDropwdown,
      mountOrderTimeDropdown = showDateOptionDropwdown,
      showProceed = showActionButton,
      isStoreClosed = false,
      isSubmitting = false,
    } = this.state;

    const inputActions = {
      onChange: this.handleOnChange,
      onBlur: this.handleOnBlur,
    };

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>Ooops!</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            {showErrorAlert ? (
              <>
                {showIfStoreClosed ? (
                  isStoreClosed ? (
                    <ErrorAlert
                      message={
                        "Sorry! This store is almost closing/already closed and not accepting orders anymore."
                      }
                    />
                  ) : (
                    <ErrorAlert message={errorAlertText} />
                  )
                ) : (
                  <ErrorAlert message={errorAlertText} />
                )}
              </>
            ) : (
              <></>
            )}

            <Row>
              <Col>
                {mountOrderDateDropdown ? (
                  <OrderDateDropdown
                    brand_url={brand_url}
                    store={selected_store}
                    inputActions={inputActions}
                    hasError={errors.hasOwnProperty("order_date")}
                    error={errors["order_date"]}
                    order_type={values["order_type"]}
                    value={values["order_date"]}
                    setOrderDate={(order_date) => {
                      let { values = {} } = this.state;
                      values.order_date = order_date;
                      this.setState({ values });
                    }}
                    onStoreNotOpenForOrdersError={() => {
                      this.setState({ isStoreClosed: true });
                    }}
                    afterFetch={() => {
                      this.setState({ showProceed: true });
                    }}
                  />
                ) : (
                  <></>
                )}
              </Col>
              <Col>
                {mountOrderTimeDropdown ? (
                  <OrderTimeDropDown
                    brand_url={brand_url}
                    store={selected_store}
                    inputActions={inputActions}
                    order_date={values["order_date"]}
                    hasError={errors.hasOwnProperty("order_time")}
                    error={errors["order_time"]}
                    order_type={values["order_type"]}
                    value={values["order_time"]}
                    no_asap={true}
                    onStoreNotOpenForOrdersError={() => {
                      this.setState({ isStoreClosed: true });
                    }}
                    afterFetch={() => {
                      this.setState({ showProceed: true });
                    }}
                  />
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Modal.Body>
          {showProceed ? (
            <Modal.Footer>
              <Button
                variant="outline-primary"
                className="br-0 btn-block font-weight-bolder"
                type="submit"
                disabled={isSubmitting}
              >
                {submitText}
              </Button>
            </Modal.Footer>
          ) : (
            <></>
          )}
        </Form>
      </Modal>
    );
  }
}
