import React, {useMemo} from "react";
import {AsideMenuList} from "./AsideMenuList";
import {useHtmlClassService} from "../../../_core/MetronicLayout";

export function AsideMenu({disableScroll, type = "admin", preview_token = "", finishedSetup = false, onClickCart, cart_counter, privileges = [], activeMenu = "", store_url = ""}) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        <AsideMenuList layoutProps={layoutProps} type={type} preview_token={preview_token} finishedSetup={finishedSetup} onClickCart={onClickCart} cart_counter={cart_counter} privileges={privileges} activeMenu={activeMenu} store_url={store_url}/>
      </div>
      {/* end::Menu Container */}
    </>
  );
}
