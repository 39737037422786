import React from "react";
import { Redirect } from "react-router-dom";
import { FormItem } from "../../components/Base";
import { Form, Row, Col } from "react-bootstrap";
import { GetSubmitClassList } from "../../helpers";
import ProSourceForm from "../../components/ProSourceForm";
import API from "../../components/API";

import core from "../../vendors/core-api/";
import { useSelector } from "react-redux";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export default function UserChangePasswordForm(props) {
  const { user } = useSelector((state) => state.auth);
  return <ForgotPassword {...props} currentUser={user} />;
}

class ForgotPassword extends API {
  constructor(props) {
    super(props);

    this.state = {
      redirectToLogin: false,
    };

    this.api = core("pu").get();
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnSubmit = (values, setSubmitting) => {
    // console.log(values);
    this.api
      .post({
        url: "/external/change-password",
        data: values,
      })
      .then(() => {
        this.props.onSubmitChangePassword();
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully saved your changes.",
          showConfirmButton: false,
          timer: 1000,
        });
        this.setState({ showEditUserForm: false });
      })
      .catch(() => {
        this.handleError();
      })
      .finally(() => {
        setSubmitting(false);
        this.setState({
          mountModal: false,
          deleteUserLoading: false,
          mountForms: false,
        });
        setTimeout(() => {
          this.setState({ mountModal: true, mountForms: true });
        }, 50);
      });
  };

  render() {
    const { data = {} } = this.state;
    const { user = {}, currentUser = {} } = this.props;

    return user._id === currentUser._id ? (
      <>
        {this.state.redirectToLogin ? <Redirect to={"/auth/login"} /> : <></>}
        <>
          <ForgotPasswordForm
            data={data}
            onSubmit={this.handleOnSubmit}
            user={user}
            currentUser={currentUser}
          />
        </>
      </>
    ) : null;
  }
}

class ForgotPasswordForm extends ProSourceForm {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        id: this.props.user._id,
        new_password: "",
        confirm_new_password: "",
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {
      required: ["new_password", "confirm_new_password"],
    };
  }

  onChange = () => {
    let {
      values: { new_password = "", confirm_new_password = "" },
      errors = {},
    } = this.state;

    if (confirm_new_password !== "") {
      if (new_password !== confirm_new_password) {
        errors["confirm_new_password"] = "Passwords do not match";
      } else {
        delete errors["confirm_new_password"];
      }
    }

    this.setState({ errors });
  };

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
    this.setState({
      values: { id: "", new_password: "", confirm_new_password: "" },
    });
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };
    // const { user = {}, currentUser = {}} = this.props;

    return (
      <Form onSubmit={this.handleOnSubmit}>
        <Row>
          <Col>
            <FormItem
              label={"New Password"}
              name={"new_password"}
              inputProps={{
                name: "new_password",
                placeholder: "New Password",
                value: this.state.values["new_password"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"password"}
              showRequired={true}
              normal
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <FormItem
              label={"Confirm New Password"}
              name={"confirm_new_password"}
              inputProps={{
                name: "confirm_new_password",
                placeholder: "Confirm New Password",
                value: this.state.values["confirm_new_password"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"password"}
              showRequired={true}
              normal
            />
          </Col>
        </Row>

        <div className="d-flex flex-row justify-content-space-between">
          <div></div>
          <div className="text-right">
            <button
              type="submit"
              className={GetSubmitClassList(
                this.state.isSubmitting,
                "btn btn-primary font-weight-bold px-9 py-4 my-3",
              )}
              disabled={this.state.isSubmitting}
            >
              <span>Save Changes</span>
            </button>
          </div>
        </div>
      </Form>
    );
  }
}
