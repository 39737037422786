import React from "react";
import { AnimateDiv, HeaderText } from "../../components/Base";
import BWSection from "../../components/widgets/BWSection";
import { Row, Col } from "react-bootstrap";
import StoreListItem from "../../partials/StoreListItem";
import BrandWebsite from "../../partials/BrandWebsite";
import core from "../../vendors/core-api";
import APIComponent from "../../components/API";
import { Redirect, useParams } from "react-router-dom";

export default function AboutPage(props) {
  const { store_url = "" } = useParams();
  return store_url !== "" ? (
    <Redirect to="/about" />
  ) : (
    <MainComponent {...props} />
  );
}

class MainComponent extends BrandWebsite {
  handleOnDataFetched2 = () => {
    const {
      data: { stores = [] },
    } = this.state;
    this.setState({
      selected_store: stores[0] || {},
      mount: true,
      showSplashScreen: false,
    });
  };

  setTitle = () => {
    const {
      data: { name = "" },
      preview_token = "",
    } = this.state;
    this.setState({
      title:
        preview_token !== ""
          ? `[Preview Mode] About | ${name}`
          : `About | ${name}`,
    });
  };

  renderContent = () => {
    const {
        data = {},
        store_id = "",
        brand_url = "",
        cart = {},
        website_theme = {},
        selected_store = {},
        mount = false,
      } = this.state,
      { order_date } = cart;
    const actions = {
      setSelectedStore: (selected_store) => {
        this.setState({ selected_store });
        this.initCurrentAvailableCounters();
      },
    };

    return (
      <>
        <AnimateDiv>
          <Row>
            <Col>
              {/* { (store_id !== "") ? (
                            <AboutContent 
                            data = {data} 
                            store_id= {store_id} 
                            brand_url= {brand_url} 
                            order_date= {order_date}
                            actions={actions}
                        />
                        ) : <></> } */}

              {mount ? (
                <AboutContent
                  data={data}
                  store_id={store_id}
                  brand_url={brand_url}
                  order_date={order_date}
                  actions={actions}
                  website_theme={website_theme}
                  selected_store={selected_store}
                />
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </AnimateDiv>
      </>
    );
  };
}

class AboutContent extends APIComponent {
  constructor(props) {
    super(props);
    const { store_id = "", brand_url = "", order_date = "" } = this.props;
    this.data_url =
      store_id !== "" && brand_url !== ""
        ? `/branded-website/brands/${brand_url}/stores/${store_id}/stocks?order_date=${order_date}`
        : "";
    this.api = core("pu").get();
  }

  handleOnDataFetched = () => {
    const { data = {} } = this.state;
    this.props.actions.setSelectedStore(data);
  };

  render() {
    const { data = {}, website_theme = {} } = this.props;

    const {
      highlighted_text_color = "",
      link_text_color = "",
      icon_color = "",
    } = website_theme;
    return (
      <>
        <AnimateDiv>
          <Row>
            <Col>
              <HeaderText title={<>{data.name}</>} />
              <BWSection>
                <BWSection.Header
                  style={{
                    color: highlighted_text_color,
                    borderBottomColor: highlighted_text_color,
                  }}
                >
                  About Us
                </BWSection.Header>
                <BWSection.Content>
                  <ParseNewLine text={data.brand_description} />
                </BWSection.Content>
              </BWSection>
              <BWSection>
                <BWSection.Header
                  style={{
                    color: highlighted_text_color,
                    borderBottomColor: highlighted_text_color,
                  }}
                >
                  Store Locations
                </BWSection.Header>
                <BWSection.Content>
                  <div>
                    {data.stores.map((store, index) => (
                      <div key={index} className="mv-1rem">
                        <StoreListItem
                          store={store}
                          store_hours_display={"branded-website-today"}
                          custom_color={icon_color}
                          custom_link_color={link_text_color}
                        />
                      </div>
                    ))}
                  </div>
                </BWSection.Content>
              </BWSection>
            </Col>
          </Row>
        </AnimateDiv>
      </>
    );
  }
}

export class ParseNewLine extends React.Component {
  render() {
    const { text = "" } = this.props;
    return (
      <>
        {text.split("\n").map((txt, index) => {
          return <p key={index}>{txt}</p>;
        })}
      </>
    );
  }
}
