import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FormItem } from "../components/Base";
import ProSourceForm from "../components/ProSourceForm";
import { GetSubmitClassList } from "../helpers";

export default class ConfirmPasswordModal extends ProSourceForm {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        confirm_password: "",
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {
      required: ["confirm_password"],
    };
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const { show, onHide, title = "Are you sure?", body = <></> } = this.props;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <>
        <Modal show={show} onHide={onHide} centered>
          <Form onSubmit={this.handleOnSubmit}>
            <Modal.Header>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {body}
              <FormItem
                label={"Enter your password to continue."}
                name={"confirm_password"}
                inputProps={{
                  name: "confirm_password",
                  placeholder: "Password",
                  value: this.state.values["confirm_password"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"password"}
                solid
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                type="submit"
                className={GetSubmitClassList(this.state.isSubmitting, "")}
                disabled={
                  this.state.values["confirm_password"] === "" ||
                  this.state.isSubmitting
                }
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
