import moment from "moment";
import { DB_DATE_FORMAT, REJECT_CANCELLED_STATUSES } from "../constants";
import { formatDeliveryStatus } from "../helpers";
import { isEmpty } from "lodash";

/**
 * Generate extras codes for easier checking
 * @param {array} extras
 * @returns
 */
export function generateExtrasChecker(extras = []) {
  return extras.map(({ extra_group_id = "", extra_id = "" }) => {
    return `${extra_group_id}_${extra_id}`;
  });
}

/**
 *
 * @param {array} existing_set_of_extras
 * @param {array} new_set_of_extras
 * @returns
 */
export function checkIfSameExtras(
  existing_set_of_extras = [],
  new_set_of_extras = [],
) {
  let same = existing_set_of_extras.length === new_set_of_extras.length; // check if same length

  if (same) {
    for (var i = 0; i < existing_set_of_extras.length; i++) {
      same = new_set_of_extras.indexOf(existing_set_of_extras[i]) !== -1;
      if (!same) break;
    }
  }

  return same;
}

/**
 *
 * @param {array} extras
 * @returns
 */
export function computeExtrasAmount(extras = []) {
  let total = 0;
  for (var i = 0; i < extras.length; i++) {
    total += parseInt(extras[i].price);
  }

  return total;
}

/**
 * Generates meal plan dates based on meal plan type
 * @param {string} mealPlanType
 * @returns
 */
export function generateMealPlanDates(mealPlanType = "") {
  let days = [],
    today = moment(new Date()),
    initDay = moment(new Date()).add("1", "week").startOf("week");

  if (today.weekday() === 0) {
    initDay = moment(new Date()).startOf("week");
  }
  if (mealPlanType === "3DayMealPlan") {
    // initDay = moment(new Date()).add("1", "week").startOf("week").add(1, "day");
    initDay = initDay.add(1, "day");
    for (let i = 0; i < 3; i++) {
      days.push(
        moment(initDay)
          .add(i * 2, "day")
          .format(DB_DATE_FORMAT),
      );
    }
  } else if (mealPlanType === "5DayMealPlan") {
    for (let i = 0; i < 5; i++) {
      days.push(
        moment(initDay)
          .add(i + 1, "day")
          .format(DB_DATE_FORMAT),
      );
    }
  }

  return days;
}

/**
 * Gets the maximum items in meal plan based on meal plan type.
 * @param {String} mealPlanType
 * @returns
 */
export function getMaxItemsInMealPlan(mealPlanType = "") {
  let maxItems = 0;

  switch (mealPlanType) {
    case "3DayMealPlan":
      maxItems = 3;
      break;
    case "5DayMealPlan":
      maxItems = 5;
      break;
    default:
      maxItems = 0;
      break;
  }

  return maxItems;
}

/**
 *
 * @param {*} data
 * @returns
 */
export function generateTimeline(data) {
  const { order } = data;
  let { change_timestamp = "", order_type = "", status = "new" } = order;
  let timeline = [];
  let status_order;

  change_timestamp = JSON.parse(change_timestamp);

  if (
    order_type === "pickup" ||
    order.order_type === "third_party_pickup" ||
    order.order_type === "curbside_pickup" ||
    order.order_type === "dine_in"
  ) {
    status_order = ["new", "accepted", "ready", "completed"];
  } else if (order_type === "delivery") {
    status_order = ["new", "accepted", "ready", "completed", "delivered"];
    const { delivery_data } = data;
    if (
      ["ready", "completed"].indexOf(status) > -1 &&
      !isEmpty(delivery_data)
    ) {
      status = getDeliveredStatus(
        status,
        delivery_data,
        order.delivery.delivery_partner,
      );
    }
  }

  if (REJECT_CANCELLED_STATUSES.indexOf(status) !== -1) {
    status_order = ["new", status];
  } else if (status === "cancelled_no_show") {
    status_order = ["new", "accepted", "ready", status];
  }
  // else if(completed_refunded.indexOf(status) !== -1) {
  //     status_order =  ["new", "accepted", "ready", "completed", "refunded"];
  // }

  const current_status = status_order.indexOf(status);

  timeline = status_order.map((current_status_order = "", index) => {
    return {
      label: formatTimelineTime(change_timestamp[current_status_order]),
      // content: getTitle(current_status_order, order_type),
      content: getTrackStatusLabel(index, status, order),
      status: getTimelineStatus(index, current_status),
    };
  });

  return timeline;
}

/**
 *
 * @param {*} timestamp
 * @returns
 */
function formatTimelineTime(timestamp) {
  return typeof timestamp === "number"
    ? moment(timestamp).utcOffset(8).format("DD/MM/YYYY h:mm A")
    : "";
}

/**
 *
 * @param {*} indexToCompare
 * @param {*} currentIndex
 * @returns
 */
function getTimelineStatus(indexToCompare, currentIndex) {
  return indexToCompare === currentIndex
    ? "current"
    : indexToCompare > currentIndex
    ? "pending"
    : "done";
}

/**
 *
 * @param {*} status
 * @param {*} delivery_data
 * @param {*} partner
 * @returns
 */
function getDeliveredStatus(status, delivery_data, partner) {
  if (partner === "lalamove") {
    if (delivery_data.status === "COMPLETED") {
      return "delivered";
    } else if (delivery_data.status === "PICKED_UP") {
      return "completed";
    } else {
      return status;
    }
  } else if (partner === "mrspeedy") {
    if (
      delivery_data.deliveries[0] &&
      delivery_data.deliveries[0].status === "finished"
    ) {
      return "delivered";
    } else {
      return status;
    }
  } else if (partner === "grab") {
    if (delivery_data.status === "COMPLETED") {
      return "delivered";
    } else if (delivery_data.status === "PICKED_UP") {
      return "completed";
    } else {
      return status;
    }
  }
}

/**
 *
 * @param {*} index
 * @param {*} status
 * @param {*} order
 * @returns
 */
export function getTrackStatusLabel(index = "", status = "", order = {}) {
  let labels = [];
  // console.log("track order: ", order)
  if (
    order.order_type === "pickup" ||
    order.order_type === "third_party_pickup" ||
    order.order_type === "curbside_pickup"
  ) {
    labels = [
      "order placed",
      "preparing your order",
      "ready for pickup",
      "dispatched",
    ];
  } else if (order.order_type === "delivery") {
    labels = [
      "order placed",
      "preparing your order",
      "ready for delivery",
      "dispatched",
      "delivered",
    ];
  } else if (order.order_type === "dine_in") {
    labels = [
      "order placed",
      "preparing your order",
      "ready for serving",
      "served",
    ];
  }
  // let labels = [ "order placed", "preparing your order", "ready for pickup", "dispatched" ];

  if (REJECT_CANCELLED_STATUSES.indexOf(status) !== -1) {
    labels = ["order placed", "order cancelled"];
  }

  return labels[index];
}

/**
 *
 * @param {*} status
 * @returns
 */
export function getTrackImage(data, isDeliveryOnGoing = false) {
  const { order } = data;
  const { status } = order;
  if (status.indexOf("cancelled") !== -1) {
    return "cancelled";
  } else if (isDeliveryOnGoing) {
    if (data.delivery_data.status == "COMPLETED") {
      return "completed";
    } else if (data.delivery_data.status == "PICKED_UP") {
      return "soon";
    } else {
      return "soon";
    }
  } else if (status.indexOf("completed") !== -1) {
    return "completed";
  } else {
    return status;
  }
}

/**
 *
 * @param {*} status
 * @param {*} order_type
 * @param {*} data
 * @returns
 */
export function getMessage(status = "", order_type = "", data = {}) {
  let message = "";

  if (status === "new") {
    message = "We have received your order.";
  } else if (status === "accepted") {
    message = "Get excited! We have started to prepare your order.";
  } else if (status === "ready") {
    message = "";

    if (
      order_type === "pickup" ||
      order_type === "third_party_pickup" ||
      order_type === "curbside_pickup" ||
      order_type === "dine_in"
    ) {
      message += " See you at the store!";
    } else if (order_type === "delivery") {
      message += `
                ${
                  data.order.delivery.delivery_partner === "lalamove"
                    ? `${formatDeliveryStatus(
                        "lalamove",
                        data.delivery_data.status,
                      )}`
                    : ""
                }
                ${
                  data.order.delivery.delivery_partner === "mrspeedy" &&
                  data.delivery_data.deliveries[0]
                    ? `${formatDeliveryStatus(
                        "mrspeedy",
                        data.delivery_data.deliveries[0].status,
                      )}`
                    : ""
                }
                ${
                  data.order.delivery.delivery_partner === "grab"
                    ? `${formatDeliveryStatus(
                        "grab",
                        data.delivery_data.status,
                      )}`
                    : ""
                }
            `;
      // message += " Stay back and relax. We are now waiting for the delivery courier to pickup your order."
    }
  }
  // else if(status === "out_for_delivery") {
  //     message = "Your order is now out for delivery! Delivery details were sent on your inbox.";
  // }
  else if (status === "completed") {
    message = "";
    if (
      order_type === "pickup" ||
      order_type === "third_party_pickup" ||
      order_type === "curbside_pickup" ||
      order_type === "dine_in"
    ) {
      message += "Order is completed. Thank you and enjoy your order!";
    } else if (order_type === "delivery") {
      message += `
                ${
                  data.order.delivery.delivery_partner === "lalamove"
                    ? `${formatDeliveryStatus(
                        "lalamove",
                        data.delivery_data.status,
                      )}`
                    : ""
                }
                ${
                  data.order.delivery.delivery_partner === "mrspeedy" &&
                  data.delivery_data.deliveries[0]
                    ? `${formatDeliveryStatus(
                        "mrspeedy",
                        data.delivery_data.deliveries[0].status,
                      )}`
                    : ""
                }
                ${
                  data.order.delivery.delivery_partner === "grab"
                    ? `${formatDeliveryStatus(
                        "grab",
                        data.delivery_data.status,
                      )}`
                    : ""
                }
            `;
    }
  } else if (status === "cancelled_full_refund") {
    message = "Your order has been cancelled and is subject for full refund.";
  } else if (status === "cancelled_partial_refund") {
    message =
      "Your order has been cancelled and is subject for partial refund.";
  } else if (status === "cancelled_no_refund") {
    message = "Your order has been cancelled and with no refund.";
  } else if (status === "cancelled_no_show") {
    message =
      "Your order has been cancelled and is marked by our staff as no show.";
  }

  return message;
}

/**
 *
 * @param {*} status
 * @param {*} order_type
 * @param {*} data
 * @returns
 */
export function getTitle(status = "", order_type = "", data) {
  let title = "";

  if (status === "new") {
    title = "We have received your order.";
  } else if (status === "accepted") {
    title = "Your order is now being prepared.";
  } else if (status === "ready") {
    let readyString = "";
    if (order_type === "delivery") {
      readyString = "delivery";
    } else if (order_type === "dine_in") {
      readyString = "serving";
    } else {
      readyString = "pickup";
    }
    title = `Your order is now ready for ${readyString}.`;
    // title = `Your order is now ready for ${(order_type === "pickup"||order_type==="third_party_pickup"||order_type==="curbside_pickup"||order_type==="dine_in") ?"pickup":"delivery"}.`;
  }

  //
  // else if(status === "out_for_delivery") {
  //     title = "Your order is now out for delivery! Delivery details were sent on your inbox.";
  // }
  else if (status === "completed") {
    if (order_type === "delivery") {
      if (data.delivery_data && data.delivery_data.status === "COMPLETED") {
        title = "";
      } else {
        title = "Order is now out for delivery!";
      }
    } else if (order_type === "dine_in") {
      title = "Order successfully served!";
    } else {
      title = "Order successfully picked up!";
    }
    // title = (order_type === "pickup"||order_type==="third_party_pickup"||order_type==="curbside_pickup"||order_type==="dine_in") ? "Order successfully picked up!" : "Order is now out for delivery.";
  } else if (
    status === "cancelled_full_refund" ||
    status === "cancelled_partial_refund" ||
    status === "cancelled_no_show" ||
    status === "cancelled_no_refund"
  ) {
    title = "Order is cancelled";
  } else if (
    status === "completed_full_refund" ||
    status === "completed_partial_refund"
  ) {
    title = "Order is now completed and subject for refund.";
  }

  return title;
}

/**
 *
 * @param {*} arrived_time
 * @returns
 */
export function canNotify(arrived_time) {
  console.log(arrived_time);
  const limit = moment(arrived_time).add(3, "minutes");
  let duration = moment.duration(moment(limit).diff(moment(new Date()))),
    interval = 1000,
    intervalId,
    timer = "03:00",
    timeElement = document.querySelector("#timer"),
    flw_btnEl = document.querySelector("#flw_up_btn");
  setInterval(function () {
    // get updated duration
    duration = moment.duration(duration - interval, "milliseconds");
    // if duration is >= 0
    if (duration.asSeconds() <= 0) {
      clearInterval(intervalId);
      if (flw_btnEl !== null) {
        // console.log(flw_btnEl)
        flw_btnEl.disabled = false;
        flw_btnEl.classList.remove("disabled");
        flw_btnEl.innerHTML = "I have arrived";
      }
      // hide the countdown element
      // timeElement.classList.add("hidden");
    } else {
      // otherwise, show the updated countdown
      timer =
        ("0" + duration.minutes()).slice(-2) +
        ":" +
        ("0" + duration.seconds()).slice(-2);
      if (timeElement !== null) {
        flw_btnEl.disabled = true;
        flw_btnEl.classList.add("disabled");
        timeElement.innerText = String(timer);
      }
    }
  }, interval);
  // let duration = moment.duration(moment(new Date()).diff(moment(arrived_time)));

  return duration.asSeconds() <= 0 || arrived_time === undefined;
}

/**
 *
 * @param {Number} totalAmount
 * @param {Number} discount
 * @returns
 */
export function computeDiscount(totalAmount, discount = 0) {
  return Math.round(totalAmount - totalAmount * (discount / 100));
}

export function getMealPlanDays(mealPlanType) {
  let number = mealPlanType.match(/\d+/);
  return number ? parseInt(number[0]) : 3; //defaults to 3 if no value
}

/**
 *
 * @param {Number} totalAmount
 * @param {Number} service_charge_fee
 * @returns
 */
export function computeServiceCharge(totalAmount, service_charge_fee = 0) {
  return Math.round(totalAmount * (service_charge_fee / 100));
}

export function browserStorage() {
  try {
    window.sessionStorage.setItem("test_key", "test");
    window.sessionStorage.removeItem("test_key");

    return window.sessionStorage;
  } catch (e) {
    console.log("No sessionStorage. Returning local storage.");
    return window.localStorage;
  }
}
