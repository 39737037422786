import React from "react";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";

export default function DisplayBrandsText(props) {
  const { user = {} } = props;
  return user.iam_role === "brand_admin" ? (
    <DisplayBrandsTextClass {...props} />
  ) : (
    <></>
  );
}

class DisplayBrandsTextClass extends APIComponent {
  constructor(props) {
    super(props);

    this.api = core("pu").get();
  }

  async getData() {
    const { user = {} } = this.props,
      { brands = [] } = user;

    this._isMounted && this.setState({ data_status: "fetching" });
    this.api &&
      this.api
        .post({ url: "/settings/get-brands-text", data: { brands } })
        .then(({ data }) => {
          const { data: responseData } = data;

          this._isMounted &&
            this.setState({
              data: responseData,
              data_status: "fetched",
            });
          this.handleOnDataFetched();

          if (this.use_display_data) {
            this.setDisplayData();
          }
        })
        .catch((error) => {
          this._isMounted &&
            this.setState({ error: error, showSwalError: true });
          this.handleOnDataFetchError();
        });
  }

  render() {
    const { data = [], data_status = "" } = this.state,
      isLoading = data_status !== "fetched";

    return isLoading ? (
      <>Loading...</>
    ) : (
      <>
        {data
          .map((d) => {
            return d.name;
          })
          .join(", ")}
      </>
    );
  }
}
