import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PRIMARY_COLOR } from "../constants";
const MySwal = withReactContent(Swal);

const RouteLeavingGuard = ({
  message,
  navigate,
  title = "Are you sure?",
  shouldBlockNavigation,
  showCloseButton,
  showCancelButton,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  const renderModal = () => {
    MySwal.fire({
      icon: "info",
      title: title,
      text: message,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCloseButton,
      showCancelButton,
    }).then((response) => {
      if (response.isConfirmed) handleConfirmNavigationClick();
      if (response.dismiss) closeModal();
    });

    return null;
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);

      return false;
    }

    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt message={handleBlockedNavigation} />
      {modalVisible ? renderModal() : null}
    </>
  );
};

RouteLeavingGuard.propTypes = {
  message: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
  shouldBlockNavigation: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  title: PropTypes.string,
};

export default RouteLeavingGuard;
