const helpertexts = {
  "getting-started": {
    brand_name: "Your brand name is the identity of your business.",
    brand_url:
      "The Brand URL is used as the subdomain for your branded Pickup.ph website.",
    brand_intro: "A brief intro or a tagline for your brand.",
    brand_description:
      "Your brand description could tell people about your products and service.",
    brand_logo:
      "The Brand Logo is used as a browser and app icon for your branded Pickup.ph website.",
    store_name: "",
    store_url: "You can only set this once.",
    store_location:
      "Let customers know the location of your store,\n since not all exact addresses are available on Google Maps.",
    store_contact_number: "Put a contact number so customers can reach you.",
    store_hours: "Set business hours for your store.",
    store_off_dates: "Off dates are the dates that you choose not to operate.",
    store_tips:
      "Once tips are enabled, customer can place tips on percentage basis: 2%, 5%, 10%.",
    is_accepting_in_advanced_orders:
      "By default, this store accepts only same-day orders. You can edit these settings to accept pre-orders for more than 1 day in advance below.",
    days_accepting_in_advanced_orders:
      "Set how many days do you allow day-advanced pre-orders",
    notify_store_before_pickup_time:
      "In default, we notify the store 15 minutes before pickup time. Set your preferred notification time in minutes.",
    address_pin:
      "set your store the address pin for delivery partners and for store visibility",
  },
  users: {
    roles:
      "Roles determine the level of accessibility of the user - whether it's on the group, brand or store level.",
    privileges:
      "Privileges allow the user to access the different features of Pickup.ph like stores, menu, orders, analytics, etc.",
    order_email_notification:
      "This will allow the user to receive order and reservation emails.",
    refund_email_notification:
      "This will allow the user to receive the refund emails.",
    status: "Once the user is marked as inactive, they won't be able to login.",
  },
  "branded-website": {
    mealplan_exclusive:
      "This category contains exclusive items that can be added once and is automatically added at the end of the week.",
  },
  promos: {
    promo_code:
      "Promo codes should be letters and numbers only, no special characters or spaces.",
    has_limit:
      "Toggle on if your promotion can only be used by limited number of times.",
    usage_limit: "You can set the number of times this promotion can be used.",
    has_minimum_purchase:
      "Promo codes require a minimum purchase with a default minimum value of 100.",
    minimum_purchase:
      "You can set the minimum purchase required before this promotion can be used.",
    start_date: "Set the start date of your promotion.",
    end_date: "Set the end date of your promotion.",
    featured:
      "Choose to highlight this promotion in marketplace stores as a featured offer",
    promo_scope:
      "You can set the scope of your promotion, whether it will be on group, brand or store level.",
  },
  announcements: {
    level:
      "You can set the scope of your announcement, whether it will be on group, brand or store level",
    end_date: "Set the end date of your announcement.",
    overlay: "This field will be overlaid on the image.",
    promo_overlay: "The promo code can be overlaid on the image.",
    alignment: "Set the alignment for promo code.",
  },
  reservations: {
    reservation_terms:
      "Your reservation can be cancelled at any time before the time of the reservation should your plans change. However, we request that you do so at least 24 hours in advance so that our store staff can plan accordingly.",
  },
};

export default helpertexts;
