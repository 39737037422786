import React from "react";
import { Modal } from "react-bootstrap";
import {
  Separator,
  Section,
  DFlex,
  OrderStatusActionButton2,
  DisplayTime,
  ThirdPartyName,
  DisplayPhone,
  OrderTotalDetails,
} from "../components/Base";
import {
  toPesoAmount,
  computeTotalCartAmount,
  isOrderReadOnly,
} from "../helpers";
import PerfectScrollbar from "react-perfect-scrollbar";

import "react-perfect-scrollbar/dist/css/styles.css";

export default class QuickOrderDetailsModal extends React.Component {
  render() {
    const { show = false, onHide, btnLoading = false } = this.props;
    const { order = {} } = this.props;

    const {
      transaction_number = "",
      guest_customer = {},
      order_time = "",
      order_details = [],
      order_type,
      pickup_details,
      notes = "",
    } = order;
    const {
      first_name = "",
      last_name = "",
      contact_number = "",
    } = guest_customer;

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Body>
          <PerfectScrollbar>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              <i aria-hidden="true" className="ki ki-close"></i>
            </button>
            <div className="d-flex flex-row justify-content-space-between mv-1rem text-uppercase font-weight-bolder font-order-section-header">
              <div>#{transaction_number}</div>
              <div>order details</div>
              {/* <div>{moment(order_time, DB_HOURS_FORMAT).format(TIME_OPTION_DISPLAY_FORMAT)}</div> */}
              <div>
                <DisplayTime order_time={order_time} />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-space-between mv-1rem text-uppercase font-weight-bolder font-order-section-header">
              <div>
                <div className="text-danger">
                  {first_name} {last_name}
                </div>
                <ThirdPartyName
                  orderType={order_type}
                  pickupDetails={pickup_details}
                />
              </div>
              <table>
                <tbody>
                  <tr>
                    <td>phone:</td>
                    <td>
                      <DisplayPhone
                        pickupDetails={pickup_details}
                        mainPhone={contact_number}
                      />
                    </td>
                  </tr>
                  {order_type === "third_party_pickup" ? (
                    <tr className="font-pickup-sm-2 font-weight-bolder">
                      <td className="text-label text-right">c/o: </td>
                      <td>
                        <DisplayPhone
                          orderType={order_type}
                          pickupDetails={pickup_details}
                          mainPhone={contact_number}
                        />
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
            <Separator solid />
            <Section
              header={
                <>
                  <div className="font-weight-bolder">Ordered Items</div>
                </>
              }
            />
            <div className="order-details modal-scrollable">
              {order_details.map(
                ({ item = {}, qty = 0, price = 0, extras = [] }, index) => {
                  return (
                    <div
                      className="item d-flex align-items-center mv-1rem inactive-link"
                      key={index}
                    >
                      <div className="item-photo-wrapper symbol symbol-40 symbol-2by3 flex-shrink-0 mr-4">
                        <div
                          className="symbol-label"
                          style={{ backgroundImage: `url(${item.image_url})` }}
                        ></div>
                      </div>
                      <div className="item-text-wrapper d-flex flex-column flex-grow-1">
                        <div className="font-weight-bolder">
                          {qty} x {item.name}
                        </div>
                        {extras.map(({ extra_id = {} }, index) => {
                          return <div key={index}>+ {extra_id.name}</div>;
                        })}
                      </div>
                      <div className="item-actions-wrapper d-flex align-items-center">
                        <span className="font-weight-bolder">
                          {" "}
                          {toPesoAmount(price * qty)}{" "}
                        </span>
                      </div>
                    </div>
                  );
                },
              )}
              {notes ? (
                <div className="item d-flex flex-wrap align-items-center my-5 inactive-link font-pickup-sm-2">
                  <div className="item-text-wrapper d-flex flex-column flex-grow-1">
                    <div className="font-weight-bolder">NOTES:</div>
                    <div className="item-actions-wrapper d-flex align-items-center break-lines">
                      {notes}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <Separator solid />
            <OrderTotalDetails order={order} />
          </PerfectScrollbar>
        </Modal.Body>
        <Modal.Footer>
          {this.props.display_buttons ? (
            <>
              {isOrderReadOnly(order.status) ? (
                <></>
              ) : (
                <OrderStatusActionButton2
                  btnBlock={true}
                  btnLoading={btnLoading}
                  onClick={this.props.actions.handleOnClickUpdateBtnInModal}
                  {...order}
                />
              )}
            </>
          ) : null}
        </Modal.Footer>
      </Modal>
    );
  }
}
