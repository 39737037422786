import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
// import helpertexts from "../../helpertexts";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import { HeaderTextWithActions } from "../../components/Base";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Button, Card, Col, Row } from "react-bootstrap";
import PromoForm from "../../partials/promos/PromoForm";

import { PRIMARY_COLOR } from "../../constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export default function Page() {
  const { id = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  return <MainComponent id={id} user={user} />;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "New Promo Code",
    };

    this.api = core("pu").get();
    this.data_url = "";

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = (values, setSubmitting) => {
    // values.amount_off = parseInt(values.amount_off);
    // values.percentage_off = parseInt(values.percentage_off);
    // values.minimum_purchase = parseInt(values.minimum_purchase);
    this.api
      .post({ url: "/promos", data: values })
      .then(({ data }) => {
        // console.log(data);
        if (data.result === "ok") {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            // timer: 1000
          });
        }
      })
      .then(() => {
        this.setState({ redirectTo: "/promos" });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  render() {
    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} push />
        ) : (
          <></>
        )}

        <HeaderTextWithActions
          // title={this.state.title}
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item
                text={"Promo Codes"}
                link={`${this.props.webview ? "/webview" : ""}/promos`}
              />
              <Breadcrumbs.Item text={this.state.title} active={"true"} />
            </Breadcrumbs>
          }
        />

        <PromoForm
          title={this.state.title}
          onSubmit={this.onSubmit}
          api={this.api}
          mode={`new`}
          user={this.props.user}
        />
      </>
    );
  }
}
