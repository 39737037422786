import { isEmpty } from "lodash";
import React from "react";
import { Modal } from "react-bootstrap";
import { toPesoAmount } from "../../../helpers";

export const RemovePromoModal = ({
  isPromoInvalid = false,
  minimumPurchaseErr = -1,
  onRemove,
  onHide,
  promo = null,
}) => {
  const applies_to_str =
    !isEmpty(promo) &&
    promo.applies_to.map((type) => type.split("_").join(" ")).join(", ");

  const modal_message =
    minimumPurchaseErr > -1
      ? `Minimum purchase of ${toPesoAmount(minimumPurchaseErr)} is required.`
      : `Selected promo only applies to ${applies_to_str || ""} orders.`;

  return (
    <Modal centered show={isPromoInvalid} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Invalid Promo</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-8">
        {!isEmpty(modal_message) && <span>{modal_message}</span>}
        &nbsp;
        {!isEmpty(promo) && <span>Do you wish to remove promo?</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate mr-2"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={!isEmpty(promo) ? onRemove : onHide}
            className="btn btn-primary btn-elevate"
          >
            {!isEmpty(promo) ? "Remove" : "Ok"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
