import React, { Component } from "react";

import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { useParams, Redirect } from "react-router-dom";

import moment from "moment";

import { HeaderTextWithActions } from "../../components/Base";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import GroupDropdown from "../../components/dashboard/GroupDropdown";
import Row1 from "../../components/dashboard/Row1";
import Row2 from "../../components/dashboard/Row2";
import Row3 from "../../components/dashboard/Row3";
import Breadcrumbs from "../../components/Breadcrumbs";

import { DB_DATE_FORMAT, DATERANGE_LIST } from "../../constants";
import { generatePickupPhLink } from "../../helpers";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";

export default function Page() {
  const { brand_url = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  return <MainComponent id={brand_url} user={user} />;
}

class MainComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: true,
      redirectTo: "",
      title: "Dashboard",
      brand_name: "Brands",
    };

    this.api = core("pu").get();

    this.getLoggedInUser = this.getLoggedInUser.bind(this);
  }

  getLoggedInUser = (field = "fullname") => {
    const { user = {} } = this.props;
    if (field === "fullname") {
      return user.first_name + " " + user.last_name;
    } else {
      return user[field];
    }
  };

  render() {
    const actions = {
      getLoggedInUser: this.getLoggedInUser,
      setBrandName: (brand_name = "") => {
        this.setState({ brand_name });
      },
      setSplashScreen: (showSplashScreen = true) => {
        this.setState({ showSplashScreen });
      },
      setRedirectTo: (redirectTo = "") => {
        this.setState({ redirectTo });
      },
    };

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <BrandComponent
          brand={this.props.id}
          store={this.props.user.store}
          api={this.api}
          actions={actions}
          role={this.props.user.iam_role}
        />
      </>
    );
  }
}

class BrandComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.data_url = "/analytics";
    this.api = this.props.api;
    this.statCardQuery = "day";
    this.selectedBrand = {};

    this.handleDateRangeClick.bind(this);
  }

  handleOnDataFetched = () => {
    const { name = "" } = this.state.data;

    this.props.actions.setBrandName(name);
    this.props.actions.setSplashScreen(false);
  };

  handleDateRangeClick = async (e, date = moment().format(DB_DATE_FORMAT)) => {
    let statCardDataRange = "";
    const dropdownText = e.currentTarget.textContent || "day";

    if (
      dropdownText === "This Week" ||
      dropdownText === "Last Week" ||
      dropdownText === "Next Week"
    ) {
      statCardDataRange = "week";
    } else if (dropdownText === "This Month" || dropdownText === "Last Month") {
      statCardDataRange = "month";
    } else if (dropdownText === "This Year" || dropdownText === "Last Year") {
      statCardDataRange = "year";
    } else if (dropdownText === "All-time") {
      statCardDataRange = "allTime";
    } else {
      statCardDataRange = "day";
    }

    this.setState({
      statCardQuery: statCardDataRange,
      date,
      data_status: "fetching",
    });
    setTimeout(() => {
      this.getData();
    }, 500);
  };

  handleChangeSelectedBrand = async (item) => {
    this.setState(
      {
        selectedBrand: item,
        // statCardQuery: statCardDataRange,
        data_status: "fetching",
      },
      () => {
        setTimeout(() => this.getData());
      },
    );
  };

  async getData() {
    this._isMounted && this.setState({ data_status: "fetching" });
    let date = this.state.date || moment().format(DB_DATE_FORMAT);
    let statCardRange = this.state.statCardQuery || "day";
    let view = this.state.selectedBrand || { id: "", name: "All" };

    const getStats = (
      rowNo,
      statCardRange = "day",
      date = moment().format(DB_DATE_FORMAT),
    ) => {
      return new Promise((resolve, reject) => {
        this.api
          .get(
            `/analytics/${rowNo}?stat_cards=${statCardRange}&date=${date}&view=${view.id}`,
          )
          .then((data) => resolve(data.data))
          .catch((err) => reject(err));
      });
    };

    try {
      const rows = await Promise.all([
        getStats("header", statCardRange, date),
        getStats("row1", statCardRange, date),
      ]);
      const [header, row1] = rows;

      this._isMounted &&
        this.setState({
          data: {
            // stats: [row1, row2, row3],
            header,
            row1,
          },
          data_status: "fetched",
          order_fetch_status: "fetched",
        });

      this.handleOnDataFetched();
      if (this.use_display_data) {
        this.setDisplayData();
      }
    } catch (error) {
      this._isMounted && this.setState({ error: error, showSwalError: true });
      this.handleOnDataFetchError();
    }
  }

  render() {
    const {
        data_status = "",
        data = {},
        statCardQuery = "day",
        date = moment().format(DB_DATE_FORMAT),
        selectedBrand = { name: "All", id: "", attr: "brand" },
      } = this.state,
      isLoading = data_status !== "fetched";
    const { header, row1 } = data;

    function dropdownText(dateRange, date) {
      const isCurrent = moment(moment().format(DB_DATE_FORMAT)).isSame(
        date,
        dateRange,
      );
      const prefix = isCurrent ? "This" : "Last";

      // if(dateRange=== 'week') return `${prefix} Week`
      if (dateRange === "week") {
        if (
          moment(moment().add(1, "weeks").format(DB_DATE_FORMAT)).isSame(date)
        ) {
          return "Next Week";
        }

        return `${prefix} Week`;
      }
      if (dateRange === "month") return `${prefix} Month`;
      if (dateRange === "year") return `${prefix} Year`;
      if (dateRange === "allTime") return "All-time";
      return `${isCurrent ? "Today" : "Yesterday"}`;
    }

    const brandLink = selectedBrand.id
      ? generatePickupPhLink(selectedBrand.brand_url)
      : "";
    const brandLinkText = selectedBrand.id
      ? generatePickupPhLink(selectedBrand.brand_url, false)
      : "";

    return (
      <>
        {isLoading ? (
          <>
            <div className="d-flex justify-content-center align-items-center h-100">
              <PacmanWrapper removeBackgroundColor />
            </div>
          </>
        ) : (
          <>
            <HeaderTextWithActions
              title={selectedBrand.name}
              className="mb-8"
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item text={"Dashboard"} />
                  <Breadcrumbs.Item
                    text={brandLinkText}
                    link={{ pathname: brandLink }}
                    linkProps={{
                      target: "_blank",
                      rel: "noopener noreferrer",
                    }}
                  />
                </Breadcrumbs>
              }
            />

            <div className="d-flex justify-content-end p-4 pb-0 ml-0 ml-md-auto bg-white rounded-top">
              <GroupDropdown
                items={header}
                hasRollup={true}
                attr={selectedBrand.attr}
                onSelectView={this.handleChangeSelectedBrand}
                selectedView={selectedBrand}
                className={"mr-2"}
                isSuperAdmin={true}
              />

              <Dropdown className="">
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  {dropdownText(statCardQuery, date)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {DATERANGE_LIST.map((item) => (
                    <Dropdown.Item
                      onClick={(e) => this.handleDateRangeClick(e, item.date)}
                      key={item.name}
                    >
                      {item.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="container bg-white py-4 px4 rounded rounded-right-0 rounded-bottom">
              <Row1
                brand={this.props.brand}
                date={date}
                row1={row1}
                statCardQuery={statCardQuery}
                statLink={`${
                  this.props.webview ? "/webview" : ""
                }/admin/reports`}
                view={selectedBrand.id}
                attr={selectedBrand.attr}
                role={this.props.role}
                brands={header.map((brand) => brand.id)}
              />
              <Row2
                params={{
                  date,
                  dateRange: statCardQuery,
                  id: selectedBrand.id,
                  attr: selectedBrand.attr,
                }}
                header={`Sold Items by ${
                  !selectedBrand.id ? "Brand" : "Category"
                }`}
              />
            </div>

            <Row3
              statCardQuery={statCardQuery}
              date={date}
              params={{
                date,
                dateRange: statCardQuery,
                id: selectedBrand.id,
                attr: selectedBrand.attr,
              }}
              dropdownText={dropdownText}
              selectedBrand={selectedBrand}
            />
          </>
        )}
      </>
    );
  }
}
