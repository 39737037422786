import React, { Component } from "react";

import "../../../../_metronic/_assets/sass/pages/login/login-3a.scss";
import "../../../../_metronic/_assets/sass/pages/wizard/wizard-universal.scss";
import {
  FormItem,
  Label,
  ErrorAlert,
  GreenCheckbox,
} from "../../../components/Base";
import PaymongoWizard from "../../../components/PaymongoWizard";
import { FormControlLabel } from "@material-ui/core";
import ProSourceForm from "../../../components/ProSourceForm";
import {
  setStorage,
  getStorage,
  removeStorage,
} from "../../../../_metronic/_helpers";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { Form, Card, Row, Col } from "react-bootstrap";
import { GetDataFromEvent, GetSubmitClassList } from "../../../helpers";
import core from "../../../vendors/core-api";
import * as auth from "../_redux/authRedux";
import { connect } from "react-redux";
import LoginCenter from "../../../components/LoginCenter";
import TitleHelmet from "../../../partials/TitleHelmet";
import { login } from "../_redux/authCrud";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link, Redirect } from "react-router-dom";
import qs from "qs";
import { BETA_TEST_AGREEMENT_LINK } from "../../../constants";
const publicIp = require("react-public-ip");

class SignUp extends Component {
  constructor(props) {
    super(props);

    const { mode = "paymongo" } = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });

    this.state = {
      paymongo_on: mode === "paymongo",
      loading: true,
      wizardState: "first",
      items: [
        {
          title: "Sign Up",
          description: "Get started with Pickup.ph by providing your email.",
          state: "current",
        },
        {
          title: "Tell us about yourself",
          description: "We're excited to see you onboard.",
          state: "pending",
        },
        // { title : "Select your subscription", description : "whether you’re a single shop or a multi-brand store, we have a plan for you.", state: "pending" },
        {
          title: "Activate your PayMongo account",
          description:
            "Before you can process payments on PayMongo, we need to learn more information about you and your business.",
          state: "pending",
        },
        {
          title: "Set up your payment",
          description:
            "Your subscription starts as soon as you set up your payment.",
          state: "pending",
        },
      ],
      currentIndex: 0,
      formValues: { user: {}, subs: { name: "starter" }, client: {} },
      // plans: [
      //     {
      //         "name" : "Starter Plan",
      //         "description" : "Get your online store started with a free landing page for your customers to see.",
      //         "features" : [
      //             "Branded Sub-domain",
      //             "Business Logo",
      //             "Business Contact Information",
      //             "Online Menu"
      //         ],
      //         "price" : "Free",
      //         "popular" : false,
      //         "selected" : true,
      //         "value" : "starter"
      //     },
      //     {
      //         "name" : "Business Plan",
      //         "description" : "Everything you need to create an online ordering system with full payment and delivery integration.",
      //         "features" : [
      //             "Branded Shopping Cart",
      //             "Admin Panel",
      //             "Order Management",
      //             "In-store Tablet Interface",
      //             "Real-time Dashboards",
      //             "Online ePayment Integration",
      //             "Angkas Delivery Integration",
      //             "Order Email Automation"
      //         ],
      //         "price" : "PHP 800 per store per year",
      //         "popular" : false,
      //         "selected" : false,
      //         "value" : "business"
      //     },
      //     {
      //         "name" : "Unlimited Plan",
      //         "description" : "Everything you love from the Business Plan along with additional features to unlock unlimited store power.",
      //         "features" : [
      //             "Multi-store Management",
      //             "Multi-brand Management",
      //             "Abandoned Cart Recovery",
      //             "Customer Re-marketing Tools",
      //             "Branded iOS & Android App",
      //             "Loyalty Programs",
      //             "In-house Delivery Scheduler"
      //         ],
      //         "price" : "PHP 999 per store per year",
      //         "popular" : true,
      //         "selected" : false,
      //         "value" : "unlimited"
      //     }
      // ],
      signUpLoading: false,
      token: "",
      showWelcomeBack: false,
      showWelcomeBackWithPassword: false,
      userInToken: {},
      showWizard: true,
      existingUser: {},
      disablePreviousInPricing: false,
      redirectTo: "",
    };

    this.handleOnClickPrev = this.handleOnClickPrev.bind(this);
    this.handleOnCheckEmailAvailability =
      this.handleOnCheckEmailAvailability.bind(this);
    this.handleOnSubmitBusinessData =
      this.handleOnSubmitBusinessData.bind(this);
    this.handleOnClickItem = this.handleOnClickItem.bind(this);
    this.handleOnSubmitPlanSelect = this.handleOnSubmitPlanSelect.bind(this);
    this.handleOnSubmitPayment = this.handleOnSubmitPayment.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleOnClickContinueFromWelcomeback =
      this.handleOnClickContinueFromWelcomeback.bind(this);
    this.handleOnClickDifferentUserFromWelcomeback =
      this.handleOnClickDifferentUserFromWelcomeback.bind(this);
    this.handleOnSubmitPasswordForm =
      this.handleOnSubmitPasswordForm.bind(this);
    // this.uploadMerchantFile = this.uploadMerchantFile.bind(this);

    this.getWizardState = this.getWizardState.bind(this);
    this.getCurrentIndex = this.getCurrentIndex.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getSalesAgent = this.getSalesAgent.bind(this);

    this.loginUser = this.loginUser.bind(this);

    this.api = core("pu").get();
    // this.fileapi = core("puf").get();
    this._mounted = false;
  }

  handleError = (error) => {
    console.log(error);
  };

  async componentDidMount() {
    this._mounted = true;

    this.getSalesAgent();
    this.getApiClient();

    const token = getStorage("PRK");
    const ipv4 = (await publicIp.v4()) || "";

    if (token !== null) {
      core().setAuthToken(token);
      this._mounted && this.setState({ token, ip_address: ipv4 });
      this.getUser();
    } else {
      this._mounted && this.setState({ loading: false, ip_address: ipv4 });
    }
  }

  async getUser() {
    this.api
      .get("/users/me")
      .then(({ data }) => {
        this.setState({
          showWelcomeBack: true,
          showWizard: false,
          userInToken: data,
          existingUser: data,
        });
      })
      .catch(() => {
        this.handleError();
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleOnCheckEmailAvailability = (values, setSubmitting) => {
    let { formValues = {} } = this.state;
    this.api
      .post({
        url: "/external/users/validate",
        data: values,
      })
      .then(({ data }) => {
        setSubmitting(false);

        const { existing = false, user = {} } = data;

        formValues.user = values;

        if (existing) {
          if (user.registration_status === "for_subscription") {
            this.setState({
              showWelcomeBackWithPassword: true,
              showWizard: false,
              existingUser: user,
              formValues,
            });
          } else {
            this.setState({ redirectTo: "/auth/login?email=" + values.email });
          }
        } else {
          this.setState({ currentIndex: 1, formValues, showWizard: true });
        }
      })
      .catch(() => {
        setSubmitting(false);
        this.handleError();
      });
  };

  handleOnSubmitBusinessData = (values, setSubmitting) => {
    let { formValues = {}, paymongo_on = false } = this.state;

    let agent = this.getSalesAgent();
    let apiclient = this.getApiClient();
    paymongo_on =
      process.env.REACT_APP_ONBOARDING === "true" ? false : paymongo_on; // turn off paymongo if onboarding is active
    const {
      email = "",
      password = "",
      first_name = "",
      last_name = "",
      contact_number = "",
      company_name = "",
      company_address = "",
    } = values;
    formValues.user = {
      email,
      password,
      first_name,
      last_name,
      contact_number,
      iam_role: "group_admin",
      order_email_notification: true,
      refund_email_notification: true,
    };

    formValues.client = {
      name: company_name,
      address: company_address,
      registration_status: "completed",
      signup_type: agent ? "inside_sales" : "self_signup",
      apiclient_name: apiclient,
      agent,
    };

    this.api
      .post({
        url: "/users/legacy/setup",
        data: formValues,
      })
      .then(({ data: { token } }) => {
        setStorage("PRK", token);
        core().setAuthToken(token);

        if (paymongo_on) {
          setSubmitting(false);
          this.setState({
            formValues,
            currentIndex: 2,
            token,
            existingUser: formValues.user,
          });
        } else {
          this.setState({ loading: true });
          setTimeout(() => {
            this.loginUser(token);
          }, 50);
        }
      })
      .catch(() => {
        this.handleError();
      });
  };

  getSalesAgent = () => {
    const { pathname = "" } = this.props.location;

    const isInternalSales = pathname.split("/").includes("sales");

    if (isInternalSales) {
      const { params } = this.props.match;

      let { agent = "internal" } = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      });

      agent = params.agent || agent;

      return agent;
    }

    return "";
  };

  getApiClient = () => {
    const { pathname = "" } = this.props.location;

    const isApiClient = pathname.split("/").includes("partner");

    if (isApiClient) {
      const { params } = this.props.match;

      let { api_client = "" } = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      });

      api_client = params.api_client || api_client;

      return api_client;

      //TODO: add error for invalid api client
    }

    return "";
  };
  handleOnSubmitPaymongoData = (values, setSubmitting) => {
    const { ip_address = "" } = this.state;

    values.ip_address = ip_address;
    values.timestamp = Date.now();

    this.api
      .post({
        url: `/client/paymongo-form/${this.state.token}`,
        data: values,
      })
      .then(() => {
        setSubmitting(false);
        this.handleOnSubmitPayment();
      })
      .catch(() => {
        this.handleError();
      });
  };

  handleOnClickItem = (e) => {
    const value = GetDataFromEvent(e, "data-value");
    let { plans = [], formValues = {} } = this.state;

    formValues.subs.name = value;

    this.setState({
      plans: plans.map((plan) => {
        plan.selected = plan.value === value;
        return plan;
      }),
      formValues,
    });
  };

  handleOnSubmitPlanSelect = () => {
    const { formValues = {}, token = "" } = this.state;
    this.setState({ signUpLoading: true });

    if (formValues.subs.name === "starter") {
      core().setAuthToken(token);
      this.api
        .post({
          url: "/users/update-subscription",
          data: formValues,
        })
        .then(() => {
          this.loginUser(token);
        })
        .catch(() => {
          this.handleError();
        });
    } else {
      // Go to payment
      this.setState({ signUpLoading: false, currentIndex: 3 });
    }
  };

  handleOnSubmitPayment = () => {
    const { formValues = {}, token = "" } = this.state;

    this.setState({ signUpLoading: true });
    core().setAuthToken(token);
    this.api
      .post({
        url: "/users/update-subscription",
        data: formValues,
      })
      .then(() => {
        this.loginUser(token);
      })
      .catch(() => {
        this.handleError();
      });
  };

  handleOnClickPrev = () => {
    let { currentIndex = 0 } = this.state;
    this.setState({ currentIndex: currentIndex - 1 });
  };

  handleOnClickContinueFromWelcomeback = () => {
    this.setState({
      currentIndex: 0,
      showWelcomeBack: false,
      showWelcomeBackWithPassword: false,
      showWizard: true,
      disablePreviousInPricing: true,
    });
  };

  handleOnClickDifferentUserFromWelcomeback = (e) => {
    e.preventDefault();
    core().resetAuthToken();
    removeStorage("PRK");

    this.setState({
      token: "",
      userInToken: {},
      showWelcomeBack: false,
      showWelcomeBackWithPassword: false,
      showWizard: true,
      disablePreviousInPricing: false,
    });
  };

  handleOnSubmitPasswordForm = (values, setSubmitting, setStatus) => {
    const { formValues = {} } = this.state;
    login(formValues.user.email, values.password)
      .then(({ data: { token } }) => {
        setSubmitting(false);
        this.setState({
          token,
          currentIndex: 0,
          showWelcomeBack: false,
          showWelcomeBackWithPassword: false,
          showWizard: true,
          disablePreviousInPricing: true,
        });
      })
      .catch(() => {
        setSubmitting(false);
        setStatus(
          this.props.intl.formatMessage(
            { id: "AUTH.VALIDATION.INVALID_LOGIN" },
            { name: formValues.email },
          ),
        );
      });
  };

  getWizardState = (index) => {
    return this.state.items[index].state === "current" ? "current" : null;
  };

  getCurrentIndex = () => {
    return this.state.currentIndex;
  };

  loginUser = (token) => {
    this.setState({ signUpLoading: false, loading: true });

    setTimeout(() => {
      this.props.login(token);
    }, 1000);
  };

  render() {
    const {
        currentIndex = 0,
        items = [],
        loading = false,
        userInToken = {},
        existingUser = {},
        formValues: {
          client: { name = "" },
        },
      } = this.state,
      selectedItem = items[currentIndex];

    return (
      <>
        <TitleHelmet title="Sign Up" />
        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}
        {loading ? (
          <LayoutSplashScreen />
        ) : (
          <LoginCenter>
            <LoginCenter.Form>
              {this.state.showWelcomeBack ? (
                <Card className="card-border-top-primary card-custom animate__animated animate__fadeIn">
                  <Card.Body>
                    <div className="mb-10">
                      <h3 className="font-size-h1">
                        Welcome back, {userInToken.first_name}{" "}
                        {userInToken.last_name}!
                      </h3>
                      <hr />
                    </div>

                    <p className="font-weight-bolder">
                      You haven't finished setting up your PayMongo. <br /> Do
                      you want to continue?{" "}
                    </p>

                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                      <button
                        type="button"
                        className={GetSubmitClassList(
                          false,
                          "btn btn-primary font-weight-bold px-9 py-4 my-3 btn-block",
                        )}
                        onClick={this.handleOnClickContinueFromWelcomeback}
                      >
                        <span>Continue</span>
                      </button>
                    </div>

                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={this.handleOnClickDifferentUserFromWelcomeback}
                      >
                        Not {userInToken.first_name} {userInToken.last_name}?
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              ) : (
                <></>
              )}

              {this.state.showWelcomeBackWithPassword ? (
                <Card className="card-border-top-primary card-custom animate__animated animate__fadeIn">
                  <Card.Body>
                    <div className="mb-10">
                      <h3 className="font-size-h1">
                        Welcome back {existingUser.first_name}{" "}
                        {existingUser.last_name}!
                      </h3>
                      <hr />
                    </div>

                    <p className="font-weight-bolder">
                      You haven't finished setting up your subscription. <br />{" "}
                      Please enter your password to continue.{" "}
                    </p>

                    <PasswordForm onSubmit={this.handleOnSubmitPasswordForm} />

                    <div className="text-center">
                      <p>
                        Not {existingUser.first_name} {existingUser.last_name}?
                        <br />
                        Unfortunately this email is taken by an existing
                        account.
                      </p>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={this.handleOnClickDifferentUserFromWelcomeback}
                      >
                        Sign up with a different email?
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              ) : (
                <></>
              )}

              {this.state.showWizard ? (
                <Card className="card-border-top-primary card-custom animate__animated animate__fadeIn">
                  <Card.Body>
                    <div className="mb-10">
                      <h3 className="font-size-h1">{selectedItem.title}</h3>
                      <p>
                        {currentIndex === 2 ? (
                          <>
                            Hi{" "}
                            {existingUser
                              ? existingUser.first_name +
                                " " +
                                existingUser.last_name
                              : ""}
                            ,
                          </>
                        ) : (
                          <></>
                        )}{" "}
                        {selectedItem.description}
                      </p>
                      <hr />
                    </div>
                    {currentIndex === 0 ? (
                      <Step1
                        onSubmit={this.handleOnCheckEmailAvailability}
                        data={this.state.formValues}
                      />
                    ) : (
                      <></>
                    )}
                    {currentIndex === 1 ? (
                      <Step2SelfService
                        onSubmit={this.handleOnSubmitBusinessData}
                        handleOnClickPrev={this.handleOnClickPrev}
                        data={this.state.formValues}
                      />
                    ) : (
                      <></>
                    )}
                    {currentIndex === 2 ? (
                      <Step3
                        user={existingUser}
                        entityName={name}
                        onSubmit={this.handleOnSubmitPaymongoData}
                        handleOnClickPrev={this.handleOnClickPrev}
                        onClickItem={this.handleOnClickItem}
                        handleOnClickNext={this.handleOnSubmitPlanSelect}
                        isSubmitting={this.state.signUpLoading}
                        hidePreviousBtn={this.state.disablePreviousInPricing}
                        uploadMerchantFile={this.uploadMerchantFile}
                        token={this.state.token}
                      />
                    ) : (
                      <></>
                    )}
                    {currentIndex === 3 ? (
                      <Step4
                        handleOnClickPrev={this.handleOnClickPrev}
                        handleOnClickNext={this.handleOnSubmitPayment}
                        isSubmitting={this.state.signUpLoading}
                      />
                    ) : (
                      <></>
                    )}
                  </Card.Body>
                </Card>
              ) : (
                <></>
              )}
            </LoginCenter.Form>
          </LoginCenter>
        )}
      </>
    );
  }
}

class Step1 extends ProSourceForm {
  constructor(props) {
    super(props);

    this.state = {
      cbox: false,
      values: { email: "", sales_representative: props.agent },
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {
      required: ["email"],
    };
  }

  onChange = (event) => {
    const target = event.target,
      value = target.value,
      name = target.name;
    let { errors } = this.state;

    if (name === "email" && value !== "") {
      const valid = this.validateEmail(value);
      if (!valid) {
        errors.email = "Please enter a valid email";
      } else {
        delete errors.email;
      }
      this.setState({ errors: errors });
    }
  };

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <>
        <Form
          className="fv-plugins-bootstrap fv-plugins-framework"
          onSubmit={this.handleOnSubmit}
        >
          <FormItem
            solid
            label={"Email"}
            name={"email"}
            inputProps={{
              name: "email",
              placeholder: "Email",
              value: this.state.values["email"],
            }}
            inputActions={inputActions}
            actions={actions}
          />
          {/* <FormItem solid 
                    label={"Password"}
                    name={"password"}
                    inputProps={{
                        name: "password",
                        placeholder: "Password",
                        value: this.state.values["password"],
                    }}
                    inputActions={inputActions}
                    actions={actions}
                    type={"password"}
                /> */}
          <FormControlLabel
            style={{ fontSize: 20 }}
            control={
              <GreenCheckbox
                style={{ pointerEvents: "auto" }}
                onChange={(event) => {
                  const { cbox } = this.state;
                  this.setState({
                    cbox: !cbox,
                  });
                }}
              />
            }
            label={
              <>
                {" "}
                <span className={"font-weight-bold"}>
                  By signing up to a Merchant account you agree to <br />{" "}
                  <a
                    href={BETA_TEST_AGREEMENT_LINK}
                    rel="noopener noreferrer"
                    className={"font-weight-bold"}
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>{" "}
                  of the Beta Test Agreement{" "}
                </span>
              </>
            }
          />
          {/* begin::Actions */}
          <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
            <button
              type="submit"
              disabled={!this.state.cbox || this.state.isSubmitting}
              className={GetSubmitClassList(
                this.state.isSubmitting,
                "btn btn-primary font-weight-bold px-9 py-4 my-3 btn-block",
              )}
            >
              <span>Next</span>
            </button>
          </div>

          {/* end::Actions */}
        </Form>
        <div className="text-center">
          <Link
            to="/auth/login"
            className="text-hover-primary my-3 mr-2 kt-link"
          >
            Existing user? Do you want to login instead?
          </Link>
        </div>
      </>
    );
  }
}

// class Step2 extends ProSourceForm {
//     constructor(props) {
//         super (props);

//         const { user : { email = "" }} = this.props.data;

//         this.state = {
//             // values : { email, first_name: "", last_name: "", contact_number: "", password: "", company_name: "", company_address: "" },
//             values : { email, first_name: "", last_name: "", contact_number: "", password: "" },
//             errors : {},
//             touched: {},
//             isSubmitting: false
//         };

//         this.validate = {
//             // "required" : ["password", "first_name", "last_name", "company_name", "company_address", "contact_number" ]
//             "required" : ["password", "first_name", "last_name", "company_name" ]
//         }
//     }

//     onSubmit = (values, setSubmitting) => {
//         this.props.onSubmit(values, setSubmitting);
//     }

//     render () {
//         const actions = { handleFeedbackError: this.handleFeedbackError, isTouched: this.isTouched }, inputActions = { onChange: this.handleOnChange, onBlur: this.handleOnBlur };

//         return <Form className="fv-plugins-bootstrap fv-plugins-framework" onSubmit={this.handleOnSubmit}>
//             <Form.Group className={"fv-plugins-icon-container"}>
//                 <Label text="Email"/>
//                 <Form.Control className="border-0 rounded-lg font-size-h6" plaintext readOnly defaultValue={this.state.values.email}/>
//             </Form.Group>

//             <FormItem solid
//                 label={"Password"}
//                 name={"password"}
//                 inputProps={{
//                     name: "password",
//                     placeholder: "Password",
//                     value: this.state.values["password"],
//                 }}
//                 inputActions={inputActions}
//                 actions={actions}
//                 type={"password"}
//             />
//             <Row>
//                 <Col>
//                     <FormItem solid
//                         label={"First Name"}
//                         name={"first_name"}
//                         inputProps={{
//                             name: "first_name",
//                             placeholder: "First Name",
//                             value: this.state.values["first_name"],
//                         }}
//                         inputActions={inputActions}
//                         actions={actions}
//                     />
//                 </Col>
//                 <Col>
//                     <FormItem solid
//                         label={"Last Name"}
//                         name={"last_name"}
//                         inputProps={{
//                             name: "last_name",
//                             placeholder: "Last Name",
//                             value: this.state.values["last_name"],
//                         }}
//                         inputActions={inputActions}
//                         actions={actions}
//                     />
//                 </Col>
//             </Row>

//             <FormItem solid
//                 label={"Contact Number"}
//                 name={"contact_number"}
//                 inputProps={{
//                     name: "contact_number",
//                     placeholder: "Contact Number",
//                     value: this.state.values["contact_number"],
//                 }}
//                 inputActions={inputActions}
//                 actions={actions}
//             />

//              {/* <FormItem solid
//                 label={"Company Name"}
//                 name={"company_name"}
//                 inputProps={{
//                     name: "company_name",
//                     placeholder: "Company Name",
//                     value: this.state.values["company_name"],
//                 }}
//                 inputActions={inputActions}
//                 actions={actions}
//             />
//             <FormItem solid
//                 label={"Company Address"}
//                 name={"company_address"}
//                 inputProps={{
//                     name: "company_address",
//                     placeholder: "Company Address",
//                     value: this.state.values["company_address"],
//                 }}
//                 inputActions={inputActions}
//                 actions={actions}
//                 type={"textarea"}
//             /> */}

//             {/* begin::Actions */}
//             <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
//                 <button type="button" disabled={this.state.isSubmitting} className={`btn btn-secondary font-weight-bold px-9 py-4 my-3`} onClick={this.props.handleOnClickPrev}>
//                     <span>Previous</span>
//                 </button>
//                 <button type="submit" disabled={this.state.isSubmitting} className={GetSubmitClassList(this.state.isSubmitting, "btn btn-primary font-weight-bold px-9 py-4 my-3")}>
//                     <span>Next</span>
//                 </button>
//             </div>
//             {/* end::Actions */}
//         </Form>
//     }
// }

class Step2SelfService extends ProSourceForm {
  constructor(props) {
    super(props);

    const {
      user: { email = "" },
    } = this.props.data;

    this.state = {
      values: {
        email,
        first_name: "",
        last_name: "",
        contact_number: "",
        password: "",
        company_name: "",
        company_address: "",
        business_type: "Individual",
      },
      // values : { email, first_name: "", last_name: "", contact_number: "", password: "" },
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {
      required: [
        "password",
        "first_name",
        "last_name",
        "company_name",
        "company_address",
        "contact_number",
      ],
    };
  }

  onChange = () => {
    const { values = {} } = this.state,
      { business_type = "Individual" } = values;

    if (business_type === "Individual") {
      this.validate = {
        required: [
          "password",
          "first_name",
          "last_name",
          "company_name",
          "contact_number",
        ],
      };
    } else {
      this.validate = {
        required: [
          "password",
          "first_name",
          "last_name",
          "company_name",
          "company_address",
          "contact_number",
        ],
      };
    }
  };

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    const isFormValid = () => {
      const { values } = this.state;
      const { password, first_name, last_name, contact_number, company_name } =
        values;
      return (
        password.trim().length >= 6 &&
        first_name.trim().length &&
        last_name.trim().length &&
        contact_number.trim().length &&
        company_name.trim().length
      );
    };

    return (
      <Form
        className="fv-plugins-bootstrap fv-plugins-framework"
        onSubmit={this.handleOnSubmit}
      >
        <Form.Group className={"fv-plugins-icon-container"}>
          <Label text="Email" />
          <Form.Control
            className="border-0 rounded-lg font-size-h6"
            plaintext
            readOnly
            defaultValue={this.state.values.email}
          />
        </Form.Group>

        <FormItem
          solid
          label={"Password"}
          name={"password"}
          inputProps={{
            name: "password",
            placeholder: "Password",
            value: this.state.values["password"],
          }}
          inputActions={inputActions}
          actions={actions}
          type={"password"}
        />
        <Row>
          <Col>
            <FormItem
              solid
              label={"First Name"}
              name={"first_name"}
              inputProps={{
                name: "first_name",
                placeholder: "First Name",
                value: this.state.values["first_name"],
              }}
              inputActions={inputActions}
              actions={actions}
            />
          </Col>
          <Col>
            <FormItem
              solid
              label={"Last Name"}
              name={"last_name"}
              inputProps={{
                name: "last_name",
                placeholder: "Last Name",
                value: this.state.values["last_name"],
              }}
              inputActions={inputActions}
              actions={actions}
            />
          </Col>
        </Row>

        <FormItem
          solid
          label={"Contact Number"}
          name={"contact_number"}
          inputProps={{
            name: "contact_number",
            placeholder: "Contact Number",
            value: this.state.values["contact_number"],
          }}
          inputActions={inputActions}
          actions={actions}
        />

        <Form.Group>
          <Label text="I am a" />
          <div className="radio-inline">
            <label className="radio radio-primary">
              <input
                type="radio"
                name="business_type"
                value="Individual"
                {...inputActions}
                checked={
                  this.state.values["business_type"] === "Individual"
                    ? "checked"
                    : ""
                }
              />
              Sole Proprietor
              <span></span>
            </label>
            <label className="radio radio-primary">
              <input
                type="radio"
                name="business_type"
                value="Company"
                {...inputActions}
                checked={
                  this.state.values["business_type"] === "Company"
                    ? "checked"
                    : ""
                }
              />
              Company
              <span></span>
            </label>
          </div>
        </Form.Group>

        {this.state.values["business_type"] === "Individual" ? (
          <>
            <FormItem
              solid
              label={"Legal Entity Name"}
              name={"company_name"}
              inputProps={{
                name: "company_name",
                placeholder: "Legal Entity Name",
                value: this.state.values["company_name"],
              }}
              inputActions={inputActions}
              actions={actions}
            />
          </>
        ) : (
          <></>
        )}

        {this.state.values["business_type"] === "Company" ? (
          <>
            <FormItem
              solid
              label={"Company Name"}
              name={"company_name"}
              inputProps={{
                name: "company_name",
                placeholder: "Company Name",
                value: this.state.values["company_name"],
              }}
              inputActions={inputActions}
              actions={actions}
            />

            <FormItem
              solid
              label={"Company Address"}
              name={"company_address"}
              inputProps={{
                name: "company_address",
                placeholder: "Company Address",
                value: this.state.values["company_address"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"textarea"}
            />
          </>
        ) : (
          <></>
        )}

        {/* begin::Actions */}
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            type="button"
            disabled={this.state.isSubmitting}
            className={`btn btn-secondary font-weight-bold px-9 py-4 my-3`}
            onClick={this.props.handleOnClickPrev}
          >
            <span>Previous</span>
          </button>
          <button
            type="submit"
            disabled={this.state.isSubmitting || !isFormValid()}
            className={GetSubmitClassList(
              this.state.isSubmitting,
              "btn btn-primary font-weight-bold px-9 py-4 my-3",
            )}
          >
            <span>Next</span>
          </button>
        </div>
        {/* end::Actions */}
      </Form>
    );
  }
}

class Step3 extends ProSourceForm {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        first_name: props.user.first_name,
        last_name: props.user.last_name,
        business_type: "Individual",
        //BUSINESS INFO
        business_trade_name: "",
        business_address_l1: "",
        business_address_l2: "",
        business_address_city: "",
        business_address_state: "",
        business_address_zip: "",
        dti_registered_name: "",
        business_registered_name: "",
        dti_registered_number: "",
        //MORE BUSINESS INFO
        business_handle: "",
        // business_link:"",
        // business_nature:"",
        //BANK ACCOUNT
        business_bank: "",
        business_account_name: "",
        business_account_number: "",
        //SOA
        signature: "",
      },
      // business_type: '',
      validate: [],
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.uploadMerchantFile = this.uploadMerchantFile.bind(this);
    this.changeInputList = this.changeInputList.bind(this);
    this.validateRequiredFieldsOfStage =
      this.validateRequiredFieldsOfStage.bind(this);
    this.clearError = this.clearError.bind(this);

    this.fileapi = core("puf").get();

    this.validate = { required: [...this.state.validate] };
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  // uploadMerchantFile = (file, setFilePath, setSubmitting) => {
  uploadMerchantFile = (file, setFilePath, uploadName = "") => {
    const formData = new FormData();
    formData.append("file", file);

    core().setAuthToken(this.props.token);
    this.fileapi
      .post({
        // url: "/util/upload-to-aws/merchants",
        url: `/util/upload-to-aws/brands?filename=${uploadName}&unique=false`,
        data: formData,
      })
      .then(({ data }) => {
        const { filePath = "" } = data;
        setFilePath(filePath);
      })
      .catch((err) => console.log(">>> error", err));
    // .finally(() => { setSubmitting(false); })
  };

  changeInputList = (idx = 0) => {
    console.log("changeInputList idx", idx);
    let listToValidate = [
      // 'business_trade_name',
      "business_type",
      "business_address_l1",
      "business_address_l2",
      "business_address_city",
      "business_address_state",
      "business_address_zip",

      "business_account_name",
      "business_account_number",
      "business_bank",
    ];
    // const values= {};
    if (idx === 3)
      listToValidate = [
        ...listToValidate,
        "business_legal_name",
        "business_registration_number",
        "business_date_of_incorporation",
        "sec_incorporation_cert",
        "articles_of_partnership",
      ];
    if (idx === 2)
      listToValidate = [
        ...listToValidate,
        "business_legal_name",
        "business_registration_number",
        "business_date_of_incorporation",
        "sec_incorporation_cert",
        "articles_of_incorporation",
        "by_laws",
      ];
    if (idx === 1)
      listToValidate = [
        ...listToValidate,
        "business_registered_name",
        "business_registration_number",
        "dti_registration_cert",
      ];

    this.setState({ validate: listToValidate, errors: {} });
    this.validate = { required: listToValidate };
  };

  validateRequiredFieldsOfStage(fields = []) {
    const { values = {}, errors = {}, touched = {} } = this.state;

    for (let field of fields) {
      const value = values[field] || "";

      touched[field] = true;

      if (value === "" && this.isTouched(field)) {
        errors[field] = "This field is required";
      } else if (errors[field] === "This field is required") {
        delete errors[field];
      }
    }

    this.setState({ touched, errors });
    const hasError = Object.keys(this.state.errors).length > 0 ? false : true;

    return hasError;
  }

  // clear errors (used on go back to step N)
  clearError = (fields = []) => {
    const { errors = {} } = this.state;

    fields.forEach((field) => delete errors[field]);

    this.setState({ errors });
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };
    const { values } = this.state;
    const { user = {}, entityName = "" } = this.props;
    // return <>
    //     {
    //         this.props.plans.map((plan, index) => (<div className={`pricing-item${plan.selected? " pricing-item-selected" : ""}`} key={index} onClick={this.props.onClickItem} data-value={plan.value}>
    //                 <div className="pricing-item-price">{plan.price}</div>
    //                 <div className="pricing-item-label">
    //                     {plan.name} {plan.popular ? <span className="label label-primary label-inline font-weight-bolder mr-2" style={{verticalAlign: "middle"}}>Most Popular</span> : <></> }
    //                 </div>
    //                 <div className="separator separator-dashed"></div>
    //                 <div className="pricing-item-desc">{plan.description}</div>
    //                 <div className="pricing-item-features">
    //                     {
    //                         plan.features.map((feature, i) => (<div key={i} className="pricing-item-feature">
    //                             <span className="svg-icon svg-icon-md mr-1 svg-icon-primary">
    //                                 <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}/>
    //                             </span> {feature}
    //                         </div>))
    //                     }
    //                 </div>
    //             </div>))
    //     }

    //     {/* begin::Actions */}
    //     <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
    //         {/* { this.props.hidePreviousBtn ? <></> : <button type="button" className={`btn btn-secondary font-weight-bold px-9 py-4 my-3`} onClick={this.props.handleOnClickPrev}><span>Previous</span> </button> } */}
    //         <button type="submit" disabled={this.props.isSubmitting} className={GetSubmitClassList(this.props.isSubmitting, "btn btn-primary font-weight-bold px-9 py-4 my-3 btn-block")} onClick={this.props.handleOnClickNext}>
    //             <span>Next</span>
    //         </button>
    //     </div>
    //     {/* end::Actions */}
    // </>
    return (
      <PaymongoWizard
        user={user}
        entityName={entityName}
        actions={actions}
        inputActions={inputActions}
        onSubmit={this.onSubmit}
        values={values}
        uploadMerchantFile={this.uploadMerchantFile}
        changeValidateList={this.changeInputList}
        getError={this.getError}
        validateFields={this.validateRequiredFieldsOfStage}
        clearError={this.clearError}
      />
    );
  }
}

class Step4 extends Component {
  render() {
    return (
      <>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            type="button"
            className={`btn btn-secondary font-weight-bold px-9 py-4 my-3`}
            onClick={this.props.handleOnClickPrev}
          >
            <span>Previous</span>{" "}
          </button>
          <button
            type="submit"
            disabled={this.props.isSubmitting}
            className={GetSubmitClassList(
              this.props.isSubmitting,
              "btn btn-primary font-weight-bold px-9 py-4 my-3",
            )}
            onClick={this.props.handleOnClickNext}
          >
            <span>Next</span>
          </button>
        </div>
      </>
    );
  }
}

class PasswordForm extends ProSourceForm {
  constructor(props) {
    super(props);

    this.state = {
      values: { password: "" },
      errors: {},
      touched: {},
      isSubmitting: false,
      status: null,
    };

    this.validate = {
      required: ["password"],
    };
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting, (status) => {
      this.setState({ status });
    });
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <>
        <Form onSubmit={this.handleOnSubmit}>
          {this.state.status && <ErrorAlert message={this.state.status} />}

          <FormItem
            solid
            label={"Password"}
            name={"password"}
            inputProps={{
              name: "password",
              placeholder: "Password",
              value: this.state.values["password"],
            }}
            inputActions={inputActions}
            actions={actions}
            type={"password"}
            style={{ marginBottom: "0.5rem" }}
          />

          <div className="text-left" style={{ marginBottom: "1rem" }}>
            <Link
              to="/auth/forgot-password"
              className="kt-link text-hover-primary "
            >
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </Link>
          </div>

          <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
            <button
              type="submit"
              className={GetSubmitClassList(
                this.state.isSubmitting,
                "btn btn-primary font-weight-bold px-9 py-4 my-3 btn-block",
              )}
            >
              <span>Continue</span>
            </button>
          </div>
        </Form>
      </>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(SignUp));
