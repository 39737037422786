import { FormControlLabel, FormGroup, RadioGroup } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { GreenCheckbox, GreenRadio } from "../../../components/Base";
import { toPesoAmount } from "../../../helpers";
import { Button } from "react-bootstrap";
import { isEmpty } from "lodash";

export const ExtraGroupModal = ({
  show = false,
  setShowItemModal,
  item = {},
  onCartUpdate,
}) => {
  const [extras, setExtras] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const { extragroup } = item;

  useEffect(() => {
    if (!isEmpty(extragroup)) {
      isValidExtraGroup();
    }
  });

  const isValidExtraGroup = () => {
    let extras_ = extras;
    let extragroup_ = extragroup;
    const required_groups = extragroup_.filter(
      ({ extra_group_id }) => extra_group_id.isRequired === true,
    );
    var results = extras_.reduce((results, extra) => {
      (results[extra.extra_group_id] =
        results[extra.extra_group_id] || []).push(extra);
      return results;
    }, {});
    let retVal = [];
    for (var i in required_groups) {
      let required_id = required_groups[i].extra_group_id._id;
      let minimum_number = required_groups[i].extra_group_id.minimum_number;
      if (results[required_id]) {
        const result_count = results[required_id].length;
        if (result_count < minimum_number) {
          retVal.push(true);
        } else {
          retVal.push(false);
        }
      } else {
        retVal.push(true);
      }
    }
    const is_extragroup_valid = retVal.findIndex((x) => x === true) === -1;
    setIsValid(is_extragroup_valid);
  };

  const onRadioChange = (event) => {
    const target = event.target,
      _id = target.getAttribute("_id"),
      price = target.getAttribute("price"),
      e_g_id = target.getAttribute("extra_group_id"),
      name = target.getAttribute("name");

    const selected = {
      extra_group_id: e_g_id,
      extra_id: _id,
      price: price,
      name,
    };

    let extras_temp = extras;
    const extra_index = extras_temp.findIndex(
      (x) => x.extra_group_id === e_g_id,
    );
    if (extra_index === -1) {
      extras_temp.push(selected);
    } else {
      extras_temp.splice(extra_index, 1);
      extras_temp.push(selected);
    }

    setExtras(extras_temp);
    isValidExtraGroup();
  };

  const CountByGroupId = (extras, group_id) => {
    return extras.reduce((total, order) => {
      if (order.extra_group_id === group_id) {
        return (total += 1);
      } else {
        return total;
      }
    }, 0);
  };

  const onCheckboxChange = (event) => {
    const target = event.target,
      _id = target.getAttribute("_id"),
      e_g_id = target.getAttribute("extra_group_id"),
      price = target.getAttribute("price"),
      maximum_number = Number(target.getAttribute("maximum_number")),
      name = target.getAttribute("name");

    const selected = {
      extra_group_id: e_g_id,
      extra_id: _id,
      price: price,
      name,
    };
    let extras_temp = extras;

    const extra_index = extras_temp.findIndex((x) => x.extra_id === _id);
    if (extra_index === -1) {
      const count = CountByGroupId(extras, e_g_id);
      if (count === maximum_number) {
        target.click();
      } else {
        extras_temp.push(selected);
      }
    } else {
      extras_temp.splice(extra_index, 1);
    }
    setExtras(extras_temp);
    isValidExtraGroup();
  };

  return (
    <Modal
      centered
      show={show}
      onHide={() => {
        setShowItemModal(!show);
      }}
    >
      <Modal.Header>
        <Modal.Title>Select Extras</Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setShowItemModal(!show)}
        >
          <i aria-hidden="true" className="ki ki-close"></i>
        </button>
      </Modal.Header>
      <Modal.Body className="p-4">
        {extragroup.map(({ extra_group_id }, i) => {
          const {
            name,
            description,
            group_selections,
            isRequired,
            maximum_number,
            minimum_number,
            _id: extragroup_id,
          } = extra_group_id;

          return (
            <div className="p-2" key={`extra-${i}`}>
              <h6 className="d-flex justify-content-between align-items-start mb-0">
                <div className="d-flex flex-row align-items-center">
                  <div className="selection-item-title">{name}</div>
                  <div style={{ fontSize: "0.95rem", fontWeight: 300 }}>
                    &nbsp;
                    {`(${isRequired ? "Select " : "Select up to "}
                    ${!isRequired ? maximum_number : minimum_number}
                )`}
                  </div>
                </div>
                {isRequired ? (
                  <span className="selection-item-tag label label-info label-inline font-weight-bold text-center">
                    REQUIRED
                  </span>
                ) : (
                  <span className="selection-item-tag label label-inline font-weight-bold text-center">
                    OPTIONAL
                  </span>
                )}
              </h6>
              <div
                className="col-example text-left"
                style={{ fontSize: "1rem" }}
              >
                {description}
              </div>
              {minimum_number === 1 && maximum_number === 1 ? (
                <RadioGroup onChange={onRadioChange}>
                  {group_selections.map(
                    ({ extra_id: { name, price, _id } }, i) => {
                      return (
                        <SelectionCardRadio
                          name={name}
                          price={price}
                          i={i}
                          _id={_id}
                          extra_group_id={extragroup_id}
                        />
                      );
                    },
                  )}
                </RadioGroup>
              ) : (
                <FormGroup>
                  {group_selections.map(
                    ({ extra_id: { name, price, _id } }, i) => {
                      return (
                        <div key={`checkbox-${i}`}>
                          <div
                            className="d-flex flex justify-content-between w-100"
                            style={{ marginTop: "-0.25rem" }}
                          >
                            <FormControlLabel
                              style={{
                                pointerEvents: "none",
                                marginBottom: "-1rem",
                              }}
                              control={
                                <GreenCheckbox
                                  style={{ pointerEvents: "auto" }}
                                  inputProps={{
                                    _id: _id,
                                    price: price,
                                    extra_group_id: extragroup_id,
                                    maximum_number: maximum_number,
                                    minimum_number: minimum_number,
                                  }}
                                  onChange={onCheckboxChange}
                                  name={name}
                                />
                              }
                              label={name}
                            />
                            {
                              <div
                                className="p-2 col-example text-left"
                                style={{ marginBottom: "-1rem" }}
                              >
                                {" "}
                                + {toPesoAmount(price)}
                              </div>
                            }
                          </div>
                        </div>
                      );
                    },
                  )}
                </FormGroup>
              )}
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer className="justify-content-center w-100">
        <Button
          disabled={!isValid}
          variant="primary"
          style={{ width: 200 }}
          onClick={() => {
            onCartUpdate(item, true, extras);
            setShowItemModal(false);
          }}
        >
          Add to cart
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const SelectionCardRadio = ({ name, price, _id, extra_group_id, i }) => {
  return (
    <div key={`radio-${i}`}>
      <div
        className="d-flex flex justify-content-between w-100"
        style={{ marginTop: "-0.25rem" }}
      >
        <FormControlLabel
          style={{ marginBottom: "-1rem" }}
          value={name}
          control={
            <GreenRadio
              inputProps={{
                _id: _id,
                price: price,
                extra_group_id: extra_group_id,
                name,
              }}
            />
          }
          label={name}
          labelPlacement={"end"}
        />
        {
          <div
            className="p-2 col-example text-left"
            style={{ marginBottom: "-1rem" }}
          >
            {" "}
            + {toPesoAmount(price)}
          </div>
        }
      </div>
    </div>
  );
};
