import React from "react";

import { Bargraph } from "../widgets/Bargraph";
import useFetch from "../../hooks/useFetch";
import { toPesoAmount, stackedBarGraphProps } from "../../helpers";
import PacmanWrapper from "./PacmanWrapper";

const SalesByFulfillmentCard = (props) => {
  const { params = {} } = props;
  const { dateRange = "day" } = params;

  const columnWidth = ["month", "year"].includes(dateRange) ? "65%" : "80%";
  const dateRangeValues = [
    "day",
    "week",
    "month",
    "year",
    "allTime",
    "yesterday",
  ];
  const dateRangeSuffixed = [
    "Daily",
    "Weekly",
    "Monthly",
    "Yearly",
    "All-time",
    "Yesterday",
  ];

  const reqObj = {
    url: "/dashboard/sales-by-fulfillment",
    method: "post",
    body: params,
  };

  const dataFormatter = {
    dataFormatter: stackedBarGraphProps,
    args: [params.dateRange],
  };

  const { data, isLoading } = useFetch(reqObj, dataFormatter);

  return (
    <>
      {isLoading || data === null ? (
        <PacmanWrapper />
      ) : (
        <Bargraph
          className={"sales-by-fulfillment h-100 m-0 overflow-hidden"}
          series={data.series}
          categories={data.categories}
          height={250}
          customOptions={{
            chart: {
              type: "bar",
              stacked: false,
              toolbar: {
                show: false,
              },
              height: "100%",
            },
            responsive: [
              {
                breakpoint: 576,
                options: {
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                columnWidth: columnWidth,
                startingShape: "rounded",
                endingShape: "rounded",
              },
            },
            grid: {
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              position: "left",
            },
            yaxis: {
              type: "category",
              labels: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            colors: ["#50b585", "#795ecc", "#f54962", "#FFA800"],
            tooltip: {
              y: {
                formatter: function (
                  value,
                  { series, seriesIndex, dataPointIndex, w },
                ) {
                  return toPesoAmount(value);
                },
              },
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return `
                                <div class="barContainer">
                                    <h5 class="barHeader">${
                                      w.globals.labels[dataPointIndex]
                                    }</h5>

                                    <div class="barContent">
                                        ${w.globals.seriesNames.reduce(
                                          (acc, cur, i) => {
                                            return `${acc}  <div class="barSeriesContent">
                                                <span class="barBadge" style="background-color: ${
                                                  w.globals.colors[i]
                                                }"></span>
                                                <span>${cur}</span>
                                                <span class='barData'>${toPesoAmount(
                                                  series[i][dataPointIndex],
                                                )}</span>
                                            </div>`;
                                          },
                                          "",
                                        )}


                                        <div class="barSeriesContent bold">
                                            <span>Total</span>
                                            <span class='barData'>${toPesoAmount(
                                              series.reduce(
                                                (acc, cur) =>
                                                  acc + cur[dataPointIndex],
                                                0,
                                              ),
                                            )}</span>
                                        </div>
                                    </div>
                                </div>
                            `;
              },
            },
            stroke: {
              show: true,
              width: 1,
              colors: ["#fff"],
            },
          }}
        >
          <h4 className="pt-8 px-8">
            {/* set the header base on the selected date range */}
            {
              dateRangeSuffixed[
                dateRangeValues.indexOf(
                  dateRange === "day" ? "week" : dateRange,
                )
              ]
            }{" "}
            Sales by Fulfillment
          </h4>
        </Bargraph>
      )}
    </>
  );
};

export default SalesByFulfillmentCard;
