import React from "react";
import {
  AnimateDiv,
  Block,
  DFlex,
  OrderTimeRender,
  Separator,
  SVGIcon,
  RequiredAsterisk,
} from "../../components/Base";
import Timeline from "../../components/widgets/Timeline";
import {
  computeDiscount,
  generateTimeline,
  computeServiceCharge,
  getMealPlanDays,
} from "../../helpers/branded-website";
import styled from "styled-components";
import TrackOrderDetailsItem from "./TrackOrderDetailsItem";
import {
  computeTotalCartAmount,
  formatPromoType,
  formatOrderType,
  phoneNumberPrefix,
  toPesoAmount,
  computeOrderServiceCharge,
  formatAdditionalChargeName,
  formatAdditionalChargeType,
} from "../../helpers";
import DisplayOrderSummaryLine from "./DisplayOrderSummaryLine";

/**
 * Track Page - Order Details Page
 * @param {Object} props.data
 * @param {Object} props.website_theme
 * @returns
 */
export default function TrackOrderDetailsPage(props) {
  const { data = {}, website_theme = {} } = props;
  // console.log("trackorderdetailspage props: ", props);
  const timeline = generateTimeline(data);
  const {
      highlighted_text_color = "",
      link_text_color = "",
      icon_color = "",
    } = website_theme,
    {
      order: { isMealPlan = false },
      order,
    } = data;
  const discountedMealPlanAmount =
    order.mealPlanDiscount > 0
      ? computeDiscount(order.originalTotalPrice, order.mealPlanDiscount)
      : order.originalTotalPrice;
  const promoDiscount = order.promoDiscount || 0;
  const totalDeliveryAmount = order.chargedDeliveryFee || 0;
  const promoTotal = promoDiscount;
  const grandTotal = order.payment.amount;
  const computed_additional_charge = computeOrderServiceCharge(order);
  const hasOrderServiceCharge = computed_additional_charge > 0;
  const mealPlanTotalDiscount =
    promoDiscount - (order.originalTotalPrice - discountedMealPlanAmount);
  const mealPlanDeliverySubsidy =
    getMealPlanDays(order.meal_plan_type) *
      order.current_free_delivery_amount || 0;
  const mealPlanTotalQuote =
    order.cart.meal_plan_total_quoted_fee - mealPlanDeliverySubsidy || 0;
  const thirdPartyInfo =
    typeof order.pickup_details === "string"
      ? JSON.parse(order.pickup_details)
      : order.pickup_details;
  const ColoredIcon = styled.span`
    svg g [fill] {
      fill: ${icon_color};
    }
  `;

  const withPriorityFee = (order) => {
    let withPrio = false;
    if (order.delivery.priority_fee) {
      if (order.delivery.priority_fee !== 0) {
        withPrio = true;
      }
    }
    return withPrio;
  };

  const isFreeDelivery = (order) => {
    return Number(order.chargedDeliveryFee) === 0;
  };

  return (
    <AnimateDiv>
      <div
        className="cursor-pointer"
        onClick={props.actions.onClickBackToHome}
        style={{ color: link_text_color }}
      >
        <ColoredIcon>
          <SVGIcon
            icon={"Navigation/Arrow-left"}
            title="Back to home"
            variant="custom"
          />
        </ColoredIcon>{" "}
        Back
      </div>
      <Block
        title={<></>}
        content={
          <>
            <Timeline className="timeline-1">
              <Timeline.Sep
                className="bg-primary-opacity-20"
                style={{ left: "12px" }}
              />
              {timeline.map((item, index) => {
                const active = item.status !== "pending";
                return (
                  <Timeline.Item key={index}>
                    {/* <Timeline.Label>
                        {
                            active ? <>{item.label}</> : <><span><i className="flaticon-time"></i></span></>
                        }
                    </Timeline.Label> */}
                    <Timeline.Badge>
                      <i
                        className={`flaticon2-accept`}
                        style={active ? { color: icon_color } : null}
                      ></i>
                    </Timeline.Badge>
                    <Timeline.Content>
                      <div
                        className={`${
                          active ? "font-weight-bolder" : ""
                        } text-capitalize`}
                      >
                        {item.content}
                      </div>
                      <span>{item.label}</span>
                    </Timeline.Content>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </>
        }
      />

      <Separator solid border={2} />

      <Block
        title={<>order details</>}
        content={
          <>
            <div className="order-details mt-1">
              {data.order.order_details.map((item, index) => (
                <TrackOrderDetailsItem
                  key={index}
                  item={item}
                  color={highlighted_text_color}
                  isMealPlan={isMealPlan}
                  showOrderStatus={false}
                />
              ))}
              {data.order.promo &&
                data.order.promo.promo_type === "free_item" && (
                  <div className="item-text-wrapper d-flex flex-column flex-grow-1 pr-2 pt-2">
                    <div className="item-name d-flex">
                      {" "}
                      <span>
                        <i
                          style={{
                            color: highlighted_text_color,
                          }}
                          className="fas fa-gift"
                        ></i>
                      </span>
                      <span className="font-weight-bold ml-2">
                        {" "}
                        {data.order.promo.free_item_description}
                      </span>
                      <div></div>
                    </div>
                  </div>
                )}
              <div className="d-flex flex-wrap align-items-center mv-1rem">
                <DFlex className="flex-column flex-grow-1">
                  <span className="text-uppercase font-weight-bolder">
                    total amount
                    {data.order.mealPlanDiscount > 0 && (
                      <span className="text-danger ml-1">
                        ({data.order.mealPlanDiscount}% OFF)
                      </span>
                    )}{" "}
                    {isMealPlan && <>*</>}
                  </span>
                </DFlex>
                <DFlex className="align-items-center">
                  <span className="text-uppercase font-weight-bolder">
                    {isMealPlan ? (
                      <>
                        {data.order.mealPlanDiscount > 0 ? (
                          <>
                            <del className="mr-2">
                              {toPesoAmount(data.order.originalTotalPrice)}
                            </del>
                            <span className="text-danger">
                              {toPesoAmount(discountedMealPlanAmount)}
                            </span>
                          </>
                        ) : (
                          <span>
                            {toPesoAmount(data.order.originalTotalPrice)}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {toPesoAmount(
                          computeTotalCartAmount(data.order.order_details),
                        )}
                      </>
                    )}
                  </span>
                </DFlex>
              </div>
              {hasOrderServiceCharge && (
                <>
                  {order.additional_charges.map((charge) => (
                    <div className="d-flex flex-wrap align-items-center mv-1rem">
                      <DFlex className="flex-column flex-grow-1">
                        <span className="text-uppercase font-weight-bolder">
                          {formatAdditionalChargeName(charge.name)}
                          <span className="text-primary ml-1">
                            {formatAdditionalChargeType(
                              charge.type,
                              charge.factor,
                            )}
                            <RequiredAsterisk />
                          </span>
                        </span>
                      </DFlex>
                      <DFlex className="align-items-center">
                        <span className="text-uppercase font-weight-bolder">
                          {toPesoAmount(charge.amount)}
                        </span>
                      </DFlex>
                    </div>
                  ))}
                  {promoDiscount === 0 && order.order_type !== "delivery" && (
                    <DisplayOrderSummaryLine
                      text={<>grand total</>}
                      amount={toPesoAmount(grandTotal)}
                    />
                  )}
                </>
              )}
              {data.order.order_type === "delivery" &&
              data.order.delivery &&
              !isMealPlan &&
              data.order.delivery ? (
                data.order.status === "new" ||
                data.order.status === "accepted" ||
                data.order.status === "ready" ||
                data.order.status === "completed" ||
                data.order.status === "cancelled_full_refund" ? (
                  !data.order.delivery.isCOD ? (
                    <>
                      <div className="d-flex flex-wrap align-items-center mv-1rem">
                        <DFlex className="flex-column flex-grow-1">
                          <span className="text-uppercase font-weight-bolder">
                            delivery fee
                          </span>
                        </DFlex>
                        <DFlex className="align-items-center">
                          <span className="text-uppercase font-weight-bolder">
                            {isFreeDelivery(data.order)
                              ? "FREE DELIVERY"
                              : withPriorityFee(data.order)
                              ? toPesoAmount(
                                  Number(data.order.chargedDeliveryFee) -
                                    data.order.delivery.priority_fee,
                                )
                              : toPesoAmount(
                                  Number(data.order.chargedDeliveryFee),
                                )}
                          </span>
                        </DFlex>
                      </div>
                      {withPriorityFee(data.order) && (
                        <div className="d-flex flex-wrap align-items-center mv-1rem">
                          <DFlex className="flex-column flex-grow-1">
                            <span className="text-uppercase font-weight-bolder">
                              priority fee
                            </span>
                          </DFlex>
                          <DFlex className="align-items-center">
                            <span className="text-uppercase font-weight-bolder">
                              {toPesoAmount(data.order.delivery.priority_fee)}
                            </span>
                          </DFlex>
                        </div>
                      )}
                      {data.order.promo && (
                        <>
                          {data.order.promo.promo_type !== "free_item" && (
                            <>
                              <DisplayOrderSummaryLine
                                text={
                                  <>{`Promo (${formatPromoType(
                                    data.order.promo,
                                  )})`}</>
                                }
                                amount={<>- {toPesoAmount(promoTotal)}</>}
                                textClassName="text-danger"
                              />
                            </>
                          )}
                        </>
                      )}
                      <DisplayOrderSummaryLine
                        text={<>grand total</>}
                        amount={toPesoAmount(grandTotal)}
                      />
                    </>
                  ) : (
                    <>
                      {data.order.promo && (
                        <>
                          {data.order.promo.promo_type !== "free_item" && (
                            <>
                              <DisplayOrderSummaryLine
                                text={
                                  <>{`Promo (${formatPromoType(
                                    data.order.promo,
                                  )})`}</>
                                }
                                amount={<>- {toPesoAmount(promoTotal)}</>}
                                textClassName="text-danger"
                              />
                            </>
                          )}
                        </>
                      )}
                      {grandTotal !== order.originalTotalPrice && (
                        <DisplayOrderSummaryLine
                          text={<>grand total</>}
                          amount={toPesoAmount(grandTotal)}
                        />
                      )}

                      <Separator solid border={2} />
                      <div className="d-flex flex-wrap align-items-center mv-1rem">
                        <DFlex className="flex-column flex-grow-1">
                          <span className="text-uppercase font-weight-bolder text-danger">
                            ⚠️delivery fee (pay delivery fee in cash to the
                            rider)
                          </span>
                        </DFlex>
                        <DFlex className="align-items-center">
                          <span className="text-uppercase font-weight-bolder text-danger">
                            {toPesoAmount(
                              Number(data.order.chargedDeliveryFee),
                            )}
                          </span>
                        </DFlex>
                      </div>
                    </>
                  )
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              {isMealPlan && !data.order.promo && (
                <>
                  <div className="d-flex flex-wrap align-items-center mv-1rem">
                    <DFlex className="flex-column flex-grow-1">
                      <span className="text-uppercase font-weight-bolder">
                        delivery fee *{" "}
                      </span>
                    </DFlex>
                    <DFlex className="align-items-center">
                      <span
                        className={
                          totalDeliveryAmount > 0 &&
                          Number(data.order.current_free_delivery_amount)
                            ? "text-uppercase font-weight-bolder text-danger"
                            : "text-uppercase font-weight-bolder"
                        }
                      >
                        {totalDeliveryAmount > 0 ? (
                          Number(data.order.current_free_delivery_amount) ? (
                            `discounted long distance ${toPesoAmount(
                              mealPlanTotalQuote,
                            )}`
                          ) : (
                            toPesoAmount(mealPlanTotalQuote)
                          )
                        ) : (
                          <>FREE DELIVERY</>
                        )}
                      </span>
                    </DFlex>
                  </div>
                  <div className="d-flex flex-wrap align-items-center mv-1rem">
                    <DFlex className="flex-column flex-grow-1">
                      <span className="text-uppercase font-weight-bolder">
                        grand total *{" "}
                      </span>
                    </DFlex>
                    <DFlex className="align-items-center">
                      <span className="text-uppercase font-weight-bolder">
                        {toPesoAmount(
                          data.order.meal_plan_index === 0
                            ? data.order.payment.amount
                            : data.order.payment.main_payment.amount,
                        )}
                      </span>
                    </DFlex>
                  </div>
                </>
              )}
              {isMealPlan && data.order.promo && (
                <>
                  <div className="d-flex flex-wrap align-items-center mv-1rem">
                    <DFlex className="flex-column flex-grow-1">
                      <span className="text-uppercase font-weight-bolder">
                        delivery fee *{" "}
                      </span>
                    </DFlex>
                    <DFlex className="align-items-center">
                      <span
                        className={
                          totalDeliveryAmount > 0 &&
                          Number(data.order.current_free_delivery_amount)
                            ? "text-uppercase font-weight-bolder text-danger"
                            : "text-uppercase font-weight-bolder"
                        }
                      >
                        {totalDeliveryAmount > 0 ? (
                          Number(data.order.current_free_delivery_amount) ? (
                            `discounted long distance ${toPesoAmount(
                              mealPlanTotalQuote,
                            )}`
                          ) : (
                            toPesoAmount(mealPlanTotalQuote)
                          )
                        ) : (
                          <>FREE DELIVERY</>
                        )}
                      </span>
                    </DFlex>
                  </div>
                  {data.order.promo && (
                    <>
                      {data.order.promo.promo_type !== "free_item" && (
                        <>
                          <DisplayOrderSummaryLine
                            text={
                              <>{`Promo (${formatPromoType(
                                data.order.promo,
                              )})*`}</>
                            }
                            amount={
                              <>- {toPesoAmount(mealPlanTotalDiscount)}</>
                            }
                            textClassName="text-danger"
                          />
                        </>
                      )}
                    </>
                  )}
                  <div className="d-flex flex-wrap align-items-center mv-1rem">
                    <DFlex className="flex-column flex-grow-1">
                      <span className="text-uppercase font-weight-bolder">
                        grand total *{" "}
                      </span>
                    </DFlex>
                    <DFlex className="align-items-center">
                      <span className="text-uppercase font-weight-bolder">
                        {toPesoAmount(
                          data.order.meal_plan_index === 0
                            ? data.order.payment.amount
                            : data.order.payment.main_payment.amount,
                        )}
                      </span>
                    </DFlex>
                  </div>
                </>
              )}
              {data.order.order_type !== "delivery" && data.order.promo ? (
                <>
                  {data.order.promo.promo_type !== "free_item" && (
                    <>
                      <DisplayOrderSummaryLine
                        text={
                          <>{`Promo (${formatPromoType(data.order.promo)})`}</>
                        }
                        amount={<>- {toPesoAmount(promoTotal)}</>}
                        textClassName="text-danger"
                      />
                      <DisplayOrderSummaryLine
                        text={<>grand total</>}
                        amount={toPesoAmount(grandTotal)}
                      />
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              {isMealPlan && (
                <>
                  <div>
                    <em>* Prices are for the entire meal plan.</em>
                  </div>
                  {Number(data.order.current_free_delivery_amount) && (
                    <div>
                      <em>
                        * Meal plan long distance delivery discount applied.
                      </em>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        }
      />

      {data.order.notes.trim() !== "" ? (
        <>
          <Separator solid border={2} />
          <Block
            title={<>additional information</>}
            content={
              <>
                <div>{data.order.notes} </div>
              </>
            }
          />{" "}
        </>
      ) : (
        <></>
      )}

      <Separator solid border={2} />

      <Block
        title={<>customer information</>}
        content={
          <>
            <div>
              <span
                className="font-weight-bold mr-2"
                style={{ color: highlighted_text_color }}
              >
                Name:
              </span>
              {data.order.guest_customer.first_name}{" "}
              {data.order.guest_customer.last_name}
            </div>
            <div>
              <span
                className="font-weight-bold mr-2"
                style={{ color: highlighted_text_color }}
              >
                Email:
              </span>
              {data.order.guest_customer.email}
            </div>
            <div>
              <span
                className="font-weight-bold mr-2"
                style={{ color: highlighted_text_color }}
              >
                Contact:
              </span>
              {phoneNumberPrefix(data.order.guest_customer.contact_number)}
            </div>
          </>
        }
      />

      {data.order.order_type === "pickup" ||
      data.order.order_type === "third_party_pickup" ||
      data.order.order_type === "delivery" ||
      data.order.order_type === "curbside_pickup" ||
      data.order.order_type === "dine_in" ? (
        <>
          {data.order.order_type === "curbside_pickup" &&
            data.order.curbside_pickup_details && (
              <>
                <Separator solid border={2} />
                <Block
                  title={<>vehicle information</>}
                  content={
                    <>
                      <div>
                        <span
                          className="font-weight-bold mr-2"
                          style={{ color: highlighted_text_color }}
                        >
                          Make:
                        </span>
                        {data.order.curbside_pickup_details.vehicle_make
                          ? data.order.curbside_pickup_details.vehicle_make
                          : "Not Specified"}
                      </div>
                      <div>
                        <span
                          className="font-weight-bold mr-2"
                          style={{ color: highlighted_text_color }}
                        >
                          Color:
                        </span>
                        {data.order.curbside_pickup_details.vehicle_color
                          ? data.order.curbside_pickup_details.vehicle_color
                          : "Not Specified"}
                      </div>
                      <div>
                        <span
                          className="font-weight-bold mr-2"
                          style={{ color: highlighted_text_color }}
                        >
                          Plate Number:
                        </span>
                        {data.order.curbside_pickup_details.vehicle_plate
                          ? data.order.curbside_pickup_details.vehicle_plate
                          : "N/A"}
                      </div>
                    </>
                  }
                />
              </>
            )}
          {data.order.order_type === "third_party_pickup" && (
            <>
              <Separator solid border={2} />
              <Block
                title={<>Third party information</>}
                content={
                  <>
                    <div>
                      <span
                        className="font-weight-bold mr-2"
                        style={{ color: highlighted_text_color }}
                      >
                        Name:
                      </span>
                      {thirdPartyInfo.first_name} {thirdPartyInfo.last_name}
                    </div>
                    <div>
                      <span
                        className="font-weight-bold mr-2"
                        style={{ color: highlighted_text_color }}
                      >
                        Contact:
                      </span>
                      {phoneNumberPrefix(thirdPartyInfo.contact_number)}
                    </div>
                  </>
                }
              />
            </>
          )}
          <Separator solid border={2} />
          <Block
            // title={<>pickup location</>}
            title={<>store information</>}
            content={
              <>
                <div>{data.order.store.name}</div>
                <div>
                  <ColoredIcon>
                    <SVGIcon
                      icon="Map/Marker1"
                      title={"Location"}
                      className="svg-icon-md svg-icon-gray-500 mr-1"
                      variant="custom"
                    />
                  </ColoredIcon>
                  {data.order.store.location}
                </div>
                <div>
                  <ColoredIcon>
                    <SVGIcon
                      icon="Devices/Phone"
                      title={"Contact Number"}
                      className="svg-icon-md svg-icon-gray-500 mr-1"
                      variant="custom"
                    />
                  </ColoredIcon>
                  {data.order.store.contact_number}
                </div>
              </>
            }
          />
          <Separator solid border={2} />
          <Block
            // title={<>pickup schedule</>}
            // title={<>{data.order.order_type==="delivery"?"dispatch":"pickup"} schedule</>}
            title={
              <>
                {data.order.order_type === "delivery"
                  ? "dispatch"
                  : formatOrderType(data.order.order_type)}{" "}
                schedule
              </>
            }
            content={
              <>
                <OrderTimeRender
                  noRange={true}
                  store={data.order.store}
                  {...data.order}
                />
                {data.order.table_number ? (
                  <>
                    <div>Table Number {data.order.table_number}</div>
                  </>
                ) : (
                  <></>
                )}
              </>
            }
          />
        </>
      ) : (
        <></>
      )}

      {data.order.order_type === "delivery" && (
        <>
          <Separator solid border={2} />
          <Block
            title={<>delivery information</>}
            content={
              <>
                <div>
                  <span
                    className=" font-weight-bold mr-2"
                    style={{ color: highlighted_text_color }}
                  >
                    Delivery Partner:
                  </span>
                  {/* {data.order.delivery.delivery_partner} */}
                  {data.order.delivery.delivery_partner === "lalamove"
                    ? `LALAMOVE ${
                        data.order.delivery.delivery_vehicle
                          ? `${data.order.delivery.delivery_vehicle.toUpperCase()}`
                          : ""
                      }`
                    : ""}
                  {data.order.delivery.delivery_partner === "mrspeedy"
                    ? `MR. SPEEDY ${
                        data.order.delivery.delivery_vehicle
                          ? `${data.order.delivery.delivery_vehicle.toUpperCase()}`
                          : ""
                      }`
                    : ""}
                  {data.order.delivery.delivery_partner === "grab"
                    ? `GRAB ${
                        data.order.delivery.delivery_vehicle
                          ? `${data.order.delivery.delivery_vehicle.toUpperCase()}`
                          : ""
                      }`
                    : ""}
                </div>
                {/* <div><span className=" font-weight-bold mr-2" style={{color: highlighted_text_color}}>Delivery Pickup Schedule:</span><OrderTimeRender {...data.order}/></div> */}
                <div>
                  <span
                    className=" font-weight-bold mr-2"
                    style={{ color: highlighted_text_color }}
                  >
                    Delivery Address:
                  </span>
                  {data.order.delivery_address}
                </div>
                {(data.order.status === "ready" ||
                  data.order.status === "completed") &&
                !data.delivery_data.lastStatus ? (
                  <>
                    <div>
                      <span
                        className=" font-weight-bold mr-2"
                        style={{ color: highlighted_text_color }}
                      >
                        Delivery Status:
                      </span>
                      {data.delivery_data ? (
                        <>
                          {data.order.delivery.delivery_partner === "lalamove"
                            ? `${data.delivery_data.status}`
                            : ""}
                          {data.order.delivery.delivery_partner === "mrspeedy"
                            ? `${data.delivery_data.orders[0].status_description}`
                            : ""}
                          {data.order.delivery.delivery_partner === "grab"
                            ? `${data.delivery_data.status}`
                            : ""}
                          {/* {data.delivery_data.status} */}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            }
          />
        </>
      )}
    </AnimateDiv>
  );
}
