import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import {
  HeaderTextWithActions,
  LoadingDiv,
  Section,
} from "../../components/Base";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Card, Row, Col, Nav, Tab } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import UserEmailNotificationsForms from "../../partials/settings/UserEmailNotificationsForm";
import UserPersonalInformationForm from "../../partials/settings/UserPersonalInformationForm";
import UserStatusForm from "../../partials/settings/UserStatusForm";
import UserPrivilegeForm from "../../partials/settings/UserPrivilegeForm";
import UserChangePasswordForm from "../../partials/settings/UserChangePasswordForm";
import Activities from "../../partials/Activities";
import PerfectScrollbar from "react-perfect-scrollbar";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";

const MENUS = {
  main: "main",
  notifications: "notifications",
  privileges: "privileges",
  status: "status",
  password: "password",
  activity: "activity",
};

const MySwal = withReactContent(Swal);

export default function PerUser(props) {
  const { id = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  return <MainComponent id={id} user={user} {...props} />;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "User Settings",
      activeMenuKey:
        MENUS[this.props.history.location.pathname.split("/").pop()] ||
        MENUS.main,
    };

    this.api = core("pu").get();
    this.data_url = "/users/" + this.props.id;

    this.onSubmitEditUser = this.onSubmitEditUser.bind(this);
    this.onSubmitChangePassword = this.onSubmitChangePassword.bind(this);
    this.onClickDeleteUser = this.onClickDeleteUser.bind(this);
    this.setActiveMenu = this.setActiveMenu.bind(this);
  }

  handleOnDataFetched = () => {
    const { data = {} } = this.state,
      { first_name = "", last_name = "" } = data;
    this.setState({ title: first_name + " " + last_name });
  };

  onSubmitChangePassword = () => {
    this.getData();
  };

  onSubmitEditUser = (values, setSubmitting) => {
    this.api
      .put({
        url: "/users/" + this.props.id,
        data: values,
      })
      .then(() => {
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully saved your changes.",
          showConfirmButton: false,
          timer: 1000,
        });
        this.setState({ showEditUserForm: false });
        this.getData();
      })
      .catch(() => {
        this.handleError();
      })
      .finally(() => {
        setSubmitting(false);
        this.setState({
          mountModal: false,
          deleteUserLoading: false,
          mountForms: false,
        });
        setTimeout(() => {
          this.setState({ mountModal: true, mountForms: true });
        }, 50);
      });
  };

  onClickDeleteUser = () => {
    const { data = {} } = this.state;
    this.setState({ deleteUserLoading: true });
    this.onSubmitEditUser({ active: !data.active }, (deleteUserLoading) => {
      this.setState({ deleteUserLoading });
    });
  };

  setActiveMenu = (activeMenuKey) => {
    this.setState({ activeMenuKey });
    this.props.history.push(
      `${this.props.webview ? "/webview" : ""}/settings/users/${
        this.props.id
      }/${activeMenuKey}`,
    );
  };

  render() {
    const { data = {}, data_status = "" } = this.state,
      isLoading = data_status !== "fetched";
    const { user = {} } = this.props,
      sameUserAsLoggedIn =
        user.hasOwnProperty("_id") && data.hasOwnProperty("_id")
          ? user._id === data._id
          : false;

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          <>
            <HeaderTextWithActions
              title={this.state.title}
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item
                    text={"User Settings"}
                    link={`${
                      this.props.webview ? "/webview" : ""
                    }/settings/users`}
                  />
                  <Breadcrumbs.Item text={this.state.title} active={"true"} />
                </Breadcrumbs>
              }
            />

            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={MENUS.main}
              activeKey={this.state.activeMenuKey}
            >
              <Row>
                <Col sm={3}>
                  <Card>
                    <Card.Body>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.main}
                            onClick={() => this.setActiveMenu(MENUS.main)}
                          >
                            Personal Information
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.privileges}
                            onClick={() => this.setActiveMenu(MENUS.privileges)}
                          >
                            Roles and Privileges
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.notifications}
                            onClick={() =>
                              this.setActiveMenu(MENUS.notifications)
                            }
                          >
                            Email Notifications
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.status}
                            onClick={() => this.setActiveMenu(MENUS.status)}
                          >
                            Status
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.activity}
                            onClick={() => this.setActiveMenu(MENUS.activity)}
                          >
                            Activity Log
                          </Nav.Link>
                        </Nav.Item>
                        {sameUserAsLoggedIn ? (
                          <Nav.Item>
                            <Nav.Link
                              eventKey={MENUS.password}
                              onClick={() => this.setActiveMenu(MENUS.password)}
                            >
                              Change Password
                            </Nav.Link>
                          </Nav.Item>
                        ) : (
                          <></>
                        )}
                      </Nav>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey={MENUS.main}>
                      <Card>
                        <Card.Body>
                          <UserPersonalInformationForm
                            api={this.api}
                            user={data}
                            handleError={this.handleError}
                            onSubmit={this.onSubmitEditUser}
                            submitButton="Save changes"
                            showDeleteButton={false}
                          />
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.privileges}>
                      <Card>
                        <Card.Body>
                          <UserPrivilegeForm
                            user={data}
                            onSubmit={this.onSubmitEditUser}
                            submitButton="Save changes"
                          />
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.notifications}>
                      <Card>
                        <Card.Body>
                          <UserEmailNotificationsForms
                            user={data}
                            handleError={this.handleError}
                            onSubmit={this.onSubmitEditUser}
                            submitButton="Save changes"
                            showDeleteButton={false}
                          />
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.status}>
                      <Card>
                        <Card.Body>
                          <UserStatusForm
                            handleError={this.handleError}
                            user={data}
                            deleteLoading={this.state.deleteLoading}
                            handleOnClickDeleteBtn={this.onClickDeleteUser}
                          />
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.activity}>
                      <Card>
                        <Card.Body>
                          <Section
                            header={
                              <div className="font-weight-bolder">
                                Activity Log
                              </div>
                            }
                          />
                          <PerfectScrollbar>
                            <Activities
                              collection="users"
                              identifier={data._id}
                              api={this.api}
                            />
                          </PerfectScrollbar>
                          {/* <p>There are currently no activity logs recorded.</p> */}
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    {sameUserAsLoggedIn ? (
                      <Tab.Pane eventKey={MENUS.password}>
                        <Card>
                          <Card.Body>
                            <UserChangePasswordForm
                              api={this.api}
                              user={data}
                              handleError={this.handleError}
                              submitButton="Save changes"
                              showDeleteButton={false}
                              onSubmitChangePassword={
                                this.onSubmitChangePassword
                              }
                            />
                          </Card.Body>
                        </Card>
                      </Tab.Pane>
                    ) : (
                      <></>
                    )}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </>
        )}
      </>
    );
  }
}
