import React, { Component } from "react";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { AnimateDiv, HeaderText, SVGIcon } from "../../components/Base";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import { useSelector } from "react-redux";
import {
  stackedBarGraphProps,
  statPieGraphProps,
  capitalizeText,
} from "../../helpers";
// import DateRangeDropdown from "../../components/dashboard/DateRangeDropdown";
import Row1 from "../../components/dashboard/Row1";
import Row2 from "../../components/dashboard/Row2";
import Row3 from "../../components/dashboard/Row3";
import GroupDropdown from "../../components/dashboard/GroupDropdown";
import { DB_DATE_FORMAT, DATERANGE_LIST } from "../../constants";

import useRedirectUserByRole from "../../hooks/useDashboardRedirect";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";

export default function Page() {
  const { mode = "" } = useParams(),
    { user } = useSelector((state) => state.auth);

  useRedirectUserByRole(user, mode);

  return (
    <>
      {user.brands.length <= 0 ? (
        <Redirect to="/getting-started" />
      ) : (
        <MainComponent
          id={user.client_id}
          user={user}
          webview={mode === "webview"}
        />
      )}
    </>
  );
}

class MainComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: true,
      redirectTo: "",
      brand_name: "Brands",
      data: {},
    };

    this.api = core("pu").get();

    this.getLoggedInUser = this.getLoggedInUser.bind(this);
  }

  getLoggedInUser = (field = "fullname") => {
    const { user = {} } = this.props;
    if (field === "fullname") {
      return user.first_name + " " + user.last_name;
    } else {
      return user[field];
    }
  };

  render() {
    const actions = {
      getLoggedInUser: this.getLoggedInUser,
      setSplashScreen: (showSplashScreen = true) => {
        this.setState({ showSplashScreen });
      },
      setRedirectTo: (redirectTo = "") => {
        this.setState({ redirectTo });
      },
    };

    const {
      iam_role = "",
      stores = [],
      brands = [],
      client = {},
    } = this.props.user;

    const getTitle = (role) => {
      if (role === "store_admin") return "Stores";
      if (role === "brand_admin") return "Brands";
      return "Group";
    };

    return (
      <>
        {/* <TitleHelmet title={this.state.brand_name|| "Group"}/> */}
        {/* {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>} */}
        <TitleHelmet title={getTitle(iam_role)} />

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <GroupComponent
          webview={this.props.webview}
          client={this.props.id}
          stores={stores}
          brands={brands}
          role={iam_role}
          api={this.api}
          actions={actions}
          client_details={client}
          hasRollup={iam_role === "group_admin" || iam_role === "accounting"}
        />
      </>
    );
  }
}

class GroupComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.data_url = "/dashboard";
    this.api = this.props.api;
    this.statCardQuery = "day";
    this.handleDateRangeClick.bind(this);
  }

  handleChangeSelectedBrand = async (item) => {
    this.setState({
      selectedBrand: item.name === "All" ? { ...item, id: "" } : item,
      data_status: "fetching",
    });
    setTimeout(() => {
      this.getData();
    }, 500);
  };

  handleDateRangeClick = async (e, date) => {
    let statCardDataRange;
    const dropdownText = e.currentTarget.textContent || "day";

    if (
      dropdownText === "This Week" ||
      dropdownText === "Last Week" ||
      dropdownText === "Next Week"
    ) {
      statCardDataRange = "week";
    } else if (dropdownText === "This Month" || dropdownText === "Last Month") {
      statCardDataRange = "month";
    } else if (dropdownText === "This Year" || dropdownText === "Last Year") {
      statCardDataRange = "year";
    } else if (dropdownText === "All-time") {
      statCardDataRange = "allTime";
    } else {
      statCardDataRange = "day";
    }

    this.setState({
      statCardQuery: statCardDataRange,
      data_status: "fetching",
      date,
    });

    setTimeout(() => {
      this.getData();
    }, 500);
  };

  async getData() {
    this._isMounted && this.setState({ data_status: "fetching" });
    let date = this.state.date || moment().format(DB_DATE_FORMAT);
    let dateRange = this.state.statCardQuery || "day";
    let view = this.state.selectedBrand || { id: "", name: "All", attr: "" };

    const defaultAttr = this.props.role === "store_admin" ? "store" : "brand";

    const getStats = (
      rowNo,
      dateRange = "day",
      date = moment().format(DB_DATE_FORMAT),
    ) => {
      const { role = "", brands = [], stores = [] } = this.props;
      const { id = "", attr = "" } = getIdsAndAttr(this.props, view);

      return new Promise((resolve, reject) => {
        // this.api.get(`/group/${this.props.client}/${rowNo}?stat_cards=${statCardRange}&date=${date}&view=${view.id}&attr=${view.attr}`)
        this.api
          .post({
            url: "/dashboard",
            data: {
              role: role,
              brands:
                role === "store_admin" || role === "console_user"
                  ? stores
                  : brands,
              id: id || view.id,
              attr: attr || view.attr,
              date,
              dateRange,
              type: rowNo,
            },
          })
          .then((data) => resolve(data.data))
          .catch((err) => reject(err));
      });
    };

    const getIdsAndAttr = function (props, selectedView = {}) {
      const { role = "", brands = [], stores = [] } = props;
      const { id = "", attr = "brand" } = selectedView;

      if (role === "brand_admin") return { id: id || brands[0], attr };
      if (role === "store_admin" || role === "console_user") {
        return { id: id || stores[0], attr: "store" };
      }

      return { id: id || brands, attr: attr || "brand" };
    };

    try {
      const rows = await Promise.all([
        getStats("header", dateRange, date),
        getStats("row1", dateRange, date),
      ]);
      const [header, row1] = rows;

      const defaultSelectedBrand = !this.props.hasRollup
        ? {
            ...header[0],
            attr: defaultAttr,
            id: ["store_admin", "console_user"].includes(this.props.role)
              ? this.props.stores[0]
              : "",
          }
        : {
            name: "All",
            id: "",
            attr: defaultAttr,
          };

      this._isMounted &&
        this.setState({
          data: {
            header,
            row1,
            stats: rows,
          },
          selectedBrand: this.state.selectedBrand || defaultSelectedBrand,
          data_status: "fetched",
          order_fetch_status: "fetched",
        });

      this.handleOnDataFetched();
      if (this.use_display_data) {
        this.setDisplayData();
      }
      this.props.actions.setSplashScreen(false);
    } catch (error) {
      this._isMounted && this.setState({ error: error, showSwalError: true });
      this.handleOnDataFetchError();
    }
  }

  render() {
    const { client_details = {}, hasRollup = false, role = "" } = this.props;
    const defaultAttr = this.props.role === "store_admin" ? "store" : "brand";

    const {
      data_status = "",
      statCardQuery = "day",
      data: { header = [], row1 = {} },
      selectedBrand = !hasRollup
        ? {
            ...header[0],
            attr: defaultAttr,
          }
        : {
            name: "All",
            id: "",
            attr: defaultAttr,
          },
      date = moment().format(DB_DATE_FORMAT),
    } = this.state;

    const isLoading = data_status !== "fetched";

    function dropdownText(dateRange, date) {
      const isCurrent = moment(moment().format(DB_DATE_FORMAT)).isSame(
        date,
        dateRange,
      );

      const prefix = isCurrent ? "This" : "Last";

      // if(dateRange=== 'week') return `${prefix} Week`
      if (dateRange === "week") {
        if (
          moment(moment().add(1, "weeks").format(DB_DATE_FORMAT)).isSame(date)
        ) {
          return "Next Week";
        }

        return `${prefix} Week`;
      }
      if (dateRange === "month") return `${prefix} Month`;
      if (dateRange === "year") return `${prefix} Year`;
      if (dateRange === "allTime") return "All-time";
      return `${isCurrent ? "Today" : "Yesterday"}`;
    }

    return (
      <>
        {isLoading ? (
          <>
            <div className="d-flex justify-content-center align-items-center h-100">
              <PacmanWrapper removeBackgroundColor />
            </div>
          </>
        ) : (
          <AnimateDiv>
            <div className="d-flex align-items-stretch align-items-md-end flex-column flex-md-row">
              {role === "group_admin" || role === "accounting" ? (
                <HeaderText
                  className="flex-grow-0 flex-shrink-1"
                  title={
                    <>
                      <span>{client_details.name}</span>
                    </>
                  }
                />
              ) : (
                <HeaderText
                  className="flex-grow-0 flex-shrink-1"
                  title={
                    <>
                      <span>{selectedBrand.name}</span>
                    </>
                  }
                  subtitle={
                    selectedBrand.location &&
                    selectedBrand.contact_number && (
                      <>
                        <SVGIcon icon="Map/Marker1" title={"Location"} />{" "}
                        {selectedBrand.location} <span className="mr-2"></span>
                        <SVGIcon
                          icon="Devices/Phone"
                          title={"Contact Number"}
                        />
                        {selectedBrand.contact_number}
                      </>
                    )
                  }
                />
              )}

              <div className="d-flex flex-shrink-0 justify-content-end p-4 pb-0 ml-0 ml-md-auto bg-white rounded-top">
                {role !== "console_user" && (
                  <GroupDropdown
                    items={header}
                    attr={role === "store_admin" ? "store" : "brand"}
                    onSelectView={this.handleChangeSelectedBrand}
                    selectedView={selectedBrand}
                    hasRollup={hasRollup}
                    className="mr-2"
                  />
                )}

                <Dropdown className="">
                  <Dropdown.Toggle variant="primary" id="dropdown-daterange">
                    {dropdownText(statCardQuery, date)}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {DATERANGE_LIST.map((item) => (
                      <Dropdown.Item
                        onClick={(e) => this.handleDateRangeClick(e, item.date)}
                        key={item.name}
                      >
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="container bg-white py-4 px4 rounded rounded-right-0 rounded-bottom">
              <Row1
                date={date}
                row1={row1}
                statCardQuery={statCardQuery}
                statLink={`${this.props.webview ? "/webview" : ""}/reports`}
                view={selectedBrand.id}
                attr={selectedBrand.attr}
                brands={header.map((brand) => brand.id)}
                role={this.props.role}
              />

              <Row2
                params={{
                  date,
                  dateRange: statCardQuery,
                  id: selectedBrand.id,
                  attr: selectedBrand.attr,
                }}
                header={`Sold Items by ${
                  !selectedBrand.id ? "Brand" : "Category"
                }`}
              />
            </div>

            <Row3
              statCardQuery={statCardQuery}
              params={{
                date,
                dateRange: statCardQuery,
                id: selectedBrand.id,
                attr: selectedBrand.attr,
              }}
              date={date}
              dropdownText={dropdownText}
              selectedBrand={selectedBrand}
            />
          </AnimateDiv>
        )}
      </>
    );
  }
}
