import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login, getUserByToken } from "../_redux/authCrud";
import LoginCenter from "../../../components/LoginCenter";
import TitleHelmet from "../../../partials/TitleHelmet";
import { Card, Row, Col, Form } from "react-bootstrap";
import {
  ErrorAlert,
  FormItem,
  GreenCheckbox,
  Label,
} from "../../../components/Base";
import { GetSubmitClassList } from "../../../helpers";
import queryString from "querystring";
import core from "../../../vendors/core-api";
import ProSourceForm from "../../../components/ProSourceForm";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { FormControlLabel } from "@material-ui/core";
import { BETA_TEST_AGREEMENT_LINK } from "../../../constants";
import { getStorage } from "../../../../_metronic/_helpers";
import { CircularProgress } from "@material-ui/core";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      redirect: false,
      checkingQueryString: false,
      existingEmail: "",
      user: {},
      error: "",
      display_multiple: false,
      webview_mode: false,
    };

    this.setLoading = this.setLoading.bind(this);
    this.setRedirect = this.setRedirect.bind(this);
    this.enableLoading = this.enableLoading.bind(this);
    this.disableLoading = this.disableLoading.bind(this);
    this.getInputClasses = this.getInputClasses.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);

    this.api = core("pu").get();

    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;

    this._mounted && this.setState({ checkingQueryString: true });
    const pickup_multiple = getStorage("PICKUP_MULTIPLE") || "",
      display_multiple = pickup_multiple === "true";
    const pickup_webview = getStorage("PICKUP_WEBVIEW") || "";

    const search = queryString.parse(this.props.location.search),
      email = search["?email"],
      webview = search["?webview"];

    if ((webview !== "" && webview !== undefined) || pickup_webview !== "") {
      this.setState({ webview_mode: true });
    }

    if (email !== "" && email !== undefined) {
      this.api
        .post({
          url: "/external/users/validate",
          data: { email },
        })
        .then(({ data }) => {
          const { existing = false, user = {} } = data;
          if (existing && user.registration_status === "completed") {
            this.setState({
              existingEmail: email,
              checkingQueryString: false,
              user,
              display_multiple,
            });
          } else {
            this._mounted &&
              this.setState({ checkingQueryString: false, display_multiple });
          }
        })
        .catch(() => {
          this._mounted &&
            this.setState({ checkingQueryString: false, display_multiple });
        });
    } else {
      this._mounted &&
        this.setState({ checkingQueryString: false, display_multiple });
    }
  }

  setLoading = (loading) => {
    this.setState({ loading });
  };

  setRedirect = (redirect) => {
    this.setState({ redirect });
  };

  enableLoading = () => {
    this.setLoading(true);
  };

  disableLoading = () => {
    this.setLoading(false);
  };

  getInputClasses = (meta, fieldname) => {
    let result = "form-control h-auto py-5 px-6 ";
    if (meta.touched && meta.error) {
      result += " is-invalid";
    }

    if (meta.touched && !meta.error) {
      result += " is-valid";
    }

    return result;
  };

  handleOnSubmit = (values, setSubmitting) => {
    const { intl } = this.props,
      { webview_mode = false } = this.state;

    login(values.email, values.password, webview_mode)
      .then(({ data: { token } }) => {
        getUserByToken()
          .then(({ data }) => {
            const { iam_role = "" } = data;

            if (iam_role === "super_admin" || iam_role === "system_admin") {
              this.props.login(token);
            } else {
              const {
                client: { registration_status = "" },
              } = data;

              if (registration_status === "for_subscription") {
                this.setRedirect(true);
              } else {
                this.props.login(token);
              }
            }
          })
          .catch((err) => {
            setSubmitting(false);
            this.disableLoading();
            this.setState({
              error: intl.formatMessage(
                { id: "AUTH.VALIDATION.INVALID_LOGIN" },
                { name: values.email },
              ),
            });
          });
      })
      .catch((error) => {
        setSubmitting(false);
        this.disableLoading();
        if (error.hasOwnProperty("response")) {
          const { data = {} } = error.response,
            { message = {} } = data;
          if (message.error === "UserNotFoundError") {
            // We couldn't find any account that belongs to this email.
            this.setState({
              error: intl.formatMessage(
                { id: "AUTH.VALIDATION.USER_NOT_FOUND" },
                { name: values.email },
              ),
            });
          } else if (message.error === "InvalidLoginError") {
            // The password you entered is incorrect.
            this.setState({
              error: intl.formatMessage(
                { id: "AUTH.VALIDATION.INVALID_LOGIN" },
                { name: values.email },
              ),
            });
          }
        } else {
          // The password you entered is incorrect.
          this.setState({
            error: intl.formatMessage(
              { id: "AUTH.VALIDATION.INVALID_LOGIN" },
              { name: values.email },
            ),
          });
        }
      });
  };

  render() {
    const { first_name = "" } = this.state.user;

    return (
      <>
        <TitleHelmet title="Sign In" />
        {this.state.redirect ? <Redirect to={"/auth/sign-up"} /> : <></>}
        {this.state.checkingQueryString ? (
          <LayoutSplashScreen />
        ) : (
          <>
            <LoginCenter>
              <LoginCenter.Form>
                <Card className="card-border-top-primary card-custom animate__animated animate__fadeIn">
                  <Card.Body style={{ maxWidth: "470px" }}>
                    <div className="mb-10">
                      <h3 className="font-size-h1">
                        {first_name !== "" ? (
                          <> Welcome back, {first_name}! </>
                        ) : (
                          <> Welcome back! </>
                        )}
                      </h3>
                      <p>Enter your Pickup.ph credentials to get started.</p>
                      <hr />
                    </div>

                    {this.state.error !== "" ? (
                      <ErrorAlert message={this.state.error} />
                    ) : (
                      <></>
                    )}

                    {this.state.display_multiple ? (
                      <ErrorAlert
                        message={
                          <>
                            Someone has logged into this user account from
                            another device. You have been logged out of this
                            session.
                          </>
                        }
                      />
                    ) : (
                      <></>
                    )}

                    {this.state.webview_mode ? (
                      <ErrorAlert message={<>Webview Mode</>} />
                    ) : (
                      <></>
                    )}

                    <LoginForm
                      email={this.state.existingEmail}
                      onSubmit={this.handleOnSubmit}
                    />

                    <div className="text-center">
                      <Link
                        to="/auth/forgot-password"
                        className="text-dark-50 text-hover-primary my-3 mr-2"
                      >
                        <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </LoginCenter.Form>
            </LoginCenter>
          </>
        )}
      </>
    );
  }
}

class LoginForm extends ProSourceForm {
  constructor(props) {
    super(props);

    const { email = "" } = this.props;

    this.state = {
      values: {
        email,
        password: "",
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {
      required: ["email", "password"],
    };
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  onChange = (event) => {
    const target = event.target,
      value = target.value,
      name = target.name;
    let { errors } = this.state;

    if (name === "email" && value !== "") {
      const valid = this.validateEmail(value);
      if (!valid) {
        errors.email = "Please enter a valid email";
      } else {
        delete errors.email;
      }
      this.setState({ errors: errors });
    }
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <>
        <Form
          className="fv-plugins-bootstrap fv-plugins-framework"
          onSubmit={this.handleOnSubmit}
        >
          {this.props.email !== "" ? (
            <>
              <Form.Group className={"fv-plugins-icon-container"}>
                <Label text="Email" />
                <Form.Control
                  className="border-0 rounded-lg font-size-h6"
                  plaintext
                  readOnly
                  defaultValue={this.state.values.email}
                />
              </Form.Group>
            </>
          ) : (
            <>
              <FormItem
                solid
                label={"Email"}
                name={"email"}
                inputProps={{
                  name: "email",
                  placeholder: "Email",
                  value: this.state.values["email"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"email"}
              />
            </>
          )}

          <FormItem
            solid
            label={"Password"}
            name={"password"}
            inputProps={{
              name: "password",
              placeholder: "Password",
              value: this.state.values["password"],
            }}
            inputActions={inputActions}
            actions={actions}
            type={"password"}
          />

          <FormControlLabel
            style={{ fontSize: 20 }}
            control={
              <GreenCheckbox
                style={{ pointerEvents: "auto" }}
                onChange={(event) => {
                  const { cbox } = this.state;
                  this.setState({
                    cbox: !cbox,
                  });
                }}
              />
            }
            label={
              <>
                {" "}
                <span className={"font-weight-bold"}>
                  By signing in to a Merchant account you agree to{" "}
                  <a
                    href={BETA_TEST_AGREEMENT_LINK}
                    rel="noopener noreferrer"
                    className={"font-weight-bold"}
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>{" "}
                  of the Beta Test Agreement{" "}
                </span>{" "}
              </>
            }
          />

          {/* begin::Actions */}
          <Row className="gutter-b">
            <Col>
              <button
                type="submit"
                disabled={!this.state.cbox || this.state.isSubmitting}
                className={GetSubmitClassList(
                  this.state.loading,
                  `btn btn-primary font-weight-bold px-9 py-4 my-3 btn-block`,
                )}
              >
                {this.state.isSubmitting ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <span>Sign In</span>
                )}
              </button>
            </Col>
            {/* <Col>
                        <Link to={"/auth/sign-up"}>
                            <button type="button" className={`btn btn-secondary font-weight-bold px-9 py-4 my-3 btn-block`}>
                                <span>Join us</span>
                            </button>
                        </Link>
                    </Col> */}
          </Row>
          {/* end::Actions */}
        </Form>
      </>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(Login));
