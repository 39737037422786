import React from "react";

import { useSelector } from "react-redux";
import { useParams, Redirect, Link } from "react-router-dom";

import qs from "qs";
import moment from "moment";
import { CSVLink } from "react-csv";
import Datatable from "react-data-table-component";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  OverlayTrigger,
  Tooltip,
  Button,
  Row,
  Col,
  Card,
  Dropdown,
} from "react-bootstrap";

import APIComponent from "../../components/API";
import { HeaderTextWithActions } from "../../components/Base";

import core from "../../vendors/core-api";
import TitleHelmet from "../../partials/TitleHelmet";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import {
  GetDataFromEvent,
  generatePickupPhLink,
  Humanize,
} from "../../helpers";
import {
  DB_DATE_FORMAT,
  ONBOARDING_PROGRESS,
  DATERANGE_LIST,
  SIGNUP_TYPE_LIST,
} from "../../constants";

// const MySwal = withReactContent(Swal);

function getListClass(name = "", listSelected = "", general_data_status = "") {
  return name === listSelected
    ? "text-uppercase font-weight-bolder font-pickup-md col-padding primary-border-bottom"
    : `text-uppercase font-weight-bolder font-pickup-md col-padding font-hover-primary ${
        general_data_status === "fetched" ? "cursor-pointer" : ""
      }`;
}

export default function Page(props) {
  const { brand_url = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  const { duration = "allTime", date = moment().format(DB_DATE_FORMAT) } =
    qs.parse(props.location.search, { ignoreQueryPrefix: true });

  return (
    <MainComponent id={brand_url} user={user} duration={duration} date={date} />
  );
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Onboarding Status",

      selectedView: "all",
      searchText: "",
      statCardQuery: props.duration || "allTime",
      date: props.date || moment().format(DB_DATE_FORMAT),
      signup_type: "",
    };

    this.api = core("pu").get();
    this.data_url = `/analytics`;

    this.getContent = this.getContent.bind(this);
    this.dropdownText = this.dropdownText.bind(this);
    this.getUnfinishedStages = this.getUnfinishedStages.bind(this);
    this.getLastProgressDate = this.getLastProgressDate.bind(this);
    this.handleDateRangeClick = this.handleDateRangeClick.bind(this);
    this.onCopyToClipboardSuccess = this.onCopyToClipboardSuccess.bind(this);
  }

  getContent() {
    return new Promise((resolve, reject) => {
      const {
        selectedView = "",
        statCardQuery = "allTime",
        date = moment().format(DB_DATE_FORMAT),
        signup_type = "",
      } = this.state;

      this.api
        .get(
          `/analytics/onboarding-progress?filter=${selectedView}&dateRange=${statCardQuery}&date=${date}&signupType=${signup_type}`,
        )
        .then((data) => resolve(data.data))
        .catch((err) => reject(err));
    });
  }

  async getData() {
    this._isMounted && this.setState({ data_status: "fetching" });

    try {
      const content = await this.getContent();

      this._isMounted &&
        this.setState({
          data: {
            ...content,
          },
          data_status: "fetched",
          order_fetch_status: "fetched",
        });

      this.handleOnDataFetched();
      if (this.use_display_data) {
        this.setDisplayData();
      }
    } catch (error) {
      this._isMounted && this.setState({ error: error, showSwalError: true });
      this.handleOnDataFetchError();
    }
  }

  getUnfinishedStages(stages = []) {
    if (!stages) return [];

    const tempStages = stages.map((stage) => {
      if (typeof stage === "object") return stage.name;

      return stage;
    });

    const unfinishedStages = ONBOARDING_PROGRESS.filter(
      (progress) => !tempStages.includes(progress),
    );

    return unfinishedStages;
  }

  getLastProgressDate(stages = []) {
    if (!stages) return [];

    const copy = [...stages]; //prevent mutation

    if (typeof copy[1] === "string") {
      return "N/A";
    }

    const date = copy.reduce((acc, cur) => {
      if (typeof cur === "string") {
        // stages.splice(1);

        return "N/A";
      }

      if (acc === null) return cur;

      return moment(cur).isAfter(acc) ? moment(cur) : moment(acc);
    }, null);

    return date;
  }

  onCopyToClipboardSuccess = () => {
    this.setState({ onCopyToClipboardSuccessMessage: "Copied!" });
    setTimeout(() => {
      this.setState({ onCopyToClipboardSuccessMessage: "" });
    }, 5000);
  };

  onClickOrderListItem = (e) => {
    const selectedView = GetDataFromEvent(e, "data-item");

    this.setState({ selectedView }, async () => {
      await this.getData();
    });
  };

  handleDateRangeClick = async (e, date = moment().format(DB_DATE_FORMAT)) => {
    let statCardDataRange = "";
    const dropdownText = e.currentTarget.textContent || "day";

    if (dropdownText === "This Week" || dropdownText === "Last Week") {
      statCardDataRange = "week";
    } else if (dropdownText === "This Month" || dropdownText === "Last Month") {
      statCardDataRange = "month";
    } else if (dropdownText === "This Year" || dropdownText === "Last Year") {
      statCardDataRange = "year";
    } else if (dropdownText === "All-time") {
      statCardDataRange = "allTime";
    } else {
      statCardDataRange = "day";
    }

    this.setState({
      statCardQuery: statCardDataRange,
      date,
      data_status: "fetching",
    });
    setTimeout(() => {
      this.getData();
    }, 500);
  };

  handleSignupTypeClick = async (signup_type = "") => {
    this.setState(
      {
        signup_type,
      },
      () => {
        this.getData();
      },
    );
  };

  dropdownText = (dateRange, date) => {
    const isCurrent = moment(moment().format(DB_DATE_FORMAT)).isSame(
      date,
      dateRange,
    );
    const prefix = isCurrent ? "This" : "Last";

    if (dateRange === "week") return `${prefix} Week`;
    if (dateRange === "month") return `${prefix} Month`;
    if (dateRange === "year") return `${prefix} Year`;
    if (dateRange === "allTime") return "All-time";
    return `${isCurrent ? "Today" : "Yesterday"}`;
  };

  render() {
    const {
      data = {},
      data_status = "",
      selectedView = "all",
      searchText = "",
      statCardQuery = "day",
      date = moment().format(DB_DATE_FORMAT),
    } = this.state;
    const { inProgress = [] } = data;
    const isLoading = data_status !== "fetched";

    const filteredItems =
      searchText !== ""
        ? inProgress.filter((item) => {
            return (
              item.client.name
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) !== -1
            );
          })
        : inProgress;

    const csvData = filteredItems.map((item) => {
      return {
        ...item,
        brands: item.brands.map((brand) => brand.name).join(", "),
        onboarding_progress:
          (
            ((ONBOARDING_PROGRESS.length -
              this.getUnfinishedStages(item.onboarding_progress).length) /
              ONBOARDING_PROGRESS.length) *
            100
          ).toFixed(2) + "%",
        unfinishedStages: this.getUnfinishedStages(item.onboarding_progress),
        admin_name: `${item.last_name}, ${item.first_name}`,
        client: {
          ...item.client,
          signup_type: Humanize(item.client.signup_type),
        },
      };
    });

    const filename = `Onboarded Clients-${date}-${statCardQuery}`;
    const csvHeaders = [
      { label: "Group", key: "client.name" },
      { label: "Brands", key: "brands" },
      { label: "Onboarding Progress", key: "onboarding_progress" },
      { label: "Pending Stages", key: "unfinishedStages" },
      { label: "Sign-up Type", key: "client.signup_type" },
      { label: "Agent", key: "client.agent" },
      { label: "Last Activity", key: "last_activity" },
      { label: "Name", key: "admin_name" },
      { label: "Email", key: "email" },
      { label: "Contact Number", key: "contact_number" },
      { label: "Date Created", key: "date_created" },
    ];

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <HeaderTextWithActions title={this.state.title} />

        <Card className="mb-4 text-center">
          <Card.Body className="p-0">
            <Row>
              <Col xs={4}>
                <div
                  className={getListClass("all", selectedView, data_status)}
                  onClick={this.onClickOrderListItem}
                  data-item="all"
                >
                  <span className="va-middle">All</span>
                </div>
              </Col>
              <Col xs={4}>
                <div
                  className={getListClass("pending", selectedView, data_status)}
                  onClick={this.onClickOrderListItem}
                  data-item="pending"
                >
                  Pending
                </div>
              </Col>
              <Col xs={4}>
                <div
                  className={getListClass(
                    "completed",
                    selectedView,
                    data_status,
                  )}
                  onClick={this.onClickOrderListItem}
                  data-item="completed"
                >
                  Completed
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Datatable
          columns={[
            {
              name: "Group",
              grow: 2,
              sortable: true,
              selector: (row) => {
                return row.client.name;
              },
              style: "font-weight-bold",
              format: (row) => {
                return (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={"tooltip-group"}>{row.client.name}</Tooltip>
                    }
                  >
                    <span className="text-break">{row.client.name}</span>
                  </OverlayTrigger>
                );
              },
            },
            {
              name: "Brand(s)",
              grow: 2,
              selector: (row) => {
                return row.brands;
              },
              format: (row) => {
                const { brands = [] } = row;

                if (brands.length === 1 && !brands[0].name)
                  return <span className="text-muted">No brand yet</span>;

                return (
                  <ul className="list-group">
                    {brands.length &&
                      brands.map((brand) => (
                        <Link
                          to={{
                            pathname: generatePickupPhLink(brand.brand_url),
                          }}
                          key={brand._id}
                          className="list-group-item"
                          style={{ whiteSpace: "pre-wrap" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {brand.name}
                        </Link>
                      ))}
                  </ul>
                );
              },
            },
            {
              name: "Progress",
              sortable: true,
              grow: 2,
              selector: (row) => {
                const { onboarding_progress = [] } = row;
                const pendingStages =
                  this.getUnfinishedStages(onboarding_progress);

                // mostly legacy clients
                if (!onboarding_progress) return 5.5;

                return pendingStages.length;
              },
              format: (row) => {
                const { onboarding_progress = [] } = row;
                const pendingStages =
                  this.getUnfinishedStages(onboarding_progress);

                if (onboarding_progress === null) {
                  return <span className="badge badge-pill">Legacy</span>;
                }

                return (
                  <div>
                    {pendingStages.length >= 1 ? (
                      <>
                        <span>
                          {(
                            ((ONBOARDING_PROGRESS.length -
                              pendingStages.length) /
                              ONBOARDING_PROGRESS.length) *
                            100
                          ).toFixed(2)}
                          %
                        </span>

                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-${"pending-stages"}`}>
                              <h6>Pending...</h6>
                              {pendingStages.map((stage, i) => (
                                <span key={stage}>
                                  {pendingStages.length - 1 === i &&
                                    pendingStages.length > 1 &&
                                    " and "}
                                  {stage}
                                  {pendingStages.length - 2 > i && ", "}
                                </span>
                              ))}
                            </Tooltip>
                          }
                        >
                          <span className="badge badge-pill badge-warning ml-2">
                            {`${
                              ONBOARDING_PROGRESS.length - pendingStages.length
                            } of ${ONBOARDING_PROGRESS.length}`}
                          </span>
                        </OverlayTrigger>
                      </>
                    ) : (
                      <span className="badge badge-pill badge-primary">
                        Completed
                      </span>
                    )}
                  </div>
                );
              },
            },
            {
              name: "Sign-up Type",
              grow: 2,
              sortable: true,
              selector: (row) =>
                row.client.signup_type ? row.client.signup_type : "legacy",
              format: (row) => {
                if (!row.client.signup_type) {
                  return (
                    <span className="text-capitalize badge badge-pill badge-light">
                      legacy
                    </span>
                  );
                }

                return (
                  <span
                    className={`text-capitalize badge badge-pill badge-${
                      row.client.signup_type === "inside_sales"
                        ? "primary"
                        : "secondary"
                    }`}
                  >
                    {row.client.signup_type
                      ? Humanize(row.client.signup_type)
                      : "legacy"}
                  </span>
                );
              },
            },
            {
              name: "Last Activity",
              sortable: true,
              grow: 2,
              selector: (row) => {
                const { last_activity = "" } = row;

                return last_activity;
              },
              style: "font-weight-bold",
              format: (row) => {
                const { last_activity = "" } = row;

                if (!last_activity) return <span>-</span>;

                return (
                  <div className="d-flex flex-column">
                    <span>{moment(last_activity).format("MMM D, YYYY")}</span>
                    <span className="badge badge-pill badge-light">
                      {moment().diff(moment(last_activity), "days") === 0
                        ? moment(last_activity).calendar(
                            moment().format(DB_DATE_FORMAT),
                            {
                              sameDay: "[Today]",
                            },
                          )
                        : moment(last_activity).fromNow()}
                    </span>
                  </div>
                );
              },
            },
            {
              name: "Contact Details",
              grow: 4,
              selector: (row) => {
                return row;
              },
              format: (row) => {
                const {
                  first_name = "",
                  last_name = "",
                  contact_number = "",
                  email = "",
                } = row;

                return (
                  <div className="py-2">
                    <div className="mt-1 mb-2">
                      <span className="text-gray-dark mr-2">Name: </span>
                      <span className="badge badge-pill badge-light">{`${last_name}, ${first_name}`}</span>
                    </div>
                    <div className="mt-1">
                      {/* <div className="track-status-label">Copy Link</div> */}
                      <span className="badge badge-pill badge-info position-relative onboarding-email">
                        {email}
                        <CopyToClipboard
                          className="position-absolute d-none onboarding-email-copy"
                          text={email}
                          onCopy={this.onCopyToClipboardSuccess}
                          style={{
                            bottom: 0,
                            right: 0,
                          }}
                        >
                          <Button
                            variant="secondary"
                            size="sm"
                            className="btn-icon btn-circle btn-sm"
                            style={{ height: "30px", width: "30px" }}
                          >
                            <i className="far fa-copy"></i>
                          </Button>
                        </CopyToClipboard>
                      </span>
                    </div>
                    <div className="mt-1">
                      <span className="badge badge-pill badge-success">
                        {contact_number}
                      </span>
                    </div>
                  </div>
                );
              },
            },
            {
              name: "Date Created",
              grow: 2,
              sortable: true,
              selector: (row) => row.date_created,
              format: (row) => {
                const { date_created = "" } = row;
                return (
                  <span className="">
                    {moment(date_created).format("MMM D, YYYY")}
                  </span>
                );
              },
            },
          ]}
          data={filteredItems}
          pagination
          noHeader
          highlightOnHover
          subHeader
          subHeaderWrap={false}
          subHeaderComponent={
            <div className="d-flex flex-column w-100">
              <Row className="d-flex justify-content-between">
                <Col className="d-flex flex-column flex-md-row">
                  <div className="input-group ml-0 mr-md-4 flex-grow-1 flex-shrink-1">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="flaticon-search"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Group Name"
                      value={searchText}
                      onChange={(e) =>
                        this.setState({ searchText: e.target.value })
                      }
                    />
                  </div>

                  <CSVLink
                    className=" d-flex flex-grow-1 align-items-center"
                    filename={filename}
                    data={csvData}
                    headers={csvHeaders}
                  >
                    <span className="d-md-inline-block position-relative">
                      <span
                        className="pr-8"
                        style={{ whiteSpace: "noWrap", overFlow: "hidden" }}
                      >
                        Export to CSV
                        <span className="ml-2">
                          <i className="fas fa-file-export text-primary" />
                        </span>
                      </span>
                    </span>
                  </CSVLink>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col className="d-flex flex-grow-1 justify-content-end align-items-center">
                  <span>Filter by: </span>

                  <Dropdown className="ml-2">
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      <span className="text-capitalize">
                        {Humanize(this.state.signup_type) || "Signup Type"}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => this.handleSignupTypeClick("all")}
                      >
                        <span className="text-capitalize">All</span>
                      </Dropdown.Item>
                      {SIGNUP_TYPE_LIST.map((type) => (
                        <Dropdown.Item
                          onClick={() => this.handleSignupTypeClick(type)}
                          key={type}
                        >
                          <span className="text-capitalize">
                            {Humanize(type)}
                          </span>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown className="ml-2">
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {this.dropdownText(statCardQuery, date)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {DATERANGE_LIST.filter(
                        (item) => item.name !== "Next Week",
                      ).map((item) => (
                        <Dropdown.Item
                          onClick={(e) =>
                            this.handleDateRangeClick(e, item.date)
                          }
                          key={item.name}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </div>
          }
          progressPending={isLoading}
        />
      </>
    );
  }
}
