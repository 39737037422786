import React from "react";
import APIComponent from "../../../components/API";
import CategoriesDisplay from "./CategoriesDisplay";
import core from "../../../vendors/core-api";
import ItemsDisplay from "./ItemsDisplay";
import { browserStorage } from "../../../helpers/branded-website";

/**
 *
 * @param {object} props.store
 * @param {object} props.website_theme
 * @param {object} props.cart
 * @returns Store menu
 */
export default class MenuDisplay extends APIComponent {
  constructor(props) {
    super(props);

    this.api = core("pu").get();

    const {
      cart: {
        isMealPlan = false,
        order_date = "",
        order_time = "",
        order_type = "",
      },
      utm_source = "",
      utm_campaign = "",
    } = props;

    this.data_url = `/branded-website/stores/${this.props.store._id}/item-list?isMealPlan=${isMealPlan}&order_date=${order_date}&order_time=${order_time}&order_type=${order_type}`;

    if (utm_source) this.data_url += `&utm_source=${utm_source}`;
    if (utm_campaign) this.data_url += `&utm_campaign=${utm_campaign}`;

    // Redundancy for other browsers
    browserStorage().setItem("pickup_utm_campaign", utm_campaign);
  }

  handleOnDataFetched = () => {
    const { items = {}, categories = [] } = this.state.data;

    this.setState(
      { noItems: categories.length === 0, categories_directory: categories },
      () => {
        this.props.setDirectories({
          categories_directory: categories,
          items_directory: items,
        });
      },
    );
  };

  handleOnDataFetchError = () => {
    const { error } = this.state;
    const { response = {} } = error;
    const { data = {} } = response;
    const { message = {} } = data;

    if (
      message.hasOwnProperty("error") &&
      message.error === "StoreEmptyFulfillmentsError"
    ) {
      this.setState({
        showEmptyFulfillmentsError: true,
        data_status: "fetched",
      });
    }
  };

  render() {
    const {
      data_status = "",
      data = {},
      noItems = false,
      showEmptyFulfillmentsError = false,
    } = this.state;
    const {
      cart: { isMealPlan = false },
      store = {},
    } = this.props;
    const isLoading = data_status !== "fetched";
    const { categories = [], items = [] } = data;
    const listRef = React.createRef();
    return isLoading ? (
      <div className="mv-1rem text-center">Loading items...</div>
    ) : (
      <div style={{ height: "100%" }}>
        {noItems && (
          <div className="mv-1rem text-center">No items to display.</div>
        )}

        {showEmptyFulfillmentsError && (
          <div className="mv-1rem text-center">
            This store doesn't have any fulfillments turned on.
            <br />
            If you think this is a mistake, kindly report this to the store
            owner.
          </div>
        )}

        <CategoriesDisplay
          categories={categories}
          website_theme={this.props.website_theme}
          listRef={listRef}
        />

        <ItemsDisplay
          categories={categories}
          items_list={items}
          website_theme={this.props.website_theme}
          actions={this.props.actions}
          isMealPlan={isMealPlan}
          store={store}
          listRef={listRef}
        />
      </div>
    );
  }
}
