import React from "react";
import { Row, Col } from "react-bootstrap";

import ItemRanking from "../../components/widgets/ItemRanking";
import StatDonutGraph from "../../components/widgets/StatDonutGraph";

const Row2 = (props) => {
  const { row2 = {}, header = "", params = {} } = props;

  const graphView = getPieGraphView(params);

  return (
    <>
      <Row className="d-flex align-items-stretch my-2">
        <Col md={6} xl={7}>
          <StatDonutGraph
            // header={header}
            header={`Sold Items by ${
              graphView[0].toUpperCase() + graphView.substring(1)
            }`} // uppercased 1st letter
            className={`item-sold-by-category w-100`}
            params={params}
            data_url={`/dashboard/${graphView}/item-sold`}
          />
        </Col>
        <Col md={6} xl={5} className="d-flex">
          <ItemRanking
            className="item-ranking w-100 mt-8 mt-md-0"
            items={row2.top3SoldItem}
            header="Top 3 Items"
            params={{ ...params, limit: 3 }}
          >
            <div className="card-header border-0 bg-primary">
              <h3 className="card-title font-weight-bolder text-white">
                Top 3 Items
              </h3>
            </div>
          </ItemRanking>
        </Col>
      </Row>
    </>
  );
};

export default Row2;

function getPieGraphView({ id = "", attr = "brand" }) {
  if (id && !Array.isArray(id) && attr === "brand") return "category";

  if (attr === "store") return "category";

  return "brand";
}
