import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { FormItem, InfoAlertHelper } from "../../components/Base";
import { Col, Form, Row } from "react-bootstrap";
import {
  GetSubmitClassList,
  getBatchValueOfTimeSlotLimits,
  getDefaultTimeSlots,
} from "../../helpers";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import { isEmpty } from "lodash";
export default class CategoryForm extends ProSourceForm {
  constructor(props) {
    super(props);

    const {
      category_name = "",
      category_limit_per_day = 0,
      category_limit_per_time_slot = [],
      store = {},
      visible = true,
      isExclusive = false,
    } = this.props;
    const { categories = [] } = store;

    this.state = {
      values: {
        category_name,
        old_category_name: category_name,
        category_limit_per_day:
          category_limit_per_day == null ? 0 : category_limit_per_day,
        category_limit_per_time_slot,
        category_limit_per_time_slot_batch: getBatchValueOfTimeSlotLimits(
          category_limit_per_time_slot,
        ),
        visible: visible,
        isExclusive,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      categories,
    };

    this.validate = {
      required: ["category_name"],
    };

    this.handleOnClickDeleteBtn = this.handleOnClickDeleteBtn.bind(this);
  }

  validateLimitInput = (input) => {
    const newInput = String(input).replace(/[^0-9]/g, "");
    if (newInput.length > 7) {
      return newInput.slice(0, 6);
    } else {
      return newInput;
    }
  };

  onChange = (event) => {
    const target = event.target,
      name = target.name,
      value = target.value;
    let { values = {}, errors = {} } = this.state,
      state = {};
    const restrictedchars = event.target.getAttribute("restrictedchars");

    if (name === "category_name" && restrictedchars.length > 0) {
      const valSplit = value.split("");
      const restrictedCharsSplit = restrictedchars.split("");

      for (const char of restrictedCharsSplit) {
        if (valSplit.includes(char)) {
          errors[name] = '"$" and "." is not allowed';
          break;
        }

        if (errors[name]) delete errors[name];
      }

      state.errors = errors;
    }

    if (name === "category_limit_per_time_slot_batch") {
      let {
        category_limit_per_time_slot_batch,
        category_limit_per_time_slot = [],
        category_limit_per_day,
      } = values;
      if (!isEmpty(category_limit_per_time_slot_batch)) {
        if (
          Number(category_limit_per_day) !== 0 &&
          Number(category_limit_per_day) <
            Number(category_limit_per_time_slot_batch)
        ) {
          errors["category_limit_per_time_slot_batch"] =
            "Value is more than daily limit";
          state.errors = errors;
        } else {
          delete errors["category_limit_per_time_slot_batch"];
          category_limit_per_time_slot = getDefaultTimeSlots(this.props.store);
          const validateInput = this.validateLimitInput(value);

          values.category_limit_per_time_slot =
            category_limit_per_time_slot.map((i) => {
              i.limit = parseInt(validateInput);
              return i;
            });
          values.category_limit_per_time_slot_batch = parseInt(validateInput);
        }
      } else {
        errors["category_limit_per_time_slot_batch"] = "Invalid input";
        state.errors = errors;
      }
    }

    if (name === "category_limit_per_day") {
      let {
        category_limit_per_day,
        category_limit_per_time_slot_batch,
        category_limit_per_time_slot,
      } = values;
      if (!isEmpty(category_limit_per_day)) {
        if (
          Number(category_limit_per_day) !== 0 &&
          Number(category_limit_per_day) <
            Number(category_limit_per_time_slot_batch)
        ) {
          errors["category_limit_per_time_slot_batch"] =
            "Value is more than daily limit";
        } else if (
          Number(category_limit_per_day) === 0 ||
          Number(category_limit_per_day) >
            Number(category_limit_per_time_slot_batch)
        ) {
          delete errors["category_limit_per_day"];
          delete errors["category_limit_per_time_slot_batch"];

          category_limit_per_time_slot = getDefaultTimeSlots(this.props.store);
          const validateTimeSlotInput = this.validateLimitInput(
            category_limit_per_time_slot_batch,
          );

          values.category_limit_per_time_slot =
            category_limit_per_time_slot.map((i) => {
              i.limit = parseInt(validateTimeSlotInput);
              return i;
            });

          values.category_limit_per_time_slot_batch = parseInt(
            validateTimeSlotInput,
          );
          const validateInput = this.validateLimitInput(value);
          values.category_limit_per_day = parseInt(validateInput);
        } else {
          delete errors["category_limit_per_day"];
          values.category_limit_per_day = 0;
          const validateInput = this.validateLimitInput(value);
          values.category_limit_per_day = parseInt(validateInput);
        }
        state.errors = errors;
      } else {
        errors["category_limit_per_day"] = "";
        state.errors = errors;
      }
    }
    this.setState(state);
  };

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  handleOnClickDeleteBtn = () => {
    this.props.onClickDeleteBtn && this.props.onClickDeleteBtn();
  };

  onToggleChanged = (event) => {
    event.persist();
    this.setState((prevState) => {
      const newValues = { ...prevState.values };
      newValues[event.target.name] = event.target.checked;
      return { values: newValues };
    });
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      },
      { store = {} } = this.props;

    return (
      <Form onSubmit={this.handleOnSubmit}>
        <FormItem
          label={"Category Name"}
          name={"category_name"}
          inputProps={{
            name: "category_name",
            placeholder: "Category Name",
            value: this.state.values["category_name"],
            restrictedchars: "$.",
          }}
          inputActions={inputActions}
          actions={actions}
          type={"text"}
          showRequired={true}
          normal
        />

        <InfoAlertHelper
          text={
            <>
              You can set the limits of this category on a daily or time slot
              basis. Set to <b>0</b> if no limit.{" "}
              <b>This is only available for non-meal plan fulfillments.</b>
            </>
          }
        />

        <Row>
          <Col>
            <FormItem
              label={"Daily Limit"}
              name={"category_limit_per_day"}
              inputProps={{
                name: "category_limit_per_day",
                placeholder: "Daily Limit",
                value: this.state.values["category_limit_per_day"],
                min: 0,
              }}
              inputActions={inputActions}
              actions={actions}
              type={"number"}
              normal
              helperText={
                <div
                  style={{
                    color:
                      this.state.errors.category_limit_per_day === ""
                        ? "red"
                        : "inherit",
                  }}
                >
                  Set to <b>0</b> if no limit.
                </div>
              }
            />
          </Col>
          <Col>
            <FormItem
              label={"Per Time Slot Limit"}
              name={"category_limit_per_time_slot_batch"}
              inputProps={{
                name: "category_limit_per_time_slot_batch",
                placeholder: "Per Time Slot Limit",
                value: this.state.values["category_limit_per_time_slot_batch"],
                min: 0,
              }}
              inputActions={inputActions}
              actions={actions}
              type={"number"}
              normal
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="form-group row">
              <label className="col-3 col-form-label font-weight-bolder text-dark">
                Show in store:
              </label>
              <div className="col-2">
                <span className="switch switch-outline switch-icon switch-primary">
                  <div className="d-flex">
                    <label>
                      <input
                        type="checkbox"
                        name="visible"
                        checked={this.state.values.visible}
                        onChange={this.onToggleChanged}
                      />
                      <span></span>
                    </label>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 250 }}
                      overlay={renderTooltip}
                    >
                      <span className="svg-icon svg-icon-primary svg-icon-xl ml-2 mt-1">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Question-circle.svg",
                          )}
                          title=" "
                        />
                      </span>
                    </OverlayTrigger>
                  </div>
                </span>
              </div>
            </div>
          </Col>
        </Row>

        {store.isMealPlanActive && (
          <Row>
            <Col>
              <div className="form-group row">
                <label className="col-3 col-form-label font-weight-bolder text-dark">
                  Mark as exclusive:
                </label>
                <div className="col-2">
                  <span className="switch switch-outline switch-icon switch-primary">
                    <div className="d-flex">
                      <label>
                        <input
                          type="checkbox"
                          name="isExclusive"
                          checked={this.state.values.isExclusive}
                          onChange={this.onToggleChanged}
                        />
                        <span></span>
                      </label>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 250 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            <b>
                              This is only available on the meal plan option.
                            </b>{" "}
                            Turning this on will convert the items under this
                            category as an exclusive item. Exclusive items can
                            be added once and is automatically added at the end
                            of the week.
                          </Tooltip>
                        }
                      >
                        <span className="svg-icon svg-icon-primary svg-icon-xl ml-2 mt-1">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Code/Question-circle.svg",
                            )}
                            title=" "
                          />
                        </span>
                      </OverlayTrigger>
                    </div>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <button
          type="submit"
          disabled={this.state.isSubmitting}
          className={GetSubmitClassList(
            this.state.isSubmitting,
            `btn btn-primary font-weight-bold px-9 py-4 my-3`,
          )}
        >
          <span>Save Changes</span>
        </button>

        {this.props.showDeleteButton ? (
          <button
            type="button"
            disabled={this.props.deleteLoading}
            className={GetSubmitClassList(
              this.props.deleteLoading,
              "btn btn-danger font-weight-bold px-9 py-4 my-3 ml-2",
            )}
            onClick={this.handleOnClickDeleteBtn}
          >
            <span>Delete</span>
          </button>
        ) : (
          <></>
        )}
      </Form>
    );
  }
}

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Turning this off will also hide all items under this category.
  </Tooltip>
);
