import React from "react";
import { STORE_USERS } from "../../constants";

export default function DisplayAssignedTitle({ user }) {
  if (user.iam_role === "brand_admin") {
    return <>Assigned Brands</>;
  } else if (STORE_USERS.includes(user.iam_role)) {
    return <>Assigned Stores</>;
  } else {
    return <></>;
  }
}
