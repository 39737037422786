import React from "react";
import { Button, Card } from "react-bootstrap";
import APIComponent from "../components/API";
import {
  Block,
  KTLink,
  Label,
  InfoAlertHelper,
  InfoTooltip,
} from "../components/Base";
import { PRIMARY_COLOR, DANGER_COLOR } from "../constants";
import { generatePickupPhLink } from "../helpers";
import core from "../vendors/core-api/";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ServiceChargeForm from "./forms/ServiceChargeForm";
// import ThemeColorsForm from "./forms/ThemeColorsForm";

const MySwal = withReactContent(Swal);

export default class PickupSettingsDiv extends React.Component {
  constructor(props) {
    super(props);
    this.api = core("pu").get();
    this.state = {
      isActive: this.props.store.hasOwnProperty("isPickupActive")
        ? this.props.store.isPickupActive
        : true,
      store: this.props.store ? this.props.store : "",
    };
  }

  componentDidUpdate() {
    // console.log("component did update state: ", this.state)
  }

  handleTogglePickupStatus = (isChecked) => {
    let values = {};

    const { isActive, store } = this.state;
    isChecked = !isChecked;
    this.setState({ isActive: isChecked });
    store.isPickupActive = isChecked;
    this.setState({ store });

    values["store_id"] = store._id;
    values["isActive"] = store.isPickupActive;
    this.api
      .put({
        url: "/store/override-pickup",
        data: values,
      })
      .then(({ data }) => {
        if (data.result === "ok") {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            // timer: 1000
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { isActive, store } = this.state;
    return (
      <>
        <Card className="card-custom gutter-b">
          <Card.Header>
            <Card.Title> Pickup Settings</Card.Title>
          </Card.Header>
          <Card.Body>
            <div>
              <InfoAlertHelper
                text={
                  "You can manage your pickup service availability in your Pickup Settings"
                }
              />
              <Block
                content={
                  <>
                    <Card className="d-flex justify-content-between w-100 mb-5">
                      <Card.Body>
                        <div
                          style={{ transform: "scale(1.2)" }}
                          className="row d-flex align-items-center"
                        >
                          <span className="switch switch-outline switch-icon switch-primary py-1 m-0 ml-10 pl-10">
                            <label>
                              <input
                                type="checkbox"
                                name="isActive"
                                checked={isActive ? "checked" : null}
                                // disabled={store.name? "" : "disabled"}
                                onChange={() =>
                                  this.handleTogglePickupStatus(isActive)
                                }
                              />
                              <span></span>
                            </label>
                          </span>
                          <label className="text-dark font-weight-boldest text-uppercase py-4 px-3">
                            Allow Pickup Orders
                          </label>
                          {InfoTooltip(
                            "Turning this off will remove the pickup option for your customers.",
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                    <ServiceChargeForm store={store} order_type={"pickup"} />
                  </>
                }
              ></Block>
            </div>
          </Card.Body>
        </Card>
      </>
    );
  }
}
