import React from "react";
import { Form, Modal } from "react-bootstrap";
import ProSourceForm from "../components/ProSourceForm";
import { DB_DATE_FORMAT } from "../constants";
import { GetSubmitClassList } from "../helpers";
import moment from "moment";
import { EditOffDatesTable } from "./StoreSetup";
import { InfoAlertHelper } from "../components/Base";
import helpertexts from "../helpertexts";

export default class SetOffDatesModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { off_dates = [] } = this.props;

    this.state = {
      values: {
        off_dates,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.handleOnSelectAddDate = this.handleOnSelectAddDate.bind(this);
    this.handleOnSelectDeleteDate = this.handleOnSelectDeleteDate.bind(this);
  }

  handleOnSelectAddDate = (date, setShowAddForm) => {
    const newDate = moment(date).format(DB_DATE_FORMAT);
    let { values = {} } = this.state;

    if (!values.off_dates.includes(newDate)) {
      values.off_dates.push(newDate);
      this.setState({ values });
    }

    setShowAddForm(false);
  };

  handleOnSelectDeleteDate = (date) => {
    let { values = {} } = this.state;
    values.off_dates = values.off_dates.filter((off_date) => off_date !== date);
    this.setState({ values });
  };

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const { show, onHide } = this.props,
      { values = {}, isSubmitting = false } = this.state;

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>Set Off Dates</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            <InfoAlertHelper
              text={helpertexts["getting-started"]["store_off_dates"]}
            />
            <EditOffDatesTable
              off_dates={values["off_dates"]}
              actions={{
                onSelectAddDate: this.handleOnSelectAddDate,
                onSelectDeleteDate: this.handleOnSelectDeleteDate,
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              disabled={isSubmitting}
              className={GetSubmitClassList(
                isSubmitting,
                `btn btn-primary font-weight-bold`,
              )}
            >
              {isSubmitting ? "Saving changes..." : "Save changes"}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
