import React from "react";
import APIComponent from "../../components/API";
import { LoadingDiv } from "../../components/Base";
import { toPesoAmount } from "../../helpers";
import core from "../../vendors/core-api/index";

import StoreCardData from "./StoreCardData";

export default class StoreCardFooter extends APIComponent {
  constructor(props) {
    super(props);
    // console.log(this.props);
    const { store_id = "" } = this.props;
    this.api = core("pu").get();
    this.data_url = "/store/" + store_id + "/statistics";
  }

  async getData() {
    if (this.data_url !== "") {
      this._isMounted && this.setState({ data_status: "fetching" });
      this.api &&
        this.api
          .get(this.data_url)
          .then(({ data }) => {
            this._isMounted &&
              this.setState({
                data: data.data,
                data_status: "fetched",
              });
            this.handleOnDataFetched();

            if (this.use_display_data) {
              this.setDisplayData();
            }
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });
    } else {
      this.afterComponentDidMount();
    }
  }

  render() {
    const { store_id = "" } = this.props;
    const { data_status = "", data = {} } = this.state,
      isLoading = data_status !== "fetched",
      { item_sold = 0, earnings = 0, orders_served = 0 } = data;
    return isLoading ? (
      <LoadingDiv />
    ) : (
      <>
        <div className="d-flex align-items-center flex-wrap">
          <StoreCardData
            data_url="/dashboard/item-sold"
            storeId={store_id}
            label="Total Items Sold"
            icon="confetti"
          />
          <StoreCardData
            data_url="/dashboard/order-served"
            storeId={store_id}
            label="Total Orders Served"
            icon="users"
          />
          <StoreCardData
            data_url="/dashboard/total-sales"
            storeId={store_id}
            label="Total Earnings"
            icon="coins"
            valueFormatter={toPesoAmount}
          />
        </div>
      </>
    );
  }
}
