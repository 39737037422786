import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ChromePicker } from "react-color";
import EmojiPicker from "../../components/widgets/EmojiPicker";
import ItemTag from "../../components/widgets/ItemTag";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers/AssetsHelpers";
import rgbHex from "rgb-hex";

export default function EditItemTagModal({
  show,
  setShow,
  tag,
  onBgColorChanged,
  onTextColorChanged,
  onIconChanged,
  original,
  toggleIsChanged,
}) {
  const handleClose = () => setShow(false);

  const handleDiscard = () => {
    setIcon(discardValues.icon);
    setBGColor(discardValues.bgColor);
    setTextColor(discardValues.textColor);
    setBorderColor(discardValues.borderColor);
    handleClose();
  };

  const handleApply = () => {
    onBgColorChanged(tag.id, bgColor);
    onTextColorChanged(tag.id, textColor);
    onIconChanged(tag.id, icon);
    toggleIsChanged("Tags (Theme)", "Changed");
    handleClose();
  };

  const handleReset = () => {
    setIcon(original[tag.id].icon);
    setBGColor(original[tag.id].bgColor);
    setTextColor(original[tag.id].textColor);
    setBorderColor(original[tag.id].borderColor);

    onBgColorChanged(tag.id, original[tag.id].bgColor);
    onTextColorChanged(tag.id, original[tag.id].textColor);
    onIconChanged(tag.id, original[tag.id].icon);

    handleClose();
  };

  const [icon, setIcon] = useState("");
  const [bgColor, setBGColor] = useState("#ffffff");
  const [textColor, setTextColor] = useState("#ffffff");
  const [borderColor, setBorderColor] = useState("#ffffff");

  const [showIconPicker, setShowIconPicker] = useState(false);
  const [showBGColorPicker, setShowBGColorPicker] = useState(false);
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);

  const [discardValues, setDiscardValues] = useState({
    icon: "",
    bgColor: "",
    textColor: "",
    borderColor: "",
  });

  useEffect(() => {
    setIcon(tag.icon);
    setBGColor(tag.bgColor);
    setTextColor(tag.textColor);
    setBorderColor(tag.borderColor);

    setDiscardValues((prevState) => {
      const newValues = { ...prevState };
      newValues.icon = tag.icon;
      newValues.bgColor = tag.bgColor;
      newValues.textColor = tag.textColor;
      newValues.borderColor = tag.borderColor;
      return newValues;
    });
  }, [tag.icon, tag.bgColor, tag.textColor, tag.borderColor]);

  const onSetIcon = (icon) => {
    setIcon(icon);
    setShowIconPicker(false);
  };

  const closeAllPickers = () => {
    setShowIconPicker(false);
    setShowBGColorPicker(false);
    setShowTextColorPicker(false);
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} className="second-modal">
        <Modal.Header closeButton>
          <Modal.Title>Edit Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <ItemTag
              backgroundColor={bgColor}
              textColor={textColor}
              borderColor={borderColor}
              icon={icon}
              name={tag.name}
            />
          </center>
          <hr />
          <strong>Click to change:</strong>
          <br />
          <br />
          <div className="row">
            <div className="col-2">
              <span
                style={{ fontSize: "2rem", cursor: "pointer" }}
                onClick={() => {
                  closeAllPickers();
                  setShowIconPicker(!showIconPicker);
                }}
              >
                {icon}
              </span>
            </div>
            <div className="col-2">
              <span
                style={{
                  position: "absolute",
                  width: "2.5rem",
                  height: "2.5rem",
                  backgroundColor: bgColor,
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  closeAllPickers();
                  setShowBGColorPicker(!showBGColorPicker);
                }}
              ></span>
            </div>
            <div className="col-2">
              <span
                style={{ fontSize: "2rem", cursor: "pointer" }}
                onClick={() => {
                  closeAllPickers();
                  setShowTextColorPicker(!showTextColorPicker);
                }}
              >
                A
              </span>
            </div>
          </div>

          <br />
          <center>
            {showIconPicker && (
              <div>
                <center>
                  <EmojiPicker setEmoji={onSetIcon} />
                </center>
                <br />
                <center style={{ cursor: "pointer" }} onClick={closeAllPickers}>
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                  />
                </center>
              </div>
            )}
            {showBGColorPicker && (
              <div>
                <center>
                  <ChromePicker
                    color={bgColor}
                    onChangeComplete={(c) => {
                      setBGColor(
                        "#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a),
                      );
                    }}
                    style={{ marginRight: "0" }}
                  />
                </center>
                <br />
                <center style={{ cursor: "pointer" }} onClick={closeAllPickers}>
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                  />
                </center>
              </div>
            )}
            {showTextColorPicker && (
              <div>
                <center>
                  <ChromePicker
                    color={textColor}
                    onChangeComplete={(c) => {
                      setTextColor(
                        "#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a),
                      );
                      setBorderColor(
                        "#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a),
                      );
                    }}
                  />
                </center>
                <br />
                <center style={{ cursor: "pointer" }} onClick={closeAllPickers}>
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                  />
                </center>
              </div>
            )}
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleApply}>
            Apply
          </Button>
          <Button variant="danger" onClick={handleDiscard}>
            Discard
          </Button>
          <Button variant="secondary" onClick={handleReset}>
            Reset
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
