import React from "react";
import { AnimateDiv, Image250 } from "../../components/Base";
import { Card } from "react-bootstrap";
import BrandWebsite from "../../partials/BrandWebsite";
import TitleHelmet from "../../partials/TitleHelmet";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import PageCenter from "../../components/PageCenter";
import { Link, Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { EXTERNAL_CHECKOUT_KEY } from "../../constants";
import qs from "qs";
import axios from "axios";

const instance = axios.create({});
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => Promise.reject(err),
);

export default function PaymentFailedPage(props) {
  const { token = "" } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  return <MainComponent token={token} {...props} />;
}

class MainComponent extends BrandWebsite {
  setTitle = () => {
    // const { token = "" } = this.props;
    this.setState({ title: "Payment Pending", showSplashScreen: false });
  };

  render() {
    let isExternal = localStorage.getItem(EXTERNAL_CHECKOUT_KEY) || false;
    isExternal = JSON.parse(isExternal);
    const { data = {}, data_status = "", website_theme = {} } = this.state,
      { token = "" } = this.props,
      isLoading = data_status !== "fetched";
    // const { highlighted_text_color = "", button_color = "", button_text_color = "" } = website_theme, buttonStyle = { backgroundColor: button_color, borderColor: button_color, color: button_text_color };
    const { highlighted_text_color = "" } = website_theme;

    return (
      <>
        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}
        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <TitleHelmet title={this.state.title} showWebsiteName={false} />
        {isLoading ? (
          <LayoutSplashScreen />
        ) : (
          <PageCenter>
            <AnimateDiv>
              <Card
                className="card-border-top-primary card-custom"
                style={{ borderTopColor: highlighted_text_color }}
              >
                <Card.Body style={{ width: "350px" }}>
                  <div className="px-20">
                    <div className="text-center mb-1 text-dark-75">
                      {!isExternal ? (
                        <Link to={"/"}>
                          <div className="font-weight-bolder font-pickup-sm-2">
                            {data.name}
                          </div>
                        </Link>
                      ) : (
                        <div className="font-weight-bolder font-pickup-sm-2">
                          {data.name}
                        </div>
                      )}
                      <div className="text-uppercase">powered by pickup.ph</div>
                    </div>
                  </div>
                  <div className="text-center my-10">
                    <Image250
                      src={toAbsoluteUrl(`/media/svg/croods/new.png`)}
                    />
                  </div>
                  <div className="text-center">
                    <div className="text-uppercase font-weight-bolder font-pickup-sm-2">
                      Payment Pending
                    </div>
                    <div>
                      Hi there! We have received your order but it seems that
                      our payment provider is still verifying your payment. We
                      will email you once the payment has been confirmed. Thank
                      you!
                    </div>
                    {/* <Button variant="custom" style={buttonStyle} className={"btn-block btn-lg text-uppercase font-weight-bolder mt-5"} onClick={this.onClickUseDifferentPaymentMethod}>Use different payment method</Button> */}
                    <PendingChecker
                      token={token}
                      setRedirectTo={(redirectTo) => {
                        this.setState({ redirectTo });
                      }}
                    />
                  </div>
                </Card.Body>
              </Card>
            </AnimateDiv>
          </PageCenter>
        )}
      </>
    );
  }
}

class PendingChecker extends APIComponent {
  constructor(props) {
    super(props);

    this.api = core("pu").get();
  }

  async getData() {
    const baseURL = this.api.getBaseURL(),
      { token = "" } = this.props;
    instance
      .post(
        "/branded-website/payments/verify-pending-token",
        {},
        { headers: { Authorization: `Basic ${token}` }, baseURL },
      )
      .then(({ data }) => {
        const {
          result = "",
          status = "",
          order = "",
          failed_token = "",
        } = data;
        if (result === "ok") {
          if (status === "paid") {
            this.props.setRedirectTo("/track?token=" + order);
          } else if (status === "failed") {
            this.props.setRedirectTo("/payments?token=" + failed_token);
          }
        } else {
          this.handleError();
        }
      })
      .catch(() => {
        this.handleError();
      });
  }

  render() {
    return <></>;
  }
}
