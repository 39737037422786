import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { SVGIcon } from "../../components/Base";
import core from "../../vendors/core-api";
import { Redirect, Link } from "react-router-dom";
import { fetchData } from "../../helpers";
import PacmanLoading from "../../components/widgets/PacmanLoading/PacmanLoading";
import { Card, Col, Row, Tab, Nav } from "react-bootstrap";
import Activities from "../../partials/Activities";
import { useSelector } from "react-redux";

export default function AdminToolsPage(props) {
  const MENUS = {
    admintools: "admintools",
    activity: "activity",
  };

  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState(false);
  const [adminToolGroups, setAdminToolGroups] = useState("");
  const [activeMenuKey, setActiveMenuKey] = useState(MENUS.admintools);
  const api = core("pu").get();

  const handleGetAdminTools = async () => {
    const tools = await fetchData("get", `/admintools/tools`);
    return tools;
  };

  useEffect(() => {
    const getTools = async () => {
      const response = await handleGetAdminTools();
      const tools = response.data.adminToolGroups;
      setAdminToolGroups(tools);
    };
    getTools();
  }, []);

  return loading ? (
    <div className="d-flex justify-content-center align-items-center h-100">
      <PacmanLoading />
    </div>
  ) : redirectTo ? (
    <Redirect to={redirectTo} />
  ) : (
    <>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={MENUS.admintools}
        activeKey={activeMenuKey}
      >
        <Row>
          <Col sm={3}>
            <Card>
              <Card.Body>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link
                      eventKey={MENUS.admintools}
                      onClick={() => setActiveMenuKey(MENUS.admintools)}
                    >
                      Admin Tools
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey={MENUS.activity}
                      onClick={() => setActiveMenuKey(MENUS.activity)}
                    >
                      Activity Logs
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey={MENUS.admintools}>
                <Card>
                  <Card.Body style={{ backgroundColor: "#efeff7" }}>
                    <div>
                      <div className="row">
                        <div className="col-md-9 col-sm-12">
                          <Breadcrumbs>
                            <Breadcrumbs.Item
                              text="Admin Tools"
                              link={`/admin/tools`}
                              active="true"
                            />
                          </Breadcrumbs>
                        </div>
                      </div>
                      <br />
                      <br />
                      <Accordion
                        allowMultipleExpanded={true}
                        allowZeroExpanded={true}
                        preExpanded={["acc-0"]}
                      >
                        {adminToolGroups &&
                          adminToolGroups.map((toolGroup, index) => {
                            return (
                              <div key={index}>
                                <AccordionItem uuid={`acc-${index}`}>
                                  <AccordionItemHeading className="display-4 font-weight-bolder">
                                    <AccordionItemButton>
                                      <span className="pl-3">
                                        {toolGroup.name}
                                      </span>
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    <>
                                      <div
                                        className="row"
                                        style={{ paddingLeft: "3rem" }}
                                      >
                                        {toolGroup.tools.map((tools, index) => {
                                          return tools.name ? (
                                            <Link
                                              key={tools.name}
                                              to={
                                                toolGroup.scope === "brands"
                                                  ? `/admin/tools/brands/${tools.url}`
                                                  : toolGroup.scope === "stores"
                                                  ? `/admin/tools/stores/${tools.url}`
                                                  : toolGroup.scope === "users"
                                                  ? `/admin/tools/users/${tools.url}`
                                                  : toolGroup.scope === "orders"
                                                  ? `/admin/tools/orders/${tools.url}`
                                                  : "/admin/tools"
                                              }
                                              className="col-lg-3 col-md-4 col-xs-12 store-name-card d-flex flex-column"
                                            >
                                              <div key={index}>
                                                <span className="font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2 text-uppercase text-primary">
                                                  <SVGIcon
                                                    icon="Code/Settings4"
                                                    title={tools.name}
                                                    className="svg-icon-md svg-icon-gray-500 mr-1"
                                                  />
                                                  {tools.name}
                                                </span>
                                              </div>
                                            </Link>
                                          ) : null;
                                        })}
                                      </div>
                                      <br />
                                    </>
                                  </AccordionItemPanel>
                                </AccordionItem>
                                <br />
                              </div>
                            );
                          })}
                      </Accordion>
                    </div>
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey={MENUS.activity}>
                <Card className="card-custom gutter-b">
                  <Card.Header>
                    <Card.Title>Activity Logs</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Activities
                      collection="admin"
                      identifier={user._id}
                      api={api}
                    />
                  </Card.Body>
                </Card>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
}
