import React, { useEffect } from "react";
import APIComponent from "../components/API";
import moment from "moment";
import {
  ASSIGNING_STATUS,
  CANCELLED_DELIVERIES,
  COMPLETED_STATUS,
  ON_PROCESS_STATUSES,
  ON_GOING_STATUSES,
  TIMEZONE_OFFSET_IN_HOURS,
} from "../constants";
import { formatDeliveryStatusTitles } from "../helpers";
import PaginationBar from "../components/PaginationBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import Skeleton from "react-loading-skeleton";

function getVariant(status) {
  let variant = "success";

  if (CANCELLED_DELIVERIES.includes(status)) variant = "danger";
  if (ON_PROCESS_STATUSES.includes(status)) variant = "warning";
  if (ASSIGNING_STATUS.includes(status)) variant = "secondary";
  return variant;
}
export default class DeliveryLogs extends APIComponent {
  constructor(props) {
    super(props);

    const { order = "", api } = this.props;

    this.api = api;
    this.state = {};
    this.data_url = `/delivery-logs/order/${order._id}?page=1&limit=10`;
  }

  handleSelectPage = (pageNum = 1) => {
    const { order = "" } = this.props;
    this.data_url = `/delivery-logs/order/${order._id}?page=${pageNum}&limit=10`;
    this.getData();
  };
  refreshLogs = () => {
    this.getData();
  };
  componentWillUnmount() {
    this._mounted = false;
  }
  componentDidUpdate(prevProps) {
    if (this.props.deliveryLogsUpdateKey !== prevProps.deliveryLogsUpdateKey) {
      this.refreshLogs();
    }
  }
  async componentDidMount() {
    this._isMounted = true;
    this.getData();
  }

  render() {
    const { data_status = "", data = {} } = this.state,
      isLoading = data_status !== "fetched";
    const {
      delivery: { delivery_partner },
    } = this.props.order;
    const {
      page = 1,
      totalDocs = 0,
      limit = 10,
      docs = [],
      totalPages = 1,
    } = data;
    const { maxHeight = "60vh" } = this.props;
    const pagesArr = Array.from({ length: totalPages }, (_, i) => i);
    const defaultLimit = Array.from({ length: limit - 3 }, (_, i) => i);
    return (
      <>
        {isLoading ? (
          <div className="timeline timeline-5">
            {defaultLimit.map((i) => (
              <div
                key={i + "sklogs"}
                className="timeline-item align-items-center"
              >
                <div className="timeline-label fw-bold text-gray-800 fs-6 text-center">
                  <Skeleton height={30} width={30} />
                </div>

                <div className="timeline-badge">
                  <i className={`fa fa-genderless text-secondary icon-xxl`}></i>
                </div>

                <div className="timeline-content text-dark-50">
                  <Skeleton height={30} width={100} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <PerfectScrollbar
            options={{ wheelPropagation: false }}
            className="scroll"
            style={{ maxHeight }}
          >
            <div className="timeline timeline-5">
              {docs.map((logs, index) => {
                const { status, timestamp, deliveryId, orderId } = logs;
                return (
                  <div
                    key={index + "dlogs"}
                    className="timeline-item align-items-center"
                  >
                    <div className="timeline-label fw-bold text-gray-800 font-size-sm text-center">
                      {moment(timestamp).format("HH:mm MMM DD")}
                    </div>

                    <div className="timeline-badge">
                      <i
                        className={` fa fa-genderless text-${getVariant(
                          status,
                        )} icon-xxl`}
                      ></i>
                    </div>

                    <div className="timeline-content text-dark-75 text-hover-primary font-weight-bolder ">
                      {formatDeliveryStatusTitles(
                        delivery_partner,
                        status,
                        "merchant",
                      )}

                      <div className="font-size-sm text-muted font-weight-bold mt-1">
                        <span className="text-uppercase">
                          {[
                            ...ASSIGNING_STATUS,
                            ...COMPLETED_STATUS,
                            ...ON_GOING_STATUSES,
                          ].includes(status) &&
                            `( ${delivery_partner.toUpperCase()} ${
                              orderId || deliveryId || ""
                            } )`}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </PerfectScrollbar>
        )}
        <div className="position-sticky" style={{ left: 0 }}>
          <PaginationBar
            pagerTitle="Logs per page"
            pagesArr={pagesArr}
            totalResults={totalDocs}
            rows={limit}
            pageNum={page}
            limit={limit}
            onSelectPage={this.handleSelectPage}
          />
        </div>
      </>
    );
  }
}
