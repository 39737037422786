import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  OrderTimeRender,
  RequiredAsterisk,
  FormItem,
  HeaderTextWithActions2,
  Label,
  WarningAlert,
  InfoAlert,
} from "../../components/Base";
import ProSourceForm from "../../components/ProSourceForm";
import OrderTimeDropDown from "../branded-website/OrderTimeDropdown";
import DatePicker from "react-datepicker";
import moment from "moment";
import { DB_DATE_FORMAT } from "../../constants";
import core from "../../vendors/core-api";
import { GetSubmitClassList, toPesoAmount } from "../../helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export default class ChangeCustomerInfoModal extends ProSourceForm {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        first_name: "",
        last_name: "",
        email: "",
        contact_number: "",
      },
      values: {
        first_name: this.props.data.guest_customer.first_name,
        last_name: this.props.data.guest_customer.last_name,
        email: this.props.data.guest_customer.email,
        contact_number: this.props.data.guest_customer.contact_number,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      date_options: [],
      time_options: [],
      store_time_options: {},
      mountDatePicker: false,
      off_dates: [],
    };

    this.validate = {
      required: ["first_name", "last_name", "contact_number", "email"],
    };

    this.api = core("pu").get();
  }

  componentDidMount() {}

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit && this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const { onHide, show, data = {} } = this.props,
      {
        values = {},
        errors = {},
        mountOrderTimeDropdown = true,
        isSubmitting = false,
        buttonText = "Save Changes",
        showWarning = false,
      } = this.state,
      {
        store: selected_store,
        brand: { brand_url = "" },
        transaction_number = "",
        order_type = "",
        isMealPlan = false,
      } = data;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <Modal onHide={onHide} show={show} centered>
        <Modal.Header>
          <Modal.Title>
            Change Customer Information #{transaction_number}
          </Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            <Row>
              <Col>
                <div
                  className="alert alert-custom alert-outline-dark fade show mb-5"
                  role="alert"
                >
                  <div className="alert-icon">
                    <i className="flaticon-warning-sign"></i>
                  </div>
                  <div className="alert-text">
                    You are updating this order's customer information.
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mv-1rem">
              <Col md={12}>
                <Form.Group>
                  <FormItem
                    label={"First Name"}
                    name={"first_name"}
                    inputProps={{
                      name: "first_name",
                      value: this.state.values["first_name"],
                    }}
                    inputActions={inputActions}
                    actions={actions}
                    type={"text"}
                    showRequired={true}
                    normal
                  />
                  <FormItem
                    label={"Last Name"}
                    name={"last_name"}
                    inputProps={{
                      name: "last_name",
                      value: this.state.values["last_name"],
                    }}
                    inputActions={inputActions}
                    actions={actions}
                    type={"text"}
                    showRequired={true}
                    normal
                  />
                  <FormItem
                    label={"Email"}
                    name={"email"}
                    inputProps={{
                      name: "email",
                      value: this.state.values["email"],
                    }}
                    inputActions={inputActions}
                    actions={actions}
                    type={"text"}
                    disabled
                    normal
                  />
                  <FormItem
                    label={"Contact Number"}
                    name={"contact_number"}
                    inputProps={{
                      name: "contact_number",
                      value: this.state.values["contact_number"],
                    }}
                    inputActions={inputActions}
                    actions={actions}
                    type={"text"}
                    showRequired={true}
                    normal
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {showWarning ? (
              <Button
                type="submit"
                variant="danger"
                className={GetSubmitClassList(isSubmitting, "")}
                disabled={this.state.isSubmitting}
              >
                {buttonText}
              </Button>
            ) : (
              <Button
                type="submit"
                className={GetSubmitClassList(isSubmitting, "")}
                disabled={this.state.isSubmitting}
              >
                {buttonText}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
