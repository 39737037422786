import React, { useEffect, useState } from "react";
import { isEmpty, isNil } from "lodash";
import { FormItem } from "../components/Base";
import { GetSubmitClassList } from "../helpers";
import { Form, Card, Button } from "react-bootstrap";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";

function getIconVariant(type) {
  const variants = {
    reply: "flaticon2-chat-1",
    dispute: "flaticon2-sheet",
    report: "flaticon2-warning",
  };
  return variants[type] || "";
}

function actionVariant(type) {
  const variants = {
    reply: "replied : ",
    dispute: "submitted a dispute",
    report: "reported : ",
  };
  return variants[type] || "";
}
const MAX_CHARACTER_LENGTH = 300;

export function ReviewForms(props) {
  const [activeTab, setActiveTab] = useState("#kt_tab_pane_2");
  const [isSubmitting, setSubmitting] = useState(false);
  const [replyNote, setReplyNote] = useState("");

  const [reportNote, setReportNote] = useState("");
  const { storeRating, api, user } = props;

  const [ratingNotes, setRatingNotes] = useState(
    storeRating.rating_notes || [],
  );
  const storeDisputeNote = storeRating.dispute_note?.note ?? "";
  const [disputeNote, setDisputeNote] = useState(storeDisputeNote);
  const rating_users = !isEmpty(storeRating.users) ? storeRating.users : [user];

  const clearNotes = () => {
    setReplyNote("");
    setReportNote("");
  };

  const submitUpdate = async (type) => {
    const { _id } = storeRating;
    let dispute_note = null;
    if (type === "dispute") {
      dispute_note = {
        note: disputeNote,
        created_by: user._id,
      };
    }
    const newStoreRating = {
      ...storeRating,
      dispute_note,
      rating_notes: ratingNotes,
    };
    setSubmitting(true);
    try {
      const response = await api.put({
        url: `/store-rating/${_id}`,
        data: { ...newStoreRating, type },
      });
      if (response.status === 200) {
        setSubmitting(false);
        clearNotes();
      }
    } catch (e) {
      setSubmitting(false);
    }
  };
  const pushRatingLog = (type) => {
    const notes = {
      dispute: "",
      reply: replyNote,
      report: reportNote,
    };
    const ratingNote = notes[type];
    ratingNotes.unshift({
      type,
      note: ratingNote,
      created_by: user._id,
    });
    setRatingNotes(ratingNotes);
    submitUpdate(type);
  };

  return (
    <>
      <Card className="mb-5">
        <Card.Body>
          <div className="text-dark-75 text-primary font-weight-bolder text-break">
            <span>Review Actions</span>
            <hr />
          </div>
          <div id="kt_quick_panel">
            <ul className="nav nav-tabs nav-tabs-line mb-5">
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "#kt_tab_pane_2" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("#kt_tab_pane_2")}
                >
                  <span className="nav-icon">
                    <i className="flaticon2-sheet"></i>
                  </span>
                  <span className="nav-text">Dispute</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "#kt_tab_pane_1" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("#kt_tab_pane_1")}
                >
                  <span className="nav-icon">
                    <i className="flaticon2-chat-1"></i>
                  </span>
                  <span className="nav-text">Reply</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "#kt_tab_pane_3" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("#kt_tab_pane_3")}
                >
                  <span className="nav-icon">
                    <i className="flaticon2-warning"></i>
                  </span>
                  <span className="nav-text">Report</span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div
                className={`tab-pane fade ${
                  activeTab === "#kt_tab_pane_1" ? "show active" : ""
                }`}
                id="kt_tab_pane_1"
                role="tabpanel"
              >
                <div className="text-break">
                  <span>
                    <div className="font-size-sm font-weight-bolder text-muted mt-2">
                      Please note that this message will be notified to the
                      reviewer
                    </div>
                  </span>
                </div>
                <Form.Control
                  as={"textarea"}
                  value={replyNote}
                  className="mt-5"
                  placeholder={"Let the reviewer know that.."}
                  rows={10}
                  maxLength={MAX_CHARACTER_LENGTH}
                  onChange={({ target: { value } }) => setReplyNote(value)}
                />
                <span className="form-text text-muted text-right">
                  {reportNote.length}/{MAX_CHARACTER_LENGTH}
                </span>
                <div className="d-flex justify-content-end">
                  <Button
                    type="submit"
                    className={GetSubmitClassList(
                      isSubmitting,
                      "btn btn-primary font-weight-bold mt-5",
                    )}
                    disabled={isEmpty(replyNote)}
                    onClick={() => pushRatingLog("reply")}
                  >
                    Send Reply
                  </Button>
                </div>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "#kt_tab_pane_2" ? "show active" : ""
                }`}
                id="kt_tab_pane_2"
                role="tabpanel"
              >
                <div className="text-break">
                  <span>
                    <div className="font-size-sm font-weight-bolder text-muted mt-2">
                      PickupPH values your commitment to providing excellent
                      service
                    </div>
                  </span>
                </div>

                <Form.Control
                  as={"textarea"}
                  value={disputeNote}
                  className="mt-5"
                  placeholder={"Let the reviewer know that.."}
                  rows={10}
                  maxLength={MAX_CHARACTER_LENGTH}
                  onChange={({ target: { value } }) => setDisputeNote(value)}
                />
                <span className="form-text text-muted text-right">
                  {disputeNote.length}/{MAX_CHARACTER_LENGTH}
                </span>
                <div className="d-flex justify-content-end">
                  <Button
                    type="submit"
                    className={GetSubmitClassList(
                      isSubmitting,
                      "btn btn-primary font-weight-bold mt-5",
                    )}
                    disabled={isEmpty(disputeNote)}
                    onClick={() => pushRatingLog("dispute")}
                  >
                    {isEmpty(storeDisputeNote)
                      ? "Submit Dispute"
                      : "Update Dispute"}
                  </Button>
                </div>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "#kt_tab_pane_3" ? "show active" : ""
                }`}
                id="kt_tab_pane_3"
                role="tabpanel"
              >
                <div className="text-break">
                  <span>
                    <div className="font-size-sm font-weight-bolder text-muted mt-2">
                      PickupPH will promptly address and take necessary actions
                      regarding the concerns raised in this reported review to
                      ensure customer satisfaction and service improvement.
                    </div>
                  </span>
                </div>

                <Form.Control
                  as={"textarea"}
                  className="mt-5"
                  value={reportNote}
                  placeholder={"Let PickupPH Team know that.."}
                  rows={10}
                  maxLength={MAX_CHARACTER_LENGTH}
                  onChange={({ target: { value } }) => setReportNote(value)}
                />
                <span className="form-text text-muted text-right">
                  {reportNote.length}/{MAX_CHARACTER_LENGTH}
                </span>
                <div className="d-flex justify-content-end">
                  <Button
                    type="submit"
                    className={GetSubmitClassList(
                      isSubmitting,
                      "btn btn-primary font-weight-bold mt-5",
                    )}
                    disabled={isEmpty(reportNote)}
                    onClick={() => pushRatingLog("report")}
                  >
                    Send Report
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      {ratingNotes.length > 0 && (
        <Card>
          <Card.Body>
            <div className="text-dark-75 text-primary font-weight-bolder text-break">
              <span>Resolution and Dispute History</span>
              <hr />
            </div>
            <PerfectScrollbar
              options={{ wheelPropagation: false }}
              className="scroll"
              style={{ maxHeight: "40vh", width: "100%" }}
            >
              <div>
                <div className="timeline timeline-justified timeline-4 ml-2">
                  <div className="timeline-items">
                    {ratingNotes
                      .sort(
                        (a, b) =>
                          new Date(b.date_created) - new Date(a.date_created),
                      )
                      .map((rating_note, index) => {
                        const { type, note, date_created, created_by } =
                          rating_note;

                        const { first_name, last_name } = rating_users.find(
                          ({ _id }) => _id === created_by,
                        );
                        return (
                          <div key={index + "rnotes"} className="timeline-item">
                            <div className="timeline-badge">
                              <i className={getIconVariant(type)}></i>
                            </div>

                            <div className="timeline-label">
                              <span className="text-primary font-weight-bold">
                                {moment(date_created).format("MM/DD hh:mmA ")}
                              </span>
                            </div>
                            <div className="timeline-content">
                              <b>
                                {first_name} {last_name} {actionVariant(type)}
                              </b>
                              {note}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </Card.Body>
        </Card>
      )}
    </>
  );
}
