import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Row, Col } from "react-bootstrap";

import { toPesoAmount } from "../../helpers";
import StatCard from "../../components/widgets/StatCard";
import StatCardApi from "../../components/widgets/StatCardApi";
import { DB_DATE_FORMAT } from "../../constants";

const Row1 = (props) => {
  const {
    date = moment().format(DB_DATE_FORMAT),
    row1 = {},
    statCardQuery = "day",
    statLink = "",
    view = "",
    attr = "",
    role = "",
    brands = [],
  } = props;

  const apiOptions = {
    brands: brands,
    date: date,
    dateRange: statCardQuery,
    role: role,
    attr: attr,
    id: view,
  };

  return (
    <>
      <div className="container bg-white py-4 px4 rounded rounded-right-0 rounded-bottom">
        <Row className="mt-5">
          <Col xs={12} xl={6}>
            <div className={`card card-custom bg-white card-stretch gutter-b`}>
              <div className="card-body">
                <span
                  className={`card-title font-weight-bolder font-size-h1 mb-0 mt-6 d-block`}
                >
                  {`${toPesoAmount(row1.total_sale * 0.3 || 0)}*`}
                </span>
                <div className={`font-weight-normal font-size-sm mt-4`}>
                  Saved by using <span className="text-primary">PickUp.ph</span>{" "}
                  instead of a delivery app
                </div>
                <span className="font-weight-light font-size-xs">
                  *Average commission for delivery apps is 30%
                </span>
              </div>
            </div>
          </Col>

          <Col xs={6} xl={3}>
            <Link
              to={`${statLink}?filter=Orders Served&duration=${statCardQuery}&date=${date}&view=${view}&attr=${attr}`}
              className="text-dark-75 font-weight-bold  font-size-sm"
            >
              <StatCardApi
                data_url="/dashboard/order-served"
                title="orders served"
                apiMethod="post"
                // statFormatter= {(stat)=> stat}
                apiOptions={apiOptions}
              />
            </Link>
          </Col>

          <Col xs={6} xl={3}>
            {role === "super_admin" ? (
              <Link
                to={`/admin/onboarding?duration=${statCardQuery}&date=${date}&view=${view}`}
                className="text-dark-75 font-weight-bold font-size-sm"
              >
                <StatCardApi
                  icon={"General/Expand-arrows"}
                  data_url={`/analytics/onboarding`}
                  title="onboarded groups"
                  dateRange={statCardQuery}
                  date={date}
                />
              </Link>
            ) : (
              <Link
                to={`${statLink}?filter=All Orders&duration=${statCardQuery}&date=${date}&view=${view}&attr=${attr}`}
                className="text-dark-75 font-weight-bold font-size-sm"
              >
                <StatCardApi
                  icon={"Weather/Suset1"}
                  data_url="/dashboard/orders-by-duration"
                  title="orders"
                  apiMethod="post"
                  apiOptions={apiOptions}
                />
              </Link>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={4}>
            <Link
              to={`${statLink}?filter=Average Item Price&duration=${statCardQuery}&date=${date}&view=${view}&attr=${attr}`}
              className="text-dark-75 font-weight-bold  font-size-sm"
            >
              <StatCardApi
                icon={"Shopping/Sale1"}
                data_url="/dashboard/average-item-price"
                title="average item price"
                apiMethod="post"
                statFormatter={(stat) => toPesoAmount(stat)}
                apiOptions={apiOptions}
              />
            </Link>
          </Col>
          <Col xs={12} sm={4}>
            <Link
              to={`${statLink}${
                role === "super_admin" ? "/brands" : ""
              }?filter=Total Sales&duration=${statCardQuery}&date=${date}&view=${view}&attr=${attr}`}
              className="text-dark-75 font-weight-bold  font-size-sm"
            >
              <StatCardApi
                icon={"Shopping/Money"}
                data_url="/dashboard/total-sales"
                title="total sales"
                apiMethod="post"
                statFormatter={(stat) => toPesoAmount(stat) || 0}
                apiOptions={apiOptions}
              />
            </Link>
          </Col>
          <Col xs={12} sm={4}>
            <Link
              to={`${statLink}?filter=Returning Customers&duration=${statCardQuery}&date=${date}&view=${view}&attr=${attr}`}
              className="text-dark-75 font-weight-bold  font-size-sm"
            >
              <StatCardApi
                icon={"General/Smile"}
                data_url="/dashboard/returning-customers"
                title="returning customers"
                apiMethod="post"
                statFormatter={(stat) => `${stat ? stat.toFixed(2) : 0}%`}
                apiOptions={apiOptions}
              />
            </Link>
          </Col>
        </Row>

        <Row className="">
          <Col xs={12} sm={3}>
            <Link
              to={`${statLink}?filter=Items Sold&duration=${statCardQuery}&date=${date}&view=${view}&attr=${attr}`}
              className="text-dark-75 font-weight-bold  font-size-sm"
            >
              <StatCardApi
                icon={"Shopping/Cart2"}
                data_url="/dashboard/item-sold"
                title="item sold"
                apiMethod="post"
                // statFormatter= {(stat)=> `${stat? stat.toFixed(2): 0}%`}
                apiOptions={apiOptions}
              />
            </Link>
          </Col>

          <Col xs={12} sm={3}>
            <StatCard
              icon={"General/Update"}
              variant="white"
              title="items remade"
              stat={
                <span className="label label-sm label-warning label-pill label-inline text-uppercase d-inline-block ">
                  Coming Soon
                </span>
              }
            />
          </Col>
          <Col xs={12} sm={3}>
            <Link
              to={`${statLink}?filter=Cancelled Orders&duration=${statCardQuery}&date=${date}&view=${view}&attr=${attr}`}
              className="text-dark-75 font-weight-bold font-size-sm"
            >
              <StatCardApi
                icon={"Files/Deleted-file"}
                data_url="/dashboard/orders/cancelled"
                title="orders cancelled"
                apiMethod="post"
                // statFormatter= {(stat)=> `${stat? stat.toFixed(2): 0}%`}
                apiOptions={apiOptions}
              />
            </Link>
          </Col>
          <Col xs={12} md={3}>
            <Link
              to={`${statLink}?filter=Refunded Orders&duration=${statCardQuery}&date=${date}&view=${view}&attr=${attr}`}
              className="text-dark-75 font-weight-bold font-size-sm"
            >
              <StatCardApi
                icon={"Communication/Chat6"}
                data_url="/dashboard/orders/refunded"
                title="refunded orders"
                apiMethod="post"
                // statFormatter= {(stat)=> `${stat? stat.toFixed(2): 0}%`}
                apiOptions={apiOptions}
              />
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Row1;
