import React from "react";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { injectIntl } from "react-intl";
import { useParams } from "react-router-dom";
import core from "../../../vendors/core-api/";
import { setStorage } from "../../../../_metronic/_helpers";

class LoginWithToken extends React.Component {
  render() {
    const LoginWithToken = () => {
      const { token = "" } = useParams();
      core().setAuthToken(token);
      setStorage("PRK", token);
      this.props.login(token);
      return <></>;
    };
    return <LoginWithToken />;
  }
}

export default injectIntl(connect(null, auth.actions)(LoginWithToken));
