import React, {useMemo} from "react";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { WEBSITE_NAME } from "../../../../app/constants";

export function Footer() {
  // const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1 text-center">
          {/* <span className="text-muted font-weight-bolder mr-2">{today.toString()}</span> &copy;{" "} */}
          <a
            href="http://www.pickup.ph"
            target="_blank"
            rel="noopener noreferrer"
            className="text-dark-75 text-hover-primary font-weight-bolder"
          >Powered by {WEBSITE_NAME}</a>
        </div>
        {/* <div class="mfes-trustmark" data-type="102" data-width="90" data-height="37"></div> */}
        <div className="nav nav-dark order-1 order-md-2">
          <a
            href="http://www.pickup.ph/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link pr-3 pl-0"
          >
            Terms and Conditions
          </a>
          <a
            href="https://pickup.ph/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link px-3"
          >
            Privacy Policy
          </a>
          <a href="https://www.mcafeesecure.com/verify?host=pickup.ph" target="_blank" rel="noopener noreferrer">
              <img className="va-middle ml-0" alt="mcafee-secure" src={toAbsoluteUrl("/media/payment-method/mcafee-secure-badge.svg")} style={{width: '100px', height: '35px'}}/>
          </a>
        </div>
      </div>
    </div>
  );
}
