import React, { useEffect, useState } from "react";
import core from "../../vendors/core-api";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { SVGIcon } from "../../components/Base";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import { Redirect } from "react-router-dom";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";
import { Link } from "react-router-dom";

export default function OrderSelectionPage() {
  const api = core("pu").get();

  const [brands, setBrands] = useState([]);
  const [group, setGroup] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [redirectTo, setRedirectTo] = useState(false);

  useEffect(() => {
    const getStores = async () => {
      try {
        setLoading(true);
        const result = await api.get("/brand/get-stores?source=orders");
        if (result.data) {
          const brands = result.data.brands;
          if (brands.length === 1) {
            if (brands[0].stores.length === 1) {
              if (brands[0].stores[0]._id) {
                setRedirectTo(`/stores/${brands[0].stores[0]._id}/orders`);
              }
            }
          }

          setBrands(brands);
          setFilteredBrands(brands);
          setGroup(result.data.groupName);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getStores();
  }, []);

  const search = (evt) => {
    evt.persist();
    if (evt.target.value) {
      setFilteredBrands(() => {
        const filteredBrands = brands.filter((brand) => {
          let valid = false;
          if (
            brand.name.toLowerCase().indexOf(evt.target.value.toLowerCase()) >
            -1
          ) {
            valid = true;
          }
          const validStores = brand.stores.filter((store) =>
            store.hasOwnProperty("name")
              ? store.name
                  .toLowerCase()
                  .indexOf(evt.target.value.toLowerCase()) > -1
              : null,
          );
          if (validStores.length > 0) {
            valid = true;
          }
          return valid;
        });
        return filteredBrands;
      });
    } else {
      setFilteredBrands(() => {
        const filteredBrands = [...brands];
        return filteredBrands;
      });
    }
  };

  return loading ? (
    <PacmanWrapper removeBackgroundColor />
  ) : redirectTo ? (
    <Redirect to={redirectTo} />
  ) : (
    <div>
      <div className="row">
        <div className="col-md-9 col-sm-12">
          <Breadcrumbs>
            <Breadcrumbs.Item text={group} />
            <Breadcrumbs.Item text="Orders" link={`/orders`} active="true" />
          </Breadcrumbs>
        </div>
        <div className="col-md-2 col-sm-6">
          <input
            type="text"
            placeholder="Search..."
            className="form-control"
            onChange={search}
          />
        </div>
      </div>
      <br />
      <br />
      <Accordion
        allowMultipleExpanded={true}
        allowZeroExpanded={true}
        preExpanded={["acc-0"]}
      >
        {filteredBrands &&
          filteredBrands.map((brand, index) => {
            return (
              <div key={index}>
                <AccordionItem uuid={`acc-${index}`}>
                  <AccordionItemHeading className="display-4 font-weight-bolder">
                    <AccordionItemButton>
                      <span className="pl-3">{brand.name}</span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <>
                      <div className="row" style={{ paddingLeft: "3rem" }}>
                        {brand.stores.map((store, index) => {
                          return store._id ? (
                            <Link
                              className="col-lg-3 col-md-4 col-xs-12 store-name-card"
                              key={index}
                              to={`/stores/${store._id}/orders`}
                            >
                              <span>
                                <SVGIcon
                                  icon={`Shopping/Cart1`}
                                  variant="primary"
                                  className="mr-1"
                                />
                                <span className="ml-1">{store.name}</span>
                              </span>
                            </Link>
                          ) : null;
                        })}
                      </div>
                      <br />
                    </>
                  </AccordionItemPanel>
                </AccordionItem>
                <br />
              </div>
            );
          })}
      </Accordion>
    </div>
  );
}
