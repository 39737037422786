import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import APIComponent from "../../../components/API";
import { DB_DATE_FORMAT } from "../../../constants";
import { isEmpty } from "lodash";

const MySwal = withReactContent(Swal);

const getIdsAndAttr = function (props, selectedView = {}) {
  const { role = "", brands = [], stores = [] } = props;
  const { id = "", attr = "brand" } = selectedView;

  if (role === "brand_admin") return { id: id || brands[0], attr };
  if (role === "store_admin") return { id: id || stores[0], attr: "store" };

  return { id: id || brands, attr: attr || "brand" };
};

class ReportsApi extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      duration: props.duration,
      date: props.date,
      limit: props.limit,
      page: props.page,
      search: "",
      csvData: [],
      isForExport: false,
      isCustomDate: props.isCustomDate,
      showEndDatePicker: props.showEndDatePicker,
      endDate: props.endDate,
    };

    this.id = this.props.id;
    this.api = this.props.api;
    this.sortBy = "order_date";
    this.attr = this.props.attr;
    this.duration = this.props.duration;
    this.date = props.date || moment().format(DB_DATE_FORMAT);
    this.data_url = `/group/${this.props.id}/reports?dateRange=${this.duration}&date=${this.date}`;

    this.keyPress = this.keyPress.bind(this);
    this.getReqBody = this.getReqBody.bind(this);
    this.handleSearchDate = this.handleSearchDate.bind(this);
    this.handleTextSearch = this.handleTextSearch.bind(this);
    this.handleChangeSort = this.handleChangeSort.bind(this);
    this.handleSelectPage = this.handleSelectPage.bind(this);
    this.handleDropdownText = this.handleDropdownText.bind(this);
    this.processSearchValue = this.processSearchValue.bind(this);
    this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
    this.checkIfEmailInStrin = this.checkIfEmailInString.bind(this);
    this.handleChangeDurationg = this.handleChangeDuration.bind(this);
    this.handleDateInputChange = this.handleDateInputChange.bind(this);
    this.handleOnDateInputClick = this.handleOnDateInputClick.bind(this);
    this.handleInitialSelectedBrand =
      this.handleInitialSelectedBrand.bind(this);
    this.handleTextSearchInputChange =
      this.handleTextSearchInputChange.bind(this);
    this.handleCustomDateRange = this.handleCustomDateRange.bind(this);
    this.isCustomDate = this.props.isCustomDate;
  }

  handleOnDataFetched = () => {
    const { name = "" } = this.state.data;
    this.props.actions.setBrandName(name);
    this.props.actions.setSplashScreen(false);
  };

  handleDateInputChange = (e, name = "") => {
    const { showEndDatePicker } = this.state;
    this.setState({
      ...((name === "" || name === "startDate") && { date: e.target.value }),
      isCustomDate: name === "" && !showEndDatePicker,
      ...(showEndDatePicker &&
        name === "endDate" && { endDate: e.target.value }),
      page: 1,
    });
    this.handleDropdownText(this.duration, e.target.value);
  };

  handleSearchDate = () => {
    const { isCustomDate, showEndDatePicker, endDate } = this.state;
    this.setState(
      {
        duration:
          isCustomDate && endDate === undefined
            ? "day"
            : showEndDatePicker
            ? "between"
            : this.state.duration,
        data_status: "fetching",
        page: 1,
        ...(showEndDatePicker && { endDate }),
      },
      () => this.getData(),
    );
  };

  handleTextSearchInputChange = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      // trigger search when enter is pressed while text input is focused
      this.handleTextSearch();
    }

    this.setState({
      search: e.target.value,
    });
  };

  handleTextSearch = () => {
    this.setState(
      {
        data_status: "fetching",
        duration: "allTime",
        page: 1,
      },
      () => this.getData(),
    );
  };

  keyPress(e) {
    if (e.keyCode === 13 && this.state.search) {
      this.handleTextSearch();
    }
  }

  handleChangeSelectedBrand = async (item) => {
    this.setState(
      {
        selectedBrand: item,
        data_status: "fetching",
        page: 1,
      },
      () => this.getData(),
    );
  };

  getReqBody() {
    // const {page = 1, limit= 30, search = '', isForExport= false, sortBy= {name: 'order_date', isReverse: false}} = this.state;
    const {
      page = 1,
      limit = 30,
      isForExport = false,
      sortBy = { name: "order_date", isReverse: false },
      showEndDatePicker = false,
      endDate = moment().add(1, "w").format(DB_DATE_FORMAT),
    } = this.state;
    const search = this.processSearchValue(this.state.search);
    let duration = this.state.duration || this.props.duration || "day";
    let date =
      this.state.date || this.props.date || moment().format(DB_DATE_FORMAT);
    const { filter = "" } = this.props;
    let view = this.state.selectedBrand || {
      id: this.props.view || "",
      name: "All",
      attr: this.props.attr || "",
    };

    const { role = "", brands = [], stores = [] } = this.props;
    const { id = "", attr = "" } = getIdsAndAttr(this.props, view);

    if (this.props.filter && this.props.filter === "Orders Tomorrow") {
      date = moment().add(1, "days").format(DB_DATE_FORMAT);
      duration = "day";
    }

    if (duration === "allTime" && page >= 0) {
      date = moment().format(DB_DATE_FORMAT);
    }

    return {
      role: role,
      brands: role === "store_admin" ? stores : brands,
      id: id || view.id,
      attr: attr || view.attr,
      date,
      dateRange: duration,
      filter,
      limit: Number(limit),
      page: page - 1,
      search,
      sortBy,
      isForExport,
      ...(showEndDatePicker && { endDate }),
    };
  }

  processSearchValue() {
    const { search = "" } = this.state;

    const searchTexts = search.split(" ");

    const newSearch = searchTexts.map((text) => {
      if (this.checkIfEmailInString(text)) {
        const startOfSlice = text.split("").indexOf("@");

        return text.slice(0, startOfSlice);
      }

      if (this.validatePhoneNumber(text, false)) {
        return text[0] === "0" ? text.substring(1, text.length) : text;
      }

      return text;
    });

    return newSearch.join(",", " ");
  }

  checkIfEmailInString(text) {
    var re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    return re.test(text);
  }

  validatePhoneNumber(contact_number = "", append = true) {
    // append = true, will append "+63" on `contact_number`

    if (append) contact_number = "+63" + contact_number;

    var re = /^(09|\+639)\d{9}$/;
    return re.test(String(contact_number));
  }

  async getData() {
    this._isMounted && this.setState({ data_status: "fetching" });
    const reqBody = this.getReqBody();

    // this.api && this.api.get(`/group/${this.props.id}/reports?dateRange=${duration}&date=${date}&filter=${filter}&view=${view.id}&attr=${view.attr}`)
    this.api &&
      this.api
        .post({
          url: "/dashboard/reports",
          data: reqBody,
        })
        .then((data) => {
          const { duration } = this.state;
          this._isMounted &&
            this.setState({
              date:
                duration === "allTime" && !isEmpty(data.data.orders)
                  ? data.data.orders[0].order_date
                  : this.state.date,
              data: data.data,
              data_status: "fetched",
              order_fetch_status: "fetched",
              selectedBrand: this.handleInitialSelectedBrand(data.data.headers),
            });

          this.handleOnDataFetched();

          if (this.use_display_data) {
            this.setDisplayData();
          }
        })
        .catch((error) => {
          this._isMounted &&
            this.setState({ error: error, showSwalError: true });
          this.handleOnDataFetchError();
        });
  }

  handleChangeDuration(duration, date) {
    this.setState(
      {
        duration,
        date,
        data_status: "fetching",
        page: 1,
      },
      () => this.getData(),
    );
  }

  handleDateRangeClick = async (e, date) => {
    let duration;
    const dropdownText = e.currentTarget.textContent || "day";

    if (
      dropdownText === "This Week" ||
      dropdownText === "Last Week" ||
      dropdownText === "Next Week"
    ) {
      duration = "week";
    } else if (dropdownText === "This Month" || dropdownText === "Last Month") {
      duration = "month";
    } else if (dropdownText === "This Year" || dropdownText === "Last Year") {
      duration = "year";
    } else if (dropdownText === "All-time") {
      duration = "allTime";
    } else {
      duration = "day";
    }

    this.setState(
      {
        showEndDatePicker: false,
        isCustomDate: false,
        duration: duration,
        data_status: "fetching",
        date,
        page: 1,
      },
      () => this.getData(),
    );
  };

  handleChangeSort(field) {
    const { sortBy = { name: "order_date", isReverse: false } } = this.state;

    this.setState(
      {
        sortBy: {
          name: field,
          isReverse:
            field === sortBy.name ? !sortBy.isReverse : sortBy.isReverse,
        },
        page: 1,
      },
      () => this.getData(),
    ); // ensure that sortBy is executed before fetiching data
  }

  handleInitialSelectedBrand = (headers = []) => {
    const { selectedBrand = {} } = this.state;
    const { view = "", role = "" } = this.props;

    const defaultSelectedBrand = {
      attr: role === "store_admin" ? "store" : "brand",
      // id: role ==='group_admin'|| role ==='accounting'? '': headers[0].id,
      // name: role ==='group_admin'|| role ==='accounting'? 'All': headers[0].name,
      id: ["group_admin", "accounting", "super_admin"].includes(role)
        ? ""
        : headers[0].id,
      name: ["group_admin", "accounting", "super_admin"].includes(role)
        ? "All"
        : headers[0].name,
    };
    let tempSelectedBrand;

    if (
      (selectedBrand.name && selectedBrand.id) ||
      selectedBrand.id === null //catch if 'All' dropdown option is selected again
    )
      return selectedBrand;

    if (view) {
      for (let brand of headers) {
        if (tempSelectedBrand || brand.id === view) {
          if (tempSelectedBrand && tempSelectedBrand.id === view) break;

          tempSelectedBrand = brand;

          break;
        }

        if (brand.stores && brand.stores.length > 0) {
          for (let store of brand.stores) {
            if (tempSelectedBrand || store.id === view) {
              tempSelectedBrand = { ...store, attr: "store" };

              break;
            }
          }
        }
      }
    }

    return tempSelectedBrand ? tempSelectedBrand : defaultSelectedBrand;
  };

  handleSelectPage = (pageNum = 1, pagesArr = []) => {
    let page = pageNum;

    if (page <= 0) {
      page = 1;
    }

    if (page > pagesArr.length) {
      page = pagesArr.length;
    }

    this.setState({ page }, () => this.getData());
  };

  handleOnDateInputClick = (
    message = "Changing the date with Orders Tomorrow view is not allowed. Please select another view",
  ) => {
    if (this.props.filter !== "Orders Tomorrow") return;

    MySwal.fire({
      icon: "error",
      title: "Oops...",
      text: message,
    });
  };

  handleCustomDateRange = (todayDate, date) => {
    const today = moment(todayDate).format(DB_DATE_FORMAT);
    const daysDiff = moment(today).diff(date, "days");

    if (daysDiff === 1) return "Yesterday";
    else if (daysDiff === 0) return "Today";
    else if (daysDiff === -1) return "Tomorrow";
    else return "Custom";
  };

  handleDropdownText(
    dateRange,
    date = moment().format(DB_DATE_FORMAT),
    isCustomDate,
  ) {
    const isCurrent = moment(moment().format(DB_DATE_FORMAT)).isSame(
      date,
      dateRange === "month" ? "month" : dateRange === "year" ? "year" : "day",
    );
    const prefix = isCurrent ? "This" : "Last";
    let returnLabel = "Today";

    // if(dateRange=== 'week') return `${prefix} Week`
    if (dateRange === "week") {
      if (
        moment(moment().add(1, "weeks").format(DB_DATE_FORMAT)).isSame(date)
      ) {
        returnLabel = "Next Week";
      } else {
        returnLabel = `${prefix} Week`;
      }
    }
    if (dateRange === "month") returnLabel = `${prefix} Month`;
    if (dateRange === "year") returnLabel = `${prefix} Year`;
    if (dateRange === "allTime") returnLabel = "All-time";
    if (dateRange === "between") returnLabel = "Date Range";
    if (dateRange === "day" || (!isCurrent && isCustomDate)) {
      returnLabel = this.handleCustomDateRange(new Date(), date);
    }

    return returnLabel;
  }
}

export default ReportsApi;
