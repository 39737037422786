import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import { HeaderTextWithActions, LoadingDiv } from "../../components/Base";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Card } from "react-bootstrap";
import UserForm from "../../partials/settings/UserForm";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export default function NewUserPage(props) {
  const { id = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  return <MainComponent id={id} user={user} {...props} />;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "User Settings",
      password: "",
    };

    this.api = core("pu").get();
    this.data_url = "/util/page-check?page=settings";

    this.onSubmitNewUser = this.onSubmitNewUser.bind(this);
  }

  handleOnDataFetched = () => {
    this.setState({ title: "New User" });
  };

  onSubmitNewUser = (values, setSubmitting) => {
    this.api
      .post({
        url: "/users",
        data: values,
      })
      .then(({ data = {} }) => {
        const { result = "" } = data;
        if (result === "ok") {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "New user successfully added to your organization. Login instructions has been sent to new user.",
            showConfirmButton: true,
          }).then(() => {
            this.setState({
              redirectTo: `${
                this.props.webview ? "/webview" : ""
              }/settings/users`,
            });
          });
        }

        this.getData();
      })
      .catch(() => {
        this.handleError();
      })
      .finally(() => {
        setSubmitting(false);
        this.setState({ mountModal: false });
        setTimeout(() => {
          this.setState({ mountModal: true });
        });
      });
  };

  render() {
    const { data_status = "" } = this.state,
      isLoading = data_status !== "fetched";
    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        {isLoading ? (
          <LoadingDiv />
        ) : (
          <>
            <HeaderTextWithActions
              title={this.state.title}
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item
                    text={"User Settings"}
                    link={`${
                      this.props.webview ? "/webview" : ""
                    }/settings/users`}
                  />
                  <Breadcrumbs.Item text={this.state.title} active={"true"} />
                </Breadcrumbs>
              }
            />

            <Card>
              <Card.Body>
                <UserForm
                  api={this.api}
                  handleError={this.handleError}
                  onSubmit={this.onSubmitNewUser}
                  submitButton="Save changes"
                  showDeleteButton={false}
                />
              </Card.Body>
            </Card>
          </>
        )}
      </>
    );
  }
}
