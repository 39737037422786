import React, { Component } from "react";
import SignaturePad from "react-signature-canvas";
import { Button } from "react-bootstrap";

export default class SignPad extends Component {
  state = { trimmedDataURL: null };
  sigPad = {};

  clear = (e) => {
    e.preventDefault();
    this.sigPad.clear();
  };

  trim = async (e) => {
    e.preventDefault();
    const data = this.sigPad.getTrimmedCanvas().toDataURL("image/png");
    this.setState({ trimmedDataURL: data });

    // const newBlob= this.dataURItoBlob(data);
    // const file = new File([newBlob], this.props.clientName+'.png', { type: 'image/png' });

    // await this.props.uploadFile(file, (filePath=> {
    //     this.props.inputActions.onChange({target: {value: filePath, name: this.props.name }});
    // }))

    this.props.setSignatureImage && this.props.setSignatureImage(data);
    this.props.inputActions.onChange({
      target: { value: data, name: this.props.name },
    });
  };

  dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  render() {
    let { trimmedDataURL } = this.state;
    return (
      <div>
        <div className="border border-secondary">
          <SignaturePad
            canvasProps={{ className: "w-100" }}
            ref={(ref) => {
              this.sigPad = ref;
            }}
          />
        </div>
        <div className="d-flex justify-content-between mt-2">
          <Button variant={"danger"} onClick={this.clear}>
            Clear
          </Button>
          <Button
            variant={"primary"}
            active={this.sigPad.isEmpty || this.state.trimmedDataURL !== null}
            onClick={this.trim}
          >
            Save
          </Button>
        </div>
        {trimmedDataURL ? (
          <img
            className="d-block img-thumbnail mx-auto mt-4"
            alt="signature"
            src={trimmedDataURL}
          />
        ) : null}
      </div>
    );
  }
}
