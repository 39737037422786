/** Collection of mini components */

import React, { Component, useState } from "react";
import InsertClassnameIfNotExisting, {
  phoneNumberPrefix,
  formatDeliveryStatusTitles,
  toPesoAmount,
  formatPromoType,
  computeTotalCartAmount,
  isDineInWithServiceCharge,
} from "../helpers";
import {
  DEFAULT_FORM_GROUP_CLASSLIST,
  DEFAULT_INPUT_CLASSLIST,
  DB_DATE_FORMAT,
  DB_HOURS_FORMAT,
  ASAP_ORDER_TIME_FORMAT,
  TIME_OPTIONS_INTERVAL_IN_MIN,
  TIME_OPTION_DISPLAY_FORMAT,
} from "../constants";
import { KTUtil } from "../../_metronic/_assets/js/components/util";

import {
  Form,
  Modal,
  Button,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import moment from "moment";
import { PRIMARY_COLOR } from "../constants";
import { withStyles } from "@material-ui/core";
import { Radio, Checkbox } from "@material-ui/core";
import { isEmpty, isUndefined, isNil } from "lodash";
import {
  GetSubmitClassList,
  computeOrderServiceCharge,
  formatAdditionalChargeName,
  formatAdditionalChargeType,
} from "../helpers";
/**
 * This is a function used for returning an icon with tooltip text on hover and click.
 *
 * @param {string} infoText - The string that will be put as text in the tooltip
 * @param {string} iconFAClass - The string for the font awesome className to be used as the icon of the component
 * @param {string} iconColor - The string for the font awesome color style to be used in the icon of the component
 * @return {component} The customized component with icon and tooltip text
 *
 * @example
 *
 *     IconTooltip('this is the tooltip text', 'fas fa-info-circle', 'lightsalmon')
 */
export const IconTooltip = (props) => {
  const { infoText, iconFAClass, iconColor } = props;
  // console.log("IconToolTip props: ", props)
  return (
    <span onClick={(e) => e.stopPropagation()}>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip>{infoText}</Tooltip>}
      >
        {/* <span className="svg-icon svg-icon-primary svg-icon-xl ml-2 mt-1">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Question-circle.svg")} title=" " />
                </span> */}
        <span>
          &nbsp;{" "}
          <i
            style={{ color: `${iconColor}`, cursor: "help" }}
            className={iconFAClass}
          ></i>
        </span>
      </OverlayTrigger>
    </span>
  );
};

/**
 * This is a function used for returning an icon with tooltip text on hover and click.
 *
 * @param {string} infoText - The string that will be put as text in the tooltip
 * @return {component} The customized component with icon and tooltip text
 *
 * @example
 *
 *     InfoTooltip('this is the tooltip text')
 */
export const InfoTooltip = (infoText, variant = "default") => {
  const variantStyle = {
    default: { color: "Dodgerblue", cursor: "help" },
    white: { color: "White", cursor: "help" },
  };

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip>{infoText}</Tooltip>}
      >
        {/* <span className="svg-icon svg-icon-primary svg-icon-xl ml-2 mt-1">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Question-circle.svg")} title=" " />
                </span> */}
        {/* <span>&nbsp; <i style={{ color: "Dodgerblue", cursor: "help" }} className="fas fa-info-circle"></i></span> */}
        <span>
          &nbsp;{" "}
          <i style={variantStyle[variant]} className="fas fa-info-circle"></i>
        </span>
      </OverlayTrigger>
    </span>
  );
};

/**
 * This is a function to display a total computation details in the order modal
 *
 * @param {} order_details - order details,
 * @param {} order - order value s,
 * @param {} order_type - order_type,
 * @return {component} The customized component for order details
 *
 */
export const OrderTotalDetails = ({ order }) => {
  let partialMealPlanPromo = 0;

  const cartTotal = order.order_details
    ? computeTotalCartAmount(order.order_details)
    : 0;
  const includedDeliveryFee =
    order.chargedDeliveryFee && !order.isDeliveryCOD
      ? Number(order.chargedDeliveryFee)
      : 0;
  const promoDiscount = order.promoDiscount ? order.promoDiscount : 0;
  const refundedAmount = order.refunded_amount ? order.refunded_amount : 0;

  if (order.isMealPlan) {
    if (order.meal_plan_type === "3DayMealPlan") {
      if (order.meal_plan_index !== 2) {
        partialMealPlanPromo = Math.floor(promoDiscount / 3);
      } else {
        partialMealPlanPromo =
          promoDiscount - 2 * Math.floor(promoDiscount / 3);
      }
    } else if (order.meal_plan_type === "5DayMealPlan") {
      if (order.meal_plan_index !== 4) {
        partialMealPlanPromo = Math.floor(promoDiscount / 5);
      } else {
        partialMealPlanPromo =
          promoDiscount - 4 * Math.floor(promoDiscount / 5);
      }
    }
  }
  const computed_additional_charge = computeOrderServiceCharge(order);
  const hasOrderServiceCharge = computed_additional_charge > 0;
  return (
    <>
      {order.promo && order.promo.promo_type === "free_item" && (
        <div className="item-text-wrapper d-flex flex-column flex-grow-1 pr-2 pt-2">
          <div className="item-name d-flex">
            <span>
              <i className="fas fa-gift"></i>
            </span>
            <span className="font-weight-bold ml-2">
              {" "}
              {order.promo.free_item_description}
            </span>
            <div></div>
          </div>
        </div>
      )}

      <div className="d-flex flex-wrap align-items-center mv-1rem">
        <DFlex className="flex-column flex-grow-1">
          <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
            total amount
          </span>
        </DFlex>
        <DFlex className="align-items-center">
          <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
            {toPesoAmount(computeTotalCartAmount(order.order_details))}
          </span>
        </DFlex>
      </div>
      {hasOrderServiceCharge && (
        <>
          {order.additional_charges.map((charge) => (
            <div className="d-flex flex-wrap align-items-center mv-1rem">
              <DFlex className="flex-column flex-grow-1">
                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                  {formatAdditionalChargeName(charge.name)}
                  <span className="text-primary ml-1">
                    {formatAdditionalChargeType(charge.type, charge.factor)}
                  </span>
                </span>
              </DFlex>
              <DFlex className="align-items-center">
                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                  {toPesoAmount(charge.amount)}
                </span>
              </DFlex>
            </div>
          ))}

          {promoDiscount === 0 && order.order_type !== "delivery" && (
            <div className="d-flex flex-wrap align-items-center mv-1rem">
              <DFlex className="flex-column flex-grow-1">
                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                  Grand total
                </span>
              </DFlex>
              <DFlex className="align-items-center">
                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                  {toPesoAmount(
                    Number(cartTotal + computed_additional_charge).toFixed(2),
                  )}
                </span>
              </DFlex>
            </div>
          )}
        </>
      )}
      {order.order_type !== "delivery" && order.promoDiscount > 0 && (
        <>
          <div className="d-flex flex-wrap align-items-center mv-1rem">
            <DFlex className="flex-column flex-grow-1">
              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                {`Promo (${formatPromoType(order.promo)})`}
              </span>
            </DFlex>

            <DFlex className="align-items-center text-danger">
              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                {`- ${toPesoAmount(order.promoDiscount)}`}
              </span>
            </DFlex>
          </div>

          <div className="d-flex flex-wrap align-items-center mv-1rem">
            <DFlex className="flex-column flex-grow-1">
              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                grand total
              </span>
            </DFlex>
            <DFlex className="align-items-center">
              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                {toPesoAmount(
                  order.originalTotalPrice -
                    order.promoDiscount +
                    computed_additional_charge,
                )}
              </span>
            </DFlex>
          </div>
        </>
      )}

      {order.order_type === "delivery" && order.delivery.isCOD && (
        <>
          {order.promoDiscount > 0 && (
            <div className="d-flex flex-wrap align-items-center mv-1rem">
              <DFlex className="flex-column flex-grow-1">
                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                  {`Promo (${formatPromoType(order.promo)})`}
                </span>
              </DFlex>
              <DFlex className="align-items-center text-danger">
                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                  {`- ${toPesoAmount(order.promoDiscount)}`}
                </span>
              </DFlex>
            </div>
          )}
          {cartTotal !==
            order.originalTotalPrice +
              computed_additional_charge -
              order.promoDiscount && (
            <div className="d-flex flex-wrap align-items-center mv-1rem">
              <DFlex className="flex-column flex-grow-1">
                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                  grand total
                </span>
              </DFlex>
              <DFlex className="align-items-center">
                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                  {toPesoAmount(
                    order.originalTotalPrice +
                      computed_additional_charge -
                      order.promoDiscount,
                  )}
                </span>
              </DFlex>
            </div>
          )}
        </>
      )}

      {order.order_type === "delivery" && (
        <>
          {order.delivery ? (
            <>
              <div className="d-flex flex-wrap align-items-center mv-1rem">
                <DFlex className="flex-column flex-grow-1">
                  {order.delivery.isCOD ? (
                    <>
                      <Separator solid />
                      <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                        {`⚠️delivery fee ` +
                          `(${
                            !order.delivery.delivery_partner
                              ? ""
                              : order.delivery.delivery_partner
                          }` +
                          `${order.delivery.isCOD ? " CASH" : ""}` +
                          `)`}
                      </span>
                    </>
                  ) : (
                    <>
                      {!order.isMealPlan ? (
                        <>
                          <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                            {`delivery fee ` +
                              `(${
                                !order.delivery.delivery_partner
                                  ? ""
                                  : order.delivery.delivery_partner
                              })`}
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                            Delivery Fee
                          </span>
                        </>
                      )}
                    </>
                  )}
                </DFlex>
                <DFlex className="align-items-center">
                  {order.delivery.isCOD ? (
                    <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                      {toPesoAmount(order.chargedDeliveryFee)}
                    </span>
                  ) : (
                    <>
                      {!order.isMealPlan ? (
                        <>
                          <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                            {toPesoAmount(order.chargedDeliveryFee)}
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                            {Number(order.chargedDeliveryFee)
                              ? toPesoAmount(Number(order.chargedDeliveryFee))
                              : `FREE`}
                          </span>
                        </>
                      )}
                    </>
                  )}
                </DFlex>
              </div>
              {order.delivery.isCOD ? (
                <></>
              ) : (
                <>
                  {!order.isMealPlan ? (
                    <>
                      {order.promoDiscount > 0 && (
                        <>
                          <div className="d-flex flex-wrap align-items-center mv-1rem">
                            <DFlex className="flex-column flex-grow-1">
                              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                {`Promo (${formatPromoType(order.promo)})`}
                              </span>
                            </DFlex>
                            <DFlex className="align-items-center text-danger">
                              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                {`- ${toPesoAmount(order.promoDiscount)}`}
                              </span>
                            </DFlex>
                          </div>
                        </>
                      )}

                      <div className="d-flex flex-wrap align-items-center mv-1rem">
                        <DFlex className="flex-column flex-grow-1">
                          <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                            grand total
                          </span>
                        </DFlex>
                        <DFlex className="align-items-center">
                          <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                            {toPesoAmount(
                              computeTotalCartAmount(order.order_details) +
                                computed_additional_charge +
                                Number(order.chargedDeliveryFee) -
                                order.promoDiscount,
                            )}
                          </span>
                        </DFlex>
                      </div>
                    </>
                  ) : (
                    <>
                      {order.promoDiscount > 0 && (
                        <>
                          <div className="d-flex flex-wrap align-items-center mv-1rem">
                            <DFlex className="flex-column flex-grow-1">
                              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                {`Partial Discount`}
                              </span>
                            </DFlex>
                            <DFlex className="align-items-center text-danger">
                              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                {`- ${toPesoAmount(partialMealPlanPromo)}`}
                              </span>
                            </DFlex>
                          </div>
                        </>
                      )}
                      <div className="d-flex flex-wrap align-items-center mv-1rem">
                        <DFlex className="flex-column flex-grow-1">
                          <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                            grand total
                          </span>
                        </DFlex>
                        <DFlex className="align-items-center">
                          <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                            {toPesoAmount(
                              computeTotalCartAmount(order.order_details) +
                                computed_additional_charge +
                                Number(order.chargedDeliveryFee) -
                                partialMealPlanPromo,
                            )}
                          </span>
                        </DFlex>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export const CountActive = (data) => {
  if (data !== null) {
    if (data.length !== 0) {
      let counts = {};
      const countAll = data.reduce((acc, curr) => {
        // let val = Number(curr.active || curr.activeCOD ? 1 : 0);
        let valActive = Number(curr.active ? 1 : 0);
        let valActiveCOD = Number(curr.activeCOD ? 1 : 0);
        return acc + valActive + valActiveCOD;
      }, 0);
      counts.countAll = countAll;
      const countWallet = data.reduce((acc, curr) => {
        let val = Number(curr.active ? 1 : 0);
        return acc + val;
      }, 0);
      counts.countWallet = countWallet;
      const countCOD = data.reduce((acc, curr) => {
        let val = Number(curr.activeCOD ? 1 : 0);
        return acc + val;
      }, 0);
      counts.countCOD = countCOD;
      // console.log("counts :", counts)
      return counts;

      // const counts =  data.reduce(function(obj, v) {
      //     obj[v.active] = ((obj[v.active] || obj[v.activeCOD]?1:0) || 0) + 1;
      //     // obj[v.active] = (obj[v.active] || 0) + 1;
      //     return obj;
      //     // set the initial value as an object
      // }, {})
      // return counts["true"]
    } else {
      return {};
      // return []
    }
  } else {
    return {};
    // return []
  }
};

const CountStoresWithDelivery = (stores) => {
  if (!stores || stores.length === 0) {
    return 0;
  }
  let count = 0;
  for (const store of stores) {
    if (StoreHasDelivery(store)) {
      count++;
    }
  }
  return count;
};

export const StoreHasDelivery = (store) => {
  return Boolean(
    (store.isDeliveryActive === true || store.isDeliveryActive === undefined) &&
      store.geocode_address_string &&
      store.geocode_location &&
      store.delivery_method &&
      store.delivery_method.length > 0 &&
      store.store_mobile_number &&
      countActiveDelivery(store) > 0,
  );
};

export const countActiveDelivery = (store) => {
  let count = 0;
  for (const sdm of store.delivery_method) {
    if (sdm.active || sdm.activeCOD) {
      count++;
    }
  }
  return count;
};

export const IsDeliveryAvailable = (stores) => {
  return CountStoresWithDelivery(stores) > 0;
};

export const isMealPlanAvailable = (stores) => {
  return countStoresWithMealPlan(stores) > 0;
};

const countStoresWithMealPlan = (stores) => {
  if (!stores || stores.length === 0) {
    return 0;
  }
  let count = 0;
  for (const store of stores) {
    if (storeHasMealPlan(store)) {
      count++;
    }
  }
  return count;
};

export const storeHasMealPlan = (store) => {
  return Boolean(store.isMealPlanActive);
};

export const isCurbsideAvailable = (stores) => {
  return countStoresWithCurbside(stores) > 0;
};

export const isPickupAvailable = (stores) => {
  return countStoresWithPickup(stores) > 0;
};

const countStoresWithPickup = (stores) => {
  if (!stores || stores.length === 0) {
    return 0;
  }
  let count = 0;
  // console.log("countStoresWithCurbside stores: ", stores)
  for (const store of stores) {
    if (storeHasPickupActive(store)) {
      count++;
    }
  }
  return count;
};

const countStoresWithCurbside = (stores) => {
  if (!stores || stores.length === 0) {
    return 0;
  }
  let count = 0;
  // console.log("countStoresWithCurbside stores: ", stores)
  for (const store of stores) {
    if (storeHasCurbside(store)) {
      count++;
    }
  }
  return count;
};

export const storeHasCurbside = (store) => {
  return Boolean(store.isCurbsideActive);
};

export const storeHasPickupActive = (store) => {
  return Boolean(
    store.hasOwnProperty("isPickupActive") ? store.isPickupActive : true,
  );
};

export const DeliveryRadio = withStyles({
  root: {
    color: "gray",
    "&$checked": {
      color: PRIMARY_COLOR,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} checked={props.checked} />);

export const GreenRadio = withStyles({
  root: {
    color: "gray",
    "&$checked": {
      color: PRIMARY_COLOR,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
export const GreenCheckbox = withStyles({
  root: {
    color: "gray",
    "&$checked": {
      color: PRIMARY_COLOR,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export class BasicComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { defaultClass: "" };
  }

  render() {
    const { className = "" } = this.props;
    return (
      <div
        {...this.props}
        className={InsertClassnameIfNotExisting(
          this.state.defaultClass,
          className,
        )}
      >
        {this.props.children}
      </div>
    );
  }
}

export const RootFlex = (props) => (
  <div className="d-flex flex-column flex-root">{props.children}</div>
);

export class DFlex extends Component {
  render() {
    const { className = "" } = this.props;
    return (
      <div
        {...this.props}
        className={InsertClassnameIfNotExisting("d-flex", className)}
      >
        {this.props.children}
      </div>
    );
  }
}

export const ErrorAlert = (props) => (
  <>
    <div
      className="alert alert-custom alert-notice alert-light-danger fade show mb-5  animate__animated animate__fadeIn"
      role="alert"
    >
      <div className="alert-text">{props.message}</div>
    </div>
  </>
);

export const WarningAlert = (props) => (
  <>
    <div
      className="alert alert-custom alert-notice alert-light-warning fade show mb-5  animate__animated animate__fadeIn"
      role="alert"
    >
      <div className="alert-text">{props.message}</div>
    </div>
  </>
);

export const InfoAlert = (props) => (
  <>
    <div
      className="alert alert-custom alert-notice alert-light-info fade show mb-5"
      role="alert"
    >
      <div className="alert-icon">
        <i className="flaticon-info"></i>
      </div>
      <div className="alert-text">{props.message}</div>
    </div>
  </>
);

export const SecondaryHelperAlert = (props) => (
  <>
    <div
      className="alert alert-custom alert-notice alert-secondary fade show mb-5 rounded-0"
      role="alert"
    >
      <div className="alert-icon">
        <span className="svg-icon svg-icon-dark-75 svg-icon-3x">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
        </span>
      </div>
      <div className="alert-text">{props.message}</div>
    </div>
  </>
);

export const SecondaryAlert = (props) => (
  <>
    <div
      className="alert alert-custom alert-notice alert-secondary fade show mb-5"
      role="alert"
    >
      <div className="alert-text">{props.message}</div>
    </div>
  </>
);

export const SuccessAlert = (props) => (
  <>
    <div
      className="alert alert-custom alert-notice alert-light-success fade show mb-5"
      role="alert"
    >
      <div className="alert-icon">
        <i className="flaticon-confetti"></i>
      </div>
      <div className="alert-text">{props.message}</div>
    </div>
  </>
);

export const Label = ({ text = "", style = {} }) => (
  <label className="font-weight-bolder text-dark" style={style}>
    {text}
  </label>
);

export const MutedLabel = ({ text = "", style = {} }) => (
  <label className="text-muted" style={style}>
    {text}
  </label>
);

export const SubLabel = ({ text = "" }) => (
  <label className="font-weight-bolder text-dark-75">{text}</label>
);

export const ModalTitle2 = ({ text = "" }) => (
  <label className="font-weight-bolder text-dark font-size-lg">{text}</label>
);

export class FormItem extends Component {
  componentWillUnmount() {
    this.props?.actions?.handleUnmount?.(this.props.name);
  }

  render() {
    const isTouched = this.props.actions.isTouched(this.props.name);
    const error = this.props.actions.handleFeedbackError(
        this.props.name,
        "error-text",
      ),
      hasError = error !== undefined;

    const {
      solid = null,
      noLabel = null,
      customFormControl = null,
      showRequired = false,
      normal = null,
      isValid = false,
      isInvalid = false,
      useTouchedValidation = true,
      disableEmptyValidation = false,
      inputProps = {},
    } = this.props;

    const placeholder =
      this.props.hasOwnProperty("inputProps") &&
      this.props.inputProps.hasOwnProperty("placeholder")
        ? this.props.inputProps.placeholder
        : this.props.placeholder;

    const { value = "" } = inputProps;

    const formClasslist = isTouched
      ? hasError
        ? [DEFAULT_FORM_GROUP_CLASSLIST, "has-danger"]
        : [DEFAULT_FORM_GROUP_CLASSLIST, "has-success"]
      : [DEFAULT_FORM_GROUP_CLASSLIST];

    let inputClasslist = normal ? [] : [DEFAULT_INPUT_CLASSLIST];

    if (useTouchedValidation) {
      if (isTouched) {
        if (disableEmptyValidation) {
          if (hasError) inputClasslist.push("is-invalid");
          else inputClasslist.push("is-valid");
        } else {
          if (value !== "") {
            if (hasError) inputClasslist.push("is-invalid");
            else inputClasslist.push("is-valid");
          }
        }
      }
    } else {
      // else, they are manually assigned
      if (isValid) inputClasslist.push("is-valid");
      if (isInvalid) inputClasslist.push("is-invalid");
    }

    if (solid) inputClasslist.push("form-control-solid");

    return (
      <>
        <Form.Group
          className={formClasslist.join(" ")}
          style={this.props.style}
        >
          {!noLabel ? (
            <>
              {" "}
              <Label text={this.props.label} style={{ marginBottom: 1 }} />{" "}
              {showRequired ? <RequiredAsterisk /> : <></>}{" "}
            </>
          ) : (
            <></>
          )}
          {!isEmpty(this.props.subLabel) ? (
            <>
              {" "}
              <MutedLabel
                text={this.props.subLabel}
                style={{ fontSize: "0.9rem" }}
              />{" "}
            </>
          ) : (
            <></>
          )}
          <div
            className={`${
              (this.props.append || this.props.prepend) && "input-group"
            }`}
          >
            {this.props.prepend}
            {!customFormControl ? (
              this.props.type && this.props.type === "textarea" ? (
                <Form.Control
                  as={"textarea"}
                  {...this.props.inputProps}
                  {...this.props.inputActions}
                  className={inputClasslist.join(" ")}
                  placeholder={placeholder}
                />
              ) : (
                <Form.Control
                  disabled={this.props.disabled}
                  type={this.props.type ? this.props.type : "text"}
                  {...this.props.inputProps}
                  {...this.props.inputActions}
                  className={inputClasslist.join(" ")}
                  placeholder={placeholder}
                  {...(this.props.type === "date" && { max: this.props.max })}
                />
              )
            ) : (
              <>{this.props.custom}</>
            )}
            {this.props.append}
          </div>
          <div className="fv-plugins-message-container">
            {<div className="fv-helper-text">{this.props.helperText}</div>}
            {error !== "" ? (
              <div className="fv-help-block">{error}</div>
            ) : (
              <></>
            )}
          </div>
        </Form.Group>
      </>
    );
  }
}

export const LoadingDiv = ({ text = "Loading..." }) => {
  return <div className="kt-align-center">{text}</div>;
};

export const DNone = (props) => {
  return (
    <div {...props} className="d-none">
      {props.children}
    </div>
  );
};

export const Lipsum = () => (
  <>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi posuere nisi
    eget neque cursus cursus.
  </>
);

export const AnimateDiv = (props) => (
  <div className="animate__animated animate__fadeIn">{props.children}</div>
);

export const HeaderText = ({
  style = {},
  title = "",
  subtitle = "",
  className = "",
}) => (
  <div style={style} className={`text-left mv-1rem ${className}`}>
    <p className="display-4 font-weight-bolder"> {title} </p>
    <div className="font-weight-bolder"> {subtitle} </div>
  </div>
);

export const HeaderTextWithActions = ({
  className = "",
  title = "",
  subtitle = "",
  breadcrumbs = <></>,
  actions = <></>,
}) => (
  <div className="d-flex flex-column flex-wrap align-items-stretch w-100">
    <div className={className}>
      {breadcrumbs}
      <div className="display-4 font-weight-bolder mv-1-rem w-100 align-self-stretch">
        {" "}
        {title}{" "}
      </div>
      <div className="font-weight-bolder"> {subtitle} </div>
    </div>
    <div className="d-flex align-items-center">{actions}</div>
  </div>
);

export const HeaderTextWithActions2 = ({
  title = "",
  subtitle = "",
  breadcrumbs = <></>,
  actions = <></>,
}) => (
  <>
    <div> {breadcrumbs} </div>
    {/* <div className="display-4 font-weight-bolder mv-1-rem d-flex flex-wrap align-items-center">
        <div className="d-flex">{title}</div>
        <div className="d-flex">{actions}</div>
    </div> */}
    <div
      className="d-flex flex-wrap align-items-center"
      style={{ marginBottom: "1rem" }}
    >
      <div className="d-flex flex-column flex-grow-1">
        <div className="font-pickup-lg font-weight-bolder">{title}</div>
      </div>
      <div className="d-flex align-items-center">{actions}</div>
    </div>
  </>
);

export const RequiredAsterisk = () => <span className="text-danger">*</span>;

export class InfoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  render() {
    const { title = <></>, body = <></> } = this.props;

    return (
      <>
        <Modal
          size="sm"
          centered
          show={this.state.show}
          onHide={() => {
            this.setState({ show: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{body}</Modal.Body>
        </Modal>

        <span
          className="text-hover-primary cursor-pointer"
          onClick={() => {
            this.setState({ show: true });
          }}
        >
          <i className="flaticon-info" />
        </span>
      </>
    );
  }
}

export const InfoAlertHelper = ({ text = "" }) => (
  <div
    className="alert alert-custom alert-white alert-shadow fade show gutter-b"
    role="alert"
  >
    <div className="alert-icon">
      <span className="svg-icon svg-icon-primary svg-icon-xl">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
      </span>
    </div>
    <div className="alert-text">{text}</div>
  </div>
);

export class KTLink extends Component {
  render() {
    const {
      external = null,
      text = "",
      link = "",
      onClick,
      style,
    } = this.props;

    return external ? (
      <a
        href={link}
        rel="noopener noreferrer"
        target="_blank"
        className="kt-link"
        onClick={onClick}
        style={style}
      >
        {text}
      </a>
    ) : (
      <button
        type="button"
        className="btn btn-link px-0"
        onClick={onClick}
        style={style}
      >
        {text}
      </button>
    );
  }
}

export const IconAlert = ({
  text = "",
  variant = "primary",
  icon = "Code/Info-circle",
  className = "alert alert-custom alert-white alert-shadow fade show gutter-b",
}) => (
  <div className={className} role="alert">
    <div className="alert-icon">
      <span className={`svg-icon svg-icon-${variant} svg-icon-xl`}>
        <SVG
          src={toAbsoluteUrl(`/media/svg/icons/${icon}.svg`)}
          title="Private"
        />
      </span>
    </div>
    <div className="alert-text">{text}</div>
  </div>
);

export const IconAlertAction = ({
  text = "",
  variant = "primary",
  icon = "Code/Info-circle",
  className = "alert alert-custom alert-white alert-shadow fade show gutter-b",
  actionText = "action",
  actionOnClick = () => {},
}) => (
  <div className={className} role="alert">
    <div className="alert-icon">
      <span className={`svg-icon svg-icon-${variant} svg-icon-xl`}>
        <SVG
          src={toAbsoluteUrl(`/media/svg/icons/${icon}.svg`)}
          title="Private"
        />
      </span>
    </div>
    <div className="alert-text">{text}</div>
    <div class="alert-close">
      <button
        type="button"
        class="btn btn-primary text-uppercase"
        onClick={actionOnClick}
      >
        {actionText}
      </button>
    </div>
  </div>
);

export class SVGIcon extends Component {
  render() {
    const {
      icon = "",
      variant = "primary",
      className = "",
      title = "",
      fill = "",
    } = this.props;

    return icon !== "" ? (
      <span
        className={InsertClassnameIfNotExisting(
          `svg-icon svg-icon-${variant}`,
          className,
        )}
      >
        <SVG
          src={toAbsoluteUrl(`/media/svg/icons/${icon}.svg`)}
          title={title}
        />
      </span>
    ) : (
      <></>
    );
  }
}

export class SVGIconCustom extends Component {
  render() {
    const {
      icon = "",
      variant = "primary",
      className = "",
      title = "",
    } = this.props;

    return icon !== "" ? (
      <span
        className={InsertClassnameIfNotExisting(
          `svg-icon svg-icon-${variant}`,
          className,
        )}
      >
        <SVG
          src={toAbsoluteUrl(`/media/svg/icons/${icon}.svg`)}
          title={title}
        />
      </span>
    ) : (
      <></>
    );
  }
}

export const MagicDiv = ({ show = false, children, className }) => {
  let classList = ["magic-div"];
  if (show) classList.push("magic-div--show");
  if (className) classList.push(className);

  return (
    <>
      <div className={classList.join(" ")}>{children}</div>
    </>
  );
};

export class KTCallout extends React.Component {
  render() {
    const {
      title = "",
      text = "",
      onClick = () => {},
      buttonText = "",
      variant = "primary",
    } = this.props;
    return (
      <div
        className={`card card-custom gutter-b bg-diagonal bg-diagonal-light-${variant}`}
      >
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between p-4">
            <div className="d-flex flex-column mr-5">
              <div className="h4 text-dark text-hover-primary mb-5">
                {title}
              </div>
              <p className="text-dark-50">{text}</p>
            </div>
            <div className="ml-6 flex-shrink-0">
              <Button
                className={`btn font-weight-bolder text-uppercase btn-${variant} py-4 px-6`}
                onClick={onClick}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class KTPlainCallout extends React.Component {
  render() {
    const {
      title = "",
      text = "",
      onClick = () => {},
      buttonText = "Submit a request",
      variant = "primary",
    } = this.props;

    return (
      <div className="card card-custom mb-2">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between p-4">
            <div className="d-flex flex-column mr-5">
              <div className="h4 text-dark text-hover-primary mb-5">
                {title}
              </div>
              <p className="text-dark-50">{text}</p>
            </div>
            <div className="ml-6 flex-shrink-0">
              <Button
                className={`btn font-weight-bolder text-uppercase btn-${variant} py-4 px-6`}
                onClick={onClick}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class Block extends React.Component {
  render() {
    const { title = <></>, content = <></>, style = null } = this.props;

    return (
      <div className="mv-1rem" style={style}>
        <Row>
          <Col>
            <div className="text-uppercase font-weight-bolder font-pickup-sm-2">
              {title}
            </div>
            <div>{content}</div>
          </Col>
        </Row>
      </div>
    );
  }
}

/**
 *
 * @param {Boolean} props.solid Optional
 * @param {Boolean} props.border Optional
 * @param {Boolean} props.dashed Optional
 * @param {Object} props.style Optional
 * @returns
 */
export function Separator(props) {
  const { solid = null, border = null, dashed = null, style = {} } = props;

  let classList = ["separator"];

  if (solid) classList.push("separator-solid");
  if (border) classList.push("separator-border-" + border);
  if (dashed) classList.push("separator-dashed");

  return <div className={classList.join(" ")} style={style} />;
}

export class ContactUs extends React.Component {
  render() {
    return <>If you think that this is a mistake, please send us an email.</>;
  }
}

export class ListItem extends React.Component {
  render() {
    const {
      icon = "",
      iconVariant = "primary",
      text = "",
      mutedText = "",
      hideIcon = false,
      reverse = false,
    } = this.props;
    return (
      <div className="d-flex align-items-center justify-content-between mb-10">
        <div className="d-flex align-items-center mr-2">
          {hideIcon ? (
            <></>
          ) : (
            <div className="symbol symbol-40 mr-3 flex-shrink-0">
              <div className="symbol-label">
                <span>
                  <i className={`${icon} text-${iconVariant}`}></i>
                </span>
              </div>
            </div>
          )}
          <div>
            {reverse ? (
              <>
                <div className="font-size-sm text-muted font-weight-bold mt-1">
                  <span className="text-uppercase">{mutedText}</span>
                </div>
                <div className="text-dark-75 font-weight-bolder">{text}</div>
              </>
            ) : (
              <>
                <div className="text-dark-75 font-weight-bolder">{text}</div>
                <div className="font-size-sm text-muted font-weight-bold mt-1">
                  <span className="text-uppercase">{mutedText}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

/**
 *
 * @param {String} props.order_time Required
 * @param {String} props.order_date Optional / Set `noDate` to true.
 * @param {Boolean} props.trackPage Optional / Default: false
 * @param {Object} props.store Optional / Needed for `time_options_interval`
 * @param {Number} props.store.time_options_interval Optional / Needed for `time_options_interval`
 * @param {String} props.display_date_format Optional / Default: "MMMM D, YYYY"
 * @param {String} props.display_time_format Optional / Default: "h:mm A"
 * @param {Boolean} props.noRange Optional / Default: false
 * @param {Boolean} props.noDate Optional / Default: false
 * @returns
 */
export function OrderTimeRender(props) {
  const {
      order_time = "",
      order_date = "",
      trackPage = false,
      store = {},
      display_date_format = "MMMM D, YYYY",
      display_time_format = "h:mm A",
      noRange = false,
      noDate = false,
    } = props,
    { time_options_interval = TIME_OPTIONS_INTERVAL_IN_MIN } = store;
  return order_time === ASAP_ORDER_TIME_FORMAT ? (
    !trackPage ? (
      <>
        {" "}
        {moment(order_date, DB_DATE_FORMAT).format(display_date_format)}{" "}
        {" - ASAP "}{" "}
      </>
    ) : (
      <> ASAP </>
    )
  ) : !trackPage ? (
    <>
      {!noDate &&
        moment(order_date, DB_DATE_FORMAT).format(display_date_format)}{" "}
      {moment(order_time, DB_HOURS_FORMAT).format(display_time_format)}
      {noRange ? (
        <></>
      ) : (
        <>
          {" "}
          to{" "}
          {moment(order_time, DB_HOURS_FORMAT)
            .add(time_options_interval, "minutes")
            .format(display_time_format)}
        </>
      )}
    </>
  ) : (
    <>
      {!noDate &&
        moment(order_date, DB_DATE_FORMAT).format(display_date_format)}{" "}
      <br />{" "}
      <span style={{ fontSize: "14px", fontWeight: 500 }}>
        {moment(order_time, DB_HOURS_FORMAT).format(display_time_format)}{" "}
        {noRange ? (
          <></>
        ) : (
          <>
            to{" "}
            {moment(order_time, DB_HOURS_FORMAT)
              .add(time_options_interval, "minutes")
              .format(display_time_format)}
          </>
        )}{" "}
      </span>
    </>
  );
}

export class OrderStatusActionButton extends React.Component {
  render() {
    const {
      status = "",
      order_type = "",
      onClick,
      _id = "",
      btnBlock = false,
      btnLoading = false,
    } = this.props;
    return status === "new" ? (
      <>
        <Button
          variant="outline-danger"
          className={`font-weight-bolder text-uppercase ${
            btnBlock ? "btn-block" : ""
          } mr-2`}
          onClick={onClick}
          data-value="reject"
          data-id={_id}
          disabled={btnLoading}
        >
          reject
        </Button>
        <Button
          variant="purple"
          className={`font-weight-bolder text-uppercase mr-2 ${
            btnBlock ? "btn-block" : ""
          }`}
          onClick={onClick}
          data-value="accept"
          data-id={_id}
          disabled={btnLoading}
        >
          accept
        </Button>
      </>
    ) : status === "accepted" ? (
      <Button
        variant="primary"
        className={`font-weight-bolder text-uppercase ${
          btnBlock ? "btn-block" : ""
        }`}
        onClick={onClick}
        data-value={"ready"}
        data-id={_id}
        disabled={btnLoading}
      >
        order ready
      </Button>
    ) : status === "ready" && order_type === "delivery" ? (
      <Button
        variant="primary"
        className={`font-weight-bolder text-uppercase ${
          btnBlock ? "btn-block" : ""
        }`}
        onClick={onClick}
        data-value={status + "_" + order_type}
        data-id={_id}
        disabled={btnLoading}
      >
        dispatched
      </Button>
    ) : (
      <>
        {order_type !== "dine_in" ? (
          <>
            <Button
              variant="outline-danger"
              className={`font-weight-bolder text-uppercase ${
                btnBlock ? "btn-block" : ""
              } mr-2`}
              onClick={onClick}
              data-value={status + "_" + order_type + "_no_show"}
              data-id={_id}
              disabled={btnLoading}
            >
              mark as no show
            </Button>
          </>
        ) : (
          <></>
        )}
        <Button
          variant="primary"
          className={`font-weight-bolder text-uppercase ${
            btnBlock ? "btn-block" : ""
          } btn-purple`}
          onClick={onClick}
          data-value={status + "_" + order_type}
          data-id={_id}
          disabled={btnLoading}
        >
          dispatched
        </Button>
      </>
    );
  }
}

export class OrderStatusActionButton2 extends React.Component {
  render() {
    const {
      status = "",
      order_type = "",
      onClick,
      _id = "",
      btnBlock = false,
      delivery = {},
      btnLoading = false,
    } = this.props;
    return status === "new" ? (
      <>
        <Button
          variant="purple"
          className={`font-weight-bolder text-uppercase ${
            btnBlock ? "btn-block" : ""
          }`}
          onClick={onClick}
          data-value="accept"
          data-id={_id}
          disabled={btnLoading}
        >
          accept
        </Button>
        {/* <Button variant="danger" className={`font-weight-bolder text-uppercase ${btnBlock ? "btn-block" : ""}`}  onClick={onClick} data-value="reject" data-id={_id}>reject</Button> */}
      </>
    ) : status === "accepted" ? (
      <Button
        variant="primary"
        className={`font-weight-bolder text-uppercase ${
          btnBlock ? "btn-block" : ""
        }`}
        onClick={onClick}
        data-value={"ready"}
        data-id={_id}
        disabled={btnLoading || delivery["lastStatus"] === "REFRESHING"}
      >
        order ready
      </Button>
    ) : status === "ready" && order_type === "delivery" ? (
      <Button
        variant="primary"
        className={`font-weight-bolder text-uppercase ${
          btnBlock ? "btn-block" : ""
        }`}
        onClick={onClick}
        data-value={status + "_" + order_type}
        data-id={_id}
        disabled={btnLoading}
      >
        dispatched
      </Button>
    ) : (
      <Button
        variant="primary"
        className={`font-weight-bolder text-uppercase ${
          btnBlock ? "btn-block" : ""
        }`}
        onClick={onClick}
        data-value={status + "_" + order_type}
        data-id={_id}
      >
        dispatched
      </Button>
    );
  }
}

export class Image250 extends React.Component {
  render() {
    const { src = "", alt = "image" } = this.props;
    return <img src={src} alt={alt} width={"250px"} />;
  }
}

export class ProgressSM extends React.Component {
  render() {
    const {
      status = "pending",
      height = "4",
      borderRadius = "4",
      variant = "primary",
    } = this.props;

    return (
      <div
        className={`${
          status === "pending" ? `bg-${variant}-o-50` : `bg-${variant}`
        }`}
        style={{
          height: `${height}px`,
          borderRadius: `${borderRadius}px`,
          width: KTUtil.isMobileDevice() ? "55px" : "6rem",
        }}
      />
    );
  }
}

export class PreviewPhoto extends Component {
  render() {
    const {
      alt = "photo",
      className = "width-250",
      showImage = true,
    } = this.props;

    return (
      <>
        {showImage && (
          <div>
            {" "}
            <img src={this.props.src} className={className} alt={alt} />{" "}
          </div>
        )}
        <button
          type="button"
          className="btn btn-link px-0"
          onClick={this.props.onClickRemove}
        >
          Change Photo
        </button>
      </>
    );
  }
}

export class DisplayLargeAndUp extends React.Component {
  render() {
    return <div className="display-large-and-up">{this.props.children}</div>;
  }
}

export class DisplayMediumAndDown extends React.Component {
  render() {
    return <div className="display-medium-and-down">{this.props.children}</div>;
  }
}

export class OrderText extends React.Component {
  render() {
    const {
      label = "",
      value = "",
      hoverPrimary = false,
      underline = false,
    } = this.props;
    return (
      <div
        className={`font-pickup-sm-2 font-weight-bolder va-middle ${
          hoverPrimary ? "order-text-hover-primary" : ""
        } ${underline ? "underline" : ""}`}
      >
        <span className="text-label">{label}</span>{" "}
        <span className="text-value">{value}</span>
      </div>
    );
  }
}

export const ThirdPartyName = ({
  orderType,
  pickupDetails,
  withPhone = false,
}) => {
  let thirdPartyInfo = {};
  if (pickupDetails) {
    try {
      thirdPartyInfo = JSON.parse(pickupDetails);
    } catch (error) {
      console.log(error);
    }
  }

  // phoneNumber = thirdPartyInfo.contact_number
  let displayName = null;
  if (
    orderType === "third_party_pickup" &&
    Object.keys(thirdPartyInfo).length > 0
  ) {
    displayName = (
      <div className="font-pickup-sm-2 font-weight-bolder">
        <span className="text-label">c/o: </span>
        <span className="text-purple">
          {thirdPartyInfo.first_name} {thirdPartyInfo.last_name}
        </span>
        {withPhone ? (
          <>
            {" - "}
            <span className="text-purple">
              {phoneNumberPrefix(thirdPartyInfo.contact_number)}
            </span>
          </>
        ) : null}
      </div>
    );
  }
  return displayName;
};

export const DeliveryPartner = ({ order, execDeliveryRefresh }) => {
  // console.log("deliveryPartner order: ", order)
  if (order.delivery) {
    return !order.multipartner_hailing
      ? `DELIVERY: ${order.delivery.delivery_partner}` +
          `${order.delivery.isCOD ? " CASH" : ""}`
      : // : order.multipartner_hailing===true?
        `DELIVERY: MULTI-PARTNER`;
    // :
    //     ``
  } else {
    return "DELIVERY";
  }
};

export const DeliveryInfo = ({ order, execDeliveryRefresh }) => {
  const { delivery, _id: order_id, status: order_status } = order;
  if (delivery) {
    const { lastStatus, delivery_partner, lastUpdateTime } = delivery;
    const status = formatDeliveryStatusTitles(
      delivery_partner,
      lastStatus,
      "merchant",
    );
    const DeliveryDetails = ({ status, order_status }) => {
      // const [deliveryStatus, setDeliveryStatus] = useState(lastStatus !== undefined? status:"For Booking");
      const [deliveryStatus, setDeliveryStatus] = useState(
        lastStatus ? status : "For Booking",
      );
      const [updatedTime, setUpdatedTime] = useState(
        lastStatus !== undefined ? moment(lastUpdateTime).fromNow() : undefined,
      );
      return (
        <>
          {lastStatus !== undefined ? (
            <div className="font-pickup-sm-2 font-weight-bolder">
              <span className="text-label">Delivery Status: </span>
              <span className="text-purple">{deliveryStatus}</span>
            </div>
          ) : (
            order_status === "ready" && (
              <div className="font-pickup-sm-2 font-weight-bolder">
                <span className="text-label">Delivery Status: </span>
                <span className="text-purple">{deliveryStatus}</span>
              </div>
            )
          )}
          {updatedTime !== undefined ? (
            <div className="font-pickup-sm-1 font-weight-bolder">
              <span className="text-label">Last update: </span>
              <span className="text-label">{updatedTime}</span>
            </div>
          ) : (
            <></>
          )}
        </>
      );
    };
    return <DeliveryDetails status={status} order_status={order_status} />;
  } else {
    return <></>;
  }
};

export const DisplayPhone = ({ orderType = "", pickupDetails, mainPhone }) => {
  let thirdPartyInfo = {};
  if (pickupDetails) {
    try {
      thirdPartyInfo = JSON.parse(pickupDetails);
    } catch (error) {
      console.log(error);
    }
  }

  let phoneNumber = mainPhone;
  if (
    orderType === "third_party_pickup" &&
    Object.keys(thirdPartyInfo).length > 0
  ) {
    phoneNumber = thirdPartyInfo.contact_number;
  }
  return (
    <span
      className={`${orderType === "third_party_pickup" ? "text-purple" : ""}`}
    >
      {phoneNumberPrefix(phoneNumber)}
    </span>
  );
};

export class TextMuted extends React.Component {
  render() {
    return <span className="text-muted">{this.props.children}</span>;
  }
}

export const Section = ({ header = <></>, body = <></> }) => {
  return (
    <>
      <div className="order-section">
        <div className="order-section--header">{header}</div>
        <div className="order-section--body">{body}</div>
      </div>
    </>
  );
};

export const DisplayTime = ({ order_time = "" }) => {
  return (
    <>
      {order_time === "asap_order_time"
        ? "ASAP"
        : moment(order_time, DB_HOURS_FORMAT).format(
            TIME_OPTION_DISPLAY_FORMAT,
          )}
    </>
  );
};

export const EditIcon = ({ onClick }) => (
  <button
    type="button"
    className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
    onClick={onClick}
  >
    <i className="far fa-edit text-primary" />
  </button>
);

export const PaymongoInput = ({
  className = "",
  header = "",
  description = "",
  inputProps = {},
  actions = {},
  inputActions = {},
}) => (
  <div className={`pm-input-container ${className}`}>
    {header && <h5 className="mt-8">{header}</h5>}
    {description && (
      <div className="text-muted font-size-md mb-4">{description}</div>
    )}
    <FormItem
      type={"inputOption.type"}
      name={"inputOption.name"}
      inputProps={inputProps}
      inputActions={inputActions}
      actions={actions}
      noLabel
      normal
      showRequired
    />
  </div>
);

export const DisplayRequiredFieldError = ({ errorsArray = [], item = "" }) => {
  const errorFound = errorsArray.filter(
    (errorItem) => errorItem.errorField === item,
  );
  if (!errorFound || !errorFound.length) return <></>;
  return errorFound && errorFound.length ? (
    <div style={{ marginTop: "-1.50rem", marginBottom: "1.75rem" }}>
      <span className="text-danger">
        {errorFound[0].errorMessage
          ? errorFound[0].errorMessage
          : `This field is required.`}
      </span>
    </div>
  ) : (
    <></>
  );
};

export const DeliveryButtons = ({ order, actions }) => {
  const [refreshLoading, setRefreshLoading] = React.useState(false);
  const isDeliveryButtonsAvailable = (order) => {
    const isAccepted = ["ready", "accepted", "completed"].includes(
      order.status,
    );

    const withDeliveryStatus = order.delivery.lastStatus !== undefined;
    let displayButtons = false;
    if (withDeliveryStatus || isAccepted) {
      displayButtons = true;
    }
    return displayButtons;
  };

  return (
    isDeliveryButtonsAvailable(order) && (
      <>
        {order.status !== "new" && (
          <Button
            disabled={
              !(
                isUndefined(order.delivery.lastStatus) ||
                [
                  "canceled",
                  "failed",
                  "expired",
                  "rejected",
                  "returned",
                ].includes(order.delivery.lastStatus.toLowerCase())
              )
            }
            className={`font-weight-bolder text-uppercase ${
              KTUtil.isMobileDevice() ? "mb-3" : "mr-5"
            }`}
            style={{
              background: "#f4511d",
              borderColor: "#f4511d",
            }}
            onClick={async (event) => {
              event.stopPropagation();
              await actions.execHailRider(order._id, order.delivery.lastStatus);
            }}
          >
            <span>
              <i className="flaticon2-delivery-truck"></i> Hail Rider
            </span>
          </Button>
        )}

        <Button
          disabled={order.delivery.lastStatus === "REFRESHING"}
          className={GetSubmitClassList(
            refreshLoading,
            `font-weight-bolder text-uppercase ${
              KTUtil.isMobileDevice() ? "mb-3" : "mr-5"
            }`,
          )}
          style={{
            background: "#2FA0ED",
            borderColor: "#2FA0ED",
          }}
          xs={6}
          md={4}
          onClick={async (event) => {
            event.stopPropagation();
            setRefreshLoading(true);
            await actions.execDeliveryRefresh(
              order._id,
              order.delivery.lastStatus,
            );
            setRefreshLoading(false);
          }}
        >
          <span>
            <i className="flaticon2-refresh-1"></i> Refresh
          </span>
        </Button>
      </>
    )
  );
};
