function methods(axiosInstance) {
  return {
    get(url = "") {
      return axiosInstance({
        method: "get",
        url: url,
      });
    },

    post({ url = "", data = {} }) {
      return axiosInstance({
        method: "post",
        url: url,
        data: data,
      });
    },

    put({ url = "", data = {} }) {
      return axiosInstance({
        method: "put",
        url: url,
        data: data,
      });
    },

    getBaseURL() {
      return axiosInstance.defaults.baseURL;
    },
  };
}

export default methods;
