import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import styled from 'styled-components';

export function HeaderMobile(props) {
  let { aside = false, logo = "" , type = "admin", cart_counter = 0, showMenuItems = true, website_theme = {} } = props, { icon_color = "" } = website_theme;

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay:
          objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile")
    };
  }, [uiService]);

  const SHOW_HEADER_MENU_ICON = false, SHOW_ASIDE_NAV_ICON = aside;

  if(logo === "") {
    logo = layoutProps.headerLogo;
  }

  let className = `header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`;

  if(type === "branded-website") className += " bw";

  const ColoredIcon = styled.span`
      svg g [fill]{
          fill: ${icon_color} !important;
      }
  `

  return (
      <>
        {/*begin::Header Mobile*/}
        <div
            id="kt_header_mobile"
            className={className}
            {...layoutProps.headerMobileAttributes}
        >
          {/*begin::Logo*/}
          {
            (type === "admin") ?
            <Link to="/">
              <img alt="logo" src={logo}/>
            </Link> : <></>
          }

          {
            (type === "branded-website" && showMenuItems) ?
            <button className="btn p-0 burger-icon" id="kt_aside_mobile_toggle">
                <span/>
            </button> : <div></div>
          }
         
          {/*end::Logo*/}

          {
            (type === "branded-website") ?
            <Link to="/">
              <img alt="logo" src={logo} style={{height: "30px", width: "inherit", maxWidth: "200px"}}/>
            </Link> : <></>
          }

          {/*begin::Toolbar*/}
          <div className="d-flex align-items-center">
            {SHOW_ASIDE_NAV_ICON && (
                <>
                  {/*begin::Aside Mobile Toggle*/}
                  <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                    <span/>
                  </button>
                  {/*end::Aside Mobile Toggle*/}
                </>
            )}

            {SHOW_HEADER_MENU_ICON && (
                <>
                  {/*begin::Header Menu Mobile Toggle*/}
                  <button className="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">
                    <span/>
                  </button>
                  {/*end::Header Menu Mobile Toggle*/}
                </>
            )}

            {
                (type === "admin") ? <>
                {/*begin::Topbar Mobile Toggle*/}
                <button className="btn p-0 burger-icon" id="kt_aside_mobile_toggle">
                    <span/>
                </button>
                <button
                    className="btn btn-hover-text-primary p-0 ml-2"
                    id="kt_header_mobile_topbar_toggle"
                >
                    <span className="svg-icon svg-icon-xl">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
                    </span>
                </button>
                {/*end::Topbar Mobile Toggle*/}
                </> : <></>
            }

            {
                (type === "branded-website" && showMenuItems) ? <>
                    <div className="bw-cart-icon">
                        <button className="btn p-0 ml-2" onClick={props.onClickCart}>
                            <ColoredIcon className="svg-icon svg-icon-xl">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart3.svg")} title="Cart"/>
                            </ColoredIcon>
                        </button>
                        { cart_counter > 0 ? <span className="label label-sm label-danger">{cart_counter}</span> : <></> }
                   </div>
                </> : <></>
            }
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header Mobile*/}
      </>
  );
}
