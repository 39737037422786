import React from "react";
import AboutPageContentForm from "./forms/AboutPageContentForm";

export default class AboutContentFormDiv extends React.Component {
  render() {
    const { data = {}, field = "" } = this.props;

    return (
      <>
        <AboutPageContentForm
          field={field}
          values={data}
          onSubmit={this.props.onSubmitAboutPageContentForm}
        />
      </>
    );
  }
}
