import React from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { PRIMARY_COLOR } from "../constants";
import core from "../vendors/core-api/";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  GetSubmitClassList,
  checkStoreDeliveryWallets,
  fetchData,
} from "../helpers";
import {
  InfoAlertHelper,
  FormItem,
  Block,
  InfoTooltip,
  CountActive,
  Label,
} from "../components/Base";
import ProSourceForm from "../components/ProSourceForm";
// import ThemeColorsForm from "./forms/ThemeColorsForm";
import moment from "moment";

const MySwal = withReactContent(Swal);

function getTimeOptions() {
  let current = moment(new Date()).startOf("day"),
    end = moment(new Date()).endOf("day"),
    options = [];
  options.push("");
  while (current.isSameOrBefore(end)) {
    options.push({
      text: current.format("hh:mm A"),
      value: current.format("HH:mm"),
    });
    current = current.add("30", "minutes");
  }

  return options;
}

function RenderTimeOptions({ time_options = [], start = "", end = "" }) {
  let add = start === "";
  return time_options.map(({ text = "", value = "" }, index) => {
    if (add && end !== "") add = value !== end;

    if (!add && start !== "") add = value === start;

    return add ? (
      <option value={value} key={index}>
        {text}
      </option>
    ) : (
      <></>
    );
  });
}
export default class MealPlanSettingsDiv extends ProSourceForm {
  constructor(props) {
    super(props);
    this.api = core("pu").get();

    let { mealPlanLabels = "" } = this.props.store;

    mealPlanLabels =
      mealPlanLabels !== ""
        ? JSON.parse(mealPlanLabels)
        : {
            "3DayMealPlanTop": "",
            "5DayMealPlanTop": "",
            "3DayMealPlanBtm": "",
            "5DayMealPlanBtm": "",
          };

    this.state = {
      isActive: this.props.store.isMealPlanActive ? true : false,
      meal_plan_order_time: this.props.store.meal_plan_order_time,
      store: this.props.store ? this.props.store : "",
      storeHasActiveDeliveryWallet: true,
      values: {
        free_delivery_amount: this.props.store.free_delivery_amount
          ? this.props.store.free_delivery_amount
          : 0,
        mealPlanDiscount: this.props.store.mealPlanDiscount
          ? this.props.store.mealPlanDiscount
          : 0,
        ...mealPlanLabels,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };
  }

  handleSelectMealPlanOrderTime = (e) => {
    // console.log("handleClickMealPlanOrderTime: ", e.target.value)
    const { store } = this.state;
    this.setState({ meal_plan_order_time: e.target.value });
    store.meal_plan_order_time = e.target.value;
    this.setState({ store });
  };

  handleToggleMealPlanStatus = (isChecked) => {
    const { store } = this.state;
    isChecked = !isChecked;
    this.setState({ isActive: isChecked });
    store.isMealPlanActive = isChecked;
    this.setState({ store });

    let values = {};
    values["store_id"] = store._id;
    values["isActive"] = store.isMealPlanActive;
    this.api
      .put({
        url: "/store/allow-meal-plan",
        data: values,
      })
      .then(({ data }) => {
        if (data.result === "ok") {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            // "confirmButtonText": "Ok",
            // showConfirmButton: false,
            timer: 1000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleSaveChanges = (e) => {
    e.preventDefault();
    let values = {};
    const { isActive, store, meal_plan_order_time } = this.state;
    const { free_delivery_amount, mealPlanDiscount } = this.state.values;
    values["store_id"] = store._id;
    // values["isActive"] = store.isMealPlanActive
    values["meal_plan_order_time"] = store.meal_plan_order_time;
    values["free_delivery_amount"] = Number(free_delivery_amount);
    values["mealPlanDiscount"] = Number(mealPlanDiscount);
    values["mealPlanLabels"] = JSON.stringify({
      "3DayMealPlanTop": this.state.values["3DayMealPlanTop"],
      "5DayMealPlanTop": this.state.values["5DayMealPlanTop"],
      "3DayMealPlanBtm": this.state.values["3DayMealPlanBtm"],
      "5DayMealPlanBtm": this.state.values["5DayMealPlanBtm"],
    });

    this.api
      .put({
        url: "/store/save-meal-plan-details",
        data: values,
      })
      .then(({ data }) => {
        if (data.result === "ok") {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            // "confirmButtonText": "Ok",
            // showConfirmButton: false,
            timer: 1000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  isSaveChangesDisabled = () => {
    let isDisabled = false;
    const {
      meal_plan_order_time,
      values: { free_delivery_amount },
    } = this.state;
    if (!meal_plan_order_time) isDisabled = true;
    if (!(Number(free_delivery_amount) >= 0) && !(free_delivery_amount === ""))
      isDisabled = true;
    if (isDisabled === false) return "";
    return "disabled";
  };

  isToggleMealPlanDisabled = () => {
    // console.log("isToggleMealPlanDisabled")
    const { storeHasActiveDeliveryWallet, isActive } = this.state;
    if (!storeHasActiveDeliveryWallet && !isActive) return "disabled";
    if (
      (!this.state.meal_plan_order_time ||
        this.state.values.free_delivery_amount < 0) &&
      !isActive
    )
      return "disabled";
    return "";
  };

  async componentDidMount() {
    // console.log("component did mount state: ", this.state)
    const { store, storeHasActiveDeliveryWallet, isActive } = this.state;
    const fetchedStore = (await fetchData("get", `/store/${store._id}`)).data;
    const hasDelivery = (await CountActive(fetchedStore.delivery_method))
      .countWallet;
    if (storeHasActiveDeliveryWallet !== hasDelivery) {
      this.setState({
        storeHasActiveDeliveryWallet: hasDelivery,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { store, storeHasActiveDeliveryWallet, isActive } = this.state;
    const fetchedStore = (await fetchData("get", `/store/${store._id}`)).data;
    const hasDelivery = (await CountActive(fetchedStore.delivery_method))
      .countWallet;
    let newIsActive =
      !storeHasActiveDeliveryWallet && isActive ? false : isActive;
    if (
      storeHasActiveDeliveryWallet !== hasDelivery ||
      isActive !== newIsActive
    ) {
      this.setState({
        storeHasActiveDeliveryWallet: hasDelivery,
        isActive: newIsActive,
      });
    }
  }

  render() {
    const {
      isActive,
      store,
      meal_plan_order_time,
      storeHasActiveDeliveryWallet,
    } = this.state;
    const time_options = getTimeOptions();
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <>
        <Card className="card-custom gutter-b">
          <Card.Header>
            <Card.Title>Meal Plan Settings</Card.Title>
          </Card.Header>
          <Card.Body>
            <div>
              <InfoAlertHelper
                text={
                  "You can manage your meal plan service availability in your Meal Plan Settings"
                }
              />
              <Block
                content={
                  <Card className="d-flex justify-content-between w-100">
                    <Card.Body>
                      <div
                        style={{ transform: "scale(1.2)" }}
                        className="row d-flex align-items-center"
                      >
                        <span className="switch switch-outline switch-icon switch-primary py-1 m-0 ml-10 pl-10">
                          <label>
                            <input
                              type="checkbox"
                              name="isActive"
                              checked={isActive ? "checked" : ""}
                              disabled={this.isToggleMealPlanDisabled()}
                              onChange={() =>
                                this.handleToggleMealPlanStatus(isActive)
                              }
                            />
                            <span></span>
                          </label>
                        </span>
                        <label className="text-dark font-weight-boldest text-uppercase py-4 px-3">
                          Allow Meal Plan Orders
                        </label>
                        {/* {InfoTooltip("⚠️Meal Plan Feature can only be enabled if you have active delivery partners")} */}
                        {InfoTooltip(
                          "Please make sure that you have an active delivery partner that allows delivery payment during checkout.",
                        )}
                        {!storeHasActiveDeliveryWallet && !isActive ? (
                          <>
                            <span
                              style={{ transform: "scale(0.8)" }}
                              className="py-4 px-3 text-danger"
                            >
                              Meal Plan Feature can only be enabled if you have
                              active delivery partners
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                }
              ></Block>

              <div className="mt-5">
                <Label
                  text={
                    <>
                      Meal Plan Order Time{" "}
                      <span style={{ color: "red" }}>*</span>
                    </>
                  }
                />
                <select
                  className="form-control mb-5"
                  onChange={this.handleSelectMealPlanOrderTime}
                  value={meal_plan_order_time}
                >
                  <RenderTimeOptions
                    time_options={time_options}
                    end={"24:00"}
                    start={""}
                  />
                </select>
              </div>

              <FormItem
                label={
                  <>
                    Meal Plan Subsidized Delivery Amount
                    {InfoTooltip(
                      "This is the discount applied to the delivery cost for each day of the meal plan orders.",
                    )}
                  </>
                }
                name={"free_delivery_amount"}
                inputProps={{
                  name: "free_delivery_amount",
                  placeholder: "0.00",
                  value: this.state.values["free_delivery_amount"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"number"}
                showRequired={true}
                normal
              />

              <FormItem
                label={<>Meal Plan Percentage Discount (%)</>}
                name={"mealPlanDiscount"}
                inputProps={{
                  name: "mealPlanDiscount",
                  placeholder: "0",
                  value: this.state.values["mealPlanDiscount"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"number"}
                showRequired={false}
                normal
              />

              <Label
                text={
                  <>
                    LABELS{" "}
                    {InfoTooltip(
                      "These labels will be displayed on the homepage.",
                    )}
                  </>
                }
              />

              <Row>
                <Col>
                  <FormItem
                    label={"3 Day Meal Plan Top Label"}
                    name={"3DayMealPlanTop"}
                    inputProps={{
                      name: "3DayMealPlanTop",
                      placeholder: "3 Day Meal Plan Top Label",
                      value: this.state.values["3DayMealPlanTop"],
                    }}
                    inputActions={inputActions}
                    actions={actions}
                    showRequired={false}
                    normal
                  />
                </Col>
                <Col>
                  <FormItem
                    label={"5 Day Meal Plan Top Label"}
                    name={"5DayMealPlanTop"}
                    inputProps={{
                      name: "5DayMealPlanTop",
                      placeholder: "5 Day Meal Plan Top Label",
                      value: this.state.values["5DayMealPlanTop"],
                    }}
                    inputActions={inputActions}
                    actions={actions}
                    showRequired={false}
                    normal
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormItem
                    label={"3 Day Meal Plan Bottom Label"}
                    name={"3DayMealPlanBtm"}
                    inputProps={{
                      name: "3DayMealPlanBtm",
                      placeholder: "3 Day Meal Plan Bottom Label",
                      value: this.state.values["3DayMealPlanBtm"],
                    }}
                    inputActions={inputActions}
                    actions={actions}
                    showRequired={false}
                    normal
                  />
                </Col>
                <Col>
                  <FormItem
                    label={"5 Day Meal Plan Bottom Label"}
                    name={"5DayMealPlanBtm"}
                    inputProps={{
                      name: "5DayMealPlanBtm",
                      placeholder: "5 Day Meal Plan Bottom Label",
                      value: this.state.values["5DayMealPlanBtm"],
                    }}
                    inputActions={inputActions}
                    actions={actions}
                    showRequired={false}
                    normal
                  />
                </Col>
              </Row>
            </div>
          </Card.Body>
          <Card.Footer>
            <Button
              variant="primary"
              className={GetSubmitClassList(this.state.isSubmitting, "")}
              onClick={this.handleSaveChanges}
              disabled={this.isSaveChangesDisabled()}
            >
              Save Changes
            </Button>
          </Card.Footer>
        </Card>
      </>
    );
  }
}
