import React from "react";
import styled from "styled-components";

import { Container } from "./ReservationsDashboard";
import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import { SVGIcon } from "../../../components/Base";
import { RESERVATIONS_TAB_LIST } from "./ReservationsConsole";
import moment from "moment";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import ReservationsDetails from "./ReservationsDetails";
import { DB_HOURS_FORMAT, PRIMARY_COLOR } from "../../../constants";
import PacmanWrapper from "../../../components/dashboard/PacmanWrapper";
import APIComponent from "../../../components/API";
import { GetDataFromEvent } from "../../../helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddNotesModal from "./AddNotesModal";

const MySwal = withReactContent(Swal);

const NAV_FOOTER_LIST = {
  WALK_IN: "queue",
  UPCOMING: "upcoming",
  SEATED: "seated",
  FINISHED: "finished",
  PAID: "paid",
  CANCELED: "canceled",
};

const NAV_FOOTER_ARR = [
  { icon: NAV_FOOTER_LIST.WALK_IN, label: "Walk-in/Queue" },
  { icon: NAV_FOOTER_LIST.UPCOMING, label: "Upcoming" },
  { icon: NAV_FOOTER_LIST.SEATED, label: "Seated" },
  { icon: NAV_FOOTER_LIST.FINISHED, label: "Finished" },
  { icon: NAV_FOOTER_LIST.PAID, label: "Paid" },
  { icon: NAV_FOOTER_LIST.CANCELED, label: "Canceled" },
];

export const STATUS_ARR = {
  EXPECTED: "expected",
  SEATED: "seated",
  FINISHED: "finished",
  PAID: "paid",
  CLOSED: "closed",
};

const DETAILS_FOOTER_ARR = {
  [STATUS_ARR.EXPECTED]: { color: "#1400FF", label: "Expected" },
  [STATUS_ARR.SEATED]: { color: "#3BB500", label: "Seated" },
  [STATUS_ARR.FINISHED]: { color: "#FF6900", label: "Finished" },
  [STATUS_ARR.PAID]: { color: "#EA3323", label: "Paid" },
  [STATUS_ARR.CLOSED]: { color: "#4900FF", label: "Closed" },
};

const ALLERGIES_ICON = [
  { icon: "peanuts", text: "Peanuts" },
  { icon: "vegan", text: "Vegan" },
  { icon: "vegetarian", text: "Vegetarian" },
  { icon: "seafood", text: "Seafood" },
];

const dummy_reservations_count = {
  [NAV_FOOTER_LIST.WALK_IN]: { count: 0 },
  [NAV_FOOTER_LIST.UPCOMING]: { count: 5 },
  [NAV_FOOTER_LIST.SEATED]: { count: 4 },
  [NAV_FOOTER_LIST.FINISHED]: { count: 6 },
  [NAV_FOOTER_LIST.PAID]: { count: 0 },
  [NAV_FOOTER_LIST.CANCELED]: { count: 1 },
};

const dummy_tags = [
  { icon: "fa-beer", label: "Beer Drinker" },
  { icon: "fa-glass-martini", label: "Loves Wine" },
  { icon: "fa-glass-martini", label: "Mimosa Lover" },
  { icon: "fa-beer", label: "Beer Drinker" },
  { icon: "fa-glass-martini", label: "Loves Wine" },
  { icon: "fa-glass-martini", label: "Mimosa Lover" },
];

const dummy_notes = [
  {
    notes: "Tips Generously",
    date_created: moment().toDate(),
  },
  {
    notes: "Hates butter on anything",
    date_created: moment().subtract(3, "month").toDate(),
  },

  {
    notes: "Will look better with hair",
    date_created: moment().subtract(1, "month").toDate(),
  },
  {
    notes: "Sometimes brings his son around, the son likes bread and butter",
    date_created: moment().subtract(1, "month").subtract(4, "days").toDate(),
  },
  {
    notes: "Heavy beer drinker",
    date_created: moment().toDate(),
  },
  {
    notes: "Loves his tea",
    date_created: moment().subtract(1, "month").toDate(),
  },
];

const getTimeOptions = () => {
  let current = moment(new Date()).startOf("hour"),
    end = moment(new Date()).endOf("day"),
    options = [];
  while (current.isSameOrBefore(end)) {
    options.push({
      text: current.format("hh:mm A"),
      value: current.format("HH:mm"),
    });
    current = current.add(30, "minutes");
  }

  return options;
};

class ReservationList extends APIComponent {
  constructor(props) {
    super(props);
    const store_id = this.props.store_id;
    this.state = {
      tab_selected: NAV_FOOTER_LIST.UPCOMING,
      list_selected: RESERVATIONS_TAB_LIST.RESERVATIONS_QUEUE,
      max_guest_enabled: 8,
      selected_date: new Date(),
      isReservationDetails: false,
      reservation_status: STATUS_ARR.EXPECTED,
      reservations_count: dummy_reservations_count,
      openStatusChangeModal: false,
      showPrintModal: false,
      showNotesModal: false,
      guest_tags: dummy_tags,
      customer_notes: dummy_notes,
      reservations: [],
      data_status: "fetching",
      store_id,
      selected_reservation: null,
    };
  }

  async getData() {
    try {
      this._isMounted &&
        this.setState({
          data_status: "fetching",
        });

      let { store_id = "" } = this.props;
      this.props.api &&
        this.props.api
          .get(`/reservation/store/${store_id}`)
          .then(({ data }) => {
            this.setState({
              reservations: data["upcoming"],
              data_status: "fetched",
            });
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });

      this.handleOnDataFetched();
    } catch (error) {
      this._isMounted && this.setState({ error: error, showSwalError: true });
      this.handleOnDataFetchError();
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.getData();
  }

  onClickConsoleTab = (e) => {
    const name = GetDataFromEvent(e, "data-item");
    this.setState({ list_selected: name });
  };

  onClickNavFooter = (e) => {
    const name = GetDataFromEvent(e, "data-item");
    this.setState({ tab_selected: name });
  };

  onCalendarBtnClick = (isPrev = false) => {
    const { selected_date } = this.state;
    this.setState({
      selected_date: isPrev
        ? moment(selected_date).subtract(1, "day")
        : moment(selected_date).add(1, "day"),
    });
  };

  onDateInputChange = (e) => {
    this.setState({
      selected_date: e.target.value,
    });
  };

  onReservationOverviewClick = (reservation) => {
    this.setState({
      isReservationDetails: !this.state.isReservationDetails,
      selected_reservation: reservation,
    });
  };

  onActionButtonClick = (value) => {
    console.log("action button clicked: ", value);
  };

  onPrintModalClick = () => {
    this.setState({ showPrintModal: !this.state.showPrintModal });
  };

  onPrintAllergies = () => {
    console.log("print allergies");
    this.onPrintModalClick();
  };

  onStatusChange = (value) => {
    const { reservation_status } = this.state;
    if (value !== reservation_status) {
      const isClosing = value === STATUS_ARR.CLOSED;

      const swalTitleText = isClosing ? "Closing the table?" : "Are you sure?";
      const swalMessage =
        "Upon modifying the status, please note that the changes made will be permanent and irreversible";
      const swalClosingMessage =
        "Prior to proceeding to the next step, please verify that the customer has settled their bill payment";

      let swalHTMLmessage = swalMessage;
      if (isClosing) {
        swalHTMLmessage = `<div style="font-size:0.9rem">${swalMessage}</div>
        <div style="font-size:1.25rem">${swalClosingMessage}</div>`;
      }

      MySwal.fire({
        icon: "warning",
        title: swalTitleText,
        html: swalHTMLmessage,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        reverseButtons: true,
        ...(isClosing && { width: 650 }),
      }).then((result) => {
        if (result.value) {
          this.setState({ reservation_status: value });
        }
      });
    }
  };

  onAddNotesClick = () => {
    console.log("notes click");
    this.setState({ showNotesModal: !this.state.showNotesModal });
  };

  onSubmitNotes = (value) => {
    const { customer_notes } = this.state;
    customer_notes.splice(0, 0, {
      notes: value.notes,
      date_created: moment().toDate(),
    });
    this.onAddNotesClick();
    MySwal.fire({
      icon: "success",
      title: "Notes successfully added!",
      timer: 4000,
    }).then((result) => {
      if (result.value) {
        this.setState({ customer_notes: customer_notes });
      }
    });
  };

  onAddTags = (e, values) => {
    e.preventDefault();
    this.setState({ guest_tags: values });
  };

  render() {
    const {
      list_selected,
      tab_selected,
      max_guest_enabled,
      isReservationDetails,
      reservations_count,
      reservations,
      selected_reservation,
      reservation_status,
      customer_notes,
      guest_tags,
      showPrintModal,
      showNotesModal,
    } = this.state;

    const getListClass = (name = "", list_selected = "") => {
      return name === list_selected
        ? "d-flex font-weight-bolder font-pickup-sm-2 secondary-border-bottom p-3"
        : "d-flex font-weight-bolder font-pickup-sm-2 cursor-pointer p-3";
    };

    const listHeaderIndex = NAV_FOOTER_ARR.findIndex(
      (footer) => footer.icon === tab_selected,
    );
    const time_options = getTimeOptions();

    const total_count = Object.values(reservations_count).reduce(
      (acc, cur) => (acc = acc + cur["count"]),
      0,
    );

    const isLoading = this.state.data_status === "fetching";

    return (
      <Container className="d-flex flex-row">
        <Card>
          <Card.Header className="d-flex p-0">
            <div className="d-flex flex-column">
              <div
                className={getListClass(
                  RESERVATIONS_TAB_LIST.RESERVATIONS_QUEUE,
                  list_selected,
                )}
                data-item={RESERVATIONS_TAB_LIST.RESERVATIONS_QUEUE}
                onClick={this.onClickConsoleTab}
              >
                Reservations
              </div>
            </div>
            <div className="d-flex flex-column">
              <div
                className={getListClass(
                  RESERVATIONS_TAB_LIST.TASKS,
                  list_selected,
                )}
                data-item={RESERVATIONS_TAB_LIST.TASKS}
                onClick={this.onClickConsoleTab}
              >
                Tasks
              </div>
            </div>
            <div className="d-flex flex-column">
              <div
                className={getListClass(
                  RESERVATIONS_TAB_LIST.GUESTS,
                  list_selected,
                )}
                data-item={RESERVATIONS_TAB_LIST.GUESTS}
                onClick={this.onClickConsoleTab}
              >
                Guests
              </div>
            </div>
          </Card.Header>
          <Card.Body className="d-flex p-0 flex-column">
            {isLoading && (
              <>
                <div className="d-flex justify-content-center align-items-center h-100">
                  <PacmanWrapper />
                </div>
              </>
            )}
            {!isLoading && !isReservationDetails && max_guest_enabled > 0 && (
              <div className="d-flex flex-row">
                <MaxEnabledDiv>
                  Maximum capacity guest enabled: <b>{max_guest_enabled}</b>
                </MaxEnabledDiv>
              </div>
            )}
            {!isLoading && !isReservationDetails && (
              <div
                className="d-flex flex-row font-weight-bolder text-capitalize p-2 justify-content-space-between"
                style={{ borderBottom: "1px solid #707070" }}
              >
                <div className="d-flex flex-row">
                  <SVGIcon
                    icon={`Reservations/${tab_selected}`}
                    title={tab_selected}
                    className="mr-2"
                  />
                  {NAV_FOOTER_ARR[listHeaderIndex].label}
                </div>
                <div className="d-flex flex-row">
                  {tab_selected === NAV_FOOTER_LIST.UPCOMING
                    ? `${
                        reservations_count[NAV_FOOTER_LIST.SEATED].count +
                        reservations_count[NAV_FOOTER_LIST.UPCOMING].count
                      }(${total_count})`
                    : `${reservations_count[tab_selected].count}`}
                </div>
              </div>
            )}
            {!isLoading && (
              <div
                style={{
                  overflow: "scroll",
                }}
              >
                {!isReservationDetails ? (
                  <ReservationOverviewCard
                    isLoading={isLoading}
                    tab_selected={tab_selected}
                    reservations={reservations}
                    time_options={time_options}
                    onReservationOverviewClick={this.onReservationOverviewClick}
                  />
                ) : (
                  <ReservationsDetails.DetailsContainer
                    reservation={selected_reservation}
                    customer_notes={customer_notes}
                    guest_tags={guest_tags}
                    onDetailsCloseClick={() =>
                      this.onReservationOverviewClick(null)
                    }
                    onActionButtonClick={this.onActionButtonClick}
                    onAddTags={this.onAddTags}
                    onPrintBtnClick={this.onPrintModalClick}
                    onAddNotesClick={this.onAddNotesClick}
                  />
                )}
              </div>
            )}
          </Card.Body>
          <Card.Footer className="d-flex flex-row p-0">
            {!isReservationDetails ? (
              <NavFooter
                reservations_count={reservations_count}
                onClickNavFooter={this.onClickNavFooter}
              />
            ) : (
              <ReservationsFooter
                reservation_status={reservation_status}
                onStatusChange={this.onStatusChange}
              />
            )}
          </Card.Footer>
        </Card>
        {showPrintModal && (
          <PrintDietaryRestrictionModal
            show={showPrintModal}
            onHide={this.onPrintModalClick}
            onPrintAllergies={this.onPrintAllergies}
          />
        )}
        {showNotesModal && (
          <AddNotesModal
            show={showNotesModal}
            onHide={this.onAddNotesClick}
            onSubmit={this.onSubmitNotes}
          />
        )}
      </Container>
    );
  }
}

const ReservationOverviewCard = (props) => {
  const {
    reservations,
    time_options,
    tab_selected,
    onReservationOverviewClick,
  } = props;
  return (
    reservations.length > 0 &&
    time_options.map(({ text, value }, i) => {
      const afterTimeOption = moment(value, DB_HOURS_FORMAT);
      const beforeTimeOption = afterTimeOption.add(30, "minutes");
      const filteredData = reservations.filter((item) => {
        return moment(item.reservation_time, DB_HOURS_FORMAT).isBetween(
          afterTimeOption,
          beforeTimeOption,
          "hours",
          "[]",
        );
      });
      return (
        <div key={`time-options-${i}`} className="p-2">
          <div className="d-flex flex-row font-weight-bolder">{text}</div>
          {filteredData.length === 0 && (
            <>
              <div className="mx-2 mt-2">No reservation available</div>
            </>
          )}
          {filteredData.length > 0 &&
            filteredData.map((reservation, idx) => {
              const { guest_customer, guest_count } = reservation;
              const customer_name = `${guest_customer.last_name}, ${guest_customer.first_name}`;
              return (
                <Card className="m-2" key={`reservation-card-${idx}`}>
                  <div
                    className="d-flex flex-row reservation-overview-card"
                    onClick={() => onReservationOverviewClick(reservation)}
                  >
                    <div
                      className="d-flex flex-row overview-col justify-content-space-between"
                      style={{ flexBasis: "90%" }}
                    >
                      <div className="d-flex flex-column p-1 ml-2">
                        <div className="d-flex flex-row font-card-title font-weight-bolder align-items-center text-capitalize">
                          <div
                            style={{
                              maxWidth: 300,
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {customer_name}
                          </div>
                          <OrangeTag className="ml-2">VIP</OrangeTag>
                          <YellowDollarSign className="fas fa-dollar-sign" />
                        </div>
                        <div className="d-flex flex-col align-items-center">
                          <SVGIcon
                            icon="Reservations/reservation-icon"
                            fill="#464E5F"
                            variant="dark"
                          />
                          <span className="ml-1" style={{ fontSize: "0.8rem" }}>
                            Ala Carte
                          </span>
                          <div className="d-flex">
                            {ALLERGIES_ICON.map(({ icon, text }) => {
                              return (
                                <SVGIcon
                                  key={icon}
                                  icon={`Reservations/allergies/${icon}`}
                                  title={text}
                                />
                              );
                            })}
                          </div>
                          <span
                            className="ml-1"
                            style={{ color: "#0045FF", fontSize: "0.75rem" }}
                          >
                            +3
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-center">
                        <div className="d-flex flex-row justify-content-center align-items-center">
                          {["message", "wine_sommelier"].map((icon) => {
                            return (
                              <div
                                className="d-flex flex-column p-1"
                                key={`overview-${icon}`}
                              >
                                <SVG
                                  src={toAbsoluteUrl(
                                    `/media/svg/icons/Reservations/${icon}.svg`,
                                  )}
                                  width={35}
                                  height={35}
                                  title={icon}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <div className="d-flex justify-content-center align-items-center font-pickup-lg font-weight-bolder m-2">
                          {guest_count}
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column justify-content-center align-items-center font-weight-bolder"
                      style={{
                        background: "#EEF0F6",
                        flexBasis: "10%",
                        lineHeight: "1.6rem",
                      }}
                    >
                      <div className="d-flex font-pickup-md">B3</div>
                      {[
                        NAV_FOOTER_LIST.SEATED,
                        NAV_FOOTER_LIST.FINISHED,
                      ].indexOf(tab_selected) > -1 && (
                        <div
                          className="d-flex justify-content-center my-1 w-100"
                          style={{
                            color: "#000",
                            backgroundColor:
                              tab_selected === NAV_FOOTER_LIST.SEATED
                                ? "#0AFF00"
                                : "#FF7F00",
                            fontSize: "0.9rem",
                            lineHeight: "1rem",
                          }}
                        >
                          00:15
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              );
            })}
        </div>
      );
    })
  );
};

const ReservationsFooter = (props) => {
  const { reservation_status, onStatusChange } = props;
  return (
    <div className="d-flex flex-row justify-content-space-between align-items-center m-1 w-100">
      <Dropdown className="m-4" variant="primary" id="dropdown-basic">
        <Dropdown.Toggle
          className="d-flex justify-content-space-between align-items-center"
          variant="outline-primary"
          id="dropdown-basic"
          style={{ width: "15rem" }}
        >
          <div className="d-flex flex-row align-items-center">
            <div
              className="d-flex mr-2"
              style={{
                width: 15,
                height: 15,
                backgroundColor: DETAILS_FOOTER_ARR[reservation_status].color,
                borderRadius: 10,
              }}
            />
            <span className="text-capitalize">
              {DETAILS_FOOTER_ARR[reservation_status].label}
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mb-2" style={{ width: "15rem" }}>
          {Object.keys(DETAILS_FOOTER_ARR)
            .filter((key) => key !== reservation_status)
            .map((key) => {
              return (
                <Dropdown.Item
                  key={DETAILS_FOOTER_ARR[key].label}
                  onClick={() => onStatusChange(key)}
                >
                  <div
                    className="d-flex mr-2"
                    style={{
                      width: 15,
                      height: 15,
                      backgroundColor: DETAILS_FOOTER_ARR[key].color,
                      borderRadius: 10,
                    }}
                  />
                  <span className="text-capitalize">
                    {DETAILS_FOOTER_ARR[key].label}
                  </span>
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
      <div className="d-flex mx-4">
        <Button
          className="mr-3"
          variant="custom"
          style={{ backgroundColor: "#707070" }}
        >
          <span className={`svg-icon svg-icon-lg`}>
            <SVG
              src={toAbsoluteUrl(`/media/svg/icons/Reservations/table.svg`)}
              title="Table Number"
            />
          </span>
          <span
            className="font-weight-bolder font-pickup-md"
            style={{ color: "#fff", padding: 0 }}
          >
            TM1
          </span>
        </Button>
        <Button variant="custom" style={{ backgroundColor: "#1400FF" }}>
          <span className={`svg-icon svg-icon-lg justify-content-center mr-0`}>
            <SVG
              src={toAbsoluteUrl(
                `/media/svg/icons/Reservations/move_console.svg`,
              )}
              title="Move Table"
            />
          </span>
        </Button>
      </div>
    </div>
  );
};

const NavFooter = (props) => {
  const { reservations_count, onClickNavFooter } = props;
  return NAV_FOOTER_ARR.map(({ icon, label }) => {
    const { tab_selected } = props;
    const isTabSelected = tab_selected === icon;
    return (
      <div
        className="d-flex"
        key={`reservations-${icon}`}
        style={{
          cursor: "pointer",
          backgroundColor: isTabSelected ? "#2C2C2C" : "#ffffff",
        }}
        data-item={icon}
        onClick={onClickNavFooter}
      >
        <div
          className="d-flex flex-column justify-content-center flex-grow-1 flex-shrink-0 m-1"
          style={{ width: 60, height: 60, paddingLeft: "1.2rem" }}
        >
          <div className="d-flex flex-row h-100">
            <div
              className="d-flex flex-grow-1 justify-content-center"
              style={{ alignItems: "flex-end" }}
            >
              <FooterIcon>
                <SVGIcon
                  icon={`Reservations/${icon}${isTabSelected ? `-active` : ""}`}
                  title={label}
                />
              </FooterIcon>
            </div>
          </div>
          <div
            className="d-flex text-capitalize justify-content-center flex-grow-1 "
            style={{
              fontSize: "0.85rem",
              lineHeight: "1rem",
              textAlign: "center",
              color: isTabSelected ? "#fff" : "#000",
            }}
          >
            {label}
          </div>
        </div>
        <div
          className="d-flex flex-column pr-2"
          style={{ color: isTabSelected ? "#fff" : "#000" }}
        >
          {reservations_count[icon].count}
        </div>
      </div>
    );
  });
};

const PrintDietaryRestrictionModal = (props) => {
  const { show, onHide, onPrintAllergies } = props;
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header className="d-flex flex-column" closeButton>
        <Modal.Title>Continue to print?</Modal.Title>
        <div>Once printed this will be sent over to the server</div>
      </Modal.Header>
      <Modal.Body className="px-1 py-2">
        <ReservationsDetails.DietaryRestrictionContainer
          isModal
          onPrintBtnClick={onPrintAllergies}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex flex-row justify-content-center w-100">
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate w-100 mx-5"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onPrintAllergies}
            className="btn btn-primary btn-elevate w-100 mx-5"
          >
            Print
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const YellowDollarSign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc400;
  border-radius: 15px;
  height: 15px;
  width: 15px;
  font-size: 0.9rem;
  text-align: center;
  margin-left: 0.5rem;
`;

export const OrangeTag = styled.div`
  width: 38px;
  height: 13px;
  background: #ea3323 0% 0% no-repeat padding-box;
  border-radius: 15px;
  color: #ffffff;
  letter-spacing: 0.36px;
  font-size: 0.7rem;
  text-align: center;
`;

const MaxEnabledDiv = styled.div`
  width: 100%;
  display: flex;
  background: #dedede 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 5px;
  justify-content: center;
`;
const FooterIcon = styled.span`
  .svg-icon svg {
    height: 28px;
    width: 28px;
  }
  margin-bottom: 5px;
`;
export default ReservationList;
