import React from "react";

import { useSelector } from "react-redux";
import { Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import { Redirect, useParams } from "react-router-dom";
import Datatable from "react-data-table-component";
import { debounce } from "lodash";

import {
  capitalizeText,
  fetchData,
  getToolTitleFromToolName,
  Humanize,
} from "../../helpers";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import { HeaderTextWithActions } from "../../components/Base";
import Breadcrumbs from "../../components/Breadcrumbs";
import AdminToolsBrandToolModal from "./AdminToolsBrandToolModal";
import PacmanLoading from "../../components/widgets/PacmanLoading/PacmanLoading";
import PaginationBar from "../../components/PaginationBar";
import { ADMINTOOLS_FETCH_LIMIT } from "../../constants";

export default function Page(props) {
  const { user } = useSelector((state) => state.auth);
  const { tool_name = "" } = useParams();
  return <MainComponent user={user} toolName={tool_name} />;
}

const utilizeFocus = () => {
  const ref = React.createRef();
  const setFocus = () => {
    ref.current && ref.current.focus();
  };

  return { setFocus, ref };
};

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      tools: "",
      showModal: false,
      showSplashScreen: false,
      redirectTo: "",
      toolName: this.props.toolName,
      showBrandToolModal: false,
      selectedData: "",
      pageNumber: 1,
      title: getToolTitleFromToolName(this.props.toolName),
      searchText: "",
      multiStoreFilter: "",
      isLoading: false,
    };

    this.api = core("pu").get();
    const { pageNumber } = this.state;
    if (this.props.toolName === "restore_brand") {
      this.data_url = `/admintools/brands/deleted?page=${pageNumber}`;
    } else {
      this.data_url = `/admintools/brands?page=${pageNumber}`;
    }

    this.inputFocus = utilizeFocus();
  }

  handleRefreshBrand = async () => {
    let data = {};
    const { pageNumber } = this.state;
    this.setState({ isLoading: true });
    if (this.props.toolName === "restore_brand") {
      ({ data } = await fetchData(
        "get",
        `/admintools/brands/deleted?page=${pageNumber}`,
      ));
    } else {
      ({ data } = await fetchData(
        "get",
        `/admintools/brands?page=${pageNumber}`,
      ));
    }
    this.setState({ data, isLoading: false });
  };

  handleRefreshBrandWithSearch = async (searchText, pageNumber = 1) => {
    let data = {};
    this.setState({ isLoading: true });
    if (this.props.toolName === "restore_brand") {
      ({ data } = await fetchData(
        "get",
        `/admintools/brands/deleted?searchKey=${searchText}&page=${pageNumber}`,
      ));
    } else {
      ({ data } = await fetchData(
        "get",
        `/admintools/brands?searchKey=${searchText}&page=${pageNumber}`,
      ));
    }
    this.setState({ data, isLoading: false });
  };

  handleChangeSearchText = (searchText) => {
    this.setState({ searchText });
    this.debounceChangeSearchText(searchText);
  };

  debounceChangeSearchText = debounce(async (searchText) => {
    // this.setState({ searchText });
    await this.handleRefreshBrandWithSearch(searchText);
  }, 500);

  handleClickBrand = (toolname, data) => {
    this.setState({ showBrandToolModal: true, selectedData: data });
  };

  handleSelectMultiStoreFilter = (value) => {
    this.setState({ multiStoreFilter: value });
  };

  handleSelectPage = async (pageNumber = 1, pagesArr = []) => {
    const { searchText } = this.state;
    let page = pageNumber;
    if (page <= 0) {
      page = 1;
    }
    if (page > pagesArr.length) {
      page = pagesArr.length;
    }
    const result = await this.handleRefreshBrandWithSearch(
      searchText,
      pageNumber,
    );
    this.setState({ pageNumber });
  };

  render() {
    const {
        data,
        data_status = "",
        toolName = "",
        selectedData = "",
        searchText = "",
        pageNumber = 1,
        multiStoreFilter = "",
      } = this.state,
      isLoading = data_status !== "fetched";

    const limit = ADMINTOOLS_FETCH_LIMIT;

    let pagesArr = [];
    if (data && data.items && data.items.length) {
      for (let i = 0; i * limit < data.totalCount; i++) {
        pagesArr.push(i);
      }
    }

    return (
      <>
        <div className="col-md-9 col-sm-12">
          <Breadcrumbs>
            <Breadcrumbs.Item text={`Admin Tools`} link={`/admin/tools`} />
            <Breadcrumbs.Item
              text={getToolTitleFromToolName(this.props.toolName)}
              link={`/admin/tools/brands`}
              active="true"
            />
          </Breadcrumbs>
        </div>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        {this.state.selectedData ? (
          <AdminToolsBrandToolModal
            handleRefreshBrand={this.handleRefreshBrand}
            show={this.state.showBrandToolModal}
            toolName={toolName}
            onHide={() => {
              this.setState({ showBrandToolModal: false });
            }}
            data={selectedData}
          />
        ) : (
          <></>
        )}

        <HeaderTextWithActions title={this.state.title} />

        {this.state.isLoading || isLoading ? (
          <>
            <div className="d-flex justify-content-center align-items-center h-100">
              <PacmanLoading />
            </div>
          </>
        ) : (
          <>
            <Card className="mt-4">
              <Card.Body>
                <SearchBar
                  searchText={searchText}
                  handleChangeSearchText={this.handleChangeSearchText}
                  inputFocus={this.inputFocus}
                />
                {data.items && data.items.length > 0 ? (
                  <>
                    <h3 className="align-self-start mb-0 pb-0 mt-4">
                      Select Brand
                    </h3>
                  </>
                ) : (
                  <>
                    <div className="mt-4 text-center">No records found.</div>
                  </>
                )}
                <BrandList
                  data={data}
                  toolName={toolName}
                  handleClickBrand={this.handleClickBrand}
                />
                {data.items && data.items.length > 0 && (
                  <div className="position-sticky" style={{ left: 0 }}>
                    <PaginationBar
                      pagesArr={pagesArr}
                      totalResults={data.totalCount}
                      rows={limit}
                      pageNum={pageNumber}
                      limit={limit}
                      onSelectPage={this.handleSelectPage}
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
          </>
        )}
      </>
    );
  }
}

function SearchBar({
  searchText = "",
  handleChangeSearchText,
  inputFocus = {},
}) {
  return (
    <>
      <Row className="w-100">
        <Col>
          <div className="d-flex flex-column w-100 align-items-center">
            <div
              style={{ width: "45vw" }}
              className="input-group input-group-lg"
            >
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="flaticon-search"></i>
                </span>
              </div>
              <input
                autoFocus
                ref={inputFocus.ref}
                type="text"
                className="form-control"
                placeholder="Search Brand Name"
                value={searchText}
                onChange={(e) => {
                  handleChangeSearchText(e.target.value);
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

function BrandList({ data, toolName, handleClickBrand }) {
  const filteredItems = data.items;

  return (
    <>
      {toolName === "restore_brand" ? (
        <>
          {filteredItems.map((row) => {
            return (
              <DeletedBrandTableCard
                key={row._id}
                data={row}
                toolName={toolName}
                handleClickBrand={handleClickBrand}
              />
            );
          })}
        </>
      ) : (
        <>
          {filteredItems.map((row) => {
            return (
              <BrandTableCard
                key={row._id}
                data={row}
                toolName={toolName}
                handleClickBrand={handleClickBrand}
              />
            );
          })}
        </>
      )}
    </>
  );
}

function BrandTableCard({ data, toolName, handleClickBrand }) {
  return (
    <>
      <div>
        <Col className="w-100">
          <Card
            style={{ width: "65vw" }}
            className="my-3 cursor-pointer"
            onClick={() => handleClickBrand(toolName, data)}
          >
            <Card.Body>
              <div className="d-flex flex-row align-items-center justify-content-space-between">
                <div className="">
                  <img
                    src={data.brand_logo}
                    alt="brand_logo"
                    style={{
                      width: "5.5rem",
                    }}
                  />
                </div>
                <div className="ml-4 d-flex flex-column flex-grow-1 align-items-start">
                  <h4>{data.name}</h4>
                  <h6>{`URL: ${data.brand_url}`}</h6>
                  <h6>
                    {`Address: ${
                      data.client && data.client.address
                        ? data.client.address
                        : "-"
                    }`}
                  </h6>
                  <h6>{`Group Status: ${
                    data.client && data.client.group_type === "multi"
                      ? "Multi Store"
                      : "Single Store"
                  }`}</h6>
                </div>
                <div className="ml-4">
                  <Button
                    type="submit"
                    className="btn-block text-uppercase font-weight-bold"
                  >
                    Select
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </>
  );
}

function DeletedBrandTableCard({ data, toolName, handleClickBrand }) {
  return (
    <>
      <div>
        <Col className="w-100">
          <Card
            style={{ width: "65vw" }}
            className="my-3 cursor-pointer"
            onClick={() => handleClickBrand(toolName, data)}
          >
            <Card.Body>
              <div className="d-flex flex-row align-items-center justify-content-space-between">
                <div className="ml-4 d-flex flex-column flex-grow-1 align-items-start">
                  <h4>{`Brand Name: ${data.origin_key}`}</h4>
                  <h6>{`Brand Id: ${data.origin_id}`}</h6>
                  <h6>{`Date Deleted: ${data.date_created}`}</h6>
                </div>
                <div className="ml-4">
                  <Button
                    type="submit"
                    className="btn-block text-uppercase font-weight-bold"
                  >
                    Select
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </>
  );
}
