import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { InfoTooltip, Separator, SVGIcon } from "../../components/Base";
import { toPesoAmount } from "../../helpers";
import DisplayOrderDateWithCalendarIcon from "../DisplayOrderDateWithCalendarIcon";

/**
 *
 * @param {*} props
 * @returns
 */
export default function CartItem(props) {
  const {
      cart_item = {},
      index = 0,
      website_theme = {},
      isMealPlan = false,
    } = props,
    { extras = [], included = true } = cart_item;
  const { button_color = "" } = website_theme;

  const CounterButton = styled.button`
    svg g [fill] {
      fill: ${button_color};
    }
  `;

  return (
    <>
      <div className="item d-flex align-items-center">
        <div className="item-photo-wrapper symbol symbol-65 symbol-2by3 flex-shrink-0 mr-4">
          <div
            className="symbol-label"
            style={{ backgroundImage: `url(${cart_item.item.image_url})` }}
          ></div>
          {!isMealPlan && cart_item.item.active_discount > 0 ? (
            cart_item.item.discount_type === "percentDiscount" ? (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "red",
                  backgroundColor: "rgba(250,250,250,0.5)",
                  fontSize: "0.8em",
                  fontWeight: "bolder",
                }}
              >
                {Number(cart_item.item.discount_value)}% OFF
              </div>
            ) : cart_item.item.discount_type === "rawDiscount" ? (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "red",
                  backgroundColor: "rgba(250,250,250,0.5)",
                  fontSize: "0.8em",
                  fontWeight: "bolder",
                }}
              >
                P {Number(cart_item.item.active_discount).toFixed(2)} OFF
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
        <div className="item-text-wrapper d-flex flex-column flex-grow-1">
          <div className={`item-name${!included ? " text-danger" : ""}`}>
            {cart_item.item.name}{" "}
            {cart_item.isExclusive &&
              InfoTooltip(
                "This is an exclusive item. Exclusive items are automatically added at the end of the week.",
              )}
          </div>
          {extras.map(({ name = "", price = 0 }) => {
            return (
              <div className="item-extras-name">
                + {name} {isMealPlan && <>({toPesoAmount(price)})</>}
              </div>
            );
          })}

          {isMealPlan && (
            <a
              href="#"
              style={{ color: website_theme.link_text_color }}
              onClick={() => {
                props.actions.handleOnClickRemoveItem(index);
              }}
            >
              <span className="font-weight-bold">Remove</span>
            </a>
          )}
        </div>
        <div>
          {!isMealPlan && (
            <>
              {cart_item.item.active_discount > 0 ? (
                <div className="item-price text-right">
                  <del>{toPesoAmount(cart_item.regular_price)}</del>
                  <span className="ml-3 text-danger font-weight-boldest">
                    {toPesoAmount(cart_item.price)}
                  </span>
                </div>
              ) : (
                <div className="item-price text-right">
                  {toPesoAmount(cart_item.price)}
                </div>
              )}
            </>
          )}

          {!isMealPlan ? (
            <div className="item-actions-wrapper d-flex align-items-center justify-content-end">
              <CounterButton
                variant="custom"
                className="btn btn-icon btn-xs br-0 mr-2"
                data-id={cart_item.item._id}
                data-index={index}
                data-action="minus"
                onClick={props.actions.handleOnClickCtr}
              >
                <SVGIcon
                  icon={"Navigation/Minus"}
                  title={"Remove"}
                  variant="custom"
                />
              </CounterButton>
              <Button
                variant="outline-primary"
                className="btn-icon btn-xs br-0 mr-2 inactive-link b-none"
              >
                <span style={{ color: button_color }}>{cart_item.qty}</span>
              </Button>
              <CounterButton
                variant="custom"
                className="btn btn-icon btn-xs br-0 mr-2"
                data-id={cart_item.item._id}
                data-index={index}
                data-action="plus"
                onClick={props.actions.handleOnClickCtr}
              >
                <SVGIcon
                  icon={"Navigation/Plus"}
                  title={"Add"}
                  variant="custom"
                />
              </CounterButton>
            </div>
          ) : (
            <>
              <DisplayOrderDateWithCalendarIcon
                order_date={cart_item.order_date}
              />
            </>
          )}
        </div>
      </div>

      {isMealPlan && <Separator dashed style={{ margin: "1rem 0" }} />}
    </>
  );
}
