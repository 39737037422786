import React, { Component } from "react";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Input } from "../../../../_metronic/_partials/controls";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import { ErrorAlert } from "../../../components/Base";
import { Card } from "react-bootstrap";
import { GetSubmitClassList } from "../../../helpers";
import TitleHelmet from "../../../partials/TitleHelmet";
import LoginCenter from "../../../components/LoginCenter";

class ForgotPassword extends Component {
  state = { isRequested: false, showForm: true, showSuccess: false };
  handleEmail(event, handleChange, status) {
    handleChange(event);
    status(undefined);
  }
  render() {
    const { intl } = this.props;
    const { isRequested } = this.state;

    if (isRequested) {
      return <Redirect to="/auth" />;
    }

    const getInputClasses = (meta, fieldname) => {
      let result = "form-control form-control-solid h-auto py-5 px-6 ";
      if (meta.touched && meta.error) {
        result += " is-invalid";
      }

      if (meta.touched && !meta.error) {
        result += " is-valid";
      }

      return result;
    };

    return (
      <>
        <TitleHelmet title="Forgot Password" />
        <LoginCenter>
          <LoginCenter.Form>
            {this.state.showForm ? (
              <>
                <Card className="card-border-top-primary card-custom animate__animated animate__fadeIn">
                  <Card.Body>
                    <div className="mb-10">
                      <h3 className="font-size-h1">Forgot Password?</h3>
                      <p>
                        Please enter your email address to request a password
                        reset.
                      </p>
                      <hr />
                    </div>
                    <Formik
                      initialValues={{ email: "" }}
                      validate={(values) => {
                        const errors = {};

                        if (!values.email) {
                          errors.email = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD",
                          });
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email,
                          )
                        ) {
                          errors.email = intl.formatMessage({
                            id: "AUTH.VALIDATION.INVALID_FIELD",
                          });
                        }

                        return errors;
                      }}
                      onSubmit={(values, { setStatus, setSubmitting }) => {
                        requestPassword(values.email)
                          .then(() => {
                            this.setState({
                              showForm: false,
                              showSuccess: true,
                            });
                          })
                          .catch((err) => {
                            if (err.response) {
                              const { data = {} } = err.response;
                              if (data.result === "error") {
                                setStatus(
                                  intl.formatMessage("GENERAL.API.ERROR"),
                                );
                              }
                            } else {
                              setStatus(
                                intl.formatMessage("GENERAL.API.ERROR"),
                              );
                            }
                          })
                          .finally(() => {
                            setSubmitting(false);
                          });
                      }}
                    >
                      {({
                        // values,
                        status,
                        // errors,
                        touched,
                        handleChange,
                        // handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setStatus,
                      }) => (
                        <form onSubmit={handleSubmit} className="form">
                          {status && <ErrorAlert message={status} />}
                          <div className="form-group">
                            <Field
                              type="email"
                              name="email"
                              component={Input}
                              placeholder="Email"
                              label="Email"
                            >
                              {({ field, form, meta }) => (
                                <div>
                                  <input
                                    type="email"
                                    {...field}
                                    className={`${getInputClasses(meta)}`}
                                    placeholder="Enter Email"
                                    onChange={(e) =>
                                      this.handleEmail(
                                        e,
                                        handleChange,
                                        setStatus,
                                      )
                                    }
                                  />
                                  {meta.touched && meta.error && (
                                    <div className="error invalid-feedback">
                                      {meta.error}
                                    </div>
                                  )}
                                </div>
                              )}
                            </Field>
                          </div>

                          <div className="form-group d-flex flex-wrap flex-center">
                            <button
                              id="kt_login_forgot_submit"
                              type="submit"
                              className={GetSubmitClassList(
                                isSubmitting,
                                "btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4",
                              )}
                              disabled={isSubmitting}
                            >
                              Submit
                            </button>
                            <Link to="/auth">
                              <button
                                type="button"
                                id="kt_login_forgot_cancel"
                                className="btn btn-secondary font-weight-bold px-9 py-4 my-3 mx-4"
                              >
                                Cancel
                              </button>
                            </Link>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </>
            ) : (
              <></>
            )}

            {this.state.showSuccess ? (
              <>
                <Card className="card-border-top-primary card-custom animate__animated animate__fadeIn">
                  <Card.Body>
                    <div className="mb-5">
                      <h3 className="font-size-h1">We found your account!</h3>
                      <hr />
                    </div>
                    <p>We sent an email with a link to reset your password.</p>
                    <p>
                      If you don't see the email, check other places it might
                      be, like your junk, spam, social, or other folders.
                    </p>
                    <p>
                      <Link to="/auth" className="kt-link">
                        {" "}
                        Back to login{" "}
                      </Link>
                    </p>
                  </Card.Body>
                </Card>
              </>
            ) : (
              <></>
            )}
          </LoginCenter.Form>
        </LoginCenter>
      </>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
