import React from "react";
import {
  DFlex,
  DisplayTime,
  FormItem,
  Section,
  Separator,
} from "../components/Base";
import { isEmpty } from "lodash";
import {
  capitalizeFirstLetter,
  GetSubmitClassList,
  phoneNumberPrefix,
  toPesoAmount,
} from "../helpers";
import { Button, Form, Modal } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ACTION_BTN_COLOR } from "./ReservationCard";
import ProSourceForm from "../components/ProSourceForm";
export default class ReservationActionModal extends ProSourceForm {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        notes: "",
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };
  }

  onSubmit = (clickAction, values) => {
    this.props.onSubmit(clickAction, values);
  };

  handleOnChange = (event) => {
    const target = event.target,
      value = target.value,
      name = target.name;
    let { values, errors } = this.state;

    values[name] = value;
    this.setState({
      values: values,
      errors,
    });

    this.setStateKey("touched", name, "true");
    this.validateForm();

    // checks if afterOnChange function exists
    this.onChange && this.onChange(event);
  };

  formattedReservationNotes = (notes) => {
    let retVal = "";
    if (notes !== "") {
      const { accepted, completed, canceled } = JSON.parse(notes);
      if (isEmpty(accepted) && isEmpty(completed) && isEmpty(canceled)) {
        return <div>{retVal}</div>;
      } else {
        [accepted, completed, canceled].map((value) => {
          if (value && value !== "") {
            return (retVal += `${value}. `);
          }
          return retVal;
        });
        return (
          <div>
            <b>Notes: </b>
            {capitalizeFirstLetter(retVal)}
          </div>
        );
      }
    } else {
      return <div>retVal</div>;
    }
  };

  render() {
    const {
      show,
      onHide,
      reservation = {},
      isSubmitting,
      clickAction,
      user,
      isCalendarView,
    } = this.props;
    const {
      reservation_time,
      guest_customer,
      store_reservation,
      guest_count,
      reservation_number,
      payment = {},
      notes,
      reservation_status,
      remarks,
    } = reservation;
    const { amount = 0 } = payment;

    const reservation_customer = isEmpty(guest_customer)
      ? ""
      : `${guest_customer.first_name} ${guest_customer.last_name}`;

    const customer_phoneNumber = isEmpty(guest_customer)
      ? ""
      : phoneNumberPrefix(guest_customer.contact_number);

    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    const ALLOWED_ROLES = ["store_admin", "console_user", "demo_user"];

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Body>
          <PerfectScrollbar>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              <i aria-hidden="true" className="ki ki-close"></i>
            </button>
            <div className="d-flex flex-row justify-content-space-between mv-1rem text-uppercase font-weight-bold font-reservation-section-header p-2">
              <div>#{reservation_number}</div>
              <div>
                {store_reservation.reservation_type === "table"
                  ? "Reservation"
                  : "Event"}{" "}
                details
              </div>
              <div>
                <DisplayTime order_time={reservation_time} />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-space-between mv-1rem text-uppercase font-weight-bold font-reservation-section-header">
              <div style={{ flexBasis: 270, color: "red" }}>
                <div>
                  {store_reservation && store_reservation.reservation_name}
                </div>
              </div>
              <div>
                <div>guest count: {guest_count}</div>
              </div>
            </div>
            <Separator solid />
            <Section
              header={
                <>
                  <div className="font-weight-bold text-capitalize">
                    Guest details
                  </div>
                </>
              }
            />
            <div>
              <div className="item d-flex align-items-center mv-1rem">
                <div className="item-text-wrapper d-flex flex-column flex-grow-1">
                  <div className="font-pickup-sm-1 font-weight-bold text-capitalize">
                    {reservation_customer}
                  </div>
                </div>
                <div className="item-actions-wrapper d-flex align-items-center">
                  <span className="font-pickup-sm-1 font-weight-bold">
                    {customer_phoneNumber}
                  </span>
                </div>
              </div>
              {!isEmpty(remarks) && (
                <div className="item d-flex align-items-center mv-1rem">
                  <div className="item-text-wrapper d-flex flex-column flex-grow-1">
                    <div className="font-pickup-sm-1 font-weight-bold text-capitalize">
                      Remarks: {remarks}
                    </div>
                  </div>
                </div>
              )}
              {!isEmpty(notes) && (
                <div className="item d-flex align-items-center mv-1rem">
                  <div className="item-text-wrapper d-flex flex-column flex-grow-1">
                    <div className="font-pickup-sm-1">
                      {this.formattedReservationNotes(notes)}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Separator solid />
            <div className="d-flex flex-wrap align-items-center mv-1rem mb-0">
              <DFlex className="flex-column flex-grow-1">
                <span className="font-pickup-sm-2 text-uppercase font-weight-bold">
                  total amount
                </span>
              </DFlex>
              <DFlex className="align-items-center">
                <span className="font-pickup-sm-2 text-uppercase font-weight-bold">
                  {(store_reservation && toPesoAmount(amount)) || 0}
                </span>
              </DFlex>
            </div>
            {clickAction &&
              reservation_status === "new" &&
              ALLOWED_ROLES.includes(user.iam_role) && (
                <div className="item d-flex flex-wrap item mv-1rem mb-0">
                  <div className="item-text-wrapper d-flex flex-column flex-grow-1 mb-0">
                    <Form onSubmit={null}>
                      <FormItem
                        style={{ marginBottom: 0 }}
                        label={`Notes: (optional)`}
                        name={"notes"}
                        inputProps={{
                          name: "notes",
                          value: this.state.values["notes"],
                          rows: 3,
                        }}
                        inputActions={inputActions}
                        actions={actions}
                        type={"textarea"}
                        normal
                        showRequired={false}
                      />
                    </Form>
                  </div>
                </div>
              )}
          </PerfectScrollbar>
        </Modal.Body>
        {(clickAction || isCalendarView) &&
          reservation.reservation_status !== "canceled" &&
          ALLOWED_ROLES.includes(user.iam_role) && (
            <Modal.Footer>
              {clickAction && (
                <Button
                  type="submit"
                  variant={ACTION_BTN_COLOR[clickAction]}
                  className={GetSubmitClassList(
                    isSubmitting,
                    "btn-block text-uppercase font-weight-bold",
                  )}
                  disabled={isSubmitting ? "disabled" : null}
                  onClick={() => {
                    const { values } = this.state;
                    this.props.onSubmit(clickAction, values.notes);
                  }}
                >
                  {clickAction === "accepted"
                    ? "Accept"
                    : clickAction === "completed"
                    ? "Complete"
                    : "Cancel"}
                </Button>
              )}
              {isCalendarView && (
                <Button
                  variant={ACTION_BTN_COLOR.canceled}
                  data-id={reservation._id}
                  data-value={"canceled"}
                  className={GetSubmitClassList(
                    isSubmitting,
                    "btn-block text-uppercase font-weight-bold",
                  )}
                  disabled={isSubmitting ? "disabled" : null}
                  onClick={this.props.onCancel}
                >
                  Cancel
                </Button>
              )}
            </Modal.Footer>
          )}
      </Modal>
    );
  }
}
