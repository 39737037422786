import React, { useState } from "react";

import qs from "qs";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Link, useParams } from "react-router-dom";
import { Dropdown, Row, Col } from "react-bootstrap";
import Datatable from "react-data-table-component";

import { toPesoAmount } from "../../../helpers";
import useFetch from "../../../hooks/useFetch";
import TitleHelmet from "../../../partials/TitleHelmet";
import { HeaderTextWithActions } from "../../../components/Base";
import { DB_DATE_FORMAT, DATERANGE_LIST } from "../../../constants";
import PacmanWrapper from "../../../components/dashboard/PacmanWrapper";

const BrandSales = (props) => {
  const { duration = "day", date = moment().format(DB_DATE_FORMAT) } = qs.parse(
    props.location.search,
    { ignoreQueryPrefix: true },
  );
  const [params, setParams] = useState({ dateRange: duration, date });

  const title = "Brand Sales";

  function dropdownText(dateRange, date) {
    const isCurrent = moment(moment().format(DB_DATE_FORMAT)).isSame(
      date,
      dateRange,
    );

    const prefix = isCurrent ? "This" : "Last";

    // if(dateRange=== 'week') return `${prefix} Week`
    if (dateRange === "week") {
      if (
        moment(moment().add(1, "weeks").format(DB_DATE_FORMAT)).isSame(date)
      ) {
        return "Next Week";
      }

      return `${prefix} Week`;
    }
    if (dateRange === "month") return `${prefix} Month`;
    if (dateRange === "year") return `${prefix} Year`;
    if (dateRange === "allTime") return "All-time";
    return `${isCurrent ? "Today" : "Yesterday"}`;
  }

  function handleDateRangeClick(e, date) {
    let statCardDataRange;
    const dropdownText = e.currentTarget.textContent || "day";

    if (
      dropdownText === "This Week" ||
      dropdownText === "Last Week" ||
      dropdownText === "Next Week"
    ) {
      statCardDataRange = "week";
    } else if (dropdownText === "This Month" || dropdownText === "Last Month") {
      statCardDataRange = "month";
    } else if (dropdownText === "This Year" || dropdownText === "Last Year") {
      statCardDataRange = "year";
    } else if (dropdownText === "All-time") {
      statCardDataRange = "allTime";
    } else {
      statCardDataRange = "day";
    }

    setParams({ date, dateRange: statCardDataRange });
  }

  return (
    <>
      <TitleHelmet title={title} />

      <HeaderTextWithActions className="mb-0 mb-md-4" title={title} />

      <BrandSalesTable
        params={params}
        dropdownText={dropdownText}
        onDateRangeClick={handleDateRangeClick}
      />
    </>
  );
};

const BrandSalesTable = (props) => {
  const { params = {}, dropdownText, onDateRangeClick } = props;
  const { date = moment().format(DB_DATE_FORMAT), dateRange = "day" } = params;

  const reqObj = {
    url: "/analytics/reports/brand",
    method: "post",
    body: params,
  };

  const dependencies = [date, dateRange];

  const { data, isLoading } = useFetch(reqObj, {}, dependencies);

  const [searchText, setSearchText] = useState("");
  const filteredItems =
    searchText !== ""
      ? data.filter((item) => {
          return (
            item.brand.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        })
      : data;

  const filename = `brand_sales-${date}-${dateRange}`;
  const csvHeaders = [
    { label: "Client", key: "client" },
    { label: "Brand", key: "brand" },
    { label: "Sales", key: "sales" },
  ];
  return (
    <>
      {isLoading || data === null ? (
        <PacmanWrapper removeBackgroundColor />
      ) : (
        <>
          <Datatable
            columns={[
              {
                name: "Client",
                sortable: true,
                selector: (row) => {
                  return row.client;
                },
                style: "font-weight-bold",
              },
              {
                name: "Brand",
                sortable: true,
                selector: (row) => {
                  return row.brand;
                },
                style: "font-weight-bold",
                format: (row) => {
                  return (
                    <Link
                      to={`/admin/reports?filter=Total%20Sales&duration=${dateRange}&date=${date}&view=${row._id}&attr=brand`}
                    >
                      {row.brand}
                    </Link>
                  );
                },
              },
              {
                name: "Sales",
                sortable: true,
                selector: (row) => {
                  return row.sales;
                },
                format: (row) => toPesoAmount(row.sales),
              },
            ]}
            // data={data}
            data={filteredItems}
            pagination
            noHeader
            highlightOnHover
            progressPending={isLoading}
            subHeader
            subHeaderWrap={false}
            subHeaderComponent={
              <>
                <Row className="w-100">
                  <Col xs={12} md={8} className="mb-2 mb-md-0">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="flaticon-search"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                    </div>
                  </Col>

                  <Col
                    xs={12}
                    md={4}
                    className="d-flex justify-content-end justify-content-md-center align-items-center"
                  >
                    <CSVLink
                      filename={filename}
                      data={data}
                      headers={csvHeaders}
                    >
                      <span className="d-md-inline-block position-relative">
                        <span className="pr-8">
                          Export to CSV
                          <span className="ml-2">
                            <i className="fas fa-file-export text-primary" />
                          </span>
                        </span>
                      </span>
                    </CSVLink>

                    <Dropdown className="d-flex justify-content-end align-items-center">
                      <Dropdown.Toggle
                        variant="primary"
                        id="dropdown-daterange"
                      >
                        {dropdownText(params.dateRange, params.date)}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {DATERANGE_LIST.map((item) => (
                          <Dropdown.Item
                            onClick={(e) => onDateRangeClick(e, item.date)}
                            key={item.name}
                          >
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>

                  {/* <Col xs={4} md={2}>
                                <Dropdown className="d-flex justify-content-end mb-4">
                                    <Dropdown.Toggle variant="primary" id="dropdown-daterange">
                                        {dropdownText(params.dateRange, params.date)}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {DATERANGE_LIST.map(item => (
                                            <Dropdown.Item 
                                                onClick={(e)=> onDateRangeClick(e, item.date)}
                                                key= {item.name}
                                            >{item.name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col> */}
                </Row>
              </>
            }
          />
        </>
      )}
    </>
  );
};

export default BrandSales;
