import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import core from "../../../vendors/core-api/";
import { removeStorage, setStorage } from "../../../../_metronic/_helpers";
import { removeToken } from "../../../modules/Firebase/init-fcm.js";

import qs from "qs";

class Logout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checking: true,
      webview: "",
    };
  }
  componentDidMount() {
    const query = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      }),
      { multiple = "", webview = "" } = query;

    setStorage("PICKUP_WEBVIEW", webview);
    setStorage("PICKUP_MULTIPLE", multiple);

    core().resetAuthToken();

    removeStorage("PRK");
    removeStorage("PICKUP_BRAND");
    removeStorage("PICKUP_STORE");
    removeStorage("PICKUP_ADDED_ITEM");
    // removeToken()

    this.props.logout();
  }
  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? (
      <LayoutSplashScreen />
    ) : (
      <Redirect to={"/auth/login"} />
    );
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions,
)(Logout);
