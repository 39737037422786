import React, { Component } from "react";
import moment from "moment";
import { Modal, Dropdown } from "react-bootstrap";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import TitleHelmet from "../../partials/TitleHelmet";
import { HeaderTextWithActions, SVGIcon } from "../../components/Base";
import Row1 from "../../components/dashboard/Row1";
import Row2 from "../../components/dashboard/Row2";
import Row3 from "../../components/dashboard/Row3";
import helpertexts from "../../helpertexts";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PRIMARY_COLOR, DB_DATE_FORMAT, DATERANGE_LIST } from "../../constants";
import {
  filterObj,
  stackedBarGraphProps,
  statPieGraphProps,
} from "../../helpers";
import CategoryForm from "../../partials/forms/CategoryForm";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";

const MySwal = withReactContent(Swal);

export default function Page() {
  const { store_id = "", brand_url = "", mode = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  return (
    <MainComponent
      id={store_id}
      brand_url={brand_url}
      user={user}
      webview={mode === "webview"}
    />
  );
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: true,
      redirectTo: "",
      title: "Store",
      menu: [],
      showAddItemModal: false,
      showEditItemModal: false,
      selectedItem: {},
      showEditCategoryModal: false,
      selectedCategory: "",

      statCardQuery: "day",
    };

    this.api = core("pu").get();
    this.fileapi = core("puf").get();

    this.data_url = "/store/" + this.props.id + "/stats";

    this.getHelperText = this.getHelperText.bind(this);
    this.getByItemId = this.getByItemId.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitItem = this.onSubmitItem.bind(this);
    this.onSubmitCategory = this.onSubmitCategory.bind(this);

    this.uploadItemPhoto = this.uploadItemPhoto.bind(this);
    this.onClickItemDelete = this.onClickItemDelete.bind(this);

    this.groupMenu = this.groupMenu.bind(this);

    this.handleDateRangeClick.bind(this);
  }

  async getData() {
    this._isMounted && this.setState({ data_status: "fetching" });
    let date = this.state.date || moment().format(DB_DATE_FORMAT);
    let statCardRange = this.state.statCardQuery || "day";

    const getStats = (
      rowNo,
      statCardRange = "day",
      date = moment().format(DB_DATE_FORMAT),
    ) => {
      return new Promise((resolve, reject) => {
        // this.api.get(`/brand/u/${this.props.brand_url}/stats/${rowNo}?stat_cards=${statCardRange}&date=${date}`)
        this.api
          .get(
            `/store/${this.props.id}/stats/${rowNo}?stat_cards=${statCardRange}&date=${date}`,
          )
          .then((data) => resolve(data.data))
          .catch((err) => reject(err));
      });
    };

    // const brandInfo = await getBrandInfo();

    try {
      let [header, row1] = await Promise.all([
        getStats("header", statCardRange, date),
        getStats("row1", statCardRange, date),
      ]);

      this._isMounted &&
        this.setState({
          ...this.state,
          data: {
            ...this.props.user.store,
            brand: this.props.user.brand,
            stats: [header, row1],
            title: header.store.name,
          },
          title: header.store.name,
          data_status: "fetched",
          order_fetch_status: "fetched",
        });

      this.handleOnDataFetched();
      if (this.use_display_data) {
        this.setDisplayData();
      }
    } catch (error) {
      this._isMounted && this.setState({ error: error, showSwalError: true });
      this.handleOnDataFetchError();
    }
  }

  handleOnDataFetched = () => {
    const { name = "" } = this.state.data;

    this.setState({ title: name });
  };

  refreshData = (data) => {
    // const { name = "" } = data;
    this.setState({ data });
    // this.setState({ data, title: name });
  };

  groupMenu = ({ categories = [], menu = [] }) => {
    let display = [];

    for (var i = 0; i < categories.length; i++) {
      const category = categories[i].category_name;
      display.push({ type: "category", category });

      let items = [];

      for (var j = 0; j < menu.length; j++) {
        if (menu[j].category === category) {
          menu[j].type = "item";
          items.push(menu[j]);
        }
      }

      if (items.length > 0) display = display.concat(items);
      else
        display.push({
          type: "message",
          message: "No items under this category.",
        });
    }

    return display;
  };

  getHelperText = (field) => {
    return helpertexts["getting-started"][field];
  };

  getByItemId = (id) => {
    const { menu = [] } = this.state.data;
    const filtered = menu.filter((item) => {
      return item.item_id._id === id;
    });
    return filtered.length > 0 ? filtered[0] : {};
  };

  onSubmit = (values, setSubmitting) => {
    const { _id = "" } = this.state.data;

    this.api
      .put({
        url: "/store/" + _id,
        data: values,
      })
      .then(({ data }) => {
        setSubmitting(false);

        this.getData();

        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully saved your changes.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          timer: 1000,
        });
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  onSubmitItem = (values, setSubmitting, afterSubmit) => {
    const { _id = "", brand = "" } = this.state.data;

    values.brand = brand;
    values.store_id = _id;

    this.api
      .post({
        url: "/item",
        data: filterObj(values, ["_id"]),
      })
      .then(({ data }) => {
        setSubmitting(false);

        let { items = [] } = this.state;
        items.push(data);

        afterSubmit();

        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully added an item!",
          timer: 1000,
        });

        this.setState({
          showAddItemModal: false,
        });

        this.getData();
      })
      .catch(() => {
        setSubmitting(false);
        this.handleError();
      });
  };

  onSubmitEditItem = (values, setSubmitting, afterSubmit) => {
    const { _id = "" } = values;

    values.store_id = this.state.data._id;

    this.api
      .put({
        url: "/item/" + _id,
        data: filterObj(values, ["_id"]),
      })
      .then(() => {
        setSubmitting(false);
        afterSubmit();

        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully updated an item!",
          timer: 1000,
        });

        this.setState({
          showAddItemModal: false,
        });

        this.getData();
      })
      .catch(() => {
        setSubmitting(false);
        this.handleError();
      });
  };

  uploadItemPhoto = (file, setFilePath, setSubmitting) => {
    const formData = new FormData();
    formData.append("file", file);

    this.fileapi
      .post({
        url: "/util/upload-to-aws/items",
        data: formData,
      })
      .then(({ data }) => {
        const { filePath = "" } = data;
        setFilePath(filePath);
      })
      .catch(() => {
        this.handleError();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  onClickItemDelete = () => {
    const { selectedItem = {}, data = {} } = this.state;

    MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to remove ${selectedItem.item_id.name} from this menu.`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.api
          .post({
            url: "/store/" + data._id + "/delete-menu-item",
            data: {
              _id: selectedItem.item_id._id,
            },
          })
          .then(({ data }) => {
            if (data.result === "ok") {
              this.getData();
              this.setState({ showEditItemModal: false });

              MySwal.fire({
                icon: "success",
                title: "Success!",
                text: `${selectedItem.item_id.name} successfully removed from this menu.`,
                showCancelButton: false,
                timer: 1000,
              }).then(() => {
                this.setState({ selectedItem: {} });
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  onSubmitCategory = (values, setSubmitting) => {
    const { category_name = "", old_category_name = "" } = values;

    if (category_name !== old_category_name) {
      let { _id = "" } = this.state.data;

      this.api
        .put({
          url: "/store/" + _id + "/update-category-name",
          data: values,
        })
        .then(() => {
          setSubmitting(false);

          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully updated a category!",
            timer: 1000,
          });

          this.setState({ showEditCategoryModal: false });

          this.getData();
        });
    }
  };

  handleDateRangeClick = async (e, date) => {
    let statCardDataRange;
    const dropdownText = e.currentTarget.textContent || "day";

    if (
      dropdownText === "This Week" ||
      dropdownText === "Last Week" ||
      dropdownText === "Next Week"
    ) {
      statCardDataRange = "week";
    } else if (dropdownText === "This Month" || dropdownText === "Last Month") {
      statCardDataRange = "month";
    } else if (dropdownText === "This Year" || dropdownText === "Last Year") {
      statCardDataRange = "year";
    } else if (dropdownText === "All-time") {
      statCardDataRange = "allTime";
    } else {
      statCardDataRange = "day";
    }

    this.setState({
      statCardQuery: statCardDataRange,
      data_status: "fetching",
      date,
    });

    setTimeout(() => {
      this.getData();
    }, 500);
  };

  render() {
    const {
        data_status = "",
        data = {},
        date = moment().format(DB_DATE_FORMAT),
      } = this.state,
      isLoading = data_status !== "fetched",
      { stats = [] } = data;
    const [header, row1] = stats;
    const { brand_url = "", id = "" } = this.props;

    function dropdownText(dateRange, date) {
      const isCurrent = moment(moment().format(DB_DATE_FORMAT)).isSame(
        date,
        dateRange,
      );
      const prefix = isCurrent ? "This" : "Last";

      // if(dateRange=== 'week') return `${prefix} Week`
      if (dateRange === "week") {
        if (
          moment(moment().add(1, "weeks").format(DB_DATE_FORMAT)).isSame(date)
        ) {
          return "Next Week";
        }

        return `${prefix} Week`;
      }
      if (dateRange === "month") return `${prefix} Month`;
      if (dateRange === "year") return `${prefix} Year`;
      if (dateRange === "allTime") return "All-time";
      // return 'Today'
      return `${isCurrent ? "Today" : "Yesterday"}`;
    }

    const { statCardQuery } = this.state;

    return (
      <>
        <TitleHelmet title={this.state.title || "Store"} />

        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          <>
            {/* Edit Category Modal */}
            <CategoryModal
              show={this.state.showEditCategoryModal}
              onHide={() => {
                this.setState({ showEditCategoryModal: false });
              }}
              title="Edit Category"
              onSubmit={this.onSubmitCategory}
              onClickDeleteBtn={this.onClickCategoryDelete}
              category_name={this.state.selectedCategory}
              // showDeleteButton={true}
            />

            <div className="d-flex">
              <HeaderTextWithActions
                className="mb-8"
                title={header.store.name}
                subtitle={
                  <>
                    <SVGIcon icon="Map/Marker1" title={"Location"} />{" "}
                    {header.store.location} <span className="mr-2"></span>
                    <SVGIcon icon="Devices/Phone" title={"Contact Number"} />
                    {header.store.contact_number}
                  </>
                }
              />

              <Dropdown className="ml-auto align-self-end rounded-top p-4 pb-0 bg-white">
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  {dropdownText(this.state.statCardQuery, date)}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {DATERANGE_LIST.map((item) => (
                    <Dropdown.Item
                      key={item.name}
                      onClick={(e) => this.handleDateRangeClick(e, item.date)}
                    >
                      {item.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="container bg-white py-4 px4 rounded rounded-right-0 rounded-bottom">
              <Row1
                brands={this.props.user.brands}
                date={date}
                row1={row1}
                statCardQuery={statCardQuery}
                statLink={`${
                  this.props.webview ? "/webview" : ""
                }/brands/${brand_url}/stores/${id}/reports`}
                view={id}
                attr="store"
                role={this.props.user.role}
              />

              <Row2
                params={{
                  date,
                  dateRange: statCardQuery,
                  id: id,
                  attr: "store",
                }}
                header={`Sold Items by Category`}
              />
            </div>

            <Row3
              statCardQuery={statCardQuery}
              params={{
                date,
                dateRange: statCardQuery,
                id: id,
                attr: "store",
              }}
              date={date}
              dropdownText={dropdownText}
              selectedBrand={{ id, attr: "store" }}
            />
          </>
        )}
      </>
    );
  }
}

class CategoryModal extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} centered>
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CategoryForm {...this.props} />
        </Modal.Body>
      </Modal>
    );
  }
}
