import React from "react";
import ProSourceForm from "../components/ProSourceForm";
import { Modal, Form, Button } from "react-bootstrap";
import clipboardCopy from "clipboard-copy";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DANGER_COLOR, PRIMARY_COLOR } from "../constants";

const MySwal = withReactContent(Swal);
class QRDetailsModal extends ProSourceForm {
  constructor(props) {
    super(props);
    this.qrCodeImage = React.createRef();
  }

  handleDownloadImage = async (src, tbleNumber) => {
    if (window.ReactNativeWebView) {
      // this will trigger if from mobile webview and will not proceed on the bottom part of the code
      const data = JSON.stringify({
        type: "dl_table_qr",
        url: src,
        fileName: `table_${tbleNumber}_qr_code${new Date().getTime()}.png`,
      });
      window.ReactNativeWebView.postMessage(data);
      return MySwal.fire({
        icon: "success",
        title: "Success!",
        text: "Download Successful.",
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Ok",
        timer: 1000,
      });
    }

    // console.log("handleDownloadImage src: ", src)
    // let img = await fetch(src)
    let img = await fetch(src, {
      cache: "no-cache",
      referrerPolicy: "no-referrer",
    });
    // console.log("qrdetailsmodal handleDownloadImage img: ", img)
    let imgBlob = await img.blob();
    // console.log("qrdetailsmodal handleDownloadImage imgBlob: ", imgBlob)
    if (imgBlob.type === "image/png") {
      try {
        const url = window.URL.createObjectURL(imgBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "image.png"); //or any other extension
        document.body.appendChild(link);
        link.click();
        return MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "Download Successful.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          timer: 1000,
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      return MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `Unable to Download the QR Image.`,
        confirmButtonColor: DANGER_COLOR,
        confirmButtonText: "Ok",
        // timer: 1000
      });
    }
  };

  handleOnClickCopyImageToClipboard = async (src) => {
    // console.log("handleOnClickCopyImageToClipboard src: ", src)
    // const img = await fetch(src)
    let img = await fetch(src, {
      cache: "no-cache",
      referrerPolicy: "no-referrer",
    });
    // console.log("qrdetailsmodal handleOnClickCopyImageToClipboard img: ", img)
    const imgBlob = await img.blob();
    // console.log("qrdetailsmodal handleOnClickCopyImageToClipboard imgBlob: ", imgBlob)
    if (imgBlob.type === "image/png") {
      try {
        await navigator.clipboard.write([
          new window.ClipboardItem({
            "image/png": imgBlob, // change image type accordingly
          }),
        ]);
        // console.log("copied to clipboard")
        return MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "Copied QR Image to clipboard successfully.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          timer: 1000,
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      return MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `Unable to Copy the QR Image.`,
        confirmButtonColor: DANGER_COLOR,
        confirmButtonText: "Ok",
        // timer: 1000
      });
    }
  };

  handleOnClickCopyToClipboard = (url) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url).then(() => {
        return MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "Copied URL to clipboard successfully.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          timer: 1000,
        });
      });
    }
  };

  render() {
    const { show = false, onHide, data } = this.props;
    // console.log('delivery address modal state: ', this.state)
    // console.log('delivery address modal props: ', this.props)
    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>Table QR Code Details</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form>
          <Modal.Body>
            {
              <div className="d-flex flex-column align-items-center">
                <div className="d-flex flex-column align-items-center my-4">
                  <div className="h1 font-weight-bolder">
                    {`Table Number ${data.tableNumber}`}
                  </div>
                  <img
                    // id="qrImage"
                    ref={this.qrCodeImage}
                    src={data.qrCode}
                    alt="image"
                    className="w-100"
                  />
                  <span className="text-break ml-2">{data.url}</span>
                  {/* <a href={data.qrCode} download="qrcode.png"> download </a> */}
                </div>
                <div className="d-flex flex-row">
                  <Button
                    className="btn m-2"
                    onClick={() => {
                      this.handleOnClickCopyToClipboard(data.url);
                    }}
                  >
                    <div
                      className="d-flex flex-row align-items-center"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <i className="far fa-share-square"></i>
                      <div className="d-flex">COPY URL TO CLIPBOARD</div>
                    </div>
                  </Button>
                  <Button
                    className="btn m-2"
                    onClick={() => {
                      this.handleOnClickCopyImageToClipboard(data.qrCode);
                    }}
                  >
                    <div
                      className="d-flex flex-row align-items-center"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <i className="far fa-share-square"></i>
                      <div className="d-flex">COPY QR TO CLIPBOARD</div>
                    </div>
                  </Button>
                  <Button
                    className="btn m-2"
                    onClick={() =>
                      this.handleDownloadImage(data.qrCode, data.tableNumber)
                    }
                  >
                    <div
                      className="d-flex flex-row align-items-center"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <i className="fas fa-download"></i>
                      <div className="d-flex">DOWNLOAD QR CODE </div>
                    </div>
                  </Button>
                </div>
              </div>
            }
          </Modal.Body>
        </Form>
      </Modal>
    );
  }
}

export default QRDetailsModal;
