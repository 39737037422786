import React from "react";

import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";

import EmptyData from "../EmptyData";
import PacmanWrapper from "./PacmanWrapper";
import useFetch from "../../hooks/useFetch";
import { GRAPH_COLORS } from "../../constants";

const CustomerMix = (props) => {
  const { params = {}, data_url = "" } = props;

  const reqObj = {
    url: data_url,
    method: "post",
    body: params,
  };

  const { data, isLoading } = useFetch(reqObj, {});

  const pieLabels = data ? Object.keys(data) : [];
  const pieSeries = data ? Object.values(data) : [];

  const options = {
    chart: {
      // MAKE 'id' FIELD A PROP WHEN ANOTHER GRAPH COMPONENT USES THIS IN THE FUTURE
      id: "statPie",
      title: props.title,
      type: "donut",
      height: 300,
      events: {
        legendClick: function (chartContext, seriesIndex, config) {
          const { series } = config.globals;

          let item = false;
          if (config.globals.deleteItems) {
            item = config.globals.deleteItems.find(
              (el) => el.index === seriesIndex,
            );
          }

          if (item) {
            const { series } = item;
            config.globals.series.splice(seriesIndex, 1, series);
            config.globals.deleteItems = config.globals.deleteItems.filter(
              (delItem) => delItem.index !== seriesIndex,
            );
            ApexCharts.exec("statPie", "updateSeries", config.globals.series);
          } else {
            let deleteItem = series.splice(seriesIndex, 1, 0);
            deleteItem = { index: seriesIndex, series: deleteItem[0] };
            config.globals.deleteItems = config.globals.deleteItems
              ? [...config.globals.deleteItems, deleteItem]
              : [deleteItem];

            ApexCharts.exec("statPie", "updateSeries", config.globals.series);
          }

          const container = document.querySelector(".item-sold-by-category");
          const selectedLegend = container.querySelectorAll(
            ".apexcharts-legend-series",
          );

          config.globals.deleteItems.forEach((el) => {
            selectedLegend[el.index].classList.toggle("opacity-35");
          });
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value, { config, globals, seriesIndex }) {
          return value;
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: props.labels,
    colors: GRAPH_COLORS,
    plotOptions: {
      pie: {
        donut: {
          size: "90%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              offsetY: -5,
            },
            value: {
              show: true,
              fontSize: "16px",
              color: undefined,
              offsetY: +5,
              formatter: function (val) {
                const total = props.series.reduce(
                  (total, item) => total + item,
                  0,
                );
                const displayValue = ((val / total) * 100).toFixed(2) || 0;

                return `${displayValue}%`;
              },
            },
            total: {
              show: true,
              label: "Total",
              color: "#ffa500",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
      onItemClick: {
        toggleDataSeries: false,
      },
    },
  };

  const customOptions = {
    colors: ["#50b585", "#795ecc"],
    tooltip: {
      y: {
        formatter: function (value, { config, globals, seriesIndex }) {
          const total = config.series.reduce((total, cur) => total + cur, 0);

          return `${value} orders, (${
            ((value / total) * 100).toFixed(1) || 0
          }%)`;
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "90%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              offsetY: -5,
            },
            value: {
              show: true,
              fontSize: "16px",
              color: undefined,
              offsetY: +5,
              // formatter: function (val) {
              //   const total = props.series.reduce((total, item) => total+ item, 0)
              //   return ((val/total).toFixed(2)*100 || 0)+'%'
              // }
            },
            total: {
              show: true,
              label: "Total Orders",
              color: "#ffa500",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
  };

  return (
    <div className="h-100">
      {isLoading || data === null ? (
        <PacmanWrapper />
      ) : (
        <div
          className={`card card-custom bg-white card card-custom h-100 px-0 position-relative overflow-hidden`}
        >
          <h4 className="card-spacer">Customer Mix</h4>
          <div className="analytics-side-percent d-flex justify-content-between position-absolute w-100 px-8">
            <span className="font-weight-bold text-primary">
              {(
                (pieSeries[0] / pieSeries.reduce((a, b) => a + b, 0)) * 100 || 0
              ).toFixed(2)}
              %
            </span>
            <span className="font-weight-bold text-info">
              {(
                (pieSeries[1] / pieSeries.reduce((a, b) => a + b, 0)) * 100 || 0
              ).toFixed(2)}
              %
            </span>
          </div>

          {/* Body */}
          <div className="card-body align-self-stretch">
            {pieSeries.length <= 0 ? (
              <EmptyData className="text-center mt-12" />
            ) : (
              <Chart
                options={{ ...options, ...customOptions, labels: pieLabels }}
                series={pieSeries}
                height={250}
                type="donut"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerMix;
