import React from "react";
import { Modal } from "react-bootstrap";
// import { FormItem } from "../components/Base";
import ProSourceForm from "../components/ProSourceForm";
// import { MINUTES_ALLOW_ORDER_BEFORE_OPENING_DEFAULT } from "../constants";
import { GetDataFromEvent } from "../helpers";
import { EditStoreHoursTable } from "./StoreSetup";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DB_HOURS_FORMAT, PRIMARY_COLOR } from "../constants";
import moment from "moment";

const MySwal = withReactContent(Swal);

export default class SetReservationHoursModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { data = {} } = this.props,
      { reservation_hours = [] } = data;

    this.state = {
      values: {
        ...data,
        reservation_hours: reservation_hours.map((store_hr, index) => {
          if (store_hr.hasOwnProperty("_id")) {
            store_hr._id =
              new Date().getTime().toString() +
              "_" +
              index +
              "_" +
              store_hr.day;
          }

          return store_hr;
        }),
      },
      errors: {},
      touched: {},
      // isSubmitting: false,
      renderTable: true,
    };

    this.handleOnChangeStoreHrs = this.handleOnChangeStoreHrs.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnChangeStoreHrs = (e) => {
    const name = GetDataFromEvent(e, "data-name"),
      index = GetDataFromEvent(e, "data-index"),
      value = e.target.value,
      checked = e.target.checked;
    let { values = {} } = this.state;

    values.reservation_hours = values.reservation_hours.map((sh) => {
      if (sh._id === index) {
        if (name === "isOpen") sh[name] = checked;
        else sh[name] = value;
      }

      return sh;
    });
    // console.log("change hours", values);

    this.setState({ values });
  };

  handleOnAddStoreHrs = (day) => {
    let { values = {} } = this.state,
      opening = "",
      closing = "";

    for (var i = 0; i < values.reservation_hours.length; i++) {
      if (day === values.reservation_hours[i].day) {
        opening = moment(values.reservation_hours[i].closing, DB_HOURS_FORMAT)
          .add("30", "minutes")
          .format(DB_HOURS_FORMAT);
        closing = "23:30";
      }
    }

    if (opening === closing || opening === "22:00" || opening === "00:00") {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `You can't add another store hour slot, kindly adjust the latest closing hour of this day to enable.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      let newItem = {};
      newItem.day = day;
      newItem.opening = opening;
      newItem.closing = closing;
      newItem.isOpen = false;
      newItem._id = new Date().getTime().toString(); // temporary
      values.reservation_hours.push(newItem);

      this.setState({ values, renderTable: false }, () => {
        this.setState({ renderTable: true });
      });
    }
  };

  handleOnDeleteStoreHrs = (id, day) => {
    // check first if there will be remaining slots for that day
    let { values = {} } = this.state;

    const store_schedule_day = values.reservation_hours.filter(
      (item) => item.day === day,
    );

    if (store_schedule_day.length === 1) {
      // means it currently has only one slot, and this can't be deleted
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `You can't remove this store hour slot! There should be at least one store hour slot per day.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      MySwal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: `You are about to remove this store hour slot`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          values.reservation_hours = values.reservation_hours.filter(
            (item) => item._id !== id,
          );

          this.setState({ values, renderTable: false }, () => {
            this.setState({ renderTable: true });
          });
        }
      });
    }
  };

  onSubmit = () => {
    let isSameOpeningClosing = false;
    const { values } = this.state;

    values.reservation_hours = values.reservation_hours.map((store_hr) => {
      if (!isSameOpeningClosing && store_hr.opening === store_hr.closing) {
        isSameOpeningClosing = true;
      }
      return store_hr;
    });

    if (!isSameOpeningClosing) {
      this.setState({ values });
    } else {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `You can't set the same opening and closing hours on the same day.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };

  handleOnSubmit = () => {
    this.onSubmit();
    this.props.onHide(this.state.values);
  };

  render() {
    const { show, onHide } = this.props,
      // { isSubmitting = false, values = {}, renderTable = true } = this.state;
      { values = {}, renderTable = true } = this.state;

    return (
      <Modal show={show} onHide={onHide} centered size="lg">
        <Modal.Header>
          <Modal.Title>Set Reservation Hours</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          {renderTable ? (
            <EditStoreHoursTable
              store_hours={values["reservation_hours"]}
              actions={{
                onChange: this.handleOnChangeStoreHrs,
              }}
              handleOnAddStoreHrs={this.handleOnAddStoreHrs}
              handleOnDeleteStoreHrs={this.handleOnDeleteStoreHrs}
            />
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className={`btn btn-primary font-weight-bold`}
            onClick={this.handleOnSubmit}
          >
            {"Save Changes"}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
