import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, useLocation, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
// import helpertexts from "../../helpertexts";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import { HeaderTextWithActions } from "../../components/Base";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Button, Card, Col, Row } from "react-bootstrap";
import PromoForm from "../../partials/promos/PromoForm";

export default function Page() {
  // const { id = "" } = useParams(), { user } = useSelector(state => state.auth);
  const { id = "" } = useParams();
  const { user } = useSelector((state) => state.auth);
  const params = useLocation();
  // console.log("params: ", params);
  return <MainComponent id={id} user={user} />;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Promo Code Details",
    };

    this.api = core("pu").get();
    this.data_url = `/promos/${this.props.id}`;

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = (values, setSubmitting) => {
    values.amount_off = parseInt(values.amount_off);
    values.percentage_off = parseInt(values.percentage_off);
    values.minimum_purchase = parseInt(values.minimum_purchase);
    this.api
      .post({ url: "/promos", data: values })
      .then(({ data }) => {
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  async componentDidMount() {
    this._isMounted = true;
    await this.getData();
  }

  componentDidUpdate() {
    // console.log("promodetails componentdidupdate state: ", this.state);
    // console.log("promodetails componentdidupdate props: ", this.props);
  }

  render() {
    const promoCode =
      this.state.data && this.state.data.promo.promo_code
        ? this.state.data.promo.promo_code
        : this.props.id;
    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} push />
        ) : (
          <></>
        )}

        <HeaderTextWithActions
          // title={this.state.title + `${promoCode ? ` - ${promoCode}` : ``}`}
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item
                text={"Promo Codes"}
                link={`${this.props.webview ? "/webview" : ""}/promos`}
              />
              <Breadcrumbs.Item text={this.state.title} active={"true"} />
            </Breadcrumbs>
          }
        />

        {this.state.data && this.state.data.promo ? (
          <>
            <PromoForm
              title={this.state.title + `${promoCode ? ` - ${promoCode}` : ``}`}
              onSubmit={this.onSubmit}
              api={this.api}
              promo={this.state.data.promo}
              mode={`view`}
              user={this.props.user}
            />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}
