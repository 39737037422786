import React from "react";
import { Card, Button } from "react-bootstrap";
import {
  OrderStatusActionButton2,
  Separator,
  OrderText,
  DisplayTime,
  ThirdPartyName,
  DeliveryInfo,
  DisplayPhone,
  DeliveryPartner,
  DeliveryButtons,
} from "../components/Base";
import {
  getStatusDisplay,
  isOrderReadOnly,
  computeTotalCartItems,
  pluralize,
} from "../helpers";
import { KTUtil } from "../../_metronic/_assets/js/components/util";

import { Link } from "react-router-dom";

function getDataValueFromUpdateBtn(status = "", order_type = "") {
  let value = status;

  if (status === "new") value = "accept";
  else if (status === "accepted") value = "ready";
  else if (status === "ready") value = status + "_" + order_type;
  return value;
}

export default class OrderCard extends React.Component {
  render() {
    const { order = {}, actions, display_buttons } = this.props;

    const {
      order_type = "",
      status = "",
      transaction_number = "",
      is_asap = false,
      guest_customer = {},
      curbside_pickup_details = {},
      order_time = "",
      order_details = [],
      order_queue = false,
      pickup_details = false,
      isMealPlan = false,
      meal_plan_type = "",
      meal_plan_index = "",
      table_number = "",
    } = order;
    const {
      first_name = "",
      last_name = "",
      contact_number = "",
    } = guest_customer;
    const {
      vehicle_make = "",
      vehicle_color = "",
      vehicle_plate = "",
    } = curbside_pickup_details;

    return (
      <Card className="mv-1rem" onClick={this.props.onClick}>
        <Card.Body>
          <div className="d-flex flex-row justify-content-space-between">
            <div className="d-flex">
              <Link to={this.props.linkTo} className="va-middle ">
                <OrderText
                  value={<>Order#{transaction_number}</>}
                  underline={true}
                />
              </Link>
              <span
                className="label label-inline va-middle text-uppercase font-weight-bolder ml-2 mb-2 mr-2"
                style={{ whiteSpace: "nowrap" }}
              >
                {" "}
                {pluralize(computeTotalCartItems(order_details), "item")}{" "}
              </span>
            </div>
            <div
              className={`text-${
                status === "new" ? "danger" : "purple"
              } text-uppercase font-weight-bolder font-pickup-sm-2`}
              style={{ textAlign: "right" }}
            >
              {getStatusDisplay(status, order_type, !is_asap)}
            </div>
          </div>
          <Separator solid />
          <div
            className="mv-1rem cursor-pointer"
            onClick={this.props.actions.handleOnClickUpdateBtn}
            data-id={order._id}
            data-value={getDataValueFromUpdateBtn(
              order.status,
              order.order_type,
            )}
          >
            <div className="d-flex justify-content-space-between">
              <OrderText
                label={<>Name:</>}
                value={
                  <>
                    {first_name} {last_name}
                  </>
                }
              />
            </div>
            <div className="order-card-details">
              <OrderText
                label={<>Phone:</>}
                value={
                  <DisplayPhone
                    pickupDetails={pickup_details}
                    mainPhone={contact_number}
                  />
                }
              />
              <OrderText
                label={<>Pickup:</>}
                value={<DisplayTime order_time={order_time} />}
              />
            </div>
            <ThirdPartyName
              orderType={order_type}
              pickupDetails={pickup_details}
              withPhone={true}
            />
            {order_type === "curbside_pickup" ? (
              <>
                <div className="d-flex justify-content-space-between">
                  <OrderText
                    label={<>Vehicle Make:</>}
                    value={<>{vehicle_make ? vehicle_make : "N/A"}</>}
                  />
                </div>
                <div className="order-card-details">
                  <OrderText
                    label={<>Color:</>}
                    value={<>{vehicle_color ? vehicle_color : "N/A"}</>}
                  />
                  <span>
                    <OrderText
                      label={<>Plate Number:</>}
                      value={<>{vehicle_plate ? vehicle_plate : "N/A"}</>}
                    />
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}

            {order_type === "dine_in" ? (
              <>
                <div className="d-flex justify-content-space-between">
                  <OrderText
                    label={<>Table Number: </>}
                    value={<>{table_number}</>}
                  />
                </div>
              </>
            ) : (
              <></>
            )}

            {order_type === "delivery" ? (
              <>
                <DeliveryInfo
                  orderType={order_type}
                  order={order}
                  execDeliveryRefresh={actions.execDeliveryRefresh}
                />
              </>
            ) : (
              <></>
            )}
          </div>

          <Separator solid />

          <div className="d-flex flex-row justify-content-space-between mv-1rem">
            <div>
              {order_type === "pickup" ? (
                <span className="label label-outline-primary label-inline label-xl va-middle text-uppercase font-weight-bolder mr-2 mb-2">
                  pickup
                </span>
              ) : order_type === "third_party_pickup" ? (
                <span className="label label-outline-primary label-inline label-xl va-middle text-uppercase font-weight-bolder mr-2 mb-2">
                  3rd party pickup
                </span>
              ) : order_type === "curbside_pickup" ? (
                <span className="label label-outline-warning label-inline label-xl va-middle text-uppercase font-weight-bolder mr-2 mb-2">
                  curbside pickup
                </span>
              ) : order_type === "dine_in" ? (
                <span className="label label-outline-danger label-inline label-xl va-middle text-uppercase font-weight-bolder mr-2 mb-2">
                  dine-in
                </span>
              ) : order_type === "delivery" ? (
                <span className="label label-outline-info label-inline label-xl va-middle text-uppercase font-weight-bolder mr-2 mb-2">
                  <DeliveryPartner
                    orderType={order_type}
                    order={order}
                    execDeliveryRefresh={actions.execDeliveryRefresh}
                  />
                </span>
              ) : (
                <></>
              )}

              {order_type === "delivery" && isMealPlan ? (
                <span
                  style={{ color: "#2196F3", borderColor: "#2196F3" }}
                  className="label label-outline-info label-inline label-xl va-middle text-uppercase font-weight-bolder mr-2 mb-2"
                >
                  Meal Plan
                  {` ${meal_plan_index + 1} `}
                  of
                  {` ${
                    meal_plan_type === "3DayMealPlan"
                      ? "3"
                      : ` ${
                          meal_plan_type === "5DayMealPlan"
                            ? "5"
                            : ` ${meal_plan_type}`
                        }`
                  }`}
                </span>
              ) : (
                <></>
              )}

              {order_type === "curbside_pickup" ? (
                <>
                  {curbside_pickup_details.hasArrived ? (
                    <span className="label label-outline-primary label-inline label-xl va-middle text-uppercase font-weight-bolder mr-2 mb-2">
                      arrived
                    </span>
                  ) : (
                    <span className="label label-outline-danger label-inline label-xl va-middle text-danger text-uppercase font-weight-bolder mr-2 mb-2">
                      not arrived
                    </span>
                  )}
                </>
              ) : (
                <></>
              )}

              {!is_asap ? (
                <span className="label label-outline-danger label-inline label-xl va-middle text-uppercase font-weight-bolder mr-2 mb-2">
                  pre-order
                </span>
              ) : (
                <></>
              )}
            </div>

            {this.props.user.iam_role !== "super_admin" && display_buttons ? (
              <>
                {order_queue ? (
                  <div className="va-middle">
                    {" "}
                    {isOrderReadOnly(order.status) ? (
                      <></>
                    ) : (
                      <div
                        className={`d-flex ${
                          KTUtil.isMobileDevice() ? "flex-column" : "flex-row"
                        }`}
                      >
                        {order_type === "delivery" && order.delivery && (
                          <DeliveryButtons order={order} actions={actions} />
                        )}
                        <OrderStatusActionButton2
                          btnBlock={false}
                          onClick={this.props.actions.handleOnClickUpdateBtn}
                          {...order}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="va-middle">
                    <div
                      className={`d-flex ${
                        KTUtil.isMobileDevice() ? "flex-column" : "flex-row"
                      }`}
                    >
                      {order_type === "delivery" && order.delivery && (
                        <DeliveryButtons order={order} actions={actions} />
                      )}
                      {order.status === "new" &&
                        this.props.user.iam_role === "console_user" && (
                          <Button
                            className={`font-weight-bolder text-uppercase`}
                            style={{
                              background: "#14C49B",
                              borderColor: "#14C49B",
                            }}
                            onClick={async (event) => {
                              event.stopPropagation();
                              await actions.moveToOrderQueue(order._id);
                            }}
                          >
                            {" "}
                            Move to Order Queue
                          </Button>
                        )}
                    </div>
                  </div>
                )}
              </>
            ) : null}
          </div>
          {/* </DisplayMediumAndDown> */}
        </Card.Body>
      </Card>
    );
  }
}
