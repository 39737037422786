import React from "react";
import { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import ProSourceForm from "../components/ProSourceForm";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Block, Label, KTLink } from "../components/Base";
import { FormItem } from "../components/Base";
import core from "../vendors/core-api";
import { isNil } from "lodash";
import { PRIMARY_COLOR } from "../constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getResizedImage, GetSubmitClassList } from "../helpers";
const MySwal = withReactContent(Swal);
const MAXIMUM_ALLOWED_TAGS = 3;
export default class MarketplaceDiv extends ProSourceForm {
  constructor(props) {
    super(props);

    const { data } = this.props,
      { _id } = data;

    this.state = {
      selected_clients: [],
      storeTagsList: [],
      applications: [],
      multiSelections: [],
      apiclients: [],
      errors: {},
      touched: {},
      redirectTo: "",
      activeEdit: false,
      isSubmitting: false,
      isEditing: !Boolean(this.props.data.apiclient_names.length),
    };

    this.api = core("pu").get();
    this.data_url = `/store/${_id}/marketplace-settings`;
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onApiClientSubmit = this.onApiClientSubmit.bind(this);
  }

  async getData() {
    if (this.data_url !== "") {
      const { iam_role = "" } = this.props;
      if (iam_role === "console_user") return;

      this._isMounted && this.setState({ data_status: "fetching" });
      this.api &&
        this.api
          .get(this.data_url)
          .then(({ data }) => {
            const { applications, storeTagsArr, apiclients } = data;
            this.setState({
              data_status: "fetched",
              storeTagsList: storeTagsArr,
              applications,
              apiclients,
            });

            if (this.use_display_data) {
              this.setDisplayData();
            }
          })
          .catch((error) => {
            this.setState({ errors: error, showSwalError: true });
          });
    } else {
      this.afterComponentDidMount();
    }
  }

  async componentDidMount() {
    await this.getData();
  }

  componentDidUpdate() {
    // console.log("state", this.state);
    // console.log("props", this.props);
  }

  onChangeSelections = (selected) => {
    this.setState({ selected_clients: selected });
  };

  onClickSubmit = () => {
    let { data = {} } = this.props;
    if (data.store_tags.length <= MAXIMUM_ALLOWED_TAGS) {
      this.props.onSubmit(data, (isSubmitting) => {
        this.setState({ isSubmitting });
      });
    } else {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `You've exceed the allowed category limit`,
      });
    }
  };

  onApiClientSubmit = async () => {
    try {
      const { selected_clients } = this.state;
      if (selected_clients.length === 0) {
        return;
      }
      const {
        data: { _id },
      } = this.props;

      await this.api.post({
        url: "/application/store-apiclient",
        data: {
          store: _id,
          selected_clients,
        },
      });

      MySwal.fire({
        icon: "success",
        title: "Success!",
        text: "You have successfully applied for the API Client",
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Confirm",
      }).then(() => {
        this.applyClickToggle(false);
        this.getData();
      });
    } catch (error) {
      if (!isNil(error.response.data.error)) {
        const message = error.response.data.error;
        MySwal.fire({
          icon: "error",
          title: "Oooops!",
          text: `${message}`,
        });
      } else {
        MySwal.fire({
          icon: "error",
          title: "Oooops!",
          text: "Something went wrong",
        });
      }
    }
  };

  cancelApplication = async (_id) => {
    const swal_result = await MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to cancel your application`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
    });

    if (swal_result.value) {
      try {
        await this.api.post({
          url: `/application/${_id}/merchant/cancel`,
        });
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "Application successfully cancelled",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Confirm",
        }).then(() => {
          this.getData();
        });
      } catch (err) {
        MySwal.fire({
          icon: "error",
          title: "Oooops!",
          text: "Something went wrong please try again",
        });
      }
    }
  };

  applyClickToggle = (value) => {
    this.setState({ activeEdit: value });
  };

  onClickEdit = () => {
    this.setState({ isEditing: true });
  };

  onClickCancelEdit = () => {
    this.setState({ isEditing: false });
  };

  blockInvalidChars = (e) => {
    const regex = /^[~@$^*()_+=[\]{}|\\/`'",.?!:; <>%&]*$/;
    if (regex.test(e.key)) {
      e.preventDefault();
    }
  };

  render() {
    const {
      title,
      multiple = true,
      data = {},
      data: { partner_logo = "", partner_banner = "" },
    } = this.props;

    const partner_logo_preview = getResizedImage(
      partner_logo,
      "preview",
      data.date_updated || data.date_created,
    );
    const partner_banner_preview = getResizedImage(
      partner_banner,
      "preview",
      data.date_updated || data.date_created,
    );
    const {
      isSubmitting = false,
      storeTagsList = [],
      isEditing,
      activeEdit,
      apiclients,
      applications,
    } = this.state;

    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
        onChangeSelections: this.onChangeSelections,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };
    return (
      <>
        <Card className="card-custom">
          <Card.Header>
            <Card.Title>{title}</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col className="col-7">
                <Block
                  content={
                    <>
                      <Label
                        text={
                          <>
                            <span className="va-middle text-capitalize">
                              API Clients
                              {data.apiclient_names.length ? (
                                <KTLink
                                  style={{ padding: 0 }}
                                  text={<>Add API Client</>}
                                  onClick={() => this.applyClickToggle(true)}
                                />
                              ) : (
                                <></>
                              )}
                            </span>
                          </>
                        }
                      />
                      {activeEdit ? (
                        <div>
                          <APIClientSelections
                            availableClients={apiclients}
                            multiple={multiple}
                            blockInvalidChars={this.blockInvalidChars}
                            actions
                            onChangeSelections={this.onChangeSelections}
                            {...this.props}
                          />
                          <br />
                          <Button
                            className={GetSubmitClassList(
                              isSubmitting,
                              "btn btn-primary font-weight-bold px-5 py-2 my-2 mx-2",
                            )}
                            disabled={this.state.selected_clients.length === 0}
                            onClick={this.onApiClientSubmit}
                          >
                            Apply
                          </Button>
                          <Button
                            variant="danger"
                            className={
                              "btn btn-secondary font-weight-bold px-5 py-2 my-2 mx-2"
                            }
                            onClick={() => this.applyClickToggle(false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      ) : (
                        <>
                          {data.apiclient_names.length ? (
                            <>
                              <div> {data.apiclient_names}</div>
                            </>
                          ) : (
                            <p>
                              No active API client.{" "}
                              <KTLink
                                style={{ padding: 0 }}
                                text={<>apply for an API Client?</>}
                                onClick={() => this.applyClickToggle(true)}
                              />{" "}
                            </p>
                          )}
                        </>
                      )}
                      <Label
                        text={
                          <>
                            <span className="va-middle text-capitalize">
                              Applications
                            </span>
                          </>
                        }
                      />
                      <div>
                        {applications.length
                          ? applications.map((application) => (
                              <Row className="align-items-center">
                                <Col>{application.name}</Col>
                                <Col>
                                  {" "}
                                  <Button
                                    variant="danger"
                                    className={
                                      "btn btn-secondary font-weight-bold px-2 py-1 my-1 mx-1"
                                    }
                                    disabled={application.status === "closed"}
                                    onClick={() =>
                                      this.cancelApplication(application._id)
                                    }
                                  >
                                    {application.status === "closed" &&
                                      "Rejected"}
                                    {application.status === "pending" &&
                                      "Cancel Application"}
                                  </Button>
                                </Col>
                              </Row>
                            ))
                          : "No pending application"}
                      </div>
                      <Block
                        content={
                          <>
                            <div className="mv-1rem">
                              <Label
                                text={
                                  <>
                                    <span className="va-middle text-capitalize">
                                      Partner Logo
                                    </span>
                                    {partner_logo !== "" && (
                                      <button
                                        type="button"
                                        className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                        onClick={() => {
                                          this.props.setState({
                                            showPartnerImageModal: true,
                                            uploadImageMode: "partner_logo",
                                            partner_upload_title:
                                              "Partner Logo",
                                          });
                                        }}
                                      >
                                        <i className="far fa-edit text-primary" />
                                      </button>
                                    )}
                                  </>
                                }
                              />
                              <div className="mt-1">
                                {partner_logo !== "" ? (
                                  <div className="d-flex justify-content-start">
                                    <img
                                      src={partner_logo_preview}
                                      alt="brand_website_bg"
                                      className="img-fluid clickable marketplace-logo-preview shadow-lg rounded-circle"
                                      onClick={() => {
                                        this.props.setState({
                                          showPartnerImageModal: true,
                                          uploadImageMode: "partner_logo",
                                          partner_upload_title: "Partner Logo",
                                        });
                                      }}
                                      onError={(ev) => {
                                        ev.target.onError = null;
                                        ev.target.src = partner_logo;
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <p>
                                      <span>Store doesn't have a Logo</span>{" "}
                                      <KTLink
                                        text={"Do you want to add one?"}
                                        onClick={() => {
                                          this.props.setState({
                                            showPartnerImageModal: true,
                                            uploadImageMode: "partner_logo",
                                            partner_upload_title:
                                              "Partner Logo",
                                          });
                                        }}
                                      />
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="mv-1rem">
                              <Label
                                text={
                                  <>
                                    <span className="va-middle text-capitalize">
                                      Partner Banner
                                    </span>{" "}
                                    {partner_banner !== "" && (
                                      <button
                                        type="button"
                                        className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                        onClick={() => {
                                          this.props.setState({
                                            showPartnerImageModal: true,
                                            uploadImageMode: "partner_banner",
                                            partner_upload_title:
                                              "Partner Banner",
                                          });
                                        }}
                                      >
                                        <i className="far fa-edit text-primary" />
                                      </button>
                                    )}
                                  </>
                                }
                              />
                              <div className="mt-1">
                                {partner_banner !== "" ? (
                                  <img
                                    src={partner_banner_preview}
                                    alt="brand_website_bg"
                                    className="object-fit-cover clickable shadow-lg rounded marketplace-banner-preview"
                                    onClick={() => {
                                      this.props.setState({
                                        showPartnerImageModal: true,
                                        uploadImageMode: "partner_banner",
                                        partner_upload_title: "Partner Banner",
                                      });
                                    }}
                                    onError={(ev) => {
                                      ev.target.onError = null;
                                      ev.target.src = partner_banner;
                                    }}
                                  />
                                ) : (
                                  <>
                                    <p>
                                      <span>Store doesn't have a Banner</span>{" "}
                                      <KTLink
                                        text={"Do you want to add one?"}
                                        onClick={() => {
                                          this.props.setState({
                                            showPartnerImageModal: true,
                                            uploadImageMode: "partner_banner",
                                            partner_upload_title:
                                              "Partner Banner",
                                          });
                                        }}
                                      />
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        }
                      />
                    </>
                  }
                />
              </Col>
            </Row>
            <Row className="flex justify-content-end ">
              <Col>
                <FormItem
                  label={"Store Category"}
                  name={"store_tags"}
                  customFormControl
                  className={"form-control-solid"}
                  inputActions={inputActions}
                  actions={actions}
                  showRequired={false}
                  custom={
                    <>
                      <Choices
                        storeTagsList={storeTagsList}
                        preselected={data.store_tags}
                        multiple={multiple}
                        disabled={!isEditing}
                        blockInvalidChars={this.blockInvalidChars}
                        {...this.props}
                      />
                    </>
                  }
                  helperText={
                    !isEditing ? (
                      <span
                        className="text-muted"
                        style={{ fontSize: "0.9rem" }}
                      >
                        Only the pickup team can edit this. If you wish to
                        change this, please contact
                        <a href="mailto:hello@pickup.ph"> hello@pickup.ph</a> or
                        your administrator.
                      </span>
                    ) : (
                      <span
                        className="text-muted"
                        style={{ fontSize: "0.9rem" }}
                      >
                        Add category tags to improve your store's visibility.
                      </span>
                    )
                  }
                />
              </Col>
              {isEditing && (
                <Col className="col-3 align-content-center mb-7">
                  <Button
                    type="submit"
                    className={GetSubmitClassList(
                      isSubmitting,
                      "btn btn-primary",
                    )}
                    onClick={this.onClickSubmit}
                  >
                    Save Changes
                  </Button>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }
}

const Choices = ({
  storeTagsList,
  data,
  multiple,
  disabled,
  preselected,
  blockInvalidChars,
}) => {
  const [multiSelections, setMultiSelections] = useState(preselected);
  return (
    <Typeahead
      id={"store-tags"}
      placeholder={
        "Select up to maximum of three (3) tags (Ex. Coffee, Breakfast, Dessert)"
      }
      onChange={(selected) => {
        setMultiSelections(selected);
        data.store_tags = selected;
      }}
      options={storeTagsList}
      selected={multiSelections}
      multiple={multiple}
      disabled={disabled}
      onKeyDown={(e) => blockInvalidChars(e)}
      labelKey="name"
    />
  );
};

const APIClientSelections = ({
  availableClients,
  data,
  preselected,
  blockInvalidChars,
  onChangeSelections,
}) => {
  const [multiSelections, setMultiSelections] = useState(preselected);
  return (
    <>
      <Typeahead
        id={"selected-clients"}
        placeholder={"Available Clients"}
        onChange={(selected) => {
          setMultiSelections(selected);
          onChangeSelections(selected);
        }}
        options={availableClients}
        selected={multiSelections}
        multiple={true}
        onKeyDown={(e) => blockInvalidChars(e)}
        labelKey="name"
      />
      <span className="text-muted" style={{ fontSize: "0.9rem" }}>
        This will be subjected for API partner approval
      </span>
    </>
  );
};
