import { parsePhoneNumber } from "awesome-phonenumber";

const validatePhoneNumber = (contact_number = "") => {
  if (contact_number[0] === "0") {
    var re = /^(0)\d{10}$/;
    return re.test(String(contact_number));
  } else {
    var re = /^(9)\d{9}$/;
    return re.test(String(contact_number));
  }
};

const validateLandline = (input) => {
  const checkPhoneNumber = parsePhoneNumber(input, "PH");
  const isValid = checkPhoneNumber.isValid();
  var new_input = input.replace(/-|\s|[{()}]/g, "");
  const inputLength = new_input.length;

  switch (parseInt(inputLength)) {
    case 5:
      var hotline0 = /^\(?(\d{1})\)?[- ]?(\d{2})[- ]?(\d{2})$/;
      const validHotlineV0 = hotline0.test(String(input));
      return validHotlineV0;
      break;
    case 6:
      var hotline1 = /^\(?(\d{3})\)?[- ]?(\d{4})$/;
      const validHotlineV1 = hotline1.test(String(input));
      return validHotlineV1;
      break;
    case 7:
      var hotline2 = /^\(?(\d{3})\)?[- ]?(\d{4})$/;
      const validHotlineV2 = hotline2.test(String(input));
      return validHotlineV2;
      break;
    case 8:
      var hotline3 = /^\(?(\d{4})\)?[- ]?(\d{4})$/;
      const validHotlineV3 = hotline3.test(String(input));
      return validHotlineV3;
      break;
    default:
      if (!isValid) return false;
      return true;
  }
};

export { validatePhoneNumber, validateLandline };
