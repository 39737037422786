import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { Modal, Form, Button, Card, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import {
  capitalizeText,
  fetchData,
  getToolTitleFromToolName,
  Humanize,
  showConfirmationSwal,
} from "../../helpers";
import ConfirmPasswordModal from "../../partials/ConfirmPasswordModal";
import { FormItem } from "../../components/Base";

class AdminToolsOrderToolModal extends ProSourceForm {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        old_order_status: "",
        new_order_status: "",
        delete_order_note: "",
        restore_order_note: "",
      },
      url: "",
      showPasswordConfirmModal: false,
      errors: [],
      touched: {},
      redirectTo: "",
      isSubmitting: false,
      isChecking: false,
    };
  }

  handleOnClickItem = (itemUrl, newHistory) => {
    let { url } = this.state;
    url = itemUrl;
    this.setState({ url });
    newHistory.push(itemUrl);
  };

  handleClearForms = () => {
    const { values } = this.state;
    values.old_order_status = "";
    values.new_order_status = "";
    values.delete_order_note = "";
    values.restore_order_note = "";
    this.setState({ values });
  };

  handleCloseModal = () => {
    this.props.onHide();
    this.handleClearForms();
  };

  handleSwitchToolConfirm = async (values, setSubmitting) => {
    const { toolName } = this.props;
    switch (toolName) {
      case "change_order_status":
        this.handleOnConfirmChangeOrderStatus(values, setSubmitting);
        break;
      case "delete_order":
        this.handleOnConfirmDeleteOrder(values, setSubmitting);
        break;
      case "restore_order":
        this.handleOnConfirmRestoreOrder(values, setSubmitting);
        break;
      default:
        console.log("no tool selected");
    }
  };

  handleOnConfirmChangeOrderStatus = async (values, setSubmitting) => {
    const { new_order_status } = this.state.values;
    const { data = [] } = this.props;
    values.old_order_status = data.status;
    values.new_order_status = new_order_status;
    values.transaction_number = data.transaction_number;
    const response = await fetchData(
      "put",
      `/admintools/orders/${data._id}/change_order_status`,
      { values },
    );
    const successMessage = "Order Status Updated Successfully";
    showConfirmationSwal(response, successMessage);
    values.confirm_password = "";
    this.setState({ showPasswordConfirmModal: false });
    if (response.data.result !== "error") {
      this.handleCloseModal();
      this.props.handleRefreshOrder();
      this.handleClearForms();
    }
    setSubmitting(false);
  };

  handleSelectOrderStatus = (event) => {
    const { values } = this.state;
    values.new_order_status = event.target.value;
    this.setState({ values });
  };

  handleOnConfirmDeleteOrder = async (values, setSubmitting) => {
    const { delete_order_note } = this.state.values;
    const { data = [] } = this.props;
    values.delete_order_note = delete_order_note;
    values.transaction_number = data.transaction_number;
    const response = await fetchData(
      "post",
      `/admintools/orders/${data._id}/delete_order`,
      { values },
    );
    const successMessage = "Order Deleted Successfully";
    showConfirmationSwal(response, successMessage);
    values.confirm_password = "";
    this.setState({ showPasswordConfirmModal: false });
    if (response.data.result !== "error") {
      this.handleCloseModal();
      this.props.handleRefreshOrder();
      this.handleClearForms();
    }
    setSubmitting(false);
  };

  handleOnConfirmRestoreOrder = async (values, setSubmitting) => {
    const { restore_order_note } = this.state.values;
    const { data = [] } = this.props;
    values.restore_order_note = restore_order_note;
    values.transaction_number = data.transaction_number;
    const response = await fetchData(
      "put",
      `/admintools/orders/${data._id}/restore_order`,
      { values },
    );
    const successMessage = "Order Restored Successfully";
    showConfirmationSwal(response, successMessage);
    values.confirm_password = "";
    this.setState({ showPasswordConfirmModal: false });
    if (response.data.result !== "error") {
      this.handleCloseModal();
      this.props.handleRefreshOrder();
      this.handleClearForms();
    }
    setSubmitting(false);
  };

  isProceedButtonDisabled = () => {
    const { toolName } = this.props;
    const { values } = this.state;

    if (toolName === "change_order_status") {
      if (!values.new_order_status) return true;
    }
    if (toolName === "delete_order") {
      if (!values.delete_order_note) return true;
    }
    if (toolName === "restore_order") {
      if (!values.restore_order_note) return true;
    }
    return false;
  };

  setConfirmMessageFromToolName = (toolName) => {
    const { values } = this.state;
    switch (toolName) {
      case "change_order_status":
        return (
          <>
            <span>{`You are changing the Order Status to `}</span>
            <span className="text-danger font-weight-bolder">
              {values.new_order_status}.
            </span>
          </>
        );
      case "delete_order":
        return `You are deleting this order.`;
      case "restore_order":
        return `You are restoring this order.`;
      default:
        return `${toolName}`;
    }
  };

  render() {
    const { show = false, onHide, toolName, data = [] } = this.props;
    const { showPasswordConfirmModal } = this.state;

    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    onsubmit = (e) => {
      e.preventDefault();
      this.setState({ showPasswordConfirmModal: true });
    };

    return (
      <>
        <ConfirmPasswordModal
          show={showPasswordConfirmModal}
          onHide={() => {
            this.setState({ showPasswordConfirmModal: false });
          }}
          body={
            <>
              <p className="text-dark">
                {this.setConfirmMessageFromToolName(toolName)}
              </p>
            </>
          }
          onSubmit={this.handleSwitchToolConfirm}
        />

        <Modal
          show={show}
          onHide={this.handleCloseModal}
          className="modal-content-xl"
          centered
        >
          <Modal.Header>
            <Modal.Title>{getToolTitleFromToolName(toolName)}</Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleCloseModal}
            >
              <i aria-hidden="true" className="ki ki-close"></i>
            </button>
          </Modal.Header>
          <Form onSubmit={this.onSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  {toolName === "restore_order" ? (
                    <>
                      <DeletedOrderDataForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  ) : (
                    <>
                      <OrderDataForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  )}
                </Col>
                <Col>
                  {toolName === "change_order_status" ? (
                    <>
                      <ChangeOrderStatusForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                        handleSelectOrderStatus={this.handleSelectOrderStatus}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {toolName === "delete_order" ? (
                    <>
                      <DeleteOrderForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {toolName === "restore_order" ? (
                    <>
                      <RestoreOrderForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <Card className="border card-custom mt-4">
                    <Card.Title className="pb-0 mb-0"></Card.Title>
                    <Card.Body>
                      <Button
                        type="submit"
                        className="btn-block text-uppercase font-weight-bold"
                      >
                        Proceed
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </>
    );
  }
}

class OrderDataForm extends AdminToolsOrderToolModal {
  render() {
    const { data, values, inputActions, actions } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body className="d-flex flex-column align-items-start">
            <div>
              <span className="text-muted">Transaction Number: </span>
              <span className="font-weight-bolder">
                {data.transaction_number}
              </span>
            </div>
            <div>
              <span className="text-muted">Status: </span>
              <span className="font-weight-bolder">
                {capitalizeText(data.status)}
              </span>
            </div>
            <div>
              <span className="text-muted">Order Type: </span>
              <span className="font-weight-bolder">
                {capitalizeText(data.order_type)}
              </span>
            </div>
            <div>
              <span className="text-muted">Brand: </span>
              <span className="font-weight-bolder">
                {data.brand && data.brand.name ? data.brand.name : "-"}
              </span>
            </div>
            <div>
              <span className="text-muted">Store: </span>
              <span className="font-weight-bolder">
                {data.store && data.store.name ? data.store.name : "-"}
              </span>
            </div>
            <div>
              <span className="text-muted">Name: </span>
              <span className="font-weight-bolder">
                {data.guest_customer && data.guest_customer.first_name
                  ? `${data.guest_customer.first_name} `
                  : "-"}
              </span>
              <span className="font-weight-bolder">
                {data.guest_customer && data.guest_customer.last_name
                  ? data.guest_customer.last_name
                  : ""}
              </span>
            </div>
            <div>
              <span className="text-muted">Email: </span>
              <span className="font-weight-bolder">
                {data.guest_customer && data.guest_customer.email
                  ? data.guest_customer.email
                  : "-"}
              </span>
            </div>
            <div>
              <span className="text-muted">Contact Number: </span>
              <span className="font-weight-bolder">
                {data.guest_customer && data.guest_customer.contact_number
                  ? data.guest_customer.contact_number
                  : "-"}
              </span>
            </div>
          </Card.Body>
        </Card>
      </>
    );
  }
}

class DeletedOrderDataForm extends AdminToolsOrderToolModal {
  render() {
    const { data, values, inputActions, actions } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body className="d-flex flex-column align-items-start">
            <div>
              <span className="text-muted">Order Transaction Number: </span>
              <span className="font-weight-bolder">{data.origin_key}</span>
            </div>
            <div>
              <span className="text-muted">Order ID: </span>
              <span className="font-weight-bolder">{data.origin_id}</span>
            </div>
            <div>
              <span className="text-muted">Date Deleted: </span>
              <span className="font-weight-bolder">{data.date_created}</span>
            </div>
          </Card.Body>
        </Card>
      </>
    );
  }
}

class ChangeOrderStatusForm extends AdminToolsOrderToolModal {
  orderStatus = [
    "new",
    "accepted",
    "cancelled",
    "cancelled_full_refund",
    "cancelled_no_refund",
    "cancelled_partial_refund",
    "cancelled_no_show",
    "cancelled_order_moved",
    "ready",
    "out_for_delivery",
    "completed",
    "refunded",
    "completed_full_refund",
    "completed_partial_refund",
  ];

  render() {
    const { data, values, inputActions, handleSelectOrderStatus, actions } =
      this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body>
            <FormItem
              label={"Current Order Status"}
              name={"old_order_status"}
              inputProps={{
                name: "old_order_status",
                // placeholder: "Old Store Name",
                // value: values["old_store_name"],
                value: capitalizeText(Humanize(data.status)),
                disabled: true,
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              normal
            />
            <div className="font-weight-bolder">New Order Status</div>
            <select
              className="form-control"
              name="order_status"
              placeholder="User Roles"
              // onChange={(e) => {
              //   setFieldValue("status", e.target.value);
              //   handleSubmit();
              // }}
              onChange={(event) => handleSelectOrderStatus(event)}
              onBlur={actions.handleBlur}
              // value={values.new_order_status}
            >
              <option value="">Select New Order Status</option>
              {this.orderStatus.map((element) => {
                return (
                  <option key={`${element}`} value={`${element}`}>
                    {capitalizeText(Humanize(element))}
                  </option>
                );
              })}
            </select>
          </Card.Body>
        </Card>
      </>
    );
  }
}

class DeleteOrderForm extends AdminToolsOrderToolModal {
  render() {
    const { data, values, inputActions, actions } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body>
            <FormItem
              label={"Note"}
              name={"delete_order_note"}
              inputProps={{
                name: "delete_order_note",
                placeholder: "Note",
                value: values["delete_order_note"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}

class RestoreOrderForm extends AdminToolsOrderToolModal {
  render() {
    const { data, values, inputActions, actions } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body>
            <FormItem
              label={"Note"}
              name={"restore_order_note"}
              inputProps={{
                name: "restore_order_note",
                placeholder: "Note",
                value: values["restore_order_note"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default withRouter(AdminToolsOrderToolModal);
