import React from "react";
import { Redirect } from "react-router-dom";
import ProSourceForm from "../components/ProSourceForm";
import { Nav, Button, Card } from "react-bootstrap";
import { Block, InfoAlertHelper, InfoTooltip } from "../components/Base";
import { PRIMARY_COLOR } from "../constants";
import { fetchData } from "../helpers";
import core from "../vendors/core-api/";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PacmanLoading from "../components/widgets/PacmanLoading/PacmanLoading";
import StoreReservationsCard from "../components/widgets/StoreReservationsCard";
import StoreReservationDetailsModal from "./StoreReservationDetailsModal";
import ReservationTermsForm from "./forms/ReservationTermsForm";
import Activities from "../partials/Activities";

const MySwal = withReactContent(Swal);

export default class ReservationsSettingsDiv extends ProSourceForm {
  constructor(props) {
    super(props);
    this.api = core("pu").get();
    this.state = {
      data: [],
      isActive: this.props.store.isReservationActive ? true : false,
      store: this.props.store ? this.props.store : "",
      isLoading: false,
      showStoreReservationModal: false,
      errors: {},
      touched: {},
      isSubmitting: false,
      redirectTo: "",
      key: "table",
    };
  }

  async componentDidMount() {
    // console.log("reservationssettingsdiv componentdidmount state: ", this.state)
    // console.log("reservationssettingsdiv componentdidmount props: ", this.props)
    let data;
    let isLoading = true;
    this.setState({ isLoading });
    try {
      let result = await this.api.get(
        `store-reservation/store/${this.props.store._id}`,
      );
      data = result.data;
    } catch (error) {
      console.log(error);
    } finally {
      isLoading = false;
      this.setState({ data, isLoading });
    }
    // console.log("reservationssettingsdiv data: ", data);
  }

  componentDidUpdate() {
    // console.log(
    //   "reservationssettingsdiv componentdidupdate state: ",
    //   this.state
    // );
    // console.log(
    //   "reservationssettingsdiv componentdidupdate props: ",
    //   this.props
    // );
  }

  handleEditStoreReservation = (item) => {
    console.log("");
  };

  refreshData = async () => {
    const result = await fetchData(
      "get",
      `store-reservation/store/${this.props.store._id}`,
    );
    // const newLogs = await fetchData(
    //   `get`,
    //   `/activities/c/promos/${this.state.group}`
    // );
    // this.setState({ updatedLogs: newLogs.data });
    return result.data;
  };

  handleDeleteStoreReservation = async (id) => {
    const result = await MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to remove this reservation type.`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
    });
    if (result.isConfirmed === true) {
      const response = await fetchData(
        "put",
        `store-reservation/archive/${id}`,
      );
      if (response.data.result === "ok") {
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully removed the reservation type.",
          confirmButtonColor: PRIMARY_COLOR,
          showConfirmButton: true,
          timer: 1000,
        });
        const data = await this.refreshData();
        this.setState({
          data,
          redirectTo: `/stores/${this.props.store._id}/settings/experiences`,
        });
      }
    }
  };

  handleReservationStatus = (isChecked) => {
    let values = {};
    const { store } = this.state;
    isChecked = !isChecked;
    this.setState({ isActive: isChecked });
    store.isReservationActive = isChecked;
    this.setState({ store });

    values["store_id"] = store._id;
    values["isActive"] = store.isReservationActive;
    this.api
      .put({
        url: "/store/allow-reservation",
        data: values,
      })
      .then(({ data }) => {
        if (data.result === "ok") {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            // timer: 1000
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onChangeExp = (value) => {
    if (value === "table") {
      this.setState({
        key: "table",
      });
    } else if (value === "event") {
      this.setState({
        key: "event",
      });
    }
  };

  render() {
    const {
      isActive,
      data = [],
      store,
      showStoreReservationModal,
      key = "table",
    } = this.state;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <>
        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} push={true} />
        ) : (
          <></>
        )}
        <Card className="card-custom gutter-b">
          <Card.Header>
            <Card.Title>Experiences Settings</Card.Title>
          </Card.Header>
          <Card.Body>
            <div>
              <InfoAlertHelper
                text={
                  "You can manage your reservations or events service availability in your Experiences Settings"
                }
              />
              <Block
                content={
                  <>
                    <Card className="d-flex justify-content-between w-100 mb-4">
                      <Card.Body>
                        <div
                          style={{ transform: "scale(1.2)" }}
                          className="row d-flex align-items-center"
                        >
                          <span className="switch switch-outline switch-icon switch-primary py-1 m-0 ml-10 pl-10">
                            <label>
                              <input
                                type="checkbox"
                                name="isActive"
                                defaultChecked={isActive}
                                onChange={() =>
                                  this.handleReservationStatus(isActive)
                                }
                              />
                              <span></span>
                            </label>
                          </span>
                          <label className="text-dark font-weight-bolder text-uppercase py-4 pl-3 pr-0">
                            Allow Experiences
                          </label>
                          {InfoTooltip(
                            "Turning this off will remove the experiences option for your customers.",
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                    <ReservationTermsForm
                      store={this.state.store}
                      onSubmitReservationTermsForm={
                        this.props.onSubmitReservationTermsForm
                      }
                    />
                    <Card className="d-flex justify-content-between w-100 mt-4">
                      <Card.Header className="font-weight-boldest">
                        <div className="d-flex justify-content-between align-items-center">
                          <div style={{ transform: "scale(1.2)" }}>
                            Experiences Types
                            {/* <i className="ml-4 fas fa-plus"></i> */}
                          </div>
                          <div>
                            <Button
                              className="align-self-end text-nowrap"
                              variant="primary"
                              onClick={() => {
                                this.setState({
                                  showStoreReservationModal: true,
                                });
                              }}
                            >
                              {`Add New`}
                            </Button>
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <Nav
                          variant="pills"
                          className="w-100 nav-justified"
                          activeKey={key}
                          onSelect={(key) => this.setState({ key })}
                        >
                          <Nav.Item className="mx-1">
                            <Nav.Link
                              className="justify-content-center"
                              eventKey="table"
                              onClick={() => this.onChangeExp("table")}
                            >
                              Tables
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="mx-1">
                            <Nav.Link
                              className="justify-content-center"
                              eventKey="event"
                              onClick={() => this.onChangeExp("event")}
                            >
                              Events
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        {this.state.isLoading ? (
                          <>
                            <div className="h-100 w-100 d-flex flex-column align-items-center">
                              <PacmanLoading />
                            </div>
                          </>
                        ) : (
                          <>
                            {key === "table" && data.length ? (
                              <>
                                {data.map((item) => {
                                  return item.reservation_type === key ? (
                                    <StoreReservationsCard
                                      key={item._id}
                                      data={item}
                                      store={store}
                                      actions={actions}
                                      inputActions={inputActions}
                                      user={this.props.user}
                                      handleDeleteStoreReservation={
                                        this.handleDeleteStoreReservation
                                      }
                                    />
                                  ) : (
                                    <></>
                                  );
                                })}
                              </>
                            ) : key === "event" && data.length ? (
                              <>
                                {data.map((item) => {
                                  return item.reservation_type === key ? (
                                    <StoreReservationsCard
                                      key={item._id}
                                      data={item}
                                      store={store}
                                      actions={actions}
                                      inputActions={inputActions}
                                      user={this.props.user}
                                      handleDeleteStoreReservation={
                                        this.handleDeleteStoreReservation
                                      }
                                    />
                                  ) : (
                                    <></>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                <div className="text-center mt-10">
                                  No store experiences found.
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </Card.Body>
                    </Card>
                  </>
                }
              ></Block>
            </div>
            {showStoreReservationModal && (
              <StoreReservationDetailsModal
                show={showStoreReservationModal}
                onHide={() => {
                  this.setState({
                    showStoreReservationModal: false,
                  });
                }}
                store={{ _id: store._id, name: store.name }}
                actions={actions}
                inputActions={inputActions}
                onSubmit={this.handleOnSubmit}
                user={this.props.user}
              />
            )}
          </Card.Body>
        </Card>
        <Card className="card-custom gutter-b">
          <Card.Header>
            <Card.Title>Activity Log</Card.Title>
          </Card.Header>
          <Card.Body>
            <Activities
              collection="store_reservation"
              identifier={store._id}
              api={this.api}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}
