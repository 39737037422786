import React from "react";
import moment from "moment";
import { Dropdown } from "react-bootstrap";

import { DB_DATE_FORMAT } from "../../constants";

const DateRangeDropdown = (props) => {
  const { onDateRangeSelect, statCardText } = props;
  function dropdownText(dateRange, date) {
    const isCurrent = date === moment().format(DB_DATE_FORMAT);
    const prefix = isCurrent ? "This" : "Last";

    if (dateRange === "week") return `${prefix} Week`;
    if (dateRange === "month") return `${prefix} Month`;
    if (dateRange === "year") return `${prefix} Year`;
    if (dateRange === "allTime") return "All-time";
    return `${isCurrent ? "Today" : "Yesterday"}`;
  }

  const dateRangeList = [
    { date: moment().format(DB_DATE_FORMAT), name: "Today", value: "day" },
    {
      date: moment(moment().subtract(1, "days")).format(DB_DATE_FORMAT),
      name: "Yesterday",
      value: "day",
    },
    {
      date: moment().format(DB_DATE_FORMAT),
      name: "This Week",
      value: "week",
    },
    {
      date: moment(moment().subtract(1, "weeks")).format(DB_DATE_FORMAT),
      name: "Last Week",
      value: "week",
    },
    {
      date: moment().format(DB_DATE_FORMAT),
      name: "This Month",
      value: "month",
    },
    {
      date: moment(moment().subtract(1, "months")).format(DB_DATE_FORMAT),
      name: "Last Month",
      value: "month",
    },
    {
      date: moment().format(DB_DATE_FORMAT),
      name: "This Year",
      value: "year",
    },
    {
      date: moment(moment().subtract(1, "year")).format(DB_DATE_FORMAT),
      name: "Last Year",
      value: "year",
    },
    {
      date: moment().format(DB_DATE_FORMAT),
      name: "All-time",
      value: "allTime",
    },
  ];
  return (
    <>
      <Dropdown className="ml-auto align-self-end rounded-top p-4 pb-0 bg-white">
        <Dropdown.Toggle variant="primary" id="dropdown-basic">
          {statCardText}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {dateRangeList.map((item) => (
            <Dropdown.Item
              key={item.name}
              onClick={(e) =>
                onDateRangeSelect(e, item.date, item.name, item.value)
              }
            >
              {item.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default DateRangeDropdown;
