import React from "react";

/**
 * DineInInfo
 * @param {object} props.table_number Dine-in table number from url
 * @param {object} props.website_theme Website theme
 * @returns
 */
export default function DineInInfo(props) {
  // console.log("dineininfo website_theme: ", props.website_theme)
  return (
    <>
      <div
        style={{ backgroundColor: "light-green" }}
        className="d-flex flex-column align-items-center mt-7 border rounded menu-dine-in-info"
      >
        <h3 className="text-center m-2">{`Dine-in at table number`}</h3>
        <div className="display-1 font-weight-bolder">
          {`${props.table_number}`}
        </div>
      </div>
    </>
  );
}
