import React from "react";
import { Modal, Form } from "react-bootstrap";
import { GetSubmitClassList } from "../helpers";
import { FormItem } from "./Base";
import ProSourceForm from "./ProSourceForm";

export default class FormModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { value = "", name = "", form_item = {} } = this.props,
      { showRequired = false } = form_item;

    let values = {};
    values[name] = value;

    this.state = {
      values,
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {
      required: showRequired ? [name] : [],
    };
  }

  onChange = (event) => {
    const { type = "text" } = this.props;

    const target = event.target,
      checked = target.checked,
      name = target.name;
    let { values = {} } = this.state,
      state = {};

    if (type === "toggle") {
      values[name] = checked;
      state.values = values;
    }

    this.setState(state);
  };

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const {
        show,
        onHide,
        modal_title = "",
        type = "text",
        form_item = {},
        name = "",
        info_helper = <></>,
        placeholder = "",
      } = this.props,
      { values = {}, isSubmitting = false } = this.state;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>{modal_title}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            {info_helper}
            {type === "text" || type === "number" ? (
              <FormItem
                normal
                name={name}
                inputProps={{
                  name,
                  placeholder,
                  value: values[name],
                }}
                inputActions={inputActions}
                actions={actions}
                type={type}
                {...form_item}
              />
            ) : (
              <></>
            )}

            {type === "toggle" ? (
              <div>
                <div class="form-group row">
                  <label class="col-6 col-form-label font-weight-bolder text-dark mr-2">
                    {form_item.hasOwnProperty("label") ? form_item.label : ""}
                  </label>
                  <div class="col-4">
                    <span class="switch switch-outline switch-icon switch-primary">
                      <label>
                        <input
                          type="checkbox"
                          name={name}
                          checked={values[name] ? "checked" : null}
                          {...inputActions}
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              disabled={isSubmitting}
              className={GetSubmitClassList(
                isSubmitting,
                `btn btn-primary font-weight-bold`,
              )}
            >
              {isSubmitting ? "Saving changes..." : "Save changes"}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
