import React, { Component } from "react";
import { Card } from "react-bootstrap";

export default function Page() {
  return <MainComponent />;
}

class MainComponent extends Component {
  render() {
    return (
      <>
        <Card>
          <Card.Body>
            <p> Brands </p>
          </Card.Body>
        </Card>
      </>
    );
  }
}
