import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import {
  FormItem,
  InfoTooltip,
  Label,
  RequiredAsterisk,
  SubLabel,
  Block,
} from "../../components/Base";
import ProSourceForm from "../../components/ProSourceForm";
import {
  PROMOS_APPLIES_TO,
  PROMO_TYPES,
  DB_DATE_FORMAT,
  PROMO_SCOPE_LEVELS,
  PRIMARY_COLOR,
  DANGER_COLOR,
} from "../../constants";
import {
  fetchData,
  GetDataFromEvent,
  GetSubmitClassList,
  Humanize,
  isPromoScopeAllowedForUserLevel,
} from "../../helpers";
import DatePicker from "react-datepicker";
import moment from "moment";
import ht from "../../helpertexts";
import DisplayPromoBrandsDropdown from "../settings/DisplayPromoBrandsDropdown";
import DisplayPromoStoresDropdown from "../settings/DisplayPromoStoresDropdown";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { debounce, values } from "lodash";
import TitleHelmet from "../TitleHelmet";
import { KTUtil } from "../../../_metronic/_assets/js/components/util";

const MySwal = withReactContent(Swal);
const FEATURED_PROMO_ON = localStorage.getItem("FEATURED_PROMO_ON") || false;
const MARKET_SETTINGS_AVAILABLE =
  process.env.REACT_APP_MARKETPLACE_SETTINGS === "AVAILABLE" ||
  localStorage.getItem("REACT_APP_MARKETPLACE_SETTINGS") === "AVAILABLE";
export default class PromoForm extends ProSourceForm {
  constructor(props) {
    super(props);

    const promo = this.props.promo ? this.props.promo : {};
    const {
      promo_code = "",
      applies_to = [],
      promo_type = "",
      amount_off = 0,
      percentage_off = 0,
      subsidized_delivery_amount = 0,
      free_item_description = "",
      minimum_purchase = 100,
      usage_limit = 0,
      start_date = "",
      end_date = "",
      active = true,
      scope = "",
      brands = [],
      stores = [],
      group = "",
      has_minimum_purchase = minimum_purchase ? true : false,
      has_limit = usage_limit ? true : false,
      has_end_date = end_date ? true : false,
      featured = false,
    } = promo;

    this.state = {
      values: {
        promo_code: this.props.mode === "copy" ? "" : promo_code,
        applies_to,
        promo_type,
        amount_off,
        percentage_off,
        subsidized_delivery_amount,
        free_item_description,
        minimum_purchase,
        usage_limit,
        start_date,
        end_date,
        active,
        scope,
        brands,
        stores,
        group,
        has_minimum_purchase,
        has_limit,
        has_end_date,
        useCount: 0,
        isUserInPromoStores: false,
        isUserInPromoBrands: false,
        isUserAdminInPromoBrandStores: false,
        original_promo_code: promo_code,
        featured,
      },
      errors: [],
      touched: {},
      redirectTo: "",
      isSubmitting: false,
      isChecking: false,
      mountStartDatePicker: true,
      mountEndDatePicker: true,
      editMode: this.props.mode === "view" ? false : true,
    };

    this.validate = {
      required: [
        "promo_code",
        "scope",
        "applies_to",
        "promo_type",
        "start_date",
        "end_date",
        "usage_limit",
        "minimum_purchase",
        "amount_off",
        "percentage_off",
        "subsidized_delivery_amount",
        "free_item_description",
        "stores",
        "brands",
      ],
    };

    this.checkIfPromoCodeExists = this.checkIfPromoCodeExists.bind(this);
    this.onClickOnChangeDate = this.onClickOnChangeDate.bind(this);
    this.onChangeSelectScope = this.onChangeSelectScope.bind(this);
    this.onBlurSelectScope = this.onBlurSelectScope.bind(this);
  }

  checkIfPromoCodeExists = async (
    promo_code = "",
    // original_promo_code = ""
  ) => {
    let promoCodeExists = false;
    const { values, errors } = this.state;
    const { mode } = this.props;

    if (!promo_code) return promoCodeExists;
    if (mode === "view" && values.original_promo_code === promo_code) {
      return promoCodeExists;
    }

    this.setState({ isChecking: true });

    const result = await fetchData(
      "get",
      `/promos/is-code-existing/${promo_code}`,
    );

    this.setState({ isChecking: false });

    const isCodeExisting = result.data.existing;

    if (isCodeExisting) {
      promoCodeExists = true;
      errors.push({
        errorField: "promo_code",
        errorMessage: `This promo already exists.`,
      });
      this.setState({ errors });
    }

    return promoCodeExists;

    // this.props.api
    //   .post({
    //     url: "/promos/is-code-existing",
    //     data: { promo_code },
    //   })
    //   .then(({ data }) => {
    //     // const { errors = {} } = this.state;
    //     // if (data.existing) {
    //     //   errors.promo_code = "This promo exists.";
    //     // } else {
    //     //   delete errors.promo_code;
    //     // }

    //     const { errors = [] } = this.state;
    //     console.log("is promo code existing: ", data.existing);
    //     if (data.existing) {
    //       // errors.push("promo_code");
    //       promoCodeExists = true;
    //       errors.push({
    //         errorField: "promo_code",
    //         errorMessage: `This promo already exists.`,
    //       });
    //       this.setState({ errors });
    //       return promoCodeExists;validatePromo
    //     }

    //     // this.setState({ errors });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {
    //     this.setState({ isChecking: false });
    //   });
  };

  debounceCheckIfPromoCodeExists = debounce(
    (q) => this.checkIfPromoCodeExists(q),
    1000,
  );

  validatePromo = async (values = [], required = []) => {
    // console.log("validatePromo");

    let errors = [];
    this.setState({ errors });

    // let errors = this.state.errors;

    required.forEach(async (element) => {
      // console.log("validatePromo value: ", element);
      // console.log(`validatePromo values [${element}]: `, values[element]);
      if (element === "minimum_purchase") {
        if (values.has_minimum_purchase && !values.minimum_purchase) {
          // errors.push(element);
          errors.push({
            errorField: element,
            // errorMessage: `This field is required`,
          });
        }
        if (
          // values.has_minimum_purchase &&
          Number(values.minimum_purchase) < 100
        ) {
          // errors.push(element);
          errors.push({
            errorField: element,
            errorMessage: `Please input a value of at least 100.`,
          });
        }
      } else if (element === "usage_limit") {
        if (values.has_limit && !values.usage_limit) {
          // errors.push(element);
          errors.push({
            errorField: element,
            // errorMessage: `This field is required`,
          });
        }
        if (values.has_limit && Number(values.usage_limit) <= 0) {
          // errors.push(element);
          errors.push({
            errorField: element,
            errorMessage: `Please input a value greater than 0.`,
          });
        }
      } else if (element === "end_date") {
        if (values.has_end_date && !values.end_date) {
          errors.push({
            errorField: element,
          });
        }
      } else if (element === "amount_off") {
        if (values.promo_type === "amount_off" && !values.amount_off) {
          // errors.push(element);
          errors.push({
            errorField: element,
            // errorMessage: `This field is required`,
          });
        }
        if (
          values.promo_type === "amount_off" &&
          Number(values.amount_off) <= 0
        ) {
          // errors.push(element);
          errors.push({
            errorField: element,
            errorMessage: `Please input a value greater than 0.`,
          });
        }
        if (
          values.promo_type === "amount_off" &&
          Number(values.amount_off) > Number(values.minimum_purchase)
        ) {
          // errors.push(element);
          errors.push({
            errorField: element,
            errorMessage: `Please input a value less than the minimum purchase amount.`,
          });
        }
      } else if (element === "percentage_off") {
        if (values.promo_type === "percentage_off" && !values.percentage_off) {
          // errors.push(element);
          errors.push({
            errorField: element,
            // errorMessage: `This field is required`,
          });
        }
        if (
          values.promo_type === "percentage_off" &&
          (Number(values.percentage_off) <= 0 ||
            Number(values.percentage_off) >= 100)
        ) {
          // errors.push(element);
          errors.push({
            errorField: element,
            errorMessage: `Please input a value between 1 and 99.`,
          });
        }
      } else if (element === "subsidized_delivery_amount") {
        if (
          values.promo_type === "free_delivery" &&
          !values.subsidized_delivery_amount
        ) {
          errors.push({
            errorField: element,
          });
        }
        if (
          values.promo_type === "free_delivery" &&
          Number(values.subsidized_delivery_amount) <= 0
        ) {
          errors.push({
            errorField: element,
            errorMessage: `Please input a value greater than 0.`,
          });
        }
      } else if (element === "free_item_description") {
        if (
          values.promo_type === "free_item" &&
          !values.free_item_description
        ) {
          errors.push({
            errorField: element,
          });
        }
      } else if (element === "promo_code") {
        const promoCodeExists = this.checkIfPromoCodeExists(values.promo_code);

        if (promoCodeExists === true) {
          // errors.push(element);
          errors.push({
            errorField: element,
            errorMessage: `This promo already exists.`,
          });
        }
        if (values.promo_code === "") {
          // errors.push(element);
          errors.push({
            errorField: element,
            // errorMessage: `This field is required`,
          });
        }
      } else if (element === "stores") {
        if (values.scope === "store" && !values.stores.length) {
          // errors.push(element);
          errors.push({
            errorField: element,
            // errorMessage: `This field is required`,
          });
        }
      } else if (element === "brands") {
        if (values.scope === "brand" && !values.brands.length) {
          // errors.push(element);
          errors.push({
            errorField: element,
            // errorMessage: `This field is required`,
          });
        }
        // } else if (element === "start_date") {
        //   if (!values.start_date instanceof Date) {
        //     console.log("start_date: ", values.start_date);
        //     errors.push(element);
        //   }
      } else if (!values[element] || !values[element].length) {
        // } else {
        // console.log("not found: ", element);
        // errors["element"] = `This field is required: ${element}`;

        // errors.push(element);
        errors.push({
          errorField: element,
          // errorMessage: `This field is required`,
        });

        // return false;
      }
    });
    this.setState({ errors });

    if (errors.length) {
      return false;
    }
    // errors = {};
    return true;
  };

  onClickOnChangeDate = (date, field) => {
    if (date instanceof Date) {
      this.setFieldValue(field, moment(date).format(DB_DATE_FORMAT));
    }

    const removeErr = this.state.errors.filter(
      ({ errorField }) => errorField != field,
    );
    this.setState({ errors: removeErr });
  };

  onChangeSelectScope = (values, field) => {
    this.setFieldValue(
      field,
      values.map(({ _id = "" }) => {
        return _id;
      }),
    );
  };

  onBlurSelectScope = () => {};

  onChange = async (e) => {
    const { name = "", checked = false } = e.target,
      { values = {}, errors = [] } = this.state,
      inputValue = e.target.value;

    if (values[name] || values[name].length) {
      const removeErr = errors.filter(({ errorField }) => errorField != name);
      this.setState({ errors: removeErr });
    }

    if (name === "promo_type") {
      const value = GetDataFromEvent(e, "data-value");
      this.setFieldValue(name, value);
      if (value === "free_delivery") {
        console.log("onChange promoType applies_to: ", values.applies_to);
        values.applies_to = [];
        values.applies_to.push("delivery");
        values.applies_to.push("meal_plan");
        console.log("onChange promoType post applies_to: ", values.applies_to);
        this.setState({ values });
        console.log("onChange promoType values: ", values);
      }
    } else if (name === "scope") {
      const value = GetDataFromEvent(e, "data-value");
      values.stores = [];
      values.brands = [];
      this.setState({ values });
      this.setFieldValue(name, value);
    } else if (name === "applies_to") {
      const value = GetDataFromEvent(e, "data-value");
      let { applies_to = [] } = values;
      if (checked) {
        applies_to.push(value);
      } else {
        applies_to = applies_to.filter((apply_to) => {
          return apply_to !== value;
        });
      }

      this.setFieldValue(name, applies_to);
    } else if (
      // name === "has_minimum_purchase" ||
      // name === "has_limit" ||
      name === "active"
    ) {
      this.setFieldValue(name, checked);
    } else if (name === "has_minimum_purchase") {
      values.minimum_purchase = 0;
      this.setState({ values });
      this.setFieldValue(name, checked);
    } else if (name === "has_limit") {
      values.usage_limit = 0;
      this.setState({ values });
      this.setFieldValue(name, checked);
    } else if (name === "has_end_date") {
      values.end_date = "";
      this.setState({ values });
      this.setFieldValue(name, checked);
    } else if (name === "promo_code" && inputValue !== "") {
      const filteredInputValue = inputValue.replace(/[^a-zA-Z0-9]/gi, "");
      const upperCaseInputValue = filteredInputValue.toUpperCase();
      this.state.values.promo_code = upperCaseInputValue;
      // const inputValue = inputValue.replace(/[^a-zA-Z0-9\_]/gi, "");
      // this.checkIfPromoCodeExists(inputValue);
      const promoCodeExists =
        await this.debounceCheckIfPromoCodeExists(upperCaseInputValue);

      // console.log("onchange promoCodeExists: ", promoCodeExists);
      // if (promoCodeExists) {
      //   errors.push({
      //     errorField: "promo_code",
      //     errorMessage: `This promo already exists.`,
      //   });
      // }
    } else if (name === "featured") {
      this.setFieldValue(name, checked);
    }
  };

  // isScopeAllowedForUserLevel = (userRole, promoScope) => {
  //   // console.log(
  //   //   "promoform isScopeAllowedForUserLevel userRole, promoScope: ",
  //   //   userRole,
  //   //   promoScope
  //   // );
  //   if (userRole === "group_admin") {
  //     if (["group", "brand", "store"].includes(promoScope)) return true;
  //     // console.log("promoform isScopeAllowedForUserLevel false: ", userRole);
  //     return false;
  //   } else if (userRole === "brand_admin") {
  //     if (["brand", "store"].includes(promoScope)) return true;
  //     // console.log("promoform isScopeAllowedForUserLevel false: ", userRole);
  //     return false;
  //   } else if (userRole === "store_admin") {
  //     if (["store"].includes(promoScope)) return true;
  //     // console.log("promoform isScopeAllowedForUserLevel false: ", userRole);
  //     return false;
  //   } else {
  //     return false;
  //   }
  // };

  checkIfUserAdminInBrandStores = async (userId) => {
    const { values } = this.state;
    const userAllStores = await fetchData("get", `/users/${userId}/allstores`);
    // console.log(
    //   "promoform componentdidmount userAllStores: ",
    //   userAllStores.data
    // );
    const isUserInPromoStores = userAllStores.data.reduce((acc, curr) => {
      let val = this.state.values.stores.includes(curr) ? 1 : 0;
      return acc + val;
    }, 0);
    // console.log(
    //   "promoform componentdidmount isUserInPromoStores: ",
    //   isUserInPromoStores
    // );

    const userAllBrands = await fetchData("get", `/users/${userId}/allbrands`);
    // console.log(
    //   "promoform componentdidmount userAllBrands: ",
    //   userAllBrands.data
    // );
    const isUserInPromoBrands = userAllBrands.data.reduce((acc, curr) => {
      let val = this.state.values.brands.includes(curr) ? 1 : 0;
      return acc + val;
    }, 0);
    // console.log(
    //   "promoform componentdidmount isUserInPromoBrands: ",
    //   isUserInPromoBrands
    // );

    const isUserGroupAdmin =
      this.props.user.iam_role === "group_admin" ? true : false;

    values.isUserInPromoStores = !!isUserInPromoStores;
    values.isUserInPromoBrands = !!isUserInPromoBrands;
    values.isUserAdminInPromoBrandStores =
      !!isUserInPromoStores || !!isUserInPromoBrands || isUserGroupAdmin;
    this.setState({ values });
  };

  onClickEditButton = async () => {
    // console.log("edit");
  };

  handleError = (error) => {
    if (error.hasOwnProperty("response")) {
      const {
        data: { message },
      } = error.response;

      if (message.error === "InvalidAction") {
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: "Unable to update promo code at this moment.",
        });
      } else {
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: `Something went wrong`,
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      }
    }
  };

  onClickSaveEditsButton = async (values) => {
    // console.log("save edits values: ", values);
    try {
      const { data } = await fetchData(
        "put",
        `promos/${this.props.promo._id}`,
        values,
      );

      if (data.result === "ok") {
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully saved your changes.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          // timer: 1000
        });
        this.setState({
          editMode: false,
          isSubmitting: true,
          redirectTo: "/promos",
        });
      }
      // console.log("promoform save edits data: ", data);
    } catch (err) {
      this.handleError(err);
    }
  };

  onClickSaveNewButton = async (values) => {
    const promoCodeExists = await this.checkIfPromoCodeExists(
      this.state.values,
      this.validate.required,
    );
    if (promoCodeExists) {
      MySwal.fire({
        icon: "error",
        title: "Error!",
        text: "Saving failed. Please check details.",
        confirmButtonColor: DANGER_COLOR,
        confirmButtonText: "Back",
        // timer: 1000
      });
      return;
    } else {
      const { data } = await fetchData("post", "/promos", values);

      if (data.result === "ok") {
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully saved your changes.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          // timer: 1000
        });
        this.setState({ isSubmitting: true, redirectTo: "/promos" });
      }
    }
  };

  onClickCancelEdit = (event) => {
    // console.log("cancel edit");
    // event.preventDefault();
    this.setState({ editMode: false, redirectTo: "/promos" });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { values } = this.state;
    if (this.props.mode === "view") {
      if (this.state.editMode === false) {
        this.setState({ editMode: true, isSubmitting: true });
        this.onClickEditButton();
      } else if (this.state.editMode === true) {
        const validated = await this.validatePromo(
          this.state.values,
          this.validate.required,
        );

        if (!validated) {
          // console.log("validated: ", validated);
          MySwal.fire({
            icon: "error",
            title: "Error!",
            text: "Please check required fields.",
            confirmButtonColor: DANGER_COLOR,
            confirmButtonText: "Back",
            // timer: 1000
          });
          this.setState({ isSubmitting: false });
          return;
        } else {
          this.onClickSaveEditsButton(values);
        }
      }
      this.setState({ isSubmitting: false });
    }

    if (this.props.mode === "new") {
      const validated = await this.validatePromo(
        this.state.values,
        this.validate.required,
      );

      if (!validated) {
        MySwal.fire({
          icon: "error",
          title: "Error!",
          text: "Please check required fields.",
          confirmButtonColor: DANGER_COLOR,
          confirmButtonText: "Back",
        });
        this.setState({ isSubmitting: false });
        return;
        // return;
      } else {
        this.onClickSaveNewButton(values);
      }
      this.setState({ isSubmitting: false });
    }

    if (this.props.mode === "copy") {
      //new promo
      // console.log("new promo");
      const validated = await this.validatePromo(
        this.state.values,
        this.validate.required,
      );

      if (!validated) {
        // console.log("validated: ", validated);
        MySwal.fire({
          icon: "error",
          title: "Error!",
          text: "Please check required fields.",
          confirmButtonColor: DANGER_COLOR,
          confirmButtonText: "Back",
          // timer: 1000
        });
        this.setState({ isSubmitting: false });
        return;
        // return;
      } else {
        this.onClickSaveNewButton(values);
      }
      this.setState({ isSubmitting: false });
      // this.props.onSubmit(values, setSubmitting);
    }
  };

  async componentDidMount() {
    const { values } = this.state;
    if (this.props.mode !== "view") return;
    if (this.props.mode === "copy") {
      this.state.values["promo_code"] = "";
      this.setState({ values });
    }

    const promoUseCount = await fetchData(
      "get",
      `/promos/${this.props.promo._id}/orders`,
    );

    await this.checkIfUserAdminInBrandStores(this.props.user._id);

    values.useCount = promoUseCount.data.length;
    this.setState({ values });
  }

  componentDidUpdate() {
    // console.log("promoform componentDidUpdate state: ", this.state);
    // console.log("promoform componentDidUpdate props: ", this.props);
  }

  generateRandomPromoCode = async () => {
    const CODE_LENGTH = 8;
    let isUnique = false;
    let generated_code = "";

    do {
      let temp_code = (
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
      )
        .substring(0, CODE_LENGTH)
        .toUpperCase();
      const { data } = await fetchData("get", "/promos/user");
      const filter_codes = data.filter(
        ({ promo_code }) => promo_code === temp_code,
      );
      isUnique = filter_codes.length === 0;
      generated_code = temp_code;
    } while (!isUnique);

    const { values = {}, errors = {} } = this.state;
    values["promo_code"] = generated_code;
    const removeErr = errors.filter(
      ({ errorField }) => errorField !== "promo_code",
    );
    this.setState({ values, errors: removeErr });
    this.setStateKey("touched", "promo_code", "true");
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <>
        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} push={true} />
        ) : (
          <></>
        )}
        {/* <Form onSubmit={this.handleOnSubmit}> */}
        <Form onSubmit={this.onSubmit}>
          <div className="d-flex align-items-center justify-content-space-between">
            <div className="display-4 font-weight-bolder mv-1-rem w-100 align-self-stretch d-flex align-items-center">
              {this.props.title}{" "}
            </div>

            <div className="d-flex flex-row">
              {this.props.mode === "view" &&
              this.state.values.isUserAdminInPromoBrandStores &&
              isPromoScopeAllowedForUserLevel(
                // this.isScopeAllowedForUserLevel(
                this.props.user.iam_role,
                this.state.values["scope"],
              ) ? (
                <>
                  {/* {this.state.editMode === true ? (
                    <> */}
                  <Button
                    disabled={this.state.isSubmitting || this.state.isChecking}
                    className={
                      "btn btn-secondary font-weight-bold px-9 py-4 my-3 mx-4"
                    }
                    variant="danger"
                    onClick={() => this.onClickCancelEdit()}
                  >
                    {this.state.editMode === true ? `Cancel` : `Back`}
                  </Button>
                  {/* </>
                  ) : (
                    <></>
                  )} */}
                  <Button
                    type="submit"
                    disabled={this.state.isSubmitting || this.state.isChecking}
                    className={GetSubmitClassList(
                      this.state.isSubmitting,
                      `btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 text-nowrap`,
                    )}
                  >
                    {this.props.mode === "view" && this.state.editMode === true
                      ? "Save changes"
                      : "Edit"}
                  </Button>
                </>
              ) : (
                <></>
              )}

              {this.props.mode === "new" ? (
                <>
                  <Button
                    disabled={this.state.isSubmitting || this.state.isChecking}
                    className={
                      "btn btn-secondary font-weight-bold px-9 py-4 my-3 mx-4"
                    }
                    variant="danger"
                    onClick={() => this.onClickCancelEdit()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={this.state.isSubmitting || this.state.isChecking}
                    className={GetSubmitClassList(
                      this.state.isSubmitting,
                      `btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 text-nowrap`,
                    )}
                  >
                    {this.state.isSubmitting ? "Saving changes..." : "Save"}
                  </Button>
                </>
              ) : (
                <></>
              )}

              {this.props.mode === "copy" ? (
                <>
                  <Button
                    disabled={this.state.isSubmitting || this.state.isChecking}
                    className={
                      "btn btn-secondary font-weight-bold px-9 py-4 my-3 mx-4"
                    }
                    variant="danger"
                    onClick={() => this.onClickCancelEdit()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={this.state.isSubmitting || this.state.isChecking}
                    className={GetSubmitClassList(
                      this.state.isSubmitting,
                      `btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 text-nowrap`,
                    )}
                  >
                    {this.state.isSubmitting ? "Saving changes..." : "Save"}
                  </Button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <Row>
            <Col sm={8}>
              <Card className="card-custom mt-4">
                <Card.Title className="pb-0 mb-0">
                  <div className="flex flex-column flex-grow-1">
                    <h3 className="ml-8 mt-8 font-weight-bolder">Promotion</h3>
                    {/* {this.props.mode !== "new" ? (
                      <>
                        <span className="w-100">
                          <div
                            style={{ transform: "scale(1.4)" }}
                            className="text-dark font-weight-bolder text-uppercase py-6 pl-2 pr-5 mr-5"
                          >
                            {`Use Count: ${this.state.values.useCount}`}
                          </div>
                        </span>
                      </>
                    ) : (
                      <></>
                    )} */}
                  </div>
                </Card.Title>
                <Card.Body>
                  <div className="d-flex flex-column flex-grow-1 card-stretch">
                    <FormItem
                      label={"Promo Code"}
                      name={"promo_code"}
                      inputProps={{
                        name: "promo_code",
                        placeholder: "Promo Code",
                        value: this.state.values["promo_code"],
                        disabled: this.isPromoCodeDisabled(),
                      }}
                      inputActions={inputActions}
                      actions={actions}
                      type={"text"}
                      normal
                      helperText={
                        <>
                          <div className="font-italic">
                            * Promo codes should be letters and numbers only, no
                            special characters or spaces.
                          </div>
                          {this.state.isChecking && (
                            <div className="text-primary">Checking...</div>
                          )}
                        </>
                      }
                      append={
                        this.props.mode === "new" && (
                          <>
                            <div className="px-4">
                              <Button
                                className="btn btn-primary font-weight-bold"
                                style={{
                                  height: KTUtil.isMobileDevice() ? 36 : 39,
                                  width: KTUtil.isMobileDevice() ? 120 : 190,
                                  marginRight: -15,
                                }}
                                onClick={this.generateRandomPromoCode}
                              >
                                {KTUtil.isMobileDevice()
                                  ? "Generate Code"
                                  : "Generate Random Code"}
                              </Button>
                            </div>
                          </>
                        )
                      }
                    />
                    <DisplayRequiredFieldError
                      errorsArray={this.state.errors}
                      item="promo_code"
                    />
                  </div>
                </Card.Body>
              </Card>

              <Card className="card-custom mt-4">
                <Card.Title className="pb-0 mb-0">
                  <h3 className="ml-8 mt-8 mb-0 font-weight-bolder">
                    Promotion Details
                  </h3>
                </Card.Title>
                <Card.Body>
                  <div className="d-flex flex-column flex-grow-1 card-stretch">
                    <Row className="ml-1">
                      <Form.Group>
                        <Label text="Promotion Applies To" />{" "}
                        <RequiredAsterisk />
                        <div className="checkbox-list-inline">
                          {PROMOS_APPLIES_TO.map((page = "", index) => {
                            return (
                              <span className="mr-3" key={index}>
                                <label className={`checkbox text-capitalize`}>
                                  {/* onChange is to this.onChange instead of this.handleOnChange to make the function handle it directly and not do the default assigning of value in this.handleOnChange */}
                                  <input
                                    type="checkbox"
                                    data-value={page}
                                    name={"applies_to"}
                                    onChange={this.onChange}
                                    disabled={this.isAppliesToDisabled(page)}
                                    checked={
                                      this.state.values["applies_to"].indexOf(
                                        page,
                                      ) !== -1
                                        ? "checked"
                                        : ""
                                    }
                                  />{" "}
                                  {Humanize(page)}
                                  <span></span>
                                </label>
                              </span>
                            );
                          })}
                        </div>
                        <DisplayRequiredFieldError
                          errorsArray={this.state.errors}
                          item="applies_to"
                        />
                      </Form.Group>
                    </Row>
                    <Row className="ml-1">
                      <div>
                        <Form.Group>
                          <Label text="Promotion Type" /> <RequiredAsterisk />
                          <div className="radio-inline">
                            {PROMO_TYPES.map((promo_type = "", index) => {
                              return (
                                <span className="mr-3" key={index}>
                                  <label className={`radio text-capitalize`}>
                                    <input
                                      type="radio"
                                      data-value={promo_type}
                                      name={"promo_type"}
                                      onChange={this.handleOnChange}
                                      disabled={!this.state.editMode}
                                      checked={
                                        promo_type ===
                                        this.state.values["promo_type"]
                                          ? "checked"
                                          : ""
                                      }
                                    />{" "}
                                    {Humanize(promo_type)}
                                    <span></span>
                                  </label>
                                </span>
                              );
                            })}
                          </div>
                          <DisplayRequiredFieldError
                            errorsArray={this.state.errors}
                            item="promo_type"
                          />
                        </Form.Group>

                        <DisplayAmountOffForm
                          inputActions={inputActions}
                          actions={actions}
                          values={this.state.values}
                          disabled={!this.state.editMode}
                          errors={this.state.errors}
                        />
                        <DisplayPercentageOffForm
                          inputActions={inputActions}
                          actions={actions}
                          values={this.state.values}
                          disabled={!this.state.editMode}
                          errors={this.state.errors}
                        />
                        <DisplaySubsidizedDeliveryAmountForm
                          inputActions={inputActions}
                          actions={actions}
                          values={this.state.values}
                          disabled={!this.state.editMode}
                          errors={this.state.errors}
                        />
                        <DisplayFreeItemForm
                          inputActions={inputActions}
                          actions={actions}
                          values={this.state.values}
                          disabled={!this.state.editMode}
                          errors={this.state.errors}
                        />
                      </div>
                    </Row>
                  </div>
                  <div className="font-italic">
                    * Promos reducing final checkout price below 100 will not
                    push thru since there is a P100 minimum purchase upon
                    checkout.
                  </div>
                </Card.Body>
              </Card>

              <Card className="card-custom gutter-b mt-4">
                <Card.Title className="pb-0 mb-0">
                  <h3 className="ml-8 mt-8 mb-0 font-weight-bolder">
                    Promotion Conditions
                  </h3>{" "}
                </Card.Title>
                <Card.Body>
                  <div className="d-flex flex-column flex-grow-1 card-stretch">
                    <Row>
                      <Col>
                        <Form.Group>
                          {/* <Label
                      text={
                        <>
                          <span>Promotion requires a minimum purchase</span>{" "}
                          <span>Minimum Purchase Amount</span>{" "}
                          <RequiredAsterisk />{" "}
                          {InfoTooltip(ht.promos.has_minimum_purchase)}{" "}
                        </>
                      }
                    /> */}
                          <div>
                            {/* <span className="switch switch-outline switch-icon switch-primary switch-sm">
                        <label>
                          <input
                            type="checkbox"
                            name="has_minimum_purchase"
                            disabled={!this.state.editMode}
                            checked={
                              this.state.values.has_minimum_purchase
                                ? "checked"
                                : null
                            }
                            {...inputActions}
                            onChange={this.onChange}
                          />
                          <span></span>
                        </label>
                      </span> */}
                            <DisplayMinimumPurchaseForm
                              inputActions={inputActions}
                              actions={actions}
                              values={this.state.values}
                              disabled={!this.state.editMode}
                            />
                          </div>
                          <DisplayRequiredFieldError
                            errorsArray={this.state.errors}
                            item="minimum_purchase"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Label
                            text={
                              <>
                                <span>
                                  Limit number of times promotion can be used{" "}
                                </span>{" "}
                                {InfoTooltip(ht.promos.has_limit)}{" "}
                              </>
                            }
                          />
                          <div>
                            <span className="switch switch-outline switch-icon switch-primary switch-sm">
                              <label>
                                <input
                                  type="checkbox"
                                  name="has_limit"
                                  disabled={!this.state.editMode}
                                  checked={
                                    this.state.values.has_limit
                                      ? "checked"
                                      : null
                                  }
                                  {...inputActions}
                                  onChange={this.onChange}
                                />
                                <span></span>
                              </label>
                            </span>
                            <DisplayUsageLimitForm
                              inputActions={inputActions}
                              actions={actions}
                              values={this.state.values}
                              disabled={!this.state.editMode}
                            />
                          </div>
                          <DisplayRequiredFieldError
                            errorsArray={this.state.errors}
                            item="usage_limit"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <hr />

                    <Form.Group>
                      <Label
                        text={
                          <>
                            <span className="text-capitalize">
                              promotion scope
                            </span>{" "}
                            <RequiredAsterisk />{" "}
                            {InfoTooltip(ht.promos.promo_scope)}{" "}
                          </>
                        }
                      />
                      <div className="radio-inline">
                        {PROMO_SCOPE_LEVELS.map((promo_scope = "", index) => {
                          if (
                            this.props.mode === "view" &&
                            this.state.editMode &&
                            isPromoScopeAllowedForUserLevel(
                              // this.isScopeAllowedForUserLevel(
                              this.props.user.iam_role,
                              promo_scope,
                            )
                          ) {
                            return (
                              <label
                                key={index}
                                className={`radio text-capitalize`}
                              >
                                <input
                                  type="radio"
                                  data-value={promo_scope}
                                  name={"scope"}
                                  onChange={this.handleOnChange}
                                  disabled={!this.state.editMode}
                                  checked={
                                    promo_scope === this.state.values["scope"]
                                      ? "checked"
                                      : ""
                                  }
                                />{" "}
                                {Humanize(promo_scope)}
                                <span></span>
                              </label>
                            );
                          } else if (
                            this.props.mode === "view" &&
                            !this.state.editMode &&
                            promo_scope === this.state.values["scope"]
                          ) {
                            return (
                              <label
                                key={index}
                                className={`radio text-capitalize`}
                              >
                                <input
                                  type="radio"
                                  data-value={promo_scope}
                                  name={"scope"}
                                  onChange={this.handleOnChange}
                                  disabled={!this.state.editMode}
                                  checked={
                                    promo_scope === this.state.values["scope"]
                                      ? "checked"
                                      : ""
                                  }
                                />{" "}
                                {Humanize(promo_scope)}
                                <span></span>
                              </label>
                            );
                          } else if (
                            this.props.mode === "new" &&
                            isPromoScopeAllowedForUserLevel(
                              // this.isScopeAllowedForUserLevel(
                              this.props.user.iam_role,
                              promo_scope,
                            )
                          ) {
                            return (
                              <label
                                key={index}
                                className={`radio text-capitalize`}
                              >
                                <input
                                  type="radio"
                                  data-value={promo_scope}
                                  name={"scope"}
                                  onChange={this.handleOnChange}
                                  disabled={!this.state.editMode}
                                  checked={
                                    promo_scope === this.state.values["scope"]
                                      ? "checked"
                                      : ""
                                  }
                                />{" "}
                                {Humanize(promo_scope)}
                                <span></span>
                              </label>
                            );
                          } else if (
                            this.props.mode === "copy" &&
                            isPromoScopeAllowedForUserLevel(
                              // this.isScopeAllowedForUserLevel(
                              this.props.user.iam_role,
                              promo_scope,
                            )
                          ) {
                            return (
                              <label
                                key={index}
                                className={`radio text-capitalize`}
                              >
                                <input
                                  type="radio"
                                  data-value={promo_scope}
                                  name={"scope"}
                                  onChange={this.handleOnChange}
                                  disabled={!this.state.editMode}
                                  checked={
                                    promo_scope === this.state.values["scope"]
                                      ? "checked"
                                      : ""
                                  }
                                />{" "}
                                {Humanize(promo_scope)}
                                <span></span>
                              </label>
                            );
                          }
                        })}
                      </div>
                      <DisplayRequiredFieldError
                        errorsArray={this.state.errors}
                        item="scope"
                      />
                    </Form.Group>

                    <div>
                      <DisplayPromoBrandsDropdown
                        disabled={!this.state.editMode}
                        brands={this.state.values.brands}
                        scope={
                          // this.state.values["scope"] === "brand" ? "brand_admin" : ""
                          this.state.values["scope"]
                        }
                        iam_role={
                          // this.state.values["scope"] === "brand" ? "brand_admin" : ""
                          this.props.user ? this.props.user.iam_role : ""
                        }
                        action={(selected) => {
                          this.onChangeSelectScope(selected, "brands");
                        }}
                        actions={actions}
                        onBlur={() => {
                          this.onBlurSelectScope("brands");
                        }}
                        mode={this.props.mode}
                        editMode={this.state.editMode}
                        // {...this.props}
                      />
                      <DisplayRequiredFieldError
                        errorsArray={this.state.errors}
                        item="brands"
                      />
                    </div>

                    <div>
                      <DisplayPromoStoresDropdown
                        disabled={!this.state.editMode}
                        stores={this.state.values.stores}
                        scope={
                          // this.state.values["scope"] === "store" ? "store_admin" : ""
                          this.state.values["scope"]
                        }
                        iam_role={
                          // this.state.values["scope"] === "store" ? "store_admin" : ""
                          this.props.user ? this.props.user.iam_role : ""
                        }
                        action={(selected) => {
                          this.onChangeSelectScope(selected, "stores");
                        }}
                        actions={actions}
                        onBlur={() => {
                          this.onBlurSelectScope("stores");
                        }}
                        mode={this.props.mode}
                        editMode={this.state.editMode}
                        // {...this.props}
                      />
                      <DisplayRequiredFieldError
                        errorsArray={this.state.errors}
                        item="stores"
                      />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={4}>
              <Card className="card-custom mt-4">
                <Card.Title className="pb-0 mb-0">
                  <h3 className="ml-8 mt-8 font-weight-bolder">Settings</h3>
                </Card.Title>
                <Card.Body>
                  <div className="row d-flex flex-row justify-content-between align-items-center">
                    <span className="switch switch-outline switch-icon switch-primary switch-sm">
                      <div
                        // style={{ transform: "scale(1.4)" }}
                        className="d-flex flex-column align-items-center"
                      >
                        <div className="text-dark font-weight-bolder text-uppercase mb-2 pl-2 pr-0">
                          Promotion Status
                        </div>
                        <div className="d-flex flex-row align-self-start justify-content-center">
                          <label className="ml-4">
                            <input
                              type="checkbox"
                              name="active"
                              disabled={!this.state.editMode}
                              checked={
                                this.state.values.active ? "checked" : null
                              }
                              {...inputActions}
                              onChange={this.onChange}
                            />
                            <label className="ml-2 mb-0 mt-2">
                              {this.state.values.active ? "Active" : "Inactive"}
                            </label>
                            <span></span>
                          </label>
                        </div>
                      </div>
                    </span>
                  </div>
                  {FEATURED_PROMO_ON && MARKET_SETTINGS_AVAILABLE && (
                    <div className="row d-flex flex-row justify-content-between align-items-center">
                      <span className="switch switch-outline switch-icon switch-primary switch-sm">
                        <div
                          // style={{ transform: "scale(1.4)" }}
                          className="d-flex flex-column align-items-center"
                        >
                          <div className="text-dark font-weight-bolder text-uppercase mb-2 pl-2 pr-0">
                            Marketplace Feature Promo
                            {InfoTooltip(ht.promos.featured)}{" "}
                          </div>
                          <div className="d-flex flex-row align-self-start justify-content-center">
                            <label className="ml-4">
                              <input
                                type="checkbox"
                                name="featured"
                                disabled={!this.state.editMode}
                                checked={
                                  this.state.values.featured ? "checked" : null
                                }
                                {...inputActions}
                                onChange={this.onChange}
                              />
                              <label className="ml-2 mb-0 mt-2">
                                {this.state.values.featured ? "Yes" : "No"}
                              </label>
                              <span></span>
                            </label>
                          </div>
                        </div>
                      </span>
                    </div>
                  )}

                  <hr />
                  <div className="text-dark font-weight-bolder text-uppercase pr-0 mb-1">
                    Active Dates
                  </div>
                  <div>
                    <Form.Group>
                      <Label
                        text={
                          <>
                            <span className="text-capitalize">start date</span>{" "}
                            <RequiredAsterisk />{" "}
                            {InfoTooltip(ht.promos.start_date)}{" "}
                          </>
                        }
                      />
                      <div>
                        {this.state.mountStartDatePicker ? (
                          <>
                            <DatePicker
                              disabled={!this.state.editMode}
                              minDate={new Date()}
                              maxDate={
                                this.state.values["end_date"] === ""
                                  ? null
                                  : new Date(this.state.values["end_date"])
                              }
                              selected={
                                this.state.values["start_date"] === ""
                                  ? null
                                  : new Date(this.state.values["start_date"])
                              }
                              className="form-control"
                              onChange={(date) => {
                                this.onClickOnChangeDate(date, "start_date");
                              }}
                              placeholderText={`📅 Select start date`}
                              wrapperClassName={"width-100"}
                              dateFormat={"MMM dd, yyyy"}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <DisplayRequiredFieldError
                        errorsArray={this.state.errors}
                        item="start_date"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Label
                        text={
                          <>
                            <span className="text-capitalize">
                              set end date
                            </span>{" "}
                          </>
                        }
                      />
                      <div>
                        <span className="switch switch-outline switch-icon switch-primary switch-sm">
                          <label>
                            <input
                              type="checkbox"
                              name="has_end_date"
                              disabled={!this.state.editMode}
                              checked={
                                this.state.values.has_end_date
                                  ? "checked"
                                  : null
                              }
                              {...inputActions}
                              onChange={this.onChange}
                            />
                            <span></span>
                          </label>
                        </span>
                      </div>

                      <div>
                        {this.state.values.has_end_date ? (
                          <>
                            <Label
                              text={
                                <>
                                  <span className="text-capitalize">
                                    end date
                                  </span>{" "}
                                  <RequiredAsterisk />{" "}
                                  {InfoTooltip(ht.promos.end_date)}{" "}
                                </>
                              }
                            />

                            <DatePicker
                              disabled={!this.state.editMode}
                              minDate={
                                this.state.values["start_date"] !== ""
                                  ? new Date(this.state.values["start_date"])
                                  : new Date()
                              }
                              selected={
                                this.state.values["end_date"] === ""
                                  ? null
                                  : new Date(this.state.values["end_date"])
                              }
                              className="form-control"
                              onChange={(date) => {
                                this.onClickOnChangeDate(date, "end_date");
                              }}
                              placeholderText={`📅 Select end date`}
                              wrapperClassName={"width-100"}
                              dateFormat={"MMM dd, yyyy"}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <DisplayRequiredFieldError
                        errorsArray={this.state.errors}
                        item="end_date"
                      />
                    </Form.Group>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      </>
    );
  }

  isAppliesToDisabled(applies_to) {
    // console.log("promoForm isAppliesToDisabled applies_to: ", applies_to);
    if (!this.state.editMode) return true;
    if (this.state.values.promo_type === "free_delivery") {
      if (applies_to === "meal_plan" || applies_to === "delivery") {
        // console.log("in");
        return false;
      } else return true;
    }
    return false;
  }

  isPromoCodeDisabled() {
    let isDisabled = true;
    if (this.props.mode === "new") {
      isDisabled = false;
    } else if (this.props.mode === "view") {
      isDisabled = true;
    } else if (this.props.mode === "copy") {
      isDisabled = false;
    }
    return isDisabled;
  }
}

const DisplayAmountOffForm = ({
  values = {},
  inputActions,
  actions,
  disabled,
  errors,
}) => {
  return values["promo_type"] === "amount_off" ? (
    <>
      <FormItem
        label={"Amount Off"}
        name={"amount_off"}
        inputProps={{
          name: "amount_off",
          placeholder: "Amount Off",
          value: values["amount_off"],
          disabled,
          min: 0,
        }}
        inputActions={inputActions}
        actions={actions}
        type={"number"}
        showRequired={true}
        normal
      />
      <DisplayRequiredFieldError errorsArray={errors} item="amount_off" />
    </>
  ) : (
    <></>
  );
};

const DisplayPercentageOffForm = ({
  values = {},
  inputActions,
  actions,
  disabled,
  errors,
}) => {
  return values["promo_type"] === "percentage_off" ? (
    <>
      <FormItem
        label={"Percentage Off (%)"}
        name={"percentage_off"}
        inputProps={{
          name: "percentage_off",
          placeholder: "Percentage Off",
          value: values["percentage_off"],
          disabled,
          min: 1,
        }}
        inputActions={inputActions}
        actions={actions}
        type={"number"}
        showRequired={true}
        normal
      />
      <DisplayRequiredFieldError errorsArray={errors} item="percentage_off" />
    </>
  ) : (
    <></>
  );
};

const DisplaySubsidizedDeliveryAmountForm = ({
  values = {},
  inputActions,
  actions,
  disabled,
  errors,
}) => {
  return values["promo_type"] === "free_delivery" ? (
    <>
      <FormItem
        label={"Subsidized Delivery Amount"}
        name={"subsidized_delivery_amount"}
        inputProps={{
          name: "subsidized_delivery_amount",
          placeholder: "Subsidized Delivery Amount",
          value: values["subsidized_delivery_amount"],
          disabled,
          min: 0,
        }}
        inputActions={inputActions}
        actions={actions}
        type={"number"}
        showRequired={true}
        normal
      />
      <DisplayRequiredFieldError
        errorsArray={errors}
        item="subsidized_delivery_amount"
      />
    </>
  ) : (
    <></>
  );
};

const DisplayFreeItemForm = ({
  values = {},
  inputActions,
  actions,
  disabled,
  errors,
}) => {
  return values["promo_type"] === "free_item" ? (
    <>
      <FormItem
        label={"Free Item Description"}
        name={"free_item_description"}
        inputProps={{
          name: "free_item_description",
          placeholder: "Free Item",
          value: values["free_item_description"],
          disabled,
          min: 0,
        }}
        inputActions={inputActions}
        actions={actions}
        type={"string"}
        showRequired={true}
        normal
      />
      <DisplayRequiredFieldError
        errorsArray={errors}
        item="free_item_description"
      />
    </>
  ) : (
    <></>
  );
};

function DisplayMinimumPurchaseForm({
  values = {},
  inputActions,
  actions,
  disabled,
}) {
  return (
    <>
      <FormItem
        label={
          <>
            <span>Minimum Purchase Amount</span> <RequiredAsterisk />{" "}
            {InfoTooltip(ht.promos.has_minimum_purchase)}{" "}
          </>
        }
        name={"minimum_purchase"}
        inputProps={{
          name: "minimum_purchase",
          placeholder: "Minimum Purchase Amount",
          value: values["minimum_purchase"],
          disabled,
          min: 0,
        }}
        inputActions={inputActions}
        actions={actions}
        type={"number"}
        // showRequired={true}
        normal
      />
    </>
  );
}

function DisplayUsageLimitForm({
  values = {},
  inputActions,
  actions,
  disabled,
}) {
  return values["has_limit"] === true ? (
    <>
      <FormItem
        label={"Usage Limit Amount"}
        name={"usage_limit"}
        inputProps={{
          name: "usage_limit",
          placeholder: "Usage Limit Amount",
          value: values["usage_limit"],
          disabled,
          min: 0,
        }}
        inputActions={inputActions}
        actions={actions}
        type={"number"}
        showRequired={true}
        normal
      />
    </>
  ) : (
    <></>
  );
}

const DisplayRequiredFieldError = ({ errorsArray = [], item = "" }) => {
  // console.log("errorsArray: ", errorsArray, item);
  const errorFound = errorsArray.filter(
    (errorItem) => errorItem.errorField === item,
  );
  // console.log("errorFound: ", errorFound);
  if (!errorFound || !errorFound.length) return <></>;
  // console.log("in");
  // console.log("errorFound.errorMessage: ", errorFound.errorMessage);
  return errorFound && errorFound.length ? (
    <span className="text-danger">
      {errorFound[0].errorMessage
        ? errorFound[0].errorMessage
        : `This field is required.`}
      {/* {errorFound.errorMessage} */}
    </span>
  ) : (
    <></>
  );
};
