import React from "react";
import { toPesoAmount } from "../../helpers";

/**
 * TrackOrderDetailsItem
 * @param {Object} props.item
 * @param {Number} props.index
 * @param {String} props.color
 * @param {Boolean} props.isMealPlan Default: false
 * @param {String} props.status Optional - only needed for meal plan orders
 * @param {String} props.showOrderStatus Optional - only needed for meal plan orders
 * @returns
 */
export default function TrackOrderDetailsItem(props) {
  const { item = {}, qty = 0, price = 0, extras = [] } = props.item,
    { isMealPlan = false, showOrderStatus = false } = props;
  return (
    <>
      <div
        className={`item d-flex flex-wrap align-items-center inactive-link${
          !isMealPlan ? " mv-1rem" : ""
        }`}
        key={props.index}
      >
        <div className="item-text-wrapper d-flex flex-column flex-grow-1">
          <div className="font-weight-bolder" style={{ color: props.color }}>
            {item.name} {isMealPlan && <>x {qty}</>}
          </div>
          {extras.map(({ extra_id = {} }) => (
            <div>+ {extra_id.name}</div>
          ))}
          {!isMealPlan && (
            <div className="font-weight-bold item-price">
              {" "}
              {toPesoAmount(price)} x {qty}{" "}
            </div>
          )}
        </div>
        <div className="item-actions-wrapper d-flex align-items-center">
          {isMealPlan ? (
            <> {showOrderStatus && <DisplayStatus status={props.status} />} </>
          ) : (
            <span className="font-weight-bold">
              {" "}
              {toPesoAmount(price * qty)}{" "}
            </span>
          )}
        </div>
      </div>
      {!isMealPlan && <div className="separator separator-solid"></div>}
    </>
  );
}

function DisplayStatus({ status }) {
  const completed = status.indexOf("completed") > -1;
  return (
    <span
      className={`text-uppercase font-weight-bold ${
        completed ? "text-purple" : "text-danger"
      }`}
    >
      {completed ? "completed" : "not completed"}
    </span>
  );
}
