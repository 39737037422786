import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

class APIComponent extends React.Component {
  constructor(props) {
    super(props);

    const { slug = "" } = this.props;

    this.state = {
      /** data */
      data: [],
      display_data: [], // used for filtering data
      pagination_data: [],
      error: null,
      data_status: "initial",
      tryAgain: false,

      /** layout */
      page_title: slug,
      page_slug: slug,
      page_subheader_options: {},
      page_notifications: [],

      /** component states */

      showSwalError: false,
    };

    this._mounted = false;
    this.data_url = "";
    this.data_identifier = "";
    this.data_model = "";
    this.default_filter_name = "";
    this.use_display_data = false;

    this.handleOnDataFetched = this.handleOnDataFetched.bind(this);
    this.handleOnDataFetchError = this.handleOnDataFetchError.bind(this);
    this.handleOnDataCreate = this.handleOnDataCreate.bind(this);
    this.handleOnDataUpdate = this.handleOnDataUpdate.bind(this);
    this.handleOnDataDelete = this.handleOnDataDelete.bind(this);
    this.handleSearchInput = this.handleSearchInput.bind(this);

    this.handleError = this.handleError.bind(this);

    this.setTitle = this.setTitle.bind(this);
    this.setDisplayData = this.setDisplayData.bind(this);

    this.findItemById = this.findItemById.bind(this);
    this.findItemByIndex = this.findItemByIndex.bind(this);

    this.showSomethingWentWrong = this.showSomethingWentWrong.bind(this);

    this.showSuccessSwal = this.showSuccessSwal.bind(this);
    this.showErrorSwal = this.showErrorSwal.bind(this);

    this.afterComponentDidMount = this.afterComponentDidMount.bind(this);
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    this.getData();
  }

  async getData() {
    if (this.data_url !== "") {
      this._isMounted && this.setState({ data_status: "fetching" });
      this.api &&
        this.api
          .get(this.data_url)
          .then((data) => {
            this._isMounted &&
              this.setState({
                data: data.data,
                data_status: "fetched",
              });
            this.handleOnDataFetched();

            if (this.use_display_data) {
              this.setDisplayData();
            }
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });
    } else {
      this.afterComponentDidMount();
    }
  }

  afterComponentDidMount = () => {
    /** do something */
  };

  /** data */

  setDisplayData = () => {
    const { data = [] } = this.state;
    this.setState({ display_data: data, pagination_data: data });
  };

  handleOnDataFetched = () => {
    /** do something */
  };

  handleOnDataFetchError = () => {
    /** do something */
    const { tryAgain = false, error } = this.state;

    if (tryAgain) {
      console.log("trying again,..");
      this.setState({
        tryAgain: true,
      });
      this.getData();
    } else {
      if (error.hasOwnProperty("response")) {
        const { data = {} } = error.response,
          { message = {} } = data;

        if (message.error === "TokenExpiredError") {
          window.location.href = "/logout";
        } else if (message.error === "UnauthorizedUserError") {
          window.location.href = "/error/error-v3";
        } else if (message.error === "JsonWebTokenError") {
          window.location.href = "/logout?multiple=true";
        } else {
          this.showErrorSwal();
          console.log(error);
        }
      } else {
        this.showErrorSwal();
        console.log(error);
      }
    }
  };

  handleError = () => {
    this.showSomethingWentWrong();
  };

  handleOnDataCreate = () => {
    /** do something */
  };

  handleOnDataUpdate = () => {
    /** do something */
  };

  handleOnDataDelete = () => {
    /** do something */
  };

  showSomethingWentWrong = () => {
    this.setState({ showSwalError: true });
  };

  findItemById = (item_id) => {
    const { data, data_status } = this.state;

    if (data_status === "fetched") {
      const item = data.filter((d) => d._id === item_id);
      if (item.length > 0) return item[0];
      else return {};
    } else {
      return {};
    }
  };

  findItemByIndex = (index) => {
    const { data, data_status } = this.state;

    if (data_status === "fetched") {
      return data[index];
    } else {
      return {};
    }
  };

  // Attach to search input
  // Usage <Form.Control onChange={this.handleSearchInput}/>
  handleSearchInput = (event) => {
    const value = event.target.value;
    const { default_filter_name = "" } = this,
      { data = [] } = this.state;

    if (default_filter_name !== "") {
      this.setState({
        display_data: data.filter((item) => {
          const filter_value = item[default_filter_name];
          const textinput = value.toLowerCase();
          return filter_value.toLowerCase().indexOf(textinput) !== -1;
        }),
      });
    }
  };

  showSuccessSwal = (message = "", timer = false) => {
    let params = { title: "Success!", html: message, icon: "success" };

    if (timer) {
      params.showConfirmButton = false;
      params.timer = 1500;
    }

    MySwal.fire(params);
  };

  showErrorSwal = (message = "Something went wrong!") => {
    MySwal.fire({
      icon: "error",
      title: "Oops...",
      text: message,
    });
  };

  /** layout */

  setTitle = () => {
    /** do something */
  };
}

export default APIComponent;
