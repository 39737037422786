import { isEmpty } from "lodash";
import React from "react";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import styled from "styled-components";
import { toPesoAmount } from "../../../helpers";
import { Label, SVGIcon } from "../../../components/Base";

export default function ExclusiveCartSummary(props) {
  return (
    <>
      <Card.Body className="d-flex p-4">
        <Col>
          <CartSummary {...props} />
        </Col>
      </Card.Body>
    </>
  );
}

const CartSummary = ({
  items = [],
  onCartUpdate,
  onQuantityUpdate,
  promo = {},
  serviceCharge = {},
  store = {},
}) => {
  const { menu = [] } = store;

  const items_available = menu
    .filter(({ available, item_id }) => available && item_id.isExclusive)
    .map(({ item_id }) => item_id._id);

  const sub_total = items
    .filter(({ item }) => items_available.indexOf(item._id) > -1)
    .reduce((sum, item) => sum + item.price * item.qty, 0);

  const getCartDiscount = (type, value, total) => {
    let retVal = 0;
    if (type === "amount_off") {
      const amount_off = Number(value);
      retVal = amount_off > total ? total : total - amount_off;
    } else if (type === "percentage_off") {
      const percentage_off = Number(total) * (Number(value) / 100);
      const cartDiscount =
        percentage_off > total ? total : total - percentage_off;
      retVal = Math.round(cartDiscount);
    }
    return retVal;
  };

  const getCartServiceCharge = (type, value, total = 0) => {
    let retVal = total;
    if (type === "amount_charge") {
      retVal = retVal + Number(value);
    } else if (type === "percent_charge") {
      const percentage_charge = Number(retVal) * (Number(value) / 100);
      const total_charge = percentage_charge + retVal;
      retVal = Math.round(total_charge);
    }
    return retVal;
  };

  const getTotalAmount = () => {
    let retVal = sub_total;
    if (
      !isEmpty(promo) &&
      ["free_item", "free_delivery"].indexOf(promo.promo_type) === -1
    ) {
      const { promo_type } = promo;
      retVal = getCartDiscount(promo_type, promo[promo_type], sub_total);
    }
    if (!isEmpty(serviceCharge)) {
      const { type, value } = serviceCharge;
      retVal = getCartServiceCharge(type, value, retVal);
    }
    return retVal;
  };

  return (
    <Col className="p-0 w-100">
      <Card>
        {isEmpty(items) ? (
          <Row className="d-flex m-0 font-weight-bolder w-100 justify-content-center align-items-center py-8">
            Your cart is empty
          </Row>
        ) : (
          <ListGroup variant="flush">
            {items.map((item, idx) => {
              return (
                <ItemCart
                  menu={!isEmpty(store) && store.menu}
                  key={`item-00${idx + 1}`}
                  item={item}
                  index={idx}
                  onCartUpdate={onCartUpdate}
                  onQuantityUpdate={onQuantityUpdate}
                />
              );
            })}
            <ListGroup.Item>
              <div className="d-flex flex-row justify-content-space-between w-100">
                <Col className="d-flex font-weight-bolder p-0">Sub-total</Col>
                <Col className="d-flex font-weight-bolder justify-content-end">
                  {toPesoAmount(sub_total)}
                </Col>
              </div>
              <div className="d-flex flex-row justify-content-space-between w-100">
                <Col className="d-flex font-weight-boldest p-0 text-uppercase">
                  Grand Total
                </Col>
                <Col className="d-flex font-weight-boldest justify-content-end">
                  {toPesoAmount(getTotalAmount())}
                </Col>
              </div>
            </ListGroup.Item>
          </ListGroup>
        )}
      </Card>
    </Col>
  );
};

const ItemCart = ({
  item = {},
  index = -1,
  onCartUpdate,
  onQuantityUpdate,
  menu = [],
}) => {
  const item_menu =
    menu.length > 0 &&
    menu.filter(({ item_id }) => item_id._id === item.item.item_id._id);

  const { item_limit_per_day, item_limit_per_time_slot } =
    item_menu.length > 0 && item_menu[0];

  const item_limit =
    item_limit_per_day > 0
      ? item_limit_per_day
      : !isEmpty(item_limit_per_time_slot) &&
        item_limit_per_time_slot.length > 0
      ? item_limit_per_time_slot[0].limit
      : null;

  return (
    item_menu.length > 0 &&
    item_menu[0].available && (
      <>
        <ListGroup.Item>
          <div className="d-flex flex-row w-100 align-items-center p-0 my-2">
            <Col className="font-weight-bold p-0 " style={{ flexBasis: "48%" }}>
              {item.item.item_id.name} ({toPesoAmount(item.price * item.qty)})
            </Col>
            <Col style={{ flexBasis: "48%" }}>
              <div className="d-flex flex-row w-100 justify-content-end p-0">
                <CounterButton
                  variant="custom"
                  className="btn btn-icon btn-xs br-0 mr-2"
                  data-index={index}
                  data-action="minus"
                  onClick={(e) => {
                    e.preventDefault();
                    onCartUpdate(item.item, false, item.extras);
                  }}
                >
                  <SVGIcon
                    icon={"Navigation/Minus"}
                    title={"Remove"}
                    variant="custom"
                  />
                </CounterButton>
                <Button
                  variant="outline-primary"
                  className="btn-icon btn-xs br-0 mr-2 inactive-link b-none"
                >
                  <span>{item.qty}</span>
                </Button>
                <CounterButton
                  variant="custom"
                  className="btn btn-icon btn-xs br-0"
                  data-index={index}
                  data-action="plus"
                  onClick={(e) => {
                    e.preventDefault();
                    onCartUpdate(item.item, true, item.extras);
                  }}
                  disabled={
                    Number(item.custom_max) > 0 && item_limit === item.qty
                  }
                >
                  <SVGIcon
                    icon={"Navigation/Plus"}
                    title={"Add"}
                    variant="custom"
                  />
                </CounterButton>
              </div>
            </Col>
          </div>
          {item.extras && item.extras.length > 0 && (
            <Col
              className="p-0 font-size-sm text-capitalize text-muted"
              style={{ maxWidth: "75%", whiteSpace: "nowrap" }}
            >
              Add-ons: {item.extras.map((extra) => extra.name).join(", ")}
            </Col>
          )}
          <div className="separator separator-solid my-4" />
          <div className="d-flex flex-row w-100 justify-content-space-between align-items-center p-0">
            <div
              className="d-flex flex-column p-0 mr-4"
              style={{ flexBasis: "48%" }}
            >
              <Label text="Minimum" style={{ marginBottom: "-0.5px" }} />
              <div>
                <input
                  type="number"
                  name="custom_min"
                  min="0"
                  max={
                    (!isEmpty(item.custom_max)
                      ? Number(item.custom_max)
                      : item.qty) - 1
                  }
                  value={item.custom_min || 0}
                  className="form-control"
                  onChange={(e) => {
                    e.preventDefault();
                    onQuantityUpdate(index, e.target.name, e.target.value);
                  }}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
            <div
              className="d-flex flex-column p-0"
              style={{ flexBasis: "48%" }}
            >
              <Label text="Maximum" style={{ marginBottom: "-0.5px" }} />
              <div>
                <input
                  type="number"
                  name="custom_max"
                  min={item.custom_min && Number(item.custom_min) + 1}
                  max={item_limit}
                  value={item.custom_max}
                  className="form-control"
                  onChange={(e) => {
                    e.preventDefault();
                    onQuantityUpdate(index, e.target.name, e.target.value);
                  }}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
          </div>
        </ListGroup.Item>
      </>
    )
  );
};

const CounterButton = styled.button`
  svg g [fill] {
    fill: "#14C49B";
  }
`;
