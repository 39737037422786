import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { ICONS } from "../../components/widgets/EmojiPicker";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers/AssetsHelpers";
import EditItemTagModal from "./EditItemTagModal";
import ItemTag from "../../components/widgets/ItemTag";

const TAGS = [
  { id: "popular", name: "Popular", icon: ICONS.oneHundred },
  { id: "vegan", name: "Vegan", icon: ICONS.vegan },
  { id: "dairyFree", name: "Dairy Free", icon: ICONS.cheese },
  { id: "spicy", name: "Spicy", icon: ICONS.spicy },
  { id: "vegetarian", name: "Vegetarian", icon: ICONS.carrot },
  { id: "glutenFree", name: "Gluten Free", icon: ICONS.dairyFree },
  { id: "newItem", name: "New", icon: ICONS.star },
  { id: "halal", name: "Halal", icon: ICONS.halal },
  { id: "lowCalorie", name: "Low Calorie", icon: ICONS.lowCalorie },
  { id: "lowCarbs", name: "Low Carbohydrate", icon: ICONS.lowCarbs },
  { id: "highProtein", name: "High Protein", icon: ICONS.highProtein },
  {
    id: "lowCholesterol",
    name: "Low Cholesterol ",
    icon: ICONS.lowCholesterol,
  },
  { id: "keto", name: "Keto ", icon: ICONS.keto },
];

export const TAG_NAMES = {
  popular: "Popular",
  vegan: "Vegan",
  dairyFree: "Dairy Free",
  spicy: "Spicy",
  vegetarian: "Vegetarian",
  glutenFree: "Gluten Free",
  newItem: "New",
  halal: "Halal",
  lowCalorie: "Low Calorie",
  lowCarbs: "Low Carbohydrate",
  highProtein: "High Protein",
  lowCholesterol: "Low Cholesterol",
  keto: "Keto",
};

const COLORS = {
  purple: { solid: "#8950FC", alpha: "#C0A1FF" },
  blue: { solid: "#214AF9", alpha: "#214af93d" },
  green: { solid: "#14C49B", alpha: "#14c49b47" },
  red: { solid: "#F64E60", alpha: "#f64e6040" },
  white: { solid: "#FFFFFF", alpha: "#ffffff3d" },
  black: { solid: "#000000", alpha: "#00000040" },
  yellow: { solid: "#FBBC04", alpha: "#FBBC0447" },
  darkgreen: { solid: "#00732F", alpha: "#00732F" },
};

const ORIGINAL = {
  popular: {
    bgColor: COLORS.purple.alpha,
    textColor: COLORS.purple.solid,
    borderColor: COLORS.purple.solid,
    icon: ICONS.fire,
  },
  vegan: {
    bgColor: COLORS.green.alpha,
    textColor: COLORS.green.solid,
    borderColor: COLORS.green.solid,
    icon: ICONS.vegan,
  },
  dairyFree: {
    bgColor: COLORS.blue.alpha,
    textColor: COLORS.blue.solid,
    borderColor: COLORS.blue.solid,
    icon: ICONS.cheese,
  },
  spicy: {
    bgColor: COLORS.red.alpha,
    textColor: COLORS.red.solid,
    borderColor: COLORS.red.solid,
    icon: ICONS.spicy,
  },
  vegetarian: {
    bgColor: COLORS.white.alpha,
    textColor: COLORS.green.solid,
    borderColor: COLORS.green.solid,
    icon: ICONS.carrot,
  },
  glutenFree: {
    bgColor: COLORS.white.alpha,
    textColor: COLORS.yellow.solid,
    borderColor: COLORS.yellow.solid,
    icon: ICONS.dairyFree,
  },
  newItem: {
    bgColor: COLORS.yellow.alpha,
    textColor: COLORS.black.solid,
    borderColor: COLORS.black.solid,
    icon: ICONS.star,
  },
  halal: {
    bgColor: COLORS.darkgreen.solid,
    textColor: COLORS.white.solid,
    borderColor: COLORS.darkgreen.solid,
    icon: ICONS.halal,
  },
  lowCalorie: {
    bgColor: COLORS.purple.alpha,
    textColor: COLORS.purple.solid,
    borderColor: COLORS.purple.solid,
    icon: ICONS.lowCalorie,
  },
  lowCarbs: {
    bgColor: COLORS.purple.alpha,
    textColor: COLORS.purple.solid,
    borderColor: COLORS.purple.solid,
    icon: ICONS.lowCarbs,
  },
  highProtein: {
    bgColor: COLORS.purple.alpha,
    textColor: COLORS.purple.solid,
    borderColor: COLORS.purple.solid,
    icon: ICONS.highProtein,
  },
  lowCholesterol: {
    bgColor: COLORS.purple.alpha,
    textColor: COLORS.purple.solid,
    borderColor: COLORS.purple.solid,
    icon: ICONS.lowCholesterol,
  },
  keto: {
    bgColor: COLORS.purple.alpha,
    textColor: COLORS.purple.solid,
    borderColor: COLORS.purple.solid,
    icon: ICONS.keto,
  },
};

export default function ItemTags({
  setTagSettings,
  setActiveTags,
  tags,
  tagSettings,
  updateChanges,
}) {
  const [checkedTags, setCheckedTags] = useState(tags ? tags : []);

  try {
    tagSettings =
      tagSettings && typeof tagSettings === "string"
        ? JSON.parse(tagSettings)
        : undefined;
  } catch (error) {
    tagSettings = ORIGINAL;
  }

  const [bgColor, setBgColor] = useState({
    popular:
      (typeof tagSettings !== "undefined" &&
        tagSettings.popular.bgColor !== tagSettings.popular.textColor &&
        tagSettings.popular.bgColor) ||
      COLORS.purple.alpha,
    vegan:
      (typeof tagSettings !== "undefined" && tagSettings.vegan.bgColor) ||
      COLORS.green.alpha,
    dairyFree:
      (typeof tagSettings !== "undefined" && tagSettings.dairyFree.bgColor) ||
      COLORS.blue.alpha,
    spicy:
      (typeof tagSettings !== "undefined" && tagSettings.spicy.bgColor) ||
      COLORS.red.alpha,
    vegetarian:
      (tagSettings &&
        tagSettings.vegetarian &&
        tagSettings.vegetarian.bgColor) ||
      COLORS.white.alpha,
    glutenFree:
      (tagSettings &&
        tagSettings.glutenFree &&
        tagSettings.glutenFree.bgColor) ||
      COLORS.white.alpha,
    newItem:
      (tagSettings && tagSettings.newItem && tagSettings.newItem.bgColor) ||
      COLORS.yellow.alpha,
    halal:
      (tagSettings && tagSettings.halal && tagSettings.halal.bgColor) ||
      COLORS.darkgreen.alpha,
    lowCalorie:
      (tagSettings &&
        tagSettings.lowCalorie &&
        tagSettings.lowCalorie.bgColor) ||
      COLORS.purple.alpha,
    lowCarbs:
      (tagSettings && tagSettings.lowCarbs && tagSettings.lowCarbs.bgColor) ||
      COLORS.purple.alpha,
    highProtein:
      (tagSettings &&
        tagSettings.highProtein &&
        tagSettings.highProtein.bgColor) ||
      COLORS.purple.alpha,
    lowCholesterol:
      (tagSettings &&
        tagSettings.lowCholesterol &&
        tagSettings.lowCholesterol.bgColor) ||
      COLORS.purple.alpha,
    keto:
      (tagSettings && tagSettings.keto && tagSettings.keto.bgColor) ||
      COLORS.purple.alpha,
  });

  const [textColor, setTextColor] = useState({
    popular:
      (typeof tagSettings !== "undefined" && tagSettings.popular.textColor) ||
      COLORS.purple.solid,
    vegan:
      (typeof tagSettings !== "undefined" && tagSettings.vegan.textColor) ||
      COLORS.green.solid,
    dairyFree:
      (typeof tagSettings !== "undefined" && tagSettings.dairyFree.textColor) ||
      COLORS.blue.solid,
    spicy:
      (typeof tagSettings !== "undefined" && tagSettings.spicy.textColor) ||
      COLORS.red.solid,
    vegetarian:
      (tagSettings &&
        tagSettings.vegetarian &&
        tagSettings.vegetarian.textColor) ||
      COLORS.green.solid,
    glutenFree:
      (tagSettings &&
        tagSettings.glutenFree &&
        tagSettings.glutenFree.textColor) ||
      COLORS.yellow.solid,
    newItem:
      (tagSettings && tagSettings.newItem && tagSettings.newItem.textColor) ||
      COLORS.black.solid,
    halal:
      (tagSettings && tagSettings.halal && tagSettings.halal.textColor) ||
      COLORS.white.solid,
    lowCalorie:
      (tagSettings &&
        tagSettings.lowCalorie &&
        tagSettings.lowCalorie.textColor) ||
      COLORS.purple.solid,
    lowCarbs:
      (tagSettings && tagSettings.lowCarbs && tagSettings.lowCarbs.textColor) ||
      COLORS.purple.solid,
    highProtein:
      (tagSettings &&
        tagSettings.highProtein &&
        tagSettings.highProtein.textColor) ||
      COLORS.purple.solid,
    lowCholesterol:
      (tagSettings &&
        tagSettings.lowCholesterol &&
        tagSettings.lowCholesterol.textColor) ||
      COLORS.purple.solid,
    keto:
      (tagSettings && tagSettings.keto && tagSettings.keto.textColor) ||
      COLORS.purple.solid,
  });

  const [borderColor, setBorderColor] = useState({
    popular:
      (typeof tagSettings !== "undefined" && tagSettings.popular.borderColor) ||
      COLORS.purple.solid,
    vegan:
      (typeof tagSettings !== "undefined" && tagSettings.vegan.borderColor) ||
      COLORS.green.solid,
    dairyFree:
      (typeof tagSettings !== "undefined" &&
        tagSettings.dairyFree.borderColor) ||
      COLORS.blue.solid,
    spicy:
      (typeof tagSettings !== "undefined" && tagSettings.spicy.borderColor) ||
      COLORS.red.solid,
    vegetarian:
      (tagSettings &&
        tagSettings.vegetarian &&
        tagSettings.vegetarian.borderColor) ||
      COLORS.green.solid,
    glutenFree:
      (tagSettings &&
        tagSettings.glutenFree &&
        tagSettings.glutenFree.borderColor) ||
      COLORS.yellow.solid,
    newItem:
      (tagSettings && tagSettings.newItem && tagSettings.newItem.borderColor) ||
      COLORS.black.solid,
    halal:
      (tagSettings && tagSettings.halal && tagSettings.halal.borderColor) ||
      COLORS.black.solid,
    lowCalorie:
      (tagSettings &&
        tagSettings.lowCalorie &&
        tagSettings.lowCalorie.borderColor) ||
      COLORS.purple.solid,
    lowCarbs:
      (tagSettings &&
        tagSettings.lowCarbs &&
        tagSettings.lowCarbs.borderColor) ||
      COLORS.purple.solid,
    highProtein:
      (tagSettings &&
        tagSettings.highProtein &&
        tagSettings.highProtein.borderColor) ||
      COLORS.purple.solid,
    lowCholesterol:
      (tagSettings &&
        tagSettings.lowCholesterol &&
        tagSettings.lowCholesterol.borderColor) ||
      COLORS.purple.solid,
    keto:
      (tagSettings && tagSettings.keto && tagSettings.keto.borderColor) ||
      COLORS.purple.solid,
  });

  const [icon, setIcon] = useState({
    popular:
      (typeof tagSettings !== "undefined" && tagSettings.popular.icon) ||
      ICONS.oneHundred,
    vegan:
      (typeof tagSettings !== "undefined" && tagSettings.vegan.icon) ||
      ICONS.vegan,
    dairyFree:
      (typeof tagSettings !== "undefined" && tagSettings.dairyFree.icon) ||
      ICONS.cheese,
    spicy:
      (typeof tagSettings !== "undefined" && tagSettings.spicy.icon) ||
      ICONS.spicy,
    vegetarian:
      (tagSettings && tagSettings.vegetarian && tagSettings.vegetarian.icon) ||
      ICONS.carrot,
    glutenFree:
      (tagSettings && tagSettings.glutenFree && tagSettings.glutenFree.icon) ||
      ICONS.dairyFree,
    newItem:
      (tagSettings && tagSettings.newItem && tagSettings.newItem.icon) ||
      ICONS.star,
    halal:
      (tagSettings && tagSettings.halal && tagSettings.halal.icon) ||
      ICONS.halal,
    lowCalorie:
      (tagSettings && tagSettings.lowCalorie && tagSettings.lowCalorie.icon) ||
      ICONS.lowCalorie,
    lowCarbs:
      (tagSettings && tagSettings.lowCarbs && tagSettings.lowCarbs.icon) ||
      ICONS.lowCarbs,
    highProtein:
      (tagSettings &&
        tagSettings.highProtein &&
        tagSettings.highProtein.icon) ||
      ICONS.highProtein,
    lowCholesterol:
      (tagSettings &&
        tagSettings.lowCholesterol &&
        tagSettings.lowCholesterol.icon) ||
      ICONS.lowCholesterol,
    keto:
      (tagSettings && tagSettings.keto && tagSettings.keto.icon) || ICONS.keto,
  });

  const onTagToggled = (event) => {
    event.persist();
    let selectedTags = [];
    if (event.target.checked) {
      setCheckedTags((prevState) => {
        selectedTags = [...prevState, event.target.name];
        return selectedTags;
      });
    } else {
      setCheckedTags((prevState) => {
        const newTags = [...prevState];
        selectedTags = newTags.filter((tag) => tag !== event.target.name);
        return selectedTags;
      });
    }
    updateChanges(
      "tags",
      selectedTags.length > 0 ? selectedTags.join(", ") : "None",
    );
  };

  const onBgColorChanged = (tagId, color) => {
    setBgColor((prevState) => ({ ...prevState, [tagId]: color }));
  };

  const onTextColorChanged = (tagId, color) => {
    setTextColor((prevState) => ({ ...prevState, [tagId]: color }));
    setBorderColor((prevState) => ({ ...prevState, [tagId]: color }));
  };

  const onIconChanged = (tagId, icon) => {
    setIcon((prevState) => ({ ...prevState, [tagId]: icon }));
  };

  useEffect(() => {
    const updateParentState = () => {
      const settings = {};
      TAGS.map((t) => t.id).forEach((id) => {
        settings[id] = {
          bgColor: bgColor[id],
          textColor: textColor[id],
          borderColor: borderColor[id],
          icon: icon[id],
        };
      });
      setTagSettings(settings);
      setActiveTags(checkedTags);
    };

    updateParentState();
  }, [
    checkedTags,
    bgColor,
    textColor,
    borderColor,
    icon,
    setActiveTags,
    setTagSettings,
  ]);

  const [showEditModal, setShowEditModal] = useState(false);
  const [tagForEdit, setTagForEdit] = useState({
    id: "",
    icon: "",
    bgColor: "",
    textColor: "",
    borderColor: "",
    name: "",
  });

  return (
    <>
      <div className="row">
        {TAGS.map((tag, i) => {
          const selectedBgColor = bgColor[tag.id];
          const selectedBorderColor = borderColor[tag.id];
          const selectedTextColor = textColor[tag.id];
          const selectedIcon = icon[tag.id];
          const Tag = () => {
            return (
              <ItemTag
                backgroundColor={selectedBgColor}
                textColor={selectedTextColor}
                borderColor={selectedBorderColor}
                icon={selectedIcon}
                name={tag.name}
              />
            );
          };

          return (
            <Col key={i} sm={12} xs={12} className="my-5">
              <div
                className="row"
                style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
              >
                <div className="col-10">
                  <span className="switch switch-outline switch-icon switch-primary mr-4">
                    <label className="row">
                      <div className="">
                        <input
                          type="checkbox"
                          defaultChecked={checkedTags.includes(tag.id)}
                          name={tag.id}
                          onChange={onTagToggled}
                        />
                        <span></span>
                      </div>
                      <div className="pl-3 mt-1 ml-sm-3 ml-lg-5">
                        <Tag />
                      </div>
                    </label>
                  </span>
                </div>
                <div className="col-2">
                  <div className="d-flex justify-content-center">
                    <span
                      className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort"
                      onClick={() => {
                        setTagForEdit({
                          id: tag.id,
                          icon: selectedIcon,
                          bgColor: selectedBgColor,
                          textColor: selectedTextColor,
                          borderColor: selectedBorderColor,
                          name: tag.name,
                        });
                        setShowEditModal(true);
                      }}
                    >
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </div>

      <EditItemTagModal
        show={showEditModal}
        setShow={setShowEditModal}
        tag={tagForEdit}
        onBgColorChanged={onBgColorChanged}
        onTextColorChanged={onTextColorChanged}
        onIconChanged={onIconChanged}
        original={ORIGINAL}
        toggleIsChanged={updateChanges}
      />
    </>
  );
}
