import React from "react";
import { Button, Card } from "react-bootstrap";
import APIComponent from "../components/API";
import { Block, KTLink, Label } from "../components/Base";
import { generatePickupPhLink } from "../helpers";
import core from "../vendors/core-api/";
// import ThemeColorsForm from "./forms/ThemeColorsForm";

export default class WebsiteStatusFormDiv extends React.Component {
  render() {
    const {
      data = {},
      hasPaymentGateway = false,
      isStore = false,
      store_url = "",
      store_id,
    } = this.props;
    const websiteType = isStore ? "store" : "brand";
    const websiteURL = isStore
      ? `${data.brand.brand_url}${process.env.REACT_APP_DOMAIN}/${data.store_url}`
      : generatePickupPhLink(data.brand_url, false);
    const pickUpLink = isStore
      ? `https://${websiteURL}`
      : generatePickupPhLink(data.brand_url, true);

    return (
      <>
        <Card className="card-custom gutter-b">
          <Card.Header>
            <Card.Title>Website Status</Card.Title>
          </Card.Header>
          <Card.Body>
            {isStore && store_url === "" ? (
              <>
                <p>Store URL has not been set yet.</p>
                <a
                  className="btn btn-primary"
                  href={`/stores/${store_id}/settings`}
                >
                  Set URL
                </a>
              </>
            ) : (
              <Block
                content={
                  <>
                    <Label text="Website Status" />
                    <div className="mv-1rem">
                      <p>
                        Your {websiteType} website{" "}
                        {data.website_status === "private" ? (
                          ""
                        ) : (
                          <a href={pickUpLink} target="_blank" rel="noreferrer">
                            {websiteURL}
                          </a>
                        )}{" "}
                        is {data.website_status}.{" "}
                        {data.website_status === "private" && !isStore ? (
                          <PreviewLink id={data._id} data={data} />
                        ) : (
                          <></>
                        )}{" "}
                      </p>
                      {data.website_status === "public" ? (
                        <Button
                          variant="outline-danger"
                          onClick={this.props.handleOnClickWebsiteStatusBtn}
                          data-action="private"
                          className="font-weight-bolder"
                        >
                          UNPUBLISH YOUR SITE
                        </Button>
                      ) : (
                        <Button
                          variant="outline-primary"
                          onClick={this.props.handleOnClickWebsiteStatusBtn}
                          data-action="public"
                          className={`font-weight-bolder${
                            !hasPaymentGateway ? " inactive-link" : ""
                          }`}
                          disabled={!hasPaymentGateway ? "disabled" : ""}
                        >
                          PUBLISH YOUR SITE
                        </Button>
                      )}
                    </div>
                  </>
                }
              />
            )}
          </Card.Body>
        </Card>
      </>
    );
  }
}

class PreviewLink extends APIComponent {
  constructor(props) {
    super(props);

    const group_url = this.props.data.group_url;

    this.api = core("pu").get();
    if (group_url === undefined) {
      this.data_url = "/brand/" + this.props.id + "/generate-preview-token";
    } else {
      this.data_url = "/client/" + this.props.id + "/generate-preview-token";
    }
  }

  handleOnDataFetched = () => {
    const { data = {} } = this.state;
    this.setState({ token: data.token });
  };

  render() {
    const { data_status = "", token = "" } = this.state,
      { data = {} } = this.props,
      isLoading = data_status !== "fetched";

    return isLoading ? (
      <></>
    ) : (
      <KTLink
        text={<>Preview your site here.</>}
        link={
          data.group_url !== undefined
            ? `${generatePickupPhLink(data.group_url)}?preview_token=${token}`
            : `${generatePickupPhLink(data.brand_url)}?preview_token=${token}`
        }
        external
      />
    );
  }
}
