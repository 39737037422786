import React from "react";
// import { generateDateOptions, generateTimeOptions } from "../../helpers";
import { Form } from "react-bootstrap";
import { Label, RequiredAsterisk } from "../../components/Base";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { formatOrderType } from "../../helpers";
import axios from "axios";

const instance = axios.create({});
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => Promise.reject(err),
);

export default class OrderDateDropdown extends APIComponent {
  constructor(props) {
    super(props);

    const { store = {} } = this.props;

    this.api = core("pu").get();
    this.data_url =
      store && store.hasOwnProperty("_id")
        ? `/branded-website/stores/${store._id}/date-options`
        : "";
  }

  async getData() {
    if (this.data_url !== "") {
      this._isMounted && this.setState({ data_status: "fetching" });

      const baseURL = this.api.getBaseURL(),
        { brand_url = "", store = {} } = this.props;

      instance
        .get(`/branded-website/stores/${store._id}/date-options`, {
          headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
          baseURL,
        })
        .then(({ data }) => {
          const filtered_options = this.formatPreOrderOptions(data);
          this._isMounted &&
            this.setState({
              date_options: filtered_options,
              data_status: "fetched",
            });
          this.handleOnDataFetched();

          if (this.use_display_data) {
            this.setDisplayData();
          }
        })
        .catch((error) => {
          const { response = {} } = error,
            { data = {} } = response,
            { message = {} } = data;
          if (
            message.hasOwnProperty("error") &&
            message.error === "StoreNotOpenForOrdersError"
          ) {
            this.props.onStoreNotOpenForOrdersError &&
              this.props.onStoreNotOpenForOrdersError();
          } else {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          }
        });
    }
  }

  handleOnDataFetched = () => {
    this.props.afterFetch && this.props.afterFetch(this.state.date_options);
  };

  formatPreOrderOptions = (date_options = []) => {
    if (date_options) {
      const filteredData = date_options.filter(
        (option) =>
          !(
            option.time_options.length === 1 &&
            option.time_options[0].value === "asap_order_time"
          ),
      );
      return filteredData;
    } else {
      return date_options;
    }
  };

  render() {
    const {
        inputActions,
        hasError = false,
        error = "",
        order_type = "",
        value = "",
      } = this.props,
      { date_options = [], data_status = "" } = this.state;
    const isLoading = data_status !== "fetched";

    return (
      <>
        {isLoading ? (
          <>
            <Form.Group className="w-100 mx-1">
              <Label
                text={
                  <>
                    <span className="text-capitalize">
                      {formatOrderType(order_type)} date
                    </span>{" "}
                    <RequiredAsterisk />{" "}
                  </>
                }
              />
              <Form.Control
                as="select"
                value={value}
                {...inputActions}
                name="order_date"
              >
                <option value="">
                  {" "}
                  Select {formatOrderType(order_type)} date
                </option>
              </Form.Control>
            </Form.Group>
          </>
        ) : (
          <>
            <Form.Group className="w-100 mx-1">
              <Label
                text={
                  <>
                    <span className="text-capitalize">
                      {formatOrderType(order_type)} date
                    </span>{" "}
                    <RequiredAsterisk />{" "}
                  </>
                }
              />
              <Form.Control
                as="select"
                value={value}
                {...inputActions}
                name="order_date"
              >
                <option value="">
                  {" "}
                  Select {formatOrderType(order_type)} date
                </option>
                {date_options.map((date, index) => (
                  <option value={date.value} key={index}>
                    {date.text}
                  </option>
                ))}
              </Form.Control>
              <div className="fv-plugins-message-container">
                {hasError ? (
                  <div className="fv-help-block">{error}</div>
                ) : (
                  <></>
                )}
              </div>
            </Form.Group>
          </>
        )}
      </>
    );
  }
}
