import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

const { REACT_APP_GANALYTICS_ID } = process.env;
const { REACT_APP_GTM_ID } = process.env;

const tagManagerArgs = {
  gtmId: REACT_APP_GTM_ID,
};

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // if (!window.location.href.includes("localhost")) {
    ReactGA.initialize(REACT_APP_GANALYTICS_ID);
    TagManager.initialize(tagManagerArgs);
    // console.log("Google Analytics Initialized")
    // }
    setInitialized(true);
  }, []);

  useEffect(() => {
    // console.log("location", location)
    if (initialized) {
      // ReactGA.pageview(location.pathname + location.search);
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });
      // console.log("Google Analytics Pageview sent")
    }
  }, [initialized, location]);
};

export default usePageTracking;
