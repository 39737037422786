/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import objectPath from "object-path";
import Chart from "react-apexcharts";
import { useHtmlClassService } from "../../../_metronic/layout";
import { GRAPH_COLORS } from "../../constants";

export function Bargraph({
  className = "",
  series = [],
  categories = [],
  customOptions = {},
  header = "",
  height = 350,
  children,
}) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500",
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200",
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300",
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger",
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  const options = getChartOptions({
    layoutProps,
    series,
    categories,
    customOptions,
  });

  return (
    <div className={`card card-custom bg-white ${className}`} id={className}>
      {/* Header */}
      {header && (
        <div className="card-header border-0 bg-primary py-5 mb-4">
          <h3 className="card-title font-weight-bolder text-white">{header}</h3>
        </div>
      )}

      {/* custom header */}
      {children}

      {/* Body */}
      <div className="card-body position-relative overflow-hidden p-0 py-sm-8 px-sm-9">
        {series.length === 0 || !series ? (
          <p className="text-center mt-12">We don't have any records yet.</p>
        ) : (
          <>
            <Chart
              series={series}
              options={options}
              type="bar"
              height={height || "100%"}
            />
          </>
        )}
      </div>
    </div>
  );
}

function getChartOptions({ categories, customOptions }) {
  const options = {
    chart: {
      type: "bar",
      // height: 350,
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: GRAPH_COLORS,
    legend: {
      position: "bottom",
      offsetX: -10,
      offsetY: 0,
      labels: {
        colors: GRAPH_COLORS,
        useSeriesColors: false,
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          dataLabels: {
            style: {
              fontSize: ".5rem",
            },
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          dataLabels: {
            style: {
              fontSize: ".75rem",
            },
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        hideOverflowingLabels: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    xaxis: {
      categories,
      position: "bottom",
      labels: {
        show: true,
        style: {
          color: undefined,
          fontSize: "10px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-title",
        },
        hideOverlappingLabels: true,
        rotateAlways: false,
        rotate: 0,
      },
    },
    yaxis: {
      decimalsInFloat: 0,
    },
  };
  return { ...options, ...customOptions };
}
