import React from "react";
import { Col, Modal, Row, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import AddToCart from "../../components/widgets/AddToCart";
import { fetchData, GetDataFromEvent } from "../../helpers";
import {
  checkIfSameExtras,
  computeExtrasAmount,
  generateExtrasChecker,
  generateMealPlanDates,
} from "../../helpers/branded-website";
import MenuDisplay from "../../partials/branded-website/menu/MenuDisplay";
import StoreInfo from "../../partials/branded-website/menu/StoreInfo";
import BrandWebsite from "../../partials/BrandWebsite";
import moment from "moment";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DANGER_COLOR } from "../../constants";
import DineInInfo from "../../partials/branded-website/menu/DineInInfo";
import Prompt from "../../components/CustomPrompt";
import { getStorage, setStorage } from "../../../_metronic/_helpers";

// import StoreOrderDetailsModal from "../../partials/branded-website/StoreOrderDetailsModal";
import {
  isCurbsideAvailable,
  IsDeliveryAvailable,
  isMealPlanAvailable,
  isPickupAvailable,
} from "../../components/Base";

const MySwal = withReactContent(Swal);

const Container = styled.div`
  .bw-iac-item {
    overflow-x: hidden;
  }

  .menu-display-column {
    height: calc(100vh - 200px);
  }

  @media (max-width: 575.98px) {
    .menu-display-column {
      height: calc(100vh - ${({ isDineIn }) => (isDineIn ? "375px" : "290px")});
    }

    .menu-brand-name {
      display: none;
    }

    .menu-dine-in-info {
      margin-top: 0.5rem !important;
    }
  }
`;

export default function MenuPage(props) {
  const {
    store_url = "",
    table_number = "",
    storetable_id = "",
    store_user = "",
  } = useParams();
  const params = new URLSearchParams(document?.location?.search);
  const utm_campaign = params.get("utm_campaign");
  const utm_source = params.get("utm_source");

  return (
    <MainComponent
      store_url={store_url}
      table_number={table_number}
      storetable_id={storetable_id}
      store_user={store_user}
      utm_campaign={utm_campaign}
      utm_source={utm_source}
      {...props}
    />
  );
}

class MainComponent extends BrandWebsite {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      showOrderDetailsModal: this.props.store_user ? true : false,
      values: {
        tableNumber: "",
      },
      isStoreOpen: true,
    };
  }

  /**
   * Called after handleOnDataFetched()
   */
  handleOnDataFetched2 = () => {
    const { store_id = "", data = {} } = this.state;
    if (store_id === "") {
      // no store previously selected, select the first store from the brand
      // should be changed if there are multiple stores present
      const { stores = [] } = data;
      if (stores.length > 0)
        this.setState({ store_id: stores[0]._id, showSplashScreen: false });
    }

    // if (getStorage(this.state.storage_key) !== null) {
    //   this.getDataFromCartStorage();
    // }
    this.getSelectedStoreData();
  };

  getDataFromCartStorage = (currentCart) => {
    if (getStorage(this.state.storage_key) !== null) {
      let cartStr = getStorage(this.state.storage_key);
      if (cartStr !== null) {
        let cart_storage = JSON.parse(cartStr);
        // state.cart = cart_storage.cart;
        let cartOrderType = currentCart.order_type;
        let localOrderType = cart_storage.cart.order_type;
        const storeId = this.state.store_id;
        const storeIdFromLocal =
          cart_storage.cart.items.length !== 0
            ? cart_storage.cart.items[0].item.store
            : this.state.store_id;
        const tableNum =
          currentCart.table_number !== undefined ? currentCart.table_number : 0;
        const tableNumLocal =
          cart_storage.cart.table_number !== undefined
            ? cart_storage.cart.table_number
            : 0;
        const isOrderTypeEqual = cartOrderType === localOrderType;
        const isMealPlan =
          cartOrderType === "meal_plan" && localOrderType === "delivery";
        const isStoreEqual = storeId === storeIdFromLocal;
        const localOrderTypeExists =
          cartOrderType === "" && localOrderType !== "";
        if ((isOrderTypeEqual || isMealPlan) && isStoreEqual) {
          // console.log(cartOrderType);
          if (cartOrderType === "dine_in") {
            if (tableNum === tableNumLocal) {
              return cart_storage.cart;
            } else {
              return currentCart;
            }
          } else if (currentCart.isInStoreOrder) {
            return currentCart;
          } else {
            return cart_storage.cart;
          }
        } else if (localOrderTypeExists) {
          return cart_storage.cart;
        } else {
          return currentCart;
        }
      } else {
        return currentCart;
      }
    } else {
      return currentCart;
    }
  };

  /**
   * Gets selected store data from brand website initial api call.
   */
  getSelectedStoreData = async () => {
    const { store_url = "" } = this.props,
      {
        data: { stores = [] },
        cart = {},
        values = {},
      } = this.state;
    const {
      table_number = "",
      storetable_id = "",
      store_user = "",
    } = this.props;
    let { order_subtype = "" } = this.state;
    let selected_store = {};
    if (store_url !== "") {
      // multistore
      const filteredStore = stores.filter(
        (store) => store.store_url === store_url,
      );
      selected_store = filteredStore[0];
    } else {
      // single store
      selected_store = stores[0];
    }

    if (store_user) {
      // in-store-order
      // const inStoreOrderDate = new Date().toISOString().slice(0, 10)
      // console.log("inStoreOrderDate isostring: ", new Date().toISOString())
      // const inStoreOrderDate = new Date().toLocaleString('sv').slice(0, 10)
      const inStoreOrderDate = moment().local().format("YYYY-MM-DD");
      // console.log(new Date().toLocaleString('sv'))
      // console.log(inStoreOrderDate)
      // console.log(inStoreOrderDateMoment)
      // console.log(moment(inStoreOrderDateMoment).format())
      // console.log(moment(inStoreOrderDateMoment).format("YYYY-MM-DD hh:mm a"))

      // console.log("inStoreOrderDate localestring: ", new Date().toLocaleString('sv'))
      const inStoreOrderTime = "asap_order_time";
      // cart.order_type = "pickup"
      if (selected_store.isPickupActive) {
        cart.order_type = "pickup";
      } else {
        if (selected_store.isDeliveryActive) {
          cart.order_type = "delivery";
        } else {
          if (selected_store.isCurbsideActive) {
            cart.order_type = "curbside_pickup";
          }
        }
      }
      cart.order_date = inStoreOrderDate;
      cart.order_time = inStoreOrderTime;
      cart.isInStoreOrder = true;
      cart.inStoreOrderUser = store_user;
      this.setState({ cart });
    }

    if (table_number !== "" || storetable_id !== "") {
      // dinein
      let dineInOrderDate = new Date().toISOString().slice(0, 10);
      let dineInOrderTime = "asap_order_time";
      let result = await fetchData(
        "get",
        `branded-website/stores/${this.state.store_id}/tables`,
      );
      const store_tables = result.data.store_tables;
      let tableNumber = Number(table_number);
      // console.log("dineintable getselectedstoredata result: ", result)
      if (table_number === "" && storetable_id !== "") {
        //from qrcode
        let tableIdExists = result.data.store_tables.find(
          (item) => item._id === storetable_id,
        );
        tableNumber = tableIdExists ? Number(tableIdExists.tableNumber) : "";
      }
      // const tableNumber = await this.getTableNumberFromStoreTableId(store_tables)
      this.state.values.tableNumber = tableNumber;
      this.setState({ values });
      // console.log("menu2 dineintable getselectedstoredata tableNumber: ", tableNumber)
      let isStoreOpen = result.data.is_store_hours_open;
      let storeTables = result.data.store_tables.map((item) => {
        if (item.available) return item.tableNumber;
      });
      this.setState({
        isStoreOpen,
      });
      if (isStoreOpen && selected_store.isDineInActive) {
        if (storeTables.includes(Number(tableNumber))) {
          // console.log("menu2 storeTables: ", storeTables)
          order_subtype = "dine_in";
          cart.order_type = "dine_in";
          // cart.table_number= table_number
          cart.table_number = tableNumber;

          cart.order_date = dineInOrderDate;
          cart.order_time = dineInOrderTime;
        } else {
          await MySwal.fire({
            icon: "error",
            title: "Ooops!",
            text: "This store table is not available at this time.",
            confirmButtonColor: DANGER_COLOR,
            confirmButtonText: "Ok",
            // timer: 1000
          });
          // window.location.replace("/")
          window.location.href = "/";
        }
      } else if (!isStoreOpen && selected_store.isDineInActive) {
        this.toggleUnavailablePage(true);
        await MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: "This store is currently closed and not available for dine-in.",
          confirmButtonColor: DANGER_COLOR,
          confirmButtonText: "Ok",
        });
        window.location.href = "/";
        this.toggleUnavailablePage(false);
      } else {
        await MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: "This store is currently not available for dine-in.",
          confirmButtonColor: DANGER_COLOR,
          confirmButtonText: "Ok",
          // timer: 1000
        });
        // window.location.replace("/")
        window.location.href = "/";
      }
    }

    if (cart.order_type === "meal_plan") {
      cart.order_type = "delivery";
      cart.isMealPlan = true;

      cart.order_dates = generateMealPlanDates(cart.meal_plan_type);
      cart.order_date = cart.order_dates[0];
      cart.order_time = selected_store.meal_plan_order_time;
    }

    const {
      isPickupActive = true,
      isDeliveryActive = true,
      isMealPlanActive,
      isCurbsideActive,
    } = selected_store;
    if (
      cart.order_type === "" &&
      !isPickupActive &&
      !isDeliveryActive &&
      !isCurbsideActive &&
      isMealPlanActive
    ) {
      cart.meal_plan_type = "3DayMealPlan";

      cart.order_type = "delivery";
      cart.isMealPlan = true;

      cart.order_dates = generateMealPlanDates(cart.meal_plan_type);
      cart.order_date = cart.order_dates[0];
      cart.order_time = selected_store.meal_plan_order_time;
    }
    const switchedCart = this.getDataFromCartStorage(cart);
    this.setState(
      {
        selected_store,
        isStoreSelected: true,
        cart: switchedCart,
        mountMenuDisplay: false,
        store_id: selected_store._id,
        order_subtype,
      },
      () => {
        this.setState({ mountMenuDisplay: true });
        // this.initCurrentAvailableCounters();
        this.onGetSelectedStoreData();
      },
    );
  };

  getTableNumberFromStoreTableId = async (store_tables) => {
    const { table_number = "", storetable_id = "" } = this.props;
    let tableNumber = table_number;
    // let result = await fetchData("get", `branded-website/stores/${this.state.store_id}/tables`)
    // console.log("dineintable getTableNumberFromStoreTableId result: ", result)
    if (table_number === "" && storetable_id !== "") {
      let tableIdExists = store_tables.find(
        (item) => item._id === storetable_id,
      );
      tableNumber = tableIdExists ? Number(tableIdExists.tableNumber) : "";
    }
    return tableNumber;
    //conflict start
    // this.setState({ selected_store, isStoreSelected: true, cart, mountMenuDisplay: false, store_id: selected_store._id }, () => {
    //     this.setState({ mountMenuDisplay: true });
    //     this.onGetSelectedStoreData();
    // });
    //conflict end
  };

  /**
   * Called after getSelectedStoreData()
   */
  onGetSelectedStoreData = () => {
    this.setTitle();
  };

  /**
   * Set page title
   */
  setTitle = () => {
    const { preview_token = "" } = this.state;
    const brandName = this.state.data.name;
    const storeName = this.state.selected_store.name || "";
    this.setState({
      title:
        preview_token !== ""
          ? `[Preview Mode] Menu | ${brandName} | ${storeName}`
          : `Menu | ${brandName} | ${storeName}`,
    });
  };

  // JD: Add to cart in item modal "Add to basket"
  handleOnClickAddToCartFromMenu = (e, counter = 0, extras = []) => {
    const id = GetDataFromEvent(e, "data-id");
    let {
        cart = {},
        selected_item = {},
        selected_item_category = {},
      } = this.state,
      existing = false,
      continueToAdd = true;
    const new_set_of_extras = generateExtrasChecker(extras);
    const cartHasExclusiveItems =
      cart.items.filter((cart_item) => cart_item.isExclusive).length > 0;

    if (cart.isMealPlan) {
      if (
        (cart.meal_plan_type === "3DayMealPlan" && cart.items.length === 3) ||
        (cart.meal_plan_type === "5DayMealPlan" && cart.items.length === 5)
      ) {
        continueToAdd = false;
      }
    }

    if (continueToAdd) {
      const { items = [] } = cart;

      if (!cart.isMealPlan) {
        for (var i = 0; i < items.length; i++) {
          const cart_item = cart.items[i];
          existing =
            cart_item.item._id === id &&
            checkIfSameExtras(
              generateExtrasChecker(cart_item.extras),
              new_set_of_extras,
            );

          if (existing) {
            cart.items[i].qty += counter;
            this.adjustCurrentAvailableCounters(id, cart.items[i].qty);
            break;
          }
        }
      }

      if (!existing) {
        if (cart.isMealPlan) {
          let newItems = [];

          for (let i = 0; i < counter; i++) {
            newItems.push({
              item: selected_item,
              qty: 1,
              price:
                parseInt(selected_item.price) + computeExtrasAmount(extras),
              regular_price:
                parseInt(selected_item.regular_price) +
                computeExtrasAmount(extras),
              isBulk: selected_item.isBulk,
              extras,
              order_date: null,
              isExclusive: selected_item_category.isExclusive,
            });
          }

          cart.items = cart.items
            // Add the new item/s
            .concat(newItems)
            // Sort items and put exclusive items at the end of the list
            .sort((a, b) => {
              if (!a.isExclusive && b.isExclusive) return -1;
              else if (a.isExclusive && !b.isExclusive) return 1;
              else return 0;
              // Assign order dates by index
            })
            .map((item, index) => {
              item.order_date =
                cart.order_dates[
                  item.isExclusive ? cart.order_dates.length - 1 : index
                ];

              return item;
            });
        } else {
          cart.items.push({
            item: selected_item,
            qty: counter,
            price: parseInt(selected_item.price) + computeExtrasAmount(extras),
            regular_price:
              parseInt(selected_item.regular_price) +
              computeExtrasAmount(extras),
            isBulk: selected_item.isBulk,
            extras,
            order_date: cart.order_date,
          });
        }

        this.adjustCurrentAvailableCounters(id, counter);
      }

      cart.items_count = cart.items.reduce((acc, cur) => (acc += cur.qty), 0);

      this.setState({ cart, showSelectedItemModal: false });
      this.updateCart(cart.items, cart.items_count);
    } else {
      this.displayMealPlanLimitSwal();
    }
  };

  handleOnClickOrderDetails = () => {
    const { showOrderDetailsModal } = this.state;
    this.setState({ showOrderDetailsModal: !showOrderDetailsModal });
  };

  handleOnClickOrderType = (order_type) => {
    // console.log(`${order_type} click`)
    const { cart } = this.state;
    cart.order_type = order_type;
    this.setState({ cart });
  };

  // componentDidMount () {
  //     console.log("menu2 componentDidMount state: ", this.state)
  //     console.log("menu2 componentDidMount props: ", this.props)
  // }

  componentDidUpdate() {}

  renderContent = () => {
    const {
      data = {},
      selected_store = {},
      website_theme = {},
      isStoreSelected = false,
      mountShowSelectedItemModal = true,
      showSelectedItemModal = false,
      selected_item = {},
      selectedItemTags = null,
      cart = {},
      mountMenuDisplay = true,
      isStoreOpen = true,
    } = this.state;
    const {
      table_number,
      storetable_id,
      history,
      store_user,
      utm_campaign,
      utm_source,
    } = this.props;
    const actions = {
      setSelectedItem: this.setSelectedItem,
      toggleShowSelectedItemModal: () => {
        this.setState({ showSelectedItemModal: true });
      },
      hideSelectedItemModal: () => {
        this.setState({ showSelectedItemModal: false });
      },
    };

    const {
      button_text_color = "",
      button_color = "",
      homepage_button_color = "",
      homepage_button_text_color = "",
    } = website_theme;

    const isHomePage = Boolean(window.location.pathname === "/");

    const buttonStyle = {
      borderColor: isHomePage ? homepage_button_color : button_color,
      backgroundColor: isHomePage ? homepage_button_color : button_color,
      color: isHomePage ? homepage_button_text_color : button_text_color,
    };

    const isDineIn = table_number || storetable_id;

    return (
      isStoreSelected && (
        <Container isDineIn={isDineIn}>
          <Prompt
            shouldBlockNavigation={(nextLocation) => {
              // const allowList = ["/table", "/checkout", "/about", "/not-found", "/session-expired", "/menu"]
              // return cart.items.length && !allowList.some((path) => nextLocation.pathname.startsWith(path))
              const denyList = [
                "/table",
                "/about",
                "/not-found",
                "/session-expired",
              ];
              let denyPath =
                cart.items.length &&
                denyList.some((path) => {
                  return (
                    nextLocation.pathname.includes(path) ||
                    nextLocation.pathname === "/"
                  );
                });
              return denyPath;
            }}
            message="There are items in your cart, are you sure you want to leave this page?"
            title="Leave cart"
            navigate={(path) => history.push(path)}
            showCloseButton
            showCancelButton
          />

          <Row>
            <Col md={3} sm={12} xs={12} id="headerDiv">
              {/* {
                        <Button
                        onClick={() => this.handleOnClickOrderDetails()}
                        >
                            Order Details
                        </Button>
                    } */}
              {store_user ? (
                <>
                  <div
                    data-testid="fulfillmentOptions"
                    className="css-znampd mv-1rem"
                  >
                    {isPickupAvailable([selected_store]) ? (
                      <>
                        <Button
                          style={
                            cart.order_type === "pickup"
                              ? buttonStyle
                              : {
                                  backgroundColor: "silver",
                                  borderColor: "white",
                                }
                          }
                          className="btn mr-5 mt-5"
                          onClick={() => this.handleOnClickOrderType("pickup")}
                        >
                          <i
                            style={{ color: button_text_color }}
                            className="fas fa-shopping-bag"
                          ></i>
                          PICKUP
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}

                    {IsDeliveryAvailable([selected_store]) ? (
                      <>
                        <Button
                          style={
                            cart.order_type === "delivery"
                              ? buttonStyle
                              : {
                                  backgroundColor: "silver",
                                  borderColor: "white",
                                }
                          }
                          className="btn mr-5 mt-5"
                          onClick={() =>
                            this.handleOnClickOrderType("delivery")
                          }
                        >
                          <i
                            style={{ color: button_text_color }}
                            className="fas fa-motorcycle"
                          ></i>
                          DELIVERY
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}

                    {isCurbsideAvailable([selected_store]) ? (
                      <>
                        <Button
                          style={
                            cart.order_type === "curbside_pickup"
                              ? buttonStyle
                              : {
                                  backgroundColor: "silver",
                                  borderColor: "white",
                                }
                          }
                          className="btn mr-5 mt-5"
                          onClick={() =>
                            this.handleOnClickOrderType("curbside_pickup")
                          }
                        >
                          <i
                            style={{ color: button_text_color }}
                            className="fas fa-car"
                          ></i>
                          CURBSIDE PICKUP
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* {
                                    isCurbsideAvailable([selected_store])
                                    ? <>
                                        <Button 
                                        style={cart.order_type === "dine_in"? buttonStyle: {backgroundColor: "silver", borderColor: "white"}}
                                        className="btn mr-5 mt-5" 
                                        onClick={() => this.handleOnClickOrderType("dine_in")}
                                        >
                                            <i style={{color:button_text_color}} className="fas fa-utensils"></i>
                                            DINE-IN
                                        </Button>
                                    </>
                                    :<></>
                                } */}

                    {/* { 
                                    isMealPlanAvailable([selected_store]) 
                                    ? <>
                                        <Button 
                                        style={{backgroundColor: "silver", borderColor: "white"}}
                                        className="btn mr-5 mt-5" 
                                        onClick={() => console.log("mealplan click")}
                                        >
                                            <i style={{color:button_text_color}} className="far fa-calendar-check"></i>
                                            MEAL PLAN
                                        </Button>
                                    </>
                                    :<></>
                                } */}
                  </div>
                  <div className="display-4 font-weight-bolder bg-dark d-flex flex-column align-items-center mt-7 border rounded text-white">
                    In-Store Order
                  </div>
                </>
              ) : (
                <></>
              )}
              {isStoreOpen && (
                <StoreInfo
                  brand={data}
                  store={selected_store}
                  website_theme={website_theme}
                />
              )}
              {
                // check if dine-in
                isStoreOpen && isDineIn ? (
                  <>
                    <DineInInfo
                      table_number={this.state.values.tableNumber}
                      website_theme={website_theme}
                    />
                  </>
                ) : (
                  <></>
                )
              }
            </Col>
            {isStoreOpen && (
              <Col md={9} sm={12} xs={12} className={"menu-display-column"}>
                {mountMenuDisplay ? (
                  <MenuDisplay
                    cart={cart}
                    store={selected_store}
                    website_theme={website_theme}
                    actions={actions}
                    utm_campaign={utm_campaign}
                    utm_source={utm_source}
                    setDirectories={({
                      categories_directory,
                      items_directory,
                    }) => {
                      this.setState(
                        { categories_directory, items_directory },
                        () => {
                          this.initCurrentAvailableCounters();
                        },
                      );
                    }}
                  />
                ) : (
                  <div className="mv-1rem text-center">Loading items...</div>
                )}
              </Col>
            )}
          </Row>
          {mountShowSelectedItemModal ? (
            <Modal
              dialogClassName="custom-modal-dialog"
              className="addtocart p-0"
              centered
              show={showSelectedItemModal}
              onHide={() => {
                this.setState({ showSelectedItemModal: false });
              }}
            >
              {selected_item.available ? (
                <AddToCart
                  selected_item={selected_item}
                  item_id={selected_item._id}
                  addToCart={this.handleOnClickAddToCartFromMenu}
                  actions={actions}
                  checkStocks={this.checkStocks}
                  cart={cart}
                  extragroup={selected_item.extragroup}
                  website_theme={website_theme}
                  selectedItemTags={selectedItemTags}
                  showSelectedItemModal={showSelectedItemModal}
                />
              ) : (
                <></>
              )}
            </Modal>
          ) : (
            <></>
          )}
        </Container>
      )
    );
  };
}
