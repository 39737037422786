import { useState, useEffect } from "react";

import core from "../vendors/core-api";

const useFetch = (request = {}, options = {}, dependencies = []) => {
  const { url = "", method = "get", body = {} } = request;

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const api = core("pu").get();

  const { dataFormatter = null, args = [] } = options;

  useEffect(() => {
    // //for testing loading state only, do not ship
    // const randomNumber= (min=3000, max=10000) => {
    //   return Math.random() * (max - min) + min;
    // }

    const fetchData = async () => {
      setIsLoading(true);

      if (method === "get") {
        return api.get(url).then((result) => {
          {
            setIsLoading(false);
            setData(result.data);
          }
        });
      }

      api[method]({ url, data: body }).then((result) => {
        // custom format data
        if (dataFormatter) {
          const formattedData = dataFormatter(result.data, ...args);
          setData(formattedData);
        } else {
          setData(result.data);
        }

        // setTimeout(()=> setIsLoading(false),randomNumber())
        setIsLoading(false);
      });
    };

    fetchData();
  }, dependencies);

  return { data, isLoading };
};

export default useFetch;
