import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { InfoAlertHelper } from "../components/Base";
import ProSourceForm from "../components/ProSourceForm";
import { GetSubmitClassList, Humanize } from "../helpers";

export default class EditPaymentMethodsModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { data = [] } = this.props;

    this.state = {
      values: {
        card: data.indexOf("card") !== -1,
        gcash: data.indexOf("gcash") !== -1,
        grab_pay: data.indexOf("grab_pay") !== -1,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      noPaymentMethod: false,
    };
  }

  onChange = (e) => {
    const checked = e.target.checked,
      name = e.target.name;
    let { values = {} } = this.state;
    values[name] = checked;

    this.setState({ values });
  };

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const { show, onHide } = this.props;

    const inputActions = {
      onChange: this.handleOnChange,
      onBlur: this.handleOnBlur,
    };

    const options = ["card", "gcash", "grab_pay"];
    this.state.noPaymentMethod =
      !this.state.values[options[0]] &&
      !this.state.values[options[1]] &&
      !this.state.values[options[2]];

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>Edit Payment Methods</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            <InfoAlertHelper
              text={
                "There are currently three modes of payment available for your brand website: Card, GCash and Grab Pay."
              }
            />

            <Form.Group className="mb-1">
              <div className="checkbox-list">
                {options.map((option, index) => {
                  return (
                    <label className="checkbox" key={index}>
                      <input
                        type="checkbox"
                        name={option}
                        checked={this.state.values[option] ? "checked" : ""}
                        {...inputActions}
                      />
                      {Humanize(option).toUpperCase()}
                      <span></span>
                    </label>
                  );
                })}
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="primary"
              className={GetSubmitClassList(this.state.isSubmitting, "")}
              disabled={
                this.state.isSubmitting || this.state.noPaymentMethod
                  ? "disabled"
                  : null
              }
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
