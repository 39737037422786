import React from "react";
import ProSourceForm from "../components/ProSourceForm";
import { FormItem } from "../components/Base";
import { Button, Form, Modal } from "react-bootstrap";
import { GetSubmitClassList } from "../helpers";

export default class BrandNameModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { values = {} } = this.props,
      { name = "" } = values;

    this.state = {
      values: {
        name,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {};
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      },
      { show, onHide } = this.props;

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>Edit Brand Name</Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            <FormItem
              label={"Brand Name"}
              name={"name"}
              inputProps={{
                name: "name",
                placeholder: "Brand Name",
                value: this.state.values["name"],
              }}
              inputActions={inputActions}
              actions={actions}
              showRequired={true}
              normal
            />

            <Button
              variant="primary"
              type="submit"
              className={GetSubmitClassList(this.state.isSubmitting, "")}
            >
              Save Changes
            </Button>
          </Modal.Body>
        </Form>
      </Modal>
    );
  }
}
