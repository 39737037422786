import React from "react";

import APIComponent from "../components/API";
import core from "../vendors/core-api";
// import helpertexts from "helpertexts";
import {
  Block,
  IconAlert,
  InfoAlertHelper,
  Label,
  FormItem,
  InfoTooltip,
  EditIcon,
  countActiveDelivery,
} from "../components/Base";
import {
  GetSubmitClassList,
  checkStoreDeliveryWallets,
  getFirstXLetters,
  getLastXLetters,
  withSecret,
  toPesoAmount,
} from "../helpers";
import { Button, Card, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PRIMARY_COLOR, DANGER_COLOR } from "../constants";
import AddDeliveryKeysModal from "./AddDeliveryKeysModal";
import ProSourceForm from "../components/ProSourceForm";
import { KTLink } from "../components/Base";
import { MapComponent } from "../components/widgets/GMapsWidget";
import { getUserByToken } from "../modules/Auth/_redux/authCrud";
import { actionTypes } from "../modules/Auth/_redux/authRedux";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import ServiceChargeForm from "./forms/ServiceChargeForm";
import PacmanWrapper from "../components/dashboard/PacmanWrapper";
const MySwal = withReactContent(Swal);

export default function DeliverySettingsDiv(props) {
  const dispatch = useDispatch();
  const refreshUserRedux = () => {
    getUserByToken()
      .then(({ data }) => {
        dispatch({ type: actionTypes.UserLoaded, payload: { user: data } });
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: `Something went wrong`,
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      });
  };
  return (
    <DeliverySettingsDivClass refreshUserRedux={refreshUserRedux} {...props} />
  );
}

class DeliverySettingsDivClass extends ProSourceForm {
  constructor(props) {
    super(props);
    this.api = core("pu").get();
    this.state = {
      ...this.state,
      values: {
        deliveryDistanceLimit: this.props.store.deliveryDistanceLimit
          ? this.props.store.deliveryDistanceLimit
          : 0,
        store_mobile_number: this.props.store.store_mobile_number
          ? this.props.store.store_mobile_number
          : "",
      },
      isActive:
        this.props.store.isDeliveryActive &&
        countActiveDelivery(this.props.store) > 0,
      isDeliveryDistanceLimitActive:
        this.props.store.isDeliveryDistanceLimitActive === false ? false : true,
      store: this.props.store ? this.props.store : "",
      errors: {},
      touched: {},
      isSubmitting: false,
      trial_grab_key: {},
      LIMIT_DISTANCE: 50, //SHOULD BE DYNAMIC,
      isSaveDeliveryLimitChangesDisabled: true,
      showRequiredError: isValidForDelivery(this.props.store),
    };
  }
  async getTrialGrabKey() {
    await this.api
      .get("/delivery-keys/admin/trial_grab_keys")
      .then((result) => {
        this.setState({ trial_grab_key: result.data });
      });
  }
  componentDidMount() {
    this.getTrialGrabKey();
  }

  componentDidUpdate(_prevProps, prevState) {
    if (
      !prevState.isDeliveryDistanceLimitActive &&
      prevState.isSaveDeliveryLimitChangesDisabled
    ) {
      this.setState({ isSaveDeliveryLimitChangesDisabled: false });
    }
  }

  handleSaveDeliveryLimitChanges = (shouldSaveChanges) => {
    // console.log("DeliverySettingsDivClass handleSaveChanges")
    let values = {};
    const { store } = this.state;
    values["store_id"] = store._id;
    values["isDeliveryDistanceLimitActive"] =
      store.isDeliveryDistanceLimitActive;
    values["deliveryDistanceLimit"] = Number(
      shouldSaveChanges
        ? this.state.values.deliveryDistanceLimit
        : store.deliveryDistanceLimit,
    );
    this.api
      .put({
        url: "/store/update-delivery-distance-limit",
        data: values,
      })
      .then(({ data }) => {
        if (data.result === "ok") {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            timer: 1000,
          }).then(() => {
            this.setState({
              isSaveDeliveryLimitChangesDisabled: true,
              values,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isSaveDeliveryLimitChangesDisabled: false });
      });
  };

  handleToggleLimitDeliveryDistanceLimit = async (isChecked) => {
    // console.log("DeliverySettingsDivClass handleToggleLimitDeliveryDistanceLimit")
    let values = {};
    const { isDeliveryDistanceLimitActive, store } = this.state;
    isChecked = !isChecked;
    this.setState({ isDeliveryDistanceLimitActive: isChecked });
    store.isDeliveryDistanceLimitActive = isChecked;
    this.setState({ store });
    if (!isChecked) {
      this.handleSaveDeliveryLimitChanges(false);
    }
  };

  handleToggleDeliveryStatus = async () => {
    const { isActive, store, showRequiredError } = this.state;
    const isChecked = !isActive;

    const createErrorNotif = () => {
      return MySwal.fire({
        icon: "error",
        title: "Ooops",
        text: "Ooops, Please make sure a valid address, contact number & delivery partner/s has been added.",
        confirmButtonText: "Ok",
        timer: 5000,
      });
    };

    if (isChecked && showRequiredError) {
      return createErrorNotif();
    }

    this.api
      .put({
        url: "/store/allow-delivery",
        data: {
          store_id: store._id,
          isActive: isChecked,
        },
      })
      .then(({ data }) => {
        if (data.result === "ok") {
          this.setState({ isActive: isChecked });
          store.isDeliveryActive = isChecked;
          this.setState({ store });

          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            // timer: 1000
          });
        }
      })
      .catch((error) => {
        createErrorNotif();
        console.log(error);
      });
  };

  validatePhoneNumber(contact_number = "") {
    var re = /^09[0-9]{9}$/;
    return re.test(String(contact_number));
  }

  isValidDistance(input) {
    if (input.length > 2 || input > this.state.LIMIT_DISTANCE) {
      return false;
    }
    return true;
  }

  handleResetField = (fieldName = "", resetValue) => {
    this.setFieldValue(fieldName, resetValue);
    this.removeError(fieldName);
  };

  handleOnChange = (event) => {
    const target = event.target,
      value = target.value,
      name = target.name;
    let { values, errors, LIMIT_DISTANCE } = this.state,
      state = {};
    values[name] = value;
    if (name === "store_mobile_number") {
      const valid = this.validatePhoneNumber(value);
      if (!valid) {
        errors.store_mobile_number =
          "Please enter a valid contact number (ex.09123456789)";
      } else {
        delete errors.store_mobile_number;
      }
      state.errors = errors;
    }

    if (name === "deliveryDistanceLimit") {
      const valid = this.isValidDistance(value);
      const checkInitialVal = value.charAt(0);
      if (isEmpty(value) || (valid && checkInitialVal == 0) || !valid) {
        errors.deliveryDistanceLimit = "Invalid input";
      } else {
        delete errors.deliveryDistanceLimit;
      }
      state.errors = errors;
      values[name] = parseInt(value);
      this.setState({
        values: values,
        state: state,
        isSaveDeliveryLimitChangesDisabled: false,
      });
    }

    this.setState({
      values: values,
      state: state,
    });

    this.setStateKey("touched", name, "true");
    this.validateForm();

    // checks if afterOnChange function exists
    this.onChange && this.onChange(event);
  };

  render() {
    const {
      isActive,
      isDeliveryDistanceLimitActive,
      store: selectedStore,
    } = this.state;
    const { store_mobile_number, geocode_location } = selectedStore;
    const {
        brand = {},
        showAddDeliveryKeysModal = false,
        store,
        editable = false,
      } = this.props,
      hasDeliveryPartner =
        store.delivery_method.length !== 0 && store.delivery_method !== null;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
        onReset: (field, value) => this.handleResetField(field, value),
      };
    return (
      <>
        <Card className="card-custom gutter-b">
          <Card.Header>
            <Card.Title>Delivery Settings</Card.Title>
          </Card.Header>
          <Card.Body>
            {hasDeliveryPartner ? (
              <InfoAlertHelper
                text={
                  "You can manage your available delivery partners and delivery address in your delivery settings"
                }
              />
            ) : (
              <IconAlert
                text={
                  "Oooops! Looks like you haven't setup your delivery partners. Please send us a message on support@pickup.ph regarding your delivery keys and your desired delivery partners so we can guide you to set this up. Thank you!"
                }
                icon={"Code/Warning-2"}
              />
            )}
            <Block
              content={
                <>
                  <Card className="d-flex justify-content-between w-100 mb-5">
                    <Card.Body>
                      <div
                        style={{ transform: "scale(1.2)" }}
                        className="row d-flex align-items-center"
                      >
                        <span className="switch switch-outline switch-icon switch-primary py-1 m-0 pl-10 ml-10">
                          <label>
                            <input
                              type="checkbox"
                              name="isActive"
                              checked={isActive}
                              // disabled={store.name? "" : "disabled"}
                              onChange={() => this.handleToggleDeliveryStatus()}
                            />
                            <span></span>
                          </label>
                        </span>
                        <label className="text-dark font-weight-boldest text-uppercase py-4 px-3 ">
                          Allow delivery orders
                        </label>
                        {InfoTooltip(
                          "Turning this off will remove the delivery option for your customers regardless if you have active delivery partners.",
                        )}
                        {this.state.showRequiredError ? (
                          <>
                            <span
                              style={{ transform: "scale(0.8)", width: 450 }}
                              className="py-4 px-3 text-danger"
                            >
                              Store mobile number, store pick-up address, and
                              delivery partner/s are required to enable delivery
                              option
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                  <ServiceChargeForm store={store} order_type={"delivery"} />
                </>
              }
            ></Block>
            <Block
              content={
                <>
                  <DeliveryAddressComponent
                    store={store}
                    brand={brand}
                    actions={actions}
                    parentState={this.state}
                    inputActions={inputActions}
                    showRequiredError={(showRequiredError) => {
                      this.setState({ showRequiredError });
                    }}
                  />
                </>
              }
            />
            <Block
              content={
                <Card className="d-flex justify-content-between w-100">
                  <Card.Body className="pb-2">
                    <div
                      // style={{transform: "scale(1.2)"}}
                      className="row d-flex align-items-center"
                    >
                      <span className="switch switch-outline switch-icon switch-primary py-1 m-0 pl-5 ml-5">
                        <label>
                          <input
                            type="checkbox"
                            name="isDeliveryDistanceLimitActive"
                            checked={
                              isDeliveryDistanceLimitActive ? "checked" : null
                            }
                            // disabled={store.name? "" : "disabled"}
                            onChange={() =>
                              this.handleToggleLimitDeliveryDistanceLimit(
                                isDeliveryDistanceLimitActive,
                              )
                            }
                          />
                          <span></span>
                        </label>
                      </span>
                      <label className="text-dark font-weight-boldest text-uppercase py-4 px-3 ">
                        Limit delivery distance
                      </label>
                      {InfoTooltip(
                        "Turn this on to customize delivery distance limit. Default limit is 50 km",
                      )}
                    </div>
                    {isDeliveryDistanceLimitActive && (
                      <>
                        <Col xs={12} md={5} lg={6}>
                          <FormItem
                            className="mt-4"
                            label={`Maximum delivery distance from store is ${this.state.LIMIT_DISTANCE}km:`}
                            name={"deliveryDistanceLimit"}
                            inputProps={{
                              name: "deliveryDistanceLimit",
                              placeholder: "0",
                              value: this.state.values["deliveryDistanceLimit"],
                            }}
                            subLabel={
                              "The maximum delivery distance should range from 1 - 50km"
                            }
                            inputActions={inputActions}
                            actions={actions}
                            type={"number"}
                            showRequired={true}
                            normal
                          />
                        </Col>
                      </>
                    )}
                  </Card.Body>
                  {isDeliveryDistanceLimitActive ? (
                    <>
                      <Card.Footer className="w-100 d-flex flex-column align-items-end">
                        <Button
                          variant="primary"
                          className={GetSubmitClassList(
                            this.state.isSubmitting,
                            "",
                          )}
                          onClick={() =>
                            this.handleSaveDeliveryLimitChanges(true)
                          }
                          disabled={
                            this.state.isSaveDeliveryLimitChangesDisabled ||
                            !isEmpty(this.state.errors["deliveryDistanceLimit"])
                          }
                        >
                          Save Changes
                        </Button>
                      </Card.Footer>
                    </>
                  ) : (
                    <></>
                  )}
                </Card>
              }
            ></Block>
            <Block
              content={
                <>
                  <DeliveryKeysComponent
                    store={store}
                    id={store._id}
                    editable={editable}
                    trial_grab_key={this.state.trial_grab_key}
                    actions={actions}
                    refreshUserRedux={this.props.refreshUserRedux}
                    updateToggleAllowDeliveryOrders={(val) => {
                      const { store } = this.props;
                      if (!val && store.isDeliveryActive) {
                        const {
                          store_mobile_number = "",
                          geocode_location = "",
                        } = store;
                        this.setState({
                          isActive:
                            Boolean(store_mobile_number) &&
                            Boolean(geocode_location) &&
                            countActiveDelivery(selectedStore) > 0,
                        });
                      }
                    }}
                    showRequiredError={(showRequiredError) => {
                      this.setState({ showRequiredError });
                    }}
                  />
                </>
              }
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}
class DeliveryAddressComponent extends APIComponent {
  constructor(props) {
    super(props);
    this.api = core("pu").get();
    this.state = {
      values: {
        mapPosition: { lat: "", lng: "" },
        markerPosition: { lat: "", lng: "" },
        currentPosition: { lat: "", lng: "" },
        delivery_address: this.props.store.hasOwnProperty(
          "geocode_address_string",
        )
          ? this.props.store.geocode_address_string
          : "",
        zoomValue: 18,
      },
      showMapComponent: false,
      showStoreMobileInput: false,
      showGeoAddress: this.props.store.hasOwnProperty("geocode_location"),
      showStoreMobile: this.props.store.hasOwnProperty("store_mobile_number"),
      data: {
        brand: this.props.brand,
        store: this.props.store,
      },
      actions: this.props.actions,
      inputActions: this.props.inputActions,
      store: this.props.store,
      isValidMarker: false,
    };
    this.saveAddress = this.saveAddress.bind(this);
  }

  validateDelivery = () => {
    // const { values } = this.state
    // this.setState({isValidMarker:values.currentPosition === {lat:"",lng:""}})
  };
  saveAddress = () => {
    const { values, store } = this.state;
    const geocode_location = JSON.stringify(values.mapPosition);
    const req = {
      geocode_address_string: values.delivery_address,
      geocode_location,
    };
    if (JSON.parse(geocode_location).lat && !isEmpty(values.delivery_address)) {
      this.api
        .put({
          url: "/store/" + store._id,
          data: req,
        })
        .then(({ data }) => {
          const { store } = this.state;
          store.geocode_address_string = data.geocode_address_string;
          store.geocode_location = data.geocode_location;
          values.mapPosition = { lat: "", lng: "" }; // for emptying on next edit
          this.setState(
            {
              data,
              values,
              showMapComponent: false,
              showGeoAddress: true,
            },
            () => {
              this.props.showRequiredError(isValidForDelivery(store));
            },
          );
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            timer: 3000,
          });
        })
        .catch(() => {
          this.handleError();
        });
    } else {
      MySwal.fire({
        icon: "error",
        title: "Ooops",
        text: "Ooops, Please enter a valid address by selecting from the autocomplete options",
        confirmButtonText: "Ok",
        timer: 5000,
      });
    }
  };
  saveMobileNumber = () => {
    const {
      state: { store },
      props,
    } = this;
    const req = {
      store_mobile_number: props.parentState.values.store_mobile_number,
    };
    this.api
      .put({
        url: "/store/" + store._id,
        data: req,
      })
      .then(({ data }) => {
        const { store } = this.state;
        store.store_mobile_number = data.store_mobile_number;
        this.setState(
          {
            store,
            showStoreMobileInput: false,
            showStoreMobile: true,
          },
          () => {
            this.props.showRequiredError(isValidForDelivery(store));
          },
        );
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully saved your changes.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          timer: 1000,
        });
      })
      .catch(() => {
        this.handleError();
      });
  };
  render() {
    const {
      showMapComponent,
      showStoreMobile,
      showGeoAddress,
      showStoreMobileInput,
      data = {},
      store = {},
      inputActions,
      actions,
    } = this.state; //, isLoading = data_status !== "fetched";
    const showMap = () => {
      this.setState({
        showMapComponent: true,
        showGeoAddress: false,
      });
    };
    const showMobileInput = () => {
      this.setState({
        showStoreMobileInput: true,
        showStoreMobile: false,
      });
    };
    const hideMobileInput = () => {
      this.props.inputActions.onReset(
        "store_mobile_number",
        store.store_mobile_number,
      );
      this.setState({
        showStoreMobileInput: false,
        showStoreMobile: store.hasOwnProperty("store_mobile_number"),
      });
    };
    const hideMap = () => {
      this.setState({
        values: {
          ...this.state.values,
          delivery_address: store.geocode_address_string,
        },
        showMapComponent: false,
        showGeoAddress: store.hasOwnProperty("geocode_location"),
      });
    };
    return (
      <>
        <div className="d-flex justify-content-between w-100">
          <Label
            text={
              <>
                <span className="va-middle text-capitalize">
                  Store Mobile Number <span className="text-danger">*</span>
                  {store.hasOwnProperty("store_mobile_number") ? (
                    showStoreMobileInput === false ? (
                      <EditIcon onClick={showMobileInput} />
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}{" "}
                </span>
              </>
            }
          />
        </div>

        {showStoreMobile ? (
          <>
            <Card className="d-flex justify-content-between w-100">
              <Card.Body className="py-3">
                <Card.Title className="mb-0">
                  <div className="col-example align-left">
                    <div className="col-example font-weight-bolder text-dark text-left">
                      {store.store_mobile_number}
                    </div>
                  </div>
                </Card.Title>
              </Card.Body>
            </Card>
          </>
        ) : showStoreMobileInput === false ? (
          <p>
            You don't have store mobile number{" "}
            <KTLink
              text={<>Do you want to add one?</>}
              onClick={showMobileInput}
            />{" "}
          </p>
        ) : (
          <>
            <FormItem
              label={
                "This number will be used as a contact for your delivery partners"
              }
              name={"store_mobile_number"}
              inputProps={{
                name: "store_mobile_number",
                placeholder: "Ex.(09123456789)",
                value: this.props.parentState.values.store_mobile_number,
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
            />
            <div className="d-flex justify-content-end">
              <Button variant="danger" onClick={hideMobileInput}>
                Cancel
              </Button>
              <Button
                variant="primary"
                className="ml-1"
                onClick={this.saveMobileNumber}
                disabled={
                  this.props.parentState.errors.hasOwnProperty(
                    "store_mobile_number",
                  ) ||
                  this.props.parentState.values.store_mobile_number ===
                    undefined
                }
              >
                Save Store Mobile Number
              </Button>
            </div>
          </>
        )}
        <div className="d-flex justify-content-between w-100">
          <Label
            style={{ marginTop: 15 }}
            text={
              <>
                <span className="va-middle text-capitalize">
                  Store Pickup Address <span className="text-danger">*</span>
                  {store.hasOwnProperty("geocode_location") ? (
                    showMapComponent === false ? (
                      <EditIcon onClick={showMap} />
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}{" "}
                </span>
              </>
            }
          />
        </div>

        {showMapComponent ? (
          <>
            <MapComponent
              state={this.state}
              validateDelivery={this.validateDelivery}
              height="400px"
              zoom={this.state.values.zoomValue}
              data={data}
              api={this.api}
              tooltipDisplay={false}
            />
            <div className="d-flex justify-content-end">
              <Button variant="danger" onClick={hideMap}>
                Cancel
              </Button>
              <Button
                variant="primary"
                className="ml-1"
                onClick={this.saveAddress}
              >
                Save Store Pickup Address
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
        {showGeoAddress ? (
          <>
            <Card className="d-flex justify-content-between w-100">
              <Card.Body className="py-3">
                <Card.Title className="mb-0">
                  <div className="col-example align-left">
                    <div className="col-example font-weight-bolder text-dark text-left">
                      {store.geocode_address_string}
                    </div>
                  </div>
                </Card.Title>
              </Card.Body>
            </Card>
          </>
        ) : showMapComponent === false ? (
          <p>
            You don't have store address for the delivery partner{" "}
            <KTLink text={<>Do you want to add one?</>} onClick={showMap} />{" "}
          </p>
        ) : (
          <></>
        )}
      </>
    );
  }
}
class DeliveryKeysComponent extends APIComponent {
  constructor(props) {
    super(props);

    const { id = "" } = this.props;
    this.api = core("pu").get();
    this.data_url = id !== "" ? `/store/delivery_methods/${id}` : "";

    this.state = {
      ...this.state,
      prio_fee: 0,
      partnerToggleOff: undefined,
      disableMealPlanSwal: false,
      showAddDeliveryKeysModal: false,
    };

    this.onSubmitUpdateKeys = this.onSubmitUpdateKeys.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
    this.handleActiveCODChange = this.handleActiveCODChange.bind(this);
    this.handledPrioChange = this.handledPrioChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSaveChanges = this.handleSaveChanges.bind(this);
    this.handeleEditTouched = this.handeleEditTouched.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  async getData() {
    if (this.data_url !== "") {
      this._isMounted && this.setState({ data_status: "fetching" });
      this.api &&
        this.api
          .get(this.data_url)
          .then((data) => {
            const { store } = this.props;
            store.delivery_method = data.data;
            this._isMounted &&
              this.setState({
                data: data.data,
              });
            this.props.showRequiredError(isValidForDelivery(store));

            this.handleOnDataFetched();
            if (this.state.partnerToggleOff !== undefined) {
              this.props.updateToggleAllowDeliveryOrders(
                this.state.partnerToggleOff,
              );
              this.setState({ partnerToggleOff: undefined });
            }
            if (this.use_display_data) {
              this.setDisplayData();
            }
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });
    }
    this.getLMDefaultPrioFee();
  }
  async getLMDefaultPrioFee() {
    try {
      const response = await this.api.get(
        "/branded-website/delivery/lm/get-default-prio-fee",
      );
      if (response) {
        const {
          data: { default_prio_fee },
        } = response;
        this.setState({ prio_fee: toPesoAmount(default_prio_fee) });
      }
    } catch (err) {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: "Failed to retrieve default prio fee",
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  }
  handleOnDataFetched = () => {
    let { data = {} } = this.state;
    for (var i in data) {
      if (data[i].delivery_id.hasOwnProperty("delivery_key")) {
        data[i].encrypted_key = data[i].delivery_id.delivery_key;
        data[i].delivery_id.delivery_key = JSON.parse(
          window.atob(data[i].delivery_id.delivery_key),
        );
      }

      data[i].touched = false;
      data[i].editTouched = false;
      data[i].showCancel = false;
    }
    this.setState({ data, data_status: "fetched" });
  };

  onSubmitUpdateKeys = (values, setSubmitting) => {
    values["store"] = this.props.id;
    this.api
      .post({
        url: "/store/add-delivery-keys",
        data: values,
      })
      .then(({ data }) => {
        if (data.result === "ok") {
          this.setState({ showAddDeliveryKeysModal: false });
          setSubmitting(false);
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            timer: 3000,
          });
          this.getData();
          this.props.refreshUserRedux();
        } else {
          setSubmitting(false);
          MySwal.fire({
            icon: "error",
            title: "Oops!",
            text: data.message,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
          });
          this.props.refreshUserRedux();
        }
      })
      .catch((error) => {
        console.log(error);
        setSubmitting(false);
      });
  };
  handleActiveChange = (index, isActive) => {
    let { data } = this.state;
    data[index].active = !isActive;
    data[index].touched = true;

    if (data[index].name === "lalamove") {
      if (!data[index].active) {
        data[index].prio_fee_enabled = false;
      }
    }
    this.setState({ data, partnerToggleOff: !isActive });
  };

  handledPrioChange = (index, value) => {
    let { data } = this.state;
    data[index].prio_fee_enabled = !value;
    data[index].touched = true;
    this.setState({
      data,
    });
  };

  handleActiveCODChange = (index, isActiveCOD) => {
    let { data } = this.state;
    data[index].activeCOD = !isActiveCOD;
    data[index].touched = true;
    this.setState({
      data,
      partnerToggleOff: !isActiveCOD,
      disableMealPlanSwal: true,
    });
  };
  handleInputChange = (index, tag, event) => {
    const target = event.target,
      value = target.value;
    let { data } = this.state;
    data[index].delivery_id.delivery_key[tag] = value;
    this.setState({ data });
  };
  handleSaveChanges = async (index) => {
    let { data, disableMealPlanSwal } = this.state;
    let { store } = this.props;
    const values = {
      active: data[index].active,
      activeCOD: data[index].activeCOD,
      prio_fee_enabled: data[index].prio_fee_enabled,
      ...data[index].delivery_id.delivery_key,
      apikey: data[index].delivery_id.delivery_key.apikey.trim(),
      secret: data[index].delivery_id.delivery_key.secret.trim(),
      host: data[index].delivery_id.delivery_key.host.trim(),
      delivery_id: data[index].delivery_id._id,
      store: this.props.id,
    };

    this.api
      .post({
        url: "/store/add-delivery-keys",
        data: values,
      })
      .then(({ data }) => {
        if (data.result === "ok") {
          this.getData();
          this.props.refreshUserRedux();
        }
        return data;
      })
      .then(async (response) => {
        const multiplePartnerError =
          response.message === "Cannot activate multiple partner";
        let hasWalletDeliveries = await checkStoreDeliveryWallets(store._id);
        if (
          store.isMealPlanActive &&
          !hasWalletDeliveries &&
          !disableMealPlanSwal &&
          !multiplePartnerError
        ) {
          MySwal.fire({
            icon: "warning",
            title: "Meal Plan Deactivated!",
            text: "You have turned off all delivery partners with wallets. Meal plan feature is now deactivated.",
            confirmButtonColor: PRIMARY_COLOR,
          });
        } else if (response.result !== "ok") {
          MySwal.fire({
            icon: "error",
            title: "Oops!",
            text: response.message,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
          });
          this.props.refreshUserRedux();
          if (multiplePartnerError) {
            this.handleActiveChange(index, true);
            this.handleActiveCODChange(index, true);
          }
        } else {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            timer: 1000,
          });
        }
        this.setState({ disableMealPlanSwal: false });
      })
      .catch((error) => {
        // console.log(error);
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: "Error Updating delivery keys, please try again",
          confirmButtonColor: DANGER_COLOR,
          confirmButtonText: "Ok",
          timer: 2000,
        });
      });
  };
  testKeys = async (index) => {
    let { data } = this.state;
    const {
      name,
      delivery_id: {
        delivery_key: { apikey, host, secret = "" },
      },
    } = data[index];
    console.log(data[index]);
    const keys = {
      apikey,
      host,
      secret,
    };
    this.api
      .post({
        url: "/branded-website/delivery/test_quotation",
        data: {
          // delivery_partner: this.state.values.delivery_partner,
          keys,
          delivery_partner: name,
        },
      })
      .then(({ data }) => {
        if (data.result === "success") {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `Keys are working`,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "OK",
            showCancelButton: false,
          });
        } else {
          MySwal.fire({
            icon: "error",
            title: "Ooops!",
            text: `Keys are not working, please double check your keys`,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "OK",
            showCancelButton: false,
          });
        }
      })
      .catch((error) => {
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: `Keys are not working, please double check your keys`,
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      });
  };
  handeleEditTouched = (index) => {
    let { data } = this.state;
    let { trial_grab_key } = this.props;
    if (trial_grab_key.api_key === data[index].encrypted_key) {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `You cannot edit default Grab Keys, please add another instead`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      data[index].editTouched = true;
      data[index].touched = true;
      data[index].showCancel = true;
      this.setState({ data });
    }
  };
  handleCancel = (index) => {
    let { data } = this.state;
    data[index].editTouched = false;
    data[index].touched = false;
    data[index].showCancel = false;
    this.getData();
    this.setState({ data });
  };
  render() {
    const {
        data = [],
        data_status = "",
        showAddDeliveryKeysModal,
      } = this.state,
      isLoading = data_status !== "fetched",
      { editable = false } = this.props;

    return (
      <>
        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          <>
            <div className="d-flex justify-content-between w-100">
              <Label
                text={
                  <>
                    <span className="va-middle text-capitalize">
                      delivery partners{" "}
                      {data.length > 0 && editable && (
                        <KTLink
                          text={<>add another delivery partner?</>}
                          onClick={() => {
                            this.setState({ showAddDeliveryKeysModal: true });
                          }}
                        />
                      )}
                    </span>
                  </>
                }
              />
            </div>
            <div className="d-flex justify-content-between w-100">
              <Label
                text={
                  <>
                    Adding same delivery partner will overwrite the previously
                    added keys
                  </>
                }
              />
            </div>
            {data &&
              data.map((delivery, i) => {
                return (
                  <Card
                    className="d-flex justify-content-between w-100 mb-2"
                    key={`delivery-method-00${i + 1}`}
                  >
                    <Card.Body className="py-3">
                      <Card.Title className="mb-0">
                        <div className="d-flex justify-content-between w-100">
                          <div className="col-example align-left py-5">
                            <div className="col-example font-weight-boldest text-dark text-left text-uppercase">
                              {delivery.name}
                            </div>
                          </div>
                        </div>
                      </Card.Title>
                      <Card.Subtitle className="text-muted">
                        <Block
                          content={
                            <div>
                              <div className="text-dark-60 m-0 font-weight-bold">
                                {delivery.editTouched ? (
                                  <>
                                    <FormItem
                                      normal
                                      label={"API Key"}
                                      name={"apikey"}
                                      inputProps={{
                                        name: "host",
                                        placeholder: "API Key",
                                        value:
                                          delivery.delivery_id.delivery_key
                                            .apikey,
                                      }}
                                      inputActions={{
                                        onChange: (event) => {
                                          this.handleInputChange(
                                            i,
                                            "apikey",
                                            event,
                                          );
                                        },
                                      }}
                                      actions={this.props.actions}
                                      type={"text"}
                                      showRequired={true}
                                    />
                                    {withSecret(delivery) ? (
                                      <FormItem
                                        normal
                                        label={"Secret Key"}
                                        name={"secret"}
                                        inputProps={{
                                          name: "host",
                                          placeholder: "Secret Key",
                                          value:
                                            delivery.delivery_id.delivery_key
                                              .secret,
                                        }}
                                        inputActions={{
                                          onChange: (event) => {
                                            this.handleInputChange(
                                              i,
                                              "secret",
                                              event,
                                            );
                                          },
                                        }}
                                        actions={this.props.actions}
                                        type={"text"}
                                        showRequired={true}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div className="font-weight-bolder text-dark-75 font-size-lg text-uppercase">
                                      API Key:{" "}
                                      <>
                                        {getFirstXLetters(
                                          delivery.delivery_id.delivery_key
                                            .apikey,
                                          7,
                                        )}
                                        ...
                                        {getLastXLetters(
                                          delivery.delivery_id.delivery_key
                                            .apikey,
                                          4,
                                        )}{" "}
                                      </>
                                    </div>
                                    {withSecret(delivery) ? (
                                      <div className="font-weight-bolder text-dark-75 font-size-lg  text-uppercase">
                                        Secret Key:{" "}
                                        <>
                                          {getFirstXLetters(
                                            delivery.delivery_id.delivery_key
                                              .secret,
                                            7,
                                          )}
                                          ...
                                          {getLastXLetters(
                                            delivery.delivery_id.delivery_key
                                              .secret,
                                            4,
                                          )}{" "}
                                        </>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          }
                        />
                      </Card.Subtitle>
                      <Card>
                        <Card.Title className="font-weight-bolder text-dark-75 font-size-md text-uppercase ml-3 mt-3 mb-0">
                          Accepted Delivery payment
                        </Card.Title>
                        <Card.Body className="p-2">
                          <div className="row d-flex flex-column justify-content-around m-0 pl-5">
                            {/* <div className="col-example align-right d-flex align-items-center"> */}
                            {delivery.name ? ( // === "lalamove"
                              <div className="row d-flex align-items-center">
                                {/* <label className="text-dark font-weight-bolder text-uppercase py-4 px-3">{delivery.active ? "Wallet Active" : "Wallet Inactive"}:</label> */}
                                <span
                                  style={{ transform: "scale(0.8)" }}
                                  className="switch switch-outline switch-icon switch-primary py-1 m-0 pl-5"
                                >
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="isActive"
                                      checked={
                                        delivery.active ? "checked" : null
                                      }
                                      disabled={
                                        this.props.trial_grab_key.api_key ===
                                          this.state.data[i].encrypted_key ||
                                        delivery.name === "mrspeedy"
                                      }
                                      onChange={(event) =>
                                        this.handleActiveChange(
                                          i,
                                          delivery.active,
                                        )
                                      }
                                    />
                                    <span></span>
                                  </label>
                                </span>
                                <label className="text-dark font-weight-bolder text-uppercase py-4 px-3">
                                  Delivery fee included in checkout total
                                </label>
                              </div>
                            ) : (
                              <></>
                            )}
                            {delivery.name === "grab" ||
                            delivery.name === "mrspeedy" ? (
                              <div className="row d-flex align-items-center">
                                {/* <label className="text-dark font-weight-bolder text-uppercase py-4 px-3">{delivery.active ? "COD Active" : "COD Inactive"}:</label> */}
                                <span
                                  style={{ transform: "scale(0.8)" }}
                                  className="switch switch-outline switch-icon switch-primary py-1 m-0 pl-5"
                                >
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="isActiveCOD"
                                      disabled={delivery.name === "mrspeedy"}
                                      checked={
                                        delivery.activeCOD ? "checked" : null
                                      }
                                      onChange={(event) =>
                                        this.handleActiveCODChange(
                                          i,
                                          delivery.activeCOD,
                                        )
                                      }
                                    />
                                    <span></span>
                                  </label>
                                </span>
                                <label className="text-dark font-weight-bolder text-uppercase py-4 px-3">
                                  Delivery payment Cash on Delivery
                                </label>
                              </div>
                            ) : (
                              <></>
                            )}
                            {delivery.name === "lalamove" && (
                              <div className="row d-flex align-items-center">
                                <span
                                  style={{ transform: "scale(0.8)" }}
                                  className="switch switch-outline switch-icon switch-primary py-1 m-0 pl-5"
                                >
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="isPrioFeeEnabled"
                                      disabled={!delivery.active}
                                      checked={
                                        delivery.prio_fee_enabled
                                          ? "checked"
                                          : null
                                      }
                                      onChange={(event) =>
                                        this.handledPrioChange(
                                          i,
                                          delivery.prio_fee_enabled,
                                        )
                                      }
                                    />
                                    <span></span>
                                  </label>
                                </span>
                                <label className="text-dark font-weight-bolder text-uppercase py-4 px-3">
                                  Delivery Priority Fee ({this.state.prio_fee})
                                </label>
                              </div>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                      <div className="d-flex justify-content-between w-100 mt-2">
                        <div className="col-example align-left py-5">
                          Host: <>{delivery.delivery_id.delivery_key.host} </>
                        </div>
                        <div className="col-example align-right">
                          {editable ? (
                            delivery.editTouched ? (
                              <></>
                            ) : (
                              <Button
                                variant="primary"
                                className="ml-3"
                                onClick={() => this.handeleEditTouched(i)}
                              >
                                Edit Keys
                              </Button>
                            )
                          ) : (
                            <></>
                          )}
                          {delivery.showCancel ? (
                            <Button
                              variant="danger"
                              className="ml-3"
                              onClick={() => this.handleCancel(i)}
                            >
                              Cancel
                            </Button>
                          ) : (
                            <></>
                          )}
                          {delivery.touched ? (
                            <>
                              <Button
                                variant="primary"
                                className="ml-3"
                                onClick={() => this.testKeys(i)}
                              >
                                Test Key
                              </Button>
                              <Button
                                variant="primary"
                                className="ml-3"
                                onClick={() => this.handleSaveChanges(i)}
                              >
                                Save Changes
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })}
            {isEmpty(data) && (
              <p>
                You don't have any delivery partner{" "}
                {editable ? (
                  <KTLink
                    text={<>Do you want to add one?</>}
                    onClick={() =>
                      this.setState({ showAddDeliveryKeysModal: true })
                    }
                  />
                ) : (
                  <></>
                )}
              </p>
            )}

            <AddDeliveryKeysModal
              show={showAddDeliveryKeysModal}
              onHide={() => this.setState({ showAddDeliveryKeysModal: false })}
              data={data}
              onSubmit={this.onSubmitUpdateKeys}
            />
          </>
        )}
      </>
    );
  }
}
function isValidForDelivery(store) {
  return (
    !Boolean(store.geocode_location) ||
    !Boolean(store.store_mobile_number) ||
    store.delivery_method.length === 0 ||
    !countActiveDelivery(store)
  );
}
