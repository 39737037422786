import React from "react";
import ProSourceForm from "../components/ProSourceForm";
import { Modal, Button, Form } from "react-bootstrap";
import { FormItem } from "../components/Base";
import { GetSubmitClassList } from "../helpers";
import { REFUND_NOTES_CHAR_LIMIT } from "../constants";

export default class RefundRequestModal extends ProSourceForm {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        refund_notes: "",
        refunded_amount: 0,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {
      required: ["refund_notes", "refunded_amount"],
    };
  }

  handleOnChange = (event) => {
    const target = event.target,
      value = target.value,
      name = target.name;
    let { values, errors } = this.state;

    values[name] = value;
    if (name === "refund_notes" && value.length > REFUND_NOTES_CHAR_LIMIT) {
      errors[name] = "Exceeds character limit";
    } else {
      delete errors[name];
    }
    this.setState({
      values: values,
      errors,
    });

    this.setStateKey("touched", name, "true");
    this.validateForm();

    // checks if afterOnChange function exists
    this.onChange && this.onChange(event);
  };

  onChange = (event) => {
    const target = event.target,
      value = target.value,
      name = target.name;
    const { max = 0 } = this.props;
    let { errors = {} } = this.state;

    if (name === "refunded_amount") {
      if (value > max) {
        errors["refunded_amount"] =
          "Cannot issue refund higher than total amount.";
      } else {
        delete errors["refunded_amount"];
      }
    }

    this.setState({ errors });
  };

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const {
      show = false,
      onHide,
      btnLoading = false,
      transaction_number = "",
      max = 0,
    } = this.props;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };
    // console.log("max: ", max)
    return (
      <>
        <Modal show={show} onHide={onHide} centered>
          <Modal.Header>
            <Modal.Title>Refund Order #{transaction_number}</Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.props.onHide}
            >
              <i aria-hidden="true" className="ki ki-close"></i>
            </button>
          </Modal.Header>
          <Form onSubmit={this.handleOnSubmit}>
            <Modal.Body>
              <FormItem
                label={"Why are you issuing a refund on this order?"}
                name={"refund_notes"}
                inputProps={{
                  name: "refund_notes",
                  placeholder: "Reason for refund",
                  value: this.state.values["refund_notes"],
                  rows: 5,
                }}
                inputActions={inputActions}
                actions={actions}
                type={"textarea"}
                normal
                showRequired
                helperText={
                  <>
                    {this.state.values["refund_notes"].length}/
                    {REFUND_NOTES_CHAR_LIMIT}
                  </>
                }
              />

              <FormItem
                label={"Amount to be refunded"}
                name={"refunded_amount"}
                inputProps={{
                  name: "refunded_amount",
                  placeholder: max.toFixed(2),
                  // value: this.state.values["refunded_amount"],
                  max: max,
                  min: 0,
                }}
                inputActions={inputActions}
                actions={actions}
                type={"number"}
                normal
                showRequired
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="submit"
                variant="danger"
                className={GetSubmitClassList(btnLoading, "")}
                disabled={btnLoading ? "disabled" : null}
              >
                Issue Refund
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
