import moment from "moment";
import React from "react";
import { DB_DATE_FORMAT } from "../constants";

/**
 *
 * @param {String} param.order_date Required
 * @param {Object} param.style Optional
 * @returns
 */
export default function DisplayOrderDateWithCalendarIcon({
  order_date = "",
  style = null,
}) {
  return (
    <div className="font-weight-bold" style={style}>
      <span className="va-middle mr-2">
        <i className="fa fa-calendar-check font-weight-bold" />
      </span>
      <span className="va-middle">
        {moment(order_date, DB_DATE_FORMAT).format("ddd, MMM D, YYYY")}
      </span>
    </div>
  );
}
