import React from "react";
import ThemeColorsForm from "./forms/ThemeColorsForm";

export default class ThemeColorsFormDiv extends React.Component {
  render() {
    return (
      <>
        <ThemeColorsForm
          {...this.props}
          values={this.props.data.website_theme}
          onSubmit={this.props.onSubmitThemeColorsForm}
        />
      </>
    );
  }
}
