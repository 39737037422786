import moment from "moment";
import places from "../assets/places3NCR4.json";
import philippines from "../assets/places.json";
import core from "./vendors/core-api";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  DEFAULT_STORE_24_HOURS_CLOSING,
  DEFAULT_STORE_24_HOURS_OPENING,
  PRIMARY_COLOR,
} from "./constants";

import {
  DEFAULT_STORE_HOURS_OPENING,
  DEFAULT_STORE_HOURS_CLOSING,
  DB_HOURS_FORMAT,
  ASAP_PREP_TIME_IN_MIN,
  DB_DATE_FORMAT,
  ASAP_ORDER_TIME_FORMAT,
  CLOSING_HRS_FORMAT,
  TIME_OPTIONS_INTERVAL_IN_MIN,
  OPENING_HRS_FORMAT,
  TIME_OPTION_DISPLAY_FORMAT,
  TIMEZONE_OFFSET,
  PRE_ORDER_TO_ORDER_QUEUE_TIMER_DEFAULT,
  MINUTES_ALLOW_ORDER_BEFORE_OPENING_DEFAULT,
} from "./constants";
import { CountActive } from "./components/Base";
import { computeDiscount } from "./helpers/branded-website";
import { isNil } from "lodash";

const MySwal = withReactContent(Swal);

export default function InsertClassnameIfNotExisting(
  defaultClassName = "",
  givenClassName = "",
) {
  let classList = givenClassName !== "" ? givenClassName.split(" ") : [];
  return classList.indexOf(defaultClassName) !== -1
    ? classList.join(" ")
    : (classList.join(" ") + " " + defaultClassName).trim();
}

/**
 * This is a function used to return the number of active delivery partner wallets of a store.
 *
 * @param {string} storeId - The string for the id of the store
 * @return {number} The number of active delivery partner wallets for the store
 *
 * @example
 *
 *     let hasDelivery = await checkStoreDeliveryWallets(store._id)
 */
export const checkStoreDeliveryWallets = async (storeId) => {
  let nonCodDeliveries = 0;
  const fetchedStore = await fetchData("get", `/store/${storeId}`);
  nonCodDeliveries = CountActive(fetchedStore.data.delivery_method).countWallet;
  // console.log("nonCodDeliveries: ", nonCodDeliveries)
  // if (nonCodDeliveries > 0) return true
  // return false
  return nonCodDeliveries;
};

/**
 * This is a function used for fetching data from the api.
 *
 * @param {string} reqType - A type of API request in string format ('get', 'post', 'put')
 * @param {string} apiUrl - A relative URL string of the API to be appended to the base URL identified in the core
 * @param {Object} apiParams - An object which contains the parameters needed by the API
 * @return {(string|array|object)} The response from the API request
 *
 * @example
 *
 *     fetchData('post', '/googleanalytics/page_views/pageTitle', {dateStart: "7daysAgo", dateEnd: "today", filter: "Tea Time"})
 */
export async function fetchData(reqType, apiUrl, apiParams) {
  let response = "";
  const thisApi = core("pu").get();
  if (reqType === "get") {
    response = await thisApi.get(apiUrl);
  } else if (reqType === "getWithParams") {
    response = await thisApi.get({
      url: apiUrl,
      data: apiParams,
    });
  } else if (reqType === "post") {
    response = await thisApi.post({
      url: apiUrl,
      data: apiParams,
    });
  } else if (reqType === "put") {
    response = await thisApi.put({
      url: apiUrl,
      data: apiParams,
    });
  }
  // else if (reqType === "delete") {
  //     response = await thisApi.delete({
  //         url: apiUrl,
  //         data: apiParams
  //     })
  // }
  return response;
}

/**
 * This is a function used to return a formatted dispatch string ("pickup", "dispatch", "serve") based on the order_type enum values
 *
 * @param {string} order_type - The string for the order type
 * @return {string} The formatted dispatch string
 *
 * @example
 *
 *     let dispatchString = formatDispatchString(order_type)
 */
export function formatDispatchString(order_type) {
  switch (order_type) {
    case "pickup":
      return "pickup";
    case "delivery":
      return "dispatch";
    case "third_party_pickup":
      return "pickup";
    case "curbside_pickup":
      return "pickup";
    case "dine_in":
      return "serve";
    default:
      return order_type;
  }
}

/**
 * This is a function used to return a formatted order type based on the order_type enum values
 *
 * @param {string} order_type - The string for the order type
 * @return {string} The formatted order type
 *
 * @example
 *
 *     let orderType = formatOrderType(order_type)
 */
export function formatOrderType(order_type) {
  switch (order_type) {
    case "pickup":
      return "Pickup";
    case "delivery":
      return "Delivery";
    case "third_party_pickup":
      return "Third Party Pickup";
    case "curbside_pickup":
      return "Curbside Pickup";
    case "dine_in":
      return "Dine-in";
    case "meal_plan":
      return "Meal Plan";
    default:
      return order_type;
  }
}

/**
 * This is a function used to return a formatted order type based on the promo
 * @param {object} promo - The object for the promo
 * @return {string} The formatted promo type
 *
 * @example
 *
 *     let orderType = formatPromoType(promo)
 */
export function formatPromoType(promo) {
  const {
    promo_type,
    amount_off = 0,
    percentage_off = 0,
    subsidized_delivery_amount = 0,
  } = promo;

  switch (promo_type) {
    case "percentage_off":
      return `${percentage_off}% OFF`;
    case "amount_off":
      return `AMOUNT OFF ${toPesoAmount(amount_off)}`;
    case "free_delivery":
      return `DELIVERY OFF ${toPesoAmount(subsidized_delivery_amount)}`;
    default:
      return "";
  }
}

export function formatPromoName(promo) {
  const { promo_type } = promo;

  switch (promo_type) {
    case "percentage_off":
      return "Percentage Off";
    case "amount_off":
      return "Amount Off";
    case "free_delivery":
      return "Delivery Off";
    case "free_item":
      return "Free Item";
    default:
      return "";
  }
}

/**
 * This is a function used to return a boolean of whether a user role is allowed for the scope of the promo
 * @param {string} userRole - String which describes the role of the user
 * @param {string} promoScope - String which describes the scope of the promo
 * @return {boolean} - Boolean if user role is allowed to edit promo or not
 *
 * @example
 *
 *     let isAllowed = isPromoScopeAllowedForUserLevel("console_user", "group")
 */
export function isPromoScopeAllowedForUserLevel(userRole, promoScope) {
  // console.log(
  //   "promoform isScopeAllowedForUserLevel userRole, promoScope: ",
  //   userRole,
  //   promoScope
  // );
  if (userRole === "group_admin") {
    if (["group", "brand", "store"].includes(promoScope)) return true;
    // console.log("promoform isScopeAllowedForUserLevel false: ", userRole);
    return false;
  } else if (userRole === "brand_admin") {
    if (["brand", "store"].includes(promoScope)) return true;
    // console.log("promoform isScopeAllowedForUserLevel false: ", userRole);
    return false;
  } else if (userRole === "store_admin") {
    if (["store"].includes(promoScope)) return true;
    // console.log("promoform isScopeAllowedForUserLevel false: ", userRole);
    return false;
  } else {
    return false;
  }
}

// Grab Formats

// export function formatGrabRequest ({ store_location, store_contact, store_name , delivery_address , customer_name, customer_contact_number ,remarks="",scheduleAt="",markerPosition={}, delivery_payment_method, isCOD, }){
export function formatGrabRequest({
  store_location,
  store_contact,
  store_name,
  delivery_address,
  first_name,
  last_name,
  customer_contact_number,
  remarks = "",
  scheduleAt = "",
  markerPosition = {},
  delivery_payment_method,
  isCOD,
  delivery_vehicle,
  storePosition = {},
  customer_email,
}) {
  let timestamp = new Date().getTime();
  let val = {
    merchantOrderID: `${store_name}-${customer_contact_number}-${timestamp}`, //"1ac1fa2f-880a-43d3-8476-7cc6e99e40f6",
    serviceType: delivery_vehicle === "car" ? "BULK" : "INSTANT", // "INSTANT" - motorcycle, "BULK" - car
    paymentMethod: delivery_payment_method, //"CASH", //"CASHLESS",
    packages: [
      {
        name: "Food",
        description: `Food from ${store_name}`,
        quantity: 1,
        price: 5,
        dimensions: {
          height: 0,
          width: 0,
          depth: 0,
          weight: 0,
        },
      },
    ],
    origin: {
      address: store_location,
      coordinates: {
        latitude: storePosition.lat,
        longitude: storePosition.lng,
      },
    },
    destination: {
      address: delivery_address,
      coordinates: {
        latitude: markerPosition.lat,
        longitude: markerPosition.lng,
      },
    },
    recipient: {
      firstName: first_name,
      lastName: last_name,
      email: customer_email.replaceAll(/\s/g, ""),
      phone: customer_contact_number,
      smsEnabled: true,
    },
    sender: {
      firstName: store_name,
      companyName: store_name,
      email: "",
      phone: store_contact,
      smsEnabled: true,
      instruction: remarks,
    },
    // "cashOnDelivery": {
    //     "amount": 0
    // }
    // ,
    // "schedule": {
    //   "pickupTimeFrom" : "2021-04-11T12:37:28+08:00",
    //   "pickupTimeTo" : "2021-04-11T12:37:28+08:00"
    // }
  };
  if (scheduleAt !== "") {
    // val.scheduleAt = moment(scheduleAt,`${DB_DATE_FORMAT} ${DB_HOURS_FORMAT}`).add(15,"minutes").utc().toISOString()
    const momentSchedule = moment(
      scheduleAt,
      `${DB_DATE_FORMAT} ${DB_HOURS_FORMAT}`,
    );
    val.schedule = {};
    val.schedule.pickupTimeFrom = momentSchedule.utc().toISOString();
    val.schedule.pickupTimeTo = momentSchedule
      .add(30, "minutes")
      .utc()
      .toISOString();
  }
  return val;
}

export function formatGrabError({ message }) {
  if (message === "Distance SLA Exceeded") {
    return "Distance is more than the maximum distance allowed.";
  } else if (message === "Order ETA SLA has exceeded") {
    return "Grab is unable to deliver within the agreed allocation, pickup and drop time window.";
  } else if (message === "Package over weight limit") {
    return "Package over weight limit.";
  } else if (message === "Package over size limit") {
    return "Package over size limit";
  } else if (message === "Invalid ServiceType") {
    return "Service Type is not supported for your project or is incorrect.";
  } else if (message === "City not supported") {
    return "City hasn't been activated for your project or, GrabExpress is unavailable in that city";
  } else if (message === "Taxi type ID not found") {
    return "Invalid Payment Type.";
  } else if (message === "Multi-City Delivery not supported") {
    return "Multi-City Delivery not supported";
  } else if (message === "Empty City ID") {
    return "Unable to get the cityID from lat-long/address given. Please check your lat-long/address values";
  } else if (message === "Internal Server Error") {
    return "Service Unavailable, Please try again later";
  } else {
    return message;
  }
}

export function formatDeliveryStatus(partner, status) {
  if (partner === "lalamove") {
    switch (status) {
      case "MULTI_ASSIGNING":
        return "Stay back and relax. We are now searching a rider to pickup your food.";
      case "ASSIGNING_DRIVER":
        return "Stay back and relax. We are now searching a rider to pickup your food.";
      case "ON_GOING":
        return "Yay! We found a rider and now on it's way to pickup your food.";
      case "PICKED_UP":
        return "Great! Your order has been picked up by the rider and now on it's way to deliver your food.";
      case "COMPLETED":
        return "Enjoy! Your food has been delivered!";
      case "CANCELED":
        return "We are currenty booking delivery for this order.";
      default:
        return "Delivery Cancelled";
    }
  } else if (partner === "mrspeedy") {
    if (status === "planned" || status === "MULTI_ASSIGNING") {
      return "Stay back and relax. We are now searching a rider to pickup your food";
    } else if (status === "courier_assigned" || status === "courier_departed") {
      return "Yay! We found a rider and now on it's way to pickup your food";
    } else if (status === "parcel_picked_up" || status === "active") {
      return "Great! Your order has been picked up by the rider and now on it's way to deliver your food";
    } else if (status === "courier_arrived") {
      return "Rider arrived at your location";
    } else if (status === "finished") {
      return "Enjoy! Your food has been delivered!";
    } else if (status === "failed") {
      return "Delivery Cancelled";
    } else if (status === "delayed") {
      return "Delivery Delayed";
    } else if (status === "canceled") {
      return "We are currenty booking delivery for this order";
    } else {
      return "Delivery Cancelled";
    }
  } else if (partner === "grab") {
    switch (status) {
      case "MULTI_ASSIGNING":
      case "ALLOCATING":
      case "QUEUEING":
      case "PENDING_PICKUP":
        return "Stay back and relax. We are now searching a rider to pickup your food.";
      case "PICKING_UP":
      case "PENDING_DROP_OFF":
        return "Yay! We found a rider and now on it's way to pickup your food.";
      case "IN_DELIVERY":
        return "Great! Your order has been picked up by the rider and now on it's way to deliver your food.";
      case "COMPLETED":
        return "Enjoy! Your food has been delivered!";
      case "CANCELED":
        return "We are currenty booking delivery for this order.";
      default:
        return "Delivery Cancelled";
    }
  }
}
export function formatDeliveryStatusTitles(partner, status, source) {
  if (partner === "lalamove") {
    switch (status) {
      case "MULTI_ASSIGNING":
      case "ASSIGNING_DRIVER":
        return "Searching for a rider";
      case "ON_GOING":
        return "Rider picking up the food";
      case "PICKED_UP":
        return "Out for delivery"; //return "Rider on its way"
      case "COMPLETED":
        return "Food has been delivered!";
      case "FOR_BOOKING":
        return "For Booking";
      case "REFRESHING":
        return "Refreshing..";
      case "CANCELED":
        if (source === "merchant") {
          return "Delivery Cancelled";
        } else if (source === "tracking") {
          return "Booking for a delivery";
        }
        break;
      default:
        return "Delivery Cancelled";
    }
  } else if (partner === "mrspeedy") {
    // if(status==="planned"||status==="courier_assigned"||status===null){
    if (status === "planned" || status === "MULTI_ASSIGNING") {
      return "Searching for a Rider";
    } else if (status === "courier_assigned" || status === "courier_departed") {
      return "Rider picking up the food";
    } else if (
      status === "parcel_picked_up" ||
      status === "courier_arrived" ||
      status === "active"
    ) {
      return "Out for delivery"; //return "Rider on its way"
    } else if (status === "finished") {
      return "Food has been delivered!";
    } else if (status === "failed") {
      return "Delivery Cancelled";
    } else if (status === "delayed") {
      return "Delivery Delayed";
    } else if (status === "FOR_BOOKING") {
      return "For Booking";
    } else if (status === "REFRESHING") {
      return "Refreshing..";
    } else if (status === "canceled") {
      if (source === "merchant") {
        return "Delivery Cancelled";
      } else if (source === "tracking") {
        return "Booking for a delivery";
      }
    } else {
      return "Delivery Cancelled";
    }
  } else if (partner === "grab") {
    switch (status) {
      case "MULTI_ASSIGNING":
      case "ALLOCATING":
      case "QUEUEING":
      case "PENDING_PICKUP":
        return "Searching for a rider";
      case "PICKING_UP":
      case "PENDING_DROP_OFF":
        return "Rider picking up the food";
      case "IN_DELIVERY":
        return "Out for delivery"; //return "Rider on its way"
      case "COMPLETED":
        return "Food has been delivered!";
      case "FOR_BOOKING":
        return "For Booking";
      case "REFRESHING":
        return "Refreshing..";
      case "CANCELED":
        if (source === "merchant") {
          return "Delivery Cancelled";
        } else if (source === "tracking") {
          return "Booking for a delivery";
        }
      default:
        return "Delivery Cancelled";
    }
  }
}

// Mr Speedy Formats

export function formatMrSpeedyRequest({
  store_location,
  store_contact,
  store_name,
  delivery_address,
  customer_name,
  customer_contact_number,
  remarks = "",
  scheduleAt = "",
  markerPosition = {},
  delivery_payment_method,
  isCOD,
  delivery_vehicle,
  storePosition = {},
}) {
  let val = {
    matter: "Food",
    vehicle_type_id: delivery_vehicle === "car" ? 7 : 8, // 8 - motorcycle, 7 - car
    total_weight_kg: 0,
    insurance_amount: 0.0,
    is_client_notification_enabled: false,
    is_contact_person_notification_enabled: false,
    is_route_optimizer_enabled: false,
    loaders_count: 0,
    backpayment_details: null,
    is_motobox_required: true,
    payment_method: delivery_payment_method,
    bank_card_id: null,
    points: [
      {
        address: store_location,
        latitude: String(storePosition.lat),
        longitude: String(storePosition.lng),
        contact_person: {
          name: store_name,
          phone: store_contact,
        },
        is_order_payment_here: false,
      },
      {
        address: delivery_address,
        latitude: String(markerPosition.lat),
        longitude: String(markerPosition.lng),
        required_start_datetime: null,
        required_finish_datetime: null,
        contact_person: {
          name: customer_name,
          phone: customer_contact_number,
        },
        is_order_payment_here: isCOD,
        note: remarks,
      },
    ],
  };
  if (scheduleAt !== "") {
    // val.points[0].required_start_datetime = moment(scheduleAt,`${DB_DATE_FORMAT} ${DB_HOURS_FORMAT}`).add(15,"minutes").utc().toISOString()
    val.points[0].required_start_datetime = moment(
      scheduleAt,
      `${DB_DATE_FORMAT} ${DB_HOURS_FORMAT}`,
    )
      .utc()
      .toISOString();
  }
  return val;
}

export function withSecret(delivery) {
  return (
    (delivery.delivery_id.delivery_key.hasOwnProperty("secret") &&
      delivery.delivery_id.delivery_key.secret !== "") ||
    delivery.name !== "mrspeedy"
  );
}

export function deliveryPartnerHost(partner) {
  if (partner === "lalamove") {
    return "https://rest.sandbox.lalamove.com";
  } else if (partner === "mrspeedy") {
    return "https://robotapitest.mrspeedy.ph/api/business/1.1";
  } else if (partner === "grab") {
    return "https://partner-api.grab.com/grab-express-sandbox";
    // } else if(partner==="mrspeedy"){
    //     return "https://robotapitest.mrspeedy.ph/api/business/1.1"
  }
}

export function formatMrSpeedyWarning({ message }) {
  if (message === "invalid_region") {
    return "Address is out of the delivery area for the region.";
  } else if (message === "required") {
    return "Required parameter was not provided.";
  } else if (message === "unknown") {
    return "Unknown parameter was encountered.";
  } else if (message === "different_regions") {
    return "Addresses from different regions are not allowed.";
  } else {
    return message;
  }
}

export function formatMrSpeedyError({ message }) {
  if (message === "requests_limit_exceeded") {
    return "You have reached an API usage limit.";
  } else if (message === "service_unavailable") {
    return "Our service is temporarily unavailable. Please try again later.";
  } else if (message === "order_cannot_be_canceled") {
    return "Order cannot be canceled.";
  } else if (message === "order_is_duplicate") {
    return "Duplicate order rejected";
  } else if (message === "route_not_found") {
    return "Route not found.";
  } else {
    return message;
  }
}

// Lalamove Formats

export function formatLmoveRequest({
  storePosition,
  store_location,
  delivery_address,
  scheduleAt = "",
  delivery_vehicle,
  markerPosition = {},
}) {
  let val = {
    serviceType: delivery_vehicle === "car" ? "SEDAN" : "MOTORCYCLE",
    language: "en_PH", //"MOTORCYCLE", "MPV"
    stops: [
      {
        coordinates: {
          lat: String(storePosition.lat),
          lng: String(storePosition.lng),
        },
        address: store_location,
      },
      {
        coordinates: {
          lat: String(markerPosition.lat),
          lng: String(markerPosition.lng),
        },
        address: delivery_address,
      },
    ],
    specialRequests: ["THERMAL_BAG_1"],
  };
  if (delivery_vehicle === "car") {
    delete val.specialRequests;
  }

  if (scheduleAt !== "") {
    // val.scheduleAt = moment(scheduleAt,`${DB_DATE_FORMAT} ${DB_HOURS_FORMAT}`).add(15,"minutes").utc().toISOString()
    val.scheduleAt = moment(scheduleAt, `${DB_DATE_FORMAT} ${DB_HOURS_FORMAT}`)
      .utc()
      .toISOString();
  }
  return val;
}

export function formatLmoveQouteResponse(params) {
  const {
    data,
    body,
    notes,
    totalFee,
    currency,
    store_name,
    store_contact,
    customer_name,
    customer_contact_number,
  } = params;
  return JSON.stringify({
    ...data,
    checkout_req: body,
    language: "en_PH",
    notes,
    quotedTotalFee: {
      amount: totalFee,
      currency: currency,
    },
    sender: {
      name: store_name,
      phone: `+63${store_contact.substring(1)}`,
    },
    recipients: {
      name: customer_name,
      phone: `+63${customer_contact_number}`,
      remarks: notes,
    },
  });
}

export function formatLmoveError({ message }) {
  if (message === "ERR_INVALID_PHONE_NUMBER") {
    return "Your contact number is invalid";
  } else if (message === "ERR_INVALID_PHONE_NUMBER") {
    return "Your contact number is invalid";
  } else if (message === "ERR_REQUIRED_FIELD") {
    return "Please complete all the fields";
  } else if (message === "ERR_REVERSE_GEOCODE_FAILURE") {
    return "Invalid address";
  } else if (message === "ERR_OUT_OF_SERVICE_AREA") {
    return "Address is out of service area";
  } else {
    return message;
  }
}

export function GetDataFromEvent(event, k) {
  return event.currentTarget.attributes.getNamedItem(k).value;
}

export function GetSubmitClassList(
  isSubmitting = false,
  defaultClassName = "",
) {
  return isSubmitting
    ? defaultClassName + " spinner spinner-right"
    : defaultClassName;
}

export function generateDefaultStoreHours(is_store_24_hours = false) {
  let store_hours = [];
  for (var i = 0; i < 7; i++)
    store_hours.push({
      _id: new Date().getTime().toString() + "_" + i,
      day: i.toString(),
      opening: is_store_24_hours
        ? DEFAULT_STORE_24_HOURS_OPENING
        : DEFAULT_STORE_HOURS_OPENING,
      closing: is_store_24_hours
        ? DEFAULT_STORE_24_HOURS_CLOSING
        : DEFAULT_STORE_HOURS_CLOSING,
      isOpen: true,
    });
  return store_hours;
}

export function generateDefaultReservationHours() {
  let store_hours = [];
  for (var i = 0; i < 7; i++)
    store_hours.push({
      _id: new Date().getTime().toString() + "_" + i,
      day: i.toString(),
      opening: DEFAULT_STORE_HOURS_OPENING,
      closing: DEFAULT_STORE_HOURS_CLOSING,
      isOpen: true,
    });
  return store_hours;
}

export function timeparse(t) {
  return t === "" ? 0 : parseInt(t.replace(":", ""));
}

// params: store_schedule_today (array from store hours)
// returns the { opening, closing, isOpen } that is applicable to multi-store hours
function getStoreHoursRange({ store_schedule_today = [] }) {
  let opening = "",
    closing = "",
    isOpen = false;
  for (let i = 0; i < store_schedule_today.length; i++) {
    if (store_schedule_today[i].isOpen) {
      const currentTime = moment(new Date(), DB_HOURS_FORMAT);
      const openingTime = moment(
        store_schedule_today[i].opening,
        DB_HOURS_FORMAT,
      );
      const closingTime = moment(
        store_schedule_today[i].closing,
        DB_HOURS_FORMAT,
      );

      if (currentTime.isBetween(openingTime, closingTime, "[]")) {
        isOpen = true;
      }

      const top = timeparse(opening),
        tcl = timeparse(closing);
      if (top >= timeparse(store_schedule_today[i].opening)) {
        opening = store_schedule_today[i].opening;
      }

      if (tcl < timeparse(store_schedule_today[i].closing)) {
        closing = store_schedule_today[i].closing;
      }
    }
  }

  return { opening, closing, isOpen };
}

export function isOpen24Hours(openingTime, closingTime) {
  return openingTime === "00:00" && closingTime === "23:59";
}

export function generateStoreHoursDisplay(
  {
    store_hours = [],
    off_dates = [],
    is_store_hours_open = false,
    is_store_has_asap_slot = false,
  },
  mode = "admin",
) {
  let days_open = [],
    days_closed = [],
    operation_hours_weekly = [],
    days_text = "",
    hours_text = "";

  const moment_today = moment(new Date()),
    moment_today_day_format = moment_today.format("d");

  let hours = [false, false, false, false, false, false, false];

  for (var i = 0; i < store_hours.length; i++) {
    const {
        day = "0",
        isOpen = false,
        opening = "",
        closing = "",
      } = store_hours[i],
      is24hr = isOpen24Hours(opening, closing),
      operation_hours_per_daily = is24hr
        ? "all around the clock"
        : moment(opening, "HH:mm").format("hh:mm A") +
          " to " +
          moment(closing, "HH:mm").format("hh:mm A");

    if (isOpen) {
      hours[parseInt(day)] = true;
    }

    if (operation_hours_weekly.indexOf(operation_hours_per_daily) === -1) {
      operation_hours_weekly.push(operation_hours_per_daily);
    }
  }

  for (var i = 0; i < hours.length; i++) {
    if (hours[i]) days_open.push(moment(i.toString(), "d").format("dddd"));
    else days_closed.push(moment(i.toString(), "d").format("dddd"));
  }

  if (mode === "branded-website") {
    if (days_open.length === 7) {
      days_text = "Open everyday";
    } else if (days_closed.length === 7) {
      days_text = "Closed the whole week";
    } else {
      if (days_open.length > days_closed.length) {
        // days_text = "Store is open the whole week except for " + humanizeArrayJoin(days_closed, ", ", "and") + ".";
        days_text =
          "Open everyday except " +
          humanizeArrayJoin(days_closed, ", ", "and") +
          ".";
      } else {
        days_text =
          "Open every " + humanizeArrayJoin(days_open, ", ", "and") + ".";
      }
    }
  } else if (mode === "branded-website-today") {
    const store_schedule_today = store_hours.filter((day_schedule = {}) => {
      return (
        day_schedule.day === moment_today_day_format && day_schedule.isOpen
      );
    });
    const sched = [];

    const isStoreOpenForToday = is_store_hours_open;
    const isCurrentlyOpen = is_store_has_asap_slot;
    let is24hr = false;
    for (var i = 0; i < store_schedule_today.length; i++) {
      is24hr = isOpen24Hours(
        store_schedule_today[i].opening,
        store_schedule_today[i].closing,
      );
      if (is24hr) {
        sched.push(" open 24 hours");
      } else {
        sched.push(
          `${moment(store_schedule_today[i].opening, "HH:mm").format(
            "hh:mm A",
          )} to ${moment(store_schedule_today[i].closing, "HH:mm").format(
            "hh:mm A",
          )}`,
        );
      }
    }
    days_text = isStoreOpenForToday
      ? `${!isCurrentlyOpen ? "Store is currently CLOSED." : ""}  ${
          is24hr ? "Available today," : "Open today from"
        }  ${sched.join(" and ")}`
      : "Closed today.";
  } else {
    if (days_open.length === 7) {
      days_text = "Store is open the whole week.";
    } else if (days_closed.length === 7) {
      days_text = "Store is closed the whole week.";
    } else {
      if (days_open.length > days_closed.length) {
        days_text =
          "Store is open the whole week except for " +
          humanizeArrayJoin(days_closed, ", ", "and") +
          ".";
      } else {
        days_text =
          "Store is closed the whole week except for " +
          humanizeArrayJoin(days_open, ", ", "and") +
          ".";
      }
    }

    if (operation_hours_weekly.length === 1) {
      hours_text = "Operation hours is from " + operation_hours_weekly[0] + ".";
    } else {
      hours_text = "Operation hours vary everyday.";
    }
  }

  return days_text + " " + hours_text;
}

export function generateReservationHoursDisplay(
  { store_hours = [], off_dates = [] },
  mode = "admin",
) {
  let days_open = [],
    days_closed = [],
    operation_hours_weekly = [],
    days_text = "",
    hours_text = "";

  const moment_today = moment(new Date()),
    moment_today_day_format = moment_today.format("d");

  let hours = [false, false, false, false, false, false, false];

  for (var i = 0; i < store_hours.length; i++) {
    const {
        day = "0",
        isOpen = false,
        opening = "",
        closing = "",
      } = store_hours[i],
      operation_hours_per_daily =
        moment(opening, "HH:mm").format("hh:mm A") +
        " to " +
        moment(closing, "HH:mm").format("hh:mm A");

    if (isOpen) {
      hours[parseInt(day)] = true;
    }

    if (operation_hours_weekly.indexOf(operation_hours_per_daily) === -1) {
      operation_hours_weekly.push(operation_hours_per_daily);
    }
  }

  for (var i = 0; i < hours.length; i++) {
    if (hours[i]) days_open.push(moment(i.toString(), "d").format("dddd"));
    else days_closed.push(moment(i.toString(), "d").format("dddd"));
  }

  if (mode === "branded-website") {
    if (days_open.length === 7) {
      days_text = "Open everyday";
    } else if (days_closed.length === 7) {
      days_text = "Closed the whole week";
    } else {
      if (days_open.length > days_closed.length) {
        // days_text = "Store is open the whole week except for " + humanizeArrayJoin(days_closed, ", ", "and") + ".";
        days_text =
          "Open everyday except " +
          humanizeArrayJoin(days_closed, ", ", "and") +
          ".";
      } else {
        days_text =
          "Open every " + humanizeArrayJoin(days_open, ", ", "and") + ".";
      }
    }
  } else if (mode === "branded-website-today") {
    const store_schedule_today = store_hours.filter((day_schedule = {}) => {
      return (
        day_schedule.day === moment_today_day_format && day_schedule.isOpen
      );
    });
    const {
      isOpen = false,
      opening = "",
      closing = "",
    } = getStoreHoursRange({
      store_schedule_today,
    });
    const isStoreOpenForToday =
        isOpen && off_dates.indexOf(moment_today.format(DB_DATE_FORMAT)) === -1,
      isCurrentlyOpen = moment_today.isBetween(
        moment(opening, "HH:mm"),
        moment(closing, "HH:mm"),
        "[]",
      ),
      sched = [];

    for (var i = 0; i < store_schedule_today.length; i++) {
      sched.push(
        `${moment(store_schedule_today[i].opening, "HH:mm").format(
          "hh:mm A",
        )} to ${moment(store_schedule_today[i].closing, "HH:mm").format(
          "hh:mm A",
        )}`,
      );
    }
    days_text = isStoreOpenForToday
      ? `${
          !isCurrentlyOpen ? "Store is currently CLOSED." : ""
        } Open today from ${sched.join(" and ")}`
      : "Closed today.";
  } else {
    if (days_open.length === 7) {
      days_text = "Store is open for reservations the whole week.";
    } else if (days_closed.length === 7) {
      days_text = "Store is closed for reservations the whole week.";
    } else {
      if (days_open.length > days_closed.length) {
        days_text =
          "Store is open for reservations the whole week except for " +
          humanizeArrayJoin(days_closed, ", ", "and") +
          ".";
      } else {
        days_text =
          "Store is closed for reservations the whole week except for " +
          humanizeArrayJoin(days_open, ", ", "and") +
          ".";
      }
    }

    if (operation_hours_weekly.length === 1) {
      hours_text =
        "Reservation hours is from " + operation_hours_weekly[0] + ".";
    } else {
      hours_text = "Reservation hours vary everyday.";
    }
  }

  return days_text + " " + hours_text;
}

export function generateOffDatesDisplay(off_dates) {
  if (off_dates.length > 0) {
    return `There are ${off_dates.length} day(s) marked as off dates.`;
  } else {
    return `Store doesn't have any off dates set.`;
  }
}

export function generateDayAdvancedPreorderSettingsDisplay({
  is_accepting_in_advanced_orders = false,
  days_accepting_in_advanced_orders = 0,
}) {
  return is_accepting_in_advanced_orders
    ? `This store is accepting pre-orders ${pluralize(
        days_accepting_in_advanced_orders,
        "day",
      )} in advance.`
    : "This store is not accepting day-advanced preorders.";
}

export function generatePreorderSettingsDisplay({
  pre_order_to_order_queue_timer = PRE_ORDER_TO_ORDER_QUEUE_TIMER_DEFAULT,
  minutes_allow_order_before_opening = MINUTES_ALLOW_ORDER_BEFORE_OPENING_DEFAULT,
}) {
  return (
    "Store will be notified " +
    pre_order_to_order_queue_timer +
    " minutes before pickup time." +
    (minutes_allow_order_before_opening > 0
      ? " Store allows ordering " +
        minutes_allow_order_before_opening +
        " minutes before opening time."
      : "")
  );
}

export function humanizeArrayJoin(arr, connector = ",", lastConnector = "and") {
  return arr.length > 0
    ? arr.length > 1
      ? arr.slice(0, arr.length - 1).join(connector) +
        " " +
        lastConnector +
        " " +
        arr.pop()
      : arr[0]
    : "";
}

export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function hashifyString(str) {
  return str
    .trim()
    .split(" ")
    .filter((spl) => {
      return spl.trim() !== "";
    })
    .join("-")
    .toLowerCase();
}

export function removeStringSpaces(str) {
  return str
    .trim()
    .split(" ")
    .filter((spl) => {
      return spl.trim() !== "";
    })
    .join("")
    .toLowerCase();
}

/**
 *
 * @param {Number} ctr
 * @param {String} str
 * @returns
 */
export function pluralize(ctr, str) {
  return `${ctr} ${str}${ctr > 1 ? "s" : ""}`;
}

export function filterObj(obj = {}, fields_to_exclude = []) {
  let newobj = {};

  for (var key in obj) {
    if (fields_to_exclude.indexOf(key) === -1) newobj[key] = obj[key];
  }

  return newobj;
}

const constructDomain = (location) => {
  const defaultDomain = "pickup.ph";

  // Dev local
  if (location?.includes("localhost")) {
    // localhost:port
    return `${location}:${window.location.port}`;
  }

  if (location?.includes("app.dev") || location?.includes("app.stg")) {
    //app.dev.pickup.ph
    //app.stg.pickup.ph
    return location
      ?.split(".")
      .filter((key) => key !== "app")
      .join(".");
  } else {
    // pickup.ph
    return defaultDomain;
  }
};

export function generatePickupPhLink(brand_url, wholeLink = true) {
  let domainName = constructDomain(window.location.hostname);

  return wholeLink
    ? "https://" + brand_url + `.${domainName}`
    : brand_url + `.${domainName}`;
}

export function computeTotalCartAmount(arr = []) {
  let total = 0;
  for (var i = 0; i < arr.length; i++) total += arr[i].qty * arr[i].price;

  return total;
}

export function phoneNumberPrefix(contact_number = "") {
  contact_number = contact_number.startsWith("+")
    ? contact_number.split("+63")[1]
    : contact_number;
  return contact_number[0] === "0" ? contact_number : "0" + contact_number;
}

export function getStatusDisplay(
  status = "",
  order_type = "",
  is_preorder = false,
) {
  let display = status;

  if (order_type === "third_party_pickup") order_type = "third party pickup";
  if (order_type === "curbside_pickup") order_type = "curbside pickup";
  if (order_type === "dine_in") order_type = "serving";

  if (status === "cancelled_full_refund") display = "cancelled - full refund";
  else if (status === "cancelled_partial_refund")
    display = "cancelled - partial refund";
  else if (status === "cancelled_no_refund") display = "cancelled - no refund";
  else if (status === "ready") display = "ready for " + order_type;
  else if (status === "new" && is_preorder) display = "scheduled";
  else if (status === "completed_full_refund")
    display = "completed - full refund";
  else if (status === "completed_partial_refund")
    display = "completed - partial refund";
  else {
    display = status.split("_").join(" ");
  }

  return display;
}

export function getVariantByStatus(status = "") {
  let variant = "primary";

  if (
    status === "cancelled_full_refund" ||
    status === "cancelled_partial_refund" ||
    status === "cancelled_no_show"
  )
    variant = "dark";
  else if (status === "new") variant = "danger";
  else if (status === "completed") variant = "info";

  return variant;
}

export function isOrderReadOnly(status = "") {
  return (
    status === "cancelled_full_refund" ||
    status === "cancelled_partial_refund" ||
    status === "cancelled_no_refund" ||
    status === "cancelled_order_moved" ||
    status === "cancelled" ||
    status === "cancelled_no_show" ||
    status === "completed" ||
    status === "refunded" || // to be deprecated
    status === "completed_full_refund" ||
    status === "completed_partial_refund"
  );
}

export function isOrderRefunded(status = "") {
  return (
    status === "completed_full_refund" ||
    status === "completed_partial_refund" ||
    status === "cancelled_full_refund" ||
    status === "cancelled_partial_refund" ||
    status === "cancelled_no_refund"
  );
}

export function getLastXLetters(str = "", x = 1) {
  return str.substr(str.length - x);
}

export function getFirstXLetters(str = "", x = 1) {
  return str.substr(0, x);
}

export function Humanize(str = "") {
  return str.split("_").join(" ").split("-").join(" ");
}

export function pickExcept(obj = {}, values = []) {
  let newobj = {};
  for (var key in obj) {
    if (!values.includes(key)) newobj[key] = obj[key];
  }

  return newobj;
}

export function decryptPaymentDetails(payment_details = "") {
  const details =
    payment_details !== "" ? JSON.parse(window.atob(payment_details)) : {};
  return details.hasOwnProperty("paymongo_payment_id")
    ? details.paymongo_payment_id
    : "";
}

export function toPesoAmount(val) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  }).format(val);
}

export function capitalizeText(text) {
  return text !== undefined
    ? text.replace(/\b\w/g, (c) => c.toUpperCase())
    : "";
}

//DASHBOARD GRAPHS
export const stackedBarGraphProps = (dataArr = [], dateRange = "day") => {
  let series = [],
    adjustments = [];
  const adjustmentFields = [
    "delivery_fee",
    "refunded_amount",
    "mealplan_discount",
  ]; // put all the future adjustments here (sample: discounts, promo?)

  const seriesNames = Object.keys(dataArr[0] || {}).filter(
    (name) => ![...adjustmentFields, "_id"].includes(name),
  );

  seriesNames.forEach((name) => {
    const seriesData = dataArr.map((data) => data[name]);
    series = [
      ...series,
      { name: name.replace("_", " ").replace("sales", ""), data: seriesData },
    ];
  });

  adjustmentFields.forEach((field) => {
    const fieldData = dataArr.map((data) => data[field] || 0);
    adjustments = [
      ...adjustments,
      { name: field.replace("_", " ").replace("sales", ""), data: fieldData },
    ];
  });

  // const categories = dataArr.map(data => moment(data._id).format(forMonth? 'D': 'MMM D'))
  const categories = dataArr.map((data) => {
    if (dateRange === "allTime")
      return moment(`${data._id}-01`, "YYYY-M-DD").format("MMM YYYY");
    if (dateRange === "year")
      return moment(`${data._id}-01`, "YYYY-M-DD").format("MMM");
    if (dateRange === "month")
      return moment(`${data._id}-01`, "YYYY-M-DD").format("D");

    return moment(data._id).format("ddd");
  });

  return { series, categories, adjustments };
};

export const statPieGraphProps = (categories = [], soldItems = []) => {
  if (!soldItems || soldItems.length === 0) {
    return { pieLabels: [], pieSeries: [] };
  }

  const categoriesWithZeroVals = categories.reduce((vals, cur) => {
    return { ...vals, [cur]: 0 };
  }, {});

  const itemsWithVal = soldItems.reduce((items, cur) => {
    return { ...items, [cur._id]: cur.totalQty };
  }, {});

  const soldItemsProps = { ...categoriesWithZeroVals, ...itemsWithVal };

  const labels = Object.keys(soldItemsProps);

  if (labels.includes("Archive")) {
    categories = [...categories, "Archive"];
  }

  return {
    pieLabels: labels,
    pieSeries: categories.map((category) => soldItemsProps[category]),
  };
};

export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

//feed item_sold_by_category
export const soldItemsCount = (arr = []) => {
  return arr.reduce((acc, cur) => (acc += cur.totalQty || 0), 0);
};

// export const getStats = (rowNo, statCardRange= 'day', date=moment().format(DB_DATE_FORMAT)) => {
//     return new Promise((resolve, reject) => {
//         this.api.get(`/brand/u/${this.props.brand}/stats/${rowNo}?stat_cards=${statCardRange}&date=${date}`)
//         .then(data=> resolve(data.data))
//         .catch(err => reject(err))
//     });
// };

//Cart functions
export function findItemInMenu(item_id = "", menu = []) {
  let item = {};
  menu.forEach((i) => {
    if (i.item_id._id === item_id) item = i;
  });
  return item;
}
export function findItemCategoryInCategories(
  category_name = "",
  categories = [],
) {
  let category = {};
  categories.forEach((c) => {
    if (c.category_name === category_name) category = c;
  });
  return category;
}

export function getCartItemQty(cart, item_id) {
  const { items = [] } = cart;
  let qty = 0;

  for (var i = 0; i < items.length; i++) {
    const { item = {} } = items[i];
    if (item._id === item_id) {
      qty = items[i].qty;
      break;
    }
  }

  return qty;
}

export function getItemCategory(item_id, menu) {
  let category = "";

  for (const item of menu) {
    if (item.item_id._id === item_id) {
      category = item.category;
    }
  }

  return category;
}

export function checkIfStoreOpenForOrders({
  is_store_open_for_orders = false,
}) {
  return is_store_open_for_orders;
}

export function isStoreOpen(store) {
  const dayToday = moment().format("d"),
    currentTime = moment(),
    { opening, closing, isOpen } = store.store_hours[dayToday];
  // const isStoreOpen = isOpen && currentTime.isAfter(moment(opening, DB_HOURS_FORMAT)) && currentTime.isBefore(moment(closing, DB_HOURS_FORMAT))? true: false;
  return isOpen &&
    currentTime.isAfter(moment(opening, DB_HOURS_FORMAT)) &&
    currentTime.isBefore(moment(closing, DB_HOURS_FORMAT))
    ? true
    : false;
}

export function computeTotalCartItems(arr = []) {
  let total = 0;
  for (var i = 0; i < arr.length; i++) total += arr[i].qty;

  return total;
}

export function checkIfCanCheckout({ order_time = "", order_date = "" }) {
  return order_time === ASAP_ORDER_TIME_FORMAT
    ? true
    : moment(new Date()).isBefore(
        moment(
          `${order_date} ${order_time}`,
          `${DB_DATE_FORMAT} ${DB_HOURS_FORMAT}`,
        ).subtract(ASAP_PREP_TIME_IN_MIN, "minutes"),
      );
}

export function getStoreSchedule(store_hours = [], day = "") {
  const selected = store_hours.filter((day_schedule = {}) => {
    return day_schedule.day === day.toString();
  });
  return selected.length > 0 ? selected[0] : {};
}

export function generateDateOptions(store = {}) {
  const {
    store_hours = [],
    off_dates = [],
    is_accepting_in_advanced_orders = false,
    days_accepting_in_advanced_orders = 0,
  } = store;

  let date_options = [];

  let current_date = moment(new Date());
  const moment_today = moment(new Date());

  const day_limit = is_accepting_in_advanced_orders
    ? days_accepting_in_advanced_orders
    : 1;

  do {
    let current_day = current_date.day();
    const current_day_schedule = getStoreSchedule(store_hours, current_day);

    const { isOpen = false, is_open_24_hours = false } = current_day_schedule;

    if (off_dates.indexOf(current_date.format(DB_DATE_FORMAT)) === -1) {
      // check if the current day is an off_date
      if (isOpen) {
        // check if current day is open
        const time_options = generateTimeOptions(
          store,
          current_date.format(DB_DATE_FORMAT),
        ); // get the time options for this date to check if it's still valid
        if (
          current_date.format(DB_DATE_FORMAT) ===
          moment_today.format(DB_DATE_FORMAT)
        ) {
          // check if current day is today
          if (
            is_open_24_hours ||
            moment_today.isBefore(
              moment(current_day_schedule.closing, CLOSING_HRS_FORMAT),
            )
          ) {
            // check if current time is BEFORE closing hours so they could order even before opening hours
            if (time_options.length > 0)
              date_options.push({
                value: current_date.format(DB_DATE_FORMAT),
                text: current_date.format("dddd, MMM Do"),
              });
          }
        } else {
          if (time_options.length > 0)
            date_options.push({
              value: current_date.format(DB_DATE_FORMAT),
              text: current_date.format("dddd, MMM Do"),
            });
        }
      }
    }

    current_date.add("1", "day");
  } while (date_options.length < day_limit);

  return date_options;
}

function getOpening(opening = "", is_open_24_hours = false) {
  return is_open_24_hours
    ? moment(new Date()).startOf("day")
    : moment(opening, OPENING_HRS_FORMAT);
}

export function generateTimeOptions(
  {
    store_hours = [],
    time_options_interval = TIME_OPTIONS_INTERVAL_IN_MIN,
    pre_order_to_order_queue_timer = PRE_ORDER_TO_ORDER_QUEUE_TIMER_DEFAULT,
    minutes_allow_order_before_opening = MINUTES_ALLOW_ORDER_BEFORE_OPENING_DEFAULT,
    is_open_24_hours = false,
    is_accepting_in_advanced_orders = false,
  },
  order_date = "",
) {
  const selected_day = moment(order_date, DB_DATE_FORMAT).day();
  const selected_day_schedule = getStoreSchedule(store_hours, selected_day);

  const is_selected_day_today =
    order_date ===
    moment(new Date()).utcOffset(TIMEZONE_OFFSET).format(DB_DATE_FORMAT);

  const { opening = "", closing = "", isOpen = false } = selected_day_schedule;
  const op = getOpening(opening, is_open_24_hours),
    cl = is_open_24_hours
      ? moment(new Date()).endOf("day")
      : moment(closing, CLOSING_HRS_FORMAT).subtract(
          time_options_interval,
          "m",
        );

  let current_hr = op,
    time_options = [],
    addTimeOptionFromHere = !is_selected_day_today;

  let next_available_time = moment(new Date())
    .add(pre_order_to_order_queue_timer, "minutes")
    .add("5", "minutes"); // adding 5 minutes for extra allowance

  const is_current_time_before_opening = moment(new Date()).isBefore(
    getOpening(opening, is_open_24_hours),
  );

  if (isOpen) {
    while (current_hr.isSameOrBefore(cl)) {
      const text = current_hr.format("hh:mm A");

      if (addTimeOptionFromHere) {
        time_options.push({
          text: `${text} - ${moment(text, TIME_OPTION_DISPLAY_FORMAT)
            .add(time_options_interval, "minutes")
            .format(TIME_OPTION_DISPLAY_FORMAT)}`,
          value: current_hr.format(OPENING_HRS_FORMAT),
        });
      }

      const is_next_available_time_in_between_hours =
        next_available_time.isBetween(
          moment(text, TIME_OPTION_DISPLAY_FORMAT).subtract(
            minutes_allow_order_before_opening,
            "minutes",
          ),
          moment(text, TIME_OPTION_DISPLAY_FORMAT).add(
            time_options_interval,
            "minutes",
          ),
          "[]",
        );

      if (
        !addTimeOptionFromHere &&
        (is_current_time_before_opening && is_accepting_in_advanced_orders
          ? true
          : is_next_available_time_in_between_hours)
      ) {
        addTimeOptionFromHere = true;

        if (is_current_time_before_opening) {
          // they are accepting orders before opening hours, the first option is not asap
          time_options.push({
            text: `${text} - ${moment(text, TIME_OPTION_DISPLAY_FORMAT)
              .add(time_options_interval, "minutes")
              .format(TIME_OPTION_DISPLAY_FORMAT)}`,
            value: current_hr.format(OPENING_HRS_FORMAT),
          });
        } else {
          time_options.push({
            text: "ASAP",
            value: ASAP_ORDER_TIME_FORMAT,
          });
        }
      }

      current_hr.add(time_options_interval, "m");
    }
  }

  return time_options;
}

export function concatPlaces() {
  let options = [];

  Object.keys(places).forEach((region, i) => {
    //get the regions

    Object.keys(places[region].province_list).forEach((province, i) => {
      //get the provinces

      Object.keys(
        places[region].province_list[province].municipality_list,
      ).forEach((city, i) => {
        //get the cities/ municipalities
        // options.push(`${city}, ${province}, ${places[region].region_name}`)

        places[region].province_list[province].municipality_list[
          city
        ].barangay_list.forEach((barangay) => {
          options.push(
            `${barangay}, ${city}, ${province}, ${places[region].region_name}`,
          );
        });
      });
    });
  });

  return options;
}

export const getPHStates = () => {
  let options = [];

  Object.keys(philippines).forEach((region, i) => {
    //get the regions

    Object.keys(philippines[region].province_list).forEach((province, i) => {
      //get the provinces

      // Object.keys(philippines[region].province_list[province].municipality_list).forEach((city, i)=> { //get the cities/ municipalities
      //     // options.push(`${city}, ${province}, ${philippines[region].region_name}`)

      //     philippines[region].province_list[province].municipality_list[city].barangay_list.forEach(barangay=> {
      //         options.push(`${barangay}, ${city}, ${province}, ${philippines[region].region_name}`)
      //     })
      // })

      options.push(province);
    });
  });

  // console.log('>>> OPTIONS', options)
  return options;
};

export function getBarangays(place) {
  const splitPlace = place.split(", ");
  const [city, province, region] = splitPlace;
  const selectedRegion = getRegion(region);
  return places[selectedRegion].province_list[province].municipality_list[city]
    .barangay_list;

  function getRegion(string = "") {
    const regions = Object.keys(places);
    const selectedRegion = regions.filter(
      (region) => places[region].region_name === string,
    );

    return selectedRegion;
  }
}

export function getDefaultTimeSlots(
  { store_hours = [], time_options_interval = TIME_OPTIONS_INTERVAL_IN_MIN },
  setLimit = 0,
) {
  let earliest_slot = "",
    latest_slot = "",
    slots = [];

  // determine the earliest and latest slot
  for (var i = 0; i < store_hours.length; i++) {
    const { opening = "", closing = "" } = store_hours[i];
    if (
      earliest_slot === "" ||
      moment(opening, DB_HOURS_FORMAT).isBefore(
        moment(earliest_slot, DB_HOURS_FORMAT),
      )
    ) {
      earliest_slot = opening;
    }

    if (
      latest_slot === "" ||
      moment(closing, DB_HOURS_FORMAT).isAfter(
        moment(latest_slot, DB_HOURS_FORMAT),
      )
    ) {
      latest_slot = closing;
    }
  }

  // generate time slots
  let current_time_slot = moment(earliest_slot, DB_HOURS_FORMAT);

  while (current_time_slot.isBefore(moment(latest_slot, DB_HOURS_FORMAT))) {
    const time_slot_text = current_time_slot.format(TIME_OPTION_DISPLAY_FORMAT);
    slots.push({
      time_slot_text,
      text: `${time_slot_text} - ${moment(
        time_slot_text,
        TIME_OPTION_DISPLAY_FORMAT,
      )
        .add(time_options_interval, "minutes")
        .format(TIME_OPTION_DISPLAY_FORMAT)}`,
      time_slot: current_time_slot.format(DB_HOURS_FORMAT),
      limit: setLimit,
    });

    current_time_slot.add(time_options_interval, "minutes");
  }

  return slots;
}

export function getBatchValueOfTimeSlotLimits(time_slot_limits = []) {
  if (time_slot_limits.length > 0) {
    if (time_slot_limits[0].limit == null) {
      return 0;
    } else {
      return time_slot_limits[0].limit;
    }
  } else {
    return 0;
  }
}

export function SortByKey(data, key, pat) {
  if (pat) {
    return data.sort((a, b) =>
      a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1,
    );
  } else {
    return data.sort((a, b) =>
      a[key].toLowerCase() < b[key].toLowerCase() ? 1 : -1,
    );
  }
}

export function getResizedImage(image_url, size, timestamp = "") {
  if (image_url) {
    const image_filename =
      image_url.substr(0, image_url.lastIndexOf(".")) || image_url;
    const image_extension = image_url.split(".").pop();
    return (
      image_filename +
      `_${size}.` +
      image_extension +
      (timestamp ? "?v=" + timestamp : "")
    );
  }
  return "";
}

export function cleanHostname(hostname) {
  // console.log(process.env.REACT_APP_DOMAIN);
  return hostname.replace(process.env.REACT_APP_DOMAIN, "").trim();
}

/**
 * disableCheckbox - checks if the privilege checkbox will be disabled to avoid unticking it
 * @param {string} iam_role
 * @param {string} page
 * @returns boolean
 */
export function disableCheckbox(iam_role = "", page = "") {
  return (
    (iam_role === "console_user" &&
      ["home", "menu", "orders", "help"].includes(page)) ||
    (iam_role === "group_admin" && ["settings"].includes(page)) ||
    ["help"].includes(page)
  );
}

export function isJson(item) {
  item = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === "object" && item !== null) {
    return true;
  }

  return false;
}

export function getFirstItem(items = []) {
  return items.length > 0 ? items[0] : "";
}

export function checkIfCanUpdateField(user, currentUser) {
  let allow = false;
  if (user.iam_role === "group_admin") {
    allow = true;
  } else if (user._id === currentUser._id) {
    allow = true;
  } else if (user.iam_role !== currentUser.iam_role) {
    allow = true;
  }

  return allow;
}

export const limitStrChar = (string = "", limit = 25, hasEllipsis = true) => {
  let newString = "";

  if (string.length <= limit) return string;

  newString = string.substring(0, limit);

  if (hasEllipsis) {
    newString = newString + "...";
  }

  return newString;
}; //

export const orderDetailsItemExtras = (order_details = []) => {
  return order_details.map((item, i) => {
    let text = "";
    text += `${i !== 0 ? " \n" : ""}${item.qty} x ${normalizeText(
      item.item.name.replace(/['"]+/g, ""),
    )}`;

    if (item.extras[0].extra_id[0]) {
      text += " (";

      item.extras.forEach((extra, index) => {
        if (extra.extra_id[0]) {
          text += normalizeText(extra.extra_id[0].name);

          if (item.extras.length - 1 !== index) {
            text += " ";
          }
        }
      });

      text += ")";
    }

    return text;
  });
};

/**
 * Computes the promo total.
 * @param {*} data - Data object that contains the fields: promo and subTotal.
 * @returns {number} - The computed promo total
 */
export function computePromoTotal(data) {
  // console.log("computePromoTotal data: ", data);
  const {
    promo = {},
    subTotal = 0,
    includedDeliveryFee = 0,
    mealPlanDiscount,
  } = data;

  const subTotalAfteMealPlan = computeDiscount(subTotal, mealPlanDiscount);

  // let amount = 0;
  const promoObject = { amount: 0, base: "cart" };

  if (Object.keys(promo).length) {
    if (promo.promo_type === "amount_off") {
      if (Number(promo.amount_off) > subTotalAfteMealPlan) {
        promoObject.amount = subTotalAfteMealPlan;
      } else {
        promoObject.amount = Number(promo.amount_off);
      }
      promoObject.base = "cart";
    } else if (promo.promo_type === "percentage_off") {
      if (
        Number(subTotalAfteMealPlan) * (Number(promo.percentage_off) / 100) >
        subTotalAfteMealPlan
      ) {
        promoObject.amount = Math.round(Number(subTotalAfteMealPlan));
      } else {
        promoObject.amount = Math.round(
          Number(subTotalAfteMealPlan) * (Number(promo.percentage_off) / 100),
        );
      }
      promoObject.base = "cart";
    } else if (promo.promo_type === "free_delivery") {
      if (Number(promo.subsidized_delivery_amount) > includedDeliveryFee) {
        promoObject.amount = Number(includedDeliveryFee);
      } else {
        promoObject.amount = Number(promo.subsidized_delivery_amount);
      }
      promoObject.base = "delivery";
    }
  }

  promoObject.amount = Math.round(promoObject.amount);

  // return Math.ceil(amount);
  // console.log("computePromoTotal promoObject: ", promoObject);
  return promoObject;
}

/**
 * Computes the grand total.
 * @param {*} data - Data object that contains the fields: subTotal, deliveryFee and promoTotal.
 * @returns {number} - The computed grand total.
 */
export function computeGrandTotal(data) {
  const {
    subTotal = 0,
    includedDeliveryFee = 0,
    promo = {},
    isCOD,
    priority_fee = 0,
    additional_charge_fee = 0,
  } = data;

  let amount = 0;

  let cartDiscount = 0;
  let deliveryDiscount = 0;
  let cartAmount = 0;
  let deliveryAmount = 0;
  const { amount: promoTotal, base: promoBase } = computePromoTotal({
    promo,
    subTotal,
    includedDeliveryFee,
  });

  if (promoBase === "cart") {
    cartDiscount = promoTotal > subTotal ? subTotal : promoTotal;
  } else if (promoBase === "delivery") {
    deliveryDiscount =
      promoTotal > includedDeliveryFee ? includedDeliveryFee : promoTotal;
  }
  cartAmount = Number(subTotal) - Number(cartDiscount);

  deliveryAmount =
    Number(includedDeliveryFee) -
    Number(deliveryDiscount) +
    Number(priority_fee);

  amount = cartAmount + (isCOD ? 0 : deliveryAmount) + additional_charge_fee;
  return amount;
}

export function roleDisplay(iam_role) {
  if (iam_role === "client_admin") {
    iam_role = "group_admin";
  }

  return Humanize(iam_role);
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getToolTitleFromToolName = (toolName) => {
  switch (toolName) {
    case "change_brand_name":
      return "Change Brand Name";
    case "change_brand_url":
      return "Change Brand URL";
    case "activate_multi_store":
      return "Activate Multi Store";
    case "delete_brand":
      return "Delete Brand";
    case "restore_brand":
      return "Restore Brand";
    case "change_store_name":
      return "Change Store Name";
    case "change_store_url":
      return "Change Store URL";
    case "delete_store":
      return "Delete Store";
    case "restore_store":
      return "Restore Store";
    case "change_user_role":
      return "Change User Role";
    case "delete_user":
      return "Delete User";
    case "restore_user":
      return "Restore User";
    case "change_order_status":
      return "Change Order Status";
    case "delete_order":
      return "Delete Order";
    case "restore_order":
      return "Restore Order";
    default:
      return toolName;
  }
};

export function normalizeText(str) {
  const normalize = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return normalize.normalize("NFD").replace(/[^\x20-\x7E]+/g, "");
}

/**
 * This is a function used to display a confirmation swal which shows error messages from the response api and the success message from the success message parameters
 */
export function showConfirmationSwal(apiResponse, successMessage) {
  // console.log("apiResponse: ", apiResponse);
  const { result, message } = apiResponse.data;
  if (result === "error") {
    MySwal.fire({
      icon: "error",
      title: "Oooops!",
      text: `${message}`,
    });
  } else {
    MySwal.fire({
      icon: "success",
      title: "Success!",
      text: `${successMessage}`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Ok",
      timer: 1000,
    });
  }
}

export const tryParse = (target) => {
  try {
    return JSON.parse(target);
  } catch (error) {
    return target;
  }
};

export const isDineInWithServiceCharge = (order) => {
  let valid = false;
  if (order.order_type === "dine_in") {
    if (Object.prototype.hasOwnProperty.call(order, "service_charge_fee")) {
      if (order.service_charge_fee > 0) {
        valid = true;
      }
    }
  }
  return valid;
};

export const computeOrderServiceCharge = (order) => {
  if (!isNil(order.additional_charges) && order.additional_charges.length) {
    const { additional_charges } = order;
    return additional_charges.reduce((sum, charge) => sum + charge.amount, 0);
  } else {
    return 0;
  }
};

export const formatAdditionalChargeName = (charge_name) => {
  if (
    charge_name.includes("service_charge_fee") ||
    charge_name.includes("concierge_service_charge")
  ) {
    return "Service Charge";
  }
};

export const formatAdditionalChargeType = (type, factor) => {
  if (type === "percent_charge") {
    return "(" + factor + "%)";
  }
};
export const computeAdditonalCharge = (
  order_type,
  additional_charges,
  total_amount,
  isMealPlan,
) => {
  if (isMealPlan) {
    return 0;
  }
  const charge_name = order_type + "_service_charge_fee";
  const charge = additional_charges.find(({ name }) => name === charge_name);
  if (charge) {
    if (!charge.active) {
      return 0;
    }
    const { type, factor } = charge;
    if (type === "amount_charge") {
      return factor;
    } else if (type === "percent_charge") {
      return Math.round(total_amount * (factor / 100));
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const getServiceCharge = (order_type, additional_charges) => {
  const charge_name = order_type + "_service_charge_fee";
  return additional_charges.find(({ name }) => name === charge_name);
};
