import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { SVGIcon } from "../../Base";
import "./styles.css";
import core from "../../../vendors/core-api";
import { useSelector } from "react-redux";
import { ROLES } from "../../../constants";
// import { sortedLastIndexOf } from "lodash-es";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ONBOARDING_PROGRESS } from "../../../constants";
import { useLocation } from "react-router-dom";

export default function OnboardingWidget({ initShow = true }) {
  const onLoadLocation = useLocation();
  const { user = {} } = useSelector((state) => state.auth);
  const api = core("pu").get();
  const [show, setShow] = useState(false);
  const close = () => {
    setShow(false);
  };
  const open = () => {
    setShow(true);
  };
  const [arrow, setArrow] = useState({
    brand: false,
    store: false,
    menu: false,
    paymongo: false,
    delivery: false,
    publish: false,
  });
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  const steps = [
    {
      id: "brand",
      name: "Create your first brand",
      link: user.group_type === "multi" ? "/stores" : "#!",
      video: "https://www.youtube.com/embed/Z88-hM6PbUg?start=20",
    },
    {
      id: "store",
      name: "Create your first store",
      link: user.group_type === "multi" ? "/stores" : "#!",
      video: "https://www.youtube.com/embed/Z88-hM6PbUg?start=30",
    },
    {
      id: "menu",
      name: "Add a menu item",
      link: "/menus",
      video: "https://www.youtube.com/embed/Z88-hM6PbUg?start=40",
    },
    {
      id: "paymongo",
      name: "Register to payment gateway",
      link: "/paymongo",
      video: "https://www.youtube.com/embed/Z88-hM6PbUg?start=70",
    },
    {
      id: "delivery",
      name: "Set up delivery",
      link: user.stores ? `/stores/${user.stores[0]}/settings/delivery` : "",
      video: "https://www.youtube.com/embed/Z88-hM6PbUg?start=75",
    },
    {
      id: "publish",
      name: "Publish your store",
      link: user.stores ? `/stores/${user.stores[0]}/settings/status` : "",
      video: "https://www.youtube.com/embed/Z88-hM6PbUg?start=80",
    },
  ];

  const onSetProgress = async (checkedItems) => {
    const result = await api.put({
      url: `/users/onboarding`,
      data: { checkedItems },
    });
    setStatus(result.data.completed);
    setProgress((checkedItems.length / steps.length) * 100);
  };

  const getCompletedStepNames = (progress = []) => {
    const completedSteps = ONBOARDING_PROGRESS.filter((step) => {
      const item = progress.find(({ name }) => name === step);

      if (item) {
        return step;
      }
    });

    return completedSteps;
  };

  const groupSameSteps = (progress = []) => {
    const steps = getCompletedStepNames(progress);

    let tempProgress = [];
    user.onboarding_progress.forEach((step) => {
      const idx = steps.indexOf(step.name);
      if (idx !== -1) {
        tempProgress[idx] = tempProgress[idx] ? tempProgress[idx] : []; // create empty array when it is not yet created

        tempProgress[idx].push(step);
        tempProgress.filter((step) => step && step.length > 1);
      }
    });

    return tempProgress;
  };

  useEffect(() => {
    const initProgress = async () => {
      const completedSteps = groupSameSteps(user.onboarding_progress);

      setCheckedItems(user.onboarding_progress);
      setStatus(user.onboarding_status);
      // setProgress((user.onboarding_progress.length / steps.length) * 100);
      setProgress((completedSteps.length / steps.length) * 100);
    };
    if (user.onboarding_progress) {
      initProgress();
    }
    if (user.onboarding_status) {
      setShow(false);
    }
  }, [user]);

  useEffect(() => {
    if (onLoadLocation.pathname == "/dashboard") setShow(true);
  }, []);

  return Object.keys(user).length > 0 &&
    ROLES[user.iam_role] === ROLES.group_admin ? (
    show ? (
      <div className="opened-container">
        <div
          className="d-flex justify-content-between mb-2 clickable"
          onClick={close}
        >
          <strong>Set up Pickup</strong>
          <SVGIcon icon={"Navigation/Angle-down"} variant="custom" />
        </div>
        <ProgressBar now={progress} />
        <br />
        <PerfectScrollbar>
          <div id="steps">
            {steps.map((v, idx) => {
              const finished = checkedItems.find((e) => e.name === v.id);
              return (
                <>
                  <div className="d-flex mb-5" key={idx}>
                    <label className="checkbox checkbox-primary checkbox-single mr-3">
                      <input type="checkbox" value={v.id} checked={finished} />
                      <span></span>
                    </label>
                    <a
                      className={`${finished ? "finished" : ""} text-normal`}
                      href={v.link}
                    >
                      {v.name}
                    </a>
                    <div
                      className="d-flex justify-content-between mb-2 clickable"
                      onClick={() => {
                        let newArrow = !arrow[v.id];
                        return setArrow({ [v.id]: newArrow });
                      }}
                    >
                      {arrow[v.id] ? (
                        <SVGIcon
                          icon={"Navigation/Angle-down"}
                          variant="custom"
                        />
                      ) : (
                        <SVGIcon
                          icon={"Navigation/Angle-right"}
                          variant="custom"
                        />
                      )}
                    </div>
                  </div>
                  {arrow[v.id] ? (
                    <div className="d-flex">
                      <iframe
                        className="responsive-iframe"
                        width="100%"
                        height="80%"
                        src={`${v.video}`}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </div>
        </PerfectScrollbar>
      </div>
    ) : (
      <div className="closed-container" onClick={open}>
        <div className="d-flex justify-content-between">
          <label>Set up Pickup</label>
          <SVGIcon icon={"Navigation/Angle-up"} variant="custom" />
        </div>
        <ProgressBar now={progress} />
      </div>
    )
  ) : null;

  // const updateProgress = (event) => {
  //     event.persist();
  //     setCheckedItems((prev) => {
  //         let newItems;
  //         if (event.target.checked) {
  //             newItems = [...prev, event.target.value];
  //         } else {
  //             newItems = prev.filter((val) => val !== event.target.value);
  //         }
  //         onSetProgress(newItems);
  //         return newItems;
  //     });
  // };
}
