import React from "react";
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

const GroupDropdown = (props) => {
  const {
    items = [],
    attr = "brand",
    className = "",
    onSelectView,
    selectedView = {},
    hasRollup = false,
    isSuperAdmin = false,
  } = props;

  const flattenedItems = [];

  items.forEach((item) => {
    flattenedItems.push({ ...item, attr });

    if (item.stores && !isSuperAdmin) {
      item.stores.forEach((store) => {
        flattenedItems.push({ ...store, attr: "store" });
      });
    }
  });

  const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
  };

  return (
    <Dropdown className={`${className}`}>
      <span className="px-2 mr-4 text-center font-weight-bold">Filter By:</span>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        {selectedView.name || ""}
      </Dropdown.Toggle>

      <Dropdown.Menu className="overflow-hidden">
        <PerfectScrollbar
          options={perfectScrollbarOptions}
          className="pr-7"
          style={{ maxHeight: "20rem", position: "relative" }}
        >
          {hasRollup && (
            <Dropdown.Item
              onClick={() =>
                onSelectView({ id: null, name: "All", attr: "brand" })
              }
            >
              <span className="font-size-lg">All</span>
            </Dropdown.Item>
          )}

          {flattenedItems.map((item) => (
            <Dropdown.Item
              key={`brand-${item.id}}`}
              onClick={() => onSelectView(item)}
            >
              <span
                className={`text-dark ${
                  item.attr === "store" ? "ml-4 text-dark-75" : "text-dark"
                }`}
              >
                {item.name}
              </span>
            </Dropdown.Item>
          ))}
        </PerfectScrollbar>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default GroupDropdown;
