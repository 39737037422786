import { BasicComponent } from "../Base";

class BWSection extends BasicComponent {
  constructor(props) {
    super(props);
    this.state = { defaultClass: "bw-section" };
  }
}

class Header extends BasicComponent {
  constructor(props) {
    super(props);
    this.state = { defaultClass: "bw-section-header" };
  }
}

class Content extends BasicComponent {
  constructor(props) {
    super(props);
    this.state = { defaultClass: "bw-section-content" };
  }
}

BWSection.Header = Header;
BWSection.Content = Content;

export default BWSection;
