import React from "react";
import moment from "moment";
import { Row, Col, Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import AreaWidget from "../../components/widgets/AreaWidget";
import { Bargraph } from "../../components/widgets/Bargraph";
import StatDonutGraph from "../../components/widgets/StatDonutGraph";
import ItemRanking from "../../components/widgets/ItemRanking";
import EmptyData from "../../components/EmptyData";
import StatCardApi from "../../components/widgets/StatCardApi";
import { stackedBarGraphProps, toPesoAmount } from "../../helpers";
import { DB_DATE_FORMAT, DATERANGE_LIST } from "../../constants";
import { InfoAlertHelper } from "../../components/Base";

import GoogleAnalyticsGrid from "../../components/dashboard/GoogleAnalyticsGrid";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export default function Page() {
  const { brand_url = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  return <MainComponent id={brand_url} user={user} />;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Org Analytics",
      statCardQuery: "day",
      selectedBrand: { id: "", name: "All" },
      selectedDateRange: "Today",
      date: moment().format(DB_DATE_FORMAT),
    };

    this.api = core("pu").get();
    this.data_url = `/analytics`;

    this.handleDateRangeClick.bind(this);
  }

  handleDateRangeClick = async (e, date = moment().format(DB_DATE_FORMAT)) => {
    let statCardDataRange = "";
    const dropdownText = e.currentTarget.textContent || "day";

    if (
      dropdownText === "This Week" ||
      dropdownText === "Last Week" ||
      dropdownText === "Next Week"
    ) {
      statCardDataRange = "week";
    } else if (dropdownText === "This Month" || dropdownText === "Last Month") {
      statCardDataRange = "month";
    } else if (dropdownText === "This Year" || dropdownText === "Last Year") {
      statCardDataRange = "year";
    } else if (dropdownText === "All-time") {
      statCardDataRange = "allTime";
    } else {
      statCardDataRange = "day";
    }

    this.setState({
      statCardQuery: statCardDataRange,
      selectedDateRange: dropdownText,
      date,
      data_status: "fetching",
    });
    setTimeout(() => {
      this.getData();
    }, 500);
  };

  handleChangeSelectedBrand = async (item) => {
    this.setState({
      selectedBrand: item,
      data_status: "fetching",
    });
    setTimeout(() => {
      this.getData();
    }, 500);
  };

  async getData() {
    this._isMounted && this.setState({ data_status: "fetching" });
    let date = this.state.date || moment().format(DB_DATE_FORMAT);
    let statCardRange = this.state.statCardQuery || "day";
    let view = this.state.selectedBrand || { id: "", name: "All" };
    // const brandId = this.props.user.brand._id;
    // const brandId = this.props.id;

    const getContent = () => {
      return new Promise((resolve, reject) => {
        // this.api.get(`/brand/u/${brandId}/analytics?dateRange=${statCardRange}`)
        this.api
          .get(
            `/analytics?dateRange=${statCardRange}&date=${date}&view=${view.id}`,
          )
          .then((data) => resolve(data.data))
          .catch((err) => reject(err));
      });
    };

    try {
      const content = await getContent();
      // console.log("orggoogleanalytics content: ", content)
      this._isMounted &&
        this.setState({
          data: {
            ...content,
          },
          data_status: "fetched",
          order_fetch_status: "fetched",
        });

      this.handleOnDataFetched();
      if (this.use_display_data) {
        this.setDisplayData();
      }
    } catch (error) {
      this._isMounted && this.setState({ error: error, showSwalError: true });
      this.handleOnDataFetchError();
    }
  }

  render() {
    // console.log("organalytics state: ", this.state)
    // console.log("organalytics props: ", this.props)

    // const brandId = this.props.user.brand._id;
    const brandId = this.props.id;
    const {
        data = {},
        data_status = "",
        selectedBrand = {},
        statCardQuery = "day",
        date = moment().format(DB_DATE_FORMAT),
        selectedFilter = {},
        selectedDateRange = {},
        brands = [],
      } = this.state,
      isLoading = data_status !== "fetched";
    const {
      allBrands = [],
      salesByOrderType = [],
      totalSales = 0,
      itemRanking = [],
      customerMix = {},
      salesByPaymentMethod = [],
      avgCustomerSpend = 0,
      avgReturningCustomerTransactions = 0,
      lifetimeSpentofReturninCustomer = 0,
      allBrandStores = [],
    } = data;

    function dropdownText(dateRange, date) {
      const isCurrent = moment(moment().format(DB_DATE_FORMAT)).isSame(
        date,
        dateRange === "month" ? "month" : dateRange === "year" ? "year" : "day",
      );
      const prefix = isCurrent ? "This" : "Last";

      // if (dateRange === "week") return `${prefix} Week`;
      if (dateRange === "week") {
        if (
          moment(moment().add(1, "weeks").format(DB_DATE_FORMAT)).isSame(date)
        ) {
          return "Next Week";
        } else {
          return `${prefix} Week`;
        }
      }
      if (dateRange === "month") return `${prefix} Month`;
      if (dateRange === "year") return `${prefix} Year`;
      if (dateRange === "allTime") return "All-time";
      return `${isCurrent ? "Today" : "Yesterday"}`;
    }

    const { series, categories } =
      !isLoading && stackedBarGraphProps(salesByOrderType, statCardQuery);
    const pieLabels = Object.keys(customerMix);
    const pieSeries = Object.values(customerMix);

    const columnWidth = ["month", "year"].includes(statCardQuery)
      ? "65%"
      : "80%";

    const cardListItemImgStyling = {
      maxHeight: "3rem",
    };

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        {/* <HeaderTextWithActions title={this.state.title} /> */}

        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          // <div className="container-fluid d-flex flex-column justify-content-between">
          <div className="">
            <InfoAlertHelper
              text={
                "The data displayed below is from the Google Analytics 4 and it may take 24 to 48 hours to refresh."
              }
            />
            <div className="d-flex justify-content-end w-100 mb-4">
              <Dropdown drop="down" alignRight>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  {selectedBrand.name || "All"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className="pr-7"
                    style={{ maxHeight: "20rem", position: "relative" }}
                  >
                    {allBrands.map((item) => (
                      <Dropdown.Item
                        onClick={() => this.handleChangeSelectedBrand(item)}
                        key={item.id}
                      >
                        {item.name}
                      </Dropdown.Item>
                    ))}
                    <Dropdown.Item
                      onClick={() =>
                        this.handleChangeSelectedBrand({ id: "", name: "All" })
                      }
                    >
                      {"All"}
                    </Dropdown.Item>
                  </PerfectScrollbar>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="align-self-end ml-4">
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  {dropdownText(statCardQuery, date)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {DATERANGE_LIST.map((item) => (
                    <Dropdown.Item
                      onClick={(e) => this.handleDateRangeClick(e, item.date)}
                      key={item.name}
                    >
                      {item.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <GoogleAnalyticsGrid
              // itemSoldURL = {`/group/${this.props.id}/item-sold?date=${date}&dateRange=${statCardQuery}&id=${selectedFilter.id}&attr=${selectedFilter.attr}`}
              // itemSoldURL = {`/dashboard/analytics/item-sold?date=${date}&dateRange=${statCardQuery}&id=${JSON.stringify(selectedFilter.id||allIds)}&attr=${selectedFilter.attr|| 'brand'}`}
              selectedFilter={selectedBrand}
              selectedDateRange={selectedDateRange}
              data={data}
              statCardQuery={statCardQuery}
              date={date}
              brands={brands}
              allBrandStores={allBrandStores}
            />
          </div>
        )}
      </>
    );
  }
}

const CardList = ({
  items = [],
  header = "",
  mapFunction,
  className = "",
  children,
}) => {
  return (
    <div className={`analytics-card-list h-100 font-size-md ${className}`}>
      {header && <h4 className="pt-8 px-8">{header}</h4>}

      <div className="card-body">
        {items.length <= 0 ? (
          <EmptyData className="text-center mt-12" />
        ) : (
          <>
            <ul className="mt-2 p-0">{items.map(mapFunction)}</ul>

            {children}
          </>
        )}
      </div>
    </div>
  );
};
