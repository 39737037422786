import React from "react";
import moment from "moment";

import APIComponent from "../API";
import { StatsWidget11 } from "./StatsWidget11";
import PacmanWrapper from "../dashboard/PacmanWrapper";
import core from "../../vendors/core-api";
import { DB_DATE_FORMAT } from "../../constants";

class AreaWidget extends APIComponent {
  constructor(props) {
    super(props);

    const {
      params: {
        dateRange = "day",
        date = moment().format(DB_DATE_FORMAT),
        id = "",
        attr = "brand",
      },
    } = props;

    this.data_url = `/dashboard/analytics/item-sold?dateRange=${dateRange}&date=${date}&id=${id}&attr=${attr}`;
    this.api = core("pu").get();
    this.dateRange = dateRange;
  }

  handleDateRangeClick = async (e) => {
    this.setState(
      {
        statCardQuery: e,
        data_status: "fetching",
      },
      () => {
        this.getData();
      },
    );
  };

  async getData() {
    this._isMounted && this.setState({ data_status: "fetching" });

    // const {brand_id='', view='', dateRange= 'day'} = this.props;
    // const statCardRange = this.state.statCardQuery|| dateRange;
    // const date = statCardRange === 'yesterday'? moment().format(DB_DATE_FORMAT): moment().subtract(1, 'days').format(DB_DATE_FORMAT)

    const getContent = () => {
      return new Promise((resolve, reject) => {
        this.api
          .get(this.data_url)
          .then((data) => resolve(data.data))
          .catch((err) => reject(err));
      });
    };

    try {
      const content = await getContent();

      this._isMounted &&
        this.setState({
          data: {
            ...content,
          },
          data_status: "fetched",
          order_fetch_status: "fetched",
        });

      this.handleOnDataFetched();
      if (this.use_display_data) {
        this.setDisplayData();
      }
    } catch (error) {
      this._isMounted && this.setState({ error: error, showSwalError: true });
      this.handleOnDataFetchError();
    }
  }

  render() {
    const { data = [], data_status = "", statCardQuery = "" } = this.state,
      isLoading = data_status !== "fetched";
    const {
      className = "",
      id = "",
      header = "",
      seriesAttr = "", // attribute to use on data.map
      seriesName = "",
      theme = "primary",
      height = 300,
      customOptions = {},
      hasDropDown = false,
      comingSoon = false,
      params: { date = moment().format(DB_DATE_FORMAT), dateRange = "day" },
    } = this.props;

    const { displayData = "", timeLine = [] } = data;
    let { current = [], previous = [] } = timeLine;

    const categories = current.map((item) =>
      moment(`${date} ${item._id}`).format("H"),
    );

    current = current.map((item) => item[seriesAttr]);
    previous = previous.map((item) => item[seriesAttr]);

    const dateStrings = [
      { dateRange: "day", current: "Today", previous: "Yesterday" },
      { dateRange: "yesterday", current: "Yesterday", previous: "Yesterday" },
      { dateRange: "week", current: "This Week", previous: "Last Week" },
      { dateRange: "month", current: "This Month", previous: "Last Month" },
      { dateRange: "year", current: "This Year", previous: "Last Year" },
      { dateRange: "allTime", current: "All-Time", previous: "All-Time" },
    ];

    const getSeries = (date, dateRange) => {
      if (dateRange === "allTime") {
        return [{ name: "All-time", data: current }];
      }

      if (
        dateRange === "week" &&
        moment(moment().add(1, "weeks").format(DB_DATE_FORMAT)).isSame(date)
      ) {
        return [
          {
            name: "This Week",
            data: previous,
          },
          {
            name: "Next Week",
            data: current,
          },
        ];
      }

      return [
        {
          name: `${
            dateStrings.find((el) => el.dateRange === statCardRange).previous
          }`,
          data: previous,
        },
        {
          name: `${
            dateStrings.find((el) => el.dateRange === statCardRange).current
          }`,
          data: current,
        },
      ];
    };

    const statCardRange = this.state.statCardQuery || dateRange;
    const series = getSeries(date, statCardRange);

    let isCurrent =
      moment().format(DB_DATE_FORMAT) === date ? "current" : "previous";

    const getCardHeader = (date, dateRange) => {
      if (dateRange === "week") {
        if (
          moment(moment().add(1, "weeks").format(DB_DATE_FORMAT)).isSame(date)
        ) {
          return `${header} Next Week`;
        }
      } else if (["month", "year"].includes(dateRange)) {
        isCurrent = moment(moment().format(DB_DATE_FORMAT)).isSame(
          date,
          dateRange,
        )
          ? "current"
          : "previous";
      }

      return `${header} ${
        dateStrings.find((el) => el.dateRange === dateRange)[isCurrent]
      }`;
    };

    const cardHeader = getCardHeader(date, dateRange);

    return (
      <div className="h-100 card">
        {isLoading || data === null ? (
          <PacmanWrapper />
        ) : (
          <StatsWidget11
            series={series}
            categories={categories}
            className={className}
            id={id}
            header={cardHeader}
            displayData={this.props.displayData || displayData}
            theme={theme}
            seriesName={seriesName}
            dateRange={statCardQuery || dateRange}
            height={height}
            hasDropDown={hasDropDown}
            dateStrings={dateStrings}
            onDateRangeClick={this.handleDateRangeClick}
            comingSoon={comingSoon}
            customOptions={customOptions}
          />
        )}
      </div>
    );
  }
}

export default AreaWidget;
