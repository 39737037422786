import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Card, OverlayTrigger, Popover } from "react-bootstrap";
import { SVGIcon } from "../../../components/Base";
import { Checkbox, Chip } from "@material-ui/core";
import moment from "moment";
import { DISPLAY_DATE_FORMAT } from "../../../constants";
import hexRgb from "hex-rgb";
import { OrangeTag } from "./ReservationsList";

const ACTION_LIST = [
  { icon: "profile", label: "Profile" },
  { icon: "message", label: "Message" },
  { icon: "edit_reservation", label: "Edit Reservation" },
  { icon: "add_tasks", label: "Add Tasks" },
  { icon: "add_notes", label: "Add Notes" },
];

const CUSTOMER_NOTES_TYPES = {
  MESSAGE: "has_message",
  BRINGING_SOMETHING: "is_bringing_something",
  WINE_SOMMELIER: "wine_sommelier",
  DIETARY_RESTRICTION: "has_dietary_restrictions",
  CUSTOMER_NOTES: "has_notes",
};

const CUSTOMER_NOTES = [
  { type: CUSTOMER_NOTES_TYPES.MESSAGE, icon: "message", title: "Message" },
  {
    type: CUSTOMER_NOTES_TYPES.BRINGING_SOMETHING,
    icon: "bring_something",
    title: "Bringing Something",
  },
  {
    type: CUSTOMER_NOTES_TYPES.WINE_SOMMELIER,
    icon: "wine_sommelier",
    title: "Wine Sommelier",
  },
  {
    type: CUSTOMER_NOTES_TYPES.DIETARY_RESTRICTION,
    icon: "dietary_restrictions",
    title: "Dietary Restrictions",
  },
  {
    type: CUSTOMER_NOTES_TYPES.CUSTOMER_NOTES,
    icon: "reservation_notes",
    title: "Customer Notes",
  },
];

const dummy_task = [
  { type: CUSTOMER_NOTES_TYPES.MESSAGE, task: "Reply to message" },
  {
    type: CUSTOMER_NOTES_TYPES.DIETARY_RESTRICTION,
    task: "Print dietary restrictions / food allergies",
  },
  {
    type: CUSTOMER_NOTES_TYPES.WINE_SOMMELIER,
    task: "Contact Samantha Ong for Wine List",
  },
];

const dummy_dietary = {
  food_allergy: [
    "Peanuts",
    "Dairy",
    "Shellfish",
    "Oyster",
    "Milk",
    "Pumpkin",
    "Boba Pearls",
  ],
  restrictions: [
    {
      icon: "fa-seedling",
      label: "Vegan",
    },
  ],
};

const NOTES_COLOR_ARR = ["#C900C9", "#00B2C9", "#FFD800"];

const DetailsContainer = (props) => {
  const {
    customer_notes,
    guest_tags,
    reservation,
    onAddTags,
    onDetailsCloseClick,
    onActionButtonClick,
    onPrintBtnClick,
    onAddNotesClick,
  } = props;

  return (
    <div className="d-flex flex-column">
      <HeaderDiv
        reservation={reservation}
        onDetailsCloseClick={onDetailsCloseClick}
      />
      <ActionListContainer onActionButtonClick={onActionButtonClick} />
      <TaskContainer />
      <GuestTagContainer tags={guest_tags} onAddTags={onAddTags} />
      <DietaryRestrictionContainer onPrintBtnClick={onPrintBtnClick} />
      <NotesContainer
        notes={customer_notes}
        onAddNotesClick={onAddNotesClick}
      />
    </div>
  );
};

const NotesContainer = (props) => {
  const { notes } = props;
  const groupedData = notes.reduce((acc, cur) => {
    const date = moment(cur.date_created).format(DISPLAY_DATE_FORMAT);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(cur);
    return acc;
  }, {});

  const formattedData = Object.keys(groupedData)
    .sort((a, b) => {
      return new Date(b) - new Date(a);
    })
    .map((date, i) => {
      return {
        date,
        notes: groupedData[date],
        color: NOTES_COLOR_ARR[i % NOTES_COLOR_ARR.length],
      };
    });

  return (
    <Card
      className="m-5 mb-3"
      style={{
        border: "1.5px solid #000000",
        borderRadius: 4,
      }}
    >
      <Card.Body className="d-flex flex-column p-0 mb-3">
        <div className="d-flex flex-row justify-content-space-between px-2 pt-1">
          <div className="d-flex align-items-center">
            <i className="fas fa-sticky-note" style={{ color: "#464E5F" }} />
            <span className="p-1 font-weight-bolder">Notes</span>
          </div>
          <div className="d-flex align-items-center">
            <a
              style={{ color: "#464e5f", textDecoration: "underline" }}
              onClick={(e) => {
                e.preventDefault();
                props.onAddNotesClick();
              }}
              href="#"
            >
              + Add
            </a>
          </div>
        </div>
        {formattedData.length > 0 && (
          <div
            className="d-flex flex-column mx-2 p-3"
            style={{
              maxWidth: 465,
              backgroundColor: "#EEF0F6",
              borderRadius: 4,
            }}
          >
            {formattedData.map(({ date_created, notes, color }, i) => {
              const { red, green, blue } = hexRgb(color);
              return (
                <div key={`notes-row-00${i}`} className="d-flex flex-column">
                  {notes.map((data, j) => {
                    return (
                      <div
                        key={`note-00${j}`}
                        className="d-flex flex-row mb-1 justify-content-space-between align-items-center"
                        style={{
                          backgroundColor: `rgb(${red}, ${green}, ${blue}, 0.3)`,
                          color: "#000",
                          fontSize: "0.8rem",
                        }}
                      >
                        <div className="d-flex flex-row">
                          <div
                            className="d-flex"
                            style={{ width: 5, backgroundColor: color }}
                          />
                          <span className="p-1">{data.notes}</span>
                        </div>
                        <span className="p-1" style={{ color: "#777777" }}>
                          {date_created}
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

const DietaryRestrictionContainer = (props) => {
  const { isModal, onPrintBtnClick } = props;
  const { food_allergy, restrictions } = dummy_dietary;
  return (
    <Card
      className="mx-5 mt-0"
      style={{
        border: "1.5px dashed #000000",
        borderRadius: 4,
      }}
    >
      <Card.Body className="d-flex flex-column p-0 mb-3">
        <div className="d-flex flex-row justify-content-space-between px-2 pt-1">
          <div className="d-flex align-items-center">
            <i
              className="fas fa-exclamation-circle"
              style={{ color: "#464E5F" }}
            />
            <span className="p-1 font-weight-bolder">
              Dietary Restrictions / Food Allergies
            </span>
          </div>

          <div className="d-flex align-items-center">
            {!isModal ? (
              <Button
                variant="custom"
                type="button"
                size="sm"
                onClick={onPrintBtnClick}
              >
                <i
                  className="flaticon2-print p-0"
                  style={{ color: "#464E5F" }}
                />
              </Button>
            ) : (
              <a
                href="#"
                style={{ color: "#464e5f", textDecoration: "underline" }}
              >
                + Add
              </a>
            )}
          </div>
        </div>
        {(restrictions.length > 0 || food_allergy.length > 0) && (
          <div
            className="d-flex flex-column mx-2 p-2"
            style={{
              maxWidth: 465,
              backgroundColor: "#EEF0F6",
              borderRadius: 4,
            }}
          >
            <div className="d-flex flex-wrap">
              {restrictions.map(({ icon, label }) => {
                return (
                  <div className="d-flex m-1" key={`restriction-${label}`}>
                    <Chip
                      style={{ backgroundColor: "#006E54", color: "#fff" }}
                      size="small"
                      label={label}
                      icon={
                        <i
                          className={`fa fa-sm ${icon} restriction-warning-icon`}
                          style={{ color: "#fff", padding: "0.35rem" }}
                        />
                      }
                    />
                  </div>
                );
              })}
            </div>
            <div className="d-flex flex-wrap">
              {food_allergy.map((label) => {
                return (
                  <div className="d-flex m-1" key={`food-allergy-${label}`}>
                    <Chip
                      style={{ backgroundColor: "#FF7F00", color: "#fff" }}
                      size="small"
                      label={label}
                      icon={
                        <i
                          className="fa fa-sm fa-exclamation-triangle restriction-warning-icon"
                          style={{
                            color: "gold",
                            backgroundColor: "#00000",
                            padding: "0.35rem",
                          }}
                        />
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

const AddTagContainer = (props) => {
  const { tags } = props;
  const [newTags, setNewTags] = useState(tags);

  useEffect(() => {}, [newTags]);
  return (
    <div className="d-flex flex-column p-2">
      <input
        type="text"
        className="form-control"
        name="addTag"
        placeholder="+ Add new tag"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setNewTags([...newTags, { label: e.target.value }]);
          }
        }}
        style={{ width: "100%" }}
      />
      <div className="d-flex mt-2 mx-1" style={{ color: "#989898" }}>
        Or select an existing tag:
      </div>
      <Card>
        <Card.Body className="p-3">
          <div className="d-flex flex-wrap">
            {newTags.map(({ icon, label }, i) => {
              return (
                <div className="d-flex p-1" key={`tag-${label}-00${i}`}>
                  <Chip
                    style={{ backgroundColor: "#FF7F00", color: "#fff" }}
                    size="small"
                    label={label}
                    icon={
                      icon ? (
                        <i
                          className={`fas fa-sm ${icon} p-1`}
                          style={{ color: "#fff" }}
                        />
                      ) : null
                    }
                  />
                </div>
              );
            })}
          </div>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-center p-3">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ width: "50%" }}
          onClick={(e) => props.onSaveClick(e, newTags)}
        >
          Save
        </button>
      </div>
    </div>
  );
};

const GuestTagContainer = (props) => {
  const { tags } = props;

  const addTagPopover = (
    <Popover id="popover-positioned-left" title="Guest Tags">
      <AddTagContainer
        tags={tags}
        onAddTags={props.onAddTags}
        onSaveClick={(e, values) => {
          props.onAddTags(e, values);
          document.body.click();
        }}
      />
    </Popover>
  );

  return (
    <Card
      className="m-5"
      style={{
        border: "1.5px solid #000000",
        borderRadius: 4,
      }}
    >
      <Card.Body className="d-flex flex-column p-0 mb-3">
        <div className="d-flex flex-row justify-content-space-between px-2 pt-1">
          <div className="d-flex align-items-center">
            <i className="fas fa-tags" style={{ color: "#464E5F" }} />
            <span className="p-1 font-weight-bolder">Guest Tags</span>
          </div>
          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={addTagPopover}
            rootClose={true}
          >
            <div className="d-flex align-items-center">
              <a
                style={{ color: "#464e5f", textDecoration: "underline" }}
                href="#"
              >
                + Add
              </a>
            </div>
          </OverlayTrigger>
        </div>
        <div className="d-flex flex-wrap mx-1" style={{ maxWidth: 450 }}>
          {tags.length > 0 &&
            tags.map(({ icon, label }, i) => {
              return (
                <div className="d-flex p-1" key={`tag-${label}-00${i}`}>
                  <Chip
                    style={{ backgroundColor: "#FF7F00", color: "#fff" }}
                    size="small"
                    label={label}
                    icon={
                      icon ? (
                        <i
                          className={`fas fa-sm ${icon} p-1`}
                          style={{ color: "#fff" }}
                        />
                      ) : null
                    }
                  />
                </div>
              );
            })}
        </div>
      </Card.Body>
    </Card>
  );
};

const TaskContainer = (props) => {
  return (
    <Card className="b-none px-5">
      <Card.Title className="py-0 mb-2">Tasks</Card.Title>
      {dummy_task.map(({ type, task }, i) => {
        const taskIcon = CUSTOMER_NOTES.filter(
          (notes) => notes.type === type,
        )[0];
        return (
          <div className="p-0" key={`reservation-task-0${i + 1}`}>
            <Card.Body className="d-flex flex-row align-items-center p-0">
              <Checkbox className="pl-0 py-2 pr-2" color="default" />
              <TaskRow className="d-flex flex-row py-1 px-3">
                <SVGIcon
                  icon={`Reservations/${taskIcon.icon}`}
                  variant="dark"
                />
                <span className="ml-2 font-pickup-sm">{task}</span>
              </TaskRow>
            </Card.Body>
          </div>
        );
      })}
    </Card>
  );
};

const ActionListContainer = (props) => {
  return (
    <div className="d-flex flex-row justify-content-space-between p-5 mt-3">
      {ACTION_LIST.map(({ icon, label }) => {
        return (
          <ActionListBox
            key={`action-btn-${icon}`}
            className="py-1 px-0"
            onClick={() => props.onActionButtonClick(icon)}
          >
            <SVGIcon
              icon={`Reservations/action_${icon}`}
              fill="#464E5F"
              variant="dark"
            />
            <span style={{ fontSize: "0.75rem" }}>{label}</span>
          </ActionListBox>
        );
      })}
    </div>
  );
};

const HeaderDiv = (props) => {
  const { onDetailsCloseClick, reservation } = props;
  const { guest_customer, guest_count } = reservation;
  const customer_name = `${guest_customer.last_name}, ${guest_customer.first_name}`;
  return (
    <div className="d-flex flex-column p-3" style={{ background: "#eef0f6" }}>
      <div className="d-flex flex-row justify-content-space-between align-items-center px-1 w-100">
        <div className="d-flex flex-row align-items-center font-card-title font-weight-bold text-capitalize">
          <div
            style={{
              maxWidth: 350,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {customer_name}
          </div>
          <OrangeTag className="ml-2 mb-2">VIP</OrangeTag>
        </div>
        <Button
          bsPrefix="res-close-btn"
          variant="custom"
          type="button"
          size="sm"
          onClick={onDetailsCloseClick}
        >
          <i className="flaticon2-cross res-close-icon" />
        </Button>
      </div>
      <HeaderDivider />
      <div className="d-flex flex-row p-2 font-pickup-sm font-weight-bold">
        <div className="d-flex mr-3">
          <SVGIcon
            className="mr-2"
            icon="Reservations/reservation-icon"
            fill="#464E5F"
            variant="dark"
          />
          <span>Ala Carte</span>
        </div>
        <div className="d-flex mr-3">
          <SVGIcon
            className="mr-2"
            icon="Reservations/guest"
            fill="#464E5F"
            variant="dark"
          />
          <span>{guest_count || 0} guests</span>
        </div>
        <div className="d-flex">
          <SVGIcon
            className="mr-2"
            icon="Reservations/time"
            fill="#464E5F"
            variant="dark"
          />
          <span>5:30 pm (2h 45 min)</span>
        </div>
      </div>
      <ContactInformation guest_customer={guest_customer} />
    </div>
  );
};

const ContactInformation = (props) => {
  const { guest_customer } = props;
  return (
    <div className="d-flex flex-column mt-5">
      <div className="d-flex px-2 font-weight-bolder">Contact Information</div>
      <div className="d-flex align-items-center px-2">
        <i className="flaticon2-email mr-2" style={{ color: "#464E5F" }} />
        <span style={{ textDecoration: "underline" }}>
          {guest_customer.email}
        </span>
      </div>
      <div className="d-flex justify-content-space-between flex-row px-2">
        <div className="d-flex align-items-center">
          <i className="fas fa-phone mr-2" style={{ color: "#464E5F" }} />
          <span style={{ textDecoration: "underline" }}>
            {guest_customer.contact_number}
          </span>
        </div>
        <div className="d-flex">
          <SVGIcon icon="Reservations/bell" title="Server" />
          <span className="d-flex align-items-center ml-2">Josh</span>
        </div>
      </div>
    </div>
  );
};

const TaskRow = styled.div`
  background-color: #d8e2ff;
  width: 100%;
  border-radius: 4px;
`;

const ActionListBox = styled.button`
  display: flex;
  flex-direction: column;
  border: 1px solid #464e5f;
  border-radius: 4px;
  width: 85px;
  height: 40px;
  align-items: center;
  background: #fff;
`;

const HeaderDivider = styled.div`
  background: #eef0f6;
  border: 0.5px solid #484848;
`;

export default { DetailsContainer, DietaryRestrictionContainer };
