import React from "react";
import { Card, Button } from "react-bootstrap";
// import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PRIMARY_COLOR } from "../../constants";
import { SVGIcon } from "../Base";
import { isNil } from "lodash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  fetchData,
  formatOrderType,
  isPromoScopeAllowedForUserLevel,
  toPesoAmount,
} from "../../helpers";
const MySwal = withReactContent(Swal);

export default class PromoCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promoRevenue: this.props.data.totalRevenue,
    };
  }

  promoScopeString = (item) => {
    const { scope } = item;
    let promoScopeString = "";

    promoScopeString = `Scope: ${scope}`;

    return promoScopeString;
  };

  promoEndString = (item) => {
    const { end_date } = item;
    let promoEndString = "";

    if (end_date) promoEndString = ` - End date ${end_date}`;

    return promoEndString;
  };

  promoStartString = (item) => {
    const { start_date } = item;
    let promoStartString = "";

    promoStartString = `Start date ${start_date}`;

    return promoStartString;
  };

  appliesToString = (item) => {
    const { applies_to } = item;
    let appliesToString = "";

    appliesToString = applies_to
      .map((item) => {
        return formatOrderType(item);
      })
      .join(", ");

    return appliesToString;
  };

  promosUsedString = (item) => {
    let promosUsedString = "";
    let usedString = "";
    let maxString = "";
    const { usage_limit, promoUsed } = this.props.data;

    usedString = `${promoUsed} used`;
    if (usage_limit) {
      maxString = ` out of ${usage_limit}`;
    } else {
      maxString = ``;
    }

    promosUsedString = usedString + maxString;

    return promosUsedString;
  };

  minimumPurchaseString = (item) => {
    const { minimum_purchase } = item;
    let minimumPurchaseString = "";

    if (minimum_purchase > 0) {
      minimumPurchaseString = `Minimum purchase of P${minimum_purchase.toFixed(
        2,
      )}`;
    } else {
      minimumPurchaseString = `No minimum purchase required`;
    }

    return minimumPurchaseString;
  };

  promoTypeString = (item) => {
    const {
      promo_type = "",
      amount_off = 0,
      percentage_off = 0,
      subsidized_delivery_amount = 0,
      free_item_description = "",
    } = item;
    let promoTypeString = "";

    if (promo_type === "amount_off") {
      const amountOffString = `P${Number(amount_off).toFixed(
        2,
      )} off order purchase`;
      promoTypeString = amountOffString;
    } else if (promo_type === "percentage_off") {
      const percentOffString = `${Number(percentage_off)}% off order purchase`;
      promoTypeString = percentOffString;
    } else if (promo_type === "free_delivery") {
      const deliveryOffString = `P${Number(subsidized_delivery_amount).toFixed(
        2,
      )} off delivery fee`;
      promoTypeString = deliveryOffString;
    } else if (promo_type === "free_item") {
      const freeItemString = `Free item: ${free_item_description}`;
      promoTypeString = freeItemString;
    } else {
      promoTypeString = promo_type;
    }

    return promoTypeString;
  };

  promoRevenueInfo = (data) => {
    let promoRevenueInfo = "";
    promoRevenueInfo = `${toPesoAmount(
      data.totalRevenue,
    )} Total revenue generated with this promo code`;
    return promoRevenueInfo;
  };

  promoDescription = (item) => {
    let promoDescription = "";
    const promoTypeString = this.promoTypeString(item);
    const minimumPurchaseString = this.minimumPurchaseString(item);
    const appliesToString = this.appliesToString(item);
    const promosUsedString = this.promosUsedString(item);
    const promoStartString = this.promoStartString(item);
    const promoEndString = this.promoEndString(item);
    const promoScopeString = this.promoScopeString(item);

    promoDescription =
      promoTypeString +
      ` - ` +
      minimumPurchaseString +
      ` - ` +
      appliesToString +
      ` - ` +
      promosUsedString +
      ` - ` +
      promoStartString +
      promoEndString +
      ` - ` +
      promoScopeString;

    return promoDescription;
  };

  componentDidMount() {}

  render() {
    const {
      promo_code,
      data,
      handleClickPromoOrders,
      handleClickPromoCode,
      handleDeletePromoCode,
      handleCopyPromoCode,
      user,
    } = this.props;
    return (
      <>
        <Card className="mt-2 mr-4">
          {/* <Card.Title>{promo_code}</Card.Title> */}
          <DisplayFeaturedBadge item={data} />
          <Card.Body>
            <div className="d-flex flex-row justify-content-space-between">
              <div>
                <h5
                  className="cursor-pointer font-weight-bolder"
                  onClick={() => handleClickPromoCode(data._id)}
                >
                  {promo_code}
                </h5>
                <div
                  style={{
                    color: "#9E50EF",
                    // color: PRIMARY_COLOR,
                  }}
                  className="font-weight-bolder mr-5"
                >
                  {this.promoRevenueInfo(data)}
                </div>
                <div className="text-muted mr-5">
                  {this.promoDescription(data)}
                </div>

                <DisplayActiveBadge item={data} />
              </div>
              {user.iam_role.includes("console_user") ||
              !isPromoScopeAllowedForUserLevel(user.iam_role, data.scope) ? (
                <></>
              ) : (
                <>
                  <div className="d-flex flex-row align-items-center">
                    <i
                      className="fas fa-list mr-5 cursor-pointer"
                      data-toggle="tooltip"
                      title="Promo Orders"
                      onClick={() => handleClickPromoOrders(data._id)}
                    ></i>
                    <i
                      className="fas fa-pen mr-5 cursor-pointer"
                      data-toggle="tooltip"
                      title="Edit promo"
                      onClick={() => handleClickPromoCode(data._id)}
                    ></i>
                    <i
                      className="fas fa-copy mr-5 cursor-pointer"
                      data-toggle="tooltip"
                      title="Copy promo"
                      onClick={() => handleCopyPromoCode(data._id)}
                    ></i>
                    <i
                      className="fas fa-trash mr-5 cursor-pointer"
                      data-toggle="tooltip"
                      title="Remove promo"
                      onClick={() => handleDeletePromoCode(data._id)}
                    ></i>
                  </div>
                </>
              )}
            </div>
          </Card.Body>
        </Card>
      </>
    );
  }
}

const DisplayActiveBadge = ({ item }) => {
  const { active } = item;
  return (
    <>
      {active ? (
        <>
          <div
            style={{ backgroundColor: PRIMARY_COLOR }}
            className="badge badge-pill badge-success text-uppercase mt-1"
          >
            Active
          </div>
        </>
      ) : (
        <>
          <div className="badge badge-pill badge-secondary text-uppercase mt-1">
            Not Active
          </div>
        </>
      )}
    </>
  );
};

const DisplayFeaturedBadge = ({ item }) => {
  const { featured = false } = item;
  // console.log(item);
  return (
    <>
      {featured && (
        <>
          <div className="ribbon ribbon-clip ribbon-right">
            <div class="ribbon-target">
              <span class="ribbon-inner bg-danger"></span>FEATURED PROMO
            </div>
          </div>
        </>
      )}
    </>
  );
};
