/** IAM */
import defaultMethods from "./default";

function methods(axiosInstance) {
  let methodList = defaultMethods(axiosInstance);

  methodList.login = function (
    username,
    password,
    webview_mode = false,
    callback,
  ) {
    return methodList
      .post({
        url: "/users/login",
        data: {
          username: username,
          password: password,
          tokenOnly: true,
          webview_mode,
        },
      })
      .then((data) => {
        return callback(null, data);
      })
      .catch((err) => {
        return callback(err);
      });
  };

  methodList.me = function () {
    return methodList.get("/users/me");
  };

  methodList.checkEmailAvailability = function (email) {
    return methodList.get(`/users/check-email?email=${email}`);
  };

  methodList.createUser = function (newUser) {
    return methodList.post({ url: "users", data: newUser });
  };

  methodList.requestPassword = function (email) {
    return methodList.post({
      url: "/external/forgot-password",
      data: { email },
    });
  };

  methodList.getPrivileges = function () {
    return methodList.get("/users/privileges");
  };

  return methodList;
}

export default methods;
