import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Row, Col, Button, Modal, Table } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { GreenRadio, FormItem } from "./Base";
import FileUpload from "./FileUpload";
import AdditionalBeneficiaryOwnerModal from "../partials/AdditionalBeneficiaryOwner";
import SignPad from "./SignPad";
import { SVGIcon } from "../components/Base";
import banks from "../../assets/banks.json";
import { getPHStates } from "../helpers";
import { isEmpty } from "lodash";

const instance = axios.create({});
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => Promise.reject(err),
);

const PaymongoWizard = (props) => {
  const steps = [
    "Your business information",
    "More business details",
    "Your bank account",
    "Statement of Acceptance",
  ];

  const requiredToAll = [
    // 'bussiness_type',
    "business_address_l1", // BUSINESS INFO
    "business_address_city",
    "business_address_state",
    "business_address_zip",
    // 'business_handle',
    // 'business_bank',
    // 'business_account_name',
    // 'business_account_number',
    // 'signature',
  ];

  const businessTypes = [
    {
      name: "Individual",
      description:
        "Select this business type if you are the only owner of a business, and you are not registered with the DTI.",
      bankAccountDesc:
        "Bank account should be under the name of the person who registered and submitted an ID.",
      tradeNameDesc: "Full legal name",
      requiredFields: [...requiredToAll],
    },
    {
      name: "Sole proprietorship",
      description:
        "Select this business type if you are the sole owner of a business, and you have it registered with the DTI.",
      bankAccountDesc:
        "Bank account should be under the name of the registered business or the name of the person to whom the DTI Certificate of Registration is issued to.",
      tradeNameDesc: "DTI-registered entity",
      requiredFields: [
        ...requiredToAll,
        "business_registered_name",
        "business_registration_number",
        "dti_registration_cert",
      ],
    },
    {
      name: "Corporation",
      description:
        "Select this business type if your business is owned by a corporate entity and is registered with the SEC.",
      bankAccountDesc:
        "Bank account should be under the name of the registered business (account name should be the same as the one on the SEC Certificate of Incorporation).",
      tradeNameDesc:
        "If different from the registered entity name, it is the name for your business that your customers will recognize.",
      requiredFields: [
        ...requiredToAll,
        "business_legal_name",
        "business_registration_number",
        "business_date_of_incorporation",
        "sec_incorporation_cert",
        "by_laws",
        "articles_of_incorporation",
      ],
    },
    {
      name: "Partnership",
      description:
        "Select this business type if your business is owned by two or more individuals or partners, and it is registered with the SEC.",
      bankAccountDesc:
        "Bank account should be under the name of the registered business (account name should be the same as the one on the SEC Certificate of Partnership).",
      tradeNameDesc:
        "If different from the registered entity name, it is the name for your business that your customers will recognize.",
      requiredFields: [
        ...requiredToAll,
        "business_legal_name",
        "business_registration_number",
        "business_date_of_incorporation",
        "sec_incorporation_cert",
        "articles_of_partnership",
      ],
    },
  ];

  const [countries, setCountries] = useState([]);
  const [selectedStep, setSelectedStep] = useState(0);
  const [businessType, setBusinessType] = useState(0);
  const [submittingState, setSubmittingState] = React.useState(false);
  const [signatureImage, setSignatureImage] = React.useState("");

  const [isBeneficialOwner, setIsBeneficialOwner] = useState(true);
  const [selectedBeneficialOwner, setSelectedBeneficialOwner] = useState(null);
  const [isAdditionalBeneficiaryOpen, setIsAdditionalBeneficiaryOpen] =
    useState(false);

  const [isBusinessHandleOpen, setIsBusinessHandleOpen] = useState(false);
  const [stageRequirements, setStageRequirements] = useState([
    [
      ...requiredToAll, // BUSINESS INFO, initial values only business address (individual buss. type)
    ],
    [
      // 'business_handle',     // MORE BUSINESS INFO
      // 'business_link',
      // 'business_nature',
    ],
    [
      "business_bank", //BANK ACCOUNT
      "business_account_name",
      "business_account_number",
    ],
    ["signature"], // SOA
  ]);

  const {
    user = {},
    entityName = "",
    actions = {},
    values = {},
    inputActions = {},
    uploadMerchantFile,
    changeValidateList,
    getError,
    clearError,
  } = props;

  const nextStep = (fields) => {
    const proceed = props.validateFields(fields);
    if (proceed) {
      return setSelectedStep(selectedStep + 1);
    }
  };

  useEffect(() => {
    // instance.get('https://restcountries.eu/rest/v2/all?fields=name;alpha2Code') //
    instance
      .get("https://restcountries.com/v2/all?fields=name;alpha2Code")
      .then(({ data }) => {
        setCountries(data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    changeValidateList(businessType);

    if (businessType === 2) {
      values["business_beneficial_owner"] = [];
      values["is_beneficial_owner"] = true;
    } else {
      delete values["business_beneficial_owner"];
      delete values["is_beneficial_owner"];
    }
  }, [businessType, changeValidateList, values]);

  const setIsBeneficialOwnerValue = (e, isOwner = true) => {
    //set the values of props values
    e.preventDefault();

    setIsBeneficialOwner(isOwner);
    inputActions.onChange({
      target: { name: "is_beneficial_owner", value: isOwner },
    });
  };

  const goPrevStep = () => {
    setSignatureImage("");
    clearError(stageRequirements[selectedStep]);
    setSelectedStep(selectedStep - 1);
  };

  const handleSubmitClick = () => {
    setSubmittingState(true);
    values.signature_image = signatureImage;
    props.onSubmit(values, setSubmittingState);
  };

  return (
    <Form className="row flex px-4">
      <div className="col-xs-12 col-lg-4">
        <div className="steps d-flex flex-column flex-sm-row flex-lg-column align-items-stretch justify-content-between font-xs">
          {steps.map((step, idx) => (
            <div key={idx}>
              <Step
                item={step}
                idx={idx}
                active={idx === selectedStep}
                setSelectedStep={setSelectedStep}
              />
            </div>
          ))}
        </div>

        <div
          className={`row mt-8 d-flex flex-wrap justify-content-around ${
            businessType === 2 && selectedStep === 0
              ? "d-inline-block"
              : "d-none"
          }`}
        >
          {businessType === 2 && values["business_beneficial_owner"] ? (
            <>
              {values["business_beneficial_owner"].length > 0 && (
                <h4 className="text-muted col-12">Added beneficial owners</h4>
              )}

              {values["business_beneficial_owner"].map((owner, idx) => (
                <div
                  className="col-xs-12 col-sm-6 col-md-3 col-lg-12 card mt-2 mr-0 mr-md-2 mr-lg-0 py-2 px-4"
                  key={idx}
                >
                  <div className="">
                    <h5 className="text-gray">{`${owner.first_name} ${owner.last_name}`}</h5>
                    <div className="d-flex flex-column">
                      <div className="">
                        <span>Ownership: </span>
                        {owner.percent_own || 0}%
                      </div>
                      <div className="">
                        <span>Date of birth: </span>
                        {owner.date_of_birth}
                      </div>
                      <div className="">
                        <span>Address: </span>
                        {`
                                                ${owner.business_address_l1},
                                                ${owner.business_address_l2},
                                                ${owner.business_address_city},
                                                ${owner.business_address_state},
                                                ${owner.business_address_zip},
                                                ${owner.business_address_country}
                                            `}
                      </div>
                    </div>

                    <div className="d-flex px-4 mt-2">
                      <button
                        className="btn p-1 mr-2"
                        onClick={(e) => {
                          e.preventDefault();

                          setSelectedBeneficialOwner(owner);
                          setIsAdditionalBeneficiaryOpen(true);
                        }}
                      >
                        <i className="far fa-edit text-warning"></i>
                      </button>
                      <button
                        className="btn p-1"
                        onClick={(e) => {
                          e.preventDefault();
                          values["business_beneficial_owner"].splice(idx, 1);
                          setSelectedBeneficialOwner(null);

                          inputActions.onChange({
                            target: {
                              value: values["business_beneficial_owner"],
                              name: "business_beneficial_owner",
                            },
                          });
                        }}
                      >
                        <i className="far fa-trash-alt text-danger"></i>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="col-xs-12 col-lg-8 mt-8 mt-lg-0">
        {selectedStep > 0 && (
          <span
            className="font-size-h6 text-primary text-hover-primary-dark"
            onClick={goPrevStep}
            style={{ cursor: "pointer" }}
          >
            <SVGIcon
              icon={"Navigation/Left-2"}
              title={"Back"}
              variant="primary"
              className="mr-2"
            />
            Go Back to Step {selectedStep}
          </span>
        )}

        {selectedStep === 0 && (
          <div className="py-8 px-4 border-1 border-primary rounded">
            <h3 className="mb-8">{steps[selectedStep]}</h3>
            <>
              <h5 className="mt-8">Type of your business</h5>

              <div className="business-type-container">
                <div className="business-type-radio-container d-flex justify-content-between flex-column flex-sm-row align-items-start align-items-sm-center">
                  {businessTypes.map((type, idx) => (
                    <div
                      className="bg-hover-secondary pr-4 rounded-pill"
                      key={idx}
                      onClick={() => {
                        inputActions.onChange({
                          target: { value: type.name, name: "business_type" },
                        });
                        setBusinessType(businessTypes.indexOf(type));
                        changeValidateList(businessTypes.indexOf(type));

                        let newBusinessTypeRequiredFields = stageRequirements;
                        newBusinessTypeRequiredFields[0] =
                          businessTypes[idx].requiredFields;

                        setStageRequirements([
                          ...newBusinessTypeRequiredFields,
                        ]);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <GreenRadio checked={idx === businessType} />
                      <span>{type.name}</span>
                    </div>
                  ))}
                </div>

                <div className="business-type-information rounded bg-secondary py-2 px-4 font-size-xs mt-2">
                  <h5 className="text-info font-size-sm">
                    {businessTypes[businessType].name}
                  </h5>
                  <p className="description text-gray mb-0">
                    {businessTypes[businessType].description}
                  </p>
                </div>
              </div>

              {/* ----------------BUSINESS TYPE FORM FIELDS---------------- */}
              {businessType === 0 && (
                <>
                  <BusinessTradeName
                    description={businessTypes[businessType].tradeNameDesc}
                    actions={actions}
                    inputActions={inputActions}
                    values={values}
                  />
                  <BusinessAddress
                    getError={getError}
                    actions={actions}
                    inputActions={inputActions}
                    values={values}
                  />
                </>
              )}
              {businessType === 1 && (
                <>
                  <h5 className="mt-8">DTI-registered business name</h5>
                  <div className="text-muted font-size-md mb-4">
                    This is the name that appears in your registration
                    certificate.
                  </div>
                  <FormItem
                    name={"business_registered_name"}
                    inputProps={{
                      name: "business_registered_name",
                      placeholder: "DTI-registered name",
                      value: values["business_registered_name"],
                    }}
                    inputActions={inputActions}
                    actions={actions}
                    noLabel
                    normal
                    showRequired
                  />

                  <BusinessTradeName
                    description={businessTypes[businessType].tradeNameDesc}
                    actions={actions}
                    inputActions={inputActions}
                    values={values}
                  />

                  <BusinessRegistrationNumber
                    actions={actions}
                    inputActions={inputActions}
                    values={values}
                    description={
                      "This is the company registration number that appears in your DTI Business Name Registration certificate."
                    }
                  />

                  <BusinessAddress
                    getError={getError}
                    actions={actions}
                    inputActions={inputActions}
                    values={values}
                  />

                  <FileUpload
                    header={
                      "Upload your DTI Business Name Registration certificate"
                    }
                    name={"dti_registration_cert"}
                    entityName={entityName}
                    actions={actions}
                    inputActions={inputActions}
                    uploadFile={uploadMerchantFile}
                    showUploadButton={true}
                  />
                </>
              )}
              {businessType === 2 && (
                <>
                  <BusinessLegalName
                    actions={actions}
                    inputActions={inputActions}
                    values={values}
                    // description={'This is the company registration number that appears in your DTI Business Name Registration certificate.'}
                  />

                  <BusinessTradeName
                    description={businessTypes[businessType].tradeNameDesc}
                    actions={actions}
                    inputActions={inputActions}
                    values={values}
                  />

                  <BusinessRegistrationNumber
                    actions={actions}
                    inputActions={inputActions}
                    values={values}
                  />

                  <DateOfIncorporation
                    actions={actions}
                    inputActions={inputActions}
                    values={values}
                  />

                  <h5 className="mt-8">Are you a beneficial owner?</h5>
                  <div className="text-muted font-size-md mb-4">
                    You own at least 20% of the business.
                  </div>
                  <div className="btn-container d-flex justify-content-between">
                    <div className="owner-query">
                      <button
                        className={`btn ${
                          isBeneficialOwner && "btn-primary"
                        } text-hover-primary`}
                        onClick={(e) => setIsBeneficialOwnerValue(e, true)}
                      >
                        Yes
                      </button>
                      <button
                        className={`btn ${
                          !isBeneficialOwner && "btn-primary"
                        } text-hover-primary`}
                        onClick={(e) => setIsBeneficialOwnerValue(e, false)}
                      >
                        No
                      </button>
                    </div>
                    <button
                      className="btn btn-success"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsAdditionalBeneficiaryOpen(true);
                      }}
                    >
                      Add additional beneficial owner
                    </button>
                  </div>

                  <BusinessAddress
                    getError={getError}
                    actions={actions}
                    inputActions={inputActions}
                    values={values}
                  />

                  <FileUpload
                    header={"Upload your SEC Certificate of Incorporation"}
                    name={"sec_incorporation_cert"}
                    entityName={entityName}
                    actions={actions}
                    inputActions={inputActions}
                    uploadFile={uploadMerchantFile}
                  />
                  <FileUpload
                    header={"Upload your By-Laws"}
                    name={"by_laws"}
                    entityName={entityName}
                    actions={actions}
                    inputActions={inputActions}
                    uploadFile={uploadMerchantFile}
                  />
                  <FileUpload
                    header={"Upload your Articles of Incorporation"}
                    name={"articles_of_incorporation"}
                    entityName={entityName}
                    actions={actions}
                    inputActions={inputActions}
                    uploadFile={uploadMerchantFile}
                  />

                  {isAdditionalBeneficiaryOpen ? (
                    <AdditionalBeneficiaryOwnerModal
                      values={selectedBeneficialOwner}
                      show={isAdditionalBeneficiaryOpen}
                      header={"Add additional beneficial owners"}
                      countries={countries.map((country) => country.name)}
                      onHide={() => {
                        setIsAdditionalBeneficiaryOpen(false);
                        setSelectedBeneficialOwner(null);
                      }}
                      onSubmit={(values, setSubmitting) => {
                        const owner = props.values["business_beneficial_owner"];

                        setSelectedBeneficialOwner(null);

                        if (selectedBeneficialOwner) {
                          const idx = owner.indexOf(selectedBeneficialOwner);
                          owner.splice(idx, 1, values);

                          inputActions.onChange({
                            target: {
                              name: "business_beneficial_owner",
                              value: owner,
                            },
                          });
                        } else {
                          inputActions.onChange({
                            target: {
                              value: [
                                ...(props.values["business_beneficial_owner"] ||
                                  []),
                                values,
                              ],
                              name: "business_beneficial_owner",
                            },
                          });
                        }
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
              {businessType === 3 && (
                <>
                  <BusinessLegalName
                    actions={actions}
                    inputActions={inputActions}
                    values={values}
                  />

                  <BusinessTradeName
                    description={businessTypes[businessType].tradeNameDesc}
                    actions={actions}
                    inputActions={inputActions}
                    values={values}
                  />

                  <BusinessRegistrationNumber
                    actions={actions}
                    inputActions={inputActions}
                    values={values}
                  />

                  <DateOfIncorporation
                    actions={actions}
                    inputActions={inputActions}
                    values={values}
                  />

                  <BusinessAddress
                    getError={getError}
                    actions={actions}
                    inputActions={inputActions}
                    values={values}
                  />

                  <FileUpload
                    header={"Upload your SEC Certificate of Incorporation"}
                    name={"sec_incorporation_cert"}
                    entityName={entityName}
                    actions={actions}
                    inputActions={inputActions}
                    uploadFile={uploadMerchantFile}
                  />

                  <FileUpload
                    header={"Upload your Articles of Partnership"}
                    name={"articles_of_partnership"}
                    entityName={entityName}
                    actions={actions}
                    inputActions={inputActions}
                    uploadFile={uploadMerchantFile}
                  />
                </>
              )}
            </>
            <Button
              block={true}
              variant={"primary"}
              className="mt-8 font-size-lg"
              onClick={(e) => {
                e.preventDefault();
                nextStep(stageRequirements[selectedStep]);
              }}
            >
              Continue to{" "}
              <span className="text-lowecase">{steps[selectedStep + 1]}</span>
            </Button>
            {/* </StepPage> */}
          </div>
        )}

        {selectedStep === 1 && (
          <div className="py-8 px-4 border-1 border-primary rounded">
            <BusinesHandleExamples
              show={isBusinessHandleOpen}
              toggleShow={() => {
                setIsBusinessHandleOpen(!isBusinessHandleOpen);
              }}
            />

            <h3 className="mb-8">{steps[selectedStep]}</h3>

            {/* <div className="d-flex justify-content-between">
                        <h5 className="mt-8">Your business handle</h5>
                        <span 
                            className="text-info bg-hover-gray" 
                            style={{cursor: 'pointer'}}
                            onClick={()=> setIsBusinessHandleOpen(!isBusinessHandleOpen)}
                        >
                            see examples
                        </span>
                    </div> */}

            {/* <div className="text-muted font-size-md mb-2">Set a unique identifier using letters a-z, numbers 0-9 and dashes (-). No spaces and special characters allowed.</div>
                    <div className="text-muted font-size-md mb-4">Note: You can't change this in the future.</div>
                    <FormItem
                        name={"business_handle"}
                        inputProps={{
                            name: "business_handle",
                            placeholder: "Business handle",
                            value: values["business_handle"],
                        }}
                        inputActions={inputActions}
                        actions={actions}
                        noLabel
                        normal
                        showRequired
                    /> */}

            <h5 className="mt-8">
              Business website or social media page
              <span className="pl-2 text-muted font-size-sm">(Optional)</span>
            </h5>
            <div className="text-muted font-size-md mb-4">
              Share a URL of your website or any social media page (business
              profiles are preferred) that you mainly use for your business.
            </div>
            <FormItem
              name={"business_link"}
              inputProps={{
                name: "business_link",
                placeholder: "http://facebook.com/business_name",
                value: values["business_link"],
              }}
              inputActions={inputActions}
              actions={actions}
              noLabel
              normal
              // showRequired
            />

            <h5 className="mt-8">
              Nature of your business
              <span className="pl-2 text-muted font-size-sm">(Optional)</span>
            </h5>
            <div className="text-muted font-size-md mb-4">
              Provide details about the products or services that you sell,
              description of your customers and other relevant information to
              help us better understand your business.
            </div>
            <FormItem
              name={"business_nature"}
              type={"textarea"}
              inputProps={{
                name: "business_nature",
                placeholder: "Nature of your business",
                value: values["business_nature"],
              }}
              inputActions={inputActions}
              actions={actions}
              noLabel
              normal
              // showRequired
            />

            <Button
              block={true}
              variant={"primary"}
              className="mt-8 font-size-lg"
              onClick={() => {
                const proceed = props.validateFields(
                  stageRequirements[selectedStep],
                );
                if (proceed) {
                  return setSelectedStep(selectedStep + 1);
                }
              }}
            >
              Continue to{" "}
              <span className="text-lowercase">{steps[selectedStep + 1]}</span>
            </Button>
          </div>
        )}

        {selectedStep === 2 && (
          <div className="py-8 px-4 border-1 border-primary rounded">
            <h3 className="mb-8">{steps[selectedStep]}</h3>

            <div className="business-type-information rounded bg-secondary py-2 px-4 font-size-xs mt-2">
              <h5 className="text-info font-size-sm">
                {businessTypes[businessType].name}
              </h5>
              <p className="description text-gray mb-0">
                {businessTypes[businessType].bankAccountDesc}
              </p>
            </div>

            <h5 className="mt-8">Bank</h5>
            <FormItem
              customFormControl
              // label={"Bank"}
              name={"business_bank"}
              className={"form-control-solid"}
              actions={actions}
              type={"text"}
              showRequired={true}
              noLabel
              normal
              custom={
                <>
                  <Typeahead
                    placeholder={"Bank"}
                    onChange={(selected) => {
                      if (selected !== undefined && selected.length > 0) {
                        inputActions.onChange({
                          target: { name: "business_bank", value: selected[0] },
                        });
                      } else {
                        inputActions.onChange({
                          target: { name: "business_bank", value: "" },
                        });
                      }
                    }}
                    options={banks}
                    allowNew={false}
                    id={"banks_ta"}
                    // onBlur={OnBlurBank}
                    onBlur={() =>
                      inputActions.onBlur({ target: { name: "business_bank" } })
                    }
                    isValid={
                      actions.isTouched("business_bank") &&
                      getError("business_bank") === ""
                        ? true
                        : false
                    }
                    isInvalid={getError("business_bank") !== ""}
                    defaultInputValue={values.business_bank}
                    // newSelectionPrefix={"New Category: "}
                  />
                </>
              }
            />

            <h5 className="mt-8">Account Name</h5>
            <FormItem
              name={"business_account_name"}
              inputProps={{
                name: "business_account_name",
                placeholder: "Account Name",
                value: values["business_account_name"],
              }}
              inputActions={inputActions}
              actions={actions}
              noLabel
              normal
              showRequired
            />

            <h5 className="mt-8">Account Number</h5>
            <div className="text-muted font-size-md mb-4">
              This should be a Peso savings or current account.
            </div>
            <FormItem
              name={"business_account_number"}
              type={"number"}
              inputProps={{
                name: "business_account_number",
                placeholder: "account number",
                value: values["business_account_number"],
              }}
              inputActions={inputActions}
              actions={actions}
              noLabel
              normal
              showRequired
            />

            <Button
              active={values.signature ? true : false}
              block={true}
              variant={"primary"}
              className="mt-8 font-size-lg"
              onClick={() => {
                const proceed = props.validateFields(
                  stageRequirements[selectedStep],
                );
                if (proceed) {
                  return setSelectedStep(selectedStep + 1);
                }
              }}
            >
              Continue to{" "}
              <span className="text-lowecase">{steps[selectedStep + 1]}</span>
            </Button>
          </div>
        )}
        {selectedStep === 3 && (
          <div className="py-8 px-4 border-1 border-primary rounded">
            <h3 className="mb-8">{steps[selectedStep]}</h3>

            <div className="soa-summary">
              <p>
                I, {`${user.first_name} ${user.last_name}`}, of legal age, am
                the authorized representative of a company duly incorporated
                under the laws of the Philippines with an office address at{" "}
                {`${values.business_address_l1}, ${values.business_address_l2}, ${values.business_address_city}, ${values.business_address_state}, ${values.business_address_zip}`}{" "}
                (the <b>"Company"</b>).
              </p>

              <p>
                On behalf of the Company, I acknowledge that I have fully read,
                understood and accept the Pickup Terms of Use for Merchants and
                the Pickup Privacy Policy which can be found from time to time
                on the Pickup.ph website. I further agree and acknowledge on
                behalf of the Company that Pickup may amend, modify or update
                the Pickup Terms of Use for Merchants and the Pickup Privacy
                Policy from time to time and will place any such amendments,
                modifications or updates on its website. By continuing to use
                the Pickup Platforms and Pickup Services, the Company will be
                taken to have agreed and consented to the application of such
                amendments, modifications or updates.
              </p>

              <p>
                I also acknowledge and agree on behalf of the Company to be
                bound by any agreements, terms and conditions or other
                arrangements the Company may have with any of Pickup’s business
                partners including any payments processing providers and
                delivery service providers ( <b>"Service Providers"</b>) that
                the Company may utilize through the Pickup Platforms from time
                to time.
              </p>
            </div>

            <SignPad
              uploadFile={uploadMerchantFile}
              name={"signature"}
              clientName={`${user.last_name}_${user.first_name}`}
              actions={actions}
              inputActions={inputActions}
              setSignatureImage={setSignatureImage}
            />

            <Button
              block={true}
              variant={!values.signature ? "secondary" : "primary"}
              active={!values.signature ? "secondary" : "primary"}
              className="mt-8 font-size-lg"
              // type={'submit'}
              onClick={isEmpty(signatureImage) ? null : handleSubmitClick}
              disabled={isEmpty(signatureImage)}
            >
              {submittingState ? (
                <>Submitting... </>
              ) : (
                <>
                  Continue to{" "}
                  <span className="text-lowercase">
                    Submit all requirements
                  </span>
                </>
              )}
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

export default PaymongoWizard;

const Step = (props) => {
  const { active = false, idx = 0, item = "" } = props;

  return (
    <div
      className={`step d-flex flex-column flex-lg-row justify-content-lg-start align-items-center mt-2 h-100 ${
        active ? "bg-primary text-white" : "text-gray-dark-75"
      }`}
      // onClick={()=> setSelectedStep(idx)}
    >
      <div
        className={`icon rounded px-2 py-1 align-self-center mr-2 ${
          active ? "bg-white text-dark-75" : "bg-secondary text-gray-dark-75"
        }`}
      >
        Step {idx + 1}
      </div>
      <div className="step-content flex-shrink-1 text-center mt-2 mt-lg-0">
        <h5 className="d-inline-block item-header font-size-sm mb-0">{item}</h5>
      </div>
    </div>
  );
};

export const BusinessAddress = (props) => {
  const {
    values = {},
    actions = {},
    inputActions = {},
    header = "Business Address",
    getError,
    includeOverseas = false,
  } = props;

  return (
    <div className="business-address-container">
      <h5 className="mt-8">{header}</h5>
      <FormItem
        name={"business_address_l1"}
        inputProps={{
          name: "business_address_l1",
          placeholder: "Line 1",
          value: values["business_address_l1"],
        }}
        inputActions={inputActions}
        actions={actions}
        normal
        noLabel
        showRequired
      />
      <FormItem
        name={"business_address_l2"}
        inputProps={{
          name: "business_address_l2",
          placeholder: "Line 2",
          value: values["business_address_l2"],
        }}
        inputActions={inputActions}
        actions={actions}
        normal
        noLabel
      />

      <Row>
        <Col xs={12}>
          <FormItem
            name={"business_address_city"}
            inputProps={{
              name: "business_address_city",
              placeholder: "city",
              value: values["business_address_city"],
            }}
            inputActions={inputActions}
            actions={actions}
            normal
            noLabel
            showRequired
          />
        </Col>
        <Col xs={12}>
          {includeOverseas ? (
            <FormItem
              name={"business_address_state"}
              inputProps={{
                name: "business_address_state",
                placeholder: "State",
                value: values["business_address_state"],
              }}
              inputActions={inputActions}
              actions={actions}
              normal
              noLabel
              showRequired
            />
          ) : (
            <FormItem
              customFormControl
              name={"business_address_state"}
              className={"form-control-solid"}
              actions={actions}
              type={"text"}
              showRequired={true}
              noLabel
              normal
              custom={
                <>
                  <Typeahead
                    placeholder={"State"}
                    onChange={(selected) => {
                      if (selected !== undefined && selected.length > 0) {
                        inputActions.onChange({
                          target: {
                            name: "business_address_state",
                            value: selected[0],
                          },
                        });
                      } else {
                        inputActions.onChange({
                          target: { name: "business_address_state", value: "" },
                        });
                      }
                    }}
                    options={getPHStates()}
                    allowNew={false}
                    id={"states_ta"}
                    // onBlur={onBlurAddressState}
                    onBlur={() =>
                      inputActions.onBlur({
                        target: { name: "business_address_state" },
                      })
                    }
                    isValid={
                      actions.isTouched("business_address_state") &&
                      getError("business_address_state") === ""
                        ? true
                        : false
                    }
                    isInvalid={getError("business_address_state") !== ""}
                    defaultInputValue={values.business_address_state}
                    // newSelectionPrefix={"New Category: "}
                  />
                </>
              }
            />
          )}
        </Col>
        <Col xs={12}>
          <FormItem
            name={"business_address_zip"}
            inputProps={{
              name: "business_address_zip",
              placeholder: "zip",
              value: values["business_address_zip"],
            }}
            inputActions={inputActions}
            actions={actions}
            normal
            noLabel
            showRequired
          />
        </Col>
      </Row>
    </div>
  );
};

const BusinessTradeName = ({
  values = {},
  actions = {},
  inputActions = {},
  description = "",
}) => {
  return (
    <>
      <h5 className="mt-8">
        Business Trade Name{" "}
        <span className="text-muted font-size-sm">(Optional)</span>
      </h5>
      <div className="text-muted font-size-md mb-4">{description}</div>
      <FormItem
        name={"business_trade_name"}
        inputProps={{
          name: "business_trade_name",
          placeholder: "Business trade Name",
          value: values["business_trade_name"],
        }}
        inputActions={inputActions}
        actions={actions}
        noLabel
        normal
        showRequired
      />
    </>
  );
};

const BusinessRegistrationNumber = ({
  values = {},
  actions = {},
  inputActions = {},
  description = "This is the company registration number that appears in your SEC Certificate of Incorporation.",
}) => {
  return (
    <>
      <h5 className="mt-8">Business registration number</h5>
      <div className="text-muted font-size-md mb-4">{description}</div>
      <FormItem
        name={"business_registration_number"}
        inputProps={{
          name: "business_registration_number",
          placeholder: "Registration number",
          value: values["business_registration_number"],
        }}
        inputActions={inputActions}
        actions={actions}
        noLabel
        normal
        showRequired
      />
    </>
  );
};

const BusinessLegalName = ({
  values = {},
  actions = {},
  inputActions = {},
}) => {
  return (
    <>
      <h5 className="mt-8">Business legal name</h5>
      <div className="text-muted font-size-md mb-4">
        We'll be verifying this name with the documents you provided, so please
        make sure it is the exact, full legal name.
      </div>
      <FormItem
        name={"business_legal_name"}
        inputProps={{
          name: "business_legal_name",
          placeholder: "Business legal name",
          value: values["business_legal_name"],
        }}
        inputActions={inputActions}
        actions={actions}
        noLabel
        normal
        showRequired
      />
    </>
  );
};

const DateOfIncorporation = ({
  values = {},
  actions = {},
  inputActions = {},
}) => {
  return (
    <>
      <h5 className="mt-8">Date of Incorporation</h5>
      {/* <div className="text-muted font-size-md mb-4">We'll be verifying this name with the documents you provided, so please make sure it is the exact, full legal name.</div> */}
      <FormItem
        type={"date"}
        name={"business_date_of_incorporation"}
        inputProps={{
          name: "business_date_of_incorporation",
          placeholder: "Date of Incorporation",
          value: values["business_date_of_incorporation"],
        }}
        inputActions={inputActions}
        actions={actions}
        noLabel
        normal
        showRequired
      />
    </>
  );
};

const BusinesHandleExamples = ({ show = false, toggleShow }) => {
  const samples = [
    { name: "Gary's Bakery and Cafe", handle: "garys-bakery-and-cafe" },
    { name: "Ella’s Clean Laundry", handle: "EllasCleanLaundry" },
    { name: "7714 Medical Supplies", handle: "7714-MedSupplies" },
    { name: "Handy Daddy Hardware", handle: "HDhardware" },
  ];

  return (
    <Modal show={show} centered={true} size={"lg"}>
      {/* <Modal.Header><h3>PayMongo handles</h3></Modal.Header> */}
      <Modal.Header>
        <Modal.Title>PayMongo handles</Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => toggleShow()}
        >
          <i aria-hidden="true" className="ki ki-close"></i>
        </button>
      </Modal.Header>

      <Modal.Body>
        <h4>Handle examples</h4>
        <Table className="rounded border border-secondary">
          <tbody>
            <tr className="text-muted">
              <td className="bg-secondary">Business name</td>
              <td>Valid PayMongo handle format</td>
            </tr>
            {samples.map((sample) => (
              <tr key={sample.name}>
                <td className="bg-secondary">{sample.name}</td>
                <td>{sample.handle}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="mt-8">
          <h4>What are PayMongo handles?</h4>
          <p>
            These are unique identifier for your PayMongo account, similar to
            how usernames work. It is also used for Links that you will be
            creating to accept payments without a website.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => toggleShow()}>
          Close window
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
