import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { Form, Row, Col } from "react-bootstrap";
import {
  isObjectEmpty,
  GetSubmitClassList,
  removeStringSpaces,
} from "../../helpers";
import { BrandLogo } from "../BrandSetup";
import ImageUpload from "../../components/ImageUpload";
import { Label, RequiredAsterisk, FormItem } from "../../components/Base";
import { BRAND_PRODUCT_TYPES } from "../../constants";

export default class BrandForm extends ProSourceForm {
  constructor(props) {
    super(props);

    const {
      user: {
        client: { name = "" },
      },
      brand = {},
      noInit = false,
    } = this.props;

    const hasBrand = !isObjectEmpty(brand);
    const brand_name = hasBrand ? brand.name : noInit ? "" : name,
      brand_logo = hasBrand ? brand.brand_logo : "",
      brand_description = hasBrand ? brand.brand_description : "",
      brand_intro = hasBrand ? brand.brand_intro : "",
      brand_url = hasBrand
        ? brand.brand_url
        : noInit
        ? ""
        : removeStringSpaces(name);
    const product_type = hasBrand ? brand.product_type : "";

    this.state = {
      values: {
        name: brand_name,
        brand_logo,
        brand_description,
        brand_intro,
        brand_url,
        product_type,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      file: null,
      brandLogoFileName: "",
      hasBrand,
      checkingHelperTextName: <></>,
      checkingHelperTextBrandURL: <></>,
      nameIsValid: false,
      nameIsInvalid: false,
      urlIsValid: false,
      urlIsInvalid: false,
      isChecking: false,
    };

    this.validate = {
      required: ["name", "brand_logo", "brand_url", "product_type"],
    };

    this.getFileHelperText = this.getFileHelperText.bind(this);

    this.onBlurName = this.onBlurName.bind(this);
    this.onBlurURL = this.onBlurURL.bind(this);
    this.hasRestrictedChar = this.hasRestrictedChar.bind(this);
  }

  componentDidMount() {
    let { values = {}, touched = {} } = this.state;
    if (values.name !== "") {
      touched["name"] = true;
      this.setState({ touched });
      this.onBlurName(values.name);
    }
  }

  onChange = (event) => {
    const name = event.target.name,
      value = event.target.value,
      { values = {}, touched = {}, errors = {} } = this.state;

    if (name === "name") {
      touched["brand_url"] = true;
      if (value.search(/[a-zA-Z0-9]/) !== -1) {
        // checks if name has alphanumeric characters
        // values.brand_url = hashifyString(value.replace(/[^a-zA-Z0-9 ]/g, ''));
        values.brand_url = removeStringSpaces(
          value.replace(/[^a-zA-Z0-9 ]/g, ""),
        );
        this.setState(
          { values, touched, nameIsValid: false, nameIsInvalid: false },
          () => {
            this.onBlurName(value);
          },
        );
      } else {
        errors.name = "Name should contain alphanumeric characters.";
        this.setState({
          touched,
          nameIsValid: false,
          nameIsInvalid: true,
          checkingHelperTextName: <></>,
          errors,
        });
      }
    } else if (name === "brand_url") {
      this.setState({ urlIsValid: false, urlIsInvalid: false }, () => {
        this.onBlurURL(value);
      });
    }
  };

  onSubmit = (values, setSubmitting) => {
    this.setState({
      nameIsValid: true,
      urlIsValid: true,
    });

    if (this.state.file) {
      this.props.uploadLogo(
        this.state.file,
        (filePath) => {
          values.brand_logo = filePath;
          this.setState({ values });
          setSubmitting(true);
          this.props.onSubmit(values, setSubmitting);
        },
        setSubmitting,
      );
    } else {
      this.props.onSubmit(values, setSubmitting);
    }
  };

  hasRestrictedChar = (value = "") => {
    const valSplit = value.split("");
    const restrictedCharsSplit = ["$", "."];
    let isInvalid = false;

    for (const char of restrictedCharsSplit) {
      if (valSplit.includes(char)) {
        isInvalid = true;
        break;
      }
    }
    return isInvalid;
  };

  onBlurName = (value) => {
    if (value !== "") {
      const { brand = {} } = this.props;
      let { errors = {}, values = {} } = this.state;

      // check if there name val has restricted value
      if (this.hasRestrictedChar(value)) {
        errors.name = '"$" and "." is not allowed';

        this.setState({
          nameIsValid: false,
          nameIsInvalid: true,
          checkingHelperTextName: <></>,
          errors,
        });

        return;
      } else {
        delete errors["name"];
      }

      if (
        // (!(errors.hasOwnProperty("name") && errors.name === "This field is required"))
        !(errors.hasOwnProperty("name") && errors.name) &&
        !(brand.hasOwnProperty("name") && brand.name === value)
      ) {
        this.setState(
          {
            checkingHelperTextName: (
              <>
                <div className="text-success">Checking availability....</div>
              </>
            ),
            nameIsValid: false,
            nameIsInvalid: false,
            urlIsValid: false,
            urlIsInvalid: false,
            isChecking: true,
          },
          () => {
            this.props.actions.onBlurBrandName(value, (valid) => {
              this.onBlurURL(values.brand_url);
              if (valid) {
                if (errors.hasOwnProperty("name"))
                  // if(errors.hasOwnProperty("name") && !this.hasRestrictedChar(value))
                  delete errors["name"];

                this.setState({
                  nameIsValid: true,
                  nameIsInvalid: false,
                  checkingHelperTextName: (
                    <>
                      {/* <div className="text-success">Brand name is valid.</div> */}
                    </>
                  ),
                  errors,
                  isChecking: false,
                });
              } else {
                errors.name = "Brand name is taken.";

                this.setState({
                  nameIsValid: false,
                  nameIsInvalid: true,
                  checkingHelperTextName: <></>,
                  errors,
                  isChecking: false,
                });
              }
            });
          },
        );
      }
    }
  };

  onBlurURL = (value) => {
    const x = value.search(/^[a-zA-Z0-9-_]+$/);
    if (x === -1) {
      // JD: & (ampersand) isn't recognized by the api so we have to catch this on the front end.
      let { errors = {} } = this.state;
      errors.brand_url =
        "Only lowercase letters, dash (-) and underscores (_) symbols are allowed.";
      this.setState({
        urlIsValid: false,
        urlIsInvalid: true,
        checkingHelperTextBrandURL: <></>,
        errors,
      });
    } else {
      const { brand = {} } = this.props;
      let { errors = {} } = this.state;

      if (
        !(
          errors.hasOwnProperty("brand_url") &&
          errors.brand_url === "This field is required"
        ) &&
        !(brand.hasOwnProperty("brand_url") && brand.brand_url === value)
      ) {
        this.setState(
          {
            checkingHelperTextBrandURL: (
              <>
                <div className="text-success">Checking availability....</div>
              </>
            ),
            urlIsValid: false,
            urlIsInvalid: false,
            isChecking: true,
          },
          () => {
            this.props.actions.onBlurBrandURL(
              value,
              ({ invalid = false, existing = false }) => {
                if (!invalid && !existing) {
                  if (errors.hasOwnProperty("brand_url"))
                    delete errors["brand_url"];

                  this.setState({
                    urlIsValid: true,
                    urlIsInvalid: false,
                    checkingHelperTextBrandURL: <></>,
                    errors,
                    isChecking: false,
                  });
                } else {
                  if (existing) errors.brand_url = "Brand URL is taken.";
                  else if (invalid)
                    errors.brand_url =
                      "Only lowercase letters, numbers, dash (-) and underscores (_) are allowed.";

                  this.setState({
                    urlIsValid: false,
                    urlIsInvalid: true,
                    checkingHelperTextBrandURL: <></>,
                    errors,
                    isChecking: false,
                  });
                }
              },
            );
          },
        );
      }
    }
  };

  getFileHelperText = () => {
    return this.state.brandLogoFileName === ""
      ? `No file selected.`
      : `File selected: ${this.state.brandLogoFileName}`;
  };

  render() {
    const { hasBrand } = this.state;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    const {
      saveButtonText = <span>Save Changes</span>,
      newBrandView = false,
      maxFileSize = 200,
      maxFileSizeText = "200KB",
    } = this.props;

    const hasErrors = !isObjectEmpty(this.state.errors);

    return (
      <>
        <Form
          className="fv-plugins-bootstrap fv-plugins-framework"
          onSubmit={this.handleOnSubmit}
        >
          {
            <>
              <Row>
                <Col sm={newBrandView ? 3 : 12}>
                  <div className="text-center">
                    {this.state.values.brand_logo ? (
                      <>
                        <BrandLogo
                          src={this.state.values.brand_logo}
                          onClickRemove={() => {
                            let { values = {} } = this.state;
                            values.brand_logo = "";
                            this.setState({ file: null, values });
                          }}
                        />
                      </>
                    ) : (
                      <ImageUpload
                        onError={this.props.actions.onError}
                        onChange={(file, preview) => {
                          let { values = {}, errors } = this.state;
                          values.brand_logo = preview;

                          // Remove brand logo error if it exist
                          delete errors["brand_logo"];

                          this.setState({
                            file,
                            values,
                            errors: { ...errors },
                          });
                        }}
                        label={
                          <div style={{ margin: "1rem 0" }}>
                            <Label
                              text={
                                <>
                                  <span>
                                    {" "}
                                    Brand Logo <RequiredAsterisk />{" "}
                                  </span>
                                </>
                              }
                            />
                            <div
                              className="text-muted"
                              style={{ fontSize: "0.8rem" }}
                            >
                              Maximum file size is 200KB. <br />
                              Suggested dimensions is 250x250 or 200x50.
                            </div>
                            <div
                              className="text-danger"
                              style={{ fontSize: "0.8rem" }}
                            >
                              {this.handleFeedbackError(
                                "brand_logo",
                                "error-text",
                              )}
                            </div>
                          </div>
                        }
                        maxFileSize={maxFileSize}
                        maxFileSizeText={maxFileSizeText}
                      />
                    )}
                  </div>
                </Col>
                <Col sm={newBrandView ? 9 : 12}>
                  <Row>
                    <Col>
                      <FormItem
                        label={<>Brand Name</>}
                        name={"name"}
                        inputProps={{
                          name: "name",
                          placeholder: "Brand Name",
                          value: this.state.values["name"],
                        }}
                        inputActions={inputActions}
                        actions={actions}
                        type={"text"}
                        helperText={
                          <>
                            <>{this.state.checkingHelperTextName}</>
                            {this.props.actions.getHelperText("brand_name")}
                          </>
                        }
                        showRequired={true}
                        useTouchedValidation={false}
                        isValid={this.state.nameIsValid}
                        isInvalid={this.state.nameIsInvalid}
                        normal
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={newBrandView ? 12 : 6} sm={newBrandView ? 12 : 6}>
                      <FormItem
                        customFormControl
                        label={"Brand URL"}
                        name={"brand_url"}
                        custom={
                          <>
                            <div className="input-group">
                              <Form.Control
                                placeholder="Brand URL"
                                name="brand_url"
                                value={this.state.values["brand_url"]}
                                isValid={this.state.urlIsValid}
                                isInvalid={this.state.urlIsInvalid}
                                {...inputActions}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  .pickup.ph
                                </span>
                              </div>
                            </div>
                          </>
                        }
                        actions={actions}
                        helperText={
                          <>
                            <>{this.state.checkingHelperTextBrandURL}</>
                            {this.props.actions.getHelperText("brand_url")}
                          </>
                        }
                        showRequired={true}
                      />
                    </Col>
                    <Col lg={newBrandView ? 12 : 6} sm={newBrandView ? 12 : 6}>
                      <FormItem
                        label={"Brand Product Type"}
                        name={"product_type"}
                        customFormControl
                        custom={
                          <>
                            <Form.Control
                              as="select"
                              name={"product_type"}
                              custom="true"
                              className="text-capitalize"
                              value={this.state.values["product_type"]}
                              {...inputActions}
                            >
                              <option value="">Select product type</option>
                              {BRAND_PRODUCT_TYPES.map((label) => {
                                return <option value={label}>{label}</option>;
                              })}
                            </Form.Control>
                          </>
                        }
                        inputActions={inputActions}
                        actions={actions}
                        showRequired={true}
                        normal
                      />
                    </Col>
                  </Row>
                  {newBrandView ? (
                    <div className="text-right">
                      <button
                        type="submit"
                        disabled={
                          hasErrors ||
                          this.state.isSubmitting ||
                          this.state.isChecking
                        }
                        className={GetSubmitClassList(
                          this.state.isSubmitting,
                          `btn btn-primary font-weight-bold px-9 py-4 my-3`,
                        )}
                      >
                        {saveButtonText}
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </>
          }
          {newBrandView ? (
            <></>
          ) : (
            <button
              type="submit"
              disabled={
                hasErrors || this.state.isSubmitting || this.state.isChecking
              }
              className={GetSubmitClassList(
                this.state.isSubmitting,
                `btn btn-primary font-weight-bold px-9 py-4 my-3`,
              )}
            >
              {saveButtonText}
            </button>
          )}
        </Form>
      </>
    );
  }
}
