import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { FormItem, Label, RequiredAsterisk } from "../../components/Base";

const paymentmethodstext = {
  card: {
    title: "CARD",
    body: "MASTERCARD, VISA",
  },
  gcash: {
    title: "GCASH",
    body: "You will be redirected to the GCash platform to fulfill your payment.",
  },
  grab_pay: {
    title: "GRAB PAY",
    body: "You will be redirected to the Grab Pay platform to fulfill your payment.",
  },
};

export default class PaymentMethod extends React.Component {
  render() {
    const { data = {} } = this.props;
    const { store = {} } = data;
    const { payment_methods = [] } = store;

    const switched_off_methods =
      process.env.REACT_APP_UNAVAILABLE_PAYMENT_METHODS || "";
    const preferred_payment_methods = payment_methods.filter((method) => {
      const unavailable = switched_off_methods
        .split(",")
        .map((off) => off?.toLowerCase()?.trim());

      return !unavailable.includes(method.toLowerCase());
    });
    return (
      <>
        {
          <>
            {
              <Form.Group className="mb-1">
                <Row>
                  {preferred_payment_methods.map(
                    (payment_method = "", index) => (
                      <Col sm={12} key={index}>
                        <label className="option option option-plain">
                          <span className="option-control">
                            <span className="radio">
                              <input
                                type="radio"
                                name="payment_method"
                                value={payment_method}
                                checked={
                                  this.props.values["payment_method"] ===
                                  payment_method
                                    ? "checked"
                                    : ""
                                }
                                {...this.props.form.inputActions}
                              />
                              <span></span>
                            </span>
                          </span>
                          <span className="option-label d-flex align-items-center">
                            <span>
                              <span className="option-head">
                                <span className="option-title">
                                  <span className="va-bottom">
                                    {paymentmethodstext[payment_method].title}{" "}
                                  </span>
                                  {payment_method === "card" ? (
                                    <>
                                      <img
                                        alt="pickupsecure"
                                        className="va-middle mr-0 pr-0"
                                        src={toAbsoluteUrl(
                                          "/media/payment-method/pickup.ph-secure.svg",
                                        )}
                                        style={{
                                          width: "100px",
                                          height: "35px",
                                        }}
                                      />
                                      <a
                                        href="https://www.mcafeesecure.com/verify?host=pickup.ph"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img
                                          className="va-middle ml-0"
                                          alt="mcafee-secure"
                                          src={toAbsoluteUrl(
                                            "/media/payment-method/mcafee-secure-badge.svg",
                                          )}
                                          style={{
                                            width: "100px",
                                            height: "35px",
                                          }}
                                        />
                                      </a>
                                      <img
                                        alt="mastercard"
                                        className="va-middle ml-4"
                                        src={toAbsoluteUrl(
                                          "/media/payment-method/mastercard.svg",
                                        )}
                                        style={{
                                          maxWidth: "100px",
                                          height: "30px",
                                        }}
                                      />
                                      <img
                                        alt="visa"
                                        className="va-middle ml-4"
                                        src={toAbsoluteUrl(
                                          "/media/payment-method/visa.svg",
                                        )}
                                        style={{
                                          maxWidth: "100px",
                                          height: "30px",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {payment_method === "gcash" ? (
                                    <img
                                      alt={"gcash"}
                                      className="va-middle"
                                      src={toAbsoluteUrl(
                                        "/media/payment-method/gcash.svg",
                                      )}
                                      style={{ width: "100px", height: "35px" }}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                  {payment_method === "grab_pay" ? (
                                    <img
                                      alt={"grab pay"}
                                      className="va-middle"
                                      src={toAbsoluteUrl(
                                        "/media/payment-method/grab_pay.svg",
                                      )}
                                      style={{ width: "100px", height: "35px" }}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </span>
                              <span className="option-body">
                                {paymentmethodstext[payment_method].body}
                              </span>
                            </span>
                          </span>
                        </label>

                        {this.props.values["payment_method"] ===
                          payment_method && payment_method === "card" ? (
                          <CardForm {...this.props} />
                        ) : (
                          <></>
                        )}
                      </Col>
                    ),
                  )}
                </Row>
              </Form.Group>
            }
          </>
        }

        <div className="text-danger">
          {this.props.form.actions.handleFeedbackError(
            "payment_method",
            "error-text",
          )}
        </div>
      </>
    );
  }
}

class CardForm extends React.Component {
  render() {
    // console.log(this.props.form);

    return (
      <>
        <FormItem
          label={"Card Number"}
          name={"card_details"}
          inputProps={{
            name: "card_details",
            placeholder: "Card Number",
            value: this.props.values["card_details"],
          }}
          inputActions={this.props.form.inputActions}
          actions={this.props.form.actions}
          type={"text"}
          showRequired={true}
          normal
        />
        <Row className="mv-1rem">
          <Col className="px-0">
            <Label
              text={
                <>
                  Expiration Date <RequiredAsterisk />
                </>
              }
            />

            <div className="d-flex flex-row justify-space-between">
              <FormItem
                noLabel={true}
                inputProps={{
                  name: "expiration_date_mm",
                  placeholder: "MM",
                  value: this.props.values["expiration_date_mm"],
                  maxLength: 2,
                }}
                inputActions={this.props.form.inputActions}
                actions={this.props.form.actions}
                type={"text"}
                showRequired={true}
                normal
                style={{
                  marginBottom: "0",
                }}
              />

              <div className="px-5 display-5 font-weight-bolder d-flex align-items-center">
                <span>/</span>
              </div>

              <FormItem
                noLabel={true}
                inputProps={{
                  name: "expiration_date_yy",
                  placeholder: "YY",
                  value: this.props.values["expiration_date_yy"],
                  maxLength: 2,
                }}
                inputActions={this.props.form.inputActions}
                actions={this.props.form.actions}
                type={"text"}
                showRequired={true}
                normal
                style={{
                  marginBottom: "0",
                }}
              />
            </div>

            <div className="text-danger">
              {this.props.form.actions.handleFeedbackError(
                "expiration_date",
                "error-text",
              )}
            </div>
          </Col>
          <Col>
            <FormItem
              label={"CVC"}
              name={"cvc"}
              inputProps={{
                name: "cvc",
                placeholder: "CVC",
                value: this.props.values["cvc"],
                autoComplete: "on",
              }}
              inputActions={this.props.form.inputActions}
              actions={this.props.form.actions}
              type={"password"}
              showRequired={true}
              normal
            />
          </Col>
        </Row>
      </>
    );
  }
}
