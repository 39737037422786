import React from "react";
import { isEmpty, isNull, isUndefined } from "lodash";
import { Card, Row } from "react-bootstrap";
import { FormItem } from "../../../components/Base";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { DB_DATE_FORMAT } from "../../../constants";
import moment from "moment";
import ProSourceForm from "../../../components/ProSourceForm";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

export class CreateCartForm extends ProSourceForm {
  constructor(props) {
    super(props);
    this.state = {
      values: props.values,
      dateOptions: [],
      timeOptions: [],
      error: {},
      touched: {},
      isSubmitting: false,
      showSelectedItemModal: false,
    };
    this.api = this.props.api;
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.getDateTimeOptions();
  }

  async componentDidUpdate(prevProps) {
    if (
      !isNull(prevProps.values.order_type) &&
      !isNull(this.props.values.order_type) &&
      prevProps.values.order_type.value !== this.props.values.order_type.value
    ) {
      await this.getDateTimeOptions();
    }
  }

  async getDateTimeOptions() {
    const { values } = this.props;

    this._isMounted && this.setState({ data_status: "fetching" });
    this.api &&
      this.api
        .get(`/branded-website/stores/${this.props.id}/date-options`)
        .then(({ data }) => {
          if (!isEmpty(data)) {
            let filtered_time = [];
            if (!isEmpty(values.order_date)) {
              filtered_time = data.filter(
                ({ value }) => value === values.order_date,
              )[0];
            }
            this.setState({
              dateOptions: data,
              timeOptions:
                !isUndefined(filtered_time) &&
                !isUndefined(filtered_time.time_options)
                  ? filtered_time.time_options.map((time) => {
                      return { label: time.text.trim(), value: time.value };
                    })
                  : [],
              data_status: "fetched",
            });
          }
        })
        .catch(() => {
          MySwal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
  }

  render() {
    const { values = {}, onDetailsUpdate, store } = this.props;
    const { isDeliveryActive, isCurbsideActive, isPickupActive } = store;
    const { dateOptions, timeOptions = [] } = this.state;

    const order_type_options = [
      { label: "Delivery", value: "delivery", disabled: !isDeliveryActive },
      { label: "Pick-up", value: "pickup", disabled: !isPickupActive },
      {
        label: "Third Party Pick-up",
        value: "third_party_pickup",
        disabled: !isPickupActive,
      },
      {
        label: "Curbside Pick-up",
        value: "curbside_pickup",
        disabled: !isCurbsideActive,
      },
    ];

    const actions = {
      handleFeedbackError: this.handleFeedbackError,
      isTouched: this.isTouched,
    };

    let defaultInputValue = {};
    if (!isEmpty(values["order_type"])) {
      const default_type = order_type_options.filter(
        (item) => item.value === values["order_type"],
      );
      if (default_type.length > 0) {
        defaultInputValue = { default_type: default_type };
      }
    }

    if (!isEmpty(values["order_time"]) && timeOptions.length > 0) {
      const default_time = timeOptions.filter(
        (time) => time.value === values["order_time"],
      );
      if (default_time.length > 0) {
        defaultInputValue = {
          ...defaultInputValue,
          default_time: default_time,
        };
      }
    }
    const { default_time = [], default_type = [] } = defaultInputValue;

    return (
      <Card.Body className="d-flex ml-8 p-0">
        <Row className="flex-nowrap p-4 w-100 justify-content-space-between">
          <FormItem
            style={{ marginRight: 20, flexBasis: "33%" }}
            customFormControl
            label={"Order Type"}
            name={"order_type"}
            className={"form-control-solid"}
            actions={actions}
            type={"text"}
            custom={
              <>
                <Typeahead
                  clearButton
                  placeholder={"Select order type"}
                  options={order_type_options}
                  id={"order_type"}
                  selected={default_type}
                  onChange={(selected) => {
                    onDetailsUpdate(
                      "order_type",
                      selected.length > 0 ? selected[0].value : selected.value,
                    );
                    this.setState({
                      values: {
                        ...values,
                        order_type:
                          selected.length > 0
                            ? selected[0].value
                            : selected.value,
                      },
                    });
                    this.getDateTimeOptions();
                  }}
                />
              </>
            }
          />
          <FormItem
            style={{ marginRight: 20, flexBasis: "33%" }}
            label={"Order Date"}
            name={"order_date"}
            actions={actions}
            className="form-control-solid"
            customFormControl
            custom={
              <div style={{ width: "100%" }}>
                <DatePicker
                  wrapperClassName="w-100"
                  selected={
                    !isNull(values["order_date"])
                      ? new Date(values["order_date"])
                      : null
                  }
                  onChange={(date) => {
                    if (date === null) {
                      this.setState({
                        values: {
                          ...values,
                          order_date: null,
                        },
                      });
                      onDetailsUpdate("order_date", null);
                    } else {
                      const format_date = moment(date).format(DB_DATE_FORMAT);
                      const filtered_time = dateOptions.filter(
                        ({ value }) => value === format_date,
                      )[0].time_options;
                      this.setState({
                        values: {
                          ...values,
                          order_date: format_date,
                        },
                        timeOptions: filtered_time.map((time) => {
                          return {
                            label: time.text.trim(),
                            value: time.value,
                          };
                        }),
                      });
                      onDetailsUpdate("order_date", format_date);
                    }
                  }}
                  minDate={
                    !isEmpty(dateOptions)
                      ? new Date(dateOptions[0].value)
                      : null
                  }
                  maxDate={
                    !isEmpty(dateOptions)
                      ? new Date(dateOptions[dateOptions.length - 1].value)
                      : null
                  }
                  className="form-control"
                  dateFormat={"MMMM dd, yyyy"}
                  placeholderText="Select order date"
                  disabled={
                    isEmpty(values["order_type"]) && isNull(dateOptions)
                  }
                />
              </div>
            }
          />
          <FormItem
            style={{ flexBasis: "33%" }}
            customFormControl
            label={"Order Time"}
            name={"order_time"}
            className={"form-control-solid"}
            actions={actions}
            type={"text"}
            custom={
              <Typeahead
                clearButton
                placeholder={"Select order time"}
                options={timeOptions}
                id={"order_time"}
                selected={default_time}
                onChange={(selected) => {
                  onDetailsUpdate(
                    "order_time",
                    selected.length > 0 ? selected[0] : selected,
                  );
                  this.setState({
                    values: {
                      ...values,
                      order_time: selected.length > 0 ? selected[0] : selected,
                    },
                  });
                }}
                disabled={isEmpty(values["order_time"]) && isEmpty(timeOptions)}
              />
            }
          />
        </Row>
      </Card.Body>
    );
  }
}
