import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { Redirect } from "react-router-dom";
import TitleHelmet from "../../partials/TitleHelmet";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import PageCenter from "../../components/PageCenter";
import { Card, Button } from "react-bootstrap";
import {
  Block,
  Separator,
  ContactUs,
  OrderTimeRender,
} from "../../components/Base";
import { GetSubmitClassList } from "../../helpers";
import CancelNotesModal from "../../partials/CancelNotesModal";
import AcceptOrderModal from "../../partials/AcceptOrderModal";

import qs from "qs";

export default function Page(props) {
  const { token = "" } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  return <MainComponent token={token} />;
}

function getTitle(data) {
  let title = "";

  if (data.action === "accept_order")
    title = "Accept Order #" + data.order.transaction_number;
  else if (data.action === "reject_order")
    title = "Reject Order #" + data.order.transaction_number;
  return title;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      showSuccessScreen: false,
      redirectTo: "",
      title: "Action",
    };

    this.api = core("pu").get();

    this.handleOnClickActionBtn = this.handleOnClickActionBtn.bind(this);
    this.executeAction = this.executeAction.bind(this);
    this.onSubmitNotes = this.onSubmitNotes.bind(this);
  }

  async getData() {
    const { token = "" } = this.props;

    this._isMounted && this.setState({ data_status: "fetching" });
    this.api &&
      this.api
        .post({ url: "/external/orders/validate-action", data: { token } })
        .then(({ data }) => {
          this._isMounted &&
            this.setState({
              title: getTitle(data),
              data,
              data_status: "fetched",
              token,
            });
          this.handleOnDataFetched();
        })
        .catch((error) => {
          this._isMounted &&
            this.setState({
              error: error,
              showSwalError: true,
              showSuccessScreen: false,
              showDataDiv: false,
            });
          this.handleOnDataFetchError();
        });
  }

  handleOnDataFetched = () => {
    const { order = {} } = this.state.data;

    if (order.status !== "new") {
      this.setState({ showOrderNotNew: true });
    }

    this.handleOnClickActionBtn();
  };

  handleOnDataFetchError = () => {
    /** overwrite default */
  };

  handleOnClickActionBtn = () => {
    const { data = {} } = this.state;

    if (data.action === "accept_order") {
      this.setState({ showAcceptAsapOrderModal: true });
    } else if (data.action === "reject_order") {
      this.setState({ showNotesModal: true });
    }
  };

  executeAction = (values = {}, setSubmitting = () => {}) => {
    const { token = "" } = this.state,
      {
        refund_notes = "",
        refunded_amount = 0,
        refund_type = "",
        minutes = "",
        is_asap = false,
      } = values;
    this.setState({ btnLoading: true });
    this.api
      .post({
        url: "/external/orders/execute-action",
        data: {
          token,
          refund_notes,
          refunded_amount,
          refund_type,
          minutes,
          is_asap,
        },
      })
      .then(({ data }) => {
        if (data.result === "ok") {
          setTimeout(() => {
            this.setState({
              showSwalError: false,
              showSuccessScreen: true,
              showDataDiv: false,
              showAcceptAsapOrderModal: false,
              showNotesModal: false,
            });
          }, 1000);
        }
      })
      .catch(() => {
        this.handleOnDataFetchError();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  onSubmitNotes = (values, setSubmitting) => {
    this.executeAction(values, setSubmitting);
  };

  render() {
    const {
        data_status = "",
        data = {},
        showSwalError = false,
        showNotesModal = false,
        showSuccessScreen = false,
        showDataDiv = true,
        btnLoading = false,
        showOrderNotNew = false,
        showAcceptAsapOrderModal = false,
      } = this.state,
      isLoading = data_status !== "fetched";

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}
        <PageCenter>
          {showSuccessScreen ? (
            <>
              <Card className="card-border-top-primary card-custom">
                <Card.Body>
                  <div className="display-4 font-weight-bolder gutter-b">
                    Success!
                  </div>
                  <div>Changes has been successfully saved.</div>
                </Card.Body>
              </Card>
            </>
          ) : (
            <></>
          )}
          {showSwalError ? (
            <>
              <Card className="card-border-top-primary card-custom">
                <Card.Body>
                  <div className="display-4 font-weight-bolder gutter-b">
                    Oooops!{" "}
                  </div>
                  <div>This seems to be an invalid action request.</div>
                  <div>
                    <ContactUs />
                  </div>
                </Card.Body>
              </Card>
            </>
          ) : (
            <></>
          )}

          {showDataDiv ? (
            isLoading ? (
              <Card className="card-border-top-primary card-custom">
                <Card.Body>
                  <div className="mb-10">
                    <p className="text-center">
                      We're currently validating your request...
                    </p>
                  </div>
                </Card.Body>
              </Card>
            ) : showOrderNotNew ? (
              <Card className="card-border-top-primary card-custom">
                <Card.Body>
                  <div className="display-4 font-weight-bolder gutter-b">
                    Oooops!{" "}
                  </div>
                  <div>It seems like this order has been updated.</div>
                  <div>
                    <ContactUs />
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <>
                <CancelNotesModal
                  show={showNotesModal}
                  onHide={() => {
                    this.setState({ showNotesModal: false });
                  }}
                  onSubmit={this.onSubmitNotes}
                  btnLoading={btnLoading}
                  order={data.order}
                />
                <AcceptOrderModal
                  show={showAcceptAsapOrderModal}
                  onHide={() => {
                    this.setState({ showAcceptAsapOrderModal: false });
                  }}
                  onSubmit={(values, setSubmitting) => {
                    this.executeAction(values, setSubmitting);
                    // if(is_asap) this.execAcceptASAPOrder(values.minutes, setSubmitting);
                    // else this.execUpdateStatus("accepted", "", setSubmitting);
                  }}
                  order={data.order}
                />
                <div>
                  <Card className="card-border-top-primary card-custom">
                    <Card.Body>
                      <div className="display-4 font-weight-bolder">
                        Order #{data.order.transaction_number}
                      </div>

                      <Separator solid border={"2"} />

                      <Block title={<> {data.order.store.name} </>} />

                      <Block
                        title={
                          data.order.order_type === "delivery" ? (
                            <>Dispatch Time</>
                          ) : (
                            <>Pickup Schedule</>
                          )
                        }
                        content={
                          <>
                            {/* <div>{moment(data.order.order_date, DB_DATE_FORMAT).format("MMMM D, YYYY")}</div>
                                                        <div>{moment(data.order.order_time, DB_HOURS_FORMAT).format("h:mm A")}</div> */}
                            <OrderTimeRender {...data.order} />
                            {data.order.order_type === "delivery" ? (
                              <div>{data.order.delivery_address}</div>
                            ) : (
                              <></>
                            )}
                          </>
                        }
                      />

                      <Button
                        variant={
                          data.action === "accept_order" ? "primary" : "danger"
                        }
                        className={GetSubmitClassList(
                          btnLoading,
                          "btn-lg btn-block text-uppercase",
                        )}
                        onClick={this.handleOnClickActionBtn}
                        disabled={btnLoading ? "disabled" : null}
                      >
                        {data.action === "accept_order" ? "accept" : "reject"}
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
              </>
            )
          ) : (
            <></>
          )}
        </PageCenter>
      </>
    );
  }
}
