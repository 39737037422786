import React from "react";
import { Card, Button } from "react-bootstrap";
// import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PRIMARY_COLOR } from "../../constants";
import { SVGIcon } from "../Base";
import EditStoreReservationDetailsModal from "../../partials/EditStoreReservationDetailsModal";

import {
  fetchData,
  formatOrderType,
  isPromoScopeAllowedForUserLevel,
  toPesoAmount,
} from "../../helpers";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { isEmpty } from "lodash";
const MySwal = withReactContent(Swal);

export default class StoreReservationsCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewStoreReservationModal: false,
      editMode: "edit",
      reservationTypeUseCount: 0,
    };

    //   this.handleViewStoreReservation =
    //     this.handleViewStoreReservation.bind(this);
    //   this.handleCopyStoreReservation =
    //     this.handleCopyStoreReservation.bind(this);
    //   this.handleDeleteStoreReservation =
    //     this.handleDeleteStoreReservation.bind(this);
  }

  componentDidMount() {
    this.getReservationTypeUseCount();
  }

  handleViewStoreReservation = () => {
    this.setState({ viewStoreReservationModal: true });
  };

  handleEditStoreReservation = () => {
    this.setState({ editMode: `edit` });
    this.handleViewStoreReservation();
  };

  handleCopyStoreReservation = () => {
    this.setState({ editMode: `copy` });
    this.handleViewStoreReservation();
  };

  // handleDeleteStoreReservation = (data) => {
  //   console.log("delete", data);
  // };

  reservationTypePaymentType = (item) => {
    let reservationTypePaymentType = "";
    // console.log(item);
    const {
      reservation_payment_type = "",
      all_in_price = 0,
      per_guest_price = 0,
    } = item;
    if (reservation_payment_type === "all_in") {
      reservationTypePaymentType = `P${Number(all_in_price).toFixed(
        2,
      )} Per Reservation`;
    } else if (reservation_payment_type === "per_guest") {
      reservationTypePaymentType = `P${Number(per_guest_price).toFixed(
        2,
      )} Per Guest`;
    }
    return reservationTypePaymentType;
  };

  reservationTypeStartString = (item) => {
    const { start_date } = item;
    let reservationTypeStartString = "";

    if (start_date) {
      reservationTypeStartString = `Start date ${start_date}`;
    }

    return isEmpty(reservationTypeStartString)
      ? ""
      : ` - ${reservationTypeStartString}`;
  };

  reservationTypeEndString = (item) => {
    const { end_date } = item;
    let reservationTypeEndString = "";

    if (end_date) reservationTypeEndString = ` - End date ${end_date}`;

    return isEmpty(reservationTypeEndString) ? "" : reservationTypeEndString;
  };

  reservationTypeUsedString = (item) => {
    let reservationTypeUsedString = "";
    let usedCount = 0;
    let usedString = "";
    let maxString = "";
    const { usage_limit } = this.props.data;

    if (this.state.reservationTypeUseCount)
      usedCount = this.state.reservationTypeUseCount;

    usedString = `${usedCount} used`;
    if (usage_limit) {
      maxString = ` out of ${usage_limit}`;
    } else {
      maxString = ``;
    }

    reservationTypeUsedString = usedString + maxString;

    return isEmpty(reservationTypeUsedString)
      ? ""
      : ` - ${reservationTypeUsedString}`;
  };

  reservationTypeAllowedGuestsRange = (item) => {
    const { min_guests, max_guests } = item;
    let reservationTypeAllowedGuestsRange = "";

    if (min_guests && max_guests)
      reservationTypeAllowedGuestsRange = `${min_guests} to ${max_guests} Guests`;

    return isEmpty(reservationTypeAllowedGuestsRange)
      ? ""
      : ` - ${reservationTypeAllowedGuestsRange}`;
  };

  reservationTypeSummary = (item) => {
    let reservationTypeSummary = "";

    const reservationTypePaymentType = this.reservationTypePaymentType(item);
    const reservationTypeStartString = this.reservationTypeStartString(item);
    const reservationTypeEndString = this.reservationTypeEndString(item);
    const reservationTypeUsedString = this.reservationTypeUsedString(item);
    const reservationTypeAllowedGuestsRange =
      this.reservationTypeAllowedGuestsRange(item);

    reservationTypeSummary = `
      ${reservationTypePaymentType} 
        ${reservationTypeAllowedGuestsRange} 
          ${reservationTypeStartString} 
            ${reservationTypeEndString} 
              ${reservationTypeUsedString}`;

    return reservationTypeSummary;
  };

  getReservationTypeUseCount = async () => {
    const reservationTypeId = this.props.data._id.toString();
    const result = await fetchData(
      "get",
      `/store-reservation/${reservationTypeId}/use-count`,
    );
    const reservationTypeUseCount = result.data.result;
    this.setState({ reservationTypeUseCount });
  };

  render() {
    const { data, store, handleDeleteStoreReservation } = this.props;
    const { viewStoreReservationModal } = this.state;
    // console.log("reservations card", data);

    return (
      <>
        <Card className="mt-2 mr-4">
          {/* <Card.Title>{promo_code}</Card.Title> */}
          <Card.Body>
            <div className="d-flex flex-row justify-content-space-between">
              <div>
                <h5
                  className="cursor-pointer font-weight-bolder"
                  onClick={() => this.handleViewStoreReservation()}
                >
                  {data.reservation_name}
                </h5>
                {/* <div
                  style={{
                    color: "#9E50EF",
                    // color: PRIMARY_COLOR,
                  }}
                  className="font-weight-bolder mr-5"
                >
                  {this.promoRevenueInfo(data)}
                </div> */}
                <div className="mr-5">{data.reservation_description}</div>
                <div className="text-muted mr-5">
                  {this.reservationTypeSummary(data)}
                </div>
                <DisplayActiveBadge item={data} />
              </div>
              {/* {user.iam_role.includes("console_user") ||
              !isPromoScopeAllowedForUserLevel(user.iam_role, data.scope) ? (
                <></>
              ) : ( */}
              <>
                <div className="d-flex flex-row align-items-center">
                  <i
                    className="fas fa-pen mr-5 cursor-pointer"
                    data-toggle="tooltip"
                    title="Edit store reservation"
                    onClick={() => this.handleEditStoreReservation()}
                  ></i>
                  <i
                    className="fas fa-copy mr-5 cursor-pointer"
                    data-toggle="tooltip"
                    title="Copy store reservation"
                    // onClick={() => this.handleCopyStoreReservation(data._id)}
                    onClick={() => this.handleCopyStoreReservation()}
                  ></i>
                  <i
                    className="fas fa-trash mr-5 cursor-pointer"
                    data-toggle="tooltip"
                    title="Remove store reservation"
                    onClick={() => handleDeleteStoreReservation(data._id)}
                  ></i>
                </div>
              </>
              {/* )} */}
            </div>
          </Card.Body>
        </Card>
        {viewStoreReservationModal && (
          <EditStoreReservationDetailsModal
            show={viewStoreReservationModal}
            onHide={() => {
              this.setState({
                viewStoreReservationModal: false,
              });
            }}
            data={data}
            store={{ _id: store._id, name: store.name }}
            actions={this.props.actions}
            inputActions={this.props.inputActions}
            onSubmit={this.handleOnSubmit}
            user={this.props.user}
            editMode={this.state.editMode}
          />
        )}
      </>
    );
  }
}

const DisplayActiveBadge = ({ item }) => {
  const { active } = item;
  return (
    <>
      {active ? (
        <>
          <div
            style={{ backgroundColor: PRIMARY_COLOR }}
            className="badge badge-pill badge-success text-uppercase mt-1"
          >
            Active
          </div>
        </>
      ) : (
        <>
          <div className="badge badge-pill badge-secondary text-uppercase mt-1">
            Not Active
          </div>
        </>
      )}
    </>
  );
};
