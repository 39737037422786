import React from "react";
import { Form } from "react-bootstrap";
import { Label, RequiredAsterisk, LoadingDiv } from "../../components/Base";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import axios from "axios";

const instance = axios.create({});
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => Promise.reject(err),
);

export default class OrderTimeDropDown extends APIComponent {
  constructor(props) {
    super(props);

    const { store = {} } = this.props;

    this.api = core("pu").get();
    this.data_url =
      store && store.hasOwnProperty("_id")
        ? `/branded-website/stores/${store._id}/date-options`
        : "";
  }

  async getData() {
    if (this.data_url !== "") {
      this._isMounted && this.setState({ data_status: "fetching" });

      const baseURL = this.api.getBaseURL(),
        {
          brand_url = "",
          store = {},
          order_date = "",
          isMealPlan = false,
        } = this.props;

      if (order_date !== "") {
        instance
          .get(
            `/branded-website/stores/${
              store._id
            }/time-options?order_date=${order_date}${
              isMealPlan ? "&isMealPlan=true" : ""
            }`,
            {
              headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
              baseURL,
            },
          )
          .then(({ data }) => {
            this._isMounted &&
              this.setState({
                time_options: data,
                data_status: "fetched",
              });
            this.handleOnDataFetched();

            if (this.use_display_data) {
              this.setDisplayData();
            }
          })
          .catch((error) => {
            const { response = {} } = error,
              { data = {} } = response,
              { message = {} } = data;
            if (
              message.hasOwnProperty("error") &&
              message.error === "StoreNotOpenForOrdersError"
            ) {
              this.props.onStoreNotOpenForOrdersError &&
                this.props.onStoreNotOpenForOrdersError();
            } else {
              this._isMounted &&
                this.setState({ error: error, showSwalError: true });
              this.handleOnDataFetchError();
            }
          });
      } else {
        this._isMounted &&
          this.setState({
            time_options: [],
            data_status: "fetched",
          });
      }
    }
  }

  handleOnDataFetched = () => {
    this.props.afterFetch && this.props.afterFetch(this.state.time_options);
  };

  render() {
    const {
        inputActions,
        hasError = false,
        error = "",
        order_type = "",
        value = "",
        prepend_label = "",
        no_asap = false,
      } = this.props,
      { time_options = [], data_status = "" } = this.state;
    const isLoading = data_status !== "fetched";

    return (
      <>
        {isLoading ? (
          <>
            <Form.Group className="w-100 mx-1">
              <Label
                text={
                  <>
                    <span className="text-capitalize">
                      {prepend_label}
                      {order_type === "delivery" ? "dispatch" : "pickup"} time
                    </span>{" "}
                    <RequiredAsterisk />{" "}
                  </>
                }
              />
              <Form.Control
                as="select"
                value={value}
                {...inputActions}
                name="order_time"
                disabled={true}
              >
                <option value="">
                  {" "}
                  Select {order_type === "delivery"
                    ? "dispatch"
                    : "pickup"}{" "}
                  time
                </option>
              </Form.Control>
              <div className="fv-plugins-message-container">
                {hasError ? (
                  <div className="fv-help-block">{error}</div>
                ) : (
                  <></>
                )}
              </div>
            </Form.Group>
          </>
        ) : (
          <Form.Group className="w-100 mx-1">
            <Label
              text={
                <>
                  <span className="text-capitalize">
                    {prepend_label}
                    {order_type === "delivery" ? "dispatch" : "pickup"} time
                  </span>{" "}
                  <RequiredAsterisk />{" "}
                </>
              }
            />
            <Form.Control
              as="select"
              value={value}
              {...inputActions}
              name="order_time"
            >
              <option value="">
                {" "}
                Select {order_type === "delivery" ? "dispatch" : "pickup"} time
              </option>
              <DisplayTimeOptions
                time_options={time_options}
                no_asap={no_asap}
              />
            </Form.Control>
            <div className="fv-plugins-message-container">
              {hasError ? <div className="fv-help-block">{error}</div> : <></>}
            </div>
          </Form.Group>
        )}
      </>
    );
  }
}

function DisplayTimeOptions({ time_options = [], no_asap = false }) {
  return time_options.map((date, index) => {
    return no_asap ? (
      date.text === "ASAP" ? (
        <></>
      ) : (
        <option value={date.value} key={index}>
          {date.text}
        </option>
      )
    ) : date.text !== "ASAP" ? (
      <></>
    ) : (
      <option value={date.value} key={index}>
        {date.text}
      </option>
    );
  });
}
