import React, { createRef } from "react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import {
  HeaderTextWithActions,
  AnimateDiv,
  SVGIcon,
} from "../../components/Base";
import Breadcrumbs from "../../components/Breadcrumbs";

import { GetDataFromEvent } from "../../helpers";
import { Card, Row, Col, Button } from "react-bootstrap";

import { isAccessAllowed } from "../../auth";
import PacmanLoading from "../../components/widgets/PacmanLoading/PacmanLoading";
import ReservationCard from "../../partials/ReservationCard";
import moment from "moment";
import {
  DB_DATE_FORMAT,
  PRIMARY_COLOR,
  TIME_OPTION_DISPLAY_FORMAT,
} from "../../constants";
import ReservationActionModal from "../../partials/ReservationActionModal";
import CancelNotesModal from "../../partials/CancelNotesModal";
import DatePicker from "react-datepicker";
import { debounce, isEmpty } from "lodash";
import FullCalendarDiv from "../../partials/FullCalendarDiv";

const MySwal = withReactContent(Swal);

export const RESERVATIONS_TAB_LIST = {
  RESERVATIONS_QUEUE: "reservations_queue",
  UPCOMING: "upcoming",
  COMPLETED: "completed",
};

function getListClass(name = "", listSelected = "", data_status = "") {
  return name === listSelected
    ? "text-uppercase font-weight-bolder font-pickup-md col-padding primary-border-bottom"
    : `text-uppercase font-weight-bolder font-pickup-md col-padding font-hover-primary ${
        data_status === RESERVATIONS_TAB_LIST.RESERVATIONS_QUEUE
          ? "cursor-pointer"
          : ""
      }`;
}

export default function Page() {
  const params = useParams(),
    { user } = useSelector((state) => state.auth),
    { brand_url = "", store_id = "" } = params;

  return isAccessAllowed(user, { brand_url, store_id }) ? (
    <HeaderComponent user={user} id={store_id} {...params} />
  ) : (
    <Redirect to={"/error/error-v3"} />
  );
}

class HeaderComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      store_name: "",
    };

    this.data_url = "/store/" + this.props.id + "/header-details";
    this.api = core("pu").get();
  }

  handleOnDataFetched = () => {
    const { data = {} } = this.state,
      { name = "", brand_name = "" } = data;
    this.setState({
      store_name: name,
      title: `${name} ≫ Events`,
      brand_name,
    });
  };

  setStoreName = (store_name = "") => {
    this.setState({ store_name, title: `${store_name} ≫ Events` });
  };

  async componentDidMount() {
    this._isMounted = true;
    await this.getData();
  }

  render() {
    const { data_status = "", data = {} } = this.state;
    return (
      <>
        {data_status !== "fetched" ? (
          <></>
        ) : (
          <>
            <HeaderTextWithActions
              className="w-100 justify-content-between"
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item text={this.state.brand_name} link={`/`} />
                  {this.props.user.iam_role !== "super_admin" && (
                    <Breadcrumbs.Item text="Events" link="/events" />
                  )}
                  <Breadcrumbs.Item
                    text={this.state.store_name}
                    link="#!"
                    active={"true"}
                  />
                </Breadcrumbs>
              }
              // title={"Orders"}
              title={
                <>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex flex-row align-items-center justify-content-between w-100 mb-2">
                      Events
                    </div>
                    {this.props.user.iam_role !== "console_user" &&
                    data.apiclient_names.length !== 0 ? (
                      <>
                        <div>
                          <h6 className="float-right mt-3 text-nowrap">
                            <Link
                              className="text-muted font-weight-bolder"
                              to={`/stores/${this.state.data._id}/settings/experiences`}
                            >
                              <SVGIcon
                                icon="General/Settings-2"
                                title="Settings"
                                variant="gray"
                              />{" "}
                              Experiences Settings
                            </Link>
                          </h6>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              }
              subtitle={<></>}
            />

            <TitleHelmet title={this.state.title} />
            <MainComponent
              {...this.props}
              api={this.api}
              setStoreName={this.setStoreName}
              store={data}
            />
          </>
        )}
      </>
    );
  }
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);
    const store_id = this.props.id;
    const selected_date = moment(new Date()).format(DB_DATE_FORMAT);
    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Reservations",
      listSelected: RESERVATIONS_TAB_LIST.RESERVATIONS_QUEUE,
      store_id,
      order_fetch_status: "fetching",
      list: {},
      data_status: "fetching",
      isDisabled: true,
      filteredArray: [],
      showActionModal: false,
      clickAction: undefined,
      selected: null,
      showCancelModal: false,
      display_buttons: props.user.display_buttons,
      isSubmitting: false,
      searchQuery: "",
      selected_date,
      tabCount: [0, 0, 0],
      isCalendarView: false, // list default view
      listView: [],
    };

    this.api = this.props.api;
  }

  async getData() {
    try {
      this._isMounted &&
        this.setState({
          data_status: "fetching",
          general_data_status: "fetching",
        });

      let { store_id = "", selected_date = "", listSelected } = this.state;
      this.api &&
        this.api
          .get(
            `/reservation/event/store/${store_id}?selector=${listSelected}${
              listSelected === "completed" ? `&date=${selected_date}` : ""
            }`,
          )
          .then(({ data }) => {
            this.setState({
              filteredArray: data[this.state.listSelected],
              data,
              data_status: "fetched",
              tabCount: [
                data[RESERVATIONS_TAB_LIST.RESERVATIONS_QUEUE].length,
                data[RESERVATIONS_TAB_LIST.UPCOMING].length,
                data[RESERVATIONS_TAB_LIST.COMPLETED],
              ],
              ...(selected_date !== "" && {
                listView: data[this.state.listSelected].filter(
                  (event) =>
                    event.reservation_date === selected_date &&
                    event.reservation_status !== "canceled",
                ),
              }),
            });
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });

      this.handleOnDataFetched();
    } catch (error) {
      this._isMounted && this.setState({ error: error, showSwalError: true });
      this.handleOnDataFetchError();
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.getData();
  }

  onReservationTabClick = async (e) => {
    if (this.state.data_status === "fetched") {
      const dateNow = moment(new Date()).format(DB_DATE_FORMAT);
      const item = GetDataFromEvent(e, "data-item");
      await this.setState({
        isCalendarView: false,
        listSelected: item,
        selected_date: dateNow,
        listView: this.state.filteredArray.filter(
          (event) => event.reservation_date === dateNow,
        ),
      });
      this.getData();
    }
  };

  findItemById = (item_id) => {
    const { filteredArray, data_status } = this.state;
    if (data_status === "fetched") {
      const item = filteredArray.filter((d) => d._id === item_id);
      if (item.length > 0) return item[0];
      else return {};
    } else {
      return {};
    }
  };

  handleOnActionClick = (e) => {
    const id = GetDataFromEvent(e, "data-id"),
      value = GetDataFromEvent(e, "data-value");

    if (value === "canceled") {
      this.setState({
        clickAction: value,
        selected: this.findItemById(id),
        showCancelModal: true,
        ...(this.state.isCalendarView && { showActionModal: false }),
      });
    } else if (value === "no-show") {
      const reservation = this.findItemById(id);
      this.handleOnNoShowClick(reservation);
    } else {
      this.setState({
        clickAction: value,
        selected: this.findItemById(id),
        showActionModal: true,
      });
    }
  };

  handleOnNoShowClick = async (reservation) => {
    const result = await MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to mark this reservation as no show.`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
    });
    if (result.isConfirmed === true) {
      this.handleOnSubmit("complete", "Customer no show", reservation);
    }
  };

  handleOnCancelSubmit = (reservation) => {
    this.setState({
      isSubmitting: true,
    });
    try {
      const { refund_notes = "", refunded_amount = 0 } = reservation;
      this.api &&
        this.api
          .put({
            url: "/reservation/" + reservation._id + "/cancel",
            data: {
              reservation_status: "canceled",
              refund_notes,
              refunded_amount,
            },
          })
          .then(({ data }) => {
            if (data.result === "ok") {
              this.setState({
                isSubmitting: false,
                showCancelModal: false,
                clickAction: undefined,
                selected: null,
              });
              MySwal.fire({
                icon: "success",
                title: "Success!",
                text: "You have successfully cancelled this reservation",
                confirmButtonColor: PRIMARY_COLOR,
                confirmButtonText: "Ok",
              });
              this.getData();
            }
          });
    } catch (err) {
      console.log(err);
      this.handleError(err);
    }
  };

  handleOnSubmit = (action, value, reservation) => {
    this.setState({
      isSubmitting: true,
    });
    const actionUrl = action === "accepted" ? "accept" : "complete";
    try {
      this.api &&
        this.api
          .put({
            url: `/reservation/${reservation._id}/${actionUrl}`,
            data: {
              reservation_status: action,
              notes: value,
              ...(action === "complete" && {
                isNoShow: action !== "completed",
              }),
            },
          })
          .then(({ data }) => {
            if (data.result === "ok") {
              this.setState({
                isSubmitting: false,
                showActionModal: false,
                clickAction: undefined,
                selected: null,
              });
              MySwal.fire({
                icon: "success",
                title: "Success!",
                text: "You have successfully updated this reservation",
                confirmButtonColor: PRIMARY_COLOR,
                confirmButtonText: "Ok",
              });
              this.getData();
            }
          });
    } catch (err) {
      console.log(err);
      this.handleError(err);
    }
  };

  onClickOnChangeDate = async (date) => {
    const { filteredArray, listSelected } = this.state;
    const selected_date = moment(date).format(DB_DATE_FORMAT);
    if (listSelected === RESERVATIONS_TAB_LIST.COMPLETED) {
      await this.setState({
        selected_date: moment(date).format(DB_DATE_FORMAT),
        data_status: "fetching",
      });
      setTimeout(() => {
        this.getData();
      }, 50);
    } else {
      this.setState({
        selected_date: moment(date).format(DB_DATE_FORMAT),
        listView: filteredArray.filter(
          (event) =>
            event.reservation_date === selected_date &&
            event.reservation_status !== "canceled",
        ),
      });
    }
  };

  onUpcomingEventClick = (reservation_number) => {
    const { filteredArray } = this.state;
    const selectedEvent = (filteredArray || []).filter(
      (item) => item.reservation_number === reservation_number,
    )[0];
    this.setState({
      showActionModal: true,
      selected: selectedEvent,
      clickAction:
        selectedEvent.reservation_status === "new" ? "accepted" : null,
    });
  };

  onReservationListClick = (date) => {
    const { filteredArray } = this.state;
    const selected_date = moment(date).format(DB_DATE_FORMAT);
    this.setState({
      isCalendarView: false,
      selected_date,
      listView: filteredArray.filter(
        (event) =>
          event.reservation_date === selected_date &&
          event.reservation_status !== "canceled",
      ),
    });
  };

  onCalendarViewClick = (date) => {
    this.setState({
      isCalendarView: true,
      selected_date: "",
    });
  };

  render() {
    const {
        data_status = "fetched",
        listSelected = RESERVATIONS_TAB_LIST.RESERVATIONS_QUEUE,
        filteredArray,
        showActionModal = false,
        selected,
        clickAction,
        showCancelModal,
        display_buttons,
        isSubmitting,
        searchQuery = "",
        selected_date = null,
        tabCount = [0, 0, 0],
        isCalendarView,
        listView = [],
      } = this.state,
      isLoading = data_status !== "fetched";

    // added debounce to wait for user to finish input search value
    const handleSearchInputChange = debounce((value) => {
      this.setState({
        searchQuery: value,
      });
    }, 500);
    this.searchInputRef = createRef();

    return (
      <>
        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}
        <Card>
          <Card.Body className="p-0">
            <Row>
              <Col xs={4}>
                <div
                  className={getListClass(
                    RESERVATIONS_TAB_LIST.RESERVATIONS_QUEUE,
                    listSelected,
                    data_status,
                  )}
                  onClick={this.onReservationTabClick}
                  data-item={RESERVATIONS_TAB_LIST.RESERVATIONS_QUEUE}
                  disabled={this.state.isDisabled}
                >
                  <span className="va-middle">
                    events <br /> queue
                  </span>
                  {tabCount[0] ? (
                    <span className="label label-danger ml-2 va-middle">
                      {tabCount[0]}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
              <Col xs={4}>
                <div
                  className={getListClass(
                    RESERVATIONS_TAB_LIST.UPCOMING,
                    listSelected,
                    data_status,
                  )}
                  onClick={this.onReservationTabClick}
                  data-item={RESERVATIONS_TAB_LIST.UPCOMING}
                  disabled={this.state.isDisabled}
                >
                  <br /> upcoming
                  {/* {tabCount[1] ? (
                    <span className="label label-purple ml-2 va-middle">
                      {tabCount[1]}
                    </span>
                  ) : (
                    <></>
                  )} */}
                </div>
              </Col>
              <Col xs={4}>
                <div
                  className={getListClass(
                    RESERVATIONS_TAB_LIST.COMPLETED,
                    listSelected,
                    data_status,
                  )}
                  onClick={this.onReservationTabClick}
                  data-item={RESERVATIONS_TAB_LIST.COMPLETED}
                  disabled={this.state.isDisabled}
                >
                  completed <br />
                  events
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {listSelected === "reservations_queue" && (
          <Card className="mt-4">
            <Card.Body className="p-5">
              <Row className="align-items-center">
                <Col xs={8} md={10} lg={10}>
                  <div style={{ marginRight: "5%" }}>
                    <div>
                      <input
                        ref={this.searchInputRef}
                        type={"search"}
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => {
                          handleSearchInputChange(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={4} md={2} lg={2}>
                  <div className={"d-flex justify-content-end"}>
                    <Button
                      variant={"primary"}
                      className={"font-weight-bolder text-uppercase"}
                      onClick={() => {
                        const value = this.searchInputRef.current.value;
                        if (!isEmpty(value)) {
                          handleSearchInputChange(value);
                        }
                      }}
                    >
                      Search
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}

        {listSelected !== RESERVATIONS_TAB_LIST.RESERVATIONS_QUEUE &&
          !isCalendarView && (
            <Card className="mt-4">
              <Card.Body className="p-5">
                <div
                  className="d-flex flex-row"
                  style={{
                    justifyContent:
                      listSelected === RESERVATIONS_TAB_LIST.UPCOMING
                        ? "space-between"
                        : "flex-end",
                  }}
                >
                  {listSelected === RESERVATIONS_TAB_LIST.UPCOMING && (
                    <Button
                      variant={"primary"}
                      className={"text-uppercase"}
                      onClick={this.onCalendarViewClick}
                    >
                      Calendar View
                    </Button>
                  )}
                  <DatePicker
                    selected={new Date(selected_date)}
                    className="form-control"
                    onChange={this.onClickOnChangeDate}
                  />
                </div>
              </Card.Body>
            </Card>
          )}

        {isLoading ? (
          <div className="h-100 w-100 d-flex flex-column align-items-center">
            <PacmanLoading />
          </div>
        ) : (
          <AnimateDiv>
            {data_status === "fetched" ? (
              <>
                {showActionModal && (
                  <ReservationActionModal
                    {...this.props}
                    listName={listSelected}
                    show={showActionModal}
                    clickAction={
                      (!isCalendarView &&
                        listSelected === RESERVATIONS_TAB_LIST.UPCOMING &&
                        selected.reservation_status !== "new") ||
                      listSelected === RESERVATIONS_TAB_LIST.COMPLETED
                        ? null
                        : clickAction
                    }
                    onHide={() => {
                      this.setState({
                        clickAction: undefined,
                        showActionModal: false,
                      });
                    }}
                    onSubmit={(action, value) => {
                      this.handleOnSubmit(action, value, selected);
                    }}
                    onCancel={this.handleOnActionClick}
                    reservation={selected}
                    isSubmitting={isSubmitting}
                    isCalendarView={isCalendarView}
                  />
                )}

                {showCancelModal && (
                  <CancelNotesModal
                    isReservation
                    show={showCancelModal}
                    onHide={() => {
                      this.setState({
                        clickAction: undefined,
                        showCancelModal: false,
                      });
                    }}
                    onSubmit={(values) => {
                      const { refund_notes = "", refunded_amount = 0 } = values;
                      this.handleOnCancelSubmit({
                        ...selected,
                        refund_notes,
                        refunded_amount,
                      });
                    }}
                    order={selected}
                    display_buttons={display_buttons}
                    action={"reject"}
                    max={selected.payment ? selected.payment.amount : 0}
                    btnLoading={isSubmitting}
                  />
                )}
                {isCalendarView &&
                listSelected === RESERVATIONS_TAB_LIST.UPCOMING ? (
                  <FullCalendarDiv
                    ref={this.calendarRef}
                    customButtonTitle="list view"
                    customButtonClick={this.onReservationListClick}
                    subTitle={
                      <>
                        <Col>
                          <Row className="mt-1 justify-content-center align-items-center">
                            Legend:
                          </Row>
                          <Row className="mt-1 justify-content-center align-items-center">
                            <div
                              className="circle-div mr-1"
                              style={{ backgroundColor: "#8750ef" }}
                            />
                            <div
                              className={"font-pickup-s text-uppercase mr-3"}
                            >
                              new
                            </div>
                            <div
                              className="circle-div mr-1"
                              style={{ backgroundColor: "#14C49B" }}
                            />
                            <div
                              className={"font-pickup-s text-uppercase mr-3"}
                            >
                              accepted
                            </div>
                            <div
                              className="circle-div mr-1"
                              style={{ backgroundColor: "#F64E60" }}
                            />
                            <div className={"font-pickup-s text-uppercase"}>
                              cancelled
                            </div>
                          </Row>
                        </Col>
                      </>
                    }
                    fixedWeekCount={false}
                    headerToolbar={false}
                    events={filteredArray.map((reservation) => ({
                      id: reservation.reservation_number,
                      title: ` ${moment(
                        reservation.reservation_time,
                        "HH:mm",
                      ).format(TIME_OPTION_DISPLAY_FORMAT)} - ${
                        reservation.store_reservation.reservation_name
                      }`,
                      date: reservation.reservation_date,
                      borderColor:
                        reservation.reservation_status === "new"
                          ? "#8750ef"
                          : reservation.reservation_status === "accepted"
                          ? "#14C49B"
                          : "#F64E60",
                      backgroundColor:
                        reservation.reservation_status === "new"
                          ? "#8750ef"
                          : reservation.reservation_status === "accepted"
                          ? "#14C49B"
                          : "#F64E60",
                    }))}
                    eventClick={({ event }) => {
                      const { id } = event;
                      if (!isEmpty(id)) {
                        this.onUpcomingEventClick(id);
                      }
                    }}
                    navLinkDayClick={this.onReservationListClick}
                    navLinks={true}
                    dayMaxEvents={4}
                    moreLinkText="See More"
                    height={"auto"}
                  />
                ) : !isCalendarView &&
                  (listSelected === RESERVATIONS_TAB_LIST.UPCOMING
                    ? listView
                    : filteredArray
                  ).length > 0 ? (
                  (listSelected === RESERVATIONS_TAB_LIST.UPCOMING
                    ? listView
                    : filteredArray
                  )
                    .filter(
                      ({
                        reservation_number = "",
                        guest_customer = {
                          first_name: "",
                          last_name: "",
                          contact_number: "",
                        },
                      }) =>
                        (guest_customer &&
                          guest_customer.first_name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())) ||
                        (guest_customer &&
                          guest_customer.last_name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())) ||
                        reservation_number
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                        (guest_customer &&
                          guest_customer.contact_number
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())),
                    )
                    .map((reservation, index) => {
                      return (
                        <ReservationCard
                          {...this.props}
                          reservation={reservation}
                          key={index}
                          listName={listSelected}
                          handleOnActionClick={this.handleOnActionClick}
                          showQuickLook={(e) => {
                            e.preventDefault();
                            this.setState({
                              showActionModal: true,
                              selected: reservation,
                            });
                          }}
                        />
                      );
                    })
                ) : (
                  <Card className="mv-1rem">
                    <Card.Body>You don't have any events.</Card.Body>
                  </Card>
                )}
              </>
            ) : (
              <div className="h-100 w-100 d-flex flex-column align-items-center">
                <PacmanLoading />
              </div>
            )}
          </AnimateDiv>
        )}
      </>
    );
  }
}
