import React from "react";

import PacmanLoading from "../../components/widgets/PacmanLoading/PacmanLoading";

const PacmanWrapper = ({ removeBackgroundColor = false }) => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100 w-100">
      <div
        style={{ backgroundColor: removeBackgroundColor ? "unset" : "white" }}
        className="card d-flex justify-content-center align-items-center h-100 w-100"
      >
        <PacmanLoading />
      </div>
    </div>
  );
};

export default PacmanWrapper;
