import React from "react";
import APIComponent from "../../components/API";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import core from "../../vendors/core-api";
import TitleHelmet from "../../partials/TitleHelmet";
import { Redirect } from "react-router-dom";

import qs from "qs";
import axios from "axios";

const instance = axios.create({});
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => Promise.reject(err),
);

export default function RetryPayment(props) {
  const { token = "" } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  return <MainComponent token={token} {...props} />;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    let { hostname = "", token = "" } = this.props;
    let brand_url = "";

    if (hostname === "localhost") hostname = process.env.REACT_APP_HOSTNAME;

    brand_url = hostname.split(".")[1] ? hostname.split(".")[0] : false;

    this.state = {
      brand_url,
      title: "Retrying payment method...",
      redirectTo: "",
      token,
    };

    this.api = core("pu").get();
  }

  async getData() {
    const { brand_url = "", token = "" } = this.state;

    const baseURL = this.api.getBaseURL();

    this._isMounted && this.setState({ data_status: "fetching" });
    instance
      .post(
        "/branded-website/payments/retry-generate-checkout-url",
        { token },
        {
          headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
          baseURL,
        },
      )
      .then((data) => {
        this._isMounted &&
          this.setState({
            renderModals: false,
            data: data.data,
            data_status: "fetched",
          });
        this.handleOnDataFetched();
      })
      .catch((error) => {
        this._isMounted && this.setState({ error: error, showSwalError: true });
        this.handleOnDataFetchError();
      });
  }

  handleOnDataFetched = () => {
    const { data = {} } = this.state,
      { result = "", checkout_url = "", redirect_error_url = "" } = data;
    if (result === "ok") {
      setTimeout(() => {
        window.location = checkout_url;
      }, 500);
    } else if (result === "error") {
      setTimeout(() => {
        window.location = redirect_error_url;
      }, 500);
    }
  };

  render() {
    return (
      <>
        <TitleHelmet title={this.state.title} showWebsiteName={false} />
        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}
        <LayoutSplashScreen />
      </>
    );
  }
}
