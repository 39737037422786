import React from "react";
import { DFlex } from "../../components/Base";

/**
 * Display order summary line.
 * @param {string} text
 * @param {string} amount
 * @param {string} textClassName
 * @returns
 */
const DisplayOrderSummaryLine = ({ text, amount, textClassName = "" }) => (
  <div className="d-flex flex-wrap align-items-center mv-1rem">
    <DFlex className="flex-column flex-grow-1">
      <span
        className={`text-uppercase font-weight-bolder${
          textClassName !== "" ? " " + textClassName : ""
        }`}
      >
        {text}
      </span>
    </DFlex>
    <DFlex className="align-items-center">
      <span
        className={`text-uppercase font-weight-bolder${
          textClassName !== "" ? " " + textClassName : ""
        }`}
      >
        {amount}
      </span>
    </DFlex>
  </div>
);

export default DisplayOrderSummaryLine;
