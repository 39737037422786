import React from "react";
import Picker from "emoji-picker-react";

export const ICONS = {
  lollipop: "🍭",
  birthdayCake: "🎂",
  shortcake: "🍰",
  candy: "🍬",
  chocolateBar: "🍫",
  cookie: "🍪",
  custard: "🍮",
  doughnut: "🍩",
  honeyPot: "🍯",
  iceCream: "🍨",
  shavedIce: "🍧",
  softIceCream: "🍦",
  pie: "🥧",
  cupcake: "🧁",
  greenApple: "🍏",
  banana: "🍌",
  cherries: "🍒",
  strawberry: "🍓",
  pear: "🍐",
  lemon: "🍋",
  peach: "🍑",
  redApple: "🍎",
  tangerine: "🍊",
  tomato: "🍅",
  watermelon: "🍉",
  pineapple: "🍍",
  coconut: "🥥",
  mango: "🥭",
  avocado: "🥑",
  chestnut: "🌰",
  carrot: "🥕",
  cucumber: "🥒",
  earOfCorn: "🌽",
  eggplant: "🍆",
  hotPepper: "🌶",
  mushroom: "🍄",
  peanuts: "🥜",
  potato: "🥔",
  broccoli: "🥦",
  leafyGreen: "🥬",
  garlic: "🧄",
  onion: "🧅",

  vegan: "🥗",
  dairyFree: "✔️",
  spicy: "🌶️",
  heartEyes: "😍",
  oneHundred: "💯",
  poo: "💩",
  rainbow: "🌈",
  fire: "🔥",
  star: "⭐",
  leaf: "🍀",
  cheese: "🧀",
  meatBone: "🍖",
  cutMeat: "🥩",
  pizza: "🍕",
  plate: "🍽️",
  spaghetti: "🍝",
  hamburger: "🍔",
  shrimp: "🍤",
  fries: "🍟",
  coffee: "☕",
  wineGlass: "🍷",
  bentoBox: "🍱",
  chickenLeg: "🍗",
  chopSticks: "🥢",
  cupWithStraw: "🥤",
  bomb: "💣",
  explodingHead: "🤯",
  coupleWithHeart: "💑",
  heavyExclamation: "❗",
  heart: "❤️",
  heartPulse: "💗",
  blueHeart: "💙",
  greenHeart: "💚",
  yellowHeart: "💛",
  orangeHeart: "🧡",
  purpleHeart: "💜",
  blackHeart: "🖤",
  giftHeart: "💝",
  halal: "حلال",
  lowCalorie: "🏋️‍♀️",
  lowCarbs: "🌿",
  highProtein: "💪",
  lowCholesterol: "❤️",
  keto: "🥑",
};

export default function EmojiPicker({ setEmoji }) {
  const onEmojiClick = (event, emojiObject) => {
    setEmoji(emojiObject.emoji);
  };

  return (
    <div>
      <Picker onEmojiClick={onEmojiClick} pickerStyle={{ width: "90%" }} />
    </div>
  );
}
