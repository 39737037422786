import React from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import { Row, Col } from "react-bootstrap";

import PacmanWrapper from "../dashboard/PacmanWrapper";
import { Bargraph } from "../../components/widgets/Bargraph";
import useFetch from "../../hooks/useFetch";
import {
  toPesoAmount,
  stackedBarGraphProps,
  capitalizeText,
} from "../../helpers";
import { DB_DATE_FORMAT } from "../../constants";

const Row3 = (props) => {
  const {
    statCardQuery = "day",
    params = {},
    selectedBrand = "",
    date = moment().format(DB_DATE_FORMAT),
    dropdownText,
  } = props;

  const reqObj = {
    url: "/dashboard/bargraph-sales",
    method: "post",
    body: params,
  };

  //fetched data formatter
  const dataFormatter = {
    dataFormatter: (data = [], args = []) => {
      const {
        series = [],
        categories = [],
        adjustments = [],
      } = stackedBarGraphProps(data, args);

      const adjustmentNames = adjustments.map((adjustment) =>
        capitalizeText(adjustment.name || ""),
      );
      const adjustmentData = adjustments.map(
        (adjustment) => adjustment.data || 0,
      );

      return { series, categories, adjustmentNames, adjustmentData };
    },
    args: [params.dateRange],
  };

  // Fetch data
  const { data, isLoading } = useFetch(reqObj, dataFormatter);

  if (isLoading || data === null) {
    return (
      <div className="h-25 my-10">
        <PacmanWrapper />
      </div>
    );
  }

  const arrangStoreData = (data) => {
    const miscData = [];
    const last3Data = ["Delivery Fee", "Refunded Amount", "Mealplan Discount"];
    const topData = data.filter((data) => {
      if (last3Data.includes(data.name)) {
        const index = last3Data.indexOf(data.name);
        miscData.splice(index, 0, data);
      } else return data;
    });
    return [...topData, ...miscData];
  };

  // get top 10 data from brand/store, show sum of values from top 11 onwards in others/misc key
  const collateTopTenOthersData = (
    names,
    data,
    dataPointIndex,
    isBrand,
    colors,
  ) => {
    const newSortedList = names
      .map((item, i) => ({
        name: item,
        value: data[i][dataPointIndex],
        color: colors[i],
      }))
      .sort((a, b) => b.value - a.value);

    const retVal = isBrand ? newSortedList : arrangStoreData(newSortedList);
    if (newSortedList.length <= 10) {
      return retVal;
    } else {
      const storeMiscData = [
        "Delivery Fee",
        "Refunded Amount",
        "Mealplan Discount",
      ];
      const startZeroIndex = retVal
        .filter((data) => !storeMiscData.includes(data.name))
        .findIndex((data) => data.value === 0);
      const topTenData = retVal.slice(
        0,
        startZeroIndex >= 0 && startZeroIndex < 9 ? startZeroIndex : 10,
      );
      const othersData = retVal.slice(
        startZeroIndex >= 0 && startZeroIndex < 9 ? startZeroIndex : 10,
        retVal.length - 3,
      );
      const othersList = {
        name: isBrand ? "Others" : "Miscellaneous",
        value: othersData.reduce((prev, curr) => prev + curr.value, 0),
        color: "grey",
      };
      const brandData = isBrand
        ? topTenData
        : [...topTenData, ...retVal.slice(-3)];
      return [...brandData, othersList];
    }
  };

  return (
    <>
      <Row className="my-10">
        <Col>
          <Bargraph
            className={"bar-graph-of-sales"}
            series={data.series}
            categories={data.categories}
            header={`Sales for ${dropdownText(statCardQuery, date)} by ${
              selectedBrand.id ? "Store" : "Brand"
            }`}
            customOptions={{
              dataLabels: {
                enabled: false,
              },
              tooltip: {
                y: {
                  formatter: function (
                    value,
                    { series, seriesIndex, dataPointIndex, w },
                  ) {
                    return toPesoAmount(value);
                  },
                },
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                  const { seriesNames } = w.globals;
                  const tooltipLists = selectedBrand.id
                    ? [...seriesNames, ...data.adjustmentNames]
                    : seriesNames;
                  const toolTipData = selectedBrand.id
                    ? [...series, ...data.adjustmentData]
                    : series;
                  const newTopTenData = collateTopTenOthersData(
                    tooltipLists,
                    toolTipData,
                    dataPointIndex,
                    isEmpty(selectedBrand.id),
                    w.globals.colors,
                  );
                  return `
                                        <div class="barContainer">
                                            <h5 class="barHeader">${
                                              w.globals.labels[dataPointIndex]
                                            }</h5>
    
                                                <div class="barContent">
                                                    ${newTopTenData.reduce(
                                                      (acc, cur, i) => {
                                                        return `${acc}  <div class="barSeriesContent">
                                                            <span class="barBadge" style="background-color: ${
                                                              cur.color
                                                            }"></span>
                                                            <span>${
                                                              cur.name
                                                            }</span>
                                                            <span class='barData'>
                                                                <span class= ${
                                                                  cur.value < 0
                                                                    ? "deduction"
                                                                    : ""
                                                                }>
                                                                    ${toPesoAmount(
                                                                      cur.value,
                                                                    )}
                                                                </span>
                                                            </span>
                                                        </div>`;
                                                      },
                                                      "",
                                                    )}

                                                <div class="barSeriesContent bold">
                                                    <span>Total</span>
                                                    <span class='barData'>${toPesoAmount(
                                                      toolTipData.reduce(
                                                        (acc, cur) =>
                                                          acc +
                                                          cur[dataPointIndex],
                                                        0,
                                                      ),
                                                    )}</span>
                                                </div>
                                            </div>
                                        </div>
                                    `;
                },
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Row3;
