import React from "react";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import AnalyticsGrid from "../../components/dashboard/AnalyticsGrid";
import { DB_DATE_FORMAT, DATERANGE_LIST } from "../../constants";
import GroupDropdown from "../../components/dashboard/GroupDropdown";
import useAnalyticsRedirect from "../../hooks/useAnalyticsRedirect";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";

const getIdsAndAttr = function (props, selectedView = {}) {
  const { role = "", brands = [], stores = [] } = props;
  const { id = "", attr = "brand" } = selectedView;

  if (role === "brand_admin") return { id: id || brands[0], attr };
  if (role === "store_admin") return { id: id || stores[0], attr: "store" };

  return { id: id || brands, attr: attr || "brand" };
};

export default function Page() {
  const { mode = "" } = useParams(),
    {
      user,
      user: { stores = [], brands = [], iam_role = "" },
    } = useSelector((state) => state.auth);

  useAnalyticsRedirect(user, mode);

  return (
    <MainComponent
      id={user.client_id}
      user={user}
      stores={stores}
      brands={brands}
      role={iam_role}
    />
  );
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Group Analytics",
      statCardQuery: "day",
      // selectedBrand: {id: '', name: 'All'},
      date: moment().format(DB_DATE_FORMAT),
    };

    this.api = core("pu").get();
    this.data_url = `/analytics`;

    this.handleDateRangeClick.bind(this);
  }

  handleDateRangeClick = async (e, date = moment().format(DB_DATE_FORMAT)) => {
    let statCardDataRange = "";
    const dropdownText = e.currentTarget.textContent || "day";

    if (
      dropdownText === "This Week" ||
      dropdownText === "Last Week" ||
      dropdownText === "Next Week"
    ) {
      statCardDataRange = "week";
    } else if (dropdownText === "This Month" || dropdownText === "Last Month") {
      statCardDataRange = "month";
    } else if (dropdownText === "This Year" || dropdownText === "Last Year") {
      statCardDataRange = "year";
    } else if (dropdownText === "All-time") {
      statCardDataRange = "allTime";
    } else {
      statCardDataRange = "day";
    }

    this.setState({
      statCardQuery: statCardDataRange,
      date,
      data_status: "fetching",
    });
    setTimeout(() => {
      this.getData();
    }, 500);
  };

  handleChangeSelectedBrand = async (item) => {
    this.setState({
      selectedBrand: item,
      data_status: "fetching",
    });
    setTimeout(() => {
      this.getData();
    }, 500);
  };

  async getData() {
    this._isMounted && this.setState({ data_status: "fetching" });
    let date = this.state.date || moment().format(DB_DATE_FORMAT);
    let statCardRange = this.state.statCardQuery || "day";
    // let view = this.state.selectedBrand|| {id: '', name: 'All', attr: ''};
    let view = this.state.selectedBrand || getIdsAndAttr(this.props);

    const getContent = () => {
      const { role = "", brands = [], stores = [] } = this.props;
      const { id = "", attr = "" } = getIdsAndAttr(this.props, view);

      return new Promise((resolve, reject) => {
        // this.api.get(`/analytics?dateRange=${statCardRange}&date=${date}&view=${view.id}`)
        // this.api.get(`/group/${this.props.id}?dateRange=${statCardRange}&date=${date}&view=${view.id}&attr=${view.attr}`)
        this.api
          .post({
            url: "/dashboard/analytics",
            data: {
              role: role,
              brands: role === "store_admin" ? stores : brands,
              id: id || view.id,
              attr: attr || view.attr,
              date,
              dateRange: statCardRange,
            },
          })
          .then((data) => resolve(data.data))
          .catch((err) => reject(err));
      });
    };

    try {
      // const hasRollup = this.props.role === 'group_admin';
      const { role = "" } = this.props;
      const hasRollup = ["group_admin", "accounting"].includes(role);
      const defaultAttr = this.props.role === "store_admin" ? "store" : "brand";
      const content = await getContent();

      const defaultSelectedBrand = !hasRollup
        ? {
            // ...header[0],
            ...content.allBrands[0],
            attr: defaultAttr,
            id: ["store_admin", "console_user"].includes(this.props.role)
              ? this.props.stores[0]
              : "",
          }
        : {
            name: "All",
            id: "",
            attr: defaultAttr,
          };

      this._isMounted &&
        this.setState({
          data: {
            ...content,
          },
          data_status: "fetched",
          order_fetch_status: "fetched",
          // selectedBrand: this.state.selectedBrand|| {...getIdsAndAttr(this.props, view)},
          // selectedBrand: this.state.selectedBrand|| {...content.allBrands[0]},
          selectedBrand: this.state.selectedBrand || defaultSelectedBrand,
        });

      this.handleOnDataFetched();
      if (this.use_display_data) {
        this.setDisplayData();
      }
    } catch (error) {
      this._isMounted && this.setState({ error: error, showSwalError: true });
      this.handleOnDataFetchError();
    }
  }

  render() {
    const { role = "", brands = [] } = this.props;
    const hasRollup = ["group_admin", "accounting"].includes(role);

    const {
        data = {},
        data_status = "",
        selectedBrand = {},
        statCardQuery = "day",
        date = moment().format(DB_DATE_FORMAT),
      } = this.state,
      isLoading = data_status !== "fetched";
    const { allBrands = [] } = data;

    const allIds = allBrands.map(({ id }) => id);

    function dropdownText(dateRange, date) {
      const isCurrent = moment(moment().format(DB_DATE_FORMAT)).isSame(
        date,
        dateRange,
      );

      const prefix = isCurrent ? "This" : "Last";

      // if(dateRange=== 'week') return `${prefix} Week`
      if (dateRange === "week") {
        if (
          moment(moment().add(1, "weeks").format(DB_DATE_FORMAT)).isSame(date)
        ) {
          return "Next Week";
        }

        return `${prefix} Week`;
      }
      if (dateRange === "month") return `${prefix} Month`;
      if (dateRange === "year") return `${prefix} Year`;
      if (dateRange === "allTime") return "All-time";
      return `${isCurrent ? "Today" : "Yesterday"}`;
    }

    const params = {
      date,
      brands,
      role,
      dateRange: statCardQuery,
      id: selectedBrand.id || "",
      attr: selectedBrand.attr || "brand",
    };

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          <div className="">
            <div className="d-flex justify-content-end w-100 mb-4">
              <GroupDropdown
                items={allBrands}
                onSelectView={this.handleChangeSelectedBrand}
                selectedView={selectedBrand}
                hasRollup={hasRollup}
                attr={role === "store_admin" ? "store" : "brand"}
              />

              <Dropdown className="align-self-end ml-4">
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  {dropdownText(statCardQuery, date)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {DATERANGE_LIST.map((item) => (
                    <Dropdown.Item
                      onClick={(e) => this.handleDateRangeClick(e, item.date)}
                      key={item.name}
                    >
                      {item.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <AnalyticsGrid
              // itemSoldURL = {`/group/${this.props.id}/item-sold?date=${date}&dateRange=${statCardQuery}&id=${selectedBrand.id}&attr=${selectedBrand.attr}`}
              itemSoldURL={`/dashboard/analytics/item-sold?date=${date}&dateRange=${statCardQuery}&id=${JSON.stringify(
                selectedBrand.id || allIds,
              )}&attr=${selectedBrand.attr || "brand"}`}
              selectedBrand={selectedBrand}
              data={data}
              statCardQuery={statCardQuery}
              date={date}
              params={params}
            />
          </div>
        )}
      </>
    );
  }
}
