import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { FormItem } from "../../components/Base";
import { Button, Card, Form } from "react-bootstrap";
import { GetSubmitClassList } from "../../helpers";
import ht from "../../helpertexts";

export default class ReservationTermsForm extends ProSourceForm {
  constructor(props) {
    super(props);

    const { store = {} } = this.props;

    this.state = {
      values: {
        ...store,
        reservation_terms:
          store.reservation_terms !== undefined &&
          store.reservation_terms !== ""
            ? store.reservation_terms
            : ht.reservations.reservation_terms,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {};
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmitReservationTermsForm(values, setSubmitting);
  };

  onChange = (e) => {
    let name = e.target.name,
      value = e.currentTarget.value;
    let { values = {} } = this.state;

    if (name === "reservation_terms") {
      values.reservation_terms = value;
      this.setState({ values });
    }
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <Card className="card-custom gutter-b">
        <Form onSubmit={this.handleOnSubmit}>
          <Card.Body>
            <FormItem
              label={"Terms and Conditions"}
              name={"reservation_terms"}
              inputProps={{
                name: "reservation_terms",
                placeholder: "Terms and Conditions",
                value: this.state.values["reservation_terms"],
                rows: 5,
              }}
              inputActions={{
                ...inputActions,
                onChange: this.onChange,
              }}
              actions={actions}
              type={"textarea"}
              normal
            />
            <Button
              variant="primary"
              type="submit"
              className={GetSubmitClassList(this.state.isSubmitting, "")}
            >
              Save Changes
            </Button>
          </Card.Body>
        </Form>
      </Card>
    );
  }
}
