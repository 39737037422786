import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const LoadingSkeleton = ({ height = 300 }) => {
  return (
    <SkeletonTheme>
      <div>
        <Skeleton height={height} />
      </div>
    </SkeletonTheme>
  );
};

export default LoadingSkeleton;
