import React from "react";
import "./styles.css";

export default function DemoBrand({ data }) {
  const stores = data.stores;
  return (
    <div>
      <br />
      <h3 className="text-center text-gray">Our Brands</h3>
      {stores.map((store) =>
        store.store_url ? (
          <div>
            <div className="store-logo-div-image-container">
              <a
                href={`https://${data.brand_url}${process.env.REACT_APP_DOMAIN}/${store.store_url}`}
                target="_blank"
              >
                <img src={store.store_logo} className="store-logo-div-image" />
              </a>
            </div>
            <hr />
          </div>
        ) : null,
      )}
      <br />
    </div>
  );
}
