import React from "react";
import { Card } from "react-bootstrap";
import { AnimateDiv } from "../../components/Base";
import PageCenter from "../../components/PageCenter";
import TitleHelmet from "../../partials/TitleHelmet";

export default function NotFoundPage(props) {
  return <MainComponent {...props} />;
}

class MainComponent extends React.Component {
  // handleOnDataFetched2 = () => {
  //     let { data: { brand_logo = "" } } = this.state;
  //     this.setState({ brand_logo, data_status: "fetched" });
  //     console.log("not found")
  // }

  render() {
    // const { data_status = "" } = this.state, isLoading = data_status !== "fetched";
    return (
      <>
        <TitleHelmet title={"Not Found"} />
        {
          <PageCenter
            loginstyle={{
              background: `linear-gradient(302deg, rgba(243,246,249,1) 1%, rgba(20,196,155,1) 56%)`,
            }}
          >
            <AnimateDiv>
              <Card className="card-custom">
                <Card.Body style={{ width: "300px" }}>
                  <div className="d-flex flex-wrap align-items-center mb-6">
                    {/* <div className="symbol symbol-75 flex-shrink-0 mr-4">
                                            <div className="symbol-label" alt="brand logo" style={{ backgroundImage: `url(${data.brand_logo})`}}></div>
                                        </div> */}
                    <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                      <div className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">
                        Not found
                      </div>
                      <span className="text-dark-75 font-weight-bold">
                        There are no Pickup stores under this domain.
                      </span>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </AnimateDiv>
          </PageCenter>
        }
      </>
    );
  }
}
