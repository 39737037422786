import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
// import helpertexts from "../helpertexts";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import {
  HeaderTextWithActions,
  LoadingDiv,
  AnimateDiv,
} from "../../components/Base";
import Breadcrumbs from "../../components/Breadcrumbs";

import { GetDataFromEvent } from "../../helpers";
import { Card } from "react-bootstrap";
import {
  PRIMARY_COLOR,
  ASAP_DEFAULT_PREP_TIME_OPTION,
  DB_DATE_FORMAT,
} from "../../constants";
import AcceptOrderModal from "../../partials/AcceptOrderModal";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SelectionModal from "../../components/widgets/SelectionModal";
import DeliveryDetailsModal from "../../partials/DeliveryDetailsModal";
import CancelNotesModal from "../../partials/CancelNotesModal";

import moment from "moment";
import OrderCard from "../../partials/OrderCard";
import { isAccessAllowed } from "../../auth";
import DatePicker from "react-datepicker";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";

const MySwal = withReactContent(Swal);

export default function Page() {
  const params = useParams(),
    { user } = useSelector((state) => state.auth),
    { brand_url = "", store_id = "" } = params;
  return isAccessAllowed(user, { brand_url, store_id }) ? (
    <HeaderComponent user={user} id={store_id} {...params} />
  ) : (
    <Redirect to={"/error/error-v1"} />
  );
}

class HeaderComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      store_name: "",
    };

    this.data_url = "/store/" + this.props.id + "/header-details";
    this.api = core("pu").get();
  }

  handleOnDataFetched = () => {
    const { data = {} } = this.state,
      { name = "", brand_name = "" } = data;
    this.setState({ store_name: name, title: `${name} ≫ Orders`, brand_name });
  };

  // setStoreName = (store_name = "") => {
  //     this.setState({ store_name, title: `${store_name} ≫ Cancelled Orders` });
  // }

  render() {
    const { data_status = "" } = this.state;

    return (
      <>
        {data_status !== "fetched" ? (
          <></>
        ) : (
          <>
            <HeaderTextWithActions
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item
                    text={this.state.brand_name}
                    link={`/brands/${this.props.brand_url}`}
                  />
                  <Breadcrumbs.Item
                    text="Stores"
                    link={`/brands/${this.props.brand_url}/stores`}
                  />
                  <Breadcrumbs.Item
                    text={this.state.store_name}
                    link={`/brands/${this.props.brand_url}/stores/${this.props.id}`}
                  />
                  <Breadcrumbs.Item
                    text={"Orders"}
                    link={`/brands/${this.props.brand_url}/stores/${this.props.id}/orders`}
                  />
                  <Breadcrumbs.Item text="Cancelled Orders" active={"true"} />
                </Breadcrumbs>
              }
              title={"Cancelled Orders"}
              subtitle={<></>}
            />

            <TitleHelmet title={this.state.title} />
            <MainComponent {...this.props} api={this.api} />
          </>
        )}
      </>
    );
  }
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    const selected_date = moment(new Date()).format(DB_DATE_FORMAT);
    // const { store = {}} = this.props.user;
    const store_id = this.props.id;

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Cancelled Orders",
      mountActionModals: false,
      listSelected: "cancelled",
      selected_date,
      store_id,
      order_fetch_status: "fetching",
    };

    this.api = this.props.api;

    this.handleOnClickUpdateBtn = this.handleOnClickUpdateBtn.bind(this);
    this.execUpdateStatus = this.execUpdateStatus.bind(this);
    this.execAcceptASAPOrder = this.execAcceptASAPOrder.bind(this);
    this.handleOnError = this.handleOnError.bind(this);
    this.onClickReadyForPickupModal =
      this.onClickReadyForPickupModal.bind(this);
    this.onSubmitCancelOrder = this.onSubmitCancelOrder.bind(this);
    this.onSubmitOutForDelivery = this.onSubmitOutForDelivery.bind(this);
    this.onClickOrderListItem = this.onClickOrderListItem.bind(this);
    this.onClickOnChangeDate = this.onClickOnChangeDate.bind(this);
  }

  async getData() {
    this._isMounted && this.setState({ data_status: "fetching" });

    let { selected_date = "", store_id = "", listSelected = "" } = this.state;

    if (listSelected === "orders_queue") {
      // shift to today if selected list is orders_queue
      selected_date = moment(new Date()).format(DB_DATE_FORMAT);
    }

    this.api &&
      this.api
        .get(
          `/store/${store_id}/orders?selector=${listSelected}&date=${selected_date}`,
        )
        .then((data) => {
          this._isMounted &&
            this.setState({
              data: data.data,
              data_status: "fetched",
              order_fetch_status: "fetched",
            });
          this.handleOnDataFetched();

          if (this.use_display_data) {
            this.setDisplayData();
          }
        })
        .catch((error) => {
          this._isMounted &&
            this.setState({ error: error, showSwalError: true });
          this.handleOnDataFetchError();
        });
  }

  handleOnError = (err) => {
    console.log(err);
  };

  findItemById = (item_id) => {
    const { data, data_status } = this.state;

    if (data_status === "fetched") {
      const item = data.orders.filter((d) => d._id === item_id);
      if (item.length > 0) return item[0];
      else return {};
    } else {
      return {};
    }
  };

  handleOnClickUpdateBtn = (e) => {
    e.preventDefault();
    const value = GetDataFromEvent(e, "data-value"),
      id = GetDataFromEvent(e, "data-id");

    this.setState({ mountActionModals: false });

    setTimeout(() => {
      if (value === "accept") {
        this.setState({
          selected: this.findItemById(id),
          showAcceptAsapOrderModal: true,
          mountActionModals: true,
        });
      } else if (value === "reject") {
        this.setState({
          selected: this.findItemById(id),
          showNotesModal: true,
          mountActionModals: true,
        });
      } else if (value === "ready") {
        MySwal.fire({
          icon: "warning",
          title: "Are you sure?",
          text: `You are about to set this order as ready.`,
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Yes",
          showCancelButton: true,
          cancelButtonText: "No",
          // "reverseButtons": true
        }).then((result) => {
          if (result.value) {
            this.setState({ selected: this.findItemById(id) });
            this.execUpdateStatus("ready");
          }
        });
      } else if (
        value === "ready_pickup" ||
        value === "out_for_delivery_delivery" ||
        value === "ready_curbside_pickup" ||
        value === "ready_dine_in" ||
        value === "ready_third_party_pickup"
      ) {
        this.setState({
          selected: this.findItemById(id),
          showCompletionSelectionModal: true,
          mountActionModals: true,
        });
      } else if (value === "ready_delivery") {
        this.setState({
          selected: this.findItemById(id),
          showReadyForDeliveryModal: true,
          mountActionModals: true,
        });
      }
    }, 50);
  };

  execUpdateStatus = (status = "", notes = "", setSubmitting = () => {}) => {
    const { selected = {} } = this.state;

    this.api
      .put({
        url: "/orders/" + selected._id + "/status",
        data: { status, notes },
      })
      .then(() => {
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully saved your changes.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          timer: 1000,
        });

        this.getData();
      })
      // .then((response) => {
      //     console.log("perstorecancelledorders execupdatestatus response: ", response)
      //     if (response.data.isInsuffCredit === true) {
      //         MySwal.fire({
      //             icon: "warning",
      //             title: "Insufficient funds",
      //             text: "You have insufficient funds in your wallet. Please top up and rehail to proceed with delivery.",
      //             confirmButtonColor: PRIMARY_COLOR,
      //             confirmButtonText: "Ok",
      //         })
      //     } else {
      //         MySwal.fire({
      //             "icon" : "success",
      //             "title" : "Success!",
      //             "text" : "You have successfully saved your changes.",
      //             "confirmButtonColor": PRIMARY_COLOR,
      //             "confirmButtonText": "Ok",
      //             timer: 1000
      //         });

      //         this.getData();
      //     }
      // })
      .catch((err) => {
        this.handleOnError(err);
      })
      .finally(() => {
        setSubmitting(false);
        this.setState({
          showAcceptAsapOrderModal: false,
          showCompletionSelectionModal: false,
          selected: {},
        });
      });
  };

  execAcceptASAPOrder = (
    minutes = ASAP_DEFAULT_PREP_TIME_OPTION,
    setSubmitting,
  ) => {
    const { selected = {} } = this.state;

    this.api
      .put({
        url: "/orders/" + selected._id + "/status",
        data: { status: "accepted", is_asap: true, minutes },
      })
      .then(() => {
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully updated this order.",
          timer: 1000,
          showConfirmButton: false,
        });

        this.getData();
      })
      .catch((err) => {
        this.handleOnError(err);
      })
      .finally(() => {
        setSubmitting(false);
        this.setState({ showAcceptAsapOrderModal: false, selected: {} });
      });
  };

  onClickReadyForPickupModal = (status = "") => {
    MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to set this order as ${
        status === "cancelled_no_show" ? "no show" : "completed"
      }.`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      // "reverseButtons": true
    }).then((result) => {
      if (result.value) {
        this.execUpdateStatus(status);
      }
    });
  };

  onSubmitCancelOrder = ({ refund_type = "", notes = "" }) => {
    this.setState({ cancelBtnLoading: true });
    this.execUpdateStatus(refund_type, notes, (cancelBtnLoading) => {
      this.setState({ cancelBtnLoading, showNotesModal: false });
    });
  };

  onSubmitOutForDelivery = (values = {}) => {
    this.setState({ btnLoading: true });
    this.execUpdateStatus("completed", JSON.stringify(values), (btnLoading) => {
      this.setState({ btnLoading, showReadyForDeliveryModal: false });
    });
  };

  async onClickOrderListItem(e) {
    const item = GetDataFromEvent(e, "data-item");
    await this.setState({ listSelected: item, order_fetch_status: "fetching" });

    setTimeout(() => {
      this.getData();
    }, 1000);
  }

  async onClickOnChangeDate(date) {
    await this.setState({
      selected_date: moment(date).format(DB_DATE_FORMAT),
      order_fetch_status: "fetching",
    });
    setTimeout(() => {
      this.getData();
    }, 1000);
  }

  render() {
    const {
        data = "",
        data_status = "",
        showAcceptAsapOrderModal = false,
        selected = {},
        showCompletionSelectionModal = false,
        showReadyForDeliveryModal = false,
        showNotesModal = false,
        btnLoading = false,
        cancelBtnLoading = false,
        mountActionModals = false,
        order_fetch_status = "",
        selected_date = "",
      } = this.state,
      isLoading = data_status !== "fetched";

    const actions = {
      handleOnClickUpdateBtn: this.handleOnClickUpdateBtn,
    };

    return (
      <>
        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        {isLoading ? (
          <></>
        ) : (
          <AnimateDiv>
            {mountActionModals ? (
              <>
                <AcceptOrderModal
                  show={showAcceptAsapOrderModal}
                  onHide={() => {
                    this.setState({ showAcceptAsapOrderModal: false });
                  }}
                  onSubmit={(values, setSubmitting) => {
                    const { is_asap = false } = values;

                    if (is_asap)
                      this.execAcceptASAPOrder(values.minutes, setSubmitting);
                    else this.execUpdateStatus("accepted", "", setSubmitting);
                  }}
                  order={selected}
                />

                <SelectionModal
                  show={showCompletionSelectionModal}
                  onHide={() => {
                    this.setState({ showCompletionSelectionModal: false });
                  }}
                  title={<>What do you want to do?</>}
                  options={[
                    {
                      title: "completed",
                      icon: "Navigation/Check",
                      desc: "",
                      onClick: () => {
                        this.onClickReadyForPickupModal("completed");
                      },
                    },
                    {
                      title: "mark as no show",
                      icon: "Navigation/Close",
                      desc: "",
                      onClick: () => {
                        this.onClickReadyForPickupModal("cancelled_no_show");
                      },
                    },
                  ]}
                />

                <DeliveryDetailsModal
                  show={showReadyForDeliveryModal}
                  onHide={() => {
                    this.setState({ showReadyForDeliveryModal: false });
                  }}
                  btnLoading={btnLoading}
                  onSubmit={this.onSubmitOutForDelivery}
                />

                <CancelNotesModal
                  show={showNotesModal}
                  onHide={() => {
                    this.setState({ showNotesModal: false });
                  }}
                  onSubmit={this.onSubmitCancelOrder}
                  btnLoading={cancelBtnLoading}
                  order={selected}
                />
              </>
            ) : (
              <></>
            )}

            {order_fetch_status === "fetched" ? (
              <>
                <Card className="mt-4">
                  <Card.Body className="p-2">
                    <div className="d-flex flex-row justify-content-space-between">
                      <DatePicker
                        selected={new Date(selected_date)}
                        className="form-control"
                        onChange={this.onClickOnChangeDate}
                      />
                    </div>
                  </Card.Body>
                </Card>
                {data.orders.length > 0 ? (
                  data.orders.map((order, index) => {
                    return (
                      <OrderCard
                        order={order}
                        key={index}
                        actions={actions}
                        linkTo={`/brands/${this.props.brand_url}/orders/${order._id}`}
                      />
                    );
                  })
                ) : (
                  <Card className="mv-1rem">
                    <Card.Body>
                      You don't have any cancelled orders on this date.
                    </Card.Body>
                  </Card>
                )}
              </>
            ) : (
              <PacmanWrapper removeBackgroundColor />
            )}
          </AnimateDiv>
        )}
      </>
    );
  }
}
