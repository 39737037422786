import React from "react";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import { Block, HeaderTextWithActions } from "../../components/Base";
import { Card } from "react-bootstrap";

export default function HelpPage() {
  const { id = "" } = useParams(),
    { user } = useSelector((state) => state.auth);

  return <MainComponent id={id} user={user} />;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Welcome to Pickup Academy",
      searchKeyword: "",
      data: [],
      filteredTopics: [],
    };

    this.api = core("pu").get();
    this.data_url = "/help";
  }

  async componentDidMount() {
    await this.api.get(this.data_url).then(async (result) => {
      this.setState({ data: result.data });
    });
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.data !== this.state.data) {
      this.triggerSearch();
    }
  }

  search = (e) => {
    e.persist();
    this.setState({ searchKeyword: e.target.value }, () => {
      this.triggerSearch();
    });
  };

  // edit for new help topics data
  triggerSearch = () => {
    if (this.state.searchKeyword) {
      this.setState((prevState) => {
        const filteredTopics = prevState.data
          .filter((topic) => {
            let valid = false;
            if (
              topic.name
                .toLowerCase()
                .indexOf(this.state.searchKeyword.toLowerCase()) > -1
            ) {
              valid = true;
            }
            const validBlocks = topic.blocks.filter(
              (block) =>
                (block.hasOwnProperty("title")
                  ? block.title
                      .toLowerCase()
                      .indexOf(this.state.searchKeyword.toLowerCase()) > -1
                  : null) ||
                (block.hasOwnProperty("content")
                  ? block.content
                      .toLowerCase()
                      .indexOf(this.state.searchKeyword.toLowerCase()) > -1
                  : null),
            );
            const validTopicBlocks = topic.topicBlocks.filter(
              (block) =>
                (block.hasOwnProperty("title")
                  ? block.title
                      .toLowerCase()
                      .indexOf(this.state.searchKeyword.toLowerCase()) > -1
                  : null) ||
                (block.hasOwnProperty("content")
                  ? block.content
                      .toLowerCase()
                      .indexOf(this.state.searchKeyword.toLowerCase()) > -1
                  : null),
            );
            if (validBlocks.length > 0 || validTopicBlocks.length > 0) {
              valid = true;
            }
            return valid;
          })
          .map((topic) => {
            const validBlocks = topic.blocks
              .filter(
                (block) =>
                  (block.hasOwnProperty("title")
                    ? block.title
                        .toLowerCase()
                        .indexOf(this.state.searchKeyword.toLowerCase()) > -1
                    : null) ||
                  (block.hasOwnProperty("content")
                    ? block.content
                        .toLowerCase()
                        .indexOf(this.state.searchKeyword.toLowerCase()) > -1
                    : null),
              )
              .map((block) => {
                let newTitle = block.title.replace(
                  new RegExp(this.state.searchKeyword, "gi"),
                  (match) =>
                    `<mark style="background: #2769AA; color: white;">${match}</mark>`,
                );
                let newContent = block.content.replace(
                  new RegExp(this.state.searchKeyword, "gi"),
                  (match) =>
                    `<mark style="background: #2769AA; color: white;">${match}</mark>`,
                );
                return {
                  ...block,
                  title: newTitle,
                  content: newContent,
                };
              });
            const validTopicBlocks = topic.topicBlocks
              .filter(
                (block) =>
                  (block.hasOwnProperty("title")
                    ? block.title
                        .toLowerCase()
                        .indexOf(this.state.searchKeyword.toLowerCase()) > -1
                    : null) ||
                  (block.hasOwnProperty("content")
                    ? block.content
                        .toLowerCase()
                        .indexOf(this.state.searchKeyword.toLowerCase()) > -1
                    : null),
              )
              .map((block) => {
                let newTitle = block.title.replace(
                  new RegExp(this.state.searchKeyword, "gi"),
                  (match) =>
                    `<mark style="background: #2769AA; color: white;">${match}</mark>`,
                );
                let newContent = block.content.replace(
                  new RegExp(this.state.searchKeyword, "gi"),
                  (match) =>
                    `<mark style="background: #2769AA; color: white;">${match}</mark>`,
                );
                return {
                  ...block,
                  title: newTitle,
                  content: newContent,
                };
              });
            return {
              ...topic,
              blocks: validBlocks,
              topicBlocks: validTopicBlocks,
            };
          });
        return { filteredTopics };
      });
    } else {
      this.setState((prevState) => {
        const filteredTopics = [...prevState.data];
        return { filteredTopics };
      });
    }
  };

  render() {
    const data = [...this.state.filteredTopics];
    // console.log("filteredTopics", this.state.filteredTopics);
    return (
      <>
        <TitleHelmet title={this.state.title} />
        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}
        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <HeaderTextWithActions
          title={this.state.title}
          actions={
            <input
              type="search"
              placeholder="Search..."
              className="form-control"
              onChange={this.search}
              style={{ marginTop: "5px" }}
            />
          }
        />

        {Object.keys(data).length > 0 &&
          data.map((item) => {
            return (
              <Card className="card-custom mv-1rem" key={item.label}>
                <div className="card-header">
                  <div className="card-title">
                    <Link
                      to={`${this.props.webview ? "/webview" : ""}/help/${
                        item.label
                      }`}
                    >
                      <h3 className="card-label">
                        {item.name}{" "}
                        <span role="img" aria-label={item.label}>
                          {item.emoji}
                        </span>
                      </h3>
                    </Link>
                  </div>
                </div>
                <Card.Body>
                  {item.blocks.map((block) => {
                    return (
                      <>
                        {this.state.searchKeyword === "" && (
                          <Block
                            key={block.title}
                            title={
                              <Link
                                to={`${
                                  this.props.webview ? "/webview" : ""
                                }/help/${item.label}`}
                              >
                                <span className="mr-lg-8 mr-5 mb-lg-0 mb-2 text-uppercase text-primary text-nowrap">
                                  {block.title}
                                </span>
                              </Link>
                            }
                            content={
                              <>
                                <span className="text-capitalize">
                                  {block.content}
                                </span>
                              </>
                            }
                          />
                        )}
                        {this.state.searchKeyword !== "" && (
                          <Block
                            key={block.title}
                            title={
                              <Link
                                to={`${
                                  this.props.webview ? "/webview" : ""
                                }/help/${block.link}`}
                              >
                                <span className="mr-lg-8 mr-5 mb-lg-0 mb-2 text-uppercase text-primary text-nowrap">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: block.title,
                                    }}
                                  />
                                </span>
                              </Link>
                            }
                            content={
                              <>
                                <span className="text-capitalize">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: block.content,
                                    }}
                                  />
                                </span>
                              </>
                            }
                          />
                        )}
                      </>
                    );
                  })}
                  {this.state.searchKeyword !== "" && (
                    <div>
                      {item.topicBlocks.map((block2) => {
                        return (
                          <Block
                            key={block2.title}
                            title={
                              <Link
                                to={`${
                                  this.props.webview ? "/webview" : ""
                                }/help/${item.label}`}
                              >
                                <span className="font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2 text-uppercase text-primary text-nowrap">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: block2.title,
                                    }}
                                  />
                                </span>
                              </Link>
                            }
                            content={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: block2.content,
                                }}
                              />
                            }
                          />
                        );
                      })}
                    </div>
                  )}
                </Card.Body>
              </Card>
            );
          })}
      </>
    );
  }
}
