import React from "react";

export default function ItemTag({
  backgroundColor,
  textColor,
  borderColor,
  icon,
  name,
}) {
  return (
    <label
      style={{
        ...styles.tagBox,
        backgroundColor,
        color: textColor,
        border: `1px solid ${borderColor}`,
      }}
    >
      <span className="mr-1" style={styles.icon}>
        {icon}
      </span>
      <span className="ml-1">{name}</span>
    </label>
  );
}

const styles = {
  tagBox: {
    fontSize: "0.8rem",
    paddingLeft: "0.2rem",
    paddingRight: "0.4rem",
    cursor: "pointer",
    color: "white",
    borderRadius: "0.42rem",
    width: "auto",
    display: "inline-block",
  },
  icon: {
    fontSize: "1rem",
  },
};
