import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect, Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import helpertexts from "../../helpertexts";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import { HeaderTextWithActions } from "../../components/Base";
import Breadcrumbs from "../../components/Breadcrumbs";
import { formatOrderType, getStatusDisplay } from "../../helpers";
import DataTable from "react-data-table-component";
import moment from "moment";
import Activities from "../../partials/Activities";
import PromoCard from "../../components/widgets/PromoCard";
import { PRIMARY_COLOR, DB_DATE_FORMAT } from "../../constants";
import { fetchData } from "../../helpers";
import Swal from "sweetalert2";
import { debounce } from "lodash";
import withReactContent from "sweetalert2-react-content";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { light } from "@material-ui/core/styles/createPalette";
import PromoDash from "../../components/dashboard/Promos";

import DateRangeDropdown from "../../components/dashboard/DateRangeDropdown";
const MySwal = withReactContent(Swal);

export default function Page() {
  const { id = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  return <MainComponent id={id} user={user} />;
}
class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Promo Codes",
      group: "",
      tableData: {},
      tableDocs: [],
      paginationOptions: {},
      searchText: "",
      activePromoId: "",
      page: 1,
      limit: 10,
      statCardQuery: "allTime",
      statCardText: "All-time",
      queryDate: moment().format(DB_DATE_FORMAT),
      showPromoOrders: false,
      dashboard_data: [],
    };

    this.api = core("pu").get();
    this.data_url = `/promos/user?revenue=true`;
    this.dashboard_data_url = `/promos/user?revenue=true&stat_card=${this.state.statCardQuery}&query_date=${this.state.queryDate}`;
    this.onDateRangeSelect = this.onDateRangeSelect.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.fetchDashboardData = this.fetchDashboardData.bind(this);
  }

  fetchDashboardData = async () => {
    try {
      const { data } = await fetchData(
        "get",
        `/promos/user?revenue=true&stat_card=${this.state.statCardQuery}&query_date=${this.state.queryDate}`,
      );
      this.setState({ dashboard_data: data });
    } catch (err) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error on getting promos",
      });
    }
  };

  refreshData = async () => {
    const result = await fetchData(
      "get",
      `/promos/user?revenue=true&stat_card=${this.state.statCardQuery}&query_date=${this.state.queryDate}`,
    );
    const newLogs = await fetchData(
      `get`,
      `/activities/c/promos/${this.state.group}/1`,
    );

    this.setState({ data: result.data, updatedLogs: newLogs.data.docs });

    return result.data;
  };

  getPromoActiveOrders = async () => {
    try {
      const { page, limit, searchText, activePromoId } = this.state;
      const result = await this.api.get(
        `/promos/${activePromoId}/active_orders?page=${page}&limit=${limit}&search=${searchText}`,
      );
      return result.data;
    } catch (err) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error on getting orders",
      });
    }
  };

  fetchOrders = async () => {
    const tableData = await this.getPromoActiveOrders();
    this.setState({ tableData });
  };

  handleClickPromoOrders = async (promoId) => {
    this.setState(
      { showPromoOrders: true, activePromoId: promoId },
      this.fetchOrders,
    );
  };
  setSearchText = debounce(async () => {
    this.fetchOrders();
  }, 500);

  handlePageChange = async (page) => {
    this.setState({ page }, this.fetchOrders);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({ page, limit: newPerPage }, this.fetchOrders);
  };

  handleClickPromoCode = (promoId) => {
    this.setState({ redirectTo: `/promos/${promoId}` });
  };

  handleCopyPromoCode = (promoId) => {
    this.setState({ redirectTo: `/promos/${promoId}/copy` });
  };

  handleDeletePromoCode = async (id) => {
    const promoId = id.toString();
    const result = await MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to remove this promo.`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
    });
    if (result.isConfirmed === true) {
      const response = await fetchData("put", `promos/archive/${promoId}`);
      if (response.data.result === "ok") {
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully removed the promo.",
          confirmButtonColor: PRIMARY_COLOR,
          showConfirmButton: true,
        });
        const data = await this.refreshData();
      }
    }
  };

  handleOnDataFetched = () => {
    let { data = [] } = this.state;
    this.setState({
      data: data.map((item) => {
        item.id = item._id;
        return item;
      }),
      group: data.length ? data[0].group : "",
    });
  };

  componentDidUpdate() {
    // console.log("promolists componentDidUpdate state: ", this.state);
    // console.log("promolists componentDidUpdate props: ", this.props);
  }
  async componentDidMount() {
    this._isMounted = true;
    this.getData();
    this.fetchDashboardData();
  }
  async onDateRangeSelect(e, date, name, dateRange) {
    this.setState(
      {
        statCardQuery: dateRange,
        queryDate: date,
        statCardText: name,
      },
      async () => {
        await this.fetchDashboardData();
      },
    );
  }
  render() {
    const {
        data = [],
        data_status = "",
        showPromoOrders,
        dashboard_data,
      } = this.state,
      isLoading = data_status !== "fetched";

    const { user } = this.props;

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} push />
        ) : (
          <></>
        )}

        <div className="d-flex flex-row ">
          <HeaderTextWithActions title={this.state.title} />
        </div>
        <Card className="card-custom mt-2">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Promo List</h3>
            </div>
            <div className="card-toolbar">
              {user.iam_role.includes("console_user") ||
              user.iam_role.includes("accounting") ? (
                <></>
              ) : (
                <Button
                  className="text-nowrap"
                  variant="primary"
                  onClick={() => {
                    this.setState({ redirectTo: "/promos/new" });
                  }}
                >
                  {`Add New`}
                </Button>
              )}
            </div>
          </div>
          <Card.Body>
            <div
              style={{
                maxHeight: `60vh`,
                overflow: "auto",
              }}
            >
              <>
                {data.length ? (
                  <>
                    {data.map((item) => {
                      return (
                        <PromoCard
                          key={item._id}
                          promo_code={item.promo_code}
                          data={item}
                          handleClickPromoOrders={this.handleClickPromoOrders}
                          handleClickPromoCode={this.handleClickPromoCode}
                          handleDeletePromoCode={this.handleDeletePromoCode}
                          handleCopyPromoCode={this.handleCopyPromoCode}
                          user={this.props.user}
                        />
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className="text-center">No promo codes found.</div>
                  </>
                )}
              </>
            </div>
          </Card.Body>
        </Card>
        <Card className="card-custom mt-2">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Performance Dashboard</h3>
            </div>
            <div className="card-toolbar">
              <DateRangeDropdown
                statCardText={this.state.statCardText}
                onDateRangeSelect={this.onDateRangeSelect}
                queryDate={this.state.queryDate}
              />
            </div>
          </div>
          <Card.Body>
            <>
              {dashboard_data.length ? (
                <PromoDash
                  data={dashboard_data}
                  statCardText={this.state.statCardText}
                />
              ) : (
                <>
                  <div className="text-center">No promo codes found.</div>
                </>
              )}
            </>
          </Card.Body>
        </Card>

        <div style={{ marginTop: "20px" }}>
          <Card className="card-custom gutter-b">
            <Card.Header>
              <Card.Title>Activity Log</Card.Title>
            </Card.Header>
            <Card.Body>
              {this.state.group !== "" ? (
                <div
                  style={{
                    maxHeight: `calc(100vh)`,
                    overflow: "auto",
                  }}
                >
                  <Activities
                    collection="promos"
                    identifier={this.state.group}
                    api={this.api}
                    updatedLogs={this.state.updatedLogs}
                  />
                </div>
              ) : null}
            </Card.Body>
          </Card>
        </div>

        <Modal
          centered
          show={showPromoOrders}
          onHide={() => {
            this.setState({
              showPromoOrders: false,
              searchText: "",
              page: 1,
              limit: 10,
              tableData: {},
            });
          }}
        >
          <Modal.Header>
            <Modal.Title>Promo Orders</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DataTable
              columns={[
                {
                  name: "Order #",
                  selector: (row) => {
                    return row._id;
                  },
                  format: (row) => {
                    const { transaction_number } = row;

                    return (
                      <Link
                        to={`/orders/${row._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {transaction_number}
                      </Link>
                    );
                  },
                },
                {
                  name: "Type",
                  selector: (row) => {
                    return formatOrderType(row.order_type);
                  },
                },
                {
                  name: "Status",
                  selector: (row) => {
                    return row.status;
                  },
                },
                {
                  name: "Date",
                  selector: (row) => {
                    return row.order_date;
                  },
                },
              ]}
              data={this.state.tableData.docs}
              pagination
              paginationServer
              paginationTotalRows={this.state.tableData.totalDocs}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
              paginationPerPage={10}
              noHeader
              highlightOnHover
              subHeader
              subHeaderComponent={
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="flaticon-search"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Transaction Number"
                    value={this.state.searchText}
                    onChange={(e) => {
                      this.setState({ searchText: e.target.value });
                      this.setSearchText();
                    }}
                  />
                </div>
              }
              // progressPending={progressPending}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
