import React, { useState, useEffect } from "react";
import { Pagination } from "react-bootstrap";

const PaginationBar = (props) => {
  const [numbers, setNumbers] = useState([]);

  const {
    pagesArr = [],
    pageNum = 1,
    limit = 20,
    rows = 0,
    totalResults = 0,
    onSelectPage,
    pagerTitle = "Rows per page:",
  } = props;

  const getDisplayedPageNums = (nums = [], pageNum = 1) => {
    if (nums.length <= 10) return nums;

    if (pageNum >= nums.length - 5) {
      return nums.slice(-10);
    }

    if (pageNum >= 7 && nums.length > 11) {
      return nums.slice(pageNum - 6, pageNum + 4);
    }

    return nums.slice(0, 10);
  };

  useEffect(() => {
    const newNums = getDisplayedPageNums(pagesArr, pageNum);

    setNumbers(newNums);
  }, [pagesArr, pageNum]);

  return (
    <>
      <div>
        <span className="mr-2">
          {pagerTitle} {limit}
        </span>
        <span className="ml-4">
          {(pageNum - 1) * limit + 1} -{" "}
          {(pageNum - 1) * limit + rows < totalResults
            ? (pageNum - 1) * limit + rows
            : totalResults}{" "}
          of {totalResults}
        </span>
      </div>

      <Pagination className="d-flex justify-content-center mt-2">
        <Pagination.First
          onClick={() => onSelectPage(1, pagesArr)}
          disabled={pageNum === 1}
        />
        <Pagination.Prev
          onClick={() => onSelectPage(pageNum - 1, pagesArr)}
          disabled={pageNum === 1}
        />

        {numbers.map((page) => (
          <Pagination.Item
            key={page}
            active={page + 1 === pageNum}
            onClick={() => onSelectPage(page + 1, pagesArr)}
          >
            {page + 1}
          </Pagination.Item>
        ))}

        <Pagination.Next
          onClick={() => onSelectPage(pageNum + 1, pagesArr)}
          disabled={pageNum === pagesArr.length}
        />
        <Pagination.Last
          onClick={() => onSelectPage(pagesArr.length, pagesArr)}
          disabled={pageNum === pagesArr.length}
        />
      </Pagination>
    </>
  );
};

export default PaginationBar;
