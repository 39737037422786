import React, { useEffect } from "react";
import { HeaderText } from "../../../components/Base";
import StoreListItem from "../../StoreListItem";

/**
 * StoreInfo
 * @param {object} props.brand Brand object
 * @param {object} props.store Store object
 * @param {object} props.website_theme Website theme
 * @returns
 */
export default function StoreInfo(props) {
  return (
    <>
      <HeaderText
        className="menu-brand-name"
        style={{ wordBreak: "break-word" }}
        title={<>{props.brand.name}</>}
      />
      <StoreListItem
        store={props.store}
        store_hours_display="branded-website-today"
        custom_color={props.website_theme.icon_color}
        custom_link_color={props.website_theme.link_text_color}
      />
    </>
  );
}
