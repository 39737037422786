import React from "react";
import { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

import APIComponent from "../../components/API";
import { FormItem } from "../../components/Base";
import core from "../../vendors/core-api";

const ALLOWED_ROLES = [
  "store_admin",
  "console_user",
  "console_user_dispatch",
  "console_user_counter",
];

export default function DisplayStoresDropdown(props) {
  if (ALLOWED_ROLES.includes(props.iam_role)) {
    return <DisplayStoresDropwdownAPI {...props} />;
  } else {
    return <></>;
  }
}

function generatePreselectedStores({
  responseData = [],
  stores = [],
  showPreselected,
}) {
  if (showPreselected) {
    return responseData.filter(({ _id = "" }) => {
      return stores.includes(_id);
    });
  } else {
    return [];
  }
}

class DisplayStoresDropwdownAPI extends APIComponent {
  constructor(props) {
    super(props);

    this.api = core("pu").get();
    this.data_url = "/settings/get-store-options";
  }

  async getData() {
    if (this.data_url !== "") {
      const { user = {} } = this.props,
        { iam_role = "", stores = [] } = user,
        showPreselected = ALLOWED_ROLES.includes(iam_role),
        { iam_role: selected_iam_role } = this.props,
        multiple = selected_iam_role === "store_admin";

      this._isMounted && this.setState({ data_status: "fetching" });
      this.api &&
        this.api
          .get(this.data_url)
          .then(({ data }) => {
            const { data: responseData } = data,
              preselectedStores = generatePreselectedStores({
                showPreselected,
                responseData,
                stores,
              });

            this._isMounted &&
              this.setState({
                data: responseData,
                data_status: "fetched",
                preselectedStores,
                multiple,
              });
            this.handleOnDataFetched();

            if (this.use_display_data) {
              this.setDisplayData();
            }
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });
    } else {
      this.afterComponentDidMount();
    }
  }

  render() {
    const {
        data_status = "",
        data = [],
        preselectedStores = [],
        multiple = false,
      } = this.state,
      isLoading = data_status !== "fetched"; //, { iam_role = "" } = this.props,
    return (
      <>
        {isLoading ? (
          <></>
        ) : (
          <FormItem
            name="stores"
            customFormControl
            label={"Stores:"}
            className={"form-control-solid"}
            actions={this.props.actions}
            showRequired={true}
            custom={
              <>
                <Choices
                  data={data}
                  preselectedStores={preselectedStores}
                  multiple={multiple}
                  {...this.props}
                />
              </>
            }
          />
        )}
      </>
    );
  }
}

const Choices = ({ data, action, preselectedStores, multiple, onBlur }) => {
  const [multiSelections, setMultiSelections] = useState(preselectedStores);

  return (
    <Typeahead
      placeholder={"Stores"}
      options={data}
      onChange={(selected) => {
        setMultiSelections(selected);
        action(selected);
      }}
      selected={multiSelections}
      multiple={multiple}
      labelKey="name"
      onBlur={() => {
        onBlur(multiSelections);
      }}
    />
  );
};
