import React from "react";

import { useSelector } from "react-redux";
import { Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import { Redirect, useParams } from "react-router-dom";
import Datatable from "react-data-table-component";
import { debounce } from "lodash";

import {
  capitalizeText,
  fetchData,
  getToolTitleFromToolName,
  Humanize,
} from "../../helpers";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import { HeaderTextWithActions } from "../../components/Base";
import Breadcrumbs from "../../components/Breadcrumbs";
import AdminToolsUserToolModal from "./AdminToolsUserToolModal";
import PacmanLoading from "../../components/widgets/PacmanLoading/PacmanLoading";
import PaginationBar from "../../components/PaginationBar";
import { ADMINTOOLS_FETCH_LIMIT } from "../../constants";

export default function Page() {
  const { user } = useSelector((state) => state.auth);
  const { tool_name = "" } = useParams();
  return <MainComponent user={user} toolName={tool_name} />;
}

const utilizeFocus = () => {
  const ref = React.createRef();
  const setFocus = () => {
    ref.current && ref.current.focus();
  };

  return { setFocus, ref };
};

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      tools: "",
      showModal: false,
      showSplashScreen: false,
      redirectTo: "",
      toolName: this.props.toolName,
      showUserToolModal: false,
      selectedData: "",
      pageNumber: 1,
      title: getToolTitleFromToolName(this.props.toolName),
      searchText: "",
      userRoleFilter: "",
      userRoleList: "",
      isLoading: false,
    };

    this.api = core("pu").get();
    const { pageNumber } = this.state;
    if (this.props.toolName === "restore_user") {
      this.data_url = `/admintools/users/deleted?page=${pageNumber}`;
    } else {
      this.data_url = `/admintools/users?page=${pageNumber}`;
    }

    this.inputFocus = utilizeFocus();
  }

  handleFetchUserRoles = async () => {
    this.setState({ isLoading: true });
    const { data } = await fetchData("get", `/admintools/userroles`);
    this.setState({ userRoleList: data.userRoles, isLoading: false });
  };

  handleRefreshUser = async () => {
    let data = {};
    const { pageNumber } = this.state;
    this.setState({ isLoading: true });
    if (this.props.toolName === "restore_user") {
      ({ data } = await fetchData(
        "get",
        `/admintools/users/deleted?page=${pageNumber}`,
      ));
    } else {
      ({ data } = await fetchData(
        "get",
        `/admintools/users?page=${pageNumber}`,
      ));
    }
    this.setState({ data, isLoading: false });
  };

  handleRefreshUserWithSearch = async (searchText, pageNumber = 1) => {
    let data = {};
    // console.log("searchText: ", searchText);
    this.setState({ isLoading: true });
    if (this.props.toolName === "restore_user") {
      ({ data } = await fetchData(
        "get",
        `/admintools/users/deleted?searchKey=${searchText}&page=${pageNumber}`,
      ));
    } else {
      ({ data } = await fetchData(
        "get",
        `/admintools/users?searchKey=${searchText}&page=${pageNumber}`,
      ));
    }
    this.setState({ data, isLoading: false });
  };

  handleChangeSearchText = (searchText) => {
    this.setState({ searchText });
    this.debounceChangeSearchText(searchText);
  };

  debounceChangeSearchText = debounce(async (searchText) => {
    await this.handleRefreshUserWithSearch(searchText);
  }, 500);

  handleClickUser = (toolName, data) => {
    this.setState({ showUserToolModal: true, selectedData: data });
  };

  handleSelectUserRoleFilter = (value) => {
    this.setState({ userRoleFilter: value });
  };

  async componentDidMount() {
    await this.handleFetchUserRoles();
    this._isMounted = true;
    this.getData();
  }

  handleSelectPage = async (pageNumber = 1, pagesArr = []) => {
    const { searchText } = this.state;
    let page = pageNumber;
    if (page <= 0) {
      page = 1;
    }
    if (page > pagesArr.length) {
      page = pagesArr.length;
    }
    const result = await this.handleRefreshUserWithSearch(
      searchText,
      pageNumber,
    );
    this.setState({ pageNumber });
  };

  render() {
    const {
        data,
        data_status = "",
        toolName = "",
        selectedData = "",
        searchText = "",
        userRoleFilter = "",
        userRoleList = "",
        pageNumber = 1,
      } = this.state,
      isLoading = data_status !== "fetched";

    const limit = ADMINTOOLS_FETCH_LIMIT;

    let pagesArr = [];
    if (data && data.items && data.items.length) {
      for (let i = 0; i * limit < data.totalCount; i++) {
        pagesArr.push(i);
      }
    }

    return (
      <>
        <div className="col-md-9 col-sm-12">
          <Breadcrumbs>
            <Breadcrumbs.Item text={`Admin Tools`} link={`/admin/tools`} />
            <Breadcrumbs.Item
              text={getToolTitleFromToolName(this.props.toolName)}
              link={`/admin/tools/users`}
              active="true"
            />
          </Breadcrumbs>
        </div>

        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        {this.state.selectedData ? (
          <AdminToolsUserToolModal
            handleRefreshUser={this.handleRefreshUser}
            show={this.state.showUserToolModal}
            toolName={toolName}
            onHide={() => {
              this.setState({ showUserToolModal: false });
            }}
            data={selectedData}
          />
        ) : (
          <></>
        )}

        <HeaderTextWithActions title={this.state.title} />

        {this.state.isLoading || isLoading ? (
          <>
            <div className="d-flex justify-content-center align-items-center h-100">
              <PacmanLoading />
            </div>
          </>
        ) : (
          <>
            <Card className="mt-4">
              <Card.Body>
                <SearchBar
                  searchText={searchText}
                  handleChangeSearchText={this.handleChangeSearchText}
                  inputFocus={this.inputFocus}
                />
                {data.items && data.items.length > 0 ? (
                  <>
                    <h3 className="align-self-start mb-0 pb-0 mt-4">
                      Select User
                    </h3>
                  </>
                ) : (
                  <>
                    <div className="mt-4 text-center">No records found.</div>
                  </>
                )}
                <UserList
                  data={data}
                  toolName={toolName}
                  handleClickUser={this.handleClickUser}
                />
                {data.items && data.items.length > 0 && (
                  <div className="position-sticky" style={{ left: 0 }}>
                    <PaginationBar
                      pagesArr={pagesArr}
                      totalResults={data.totalCount}
                      rows={limit}
                      pageNum={pageNumber}
                      limit={limit}
                      onSelectPage={this.handleSelectPage}
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
          </>
        )}
      </>
    );
  }
}

function SearchBar({
  searchText = "",
  handleChangeSearchText,
  inputFocus = {},
}) {
  return (
    <>
      <Row className="w-100">
        <Col>
          <div className="d-flex flex-column w-100 align-items-center">
            <div
              style={{ width: "45vw" }}
              className="input-group input-group-lg"
            >
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="flaticon-search"></i>
                </span>
              </div>
              <input
                autoFocus
                ref={inputFocus.ref}
                type="text"
                className="form-control"
                placeholder="Search User Email"
                value={searchText}
                onChange={(e) => {
                  handleChangeSearchText(e.target.value);
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

function UserList({ data, toolName, handleClickUser }) {
  const filteredItems = data.items;

  return (
    <>
      {toolName === "restore_user" ? (
        <>
          {filteredItems.map((row) => {
            return (
              <DeletedUserTableCard
                key={row._id}
                data={row}
                toolName={toolName}
                handleClickUser={handleClickUser}
              />
            );
          })}
        </>
      ) : (
        <>
          {filteredItems.map((row) => {
            return (
              <UserTableCard
                key={row._id}
                data={row}
                toolName={toolName}
                handleClickUser={handleClickUser}
              />
            );
          })}
        </>
      )}
    </>
  );
}

function UserTableCard({ data, toolName, handleClickUser }) {
  return (
    <>
      <div>
        <Col className="w-100">
          <Card
            style={{ width: "65vw" }}
            className="my-3 cursor-pointer"
            onClick={() => handleClickUser(toolName, data)}
          >
            <Card.Body>
              <div className="d-flex flex-row align-items-center justify-content-space-between">
                <div className="ml-4 mr-2 d-flex flex-column flex-grow-1 align-items-start">
                  <h4>{`Email: ${data.email}`}</h4>
                  <h6>{`Client: ${
                    data.client_id && data.client_id.name
                      ? data.client_id.name
                      : `-`
                  }`}</h6>
                  <h6>{`User Role: ${capitalizeText(
                    Humanize(data.iam_role),
                  )}`}</h6>
                  <h6>
                    <span>{`Name: ${data.first_name} `}</span>
                    <span>{`${data.last_name}`}</span>
                  </h6>
                </div>
                <div className="ml-4">
                  <Button
                    type="submit"
                    className="btn-block text-uppercase font-weight-bold"
                  >
                    Select
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </>
  );
}

function DeletedUserTableCard({ data, toolName, handleClickUser }) {
  return (
    <>
      <div>
        <Col className="w-100">
          <Card
            style={{ width: "65vw" }}
            className="my-3 cursor-pointer"
            onClick={() => handleClickUser(toolName, data)}
          >
            <Card.Body>
              <div className="d-flex flex-row align-items-center justify-content-space-between">
                <div className="ml-4 d-flex flex-column flex-grow-1 align-items-start">
                  <h4>{`User Email: ${data.origin_key}`}</h4>
                  <h6>{`User Id: ${data.origin_id}`}</h6>
                  <h6>{`Date Deleted: ${data.date_created}`}</h6>
                </div>
                <div className="ml-4">
                  <Button
                    type="submit"
                    className="btn-block text-uppercase font-weight-bold"
                  >
                    Select
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </>
  );
}
