import React from "react";
import { Form, Modal } from "react-bootstrap";
import ProSourceForm from "../components/ProSourceForm";
import {
  GetSubmitClassList,
  GetDataFromEvent,
  generateDefaultStoreHours,
} from "../helpers";
import { EditStoreHoursTable } from "./StoreSetup";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DB_HOURS_FORMAT, PRIMARY_COLOR } from "../constants";
import moment from "moment";
import { cloneDeep } from "lodash";

const MySwal = withReactContent(Swal);

export default class SetStoreHoursModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { data = {} } = this.props,
      { store_hours = [], is_open_24_hours } = data;
    this.state = {
      data,
      values: {
        store_hours: this.generateStoreHours(store_hours),
        is_open_24_hours: is_open_24_hours || false,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      renderTable: true,
    };

    this.handleOnChangeStoreHrs = this.handleOnChangeStoreHrs.bind(this);
  }

  generateStoreHours = (store_hours) => {
    return cloneDeep(store_hours).map((store_hr, index) => {
      if (store_hr.hasOwnProperty("_id")) {
        store_hr._id =
          new Date().getTime().toString() + "_" + index + "_" + store_hr.day;
      }
      return store_hr;
    });
  };

  handleOnChangeStoreHrs = (e) => {
    const name = GetDataFromEvent(e, "data-name"),
      index = GetDataFromEvent(e, "data-index"),
      value = e.target.value,
      checked = e.target.checked;
    let { values = {} } = this.state;

    values.store_hours = values.store_hours.map((sh) => {
      if (sh._id === index) {
        if (name === "isOpen") sh[name] = checked;
        else sh[name] = value;
      }

      return sh;
    });

    this.setState({ values });
  };

  handleOnAddStoreHrs = (day) => {
    let { values = {} } = this.state,
      opening = "",
      closing = "";

    for (var i = 0; i < values.store_hours.length; i++) {
      if (day === values.store_hours[i].day) {
        opening = moment(values.store_hours[i].closing, DB_HOURS_FORMAT)
          .add("30", "minutes")
          .format(DB_HOURS_FORMAT);
        closing = "23:30";
      }
    }

    if (opening === closing || opening === "22:00" || opening === "00:00") {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `You can't add another store hour slot, kindly adjust the latest closing hour of this day to enable.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      let newItem = {};
      newItem.day = day;
      newItem.opening = opening;
      newItem.closing = closing;
      newItem.isOpen = false;
      newItem._id = new Date().getTime().toString(); // temporary
      values.store_hours.push(newItem);

      this.setState({ values, renderTable: false }, () => {
        this.setState({ renderTable: true });
      });
    }
  };

  handleOnDeleteStoreHrs = (id, day) => {
    // check first if there will be remaining slots for that day
    let { values = {} } = this.state;

    const store_schedule_day = values.store_hours.filter(
      (item) => item.day === day,
    );

    if (store_schedule_day.length === 1) {
      // means it currently has only one slot, and this can't be deleted
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `You can't remove this store hour slot! There should be at least one store hour slot per day.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      MySwal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: `You are about to remove this store hour slot`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          values.store_hours = values.store_hours.filter(
            (item) => item._id !== id,
          );

          this.setState({ values, renderTable: false }, () => {
            this.setState({ renderTable: true });
          });
        }
      });
    }
  };

  onSubmit = (values, setSubmitting) => {
    let isSameOpeningClosing = false;

    values.store_hours = values.store_hours.map((store_hr) => {
      if (!isSameOpeningClosing && store_hr.opening === store_hr.closing) {
        isSameOpeningClosing = true;
      }
      return store_hr;
    });

    if (!isSameOpeningClosing) {
      this.props.onSubmit(values, setSubmitting);
    } else {
      setSubmitting(false);
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `You can't set the same opening and closing hours on the same day.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };

  handleOnChange = (event) => {
    const target = event.target,
      name = target.name,
      checked = target.checked;
    let { values = {}, data } = this.state,
      state = {};
    if (name === "is_open_24_hours") {
      if (data.is_open_24_hours !== checked) {
        MySwal.fire({
          icon: "warning",
          title: "Are you sure?",
          html: `This will reset your current store hours.</br> Would you like to proceed?`,
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Yes",
          showCancelButton: true,
          cancelButtonText: "No",
          reverseButtons: true,
        }).then((result) => {
          if (result.value && result.isConfirmed) {
            values[name] = checked;
            values["store_hours"] = checked
              ? generateDefaultStoreHours(true)
              : data.is_open_24_hours
              ? generateDefaultStoreHours()
              : this.generateStoreHours(data.store_hours);
            state.values = values;
          } else {
            values[name] = !checked;
            state.values = values;
          }
          this.setState(state);
        });
      } else {
        values[name] = checked;
        values["store_hours"] = checked
          ? generateDefaultStoreHours(true)
          : data.is_open_24_hours
          ? generateDefaultStoreHours()
          : this.generateStoreHours(data.store_hours);
        state.values = values;
        this.setState(state);
      }
    }
  };

  render() {
    const { show, onHide } = this.props,
      { isSubmitting = false, values = {}, renderTable = true } = this.state,
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };
    return (
      <Modal show={show} onHide={onHide} centered size="lg">
        <Modal.Header>
          <Modal.Title>Set Store Hours</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            <div className="d-flex flex-row justify-content-center align-items-center mb-3">
              <div className="d-flex align-items-center font-size-lg font-weight-bold mr-5">
                Store is 24 hours open
              </div>
              <span className="d-flex switch switch-outline switch-icon switch-primary">
                <label className="m-0">
                  <input
                    type="checkbox"
                    name={"is_open_24_hours"}
                    checked={values.is_open_24_hours}
                    {...inputActions}
                  />
                  <span></span>
                </label>
              </span>
            </div>
            {renderTable ? (
              <EditStoreHoursTable
                isStoreOpen24Hrs={values["is_open_24_hours"]}
                store_hours={values["store_hours"]}
                actions={{
                  onChange: this.handleOnChangeStoreHrs,
                }}
                handleOnAddStoreHrs={this.handleOnAddStoreHrs}
                handleOnDeleteStoreHrs={this.handleOnDeleteStoreHrs}
              />
            ) : (
              <></>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              disabled={isSubmitting}
              className={GetSubmitClassList(
                isSubmitting,
                `btn btn-primary font-weight-bold`,
              )}
            >
              {isSubmitting ? "Saving changes..." : "Save changes"}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
