import React from "react";
import HeaderAndMenuForm from "./forms/HeaderAndMenuForm";

export default class HeaderAndMenuFormDiv extends React.Component {
  render() {
    return (
      <>
        <HeaderAndMenuForm
          {...this.props}
          values={this.props.data.website_theme}
          onSubmit={this.props.onSubmitThemeColorsForm}
        />
      </>
    );
  }
}
