import React from "react";
import { Modal, Form } from "react-bootstrap";
import { FormItem, InfoAlertHelper } from "../components/Base";
import ProSourceForm from "../components/ProSourceForm";
import { GetSubmitClassList } from "../helpers";
import helpertexts from "../helpertexts";

export default class DayAdvancedPreOrderModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { data = {} } = this.props,
      {
        is_accepting_in_advanced_orders = false,
        days_accepting_in_advanced_orders = 0,
      } = data;

    this.state = {
      values: {
        is_accepting_in_advanced_orders,
        days_accepting_in_advanced_orders,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };
  }

  onChange = (event) => {
    const target = event.target,
      checked = target.checked,
      name = target.name;
    let { values = {} } = this.state,
      state = {};

    if (name === "is_accepting_in_advanced_orders") {
      values[name] = checked;
      state.values = values;
    }

    this.setState(state);
  };

  onSubmit = (values, setSubmitting) => {
    values.days_accepting_in_advanced_orders = parseInt(
      values.days_accepting_in_advanced_orders,
    );
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const { show, onHide } = this.props,
      { isSubmitting = false, values = {} } = this.state;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>Pre Order Settings</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            <InfoAlertHelper
              text={
                helpertexts["getting-started"][
                  "is_accepting_in_advanced_orders"
                ]
              }
            />
            <div>
              <div class="form-group row">
                <label class="col-7 col-form-label font-weight-bolder text-dark mr-2">
                  Will the store accept pre-orders for more than 1 day in
                  advance?
                </label>
                <div class="col-3">
                  <span class="switch switch-outline switch-icon switch-primary">
                    <label>
                      <input
                        type="checkbox"
                        name={"is_accepting_in_advanced_orders"}
                        checked={
                          values["is_accepting_in_advanced_orders"]
                            ? "checked"
                            : null
                        }
                        {...inputActions}
                      />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
            <FormItem
              normal
              label={"How many days in advance can a customer pre-order?"}
              name={"day_advanced_preorders"}
              inputProps={{
                name: "days_accepting_in_advanced_orders",
                placeholder: "0",
                value: values["days_accepting_in_advanced_orders"],
                disabled: values["is_accepting_in_advanced_orders"]
                  ? ""
                  : "disabled",
                min: 1,
                max: 31,
              }}
              inputActions={inputActions}
              actions={actions}
              type={"number"}
              showRequired={values["is_accepting_in_advanced_orders"]}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              disabled={isSubmitting}
              className={GetSubmitClassList(
                isSubmitting,
                `btn btn-primary font-weight-bold`,
              )}
            >
              {isSubmitting ? "Saving changes..." : "Save changes"}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
