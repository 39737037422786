import React from "react";
import { SVGIcon } from "../Base";

function setIfNotEmpty(variable, value) {
  return variable === "" ? value : variable;
}

export default class StatCard extends React.Component {
  render() {
    const {
      variant = "primary",
      icon = "Communication/Group",
      title = "Sample",
      stat = "0 ",
    } = this.props;

    let { iconColor = "", statColor = "", titleColor = "" } = this.props;

    if (variant === "primary") {
      iconColor = setIfNotEmpty(iconColor, "svg-icon-white");
      statColor = setIfNotEmpty(statColor, "text-white");
      titleColor = setIfNotEmpty(titleColor, "text-white");
    } else if (variant === "light-success") {
      iconColor = setIfNotEmpty(iconColor, "svg-icon-success");
      statColor = setIfNotEmpty(statColor, "text-dark");
      titleColor = setIfNotEmpty(titleColor, "text-muted");
    } else if (variant === "light-primary") {
      iconColor = setIfNotEmpty(iconColor, "svg-icon-primary");
      statColor = setIfNotEmpty(statColor, "text-dark");
      titleColor = setIfNotEmpty(titleColor, "text-muted");
    }

    return (
      <>
        <div className={`card card-custom bg-${variant} card-stretch gutter-b`}>
          <div className="card-body">
            <SVGIcon
              icon={icon}
              title={title}
              className={`svg-icon-2x ${iconColor}`}
            />
            <span
              className={`card-title font-weight-bolder ${statColor} font-size-h2 mb-0 mt-6 d-block`}
            >
              {stat}
            </span>
            <span className={`font-weight-bold ${titleColor} font-size-sm`}>
              {title}
            </span>
          </div>
        </div>
      </>
    );
  }
}
