import React, { Component } from "react";
import moment from "moment";
import qs from "qs";
import { Dropdown, InputGroup, FormControl } from "react-bootstrap";
import ReportsApi from "../../../pages/client-admin/reports/ReportsApi";
import { HeaderTextWithActions } from "../../../components/Base";
import ReportsTable from "../../../components/ReportsTable";
import GroupDropdown from "../../../components/dashboard/GroupDropdown";
import core from "../../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import TitleHelmet from "../../../partials/TitleHelmet";
import { useSelector } from "react-redux";
import { DB_DATE_FORMAT, DATERANGE_LIST } from "../../../constants";
import { capitalizeText } from "../../../../app/helpers";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ExportCSV from "../../../components/ExportCSV";
import PaginationBar from "../../../components/PaginationBar";
import { KTUtil } from "../../../../_metronic/_assets/js/components/util";
import PacmanWrapper from "../../../components/dashboard/PacmanWrapper";

const getIdsAndAttr = function (props, selectedView = {}) {
  if (selectedView.id || selectedView.id === null) {
    return selectedView;
  }

  return { id: props.id, attr: "brand" };
};

export default function Page(props) {
  const { mode = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  const {
    filter = "",
    duration = "day",
    attr = "",
    view = "",
    date = moment().format(DB_DATE_FORMAT),
    page = 1,
    limit = 30,
  } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  return (
    <MainComponent
      // id={user.client_id}
      id={view}
      view={view}
      attr={attr}
      user={user}
      filter={filter}
      duration={duration}
      date={date}
      webview={mode === "webview"}
      page={page}
      limit={limit}
    />
  );
}

class MainComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: true,
      redirectTo: "",
      brand_name: "Brands",
    };

    this.api = core("pu").get();

    this.getLoggedInUser = this.getLoggedInUser.bind(this);
  }

  getLoggedInUser = (field = "fullname") => {
    const { user = {} } = this.props;
    if (field === "fullname") {
      return user.first_name + " " + user.last_name;
    } else {
      return user[field];
    }
  };

  render() {
    const actions = {
      getLoggedInUser: this.getLoggedInUser,
      setBrandName: (brand_name = "") => {
        this.setState({ brand_name });
      },
      setSplashScreen: (showSplashScreen = true) => {
        this.setState({ showSplashScreen });
      },
      setRedirectTo: (redirectTo = "") => {
        this.setState({ redirectTo });
      },
    };

    const {
      id = "",
      view = "",
      attr = "",
      filter = "",
      page = 1,
      limit = 30,
      webview = "",
      user = {},
      user: { brands = [], stores = [], iam_role = "" },
      duration = "day",
      date = moment().format(DB_DATE_FORMAT),
    } = this.props;

    return (
      <>
        <TitleHelmet title={this.state.brand_name} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <SuperAdmin
          webview={webview}
          id={id}
          api={this.api}
          actions={actions}
          filter={filter}
          duration={duration}
          view={view}
          attr={attr}
          user={user}
          date={date}
          brands={brands}
          stores={stores}
          role={iam_role}
          page={page}
          limit={limit}
        />
      </>
    );
  }
}

class SuperAdmin extends ReportsApi {
  getReqBody() {
    // const {page = 1, limit= 30, search = '', isForExport= false, sortBy= {name: 'order_date', isReverse: false}} = this.state;
    const {
      page = 1,
      limit = 30,
      isForExport = false,
      sortBy = { name: "order_date", isReverse: false },
      showEndDatePicker = false,
      endDate = moment().add(1, "w").format(DB_DATE_FORMAT),
    } = this.state;
    const search = this.processSearchValue(this.state.search);
    let duration = this.state.duration || this.props.duration || "day";
    let date =
      this.state.date || this.props.date || moment().format(DB_DATE_FORMAT);
    const { filter = "" } = this.props;

    let view = this.state.selectedBrand || {
      id: this.props.id || "",
      name: "",
      attr: "brand",
    };

    const { role = "", brands = [] } = this.props;
    const { id = "", attr = "" } = getIdsAndAttr(this.props, view);

    if (this.props.filter && this.props.filter === "Orders Tomorrow") {
      date = moment().add(1, "days").format(DB_DATE_FORMAT);
      duration = "day";
    }

    if (duration === "allTime" && page > 1) {
      date = moment().format(DB_DATE_FORMAT);
    }

    return {
      role: role,
      id,
      attr: attr || view.attr,
      date,
      dateRange: duration,
      filter,
      limit: Number(limit),
      page: page - 1,
      search,
      sortBy,
      isForExport,
      ...(showEndDatePicker && { endDate }),
    };
  }

  async getData() {
    this._isMounted && this.setState({ data_status: "fetching" });
    const reqBody = this.getReqBody();

    // this.api && this.api.get(`/group/${this.props.id}/reports?dateRange=${duration}&date=${date}&filter=${filter}&view=${view.id}&attr=${view.attr}`)
    this.api &&
      this.api
        .post({
          url: "/analytics/reports",
          data: reqBody,
        })
        .then((data) => {
          this._isMounted &&
            this.setState({
              data: data.data,
              data_status: "fetched",
              order_fetch_status: "fetched",
              selectedBrand: this.handleInitialSelectedBrand(data.data.headers),
            });

          this.handleOnDataFetched();

          if (this.use_display_data) {
            this.setDisplayData();
          }
        })
        .catch((error) => {
          this._isMounted &&
            this.setState({ error: error, showSwalError: true });
          this.handleOnDataFetchError();
        });
  }

  render() {
    const { role = "", webview = false } = this.props;
    const {
        data_status = "",
        data = {},
        selectedBrand = { id: "", name: "All" },
        duration = "day",
        date = moment().format(DB_DATE_FORMAT),
        page = 1,
        limit = 30,
        showEndDatePicker = this.props.showEndDatePicker || false,
        endDate = moment().add(1, "w").format(DB_DATE_FORMAT),
      } = this.state,
      isLoading = data_status !== "fetched";

    const title = capitalizeText(this.props.filter) || "Orders";
    const { headers = [], orders = [], totalResults = 0 } = data;

    function dropdownText(dateRange, date = moment().format(DB_DATE_FORMAT)) {
      const isCurrent = date === moment().format(DB_DATE_FORMAT);
      const prefix = isCurrent ? "This" : "Last";

      if (dateRange === "week") return `${prefix} Week`;
      if (dateRange === "month") return `${prefix} Month`;
      if (dateRange === "year") return `${prefix} Year`;
      if (dateRange === "allTime") return "All-time";
      if (!isCurrent) {
        return moment(date).isAfter(new Date()) ? "Tomorrow" : "Yesterday";
      }

      return "Today";
    }

    const pagesArr = [];
    for (let i = 0; i * limit < totalResults; i++) {
      pagesArr.push(i);
    }

    return (
      <>
        <TitleHelmet title="Reports" />
        {this.props.webview && (
          <div className="row">
            <div className="col-md-9 col-sm-12">
              <Breadcrumbs>
                <Breadcrumbs.Item
                  text="Dashboard"
                  link={`/webview/dashboard`}
                  active="true"
                />
              </Breadcrumbs>
            </div>
          </div>
        )}
        <HeaderTextWithActions className="mb-0 mb-md-4" title={title} />

        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          <div className="d-flex flex-column">
            <div className="mb-8 position-sticky" style={{ left: 0 }}>
              <InputGroup className="mb-3 overflow-hidden">
                <InputGroup.Prepend>
                  <InputGroup.Text id="text-search-input">
                    Search{" "}
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <FormControl
                  aria-label="Search"
                  aria-describedby="inputGroup-sizing-default"
                  placeholder="Order#, customer name, email, and contact number"
                  value={this.state.search}
                  onChange={(e) => this.handleTextSearchInputChange(e)}
                  onKeyDown={this.keyPress}
                />

                <button
                  className="btn btn-default"
                  onClick={this.handleTextSearch}
                >
                  <i className="fa fa-search"></i>
                </button>
              </InputGroup>
            </div>

            <div
              className="row align-items-center mb-4 position-sticky d-flex flex-column flex-md-row"
              style={{ left: "0", zIndex: 100 }}
            >
              <div
                className={`${
                  showEndDatePicker ? "col-12 col-sm-7" : "col-12 col-md-6"
                } input-group position-sticky w-100`}
                style={{ left: "0" }}
                onClick={() => this.handleOnDateInputClick()}
              >
                <form
                  className={`input-group-prepend align-items-center mr-4 ${
                    KTUtil.isMobileDevice() && showEndDatePicker
                      ? "flex-wrap"
                      : ""
                  }`}
                >
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Order Date
                  </span>
                  <input
                    type="date"
                    className="form-control rounded-0 d-inline-block"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    onChange={this.handleDateInputChange}
                    value={
                      this.state.page !== 2
                        ? (title === "Orders Tomorrow" &&
                            moment().add(1, "days").format(DB_DATE_FORMAT)) ||
                          this.state.date ||
                          moment().format(DB_DATE_FORMAT)
                        : null
                    }
                    style={{ width: 155 }}
                  />
                  {showEndDatePicker && (
                    <div
                      className={`d-flex ${
                        KTUtil.isMobileDevice() ? "ml-6" : ""
                      }`}
                    >
                      <div className="d-flex p-3"> to </div>
                      <input
                        type="date"
                        name="endDate"
                        className="form-control rounded-0 d-inline-block"
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(e) =>
                          this.handleDateInputChange(e, "endDate")
                        }
                        min={this.state.date}
                        value={
                          endDate || moment().add(1, "w").format(DB_DATE_FORMAT)
                        }
                        style={{ width: 155 }}
                      />
                    </div>
                  )}
                  <button
                    className="btn btn-primary rounded-0"
                    onClick={this.handleSearchDate}
                  >
                    <i className="fa fa-search p-0" />
                  </button>
                </form>
                <div style={{ marginLeft: "-2rem" }}>
                  <ExportCSV
                    filename={`${title}-${dropdownText(
                      duration || this.props.duration,
                      date,
                    )}-${date}.csv`}
                    // headers={csvHeaders}
                    url="/analytics/reports"
                    reqBody={this.getReqBody()}
                  />
                </div>
              </div>

              <div className="d-flex position-sticky ml-auto mr-1 ">
                {role !== "console_user" && (
                  <GroupDropdown
                    items={headers}
                    attr={role === "store_admin" ? "store" : "brand"}
                    onSelectView={this.handleChangeSelectedBrand}
                    selectedView={selectedBrand}
                    hasRollup={true}
                    className="mr-2 mr-4 mt-4 mt-md-0 rounded-top"
                    style={{ zIndex: 100 }}
                  />
                )}

                <Dropdown
                  className="mr-4 mt-4 mt-md-0 rounded-top"
                  style={{ right: "0" }}
                >
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {this.handleDropdownText(
                      duration || this.props.duration,
                      date,
                    ) || "Today"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu id={"daterange-dropdown"}>
                    {[
                      ...DATERANGE_LIST,
                      { date: null, name: "Date Range" },
                    ].map((item) => (
                      <Dropdown.Item
                        key={item.name}
                        onClick={(e) => {
                          const dropdownText = e.currentTarget.textContent;
                          if (dropdownText === "Date Range") {
                            this.setState({
                              date: moment().format(DB_DATE_FORMAT),
                              endDate: moment()
                                .add(1, "w")
                                .format(DB_DATE_FORMAT),
                              showEndDatePicker: true,
                              duration: "between",
                            });
                          } else {
                            this.handleDateRangeClick(e, item.date);
                          }
                        }}
                      >
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div style={{ overflowX: "auto" }}>
              <ReportsTable
                data={orders}
                onClickSort={this.handleChangeSort}
                levelId={this.props.brand}
                webview={this.props.webview}
              />

              {totalResults > 0 && (
                <div className="position-sticky" style={{ left: 0 }}>
                  <PaginationBar
                    pagesArr={pagesArr}
                    totalResults={totalResults}
                    rows={orders.length}
                    pageNum={page}
                    limit={limit}
                    onSelectPage={this.handleSelectPage}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}
