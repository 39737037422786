import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FormItem } from "../../components/Base";
import ProSourceForm from "../../components/ProSourceForm";
import { GetSubmitClassList } from "../../helpers";

export default class UpdateGroupTypeModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { group_type = "" } = this.props;

    this.state = {
      values: {
        group_type: group_type,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const { show, onHide } = this.props,
      { isSubmitting = false } = this.state;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <>
        <Modal show={show} onHide={onHide} centered>
          <Form onSubmit={this.handleOnSubmit}>
            <Modal.Header>
              <Modal.Title>Update Group</Modal.Title>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onHide}
              >
                <i aria-hidden="true" className="ki ki-close"></i>
              </button>
            </Modal.Header>
            <Modal.Body>
              <FormItem
                label={"Group Type"}
                name={"group_type"}
                actions={actions}
                showRequired={true}
                normal
                customFormControl
                custom={
                  <>
                    <Form.Control
                      as="select"
                      value={this.state.values["group_type"]}
                      {...inputActions}
                      name="group_type"
                      className="text-capitalize"
                    >
                      <option value="single">Single</option>
                      <option value="multi">Multi</option>
                    </Form.Control>
                  </>
                }
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="submit"
                disabled={isSubmitting}
                className={GetSubmitClassList(
                  isSubmitting,
                  `btn btn-primary font-weight-bold`,
                )}
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
