import React, { useState, useEffect } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import { SVGIcon } from "./Base";
import EmptyData from "./EmptyData";

import {
  getStatusDisplay,
  Humanize,
  toPesoAmount,
  decryptPaymentDetails,
} from "../../app/helpers";

const ReportsTable = (props) => {
  const { data = [], onClickSort, levelId = "", webview } = props;

  return (
    <>
      {data.length === 0 || !data ? (
        <EmptyData className={"py-8 px-6 text-center bg-white"} />
      ) : (
        <table className="table responsive bg-white py-10 px-6 rounded">
          <thead>
            <tr>
              <th scope="col">
                ORDER#
                <button
                  className="btn btn-white p-0"
                  onClick={() => onClickSort("transaction_number")}
                >
                  <SVGIcon icon={"Shopping/Sort1"} />
                </button>
              </th>
              <th scope="col">
                ORDER DATE
                <button
                  className="btn btn-white p-0"
                  onClick={() => onClickSort("order_date")}
                >
                  <SVGIcon icon={"Shopping/Sort1"} />
                </button>
              </th>
              <th scope="col">
                PAYMENT ID
                {/* <button className="btn btn-white p-0"
                            onClick= {() => onClickSort('order_type')}
                        >
                            <SVGIcon icon={'Shopping/Sort1'}/>
                        </button> */}
              </th>
              <th scope="col">
                PAYMENT METHOD
                <button
                  className="btn btn-white p-0"
                  onClick={() => onClickSort("payment_method")}
                >
                  <SVGIcon icon={"Shopping/Sort1"} />
                </button>
              </th>
              <th scope="col">
                ORDER TYPE
                <button
                  className="btn btn-white p-0"
                  onClick={() => onClickSort("order_type")}
                >
                  <SVGIcon icon={"Shopping/Sort1"} />
                </button>
              </th>
              <th scope="col">
                CUSTOMER EMAIL
                <button
                  className="btn btn-white p-0"
                  onClick={() => onClickSort("email")}
                >
                  <SVGIcon icon={"Shopping/Sort1"} />
                </button>
              </th>
              <th>
                CUSTOMER NAME
                <button
                  className="btn btn-white p-0"
                  onClick={() => onClickSort("customer_name")}
                >
                  <SVGIcon icon={"Shopping/Sort1"} />
                </button>
              </th>
              <th>
                CONTACT #
                <button
                  className="btn btn-white p-0"
                  onClick={() => onClickSort("contact_number")}
                >
                  <SVGIcon icon={"Shopping/Sort1"} />
                </button>
              </th>
              <th>
                DELIVERY ADDRESS
                <button
                  className="btn btn-white p-0"
                  onClick={() => onClickSort("delivery_address")}
                >
                  <SVGIcon icon={"Shopping/Sort1"} />
                </button>
              </th>
              <th>
                City
                {/* <button className="btn btn-white p-0"
                            onClick= {() => onClickSort('contact_number')}>
                            <SVGIcon icon={'Shopping/Sort1'}/>
                        </button> */}
              </th>
              <th>
                DELIVERY CHARGE
                <button
                  className="btn btn-white p-0"
                  onClick={() => onClickSort("chargedDeliveryFee")}
                >
                  <SVGIcon icon={"Shopping/Sort1"} />
                </button>
              </th>
              <th scope="col">
                STATUS
                <button
                  className="btn btn-white p-0"
                  onClick={() => onClickSort("status")}
                >
                  <SVGIcon icon={"Shopping/Sort1"} />
                </button>
              </th>
              <th scope="col">
                TOTAL AMOUNT
                <button
                  className="btn btn-white p-0"
                  onClick={() => onClickSort("amount")}
                >
                  <SVGIcon icon={"Shopping/Sort1"} />
                </button>
              </th>
              <th>ITEMS(EXTRAS)</th>
            </tr>
          </thead>
          <tbody>
            {data.map((order, i) => (
              <TableRow
                key={order._id + i}
                order={order}
                webview={webview}
                levelId={levelId}
              />
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default ReportsTable;

function TableRow({ order, webview = "" }) {
  const [isCharLimited, setIsCharLimited] = useState(true);
  const [isCollapseBtnVisible, setIsCollapseBtnVisible] = useState(true);
  const { pathname } = useLocation();

  const {
    order_date,
    _id,
    transaction_number,
    order_type,
    email,
    customer_name,
    contact_number,
    delivery_address,
    chargedDeliveryFee,
    status,
    returning_customer,
    payment_details = "",
    payment_method = "",
    order_details = [],
    city = "",
  } = order;

  const itemExtrasStyle = {
    overflow: "hidden",
    height: "3rem",
  };

  const buttonStyle = {
    position: "absolute",
    top: 0,
    right: "-0.5rem",
    transform: "scale(.75)",
  };

  const totalAmountDisplay = (order = {}) => {
    const { isMealPlan = false, amount = 0, refunded_amount = 0 } = order;

    //just show mealplan icon if 'isMealPlan' field is true
    if (isMealPlan)
      return (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={(props) => (
            <Tooltip id="button-tooltip-meal-plan" {...props}>
              Meal Plan
            </Tooltip>
          )}
        >
          <span>
            {toPesoAmount(amount - refunded_amount)}
            <i className="far fa-calendar-check ml-2 text-success" />
          </span>
        </OverlayTrigger>
      );

    return toPesoAmount(amount);
  };

  useEffect(() => {
    if (order_details.length > 1 || order_details[0].extras.length > 1) {
      setIsCollapseBtnVisible(false);
    }
  }, []);

  return (
    <tr>
      <td>
        {webview ? (
          <span>#{transaction_number}</span>
        ) : (
          <Link to={{ pathname: `/orders/${_id}`, state: { from: pathname } }}>
            #{transaction_number}
          </Link>
        )}
      </td>
      <td>{order_date}</td>
      <td>{decryptPaymentDetails(payment_details)}</td>
      <td>{Humanize(payment_method)}</td>
      <td>{Humanize(order_type)}</td>
      <td>
        {email}

        {returning_customer && (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <span className="ml-2">
              <SVGIcon
                variant="success"
                title=" "
                icon={"Navigation/Double-check"}
              />
            </span>
          </OverlayTrigger>
        )}
      </td>
      <td>{customer_name}</td>
      <td>{contact_number}</td>
      <td>{delivery_address ? delivery_address : "-"}</td>
      {/* <td>{order_type=== 'delivery' ? `${locality}, ${city}`: '-'}</td> */}
      <td>{order_type === "delivery" && city ? `${city}` : "-"}</td>
      <td>
        {chargedDeliveryFee ? `${toPesoAmount(chargedDeliveryFee)}` : "-"}
      </td>
      <td>{getStatusDisplay(status, order_type)} </td>
      {/* <td>{toPesoAmount(amount - refunded_amount)}</td> */}
      <td>
        <div style={{ overflow: "hidden", whiteSpace: "noWrap" }}>
          {totalAmountDisplay(order)}
        </div>
      </td>
      <td>
        <div
          className="text-nowrap position-relative"
          style={isCharLimited ? itemExtrasStyle : {}}
        >
          <button
            className={`btn btn-default btn-sm opacity-75 opacity-hover-100 ${
              isCollapseBtnVisible && "d-none"
            }`}
            onClick={() => setIsCharLimited(!isCharLimited)}
            style={buttonStyle}
          >
            <i
              className={`fas fa-chevron-${isCharLimited ? "down" : "up"}`}
            ></i>
          </button>
          {order_details.map((order, i) => (
            <>
              <div
                key={`${order.item._id}${i}`}
                className="text-dark mb-1"
                style={{ whiteSpace: "nowrap", marginRight: "4.5rem" }}
              >
                {order.qty} x {order.item.name}
              </div>

              {order.extras && (
                <div
                  key={order._id}
                  className="ml-4 flex-col text-dark-75 mb-1"
                  style={{ whiteSpace: "nowrap", marginRight: "4.5rem" }}
                >
                  {order.extras.map((extra, j) => (
                    <div key={`${_id}${j}`}>
                      {" "}
                      {extra.extra_id[0] && `+ ${extra.extra_id[0].name}`}
                    </div>
                  ))}
                </div>
              )}
            </>
          ))}
        </div>
      </td>
    </tr>
  );
}

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip-returning-customer" {...props}>
    Returning Customer
  </Tooltip>
);
