import moment from "moment";

export const getArrayTopXOthers = (array, topX, sortOrder) => {
  let result = {};
  result.bottom = [];
  result.othersTotal = 0;
  let sortedArray = [];
  if (sortOrder === "desc") {
    sortedArray = array.sort((a, b) => Number(b.value) - Number(a.value));
  } else if (sortOrder === "asc") {
    sortedArray = array.sort((a, b) => Number(a.value) - Number(b.value));
  }
  // const topArray = array.slice(0, topX)
  const topArray = sortedArray.slice(0, topX);
  result.top = topArray;
  result.newArray = topArray;
  if (!(topX < array.length)) return result;
  const bottomArray = array.slice(topX, array.length);

  const othersTotal = bottomArray.reduce((acc, item) => {
    return acc + Number(item.value);
  }, 0);
  const othersObject = {
    name: "Others",
    value: othersTotal,
  };

  const newArray = [...topArray, othersObject];
  result.top = topArray;
  result.bottom = bottomArray;
  result.othersTotal = othersTotal;
  result.newArray = newArray;

  return result;
};

export const getNameFilterArray = (filter, allBrands) => {
  let nameFilterArray = [];

  if (!filter.attr) {
    //superadmin
    if (filter.name === "All") {
      nameFilterArray = allBrands.reduce(
        (acc, item) => acc.push(`${item.name}`) && acc,
        [],
      );
    } else {
      nameFilterArray = [`${filter.name}`];
    }
  } else {
    if (filter.attr === "brand") {
      if (filter.name === "All") {
        nameFilterArray = allBrands.reduce(
          (acc, item) => acc.push(`${item.name}`) && acc,
          [],
        );
      } else {
        nameFilterArray = [`${filter.name}`];
      }
    } else if (filter.attr === "store") {
      nameFilterArray = [`${filter.name}`];
    } else if (!filter.attr) {
      //superadmin
      // console.log("getnamefilterarray superadmin")
      nameFilterArray = allBrands.reduce(
        (acc, item) => acc.push(`${item.name}`) && acc,
        [],
      );
    }
  }

  return nameFilterArray;
};

export const getPageFilterArray2 = (filter, allBrands) => {
  let pageFilterArray = [];

  if (filter.attr === "brand") {
    if (filter.name === "All") {
      pageFilterArray = allBrands.reduce(
        (acc, item) =>
          acc.push(
            `Home | ${item.name}`,
            `Menu | ${item.name}`,
            `Checkout | ${item.name}`,
          ) && acc,
        [],
      );
    } else {
      pageFilterArray = [
        `Home | ${filter.name}`,
        `Menu | ${filter.name}`,
        `Checkout | ${filter.name}`,
      ];
    }
  } else if (filter.attr === "store") {
    // let filteredBrand = allBrands.filter((item) => item.stores.name==='Tea Time - Socialdesk Tower')
    let filteredBrand = allBrands.map((item) => {
      return {
        ...item,
        stores: item.stores.find((store) => store.name === filter.name),
      };
    });
    pageFilterArray = [
      `Menu | ${filteredBrand[0].name} | ${filter.name}`,
      `Checkout | ${filteredBrand[0].name} | ${filter.name}`,
    ];
  }

  return pageFilterArray;
};

export const getPageFilterArray = (filter, allBrands, brandName) => {
  let pageFilterArray = [];

  // console.log("getPageFilterArray filter: ", filter)
  // console.log("getPageFilterArray allBrands: ", allBrands)
  // console.log("getPageFilterArray brandName: ", brandName)

  if (!filter.attr) {
    //superadmin
    // if (filter.name === "All") {
    //     let reducedStores = allBrands.reduce((brandAcc, brandItem) => {
    //         return brandAcc.push(`Home | ${brandItem.name}`) && brandAcc
    //     }, [])
    //     pageFilterArray = reducedStores
    // } else {
    //     let filtered = [allBrands.find((item) => item.name === filter.name)]
    //     let reducedStores = filtered.reduce((brandAcc, brandItem) => {
    //         return brandAcc.push(`Home | ${brandItem.name}`) && brandAcc
    //     }, [])
    //     pageFilterArray = reducedStores
    // }

    if (filter.name === "All") {
      let reducedStores = allBrands.reduce((brandAcc, brandItem) => {
        let storeItems = brandItem.stores.reduce((storeAcc, storeItem) => {
          // return { brandName: item.name, storeName: storeItem.name}
          return (
            storeAcc.push(
              `Home | ${brandItem.name}`,
              `Menu | ${brandItem.name} | ${storeItem.name}`,
              `Checkout | ${brandItem.name} | ${storeItem.name}`,
            ) && storeAcc
          );
        }, brandAcc);
        return storeItems;
      }, []);
      pageFilterArray = reducedStores;
    } else {
      let filteredBrand = allBrands.map((item) => {
        return {
          ...item,
          stores: item.stores.filter((store) => item.name === filter.name),
        };
      });
      // let filteredStores = filteredBrand[0].stores.map((item) => {
      //     return { brandName: filteredBrand[0].name, storeName: item.name}
      // })
      // console.log("getPageFilterArray filter: ", filter)
      // console.log("getPageFilterArray filteredBrand: ", filteredBrand)
      // console.log("getPageFilterArray filteredStores: ", filteredStores)
      let reducedStores = filteredBrand.reduce((brandAcc, brandItem) => {
        let storeItems = brandItem.stores.reduce((storeAcc, storeItem) => {
          return (
            storeAcc.push(
              `Home | ${brandItem.name}`,
              `Menu | ${brandItem.name} | ${storeItem.name}`,
              `Checkout | ${brandItem.name} | ${storeItem.name}`,
            ) && storeAcc
          );
        }, brandAcc);
        return storeItems;
      }, []);
      // console.log("getPageFilterArray reducedStores: ", reducedStores)
      pageFilterArray = reducedStores;
    }
  } else {
    if (filter.attr === "brand" && allBrands[0].stores) {
      if (filter.name === "All") {
        let reducedStores = allBrands.reduce((brandAcc, brandItem) => {
          let storeItems = brandItem.stores.reduce((storeAcc, storeItem) => {
            // return { brandName: item.name, storeName: storeItem.name}
            return (
              storeAcc.push(
                `Home | ${brandItem.name}`,
                `Menu | ${brandItem.name} | ${storeItem.name}`,
                `Checkout | ${brandItem.name} | ${storeItem.name}`,
              ) && storeAcc
            );
          }, brandAcc);
          return storeItems;
        }, []);
        pageFilterArray = reducedStores;
      } else {
        let filteredBrand = allBrands.map((item) => {
          return {
            ...item,
            stores: item.stores.filter((store) => item.name === filter.name),
          };
        });
        // let filteredStores = filteredBrand[0].stores.map((item) => {
        //     return { brandName: filteredBrand[0].name, storeName: item.name}
        // })
        // console.log("getPageFilterArray filter: ", filter)
        // console.log("getPageFilterArray filteredBrand: ", filteredBrand)
        // console.log("getPageFilterArray filteredStores: ", filteredStores)
        let reducedStores = filteredBrand.reduce((brandAcc, brandItem) => {
          let storeItems = brandItem.stores.reduce((storeAcc, storeItem) => {
            return (
              storeAcc.push(
                `Home | ${brandItem.name}`,
                `Menu | ${brandItem.name} | ${storeItem.name}`,
                `Checkout | ${brandItem.name} | ${storeItem.name}`,
              ) && storeAcc
            );
          }, brandAcc);
          return storeItems;
        }, []);
        // console.log("getPageFilterArray reducedStores: ", reducedStores)
        pageFilterArray = reducedStores;
      }
    } else if (filter.attr === "store" || !allBrands[0].stores) {
      // let filteredBrand = allBrands.map((item) => {
      //     return {...item, stores: item.stores.find((store) => store.name === filter.name)}
      // })
      let filteredBrand = [];
      let selectedBrand = "";
      if (allBrands[0].stores) {
        // console.log("has stores")
        filteredBrand = allBrands.map((item) => {
          return {
            ...item,
            stores: item.stores.find((store) => store.name === filter.name),
          };
        });
        // console.log("getPageFilterArray filteredBrand: ", filteredBrand)
        selectedBrand = filteredBrand.find((item) => item.stores);
        // console.log("getPageFilterArray selectedBrand: ", selectedBrand)
        // pageFilterArray = [`Menu | ${filteredBrand[0].name} | ${filter.name}`, `Checkout | ${filteredBrand[0].name} | ${filter.name}`]
        pageFilterArray = [
          `Menu | ${selectedBrand.name} | ${filter.name}`,
          `Checkout | ${selectedBrand.name} | ${filter.name}`,
        ];
      } else {
        // console.log("no stores")
        let filtered = [allBrands.find((item) => item.name === filter.name)];
        // console.log("getPageFilterArray filtered: ", filtered)
        filteredBrand = filtered.map((item) => {
          return { ...item, stores: item.name === filter.name };
        });
        pageFilterArray = [
          `Menu | ${brandName} | ${filter.name}`,
          `Checkout | ${brandName} | ${filter.name}`,
        ];
      }

      // let filteredBrand = allBrands.map((item) => {
      //     if (item.stores) {
      //         return {...item, stores: item.stores.find((store) => store.name === filter.name)}
      //     } else {
      //         console.log("no stores")
      //         if (item.name === filter.name) return item.name
      //         // return { stores: item.name === filter.name }
      //     }
      // })
      // console.log("getPageFilterArray filteredBrand: ", filteredBrand)
      // pageFilterArray = [`Menu | ${filteredBrand[0].name} | ${filter.name}`, `Checkout | ${filteredBrand[0].name} | ${filter.name}`]
    }
  }

  return pageFilterArray;
};

export const getDateRange = (selectedDateRange) => {
  let dateRange = {};
  let dateStart = "today";
  let dateEnd = "today";

  if (selectedDateRange === "Today") {
    dateStart = "today";
    dateEnd = "today";
  } else if (selectedDateRange === "Yesterday") {
    dateStart = "yesterday";
    dateEnd = "yesterday";
  } else if (selectedDateRange === "This Week") {
    // dateStart = "6daysAgo"
    // dateEnd = "today"
    dateStart = moment().startOf("isoWeek").format("YYYY-MM-DD");
    // console.log("this week dateStart: ", dateStart)
    dateEnd = moment().endOf("isoWeek").format("YYYY-MM-DD");
    // console.log("this week dateEnd: ", dateEnd)
  } else if (selectedDateRange === "Last Week") {
    // dateStart = "14daysAgo"
    // dateEnd = "7daysAgo"
    dateStart = moment()
      .subtract(1, "weeks")
      .startOf("isoWeek")
      .format("YYYY-MM-DD");
    // console.log("last week dateStart: ", dateStart)
    dateEnd = moment()
      .subtract(1, "weeks")
      .endOf("isoWeek")
      .format("YYYY-MM-DD");
    // console.log("last week dateEnd: ", dateEnd)
  } else if (selectedDateRange === "This Month") {
    // dateStart = "30daysAgo"
    // dateEnd = "today"
    dateStart = moment().startOf("month").format("YYYY-MM-DD");
    // console.log("this month dateStart: ", dateStart)
    dateEnd = moment().endOf("month").format("YYYY-MM-DD");
    // console.log("this month dateEnd: ", dateEnd)
  } else if (selectedDateRange === "Last Month") {
    // dateStart = "60daysAgo"
    // dateEnd = "30daysAgo"
    dateStart = moment()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    // console.log("last month dateStart: ", dateStart)
    dateEnd = moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
    // console.log("last month dateEnd: ", dateEnd)
  } else if (selectedDateRange === "This Year") {
    // dateStart = "365daysAgo"
    // dateEnd = "today"
    dateStart = moment().startOf("year").format("YYYY-MM-DD");
    // console.log("this year dateStart: ", dateStart)
    dateEnd = moment().endOf("year").format("YYYY-MM-DD");
    // console.log("this year dateEnd: ", dateEnd)
  } else if (selectedDateRange === "Last Year") {
    // dateStart = "730daysAgo"
    // dateEnd = "365daysAgo"
    dateStart = moment()
      .subtract(1, "year")
      .startOf("year")
      .format("YYYY-MM-DD");
    // console.log("last year dateStart: ", dateStart)
    dateEnd = moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD");
    // console.log("last year dateEnd: ", dateEnd)
  } else if (selectedDateRange === "All-time") {
    dateStart = "2020-01-01";
    dateEnd = "today";
  }

  dateRange.dateStart = dateStart;
  dateRange.dateEnd = dateEnd;

  return dateRange;
};
