/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

import PacmanWrapper from "../dashboard/PacmanWrapper";
import useFetch from "../../hooks/useFetch";

export default function ItemRanking({
  params = {},
  className = "",
  children = "",
}) {
  const ranking = ["1st", "2nd", "3rd", "4th"];

  const reqObj = {
    url: "/dashboard/item-ranking",
    method: "post",
    body: params,
  };

  const { data, isLoading } = useFetch(reqObj, {});
  const items = data || [];

  return (
    <>
      {isLoading || data === null ? (
        <PacmanWrapper />
      ) : (
        <div
          className={`card card-custom d-flex  ${className}`}
          style={{ fontSize: ".9rem" }}
        >
          {/* customer header */}
          {children}

          <div className="card-body align-self-stretch">
            {items.length === 0 || !items ? (
              <p className="text-center m-0 mt-12">
                We don't have any records yet.
              </p>
            ) : (
              <>
                {items.map((item, i) => (
                  <div className="pt-2 w-100 mr-8" key={item._id}>
                    <div className="d-flex align-items-center mb-4">
                      <span className="text-gray p-4 font-weight-bold w-25">
                        {ranking[i]}
                      </span>

                      <div className="d-flex w-75">
                        <div className="symbol symbol-40 mr-5">
                          <img
                            className="img-fluid"
                            // src={item.item_details[0].image_url}
                            // alt={item.item_details[0].name}
                            src={item.item_details.image_url}
                            alt={item.item_details.name}
                          />
                        </div>

                        <div className="d-flex flex-column font-weight-bold">
                          <span
                            // href="#"
                            className="text-dark text-hover-primary mb-1"
                          >
                            {/* {item.item_details[0].name} */}
                            {item.item_details.name}
                          </span>
                          <span className="text-muted">
                            Sold
                            <span className="text-dark-75 ml-4">
                              {item.soldQty}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
