import React from "react";
import { Button, Card, Col, Modal, Spinner } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import APIComponent from "../../components/API";
import {
  HeaderTextWithActions,
  InfoModal,
  Label,
  RequiredAsterisk,
} from "../../components/Base";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  generateOffDatesDisplay,
  generateStoreHoursDisplay,
  generateDefaultStoreHours,
  GetDataFromEvent,
  debounce,
} from "../../helpers";
import core from "../../vendors/core-api";
import { Link } from "react-router-dom";
import { EditOffDatesTable } from "../StoreSetup";
import HelperText from "../../helpertexts";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PRIMARY_COLOR } from "../../constants";
import { isObject } from "formik";
import moment from "moment";
import SetStoreHoursModal from "../SetStoreHoursModal";
import { validatePhoneNumber } from "../../helpers/phoneValidation";
import { MapComponent } from "../../components/widgets/GMapsWidget";
const MySwal = withReactContent(Swal);

export default class NewStore extends APIComponent {
  constructor(props) {
    super(props);
    this.api = core("pu").get();
    this.getGroup();
    this.getBrands();

    this.state = {
      groupName: "Group",
      brands: [],
      values: {
        brand: props.match.params.brand || "",
        name: "",
        store_url: "",
        location: "",
        contact_number: "",
        store_hours: generateDefaultStoreHours(),
        off_dates: [],
        mapPosition: { lat: "", lng: "" },
        markerPosition: { lat: "", lng: "" },
        currentPosition: { lat: "", lng: "" },
        delivery_address: "",
        zoomValue: 18,
      },
      showMapComponent: false,
      errors: {
        brand: "",
        name: "",
        store_url: "",
        location: "",
        contact_number: "",
        store_hours: "",
        off_dates: "",
      },
      touched: {
        brand: "",
        name: "",
        store_url: "",
        location: "",
        contact_number: "",
        store_hours: "",
        off_dates: "",
      },
      showSuccessModal: false,
      showStoreHoursModal: false,
      showOffDatesModal: false,
      storeUrl: { loading: false, status: "", message: "" },
      selectedBrandURL: "",
      renderTable: true,
    };

    this.checkBrandURL = debounce(this.checkBrandURL.bind(this), 1000);
    this.hasRestrictedChar = this.hasRestrictedChar.bind(this);
  }

  getGroup = async () => {
    try {
      const result = await this.api.get("/brand/get-group");
      if (result.data) {
        const groupName = result.data.name;
        this.setState({ groupName });
      }
    } catch (error) {
      console.log(error);
    }
  };

  getBrands = async () => {
    try {
      const result = await this.api.get("/brand/get-brands");
      if (result.data) {
        const brands = result.data.brands;
        const selected_brand = brands.filter((brand) => {
          return brand._id === this.props.match.params.brand;
        })[0];

        this.setState({
          brands,
          ...(selected_brand && { selectedBrandURL: selected_brand.brand_url }),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  checkBrandURL = async () => {
    try {
      if (this.state.values.store_url) {
        if (/\s/.test(this.state.values.store_url)) {
          this.setState((prevState) => {
            return {
              errors: {
                ...prevState.errors,
                store_url: "Spaces are not allowed.",
              },
            };
          });
          this.setState((prevState) => {
            return {
              storeUrl: { ...prevState.storeUrl, loading: false },
            };
          });
          // throw new Error("Spaces are not allowed.");
        } else {
          const { values = {} } = this.state;

          const result = await this.api.post({
            url: "/brand/" + values.brand + "/store-url-check",
            data: { store_url: this.state.values.store_url.trim() },
          });
          if (result.status === 200) {
            this.setState((prevState) => {
              return {
                storeUrl: {
                  ...prevState.storeUrl,
                  status: "OK",
                  message: "URL is available.",
                },
              };
            });
          }
          this.setState((prevState) => {
            return {
              storeUrl: { ...prevState.storeUrl, loading: false },
            };
          });
        }
      }
    } catch (error) {
      if (error.response.data.code === "DUPLICATE_BRAND_URL") {
        this.setState((prevState) => {
          return {
            storeUrl: {
              ...prevState.storeUrl,
              loading: false,
              message: "",
              status: "",
            },
          };
        });
        this.setState((prevState) => {
          return {
            errors: { ...prevState.errors, store_url: "URL is already taken." },
          };
        });
      } else if (error.response.data.code === "INVALID_URL") {
        this.setState((prevState) => {
          return {
            storeUrl: {
              ...prevState.storeUrl,
              loading: false,
              message: "",
              status: "",
            },
          };
        });
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              store_url:
                "Only lowercase letters, numbers, dash (-) and underscores (_) symbols are allowed.",
            },
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            errors: { ...prevState.errors, store_url: error.message },
            storeUrl: { ...prevState.storeUrl, loading: false },
          };
        });
      }
    }
  };

  saveStore = async () => {
    try {
      const isValid = this.validateForm();
      if (!isValid) {
        throw new Error("Please complete the form.");
      }
      this.setState({ loading: true });
      const store_url = this.state.values.store_url.trim().toLowerCase();
      const data = { ...this.state.values, store_url };
      const result = await this.api.post({ url: "/brand/new-store", data });
      if (result.data) {
        this.resetFields();
        this.setState({ showSuccessModal: true });
      }
    } catch (error) {
      let message = "Something went wrong";
      if (error.response.data) {
        if (isObject(error.response.data.message)) {
          message = "Something went wrong";
        } else {
          message = error.response.data.message;
        }
      } else {
        message = error.message;
      }
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: message,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
      this.setState({ loading: false });
      if (error.response.data.code === "DUPLICATE_BRAND_URL") {
        this.setState((prevState) => {
          return {
            errors: { ...prevState.errors, brand_url: "URL is already taken." },
          };
        });
      }
    }
    this.setState({ loading: false });
  };

  hasRestrictedChar = (value = "") => {
    const valSplit = value.split("");
    const restrictedCharsSplit = ["$", "."];
    let isInvalid = false;

    for (const char of restrictedCharsSplit) {
      if (valSplit.includes(char)) {
        isInvalid = true;
        break;
      }
    }

    return isInvalid;
  };

  onInputChange = (event) => {
    event.persist();
    this.setState(
      (prevState) => {
        return {
          values: {
            ...prevState.values,
            [event.target.name]: event.target.value,
          },
          touched: { ...prevState.touched, [event.target.name]: true },
        };
      },
      function () {
        if (
          event.target.name === "name" &&
          this.hasRestrictedChar(event.target.value)
        ) {
          return this.setState((prevState) => {
            return {
              errors: {
                ...prevState.errors,
                [event.target.name]: '"$" and "." is not allowed',
              },
            };
          });
        }
        if (!this.state.values[event.target.name]) {
          this.setState((prevState) => {
            return {
              errors: {
                ...prevState.errors,
                [event.target.name]: "This field is required.",
              },
            };
          });
        } else {
          this.setState((prevState) => {
            return {
              errors: { ...prevState.errors, [event.target.name]: "" },
            };
          });

          if (event.target.name === "contact_number") {
            const inputValue = event.target.value;
            const regEx = /[a-zA-Z]/g;
            const validRegx = regEx.test(String(inputValue));
            const valid = validatePhoneNumber(inputValue);

            if (!valid || validRegx) {
              this.setState((prevState) => {
                return {
                  errors: {
                    ...prevState.errors,
                    contact_number:
                      "Please enter a valid contact number. (ex.09123456789)",
                  },
                };
              });
            } else {
              this.setState((prevState) => {
                return {
                  errors: { ...prevState.errors, [event.target.name]: "" },
                };
              });
            }
          }
        }
      },
    );

    if (event.target.name === "store_url" && event.target.value.trim()) {
      this.setState((prevState) => {
        return {
          storeUrl: {
            ...prevState.storeUrl,
            loading: Boolean(event.target.value),
            status: "",
            message: "",
          },
        };
      });
      this.checkBrandURL();
    } else if (event.target.name === "brand") {
      const value = event.target.value;
      const { brands = [], values = {} } = this.state,
        brand = brands.filter((brand) => {
          return brand._id === value;
        });
      if (brand.length > 0) {
        this.setState({ selectedBrandURL: brand[0].brand_url });
      }

      if (values.store_url !== "") {
        this.setState((prevState) => {
          return {
            storeUrl: {
              ...prevState.storeUrl,
              loading: true,
              status: "",
              message: "",
            },
          };
        });
        this.checkBrandURL();
      }
    }
  };

  validateForm = () => {
    let isValid = true;
    for (const key of [
      "brand",
      "name",
      "store_url",
      "location",
      "contact_number",
      "delivery_address",
    ]) {
      const value = this.state.values[key] ? this.state.values[key].trim() : "";
      if (!value) {
        this.setState((prevState) => {
          return {
            errors: { ...prevState.errors, [key]: "This field is required." },
          };
        });
        isValid = false;
      } else {
        this.setState((prevState) => {
          return {
            errors: { ...prevState.errors, [key]: "" },
          };
        });
      }
    }
    if (this.state.showMapComponent) {
      this.setState((prevState) => {
        return {
          errors: {
            ...prevState.errors,
            delivery_address: "Address Pin is required to be set",
          },
        };
      });
      isValid = false;
    }

    return isValid;
  };

  resetFields = () => {
    for (const key of [
      "name",
      "store_url",
      "location",
      "contact_number",
      "brand",
      "delivery_address",
    ]) {
      this.setState((prevState) => {
        return {
          values: { ...prevState.values, [key]: "" },
          touched: { ...prevState.values, [key]: "" },
          errors: { ...prevState.values, [key]: "" },
        };
      });
    }

    this.setState({ storeUrl: { loading: false, status: "", message: "" } });
  };

  handleOnSelectAddDate = (date, setShowAddForm) => {
    let { values = {} } = this.state;
    values.off_dates.push(moment(date).format("YYYY-MM-DD"));
    this.setState({ values });

    setShowAddForm(false);
  };

  handleOnSelectDeleteDate = (date) => {
    let { values = {} } = this.state;
    values.off_dates = values.off_dates.filter((off_date) => off_date !== date);
    this.setState({ values });
  };

  handleOnChangeStoreHrs = (e) => {
    const name = GetDataFromEvent(e, "data-name"),
      index = GetDataFromEvent(e, "data-index"),
      value = e.target.value,
      checked = e.target.checked;

    let { values = {} } = this.state;

    values.store_hours = values.store_hours.map((sh, i) => {
      if (i === parseInt(index)) {
        if (name === "isOpen") sh[name] = checked;
        else sh[name] = value;
      }

      return sh;
    });

    this.setState({ values });
  };

  handleOnAddStoreHrs = (day) => {
    let { values = {} } = this.state,
      opening = "",
      closing = "";

    for (var i = 0; i < values.store_hours.length; i++) {
      if (day === values.store_hours[i].day) {
        opening = values.store_hours[i].closing;
        closing = "23:30";
      }
    }

    if (opening === closing) {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `You can't add another store hour slot, kindly adjust the latest closing hour of this day to enable.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      let newItem = {};
      newItem.day = day;
      newItem.opening = opening;
      newItem.closing = closing;
      newItem.isOpen = false;
      newItem._id = new Date().getTime().toString(); // temporary
      values.store_hours.push(newItem);

      this.setState({ values, renderTable: false }, () => {
        this.setState({ renderTable: true });
      });
    }
  };

  handleOnDeleteStoreHrs = (id, day) => {
    // check first if there will be remaining slots for that day
    let { values = {} } = this.state;

    const store_schedule_day = values.store_hours.filter(
      (item) => item.day === day,
    );

    if (store_schedule_day.length === 1) {
      // means it currently has only one slot, and this can't be deleted
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `You can't remove this store hour slot! There should be at least one store hour slot per day.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      MySwal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: `You are about to remove this store hour slot`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          values.store_hours = values.store_hours.filter(
            (item) => item._id !== id,
          );

          this.setState({ values, renderTable: false }, () => {
            this.setState({ renderTable: true });
          });
        }
      });
    }
  };

  render() {
    return (
      <div>
        {/* <Breadcrumbs>
                    <Breadcrumbs.Item text={this.state.groupName} link={`${this.props.match.params.mode==="webview"?"/webview":""}/stores`} />
                    <Breadcrumbs.Item text="New Store" active="true" link={`${this.props.match.params.mode==="webview"?"/webview":""}/stores/new-store`} />
                </Breadcrumbs> */}

        <HeaderTextWithActions
          title={"New Store"}
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item
                text={this.state.groupName}
                link={`${
                  this.props.match.params.mode === "webview" ? "/webview" : ""
                }/stores`}
              />
              <Breadcrumbs.Item
                text="New Store"
                active="true"
                link={`${
                  this.props.match.params.mode === "webview" ? "/webview" : ""
                }/stores/new-store`}
              />
            </Breadcrumbs>
          }
        />
        {/* <div style={{ height: "3rem" }}></div> */}

        <Card>
          <Card.Body>
            <Row>
              <Col sm={12}>
                {/* <Col sm={12} xl={8}> */}
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Label text="Brand" /> <RequiredAsterisk />
                      <Form.Control
                        as="select"
                        name="brand"
                        custom="true"
                        className={
                          this.state.errors.brand
                            ? "is-invalid"
                            : this.state.touched.brand
                            ? "is-valid"
                            : ""
                        }
                        value={this.state.values.brand}
                        onChange={this.onInputChange}
                      >
                        <option value="">Select brand</option>
                        {this.state.brands &&
                          this.state.brands.map((brand, index) => (
                            <option key={index} value={brand._id}>
                              {brand.name}
                            </option>
                          ))}
                      </Form.Control>
                      <div className="fv-plugins-message-container ml-1">
                        <div className="fv-helper-text">
                          <span>
                            Under which brand you want to add your store to.
                          </span>{" "}
                          <Link to="/stores/new-brand">Add brand</Link>
                        </div>
                        {this.state.errors.brand && (
                          <div className="fv-help-block">
                            {this.state.errors.brand}
                          </div>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Label text="Store URL" /> <RequiredAsterisk />
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            {this.state.selectedBrandURL}
                            {process.env.REACT_APP_DOMAIN}/
                          </span>
                        </div>
                        <Form.Control
                          type="text"
                          name="store_url"
                          placeholder="Store URL"
                          value={this.state.values.store_url}
                          className={`${
                            this.state.errors.store_url ? "is-invalid" : ""
                          } ${
                            this.state.storeUrl.status === "OK"
                              ? "is-valid"
                              : ""
                          }`}
                          onChange={this.onInputChange}
                          disabled={
                            this.state.values.brand === "" ? "disabled" : ""
                          }
                        />
                        {this.state.storeUrl.loading && (
                          <div className="mt-2 ml-3">
                            <Spinner animation="border" size="sm" />
                          </div>
                        )}
                      </div>
                      {this.state.errors.store_url && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {this.state.errors.store_url}
                          </div>
                        </div>
                      )}
                      {this.state.storeUrl.status === "OK" && (
                        <div className="fv-plugins-message-container">
                          <div className="text-success">
                            {this.state.storeUrl.message}
                          </div>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Label text="Store Name" /> <RequiredAsterisk />
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Store Name"
                        value={this.state.values.name}
                        className={
                          this.state.errors.name
                            ? "is-invalid"
                            : this.state.touched.name
                            ? "is-valid"
                            : ""
                        }
                        onChange={this.onInputChange}
                      />
                      <div className="fv-plugins-message-container">
                        {this.state.errors.name && (
                          <div className="fv-help-block">
                            {this.state.errors.name}
                          </div>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Label text="Location" /> <RequiredAsterisk />{" "}
                      <InfoModal
                        title="Address Pin"
                        body={HelperText["getting-started"].store_location}
                      />
                      <Form.Control
                        type="text"
                        name="location"
                        placeholder="Location"
                        value={this.state.values.location}
                        className={
                          this.state.errors.location
                            ? "is-invalid"
                            : this.state.touched.location
                            ? "is-valid"
                            : ""
                        }
                        onChange={this.onInputChange}
                      />
                      <div className="fv-plugins-message-container ml-1">
                        {this.state.errors.location && (
                          <div className="fv-help-block">
                            {this.state.errors.location}
                          </div>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Label text="Contact Number" /> <RequiredAsterisk />
                      <Form.Control
                        type="text"
                        name="contact_number"
                        placeholder="Contact Number"
                        value={this.state.values.contact_number}
                        className={
                          this.state.errors.contact_number
                            ? "is-invalid"
                            : this.state.touched.contact_number
                            ? "is-valid"
                            : ""
                        }
                        onChange={this.onInputChange}
                      />
                      <div className="fv-plugins-message-container ml-1">
                        {this.state.errors.contact_number && (
                          <div className="fv-help-block">
                            {this.state.errors.contact_number}
                          </div>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} xs={12}>
                    <Form.Group>
                      <Label
                        text={
                          <>
                            <span className="mr-2">
                              Address Pin <RequiredAsterisk />
                            </span>
                            <InfoModal
                              title="Address Pin"
                              body={HelperText["getting-started"].address_pin}
                            />
                          </>
                        }
                      />
                      <div className="fv-plugins-message-container ml-1">
                        {this.state.errors.delivery_address && (
                          <div className="fv-help-block">
                            {this.state.errors.delivery_address}
                          </div>
                        )}
                      </div>
                      {this.state.showMapComponent ? (
                        <>
                          <MapComponent
                            state={this.state}
                            height="400px"
                            zoom={this.state.values.zoomValue}
                            api={this.api}
                            tooltipDisplay={false}
                          />
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="danger"
                              onClick={() =>
                                this.setState({
                                  values: {
                                    ...this.state.values,
                                    delivery_address: "",
                                  },
                                  showMapComponent: false,
                                })
                              }
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="primary"
                              className="ml-1"
                              onClick={() => {
                                this.setState({
                                  showMapComponent: false,
                                });
                                this.setState((prevState) => {
                                  return {
                                    errors: {
                                      ...prevState.errors,
                                      delivery_address: "",
                                    },
                                  };
                                });
                              }}
                            >
                              Set Address Pin
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            {this.state.values.delivery_address === ""
                              ? "Store doesn't have an Address Pin."
                              : this.state.values.delivery_address}{" "}
                            <button
                              type="button"
                              className="btn btn-link px-0"
                              onClick={() =>
                                this.setState({ showMapComponent: true })
                              }
                            >
                              Edit
                            </button>
                          </div>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Label
                        text={
                          <>
                            <span className="mr-2">Store Hours</span>
                            <InfoModal
                              title="Store Hours"
                              body={HelperText["getting-started"].store_hours}
                            />
                          </>
                        }
                      />
                      <div>
                        <p className="mb-0">
                          {generateStoreHoursDisplay({
                            store_hours: this.state.values["store_hours"],
                          })}{" "}
                          <button
                            type="button"
                            className="btn btn-link px-0"
                            onClick={() =>
                              this.setState({ showStoreHoursModal: true })
                            }
                          >
                            Edit
                          </button>
                        </p>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Label
                        text={
                          <>
                            <span className="mr-2">Off Dates</span>
                            <InfoModal
                              title="Off Dates"
                              body={
                                HelperText["getting-started"].store_off_dates
                              }
                            />
                          </>
                        }
                      />
                      <div>
                        <p className="mb-0">
                          {generateOffDatesDisplay(
                            this.state.values["off_dates"],
                          )}{" "}
                          <button
                            type="button"
                            className="btn btn-link px-0"
                            onClick={() =>
                              this.setState({ showOffDatesModal: true })
                            }
                          >
                            Edit
                          </button>
                        </p>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <br />
                <div className="float-right">
                  <button
                    type="button"
                    disabled={
                      this.state.loading ||
                      Object.values(this.state.errors).filter(Boolean).length >
                        0
                    }
                    className={`btn btn-primary ${
                      this.state.loading ? "spinner spinner-right" : ""
                    }`}
                    onClick={this.saveStore}
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <SuccessModal
          show={this.state.showSuccessModal}
          onHide={() => this.setState({ showSuccessModal: false })}
        />

        {/* store hours modal */}
        {/* <Modal 
                    show={this.state.showStoreHoursModal}
                    onHide={() => {
                        this.setState({ showStoreHoursModal: false });
                    }}
                    centered
                    size="lg"
                >
                     <Modal.Header>
                        <Modal.Title>Set Store Hours</Modal.Title>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                this.setState({ showStoreHoursModal: false });
                            }}
                        >
                            <i aria-hidden="true" className="ki ki-close"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.renderTable ? <EditStoreHoursTable
                                store_hours={this.state.values["store_hours"]}
                                actions={{ onChange: this.handleOnChangeStoreHrs }}
                                handleOnAddStoreHrs={this.handleOnAddStoreHrs}
                                handleOnDeleteStoreHrs={this.handleOnDeleteStoreHrs}
                            /> : <></>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={() => {
                                this.setState({ showStoreHoursModal: false });
                            }}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal> */}

        <SetStoreHoursModal
          show={this.state.showStoreHoursModal}
          onHide={() => {
            this.setState({ showStoreHoursModal: false });
          }}
          data={this.state.values}
          onSubmit={(formValues, setSubmitting) => {
            setSubmitting(false);
            let { values = {} } = this.state;
            values.store_hours = formValues.store_hours;
            this.setState({ values, showStoreHoursModal: false });
          }}
        />

        {/* off dates modal */}
        <Modal
          show={this.state.showOffDatesModal}
          onHide={() => {
            /** do nothing */
          }}
          centered
        >
          <Modal.Header>
            <Modal.Title>Set Off Dates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditOffDatesTable
              off_dates={this.state.values["off_dates"]}
              actions={{
                onSelectAddDate: this.handleOnSelectAddDate,
                onSelectDeleteDate: this.handleOnSelectDeleteDate,
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ showOffDatesModal: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const SuccessModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} centered size={"sm"}>
      <Modal.Header>
        <Modal.Title>Store successfully created!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>You may go back to your store list or add another store.</span>
      </Modal.Body>
      <Modal.Footer>
        <Link to="/stores">
          <Button variant="primary">Go back to stores</Button>
        </Link>
        <Button variant="secondary" onClick={onHide}>
          Add another
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
