import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { FormItem } from "../../components/Base";
import { Button, Card, Form } from "react-bootstrap";
import { GetSubmitClassList } from "../../helpers";

export default class AboutPageContentForm extends ProSourceForm {
  constructor(props) {
    super(props);

    const { values = {}, field = "brand_description" } = this.props;

    this.state = {
      values: {
        brand_description: values[field],
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {};
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <Card className="card-custom gutter-b">
        <Card.Header>
          <Card.Title>About Us Content</Card.Title>
        </Card.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Card.Body>
            <FormItem
              label={"About Text"}
              name={"brand_description"}
              inputProps={{
                name: "brand_description",
                placeholder: "About Us",
                value: this.state.values["brand_description"],
                rows: 10,
              }}
              inputActions={inputActions}
              actions={actions}
              type={"textarea"}
              normal
            />
          </Card.Body>
          <Card.Footer>
            <Button
              variant="primary"
              type="submit"
              className={GetSubmitClassList(this.state.isSubmitting, "")}
            >
              Save Changes
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    );
  }
}
