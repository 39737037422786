import React from "react";

import { Redirect, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Datatable from "react-data-table-component";
import moment from "moment";
import { Row, Col, Card } from "react-bootstrap";

// import helpertexts from "../../helpertexts";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import { HeaderTextWithActions } from "../../components/Base";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";

export default function Page() {
  const { user } = useSelector((state) => state.auth);
  return <MainComponent user={user} />;
}

function Table({ data, progressPending = false }) {
  const [searchText, setSearchText] = React.useState("");
  const filteredItems =
    searchText !== ""
      ? data.filter((item) => {
          return (
            item.client.name.toLowerCase().indexOf(searchText.toLowerCase()) !==
            -1
          );
        })
      : data;

  return (
    <>
      <Datatable
        columns={[
          {
            name: "Group Name",
            selector: (row) => {
              // return row.name;
              return row.client.name;
            },
            format: (row) => {
              return (
                <Link
                  to={"/admin/groups/" + row._id}
                  className="font-weight-bolder text-primary"
                >
                  {row.client.name}
                </Link>
              );
            },
            sortable: true,
            style: "font-weight-bold",
          },
          {
            name: "Type",
            selector: (row) => {
              return row.group_type;
            },
            format: (row) => {
              const { group_type = "single" } = row;
              return <span className="text-capitalize">{group_type}</span>;
            },
          },
          {
            name: "Date Created",
            selector: (row) => {
              // return moment(row.date_created).format("MMM D, YYYY hh:mm A");
              return moment(row.date_created).format("MMM D, YYYY");
            },
          },
        ]}
        data={filteredItems}
        pagination
        noHeader
        highlightOnHover
        subHeader
        subHeaderWrap={false}
        subHeaderComponent={
          <>
            <Row>
              <Col>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="flaticon-search"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </>
        }
        progressPending={progressPending}
      />
    </>
  );
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Groups",
    };

    this.api = core("pu").get();
    this.data_url = "/client";
  }

  handleOnDataFetched = () => {
    let { data = [] } = this.state;
    this.setState({
      data: data.map((item) => {
        item.id = item._id;
        return item;
      }),
    });
  };

  render() {
    const { data = [], data_status = "" } = this.state,
      isLoading = data_status !== "fetched";

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <HeaderTextWithActions title={this.state.title} />

        <Card>
          <Card.Body>
            <Table data={data} progressPending={isLoading} />
          </Card.Body>
        </Card>
      </>
    );
  }
}
