import React from "react";
import { Modal, Form } from "react-bootstrap";
import styled from "styled-components";

import ProSourceForm from "../components/ProSourceForm";

const DivStyled = styled.div`
  height: 50vh;
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
  }
`;

class GAReferrerListModal extends ProSourceForm {
  constructor(props) {
    super(props);
  }

  handleOnClickItem = () => {
    console.log("click menu plan item state: ", this.state);
  };

  render() {
    const { show = false, onHide, data } = this.props;
    // console.log('delivery address modal props: ', this.props)
    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>Referrer Details</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form>
          <Modal.Body>
            {
              <DivStyled>
                <table className="table-bordered table-striped">
                  <tbody>
                    <tr className="font-weight-bolder">
                      <td className="px-2">Rank</td>
                      <td className="px-2">Count</td>
                      <td className="px-2">URL</td>
                    </tr>
                    {data.map((item, index) => {
                      return (
                        <tr className="font-weight-bold mb-2">
                          <td>{index + 1}</td>
                          <td>{item.value}</td>
                          <td>{item.name ? item.name : `(none)`}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </DivStyled>
            }
          </Modal.Body>
        </Form>
      </Modal>
    );
  }
}

export default GAReferrerListModal;
