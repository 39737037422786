import React from "react";
import { BasicComponent } from "./Base";
import InsertClassnameIfNotExisting from "../helpers";
import { Link } from "react-router-dom";

/***
 * 
 * 
 * <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
        <li class="breadcrumb-item">
            <a href="" class="text-muted">Features</a>
        </li>
        <li class="breadcrumb-item">
            <a href="" class="text-muted">Custom</a>
        </li>
        <li class="breadcrumb-item">
            <a href="" class="text-muted">Utilities</a>
        </li>
    </ul>
 */

class Breadcrumbs extends BasicComponent {
  constructor(props) {
    super(props);
    this.state = {
      defaultClass:
        "breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm",
    };
  }

  render() {
    const { className = "" } = this.props;
    return (
      <ul
        {...this.props}
        className={InsertClassnameIfNotExisting(
          this.state.defaultClass,
          className,
        )}
      >
        {this.props.children}
      </ul>
    );
  }
}

class Item extends BasicComponent {
  constructor(props) {
    super(props);
    this.state = { defaultClass: "breadcrumb-item" };
  }

  render() {
    const {
      className = "",
      link = "",
      text = "",
      active = null,
      linkProps = {},
    } = this.props;
    return (
      <li
        {...this.props}
        className={InsertClassnameIfNotExisting(
          this.state.defaultClass,
          className,
        )}
      >
        <Link to={link} className={active ? null : "text-muted"} {...linkProps}>
          {text}
        </Link>
      </li>
    );
  }
}

Breadcrumbs.Item = Item;

export default Breadcrumbs;
