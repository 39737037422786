import moment from "moment";
import React, { useEffect, useState, useSelector } from "react";
import core from "../../vendors/core-api";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  isCurbsideAvailable,
  IsDeliveryAvailable,
  isPickupAvailable,
  SVGIcon,
} from "../../components/Base";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import { Redirect } from "react-router-dom";
import { Button } from "react-bootstrap";
import { fetchData } from "../../helpers";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PRIMARY_COLOR } from "../../constants";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";
const MySwal = withReactContent(Swal);

export default function PlaceOrderSelectionPage(props) {
  const api = core("pu").get();

  const [brands, setBrands] = useState([]);
  const [group, setGroup] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [redirectTo, setRedirectTo] = useState(false);

  const generateBrandwebsiteUrl = async (storeId) => {
    const data_url = "/store/" + storeId + "/header-details";
    try {
      const result = await api.get(data_url);
      const brandwebsiteUrl =
        result.data.base_url +
        (result.data.store_url ? `/${result.data.store_url}` : ``) +
        `/in-store-order/` +
        props.user._id;
      return brandwebsiteUrl;
    } catch (error) {
      console.error(error);
    }
  };

  const handlePlaceOrder = async (store) => {
    const storeDetails = await fetchData("get", `/store/${store._id}`);

    const { data } = storeDetails;
    const dayToday = moment().format("d"),
      store_schedule_today = data.store_hours.filter(
        (sched) => sched.day === dayToday,
      );

    const currentTime = moment(new Date(), "HH:mm");
    const isStoreOpen = store_schedule_today.some((storeHour) => {
      const openingTime = moment(storeHour.opening, "HH:mm"),
        closingTime = moment(storeHour.closing, "HH:mm");
      return (
        currentTime.isBetween(openingTime, closingTime) && storeHour.isOpen
      );
    });

    if (isStoreOpen) {
      if (
        isPickupAvailable([data]) ||
        IsDeliveryAvailable([data]) ||
        isCurbsideAvailable([data])
      ) {
        const brandwebsiteUrl = await generateBrandwebsiteUrl(store._id);
        window.open(brandwebsiteUrl, "_blank");
      } else {
        MySwal.fire({
          icon: "warning",
          title: "No fulfillments available",
          text: "Please check this store's available fulfillment options in the store settings.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
        });
      }
    } else {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: "Sorry, this store is currently closed.",
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Ok",
      });
    }
  };

  const isPlaceOrderButtonDisabled = (store) => {
    if (store.website_status === "public") return false;
    return true;
  };

  useEffect(() => {
    const getStores = async () => {
      try {
        setLoading(true);
        const result = await api.get("/brand/get-stores?source=place-orders");
        if (result.data) {
          const brands = result.data.brands;
          // console.log("placeorderselectionpage getstores data: ", result.data)
          if (brands.length === 1) {
            if (brands[0].stores.length === 1) {
              if (
                brands[0].stores[0]._id &&
                brands[0].stores[0].website_status === "public"
              ) {
                // setRedirectTo(`/stores/${brands[0].stores[0]._id}/orders`);
                // console.log("placeorderselectionpage getstores props: ", props)

                // const brandwebsiteUrl = await generateBrandwebsiteUrl(brands[0].stores[0]._id)
                // window.open(brandwebsiteUrl, "_blank")

                const storeDetails = await fetchData(
                  "get",
                  `/store/${brands[0].stores[0]._id}`,
                );
                if (
                  isPickupAvailable([storeDetails.data]) ||
                  IsDeliveryAvailable([storeDetails.data]) ||
                  isCurbsideAvailable([storeDetails.data])
                ) {
                  const brandwebsiteUrl = await generateBrandwebsiteUrl(
                    brands[0].stores[0]._id,
                  );
                  window.open(brandwebsiteUrl, "_blank");
                } else {
                  MySwal.fire({
                    icon: "warning",
                    title: "No fulfillments available",
                    text: "Please check this store's available fulfillment options in the store settings.",
                    confirmButtonColor: PRIMARY_COLOR,
                    confirmButtonText: "Ok",
                  });
                }
              }
            }
          }

          setBrands(brands);
          setFilteredBrands(brands);
          setGroup(result.data.groupName);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getStores();
  }, []);

  const search = (evt) => {
    evt.persist();
    if (evt.target.value) {
      setFilteredBrands(() => {
        const filteredBrands = brands.filter((brand) => {
          let valid = false;
          if (
            brand.name.toLowerCase().indexOf(evt.target.value.toLowerCase()) >
            -1
          ) {
            valid = true;
          }
          const validStores = brand.stores.filter(
            (store) =>
              store.name.toLowerCase().indexOf(evt.target.value.toLowerCase()) >
              -1,
          );
          if (validStores.length > 0) {
            valid = true;
          }
          return valid;
        });
        return filteredBrands;
      });
    } else {
      setFilteredBrands(() => {
        const filteredBrands = [...brands];
        return filteredBrands;
      });
    }
  };

  return loading ? (
    <PacmanWrapper removeBackgroundColor />
  ) : redirectTo ? (
    <Redirect to={redirectTo} />
  ) : (
    <div>
      <div className="row">
        <div className="col-md-9 col-sm-12">
          <Breadcrumbs>
            <Breadcrumbs.Item text={group} />
            <Breadcrumbs.Item
              text="Place Orders"
              link={`/placeorders`}
              active="true"
            />
          </Breadcrumbs>
        </div>
        <div className="col-md-2 col-sm-6">
          <input
            type="text"
            placeholder="Search..."
            className="form-control"
            onChange={search}
          />
        </div>
      </div>
      <br />
      <br />
      <Accordion
        allowMultipleExpanded={true}
        allowZeroExpanded={true}
        preExpanded={["acc-0"]}
      >
        {filteredBrands &&
          filteredBrands.map((brand, index) => {
            return (
              <div key={index}>
                <AccordionItem uuid={`acc-${index}`}>
                  <AccordionItemHeading className="display-4 font-weight-bolder">
                    <AccordionItemButton>
                      <span className="pl-3">{brand.name}</span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <>
                      <div className="row" style={{ paddingLeft: "3rem" }}>
                        {brand.stores.map((store, index) => {
                          return store._id ? (
                            <div className="col-lg-3 col-md-4 col-xs-12 store-name-card d-flex flex-column">
                              <div
                                // className="col-lg-3 col-md-4 col-xs-12 store-name-card"
                                key={index}
                                // href={`/stores/${store._id}/orders`}
                              >
                                <span>
                                  <SVGIcon
                                    icon={`Shopping/Cart1`}
                                    variant="primary"
                                    className="mr-1"
                                  />
                                  <span
                                    className={`ml-1 ${
                                      store.website_status === "private"
                                        ? "text-danger"
                                        : ""
                                    }`}
                                  >
                                    {store.name}
                                  </span>
                                </span>
                              </div>
                              <Button
                                className="mt-4 align-self-center"
                                style={{ width: "80%" }}
                                onClick={() => handlePlaceOrder(store)}
                                disabled={isPlaceOrderButtonDisabled(store)}
                              >
                                Place Order
                              </Button>
                            </div>
                          ) : null;
                        })}
                      </div>
                      <br />
                    </>
                  </AccordionItemPanel>
                </AccordionItem>
                <br />
              </div>
            );
          })}
      </Accordion>
    </div>
  );
}
