import React from "react";
import { RootFlex, DFlex } from "./Base";

export default class PageCenter extends React.Component {
  render() {
    const { loginstyle } = this.props;
    return (
      <>
        <RootFlex>
          <DFlex
            className="login login-2 login-signin-on flex-row-fluid"
            style={loginstyle}
          >
            <DFlex className="flex-center flex-row-fluid">
              {this.props.children}
            </DFlex>
          </DFlex>
        </RootFlex>
      </>
    );
  }
}
