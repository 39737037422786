import React from "react";
import { Form, Modal, Row, Col, Card } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import ProSourceForm from "../components/ProSourceForm";
import core from "../vendors/core-api";
import {
  PRIMARY_COLOR,
  DB_DATE_FORMAT,
  RESERVATION_PRICE_MINIMUM,
} from "../constants";
import {
  GetSubmitClassList,
  generateReservationHoursDisplay,
  isPromoScopeAllowedForUserLevel,
} from "../helpers";
import { FormItem, Label } from "../components/Base";
import DatePicker from "react-datepicker";
import moment from "moment";
import SetReservationHoursModal from "./SetReservationHoursModal";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export default class EditStoreReservationDetailsModal extends ProSourceForm {
  constructor(props) {
    super(props);
    this.api = core("pu").get();

    const { data, store } = this.props;
    console.log(data);

    this.state = {
      values: {
        ...data,
        store: store._id,
        has_end_date: data.end_date !== "" ? true : false,
        hasUsageLimit:
          data.usage_limit !== "" && data.usage_limit !== null ? true : false,
      },
      isModalOpen: true,
      showReservationHoursModal: false,
      redirectTo: "",
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {
      required: [
        "reservation_name",
        "reservation_description",
        "reservation_type",
        "reservation_payment_type",
        "start_date",
        "min_guests",
      ],
    };

    this.onChangeActive = this.onChangeActive.bind(this);
    this.getIsDisabled = this.getIsDisabled.bind(this);
    this.getTimeOptions = this.getTimeOptions.bind(this);
    this.triggerValidations = this.triggerValidations.bind(this);
    this.blockInvalidChars = this.blockInvalidChars.bind(this);
    this.blockInvalidChars2 = this.blockInvalidChars2.bind(this);
    this.onBlurPrices = this.onBlurPrices.bind(this);
  }

  componentDidMount() {
    const { data } = this.props;
    const { values } = this.state;
    let { required } = this.validate;
    if (values.reservation_payment_type === "all_in") {
      required = required.filter((item) => item !== "per_guest_price");
      required.push("all_in_price");
      this.validate = { required };
    } else if (values.reservation_payment_type === "per_guest") {
      required = required.filter((item) => item !== "all_in_price");
      required.push("per_guest_price");
      this.validate = { required };
    }
    // console.log(data);
    // console.log("mount pre values: ", values);
    if (this.props.editMode === "copy") {
      values.reservation_name = "";
      values.reservation_description = "";
      // console.log("mount copy values: ", values);
      this.setState({ values: { ...data } });
    } else if (this.props.editMode === "edit") {
      values.reservation_name = data.reservation_name;
      values.reservation_description = data.reservation_description;
      // console.log("mount edit values: ", values);
      this.setState({ values: { ...data } });
    }
    if (values.usage_limit !== "" && values.usage_limit !== null) {
      this.setState({ values: { ...values, hasUsageLimit: true } });
    } else {
      this.setState({ values: { ...values, hasUsageLimit: false } });
    }
    if (values.end_date !== "") {
      this.setState({ values: { ...values, has_end_date: true } });
    } else {
      this.setState({ values: { ...values, has_end_date: false } });
    }
    // console.log("mount state: ", this.state.values);
    // console.log("mount props: ", this.props);
  }

  componentDidUpdate() {
    const { values, errors } = this.state;
    const { data } = this.props;
    let { required } = this.validate;
    if (values.reservation_payment_type === "all_in") {
      required = required.filter((item) => item !== "per_guest_price");
      required.push("all_in_price");
      this.validate = { required };
    } else if (values.reservation_payment_type === "per_guest") {
      required = required.filter((item) => item !== "all_in_price");
      required.push("per_guest_price");
      this.validate = { required };
    }
    if (values.has_end_date) {
      required.push("end_date");
      this.validate = { required };
    } else {
      required = required.filter((item) => item !== "end_date");
      this.validate = { required };
      delete errors["end_date"];
    }
    if (
      this.props.editMode === "copy" &&
      values.reservation_name === data.reservation_name &&
      values.reservation_description === data.reservation_description
    ) {
      values.reservation_name = "";
      values.reservation_description = "";
      this.setState({ values });
    }
    // else if (
    //   this.props.editMode === "edit" &&
    //   values.reservation_name === "" &&
    //   values.reservation_description === ""
    // ) {
    //   values.reservation_name = data.reservation_name;
    //   values.reservation_description = data.reservation_description;
    //   this.setState({ values });
    // }
    // console.log("update state: ", this.state);
    // console.log("update props: ", this.props);
  }

  validateStoreReservation = async (values = []) => {
    const { errors } = this.state;
    const { required } = this.validate;
    // console.log(values);

    required.forEach(async (name) => {
      await this.triggerValidations(name);
    });

    this.setState({ errors });
    // console.log("newErrors", errors);
    if (Object.keys(errors).length === 0) {
      return true;
    }
    return false;
  };

  onChangeNameDescription = (e) => {
    const { values, errors } = this.state;
    const name = e.target.name;
    if (name === "reservation_name") {
      values.reservation_name = e.target.value;
    } else if (name === "reservation_description") {
      values.reservation_description = e.target.value;
    }
    if (values[name] !== "") {
      delete errors[name];
    }
    this.setState({ values, errors });
    this.validateForm();
    this.onChange && this.onChange(e);
  };

  onChangeActive = (event) => {
    const name = event.target.name;
    let { values, errors } = this.state;

    values[name] = values[name] ? true : false;
    values[name] = !values[name];

    if (name === "hasUsageLimit" && values[name] === false) {
      delete errors["usage_limit"];
      values.usage_limit = "";
    }

    this.setState({ values });
    this.onChange && this.onChange(event);
  };

  getIsDisabled = () => {
    const { user } = this.props;
    const isScope = isPromoScopeAllowedForUserLevel(user.iam_role);
    return isScope;
  };

  onClickOnChangeDate = (date, field) => {
    const { errors } = this.state;
    if (date instanceof Date) {
      this.setFieldValue(field, moment(date).format(DB_DATE_FORMAT));
    }

    if (date !== "") {
      delete errors[field];
    } else {
      errors[field] = "This field is required";
    }
    this.setState({ errors });
    this.validateForm();
  };

  onChangeEventTime = (time, field) => {
    const { values } = this.state;
    this.setFieldValue(field, time);
    this.setState({ values: { ...values, event_time: time } });
  };

  blockInvalidChars = (e) => {
    return ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
  };

  blockInvalidChars2 = (e) => {
    return ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  onBlurPrices = () => {
    const { values = {} } = this.state;
    values.all_in_price = Number(values.all_in_price).toFixed(2);
    values.per_guest_price = Number(values.per_guest_price).toFixed(2);
  };

  onChangeNumberFields = (e) => {
    const name = e.target.name;
    this.setFieldValue(name, e.target.value);
    this.triggerValidations(name);
    this.validateForm();
    this.onChange && this.onChange(e);
  };

  triggerValidations = async (name) => {
    const { values, errors } = this.state;

    if (values[name] === "" || values[name] === undefined) {
      errors[name] = "This field is required";
    } else if (Number(values[name]) < 0) {
      errors[name] = "Value cannot be a negative number";
    } else if (Number(values[name]) === 0) {
      if (name === "max_guests" || name === "usage_limit") {
        delete errors[name];
      } else {
        errors[name] = "Value must be greater than 0";
      }
    } else if (name === "all_in_price" || name === "per_guest_price") {
      if (Number(values[name]) < RESERVATION_PRICE_MINIMUM) {
        errors[name] = `Please enter a valid value`;
      } else {
        delete errors[name];
      }
    } else if (name === "min_guests") {
      if (Number(values["max_guests"]) === 0) {
        delete errors[name];
      } else if (
        Number(values["max_guests"]) !== 0 &&
        Number(values[name]) > Number(values["max_guests"])
      ) {
        errors[name] =
          "Minimum number of guests cannot be greater than maximum";
      } else {
        delete errors[name];
        delete errors["max_guests"];
      }
    } else if (name === "max_guests") {
      if (Number(values[name]) === 0) {
        delete errors["min_guests"];
      } else if (
        Number(values[name]) !== 0 &&
        Number(values["min_guests"]) > Number(values[name])
      ) {
        errors["min_guests"] =
          "Minimum number of guests cannot be greater than maximum";
        if (Number(values[name]) !== 0 && errors[name]) {
          errors[name] = `Please enter a valid value`;
        }
      } else {
        delete errors["min_guests"];
        delete errors[name];
      }
    } else if (name === "usage_limit") {
      if (Number(values[name]) === 0 || values[name] !== "") {
        delete errors[name];
      } else {
        errors[name] = "This field is required";
      }
    } else {
      delete errors[name];
    }
    this.setState({ values, errors });
  };

  getTimeOptions = () => {
    let current = moment(new Date()).startOf("day"),
      end = moment(new Date()).endOf("day"),
      options = [];
    while (current.isSameOrBefore(end)) {
      options.push({
        text: current.format("hh:mm A"),
        value: current.format("HH:mm"),
      });
      current = current.add("30", "minutes");
    }

    return options;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { values } = this.state;
    const validated = await this.validateStoreReservation(values);

    if (!validated) {
      // console.log("validated:", validated);
      this.setState({ isSubmitting: false });
      return;
    } else {
      if (this.props.editMode === "edit") {
        this.handleSubmitEdit(values, this.setSubmitting);
      } else if (this.props.editMode === "copy") {
        this.handleSubmitCopy(values, this.setSubmitting);
      }
    }
    this.setState({ isSubmitting: false });
  };

  handleSubmitEdit = (values, setSubmitting) => {
    // console.log(values);
    const _id = values._id;
    this.api
      .put({ url: `/store-reservation/${_id}`, data: values })
      .then(({ data }) => {
        // console.log(data);
        if (data !== null) {
          this.setState({ isModalOpen: false });
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            timer: 2000,
          }).then(() => {
            setSubmitting(false);
            this.setState({
              isSubmitting: true,
              redirectTo: `/stores/${this.props.store._id}/settings/experiences`,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSubmitCopy = (values, setSubmitting) => {
    // console.log(values);
    delete values._id;
    this.api
      .post({ url: `/store-reservation/`, data: values })
      .then(({ data }) => {
        // console.log(data);
        if (data !== null) {
          this.setState({ isModalOpen: false });
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            timer: 2000,
          }).then(() => {
            setSubmitting(false);
            this.setState({
              isSubmitting: true,
              redirectTo: `/stores/${this.props.store._id}/settings/reservations`,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleOnChange = async (event) => {
    const target = event.target,
      value = target.value,
      name = target.name;
    let { values, errors } = this.state;
    if (values[name] !== "" || values[name].length !== 0) {
      delete errors[name];
    }

    values[name] = value;
    this.setState({ values, errors });
    this.setFieldValue(name, value);
    this.setStateKey("touched", name, "true");
    this.validateForm();

    // checks if afterOnChange function exists
    this.onChange && this.onChange(event);
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      },
      { show, onHide } = this.props;
    const {
      values = {},
      isSubmitting = false,
      isModalOpen,
      showReservationHoursModal,
    } = this.state;
    // console.log("reservations values", values);

    // let reservationName = "";
    // let reservationDescription = "";
    // if (this.props.editMode === "edit") {
    //   reservationName = this.state.values.reservation_name;
    //   reservationDescription = this.state.values.reservation_description;
    // } else if (this.props.editMode === "copy") {
    //   reservationName = "";
    //   reservationDescription = "";
    // }

    return (
      <>
        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} push={true} />
        ) : (
          <></>
        )}
        <Modal
          show={show && isModalOpen}
          onHide={onHide}
          centered
          className="modal-content-xl"
        >
          <Modal.Header>
            <Modal.Title>
              {this.props.editMode === "edit"
                ? "Edit Store Experience"
                : `Copy Store Experience - ${this.props.data.reservation_name}`}
            </Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              <i aria-hidden="true" className="ki ki-close"></i>
            </button>
          </Modal.Header>
          {/* <Form onSubmit={this.handleOnSubmit}> */}
          <Form onSubmit={this.onSubmit}>
            <Modal.Body>
              {/* Start UI Adjustment */}
              <Row>
                <Col sm={8}>
                  <Card className="card-custom border mt-4">
                    <Card.Title className="pb-0 mb-0">
                      <div className="flex flex-column flex-grow-1">
                        <h3 className="ml-8 mt-8 font-weight-bolder">
                          Experience
                        </h3>
                      </div>
                    </Card.Title>
                    <Card.Body>
                      <FormItem
                        label={"Experience Name"}
                        name={"reservation_name"}
                        inputProps={{
                          name: "reservation_name",
                          placeholder: "Experience Name",
                          value: this.state.values["reservation_name"],
                          // value: reservationName,
                          disabled: this.getIsDisabled(),
                        }}
                        inputActions={{
                          ...inputActions,
                          onChange: this.onChangeNameDescription,
                        }}
                        actions={actions}
                        showRequired={true}
                        normal
                      />
                      <FormItem
                        label={"Experience Description"}
                        name={"reservation_description"}
                        type={"textarea"}
                        inputProps={{
                          name: "reservation_description",
                          placeholder: "Description",
                          value: this.state.values["reservation_description"],
                          // value: reservationDescription,
                          disabled: this.getIsDisabled(),
                        }}
                        inputActions={{
                          ...inputActions,
                          onChange: this.onChangeNameDescription,
                        }}
                        actions={actions}
                        showRequired={true}
                        normal
                      />
                    </Card.Body>
                  </Card>
                  <Card className="card-custom border mt-4">
                    <Card.Title className="pb-0 mb-0">
                      <div className="flex flex-column flex-grow-1">
                        <h3 className="ml-8 mt-8 font-weight-bolder">
                          Experience Details
                        </h3>
                      </div>
                    </Card.Title>
                    <Card.Body>
                      <FormItem
                        label={"Experience Type"}
                        name={"reservation_type"}
                        customFormControl
                        custom={
                          <>
                            <Form.Control
                              as="select"
                              name={"reservation_type"}
                              custom="true"
                              {...inputActions}
                              className="text-capitalize"
                              value={this.state.values["reservation_type"]}
                            >
                              <option value="">Select type</option>
                              <option value="table">Table</option>
                              <option value="event">Event</option>
                            </Form.Control>
                          </>
                        }
                        inputActions={inputActions}
                        actions={actions}
                        showRequired={true}
                        normal
                      />
                      <Row>
                        {/* {this.state.values["reservation_type"] === "table" && (
                          <>
                            <Col>
                              <FormItem
                                label={"Days Accepting in Advance"}
                                name={"days_accepting_in_advanced_reservations"}
                                type={"number"}
                                inputProps={{
                                  name: "days_accepting_in_advanced_reservations",
                                  placeholder: "Days Accepting in Advance",
                                  value:
                                    this.state.values[
                                      "days_accepting_in_advanced_reservations"
                                    ],
                                  disabled: this.getIsDisabled(),
                                }}
                                inputActions={inputActions}
                                actions={actions}
                                showRequired={true}
                                normal
                              />
                            </Col>
                          </>
                        )} */}
                      </Row>
                      <Row>
                        <Col>
                          <FormItem
                            label={"Payment Type"}
                            name={"reservation_payment_type"}
                            customFormControl
                            custom={
                              <>
                                <Form.Control
                                  as="select"
                                  name={"reservation_payment_type"}
                                  custom="true"
                                  {...inputActions}
                                  className="text-capitalize"
                                  value={
                                    this.state.values[
                                      "reservation_payment_type"
                                    ]
                                  }
                                >
                                  <option value="">Select type</option>
                                  <option value="all_in">All In</option>
                                  <option value="per_guest">Per Guest</option>
                                </Form.Control>
                              </>
                            }
                            inputActions={inputActions}
                            actions={actions}
                            showRequired={true}
                            normal
                          />
                        </Col>
                        <Col>
                          {values.reservation_payment_type === "all_in" ? (
                            <FormItem
                              label={"All In Price"}
                              name={"all_in_price"}
                              inputProps={{
                                name: "all_in_price",
                                placeholder: "All In Price",
                                value: this.state.values["all_in_price"],
                                disabled: this.getIsDisabled(),
                              }}
                              inputActions={{
                                ...inputActions,
                                onChange: this.onChangeNumberFields,
                                onKeyDown: this.blockInvalidChars2,
                                onBlur: this.onBlurPrices,
                              }}
                              actions={actions}
                              type={"number"}
                              showRequired={true}
                              helperText={
                                <span
                                  className="text-muted"
                                  style={{ fontSize: "0.9rem" }}
                                >
                                  Price must be greater than P100.00
                                </span>
                              }
                              normal
                            />
                          ) : (
                            <FormItem
                              label={"Per Guest Price"}
                              name={"per_guest_price"}
                              inputProps={{
                                name: "per_guest_price",
                                placeholder: "Per Guest Price",
                                value: this.state.values["per_guest_price"],
                                disabled: this.getIsDisabled(),
                              }}
                              inputActions={{
                                ...inputActions,
                                onChange: this.onChangeNumberFields,
                                onKeyDown: this.blockInvalidChars2,
                                onBlur: this.onBlurPrices,
                              }}
                              actions={actions}
                              type={"number"}
                              showRequired={true}
                              helperText={
                                <span
                                  className="text-muted"
                                  style={{ fontSize: "0.9rem" }}
                                >
                                  Price must be greater than P100.00
                                </span>
                              }
                              normal
                            />
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card className="card-custom border mt-4">
                    <Card.Title className="pb-0 mb-0">
                      <div className="flex flex-column flex-grow-1">
                        <h3 className="ml-8 mt-8 font-weight-bolder">
                          Experience Conditions
                        </h3>
                      </div>
                    </Card.Title>
                    <Card.Body>
                      <Row>
                        <Col>
                          <FormItem
                            customFormControl
                            label={<span>Experience Hours</span>}
                            name={"reservation_hours"}
                            custom={
                              <div>
                                <p className="mb-0">
                                  {generateReservationHoursDisplay({
                                    store_hours:
                                      this.state.values["reservation_hours"],
                                  })}{" "}
                                  <button
                                    type="button"
                                    className="btn btn-link px-0"
                                    onClick={() => {
                                      this.setState({
                                        showReservationHoursModal: true,
                                      });
                                    }}
                                  >
                                    Edit
                                  </button>
                                </p>
                              </div>
                            }
                            actions={actions}
                            showRequired={true}
                          />
                          <SetReservationHoursModal
                            show={showReservationHoursModal}
                            onHide={() => {
                              this.setState({
                                showReservationHoursModal: false,
                              });
                            }}
                            data={this.state.values}
                            onSubmit={() => {
                              this.setState({
                                values,
                                showReservationHoursModal: false,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormItem
                            label={"Minimum Number of Guests"}
                            name={"min_guests"}
                            type={"number"}
                            inputProps={{
                              name: "min_guests",
                              placeholder: "Minimum Number of Guests",
                              value: this.state.values["min_guests"],
                              disabled: this.getIsDisabled(),
                              min: 1,
                            }}
                            inputActions={{
                              ...inputActions,
                              onChange: this.onChangeNumberFields,
                              onKeyDown: this.blockInvalidChars,
                            }}
                            actions={actions}
                            showRequired={true}
                            normal
                          />
                        </Col>
                        <Col>
                          <FormItem
                            label={"Maximum Number of Guests"}
                            name={"max_guests"}
                            type={"number"}
                            inputProps={{
                              name: "max_guests",
                              placeholder: "Maximum Number of Guests",
                              value: this.state.values["max_guests"],
                              disabled: this.getIsDisabled(),
                              min: 0,
                            }}
                            inputActions={{
                              ...inputActions,
                              onChange: this.onChangeNumberFields,
                              onKeyDown: this.blockInvalidChars,
                            }}
                            actions={actions}
                            showRequired={true}
                            helperText={
                              <span
                                className="text-muted"
                                style={{ fontSize: "0.9rem" }}
                              >
                                Set to <b>0</b> if no limit.
                              </span>
                            }
                            normal
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Label
                            text={
                              <>
                                <span className="text-capitalize">
                                  limit number of reservations of this kind
                                </span>{" "}
                              </>
                            }
                          />
                          <div>
                            <span className="switch switch-outline switch-icon switch-primary switch-sm">
                              <label>
                                <input
                                  type="checkbox"
                                  name="hasUsageLimit"
                                  disabled={false}
                                  checked={
                                    this.state.values.hasUsageLimit
                                      ? "checked"
                                      : null
                                  }
                                  {...inputActions}
                                  onChange={this.onChangeActive}
                                />
                                <span></span>
                              </label>
                            </span>
                          </div>
                        </Col>
                        <Col>
                          {this.state.values.hasUsageLimit && (
                            <FormItem
                              label={"Usage Limit"}
                              name={"usage_limit"}
                              type={"number"}
                              inputProps={{
                                name: "usage_limit",
                                placeholder: "Usage Limit",
                                value: this.state.values["usage_limit"],
                                disabled: this.getIsDisabled(),
                                min: 0,
                              }}
                              inputActions={{
                                ...inputActions,
                                onChange: this.onChangeNumberFields,
                                onKeyDown: this.blockInvalidChars,
                              }}
                              actions={actions}
                              showRequired={true}
                              helperText={
                                <span
                                  className="text-muted"
                                  style={{ fontSize: "0.9rem" }}
                                >
                                  Set to <b>0</b> if no limit.
                                </span>
                              }
                              normal
                            />
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>

                <Col sm={4}>
                  <Card className="card-custom border mt-4">
                    <Card.Title className="pb-0 mb-0">
                      <div className="flex flex-column flex-grow-1">
                        <h3 className="ml-8 mt-8 font-weight-bolder">
                          {this.state.values["reservation_type"] === "event"
                            ? "Event"
                            : "Reservation"}{" "}
                          Dates
                        </h3>
                      </div>
                    </Card.Title>
                    <Card.Body>
                      <Label text="Active" />{" "}
                      <div className="d-flex align-items-center">
                        <div className="pt-2">
                          <span className="switch switch-outline switch-icon switch-primary">
                            <label className="align-items-center">
                              <input
                                type="checkbox"
                                name="active"
                                checked={this.state.values.active}
                                value={this.state.values["active"]}
                                onChange={this.onChangeActive}
                                onBlur={this.handleOnBlur}
                                disabled={this.getIsDisabled()}
                              />
                              <span></span>
                              <div
                                className="ml-2"
                                style={{ fontSize: ".8rem" }}
                              >
                                {this.state.values.active ? "Yes" : "No"}
                              </div>
                            </label>
                          </span>
                        </div>
                      </div>
                      <hr />
                      <div className="text-dark font-weight-bolder text-uppercase pr-0 mb-2">
                        Active Dates
                      </div>
                      <FormItem
                        label={
                          this.state.values["reservation_type"] === "event"
                            ? "Event Start Date"
                            : "Start Date"
                        }
                        name={"start_date"}
                        customFormControl
                        custom={
                          <DatePicker
                            disabled={this.getIsDisabled()}
                            minDate={new Date()}
                            maxDate={
                              this.state.values["end_date"] === "" ||
                              this.state.values["end_date"] === undefined
                                ? null
                                : new Date(this.state.values["end_date"])
                            }
                            selected={
                              this.state.values["start_date"] === "" ||
                              this.state.values["start_date"] === undefined
                                ? null
                                : new Date(this.state.values["start_date"])
                            }
                            className="form-control"
                            onChange={(date) => {
                              this.onClickOnChangeDate(date, "start_date");
                            }}
                            placeholderText={`📅 Select start date`}
                            wrapperClassName={"width-100"}
                            dateFormat={"MMM dd, yyyy"}
                          />
                        }
                        inputActions={inputActions}
                        actions={actions}
                        showRequired={true}
                        normal
                      />
                      <Form.Group>
                        {this.state.values["reservation_type"] === "event" && (
                          <FormItem
                            label={"Event Time"}
                            name={"event_time"}
                            customFormControl
                            custom={
                              <>
                                <Form.Control
                                  as="select"
                                  name={"event_time"}
                                  custom="true"
                                  {...inputActions}
                                  className="text-capitalize"
                                  value={this.state.values["event_time"]}
                                >
                                  <SetEventTimeHours
                                    reservationHours={
                                      this.state.values["reservation_hours"]
                                    }
                                    getTimeOptions={this.getTimeOptions()}
                                    eventDate={this.state.values.start_date}
                                  />
                                </Form.Control>
                              </>
                            }
                            inputActions={inputActions}
                            actions={actions}
                            showRequired={true}
                            normal
                          />
                        )}
                      </Form.Group>
                      <Form.Group>
                        <Label
                          text={
                            <>
                              <span className="text-capitalize">
                                set end date
                              </span>{" "}
                            </>
                          }
                        />
                        <div>
                          <span className="switch switch-outline switch-icon switch-primary switch-sm">
                            <label>
                              <input
                                type="checkbox"
                                name="has_end_date"
                                disabled={false}
                                checked={
                                  this.state.values.has_end_date
                                    ? "checked"
                                    : null
                                }
                                {...inputActions}
                                onChange={this.onChangeActive}
                              />
                              <span></span>
                            </label>
                          </span>
                        </div>

                        <div>
                          {this.state.values.has_end_date && (
                            <FormItem
                              label={
                                this.state.values["reservation_type"] ===
                                "event"
                                  ? "Event End Date"
                                  : "End Date"
                              }
                              name={"end_date"}
                              customFormControl
                              custom={
                                <DatePicker
                                  disabled={this.getIsDisabled()}
                                  minDate={
                                    this.state.values["start_date"] !== "" ||
                                    this.state.values["start_date"] !==
                                      undefined
                                      ? new Date(
                                          this.state.values["start_date"],
                                        )
                                      : new Date()
                                  }
                                  selected={
                                    this.state.values["end_date"] === "" ||
                                    this.state.values["end_date"] === undefined
                                      ? null
                                      : new Date(this.state.values["end_date"])
                                  }
                                  className="form-control"
                                  onChange={(date) => {
                                    this.onClickOnChangeDate(date, "end_date");
                                  }}
                                  placeholderText={`📅 Select end date`}
                                  wrapperClassName={"width-100"}
                                  dateFormat={"MMM dd, yyyy"}
                                />
                              }
                              inputActions={inputActions}
                              actions={actions}
                              showRequired={true}
                              normal
                            />
                          )}
                        </div>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {/* End UI Adjustment */}
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                disabled={isSubmitting}
                className={GetSubmitClassList(
                  isSubmitting,
                  `btn btn-primary font-weight-bold`,
                )}
              >
                {isSubmitting ? "Saving changes..." : "Save changes"}
              </button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

function RenderTimeOptions({ time_options = [], start = "", end = "" }) {
  let add = start === "";
  return time_options.map(({ text = "", value = "" }, index) => {
    if (add && end !== "") add = value !== end;

    if (!add && start !== "") add = value === start;

    return add ? (
      <option value={value} key={index}>
        {text}
      </option>
    ) : (
      <></>
    );
  });
}

function SetEventTimeHours({
  reservationHours = [],
  getTimeOptions,
  eventDate,
}) {
  const current = new Date();
  const currentDay = current.getDay();
  const currentTime = moment(current).format("YYYY-MM-DD HH:mm");
  const start = reservationHours[currentDay].opening;
  const startTime = moment(`${eventDate} ${start}`).format("YYYY-MM-DD HH:mm");
  const end = reservationHours[currentDay].closing;
  const remainder = 30 - (moment(current).minute() % 30);
  const newStartTime = moment(current)
    .add(remainder, "minutes")
    .format("HH:mm");

  return (
    <RenderTimeOptions
      time_options={getTimeOptions}
      start={currentTime > startTime ? newStartTime : start}
      end={end}
      isClosing={false}
    />
  );
}
