import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
// import helpertexts from "../helpertexts";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import {
  HeaderTextWithActions,
  AnimateDiv,
  isPickupAvailable,
  IsDeliveryAvailable,
  isCurbsideAvailable,
} from "../../components/Base";
import Breadcrumbs from "../../components/Breadcrumbs";

import {
  fetchData,
  GetDataFromEvent,
  GetSubmitClassList,
  SortByKey,
  toPesoAmount,
} from "../../helpers";
import { Card, Row, Col, Button, Dropdown } from "react-bootstrap";
import {
  PRIMARY_COLOR,
  ASAP_DEFAULT_PREP_TIME_OPTION,
  DB_DATE_FORMAT,
} from "../../constants";
import AcceptOrderModal from "../../partials/AcceptOrderModal";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SelectionModal from "../../components/widgets/SelectionModal";
import DeliveryDetailsModal from "../../partials/DeliveryDetailsModal";
import CancelNotesModal from "../../partials/CancelNotesModal";

import DatePicker from "react-datepicker";
import moment from "moment";
import OrderCard from "../../partials/OrderCard";
import QuickOrderDetailsModal from "../../partials/QuickOrderDetailsModal";
import { isAccessAllowed } from "../../auth";
import PacmanLoading from "../../components/widgets/PacmanLoading/PacmanLoading";

import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";

const MySwal = withReactContent(Swal);

function getListClass(name = "", listSelected = "", general_data_status = "") {
  return name === listSelected
    ? "text-uppercase font-weight-bolder font-pickup-md col-padding primary-border-bottom"
    : `text-uppercase font-weight-bolder font-pickup-md col-padding font-hover-primary ${
        general_data_status === "fetched" ? "cursor-pointer" : ""
      }`;
}

// NOTE: Brand orders page now only showing orders from main store.

// export default function Page() {
//     const { id = "" } = useParams(), { user } = useSelector(state => state.auth);
//     return <HeaderComponent id={id} user={user}/>;
// }

export const statusOptions = [
  { value: "new", label: "New" },
  { value: "accepted", label: "Accepted" },
  { value: "ready", label: "Ready" },
];

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

export default function Page() {
  const params = useParams(),
    { user } = useSelector((state) => state.auth),
    { brand_url = "", store_id = "" } = params;
  return isAccessAllowed(user, { brand_url, store_id }) ? (
    <HeaderComponent user={user} id={store_id} {...params} />
  ) : (
    <Redirect to={"/error/error-v3"} />
  );
}

class HeaderComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      store_name: "",
    };

    this.data_url = "/store/" + this.props.id + "/header-details";
    this.api = core("pu").get();
  }

  handleOnDataFetched = () => {
    const { data = {} } = this.state,
      { name = "", brand_name = "" } = data;
    this.setState({ store_name: name, title: `${name} ≫ Orders`, brand_name });
  };

  setStoreName = (store_name = "") => {
    this.setState({ store_name, title: `${store_name} ≫ Orders` });
  };

  handleClickPlaceNewOrder = async () => {
    const storeDetails = await fetchData(
      "get",
      `/store/${this.state.data._id}`,
    );
    const { data } = storeDetails;
    const dayToday = moment().format("d"),
      store_schedule_today = data.store_hours.filter(
        (sched) => sched.day === dayToday,
      );

    const currentTime = moment(new Date(), "HH:mm");
    const isStoreOpen = store_schedule_today.some((storeHour) => {
      const openingTime = moment(storeHour.opening, "HH:mm"),
        closingTime = moment(storeHour.closing, "HH:mm");
      return (
        currentTime.isBetween(openingTime, closingTime) && storeHour.isOpen
      );
    });

    if (isStoreOpen) {
      if (
        isPickupAvailable([data]) ||
        IsDeliveryAvailable([data]) ||
        isCurbsideAvailable([data])
      ) {
        const brandwebsiteUrl =
          this.state.data.base_url +
          (this.state.data.store_url ? `/${this.state.data.store_url}` : ``) +
          `/in-store-order/` +
          this.props.user._id;
        window.open(brandwebsiteUrl, "_blank");
      } else {
        MySwal.fire({
          icon: "warning",
          title: "No fulfillments available",
          text: "Please check this store's available fulfillment options in the store settings.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
        });
      }
    } else {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: "Sorry, this store is currently closed.",
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Ok",
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    await this.getData();
    // console.log("perstoreorders HeaderComponent componentDidMount state: ", this.state)
    // console.log("perstoreorders HeaderComponent componentDidMount props: ", this.props)
  }

  componentDidUpdate() {
    // console.log("perstoreorders HeaderComponent componentDidUpdate state: ", this.state)
    // console.log("perstoreorders HeaderComponent componentDidUpdate props: ", this.props)
  }

  render() {
    const { data_status = "", data = {} } = this.state;

    return (
      <>
        {data_status !== "fetched" ? (
          <></>
        ) : (
          <>
            <HeaderTextWithActions
              className="w-100 justify-content-between"
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item text={this.state.brand_name} link={`/`} />
                  {this.props.user.iam_role !== "super_admin" && (
                    <Breadcrumbs.Item text="Orders" link="/orders" />
                  )}
                  <Breadcrumbs.Item
                    text={this.state.store_name}
                    link="#!"
                    active={"true"}
                  />
                </Breadcrumbs>
              }
              // title={"Orders"}
              title={
                <div className="d-flex flex-row align-items-center justify-content-between w-100 mb-2">
                  <div>Orders</div>
                  {this.props.user.privileges.includes("place-orders") ? (
                    <>
                      <Button
                        className="font-weight-bolder text-uppercase"
                        onClick={() => this.handleClickPlaceNewOrder()}
                      >
                        Place New Order
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              }
              subtitle={<></>}
            />

            <TitleHelmet title={this.state.title} />
            <MainComponent
              {...this.props}
              api={this.api}
              setStoreName={this.setStoreName}
              store={data}
            />
          </>
        )}
      </>
    );
  }
}

const item_order = ["new", "accepted", "ready"];

export function sortOrders(orders) {
  const timestamp_orders = SortByKey(orders, "date_created", false);
  const sorted_orders = SortByKey(timestamp_orders, "order_time", true);
  // const sorted_orders = SortByKey(orders, "order_time", true);
  const status_orders = sorted_orders.sort(
    (a, b) => item_order.indexOf(a.status) - item_order.indexOf(b.status),
  );
  return status_orders;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    const selected_date = moment(new Date()).format(DB_DATE_FORMAT);
    // const { store = {}} = this.props.user;
    const store_id = this.props.id;

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Orders",
      mountActionModals: false,
      listSelected: "orders_queue", // ["pre_orders", "completed_orders"]
      selected_date,
      store_id,
      order_fetch_status: "fetching",
      list: {},
      printButtonLoading: false,
      display_buttons: props.user.display_buttons,
      data_status: "fetching",
      general_data_status: "fetching",
      isDisabled: true,
      optionSelected: null,
      filteredArray: [],
      filterSelected: "search_filter",
      searchQuery: "",
    };

    this.api = this.props.api;

    this.handleOnClickUpdateBtn = this.handleOnClickUpdateBtn.bind(this);
    this.execUpdateStatus = this.execUpdateStatus.bind(this);
    this.execAcceptASAPOrder = this.execAcceptASAPOrder.bind(this);
    this.execDeliveryRefresh = this.execDeliveryRefresh.bind(this);
    this.execHailRider = this.execHailRider.bind(this);
    this.moveToOrderQueue = this.moveToOrderQueue.bind(this);

    this.handleError = this.handleError.bind(this);
    this.onClickReadyForPickupModal =
      this.onClickReadyForPickupModal.bind(this);
    this.onSubmitCancelOrder = this.onSubmitCancelOrder.bind(this);
    this.onSubmitOutForDelivery = this.onSubmitOutForDelivery.bind(this);
    this.onClickOrderListItem = this.onClickOrderListItem.bind(this);
    this.onClickOnChangeDate = this.onClickOnChangeDate.bind(this);
    this.handleOnClickUpdateBtnInModal =
      this.handleOnClickUpdateBtnInModal.bind(this);
    this.handleOnClickPrintOrders = this.handleOnClickPrintOrders.bind(this);
    this.getListData = this.getListData.bind(this);
  }

  async getListData({ store_id, listSelected = "", selected_date = "" }) {
    return new Promise((resolve, reject) => {
      this.api
        .get(
          `/store/${store_id}/orders?selector=${listSelected}&date=${selected_date}`,
        )
        .then((data) => resolve(data.data))
        .catch((err) => reject(err));
    });
  }

  async getData() {
    try {
      this._isMounted &&
        this.setState({
          data_status: "fetching",
          general_data_status: "fetching",
        });

      let { selected_date = "", store_id = "", listSelected = "" } = this.state;
      if (listSelected === "orders_queue") {
        // shift to today if selected list is orders_queue
        selected_date = moment(new Date()).format(DB_DATE_FORMAT);
      }

      if (listSelected === "orders_queue") {
        let orders_queue = await this.getListData({
          store_id,
          listSelected: "orders_queue",
          selected_date,
        });

        let list = { orders_queue, pre_orders: {}, completed_orders: {} };

        this.setState({
          filteredArray: list[listSelected],
          list,
          data: list[listSelected],
          data_status: "fetched",
          order_fetch_status: "fetched",
        });

        this.handleOnDataFetched();

        let [pre_orders, completed_orders] = await Promise.all([
          this.getListData({
            store_id,
            listSelected: "pre_orders",
            selected_date,
          }),
          this.getListData({
            store_id,
            listSelected: "completed_orders",
            selected_date,
          }),
        ]);

        list = { orders_queue, pre_orders, completed_orders };
        this.setState({ list, general_data_status: "fetched" });
      } else {
        let [orders_queue, pre_orders, completed_orders] = await Promise.all([
          this.getListData({
            store_id,
            listSelected: "orders_queue",
            selected_date,
          }),
          this.getListData({
            store_id,
            listSelected: "pre_orders",
            selected_date,
          }),
          this.getListData({
            store_id,
            listSelected: "completed_orders",
            selected_date,
          }),
        ]);

        let list = { orders_queue, pre_orders, completed_orders };

        this.setState({
          filteredArray: list[listSelected],
          list,
          data: list[listSelected],
          data_status: "fetched",
          order_fetch_status: "fetched",
          general_data_status: "fetched",
        });
        this.handleOnDataFetched();
      }
      this.handleOnDataFetched();
    } catch (error) {
      this._isMounted && this.setState({ error: error, showSwalError: true });
      this.handleOnDataFetchError();
    }
  }

  execDeliveryRefresh = async (order_id) => {
    try {
      const { orders } = this.state.data;
      const orderIndex = orders.findIndex((p) => p._id === order_id);
      orders[orderIndex].delivery["lastStatus"] = "REFRESHING";
      this.setState({
        data: { orders: orders },
      });
      const { data } = await this.api.put({
        url: "/orders/" + order_id + "/delivery-refresh",
      });
      orders[orderIndex] = data.updatedOrder;
      orders[orderIndex].multipartner_hailing = data.multipartner_hailing;

      const { optionSelected } = this.state;
      if (optionSelected !== null) {
        var filtered = [];

        for (var arr in orders) {
          for (var filter in optionSelected) {
            if (orders[arr].status == optionSelected[filter].value) {
              filtered.push(orders[arr]);
            }
          }
        }
        this.setState({
          filteredArray: { orders: filtered },
        });
      } else {
        this.setState({
          filteredArray: { orders: orders },
        });
      }
      return data;
    } catch (err) {
      console.log(err);
      this.handleError(err);
    }
  };
  execCheckDiscrepancy = async (order_id, callbackFunc) => {
    try {
      const { data } = await this.api.post({
        url: "/orders/" + order_id + "/delivery-discrepancy-check",
      });

      const { withDiscrepancy, quotations } = data;
      if (withDiscrepancy) {
        const discrepancyWarning = await MySwal.fire({
          icon: "warning",
          title: "Quotation discrepancy detected",
          html: `Detected a discrepancy in quotation <br/><b>${toPesoAmount(
            quotations.old,
          )}</b> to <b>${toPesoAmount(
            quotations.new,
          )}</b><br/> do you want to proceed?`,
          confirmButtonColor: "#F4521E", //PRIMARY_COLOR,
          confirmButtonText: "Yes, Proceed",
          showCancelButton: true,
          cancelButtonText: "Cancel",
        });
        if (discrepancyWarning.isConfirmed) {
          callbackFunc();
          return;
        }
        return;
      }
    } catch (err) {
      this.handleError(err);
    }
  };
  execHailRider = async (order_id, lastStatus) => {
    try {
      const { orders } = this.state.data;
      const orderIndex = orders.findIndex((p) => p._id === order_id);
      const hailFunction = async () => {
        try {
          orders[orderIndex].delivery["lastStatus"] = "REFRESHING";
          this.setState({
            data: { orders: orders },
          });
          // console.log("Hail rider swal confirmed");
          const { data } = await this.api.put({
            url: "/orders/" + order_id + "/delivery-hail",
          });
          orders[orderIndex] = data.updatedOrder;
          orders[orderIndex].multipartner_hailing = data.multipartner_hailing;
          if (data.isInsuffCredit === true) {
            MySwal.fire({
              icon: "warning",
              title: "Insufficient funds",
              text: "You have insufficient funds in your wallet. Please top up and rehail to proceed with delivery.",
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "Ok",
            });
          }

          const { optionSelected } = this.state;
          if (optionSelected !== null) {
            var filtered = [];

            for (var arr in orders) {
              for (var filter in optionSelected) {
                if (orders[arr].status == optionSelected[filter].value) {
                  filtered.push(orders[arr]);
                }
              }
            }
            this.setState({
              filteredArray: { orders: filtered },
            });
          } else {
            this.setState({
              filteredArray: { orders: orders },
            });
          }
          // console.log("Hail rider api result: ", data);
        } catch (err) {
          this.handleError(err);
        }
      };
      let messageText, messageTitle;
      if (lastStatus === undefined) {
        messageTitle = `Warning!`;
        messageText = `This order is set to Auto-Hail once the order status is Accepted or set to Ready.
                Are you sure you want to override the auto-hailing and commence hailing the rider now?`;
      } else {
        messageTitle = `Are you sure?`;
        messageText = `This delivery has been cancelled. Are you sure you want to hail a rider again?`;
      }
      const hailingWarning = await MySwal.fire({
        icon: "warning",
        title: messageTitle,
        text: messageText,
        confirmButtonColor: "#F4521E", //PRIMARY_COLOR,
        confirmButtonText: "Hail Rider",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        preConfirm: async () => {
          await this.execCheckDiscrepancy(order_id, hailFunction);
        },
        showLoaderOnConfirm: true,
      });
      if (hailingWarning.isConfirmed) {
        hailFunction();
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  moveToOrderQueue = async (order_id) => {
    try {
      const { orders } = this.state.data;
      const orderIndex = orders.findIndex((p) => p._id === order_id);
      if (orders[orderIndex].status === "new") {
        const result = await MySwal.fire({
          icon: "warning",
          title: "Are you sure?",
          text: `You are about to move order #${orders[orderIndex].transaction_number} to queue.`,
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Move Order to Queue",
          showCancelButton: true,
          cancelButtonText: "Cancel",
        });
        if (result.isConfirmed === true) {
          const { data } = await this.api.put({
            url: `/orders/${order_id}/move-order-queue`,
          });
          if (data && data.order_queue) {
            this.getData();
            MySwal.fire({
              icon: "success",
              title: "Success!",
              text: `Order successfully moved to queue`,
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "Ok",
              timer: 1000,
            });
          }
        }
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  handleOnDataFetched = () => {
    let { data = {}, listSelected = "", filteredArray } = this.state;
    if (listSelected === "completed_orders") {
      filteredArray.orders = SortByKey(
        filteredArray.orders,
        "transaction_number",
        true,
      );
    } else {
      filteredArray.orders = sortOrders(filteredArray.orders);
    }
    this.setState({ filteredArray });
  };

  handleError = (error) => {
    if (error.hasOwnProperty("response")) {
      const { data = {} } = error.response,
        { message = {} } = data;

      if (message.error === "TokenExpiredError") {
        window.location.href = "/logout";
      } else if (message.error === "UnauthorizedUserError") {
        window.location.href = "/error/error-v1";
      } else if (message.error === "OrderStatusNotSync") {
        MySwal.fire({
          icon: "error",
          title: "Order not synced",
          text: "Error on updating this order. Please refresh this page.",
        });
      } else {
        this.showErrorSwal();
        console.log(error);
      }
    } else {
      this.showErrorSwal();
      console.log(error);
    }
  };

  findItemById = (item_id) => {
    const { filteredArray, data_status } = this.state;

    if (data_status === "fetched") {
      const item = filteredArray.orders.filter((d) => d._id === item_id);
      if (item.length > 0) return item[0];
      else return {};
    } else {
      return {};
    }
  };

  handleOnClickUpdateBtn = (e) => {
    e.preventDefault();
    const value = GetDataFromEvent(e, "data-value"),
      id = GetDataFromEvent(e, "data-id");

    this.setState({
      mountActionModals: false,
      selected: this.findItemById(id),
    });

    setTimeout(() => {
      if (value === "accept") {
        this.setState({
          showAcceptAsapOrderModal: true,
          mountActionModals: true,
        });
      } else if (value === "reject") {
        this.setState({ showNotesModal: true, mountActionModals: true });
      } else if (
        value === "ready" ||
        value === "ready_pickup" ||
        value === "out_for_delivery_delivery" ||
        value === "ready_third_party_pickup" ||
        value === "ready_curbside_pickup" ||
        value === "ready_dine_in" ||
        value === "ready_delivery"
      ) {
        this.setState({ showOrderDetailsModal: true, mountActionModals: true });
      }
      // else if(value === "ready_delivery") {
      //     this.setState({ showReadyForDeliveryModal: true, mountActionModals: true})
      // }
    }, 50);
  };

  handleOnClickUpdateBtnInModal = (e) => {
    e.preventDefault();

    const value = GetDataFromEvent(e, "data-value");
    // console.log(value);
    if (
      value === "ready_pickup" ||
      value === "out_for_delivery_delivery" ||
      value === "ready_third_party_pickup" ||
      value === "ready_curbside_pickup" ||
      value === "ready_dine_in" ||
      value === "ready_delivery"
    ) {
      this.setState({ updateBtnLoading: true });
      this.execUpdateStatus("completed", "", (updateBtnLoading) => {
        this.setState({ updateBtnLoading });
      });
    } else if (value === "ready") {
      this.setState({ updateBtnLoading: true });
      this.execUpdateStatus("ready", "", (updateBtnLoading) => {
        this.setState({ updateBtnLoading });
      });
    }
  };

  handleOnClickPrintOrders = () => {
    let { selected_date = "", store_id = "", listSelected = "" } = this.state;
    if (listSelected === "pre_orders") {
      this.setState({ printButtonLoading: true });
      this.api
        .post({
          url: `/store/${store_id}/print-upcoming`,
          data: { order_date: selected_date },
        })
        .then(({ data }) => {
          const { result = "", file_url = "" } = data;
          if (result === "ok") {
            // this.setState({ })
            window.location.href = file_url;
          }
        })
        .catch(() => {
          this.handleError();
        })
        .finally(() => {
          this.setState({ printButtonLoading: false });
        });
    }
  };

  execUpdateStatus = (status = "", notes = "", setSubmitting = () => {}) => {
    const { selected = {} } = this.state;

    this.api
      .put({
        url: "/orders/" + selected._id + "/status",
        data: { status, notes, current_status: selected.status },
      })

      .then((response) => {
        // console.log("perstoreorders execupdatestatus response: ", response)
        if (response.data.isInsuffCredit) {
          MySwal.fire({
            icon: "warning",
            title: "Insufficient funds",
            text: "You have insufficient funds in your wallet. Please top up and rehail to proceed with delivery.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
          });

          this.getData();
        } else {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully updated this order.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            timer: 1000,
          });

          this.getData();
        }
        this.setState({ optionSelected: null });
      })
      .catch((err) => {
        this.handleError(err);
      })
      .finally(() => {
        setSubmitting(false);
        this.setState({
          showOrderDetailsModal: false,
          showAcceptAsapOrderModal: false,
          showCompletionSelectionModal: false,
          selected: {},
        });
      });
  };

  execAcceptASAPOrder = (
    minutes = ASAP_DEFAULT_PREP_TIME_OPTION,
    setSubmitting,
  ) => {
    const { selected = {} } = this.state;

    this.api
      .put({
        url: "/orders/" + selected._id + "/status",
        data: {
          status: "accepted",
          is_asap: true,
          minutes,
          current_status: selected.status,
        },
      })
      .then((response) => {
        if (response.data.isWithDiscrepancy) {
          MySwal.fire({
            icon: "warning",
            title: "Qutation discrepancy detected",
            text: "Theres a difference from the quotation auto hailing cancelled use hail rider button to manually hail",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
          });
          this.getData();
        } else if (response.data.isInsuffCredit) {
          MySwal.fire({
            icon: "warning",
            title: "Insufficient funds",
            text: "You have insufficient funds in your wallet. Please top up and rehail to proceed with delivery.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
          });

          this.getData();
        } else {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully updated this order.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            timer: 1000,
          });

          this.getData();
        }
        this.setState({ optionSelected: null });
      })
      .catch((err) => {
        this.handleError(err);
      })
      .finally(() => {
        setSubmitting(false);
        this.setState({ showAcceptAsapOrderModal: false, selected: {} });
      });
  };

  onClickReadyForPickupModal = (status = "") => {
    MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to set this order as ${
        status === "cancelled_no_show" ? "no show" : "completed"
      }.`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      // "reverseButtons": true
    }).then((result) => {
      if (result.value) {
        this.execUpdateStatus(status);
      }
    });
  };

  onSubmitCancelOrder = ({ refund_type = "", notes = "" }) => {
    this.setState({ cancelBtnLoading: true });
    this.execUpdateStatus(refund_type, notes, (cancelBtnLoading) => {
      this.setState({ cancelBtnLoading, showNotesModal: false });
    });
  };

  onSubmitOutForDelivery = (values = {}) => {
    this.setState({ btnLoading: true });
    this.execUpdateStatus("completed", JSON.stringify(values), (btnLoading) => {
      this.setState({ btnLoading, showReadyForDeliveryModal: false });
    });
  };

  async onClickOrderListItem(e) {
    const { general_data_status = "" } = this.state;
    if (general_data_status === "fetched") {
      this.setState({ isDisabled: false });
      const item = GetDataFromEvent(e, "data-item");
      const { list = {} } = this.state;
      await this.setState({
        filteredArray: list[item],
        listSelected: item,
        selected_date: moment(new Date()).format(DB_DATE_FORMAT),
      });
      this.getData();
      this.setState({ optionSelected: null, searchQuery: "" });
    } else {
      this.setState({ isDisabled: true });
      // const item = GetDataFromEvent(e, "data-item");
      // await this.setState({ data: { orders: [] }, listSelected: item });
      // await this.getData(); // getData for change in listSelected
    }
  }

  async onClickOnChangeDate(date) {
    await this.setState({
      selected_date: moment(date).format(DB_DATE_FORMAT),
      order_fetch_status: "fetching",
    });
    setTimeout(() => {
      this.getData();
    }, 50);
  }

  async componentDidMount() {
    this._isMounted = true;
    // console.log('Main', this.state.listSelected);
    await this.getData();
    // console.log("perstoreorders MainComponent componentDidMount state: ", this.state)
    // console.log("perstoreorders MainComponent componentDidMount props: ", this.props)
  }

  // componentDidUpdate () {
  //     console.log("perstoreorders MainComponent componentDidUpdate state: ", this.state)
  //     console.log("perstoreorders MainComponent componentDidUpdate props: ", this.props)
  // }

  handleChange = (selected) => {
    const { orders } = this.state.data;
    if (selected && selected.length > 0) {
      var filtered = [];

      for (var arr in orders) {
        for (var filter in selected) {
          if (orders[arr].status == selected[filter].value) {
            filtered.push(orders[arr]);
          }
        }
      }
      this.setState({
        filteredArray: { orders: filtered },
      });
    } else {
      this.setState({
        filteredArray: { orders: orders },
      });
    }

    this.setState({
      optionSelected: selected,
    });
  };

  handleFilterSelection = (eventkey) => {
    this.setState({
      filterSelected: eventkey,
      searchQuery: "",
      optionSelected: null,
    });
    this.handleChange(null);
  };

  handleOnChange = (event) => {
    this.setState({
      searchQuery: event.target.value,
    });
  };

  render() {
    const {
        data_status = "",
        showAcceptAsapOrderModal = false,
        selected = {},
        showCompletionSelectionModal = false,
        showReadyForDeliveryModal = false,
        showNotesModal = false,
        btnLoading = false,
        cancelBtnLoading = false,
        updateBtnLoading = false,
        mountActionModals = false,
        selected_date = "",
        listSelected = "",
        order_fetch_status = "",
        showOrderDetailsModal = false,
        list = {},
        general_data_status = "",
        display_buttons,
        filteredArray = "",
        filterSelected = "",
        searchQuery = "",
      } = this.state,
      isLoading = data_status !== "fetched";

    const actions = {
      handleOnClickUpdateBtn: this.handleOnClickUpdateBtn,
      handleOnClickUpdateBtnInModal: this.handleOnClickUpdateBtnInModal,
      execDeliveryRefresh: this.execDeliveryRefresh,
      execHailRider: this.execHailRider,
      moveToOrderQueue: this.moveToOrderQueue,
    };
    const keysToInclude = [
      "transaction_number",
      "guest_customer",
      "order_time",
      "status",
      "order_type",
      "isMealPlan",
      "is_asap",
      "curbside_pickup_details",
      "delivery",
    ];

    const normalizeValues = (key, value) => {
      let final_value = value;
      if (key === "is_asap" && value === false) final_value = "pre-order";
      if (key === "isMealPlan" && value === true) final_value = "meal plan";
      if (value === "third_party_pickup") final_value = "3rd party pickup";
      if (value === "curbside_pickup") final_value = "curbside pickup";
      return final_value;
    };
    const order_queue_count =
      list.hasOwnProperty("orders_queue") &&
      list.orders_queue.hasOwnProperty("orders")
        ? list.orders_queue.orders.length
        : 0;
    const preorder_queue_count =
      list.hasOwnProperty("pre_orders") &&
      list.pre_orders.hasOwnProperty("orders")
        ? list.pre_orders.orders.length
        : 0;

    return (
      <>
        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <Card>
          <Card.Body className="p-0">
            <Row>
              <Col xs={4}>
                <div
                  className={getListClass(
                    "orders_queue",
                    listSelected,
                    general_data_status,
                  )}
                  onClick={this.onClickOrderListItem}
                  data-item="orders_queue"
                  disabled={this.state.isDisabled}
                >
                  <span className="va-middle">
                    order <br /> queue
                  </span>
                  {isLoading ? (
                    <></>
                  ) : order_queue_count ? (
                    <span className="label label-danger ml-2 va-middle">
                      {order_queue_count}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
              <Col xs={4}>
                <div
                  className={getListClass(
                    "pre_orders",
                    listSelected,
                    general_data_status,
                  )}
                  onClick={this.onClickOrderListItem}
                  data-item="pre_orders"
                  disabled={this.state.isDisabled}
                >
                  {" "}
                  <br /> upcoming
                  {isLoading ? (
                    <></>
                  ) : preorder_queue_count ? (
                    <span className="label label-purple ml-2 va-middle">
                      {preorder_queue_count}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
              <Col xs={4}>
                <div
                  className={getListClass(
                    "completed_orders",
                    listSelected,
                    general_data_status,
                  )}
                  onClick={this.onClickOrderListItem}
                  data-item="completed_orders"
                  disabled={this.state.isDisabled}
                >
                  completed <br />
                  orders
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {listSelected == "orders_queue" && (
          <Card className="d-flex mt-4">
            <Card.Body className="d-flex p-2">
              <div className="d-flex flex-row w-100">
                <div
                  className="d-flex flex-column pr-3"
                  style={{ width: "95%" }}
                >
                  <div className="w-100">
                    {filterSelected == "filter_status" && (
                      <div>
                        {" "}
                        <ReactSelect
                          placeholder={"Filter by status"}
                          options={statusOptions}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          onChange={this.handleChange}
                          allowSelectAll={true}
                          value={this.state.optionSelected}
                          styles={{
                            option: (
                              styles,
                              { data, isDisabled, isFocused, isSelected },
                            ) => {
                              return {
                                ...styles,
                                cursor: isDisabled ? "not-allowed" : "default",
                                backgroundColor: isDisabled
                                  ? undefined
                                  : isSelected
                                  ? "#14C49B"
                                  : isFocused
                                  ? "#14C49B"
                                  : undefined,
                              };
                            },
                          }}
                        />
                      </div>
                    )}

                    {filterSelected === "search_filter" && (
                      <div>
                        <input
                          type={"search"}
                          className="form-control"
                          placeholder="Search"
                          onChange={this.handleOnChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className={"d-flex justify-content-end"}>
                    <Dropdown onSelect={this.handleFilterSelection}>
                      <Dropdown.Toggle style={{ backgroundColor: "#14C49B" }}>
                        {filterSelected == "search_filter"
                          ? "Search"
                          : filterSelected == "filter_status"
                          ? "Filter by status"
                          : "Choose Filter"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {filterSelected == "search_filter" ? (
                          <Dropdown.Item eventKey="filter_status">
                            Filter by status
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item eventKey="search_filter">
                            Search
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        )}

        {listSelected !== "orders_queue" ? (
          <Card className="mt-4">
            <Card.Body className="p-2">
              <div className="d-flex flex-row justify-content-space-between">
                <div>
                  {listSelected === "pre_orders" ? (
                    <Button
                      variant="primary"
                      onClick={this.handleOnClickPrintOrders}
                      className={GetSubmitClassList(
                        this.state.printButtonLoading,
                        "",
                      )}
                    >
                      PRINT
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
                <DatePicker
                  selected={new Date(selected_date)}
                  className="form-control"
                  onChange={this.onClickOnChangeDate}
                />
              </div>
            </Card.Body>
          </Card>
        ) : (
          <></>
        )}

        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          <AnimateDiv>
            {order_fetch_status === "fetched" ? (
              <>
                {mountActionModals ? (
                  <>
                    <QuickOrderDetailsModal
                      show={showOrderDetailsModal}
                      onHide={() => {
                        this.setState({ showOrderDetailsModal: false });
                      }}
                      order={selected}
                      actions={actions}
                      btnLoading={updateBtnLoading}
                      display_buttons={display_buttons}
                    />
                    <AcceptOrderModal
                      show={showAcceptAsapOrderModal}
                      onHide={() => {
                        this.setState({ showAcceptAsapOrderModal: false });
                      }}
                      onSubmit={(values, setSubmitting) => {
                        this.execAcceptASAPOrder(values.minutes, setSubmitting);
                      }}
                      order={selected}
                      store={this.props.store}
                      display_buttons={display_buttons}
                    />

                    <SelectionModal
                      show={showCompletionSelectionModal}
                      onHide={() => {
                        this.setState({ showCompletionSelectionModal: false });
                      }}
                      title={<>What do you want to do?</>}
                      options={[
                        {
                          title: "completed",
                          icon: "Navigation/Check",
                          desc: "",
                          onClick: () => {
                            this.onClickReadyForPickupModal("completed");
                          },
                        },
                        {
                          title: "mark as no show",
                          icon: "Navigation/Close",
                          desc: "",
                          onClick: () => {
                            this.onClickReadyForPickupModal(
                              "cancelled_no_show",
                            );
                          },
                        },
                      ]}
                    />

                    <DeliveryDetailsModal
                      show={showReadyForDeliveryModal}
                      onHide={() => {
                        this.setState({ showReadyForDeliveryModal: false });
                      }}
                      btnLoading={btnLoading}
                      onSubmit={this.onSubmitOutForDelivery}
                      display_buttons={display_buttons}
                    />

                    <CancelNotesModal
                      show={showNotesModal}
                      onHide={() => {
                        this.setState({ showNotesModal: false });
                      }}
                      onSubmit={this.onSubmitCancelOrder}
                      btnLoading={cancelBtnLoading}
                      order={selected}
                      display_buttons={display_buttons}
                    />
                  </>
                ) : (
                  <></>
                )}
                {filteredArray.hasOwnProperty("orders") &&
                filteredArray.orders.length > 0 ? (
                  filteredArray.orders
                    .filter((item) => {
                      const concatenatedValues = keysToInclude
                        .flatMap((key) => {
                          const value = item[key];
                          if (typeof value === "object" && value !== null) {
                            return Object.values(value);
                          }
                          const final_value = normalizeValues(key, value);
                          return final_value;
                        })
                        .join(" ")
                        .toLowerCase()
                        .trim(" ");

                      return concatenatedValues.includes(
                        searchQuery.toLowerCase().trim(" "),
                      );
                    })
                    .map((order, index) => {
                      return (
                        <OrderCard
                          {...this.props}
                          order={order}
                          actions={actions}
                          linkTo={`/orders/${order._id}`}
                          key={index}
                          display_buttons={display_buttons}
                          // onClick={() => { this.setState({ showOrderDetailsModal: true })}}
                        />
                      );
                    })
                ) : (
                  <Card className="mv-1rem">
                    <Card.Body>You don't have any orders.</Card.Body>
                  </Card>
                )}
              </>
            ) : (
              <div className="h-100 w-100 d-flex flex-column align-items-center">
                <PacmanLoading />
              </div>
            )}
          </AnimateDiv>
        )}
      </>
    );
  }
}
