import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import APIComponent from "../../../components/API";
import core from "../../../vendors/core-api";
import { HeaderText } from "../../../components/Base";
import moment from "moment";
import { RESERVATION_DATE_HEADER_FORMAT } from "../../../constants";
import { isAccessAllowed } from "../../../auth";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import Chart from "react-apexcharts";
import BWSection from "../../../components/widgets/BWSection";
import PacmanLoading from "../../../components/widgets/PacmanLoading/PacmanLoading";
import { Link } from "react-router-dom";

export default function Page() {
  const params = useParams(),
    { user } = useSelector((state) => state.auth),
    { brand_url = "", store_id = "" } = params;

  return isAccessAllowed(user, { brand_url, store_id }) ? (
    <ReservationsDashboard user={user} {...params} />
  ) : (
    <Redirect to={"/error/error-v3"} />
  );
}

class ReservationsDashboard extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      store_name: "",
      isReservationAvailable: true,
    };
    this.data_url = "reservation/brands/dashboard";
    this.api = core("pu").get();
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.getData();
  }

  constructTotalReservationsCount = (data = [], isForChartData = false) => {
    let reservations = 0,
      walk_in = 0,
      cancelled = 0,
      no_show = 0;
    if (data.length > 0) {
      const data_reservations = data
        .map(({ reservations }) => {
          return reservations;
        })
        .flat();
      reservations = data_reservations.filter(
        ({ reservation_status }) => reservation_status === "accepted",
      ).length;
      walk_in = data_reservations.filter(
        ({ reservation_status }) => reservation_status === "pending",
      ).length;
      cancelled = data_reservations.filter(
        ({ reservation_status }) => reservation_status === "canceled",
      ).length;
      no_show = data_reservations.filter(
        ({ reservation_status }) => reservation_status === "no_show",
      ).length;
    }
    return {
      reservations,
      walk_in,
      ...(!isForChartData && { cancelled }),
      ...(!isForChartData && { no_show }),
    };
  };

  render() {
    const { isReservationAvailable, data } = this.state;
    const currentDateTime = moment().format(RESERVATION_DATE_HEADER_FORMAT);

    const stores = ((data && data.data) || [])
      .map((brand) => {
        return brand.stores;
      })
      .flat();

    const storeReservations =
      stores.length > 0
        ? stores
            .map(({ _id, name, reservations }) => {
              return { id: _id, name, count: reservations.length };
            })
            .sort((a, b) => {
              return a.name < b.name ? -1 : 1;
            })
        : [];

    const brand_selected = !data
      ? null
      : data.brands && data.brands.length > 0
      ? data.brands[0]
      : data.brands;
    return (
      <MainContainer>
        <div>
          <a href="/reservations">* Switch Back to Previous Dashboard *</a>
        </div>
        <Row className="d-flex justify-content-space-between align-items-center">
          <HeaderText title={currentDateTime} />
          <AvailablityContainer className="d-flex align-items-center font-pickup-sm-1 justify-content-space-between px-3">
            <div className="d-flex flex-row">
              <OnlineIndicator
                color={isReservationAvailable ? "#00E33D" : "#FF0000"}
              />
              <span>
                <b>
                  Availability {isReservationAvailable ? "Enabled" : "Disabled"}{" "}
                </b>
              </span>
            </div>
            <a
              style={{ textDecoration: "underline" }}
              onClick={() =>
                this.setState({
                  isReservationAvailable: !isReservationAvailable,
                })
              }
            >
              Edit
            </a>
          </AvailablityContainer>
        </Row>
        <Row className="d-flex justify-content-space-between align-items-center">
          <TotalReservations
            data={this.constructTotalReservationsCount(stores || [])}
          />
          <ReservationsDiv data={storeReservations} />
        </Row>
        <Row className="d-flex justify-content-space-between align-items-center mt-5">
          <Container
            style={{ height: 100, width: "100%" }}
            className="flex-column"
          />
        </Row>
        <Row>
          {!stores && (
            <Container
              style={{ width: "100%" }}
              className="d-flex justify-content-center mt-5"
            >
              <PacmanLoading />
            </Container>
          )}
          {stores && (
            <ReservationSnapshots
              stores={stores}
              data={this.constructTotalReservationsCount(stores, true)}
            />
          )}
        </Row>
      </MainContainer>
    );
  }
}

const ReservationSnapshots = (props) => {
  const { data } = props;
  const { reservations = 0, walk_in = 0 } = data;
  const options = {
    chart: {
      id: "statPie",
      type: "radialBar",
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "45%",
        },
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#4D0AF5",
          startAngle: -90,
          endAngle: 90,
        },
        offsetX: 20,
        offsetY: 0,
        dataLabels: {
          show: true,
          name: {
            show: true,
            offsetY: -10,
          },
          value: {
            show: true,
            offsetY: -50,
            fontWeight: 700,
            fontSize: 36,
          },
          total: {
            show: true,
            label: "Total",
            formatter: (w) => {
              return (
                w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0) / w.globals.series.length
              );
            },
          },
        },
      },
    },
    grid: {
      padding: {
        top: -35,
        bottom: 20,
      },
    },
    fill: {
      opacity: 1,
      colors: ["#08DDB8"],
    },
  };

  return (
    <Container style={{ width: "100%" }} className="flex-column mt-5">
      <div className="d-flex flex-row pl-4 pt-3 font-size-h4 justify-content-space-between">
        <b>Today's Snapshot</b>
      </div>
      <div className="d-flex flex-row pl-7 pt-1 pb-3 justify-content-space-evenly">
        <Chart
          width={350}
          height={400}
          options={options}
          type="radialBar"
          series={[reservations + walk_in]}
        />
        <ChartLegendContainer data={data} />
      </div>
    </Container>
  );
};

const ChartLegendContainer = ({ data }) => {
  return (
    <div
      className="d-flex flex-column"
      style={{ flexBasis: "55%", height: "100%" }}
    >
      <div style={{ width: "70%" }}>
        <div className="d-flex flex-row">
          <Col className="d-flex text-uppercase font-weight-bolder">Status</Col>
          <Col className="d-flex text-uppercase font-weight-bolder justify-content-center">
            Reservations
          </Col>
        </div>
        <div className="d-flex flex-row mt-2">
          <Col className="d-flex font-weight-bolder">
            <div className="d-flex flex-row">
              <div
                className="d-flex bullet bullet-dot"
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#65DABA",
                  margin: 5,
                  marginLeft: 0,
                }}
              />
              <span>Walk-ins</span>
            </div>
          </Col>
          <Col className="d-flex font-weight-bolder justify-content-center">
            {data.walk_in}
          </Col>
        </div>
        <div className="d-flex flex-row">
          <Col className="d-flex font-weight-bolder">
            <div className="d-flex flex-row">
              <div
                className="d-flex bullet bullet-dot"
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#4D0AF5",
                  margin: 5,
                  marginLeft: 0,
                }}
              />
              <span>Booked</span>
            </div>
          </Col>
          <Col className="d-flex font-weight-bolder justify-content-center">
            {data.reservations}
          </Col>
        </div>
        <LegendDivider />
        <div className="d-flex flex-row">
          <Col className="d-flex font-weight-bolder">
            <div className="d-flex flex-row font-size-lg">
              <span>
                <b>Total</b>
              </span>
            </div>
          </Col>
          <Col className="d-flex text-uppercase font-weight-bolder justify-content-center">
            {data.reservations + data.walk_in}
          </Col>
        </div>
      </div>
    </div>
  );
};

const ReservationsDiv = ({ data = [] }) => {
  return (
    <Container className="flex-column" style={{ width: "60%" }}>
      <div className="d-flex flex-row pl-4 pt-3 font-size-h4">
        <b>Reservations</b>
      </div>
      <Col md={12}>
        <div className="bw-reservations">
          <BWSection className="py-3">
            <BWSection.Content>
              <div className="reservations-count-div position-sticky bg-white ">
                <nav className="position-sticky reservations-count-div-nav">
                  <ul
                    className="nav nav-pills flex-nowrap"
                    style={{ width: "100%" }}
                  >
                    {data.map(({ id, name, count }, i) => {
                      return (
                        <li
                          className={`flex-grow-1 flex-shrink-0 nav-item category-menu-nav-item mx-5`}
                          key={`count-${i}`}
                        >
                          <Link
                            to={`/stores/${id}/reservations/new`}
                            className="text-dark-75 font-weight-bold font-size-sm"
                          >
                            <ReservationCountDiv className="d-flex flex-column align-items-center">
                              <div className="d-flex flex-row font-weight-bolder display4 mb-0 d-block">
                                {count}
                              </div>
                              <div className="d-flex flex-row">
                                <div
                                  style={{
                                    maxWidth: data.length >= 4 ? 100 : 250,
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                  }}
                                >
                                  {name}
                                </div>
                              </div>
                            </ReservationCountDiv>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </BWSection.Content>
          </BWSection>
        </div>
      </Col>
    </Container>
  );
};

const TotalReservations = ({ data }) => {
  const { reservations = 0, walk_in = 0, cancelled = 0, no_show = 0 } = data;
  return (
    <Container className="flex-column" style={{ width: "39%" }}>
      <div className="d-flex flex-row pl-4 pt-3 font-size-h4">
        <b>Total Reservations</b>
      </div>
      <div className="d-flex flex-row justify-content-space-evenly py-3">
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex font-weight-bolder display4 mb-0">
            {reservations}
          </div>
          <div className="d-flex">Reservations</div>
        </div>
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex font-weight-bolder display4 mb-0">
            {walk_in}
          </div>
          <div className="d-flex">Walk-ins</div>
        </div>
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex font-weight-bolder display4 mb-0">
            {cancelled}
          </div>
          <div className="d-flex">Cancelled</div>
        </div>
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex font-weight-bolder display4 mb-0">
            {no_show}
          </div>
          <div className="d-flex">No Show</div>
        </div>
      </div>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
`;

export const MainContainer = styled.div`
  padding: 0 25px;
`;

const LegendDivider = styled.div`
  height: 1px;
  background-color: #707070;
  margin: 20px 50px 10px 10px;
`;

const OnlineIndicator = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
`;

const AvailablityContainer = styled(Container)`
  width: 300px;
  height: 40px;
  margin-top: -20px;
`;

const ReservationCountDiv = styled.div`
  &:focus,
  &:hover {
    color: #14c49b;
    transition: all 0.15s ease !default;
  }
`;
