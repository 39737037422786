import React from "react";
import ProSourceForm from "../components/ProSourceForm";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { FormItem } from "../components/Base";
import { GetSubmitClassList } from "../helpers";
import { BusinessAddress } from "../components/PaymongoWizard";

export default class AdditionalBeneficiaryOwnerModal extends ProSourceForm {
  constructor(props) {
    super(props);

    this.state = {
      values: props.values || {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        percent_own: "",
        business_address_l1: "",
        business_address_l2: "",
        business_address_city: "",
        business_address_state: "",
        business_address_zip: "",
        business_address_country: "Philippines",
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {
      required: [
        "first_name",
        "last_name",
        "date_of_birth",
        "percent_own",
        "business_address_l1",
        "business_address_l2",
        "business_address_city",
        "business_address_state",
        "business_address_zip",
        "business_address_country",
      ],
    };

    this.getInputValue = this.getInputValue.bind(this.getInputValue);
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  getInputValue = (field = "") => {
    return this.state.values[field];
  };

  render() {
    const {
      show = false,
      onHide,
      btnLoading = false,
      header = "",
      handleOnSubmit,
    } = this.props;
    // const {idx, owner= {}, saveEditedOwner} = selectedOwner;

    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>{header}</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form onSubmit={handleOnSubmit}>
          <Modal.Body>
            <h4 className="text-info">What’s a beneficial owner?</h4>
            <p>
              A beneficial owner is an individual who owns at least 20% of the
              business or is in a position to exercise significant control over
              the business's finances and operations (CEO, CFO, COO or similar).
            </p>
            <Row>
              <Col xs={12}>
                <h5 className="mt-8">Full name of beneficial owner</h5>
              </Col>
              <Col xs={6}>
                <FormItem
                  name={"first_name"}
                  inputProps={{
                    name: "first_name",
                    placeholder: "First Name",
                    // value: this.state.values["first_name"],
                    value: this.getInputValue("first_name"),
                  }}
                  inputActions={inputActions}
                  actions={actions}
                  normal
                  showRequired
                  noLabel
                />
              </Col>
              <Col xs={6}>
                <FormItem
                  name={"last_name"}
                  inputProps={{
                    name: "last_name",
                    placeholder: "Last Name",
                    // value: this.state.values["last_name"],
                    value: this.getInputValue("last_name"),
                  }}
                  inputActions={inputActions}
                  actions={actions}
                  normal
                  showRequired
                  noLabel
                />
              </Col>
              <Col xs={12}>
                <h5>Date of birth of beneficial owner</h5>
              </Col>
              <Col xs={12}>
                <FormItem
                  solid
                  type={"date"}
                  name={"date_of_birth"}
                  inputProps={{
                    name: "date_of_birth",
                    placeholder: "Date of Birth",
                    // value: this.state.values["date_of_birth"],
                    value: this.getInputValue("date_of_birth"),
                  }}
                  inputActions={inputActions}
                  actions={actions}
                  normal
                  showRequired
                  noLabel
                />
              </Col>

              <Col xs={12}>
                <h5>Percentage they own</h5>
              </Col>
              <Col xs={12}>
                <FormItem
                  solid
                  type={"number"}
                  name={"percent_own"}
                  inputProps={{
                    name: "percent_own",
                    placeholder: "%",
                    // value: this.state.values["percent_own"],
                    value: this.getInputValue("percent_own"),
                    min: 20,
                    max: 100,
                  }}
                  inputActions={inputActions}
                  actions={actions}
                  normal
                  showRequired
                  noLabel
                />
              </Col>
              <Col xs={12}>
                <BusinessAddress
                  getError={this.getError}
                  header={"Address of beneficial owner"}
                  actions={actions}
                  inputActions={inputActions}
                  values={this.state.values}
                  includeOverseas={true}
                />

                <FormItem
                  customFormControl
                  // label={"Bank"}
                  name={"business_address_country"}
                  className={"form-control-solid"}
                  solid
                  actions={actions}
                  type={"text"}
                  showRequired={true}
                  noLabel
                  normal
                  custom={
                    <>
                      <Typeahead
                        className="form-control-solid"
                        placeholder={"Country"}
                        onChange={(selected) => {
                          if (selected !== undefined && selected.length > 0) {
                            this.handleOnChange({
                              target: {
                                name: "business_address_country",
                                value: selected[0],
                              },
                            });
                          } else {
                            this.handleOnChange({
                              target: {
                                name: "business_address_country",
                                value: "Philippines",
                              },
                            });
                          }
                        }}
                        options={this.props.countries}
                        allowNew={false}
                        id={"countries_ta"}
                        // onBlur={OnBlurBank}
                        onBlur={() =>
                          this.handleOnBlur({
                            target: { name: "business_address_country" },
                          })
                        }
                        isValid={
                          this.isTouched("business_address_country") &&
                          this.getError("business_address_country") === ""
                            ? true
                            : false
                        }
                        isInvalid={
                          this.getError("business_address_country") !== ""
                        }
                        // defaultInputValue={this.state.values.business_address_country}
                        defaultInputValue={
                          this.state.values.business_address_country
                        }
                        // newSelectionPrefix={"New Category: "}
                      />
                    </>
                  }
                />
              </Col>
            </Row>
            {/* </Form> */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              // type="submit"
              block
              variant="primary"
              className={` font-size-lg ${GetSubmitClassList(btnLoading, "")}`}
              disabled={btnLoading ? "disabled" : null}
              onClick={() => {
                // this.setAllFieldsToTouched();
                // this.handleFormValidation();
                // const errors = this.validateForm();

                // if(!errors && errors.length > 0){
                //     this.props.onSubmit(this.state.values, false);
                //     this.props.onHide();
                // }

                this.props.onSubmit(this.state.values, false);
                this.props.onHide();
              }}
            >
              Add beneficial owner
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
