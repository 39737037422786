/** helper functions under settings */

export function getBrands(stores = []) {
  let brands = [];
  for (var i = 0; i < stores.length; i++) {
    const { brand = "" } = stores[i];
    if (!brands.includes(brand)) brands.push(brand);
  }
  return brands;
}
