import React, { Component } from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { RootFlex, DFlex } from "./Base";
import { Link } from "react-router-dom";

class LoginCenter extends Component {
  render() {
    return (
      <>
        <RootFlex>
          <DFlex className="login login-2 login-signin-on flex-row-fluid">
            <DFlex className="flex-center flex-row-fluid">
              {this.props.children}
            </DFlex>
          </DFlex>
        </RootFlex>
      </>
    );
  }
}

class Logo extends Component {
  render() {
    return (
      <>
        <Link to="/" className="login-logo text-center pt-10 pb-10">
          <img
            alt="Logo"
            className="max-h-70px"
            src={toAbsoluteUrl("/media/logos/logo-dark.png")}
          />
        </Link>
      </>
    );
  }
}

class Form extends Component {
  render() {
    return (
      <>
        <div className="login-form p-7 position-relative overflow-hidden">
          <DFlex className="flex-column-auto flex-column px-30">
            <Logo />
          </DFlex>
          {this.props.children}
        </div>
      </>
    );
  }
}

LoginCenter.Form = Form;

export default LoginCenter;
