import React, { Component } from "react";

export class BrandLogo extends Component {
  render() {
    const {
      alt = "Brand Logo",
      disable_change_photo = false,
      style = {},
    } = this.props;

    return (
      <>
        <div>
          {" "}
          <img
            src={this.props.src}
            className="display-brand-logo"
            alt={alt}
            style={style}
          />{" "}
        </div>
        {disable_change_photo ? (
          <></>
        ) : (
          <button
            type="button"
            className="btn btn-link px-0"
            onClick={this.props.onClickRemove}
          >
            Change Photo
          </button>
        )}
      </>
    );
  }
}
