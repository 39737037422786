import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { Modal, Form, Button, Card, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import {
  fetchData,
  getToolTitleFromToolName,
  removeStringSpaces,
  showConfirmationSwal,
} from "../../helpers";
import ConfirmPasswordModal from "../../partials/ConfirmPasswordModal";
import { FormItem } from "../../components/Base";

class AdminToolsStoreToolModal extends ProSourceForm {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        old_store_name: "",
        new_store_name: "",
        old_store_url: "",
        new_store_url: "",
        delete_store_note: "",
        restore_store_note: "",
      },
      url: "",
      showPasswordConfirmModal: false,
      errors: [],
      touched: {},
      redirectTo: "",
      isSubmitting: false,
      isChecking: false,
    };
  }

  handleOnClickItem = (itemUrl, newHistory) => {
    let { url } = this.state;
    url = itemUrl;
    this.setState({ url });
    newHistory.push(itemUrl);
  };

  handleClearForms = () => {
    const { values } = this.state;
    values.old_store_name = "";
    values.new_store_name = "";
    values.old_store_url = "";
    values.new_store_url = "";
    values.delete_store_note = "";
    values.restore_store_note = "";
    this.setState({ values });
  };

  handleCloseModal = () => {
    this.props.onHide();
    this.handleClearForms();
  };

  handleToggleMultiStore = () => {
    const { values } = this.state;
    if (!values.is_multi_store_active) values.is_multi_store_active = true;
    // values.is_multi_store_active = !values.is_multi_store_active;
    this.setState({ values });
  };

  handleSwitchToolConfirm = async (values, setSubmitting) => {
    const { toolName } = this.props;
    switch (toolName) {
      case "change_store_name":
        this.handleOnConfirmChangeStoreName(values, setSubmitting);
        break;
      case "change_store_url":
        this.handleOnConfirmChangeStoreUrl(values, setSubmitting);
        break;
      case "delete_store":
        this.handleOnConfirmDeleteStore(values, setSubmitting);
        break;
      case "restore_store":
        this.handleOnConfirmRestoreStore(values, setSubmitting);
        break;
      default:
        console.log("no tool selected");
    }
  };

  handleOnConfirmChangeStoreName = async (values, setSubmitting) => {
    const { new_store_name } = this.state.values;
    const { data = [] } = this.props;
    values.old_store_name = data.name;
    values.new_store_name = new_store_name;
    const response = await fetchData(
      "put",
      `/admintools/stores/${data._id}/change_store_name`,
      { values },
    );
    const successMessage = "Store Name Updated Successfully";
    showConfirmationSwal(response, successMessage);
    values.confirm_password = "";
    this.setState({ showPasswordConfirmModal: false });
    if (response.data.result !== "error") {
      this.handleCloseModal();
      this.props.handleRefreshStore();
      this.handleClearForms();
    }
    setSubmitting(false);
  };

  handleOnConfirmChangeStoreUrl = async (values, setSubmitting) => {
    const { new_store_url } = this.state.values;
    const { data = [] } = this.props;
    values.old_store_url = data.store_url;
    values.new_store_url = new_store_url;

    const response = await fetchData(
      "put",
      `/admintools/stores/${data._id}/change_store_url`,
      { values },
    );
    const successMessage = "Store Url Updated Successfully";
    showConfirmationSwal(response, successMessage);
    values.confirm_password = "";
    this.setState({ showPasswordConfirmModal: false });
    if (response.data.result !== "error") {
      this.handleCloseModal();
      this.props.handleRefreshStore();
      this.handleClearForms();
    }
    setSubmitting(false);
  };

  handleOnConfirmDeleteStore = async (values, setSubmitting) => {
    const { delete_store_note } = this.state.values;
    values.delete_store_note = delete_store_note;
    const { data = [] } = this.props;
    const response = await fetchData(
      "post",
      `/admintools/stores/${data._id}/delete_store`,
      { values },
    );
    const successMessage = "Store Deleted Successfully";
    showConfirmationSwal(response, successMessage);
    values.confirm_password = "";
    this.setState({ showPasswordConfirmModal: false });
    if (response.data.result !== "error") {
      this.handleCloseModal();
      this.props.handleRefreshStore();
      this.handleClearForms();
    }
    setSubmitting(false);
  };

  handleOnConfirmRestoreStore = async (values, setSubmitting) => {
    const { restore_store_note } = this.state.values;
    values.restore_store_note = restore_store_note;
    const { data = [] } = this.props;
    const response = await fetchData(
      "put",
      `/admintools/stores/${data._id}/restore_store`,
      { values },
    );
    const successMessage = "Store Restored Successfully";
    showConfirmationSwal(response, successMessage);
    values.confirm_password = "";
    this.setState({ showPasswordConfirmModal: false });
    if (response.data.result !== "error") {
      this.handleCloseModal();
      this.props.handleRefreshStore();
      this.handleClearForms();
    }
    setSubmitting(false);
  };

  isProceedButtonDisabled = () => {
    const { toolName } = this.props;
    const { values } = this.state;

    if (toolName === "change_store_name") {
      if (!values.new_store_name) return true;
    }
    if (toolName === "change_store_url") {
      if (!values.new_store_url) return true;
    }
    if (toolName === "delete_store") {
      if (!values.delete_store_note) return true;
    }
    if (toolName === "restore_store") {
      if (!values.restore_store_note) return true;
    }
    return false;
  };

  setConfirmMessageFromToolName = (toolName) => {
    const { values } = this.state;
    switch (toolName) {
      case "change_store_name":
        return (
          <>
            <span>{`You are changing the store name to `}</span>
            <span className="text-danger font-weight-bolder">
              {values.new_store_name}.
            </span>
          </>
        );
      case "change_store_url":
        return (
          <>
            <span>{`You are changing the store url to `}</span>
            <span className="text-danger font-weight-bolder">
              {values.new_store_url}.
            </span>
          </>
        );
      case "delete_store":
        return `You are deleting this store.`;
      case "restore_store":
        return `You are restoring this store.`;
      default:
        return `${toolName}`;
    }
  };

  onChange = (event) => {
    const name = event.target.name,
      value = event.target.value,
      { values = {}, touched = {}, errors = {} } = this.state;

    if (name === "new_store_url") {
      if (value.search(/[^-a-z0-9_]/) !== -1) {
        values.new_store_url = removeStringSpaces(
          value.replace(/[^-a-z0-9_]/g, ""),
        );
        this.setState(
          { values, touched, nameIsValid: false, nameIsInvalid: false },
          () => {
            // this.onBlurName(value);
          },
        );
      } else {
        errors.name =
          "New store url should only contain lower-case alphanumeric characters, '_', and '-'.";
        this.setState({
          touched,
          errors,
        });
      }
    }
  };

  render() {
    const { show = false, onHide, toolName, data = [] } = this.props;
    const { showPasswordConfirmModal } = this.state;

    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    onsubmit = (e) => {
      e.preventDefault();
      this.setState({ showPasswordConfirmModal: true });
    };

    return (
      <>
        <ConfirmPasswordModal
          show={showPasswordConfirmModal}
          onHide={() => {
            this.setState({ showPasswordConfirmModal: false });
          }}
          body={
            <>
              <p className="text-dark">
                {this.setConfirmMessageFromToolName(toolName)}
              </p>
            </>
          }
          onSubmit={this.handleSwitchToolConfirm}
        />

        <Modal
          show={show}
          onHide={this.handleCloseModal}
          className="modal-content-xl"
          centered
        >
          <Modal.Header>
            <Modal.Title>{getToolTitleFromToolName(toolName)}</Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleCloseModal}
            >
              <i aria-hidden="true" className="ki ki-close"></i>
            </button>
          </Modal.Header>
          <Form onSubmit={this.onSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  {toolName === "restore_store" ? (
                    <>
                      <DeletedStoreDataForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  ) : (
                    <>
                      <StoreDataForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  )}
                </Col>
                <Col>
                  {toolName === "change_store_name" ? (
                    <>
                      <ChangeStoreNameForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {toolName === "change_store_url" ? (
                    <>
                      <ChangeStoreURLForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {toolName === "delete_store" ? (
                    <>
                      <DeleteStoreForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {toolName === "restore_store" ? (
                    <>
                      <RestoreStoreForm
                        data={data}
                        values={this.state.values}
                        inputActions={inputActions}
                        actions={actions}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <Card className="border card-custom mt-4">
                    <Card.Title className="pb-0 mb-0"></Card.Title>
                    <Card.Body>
                      <Button
                        type="submit"
                        className="btn-block text-uppercase font-weight-bold"
                        disabled={this.isProceedButtonDisabled()}
                      >
                        Proceed
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </>
    );
  }
}

class StoreDataForm extends AdminToolsStoreToolModal {
  render() {
    const { data, values, inputActions, actions } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body className="d-flex flex-column align-items-start">
            <img
              className="align-self-center mb-4"
              src={
                data.brand && data.brand.brand_logo ? data.brand.brand_logo : ""
              }
              alt={data.name}
              style={{
                width: "5.5rem",
              }}
            />
            <div>
              <span className="text-muted">Store Name: </span>
              <span className="font-weight-bolder">{data.name}</span>
            </div>
            <div>
              <span className="text-muted">Brand Name: </span>
              <span className="font-weight-bolder">
                {data.brand && data.brand.name ? data.brand.name : "-"}
              </span>
            </div>
            <div>
              <span className="text-muted">Store URL: </span>
              <span className="font-weight-bolder">{data.store_url}</span>
            </div>
            <div>
              <span className="text-muted">Brand URL: </span>
              <span className="font-weight-bolder">
                {data.brand && data.brand.brand_url
                  ? data.brand.brand_url
                  : "-"}
              </span>
            </div>
            <div>
              <span className="text-muted">Location: </span>
              <span className="font-weight-bolder">
                {data.location ? data.location : "-"}
              </span>
            </div>
          </Card.Body>
        </Card>
      </>
    );
  }
}

class DeletedStoreDataForm extends AdminToolsStoreToolModal {
  render() {
    const { data } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body className="d-flex flex-column align-items-start">
            <div>
              <span className="text-muted">Store Name: </span>
              <span className="font-weight-bolder">{data.origin_key}</span>
            </div>
            <div>
              <span className="text-muted">Store ID: </span>
              <span className="font-weight-bolder">{data.origin_id}</span>
            </div>
            <div>
              <span className="text-muted">Date Deleted: </span>
              <span className="font-weight-bolder">{data.date_created}</span>
            </div>
          </Card.Body>
        </Card>
      </>
    );
  }
}

class ChangeStoreNameForm extends AdminToolsStoreToolModal {
  render() {
    const { data, values, inputActions, actions } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body>
            <FormItem
              label={"Old Store Name"}
              name={"old_store_name"}
              inputProps={{
                name: "old_store_name",
                // placeholder: "Old Store Name",
                // value: values["old_store_name"],
                value: data.name,
                disabled: true,
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              normal
            />
            <FormItem
              label={"New Store Name"}
              name={"new_store_name"}
              inputProps={{
                name: "new_store_name",
                placeholder: "New Store Name",
                value: values["new_store_name"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}

class ChangeStoreURLForm extends AdminToolsStoreToolModal {
  render() {
    const { data, values, inputActions, actions } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body>
            <FormItem
              label={"Old Store Url"}
              name={"old_store_url"}
              inputProps={{
                name: "old_store_url",
                // placeholder: "Old Store Url",
                // value: values["old_store_url"],
                value: data.store_url,
                disabled: true,
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              normal
            />
            <FormItem
              label={"New Store Url"}
              name={"new_store_url"}
              inputProps={{
                name: "new_store_url",
                placeholder: "New Store Url",
                value: values["new_store_url"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
            />
            <div className="mt-0 small font-italic text-muted">{`New store url should only contain lower-case alphanumeric characters, '_', and '-'.`}</div>
          </Card.Body>
        </Card>
      </>
    );
  }
}

class DeleteStoreForm extends AdminToolsStoreToolModal {
  render() {
    const { data, values, inputActions, actions } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body>
            <FormItem
              label={"Note"}
              name={"delete_store_note"}
              inputProps={{
                name: "delete_store_note",
                placeholder: "Note",
                value: values["delete_store_note"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}

class RestoreStoreForm extends AdminToolsStoreToolModal {
  render() {
    const { data, values, inputActions, actions } = this.props;
    return (
      <>
        <Card className="border card-custom mt-4">
          <Card.Title className="pb-0 mb-0"></Card.Title>
          <Card.Body>
            <FormItem
              label={"Note"}
              name={"restore_store_note"}
              inputProps={{
                name: "restore_store_note",
                placeholder: "Note",
                value: values["restore_store_note"],
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default withRouter(AdminToolsStoreToolModal);
