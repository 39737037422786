import React from "react";
import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import { Block, HeaderTextWithActions } from "../../components/Base";
import { Card } from "react-bootstrap";
import Breadcrumbs from "../../components/Breadcrumbs";
import HelpImage from "../../components/widgets/HelpImage";

export default function HelpPageTopic(props) {
  const { id = "" } = useParams(),
    { user } = useSelector((state) => state.auth);

  return <MainComponent id={id} user={user} {...props} />;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: this.props.title,
      data: {},
    };

    this.api = core("pu").get();
    this.data_url = this.props.data_url;
  }

  async componentDidMount() {
    await this.api.get(this.data_url).then((result) => {
      this.setState({ data: result.data });
    });
  }

  render() {
    const { data = {} } = this.state;
    const topicBlock = data.topicBlocks;
    return (
      <>
        <TitleHelmet title={this.state.title} />
        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}
        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        {Object.keys(data).length > 0 && (
          <>
            <HeaderTextWithActions
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item
                    text="Help"
                    link={`${this.props.webview ? "/webview" : ""}/help`}
                  />
                  <Breadcrumbs.Item
                    text={this.state.title}
                    active={"true"}
                    link={data.label}
                  />
                </Breadcrumbs>
              }
              title={this.state.title}
            />
            <Card className="card-custom mv-1rem">
              <Card.Body>
                {topicBlock !== undefined ? (
                  topicBlock.map((item) => {
                    return (
                      <Block
                        key={item.blockNumber}
                        title={item.title}
                        content={
                          <>
                            {
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              />
                            }
                            {item.image.length > 0 ? (
                              item.image.map((imageItem) => {
                                return (
                                  <HelpImage
                                    src={imageItem.src}
                                    alt={imageItem.alt}
                                    key={imageItem.alt}
                                  />
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </>
                        }
                      />
                    );
                  })
                ) : (
                  <></>
                )}
              </Card.Body>
            </Card>
          </>
        )}
        <Link to={`${this.props.webview ? "/webview" : ""}/help`}>
          <span className="font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2 text-primary text-nowrap">
            ← Back to Help Center
          </span>
        </Link>
      </>
    );
  }
}
