import React, { Component } from "react";
import moment from "moment";
import qs from "qs";
import { Dropdown, InputGroup, FormControl } from "react-bootstrap";
import ReportsApi from "./reports/ReportsApi";
import { HeaderTextWithActions } from "../../components/Base";
import GroupDropdown from "../../components/dashboard/GroupDropdown";
import ReportsTable from "../../components/ReportsTable";
import core from "../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import { useSelector } from "react-redux";
import { DB_DATE_FORMAT, DATERANGE_LIST } from "../../constants";
import { capitalizeText } from "../../../app/helpers";
import Breadcrumbs from "../../components/Breadcrumbs";
import ExportCSV from "../../components/ExportCSV";
import PaginationBar from "../../components/PaginationBar";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";

const getIdsAndAttr = function (props, selectedView = {}) {
  const { role = "", brands = [] } = props;
  const { id = "", attr = "brand" } = selectedView;

  if (role === "brand_admin") return { id: id || brands[0], attr };

  return { id: id || brands, attr: attr || "brand" };
};

export default function Page(props) {
  const { brand_url = "", mode = "" } = useParams(),
    {
      user,
      user: { brands = [] },
    } = useSelector((state) => state.auth);
  const {
    filter = "",
    duration = "day",
    date = moment().format(DB_DATE_FORMAT),
    view = "",
    attr = "",
    page = 1,
    limit = 30,
    deliveryOnly = false,
  } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  return (
    <MainComponent
      id={brand_url}
      view={view}
      attr={attr}
      user={user}
      role={user.iam_role}
      filter={filter}
      duration={duration}
      date={date}
      webview={mode === "webview"}
      limit={limit}
      page={page}
      brands={brands}
      deliveryOnly={deliveryOnly}
    />
  );
}

class MainComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: true,
      redirectTo: "",
      brand_name: "Brands",
    };

    this.api = core("pu").get();

    this.getLoggedInUser = this.getLoggedInUser.bind(this);
  }

  getLoggedInUser = (field = "fullname") => {
    const { user = {} } = this.props;
    if (field === "fullname") {
      return user.first_name + " " + user.last_name;
    } else {
      return user[field];
    }
  };

  render() {
    const actions = {
      getLoggedInUser: this.getLoggedInUser,
      setBrandName: (brand_name = "") => {
        this.setState({ brand_name });
      },
      setSplashScreen: (showSplashScreen = true) => {
        this.setState({ showSplashScreen });
      },
      setRedirectTo: (redirectTo = "") => {
        this.setState({ redirectTo });
      },
    };

    const {
      view = "",
      attr = "",
      id = "",
      webview = "",
      filter = "",
      duration = "day",
      user = {},
      role = "",
      date = moment().format(DB_DATE_FORMAT),
      page = 1,
      limit = 30,
      brands = [],
      deliveryOnly = false,
    } = this.props;

    return (
      <>
        <TitleHelmet title={this.state.brand_name} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <BrandComponent
          webview={webview}
          brand={id}
          brands={brands}
          api={this.api}
          actions={actions}
          filter={filter}
          duration={duration}
          user={user}
          role={role}
          date={date}
          view={view}
          attr={attr}
          page={page}
          limit={limit}
          deliveryOnly={deliveryOnly}
        />
      </>
    );
  }
}

class BrandComponent extends ReportsApi {
  getReqBody() {
    const {
      page = 1,
      limit = 30,
      isForExport = false,
      sortBy = { name: "order_date", isReverse: false },
    } = this.state;
    const search = this.processSearchValue(this.state.search);

    let duration = this.state.duration || this.props.duration || "day";
    let date =
      this.state.date || this.props.date || moment().format(DB_DATE_FORMAT);
    const { filter = "", deliveryOnly = false } = this.props;

    let view = this.state.selectedBrand || {
      id: this.props.brands[0] || "",
      name: "All",
      attr: this.props.attr || "brand",
    };

    const { role = "", brands = [] } = this.props;
    const { attr = "" } = getIdsAndAttr(this.props, view);

    if (this.props.filter && this.props.filter === "Orders Tomorrow") {
      date = moment().add(1, "days").format(DB_DATE_FORMAT);
      duration = "day";
    }

    if (duration === "allTime" && page > 1) {
      date = moment().format(DB_DATE_FORMAT);
    }

    return {
      role: role,
      brands: brands,
      id: view.id || brands[0],
      attr: view.attr || attr,
      date,
      dateRange: duration,
      filter,
      limit: Number(limit),
      page: page - 1,
      search,
      sortBy,
      isForExport,
      deliveryOnly,
    };
  }

  render() {
    const { role = "" } = this.props;
    const {
        data_status = "",
        data = {},
        selectedBrand = { id: "", name: "All" },
        duration = this.props.duration || "day",
        date = this.props.date || moment().format(DB_DATE_FORMAT),
        page = 1,
        limit = 30,
      } = this.state,
      isLoading = data_status !== "fetched";

    const title = capitalizeText(this.props.filter) || "Orders";
    const { headers = [], orders = [], totalResults = 0 } = data;

    const pagesArr = [];
    for (let i = 0; i * limit < totalResults; i++) {
      pagesArr.push(i);
    }

    return (
      <>
        <TitleHelmet title="Reports" />
        {this.props.webview && (
          <div className="row">
            <div className="col-md-9 col-sm-12">
              <Breadcrumbs>
                <Breadcrumbs.Item
                  text="Dashboard"
                  link={`/webview/dashboard`}
                  active="true"
                />
              </Breadcrumbs>
            </div>
          </div>
        )}
        <HeaderTextWithActions className="mb-0 mb-md-4" title={title} />

        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          <div
            className="d-flex flex-column"
            style={{
              overflowX: "scroll",
              minHeight: "65vh",
            }}
          >
            <div className="mb-8 position-sticky" style={{ left: 0 }}>
              <InputGroup className="mb-3 overflow-hidden">
                <InputGroup.Prepend>
                  <InputGroup.Text id="text-search-input">
                    Search{" "}
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <FormControl
                  type="search"
                  aria-label="Search"
                  aria-describedby="inputGroup-sizing-default"
                  placeholder="Order#, customer name, email, and contact number"
                  value={this.state.search}
                  onChange={(e) => this.handleTextSearchInputChange(e)}
                  onKeyDown={this.keyPress}
                />

                <button
                  className="btn btn-default"
                  onClick={this.handleTextSearch}
                >
                  <i className="fa fa-search"></i>
                </button>
              </InputGroup>
            </div>

            <div
              className="row align-items-center mb-4 position-sticky d-flex flex-column flex-md-row"
              style={{ left: "0", zIndex: 1 }}
            >
              <div
                className="col-12 col-md-6 input-group position-sticky w-100 p-0"
                style={{ left: "0" }}
                onClick={() => this.handleOnDateInputClick()}
              >
                <form className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Order Date
                  </span>
                  <input
                    type="date"
                    className="form-control rounded-0 d-inline-block"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    onChange={this.handleDateInputChange}
                    value={
                      this.state.page !== 2
                        ? (title === "Orders Tomorrow" &&
                            moment().add(1, "days").format(DB_DATE_FORMAT)) ||
                          this.state.date ||
                          moment().format(DB_DATE_FORMAT)
                        : null
                    }
                  />
                  <button
                    className="btn btn-primary rounded-0"
                    onClick={this.handleSearchDate}
                  >
                    <i className="fa fa-search p-0" />
                  </button>
                </form>
                {/* <form className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Order Date</span>
                                <input 
                                    type="date" 
                                    className="form-control rounded-0 d-inline-block" 
                                    aria-label="Small" 
                                    aria-describedby="inputGroup-sizing-sm" 
                                    onChange= {this.handleDateInputChange}
                                    disabled= {title==='Orders Tomorrow'?true: false}
                                    value= {(title==='Orders Tomorrow' && moment().add(1, 'days').format(DB_DATE_FORMAT))||this.state.date|| moment().format(DB_DATE_FORMAT)}
                                />
                                <button 
                                    disabled= {title==='Orders Tomorrow'? true: false}
                                    className="btn btn-primary rounded-0" 
                                    onClick={this.handleSearchDate}
                                >
                                    <i className="fa fa-search p-0"/>
                                </button>
                            </form>     */}

                <ExportCSV
                  filename={`${title}-${this.handleDropdownText(
                    duration || this.props.duration,
                    date,
                  )}-${date}.csv`}
                  url="/dashboard/reports"
                  reqBody={this.getReqBody()}
                />
              </div>

              <div className="d-flex position-sticky ml-auto mr-4 ">
                {role !== "console_user" && (
                  <GroupDropdown
                    items={headers}
                    attr={role === "store_admin" ? "store" : "brand"}
                    onSelectView={this.handleChangeSelectedBrand}
                    selectedView={selectedBrand}
                    hasRollup={role === "group_admin" || role === "accounting"}
                    className="mr-2 mr-4 mt-4 mt-md-0 rounded-top"
                    style={{ zIndex: 100 }}
                  />
                )}

                <Dropdown
                  className="mr-3 mt-4 mt-md-0 rounded-top"
                  style={{ right: "0" }}
                >
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {this.handleDropdownText(
                      duration || this.props.duration,
                      date,
                    ) || "Today"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu id={"daterange-dropdown"}>
                    {DATERANGE_LIST.map((item) => (
                      <Dropdown.Item
                        key={item.name}
                        onClick={(e) => this.handleDateRangeClick(e, item.date)}
                      >
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <ReportsTable
              data={orders}
              onClickSort={this.handleChangeSort}
              levelId={this.props.brand}
              webview={this.props.webview}
            />

            {totalResults > 0 && (
              <div className="position-sticky" style={{ left: 0 }}>
                <PaginationBar
                  pagesArr={pagesArr}
                  totalResults={totalResults}
                  rows={orders.length}
                  pageNum={page}
                  limit={limit}
                  onSelectPage={this.handleSelectPage}
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}
