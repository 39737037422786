import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { LayoutSplashScreen } from "../../../_metronic/layout";

export default function Page() {
  const { brand_url = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  return <HeaderComponent id={brand_url} user={user} />;
}

// Cancelled Orders will redirect to the main store of the Brand

class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);

    const { store = {} } = this.props.user;

    this.state = {
      redirectTo: `/brands/${this.props.id}/stores/${store._id}/cancelled-orders`,
    };
  }

  render() {
    return (
      <>
        <LayoutSplashScreen />
        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}
      </>
    );
  }
}
