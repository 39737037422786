import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/error/error-3.scss";
import { Link } from "react-router-dom";
import { SVGIcon } from "../../components/Base";

export function ErrorPage3() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-3 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg3.jpg")})`,
        }}
      >
        <div className="px-10 px-md-30 py-10 py-md-0 d-flex flex-column justify-content-md-center">
          <h1 className="error-title text-stroke text-transparent">401</h1>
          <p className="display-4 font-weight-boldest text-white mb-12">
            Ooops!
          </p>
          <p className="font-size-h2 font-weight-boldest text-dark-75">
            Sorry, you are not authorized to view this page.
          </p>
          <p className="font-size-h4 line-height-md font-weight-bold">
            If you are using pickup.ph in multiple tabs, please open an
            incognito window and login again. <br />
            To find out how to open an incognito window pls click{" "}
            <u>
              <a
                href="https://www.youtube.com/watch?v=SgjkB2-od04"
                target="_blank"
                className="text-dark-75"
                rel="noreferrer"
              >
                here
              </a>
            </u>
            .
          </p>
          <p className="font-size-h4 line-height-md font-weight-boldest">
            <Link to="/" className="text-dark-75">
              <u>
                {" "}
                <SVGIcon
                  icon={"Navigation/Arrow-left"}
                  title="Back to home"
                  variant="dark"
                />{" "}
                Back to home
              </u>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
