/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { roleDisplay } from "../../../../../app/helpers";

export function QuickUser() {
  const { user } = useSelector((state) => state.auth),
    { iam_role = "" } = user,
    isSuperAdmin = iam_role === "super_admin";
  const history = useHistory();

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right d-flex flex-column p-10"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="offcanvas-title font-weight-bold m-0">
          Hi, {user.first_name}!
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content d-flex h-auto">
        <div className="d-flex align-items-center mt-5 w-100">
          <div className="d-flex symbol symbol-100 symbol-light-success mr-5">
            <div className="symbol-label font-size-h1 font-weight-bold">
              {user.first_name[0]}
            </div>
          </div>
          <div
            className="d-flex flex-column"
            style={{ minWidth: 1, flexBasis: "70%" }}
          >
            <div className="navi">
              <div className="navi-item">
                <span className="navi-link p-0">
                  <span className="navi-icon">
                    <i className="far fa-user-circle text-primary"></i>
                  </span>
                  <span className="navi-text font-weight-bold font-size-h5 text-dark-75">
                    {user.first_name} {user.last_name}
                  </span>
                </span>
              </div>
            </div>
            {isSuperAdmin ? (
              <></>
            ) : (
              <div className="text-muted mt-1">{user.client.client_name}</div>
            )}
            <div className="navi">
              <div className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon">
                    <i className="far fa-address-card text-primary"></i>
                  </span>
                  <span className="navi-text text-muted text-capitalize">
                    {roleDisplay(user.iam_role)}
                  </span>
                </span>
              </div>
              <a
                href={`mailto:${user.email}`}
                target={"_blank"}
                title={user.email}
                className="navi-item"
                rel="noreferrer"
              >
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon">
                    <i className="far fa-envelope text-primary"></i>
                  </span>
                  <span
                    className="navi-text text-muted text-hover-primary"
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {user.email}
                  </span>
                </span>
              </a>
            </div>
            <button className="btn btn-primary btn-bold" onClick={logoutClick}>
              Sign out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
