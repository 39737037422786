import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import {
  HeaderTextWithActions,
  LoadingDiv,
  Block,
  Label,
  EditIcon,
} from "../../components/Base";
import { Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Breadcrumbs from "../../components/Breadcrumbs";
import StatCard from "../../components/widgets/StatCard";
import moment from "moment";
import UpdateGroupTypeModal from "../../partials/groups/UpdateGroupTypeModal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";

const MySwal = withReactContent(Swal);

const MENUS = {
  main: "main",
  files: "files",
  paymongo_form: "paymongo_form",
};

function getFilename(url) {
  return url.split("/").pop();
}

function FileItem({ file = {} }) {
  const { url = "", date_created = "" } = file,
    filename = getFilename(url);
  return (
    <div className="d-flex flex-wrap align-items-center mb-10">
      <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
        <a
          href={url}
          target="_blank"
          className="text-primary font-weight-bold font-size-lg mb-1"
          rel="noreferrer"
        >
          {filename}
        </a>
        <div className="text-muted">
          Added on {moment(date_created).format("MMM D, YYYY hh:mm A")}
        </div>
      </div>
    </div>
  );
}

function FieldsRow({ fields }) {
  return (
    <Row>
      {fields.map((field, index) => {
        const { value = "N/A" } = field;
        return (
          <Col key={index}>
            <Block
              content={
                <>
                  <Label
                    text={
                      <>
                        <span className="va-middle text-capitalize">
                          {field.name}
                        </span>
                      </>
                    }
                  />
                  <div>{value}</div>
                </>
              }
            />
          </Col>
        );
      })}
    </Row>
  );
}

function OwnerRow({ owner, counter = 0 }) {
  return (
    <>
      {[
        [{ name: `beneficial owner ${counter + 1}`, value: "" }],
        [
          { name: "first name", value: owner.first_name },
          { name: "last name", value: owner.last_name },
        ],
        [
          { name: "date of birth", value: owner.date_of_birth },
          { name: "percent own", value: owner.percent_own },
        ],
        [
          { name: "line 1", value: owner.business_address_l1 },
          { name: "line 2", value: owner.business_address_l2 },
        ],
        [
          { name: "city", value: owner.business_address_city },
          { name: "state", value: owner.business_address_state },
          { name: "zip", value: owner.business_address_zip },
          { name: "country", value: owner.business_address_country },
        ],
      ].map((fields) => {
        return <FieldsRow fields={fields} />;
      })}
    </>
  );
}

function DisplaySignUpForm({ signup_form = {} }) {
  return (
    <>
      {[
        [
          { name: "business type", value: signup_form.business_type },
          { name: "trade name", value: signup_form.business_trade_name },
        ],
        [
          { name: "first name", value: signup_form.first_name },
          { name: "last name", value: signup_form.last_name },
        ],
        [
          { name: "line 1", value: signup_form.address.l1 },
          { name: "line 2", value: signup_form.address.l2 },
        ],
        [
          { name: "city", value: signup_form.address.city },
          { name: "state", value: signup_form.address.state },
          { name: "zip", value: signup_form.address.zip },
        ],
      ].map((fields) => {
        return <FieldsRow fields={fields} />;
      })}

      <hr />

      {[
        [
          {
            name: "registered name",
            value: signup_form.business_registered_name,
          },
        ],
        [
          {
            name: "registration number",
            value: signup_form.business_registration_number,
          },
        ],
        [
          {
            name: "dti registration number",
            value: signup_form.dti_registration_cert,
          },
        ],
        [
          {
            name: "business legal name",
            value: signup_form.business_legal_name,
          },
        ],
        [
          {
            name: "date of incorporation",
            value: signup_form.business_date_of_incorporation,
          },
        ],
        [
          {
            name: "sec certificate of incorporation",
            value: signup_form.sec_incorporation_cert
              ? getFilename(signup_form.sec_incorporation_cert)
              : "N/A",
          },
        ],
        [
          {
            name: "by laws",
            value: signup_form.by_laws
              ? getFilename(signup_form.by_laws)
              : "N/A",
          },
        ],
        [
          {
            name: "articles of incorporation",
            value: signup_form.articles_of_incorporation
              ? getFilename(signup_form.articles_of_incorporation)
              : "N/A",
          },
        ],
        [
          {
            name: "is beneficial owner",
            value: signup_form.hasOwnProperty("is_beneficial_owner")
              ? signup_form.is_beneficial_owner
                ? "True"
                : "False"
              : "N/A",
          },
        ],
        [
          {
            name: "articles of partnership",
            value: signup_form.articles_of_partnership
              ? getFilename(signup_form.articles_of_partnership)
              : "N/A",
          },
        ],
      ].map((fields) => {
        return <FieldsRow fields={fields} />;
      })}

      <hr />

      {signup_form.hasOwnProperty("business_beneficial_owner") &&
      signup_form.business_beneficial_owner.length > 0 ? (
        <>
          {signup_form.business_beneficial_owner.map((owner, i) => {
            return <OwnerRow owner={owner} counter={i} key={i} />;
          })}
          <hr />
        </>
      ) : (
        <></>
      )}

      {[
        [
          {
            name: "business handle",
            value: signup_form.business_handle
              ? signup_form.business_handle
              : "N/A",
          },
        ],
        [
          {
            name: "business link",
            value: signup_form.business_link
              ? signup_form.business_link
              : "N/A",
          },
        ],
        [
          {
            name: "business nature",
            value: signup_form.business_nature
              ? signup_form.business_nature
              : "N/A",
          },
        ],
      ].map((fields) => {
        return <FieldsRow fields={fields} />;
      })}

      <hr />

      {[
        [{ name: "bank name", value: signup_form.bank.bank_name }],
        [
          {
            name: "bank account name",
            value: signup_form.bank.account_name,
          },
        ],
        [
          {
            name: "bank account number",
            value: signup_form.bank.account_number,
          },
        ],
      ].map((fields) => {
        return <FieldsRow fields={fields} />;
      })}
    </>
  );
}

export default function Page() {
  const { group_id = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  return <MainComponent id={group_id} user={user} />;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Groups",
      activeMenuKey: "main",
    };

    this.api = core("pu").get();
    this.data_url = "/client/" + this.props.id;

    this.setActiveMenu = this.setActiveMenu.bind(this);
    this.handleOnClickGenerateCSV = this.handleOnClickGenerateCSV.bind(this);
    this.handleOnSubmitUpdateGroup = this.handleOnSubmitUpdateGroup.bind(this);
  }

  handleOnDataFetched = () => {
    const { data = {} } = this.state,
      { name = "", signup_form = "" } = data;
    this.setState({
      title: name,
      signup_form: signup_form === "" ? {} : JSON.parse(signup_form),
    });
  };

  handleOnClickGenerateCSV = () => {
    this.api
      .post({ url: "/client/" + this.props.id + "/generate-csv" })
      .then(({ data }) => {
        const { result = "", file = "" } = data;

        if (result === "ok") {
          window.location = file;
        }
      })
      .catch(() => {
        this.handleError();
      });
  };

  handleOnSubmitUpdateGroup = (values, setSubmitting) => {
    setSubmitting(false);
    this.api
      .put({ url: "/client/" + this.props.id + "/admin", data: values })
      .then(({ data }) => {
        const { result = "" } = data;

        if (result === "ok") {
          this.setState({ showUpdateGroupTypeModal: false });
          this.getData();
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes!",
            timer: 1000,
          });
        }
      })
      .catch(() => {
        this.handleError();
      });
  };

  setActiveMenu = (activeMenuKey) => {
    this.setState({ activeMenuKey });
  };

  render() {
    const {
        data = {},
        data_status = "",
        showUpdateGroupTypeModal = false,
      } = this.state,
      isLoading = data_status !== "fetched";
    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          <>
            <UpdateGroupTypeModal
              show={showUpdateGroupTypeModal}
              onHide={() => {
                this.setState({ showUpdateGroupTypeModal: false });
              }}
              group_type={data.group_type}
              onSubmit={this.handleOnSubmitUpdateGroup}
            />

            <HeaderTextWithActions
              title={this.state.title}
              className="mb-8"
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item text="Groups" link={`/admin/groups`} />
                  <Breadcrumbs.Item text={this.state.title} link={null} />
                </Breadcrumbs>
              }
            />

            <Row>
              <Col>
                <Link to={`/admin/groups/${this.props.id}/brands`}>
                  <StatCard
                    variant="white"
                    title="brands"
                    stat={data.brands}
                    icon={"Layout/Layout-4-blocks"}
                  />
                </Link>
              </Col>
              <Col>
                <Link to={`/admin/groups/${this.props.id}/stores`}>
                  <StatCard
                    variant="white"
                    title="stores"
                    stat={data.stores}
                    icon={"Shopping/Cart1"}
                  />
                </Link>
              </Col>
              <Col>
                <Link to={`/admin/groups/${this.props.id}/users`}>
                  <StatCard variant="white" title="users" stat={data.users} />
                </Link>
              </Col>
            </Row>

            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={MENUS.main}
              activeKey={this.state.activeMenuKey}
            >
              <Row>
                <Col sm={3}>
                  <Card>
                    <Card.Body>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.main}
                            onClick={() => this.setActiveMenu(MENUS.main)}
                          >
                            Group Information
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.paymongo_form}
                            onClick={() =>
                              this.setActiveMenu(MENUS.paymongo_form)
                            }
                          >
                            PayMongo Form
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.files}
                            onClick={() => this.setActiveMenu(MENUS.files)}
                          >
                            Files
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey={MENUS.main}>
                      <Card>
                        <Card.Body>
                          <Block
                            content={
                              <>
                                <Label
                                  text={
                                    <>
                                      <span className="va-middle text-capitalize">
                                        group name
                                      </span>
                                    </>
                                  }
                                />
                                <div>{data.name}</div>
                              </>
                            }
                          />

                          <Block
                            content={
                              <>
                                <Label
                                  text={
                                    <>
                                      <span className="va-middle text-capitalize">
                                        group type
                                      </span>
                                      <EditIcon
                                        onClick={() => {
                                          this.setState({
                                            showUpdateGroupTypeModal: true,
                                          });
                                        }}
                                      />
                                    </>
                                  }
                                />
                                <div>
                                  {data.group_type ? (
                                    <span className="text-capitalize">
                                      {data.group_type}
                                    </span>
                                  ) : (
                                    "Single"
                                  )}
                                </div>
                              </>
                            }
                          />

                          <Block
                            content={
                              <>
                                <Label
                                  text={
                                    <>
                                      <span className="va-middle text-capitalize">
                                        date created
                                      </span>
                                    </>
                                  }
                                />
                                <div>
                                  {moment(data.date_created).format(
                                    "MMM D, YYYY hh:mm A",
                                  )}
                                </div>
                              </>
                            }
                          />
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.paymongo_form}>
                      <Card>
                        <Card.Body>
                          {data.signup_form ? (
                            <>
                              <DisplaySignUpForm
                                signup_form={JSON.parse(data.signup_form)}
                              />
                              <Button onClick={this.handleOnClickGenerateCSV}>
                                Generate CSV
                              </Button>
                            </>
                          ) : (
                            <>
                              This merchant wasn't able to fill up the PayMongo
                              form.
                            </>
                          )}
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.files}>
                      <Card>
                        <Card.Body>
                          {data.files && data.files.length > 0 ? (
                            data.files.map((file) => <FileItem file={file} />)
                          ) : (
                            <>No files under this group.</>
                          )}
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </>
        )}
      </>
    );
  }
}
