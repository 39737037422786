import React from "react";

import { CSVLink } from "react-csv";

import APIComponent from "./API";
import core from "../vendors/core-api";
import {
  getStatusDisplay,
  Humanize,
  decryptPaymentDetails,
  orderDetailsItemExtras,
  normalizeText,
} from "../../app/helpers";

const csvHeaders = [
  { label: "Order Details", key: "transaction_number" },
  { label: "Order Date", key: "order_date" },
  { label: "Payment ID", key: "payment_details" },
  { label: "Payment Method", key: "payment_method" },
  { label: "Order Type", key: "order_type" },
  { label: "Customer Email", key: "email" },

  { label: "Customer Name", key: "customer_name" },
  { label: "Contact Number", key: "contact_number" },
  { label: "Delivery Address", key: "delivery_address" },
  { label: "City", key: "city" },
  { label: "Delivery Charge", key: "chargedDeliveryFee" },

  { label: "Status", key: "status" },
  { label: "Total Amount", key: "amount" },
  { label: "Items(Extras)", key: "items" },
];

class ExportCSV extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };

    this.csvLinkEl = React.createRef();
    this.data_url = props.url;
    this.api = core("pu").get();
    this.initialLoad = true;
    this.csvHeaders = csvHeaders;
  }

  async getData() {}

  async getCSVData(e) {
    e.stopPropagation();

    this.initialLoad = false;
    this._isMounted && this.setState({ data_status: "fetching" });

    this.api &&
      this.api
        .post({
          url: this.props.url,
          data: { ...this.props.reqBody, isForExport: true },
        })
        .then((data) => {
          this.setState(
            {
              data: data.data.orders.map((order) => {
                return {
                  ...order,
                  payment_details: decryptPaymentDetails(order.payment_details),
                  // items: stringfyItemExtras(order.items),
                  items: orderDetailsItemExtras(order.order_details),
                  order_type: Humanize(order.order_type),
                  status: getStatusDisplay(order.status, order.order_type),
                  // delivery_address: order.order_type === 'delivery'? `${order.locality}, ${order.city}`: '-'
                  city:
                    order.order_type === "delivery" && order.city
                      ? `${order.city}`
                      : "-",
                  delivery_address:
                    order.order_type === "delivery"
                      ? `${normalizeText(
                          order.delivery_address.replace(/['"]+/g, ""),
                        )}`
                      : "-",
                };
              }),
              isForExport: false,
              data_status: "fetched",
            },
            () => {
              setTimeout(() => {
                if (window.ReactNativeWebView) {
                  // this will trigger if from mobile webview and will not proceed on the bottom part of the code
                  let data = JSON.stringify({
                    type: "dl_csv",
                    fileName: `${new Date().getTime()}_${this.props.filename}`,
                    headers: this.csvHeaders,
                    rowData: this.state.data,
                  });
                  window.ReactNativeWebView.postMessage(data);
                  return null;
                }

                this.csvLinkEl.current.link.click();
              });
            },
          );

          this.handleOnDataFetched();
        })
        .catch((error) => {
          this._isMounted &&
            this.setState({ error: error, showSwalError: true });
          this.handleOnDataFetchError();
        });
  }

  render() {
    const { data = [], data_status = "" } = this.state;
    const { filename = "" } = this.props;

    const isLoading = data_status !== "fetched";
    const isSpinnerDisplayed = isLoading && !this.initialLoad;

    return (
      <div className="d-flex justify-content align-items-center ml-4 mt-4 mt-md-0 px-2">
        <button
          className="btn btn-light p-2 ml-2"
          onClick={(e) => this.getCSVData(e)}
        >
          <span className="d-md-inline-block position-relative">
            <span className={`${isSpinnerDisplayed && "pr-8"}`}>
              Export to CSV
              {isSpinnerDisplayed ? (
                <span className="spinner spinner-sm spinner-left spinner-primary" />
              ) : (
                <span className="ml-2">
                  <i className="fas fa-file-export text-primary" />
                </span>
              )}
            </span>
          </span>
        </button>

        <CSVLink
          filename={filename}
          ref={this.csvLinkEl}
          data={data}
          headers={csvHeaders}
        />
      </div>
    );
  }
}

export default ExportCSV;
