import React from "react";

import { Modal, Button } from "react-bootstrap";
import BWSection from "../../components/widgets/BWSection";
// import { CURRENCY } from "../../constants";
import {
  formatOrderType,
  GetSubmitClassList,
  pluralize,
  toPesoAmount,
} from "../../helpers";
import {
  OrderTimeRender,
  InfoAlertHelper,
  AnimateDiv,
  ErrorAlert,
} from "../../components/Base";
import CartItem from "./CartItem";
import {
  computeDiscount,
  getMaxItemsInMealPlan,
} from "../../helpers/branded-website";

function computeTotalCartAmount(arr = []) {
  let total = 0;
  for (var i = 0; i < arr.length; i++) total += arr[i].qty * arr[i].price;

  return total;
}

export default class CartModal extends React.Component {
  render() {
    const {
        show = false,
        onHide,
        cart = {},
        actions = {},
        store = {},
        checkoutLoading = false,
        preview_token = "",
        showCartModalOrderTimeError = false,
        showCheckoutFailedMessage = false,
        showItemsUnavailableForTimeSlotError = false,
        website_theme = {},
      } = this.props,
      {
        isMealPlan = false,
        isInStoreOrder = false,
        order_dates = [],
        order_time = "",
        items = [],
      } = cart;

    const {
      button_text_color = "",
      button_color = "",
      link_text_color = "",
    } = website_theme;

    const buttonStyle = {
        borderColor: button_color,
        backgroundColor: button_color,
        color: button_text_color,
      },
      linkStyle = { color: link_text_color };
    const maxItemsInMealPlan = isMealPlan
        ? getMaxItemsInMealPlan(cart.meal_plan_type)
        : "",
      remainingItems = isMealPlan ? maxItemsInMealPlan - cart.items_count : 0;
    const isMealPlanComplete = cart.items_count === maxItemsInMealPlan,
      hasMealPlanDiscount =
        isMealPlan &&
        store.hasOwnProperty("mealPlanDiscount") &&
        store.mealPlanDiscount > 0;

    // const onOrderTypeClick = (type) =>{
    //     cart.order_type = type
    //     this.setState({cart})
    // }

    // const SelectedClass = (type)=>{
    //     // if(cart.order_type  === type){
    //     //     return "btn-primary outline-primary"
    //     // }else{
    //     //     return "btn-outline-secondary"
    //     // }
    //     return "";
    // }

    // const CustomIconButton = styled.button `
    //     color: ${button_text_color};
    //     background-color: ${button_color};
    //     border-color: ${button_color};

    //     .svg-icon.svg-icon-custom {
    //         fill: ${button_text_color}
    //     }

    //     &:hover {
    //         color: ${button_text_color};
    //         font-weight: 500;
    //     }
    // `;

    // const CustomIconOutlineButton = styled.button `
    //     border-color: ${button_color};
    //     color: ${button_color};

    //     .svg-icon.svg-icon-custom {
    //         fill: ${button_color}
    //     }

    //     &:hover .svg-icon.svg-icon-custom {
    //         fill: ${button_text_color}
    //     }

    //     &:hover {
    //         color: ${button_text_color};
    //         font-weight: 500;
    //         background-color: ${button_color};
    //     }
    // `;

    let total_amount = computeTotalCartAmount(cart.items);

    if (hasMealPlanDiscount) {
      total_amount = computeDiscount(total_amount, store.mealPlanDiscount);
    }

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>Your Basket</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          {preview_token !== "" ? (
            <InfoAlertHelper text={"Checkout is disabled on preview mode."} />
          ) : (
            <></>
          )}

          {showCartModalOrderTimeError ? (
            <AnimateDiv>
              <ErrorAlert
                message={
                  <>
                    Unfortunately you have missed the cutoff for this time slot.{" "}
                  </>
                }
              />
              {!isInStoreOrder ? (
                <>
                  <Button
                    variant="outline-danger"
                    onClick={this.props.handleOnClickEditTimeFromError}
                    className="cursor-pointer font-weight-bolder mb-2"
                  >
                    Click here to select another time slot.
                  </Button>
                </>
              ) : (
                <></>
              )}
            </AnimateDiv>
          ) : (
            <></>
          )}
          {showCheckoutFailedMessage ? (
            <AnimateDiv>
              <ErrorAlert
                message={
                  <>
                    Ooops! Looks like some of the items from your basket went
                    out of stock while you were checking out.
                  </>
                }
              />
              {!isInStoreOrder ? (
                <>
                  {cart.order_type !== "dine_in" ? (
                    <Button
                      variant="outline-danger"
                      onClick={this.props.handleOnClickEditTimeFromError}
                      className="cursor-pointer font-weight-bolder mb-2"
                    >
                      Click here to select another time slot.
                    </Button>
                  ) : (
                    <div className="mb-5">
                      <div>Please remove these items from the basket:</div>
                      <div>
                        {items.map((item) => {
                          if (!item.included) {
                            return (
                              <span className="font-weight-bolder">
                                {item.item.name}
                              </span>
                            );
                          }
                          return <></>;
                        })}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </AnimateDiv>
          ) : (
            <></>
          )}
          {showItemsUnavailableForTimeSlotError ? (
            <AnimateDiv>
              <ErrorAlert
                message={
                  <>
                    Ooops! Looks like some of the items from your basket isn't
                    available for the time slot you have selected.
                  </>
                }
              />
              {!isInStoreOrder ? (
                <>
                  <Button
                    variant="outline-danger"
                    onClick={this.props.handleOnClickEditTimeFromError}
                    className="cursor-pointer font-weight-bolder mb-2"
                  >
                    Click here to select another time slot.
                  </Button>
                </>
              ) : (
                <></>
              )}
            </AnimateDiv>
          ) : (
            <></>
          )}

          <div className="bw-cart">
            <BWSection className="bw-cart-items-added">
              <BWSection.Header>Store Location</BWSection.Header>
              <BWSection.Content>
                <div>
                  <div>
                    <b>{store.name}</b>
                  </div>
                  <div>{store.location}</div>
                  <div>{store.contact_number}</div>
                </div>
              </BWSection.Content>
            </BWSection>
            {/* <BWSection className="bw-cart-items-added">
                        <BWSection.Header>Order Type</BWSection.Header>
                        <BWSection.Content>
                            <div className="w-100">
                                <div className="btn-group btn-group-lg w-100" role="group" aria-label="Large button group">
                                        <CustomIconButton type="button" className={`btn ${SelectedClass("pickup")} font-weight-bolder`}  onClick={()=>{onOrderTypeClick("pickup")}} >PICKUP    <SVGIcon icon={"Customs/take-away"}  className={`svg-icon-4x`} variant="custom" /> </CustomIconButton>
                                        <CustomIconOutlineButton type="button" disabled={"disabled"} className={`btn ${SelectedClass("delivery")} font-weight-bolder`} onClick={()=>{onOrderTypeClick("delivery")}} >DELIVERY   <SVGIcon icon={"Customs/delivery"} className={`svg-icon-4x`} variant="custom"/></CustomIconOutlineButton>
                                </div>
                            </div>            
                        </BWSection.Content>
                    </BWSection> */}

            {/* <BWSection className="bw-cart-items-added">
                        <BWSection.Header>Order Type</BWSection.Header>
                        <BWSection.Content>
                            <div>
                                <OrdetTypeSelection order_type = {cart.order_type}/>                             
                            </div>                      
                        </BWSection.Content>
                    </BWSection> */}

            {!isMealPlan && (
              <BWSection className="bw-cart-items-added">
                {cart.order_type === "delivery" ? (
                  <BWSection.Header>
                    <span>Dispatch Time</span>
                    {!isInStoreOrder ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-link px-0 py-0 va-text-bottom ml-1"
                          onClick={
                            this.props.actions.handleOnClickChangeOrderType
                          }
                          style={linkStyle}
                        >
                          {"EDIT"}
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </BWSection.Header>
                ) : (
                  <BWSection.Header>
                    <span>{formatOrderType(cart.order_type)} Schedule</span>
                    {!isInStoreOrder && cart.order_type !== "dine_in" ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-link px-0 py-0 va-text-bottom ml-1"
                          onClick={
                            this.props.actions.handleOnClickChangeOrderType
                          }
                          style={linkStyle}
                        >
                          {"EDIT"}
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </BWSection.Header>
                )}
                <BWSection.Content>
                  <div>
                    <OrderTimeRender {...cart} />
                  </div>
                  {cart.order_type === "delivery" ? (
                    <div> {cart.delivery_address} </div>
                  ) : (
                    <></>
                  )}
                </BWSection.Content>
              </BWSection>
            )}

            <BWSection className="bw-cart-items-added">
              <BWSection.Header>Items Added</BWSection.Header>
              <BWSection.Content>
                {isMealPlan && (
                  <div className="mv-1rem">
                    <div className="font-weight-bolder text-uppercase">
                      <span className="va-middle">
                        {maxItemsInMealPlan} day meal plan
                      </span>
                      <button
                        type="button"
                        className="btn btn-link px-0 py-0 va-middle ml-1"
                        onClick={
                          this.props.actions.handleOnClickChangeMealPlanType
                        }
                        style={linkStyle}
                      >
                        {"EDIT"}
                      </button>
                    </div>
                    <div className="font-weight-bolder">
                      Dispatch Time:{" "}
                      <OrderTimeRender order_time={order_time} noDate={true} />
                    </div>
                  </div>
                )}
                {cart.items.length > 0 ? (
                  <>
                    <div className="bw-cart-items-wrapper modal-scrollable">
                      {cart.items.map((cart_item, index) => (
                        <CartItem
                          key={index}
                          cart_item={cart_item}
                          index={index}
                          actions={actions}
                          website_theme={website_theme}
                          isMealPlan={isMealPlan}
                          order_dates={order_dates}
                          order_time={order_time}
                        />
                      ))}
                    </div>
                    {((isMealPlan && isMealPlanComplete) || !isMealPlan) && (
                      <div className="bw-cart-items--total-amount font-weight-bolder">
                        <div>
                          Total Amount
                          {hasMealPlanDiscount && (
                            <span className="text-danger ml-2">
                              ({store.mealPlanDiscount}% OFF)
                            </span>
                          )}
                        </div>
                        <div>
                          {hasMealPlanDiscount ? (
                            <div>
                              <del className="ml-2">
                                {toPesoAmount(
                                  computeTotalCartAmount(cart.items),
                                )}
                              </del>
                              <span className="text-danger ml-2">
                                {toPesoAmount(total_amount)}
                              </span>
                            </div>
                          ) : (
                            toPesoAmount(total_amount)
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div>
                    <p>No items added to your basket.</p>
                  </div>
                )}
              </BWSection.Content>
            </BWSection>
            {cart.items.length > 0 && total_amount < 100 ? (
              <div>
                <span className="text-danger">
                  {" "}
                  Minimum order is {toPesoAmount(100)}.
                </span>
              </div>
            ) : (
              <></>
            )}
            {isMealPlan && !isMealPlanComplete ? (
              <div>
                <span className="text-danger">
                  {" "}
                  You need to add {pluralize(remainingItems, "more item")} for
                  this {maxItemsInMealPlan} day meal plan.
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {showCartModalOrderTimeError ||
          showCheckoutFailedMessage ||
          showItemsUnavailableForTimeSlotError ? (
            <>
              <Button
                style={buttonStyle}
                className={GetSubmitClassList(
                  checkoutLoading,
                  "btn-lg btn-block font-weight-bold",
                )}
                variant="primary"
                disabled={
                  preview_token === "" &&
                  cart.items.length > 0 &&
                  total_amount >= 100 &&
                  (isMealPlan ? cart.items_count === maxItemsInMealPlan : true)
                    ? null
                    : "disabled"
                }
                onClick={this.props.onHide}
              >
                Back
              </Button>
            </>
          ) : (
            <>
              <Button
                style={buttonStyle}
                className={GetSubmitClassList(
                  checkoutLoading,
                  "btn-lg btn-block font-weight-bold",
                )}
                variant="primary"
                disabled={
                  preview_token === "" &&
                  cart.items.length > 0 &&
                  total_amount >= 100 &&
                  (isMealPlan ? cart.items_count === maxItemsInMealPlan : true)
                    ? null
                    : "disabled"
                }
                onClick={this.props.actions.handleOnClickCheckout}
              >
                Proceed to checkout
              </Button>
            </>
          )}
          {/* <Button 
                style={buttonStyle} 
                className={GetSubmitClassList(checkoutLoading, "btn-lg btn-block font-weight-bold")} 
                variant="primary" disabled={(preview_token === "" && cart.items.length > 0 && total_amount >= 100 && (isMealPlan ? cart.items_count === maxItemsInMealPlan : true)) ? null: "disabled"} 
                onClick={this.props.actions.handleOnClickCheckout} > 
                    Proceed to checkout 
                </Button>  */}
        </Modal.Footer>
      </Modal>
    );
  }
}
