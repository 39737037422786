import React, {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import _ from "lodash";
import core from "../../vendors/core-api/index";

export const CheckoutPromoForm = forwardRef(
  (
    {
      setPromoOnCheckout,
      total_amount,
      order_type,
      storeId,
      isMealPlan,
      isCOD,
      setPromoFormLoading,
      isDisabled,
      promoCode,
      successText,
      isPromoCodeValid,
    },
    ref,
  ) => {
    const api = core("pu").get();

    const [promoValue, setPromoValue] = useState(promoCode);
    const [isLoading, setLoading] = useState(false);
    const [isValid, setValid] = useState(true);
    const [errorText, setErrorText] = useState("");
    const debounceChange = _.debounce(
      (e) => setPromoValue(e.target.value.trim().toUpperCase()),
      500,
    );

    const handleChange = (e) => {
      e.persist();

      debounceChange(e);
    };

    useEffect(() => {
      /**
       * API lookup if the promo code is still valid.
       * @param {*} promo_code
       */
      async function promoLookup(promo_code) {
        if (!promo_code) {
          setPromoOnCheckout({}, true);
          return;
        }
        setPromoFormLoading(true);
        setLoading(true);

        try {
          const { data } = await api.post({
            url: "/branded-website/promos/verify",
            data: {
              promo_code,
              total_amount,
              order_type,
              storeId,
              isMealPlan,
              isCOD,
            },
          });

          setPromoOnCheckout(data, true);
          setValid(true);
          setPromoFormLoading(false);
          setLoading(false);
        } catch (error) {
          if (error.hasOwnProperty("response")) {
            const { data = {} } = error.response;
            if (data.error === "PromoInvalidError") {
              setErrorText(`Promo code is invalid. ${data.error_message}`);
              setValid(false);
              setLoading(false);
              setPromoFormLoading(false);
              setPromoOnCheckout({}, false);
            }
          }
          setPromoFormLoading(false);
          setLoading(false);
        }
      }

      promoLookup(promoValue);
    }, [promoValue, isMealPlan, isCOD, order_type]);

    const handleInvalidPromoCode = () => {
      setErrorText("Promo Code is invalid");
      setValid(false);
      setPromoOnCheckout({}, false);
    };

    useImperativeHandle(ref, () => ({ handleInvalidPromoCode }));

    return (
      <Row ref={ref}>
        <Col lg={12}>
          <Form.Group className="fv-plugins-icon-container">
            <div className="input-group">
              <Form.Control
                className="text-uppercase"
                type="text"
                name="promo_code"
                placeholder="Promo Code"
                disabled={false}
                defaultValue={promoCode}
                isValid={promoValue && isValid}
                isInvalid={promoValue && !isValid}
                onChange={handleChange}
              />
              {isLoading && (
                <div className="mt-2 ml-3">
                  <Spinner animation="border" size="sm" />
                </div>
              )}
            </div>
            {promoValue && isValid && (
              <div className="fv-plugins-message-container">
                {successText || (
                  <div className="text-success">Promo code applied!</div>
                )}
              </div>
            )}
            {promoValue && !isValid && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errorText}</div>
              </div>
            )}
          </Form.Group>
        </Col>
      </Row>
    );
  },
);
