import React from "react";

import moment from "moment";
import { useSelector } from "react-redux";

import LoadingSkeleton from "../../components/dashboard/LoadingSkeleton";

import { DB_DATE_FORMAT } from "../../constants";
import useFetch from "../../hooks/useFetch";

const StoreCardData = (props) => {
  const {
    user: { brands = [], iam_role = "" },
  } = useSelector((state) => state.auth);

  const {
    storeId = "",
    label = "",
    data_url = "",
    icon = "",
    valueFormatter = null,
  } = props;

  const reqObj = {
    url: data_url,
    method: "post",
    body: {
      date: moment().format(DB_DATE_FORMAT),
      brands,
      role: iam_role,
      dateRange: "allTime",
      id: storeId,
      attr: "store",
    },
  };

  const { data, isLoading } = useFetch(reqObj);

  return (
    <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
      <span className="mr-4">
        <i
          className={`flaticon-${icon} icon-2x text-muted font-weight-bold`}
        ></i>
      </span>
      <div className="d-flex flex-column text-dark-75">
        <span className="font-weight-bolder font-size-sm">{label}</span>
        <span className="font-weight-bolder font-size-h5">
          {isLoading || data === null ? (
            <LoadingSkeleton height={20} />
          ) : (
            <span className="text-dark-50 font-weight-bold">
              {valueFormatter ? valueFormatter(data.value) : data.value}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

export default StoreCardData;
