import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { Modal, Form } from "react-bootstrap";

export default class DeliveryAddressModal extends ProSourceForm {
  render() {
    const { show = false, onHide, mapComponent, setAddressButton } = this.props;
    // console.log('delivery address modal props: ', this.props)
    return (
      <Modal show={show} centered>
        <Modal.Header>
          <Modal.Title>Set Delivery Address</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form>
          <Modal.Body>
            {mapComponent}
            <div className="mt-2 d-flex flex-row justify-content-center">
              {/* <div className="font-weight-bolder align-self-center">{this.props.state.values.delivery_address}</div> */}
              <div></div>
              {setAddressButton}
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    );
  }
}
