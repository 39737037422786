import { isEmpty, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { toPesoAmount } from "../../../helpers";

export const PromoContainer = ({
  promos = [],
  onPromoSelect,
  onError,
  promo_code = "",
  store = {},
  items = [],
  order_type = "",
}) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (!isEmpty(promo_code) && !isEmpty(promos)) {
      const defaultInputValue = promos.filter(
        (promo) => promo.label === promo_code,
      );
      if (defaultInputValue.length) {
        setSelected(defaultInputValue);
        onPromoSelect(
          defaultInputValue.length > 0
            ? defaultInputValue[0].value
            : defaultInputValue,
        );
      }
    }
    if (isNull(promo_code) && !isEmpty(selected)) {
      setSelected([]);
    }
  }, [promo_code, promos]);

  const { menu = [] } = store;
  const items_available = menu
    .filter(({ available, item_id }) => available && item_id.isExclusive)
    .map(({ item_id }) => item_id._id);

  const sub_total = items
    .filter(({ item }) => items_available.indexOf(item._id) > -1)
    .reduce((sum, item) => sum + item.price * item.qty, 0);

  const filtered_promo = isNull(order_type)
    ? promos
    : promos.filter(({ value }) => value.applies_to.includes(order_type));

  return (
    <>
      <Card.Title className="pb-0 mb-0">
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex flex-row align-items-center justify-content-space-between ml-8 mt-8 mr-8 mb-0">
            <h3 className="font-weight-bolder">
              <i className="fas fa-tags mr-2" style={{ color: "#14C49B" }} />
              Promo Code
            </h3>
            <div className="d-flex align-items-center">
              <a
                className="btn btn-sm"
                style={{
                  color: "#464e5f",
                  textDecoration: "underline",
                }}
                href={`/promos/new`}
                target="_blank"
                rel="noreferrer"
              >
                + New
              </a>
            </div>
          </div>
        </div>
      </Card.Title>
      <Card.Body className="px-8 py-4">
        <Typeahead
          clearButton
          style={{ width: "100%", marginBottom: 10 }}
          id={`basic-typeahead-single`}
          placeholder={`Select promo to add`}
          options={filtered_promo}
          onChange={(selected) => {
            const ret_val = selected.length > 0 ? selected[0].value : selected;
            const { minimum_purchase } = ret_val;
            if (!isNull(selected) && minimum_purchase > sub_total) {
              onError(minimum_purchase, "mininum_purchase_required");
            } else {
              setSelected(selected);
              onPromoSelect(ret_val);
            }
          }}
          selected={selected}
        />
        {!isEmpty(selected) && (
          <div className="d-flex flex-row justify-content-space-between">
            <div className="text-uppercase font-weight-bolder">
              Promo value:
            </div>
            <div className="text-uppercase font-weight-bolder text-danger">
              {selected[0].value.promo_type === "free_delivery"
                ? "FREE DELIVERY"
                : selected[0].value.promo_type === "free_item"
                ? `${
                    selected[0].value.free_item_description
                      .toLowerCase()
                      .includes("free")
                      ? ""
                      : "FREE"
                  } ${selected[0].value.free_item_description}`
                : selected[0].value.promo_type === "amount_off"
                ? toPesoAmount(selected[0].value[selected[0].value.promo_type])
                : selected[0].value[selected[0].value.promo_type] + "% OFF"}
            </div>
          </div>
        )}
      </Card.Body>
    </>
  );
};
