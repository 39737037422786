import PropTypes from "prop-types";
import React, { useState } from "react";

import { useSelector } from "react-redux";
import { Button, Form, Modal } from "react-bootstrap";
import { Block } from "../../components/Base";
import moment from "moment";
import Swal from "sweetalert2";
import { isEmpty } from "lodash";
import { Row, Col } from "react-bootstrap";
import Datatable from "react-data-table-component";
import withReactContent from "sweetalert2-react-content";
import { Typeahead } from "react-bootstrap-typeahead";

import {
  PRIMARY_COLOR,
  DB_DATE_FORMAT,
  PROMO_SCOPE_LEVELS,
  ANNOUNCEMENT_TEXT_CHAR_LIMIT,
} from "../../constants";
import ProSourceForm from "../../components/ProSourceForm";
import ImageUpload from "../../components/ImageUpload";
import PreviewModal from "../../partials/branded-website/modals/Announcement";
import DatePicker from "react-datepicker";

import {
  FormItem,
  Label,
  RequiredAsterisk,
  PreviewPhoto,
  InfoTooltip,
} from "../../components/Base";
import {
  GetSubmitClassList,
  fetchData,
  Humanize,
  capitalizeText,
  isPromoScopeAllowedForUserLevel,
} from "../../helpers";
import ht from "../../helpertexts";
import core from "../../vendors/core-api";
import useFetch from "../../hooks/useFetch";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";

const MySwal = withReactContent(Swal);

function getBtnVariant(condition = false) {
  return `btn btn-icon btn-${condition ? "primary" : "outline-primary"}`;
}

const Announcements = () => {
  const api = core("pu").get();
  const fileApi = core("puf").get();

  const { user = {} } = useSelector((state) => state.auth);

  const reqObj = {
    url: `/announcements/user/${user._id}`,
    method: "get",
    body: {},
  };

  const [rerender, setRerender] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({});
  const [previewItem, setPreviewItem] = useState(null);
  const { data, isLoading } = useFetch(reqObj, {}, [rerender]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const formSubmit = (values = {}, method = "post") => {
    let level_model,
      created_by = "";
    switch (user.iam_role) {
      case "super_admin":
        level_model = "User";
        created_by = "super";
        break;
      case "group_admin":
        level_model = "Client";
        created_by = "group";
        break;
      case "brand_admin":
        level_model = "Brand";
        created_by = "brand";
        break;
      case "store_admin":
        level_model = "Store";
        created_by = "store";
        break;
      case "console_user":
        level_model = "Store";
        created_by = "store";
        break;
      default:
        level_model = "Store";
        created_by = "store";
        break;
    }
    // console.log("formSubmit", values);

    api[method]({
      url: `/announcements${method === "put" ? `/${values._id}` : ""}`,
      data: {
        ...values,
        level_model,
        level_id: user._id,
        created_by,
      },
    }).then(() => {
      MySwal.fire({
        icon: "success",
        title: "Success!",
        text:
          method === "put"
            ? "Announcement has been edited"
            : "New Announcement Added",
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Ok",
        timer: 1000,
      });

      setRerender(!rerender);
      setIsModalOpen(!isModalOpen);
      setSelectedAnnouncement({});
    });
  };

  const uploadImage = (file, setFilePath, setSubmitting) => {
    const formData = new FormData();
    formData.append("file", file);

    fileApi
      .post({
        url: "/util/upload-to-aws/announcements",
        data: formData,
      })
      .then(({ data }) => {
        const { filePath = "" } = data;
        setFilePath(filePath);
      })
      .catch((error) => {
        console.log("error: ", error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onSelectAnnouncement = (data = {}) => {
    setSelectedAnnouncement(data);
    setIsModalOpen(!isModalOpen);
  };

  const onToggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setSelectedAnnouncement({});
  };

  const handleDelete = async (id) => {
    const result = await MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to remove this announcement.`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
    });
    if (result.isConfirmed === true) {
      try {
        const response = await fetchData("put", `/announcements/archive/${id}`);
        // console.log(response, "responseData");
        const status = response.data.status;
        if (status === "OK") {
          // console.log("response>>>>", status);
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully removed the announcement.",
            confirmButtonColor: PRIMARY_COLOR,
            showConfirmButton: true,
            timer: 1000,
          });
          setRerender(!rerender);
        }
      } catch (err) {
        console.log(err, "announcementErr");
      }
    }
  };

  const filteredItems =
    searchText !== ""
      ? data.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        })
      : data;
  // console.log("data", data);

  return (
    <div className="position-relative" style={{ marginBottom: "50px" }}>
      <h1 className="mb-8">Announcements</h1>
      {!user.iam_role.includes("console") && user.iam_role !== "accounting" ? (
        <Button
          className="position-absolute"
          style={{ top: 0, right: 0 }}
          onClick={() => setIsModalOpen(!isModalOpen)}
        >
          Add
        </Button>
      ) : (
        <></>
      )}
      {isModalOpen && (
        <AnnouncementModal
          show={isModalOpen}
          onSubmit={formSubmit}
          uploadImage={uploadImage}
          isModalOpen={isModalOpen}
          onToggleModal={onToggleModal}
          values={selectedAnnouncement}
          user={user}
        />
      )}
      {previewItem && (
        <PreviewModal
          data_url={`/announcements/${previewItem._id}`}
          onHide={() => setPreviewItem(null)}
        />
      )}

      {data === null || data.length === 0 ? (
        <p>No announcements found. </p>
      ) : !isLoading ? (
        <Datatable
          columns={[
            {
              name: "Name",
              selector: (row) => {
                return row.name;
              },
              sortable: true,
              style: "font-weight-bold",
              format: (row) => {
                return (
                  <span
                    className="cursor-pointer text-hover-primary"
                    onClick={() => onSelectAnnouncement(row)}
                  >
                    {row.name.length > 29
                      ? `${row.name.substring(0, 28)}...`
                      : row.name}
                  </span>
                );
              },
              grow: 1,
            },
            {
              name: "Status",
              selector: (row) => {
                return row.status;
              },
              format: (row) => {
                return (
                  <span
                    className={`badge badge-pill badge-${
                      row.status === "active" ? "primary" : "light"
                    }`}
                  >
                    {row.status}
                  </span>
                );
              },
              sortable: true,
            },
            {
              name: "Header",
              selector: (row) => {
                return row.header;
              },
              sortable: true,
              format: (row) => {
                let { header = "" } = row;
                header =
                  header.length > 25 ? `${header.substring(0, 24)}...` : header;

                return header;
              },
            },
            {
              name: "Preview",
              selector: (row) => {
                return row._id;
              },
              format: (row) => {
                return (
                  <span
                    className="cursor-pointer text-light text-hover-primary"
                    onClick={() => setPreviewItem(row)}
                  >
                    <i className="far fa-eye" />
                  </span>
                );
              },
            },
            {
              name: "Date Created",
              selector: (row) => {
                return moment(row.date_created).format("MMM D, YYYY hh:mm A");
              },
              sortable: true,
            },
            {
              name: "",
              selector: (row) => {
                return row._id;
              },
              format: (row) => {
                const isVisible = isPromoScopeAllowedForUserLevel(
                  user.iam_role,
                  row.level,
                );
                return (
                  (isVisible || user.iam_role === "super_admin") && (
                    <span
                      className="cursor-pointer text-light text-hover-primary"
                      onClick={() => handleDelete(row._id)}
                    >
                      <i
                        className="fas fa-trash mr-5 cursor-pointer"
                        data-toggle="tooltip"
                        title="Remove announcement"
                      ></i>
                    </span>
                  )
                );
              },
            },
          ]}
          data={filteredItems || []}
          pagination
          noHeader
          highlightOnHover
          subHeader
          subHeaderWrap={false}
          subHeaderComponent={
            <>
              <Row>
                <Col>
                  <div className="input-group mt-8">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="flaticon-search"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </>
          }
          // progressPending={progressPending}
        />
      ) : (
        <PacmanWrapper removeBackgroundColor />
      )}
    </div>
  );
};

class AnnouncementModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const { values = {}, user = {} } = this.props,
      { img = "" } = values;

    this.state = {
      values: {
        img: "",
        isActive: ["active", undefined].includes(values.status) ? true : false,
        isOverlaid: false,
        name: "",
        header: "",
        text: "",
        // tags: "",
        promoCode: "",
        end_date: "",
        brands: [],
        stores: [],
        group: "",
        level: user.iam_role.includes("admin")
          ? user.iam_role.replace("_admin", "")
          : "",
        selectedBrandsStores: [],
        text_orientation: "",
        ...values,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      isFileSubmitting: false,
      previewUploadImageFile: img,
      croppedImage: "",
    };

    this.validate = {
      required: [
        "name",
        "header",
        "file",
        "status",
        "img",
        "text",
        "level",
        user.iam_role !== "super_admin" ? "selectedBrandsStores" : "",
      ],
    };
    this.isEditModal = !isEmpty(values);

    this.onStatusToggle = this.onStatusToggle.bind(this);
    this.onOverlaidToggle = this.onOverlaidToggle.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.onChangePromoCode = this.onChangePromoCode.bind(this);
    this.setCroppedImage = this.setCroppedImage.bind(this);
    this.handleOnSubmitUploadFile = this.handleOnSubmitUploadFile.bind(this);
    this.onClickOrientationChange = this.onClickOrientationChange.bind(this);
  }

  onSubmit = (values, setSubmitting) => {
    const method = this.isEditModal ? "put" : "post";
    // console.log("submit", values);

    const currentDate = moment(new Date()).format(DB_DATE_FORMAT);

    if (this.state.file) {
      this.props.uploadImage(
        this.state.file,
        (filePath) => {
          values.img = filePath;
          this.setState({ values });
          // setSubmitting(true);
          this.props.onSubmit(values, method);
        },
        setSubmitting,
      );
    } else if (values.end_date < currentDate) {
      if (values.end_date !== "" && values.status === "active") {
        MySwal.fire({
          icon: "warning",
          title: "Ooops!",
          text: `End date is earlier than the current date. Please set end date to current date or later.`,
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
          showCancelButton: false,
        }).then(() => {
          this.setState({ isSubmitting: false });
        });
      } else if (values.end_date === "") {
        if (values.status !== "active") {
          this.setState({
            values: { ...this.state.values, status: "inactive" },
          });
        }
        this.props.onSubmit(values, method);
      }
    } else {
      this.props.onSubmit(values, method);
    }
  };

  onStatusToggle = (event) => {
    const name = event.target.name;
    let { values } = this.state;

    values[name] = !values[name];
    values.status = values[name] ? "active" : "inactive";

    const currentDate = moment(new Date()).format(DB_DATE_FORMAT);
    if (
      values.end_date < currentDate &&
      values.end_date !== "" &&
      values.status === "active"
    ) {
      MySwal.fire({
        icon: "warning",
        title: "Ooops!",
        text: `End date is earlier than the current date. Please set end date to current date or later.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }

    this.setState({
      values: values,
    });

    this.setStateKey("touched", name, "true");
    this.validateForm();

    // checks if afterOnChange function exists
    this.onChange && this.onChange(event);
  };

  onOverlaidToggle = (event) => {
    const name = event.target.name;
    let { values } = this.state;

    values[name] = !values[name];
    values.isOverlaid = values[name] ? true : false;

    this.setState({ values: values });
    this.onChange && this.onChange(event);
  };

  onChangeText = (e) => {
    const { values, errors } = this.state;
    values.text = e.target.value;
    if (values.text.length > ANNOUNCEMENT_TEXT_CHAR_LIMIT) {
      errors["text"] = "Text character limit exceeded.";
    } else {
      delete errors["text"];
    }
    this.setState({ values, errors });
    this.validateForm();
    this.onChange && this.onChange(e);
  };

  onChangePromoCode = (e) => {
    const { values, errors } = this.state;
    values.promoCode = e.target.value;
    if (values.promoCode === "" && values.isOverlaid === true) {
      values.isOverlaid = false;
    }
    this.setState({ values, errors });
    this.onChange && this.onChange(e);
  };

  dataUrlToFile = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: "image/jpeg" });
  };

  handleOnSubmitUploadFile = () => {
    this.setState({ isFileSubmitting: true });

    if (this.state.croppedImage) {
      this.dataUrlToFile(
        this.state.croppedImage,
        this.state.uploadImageFile.name,
      )
        .then((file) => {
          this.props.uploadImage(
            file,
            (filePath) => {
              this.setState({
                values: {
                  ...this.state.values,
                  img: filePath,
                  previewUploadImageFile: "",
                },
              });
            },
            () => {
              this.setState({ isFileSubmitting: false });
            },
          );
        })
        .catch((err) => console.log(err));
    } else {
      // console.log(this.state.uploadImageFile);
      this.props.uploadImage(
        this.state.uploadImageFile,
        (filePath) => {
          this.setState({ values: { ...this.state.values, img: filePath } });
        },
        () => {
          this.setState({ isFileSubmitting: false });
        },
      );
    }
  };

  setCroppedImage = (croppedImage) => {
    this.setState({ croppedImage });
  };

  onClickOnChangeDate = (date, field) => {
    if (date instanceof Date) {
      this.setFieldValue(field, moment(date).format(DB_DATE_FORMAT));
    }
  };

  getBrandsStores = async () => {
    const { values } = this.state;
    const { user } = this.props;
    const userAllBrands = await fetchData("get", `/settings/get-brand-options`);
    const userAllStores = await fetchData("get", `/settings/get-store-options`);
    if (values.level === "group") {
      values.selectedBrandsStores = [
        {
          _id: user.client._id,
          name: user.client.name,
        },
      ];
    }
    if (user.iam_role === "accounting" || user.iam_role.includes("console")) {
      values.brands = [];
      values.stores = [];
    } else {
      values.brands = userAllBrands.data.data;
      values.stores = userAllStores.data.data;
    }
    this.setState({ values });
  };

  onChangeSelectLevel = (e) => {
    this.setFieldValue("level", e.target.value);
  };

  getIsDisabled = () => {
    const { values } = this.state;
    const { user } = this.props;
    const isScope = isPromoScopeAllowedForUserLevel(
      user.iam_role,
      values.level,
    );
    if (user.iam_role === "super_admin") {
      return false;
    } else if (
      user.iam_role.includes("console") ||
      user.iam_role === "accounting"
    ) {
      return true;
    }
    return !isScope && this.isEditModal;
  };

  onClickOrientationChange = (text_orientation = "") => {
    const { values } = this.state;
    this.setState({ values: { ...values, text_orientation } });
  };

  async componentDidMount() {
    await this.getBrandsStores();
  }

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      },
      { show, maxFileSize = 2000, maxFileSizeText = "2MB", user } = this.props;

    const { values = {}, errors = {} } = this.state;
    const { text_orientation = "" } = values;
    // console.log(this.state.values);

    return (
      <Modal show={show} centered className="modal-content-xl">
        <Modal.Header>
          <Modal.Title>
            {!this.isEditModal ? "Add" : this.getIsDisabled() ? "View" : "Edit"}{" "}
            Announcement
          </Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.onToggleModal}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            <Row>
              <Col>
                <FormItem
                  label={"Name"}
                  name={"name"}
                  inputProps={{
                    name: "name",
                    placeholder: "Announcement Name",
                    value: this.state.values["name"],
                    disabled: this.getIsDisabled(),
                  }}
                  inputActions={inputActions}
                  actions={actions}
                  showRequired={true}
                  normal
                />
              </Col>
              <Col>
                <FormItem
                  label={"Header"}
                  name={"header"}
                  inputProps={{
                    name: "header",
                    placeholder: "Header",
                    value: this.state.values["header"],
                    disabled: this.getIsDisabled(),
                  }}
                  inputActions={inputActions}
                  actions={actions}
                  showRequired={true}
                  normal
                />
              </Col>
            </Row>
            <div>
              <FormItem
                label={"Text"}
                name={"text"}
                type={"textarea"}
                inputProps={{
                  name: "text",
                  placeholder: "Text",
                  value: this.state.values["text"],
                  disabled: this.getIsDisabled(),
                }}
                inputActions={{
                  ...inputActions,
                  onChange: this.onChangeText,
                }}
                actions={actions}
                showRequired={true}
                normal
              />
              <span className="form-text text-muted text-right">
                {this.state.values["text"].length}/
                {ANNOUNCEMENT_TEXT_CHAR_LIMIT}
              </span>
            </div>
            <Row>
              <Col>
                <div>
                  <Label text="Image" /> <RequiredAsterisk />
                  <div className="text-danger" style={{ fontSize: "1rem" }}>
                    {this.handleFeedbackError("img", "error-text")}
                  </div>
                  {!this.state.previewUploadImageFile &&
                  !this.getIsDisabled() ? (
                    <ImageUpload
                      onError={actions.handleError}
                      onChange={(file, preview) => {
                        this.setState({
                          uploadImageFile: file,
                          previewUploadImageFile: preview,
                        });
                        delete errors["img"];
                        this.setState({
                          file,
                          values: { ...this.state.values, img: file },
                          errors: { ...errors },
                        });
                      }}
                      label={
                        <div style={{ margin: "1rem 0" }}>
                          <div
                            className="text-muted"
                            style={{ fontSize: "0.8rem" }}
                          >
                            Maximum file size is {maxFileSizeText} <br />
                            Suggested dimensions is 250x250
                          </div>
                        </div>
                      }
                      maxFileSize={maxFileSize}
                      maxFileSizeText={maxFileSizeText}
                    />
                  ) : (
                    <PreviewPhoto
                      src={
                        this.state.previewUploadImageFile ||
                        this.state.values.img
                      }
                      onClickRemove={() => {
                        this.setState({
                          uploadImageFile: "",
                          previewUploadImageFile: "",
                        });
                      }}
                    />
                  )}
                </div>
              </Col>
              <Col>
                <Block
                  content={
                    <>
                      <Label text="Status" />{" "}
                      <div className="d-flex align-items-center">
                        <div className="pt-2">
                          <span className="switch switch-outline switch-icon switch-primary">
                            <label className="align-items-center">
                              <input
                                type="checkbox"
                                name="isActive"
                                checked={this.state.values.isActive}
                                value={
                                  this.state.values.isActive
                                    ? "Active"
                                    : "Inactive"
                                }
                                onChange={this.onStatusToggle}
                                onBlur={this.handleOnBlur}
                                disabled={this.getIsDisabled()}
                              />
                              <span></span>
                              <div
                                className="ml-2"
                                style={{ fontSize: ".8rem" }}
                              >
                                {this.state.values.isActive
                                  ? "Active"
                                  : "Inactive"}
                              </div>
                            </label>
                          </span>
                        </div>
                      </div>
                    </>
                  }
                />
              </Col>
            </Row>
            <FormItem
              label={"Promo Code"}
              name={"promoCode"}
              inputProps={{
                name: "promoCode",
                placeholder: "Promo Code",
                value: this.state.values["promoCode"],
                disabled:
                  this.getIsDisabled() || !user.privileges.includes("promos"),
              }}
              inputActions={{
                ...inputActions,
                onChange: this.onChangePromoCode,
              }}
              actions={actions}
              normal
              style={{ width: "50%" }}
            />
            <Row>
              <Col>
                <Block
                  content={
                    <>
                      <Label text="Overlaid" />{" "}
                      {InfoTooltip(ht.announcements.promo_overlay)}{" "}
                      <div className="d-flex align-items-center">
                        <div className="pt-2">
                          <span className="switch switch-outline switch-icon switch-primary">
                            <label className="align-items-center">
                              <input
                                type="checkbox"
                                name="isOverlaid"
                                checked={this.state.values.isOverlaid}
                                value={this.state.values.isOverlaid}
                                onChange={this.onOverlaidToggle}
                                onBlur={this.handleOnBlur}
                                disabled={
                                  this.getIsDisabled() ||
                                  !user.privileges.includes("promos") ||
                                  this.state.values.promoCode === ""
                                }
                              />
                              <span></span>
                              <div
                                className="ml-2"
                                style={{ fontSize: ".8rem" }}
                              >
                                {this.state.values.isOverlaid ? "Yes" : "No"}
                              </div>
                            </label>
                          </span>
                        </div>
                      </div>
                    </>
                  }
                />
              </Col>
              <Col>
                {values.isOverlaid && (
                  <Block
                    content={
                      <>
                        <Label text="Alignment" />{" "}
                        {InfoTooltip(ht.announcements.alignment)}{" "}
                        <div className="mv-1rem">
                          <div className="btn-group" role="group">
                            <button
                              type="button"
                              className={getBtnVariant(
                                text_orientation === "left",
                              )}
                              onClick={() => {
                                this.onClickOrientationChange(
                                  "left",
                                  "text_orientation",
                                );
                              }}
                              disabled={
                                this.getIsDisabled() ||
                                !user.privileges.includes("promos") ||
                                this.state.values.promoCode === ""
                              }
                            >
                              <i className="fas fa-align-left"></i>
                            </button>
                            <button
                              type="button"
                              className={getBtnVariant(
                                text_orientation === "center",
                              )}
                              onClick={() => {
                                this.onClickOrientationChange(
                                  "center",
                                  "text_orientation",
                                );
                              }}
                              disabled={
                                this.getIsDisabled() ||
                                !user.privileges.includes("promos") ||
                                this.state.values.promoCode === ""
                              }
                            >
                              <i className="fas fa-align-center"></i>
                            </button>
                            <button
                              type="button"
                              className={getBtnVariant(
                                text_orientation === "right",
                              )}
                              onClick={() => {
                                this.onClickOrientationChange(
                                  "right",
                                  "text_orientation",
                                );
                              }}
                              disabled={
                                this.getIsDisabled() ||
                                !user.privileges.includes("promos") ||
                                this.state.values.promoCode === ""
                              }
                            >
                              <i className="fas fa-align-right"></i>
                            </button>
                          </div>
                        </div>
                      </>
                    }
                  />
                )}
              </Col>
            </Row>
            {/* <FormItem
              label={"Tags"}
              name={"tags"}
              inputProps={{
                name: "tags",
                placeholder: "Tags",
                value: this.state.values["tags"],
              }}
              inputActions={inputActions}
              actions={actions}
              //   showRequired={true}
              normal
            /> */}
            {values.level !== "super" ? (
              <>
                <Row>
                  <Col>
                    <Label text="Scope" /> <RequiredAsterisk />
                    {InfoTooltip(ht.announcements.level)}{" "}
                    <div className="radio-inline mb-10">
                      {PROMO_SCOPE_LEVELS.map((scope, i) => {
                        if (
                          isPromoScopeAllowedForUserLevel(
                            this.props.user.iam_role,
                            scope,
                          ) ||
                          this.getIsDisabled()
                        ) {
                          return (
                            <label key={i} className={`radio text-capitalize`}>
                              <input
                                type="radio"
                                value={scope}
                                name={scope}
                                disabled={
                                  this.getIsDisabled() ||
                                  !isPromoScopeAllowedForUserLevel(
                                    this.props.user.iam_role,
                                    scope,
                                  )
                                }
                                onChange={(e) => {
                                  this.onChangeSelectLevel(e);
                                  this.setState({
                                    values: {
                                      ...this.state.values,
                                      selectedBrandsStores: [],
                                    },
                                  });
                                }}
                                checked={values.level === scope}
                              />{" "}
                              {Humanize(scope)}
                              <span></span>
                            </label>
                          );
                        }
                        return <></>;
                      })}
                      <div className="text-danger" style={{ fontSize: "1rem" }}>
                        {this.handleFeedbackError("level", "error-text")}
                      </div>
                    </div>
                  </Col>
                  {values.level !== "" && values.level !== "group" ? (
                    <Col>
                      <FormItem
                        customFormControl
                        label={
                          values.level !== "" || values.level !== null
                            ? `${capitalizeText(values.level)}s`
                            : ""
                        }
                        className={"form-control-solid"}
                        inputActions={inputActions}
                        actions={actions}
                        showRequired={true}
                        name={values.level}
                        multiple={true}
                        custom={
                          <>
                            <Typeahead
                              id={`${values.level}-selector`}
                              placeholder={`${capitalizeText(values.level)}s`}
                              options={values[`${values.level}s`].filter(
                                (item) => {
                                  return !values.selectedBrandsStores.some(
                                    (brandStore) => item._id === brandStore._id,
                                  );
                                },
                              )}
                              onChange={(selected) => {
                                this.setState({
                                  values: {
                                    ...this.state.values,
                                    selectedBrandsStores: selected,
                                  },
                                });
                              }}
                              selected={values.selectedBrandsStores}
                              multiple={true}
                              labelKey="name"
                              disabled={this.getIsDisabled()}
                            />
                            <div
                              className="text-danger"
                              style={{ fontSize: "1rem" }}
                            >
                              {this.handleFeedbackError(
                                "selectedBrandsStores",
                                "error-text",
                              )}
                            </div>
                          </>
                        }
                      />
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </>
            ) : (
              <></>
            )}
            <div style={{ width: "50%", marginBottom: "10px" }}>
              <Label text="End Date" />
              {InfoTooltip(ht.announcements.end_date)}{" "}
              <DatePicker
                label={"End Date"}
                minDate={new Date()}
                selected={
                  values.end_date === "" ? null : new Date(values.end_date)
                }
                className="form-control"
                onChange={(date) => {
                  if (date === null) {
                    this.setState({
                      values: { ...this.state.values, end_date: "" },
                    });
                  } else {
                    this.setState({
                      values: { ...this.state.values, end_date: date },
                    });
                  }
                  this.onClickOnChangeDate(date, "end_date");
                }}
                isClearable
                placeholderText={`📅 Select end date`}
                wrapperClassName={"width-100"}
                dateFormat={"MMM dd, yyyy"}
                disabled={this.getIsDisabled()}
              />
            </div>
            <Button
              variant="primary"
              type="submit"
              className={GetSubmitClassList(this.state.isSubmitting, "")}
              disabled={this.getIsDisabled()}
            >
              Save Changes
            </Button>
          </Modal.Body>
        </Form>
      </Modal>
    );
  }
}

AnnouncementModal.propTypes = {
  maxFileSize: PropTypes.number,
  maxFileSizeText: PropTypes.string,
  onSubmit: PropTypes.func,
  onToggleModal: PropTypes.func,
  show: PropTypes.bool,
  uploadImage: PropTypes.func,
  values: PropTypes.object,
};

export default Announcements;
