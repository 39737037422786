import React from "react";
import { Card } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export default class ComingSoonDiv extends React.Component {
  render() {
    return (
      <Card>
        <Card.Body>
          <div className="text-center">
            <div>
              <img
                src={toAbsoluteUrl(`/media/svg/croods/soon.png`)}
                width={"50%"}
                alt={"coming soon"}
              />
            </div>
            <p className="display5 display4-lg">Coming Soon</p>
            <div className="font-weight-bolder">
              <p>Something amazing is on the works. Please anticipate it :-)</p>
              <p>With love - the Pickup.ph team.</p>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
}
