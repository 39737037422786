import React from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import PaymongoWizard from "../../components/PaymongoWizard";
import ProSourceForm from "../../components/ProSourceForm";
import core from "../../vendors/core-api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PRIMARY_COLOR } from "../../constants";
import { Card } from "react-bootstrap";
import Login from "../../components/Login";
import { getUserByToken } from "../../modules/Auth/_redux/authCrud";
import { actionTypes } from "../../modules/Auth/_redux/authRedux";
const publicIp = require("react-public-ip");

const instance = axios.create({});
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => Promise.reject(err),
);

const MySwal = withReactContent(Swal);
// const history = useHistory();

export default function Page() {
  const { user, authToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const refreshUserRedux = () => {
    getUserByToken()
      .then(({ data }) => {
        dispatch({ type: actionTypes.UserLoaded, payload: { user: data } });
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: `Something went wrong`,
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      });
  };
  return (
    <Paymongo
      user={user}
      token={authToken}
      refreshUserRedux={refreshUserRedux}
    />
  );
}

class Paymongo extends ProSourceForm {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        // first_name: '', //should come from client?
        // last_name: '', //should come from client?
        first_name: props.user.first_name, //should come from client?
        last_name: props.user.last_name, //should come from client?
        business_type: "Individual",
        //BUSINESS INFO
        business_trade_name: "",
        business_address_l1: "",
        business_address_l2: "",
        business_address_city: "",
        business_address_state: "",
        business_address_zip: "",
        dti_registered_name: "",
        business_registered_name: "",
        dti_registered_number: "",
        //MORE BUSINESS INFO
        business_handle: "",
        // business_link:"",
        // business_nature:"",
        //BANK ACCOUNT
        business_bank: "",
        business_account_name: "",
        business_account_number: "",
        //SOA
        signature: "",
        ip_address: "",
      },
      // business_type: '',
      validate: [],
      errors: {},
      touched: {},
      isSubmitting: false.actions,
      token: props.token,
      redirect: false,
    };

    this.uploadMerchantFile = this.uploadMerchantFile.bind(this);
    this.changeInputList = this.changeInputList.bind(this);
    this.clearError = this.clearError.bind(this);
    this.validateRequiredFieldsOfStage =
      this.validateRequiredFieldsOfStage.bind(this);

    this.fileapi = core("puf").get();
    this.api = core("pu").get();

    this.validate = { required: [...this.state.validate] };
  }

  async componentDidMount() {
    const ipv4 = (await publicIp.v4()) || "";

    let { values = {} } = this.state;
    values.ip_address = ipv4;
    this.setState({ values });
  }

  onSubmit = (values, setSubmitting) => {
    // this.props.onSubmit(values, setSubmitting);
    values.timestamp = Date.now();

    this.api
      .post({
        url: `/client/paymongo-form/${this.state.token}`,
        data: values,
      })
      .then(() => {
        setSubmitting(false);

        MySwal.fire({
          icon: "success",
          title: "Success",
          text: "Paymongo form completed!",
          confirmButtonColor: PRIMARY_COLOR,
        }).then(() => {
          this.props.refreshUserRedux();
          this.setState({ redirect: true });
        });
      })
      .catch(() => {
        this.handleError();
      });
  };

  uploadMerchantFile = (file, setFilePath, uploadName) => {
    const formData = new FormData();
    formData.append("file", file);

    console.log(">>> uploadName", uploadName);

    core().setAuthToken(this.props.token);
    this.fileapi
      .post({
        // url: `/util/upload-to-aws/brands`,
        url: `/util/upload-to-aws/brands?filename=${uploadName}`,
        data: formData,
      })
      .then(({ data }) => {
        const { filePath = "" } = data;
        setFilePath(filePath);
      })
      .catch((err) => console.log(">>> error", err));
    // .finally(() => { setSubmitting(false); })
  };

  changeInputList = (idx = 0) => {
    let listToValidate = [
      // 'business_trade_name',
      "business_type",
      "business_address_l1",
      "business_address_l2",
      "business_address_city",
      "business_address_state",
      "business_address_zip",

      "business_account_name",
      "business_account_number",
      "business_bank",
    ];
    // const values= {};
    if (idx === 3)
      listToValidate = [
        ...listToValidate,
        "business_legal_name",
        "business_registration_number",
        "business_date_of_incorporation",
        "sec_incorporation_cert",
        "articles_of_partnership",
      ];
    if (idx === 2)
      listToValidate = [
        ...listToValidate,
        "business_legal_name",
        "business_registration_number",
        "business_date_of_incorporation",
        "sec_incorporation_cert",
        "articles_of_incorporation",
        "by_laws",
      ];
    if (idx === 1)
      listToValidate = [
        ...listToValidate,
        "business_registered_name",
        "business_registration_number",
        "dti_registration_cert",
      ];

    this.setState({ validate: listToValidate, errors: {} });
    this.validate = { required: listToValidate };
  };

  validateRequiredFieldsOfStage(fields = []) {
    const { values = {}, errors = {}, touched = {} } = this.state;

    for (let field of fields) {
      const value = values[field] || "";

      touched[field] = true;

      if (value === "" && this.isTouched(field)) {
        errors[field] = "This field is required";
      } else if (errors[field] === "This field is required") {
        delete errors[field];
      }
    }

    this.setState({ touched, errors });
    const hasError = Object.keys(this.state.errors).length > 0 ? false : true;

    return hasError;
  }

  clearError = (fields = []) => {
    const { errors = {} } = this.state;

    fields.forEach((field) => delete errors[field]);

    this.setState({ errors });
  };

  validateBusinessRegNum = (input) => {
    const newInput = String(input).replace(/\D+/g, "");
    if (newInput.length > 15) {
      return newInput.slice(0, 14);
    } else {
      return newInput;
    }
  };

  handleOnChange = (event) => {
    const target = event.target,
      value = target.value,
      name = target.name;
    let { values, errors } = this.state,
      state = {};
    values[name] = value;

    if (name === "business_registration_number") {
      const validateInput = this.validateBusinessRegNum(value);
      if (validateInput.length <= 5) {
        errors.business_registration_number =
          "Please enter a valid business registration number.";
      } else {
        delete errors.business_registration_number;
      }
      values[name] = validateInput;
      state.errors = errors;
    } else {
      values[name] = value;
    }

    this.setState({
      values: values,
      state: state,
    });

    this.setStateKey("touched", name, "true");
    this.validateForm();

    // checks if afterOnChange function exists
    this.onChange && this.onChange(event);
  };

  handleOnBlur = (event) => {
    const target = event.target,
      name = target.name;
    let { errors = {}, values = {} } = this.state,
      { business_registration_number } = values,
      state = {};

    if (name === "business_registration_number") {
      const validateInput = this.validateBusinessRegNum(
        business_registration_number,
      );

      if (validateInput.length <= 5) {
        errors.business_registration_number =
          "Please enter a valid business registration number.";
      } else {
        delete errors.business_registration_number;
      }

      const newInput = validateInput.replace(".", "");
      values.business_registration_number = newInput;
      state.errors = errors;
    }
    this.setState(state);
    this.setStateKey("touched", name, "true");
    this.validateForm();

    // checks if afterOnBlur function exists
    this.onBlur && this.onBlur(event);
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };
    const { values } = this.state;
    const { user = {} } = this.props;

    return (
      <>
        {this.state.redirect ? <Redirect to="/" /> : <></>}
        <div className="text-center">
          <Login.Logo />
        </div>
        <Card className="mt-10">
          <Card.Body>
            <PaymongoWizard
              user={user}
              actions={actions}
              inputActions={inputActions}
              onSubmit={this.onSubmit}
              values={values}
              uploadMerchantFile={this.uploadMerchantFile}
              changeValidateList={this.changeInputList}
              getError={this.getError}
              validateFields={this.validateRequiredFieldsOfStage}
              showSubmittingState={this.state.showSubmittingState}
              clearError={this.clearError}
              entityName={user.client.name}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}
