import React from "react";
import { Col } from "react-bootstrap";
import rgbHex from "rgb-hex";

import { CustomPicker } from "react-color";
import {
  Alpha,
  EditableInput,
  Hue,
  Saturation,
} from "react-color/lib/components/common";

const CustomPointer = () => {
  const pointer = {
    width: 12,
    height: 12,
    borderRadius: 6,
    boxShadow: "rgb(255, 255, 255) 0px 0px 0px 1px inset",
    transform: "translate(-6px, -6px)",
  };
  return <div style={pointer} />;
};

export const ColorPicker = (props) => {
  const { color, hex, hsl, hsv, rgb, onChange } = props;

  const inputStyles = {
    input: {
      height: 34,
      width: "100%",
      marginTop: 15,
      marginBottom: 5,
    },
  };

  const styles = {
    saturation: {
      width: "100%",
      height: 125,
      position: "relative",
      marginBottom: 10,
    },
    hue: {
      height: 10,
      position: "relative",
      marginBottom: 10,
    },
    swatch: {
      background: hex,
      width: 16,
      height: 16,
      borderRadius: 8,
      position: "relative",
      overflow: "hidden",
      margin: "-10px 5px 0 10px",
    },
  };

  return (
    <div>
      <div style={styles.saturation}>
        <Saturation
          onChange={onChange}
          hsl={hsl}
          hsv={hsv}
          pointer={CustomPointer}
        />
      </div>
      <div style={{ width: "100%" }}>
        <div
          className="d-flex flex-row align-items-center justify-content-center"
          style={{ height: 40 }}
        >
          <div style={styles.swatch} />
          <Col>
            <div style={styles.hue}>
              <Hue hsl={hsl} hsv={hsv} onChange={onChange} />
            </div>
            <div style={styles.hue}>
              <Alpha rgb={rgb} hsl={hsl} onChange={onChange} />
            </div>
          </Col>
        </div>

        <div className="d-flex flex-row align-items-center justify-content-center text-uppercase">
          <EditableInput
            label="hex"
            style={inputStyles}
            value={
              color.a === 1
                ? hex
                : `#${rgbHex(color.r, color.g, color.b, color.a)}`
            }
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomPicker(ColorPicker);
