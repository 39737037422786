/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import LoginWithToken from "./LoginWithToken";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import CreatePassword from "./CreatePassword";

export function AuthPage() {
  return (
    <>
      <Switch>
        <ContentRoute path="/auth/login" component={Login} />
        <ContentRoute
          path="/auth/login-with-token/:token"
          component={LoginWithToken}
        />
        <ContentRoute path="/auth/sign-up/sales/:agent" component={SignUp} />
        <ContentRoute
          path="/auth/sign-up/partner/:api_client/sales/:agent"
          component={SignUp}
        />
        <ContentRoute
          path="/auth/sign-up/partner/:api_client"
          component={SignUp}
        />
        <ContentRoute path="/auth/sign-up" component={SignUp} />
        <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />
        <ContentRoute path="/auth/reset-password" component={ResetPassword} />
        <ContentRoute path="/auth/create-password" component={CreatePassword} />
        <Redirect from="/auth" exact={true} to="/auth/login" />
        <Redirect to="/auth/login" />
      </Switch>
    </>
  );
}
