import React from "react";
import ProSourceForm from "../components/ProSourceForm";
import { Modal, Form, Button } from "react-bootstrap";
import { SecondaryAlert, FormItem } from "../components/Base";
import { GetSubmitClassList } from "../helpers";

export default class DeliveryDetailsModal extends ProSourceForm {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        rider_full_name: "",
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {
      required: ["rider_full_name"],
    };
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const {
      show = false,
      onHide,
      btnLoading = false,
      display_buttons,
    } = this.props;

    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>Delivery Details</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            <SecondaryAlert
              message={
                <>Delivery details will be sent to the customer via email.</>
              }
            />

            <FormItem
              label={"Rider Full Name"}
              name={"rider_full_name"}
              inputProps={{
                name: "rider_full_name",
                placeholder: "Rider Full Name",
                value: this.state.values["rider_full_name"],
                disabled: !display_buttons,
              }}
              inputActions={inputActions}
              actions={actions}
              normal
              showRequired
            />
          </Modal.Body>
          <Modal.Footer>
            {display_buttons ? (
              <Button
                type="submit"
                variant="primary"
                className={GetSubmitClassList(btnLoading, "")}
                disabled={btnLoading ? "disabled" : null}
              >
                Save Changes
              </Button>
            ) : null}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
