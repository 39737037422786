import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import helpertexts from "../helpertexts";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import {
  HeaderTextWithActions,
  InfoAlertHelper,
  Label,
  PreviewPhoto,
  WarningAlert,
} from "../../components/Base";
import { Button, Card, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import Breadcrumbs from "../../components/Breadcrumbs";
import { GetDataFromEvent, GetSubmitClassList } from "../../helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DANGER_COLOR, PRIMARY_COLOR } from "../../constants";
import ImageUpload from "../../components/ImageUpload";
import SelectionModal from "../../components/widgets/SelectionModal";

import ConfirmPasswordModal from "../../partials/ConfirmPasswordModal";
import helpertexts from "../../helpertexts";
import FormModal from "../../components/FormModal";
import SetStoreHoursModal from "../../partials/SetStoreHoursModal";
import SetOffDatesModal from "../../partials/SetOffDatesModal";
import PreorderSettingsModal from "../../partials/PreorderSettingsModal";
import InAdvancedPreOrderModal from "../../partials/InAdvancedPreOrderModal";
import StoreSettingsDiv from "../../partials/StoreSettingsDiv";
import PaymentSettingsDiv from "../../partials/PaymentSettingsDiv";
import DeliverySettingsDiv from "../../partials/DeliverySettingsDiv";
import DineInSettingsDiv from "../../partials/DineInSettingsDiv";
import CurbsideSettingsDiv from "../../partials/CurbsideSettingsDiv";
import PickupSettingsDiv from "../../partials/PickupSettingsDiv";
import MealPlanSettingsDiv from "../../partials/MealPlanSettingsDiv";
import EditPaymentMethodsModal from "../../partials/EditPaymentMethodsModal";
import BrandNameModal from "../../partials/BrandNameModal";
import LogoFormDiv from "../../partials/LogoFormDiv";
import StoreTitleFormDiv from "../../partials/StoreTitleFormDiv";
import ThemeColorsFormDiv from "../../partials/ThemeColorsFormDiv";
import AboutContentFormDiv from "../../partials/AboutContentFormDiv";
import HomepageContentFormDiv from "../../partials/HomepageContentFormDiv";
import WebsiteStatusFormDiv from "../../partials/WebsiteStatusFormDiv";
import PreviewCropper from "../../components/widgets/PreviewCropper";
import { getUserByToken } from "../../modules/Auth/_redux/authCrud";
import { actionTypes } from "../../modules/Auth/_redux/authRedux";
import Activities from "../../partials/Activities";
import MarketplaceDiv from "../../partials/MarketplaceDiv";
import MarketplaceSearchDiv from "../../partials/MarketplaceSearchDiv";
import { DB_DATE_FORMAT } from "../../constants";
import moment from "moment";
import ViewOrdersModal from "../../partials/ViewOrdersModal";
import ReservationsSettingsDiv from "../../partials/ReservationsSettingsDiv";
import { isEmpty, isObject } from "lodash";

const MySwal = withReactContent(Swal);

const MENUS = {
  main: "main",
  logo: "logo",
  title: "title",
  theme: "theme",
  header: "header",
  about: "about",
  homepage: "homepage",
  display: "display",
  payment: "payment",
  delivery: "delivery",
  dinein: "dinein",
  curbside: "curbside",
  pickup: "pickup",
  mealplan: "mealplan",
  experiences: "experiences",
  status: "status",
  admin_delivery: "admin_delivery",
  admin_marketplace: "admin_marketplace",
  marketplace: "marketplace",
  activity: "activity",
};

// function getOrientation (website_content = {}, mode = "brand_logo") {
//     return website_content.hasOwnProperty(`${mode}_orientation`) ? website_content[`${mode}_orientation`] : "left";
// }

export default function Page({ history }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const refreshUserRedux = () => {
    getUserByToken()
      .then(({ data }) => {
        dispatch({ type: actionTypes.UserLoaded, payload: { user: data } });
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: `Something went wrong`,
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      });
  };

  const params = useParams(),
    { user } = useSelector((state) => state.auth),
    { store_id = "", mode = "" } = params;
  // return isAccessAllowed(user, { brand_url, store_id }) ? (
  return (
    <MainComponent
      user={user}
      id={store_id}
      webview={mode === "webview"}
      history={history}
      {...params}
      {...location}
      refreshUserRedux={refreshUserRedux}
    />
  );
  // ) : (
  //     <Redirect to={"/error/error-v1"} />
  // );
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);
    const selected_date = moment(new Date()).format(DB_DATE_FORMAT);
    const store_id = this.props.id;
    this.state = {
      listSelected: "pre_orders",
      selected_date,
      store_id,
      list: [],
      showSplashScreen: false,
      redirectTo: "",
      title: "",
      form_modal: {},
      uploadImageFile: "",
      previewUploadImageFile: "",
      croppedImage: "",
      website_status_update: "",
      activeMenuKey:
        MENUS[this.props.history.location.pathname.split("/").pop()] ||
        MENUS.main,
      brand_url: "",
      brand_id: "",
      // data_status: "fetching",
      // general_data_status: "fetching",
    };

    this.api = core("pu").get();
    this.fileapi = core("puf").get();

    this.data_url = "/store/" + this.props.id + "/settings";

    this.getHelperText = this.getHelperText.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.showFormModal = this.showFormModal.bind(this);
    this.handleOnSubmitEditPaymentMethod =
      this.handleOnSubmitEditPaymentMethod.bind(this);
    this.handleOnClickRemoveBackgroundImage =
      this.handleOnClickRemoveBackgroundImage.bind(this);
    this.handleOnSubmitUploadBackgroundImage =
      this.handleOnSubmitUploadBackgroundImage.bind(this);
    this.handleOnClickWebsiteStatusBtn =
      this.handleOnClickWebsiteStatusBtn.bind(this);
    this.handleOnSubmitWebsitePrivacyUpdate =
      this.handleOnSubmitWebsitePrivacyUpdate.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.updateBrand = this.updateBrand.bind(this);
    this.updateStore = this.updateStore.bind(this);
    this.getLoggedInUser = this.getLoggedInUser.bind(this);
    this.onSubmitThemeColorsForm = this.onSubmitThemeColorsForm.bind(this);
    this.onSubmitWebsiteContentForm =
      this.onSubmitWebsiteContentForm.bind(this);
    this.onSubmitAboutPageContentForm =
      this.onSubmitAboutPageContentForm.bind(this);
    this.hasRestrictedChar = this.hasRestrictedChar.bind(this);
  }

  async getListData({ store_id, listSelected = "", selected_date = "" }) {
    return new Promise((resolve, reject) => {
      this.api
        .get(
          `/store/${store_id}/orders?selector=${listSelected}&date=${selected_date}`,
        )
        .then((data) => resolve(data.data))
        .catch((err) => reject(err));
    });
  }

  async getDataOrders() {
    try {
      let { selected_date = "", store_id = "", listSelected = "" } = this.state;
      if (listSelected === "pre_orders") {
        let pre_orders = await this.getListData({
          store_id,
          listSelected: "pre_orders",
          selected_date,
        });
        if ("orders" in pre_orders) {
          this.setState({
            list: pre_orders.orders,
          });
        }
      }
    } catch (error) {
      console.log("Here", error);
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    this.getData();
    await this.getDataOrders();
  }

  handleError = (error, maxFileSizeText = "200KB") => {
    if (error === "size-too-much") {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `File is too big! Maximum upload size is ${maxFileSizeText}.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else if (error === "unsupported-file-extension") {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `File type unsupported. Please upload PNG, JPG and JPEG files only.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else if (error === undefined) {
      /** do nothing */
    } else {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `Something went wrong`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };

  getHelperText = (field) => {
    return helpertexts["getting-started"][field];
  };

  handleOnDataFetched = () => {
    this.setState({ mountDisplaySettings: false });

    let { data = {} } = this.state,
      {
        name = "",
        payment_gateway,
        website_theme = "",
        website_content = "",
        brand,
      } = data;

    data.website_theme = website_theme !== "" ? JSON.parse(website_theme) : {};
    data.website_content =
      website_content !== "" ? JSON.parse(website_content) : {};

    const hasPaymentGateway =
      payment_gateway !== "" && payment_gateway !== null;

    this.setState({
      title: name + ": Settings",
      mountDisplaySettings: true,
      showNoPaymentDetailsModal: !hasPaymentGateway,
      hasPaymentGateway,
      brand_url: brand.brand_url,
      brand_id: brand._id,
      showGeoAddress: data.hasOwnProperty("geocode_location"),
      showMapComponent: false,
      values: {
        mapPosition: { lat: "", lng: "" },
        markerPosition: { lat: "", lng: "" },
        currentPosition: { lat: "", lng: "" },
        delivery_address: data.hasOwnProperty("geocode_address_string")
          ? data.geocode_address_string
          : "",
        zoomValue: 18,
      },
    });
  };

  hasRestrictedChar = (value = "") => {
    const valSplit = value.split("");
    const restrictedCharsSplit = ["$", "."];
    let isInvalid = false;

    for (const char of restrictedCharsSplit) {
      if (valSplit.includes(char)) {
        isInvalid = true;
        break;
      }
    }

    return isInvalid;
  };

  onSubmit = (values, setSubmitting) => {
    const { name = "" } = values;

    if (name && this.hasRestrictedChar(name)) {
      MySwal.fire({
        icon: "warning",
        title: "Restricted characters detected",
        text: '"$" and "." is not allowed',
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Yes",
        // showCancelButton: true,
        // cancelButtonText: "No",
      });

      setSubmitting(false);

      return;
    }

    if (values.store_url) {
      MySwal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "You are not able to change the Store URL once set.",
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.apiSubmit(values, setSubmitting);
        } else {
          setSubmitting(false);
        }
      });
    } else {
      this.apiSubmit(values, setSubmitting);
    }
  };

  apiSubmit = (values, setSubmitting) => {
    const { _id = "" } = this.state.data;
    this.api
      .put({
        url: "/store/" + _id,
        data: values,
      })
      .then(() => {
        setSubmitting(false);
        this.setState({
          showFormModal: false,
          showStoreHoursModal: false,
          showOffDatesModal: false,
          showInAdvancedPreOrderModal: false,
          showPreorderSettingsModal: false,
        });

        this.getData();

        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully saved your changes.",
          confirmButtonColor: PRIMARY_COLOR,
          showConfirmButton: false,
          timer: 500,
        });
      })
      .catch((error) => {
        let message = "Something went wrong";
        if (error?.response?.data) {
          if (!isObject(error.response.data.message)) {
            message = error.response.data.message || message;
          }
        } else {
          message = error?.message || message;
        }

        MySwal.fire({
          icon: "error",
          title: "Oops!",
          text: message,
          confirmButtonColor: DANGER_COLOR,
          showConfirmButton: false,
          timer: 2000,
        });

        setSubmitting(false);
      });
  };

  showFormModal = (name = "") => {
    let form_modal = {},
      { data = {} } = this.state;

    form_modal.name = name;
    form_modal.value = data[name];
    form_modal.onSubmit = this.onSubmit;

    if (name === "name") {
      // store name
      form_modal.modal_title = "Edit store name";
      form_modal.form_item = {
        label: "Store Name",
        placeholder: "Store Name",
        showRequired: true,
        helperText: this.getHelperText("store_name"),
      };
    } else if (name === "store_url") {
      // store url
      form_modal.modal_title = "Edit store url";
      form_modal.form_item = {
        label: "Store URL",
        placeholder: "Store URL",
        showRequired: true,
        helperText: this.getHelperText("store_url"),
        prepend: (
          <div className="input-group-prepend">
            <span className="input-group-text">
              {`${this.state.brand_url}${process.env.REACT_APP_DOMAIN}/`}
            </span>
          </div>
        ),
      };
    } else if (name === "location") {
      // store location
      form_modal.modal_title = "Edit store location";
      form_modal.form_item = {
        label: "Location",
        showRequired: true,
        helperText: this.getHelperText("store_location"),
      };
    } else if (name === "contact_number") {
      // store contact number
      form_modal.modal_title = "Edit store contact number";
      form_modal.form_item = {
        label: "Contact Number",
        showRequired: true,
        helperText: this.getHelperText("store_contact_number"),
      };
    } else if (name === "is_accepting_tips") {
      form_modal.modal_title = "Edit tips";
      form_modal.type = "toggle";
      form_modal.form_item = {
        label: "Enable tips:",
      };
      form_modal.info_helper = (
        <InfoAlertHelper text={this.getHelperText("store_tips")} />
      );
    } else if (name === "is_accepting_preorders") {
      form_modal.modal_title = "Edit day-advanced pre-orders";
      form_modal.type = "toggle";
      form_modal.form_item = {
        label: "Accept day-advanced pre-orders?",
      };
      form_modal.info_helper = (
        <InfoAlertHelper text={this.getHelperText("is_accepting_preorders")} />
      );
    } else if (name === "notify_store_before_pickup_time") {
      form_modal.modal_title = "Edit pre-order settings";
      form_modal.type = "number";
      form_modal.form_item = {
        label: "Pre-order pickup time notification allowance",
        showRequired: true,
        helperText: "Set your preferred notification allowance in minutes.",
      };

      form_modal.info_helper = (
        <InfoAlertHelper
          text={this.getHelperText("notify_store_before_pickup_time")}
        />
      );
    } else if (name === "store_tags") {
      form_modal.modal_title = "Store Tags";
      form_modal.form_item = {
        label: "Tags:",
      };
    }

    this.setState({ mountFormModal: false, form_modal });
    setTimeout(() => {
      this.setState({ mountFormModal: true, showFormModal: true });
    });
  };

  getLoggedInUser = (field = "fullname") => {
    const { user = {} } = this.props;
    if (field === "fullname") {
      return user.first_name + " " + user.last_name;
    } else {
      return user[field];
    }
  };

  uploadFile = (file, setSubmitting) => {
    this.setState({ updating: true });

    const { uploadImageMode = "" } = this.state;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("storeId", this.props.id);
    formData.append("uploadImageMode", uploadImageMode);

    this.fileapi
      .post({
        url: "/util/upload-to-aws/brands",
        data: formData,
      })
      .then(({ data }) => {
        const { filePath = "" } = data;
        if (uploadImageMode === "background") {
          this.updateStore(
            { store_website_bg: filePath },
            () => {
              this.setState({
                showUploadBackgroundImageModal: false,
                updating: false,
              });
            },
            this.handleError(),
          );
        } else if (uploadImageMode === "brand_logo") {
          this.updateStore(
            { store_logo: filePath },
            () => {
              this.setState({
                showUploadBrandLogoModal: false,
                updating: false,
              });
            },
            this.handleError(),
          );
        } else if (uploadImageMode === "partner_logo") {
          this.updateStore(
            { partner_logo: filePath },
            () => {
              this.setState({
                showPartnerImageModal: false,
                updating: false,
              });
            },
            this.handleError(),
          );
        } else if (uploadImageMode === "partner_banner") {
          this.updateStore(
            { partner_banner: filePath },
            () => {
              this.setState({
                showPartnerImageModal: false,
                updating: false,
              });
            },
            this.handleError(),
          );
        } else if (uploadImageMode === "navbar_logo") {
          this.updateStore(
            { navbar_logo: filePath },
            () => {
              this.setState({
                showUploadNavbarLogoModal: false,
                updating: false,
              });
            },
            this.handleError(),
          );
        }
      })
      .catch(() => {
        this.handleError();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  updateBrand(
    updateParams = {},
    handleOnSuccess = () => {},
    handleOnError = () => {},
  ) {
    const { brand = {} } = this.state.data;
    this.api
      .put({
        url: "/brand/" + brand._id,
        data: updateParams,
      })
      .then(() => {
        handleOnSuccess();
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully saved your changes.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          timer: 1000,
        });

        this.getData();
        this.setState({
          previewUploadImageFile: "",
          showOrientationModal: false,
        });
      })
      .catch(() => {
        handleOnError();
      });
  }

  updateStore(
    updateParams = {},
    handleOnSuccess = () => {},
    handleOnError = () => {},
  ) {
    const { _id = {} } = this.state.data;
    this.api
      .put({
        url: "/store/" + _id,
        data: updateParams,
      })
      .then(() => {
        handleOnSuccess();
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully saved your changes.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          timer: 1000,
        });

        this.getData();
        this.setState({
          previewUploadImageFile: "",
          showOrientationModal: false,
        });
      })
      .catch(() => {
        handleOnError();
      });
  }

  onSubmitThemeColorsForm = (values, setSubmitting) => {
    this.updateStore(
      { website_theme: JSON.stringify(values) },
      () => {
        setSubmitting(false);
      },
      this.handleError(),
    );
  };

  onSubmitWebsiteContentForm = (values, setSubmitting) => {
    this.updateStore(
      { website_content: JSON.stringify(values) },
      () => {
        setSubmitting(false);
      },
      this.handleError(),
    );
  };

  onSubmitAboutPageContentForm = (
    { brand_description = "" },
    setSubmitting,
  ) => {
    this.updateStore(
      { store_description: brand_description },
      () => {
        setSubmitting(false);
      },
      this.handleError(),
    );
  };

  onSubmitReservationTermsForm = (
    { reservation_terms = "" },
    setSubmitting,
  ) => {
    this.updateStore(
      { reservation_terms: reservation_terms },
      () => {
        setSubmitting(false);
      },
      this.handleError(),
    );
  };

  onSubmitMarketplaceDetails = (values, setSubmitting) => {
    this.updateStore(
      { store_tags: values.store_tags },
      () => {
        setSubmitting(false);
      },
      this.handleError(),
    );
  };

  onSubmitMarketplaceSearch = (values, setSubmitting) => {
    this.updateStore(
      { search_tags: values.search_tags },
      () => {
        setSubmitting(false);
      },
      this.handleError(),
    );
  };

  handleOnSubmitEditPaymentMethod = (values, setSubmitting) => {
    let payment_methods = [];

    for (var key in values) if (values[key]) payment_methods.push(key);

    this.updateStore(
      { payment_methods },
      () => {
        setSubmitting(false);
        this.setState({ showEditPaymentMethodsModal: false });
      },
      this.handleError(),
    );
  };

  handleOnClickRemoveBackgroundImage = () => {
    MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to remove the current background image.`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ removeBackgroundImageBtnLoading: true });

        this.updateStore(
          { store_website_bg: "" },
          () => {
            this.setState({ showBackgroundImageModal: false, updating: false });
          },
          this.handleError(),
        );
      }
    });
  };

  dataUrlToFile = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: "image/jpeg" });
  };

  handleOnSubmitUploadBackgroundImage = () => {
    if (this.state.croppedImage) {
      this.dataUrlToFile(
        this.state.croppedImage,
        this.state.uploadImageFile.name,
      )
        .then((file) => {
          this.uploadFile(file, () => {});
        })
        .catch((err) => console.log(err));
    } else {
      console.log(this.state.uploadImageFile);
      this.uploadFile(this.state.uploadImageFile, () => {});
    }
  };

  handleOnClickWebsiteStatusBtn = (e) => {
    const {
      data: { brand },
    } = this.state;
    if (brand && brand.website_status === "private") {
      MySwal.fire({
        icon: "error",
        title: "Unpublished Brand",
        text: "Do you want to publish the brand before publishing your store?",
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
      }).then((result) => {
        if (result) {
          if (result.isConfirmed) {
            this.props.history.push(
              { pathname: `/brands/${brand.brand_url}/settings/status` },
              { storeId: this.state.data._id },
            );
          }
        }
      });
    } else {
      const website_status_update = GetDataFromEvent(e, "data-action");
      this.setState({ showPasswordConfirmModal: true, website_status_update });
    }
  };

  handleOnSubmitWebsitePrivacyUpdate = (values, setSubmitting) => {
    const { website_status_update = "", data = {} } = this.state;
    values.website_status = website_status_update;

    this.api
      .put({
        data: { data: window.btoa(JSON.stringify(values)) },
        url: `/store/${data._id}/website-status`,
      })
      .then(({ data }) => {
        const { result = "" } = data;
        if (result === "error") {
          MySwal.fire({
            icon: "error",
            title: "Oooops!",
            text: "Incorrect password!",
          });

          setSubmitting(false);
        } else {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text:
              website_status_update === "public"
                ? "Your website has been successfully published."
                : "Your website has been successfully unpublished",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            timer: 1000,
          });

          setSubmitting(false);

          this.getData();
          this.setState({ showPasswordConfirmModal: false });

          this.props.refreshUserRedux();
        }
      })
      .catch(() => {
        this.handleError();
      });
  };

  setActiveMenu = (activeMenuKey) => {
    const { webview = false } = this.props;

    this.setState({ activeMenuKey });
    if (webview) {
      this.props.history.push(
        `/webview/stores/${this.props.id}/settings/${
          activeMenuKey !== MENUS.main ? activeMenuKey : ""
        }`,
      );
    } else {
      this.props.history.push(
        `/stores/${this.props.id}/settings/${
          activeMenuKey !== MENUS.main ? activeMenuKey : ""
        }`,
      );
    }
  };

  setCroppedImage = (croppedImage) => {
    this.setState({ croppedImage });
  };

  saveAddress = () => {
    const { values, data } = this.state;
    const geocode_location = JSON.stringify(values.mapPosition);
    const req = {
      geocode_address_string: values.delivery_address,
      geocode_location,
    };
    if (JSON.parse(geocode_location).lat && !isEmpty(values.delivery_address)) {
      this.api
        .put({
          url: "/store/" + data._id,
          data: req,
        })
        .then(({ data: store }) => {
          data.geocode_address_string = store.geocode_address_string;
          data.geocode_location = store.geocode_location;
          values.mapPosition = { lat: "", lng: "" }; // for emptying on next edit
          this.setState({
            data,
            values,
            showMapComponent: false,
            showGeoAddress: true,
          });
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            timer: 3000,
          });
        })
        .catch(() => {
          this.handleError();
        });
    } else {
      MySwal.fire({
        icon: "error",
        title: "Ooops",
        text: "Ooops, Please enter a valid address by selecting from the autocomplete options",
        confirmButtonText: "Ok",
        timer: 5000,
      });
    }
  };
  render() {
    const {
      data = {},
      data_status = "",
      partner_upload_title = "",
      form_modal = {},
      showFormModal = false,
      mountFormModal = false,
      showStoreHoursModal = false,
      showOffDatesModal = false,
      showPreorderSettingsModal = false,
      showInAdvancedPreOrderModal = false,
      mountDisplaySettings = false,
      showEditPaymentGatewayModal = false,
      showEditPaymentMethodsModal = false,
      showBackgroundImageModal = false,
      showPartnerImageModal = false,
      showUploadBackgroundImageModal = false,
      updating = false,
      showPasswordConfirmModal = false,
      showNoPaymentDetailsModal = false,
      showUploadBrandLogoModal = false,
      showUploadNavbarLogoModal = false,
      showBrandNameModal = false,
      hasPaymentGateway = false,
      showAddDeliveryKeysModal = false,
      showViewOrdersModal = false,
    } = this.state;
    const { isLoading = data_status !== "fetched" } = this.props;
    const MARKET_SETTINGS_AVAILABLE =
      process.env.REACT_APP_MARKETPLACE_SETTINGS === "AVAILABLE" ||
      localStorage.getItem("REACT_APP_MARKETPLACE_SETTINGS") === "AVAILABLE";
    const display_settings_props = {
      data,
      setState: (state) => {
        this.setState(state);
      },
      handleOnClickWebsiteStatusBtn: this.handleOnClickWebsiteStatusBtn,
      brand_homepage: `/brands/${this.props.brand_url}`,
      onSubmitThemeColorsForm: this.onSubmitThemeColorsForm,
      onSubmitWebsiteContentForm: this.onSubmitWebsiteContentForm,
      onSubmitAboutPageContentForm: this.onSubmitAboutPageContentForm,
    };
    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        {isLoading ? (
          <></>
        ) : (
          <>
            <HeaderTextWithActions
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item
                    text="Stores"
                    link={`${this.props.webview ? "/webview" : ""}/stores`}
                  />
                  <Breadcrumbs.Item text={data.name} link={`#`} />
                  <Breadcrumbs.Item
                    text="Store Settings"
                    active={"true"}
                    link={"#"}
                  />
                </Breadcrumbs>
              }
              title={<>{data.name}</>}
              subtitle={<></>}
            />

            {mountFormModal ? (
              <FormModal
                show={showFormModal}
                onHide={() => {
                  this.setState({ showFormModal: false });
                }}
                {...form_modal}
              />
            ) : (
              <></>
            )}

            {showStoreHoursModal && (
              <SetStoreHoursModal
                show={showStoreHoursModal}
                onHide={() => {
                  this.setState({ showStoreHoursModal: false });
                }}
                data={data}
                onSubmit={this.onSubmit}
              />
            )}

            {showOffDatesModal && (
              <SetOffDatesModal
                show={showOffDatesModal}
                onHide={() => {
                  this.setState({ showOffDatesModal: false });
                }}
                off_dates={data.off_dates}
                onSubmit={this.onSubmit}
              />
            )}

            {showInAdvancedPreOrderModal && (
              <InAdvancedPreOrderModal
                show={showInAdvancedPreOrderModal}
                onHide={() => {
                  this.setState({ showInAdvancedPreOrderModal: false });
                }}
                data={data}
                onSubmit={this.onSubmit}
              />
            )}

            {showPreorderSettingsModal && (
              <PreorderSettingsModal
                storeId={this.props.id}
                preOrders={this.state.list}
                show={showPreorderSettingsModal}
                onHide={() => {
                  this.setState({ showPreorderSettingsModal: false });
                }}
                data={data}
                onSubmit={this.onSubmit}
                viewOrdersAffected={() => {
                  this.setState({ showViewOrdersModal: true });
                }}
              />
            )}

            <ViewOrdersModal
              show={showViewOrdersModal}
              onHide={() => {
                this.setState({ showViewOrdersModal: false });
              }}
              preOrders={this.state.list}
            />

            {showEditPaymentMethodsModal && (
              <EditPaymentMethodsModal
                show={showEditPaymentMethodsModal}
                onHide={() => {
                  this.setState({ showEditPaymentMethodsModal: false });
                }}
                data={data.payment_methods}
                onSubmit={this.handleOnSubmitEditPaymentMethod}
              />
            )}

            {/* {
                        mountOrientationModal ? <OrientationModal
                            show={showOrientationModal}
                            onHide={() => { this.setState({ showOrientationModal: false })}}
                            edit_orientation_mode={edit_orientation_mode}
                            orientation={getOrientation(data.brand.website_content, edit_orientation_mode)}
                            onSubmit={(orientation, setSubmitting) => {
                                let { website_content = {} } = data.brand;
                                website_content[`${edit_orientation_mode}_orientation`] = orientation;
                                this.onSubmitWebsiteContentForm(website_content, setSubmitting);
                            }}
                        /> : <></>
                    } */}

            {/* <SelectionModal
                        show={showEditLogoSelectionModal}
                        onHide={() => { this.setState({ showEditLogoSelectionModal: false })}}
                        title={<>Edit Brand Logo</>}
                        options={[
                            {
                                title: "upload new image",
                                icon: "Files/Uploaded-file",
                                desc: "",
                                onClick: () => {
                                    this.setState({ showEditLogoSelectionModal: false, showUploadBrandLogoModal: true, uploadImageMode: "brand_logo" })
                                }
                            },
                            {
                                title: "edit orientation",
                                icon: "Text/Align-justify",
                                desc: "",
                                onClick: () => {
                                    this.setState({ mountOrientationModal: false });
                                    setTimeout(() => { this.setState({ showEditLogoSelectionModal: false, showOrientationModal: true, mountOrientationModal: true, edit_orientation_mode: "brand_logo" }) }, 50); 
                                }
                            },
                        ]}
                    /> */}

            <SelectionModal
              show={showBackgroundImageModal}
              onHide={() => {
                this.setState({ showBackgroundImageModal: false });
              }}
              title={<>Edit Custom Homepage Background</>}
              options={[
                {
                  title: "remove",
                  icon: "General/Trash",
                  desc: "",
                  onClick: this.handleOnClickRemoveBackgroundImage,
                },
                {
                  title: "upload new image",
                  icon: "Files/Uploaded-file",
                  desc: "",
                  onClick: () => {
                    this.setState({
                      showUploadBackgroundImageModal: true,
                      showBackgroundImageModal: false,
                    });
                  },
                },
              ]}
            />

            <Modal
              centered
              show={showPartnerImageModal}
              onHide={() => {
                this.setState({ showPartnerImageModal: false });
              }}
            >
              <Modal.Header>
                <Modal.Title>Upload image</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="text-center">
                  {this.state.previewUploadImageFile !== "" ? (
                    <PreviewCropper
                      image={this.state.previewUploadImageFile}
                      aspectRatio={
                        partner_upload_title.includes("Logo") ? 1 / 1 : 20 / 11
                      }
                      setCroppedImage={this.setCroppedImage}
                    />
                  ) : (
                    <ImageUpload
                      onError={this.handleError}
                      onChange={(file, preview) => {
                        this.setState({
                          uploadImageFile: file,
                          previewUploadImageFile: preview,
                        });
                      }}
                      onRemove={() => {}}
                      removeText={"Remove"}
                      name={"img_upload"}
                      upload_icon={"flaticon-upload"}
                      label={
                        <div style={{ margin: "1rem 0" }}>
                          <Label
                            text={
                              <>
                                <span> {partner_upload_title} </span>
                              </>
                            }
                          />
                          <div
                            className="text-muted"
                            style={{ fontSize: "0.8rem" }}
                          >
                            Preferred dimensions:
                            {partner_upload_title.includes("Logo")
                              ? "80x80, 160x160 \n1:1 aspect ratio"
                              : "400x220,800x440 \n20:11 aspect ratio"}
                          </div>
                        </div>
                      }
                      useResize={false}
                      maxFileSize="3000"
                      maxFileSizeText="3MB"
                    />
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                {this.state.previewUploadImageFile !== "" && (
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.setState({
                        uploadImageFile: "",
                        previewUploadImageFile: "",
                      });
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  variant="primary"
                  disabled={
                    (this.state.previewUploadImageFile !== ""
                      ? ""
                      : "disabled") || (updating ? "disabled" : "")
                  }
                  onClick={this.handleOnSubmitUploadBackgroundImage}
                  className={GetSubmitClassList(updating, "")}
                >
                  Upload
                </Button>
              </Modal.Footer>
            </Modal>

            <BrandNameModal
              show={showBrandNameModal}
              onHide={() => {
                this.setState({ showBrandNameModal: false });
              }}
              values={data.brand}
              onSubmit={({ name = "" }, setSubmitting) => {
                this.updateBrand(
                  { name },
                  () => {
                    setSubmitting(false);
                    this.setState({ showBrandNameModal: false });
                  },
                  this.handleError(),
                );
              }}
            />

            <Modal
              centered
              show={showUploadBackgroundImageModal}
              onHide={() => {
                this.setState({
                  showUploadBackgroundImageModal: false,
                  previewUploadImageFile: "",
                  uploadImageFile: "",
                });
              }}
            >
              <Modal.Header>
                <Modal.Title>Upload new background image</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="text-center">
                  {this.state.previewUploadImageFile !== "" ? (
                    <>
                      <PreviewCropper
                        image={this.state.previewUploadImageFile}
                        setCroppedImage={this.setCroppedImage}
                      />
                      {/* <PreviewPhoto
                                                src={this.state.previewUploadImageFile}
                                                onClickRemove={() => {
                                                    this.setState({
                                                        uploadImageFile: "",
                                                        previewUploadImageFile: "",
                                                    });
                                                }}
                                                showImage={false}
                                            />
                                            <div> {this.state.uploadImageFile.name} </div> */}
                    </>
                  ) : (
                    <ImageUpload
                      onError={this.handleError}
                      onChange={(file, preview) => {
                        this.setState({
                          uploadImageFile: file,
                          previewUploadImageFile: preview,
                        });
                      }}
                      onRemove={() => {}}
                      removeText={"Remove"}
                      name={"img_upload"}
                      upload_icon={"flaticon-upload"}
                      label={
                        <div style={{ margin: "1rem 0" }}>
                          <Label
                            text={
                              <>
                                <span> Background Image </span>
                              </>
                            }
                          />
                          <div
                            className="text-muted"
                            style={{ fontSize: "0.8rem" }}
                          >
                            Preferred dimensions: 1880 x 1253
                          </div>
                        </div>
                      }
                      useResize={false}
                      maxFileSize="25000"
                      maxFileSizeText="25MB"
                    />
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                {this.state.previewUploadImageFile !== "" && (
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.setState({
                        uploadImageFile: "",
                        previewUploadImageFile: "",
                      });
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  variant="primary"
                  disabled={
                    (this.state.previewUploadImageFile !== ""
                      ? ""
                      : "disabled") || (updating ? "disabled" : "")
                  }
                  onClick={this.handleOnSubmitUploadBackgroundImage}
                  className={GetSubmitClassList(updating, "")}
                >
                  Upload
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              centered
              show={showUploadBrandLogoModal}
              onHide={() => {
                this.setState({
                  showUploadBrandLogoModal: false,
                  uploadImageFile: "",
                  previewUploadImageFile: "",
                });
              }}
            >
              <Modal.Header>
                <Modal.Title>Upload new store logo</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="text-center">
                  {this.state.previewUploadImageFile !== "" ? (
                    <>
                      <PreviewPhoto
                        src={this.state.previewUploadImageFile}
                        onClickRemove={() => {
                          this.setState({
                            uploadImageFile: "",
                            previewUploadImageFile: "",
                          });
                        }}
                      />
                      <div> {this.state.uploadImageFile.name} </div>
                    </>
                  ) : (
                    <ImageUpload
                      onError={this.handleError}
                      onChange={(file, preview) => {
                        this.setState({
                          uploadImageFile: file,
                          previewUploadImageFile: preview,
                        });
                      }}
                      onRemove={() => {}}
                      removeText={"Remove"}
                      name={"img_upload"}
                      upload_icon={"flaticon-upload"}
                      label={
                        <div style={{ margin: "1rem 0" }}>
                          <Label
                            text={
                              <>
                                <span> Store Logo </span>
                              </>
                            }
                          />
                          <div
                            className="text-muted"
                            style={{ fontSize: "0.8rem" }}
                          >
                            Suggested dimensions is 250x250 or 200x50.
                          </div>
                        </div>
                      }
                      useResize={false}
                    />
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  disabled={
                    (this.state.previewUploadImageFile !== ""
                      ? ""
                      : "disabled") || (updating ? "disabled" : "")
                  }
                  onClick={this.handleOnSubmitUploadBackgroundImage}
                  className={GetSubmitClassList(updating, "")}
                >
                  Upload
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              centered
              show={showUploadNavbarLogoModal}
              onHide={() => {
                this.setState({
                  showUploadNavbarLogoModal: false,
                  uploadImageFile: "",
                  previewUploadImageFile: "",
                });
              }}
            >
              <Modal.Header>
                <Modal.Title>Upload new navbar logo</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="text-center">
                  {this.state.previewUploadImageFile !== "" ? (
                    <>
                      <PreviewPhoto
                        src={this.state.previewUploadImageFile}
                        onClickRemove={() => {
                          this.setState({
                            uploadImageFile: "",
                            previewUploadImageFile: "",
                          });
                        }}
                      />
                      <div> {this.state.uploadImageFile.name} </div>
                    </>
                  ) : (
                    <ImageUpload
                      onError={this.handleError}
                      onChange={(file, preview) => {
                        this.setState({
                          uploadImageFile: file,
                          previewUploadImageFile: preview,
                        });
                      }}
                      onRemove={() => {}}
                      removeText={"Remove"}
                      name={"img_upload"}
                      upload_icon={"flaticon-upload"}
                      label={
                        <div style={{ margin: "1rem 0" }}>
                          <Label
                            text={
                              <>
                                <span> Navbar Logo </span>
                              </>
                            }
                          />
                          <div
                            className="text-muted"
                            style={{ fontSize: "0.8rem" }}
                          >
                            {/* Preferred dimensions: 1880 x 1253 */}
                          </div>
                        </div>
                      }
                      useResize={false}
                    />
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  disabled={
                    (this.state.previewUploadImageFile !== ""
                      ? ""
                      : "disabled") || (updating ? "disabled" : "")
                  }
                  onClick={this.handleOnSubmitUploadBackgroundImage}
                  className={GetSubmitClassList(updating, "")}
                >
                  Upload
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Update website privacy settings */}
            <ConfirmPasswordModal
              show={showPasswordConfirmModal}
              onHide={() => {
                this.setState({ showPasswordConfirmModal: false });
              }}
              body={
                <>
                  <p className="text-dark">
                    You are about to{" "}
                    {this.state.website_status_update === "private" ? (
                      <>unpublish</>
                    ) : (
                      <>publish</>
                    )}{" "}
                    your site.
                  </p>
                </>
              }
              onSubmit={this.handleOnSubmitWebsitePrivacyUpdate}
            />

            <Modal
              centered
              size="sm"
              show={showNoPaymentDetailsModal}
              onHide={() => {
                this.setState({ showNoPaymentDetailsModal: false });
              }}
            >
              <Modal.Header>
                <Modal.Title>Setup your payment details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Oooops! Looks like you haven't setup your payment details.
                  Please send us a message on support@pickup.ph regarding your
                  PayMongo keys and your desired payment methods so we can set
                  this up for you. Thank you!
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => {
                    this.setState({ showNoPaymentDetailsModal: false });
                  }}
                >
                  Got it!
                </Button>
              </Modal.Footer>
            </Modal>

            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={MENUS.main}
              activeKey={this.state.activeMenuKey}
            >
              <Row>
                <Col sm={3}>
                  <Card>
                    <Card.Body>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.main}
                            onClick={() => this.setActiveMenu(MENUS.main)}
                          >
                            Store Settings
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.logo}
                            onClick={() => this.setActiveMenu(MENUS.logo)}
                          >
                            Store Logo
                          </Nav.Link>
                        </Nav.Item>
                        {MARKET_SETTINGS_AVAILABLE && (
                          <Nav.Item>
                            <Nav.Link
                              eventKey={MENUS.marketplace}
                              onClick={() =>
                                this.setActiveMenu(MENUS.marketplace)
                              }
                            >
                              Marketplace Settings
                            </Nav.Link>
                          </Nav.Item>
                        )}

                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.title}
                            onClick={() => this.setActiveMenu(MENUS.title)}
                          >
                            Homepage Title
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.theme}
                            onClick={() => this.setActiveMenu(MENUS.theme)}
                          >
                            Theme Colours
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.about}
                            onClick={() => this.setActiveMenu(MENUS.about)}
                          >
                            About Us Content
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.homepage}
                            onClick={() => this.setActiveMenu(MENUS.homepage)}
                          >
                            Home Page Content
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.payment}
                            onClick={() => this.setActiveMenu(MENUS.payment)}
                          >
                            Payment Settings
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.pickup}
                            onClick={() => this.setActiveMenu(MENUS.pickup)}
                          >
                            Pickup Fulfillment Settings
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.delivery}
                            onClick={() => this.setActiveMenu(MENUS.delivery)}
                          >
                            Delivery Settings
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.dinein}
                            onClick={() => this.setActiveMenu(MENUS.dinein)}
                          >
                            Dine-in Settings
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.curbside}
                            onClick={() => this.setActiveMenu(MENUS.curbside)}
                          >
                            Curbside Pickup Settings
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.mealplan}
                            onClick={() => this.setActiveMenu(MENUS.mealplan)}
                          >
                            Meal Plan Settings
                          </Nav.Link>
                        </Nav.Item>
                        {data.apiclient_names.length !== 0 && (
                          <Nav.Item>
                            <Nav.Link
                              eventKey={MENUS.experiences}
                              onClick={() =>
                                this.setActiveMenu(MENUS.experiences)
                              }
                            >
                              Experiences Settings
                            </Nav.Link>
                          </Nav.Item>
                        )}
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.status}
                            onClick={() => this.setActiveMenu(MENUS.status)}
                          >
                            Website Status
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.activity}
                            onClick={() => this.setActiveMenu(MENUS.activity)}
                          >
                            Activity Log
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey={MENUS.main}>
                      <Card className="card-custom gutter-b">
                        {data.is_test_payment && (
                          <WarningAlert
                            message={
                              <>
                                Store is currently using test payment, update
                                your payment keys here{" "}
                                <Nav.Link
                                  eventKey={MENUS.payment}
                                  onClick={() =>
                                    this.setActiveMenu(MENUS.payment)
                                  }
                                >
                                  Payment Settings
                                </Nav.Link>
                              </>
                            }
                          />
                        )}
                        <Card.Header>
                          <Card.Title>Store Settings</Card.Title>
                        </Card.Header>

                        <Card.Body>
                          <StoreSettingsDiv
                            data={data}
                            state={this.state}
                            api={this.api}
                            saveAddress={this.saveAddress}
                            showFormModal={this.showFormModal}
                            setState={(state) => {
                              this.setState(state);
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.marketplace}>
                      <>
                        <MarketplaceDiv
                          editable={true}
                          data={data}
                          setState={(state) => {
                            this.setState(state);
                          }}
                          title="Marketplace Settings"
                          onSubmit={this.onSubmitMarketplaceDetails}
                        />
                      </>
                    </Tab.Pane>

                    <Tab.Pane eventKey={MENUS.admin_marketplace}>
                      <MarketplaceDiv
                        setState={(state) => {
                          this.setState(state);
                        }}
                        editable={true}
                        data={data}
                        title="Marketplace Settings"
                        onSubmit={this.onSubmitMarketplaceDetails}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.logo}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <LogoFormDiv
                            fields={{ logo: "store_logo" }}
                            title="Store Logo"
                            {...display_settings_props}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.title}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <StoreTitleFormDiv {...display_settings_props} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.theme}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <ThemeColorsFormDiv {...display_settings_props} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.about}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <AboutContentFormDiv
                            field="store_description"
                            {...display_settings_props}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.homepage}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <HomepageContentFormDiv
                            fields={{ website_bg: "store_website_bg" }}
                            {...display_settings_props}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.payment}>
                      <div className="general-bg-color">
                        <PaymentSettingsDiv
                          data={data}
                          showEditPaymentGatewayModal={
                            showEditPaymentGatewayModal
                          }
                          setState={(state) => {
                            this.setState(state);
                          }}
                          refreshUserRedux={this.props.refreshUserRedux}
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.delivery}>
                      <div className="general-bg-color">
                        <DeliverySettingsDiv
                          brand={data.brand}
                          store={data}
                          showEditPaymentGatewayModal={
                            showEditPaymentGatewayModal
                          }
                          showAddDeliveryKeysModal={showAddDeliveryKeysModal}
                          setState={(state) => {
                            this.setState(state);
                          }}
                          editable={true}
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.dinein}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <DineInSettingsDiv
                            store={data}
                            setState={(state) => {
                              this.setState(state);
                            }}
                            user={this.props.user}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.curbside}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <CurbsideSettingsDiv
                            store={data}
                            setState={(state) => {
                              this.setState(state);
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.pickup}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <PickupSettingsDiv
                            store={data}
                            setState={(state) => {
                              this.setState(state);
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.mealplan}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <MealPlanSettingsDiv
                            store={data}
                            setState={(state) => {
                              this.setState(state);
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.experiences}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <ReservationsSettingsDiv
                            store={data}
                            setState={(state) => {
                              this.setState(state);
                            }}
                            user={this.props.user}
                            onSubmitReservationTermsForm={
                              this.onSubmitReservationTermsForm
                            }
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.status}>
                      {mountDisplaySettings ? (
                        <div className="general-bg-color">
                          <WebsiteStatusFormDiv
                            {...display_settings_props}
                            hasPaymentGateway={hasPaymentGateway}
                            isStore={true}
                            store_url={data.store_url}
                            store_id={data._id}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.admin_delivery}>
                      <div className="general-bg-color">
                        <DeliverySettingsDiv
                          brand={data.brand}
                          store={data}
                          showEditPaymentGatewayModal={
                            showEditPaymentGatewayModal
                          }
                          showAddDeliveryKeysModal={showAddDeliveryKeysModal}
                          setState={(state) => {
                            this.setState(state);
                          }}
                          editable={true}
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.activity}>
                      <div className="general-bg-color">
                        <Card className="card-custom gutter-b">
                          <Card.Header>
                            <Card.Title>Activity Log</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <Activities
                              collection="store"
                              identifier={data._id}
                              api={this.api}
                            />
                          </Card.Body>
                        </Card>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </>
        )}
      </>
    );
  }
}
