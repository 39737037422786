import React from "react";
import {
  FormItem,
  AnimateDiv,
  DFlex,
  Block,
  Separator,
  OrderTimeRender,
  RequiredAsterisk,
  SVGIcon,
  DeliveryRadio,
  CountActive,
  InfoAlert,
  WarningAlert,
} from "../../components/Base";
import { isNil } from "lodash";
import BWSection from "../../components/widgets/BWSection";
import { MapComponent } from "../../components/widgets/GMapsWidget";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import BrandWebsite from "../../partials/BrandWebsite";
import { setStorage } from "../../../_metronic/_helpers";
// import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'
import {
  CURRENCY,
  ASAP_ORDER_TIME_FORMAT,
  PAYMENT_DELAY_TIME,
  PRIMARY_COLOR,
  RESERVATION_LIMIT_MS,
  DB_DATE_FORMAT,
  MEAL_DATE_FORMAT,
  DANGER_COLOR,
  EXTERNAL_CHECKOUT_KEY,
  DELIVERY_KM_LIMIT,
} from "../../constants";
import {
  computeTotalCartAmount,
  GetDataFromEvent,
  GetSubmitClassList,
  pickExcept,
  toPesoAmount,
  formatLmoveQouteResponse,
  formatLmoveRequest,
  formatLmoveError,
  formatMrSpeedyRequest,
  formatMrSpeedyWarning,
  formatGrabRequest,
  formatGrabError,
  formatOrderType,
  computePromoTotal,
  computeGrandTotal,
  tryParse,
  computeAdditonalCharge,
  getServiceCharge,
  formatAdditionalChargeType,
} from "../../helpers";
import ProSourceForm from "../../components/ProSourceForm";
import PaymentMethod from "../../partials/branded-website/PaymentMethod";
import LimitsModal from "../../partials/branded-website/LimitsModal";
import DeliveryAddressModal from "../../partials/branded-website/DeliveryAddressModal";
import { RadioGroup, FormControlLabel } from "@material-ui/core";
import Payment from "payment";

import axios from "axios";
import qs from "qs";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, Redirect, useParams } from "react-router-dom";
import ChangeDateTimeModal from "../../partials/branded-website/ChangeDateTimeModal";
// import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import Timer from "react-compound-timer";
import { setTheme } from "../../theme";
import styled from "styled-components";
import { deviceDetect } from "react-device-detect";
import moment from "moment";
import ReactGA from "react-ga4";
import { computeDiscount } from "../../helpers/branded-website";
import { CheckoutPromoForm } from "../../partials/branded-website/CheckoutPromoForm";
import DineInInfo from "../../partials/branded-website/menu/DineInInfo";
import { isEmpty } from "lodash";

import StoreInfo from "../../partials/branded-website/menu/StoreInfo";
import PacmanLoading from "../../components/widgets/PacmanLoading/PacmanLoading";
const MySwal = withReactContent(Swal);

const instance = axios.create({});
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => Promise.reject(err),
);

export default function CheckoutPage(props) {
  const { token = "", external_checkout = false } = qs.parse(
    props.location.search,
    {
      ignoreQueryPrefix: true,
    },
  );
  const { backup_id = "" } = useParams();

  return (
    <MainComponent
      token={token}
      external_checkout={external_checkout}
      backup_id={backup_id}
      {...props}
    />
  );
}

function formatCartDetails(cart_details) {
  let new_cart_details = [];
  for (var i = 0; i < cart_details.length; i++) {
    const {
      item = {},
      qty = 0,
      price = 0,
      regular_price = 0,
      extras = [],
      isBulk,
    } = cart_details[i];
    new_cart_details.push({
      item: {
        _id: item._id,
      },
      qty,
      price,
      regular_price,
      isBulk,
      extras: extras.map(({ extra_group_id, extra_id, price }) => {
        return {
          extra_group_id: extra_group_id._id,
          extra_id: extra_id._id,
          price,
        };
      }),
    });
  }

  return new_cart_details;
}

/**
 * Set required fields on the checkout form
 * @param {string} orderType - Order type: pickup, delivery, etc
 * @param {string} paymentMethod - Payment method: card, gcash, grab pay, etc
 * @returns
 */
function setRequiredFields(
  orderType = "",
  paymentMethod = "",
  isInStoreOrder = false,
) {
  let basicRequiredArray = [
    "first_name",
    "last_name",
    "email",
    "contact_number",
  ];
  let pickupRequiredArray = basicRequiredArray;
  let thirdPartyPickupRequiredArray = basicRequiredArray.concat([
    "third_p_first_name",
    "third_p_last_name",
    "third_p_contact_number",
  ]);
  // let deliveryRequiredArray = basicRequiredArray.concat(["delivery_payment_method", "delivery_partner", "delivery_fee"])
  let deliveryRequiredArray = basicRequiredArray;
  let curbsideRequiredArray = basicRequiredArray.concat([
    "vehicle_make",
    "vehicle_color",
  ]);
  let dineinRequiredArray = basicRequiredArray;

  let required =
    orderType === "pickup"
      ? pickupRequiredArray
      : orderType === "third_party_pickup"
      ? thirdPartyPickupRequiredArray
      : orderType === "curbside_pickup"
      ? curbsideRequiredArray
      : orderType === "dine_in"
      ? dineinRequiredArray
      : orderType === "delivery"
      ? deliveryRequiredArray
      : [];

  if (!isInStoreOrder) {
    required = required.concat(["payment_method"]);
    if (orderType === "delivery") {
      required = required.concat([
        "delivery_payment_method",
        "delivery_partner",
        // "delivery_fee",
      ]);
    }
  }

  if (paymentMethod === "card") {
    required = required.concat([
      "card_details",
      "card_number",
      "cvc",
      "expiration_date",
    ]);
  }

  return required;
}

class MainComponent extends BrandWebsite {
  // private fields
  #backupData = {};
  #isFromCheckoutEmail = false;
  #existingOrderId = "";

  componentDidUpdate() {
    // console.log("checkout maincomponent state: ", this.state);
    // console.log("checkout maincomponent props: ", this.props);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getData();
    // document.getElementById("kt_header").remove();

    window.addEventListener("message", (event) => {
      const eventData = tryParse(event.data);

      const eventName = eventData?.message || eventData;
      const eventUrl = eventData?.url || "";

      if (eventName === "paymentPending" && eventUrl !== window.location.href) {
        this.afterCheckout(eventUrl);
      }

      if (
        eventName === "3DS-authentication-complete" ||
        eventName === "trackingPage"
      ) {
        const baseURL = this.api.getBaseURL(),
          { payment_id = "" } = this.state;
        instance
          .post(
            "/branded-website/payments/payment-logs",
            {
              message: JSON.stringify({
                action: "3DS-authentication-complete",
              }),
            },
            {
              headers: { Authorization: `Basic ${window.btoa(payment_id)}` },
              baseURL,
            },
          )
          .then(() => this.afterCheckout(this.state.afterIframeRedirect));
      }
    });

    // window.addEventListener("beforeunload", (event) => {
    //     const { submitted = false } = this.state;
    //     console.log("submitted", submitted);
    //     if(!submitted) {
    //         event.preventDefault();
    //         event.returnValue = "";
    //     }
    // })

    // window.addEventListener("unload pagehide", () => {
    //     const { submitted = false } = this.state, { token = "" } = this.props, baseURL = this.api.getBaseURL();
    //     if(!submitted) {
    //         console.log("mark this cart as expired!")
    //         navigator.sendBeacon(baseURL + "branded-website/carts/mark-as-expired?data=" + window.btoa(JSON.stringify({ token })));
    //     }
    // })

    window.addEventListener("popstate", async (e) => {
      await this.markAsExpired();
    });
  }

  handle3dsIframeLoad = async (event) => {
    const { payment_id = "" } = this.state;

    await instance.post(
      "/branded-website/payments/payment-logs",
      { message: JSON.stringify({ action: "3DS-verification-redirected" }) },
      {
        headers: { Authorization: `Basic ${window.btoa(payment_id)}` },
        baseURL: this.api.getBaseURL(),
      },
    );
  };

  async getData() {
    const { brand_url = "" } = this.state,
      { backup_id = "" } = this.props,
      baseURL = this.api.getBaseURL();
    let { token = "" } = this.props;
    // backupValues = {}
    this._isMounted && this.setState({ data_status: "fetching" });

    // console.log("checkout maincomponent getdata token: ", token)

    if (backup_id) {
      // from checkout email
      await instance
        .get(`/branded-website/carts/backup/${backup_id}`, { baseURL })
        .then((response) => {
          // console.log("checkout getData response: ", response)
          this.backupValues = response.data.values;
          this.isFromCheckoutEmail = true;
          this.existingOrderId = response.data.order_id;
          token = response.data.values.token;
        });
      // console.log("checkout maincomponent getdata backupValues: ", this.backupValues)
    }

    if (token === "") {
      // no token - checkout is already completed
      console.log("empty token");
      if (this.existingOrderId) {
        this.setState({ redirectTo: `/track?token=${this.existingOrderId}` });
      } else {
        this.setState({ redirectTo: `/` });
      }
      return;
    }

    await instance
      .post(
        "/branded-website/carts/verify",
        { data: window.btoa(JSON.stringify({ token })) },
        {
          headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
          baseURL,
        },
      )
      .then((response) => {
        if (response.data) {
          const {
            success = false,
            data = {},
            retry_token = "",
          } = response.data;
          const { selected_store = {}, store = {} } = data;
          const { website_theme = "" } = store;
          const { items_directory = {}, categories } = selected_store;
          const state = {
            website_theme: setTheme(
              website_theme !== "" ? JSON.parse(website_theme) : {},
            ),
          };

          if (success) {
            this._isMounted &&
              this.setState({
                renderModals: false,
                data,
                data_status: "fetched",
                success,
                items_directory,
                categories_directory: categories,
                universal_retry_token: retry_token,
                showSplashScreen: false,
                ...state,
              });

            this.handleOnDataFetched();
          } else {
            this.setState({ redirectTo: "/session-expired?" + token });
          }
        } else {
          this.setState({ redirectTo: "/" });
        }
      })
      .catch(() => {
        // this._isMounted && this.setState({error: error, showSwalError : true});
        // this.handleOnDataFetchError();
        this.setState({ redirectTo: "/" });
      });
  }

  afterCheckout = (redirectURL = "") => {
    setTimeout(() => {
      if (window.self !== window.top) {
        // checking if it is an iframe
        window.parent.location.href = redirectURL;
      } else {
        window.location.href = redirectURL;
      }
    }, PAYMENT_DELAY_TIME);
  };

  setTitle = () => {
    // const { data: { brand: { name = "" } } }= this.state;
    // this.setState({ title: "Checkout | " + name })
    const brandName = this.state.data.brand.name;
    const storeName = this.state.data.selected_store.name;
    this.setState({ title: "Checkout | " + brandName + " | " + storeName });
  };

  isExternalCheckout(external_checkout) {
    if (external_checkout) {
      setStorage(EXTERNAL_CHECKOUT_KEY, true);
    } else {
      setStorage(EXTERNAL_CHECKOUT_KEY, false);
    }
  }

  handleOnDataFetched2 = () => {
    let {
        layout_props = {},
        data = {},
        current_taken_items_counter = {},
        current_taken_categories_counter = {},
      } = this.state,
      { cart_details = [], brand = {} } = data,
      { brand_logo = "", navbar_logo = "" } = brand,
      { external_checkout } = this.props;

    layout_props.logo =
      navbar_logo !== "" && navbar_logo !== null ? navbar_logo : brand_logo;
    layout_props.showMenuItems = false;
    layout_props.hideCartIcon = true;
    layout_props.hideHeader = external_checkout;
    this.isExternalCheckout(external_checkout);

    cart_details.forEach((cart_detail) => {
      const { item = {}, qty = 0 } = cart_detail,
        item_object = this.getItemFromItemsDirectory(item._id);
      this.adjustCurrentAvailableCounters(item._id, qty);
      // JD - Setting the current taken items & categories counter will let the logic determine the reserved items for the current transaction
      if (current_taken_items_counter.hasOwnProperty(item._id)) {
        current_taken_items_counter[item._id] += qty;
      } else {
        current_taken_items_counter[item._id] = qty;
      }

      if (
        current_taken_categories_counter.hasOwnProperty(item_object.category)
      ) {
        current_taken_categories_counter[item_object.category] += qty;
      } else {
        current_taken_categories_counter[item_object.category] = qty;
      }
    });

    this.setState({
      layout_props,
      data,
      current_taken_items_counter,
      current_taken_categories_counter,
    });
  };
  updateCartOnData = async (cart_details, cart_id, brand_url) => {
    try {
      const updated_cart_details = cart_details.map((cart) => {
        return {
          _id: cart._id,
          item: cart.item._id,
          qty: cart.qty,
          price: cart.price,
          regular_price: cart.regular_price,
          isBulk: cart.isBulk,
          extras: cart.extras,
        };
      });
      const data = {
        cart_details: updated_cart_details,
      };
      return updateCartDetails(data, cart_id, brand_url, this.api.getBaseURL());
    } catch (err) {
      await MySwal.fire({
        icon: "error",
        title: "Ooops!",
        html: "There's a problem on updating the cart details. Please try again.",
      });
    }
  };

  updateCartOrderType = async (orderType) => {
    try {
      let { data = {}, brand_url = "" } = this.state,
        { order_details = {}, _id: cart_id } = data;
      order_details.order_type = orderType;
      order_details.order_subtype = orderType;
      let details = { order_details: JSON.stringify(order_details) };
      if (orderType === "pickup") {
        details = {
          ...details,
          ...{
            delivery_partner: "",
            delivery_fee: "",
            delivery_details: "",
            priority_fee: 0,
          },
        };
      }
      return updateCartDetails(
        details,
        cart_id,
        brand_url,
        this.api.getBaseURL(),
      );
    } catch (err) {
      await MySwal.fire({
        icon: "error",
        title: "Ooops!",
        html: "There's a problem on updating the order type. Please try again.",
      });
    }
  };

  verifyPromoCode = async ({
    promo_code,
    total_amount,
    order_type,
    storeId,
    isMealPlan,
    isCOD,
  }) => {
    try {
      const { data } = await this.api.post({
        url: "/branded-website/promos/verify",
        data: {
          promo_code,
          total_amount,
          order_type,
          storeId,
          isMealPlan,
          isCOD,
        },
      });
      return data && data.active;
    } catch (error) {
      if (error.hasOwnProperty("response")) {
        const { data = {} } = error.response;
        if (data.error === "PromoInvalidError") {
          return false;
        }
      }
    }
  };
  handleOnSubmit = async (values, setSubmitting) => {
    const { data = {}, brand_url = "" } = this.state,
      {
        order_details = {},
        store = {},
        cart_details = [],
        _id = "",
        isMealPlan = false,
      } = data,
      { order_time = "", order_date = "", order_type = "" } = order_details;

    await this.updateCartOnData(cart_details, _id, brand_url);
    ReactGA.event("submitPlaceOrder", {
      category: "placeOrder",
      action: "submitPlaceOrder",
      label: "Submit Place Order",
      store_name: data.store.name,
      brand_url: brand_url,
      brand_name: data.brand.name,
    });

    try {
      instance
        .post(
          "/branded-website/carts/can-checkout",
          {
            data: window.btoa(
              JSON.stringify({
                order_date,
                order_time,
                cart_details: formatCartDetails(cart_details),
                store_id: store._id,
                cart_id: _id,
              }),
            ),
          },
          {
            headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
            baseURL: this.api.getBaseURL(),
          },
        )
        .then((response) => {
          const {
            success = false,
            inventory_check_result = true,
            invalid_keys = false,
            can_change_order_date = false,
          } = response.data;
          if (success) {
            ReactGA.event("successPlaceOrder", {
              category: "placeOrder",
              action: "successPlaceOrder",
              label: "Success Place Order",
              store_name: data.store.name,
              brand_url: brand_url,
              brand_name: data.brand.name,
            });

            if (order_type === "delivery") {
              // console.log("checkout handleOnSubmit delivery")
              if (isMealPlan) {
                if (values.meal_plan_delivery_quotes.length) {
                  this.setState({ submitted: true });
                  this.handleOnPlaceOrder(values, setSubmitting);
                } else {
                  setSubmitting(false);
                  this.handleOnError();
                }
              } else {
                if (
                  data.order_details.inStoreOrderUser &&
                  !this.isFromCheckoutEmail
                ) {
                  // console.log("handleOnSubmit");
                  this.handleSendToCustomerForCheckout(values, setSubmitting);
                } else {
                  if (
                    values.delivery_fee !== "" &&
                    values.delivery_partner !== "" &&
                    values.delivery_details !== ""
                  ) {
                    this.setState({ submitted: true });
                    this.handleOnPlaceOrder(values, setSubmitting);
                  } else {
                    setSubmitting(false);
                    this.handleOnError();
                  }
                }
              }
            } else if (
              order_type === "pickup" ||
              order_type === "third_party_pickup" ||
              order_type === "curbside_pickup" ||
              order_type === "dine_in"
            ) {
              this.setState({ submitted: true });
              if (
                data.order_details.inStoreOrderUser &&
                !this.isFromCheckoutEmail
              ) {
                this.handleSendToCustomerForCheckout(values, setSubmitting);
              } else {
                this.handleOnPlaceOrder(values, setSubmitting);
              }
            }
          } else if (!inventory_check_result) {
            ReactGA.event("failPlaceOrder", {
              category: "placeOrder",
              action: "failPlaceOrder",
              label: "Fail Place Order",
              store_name: data.store.name,
              brand_url: brand_url,
              brand_name: data.brand.name,
            });

            setSubmitting(false);
            this.setState({ mountOrderDetailsForm: false }, () => {
              let changeDateTimeModalText =
                "Ooops! Looks like some of the items you have added is not available for this time slot. Please select another time slot below :";
              let orderDateTimeModalAction = "Proceed";
              let handleChangeDateTime =
                this.handleOnSubmitOrderDetailsFormCheckout;
              let showOrderDateTimeModalButton = false;

              if (!can_change_order_date) {
                changeDateTimeModalText =
                  "Sorry! This store is almost closing/already closed and not accepting orders anymore.";
                orderDateTimeModalAction = "Back to home";
                if (window.ReactNativeWebView) {
                  handleChangeDateTime = () => {
                    window.ReactNativeWebView.postMessage("backToHome");
                    window.history.back();
                  };
                } else if (window !== window.parent) {
                  handleChangeDateTime = () => {
                    window.parent.postMessage("backToHome", "*");
                    window.history.back();
                  };
                } else {
                  handleChangeDateTime = () =>
                    setTimeout(() => (window.location = "/"), 500);
                }

                showOrderDateTimeModalButton = true;
              }

              this.setState({
                mountOrderDetailsForm: true,
                showChangeDateTimeModal: true,
                showCartModalOrderTimeError: true,
                displayOrderDateAndTimeOptions: can_change_order_date,
                handleChangeDateTime,
                changeDateTimeModalText,
                orderDateTimeModalAction,
                showOrderDateTimeModalButton,
              });
            }); // unmount to refresh the list of order time
          } else {
            if (invalid_keys) {
              MySwal.fire({
                icon: "error",
                title: "Ooops!",
                html: "There's a problem on creating payment request",
              });
              setSubmitting(false);
              setTimeout(() => {
                this.setState({ redirectTo: "/" });
              }, 2000);
            } else {
              ReactGA.event("failPlaceOrder", {
                category: "placeOrder",
                action: "failPlaceOrder",
                label: "Fail Place Order",
                store_name: data.store.name,
                brand_url: brand_url,
                brand_name: data.brand.name,
              });

              setSubmitting(false);
              this.setState({ mountOrderDetailsForm: false }, () => {
                this.setState({
                  mountOrderDetailsForm: true,
                  showChangeDateTimeModal: true,
                  showCartModalOrderTimeError: true,
                });
              }); // unmount to refresh the list of order time
            }
          }
        })
        .catch((err) => {
          ReactGA.event("errorPlaceOrder", {
            category: "placeOrder",
            action: "errorPlaceOrder",
            label: "Error Place Order",
            store_name: data.store.name,
            brand_url: brand_url,
            brand_name: data.brand.name,
          });

          this.handleOnError({
            message: JSON.stringify({
              error: err,
              cart: _id,
              source: "can-checkout",
            }),
          });
        });
    } catch (err) {
      ReactGA.event("errorPlaceOrder", {
        category: "placeOrder",
        action: "errorPlaceOrder",
        label: "Error Place Order",
        store_name: data.store.name,
        brand_url: brand_url,
        brand_name: data.brand.name,
      });

      this.handleOnError({
        message: JSON.stringify({
          error: err.toString,
          cart: _id,
          source: "can-checkout",
        }),
      });
    }
  };

  handleOnBlur = (name) => {
    this.setStateKey("touched", name, "true");
    this.validateForm();
  };

  handleOnError = (reqbody) => {
    const { universal_retry_token = "" } = this.state;

    if (universal_retry_token !== "") {
      if (reqbody) this.sendErrorData(reqbody);
      this.setState({ redirectTo: "/failed?token=" + universal_retry_token });
    } else {
      // this is impossible because `universal_retry_token` is assigned upon loading of this page
      console.log("no universal retry token");
    }
  };

  async handleSendToCustomerForCheckout(values, setSubmitting) {
    // console.log("checkout handleSendToCustomerForCheckout")
    const { brand_url = "", data = {} } = this.state,
      store_id = data.store._id;

    let total_amount = computeTotalCartAmount(data.cart_details);

    if (
      data.isMealPlan &&
      data.hasOwnProperty("mealPlanDiscount") &&
      data.mealPlanDiscount > 0
    ) {
      total_amount = computeDiscount(total_amount, data.mealPlanDiscount);
    }

    values.cart = data._id;
    values.cart_details = data.cart_details;
    values.order_details = JSON.stringify(data.order_details);
    values.store_id = store_id;
    values.is_asap = data.order_details.order_time === ASAP_ORDER_TIME_FORMAT;
    values.originalTotalPrice = computeTotalCartAmount(data.cart_details);

    if (data.order_details.order_type === "third_party_pickup") {
      values.pickup_details = JSON.stringify({
        first_name: values.third_p_first_name,
        last_name: values.third_p_last_name,
        contact_number:
          values.third_p_contact_number[0] === "0"
            ? values.third_p_contact_number.substring(
                1,
                values.third_p_contact_number.length,
              )
            : values.third_p_contact_number,
      });
      delete values.third_p_first_name;
      delete values.third_p_last_name;
      delete values.third_p_contact_number;
      values.amount = total_amount;
    } else if (data.order_details.order_type === "delivery") {
      if (values.isCOD) {
        values.amount = total_amount;
      } else {
        values.amount = total_amount + Number(values.delivery_fee);
      }
    } else {
      values.amount = total_amount;
    }
    values.contact_number =
      values.contact_number[0] === "0"
        ? values.contact_number.substring(1, values.contact_number.length)
        : values.contact_number;

    const baseURL = this.api.getBaseURL();

    let backupData = {
      values,
      cart: data._id,
      store: store_id,
    };

    try {
      const backup = await instance.post(
        "/branded-website/carts/save-backup-order/",
        backupData,
        {
          headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
          baseURL,
        },
      );
      setSubmitting(false);

      if (backup.data.result) {
        const result = await MySwal.fire({
          icon: "success",
          title: "Success!",
          text: `A message was sent to the customer to proceed to checkout. Press ok to close this window.`,
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
        });
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ result: "ok" }),
          );
          window.history.back();
        } else if (window !== window.parent) {
          window.parent.postMessage(JSON.stringify({ result: "ok" }));
          window.history.back();
        }
        window.close();
      }
    } catch (err) {
      console.error(err);
    }
  }

  async proceedToPayment(values, setSubmitting) {
    const { brand_url = "", data = {} } = this.state,
      store_id = data.store._id,
      brand_id = data.brand._id;

    const baseURL = this.api.getBaseURL();

    try {
      // 1. Create the attribute for creating a payment method
      const { expiration_date = "", card_details = "", cvc = "" } = values,
        exp = expiration_date.split("/");
      const attributes = {
        type: values.payment_method,
        ...(values.payment_method === "card" && {
          details: {
            card_number: card_details.replace(/ /g, ""), // str
            exp_month: parseInt(exp[0]), // int
            exp_year: parseInt(exp[1]), // int
            cvc: cvc, // str
          },
        }),
      };

      // 2. Remove the credit card details before sending on creating a payment intent
      const payments_payload = pickExcept(values, [
        "card_details",
        "expiration_date",
        "cvc",
      ]);

      // 3. Create Payment Intent
      const payment = await instance.post(
        "/branded-website/payments/",
        payments_payload,
        {
          headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
          baseURL,
        },
      );
      const {
        auth = "",
        payment_id = "",
        backup = "",
        cart = "",
      } = payment.data;

      const paymongo_data = await instance.post(
        "https://api.paymongo.com/v1/payment_methods",
        { data: { attributes } },
        { headers: { Authorization: auth } },
      );
      const pm = paymongo_data.data.data.id;

      const payments_token = await instance.post(
        "/branded-website/payments/attach-payment-method",
        {
          payment_id,
          backup,
          payment_method: window.btoa(pm),
          cart,
          store_id,
          brand_id,
        },
        {
          headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
          baseURL,
        },
      );

      if (payments_token.data.status === "awaiting_next_action") {
        const flow = process.env.REACT_APP_PAYMENT_REDIRECT_FLOW;

        await instance.post(
          "/branded-website/payments/payment-logs",
          {
            message: JSON.stringify({ action: "3DS-awaiting-next-action" }),
            store: store_id,
            brand: brand_id,
          },
          {
            headers: { Authorization: `Basic ${window.btoa(payment_id)}` },
            baseURL,
          },
        );

        if (flow && flow === "REDIRECT") {
          this.setState(
            {
              payment_id,
            },
            () => {
              window.open(
                payments_token.data.next_action.redirect.url,
                "_self",
              );
            },
          );
        } else {
          this.setState({
            renderIframe: payments_token.data.next_action.redirect.url,
            afterIframeRedirect: payments_token.data.redirect_url,
            payment_id,
          });
        }
      } else if (
        payments_token.data.status === "succeeded" ||
        payments_token.data.status === "failed"
      ) {
        // this.mrspeedyPlaceOrder(values, data);
        this.afterCheckout(payments_token.data.redirect_url);
      }
    } catch (err) {
      if (
        err.hasOwnProperty("response") &&
        err.response.hasOwnProperty("data") &&
        err.response.data.hasOwnProperty("errors") &&
        err.response.data.errors.length > 0
      ) {
        const error = err.response.data.errors.shift();
        if (
          error.hasOwnProperty("code") &&
          error.code === "parameter_above_maximum" &&
          error.hasOwnProperty("source") &&
          error.source.hasOwnProperty("attribute") &&
          error.source.attribute === "cvc"
        ) {
          setSubmitting(false);

          MySwal.fire({
            icon: "error",
            title: "Ooops!",
            html: "The value for cvc cannot be more than 3 characters.",
          });
        } else {
          // this error is from the api, so no conversion of toString()
          this.handleOnError({
            message: JSON.stringify({
              error: err,
              device: deviceDetect(),
              cart: data._id,
            }),
          });
        }
      } else {
        this.handleOnError({
          message: JSON.stringify({
            error: err.toString(),
            device: deviceDetect(),
            cart: data._id,
          }),
        });
      }
    }
  }

  async handleOnPlaceOrder(values, setSubmitting) {
    // console.log("checkout handleOnPlaceOrder")
    const { data = {} } = this.state,
      store_id = data.store._id;

    let total_amount = computeTotalCartAmount(data.cart_details);

    if (values.promoDiscount) {
      console.log(
        // "handleOnPlaceOrder values.promoDiscount: ",
        values.promoDiscount,
      );
      // console.log("handleOnPlaceOrder pre total_amount: ", total_amount);
      total_amount = total_amount - Number(values.promoDiscount);
      // console.log("handleOnPlaceOrder post total_amount: ", total_amount);
    }

    let hasMealPlanDiscount =
      data.isMealPlan &&
      data.hasOwnProperty("mealPlanDiscount") &&
      data.mealPlanDiscount > 0;
    if (hasMealPlanDiscount) {
      total_amount = computeDiscount(total_amount, data.mealPlanDiscount);
    }
    values.brand_id = data.brand._id;
    values.hasMealPlanDiscount = hasMealPlanDiscount;
    values.mealPlanDiscount = data.mealPlanDiscount;
    values.cart = data._id;
    values.cart_details = data.cart_details;
    values.order_details = JSON.stringify(data.order_details);
    values.store_id = store_id;
    values.is_asap = data.order_details.order_time === ASAP_ORDER_TIME_FORMAT;
    values.originalTotalPrice = computeTotalCartAmount(data.cart_details);

    if (data.order_details.order_type === "third_party_pickup") {
      values.pickup_details = JSON.stringify({
        first_name: values.third_p_first_name,
        last_name: values.third_p_last_name,
        contact_number:
          values.third_p_contact_number[0] === "0"
            ? values.third_p_contact_number.substring(
                1,
                values.third_p_contact_number.length,
              )
            : values.third_p_contact_number,
      });
      delete values.third_p_first_name;
      delete values.third_p_last_name;
      delete values.third_p_contact_number;
      values.amount = total_amount;
    } else if (data.order_details.order_type === "delivery") {
      if (values.isCOD) {
        values.amount = total_amount;
      } else {
        values.amount = total_amount + Number(values.delivery_fee);
      }
    } else {
      values.amount = total_amount;
    }

    values.partner_identifier = data?.customer?.partner_identifier || "";
    values.contact_number =
      values.contact_number[0] === "0"
        ? values.contact_number.substring(1, values.contact_number.length)
        : values.contact_number;

    //validate promo code if active
    if (values.promo && values.promo.promo_code) {
      const isPromoCodeValid = await this.verifyPromoCode({
        promo_code: values.promo && values.promo.promo_code,
        total_amount,
        order_type: values.orderType,
        storeId: store_id,
        isMealPlan: data.isMealPlan,
        isCOD: values.isCOD,
      });

      if (!isPromoCodeValid) {
        if (this.checkoutFormRef && this.checkoutFormRef.current) {
          this.checkoutFormRef.current.handleInvalidPromoCode();
        }
        setSubmitting(false);
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          html: "Promo code is invalid.",
        });
      } else {
        this.proceedToPayment(values, setSubmitting);
      }
    } else {
      this.proceedToPayment(values, setSubmitting);
    }
  }

  removeItemFromCartAfterCheckout = (id = "") => {
    let { data = {} } = this.state;
    data.cart_details = data.cart_details.filter(({ _id = "" }) => {
      return _id !== id;
    });

    let cartHasBulk = data.cart_details.filter((item) => item.isBulk === true);
    if (cartHasBulk.length > 0) {
      data.hasBulk = true;
    } else {
      data.hasBulk = false;
    }

    this.setState({ data });
    // console.log("item removed from cart 7")
    return data.cart_details;
  };

  // JD - `limitChecker` tailored for Checkout page
  limitChecker = (data = {}, qty = 0, initial_reserved_count = 0) => {
    let {
      current_taken_counter = 0,
      available_count_slot = -1,
      available_count = -1,
    } = data;

    let selected_limit = -1,
      selected_limit_label = "",
      proceed = true;

    if (available_count !== -1 && available_count_slot !== -1) {
      // if both have limits
      selected_limit =
        available_count < available_count_slot
          ? available_count
          : available_count_slot;
      selected_limit_label =
        available_count < available_count_slot ? "day" : "time slot";
    } else if (available_count_slot !== -1 && available_count === -1) {
      // time slot limit only
      selected_limit = available_count_slot;
      selected_limit_label = "time slot";
    } else if (available_count_slot === -1 && available_count !== -1) {
      // daily limits only
      selected_limit = available_count;
      selected_limit_label = "day";
    }

    if (selected_limit !== -1) {
      if (selected_limit > initial_reserved_count) {
        // JD: You can still add items, as the limits computed that there are still available slots for this item
        const new_taken_counter = qty + current_taken_counter;
        if (new_taken_counter > selected_limit) {
          proceed = false;
        }
      } else {
        proceed = false;
      }
    }

    return { proceed, selected_limit, selected_limit_label };
  };

  // JD - `checkStocks` tailored for Checkout page
  checkStocks = (item_id = "", qty = 0) => {
    // console.time("check stocks");

    const {
      current_taken_items_counter = {},
      current_taken_categories_counter = {},
    } = this.state;

    const item = this.getItemFromItemsDirectory(item_id),
      item_limit_checker = this.limitChecker(
        item,
        qty,
        current_taken_items_counter[item_id],
      );
    const category = this.getCategoryFromCategoriesDirectory(item.category),
      category_limit_checker = this.limitChecker(
        category,
        qty,
        current_taken_categories_counter[item.category],
      );

    let proceed = true;

    if (!item_limit_checker.proceed) {
      proceed = false;
      this.setState({ mountLimitsModal: false });
      setTimeout(() => {
        this.setState({
          mountLimitsModal: true,
          showLimitsModal: true,
          limitsModalText: `You can only add ${
            item_limit_checker.selected_limit
          } pcs of this item for this ${
            item_limit_checker.selected_limit_label
          }. ${
            !this.state.data.isInStoreOrder
              ? `Do you want to change your order schedule?`
              : ``
          }`,
        });
      }, 5);
    } else if (!category_limit_checker.proceed) {
      proceed = false;
      this.setState({ mountLimitsModal: false });
      setTimeout(() => {
        this.setState({
          mountLimitsModal: true,
          showLimitsModal: true,
          limitsModalText: `You can only add ${
            category_limit_checker.selected_limit
          } pcs of this category for this ${
            category_limit_checker.selected_limit_label
          }. ${
            !this.state.data.isInStoreOrder
              ? `Do you want to change your order schedule?`
              : ``
          }`,
        });
      }, 50);
    }

    // console.timeEnd("check stocks");
    return proceed;
  };

  // JD - `onClickUpdateCartAfterCheckout` is the event on the increment and decrement counters in the Checkout page
  onClickUpdateCartAfterCheckout = async (e) => {
    // console.log("onClickUpdateCartAfterCheckout");

    let { data = {} } = this.state,
      { cart_details = [] } = data;
    let cartDetails = cart_details;
    let newCartDetails = [];
    let result;
    const action = GetDataFromEvent(e, "data-action"),
      id = GetDataFromEvent(e, "data-id");
    const cartItem = await cartDetails.find((item) => item._id === id),
      selected_item = cartItem.item;

    if (action === "minus" && cartItem.qty <= 1) {
      result = await MySwal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: `You are about to remove this item from your cart.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
      });
    }

    cartDetails = await cartDetails.map(async (cartDetail = {}) => {
      let { _id = "", item = {} } = cartDetail;

      if (id === _id) {
        if (action === "plus" && this.checkStocks(item._id, 1)) {
          cartDetail.qty++;
          this.adjustCurrentAvailableCounters(item._id, 1);
        } else if (action === "minus") {
          if (cartDetail.qty > 1) {
            cartDetail.qty--;
            this.adjustCurrentAvailableCounters(item._id, -1);
          } else {
            if (result.value) {
              newCartDetails = await this.removeItemFromCartAfterCheckout(_id);
              let newHasBulk = newCartDetails.filter(
                (item) => item.isBulk === true,
              );
              if (newHasBulk.length === 0) {
                this.setState({ cartHasBulkChanged: true });
              }
              cartDetail.qty--;
              this.adjustCurrentAvailableCounters(item._id, -1);
              // this.updateCartHasBulkStatus()
            }
          }
        }
      }

      return cartDetail;
    });

    cart_details = cartDetails.filter((item) => item.qty > 0);
    this.setState({ data, selected_item });
    return data.cart_details;
  };

  confirmRemoveItemAtCheckout = async (cartDetail, item, _id) => {
    let newCartDetails = [];
    let response = await MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to remove this item from your cart.`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        newCartDetails = await this.removeItemFromCartAfterCheckout(_id);
        let newHasBulk = newCartDetails.filter((item) => item.isBulk === true);
        if (newHasBulk.length === 0) {
          this.setState({ cartHasBulkChanged: true });
        }
        cartDetail.qty--;
        this.adjustCurrentAvailableCounters(item._id, -1);
      }
    });
    return newCartDetails;
  };

  updateCartHasBulkStatus = () => {
    return this.state.cartHasBulkChanged;
  };

  mountTimer = () => {
    this.setState({ mountTimer: false });
    setTimeout(() => {
      this.setState({ mountTimer: true });
    }, 50);
  };

  handleOnSubmitOrderDetailsFormCheckout = (values, setSubmitting) => {
    this.setState({ loadingActive: true, showChangeDateTimeModal: false });
    this.onClickRenewReservationButton(values, setSubmitting);
  };

  onClickRenewReservationButton = (values, setSubmitting) => {
    setSubmitting(false);
    this.setState({
      showPickupDetailsModal: false,
      showDeliveryDetailsModal: false,
      showCartModalOrderTimeError: false,
      showLimitsModal: false,
    });

    let { data = {} } = this.state,
      { brand = {}, cart_details = [] } = data,
      { brand_url = "" } = brand,
      { order_date = "", order_time = "" } = values,
      baseURL = this.api.getBaseURL();

    this.setState({ renewMyReservationButtonLoading: true });

    instance
      .post(
        "/branded-website/carts/renew",
        {
          data: window.btoa(
            JSON.stringify({
              cart_id: data._id,
              order_date,
              order_time,
              cart_details: formatCartDetails(cart_details),
            }),
          ),
        },
        {
          headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
          baseURL,
        },
      )
      .then((response_data) => {
        const { success = false } = response_data.data;

        if (success) {
          data.order_details.order_date = order_date;
          data.order_details.order_time = order_time;

          this.mountTimer();

          setTimeout(() => {
            this.setState({
              data,
              renewMyReservationButtonLoading: false,
              showReservationExpiredModal: false,
              showChangeDateTimeModal: false,
              changeDateTimeModalText: "",
              loadingActive: false,
            });
          }, 5);
        } else {
          this.setState({ mountOrderDetailsForm: false }, () => {
            this.setState({
              loadingActive: false,
              mountOrderDetailsForm: true,
              showChangeDateTimeModal: true,
              showCartModalOrderTimeError: false,
              changeDateTimeModalText:
                "Ooops! Looks like some of the items you have added is not available for this time slot. Please select another time slot below :",
            });
          }); // unmount to refresh the list of order time
        }
      })
      .catch((error) => {
        const { response = {} } = error,
          { data = {} } = response,
          { message = {} } = data;
        if (
          message.hasOwnProperty("error") &&
          message.error === "OrderScheduleInvalidError"
        ) {
          this.setState({ mountOrderDetailsForm: false }, () => {
            this.setState({
              mountOrderDetailsForm: true,
              showChangeDateTimeModal: true,
              showCartModalOrderTimeError: true,
              renewMyReservationButtonLoading: false,
              showReservationExpiredModal: false,
              hasOrderScheduleInvalidError: true,
              loadingActive: false,
            });
          }); // unmount to refresh the list of order time
        } else {
          this.handleOnError();
        }
      });
  };

  markAsExpired = async () => {
    const { token = "" } = this.props,
      baseURL = this.api.getBaseURL();
    const url =
      baseURL +
      "branded-website/carts/mark-as-expired?data=" +
      window.btoa(JSON.stringify({ token }));
    await instance.post(url);
  };

  onClickCancelOrder = async () => {
    const { external_checkout = false } = this.props;
    await this.markAsExpired();
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("cancelOrder");
      window.history.back();
    } else if (window !== window.parent) {
      window.parent.postMessage("cancelOrder", "*");
      window.history.back();
    } else {
      if (external_checkout) {
        //TODO: custom experience for external checkout order cancellation
        this.setState({
          redirectTo: "/cancelled-order",
        });
      } else {
        this.setState({
          redirectTo: "/cancelled-order",
        });
      }
    }
  };

  renderContent = () => {
    const {
        data = {},
        data_status = "",
        showChangeDateTimeModal = false,
        mountOrderDetailsForm = false,
        showCartModalOrderTimeError = false,
        showReservationExpiredModal = false,
        renewMyReservationButtonLoading = false,
        showCantRenewModal = false,
        mountTimer = true,
        changeDateTimeModalText = "Unfortunately you have missed the cutoff for this time slot. Please select another time slot below :",
        handleChangeDateTime = this.handleOnSubmitOrderDetailsFormCheckout,
        website_theme = {},
        mountLimitsModal = false,
        showLimitsModal = false,
        loadingActive = false,
        displayOrderDateAndTimeOptions = true,
        showOrderDateTimeModalButton = false,
        orderDateTimeModalAction = "Proceed",
      } = this.state,
      { order_details = {} } = data;

    const { highlighted_text_color = "" } = website_theme;
    const logo = document.querySelectorAll(".header-logo");

    logo.forEach((item) =>
      item.addEventListener(
        "click",
        async (event) => {
          await this.markAsExpired();
        },
        false,
      ),
    );
    return (
      <>
        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}
        {mountOrderDetailsForm ? (
          <>
            <ChangeDateTimeModal
              order_type="pickup"
              title="Select Date and Time"
              selected_store={data.store}
              show={showChangeDateTimeModal}
              showDateOptionDropwdown={displayOrderDateAndTimeOptions}
              onHide={() => {
                this.setState({ showChangeDateTimeModal: false });
              }}
              onSubmit={handleChangeDateTime}
              showCartModalOrderTimeError={showCartModalOrderTimeError}
              brand_url={this.state.brand_url}
              {...order_details}
              showErrorAlert={true}
              errorAlertText={changeDateTimeModalText}
              showIfStoreClosed={true}
              showActionButton={showOrderDateTimeModalButton}
              submitText={orderDateTimeModalAction}
            />
          </>
        ) : (
          <></>
        )}

        {mountLimitsModal ? (
          <LimitsModal
            show={showLimitsModal}
            onHide={() => {
              this.setState({ showLimitsModal: false });
            }}
            text={this.state.limitsModalText}
            brand_url={this.state.brand_url}
            selected_store={data.selected_store}
            cart={order_details}
            onSubmit={this.handleOnSubmitOrderDetailsFormCheckout}
            selected_item={this.state.selected_item}
          />
        ) : (
          <></>
        )}
        <AnimateDiv>
          <Modal
            show={showReservationExpiredModal}
            centered
            onHide={() => {
              /** do nothing */
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Your session has expired</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you want to renew your session?</Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={this.onClickCancelOrder}>
                {this.props.external_checkout ? "Back" : "Cancel my order"}
              </Button>
              <Button
                variant="primary"
                className={GetSubmitClassList(
                  renewMyReservationButtonLoading,
                  "success",
                )}
                onClick={() => {
                  this.onClickRenewReservationButton(
                    order_details,
                    (renewMyReservationButtonLoading) => {
                      this.setState({ renewMyReservationButtonLoading });
                    },
                  );
                }}
              >
                {renewMyReservationButtonLoading
                  ? "Processing"
                  : "Renew my session"}
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showCantRenewModal}
            centered
            onHide={() => {
              /** do nothing */
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Sorry! We can't renew your reservation.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              The items in your basket are currently unavailable.
              <p> Do you want to order new ones instead? </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={this.onClickCancelOrder}>
                Cancel my order
              </Button>
              <Button variant="primary">Ok</Button>
            </Modal.Footer>
          </Modal>
          <BWSection className="checkout">
            <BWSection.Header
              style={{
                color: highlighted_text_color,
                borderBottomColor: highlighted_text_color,
              }}
            >
              Checkout
            </BWSection.Header>
            <BWSection.Content>
              <Row>
                <Col>
                  {loadingActive ? (
                    <div className="h-100 w-100 d-flex flex-column align-items-center">
                      <PacmanLoading />
                    </div>
                  ) : (
                    <CheckoutForm
                      ref={this.checkoutFormRef}
                      external_checkout={this.props.external_checkout}
                      token={this.props.token}
                      backupValues={this.backupValues}
                      mountTimer={mountTimer}
                      isFromCheckoutEmail={this.isFromCheckoutEmail}
                      data={data}
                      markAsExpired={this.markAsExpired}
                      updateCartHasBulkStatus={this.updateCartHasBulkStatus}
                      updateCartOrderType={this.updateCartOrderType}
                      onSubmit={this.handleOnSubmit}
                      onClickUpdateCartAfterCheckout={
                        this.onClickUpdateCartAfterCheckout
                      }
                      api={this.api}
                      setShowReservationExpiredModal={(
                        showReservationExpiredModal,
                      ) => {
                        this.setState({
                          showReservationExpiredModal,
                          showChangeDateTimeModal: false,
                        });
                      }}
                      website_theme={website_theme}
                    />
                  )}
                </Col>
              </Row>
            </BWSection.Content>
          </BWSection>
        </AnimateDiv>
      </>
    );
  };
}

class CheckoutForm extends ProSourceForm {
  constructor(props) {
    super(props);
    const { data = {}, backupValues = {} } = this.props;
    // const { backup_order = {} } = data
    let backup_order = {};
    // console.log("checkout checkoutform constructor backupValues: ", this.props.backupValues)
    if (this.props.backupValues) {
      // console.log("true")
      // for checkout emails from in-store orders
      backup_order = backupValues ? backupValues : {};
    } else {
      // console.log("false")
      backup_order = data.backup_order ? data.backup_order : {};
    }

    this.order_details = data.order_details;

    const customer = data.customer || {};
    const {
      token = "",
      first_name = "",
      last_name = "",
      email = "",
      contact_number = "",
      notes = "",
      orderType = "",
      meal_plan_delivery_quotes = [],
      meal_plan_daily_quotes = [],
      delivery_fee = "",
      delivery_fee_lalamove = "",
      delivery_fee_mrspeedy = "",
      delivery_fee_grab = "",
      delivery_quotes = [],
      delivery_payment_method = "",
      delivery_details_lalamove = "",
      delivery_details_mrspeedy = "",
      delivery_details_grab = "",
      delivery_vehicle = "motorcycle",
      allowCarDelivery = true,
      allowMotorcycleDelivery = true,
      isCOD = false,
      mapPosition = { lat: "", lng: "" },
      deliveryPartnerHasActiveCOD = false,
      currentPosition = { lat: "", lng: "" },
      delivery_details = "",
      meal_plan_total_delivery_fee = 0,
      deliveryPartnerHasActiveWallet = false,
      meal_plan_total_quoted_fee = 0,
      delivery_address = "",
      delivery_city = "",
      delivery_distance = "",
      delivery_partner = "",
      markerPosition = {},
      lalamoveScheduleAt = "",
      mrspeedyScheduleAt = "",
      grabScheduleAt = "",
      store_delivery_address = "",
      storePosition = {},
      vehicle_make = "",
      vehicle_color = "",
      vehicle_plate = "",
      table_number = "",
      default_prio_fee = 0,
      pickup_details = customer?.pickup_details || "",
      promoCode = "",
      promoDiscount = 0,
      promoBase = "cart",
      originalTotalPrice = 0,
      delivery_type = "standard",
      grandTotal = 0,
      isMapLoading = false,
    } = backup_order;

    const pickup_details_default = tryParse(pickup_details);

    this.state = {
      values: {
        token,
        first_name: customer.first_name || first_name,
        last_name: customer.last_name || last_name,
        email: customer.email || email,
        contact_number: customer.mobile_number || contact_number,
        partner_identifier: customer.partner_identifier || "",
        address_preset:
          Boolean(customer.address?.formatted) &&
          Boolean(Object.keys(customer.address?.points).length),
        notes,
        orderType,
        region: "",
        province: "",
        city: "",
        barangay: "",
        street: "",
        store_delivery_address,
        storePosition,
        showDeliveryAddressModal: false,
        hasBulk: data.hasBulk,
        referrer: data.referrer,
        detailed_address: "",
        delivery_details,
        delivery_details_lalamove,
        delivery_details_mrspeedy,
        delivery_details_grab,
        delivery_fee,
        delivery_fee_lalamove,
        delivery_fee_mrspeedy,
        delivery_fee_grab,
        delivery_partner,
        deliveryPartnerHasActiveWallet,
        deliveryPartnerHasActiveCOD,
        delivery_payment_method:
          customer.delivery_payment_method || delivery_payment_method,
        delivery_quotes,
        meal_plan_delivery_quotes,
        meal_plan_daily_quotes,
        meal_plan_total_delivery_fee,
        meal_plan_total_quoted_fee,
        delivery_vehicle: customer.delivery_vehicle || delivery_vehicle,
        allowCarDelivery,
        allowMotorcycleDelivery,
        default_prio_fee,
        isCOD:
          customer.delivery_payment_method === "cash" ? true : false || isCOD,
        lalamoveScheduleAt,
        mrspeedyScheduleAt,
        grabScheduleAt,
        vehicle_make,
        vehicle_color,
        vehicle_plate,
        table_number,
        inStoreOrderUser: this.order_details.inStoreOrderUser,
        isThirdPartyPickup:
          this.order_details &&
          this.order_details.order_type === "third_party_pickup",
        payment_method: "",
        card_details: "",
        cvc: "",
        expiration_date: "",
        third_p_first_name: pickup_details_default?.first_name || "",
        third_p_last_name: pickup_details_default?.last_name || "",
        third_p_contact_number: pickup_details_default?.contact_number || "",
        delivery_address: delivery_address || customer.address?.formatted,
        delivery_city,
        delivery_distance,
        mapPosition,
        markerPosition: Object.keys(markerPosition).length
          ? markerPosition
          : customer.address?.points,
        currentPosition,
        zoomValue: 18,
        expiration_mm: "",
        expiration_yy: "",
        promoCode: data.promo_code || promoCode,
        promo: {},
        promoDiscount,
        promoBase,
        isMarketingAllowed: true,
        originalTotalPrice,
        grandTotal,
        delivery_type,
      },
      subTotal: 0,
      hasPromo: false,
      promoTotal: 0,
      grandTotal: 0,
      promoForm: {},
      isPromoFormLoading: false,
      isValidDelivery: false,
      errors: {},
      touched: {},
      isSubmitting: false,
      hasServiceCharge: false,
      additional_charge_fee: 0,
      contactNumberIsValid:
        !isEmpty(customer) &&
        this.validatePhoneNumber(customer.mobile_number || contact_number),
      contactNumberIsInvalid:
        !isEmpty(customer) &&
        !this.validatePhoneNumber(customer.mobile_number || contact_number),
      // isMarketingAllowed: true,
      isPromoCodeValid: true,
    };

    if (!isEmpty(customer)) {
      this.validate = {
        required: ["first_name", "last_name", "email", "contact_number"],
      };
      this.validateEdgeCheckout();
    }
    // console.log(this.state);

    this.handlePromoCodeDisable = this.handlePromoCodeDisable.bind(this);
    this.checkoutPromoFormRef = React.createRef();
  }

  componentDidMount() {
    const {
      data,
      data: {
        selected_store: { additional_charges = [] },
      },
    } = this.props;
    const { values } = this.state;
    const order_type = this.order_details.order_type;
    const table_number = this.order_details.table_number;
    const inStoreOrderUser = this.order_details.inStoreOrderUser;

    values.token = this.props.token;
    values.orderType = order_type;
    values.table_number = table_number;
    values.inStoreOrderUser = inStoreOrderUser;
    const subTotal = computeTotalCartAmount(data.cart_details);

    const additional_charge_fee = computeAdditonalCharge(
      order_type,
      additional_charges,
      subTotal,
      data.isMealPlan,
    );

    if (additional_charge_fee > 0) {
      const active_charge = getServiceCharge(order_type, additional_charges);
      const chargeType = formatAdditionalChargeType(
        active_charge.type,
        active_charge.factor,
      );
      this.setState({ chargeType });
    }

    this.setState({ values });

    this.setState({
      subTotal,
      hasServiceCharge: additional_charge_fee > 0,
      additional_charge_fee,
    });

    const HasErrors = Object.keys(this.state.errors).length > 0;
    if (order_type === "delivery") {
      if (data.isMealPlan) {
        if (this.state.values.meal_plan_total_quoted_fee > 0) {
          this.setState({ delivery_toggle: true });
          this.deleteDeliveryError();
        }

        if (values.address_preset && !HasErrors) {
          this.setAddress();
        }
      } else {
        if (Number(this.state.values.delivery_fee) > 0) {
          this.setState({ delivery_toggle: true });
          this.deleteDeliveryError();
        }

        if (
          Number(this.state.values.delivery_fee) > 0 ||
          (values.address_preset && !HasErrors)
        ) {
          this.setAddress();
        }
      }
    }
    this.validateForm();
  }

  componentDidUpdate() {
    // console.log("checkout checkoutform did update state: ", this.state);
    // console.log("checkout checkoutform did update props: ", this.props);
  }

  validateEdgeCheckout = () => {
    let { errors = {}, values = {} } = this.state,
      state = {};
    const { required } = this.validate;
    (required || []).map((key) => {
      this.setStateKey("touched", key, true);
      if (key === "contact_number") {
        const valid = this.validatePhoneNumber(values[key]);
        if (!valid) {
          errors.contact_number = "Please enter a valid contact number";
          state.contactNumberIsInvalid = true;
          state.contactNumberIsValid = false;
        } else {
          delete errors.contact_number;
          state.contactNumberIsInvalid = false;
          state.contactNumberIsValid = true;
        }
      }
      if (key === "email") {
        const valid = this.validateEmail(values[key]);
        if (!valid) {
          errors.email = "Please enter a valid email";
        } else {
          delete errors.email;
        }
      }
      if (["last_name", "first_name"].includes(key)) {
        const regEx = /[^a-zA-Z\s]/g; // regex allowing spaces in validation
        const validRegex = !regEx.test(String(values[key]));
        if (!validRegex) {
          errors[key] = `Please enter a valid ${key.split("_")[0]} name`;
        } else {
          delete errors[key];
        }
      }
      state.errors = errors;
      this.setState(state);
    });
  };

  handleShowDeliveryAddressModal = () => {
    const { values = {} } = this.state;
    values.showDeliveryAddressModal = true;
    this.setState({ values });
    // console.log('show delivery address modal: ', values.showDeliveryAddressModal)
  };

  handleHideDeliveryAddressModal = () => {
    const { values = {} } = this.state;
    values.showDeliveryAddressModal = false;
    this.setState({ values });
    // console.log('show delivery address modal: ', values.showDeliveryAddressModal)
  };

  clearDeliveryFees = () => {
    const { values = {} } = this.state;
    const {
      data: { cart_details },
      data,
    } = this.props;
    values.delivery_fee_lalamove = 0;
    values.delivery_details_lalamove = "";
    values.delivery_fee_mrspeedy = 0;
    values.delivery_details_mrspeedy = "";
    values.delivery_fee_grab = 0;
    values.delivery_details_grab = "";
    // values.delivery_payment_method = ""
    values.delivery_fee = 0;
    values.delivery_partner = "";
    values.delivery_details = "";
    values.delivery_quotes = [];
    values.meal_plan_total_quoted_fee = 0;
    values.delivery_type = "standard";
    values.meal_plan_delivery_quotes = [];
    values.meal_plan_daily_quotes = [];
    for (var i in cart_details) {
      cart_details[i].quote = "0";
    }
    data.cart_details = cart_details;
    this.setState({ data });
    this.setState({ values });
  };

  clearDeliveryPaymentMethod = () => {
    const { values = {} } = this.state;
    values.delivery_payment_method = "";
    this.setState({ values });
  };

  setMapLoading = (status) => {
    this.setState({ isMapLoading: status });
  };

  computeDistance = () => {
    // console.log("compute distance")

    const {
      values: { markerPosition = "" },
    } = this.state;
    const {
      data: {
        selected_store: { geocode_location = {} },
      },
    } = this.props;
    const storePosition = JSON.parse(geocode_location);
    const kmRadius = 6371.071;
    const mileRadius = 3958.8;
    let diffLat,
      diffLng,
      storeLatRad,
      customerLatRad = 0;
    let R = kmRadius;

    // console.log("checkout computeDistance markerPosition: ", markerPosition)
    // console.log("checkout computeDistance storePosition: ", storePosition)
    storeLatRad = storePosition.lat * (Math.PI / 180);
    customerLatRad = markerPosition.lat * (Math.PI / 180);
    diffLat = customerLatRad - storeLatRad;
    diffLng = (markerPosition.lng - storePosition.lng) * (Math.PI / 180);
    // const distance = Math.sqrt((Math.pow((storeLat - customerLat), 2)) + (Math.pow((storeLng - customerLng), 2)))
    const distance =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
            Math.cos(storeLatRad) *
              Math.cos(customerLatRad) *
              Math.sin(diffLng / 2) *
              Math.sin(diffLng / 2),
        ),
      );
    // console.log("checkout computeDistance distance: ", distance)
    return distance;
  };

  setAddress = () => {
    const { values = {} } = this.state;
    // const { data:{brand:{delivery_method   }} } = this.props
    const {
      data: {
        selected_store: { geocode_address_string = "", geocode_location = {} },
      },
    } = this.props;

    this.deleteDeliveryError();

    if (!values.address_preset) {
      this.clearDeliveryPaymentMethod();
    }
    this.clearDeliveryFees();

    if (!values.markerPosition.lat) {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        html: "Please confirm your delivery address on the map.",
      });
    } else {
      values.store_delivery_address = geocode_address_string;
      values.storePosition = JSON.parse(geocode_location);
      values.delivery_distance = this.computeDistance();
      this.setState({ values: values });

      if (values.delivery_distance) {
        const { isDeliveryDistanceLimitActive, deliveryDistanceLimit } =
          this.props.data.selected_store;
        const deliveryDistanceAllowed = isDeliveryDistanceLimitActive
          ? deliveryDistanceLimit
          : DELIVERY_KM_LIMIT;
        if (values.delivery_distance > deliveryDistanceAllowed) {
          MySwal.fire({
            icon: "error",
            title: "Address out of delivery area",
            html: "The selected address is not within the store's delivery range, update address or select another branch.",
          });
          return;
        }
      }
      if (!values.address_preset) {
        this.autoSelectDeliveryVehicle();
      }
      this.getAllQuotes();
      if (!values.address_preset) {
        this.autoSelectDeliveryPaymentMethod();
      }
      values.showDeliveryAddressModal = false;
      document.activeElement.blur();
      // values.delivery_distance = this.computeDistance()
      // this.setState({ values: values })
      // console.log(this.props,"props")
    }

    // values.store_delivery_address = geocode_address_string
    // values.storePosition = JSON.parse(geocode_location)
    // values.delivery_payment_method = ""
    // values.delivery_partner = ""
    // values.delivery_fee = 0
    // values.showDeliveryAddressModal = false
    // this.setState({ values: values })
    // this.getAllQuotes()
    // this.autoSelectDeliveryPaymentMethod()
  };

  checkActive = async ({
    name,
    active,
    activeCOD,
    single,
    cart_details,
    values,
    isMealPlan,
  }) => {
    const shouldNotSkip =
      (active && !values.isCOD) || (activeCOD && values.isCOD);
    if (!values.address_preset) {
      if (!shouldNotSkip) return "skip";
    }
    const getPartnerQuotation = async (meal_plan_date = "") => {
      if (name === "lalamove") {
        await this.lmoveGetQuotation(single, meal_plan_date);
      } else if (name === "mrspeedy") {
        await this.mrspeedyGetQuotation(single, meal_plan_date);
      } else if (name === "grab") {
        await this.grabGetQuotation(single, meal_plan_date);
      }
    };

    if (isMealPlan) {
      const quotes = [];
      single = false;
      values.delivery_fee = 0;
      values.meal_plan_delivery_quotes = [];
      values.meal_plan_daily_quotes = [];
      this.setState({ values });

      for (var j in cart_details) {
        quotes.push(getPartnerQuotation(cart_details[j].order_date));
      }

      await Promise.all(quotes);
    } else {
      await getPartnerQuotation();
    }
  };

  deleteDeliveryError = () => {
    const { errors = {} } = this.state;
    // console.log('state: ', this.state)
    // console.log('errors before clear: ', errors)
    delete errors.delivery_address_mrspeedy;
    delete errors.delivery_address_lalamove;
    delete errors.delivery_address_grab;
    // this.setState({ errors: {} }, () => {console.log("set address errors after clear: ", errors)})
    this.setState({ errors: {} });
  };

  lmoveGetQuotation = async (single, meal_plan_date) => {
    const { data = {} } = this.props;
    const { values = {}, errors = {} } = this.state,
      {
        data: {
          store: {
            geocode_address_string: store_delivery_address,
            geocode_location: storePosition,
            name: store_name,
            // contact_number: store_contact,
            _id: store_id,
            store_mobile_number: store_contact,
            delivery_method,
            meal_plan_order_time,
            pre_order_to_order_queue_timer,
          },
          brand: { _id: brand_id },
          _id: cart_id,

          isMealPlan,
        },
      } = this.props;
    let {
      values: {
        first_name,
        last_name,
        contact_number,
        delivery_vehicle,
        markerPosition,
        lalamoveScheduleAt,
        notes,
      },
    } = this.state;

    const { market_code, prio_fee_enabled = false } = delivery_method.find(
      (partner) => partner.name === "lalamove",
    );

    lalamoveScheduleAt = this.getScheduleString(
      isMealPlan,
      meal_plan_date,
      meal_plan_order_time,
      pre_order_to_order_queue_timer,
    );

    this.setState({ values });
    // console.log("lmove request quote body lalamoveScheduleAt: ", lalamoveScheduleAt)
    let jsonStorePosition = JSON.parse(storePosition);
    let customer_name = first_name + " " + last_name,
      customer_contact_number = contact_number;
    const body = formatLmoveRequest({
      storePosition: jsonStorePosition,
      store_location: store_delivery_address,
      store_name: store_name,
      store_contact: store_contact,
      markerPosition: markerPosition,
      delivery_address: values.delivery_address,
      customer_name: customer_name,
      customer_contact_number: contact_number,
      delivery_vehicle: delivery_vehicle,
      scheduleAt: lalamoveScheduleAt, //scheduleAtString
      market_code,
    });
    (await this.props.api) &&
      this.props.api
        .post({
          url: `/branded-website/delivery/lm/quotation`,
          data: {
            requestBody: body,
            cart_id: cart_id,
            store_id: store_id,
            brand_id: brand_id,
            delivery_partner: "lalamove",
          },
        })
        .then(({ data }) => {
          if (data.hasOwnProperty("message")) {
            errors.delivery_address_lalamove = formatLmoveError(data);
            this.setState({ errors });
          } else {
            const totalFee = data.priceBreakdown.total;
            const currency = data.priceBreakdown.currency;
            if (lalamoveScheduleAt === "") {
              delete data.scheduleAt;
            }
            const params = {
              data,
              body,
              notes,
              totalFee,
              currency,
              store_name,
              store_contact,
              customer_name,
              customer_contact_number,
            };
            let details = formatLmoveQouteResponse(params);
            values.delivery_details_lalamove = details;
            delete errors.delivery_address;
            values.delivery_fee_lalamove = totalFee;

            if (single) {
              values.delivery_partner = "lalamove";
              values.delivery_fee = totalFee;
              values.delivery_details = details;
            }
            const { default_prio_fee } = data;
            if (!isMealPlan && prio_fee_enabled) {
              values.default_prio_fee = default_prio_fee;
            }

            let lalamove = {};
            lalamove.name = "lalamove";
            lalamove.quote = totalFee;
            lalamove.request_body = details;
            lalamove.delivery_payment_method = "non_cash";

            this.setState({ values, errors });
            this.autoSelectDeliveryPartner(meal_plan_date);

            return lalamove;
          }
        })
        .catch((error) => {
          errors.delivery_address_lalamove =
            "Getting quotation error, please try again";
          this.setState({ errors });
        });
  };
  grabGetQuotation = async (single, meal_plan_date) => {
    const { data = {} } = this.props;
    const { values = {}, errors = {} } = this.state,
      {
        data: {
          store: {
            geocode_address_string: store_delivery_address,
            geocode_location: storePosition,
            name: store_name,
            // contact_number: store_contact,
            store_mobile_number: store_contact,
            _id: store_id,
            pre_order_to_order_queue_timer,
            meal_plan_order_time,
          },
          brand: { _id: brand_id },
          _id: cart_id,
          isMealPlan,
        },
      } = this.props;
    let {
      values: {
        first_name,
        last_name,
        contact_number,
        markerPosition,
        delivery_payment_method,
        isCOD,
        delivery_vehicle,
        delivery_address,
        notes,
        email,
        grabScheduleAt,
      },
    } = this.state;

    grabScheduleAt = this.getScheduleString(
      isMealPlan,
      meal_plan_date,
      meal_plan_order_time,
      pre_order_to_order_queue_timer,
    );
    this.setState({ values });
    let jsonStorePosition = JSON.parse(storePosition);
    const body = formatGrabRequest({
      store_location: store_delivery_address,
      storePosition: jsonStorePosition,
      store_name: store_name,
      store_contact: store_contact,
      markerPosition: markerPosition,
      delivery_address: delivery_address,
      first_name: first_name,
      last_name: last_name,
      customer_contact_number: contact_number,
      customer_email: email,
      delivery_payment_method:
        delivery_payment_method === "cash"
          ? "CASH"
          : delivery_payment_method === "non_cash"
          ? "CASHLESS"
          : "",
      isCOD: isCOD,
      delivery_vehicle: delivery_vehicle,
      remarks: notes,

      scheduleAt: grabScheduleAt, //scheduleAtString
    });

    (await this.props.api) &&
      this.props.api
        .post({
          url: `/branded-website/delivery/grab/quotation`,
          data: {
            requestBody: body,
            store_id: store_id,
            brand_id,
            cart_id,
            delivery_partner: "grab",
          },
        })
        .then(({ data }) => {
          if (data.message || data.devMessage) {
            errors.delivery_address_grab = formatGrabError({
              message: data.devMessage || data.arg || data.message,
            });
            this.setState({ errors });
          } else {
            let details = JSON.stringify({
              ...body,
              checkout_req: body,
            });
            values.delivery_details_grab = details;
            delete errors.delivery_address;
            values.delivery_fee_grab = data.quotes[0].amount;

            if (single) {
              values.delivery_partner = "grab";
              values.delivery_fee = data.quotes[0].amount;
              values.delivery_details = values.delivery_details_grab;
            }

            let grab = {};
            grab.name = "grab";
            grab.quote = data.quotes[0].amount;
            grab.request_body = details;
            grab.delivery_payment_method = delivery_payment_method;
            this.setState({ values, errors });
            this.autoSelectDeliveryPartner(meal_plan_date);
            return grab;
          }
        })
        .catch((error) => {
          const { data = {} } = error.response;
          if (data.result === "error") {
            errors.delivery_address_grab = formatGrabError({
              message: data.message,
            });
            this.setState({ errors });
          }
        });
  };

  onChangeDeliveryPartner = (event) => {
    const partner = event.target.value;
    const { values = {} } = this.state;
    const {
      data: {
        isMealPlan,
        cart_details,
        brand: { _id: brand_id },
        store: { delivery_method },
      },
      data,
    } = this.props;
    if (isMealPlan) {
      const plan_quotes = values.meal_plan_delivery_quotes;
      var totalFee = 0;
      for (var i in cart_details) {
        const datted_quotes = values.meal_plan_daily_quotes.filter(
          (e) => e.meal_plan_date === cart_details[i].order_date,
        );
        const selected_partner = datted_quotes.filter(
          (e) => e.name === partner,
        );
        totalFee += Number(selected_partner[0].quote);
        const dateIndex = plan_quotes.findIndex(
          (e) => e.meal_plan_date === cart_details[i].order_date,
        );
        cart_details[i].quote = selected_partner[0].quote;
        plan_quotes[dateIndex].delivery_fee = selected_partner[0].quote;
        plan_quotes[dateIndex].delivery_partner = selected_partner[0].name;
        plan_quotes[dateIndex].delivery_details =
          selected_partner[0].request_body;
      }
      data.cart_details = cart_details;
      values.delivery_fee = totalFee;
      values.meal_plan_delivery_quotes = plan_quotes;
      values.delivery_partner = partner;
      // console.log(cart_details)
      this.setState({ values });
      this.setState({ data });

      // this.getAllQuotes()
    } else {
      if (partner === "mrspeedy") {
        // this.initMrSpeedy();
        values.delivery_fee = values.delivery_fee_mrspeedy;
        values.delivery_details = values.delivery_details_mrspeedy;
      } else if (partner === "lalamove") {
        values.delivery_fee = values.delivery_fee_lalamove;
        values.delivery_details = values.delivery_details_lalamove;
      } else if (partner === "grab") {
        values.delivery_fee = values.delivery_fee_grab;
        values.delivery_details = values.delivery_details_grab;
      } else {
        values.delivery_fee = "";
        values.delivery_details = "";
      }
      values.delivery_partner = partner;
      this.setState({
        values: values,
      });
      const { prio_fee_enabled = false } = delivery_method.find(
        (partner) => partner.name === "lalamove",
      );
      if (prio_fee_enabled) {
        this.onChangeDeliveryType({ target: { value: "priority" } });
      }
    }
  };
  onChangeDeliveryType = (event) => {
    const delivery_type = event.target.value;
    const {
      values = {},
      brand_url = "",
      data: { _id: cart_id },
      values: { delivery_partner },
    } = this.state;
    if (delivery_partner === "lalamove") {
      values.delivery_type = delivery_type;
      updateCartDeliveryPrioFee(
        cart_id,
        delivery_partner,
        delivery_type,
        brand_url,
        this.props.api.getBaseURL(),
      );
      this.setState({
        values: values,
      });
    }
  };
  formatMealPlanDelivery = (event) => {
    const partner = event.target.value;
    const { values = {} } = this.state;
    if (partner === "mrspeedy") {
      // this.initMrSpeedy();
      values.delivery_fee = values.delivery_fee_mrspeedy;
      values.delivery_details = values.delivery_details_mrspeedy;
    } else if (partner === "lalamove") {
      values.delivery_fee = values.delivery_fee_lalamove;
      values.delivery_details = values.delivery_details_lalamove;
    } else if (partner === "grab") {
      values.delivery_fee = values.delivery_fee_grab;
      values.delivery_details = values.delivery_details_grab;
    } else {
      values.delivery_fee = "";
      values.delivery_details = "";
    }
    values.delivery_partner = partner;
    this.setState({
      values: values,
    });
  };

  initMrSpeedy = () => {
    let { values = {} } = this.state;
    this.setState({
      values: values,
    });
  };

  resetDelivery = () => {
    let { values = {} } = this.state;
    values.delivery_payment_method = "";
    // values.isCOD = false;
    this.setState({
      values: values,
    });
  };

  getQuoteArray = async (meal_plan_date) => {
    const { values = {} } = this.state;
    const {
      data: {
        store: { delivery_method },
        isMealPlan,
        cart_details,
      },
    } = this.props;
    values.delivery_quotes = [];
    // values.meal_plan_daily_quotes=[]
    this.setState({ values });
    let activeDelivery = [];
    if (values.delivery_payment_method === "cash") {
      activeDelivery = delivery_method.filter(
        ({ activeCOD }) => activeCOD === true,
      );
      // console.log("getQuoteArray activeDelivery cash: ", activeDelivery)
    } else if (values.delivery_payment_method === "non_cash") {
      activeDelivery = delivery_method.filter(({ active }) => active === true);
      // console.log("getQuoteArray activeDelivery non_cash: ", activeDelivery)
    }
    // values.delivery_quotes = []
    if (
      values.delivery_fee_lalamove > 0 &&
      activeDelivery.find(({ name }) => name === "lalamove")
    )
      values.delivery_quotes.push({
        name: "lalamove",
        quote: Number(values.delivery_fee_lalamove).toFixed(2),
        request_body: values.delivery_details_lalamove,
        payment_method: values.delivery_payment_method,
        vehicle: values.delivery_vehicle,
      });
    if (
      values.delivery_fee_grab > 0 &&
      activeDelivery.find(({ name }) => name === "grab")
    )
      values.delivery_quotes.push({
        name: "grab",
        quote: Number(values.delivery_fee_grab).toFixed(2),
        request_body: values.delivery_details_grab,
        payment_method: values.delivery_payment_method,
        vehicle: values.delivery_vehicle,
      });
    if (
      values.delivery_fee_mrspeedy > 0 &&
      activeDelivery.find(({ name }) => name === "mrspeedy")
    )
      values.delivery_quotes.push({
        name: "mrspeedy",
        quote: Number(values.delivery_fee_mrspeedy).toFixed(2),
        request_body: values.delivery_details_mrspeedy,
        payment_method: values.delivery_payment_method,
        vehicle: values.delivery_vehicle,
      });
    if (isMealPlan) {
      if (meal_plan_date !== undefined) {
        if (
          values.delivery_fee_lalamove > 0 &&
          activeDelivery.find(({ name }) => name === "lalamove")
        ) {
          const daily_index = values.meal_plan_daily_quotes.findIndex(
            (e) => e.meal_plan_date === meal_plan_date && e.name === "lalamove",
          );
          if (daily_index === -1) {
            values.meal_plan_daily_quotes.push({
              meal_plan_date: meal_plan_date,
              name: "lalamove",
              quote: Number(values.delivery_fee_lalamove).toFixed(2),
              request_body: values.delivery_details_lalamove,
              payment_method: values.delivery_payment_method,
              vehicle: values.delivery_vehicle,
            });
          }
        }
        if (
          values.delivery_fee_grab > 0 &&
          activeDelivery.find(({ name }) => name === "grab")
        ) {
          const daily_index = values.meal_plan_daily_quotes.findIndex(
            (e) => e.meal_plan_date === meal_plan_date && e.name === "grab",
          );
          if (daily_index === -1) {
            values.meal_plan_daily_quotes.push({
              meal_plan_date: meal_plan_date,
              name: "grab",
              quote: Number(values.delivery_fee_grab).toFixed(2),
              request_body: values.delivery_details_grab,
              payment_method: values.delivery_payment_method,
              vehicle: values.delivery_vehicle,
            });
          }
        }
        if (
          values.delivery_fee_mrspeedy > 0 &&
          activeDelivery.find(({ name }) => name === "mrspeedy")
        ) {
          const daily_index = values.meal_plan_daily_quotes.findIndex(
            (e) => e.meal_plan_date === meal_plan_date && e.name === "mrspeedy",
          );
          if (daily_index === -1) {
            values.meal_plan_daily_quotes.push({
              meal_plan_date: meal_plan_date,
              name: "mrspeedy",
              quote: Number(values.delivery_fee_mrspeedy).toFixed(2),
              request_body: values.delivery_details_mrspeedy,
              payment_method: values.delivery_payment_method,
              vehicle: values.delivery_vehicle,
            });
          }
        }
        const index = values.meal_plan_delivery_quotes.findIndex(
          (e) => e.meal_plan_date === meal_plan_date,
        );
        const datted_quotes = values.meal_plan_daily_quotes.filter(
          (e) => e.meal_plan_date === meal_plan_date,
        );
        if (index === -1) {
          values.meal_plan_delivery_quotes.push({
            meal_plan_date,
            delivery_quotes: datted_quotes,
          });
        } else {
          values.meal_plan_delivery_quotes[index] = {
            meal_plan_date,
            delivery_quotes: datted_quotes,
          };
        }
        if (values.meal_plan_delivery_quotes.length >= cart_details.length) {
          this.updateMealPlanQuotes();
        }
      }
    }
    this.setState({ values });
  };

  updateMealPlanQuotes = () => {
    const { values } = this.state;
    const {
      data: {
        store: { delivery_method, free_delivery_amount = 0 },
        isMealPlan,
        cart_details,
      },
      data,
    } = this.props;
    const plan_quotes = values.meal_plan_delivery_quotes;
    var totalFee = 0,
      lalamove = 0,
      mrspeedy = 0,
      grab = 0,
      totalQuotedFee = 0;

    for (var i in cart_details) {
      const datted_quotes = values.meal_plan_daily_quotes.filter(
        (e) => e.meal_plan_date === cart_details[i].order_date,
      );
      for (var k in datted_quotes) {
        const partner = datted_quotes[k];
        if (partner.name === "grab") {
          grab += Number(partner.quote);
        } else if (partner.name === "lalamove") {
          lalamove += Number(partner.quote);
        } else if (partner.name === "mrspeedy") {
          mrspeedy += Number(partner.quote);
        }
      }
      if (
        values.delivery_payment_method !== "cash" ||
        CountActive(delivery_method).countCOD === 1
      ) {
        const dateIndex = plan_quotes.findIndex(
          (e) => e.meal_plan_date === cart_details[i].order_date,
        );
        const selected_partner = datted_quotes.sort(
          (a, b) => Number(b.quote) - Number(a.quote),
        );

        if (Number(selected_partner[0].quote) >= free_delivery_amount) {
          plan_quotes[dateIndex].delivery_fee =
            Number(selected_partner[0].quote) - free_delivery_amount;
        } else {
          plan_quotes[dateIndex].delivery_fee = 0;
        }
        plan_quotes[dateIndex].quoted_fee = selected_partner[0].quote;
        plan_quotes[dateIndex].delivery_partner = selected_partner[0].name;
        plan_quotes[dateIndex].delivery_details =
          selected_partner[0].request_body;

        cart_details[i].quote = selected_partner[0].quote;
        totalFee += Number(plan_quotes[dateIndex].delivery_fee);
        totalQuotedFee += Number(plan_quotes[dateIndex].quoted_fee);
        values.delivery_partner = selected_partner[0].name;
        values.meal_plan_delivery_quotes = plan_quotes;
      }
    }
    data.cart_details = cart_details;
    values.delivery_fee_lalamove = lalamove;
    values.delivery_fee_grab = grab;
    values.delivery_fee_mrspeedy = mrspeedy;
    values.meal_plan_total_quoted_fee = totalQuotedFee;

    // if(totalQuotedFee>=free_delivery_amount*cart_details.length){
    values.delivery_fee = totalFee;
    // }else{
    //     values.delivery_fee = 0
    // }

    // console.log(values,"mquo")
    this.setState({ values });
    this.setState({ data });
  };

  getAllQuotes = async () => {
    const { values = {} } = this.state;
    const {
      data: {
        store: { delivery_method },
        isMealPlan,
        cart_details,
      },
    } = this.props;
    // console.log("get all quotes delivery method: ", delivery_method)
    if (delivery_method.length > 0 && values.delivery_payment_method !== "") {
      if (CountActive(delivery_method).countAll === 1) {
        const activeDelivery = delivery_method.find(
          ({ active, activeCOD }) => active === true || activeCOD === true,
        );
        // console.log("active delivery: ", activeDelivery)
        if (activeDelivery.active) {
          values.deliveryPartnerHasActiveWallet = true;
        } else {
          values.deliveryPartnerHasActiveWallet = false;
        }
        if (activeDelivery.activeCOD) {
          values.deliveryPartnerHasActiveCOD = true;
        } else {
          values.deliveryPartnerHasActiveCOD = false;
        }
        await this.checkActive({
          ...activeDelivery,
          single: true,
          cart_details,
          values,
          isMealPlan,
        });
      } else {
        const deliveryQuotes = delivery_method.map(async (method) => {
          return await this.checkActive({
            ...method,
            single: false,
            cart_details,
            values,
            isMealPlan,
          });
        });

        await Promise.all(deliveryQuotes);
      }
    }
    this.setState({ delivery_toggle: true });
  };

  autoSelectDeliveryPaymentMethod = async () => {
    // const { values } = this.state;
    const {
      data: {
        store: { delivery_method },
        isMealPlan,
      },
    } = this.props;
    let e = { target: {} };
    // console.log("payment method: ", values.delivery_payment_method)
    if (isMealPlan) {
      e.target.value = "non_cash";
      await this.onChangeDeliveryPayment(e);
    } else {
      if (
        CountActive(delivery_method).countCOD === 0 &&
        CountActive(delivery_method).countWallet > 0
      ) {
        // console.log("auto select non_cash")
        e.target.value = "non_cash";
        await this.onChangeDeliveryPayment(e);
        // values.delivery_payment_method = "non_cash"
        // values.isCOD = false
        // this.setState({ values: values })
        // this.autoSelectDeliveryPartner()
      }
      if (
        CountActive(delivery_method).countWallet === 0 &&
        CountActive(delivery_method).countCOD > 0
      ) {
        // console.log("auto select cash")
        e.target.value = "cash";
        await this.onChangeDeliveryPayment(e);
        // values.delivery_payment_method = "cash"
        // values.isCOD = true
        // this.setState({ values: values })
      }
    }
  };

  autoSelectDeliveryPartner = (meal_plan_date) => {
    const { values } = this.state;
    const {
      data: {
        store: { delivery_method },
        isMealPlan,
        cart_details,
      },
      data,
    } = this.props;
    const e = { target: {} };

    this.getQuoteArray(meal_plan_date);
    if (!isMealPlan) {
      if (values.delivery_quotes.length > 0) {
        if (
          values.delivery_payment_method === "non_cash" ||
          (values.delivery_payment_method === "cash" &&
            CountActive(delivery_method).countCOD === 1)
        ) {
          values.delivery_quotes = values.delivery_quotes.sort(
            (a, b) => b.quote - a.quote,
          );

          e.target.value = values.delivery_quotes[0].name;
          this.onChangeDeliveryPartner(e);
          this.deleteDeliveryError();
        }
      }
    }
  };

  onChangeDeliveryPayment = (event) => {
    const {
      data: {
        store: { delivery_method },
      },
    } = this.props;
    const { values = {} } = this.state;
    this.clearDeliveryFees();
    let deliveryPaymentMethod = event.target.value;
    if (deliveryPaymentMethod === this.state.values.delivery_payment_method)
      return; //exit clause when delivery payment method did not change
    if (
      CountActive(delivery_method).countWallet > 1 &&
      deliveryPaymentMethod === "non_cash"
    ) {
      values.delivery_fee = 0;
      values.delivery_partner = "";
    }
    if (
      CountActive(delivery_method).countCOD > 1 &&
      deliveryPaymentMethod === "cash"
    ) {
      values.delivery_fee = 0;
      values.delivery_partner = "";
    }

    if (deliveryPaymentMethod === "cash") {
      values.isCOD = true;
      values.delivery_payment_method = "cash";
    } else if (deliveryPaymentMethod === "non_cash") {
      values.isCOD = false;
      values.delivery_payment_method = "non_cash";
    }
    this.setState({ values: values });
    // get quotes again
    this.getAllQuotes();
    this.autoSelectDeliveryVehicle();
    this.autoSelectDeliveryPartner();
  };

  onChangeDeliveryVehicle = (delivery_vehicle) => {
    const { values = {}, grandTotal = 0 } = this.state;

    if (values.delivery_vehicle !== delivery_vehicle) {
      values.delivery_vehicle = delivery_vehicle;

      this.setState({ values, grandTotal });
      this.clearDeliveryFees();
      this.getAllQuotes();
      this.autoSelectDeliveryPartner();
      this.setState({ values, grandTotal });
    }
  };

  autoSelectDeliveryVehicle = () => {
    const { values = {} } = this.state;
    if (values.hasBulk) {
      values.delivery_vehicle = "car";
      values.allowMotorcycleDelivery = false;
      values.allowCarDelivery = true;
    } else {
      values.delivery_vehicle = "motorcycle";
      values.allowMotorcycleDelivery = true;
      values.allowCarDelivery = true;
    }
    this.setState({ values });
  };

  handleOnMouseDown = (event) => {
    // preventdefault to stop on blur event on preventing onclick resulting to delayed click of someone else button and +/- items
    event.preventDefault();
  };

  handleOrderItemChange = async (e) => {
    e.preventDefault();
    const { values = {} } = this.state;
    let oldHasBulk = this.props.data.hasBulk;
    await this.props.onClickUpdateCartAfterCheckout(e);

    let newHasBulk = await this.props.updateCartHasBulkStatus();
    let hasBulkChanged = false;
    if (oldHasBulk === newHasBulk) hasBulkChanged = true;
    if (hasBulkChanged) {
      values.hasBulk = false;
      this.setState({
        values,
      });
      this.clearDeliveryPaymentMethod();
      this.clearDeliveryFees();
      this.autoSelectDeliveryVehicle();
      this.autoSelectDeliveryPaymentMethod();
      this.getAllQuotes();
      this.autoSelectDeliveryPartner();
      oldHasBulk = newHasBulk;
    }
  };

  getScheduleString(
    isMealPlan,
    meal_plan_date,
    meal_plan_order_time,
    pre_order_to_order_queue_timer,
  ) {
    let scheduleAtString = "";
    if (isMealPlan) {
      scheduleAtString = meal_plan_date + " " + meal_plan_order_time;
    } else {
      const { order_date, order_time } = this.order_details;
      scheduleAtString =
        order_time === ASAP_ORDER_TIME_FORMAT
          ? moment()
              .add(pre_order_to_order_queue_timer, "minutes")
              .format("YYYY-MM-DD HH:mm")
          : order_date + " " + order_time;
    }
    return scheduleAtString;
  }

  validatePhoneNumber(contact_number = "") {
    if (contact_number[0] === "0") {
      var re = /^(0)\d{10}$/;
      return re.test(String(contact_number));
    } else {
      var re = /^(9)\d{9}$/;
      return re.test(String(contact_number));
    }
  }

  onChange = (event) => {
    const target = event.target,
      name = target.name;
    let value = target.value,
      { errors = {}, values = {} } = this.state,
      state = {};
    const { payment_method = "" } = values;

    const delivery_addresses = [
      "region",
      "province",
      "city",
      "barangay",
      "street",
    ];

    if (value !== "") {
      if (name === "third_p_first_name" || name == "third_p_last_name") {
        const regEx = /[^a-zA-Z\s]/g; // allowing spaces in validation
        const validRegex = !regEx.test(String(value));
        if (!validRegex) {
          errors[name] = `Please enter a valid ${name
            .split("third_p_")[1]
            .replace("_", " ")}`;
        } else {
          delete errors[name];
        }
      }

      if (name === "email") {
        const valid = this.validateEmail(value);
        if (!valid) {
          errors.email = "Please enter a valid email";
        } else {
          delete errors.email;
        }

        state.errors = errors;
      } else if (name === "contact_number") {
        const valid = this.validatePhoneNumber(value);
        if (!valid) {
          errors.contact_number = "Please enter a valid contact number";
          state.contactNumberIsInvalid = true;
          state.contactNumberIsValid = false;
        } else {
          delete errors.contact_number;
          state.contactNumberIsInvalid = false;
          state.contactNumberIsValid = true;
        }

        state.errors = errors;
      } else if (name === "third_p_contact_number") {
        value =
          value.length > 0
            ? value[0] === "0"
              ? value.substring(1, value.length)
              : value
            : value;

        const valid = this.validatePhoneNumber(value);
        if (!valid) {
          errors.third_p_contact_number = "Please enter a valid contact number";
          state.third_p_contactNumberIsInvalid = true;
          state.third_p_contactNumberIsValid = false;
        } else {
          delete errors.third_p_contact_number;
          state.third_p_contactNumberIsInvalid = false;
          state.third_p_contactNumberIsValid = true;
        }

        state.errors = errors;
      } else if (name === "card_details") {
        if (payment_method === "card") {
          // values.card_type = Payment.fns.cardType(value);

          const card_type = Payment.fns.cardType(value);
          const acceptedCardTypes = ["visa", "mastercard"];

          if (!Payment.fns.validateCardNumber(value)) {
            errors.card_details = "This card number is not valid.";
          } else if (card_type === "amex") {
            errors.card_details =
              "We do not accept American Express cards at the moment.";
          } else if (!acceptedCardTypes.includes(card_type)) {
            errors.card_details =
              "We only accept Visa and Mastercard cards at the moment.";
          } else {
            delete errors.card_details;
          }

          state.errors = errors;
        }
      } else if (
        name === "expiration_date_mm" ||
        name === "expiration_date_yy"
      ) {
        const { expiration_date_mm = "", expiration_date_yy = "" } = values;
        values.expiration_date = expiration_date_mm + "/" + expiration_date_yy;
        state.values = values;

        if (payment_method === "card") {
          if (!Payment.fns.validateCardExpiry(values.expiration_date)) {
            errors.expiration_date = "This expiration date is not valid.";
          } else {
            delete errors.expiration_date;
          }

          state.errors = errors;
        }
      } else if (name === "cvc") {
        if (payment_method === "card") {
          if (!Payment.fns.validateCardCVC(value, values.card_type)) {
            errors.cvc = "This cvc is not valid.";
          } else {
            delete errors.cvc;
          }

          state.errors = errors;
        }
      } else if (name === "payment_method") {
        if (value !== "card") {
          this.removeErrors([
            "card_details",
            "card_number",
            "cvc",
            "expiration_date",
          ]);
        }
      } else if (delivery_addresses.includes(name)) {
        //reset all lower/ smaller places of selected name
        const position = delivery_addresses.indexOf(name);
        const lowerPlaces = delivery_addresses.slice(position + 1);

        lowerPlaces.forEach((place) => (values[place] = ""));
      } else if (["last_name", "first_name", "vehicle_color"].includes(name)) {
        const regEx = /[^a-zA-Z\s]/g; // allowing spaces in validation
        const validRegex = !regEx.test(String(value));
        if (!validRegex) {
          errors[name] = `Please enter a valid ${name.replace("_", " ")}`;
        } else {
          delete errors[name];
        }
      } else if (name === "vehicle_plate") {
        const regEx = /[^a-zA-Z0-9\s]/g;
        if (regEx.test(String(value))) {
          errors[name] = `Vehicle plate number is not valid.`;
        } else {
          delete errors[name];
        }
      }

      this.setState(state);
    }
  };

  onBlur = (event) => {
    // console.log("onBlur")
    event.preventDefault();
    const target = event.target,
      name = target.name;
    let { errors = {}, values = {} } = this.state,
      { contact_number = "", third_p_contact_number = "" } = values,
      state = {};

    state.values = values;
    this.setState({ values });

    if (name === "contact_number") {
      const valid = this.validatePhoneNumber(contact_number);
      if (!valid) {
        errors.contact_number = "Please enter a valid contact number";
        state.contactNumberIsInvalid = true;
        state.contactNumberIsValid = false;
      } else {
        delete errors.contact_number;
        state.contactNumberIsInvalid = false;
        state.contactNumberIsValid = true;
      }

      // if (errors.hasOwnProperty("contact_number")) {
      //   state.contactNumberIsInvalid = true;
      //   state.contactNumberIsValid = false;
      //   // this.setState({ contactNumberIsInvalid: true, contactNumberIsValid: false, values });
      // } else {
      //   state.contactNumberIsInvalid = false;
      //   state.contactNumberIsValid = true;
      //   // this.setState({ contactNumberIsInvalid: false, contactNumberIsValid: true, values });
      // }
    }
    if (name === "third_p_contact_number") {
      if (errors.hasOwnProperty("third_p_contact_number")) {
        state.third_p_contactNumberIsInvalid = true;
        state.third_p_contactNumberIsValid = false;
        // this.setState({ third_p_contactNumberIsInvalid: true, third_p_contactNumberIsValid: false });
      } else {
        state.third_p_contactNumberIsInvalid = false;
        state.third_p_contactNumberIsValid = true;
        // this.setState({ third_p_contactNumberIsInvalid: false, third_p_contactNumberIsValid: true });
      }
    }

    this.setState(state);
  };

  onBlurDeliveryDetails = (name) => {
    this.setStateKey("touched", name, "true");
    this.validateForm();
  };

  onSubmit = (values, setSubmitting) => {
    values.promo_id = this.state.values.promo._id;
    this.props.onSubmit(values, setSubmitting);
  };

  validateDelivery = () => {
    const {
      values: { first_name, last_name, contact_number, email, errors },
    } = this.state;
    // if (first_name === "" || last_name === "" || contact_number === "" || this.validatePhoneNumber(contact_number) === false  || city ==="" || barangay ===""  || street ==="") {
    // if (first_name === "" || last_name === "" || contact_number === "" || this.validatePhoneNumber(contact_number) === false  || city ==="" || street ==="") {

    const isNameValid = (name) => {
      const regEx = /[^a-zA-Z\s]/g;
      return !regEx.test(String(name)) && name !== "";
    };
    if (
      !isNameValid(first_name) ||
      !isNameValid(last_name) ||
      contact_number === "" ||
      this.validatePhoneNumber(contact_number) === false ||
      this.validateEmail(email) === false
    ) {
      // this.setState({ isValidDelivery: false });

      return false;
    } else {
      // this.setState({ isValidDelivery: true });

      return true;
    }
  };
  validateDeliveryMarker = () => {
    const {
      values: { markerPosition },
    } = this.state;
    if (Object.prototype.hasOwnProperty.call(markerPosition, "lat")) {
      return true;
    } else {
      return false;
    }
  };
  validateForm = () => {
    const errors = this.handleFormValidation();
    this.setState({ errors: errors });
    if (this.order_details.order_type === "delivery") {
      this.validateDelivery();
    }
    return errors;
  };

  /**
   * Sets the promo information
   * @param {*} promo
   */
  setPromoOnCheckout = (promo, isPromoCodeValid) => {
    const { values, subTotal, additional_charge_fee } = this.state;
    const { delivery_fee, isCOD, orderType } = values;

    const grandTotal = computeGrandTotal({
      subTotal,
      includedDeliveryFee: delivery_fee,
      promo,
      isCOD,
      additional_charge_fee,
    });
    values.promo = promo;
    this.setState({
      values,
      grandTotal,
      hasPromo: Boolean(Object.keys(promo).length),
      isPromoCodeValid,
    });
  };

  setPromoFormLoading = (isLoading) => {
    this.setState({
      isPromoFormLoading: isLoading,
    });
  };
  /**
   * Rechecks the promo code to check validity.
   */
  refreshPromo = () => {
    const { promoForm = {} } = this.state;
    // console.log("refresh promo", promoForm);

    if (promoForm.text !== "") this.onChangePromoCode(promoForm.text);
  };

  summaryPromoString = () => {
    const { promo } = this.state.values;
    let promoDescription = "";
    if (promo.promo_type === "amount_off") {
      promoDescription = `(Amount off PHP ${promo.amount_off.toFixed(2)})`;
    } else if (promo.promo_type === "percentage_off") {
      promoDescription = `(${promo.percentage_off}% Off)`;
    } else if (promo.promo_type === "free_delivery") {
      promoDescription = `(Delivery off PHP ${promo.subsidized_delivery_amount.toFixed(
        2,
      )})`;
    }
    return `Promo ${promoDescription}`;
  };

  handleInvalidPromoCode = () => {
    if (this.checkoutPromoFormRef && this.checkoutPromoFormRef.current) {
      this.checkoutPromoFormRef.current.handleInvalidPromoCode();
    }
  };

  render() {
    const {
      data = {},
      mountTimer = true,
      website_theme = {},
      external_checkout = false,
      data: {
        store: { delivery_method = [], store_url = "" },
        selected_store: { isPickupActive = false },
      },
    } = this.props;
    const {
      values: {
        payment_method = "",
        isMarketingAllowed = true,
        orderType = "",
        table_number = "",
      },
      subTotal,
      additional_charge_fee,
    } = this.state;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
        handleUnmount: this.handleUnmount,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };
    // console.log(isMarketingAllowed);
    // console.log("checkout data", data);

    let total_amount = computeTotalCartAmount(data.cart_details),
      hasMealPlanDiscount =
        data.isMealPlan &&
        data.hasOwnProperty("mealPlanDiscount") &&
        data.mealPlanDiscount > 0;

    // if (this.state.values.promoDiscount) {
    //   console.log("render promoDiscount: ", this.state.values.promoDiscount);
    //   total_amount =
    //     computeTotalCartAmount(data.cart_details) -
    //     this.state.values.promoDiscount;
    // }

    if (hasMealPlanDiscount && data.isMealPlan) {
      total_amount = computeDiscount(total_amount, data.mealPlanDiscount);
    }

    // this.validate = {
    //     "required": setRequiredFields(data.order_details.order_type, payment_method)
    // }

    if (
      data.order_details.inStoreOrderUser &&
      !this.props.isFromCheckoutEmail
    ) {
      this.validate = {
        required: setRequiredFields(
          data.order_details.order_type,
          payment_method,
          true,
        ),
      };
    } else {
      this.validate = {
        required: setRequiredFields(
          data.order_details.order_type,
          payment_method,
        ),
      };
    }

    const {
      highlighted_text_color = "",
      button_color = "",
      button_text_color = "",
    } = website_theme;
    const SelectedClass = (type) => {
      // console.log("SelectedClass data: ", data.order_details.order_type);
      // console.log("SelectedClass type: ", type);
      // const { values } = this.state
      // if ((data.order_details.inStoreOrderUser && this.props.isFromCheckoutEmail)) {
      //     if(type==="third_party_pickup" && values.isThirdPartyPickup){
      //         return "btn-primary outline-primary"
      //     }
      // }

      // if (data.order_details.order_type === type) {
      if (data.order_details.order_type === type) {
        return "btn-primary outline-primary";
      } else {
        return "btn-outline-secondary";
      }
    };

    const {
      delivery_fee,
      promo = {},
      isCOD,
      default_prio_fee = 0,
      delivery_type,
    } = this.state.values;

    const grandTotal = computeGrandTotal({
      subTotal: total_amount,
      priority_fee: delivery_type === "standard" ? 0 : default_prio_fee,
      includedDeliveryFee: delivery_fee,
      promo,
      isCOD,
      additional_charge_fee,
    });

    const { amount: promoTotal } = computePromoTotal({
      promo,
      subTotal: total_amount,
      includedDeliveryFee: delivery_fee,
    });

    const deliveryHasError =
      this.state.errors.delivery_address_lalamove ||
      this.state.errors.delivery_address_mrspeedy ||
      this.state.errors.delivery_address_grab;
    const bannerAlertCss = external_checkout
      ? "checkout-sticky-message-external"
      : "checkout-sticky-message";

    const onPickupChange = (type) => {
      const { values, errors } = this.state;
      data.order_details.order_type = type;
      values.isThirdPartyPickup = type === "third_party_pickup";
      values.orderType = type;

      if (!values.isThirdPartyPickup) {
        delete errors.third_p_first_name;
        delete errors.third_p_last_name;
        delete errors.third_p_contact_number;

        values["third_p_first_name"] = "";
        values["third_p_last_name"] = "";
        values["third_p_contact_number"] = "";
      }

      this.setState({ data, values, errors });
    };

    const handleEditAddress = () => {
      // let { values = {}, errors = {} } = this.state
      // values["delivery_address"] = ""
      // this.setState({ values })
      this.deleteDeliveryError();
      this.setState({ errors: {} });
      this.setState({ delivery_toggle: false });
    };

    const changeOrderType = async (orderType) => {
      await MySwal.fire({
        icon: "info",
        title: "Are you sure?",
        text: `You are about to change the order to "Click and Collect"`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Confirm",
        showCancelButton: true,
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.value) {
          await this.props.updateCartOrderType("pickup");
          window.location.reload();
        }
      });
    };

    const formatScheduleString = (orderType) => {
      if (orderType === "delivery") return "Dispatch Schedule";
      if (orderType === "dine_in") return "Dine-in Schedule";
      return "Pickup Schedule";
    };
    const CounterButton = styled.button`
      svg g [fill] {
        fill: ${button_color};
      }
    `;

    const CustomButton = styled.button`
      &.btn-primary {
        color: ${button_text_color};
        background-color: ${button_color};
        border-color: ${button_color};
      }

      &.btn-primary:hover {
        color: ${button_text_color} !important;
        background-color: ${button_color} !important;
        border-color: ${button_color} !important;
      }
    `;

    const buttonStyle = {
      backgroundColor: button_color,
      color: button_text_color,
      borderColor: button_color,
    };

    const CartDetailItem = ({
      _id = "",
      item = {},
      qty = 0,
      price = 0,
      extras = [],
      regular_price = 0,
      isBulk = false,
      order_date = "",
      quote = "",
      isMealPlan = false,
      isCOD = false,
      counterButtons = false,
    }) => (
      <>
        <div className="item d-flex flex-wrap align-items-center mv-1rem">
          <div className="item-photo-wrapper symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4">
            <div
              className="symbol-label"
              style={{ backgroundImage: `url(${item.image_url})` }}
            ></div>
            {
              // discount overlay on order items
              !isMealPlan && item.active_discount > 0 ? (
                item.discount_type === "percentDiscount" ? (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      color: "red",
                      backgroundColor: "rgba(250,250,250,0.5)",
                      fontSize: "0.8em",
                      fontWeight: "bolder",
                    }}
                  >
                    {Number(item.discount_value)}% OFF
                  </div>
                ) : item.discount_type === "rawDiscount" ? (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      color: "red",
                      backgroundColor: "rgba(250,250,250,0.5)",
                      fontSize: "0.8em",
                      fontWeight: "bolder",
                    }}
                  >
                    P {Number(item.active_discount).toFixed(2)} OFF
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )
            }
          </div>
          <div className="item-text-wrapper d-flex flex-column flex-grow-1">
            {order_date && isMealPlan && (
              <div className="item-price text-left">
                <span className="font-weight-bolder">
                  <i className="fa fa-calendar-check font-weight-bold" />
                </span>{" "}
                &nbsp;
                {moment(order_date, DB_DATE_FORMAT).format(MEAL_DATE_FORMAT)}
              </div>
            )}
            <div
              className="font-weight-bolder"
              style={{ color: highlighted_text_color }}
            >
              {item.name}
              {item.isBulk ? (
                <span
                  data-toggle="tooltip"
                  data-placement="top"
                  title="This item is bulky and can only be delivered by cars"
                >
                  &nbsp;
                  <i
                    style={{ color: "lightsalmon", cursor: "help" }}
                    className="fas fa-truck-loading"
                  ></i>
                </span>
              ) : (
                <></>
              )}
            </div>
            {/* <div>{item.description}</div> */}
            {extras.map(({ extra_id = {} }, i) => {
              return (
                <div key={i}>
                  + {extra_id.name}{" "}
                  {data.isMealPlan && <>({toPesoAmount(extra_id.price)})</>}
                </div>
              );
            })}
            {!isMealPlan &&
              (item.active_discount > 0 ? (
                <div className="item-price text-left">
                  <del>PHP&nbsp;{Number(regular_price).toFixed(2)}</del>
                  <span className="ml-3 text-danger font-weight-boldest">
                    PHP&nbsp;{Number(price).toFixed(2)} x {qty}
                  </span>
                </div>
              ) : (
                <div className="item-price text-left">
                  PHP&nbsp;{Number(price).toFixed(2)} x {qty}
                </div>
              ))}

            {/* <div className="font-weight-bolder item-price">{toPesoAmount(price)} </div> */}
          </div>
          {!isMealPlan ? (
            <div className="item-actions d-flex align-items-center">
              {/* <CounterButton variant="light" className="btn btn-icon btn-sm br-0 mr-2" data-id={_id} data-action="minus" onClick={this.props.onClickUpdateCartAfterCheckout}> */}
              {counterButtons && (
                <CounterButton
                  variant="light"
                  className="btn btn-icon btn-sm br-0 mr-2"
                  data-id={_id}
                  data-action="minus"
                  onClick={this.handleOrderItemChange}
                  onMouseDown={this.handleOnMouseDown}
                >
                  <SVGIcon
                    icon={"Navigation/Minus"}
                    title={"Remove"}
                    variant="custom"
                  />
                </CounterButton>
              )}
              {/* <Button
                  variant="outline-primary"
                  className="btn-icon btn-sm br-0 mr-2 inactive-link b-none"
                > */}
              <span className="font-weight-bolder">
                PHP&nbsp;{Number(price * qty).toFixed(2)}
              </span>
              {/* </Button> */}
              {/* <CounterButton variant="light" className="btn btn-icon btn-sm br-0 mr-2" data-id={_id} data-action="plus" onClick={this.props.onClickUpdateCartAfterCheckout}> */}
              {counterButtons && (
                <CounterButton
                  variant="light"
                  className="btn btn-icon btn-sm br-0 mr-2"
                  data-id={_id}
                  data-action="plus"
                  onClick={this.handleOrderItemChange}
                  onMouseDown={this.handleOnMouseDown}
                >
                  <SVGIcon
                    icon={"Navigation/Plus"}
                    title={"Add"}
                    variant="custom"
                  />
                </CounterButton>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="separator separator-solid"></div>
      </>
    );

    return (
      <>
        {data.is_test_payment && (
          <div className={bannerAlertCss}>
            <WarningAlert
              message={
                "🚧 This store is currently in testing mode. You can proceed to checkout however, no actual transactions will take place. 🚧"
              }
            />
          </div>
        )}

        <Form
          onSubmit={
            this.handleOnSubmitDisable(data, total_amount)
              ? this.handleOnSubmitFail
              : this.handleOnSubmit
          }
        >
          <div className="checkout-banner mv-1rem">
            You are ordering from{" "}
            <span className="font-weight-bolder">{data.brand.name}</span> for{" "}
            <span className="text-uppercase font-weight-bolder">
              {formatOrderType(data.order_details.order_type)}
            </span>{" "}
            on{" "}
            <span className="font-weight-bolder">
              {data.isMealPlan ? (
                <>
                  {moment(
                    data.cart_details[0].order_date,
                    DB_DATE_FORMAT,
                  ).format("MMMM D, YYYY")}{" "}
                  to{" "}
                  {moment(
                    data.cart_details[data.cart_details.length - 1].order_date,
                    DB_DATE_FORMAT,
                  ).format("MMMM D, YYYY")}
                </>
              ) : (
                <OrderTimeRender store={data.store} {...data.order_details} />
              )}
            </span>
          </div>
          <Separator solid border={"2"} />
          <Row>
            <Col md={3}>
              {
                // data.order_details.inStoreOrderUser
                data.order_details.inStoreOrderUser &&
                !this.props.isFromCheckoutEmail ? (
                  <>
                    <div className="display-4 font-weight-bolder bg-dark d-flex flex-column justify-content-center align-items-center px-4 mt-7 border rounded text-white">
                      In-Store Order
                    </div>
                  </>
                ) : (
                  <></>
                )
              }

              <StoreInfo
                brand={data.brand}
                store={data.selected_store}
                website_theme={website_theme}
              />

              <Separator solid border={"2"} />

              <Block
                title={formatScheduleString(data.order_details.order_type)}
                content={
                  <>
                    <OrderTimeRender
                      noDate={data.isMealPlan}
                      store={data.store}
                      {...data.order_details}
                    />
                    {data.order_details.order_type === "delivery" ? (
                      <div>{data.order_details.delivery_address}</div>
                    ) : (
                      <></>
                    )}
                  </>
                }
              />

              <Separator solid border={"2"} />

              {
                // check if dine-in
                this.state.values.table_number ? (
                  <>
                    <DineInInfo
                      table_number={this.state.values.table_number}
                      website_theme={website_theme}
                    />
                  </>
                ) : (
                  <></>
                )
              }
            </Col>
            <Col md={9}>
              {mountTimer ? (
                <Block
                  content={
                    <>
                      <Timer
                        initialTime={RESERVATION_LIMIT_MS}
                        direction="backward"
                        timeToUpdate={250}
                        checkpoints={[
                          {
                            time: 0,
                            callback: () => {
                              this.props.setShowReservationExpiredModal(true);
                            },
                          },
                        ]}
                      >
                        {() => (
                          <>
                            <div
                              className="alert alert-custom alert-outline-dark fade show mb-5"
                              role="alert"
                            >
                              <div className="alert-icon">
                                <i className="flaticon-time"></i>
                              </div>
                              <div className="alert-text">
                                You have{" "}
                                <b>
                                  <Timer.Minutes /> minutes and{" "}
                                  <Timer.Seconds /> seconds
                                </b>{" "}
                                to complete the order before your session
                                expires.
                              </div>
                            </div>
                          </>
                        )}
                      </Timer>
                    </>
                  }
                />
              ) : (
                <></>
              )}

              {data.order_details.order_type === "delivery" ? (
                !this.state.delivery_toggle ? (
                  <>
                    <Separator solid border={"2"} />
                    <Block
                      title={<>Customer Information</>}
                      content={
                        <Block
                          content={
                            <>
                              <Row>
                                <Col>
                                  <FormItem
                                    label={"First Name"}
                                    name={"first_name"}
                                    inputProps={{
                                      name: "first_name",
                                      placeholder: "First Name",
                                      value: this.state.values["first_name"],
                                      maxLength: 50,
                                    }}
                                    inputActions={inputActions}
                                    actions={actions}
                                    type={"text"}
                                    showRequired={true}
                                    normal
                                  />
                                </Col>
                                <Col>
                                  <FormItem
                                    label={"Last Name"}
                                    name={"last_name"}
                                    inputProps={{
                                      name: "last_name",
                                      placeholder: "Last Name",
                                      value: this.state.values["last_name"],
                                      maxLength: 50,
                                    }}
                                    inputActions={inputActions}
                                    actions={actions}
                                    type={"text"}
                                    showRequired={true}
                                    normal
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={12} md={6}>
                                  <FormItem
                                    label={"Email"}
                                    name={"email"}
                                    inputProps={{
                                      name: "email",
                                      placeholder: "Email",
                                      value: this.state.values["email"],
                                      maxLength: 50,
                                    }}
                                    inputActions={inputActions}
                                    actions={actions}
                                    type={"text"}
                                    showRequired={true}
                                    normal
                                  />
                                </Col>
                                <Col sm={12} md={6}>
                                  <FormItem
                                    customFormControl
                                    label={"Contact Number"}
                                    name={"contact_number"}
                                    custom={
                                      <>
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              +63
                                            </span>
                                          </div>
                                          <Form.Control
                                            placeholder="Contact Number"
                                            name="contact_number"
                                            value={
                                              this.state.values[
                                                "contact_number"
                                              ]
                                            }
                                            isValid={
                                              this.state.contactNumberIsValid
                                            }
                                            isInvalid={
                                              this.state.contactNumberIsInvalid
                                            }
                                            {...inputActions}
                                          />
                                        </div>
                                      </>
                                    }
                                    actions={actions}
                                    showRequired={true}
                                  />
                                </Col>
                              </Row>
                            </>
                          }
                        />
                      }
                    />
                    <Separator solid border={"2"} />
                    <Block
                      title={<>Additional Information</>}
                      content={
                        <Block
                          content={
                            <FormItem
                              label={"Additional Instructions"}
                              name={"notes"}
                              inputProps={{
                                name: "notes",
                                // placeholder: "Customer remarks of pickup/delivery instructions",
                                placeholder: `Customer remarks for ${formatOrderType(
                                  data.order_details.order_type,
                                )} instructions`,
                                value: this.state.values["notes"],
                                rows: 5,
                                maxLength: 150,
                              }}
                              inputActions={inputActions}
                              actions={actions}
                              type={"textarea"}
                              normal
                            />
                          }
                        />
                      }
                    />
                  </>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              {data.order_details.order_type === "pickup" ||
              data.order_details.order_type === "third_party_pickup" ||
              data.order_details.order_type === "curbside_pickup" ||
              data.order_details.order_type === "dine_in" ? (
                <>
                  <Separator solid border={"2"} />
                  <Block
                    title={<>Customer Information</>}
                    content={
                      <Block
                        content={
                          <>
                            <Row>
                              <Col>
                                <FormItem
                                  label={"First Name"}
                                  name={"first_name"}
                                  inputProps={{
                                    name: "first_name",
                                    placeholder: "First Name",
                                    value: this.state.values["first_name"],
                                    maxLength: 50,
                                  }}
                                  inputActions={inputActions}
                                  actions={actions}
                                  type={"text"}
                                  showRequired={true}
                                  normal
                                />
                              </Col>
                              <Col>
                                <FormItem
                                  label={"Last Name"}
                                  name={"last_name"}
                                  inputProps={{
                                    name: "last_name",
                                    placeholder: "Last Name",
                                    value: this.state.values["last_name"],
                                    maxLength: 50,
                                  }}
                                  inputActions={inputActions}
                                  actions={actions}
                                  type={"text"}
                                  showRequired={true}
                                  normal
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12} md={6}>
                                <FormItem
                                  label={"Email"}
                                  name={"email"}
                                  inputProps={{
                                    name: "email",
                                    placeholder: "Email",
                                    value: this.state.values["email"],
                                    maxLength: 50,
                                  }}
                                  inputActions={inputActions}
                                  actions={actions}
                                  type={"text"}
                                  showRequired={true}
                                  normal
                                />
                              </Col>
                              <Col sm={12} md={6}>
                                <FormItem
                                  customFormControl
                                  label={"Contact Number"}
                                  name={"contact_number"}
                                  custom={
                                    <>
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            +63
                                          </span>
                                        </div>
                                        <Form.Control
                                          placeholder="Contact Number"
                                          name="contact_number"
                                          value={
                                            this.state.values["contact_number"]
                                          }
                                          isValid={
                                            this.state.contactNumberIsValid
                                          }
                                          isInvalid={
                                            this.state.contactNumberIsInvalid
                                          }
                                          {...inputActions}
                                        />
                                      </div>
                                    </>
                                  }
                                  actions={actions}
                                  showRequired={true}
                                />
                              </Col>
                            </Row>
                          </>
                        }
                      />
                    }
                  />
                </>
              ) : (
                <></>
              )}

              {data.order_details.order_type === "curbside_pickup" ? (
                <>
                  <Separator solid border={"2"} />
                  <Block
                    title={<>Vehicle Information</>}
                    content={
                      <Block
                        content={
                          <>
                            <Row>
                              <Col>
                                <FormItem
                                  label={"Vehicle Make"}
                                  name={"vehicle_make"}
                                  inputProps={{
                                    name: "vehicle_make",
                                    placeholder: "Vehicle Type / Brand / Model",
                                    value: this.state.values["vehicle_make"],
                                    maxLength: 25,
                                  }}
                                  inputActions={inputActions}
                                  actions={actions}
                                  type={"text"}
                                  showRequired={true}
                                  normal
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormItem
                                  label={"Vehicle Color"}
                                  name={"vehicle_color"}
                                  inputProps={{
                                    name: "vehicle_color",
                                    placeholder: "Vehicle Color",
                                    value: this.state.values["vehicle_color"],
                                    maxLength: 30,
                                  }}
                                  inputActions={inputActions}
                                  actions={actions}
                                  type={"text"}
                                  showRequired={true}
                                  normal
                                />
                              </Col>
                              <Col>
                                <FormItem
                                  label={"Vehicle Plate Number"}
                                  name={"vehicle_plate"}
                                  inputProps={{
                                    name: "vehicle_plate",
                                    placeholder: "Vehicle Plate Number",
                                    value: this.state.values["vehicle_plate"],
                                    maxLength: 10,
                                  }}
                                  inputActions={inputActions}
                                  actions={actions}
                                  type={"text"}
                                  showRequired={false}
                                  normal
                                />
                              </Col>
                            </Row>
                          </>
                        }
                      />
                    }
                  />
                </>
              ) : (
                <></>
              )}

              {data.order_details.order_type === "pickup" ||
              data.order_details.order_type === "third_party_pickup" ? (
                <>
                  <Separator solid border={"2"} />
                  <Block
                    title={<>this order is for</>}
                    content={
                      <>
                        <Row>
                          <Col>
                            <div className="w-100 py-4">
                              <div
                                className="btn-group btn-group-lg w-100"
                                role="group"
                                aria-label="Large button group"
                              >
                                <CustomButton
                                  type="button"
                                  className={`btn ${
                                    !this.state.values.isThirdPartyPickup
                                      ? "btn-primary outline-primary"
                                      : "btn-outline-secondary"
                                  } font-weight-bolder`}
                                  onClick={() => {
                                    onPickupChange("pickup");
                                  }}
                                  onMouseDown={this.handleOnMouseDown}
                                >
                                  Myself
                                </CustomButton>
                                <CustomButton
                                  type="button"
                                  className={`btn ${
                                    this.state.values.isThirdPartyPickup
                                      ? "btn-primary outline-primary"
                                      : "btn-outline-secondary"
                                  } font-weight-bolder`}
                                  onClick={() => {
                                    onPickupChange("third_party_pickup");
                                  }}
                                  onMouseDown={this.handleOnMouseDown}
                                >
                                  Someone else
                                </CustomButton>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {
                          this.state.values.isThirdPartyPickup ? (
                            <>
                              <Separator solid border={"2"} />
                              <Row className={"mt-5"}>
                                <Col>
                                  <FormItem
                                    label={"First Name"}
                                    name={"third_p_first_name"}
                                    inputProps={{
                                      name: "third_p_first_name",
                                      placeholder: "First Name",
                                      value:
                                        this.state.values["third_p_first_name"],
                                      maxLength: 50,
                                    }}
                                    inputActions={inputActions}
                                    actions={actions}
                                    type={"text"}
                                    showRequired={true}
                                    normal
                                  />
                                </Col>
                                <Col>
                                  <FormItem
                                    label={"Last Name"}
                                    name={"third_p_last_name"}
                                    inputProps={{
                                      name: "third_p_last_name",
                                      placeholder: "Last Name",
                                      value:
                                        this.state.values["third_p_last_name"],
                                      maxLength: 50,
                                    }}
                                    inputActions={inputActions}
                                    actions={actions}
                                    type={"text"}
                                    showRequired={true}
                                    normal
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormItem
                                    customFormControl
                                    label={"Contact Number"}
                                    name={"third_p_contact_number"}
                                    custom={
                                      <>
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              +63
                                            </span>
                                          </div>
                                          <Form.Control
                                            placeholder="Contact Number"
                                            name="third_p_contact_number"
                                            value={
                                              this.state.values[
                                                "third_p_contact_number"
                                              ]
                                            }
                                            isValid={
                                              this.state
                                                .third_p_contactNumberIsValid
                                            }
                                            isInvalid={
                                              this.state
                                                .third_p_contactNumberIsInvalid
                                            }
                                            {...inputActions}
                                          />
                                        </div>
                                      </>
                                    }
                                    actions={actions}
                                    showRequired={true}
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <></>
                          ) //SELF PICKUP
                        }
                      </>
                    }
                  />
                </>
              ) : (
                <></>
              )}

              {data.order_details.order_type !== "delivery" ? (
                <></>
              ) : (
                <>
                  {data.order_details.inStoreOrderUser &&
                  !this.props.isFromCheckoutEmail ? (
                    <></>
                  ) : (
                    <>
                      <Separator solid border={"2"} />
                      <Block
                        title={
                          <div className="d-flex">
                            <span className="flex-grow-1">
                              {data.order_details.order_type} Details
                            </span>
                            {this.state.delivery_toggle ? (
                              <button
                                type="button"
                                className="btn btn-link px-0 py-0 va-text-bottom ml-1"
                                onClick={handleEditAddress}
                              >
                                {"EDIT"}
                              </button>
                            ) : (
                              <></>
                            )}{" "}
                          </div>
                        }
                        content={
                          <>
                            {this.state.delivery_toggle ? (
                              <>
                                <label className="font-weight-bolder text-dark mt-4">
                                  Customer Information
                                </label>
                                <div className="mt-3">{`${this.state.values["first_name"]} ${this.state.values["last_name"]}`}</div>
                                <div className="mt-3">
                                  {this.state.values["email"]}
                                </div>
                                <div className="mt-3">{`+63${this.state.values["contact_number"]}`}</div>
                                <label className="font-weight-bolder text-dark mt-4">
                                  Additional Instructions
                                </label>
                                <div className="mt-3">{`${
                                  this.state.values["notes"] === ""
                                    ? "None"
                                    : this.state.values["notes"]
                                }`}</div>
                                <label className="font-weight-bolder text-dark mt-4">
                                  Delivery Address
                                </label>
                                <div className="mt-3">
                                  {this.state.values["delivery_address"]}
                                </div>
                                <div className="form-group">
                                  <label className="font-weight-bolder text-dark mt-4">
                                    Delivery Payment Options
                                  </label>{" "}
                                  <span className="text-danger">*</span>
                                  <RadioGroup
                                    onChange={this.onChangeDeliveryPayment}
                                  >
                                    {CountActive(delivery_method).countAll ===
                                    1 ? (
                                      <>
                                        {CountActive(delivery_method)
                                          .countWallet > 0 ? (
                                          <FormControlLabel
                                            style={{
                                              marginBottom: "-1rem",
                                            }}
                                            value={"non_cash"}
                                            control={
                                              <DeliveryRadio
                                                inputProps={{
                                                  name: "non_cash",
                                                  disabled: false,
                                                }}
                                                checked={
                                                  !this.state.values
                                                    .deliveryPartnerHasActiveCOD &&
                                                  this.state.values
                                                    .deliveryPartnerHasActiveWallet &&
                                                  CountActive(delivery_method)
                                                    .countAll === 1
                                                    ? true
                                                    : false
                                                }
                                              />
                                            }
                                            label={
                                              "Include Delivery Fee in Checkout Total"
                                            }
                                            labelPlacement={"end"}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                        {CountActive(delivery_method).countCOD >
                                          0 && !data.isMealPlan ? (
                                          <FormControlLabel
                                            // key={"radio 2"}
                                            style={{
                                              marginBottom: "-1rem",
                                            }}
                                            value={"cash"}
                                            control={
                                              <DeliveryRadio
                                                inputProps={{
                                                  name: "cash",
                                                  disabled: false,
                                                }}
                                                checked={
                                                  this.state.values
                                                    .deliveryPartnerHasActiveCOD &&
                                                  !this.state.values
                                                    .deliveryPartnerHasActiveWallet &&
                                                  CountActive(delivery_method)
                                                    .countAll === 1
                                                    ? true
                                                    : false
                                                }
                                              />
                                            }
                                            label={
                                              "Pay Delivery Fee in Cash to Rider"
                                            }
                                            labelPlacement={"end"}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {CountActive(delivery_method)
                                          .countWallet > 0 ? (
                                          <FormControlLabel
                                            // key={"radio 1"}
                                            style={{
                                              marginBottom: "-1rem",
                                            }}
                                            value={"non_cash"}
                                            control={
                                              <DeliveryRadio
                                                inputProps={{
                                                  name: "non_cash",
                                                  disabled: false,
                                                }}
                                                checked={
                                                  (CountActive(delivery_method)
                                                    .countWallet > 0 &&
                                                    CountActive(delivery_method)
                                                      .countCOD === 0) ||
                                                  this.state.values
                                                    .delivery_payment_method ===
                                                    "non_cash"
                                                    ? true
                                                    : false
                                                }
                                              />
                                            }
                                            label={
                                              "Include Delivery Fee in Checkout Total"
                                            }
                                            labelPlacement={"end"}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                        {CountActive(delivery_method).countCOD >
                                          0 && !data.isMealPlan ? (
                                          <FormControlLabel
                                            // key={"radio 2"}
                                            style={{
                                              marginBottom: "-1rem",
                                            }}
                                            value={"cash"}
                                            control={
                                              <DeliveryRadio
                                                inputProps={{
                                                  name: "cash",
                                                  disabled: false,
                                                }}
                                                checked={
                                                  (CountActive(delivery_method)
                                                    .countWallet === 0 &&
                                                    CountActive(delivery_method)
                                                      .countCOD > 0) ||
                                                  this.state.values
                                                    .delivery_payment_method ===
                                                    "cash"
                                                    ? true
                                                    : false
                                                }
                                              />
                                            }
                                            label={
                                              "Pay Delivery Fee in Cash to Rider"
                                            }
                                            labelPlacement={"end"}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </RadioGroup>
                                </div>
                                {this.state.values ? (
                                  <>
                                    <div className="form-group">
                                      <label className="font-weight-bolder text-dark mt-4">
                                        Delivery Vehicle Options
                                      </label>{" "}
                                      <span className="text-danger">*</span>
                                      <div>
                                        <div className="d-flex flex-row">
                                          {this.state.values
                                            .allowCarDelivery && (
                                            <Button
                                              style={{
                                                height: "70px",
                                                width: "100px",
                                                borderColor: "transparent",
                                                backgroundColor:
                                                  this.state.values
                                                    .delivery_vehicle === "car"
                                                    ? ""
                                                    : "lightgray",
                                              }}
                                              className="d-flex flex-column align-items-center justify-content-center btn mx-5 my-5 pa-0"
                                              disabled={
                                                this.state.values[
                                                  data.isMealPlan
                                                    ? "meal_plan_total_quoted_fee"
                                                    : "delivery_fee"
                                                ] === 0
                                                  ? "disabled"
                                                  : ""
                                              }
                                              onClick={() => {
                                                this.onChangeDeliveryVehicle(
                                                  "car",
                                                );
                                              }}
                                            >
                                              <i className="fas fa-car"></i>
                                              <div> Car </div>
                                            </Button>
                                          )}
                                          {this.state.values
                                            .allowMotorcycleDelivery && (
                                            <Button
                                              style={{
                                                height: "70px",
                                                width: "100px",
                                                borderColor: "transparent",
                                                backgroundColor:
                                                  this.state.values
                                                    .delivery_vehicle ===
                                                  "motorcycle"
                                                    ? ""
                                                    : "lightgray",
                                              }}
                                              className="d-flex flex-column align-items-center justify-content-center btn mx-5 my-5 pa-0"
                                              disabled={
                                                this.state.values[
                                                  data.isMealPlan
                                                    ? "meal_plan_total_quoted_fee"
                                                    : "delivery_fee"
                                                ] === 0
                                                  ? "disabled"
                                                  : ""
                                              }
                                              onClick={() => {
                                                this.onChangeDeliveryVehicle(
                                                  "motorcycle",
                                                );
                                              }}
                                            >
                                              <i className="fas fa-motorcycle"></i>

                                              <div> Motorcycle </div>
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                      {this.state.values.hasBulk ? (
                                        <>
                                          <div className="fv-plugins-message-container mt-4">
                                            <div className="fv-help-block mt-4">
                                              You currently have bulk items in
                                              your cart that requires car
                                              delivery
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {data.isMealPlan === false &&
                                  (this.state.values.delivery_payment_method ===
                                  "cash" ? (
                                    <div className="form-group">
                                      <label className="font-weight-bolder text-dark mt-4">
                                        Delivery Partners
                                      </label>{" "}
                                      <span className="text-danger">*</span>
                                      <span>
                                        {" "}
                                        (Delivery fees are subject to change)
                                      </span>
                                      <RadioGroup
                                        onChange={this.onChangeDeliveryPartner}
                                      >
                                        {delivery_method.map((delivery, i) => {
                                          let hasDeliveryFee = false;
                                          const { name, active, activeCOD } =
                                            delivery;
                                          if (name === "lalamove") {
                                            if (
                                              Number(
                                                this.state.values[
                                                  "delivery_fee_lalamove"
                                                ],
                                              ) > 0
                                            )
                                              hasDeliveryFee = true;
                                          } else if (name === "mrspeedy") {
                                            if (
                                              Number(
                                                this.state.values[
                                                  "delivery_fee_mrspeedy"
                                                ],
                                              ) > 0
                                            )
                                              hasDeliveryFee = true;
                                          } else if (name === "grab") {
                                            if (
                                              Number(
                                                this.state.values[
                                                  "delivery_fee_grab"
                                                ],
                                              ) > 0
                                            )
                                              hasDeliveryFee = true;
                                          }
                                          if (active || activeCOD) {
                                            // escape clause: don't show partners that don't have selected delivery payment option
                                            if (!(active && activeCOD)) {
                                              if (
                                                (active &&
                                                  this.state.values
                                                    .delivery_payment_method ===
                                                    "cash") ||
                                                (activeCOD &&
                                                  this.state.values
                                                    .delivery_payment_method ===
                                                    "non_cash")
                                              )
                                                return <></>;
                                            }

                                            if (
                                              CountActive(delivery_method)
                                                .countAll === 1 ||
                                              (this.state.values
                                                .delivery_payment_method ===
                                                "cash" &&
                                                CountActive(delivery_method)
                                                  .countCOD === 1) ||
                                              (this.state.values
                                                .delivery_payment_method ===
                                                "non_cash" &&
                                                CountActive(delivery_method)
                                                  .countWallet === 1)
                                            ) {
                                              //set Delivery Radio to True
                                              let e = { target: {} };
                                              e.target.value = name;
                                              if (data.isMealPlan) {
                                                if (
                                                  name !==
                                                    this.state.values
                                                      .delivery_partner &&
                                                  hasDeliveryFee &&
                                                  this.state.values
                                                    .meal_plan_delivery_quotes
                                                    .length >=
                                                    data.cart_details.length
                                                )
                                                  this.onChangeDeliveryPartner(
                                                    e,
                                                  ); // execute this only once when delivery partner changed
                                              } else {
                                                if (
                                                  name !==
                                                    this.state.values
                                                      .delivery_partner &&
                                                  hasDeliveryFee
                                                )
                                                  this.onChangeDeliveryPartner(
                                                    e,
                                                  );
                                              }

                                              // execute this only once when delivery partner changed
                                              return (
                                                <FormControlLabel
                                                  key={i + "radio"}
                                                  style={{
                                                    marginBottom: "-1rem",
                                                  }}
                                                  value={name}
                                                  control={
                                                    <DeliveryRadio
                                                      inputProps={{
                                                        name: name,
                                                        disabled: hasDeliveryFee
                                                          ? false
                                                          : true,
                                                      }}
                                                      checked={true}
                                                    />
                                                  }
                                                  label={
                                                    name === "lalamove"
                                                      ? hasDeliveryFee
                                                        ? `Lalamove ${CURRENCY} ${Number(
                                                            this.state.values[
                                                              "delivery_fee_lalamove"
                                                            ],
                                                          ).toFixed(2)}`
                                                        : `Lalamove ${
                                                            this.state.errors
                                                              .delivery_address_lalamove
                                                              ? "(Not Available)"
                                                              : "(Loading...)"
                                                          }`
                                                      : name === "mrspeedy"
                                                      ? hasDeliveryFee
                                                        ? `Mr. Speedy ${CURRENCY} ${Number(
                                                            this.state.values[
                                                              "delivery_fee_mrspeedy"
                                                            ],
                                                          ).toFixed(2)}`
                                                        : `Mr. Speedy ${
                                                            this.state.errors
                                                              .delivery_address_mrspeedy
                                                              ? "(Not Available)"
                                                              : "(Loading...)"
                                                          }`
                                                      : name === "grab"
                                                      ? hasDeliveryFee
                                                        ? `Grab ${CURRENCY} ${Number(
                                                            this.state.values[
                                                              "delivery_fee_grab"
                                                            ],
                                                          ).toFixed(2)}`
                                                        : `Grab ${
                                                            this.state.errors
                                                              .delivery_address_grab
                                                              ? "(Not Available)"
                                                              : "(Loading...)"
                                                          }`
                                                      : ""
                                                  }
                                                  labelPlacement={"end"}
                                                />
                                              );
                                            } else {
                                              return (
                                                <FormControlLabel
                                                  key={i + "radio"}
                                                  style={{
                                                    marginBottom: "-1rem",
                                                  }}
                                                  value={name}
                                                  control={
                                                    <DeliveryRadio
                                                      inputProps={{
                                                        name: name,
                                                        disabled: hasDeliveryFee
                                                          ? false
                                                          : true,
                                                      }}
                                                      checked={
                                                        this.state.values
                                                          .delivery_partner ===
                                                        name
                                                      }
                                                    />
                                                  }
                                                  label={
                                                    name === "lalamove"
                                                      ? hasDeliveryFee
                                                        ? `Lalamove ${CURRENCY} ${Number(
                                                            this.state.values[
                                                              "delivery_fee_lalamove"
                                                            ],
                                                          ).toFixed(2)}`
                                                        : `Lalamove ${
                                                            this.state.errors
                                                              .delivery_address_lalamove
                                                              ? "(Not Available)"
                                                              : "(Loading...)"
                                                          }`
                                                      : name === "mrspeedy"
                                                      ? hasDeliveryFee
                                                        ? `Mr. Speedy ${CURRENCY} ${Number(
                                                            this.state.values[
                                                              "delivery_fee_mrspeedy"
                                                            ],
                                                          ).toFixed(2)}`
                                                        : `Mr. Speedy ${
                                                            this.state.errors
                                                              .delivery_address_mrspeedy
                                                              ? "(Not Available)"
                                                              : "(Loading...)"
                                                          }`
                                                      : name === "grab"
                                                      ? hasDeliveryFee
                                                        ? `Grab ${CURRENCY} ${Number(
                                                            this.state.values[
                                                              "delivery_fee_grab"
                                                            ],
                                                          ).toFixed(2)}`
                                                        : `Grab ${
                                                            this.state.errors
                                                              .delivery_address_grab
                                                              ? "(Not Available)"
                                                              : "(Loading...)"
                                                          }`
                                                      : ""
                                                  }
                                                  labelPlacement={"end"}
                                                />
                                              );
                                            }
                                          } else {
                                            return <></>;
                                          }
                                        })}
                                      </RadioGroup>
                                      <div className="fv-plugins-message-container mt-4">
                                        {this.props.data.store.delivery_method.find(
                                          (partner) =>
                                            partner["name"] === "lalamove" &&
                                            partner["activeCOD"],
                                        ) &&
                                        this.state.errors
                                          .delivery_address_lalamove ? (
                                          <div className="fv-help-block">{`Lalamove message: ${this.state.errors.delivery_address_lalamove}`}</div>
                                        ) : (
                                          <></>
                                        )}
                                        {this.props.data.store.delivery_method.find(
                                          (partner) =>
                                            partner["name"] === "mrspeedy" &&
                                            partner["activeCOD"],
                                        ) &&
                                        this.state.errors
                                          .delivery_address_mrspeedy ? (
                                          <div className="fv-help-block">{`Mr. Speedy message: ${this.state.errors.delivery_address_mrspeedy}`}</div>
                                        ) : (
                                          <></>
                                        )}
                                        {this.props.data.store.delivery_method.find(
                                          (partner) =>
                                            partner["name"] === "grab" &&
                                            partner["activeCOD"],
                                        ) &&
                                        this.state.errors
                                          .delivery_address_grab ? (
                                          <div className="fv-help-block">{`Grab message: ${this.state.errors.delivery_address_grab}`}</div>
                                        ) : (
                                          <></>
                                        )}
                                        {deliveryHasError ? (
                                          <div className="fv-help-block mt-4">
                                            Please check your delivery address
                                            and try again.
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  ) : this.state.values
                                      .delivery_payment_method ===
                                    "non_cash" ? (
                                    <div className="form-group">
                                      <label className="font-weight-bolder text-dark mt-4">
                                        Multiple Delivery Providers
                                      </label>{" "}
                                      <span>
                                        (Delivery fees are subject to change)
                                      </span>{" "}
                                      <span className="text-danger">*</span>
                                      {this.state.values.delivery_partner ===
                                      "lalamove" ? (
                                        this.state.values.delivery_type ===
                                        "priority" ? (
                                          <>
                                            <div className="d-flex justify-space-between py-3">
                                              <DFlex className="flex-column align-items-center justify-content-center pr-4">
                                                {this.state.values
                                                  .delivery_vehicle ===
                                                "motorcycle" ? (
                                                  <i className="fas fa-motorcycle"></i>
                                                ) : (
                                                  <i className="fas fa-car"></i>
                                                )}
                                              </DFlex>
                                              <DFlex className="flex-column flex-grow-1">
                                                <span className="font-weight-bolder">
                                                  Delivery Fee
                                                </span>
                                              </DFlex>
                                              <DFlex className="align-items-center pl-2">
                                                <span className="text-uppercase font-weight-bolder">
                                                  {Number(
                                                    this.state.values
                                                      .delivery_fee,
                                                  ) > 0 && this.state.values
                                                    ? ` PHP ${Number(
                                                        this.state.values
                                                          .delivery_fee_lalamove,
                                                      ).toFixed(2)} `
                                                    : deliveryHasError
                                                    ? "(Not Available)"
                                                    : "(Loading...)"}
                                                </span>
                                              </DFlex>
                                            </div>
                                            <div className="d-flex justify-space-between">
                                              <DFlex className="flex-column align-items-center justify-content-center pl-1 pr-6">
                                                <i className="fas fa-bolt text-danger" />
                                              </DFlex>
                                              <DFlex className="flex-column flex-grow-1">
                                                <span className="font-weight-bolder">
                                                  Priority Fee{" "}
                                                </span>
                                                <span className="prio_fee_font_size text-muted">
                                                  This priority fee will assist
                                                  in hailing riders for your
                                                  order.
                                                  {isPickupActive && (
                                                    <>
                                                      If you prefer to opt out
                                                      of the priority fee,
                                                      please change this order
                                                      to
                                                      <span
                                                        className="font-weight-bolder text-primary cursor-pointer"
                                                        onClick={async () => {
                                                          await changeOrderType(
                                                            "pickup",
                                                          );
                                                        }}
                                                      >
                                                        {" "}
                                                        "Click and Collect"
                                                      </span>
                                                      , please note that with
                                                      Click and Collect orders
                                                      you will need to hail your
                                                      own rider and
                                                      self-manage the delivery.
                                                    </>
                                                  )}
                                                </span>
                                              </DFlex>
                                              <DFlex className="align-items-center pl-10">
                                                <span
                                                  className="text-uppercase font-weight-bolder"
                                                  style={{ width: "5.2rem" }}
                                                >
                                                  {Number(
                                                    this.state.values
                                                      .delivery_fee,
                                                  ) > 0 && this.state.values
                                                    ? ` PHP ${Number(
                                                        this.state.values
                                                          .default_prio_fee,
                                                      ).toFixed(2)} `
                                                    : deliveryHasError
                                                    ? "(Not Available)"
                                                    : "(Loading...)"}
                                                </span>
                                              </DFlex>
                                            </div>
                                          </>
                                        ) : (
                                          <div className="mt-3">
                                            Delivery Fee:
                                            {Number(
                                              this.state.values.delivery_fee,
                                            ) > 0 && this.state.values
                                              ? `PHP ${Number(
                                                  this.state.values
                                                    .delivery_fee,
                                                ).toFixed(2)}`
                                              : deliveryHasError
                                              ? "(Not Available)"
                                              : "(Loading...)"}
                                          </div>
                                        )
                                      ) : (
                                        <div className="mt-3">
                                          Delivery Fee:
                                          {Number(
                                            this.state.values.delivery_fee,
                                          ) > 0 && this.state.values
                                            ? `PHP ${Number(
                                                this.state.values.delivery_fee,
                                              ).toFixed(2)}`
                                            : deliveryHasError
                                            ? "(Not Available)"
                                            : "(Loading...)"}
                                        </div>
                                      )}
                                      <div className="fv-plugins-message-container mt-4">
                                        {this.state.values.delivery_quotes
                                          .length === 0 ? (
                                          <>
                                            {/* {this.state.errors.delivery_address_lalamove ? <div className="fv-help-block">{`Lalamove message: ${this.state.errors.delivery_address_lalamove}`}</div> : <></>} */}
                                            {this.props.data.store.delivery_method.find(
                                              (partner) =>
                                                partner["name"] ===
                                                  "lalamove" &&
                                                partner["active"],
                                            ) &&
                                            this.state.errors
                                              .delivery_address_lalamove ? (
                                              <div className="fv-help-block">{`Lalamove message: ${this.state.errors.delivery_address_lalamove}`}</div>
                                            ) : (
                                              <></>
                                            )}
                                            {this.props.data.store.delivery_method.find(
                                              (partner) =>
                                                partner["name"] ===
                                                  "mrspeedy" &&
                                                partner["active"],
                                            ) &&
                                            this.state.errors
                                              .delivery_address_mrspeedy ? (
                                              <div className="fv-help-block">{`Mr. Speedy message: ${this.state.errors.delivery_address_mrspeedy}`}</div>
                                            ) : (
                                              <></>
                                            )}
                                            {this.props.data.store.delivery_method.find(
                                              (partner) =>
                                                partner["name"] === "grab" &&
                                                partner["active"],
                                            ) &&
                                            this.state.errors
                                              .delivery_address_grab ? (
                                              <div className="fv-help-block">{`Grab message: ${this.state.errors.delivery_address_grab}`}</div>
                                            ) : (
                                              <></>
                                            )}
                                            {deliveryHasError ? (
                                              <div className="fv-help-block mt-4">
                                                Please check your delivery
                                                address and try again.
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  ))}
                              </>
                            ) : (
                              <>
                                <Row className="py-3 ">
                                  <Col>
                                    <label className="font-weight-bolder text-dark mt-3">
                                      Set your delivery address
                                    </label>{" "}
                                    <span className="text-danger">*</span>
                                    <Button
                                      style={buttonStyle}
                                      variant="primary"
                                      className={GetSubmitClassList(
                                        this.state.isSubmitting,
                                        "btn-block btn-lg font-weight-bolder",
                                      )}
                                      onClick={
                                        this.handleShowDeliveryAddressModal
                                      }
                                      disabled={!this.validateDelivery()}
                                    >
                                      Set Delivery Address
                                    </Button>
                                    {this.state.values
                                      .showDeliveryAddressModal ? (
                                      <DeliveryAddressModal
                                        show={
                                          this.state.values
                                            .showDeliveryAddressModal
                                        }
                                        state={this.state}
                                        validateDelivery={this.validateDelivery()}
                                        height="400px"
                                        zoom={this.state.values["zoomValue"]}
                                        data={data}
                                        api={this.props.api}
                                        mapComponent={
                                          <MapComponent
                                            state={this.state}
                                            validateDelivery={this.validateDelivery()}
                                            height="400px"
                                            zoom={
                                              this.state.values["zoomValue"]
                                            }
                                            data={data}
                                            api={this.props.api}
                                            source={"checkout"}
                                            tooltipDisplay={true}
                                            setMapLoading={this.setMapLoading}
                                          />
                                        }
                                        setAddressButton={
                                          <Button
                                            style={{
                                              ...buttonStyle,
                                              width: "250px",
                                            }}
                                            // className={"align-self-end"}
                                            variant="primary"
                                            className={GetSubmitClassList(
                                              this.state.isSubmitting ||
                                                this.state.isMapLoading,
                                              "btn-block btn-lg font-weight-bolder align-self-end",
                                            )}
                                            onClick={this.setAddress}
                                            disabled={
                                              !this.validateDelivery() ||
                                              this.state.isMapLoading
                                            }
                                          >
                                            Confirm Location
                                          </Button>
                                        }
                                        onHide={
                                          this.handleHideDeliveryAddressModal
                                        }
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                </Row>
                              </>
                            )}
                          </>
                        }
                      />
                    </>
                  )}
                </>
              )}
              {data.order_details.order_type !== "delivery" ? (
                <>
                  <Separator solid border={"2"} />
                  <Block
                    title={<>Additional Information</>}
                    content={
                      <Block
                        content={
                          <FormItem
                            label={"Additional Instructions"}
                            name={"notes"}
                            inputProps={{
                              name: "notes",
                              placeholder: `Customer remarks for ${formatOrderType(
                                data.order_details.order_type,
                              )} instructions`,
                              value: this.state.values["notes"],
                              rows: 5,
                              maxLength: 150,
                            }}
                            inputActions={inputActions}
                            actions={actions}
                            type={"textarea"}
                            normal
                          />
                        }
                      />
                    }
                  />
                </>
              ) : (
                <></>
              )}

              {this.state.values.orderType &&
              (!data.order_details.inStoreOrderUser ||
                (data.order_details.inStoreOrderUser &&
                  this.props.isFromCheckoutEmail)) ? (
                <>
                  <Separator solid border={"2"} />

                  <Block
                    title={<>Promo code</>}
                    content={
                      <Block
                        content={
                          <>
                            <CheckoutPromoForm
                              ref={this.checkoutPromoFormRef}
                              total_amount={total_amount}
                              setPromoOnCheckout={this.setPromoOnCheckout}
                              setPromoFormLoading={this.setPromoFormLoading}
                              order_type={this.state.values.orderType}
                              promoCode={this.state.values.promoCode}
                              storeId={this.props.data.selected_store._id}
                              isMealPlan={this.props.data.isMealPlan}
                              isCOD={this.state.values.isCOD}
                              isDisabled={this.handlePromoCodeDisable()}
                              successText={
                                this.props.data.order_details.order_type ===
                                  "delivery" &&
                                (this.state.values[
                                  this.props.data.isMealPlan
                                    ? "meal_plan_total_quoted_fee"
                                    : "delivery_fee"
                                ] === 0 ||
                                  !this.state.values
                                    ?.delivery_payment_method) ? (
                                  <div className="text-info">
                                    Promo to be applied after delivery
                                    quotation.
                                  </div>
                                ) : null
                              }
                            />
                          </>
                        }
                      />
                    }
                  />
                </>
              ) : (
                <></>
              )}
              <Separator solid border={"2"} />
              <Block
                title={
                  <div className="d-flex">
                    <span className="flex-grow-1">Order Summary </span>
                    {!external_checkout && (
                      <Link
                        className="btn btn-link px-0 py-0 va-text-bottom ml-1"
                        onClick={() => {
                          this.props.markAsExpired();
                        }}
                        to={
                          store_url !== "" && table_number === ""
                            ? `/${store_url}/menu?token=${this.props.token}`
                            : table_number !== ""
                            ? `/${store_url}/table/${table_number}`
                            : `/menu?token=${this.props.token}`
                        }
                      >
                        {" "}
                        Edit Items
                      </Link>
                    )}{" "}
                  </div>
                }
                content={
                  <>
                    <Row>
                      <Col>
                        {data.cart_details.length > 0 ? (
                          <>
                            {data.cart_details.map((item, index) => {
                              return (
                                <CartDetailItem
                                  key={index}
                                  {...item}
                                  isMealPlan={data.isMealPlan}
                                  isCOD={this.state.values["isCOD"]}
                                />
                              );
                            })}
                          </>
                        ) : (
                          <div className="mv-1rem">
                            <p>
                              No items added to your cart.
                              <Link
                                to={
                                  store_url !== ""
                                    ? `/${store_url}/menu`
                                    : "/menu"
                                }
                              >
                                Go to menu?
                              </Link>{" "}
                            </p>
                          </div>
                        )}
                        {this.state.hasPromo &&
                          this.state.values?.promo?.promo_type ===
                            "free_item" && (
                            <div className="item-text-wrapper d-flex flex-column flex-grow-1 pr-2 pt-2">
                              <div className="item-name d-flex">
                                {" "}
                                <span>
                                  <i
                                    style={{
                                      color: highlighted_text_color,
                                    }}
                                    className="fas fa-gift"
                                  ></i>
                                </span>
                                <span className="font-weight-bold ml-2">
                                  {" "}
                                  {
                                    this.state.values?.promo
                                      ?.free_item_description
                                  }
                                </span>
                                <div></div>
                              </div>
                            </div>
                          )}
                        <div className="d-flex flex-wrap align-items-center mv-1rem">
                          <DFlex className="flex-column flex-grow-1">
                            <span className="text-uppercase font-weight-bolder">
                              total amount{" "}
                              {hasMealPlanDiscount && (
                                <span className="text-danger font-weight-bold ml-2">
                                  ({data.mealPlanDiscount}% OFF)
                                </span>
                              )}{" "}
                            </span>
                          </DFlex>
                          <DFlex className="align-items-center">
                            {hasMealPlanDiscount ? (
                              <div>
                                <del className="font-weight-bolder">
                                  {CURRENCY}{" "}
                                  {Number(
                                    computeTotalCartAmount(data.cart_details),
                                  ).toFixed(2)}
                                </del>
                                <span className="text-uppercase text-danger font-weight-bolder ml-2">
                                  {CURRENCY} {Number(total_amount).toFixed(2)}
                                </span>
                              </div>
                            ) : (
                              <span className="text-uppercase font-weight-bolder">
                                {CURRENCY} {Number(total_amount).toFixed(2)}
                              </span>
                            )}
                          </DFlex>
                        </div>

                        {this.nonDeliveryWithPromosOrCharge() && (
                          <>
                            {this.state.hasPromo && (
                              <div className="d-flex flex-wrap align-items-center mv-1rem">
                                <DFlex className="flex-column flex-grow-1">
                                  <span className="text-uppercase font-weight-bolder text-danger">
                                    {this.summaryPromoString()}
                                  </span>
                                </DFlex>
                                <DFlex className="align-items-center">
                                  <span className="text-uppercase font-weight-bolder text-danger">
                                    -{CURRENCY}&nbsp;
                                    {Number(promoTotal).toFixed(2)}
                                  </span>
                                </DFlex>
                              </div>
                            )}
                            {this.state.hasServiceCharge && (
                              <div className="d-flex flex-wrap align-items-center mv-1rem">
                                <DFlex className="flex-column flex-grow-1">
                                  <span className="text-uppercase font-weight-bolder">
                                    Service Charge {this.state.chargeType}{" "}
                                    <RequiredAsterisk />
                                  </span>
                                </DFlex>
                                <DFlex className="align-items-center">
                                  <span className="text-uppercase font-weight-bolder">
                                    {CURRENCY}&nbsp;
                                    {Number(additional_charge_fee).toFixed(2)}
                                  </span>
                                </DFlex>
                              </div>
                            )}

                            <div className="d-flex flex-wrap align-items-center mv-1rem">
                              <DFlex className="flex-column flex-grow-1">
                                <span className="text-uppercase font-weight-bolder">
                                  Grand total{" "}
                                </span>
                              </DFlex>
                              <DFlex className="align-items-center">
                                <span className="text-uppercase font-weight-bolder">
                                  {CURRENCY}&nbsp;
                                  {Number(grandTotal).toFixed(2)}
                                </span>
                              </DFlex>
                            </div>
                          </>
                        )}
                        {this.state.delivery_toggle ? (
                          <>
                            {this.state.values.delivery_payment_method ===
                            "cash" ? (
                              <>
                                {this.state.hasServiceCharge && (
                                  <div className="d-flex flex-wrap align-items-center mv-1rem">
                                    <DFlex className="flex-column flex-grow-1">
                                      <span className="text-uppercase font-weight-bolder">
                                        Service Charge {this.state.chargeType}{" "}
                                        <RequiredAsterisk />
                                      </span>
                                    </DFlex>
                                    <DFlex className="align-items-center">
                                      <span className="text-uppercase font-weight-bolder">
                                        {CURRENCY}&nbsp;
                                        {Number(additional_charge_fee).toFixed(
                                          2,
                                        )}
                                      </span>
                                    </DFlex>
                                  </div>
                                )}
                                {this.state.hasPromo &&
                                  this.state.values?.promo?.promo_type !==
                                    "free_item" && (
                                    <>
                                      <div className="d-flex flex-wrap align-items-center mv-1rem">
                                        <DFlex className="flex-column flex-grow-1">
                                          <span className="text-uppercase font-weight-bolder text-danger">
                                            {this.summaryPromoString()}
                                          </span>
                                        </DFlex>
                                        <DFlex className="align-items-center">
                                          <span className="text-uppercase font-weight-bolder text-danger">
                                            -{CURRENCY}&nbsp;
                                            {Number(promoTotal).toFixed(2)}
                                          </span>
                                        </DFlex>
                                      </div>
                                    </>
                                  )}
                                {grandTotal !== total_amount && (
                                  <div className="d-flex flex-wrap align-items-center mv-1rem">
                                    <DFlex className="flex-column flex-grow-1">
                                      <span className="text-uppercase font-weight-bolder">
                                        Grand total{" "}
                                      </span>
                                    </DFlex>
                                    <DFlex className="align-items-center">
                                      <span className="text-uppercase font-weight-bolder">
                                        {CURRENCY}&nbsp;
                                        {Number(grandTotal).toFixed(2)}
                                      </span>
                                    </DFlex>
                                  </div>
                                )}

                                <Separator solid border={"2"} />
                                <div className="d-flex flex-wrap align-items-center mv-1rem">
                                  <DFlex className="flex-column flex-grow-1">
                                    <span className="text-uppercase font-weight-bolder text-danger">
                                      ⚠️Delivery Fee - Pay in cash to rider
                                    </span>
                                  </DFlex>
                                  <DFlex className="align-items-center">
                                    <span className="text-uppercase font-weight-bolder text-danger">
                                      {this.state.values["delivery_fee"] === 0
                                        ? "Loading..."
                                        : `${CURRENCY} ${Number(
                                            this.state.values["delivery_fee"],
                                          ).toFixed(2)}`}
                                    </span>
                                  </DFlex>
                                </div>
                              </>
                            ) : (
                              this.state.values.delivery_payment_method ===
                                "non_cash" && (
                                <>
                                  <div className="d-flex flex-wrap align-items-center mv-1rem">
                                    <DFlex className="flex-column flex-grow-1">
                                      <span className="text-uppercase font-weight-bolder">
                                        Delivery Fee{" "}
                                      </span>
                                    </DFlex>
                                    <DFlex className="align-items-center">
                                      {data.isMealPlan ? (
                                        <span
                                          className={
                                            Number(
                                              data.store.free_delivery_amount,
                                            ) > 0 &&
                                            this.state.values[
                                              "meal_plan_total_quoted_fee"
                                            ] >=
                                              Number(
                                                data.store.free_delivery_amount,
                                              ) *
                                                data.cart_details.length
                                              ? "text-uppercase font-weight-bolder text-danger"
                                              : "text-uppercase font-weight-bolder"
                                          }
                                        >
                                          {this.state.values[
                                            "meal_plan_total_quoted_fee"
                                          ] >=
                                          Number(
                                            data.store.free_delivery_amount,
                                          ) *
                                            data.cart_details.length
                                            ? `${
                                                this.state.values[
                                                  "delivery_fee"
                                                ] === 0
                                                  ? "Loading..."
                                                  : `${
                                                      Number(
                                                        data.store
                                                          .free_delivery_amount,
                                                      ) !== 0
                                                        ? "Discounted Long Distance"
                                                        : ""
                                                    } ${CURRENCY} ${Number(
                                                      this.state.values[
                                                        "delivery_fee"
                                                      ],
                                                    ).toFixed(2)}`
                                              }`
                                            : this.state.values[
                                                "meal_plan_total_quoted_fee"
                                              ] === 0
                                            ? "Loading..."
                                            : `Free Delivery`}
                                        </span>
                                      ) : (
                                        <span className="text-uppercase font-weight-bolder">
                                          {this.state.values["delivery_fee"] ===
                                          0
                                            ? "Loading..."
                                            : `${CURRENCY} ${Number(
                                                this.state.values[
                                                  "delivery_fee"
                                                ],
                                              ).toFixed(2)}`}
                                        </span>
                                      )}
                                    </DFlex>
                                  </div>
                                  {this.state.values.delivery_type ===
                                    "priority" && (
                                    <div className="d-flex flex-wrap align-items-center mv-1rem">
                                      <DFlex className="flex-column flex-grow-1">
                                        <span className="text-uppercase font-weight-bolder">
                                          Priority Fee{" "}
                                        </span>
                                      </DFlex>
                                      <DFlex className="align-items-center">
                                        <span className="text-uppercase font-weight-bolder">
                                          {this.state.values["delivery_fee"] ===
                                          0
                                            ? "Loading..."
                                            : `${CURRENCY} ${Number(
                                                this.state.values
                                                  .default_prio_fee,
                                              ).toFixed(2)}`}
                                        </span>
                                      </DFlex>
                                    </div>
                                  )}

                                  {this.state.hasPromo &&
                                  this.state.values?.promo?.promo_type !==
                                    "free_item" ? (
                                    <>
                                      <div className="d-flex flex-wrap align-items-center mv-1rem">
                                        <DFlex className="flex-column flex-grow-1">
                                          <span className="text-uppercase font-weight-bolder text-danger">
                                            {/* Promo Discount{" "} */}
                                            {this.summaryPromoString()}
                                          </span>
                                        </DFlex>
                                        <DFlex className="align-items-center">
                                          <span className="text-uppercase font-weight-bolder text-danger">
                                            -{CURRENCY}&nbsp;
                                            {Number(promoTotal).toFixed(2)}
                                          </span>
                                        </DFlex>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {data.isMealPlan ? (
                                    <>
                                      <div className="d-flex flex-wrap align-items-center mv-1rem">
                                        <DFlex className="flex-column flex-grow-1">
                                          <span className="text-uppercase font-weight-bolder">
                                            Grand total{" "}
                                          </span>
                                        </DFlex>
                                        <DFlex className="align-items-center">
                                          <span className="text-uppercase font-weight-bolder">
                                            {CURRENCY}&nbsp;
                                            {Number(grandTotal).toFixed(2)}
                                          </span>
                                        </DFlex>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {this.state.hasServiceCharge && (
                                        <div className="d-flex flex-wrap align-items-center mv-1rem">
                                          <DFlex className="flex-column flex-grow-1">
                                            <span className="text-uppercase font-weight-bolder">
                                              Service Charge{" "}
                                              {this.state.chargeType}{" "}
                                              <RequiredAsterisk />
                                            </span>
                                          </DFlex>
                                          <DFlex className="align-items-center">
                                            <span className="text-uppercase font-weight-bolder">
                                              {CURRENCY}&nbsp;
                                              {Number(
                                                additional_charge_fee,
                                              ).toFixed(2)}
                                            </span>
                                          </DFlex>
                                        </div>
                                      )}
                                      <div className="d-flex flex-wrap align-items-center mv-1rem">
                                        <DFlex className="flex-column flex-grow-1">
                                          <span className="text-uppercase font-weight-bolder">
                                            Grand total{" "}
                                          </span>
                                        </DFlex>
                                        <DFlex className="align-items-center">
                                          <span className="text-uppercase font-weight-bolder">
                                            {CURRENCY}&nbsp;
                                            {Number(grandTotal).toFixed(2)}
                                          </span>
                                        </DFlex>
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            )}
                          </>
                        ) : (
                          <>
                            {orderType === "delivery" && (
                              <>
                                {this.state.hasServiceCharge && (
                                  <div className="d-flex flex-wrap align-items-center mv-1rem">
                                    <DFlex className="flex-column flex-grow-1">
                                      <span className="text-uppercase font-weight-bolder">
                                        Service Charge {this.state.chargeType}{" "}
                                        <RequiredAsterisk />
                                      </span>
                                    </DFlex>
                                    <DFlex className="align-items-center">
                                      <span className="text-uppercase font-weight-bolder">
                                        {CURRENCY}&nbsp;
                                        {Number(additional_charge_fee).toFixed(
                                          2,
                                        )}
                                      </span>
                                    </DFlex>
                                  </div>
                                )}
                                <div className="d-flex flex-wrap align-items-center mv-1rem">
                                  <DFlex className="flex-column flex-grow-1">
                                    <span className="text-uppercase font-weight-bolder">
                                      Grand total{" "}
                                    </span>
                                  </DFlex>
                                  <DFlex className="align-items-center">
                                    <span className="text-uppercase font-weight-bolder">
                                      {CURRENCY}&nbsp;
                                      {Number(grandTotal).toFixed(2)}
                                    </span>
                                  </DFlex>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </>
                }
              />

              <Separator solid border={"2"} />

              {data.order_details.inStoreOrderUser &&
              !this.props.isFromCheckoutEmail ? (
                <></>
              ) : (
                <>
                  <Block
                    title={
                      <>
                        Payment Method <RequiredAsterisk />
                      </>
                    }
                    content={
                      <Block
                        content={
                          <>
                            <PaymentMethod
                              data={this.props.data}
                              values={this.state.values}
                              form={{ inputActions, actions }}
                            />
                          </>
                        }
                      />
                    }
                  />
                </>
              )}

              {total_amount < 100 || (promoTotal && grandTotal < 100) ? (
                <div className="mv-1rem">
                  <span className="text-danger"> Minimum order is P 100.</span>
                </div>
              ) : (
                <></>
              )}

              {data.order_details.inStoreOrderUser &&
              !this.props.isFromCheckoutEmail ? (
                <></>
              ) : (
                <>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name={"isMarketingAllowed"}
                      checked={isMarketingAllowed}
                      onClick={() => {
                        this.setState((prevState) => ({
                          values: {
                            ...prevState.values,
                            isMarketingAllowed: !isMarketingAllowed,
                          },
                        }));
                      }}
                    />
                    I agree that placing the order places me under an obligation
                    to make a payment in accordance with the General{" "}
                    <a
                      href="https://pickup.ph/terms-of-service"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions
                    </a>
                    .<span></span>
                  </label>
                </>
              )}

              <Button
                style={buttonStyle}
                variant="primary"
                className={GetSubmitClassList(
                  this.state.isSubmitting,
                  "btn-block btn-lg font-weight-bolder",
                )}
                type="submit"
                disabled={this.handleOnSubmitDisable(data, total_amount)}
              >
                {data.order_details.inStoreOrderUser &&
                !this.props.isFromCheckoutEmail
                  ? "Send to customer for checkout"
                  : "Place Order"}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }

  nonDeliveryWithPromosOrCharge = () => {
    const {
      values: { orderType },
      values,
      hasPromo,
      hasServiceCharge,
    } = this.state;
    let valid = false;
    if (orderType !== "delivery") {
      if (hasServiceCharge) valid = true;
      if (hasPromo) {
        if (values.promo.promo_type !== "free_item") {
          valid = true;
        }
      }
    }
    return valid;
  };

  handlePromoCodeDisable() {
    const { values, isSubmitting, isPromoCodeValid } = this.state;
    const { isMealPlan } = this.props;
    let quotes = values?.delivery_quotes || [];

    if (isMealPlan) quotes = values?.meal_plan_delivery_quotes || [];

    return !Boolean(quotes?.length) || isSubmitting || isPromoCodeValid;
  }

  handleOnSubmitDisable(data, total_amount) {
    const { isMealPlan, isFromCheckoutEmail } = this.props;
    const {
      values,
      delivery_toggle,
      isSubmitting,
      grandTotal,
      hasPromo,
      isPromoFormLoading,
      isPromoCodeValid,
    } = this.state;

    // Handles incomplete customer information form
    if (!this.isFormOk()) return true;

    // Handles loading state
    if (isSubmitting) return true;

    // Handles empty cart and/or less than ideal cart value
    if (data?.cart_details?.length === 0 || total_amount < 100) return true;

    // Handle consent checkbox
    if (!values.isMarketingAllowed) return true;

    // Handles delivery type, and delivery quotes
    if (data.order_details.order_type === "delivery") {
      // Handles in store order creation
      if (data.order_details.isInStoreOrder && !Boolean(isFromCheckoutEmail))
        return false;

      // There must be a delivery partner, you can't get quotes otherwise
      if (!delivery_toggle || values.delivery_partner === "") return true;

      let quotes = values?.delivery_quotes || [];

      if (isMealPlan) quotes = values?.meal_plan_delivery_quotes || [];

      // Default to true because order type is delivery
      let areQuotesNeeded = true;

      // Handles in store order creation
      // isFromCheckoutEmail === true, then it's the user completing the checkout process
      // isFromCheckoutEmail === false, then it's the store staff creating the order for their client
      if (data.order_details.isInStoreOrder)
        areQuotesNeeded = Boolean(isFromCheckoutEmail);

      // No delivery quotes yet and quotes are needed, should not be able to checkout
      if (areQuotesNeeded && !quotes?.length) return true;
    }
    if (hasPromo) {
      if (grandTotal <= 100) return true;
    }

    if (!isPromoCodeValid) return true;

    if (isPromoFormLoading) return true;

    return false;
  }

  handleOnSubmitFail(e) {
    e.preventDefault();
    const result = MySwal.fire({
      icon: "error",
      title: "Checkout failed.",
      text: `Please check your order details and try again.`,
      confirmButtonColor: DANGER_COLOR,
      confirmButtonText: "Back",
    });
  }
}
async function updateCartDetails(cart_details, cart_id, brand_url, baseURL) {
  try {
    return await instance.post(
      "/branded-website/carts/update-cart-details",
      { cart_details, cart_id },
      {
        headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
        baseURL,
      },
    );
  } catch (err) {
    throw err;
  }
}

async function updateCartDeliveryPrioFee(
  cart_id,
  delivery_partner,
  delivery_type,
  brand_url,
  baseURL,
) {
  try {
    return await instance.post(
      "/branded-website/carts/update-delivery-prio-fee",
      { cart_id, delivery_partner, delivery_type },
      {
        headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
        baseURL,
      },
    );
  } catch (err) {
    throw err;
  }
}
