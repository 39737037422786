import React from "react";
import { SVGIcon } from "../../../components/Base";
import { Waypoint } from "react-waypoint";
import ScrollToElement from "scroll-to-element";
import computeScrollIntoView from "scroll-into-view-if-needed";

/**
 *
 * @param {array} props.categories
 * @param {object} props.website_theme
 * @returns List of categories under the store
 */
export default class CategoriesDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headerOffset: 0,
      headerHeight: 0,
      activeItem: 0,
    };

    this.menuLinkRefs = props.categories.map(() => React.createRef());
    this.onNavLinkClick = this.onNavLinkClick.bind(this.onNavLinkClick);
  }

  componentDidMount() {
    const headerElemHeight = document.querySelector("#kt_header").clientHeight;
    const mobileHeaderElemHeight =
      document.querySelector("#kt_header_mobile").clientHeight;
    const headerHeight = headerElemHeight || mobileHeaderElemHeight;
    const categoryMenuBarHeight =
      document.querySelector(".category-menu-nav").clientHeight || 0;
    const headerOffset = (categoryMenuBarHeight + headerHeight) * -1;
    const links = document.querySelectorAll(".category-menu-nav-item");

    if (links.length > 0) {
      const menuItemObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry, i) => {
            const left = document.querySelector(".category-menu-left");
            const right = document.querySelector(".category-menu-right");
            const nav = document.querySelector(".category-menu-nav");

            // stop when right and left btn is not existing
            if (!left || !right) return;

            if (entry.isIntersecting) {
              // if(refNum === 0) {
              if (entry.target.textContent === links[0].textContent) {
                setTimeout(() => {
                  left.classList.add("d-none", "opacity-100");
                  nav.classList.remove("pl-8");
                  // right.classList.remove('d-none', 'opacity-1')
                }, 10);
              }
              // if(refNum === categoryLength-1) {
              if (
                entry.target.textContent === links[links.length - 1].textContent
              ) {
                setTimeout(() => {
                  right.classList.add("d-none", "opacity-100");
                  nav.classList.remove("pr-8");
                  // left.classList.remove('d-none', 'opacity-1')
                }, 10);
              }
            } else {
              // if(refNum === 0) {
              if (entry.target.textContent === links[0].textContent) {
                setTimeout(() => {
                  left.classList.remove("d-none", "opacity-1");
                  nav.classList.add("pl-8");
                }, 10);
              }
              // if(refNum === categoryLength-1) {
              if (
                entry.target.textContent === links[links.length - 1].textContent
              ) {
                setTimeout(() => {
                  right.classList.remove("d-none", "opacity-1");
                  nav.classList.add("pr-8");
                }, 10);
              }
            }
          });
        },
        { threshold: 0.6 },
      );

      menuItemObserver.observe(links[0]);
      menuItemObserver.observe(links[links.length - 1]);
    }

    this.setState({ headerOffset, headerHeight });
  }

  // onNavLinkClick = (i)=> {

  onNavLinkClick = (category_idx, name) => {
    // if (true) {
    if (window.ReactNativeWebView) {
      const headerDivHeight =
        document.querySelector("#headerDiv").clientHeight || 0;
      const headerHeight = headerDivHeight > 350 ? 0 : headerDivHeight;
      const paddingHeight = headerDivHeight > 350 ? 25 : 15;
      console.log(headerHeight, "headerHeight");
      const el = document.querySelectorAll(".bw-iac-item")[category_idx];
      const rect = el.getBoundingClientRect();
      this.props.listRef.current._ps.element.scrollTop =
        rect.top +
        this.props.listRef.current._ps.element.scrollTop +
        (this.state.headerOffset - headerHeight - paddingHeight);
    } else {
      this.props.listRef.current.scrollToIndex({
        index: category_idx,
        align: "start",
        behavior: "smooth",
      });
    }

    setTimeout(() => {
      const items = document.querySelectorAll(".nav-link");
      const activeItem = Array.from(items).find(
        (item) => item.textContent === name,
      );

      items.forEach((item) => (item.style.color = "#B5B5C3"));
      activeItem.style.color = this.props.website_theme.link_text_color;
    }, 150);
  };

  isElementVisible(el) {
    var rect = el.getBoundingClientRect(),
      vWidth = window.innerWidth || document.documentElement.clientWidth,
      vHeight = window.innerHeight || document.documentElement.clientHeight,
      efp = function (x, y) {
        return document.elementFromPoint(x, y);
      };

    // Return false if it's not in the viewport
    if (
      rect.right < 0 ||
      rect.bottom < 0 ||
      rect.left > vWidth ||
      rect.top > vHeight
    )
      return false;

    // Return true if any of its four corners are visible
    return (
      el.contains(efp(rect.left, rect.top)) ||
      el.contains(efp(rect.right, rect.top)) ||
      el.contains(efp(rect.right, rect.bottom)) ||
      el.contains(efp(rect.left, rect.bottom))
    );
  }

  onClickNext = () => {
    const navItems = document.querySelectorAll(".category-menu-nav-item");

    const categoryVisibilityStatus = [...navItems].map((el) => ({
      visible: this.isElementVisible(el),
      element: el,
    }));
    const lastVisibleIndex = categoryVisibilityStatus.findIndex(
      (category) => category.visible,
    );
    const validElements = categoryVisibilityStatus.find(
      (category, index) => !category.visible && index > lastVisibleIndex,
    );

    computeScrollIntoView(
      validElements?.element || navItems[navItems.length - 1],
      {
        scrollMode: "if-needed",
        inline: "start",
        behavior: "smooth",
        boundary: document.querySelector(".category-menu-nav"),
      },
    );
  };

  onClickPrev = () => {
    const navItems = document.querySelectorAll(".category-menu-nav-item");

    const categoryVisibilityStatus = [...navItems].map((el) => ({
      visible: this.isElementVisible(el),
      element: el,
    }));
    const lastVisibleIndex = categoryVisibilityStatus.findIndex(
      (category) => category.visible,
    );
    const targetElement = categoryVisibilityStatus[lastVisibleIndex - 2];

    computeScrollIntoView(targetElement?.element || navItems[0], {
      scrollMode: "if-needed",
      inline: "start",
      behavior: "smooth",
      boundary: document.querySelector(".category-menu-nav"),
    });
  };

  render() {
    return (
      <>
        <div
          className="category-menu position-sticky bg-white"
          style={{ top: this.state.headerHeight }}
        >
          <nav className="position-sticky category-menu-nav">
            <ul className="nav nav-pills flex-nowrap">
              {this.props.categories.map((category, i) => {
                const {
                  _id = "",
                  visible = true,
                  visible_item_count = 0,
                } = category;
                // TODO: Add `visible_item_count` in categories list so we won't do it on the front end.
                // const visible_item_count = items.filter(item => (item.available === true || item.visible === true) && (!item.off_date || item.isVisibleOnOffDate )).length;

                if (visible && visible_item_count > 0)
                  return (
                    <li
                      className={`nav-item category-menu-nav-item flex-grow-1 flex-shrink-0`}
                      key={i}
                    >
                      <a
                        key={_id}
                        ref={this.menuLinkRefs[i]}
                        data-ref={i}
                        className={`category-menu-nav-item-${category.category_position} nav-link font-weight-bold font-size-sm border-bottom-2`}
                        onClick={() =>
                          this.onNavLinkClick(i, category.category_name)
                        }
                        // onClick={()=> this.onNavLinkClick(category.category_name)}
                      >
                        {category.category_name}
                      </a>
                    </li>
                  );
              })}
            </ul>
          </nav>

          <div
            className="category-menu-btn category-menu-left bg-light h-100"
            data-ref={"left"}
            onClick={this.onClickPrev}
          >
            <SVGIcon
              icon={"Navigation/Angle-left"}
              title={"prev"}
              variant="dark-75"
              className={`svg-icon-2x h-100 d-flex align-items-center dark-75`}
            />
          </div>

          <div
            className="category-menu-btn category-menu-right bg-light h-100"
            data-ref={"right"}
            onClick={this.onClickNext}
          >
            <SVGIcon
              icon={"Navigation/Angle-right"}
              title={"next"}
              variant="dark-75"
              className={`svg-icon-2x h-100 d-flex align-items-center dark-75`}
            />
          </div>
        </div>

        <Waypoint
          onEnter={() => {
            const items = document.querySelectorAll(".nav-link");
            const activeItem = items[0];

            // activeItem.classList.add('text-primary', 'border-primary')
            setTimeout(() => {
              // items.forEach(item => item.classList.remove('text-primary', 'border-primary'))
              // activeItem.classList.add('text-primary', 'border-primary')
              items.forEach((item) => (item.style.color = "#B5B5C3"));
              activeItem.style.color = this.props.website_theme.link_text_color;
            }, 150);
          }}
          fireOnRapidScroll={true}
          topOffset="0%"
        >
          <div />
        </Waypoint>
      </>
    );
  }
}
