import React from "react";
import { Switch } from "react-router";
import { ContentRoute } from "../../_metronic/layout";
import PerUser from "../pages/client-admin/PerUser";
import NewUserPage from "../pages/client-admin/NewUserPage";
import UsersList from "../pages/client-admin/Users";

// const MySwal = withReactContent(Swal);

export default function UserSettingsDiv(props) {
  return (
    <>
      <Switch>
        <ContentRoute
          path={`${props.webview ? "/webview" : ""}/settings/users/new`}
        >
          <NewUserPage {...props} />
        </ContentRoute>
        <ContentRoute
          path={`${props.webview ? "/webview" : ""}/settings/users/:id`}
        >
          <PerUser {...props} />
        </ContentRoute>
        <ContentRoute
          path={`${props.webview ? "/webview" : ""}/settings/users`}
        >
          <UsersList {...props} />
        </ContentRoute>
      </Switch>
    </>
  );
}
