import React from "react";
import { Button, Card, Modal } from "react-bootstrap";
import {
  HeaderTextWithActions,
  Label,
  RequiredAsterisk,
} from "../../components/Base";
import Breadcrumbs from "../../components/Breadcrumbs";
import core from "../../vendors/core-api";
import { BrandLogo } from "../BrandSetup";
import { Link } from "react-router-dom";
import BrandForm from "./BrandForm";
import { useSelector } from "react-redux";
import helpertexts from "../../helpertexts";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PRIMARY_COLOR } from "../../constants";

const MySwal = withReactContent(Swal);

const SuccessModal = ({ show, onHide, newBrandId, newBrandLogo }) => {
  return (
    <Modal show={show} onHide={onHide} centered size={"sm"}>
      <Modal.Header>
        <Modal.Title>Brand successfully created!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <center>
          <BrandLogo src={newBrandLogo} disable_change_photo={true} />
        </center>
        <br />
        <span>You may set up your new brand by creating a store.</span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Link to={`/stores/new-store/${newBrandId}`}>
          <Button variant="primary">Add new store</Button>
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default function NewBrandPage(props) {
  const { user } = useSelector((state) => state.auth);
  return <NewBrandForm user={user} {...props} />;
}

class NewBrandForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mountFile: true,
      showSuccessModal: false,
      newBrandId: "",
      newBrandLogo: "",
      mountForm: true,
    };

    this.fileUploadRef = React.createRef();

    this.onClickUploadLogo = this.onClickUploadLogo.bind(this);
    this.onSubmitBrand = this.onSubmitBrand.bind(this);
    this.uploadLogo = this.uploadLogo.bind(this);
    this.getHelperText = this.getHelperText.bind(this);

    this.api = core("pu").get();
    this.fileapi = core("puf").get();
  }

  onSubmitBrand = (values, setSubmitting) => {
    const {
      user: {
        client: { _id = "" },
      },
    } = this.props;

    values.client = _id;

    this.api
      .post({
        url: "/brand",
        data: values,
      })
      .then(({ data }) => {
        this.setState(
          {
            newBrandId: data._id,
            newBrandLogo: data.brand_logo,
            showSuccessModal: true,
            mountForm: false,
          },
          () => {
            this.setState({ mountForm: true });
          },
        );
      })
      .catch((error) => {
        setSubmitting(false);
        this.handleError(error);
      });
  };

  onClickUploadLogo = (e) => {
    e.preventDefault();

    this.setState({ mountFile: false });

    setTimeout(() => {
      this.setState({ mountFile: true });
      this.fileUploadRef.current.click();
    }, 50);
  };

  handleError = (error, maxFileSizeText = "200KB") => {
    if (error === "size-too-much") {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `File is too big! Maximum upload size is ${maxFileSizeText}.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else if (error === undefined) {
      /** do nothing */
    } else {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `Something went wrong`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };

  uploadLogo = (file, setFilePath, setSubmitting) => {
    const formData = new FormData();
    formData.append("file", file);
    this.fileapi
      .post({
        url: "/util/upload-to-aws/brands",
        data: formData,
      })
      .then(({ data }) => {
        const { filePath = "" } = data;
        setFilePath(filePath);
      })
      .catch(() => {
        this.handleError();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  getHelperText = (field) => {
    return helpertexts["getting-started"][field];
  };

  onBlurBrandName = (value, callback) => {
    if (value !== "") {
      this.api
        .post({
          url: `brand/is-name-existing`,
          data: { name: value },
        })
        .then(({ data }) => {
          return callback(
            !data.existing,
          ); /* if it's not existing, it's valid */
        })
        .catch((err) => {
          this.handleError();
        });
    }
  };

  onBlurBrandURL = (value, callback) => {
    if (value !== "") {
      this.api
        .post({
          url: `brand/is-url-existing`,
          data: { brand_url: value },
        })
        .then(({ data }) => {
          const { existing = false, invalid = false } = data;
          return callback({ existing, invalid });
        })
        .catch((err) => {
          this.handleError();
        });
    } else {
      return callback({ invalid: true });
    }
  };

  render() {
    const actions = {
      getHelperText: this.getHelperText,
      onBlurBrandName: this.onBlurBrandName,
      onBlurBrandURL: this.onBlurBrandURL,
      handleError: this.handleError,
    };

    const {
      client: { name: clientName },
    } = this.props.user;

    return (
      <>
        <HeaderTextWithActions
          title={"New Brand"}
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item
                text={clientName}
                link={`${
                  this.props.match.params.mode === "webview" ? "/webview" : ""
                }/stores`}
              />
              <Breadcrumbs.Item
                text="New Brand"
                active="true"
                link={`${
                  this.props.match.params.mode === "webview" ? "/webview" : ""
                }/stores/new-brand`}
              />
            </Breadcrumbs>
          }
        />
        <Card>
          <Card.Body>
            {this.state.mountForm ? (
              <BrandForm
                saveButtonText={<>Save</>}
                fileUploadRef={this.fileUploadRef}
                mountFile={this.state.mountFile}
                onSubmit={this.onSubmitBrand}
                onClickUploadLogo={this.onClickUploadLogo}
                uploadLogo={this.uploadLogo}
                actions={actions}
                noInit={true}
                newBrandView={true}
                {...this.props}
              />
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
        <SuccessModal
          show={this.state.showSuccessModal}
          onHide={() => this.setState({ showSuccessModal: false })}
          newBrandId={this.state.newBrandId}
          newBrandLogo={this.state.newBrandLogo}
        />
      </>
    );
  }
}
