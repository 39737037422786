import React from "react";
import moment from "moment";
import { Row, Col } from "react-bootstrap";

import EmptyData from "../../components/EmptyData";

import PageViewsByTitle from "../widgets/GoogleAnalytics/PageViewsByTitle";
import PageViewsByDevice from "../widgets/GoogleAnalytics/PageViewsByDevice";
import PageViewsByReferrer from "../widgets/GoogleAnalytics/PageViewsByReferrer";
import BasketAbandons from "../widgets/GoogleAnalytics/BasketAbandons";
import PageViewsByOS from "../widgets/GoogleAnalytics/PageViewsByOS";

const GoogleAnalyticsGrid = (props) => {
  const {
    selectedFilter = {},
    selectedDateRange = {},
    data = {},
    brands = {},
  } = props;

  // console.log("googleanalyticsgrid props: ", props)

  return (
    <>
      <div className="">
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="px-0 px-lg-4 overflow-hidden rounded"
          >
            <PageViewsByTitle
              className="card card-custom h-100 mt-2 px-0 px-lg-2 position-relative overflow-hidden"
              data={data}
              height={250}
              selectedFilter={selectedFilter}
              selectedDateRange={selectedDateRange}
              title={"Page Views for " + selectedDateRange}
              brands={brands}
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="px-0 px-lg-4 overflow-hidden rounded"
          >
            <PageViewsByReferrer
              className="card card-custom h-100 mt-2 px-0 px-lg-2 position-relative overflow-hidden"
              data={data}
              height={250}
              selectedFilter={selectedFilter}
              selectedDateRange={selectedDateRange}
              title={"Lead Sources for " + selectedDateRange}
              brands={brands}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="px-0 px-lg-4 overflow-hidden rounded"
          >
            <BasketAbandons
              className="card card-custom h-100 mt-2 px-0 px-lg-2 position-relative overflow-hidden"
              data={data}
              height={250}
              selectedFilter={selectedFilter}
              selectedDateRange={selectedDateRange}
              title={"Basket Abandons for " + selectedDateRange}
              brands={brands}
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="px-0 px-lg-4 overflow-hidden rounded"
          >
            <PageViewsByDevice
              className="card card-custom h-100 mt-2 px-0 px-lg-2 position-relative overflow-hidden"
              data={data}
              height={250}
              selectedFilter={selectedFilter}
              selectedDateRange={selectedDateRange}
              title={"Customer Device Usage for " + selectedDateRange}
              brands={brands}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="px-0 px-lg-4 overflow-hidden rounded"
          >
            <PageViewsByOS
              className="card card-custom h-100 mt-2 px-0 px-lg-2 position-relative overflow-hidden"
              data={data}
              height={250}
              selectedFilter={selectedFilter}
              selectedDateRange={selectedDateRange}
              title={
                "Customer Device Operating System Usage for " +
                selectedDateRange
              }
              brands={brands}
            />
          </Col>
        </Row>

        {/* <Row className="mt-2">
                <Col xs={12} lg={4} className="px-0 px-lg-4 overflow-hidden rounded">
                    <PageViewsByTitle
                        className='card card-custom h-100 mt-2 px-0 px-lg-2 position-relative overflow-hidden'
                        data={data}
                        height={250}
                        selectedFilter={selectedFilter}
                        selectedDateRange={selectedDateRange}
                        title={"Page Views for " + selectedDateRange}
                        // header='Page Views By Device'
                    />
                </Col>
                <Col xs={12} lg={4} className="px-0 px-lg-4 overflow-hidden rounded">
                    <PageViewsByReferrer
                        className='card card-custom h-100 mt-2 px-0 px-lg-2 position-relative overflow-hidden'
                        data={data}
                        height={'250px'}
                        selectedFilter={selectedFilter}
                        selectedDateRange={selectedDateRange}
                        title={"Lead Sources for " + selectedDateRange}
                        // header='Page Views By Device'
                    />
                </Col>
                <Col xs={12} lg={4} className="px-0 px-lg-4 overflow-hidden rounded">
                    <BasketAbandons
                        className='card card-custom h-100 mt-2 px-0 px-lg-2 position-relative overflow-hidden'
                        data={data}
                        height={250}
                        selectedFilter={selectedFilter}
                        selectedDateRange={selectedDateRange}
                        title={"Basket Abandons for " + selectedDateRange}
                        // header='Page Views By Device'
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col xs={12} lg={4} className="px-0 px-lg-4 overflow-hidden rounded">
                    <PageViewsByDevice
                        className='card card-custom h-100 mt-2 px-0 px-lg-2 position-relative overflow-hidden'
                        data={data}
                        height={250}
                        selectedFilter={selectedFilter}
                        selectedDateRange={selectedDateRange}
                        title={"Customer Device Usage for " + selectedDateRange}
                        // header='Page Views By Device'
                    />
                </Col>
            </Row> */}
      </div>
    </>
  );
};

export default GoogleAnalyticsGrid;

const CardList = ({
  items = [],
  header = "",
  mapFunction,
  className = "",
  children,
}) => {
  return (
    <div className={`analytics-card-list h-100 font-size-md ${className}`}>
      {header && <h4 className="pt-8 px-8">{header}</h4>}

      <div className="card-body">
        {items.length <= 0 ? (
          <EmptyData className="text-center mt-12" />
        ) : (
          <>
            <ul className="mt-2 p-0">{items.map(mapFunction)}</ul>

            {children}
          </>
        )}
      </div>
    </div>
  );
};
