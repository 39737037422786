import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SVGIcon } from "../Base";

export default class SelectionModal extends React.Component {
  render() {
    const {
      show = false,
      onHide = () => {},
      title = "",
      options = [],
      size = 6,
    } = this.props;

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row className="row-marginless">
            {options.map((option, index) => (
              <Col key={index} sm={size} onClick={option.onClick}>
                {option.to ? (
                  <Link
                    className="d-block py-10 px-5 text-center bg-hover-light"
                    to={option.to}
                  >
                    <SVGIcon
                      icon={option.icon}
                      title={option.title}
                      className="svg-icon-3x"
                    />
                    <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">
                      {option.title}
                    </span>
                    <span className="d-block text-dark-50 font-size-lg">
                      {option.desc}
                    </span>
                  </Link>
                ) : (
                  <div className="d-block py-10 px-5 text-center bg-hover-light">
                    <SVGIcon
                      icon={option.icon}
                      title={option.title}
                      className="svg-icon-3x"
                    />
                    <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">
                      {option.title}
                    </span>
                    <span className="d-block text-dark-50 font-size-lg">
                      {option.desc}
                    </span>
                  </div>
                )}
              </Col>
            ))}
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}
