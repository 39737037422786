/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
// import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import * as FullStory from "@fullstory/browser";
import * as Sentry from "@sentry/react";

import usePageTracking from "./components/GoogleAnalytics";

import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";

const environment = process.env.NODE_ENV || "development"; // changes for CI __
const API_URL = process.env.REACT_APP_API_URL;
if (environment === "production") {
  if (API_URL === "https://api.pickup.ph/") {
    FullStory.init({ orgId: process.env.REACT_APP_FULLSTORY_ORG_ID });
    // Sentry integration script dsn

    Sentry.init({
      dsn: "https://b45a8804c5074908851974873a5fa4c0@o1308656.ingest.sentry.io/6554678", //sentry beta pickup
    });
  }
}

export default function App(props) {
  const { hostname = "" } = window.location;
  const { store, persistor, basename } = props;

  usePageTracking();

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          {/* <BrowserRouter basename={basename}> */}
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
          <MaterialThemeProvider>
            {/* Provide `react-intl` context synchronized with Redux state.  */}
            <I18nProvider>
              {/* Render routes with provided `Layout`. */}
              <Routes hostname={hostname} />
            </I18nProvider>
          </MaterialThemeProvider>
          {/* </BrowserRouter> */}
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
