import React from "react";
import { AnimateDiv, Image250 } from "../../components/Base";
import { Card, Button } from "react-bootstrap";
import BrandWebsite from "../../partials/BrandWebsite";
import TitleHelmet from "../../partials/TitleHelmet";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import PageCenter from "../../components/PageCenter";
import { Link, Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import APIComponent from "../../components/API";
import { EXTERNAL_CHECKOUT_KEY } from "../../constants";
import core from "../../vendors/core-api";

import qs from "qs";

export default function SessionExpired(props) {
  const { token = "" } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  return <MainComponent token={token} {...props} />;
}

class MainComponent extends BrandWebsite {
  setTitle = () => {
    const { token = "" } = this.props;
    this.setState({ title: "Session Expired", token, showSplashScreen: false });
  };

  onClickUseDifferentPaymentMethod = () => {
    this.setState({ showSplashScreen: true });
    setTimeout(() => {
      this.setState({ redirectTo: "/" });
    }, 500);
  };

  render() {
    let isExternal = localStorage.getItem(EXTERNAL_CHECKOUT_KEY) || false;
    isExternal = JSON.parse(isExternal);
    const {
        data = {},
        data_status = "",
        brand_url = "",
        token = "",
        website_theme = {},
      } = this.state,
      isLoading = data_status !== "fetched";
    const {
        highlighted_text_color = "",
        button_color = "",
        button_text_color = "",
      } = website_theme,
      buttonStyle = {
        backgroundColor: button_color,
        borderColor: button_color,
        color: button_text_color,
      };

    return (
      <>
        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}
        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <TitleHelmet title={this.state.title} showWebsiteName={false} />
        {isLoading ? (
          <></>
        ) : (
          <PageCenter>
            <AnimateDiv>
              <Card
                className="card-border-top-primary card-custom"
                style={{ borderTopColor: highlighted_text_color }}
              >
                <Card.Body style={{ width: "350px" }}>
                  <div className="px-20">
                    <div className="text-center mb-1 text-dark-75">
                      {!isExternal ? (
                        <Link to={"/"}>
                          <div className="font-weight-bolder font-pickup-sm-2">
                            {data.name}
                          </div>
                        </Link>
                      ) : (
                        <div className="font-weight-bolder font-pickup-sm-2">
                          {data.name}
                        </div>
                      )}
                      <div className="text-uppercase">powered by pickup.ph</div>
                    </div>
                  </div>
                  <div className="text-center my-10">
                    <Image250
                      src={toAbsoluteUrl(`/media/svg/croods/cancelled.png`)}
                    />
                  </div>
                  <div className="text-center">
                    <div className="text-uppercase font-weight-bolder font-pickup-sm-2 ">
                      Session Expired
                    </div>
                    <div>
                      {`Ooops! Your session has been marked as expired due to past
                      order schedule or full inventory on your selected store
                      schedule. ${
                        isExternal ? "" : "Do you want to try ordering again?"
                      }`}
                    </div>
                    {token !== "" ? (
                      <ErrorComponent brand_url={brand_url} token={token} />
                    ) : (
                      <></>
                    )}
                    {!isExternal && (
                      <Button
                        variant="custom"
                        style={buttonStyle}
                        className={
                          "btn-block btn-lg text-uppercase font-weight-bolder mt-5"
                        }
                        onClick={this.onClickUseDifferentPaymentMethod}
                      >
                        try ordering again
                      </Button>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </AnimateDiv>
          </PageCenter>
        )}
      </>
    );
  }
}

class ErrorComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.api = core("pu").get();
  }

  async getData() {
    const { brand_url = "", token = "" } = this.props;

    this._isMounted && this.setState({ data_status: "fetching" });
    this.api &&
      this.api
        .post({
          url: `/branded-website/brands/${brand_url}/verify-retry-token`,
          data: { token },
        })
        .then((data) => {
          this._isMounted &&
            this.setState({
              renderModals: false,
              data: data.data,
              data_status: "fetched",
            });
          this.handleOnDataFetched();
        })
        .catch((error) => {
          this._isMounted &&
            this.setState({ error: error, showSwalError: true });
          this.handleOnDataFetchError();
        });
  }

  render() {
    const { data = {}, data_status = "" } = this.state,
      isLoading = data_status !== "fetched";

    return isLoading ? (
      <></>
    ) : (
      <>
        {data.hasOwnProperty("details") ? (
          <>
            {data.details === "paymongo_failed" ? (
              <>
                Ooops! Looks like this payment method isn't available at the
                moment. We recommend to try a different one.
              </>
            ) : (
              <></>
            )}

            {data.details === "ewallet_failed" ? (
              <>
                Ooops! Looks like your chosen payment method encountered an
                error while processing your payment.
              </>
            ) : (
              <></>
            )}

            {data.details === "payment_intent_verify" ||
            data.details === "payment_intent_failed" ? (
              <>
                {data.hasOwnProperty("last_payment_error") &&
                data.last_payment_error.hasOwnProperty("failed_message") ? (
                  <>
                    {data.last_payment_error.failed_message}
                    {data.last_payment_error.hasOwnProperty("failed_code") ? (
                      <>
                        {data.last_payment_error.failed_code ===
                          "card_expired" ||
                        data.last_payment_error.failed_code ===
                          "insufficient_funds" ? (
                          <>
                            {" "}
                            We suggest to use a different card or use a
                            different payment method.
                          </>
                        ) : (
                          <></>
                        )}
                        {data.last_payment_error.failed_code ===
                        "cvc_invalid" ? (
                          <>
                            {" "}
                            Please input the correct CVC/CVN or use a different
                            card or use a different payment method.
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}
