import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, MainContainer } from "./ReservationsDashboard";
import { isAccessAllowed } from "../../../auth";
import APIComponent from "../../../components/API";
import ReservationsCalendarHeaderDiv from "./ReservationsCalendarHeader";
import core from "../../../vendors/core-api";
import { HeaderTextWithActions } from "../../../components/Base";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ReservationsList from "./ReservationsList";

export default function Page() {
  const params = useParams(),
    { user } = useSelector((state) => state.auth),
    { brand_url = "", store_id = "" } = params;

  return isAccessAllowed(user, { brand_url, store_id }) ? (
    <ReservationsConsole id={store_id} />
  ) : (
    <Redirect to={"/error/error-v3"} />
  );
}

export const RESERVATIONS_TAB_LIST = {
  RESERVATIONS_QUEUE: "reservations",
  TASKS: "tasks",
  GUESTS: "guests",
};

class ReservationsConsole extends APIComponent {
  constructor(props) {
    super(props);
    const store_id = this.props.id;
    this.state = {
      store_id,
    };

    this.data_url = "/store/" + store_id + "/header-details";
    this.api = core("pu").get();
  }

  handleOnDataFetched = () => {
    const { data = {} } = this.state,
      { name = "", brand_name = "" } = data;
    this.setState({
      store_name: name,
      brand_name,
    });
  };

  async componentDidMount() {
    this._isMounted = true;
    await this.getData();
  }

  render() {
    const { selected_date, store_name, store_id, brand_name } = this.state;

    return (
      <MainContainer>
        <HeaderTextWithActions
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item text={brand_name} link={`/`} />
              <Breadcrumbs.Item
                text="Reservations"
                link={`/reservations/new`}
              />
              <Breadcrumbs.Item
                text={store_name}
                link={`/stores/${store_id}/reservations/new`}
                active
              />
            </Breadcrumbs>
          }
          title={""}
          subtitle={<></>}
        />

        <div
          className="d-flex flex-row justify-content-space-between"
          style={{ height: "55rem" }}
        >
          <ReservationsList store_id={store_id} api={this.api} />
          <div className="d-flex flex-column ml-5" style={{ flexBasis: "60%" }}>
            <ReservationsCalendarHeaderDiv
              selected_date={selected_date}
              onCalendarBtnClick={this.onCalendarBtnClick}
              onDateInputChange={this.onDateInputChange}
            />
            <ReservationsSeatMap />
          </div>
        </div>
      </MainContainer>
    );
  }
}

const ReservationsSeatMap = () => {
  return (
    <Container
      className="flex-column mt-3 w-100 h-100"
      style={{ backgroundColor: "#222222" }}
    >
      <div
        className="d-flex justify-content-center align-items-center font-pickup-lg font-weight-bolder h-100"
        style={{ color: "#fff" }}
      >
        {" "}
        SEAT MAP{" "}
      </div>
    </Container>
  );
};
