import React, { useState, useEffect } from "react";
import { SVGIcon, GreenCheckbox, GreenRadio } from "../../components/Base";
import { getCartItemQty, getResizedImage, toPesoAmount } from "../../helpers";
// import { withStyles } from '@material-ui/core/styles';
import { RadioGroup, FormControlLabel, FormGroup } from "@material-ui/core";
import { Card, Modal, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

const ExtrasComponent = ({
  extragroup = [],
  setExtras,
  extras = [],
  setNotValid,
  CheckIfNotValid,
}) => {
  const handleChange = (event) => {
    const target = event.target,
      _id = target.getAttribute("_id"),
      price = target.getAttribute("price"),
      e_g_id = target.getAttribute("extra_group_id"),
      name = target.getAttribute("name");

    const selected = {
      extra_group_id: e_g_id,
      extra_id: _id,
      price: price,
      name,
    };

    let extras_temp = extras;
    const extra_index = extras_temp.findIndex(
      (x) => x.extra_group_id === e_g_id,
    );
    if (extra_index === -1) {
      extras_temp.push(selected);
    } else {
      extras_temp.splice(extra_index, 1);
      extras_temp.push(selected);
    }

    setExtras(extras_temp);
    setNotValid(CheckIfNotValid());
  };
  const CountByGroupId = (extras, group_id) => {
    return extras.reduce((total, order) => {
      if (order.extra_group_id === group_id) {
        return (total += 1);
      } else {
        return total;
      }
    }, 0);
  };
  const CheckBoxhandle = (event) => {
    const target = event.target,
      _id = target.getAttribute("_id"),
      e_g_id = target.getAttribute("extra_group_id"),
      price = target.getAttribute("price"),
      maximum_number = Number(target.getAttribute("maximum_number")),
      name = target.getAttribute("name");

    const selected = {
      extra_group_id: e_g_id,
      extra_id: _id,
      price: price,
      name,
    };
    let extras_temp = extras;

    const extra_index = extras_temp.findIndex((x) => x.extra_id === _id);
    if (extra_index === -1) {
      const count = CountByGroupId(extras, e_g_id);
      if (count === maximum_number) {
        target.click();
      } else {
        extras_temp.push(selected);
      }
    } else {
      extras_temp.splice(extra_index, 1);
    }

    setExtras(extras_temp);
    setNotValid(CheckIfNotValid());
  };
  const SelectionCardHeader = ({
    maximum_number,
    minimum_number,
    description,
    name,
    isRequired,
  }) => {
    return (
      <>
        <Card.Title style={{ marginBottom: "1rem" }}>
          {/* <div className="d-flex justify-content-between w-100" >
                        <div className="col-example text-left font-weight-bold" style={{ fontSize: "1.25rem" }}>{name}</div>
                        <div className={`col-example text-right align-content-right ${ isRequired && 'flex-shrink-0 flex-grow-1'}`}>
                            {maximum_number === 1 ? <></> : <span className="label label-warning label-inline font-weight-bold text-center mb-1">CAN SELECT: {maximum_number}</span>}
                            {isRequired ?
                                <span className="label label-info label-inline font-weight-bold text-center"> {minimum_number} REQUIRED</span> :
                                <span className="label label-inline font-weight-bold text-center"> OPTIONAL</span>
                            }
                        </div>
                    </div> */}
          <h6 className="d-flex justify-content-between align-items-start">
            <span className="selection-item-title">{name}</span>
            {isRequired ? (
              <span className="selection-item-tag label label-info label-inline font-weight-bold text-center">
                REQUIRED
              </span>
            ) : (
              <span className="selection-item-tag label label-inline font-weight-bold text-center">
                OPTIONAL
              </span>
            )}
          </h6>
        </Card.Title>

        <Card.Subtitle className="text-muted">
          <div className="d-flex flex-column">
            <div className="col-example text-left" style={{ fontSize: "1rem" }}>
              {description}
            </div>
            <div className="col-example text-left mt-2">
              <span
                className="text-dark"
                style={{
                  fontSize: ".95rem",
                  fontWeight: "300",
                }}
              >
                {/* {isRequired ? 'Select ': 'Select up to '} {maximum_number} {!isRequired && ' (optional)'} */}
                {isRequired ? "Select " : "Select up to "}{" "}
                {!isRequired ? maximum_number + " (optional)" : minimum_number}
              </span>
            </div>
          </div>
        </Card.Subtitle>
      </>
    );
  };
  const SelectionCardRadio = ({ name, price, _id, extra_group_id, i }) => {
    return (
      <div key={i + "es"}>
        <div className="d-flex flex justify-content-between w-100">
          <FormControlLabel
            style={{ marginBottom: "-1rem" }}
            value={name}
            control={
              <GreenRadio
                inputProps={{
                  _id: _id,
                  price: price,
                  extra_group_id: extra_group_id,
                  name,
                }}
              />
            }
            label={name}
            labelPlacement={"end"}
          />
          {/* { !isMealPlan && <div className="p-2 col-example text-left" style={{ marginBottom: "-1rem" }}> + {toPesoAmount(price)}</div> } */}
          {
            <div
              className="p-2 col-example text-left"
              style={{ marginBottom: "-1rem" }}
            >
              {" "}
              + {toPesoAmount(price)}
            </div>
          }
        </div>
      </div>
    );
  };
  return extragroup.map((extra, i) => {
    const {
      name,
      description,
      group_selections,
      isRequired,
      maximum_number,
      minimum_number,
      _id: extra_group_id,
    } = extra.extra_group_id;
    return (
      <Card className="d-flex justify-content-between w-100" key={i + "es"}>
        <Card.Body className="py-2 px-7">
          <SelectionCardHeader
            maximum_number={maximum_number}
            minimum_number={minimum_number}
            description={description}
            name={name}
            isRequired={isRequired}
          />
          {minimum_number === 1 && maximum_number === 1 ? (
            <RadioGroup onChange={handleChange}>
              {group_selections.map(({ extra_id: { name, price, _id } }, i) => {
                return (
                  <SelectionCardRadio
                    name={name}
                    price={price}
                    i={i}
                    _id={_id}
                    extra_group_id={extra_group_id}
                  />
                );
              })}
            </RadioGroup>
          ) : (
            <FormGroup>
              {group_selections.map(({ extra_id: { name, price, _id } }, i) => {
                return (
                  <div key={i + "es"}>
                    <div className="d-flex flex justify-content-between w-100">
                      <FormControlLabel
                        style={{ pointerEvents: "none", marginBottom: "-1rem" }}
                        control={
                          <GreenCheckbox
                            style={{ pointerEvents: "auto" }}
                            inputProps={{
                              _id: _id,
                              price: price,
                              extra_group_id: extra_group_id,
                              maximum_number: maximum_number,
                              minimum_number: minimum_number,
                            }}
                            onChange={CheckBoxhandle}
                            name={name}
                          />
                        }
                        label={name}
                      />
                      {/* { !isMealPlan && <div className="p-2 col-example text-left" style={{ marginBottom: "-1rem" }}> + {toPesoAmount(price)}</div> } */}
                      {
                        <div
                          className="p-2 col-example text-left"
                          style={{ marginBottom: "-1rem" }}
                        >
                          {" "}
                          + {toPesoAmount(price)}
                        </div>
                      }
                    </div>
                  </div>
                );
              })}
            </FormGroup>
          )}
        </Card.Body>
      </Card>
    );
  });
};

const AddToCart = ({
  item_id,
  addToCart,
  actions,
  checkStocks,
  extragroup = [],
  cart = {},
  website_theme = {},
  selected_item = {},
  selectedItemTags = {},
}) => {
  const { isMealPlan = false } = cart;

  const CheckIfNotValid = () => {
    let extras_ = extras;
    let extragroup_ = extragroup;
    const required_groups = extragroup_.filter(
      ({ extra_group_id }) => extra_group_id.isRequired === true,
    );
    var results = extras_.reduce(function (results, extra) {
      (results[extra.extra_group_id] =
        results[extra.extra_group_id] || []).push(extra);
      return results;
    }, {});
    let returns = [];
    for (var i in required_groups) {
      let required_id = required_groups[i].extra_group_id._id;
      let minimum_number = required_groups[i].extra_group_id.minimum_number;
      if (results[required_id]) {
        const result_count = results[required_id].length;
        if (result_count < minimum_number) {
          returns.push(true);
        } else {
          returns.push(false);
        }
      } else {
        returns.push(true);
      }
    }
    return returns.findIndex((x) => x === true) === -1 ? false : true;
  };

  const { handleOnClickRemoveItem } = actions;
  const [extras, setExtras] = useState([]); //to be used for extras in cart and order
  const [counter, setCounter] = useState(1); // JD: `counter` is number in the item modal
  const [notValid, setNotValid] = useState(CheckIfNotValid());

  // TODO: Check AddToCart vs CartModal checkStocks
  // JD: Increment counter in item modal
  const incrementCounter = function () {
    const newCounter = counter + 1;

    if (checkStocks(item_id, newCounter)) {
      setCounter(newCounter);
    }
  };

  // JD: Decrement counter in item modal
  const decrementCounter = function () {
    const newCounter = counter - 1;
    setCounter(newCounter);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions(),
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }
  const { height, width } = useWindowDimensions();
  const isMobile = () => {
    return width < 576;
  };
  const { button_text_color = "", button_color = "" } = website_theme;
  const medium_image = getResizedImage(
    selected_item.image_url,
    "medium",
    selected_item.date_updated || selected_item.date_created,
  );
  const large_image = getResizedImage(
    selected_item.image_url,
    "large",
    selected_item.date_updated || selected_item.date_created,
  );
  const ModalContent = () => (
    <>
      <div>
        <button
          type="button"
          className="close addtocart-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={actions.hideSelectedItemModal}
        >
          <i
            aria-hidden="true"
            className="ki ki-close rounded-circle bg-dark text-light px-5 py-4"
          ></i>
        </button>
        <div className="item-modal-image-container">
          <img
            className="item-modal-image"
            src={isMobile() ? medium_image : large_image}
            alt={selected_item.name}
            onError={(ev) => {
              ev.target.onError = null;
              ev.target.src = selected_item.image_url;
            }}
          />
          {!isMealPlan && selected_item.active_discount > 0 ? (
            selected_item.discount_type === "percentDiscount" ? (
              <div
                className="px-5"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "red",
                  backgroundColor: "rgba(250,250,250,0.5)",
                  fontSize: "3em",
                  fontWeight: "bolder",
                }}
              >
                {Number(selected_item.discount_value)}% OFF
              </div>
            ) : selected_item.discount_type === "rawDiscount" ? (
              <div
                className="px-5"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "red",
                  backgroundColor: "rgba(250,250,250,0.5)",
                  fontSize: "3em",
                  fontWeight: "bolder",
                }}
              >
                P {Number(selected_item.active_discount).toFixed(2)} OFF
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      </div>

      <Modal.Header className="addtocart-header d-flex flex-column mt-2 py-0 px-7">
        <div className="d-flex justify-content-between  w-100">
          <span
            className="mb-0 font-weight-bolder col-example text-left w-75"
            style={{ fontSize: "1.25rem" }}
          >
            {selected_item.name}{" "}
          </span>
          {!isMealPlan && (
            <>
              {selected_item.active_discount > 0 ? (
                <div className="item-price">
                  <del>{toPesoAmount(selected_item.regular_price)}</del>
                  <span className="ml-3 text-danger font-weight-boldest">
                    {toPesoAmount(selected_item.price)}
                  </span>
                </div>
              ) : (
                <div className="item-price">
                  {toPesoAmount(selected_item.price)}
                </div>
              )}
            </>
          )}
        </div>
        <p className="w-100 mb-0">{selected_item.description}</p>
        {!selected_item.isMealPlan &&
        selected_item.discount_description &&
        selected_item.active_discount > 0 ? (
          <div className="w-100 mb-0 text-danger">
            {selected_item.discount_description}
          </div>
        ) : (
          <></>
        )}
        <div className="w-100 px-4 pt-1 pb-0">
          <div className="row">{selectedItemTags}</div>
        </div>
      </Modal.Header>
    </>
  );
  const buttonStyle = {
    borderColor: button_color,
    backgroundColor: button_color,
    color: button_text_color,
  };
  return (
    <div className="addtocart-wrapper position-relative">
      <PerfectScrollbar
        options={{
          swipeEasing: true,
          wheelPropagation: false,
          suppressScrollX: true,
        }}
        style={{ maxHeight: isMobile() ? height - 60 : "calc(100vh - 200px)" }}
      >
        <ModalContent />
        <Modal.Body className="addtocart-body w-100">
          <ExtrasComponent
            extragroup={extragroup}
            extras={extras}
            setExtras={setExtras}
            setNotValid={setNotValid}
            CheckIfNotValid={CheckIfNotValid}
            isMealPlan={isMealPlan}
          />
        </Modal.Body>
      </PerfectScrollbar>
      <div className="d-flex flex justify-content-center flex-column">
        <div className="addtocart-footer w-100 d-flex justify-content-between  bg-light">
          <div className="addtocart-footer-controller rounded overflow-hidden border">
            <Button
              variant="light"
              className="btn-icon btn-sm br-0"
              data-id={item_id}
              data-action="minus"
              onClick={
                counter !== 0 ? decrementCounter : handleOnClickRemoveItem
              }
              disabled={counter <= 1}
            >
              <SVGIcon
                icon={"Navigation/Minus"}
                title={"Remove"}
                variant="dark"
              />
            </Button>

            <Button
              variant="outline-danger"
              className="btn-icon btn-sm br-0 inactive-link b-none"
            >
              <span className="font-weight-bolder">{counter}</span>
            </Button>

            <Button
              variant="light"
              className="btn-icon btn-sm br-0"
              data-id={item_id}
              data-action="plus"
              onClick={incrementCounter}
            >
              <SVGIcon icon={"Navigation/Plus"} title={"Add"} variant="dark" />
            </Button>
          </div>
          <Button
            style={buttonStyle}
            className="addtocart-footer-btn font-weight-bold ml-4"
            data-id={item_id}
            disabled={notValid}
            onClick={
              (e) => addToCart(e, counter, extras)
              //Use the Extras variable for extras array
            }
          >
            {"Add to basket"}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default AddToCart;
