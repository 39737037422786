import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { Block, FormItem, Label } from "../../components/Base";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  checkIfCanUpdateField,
  GetDataFromEvent,
  GetSubmitClassList,
} from "../../helpers";
import { useSelector } from "react-redux";

export default function UserPersonalInformationForm(props) {
  const { user } = useSelector((state) => state.auth);
  return <UserPersonalInformationFormClass {...props} currentUser={user} />;
}

class UserPersonalInformationFormClass extends ProSourceForm {
  constructor(props) {
    super(props);

    const { user = {} } = this.props,
      {
        first_name = "",
        last_name = "",
        contact_number = "",
        email = "",
        iam_role = "",
        is_accounting = false,
        active = true,
        privileges = [],
      } = user;

    this.state = {
      values: {
        first_name,
        last_name,
        contact_number,
        email,
        iam_role,
        is_accounting,
        active,
        privileges,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      checkingEmail: false,
    };

    this.validate = {
      required: ["first_name", "last_name", "email", "iam_role"],
    };

    this.handleOnClickDeleteBtn = this.handleOnClickDeleteBtn.bind(this);
    this.onChangePrivilege = this.onChangePrivilege.bind(this);
  }

  onChangePrivilege = (event) => {
    const target = event.target,
      checked = target.checked,
      value = GetDataFromEvent(event, "data-value");
    let { values = {} } = this.state;

    if (checked) {
      values.privileges.push(value);
    } else {
      values.privileges = values.privileges.filter((privilege) => {
        return privilege !== value;
      });
    }

    this.setState({ values });
  };

  onChange = (event) => {
    const target = event.target,
      checked = target.checked,
      name = target.name,
      value = target.value;

    let { values = {}, errors = {} } = this.state,
      state = {};

    if (name === "is_accounting") {
      values[name] = checked;
      state.values = values;
    } else if (name === "email" && value !== "") {
      const valid = this.validateEmail(value);
      if (!valid) {
        errors.email = "Please enter a valid email";
      } else {
        delete errors.email;
      }

      state.errors = errors;
    }

    this.setState(state);
  };

  onBlur = (event) => {
    const target = event.target,
      name = target.name,
      value = target.value;
    let { errors = {} } = this.state;

    if (name === "email" && value !== "") {
      this.setState({ checkingEmail: true });
      this.props.api
        .post({
          url: "/external/users/validate",
          data: { email: value },
        })
        .then(({ data }) => {
          this.setState({ checkingEmail: false });
          const { existing = false } = data;
          if (existing) {
            errors["email"] = "Email is already taken.";
          } else {
            if (errors["email"] === "Email is already taken.") {
              // check first if it's the existing email error before deleting
              delete errors["email"];
            }
          }

          this.setState({ errors });
        })
        .catch(() => {
          this.props.handleError();
        })
        .finally(() => {
          this.setState({ checkingEmail: false });
        });
    }
  };

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  handleOnClickDeleteBtn = () => {
    this.props.onClickDeleteBtn && this.props.onClickDeleteBtn();
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      },
      { submitButton = "Save Changes", showBackButton = false } = this.props,
      { checkingEmail = false, values = {} } = this.state;

    const isClientAdmin = this.props.hasOwnProperty("user")
        ? this.props.user.hasOwnProperty("iam_role") &&
          this.props.user.iam_role === "group_admin"
        : false,
      isActive = values.active;

    return checkIfCanUpdateField(this.props.user, this.props.currentUser) ? (
      <Form onSubmit={this.handleOnSubmit}>
        <Row>
          <Col>
            <FormItem
              label={"First Name:"}
              name={"first_name"}
              inputProps={{
                name: "first_name",
                placeholder: "First Name",
                value: this.state.values["first_name"],
                disabled: !isActive ? "disabled" : "",
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
            />
          </Col>
          <Col>
            <FormItem
              label={"Last Name:"}
              name={"last_name"}
              inputProps={{
                name: "last_name",
                placeholder: "Last Name",
                value: this.state.values["last_name"],
                disabled: !isActive ? "disabled" : "",
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <FormItem
              label={"Email:"}
              name={"email"}
              inputProps={{
                name: "email",
                placeholder: "Email",
                value: this.state.values["email"],
                disabled: !isActive ? "disabled" : "",
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              showRequired={true}
              normal
              helperText={
                checkingEmail ? (
                  <>
                    <span className="text-success">
                      Checking email availability...
                    </span>
                  </>
                ) : (
                  <></>
                )
              }
            />
          </Col>
          <Col>
            <FormItem
              label={"Contact Number:"}
              name={"contact_number"}
              inputProps={{
                name: "contact_number",
                placeholder: "Contact Number",
                value: this.state.values["contact_number"],
                disabled: !isActive ? "disabled" : "",
              }}
              inputActions={inputActions}
              actions={actions}
              type={"text"}
              normal
            />
          </Col>
        </Row>

        <div className="d-flex flex-row justify-content-space-between">
          <div>
            {showBackButton ? (
              <Button
                type="button"
                variant="secondary"
                onClick={this.props.onClickBackBtn}
                className={GetSubmitClassList(
                  this.state.isSubmitting,
                  `btn btn-primary font-weight-bold px-9 py-4 my-3`,
                )}
              >
                <span>Back</span>
              </Button>
            ) : (
              <></>
            )}
          </div>
          <div className="text-right">
            {this.props.showDeleteButton && !isClientAdmin ? (
              <button
                type="button"
                disabled={this.props.deleteLoading}
                className={GetSubmitClassList(
                  this.props.deleteLoading,
                  "btn btn-danger font-weight-bold px-9 py-4 my-3 mr-2",
                )}
                onClick={this.props.handleOnClickDeleteBtn}
              >
                <span>Mark as {isActive ? <>inactive</> : <>active</>}</span>
              </button>
            ) : (
              <></>
            )}
            {isActive ? (
              <button
                type="submit"
                disabled={this.state.isSubmitting || checkingEmail}
                className={GetSubmitClassList(
                  this.state.isSubmitting,
                  `btn btn-primary font-weight-bold px-9 py-4 my-3`,
                )}
              >
                <span>{submitButton}</span>
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Form>
    ) : (
      <>
        <Row>
          <Col>
            <Block
              content={
                <>
                  <Label text="First Name" />
                  <div>{this.state.values.first_name}</div>
                </>
              }
            />
          </Col>
          <Col>
            <Block
              content={
                <>
                  <Label text="Last Name" />
                  <div>{this.state.values.last_name}</div>
                </>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Block
              content={
                <>
                  <Label text="Email" />
                  <div>{this.state.values.email}</div>
                </>
              }
            />
          </Col>
          <Col>
            <Block
              content={
                <>
                  <Label text="Contact Number" />
                  <div>{this.state.values.contact_number}</div>
                </>
              }
            />
          </Col>
        </Row>
      </>
    );
  }
}
