import React from "react";
import { Container } from "./ReservationsDashboard";
import { Button, Card, Dropdown } from "react-bootstrap";
import moment from "moment";
import { DB_DATE_FORMAT } from "../../../constants";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const getCurrentWeek = (date) => {
  const startWeek = moment(date).subtract(3, "day");
  const endWeek = moment(date).add(3, "day");

  const daysArr = [];
  let day = startWeek;

  while (day <= endWeek) {
    daysArr.push({
      date: day,
      day: day.format("ddd"),
      number: day.format("DD"),
    });
    day = day.add(1, "d");
  }
  return daysArr;
};

const ReservationsCalendarHeader = (props) => {
  const { selected_date, onCalendarBtnClick, onDateInputChange } = props;
  const title = moment(selected_date).format("MMMM YYYY");
  const weekArray = getCurrentWeek(selected_date);
  return (
    <Container
      className="flex-row reservations-calendar"
      style={{ maxHeight: 165, width: "100%" }}
    >
      <Card style={{ width: "100%" }}>
        <Card.Body className="p-4">
          <div className="d-flex flex-row">
            <div className="font-pickup-lg font-weight-bolder">{title}</div>
            <input
              type="date"
              className="form-control rounded-0 d-inline-block ml-5"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              onChange={onDateInputChange}
              value={moment(selected_date).format(DB_DATE_FORMAT)}
              style={{ width: 130 }}
            />
            <Dropdown
              className="ml-4 mr-4 mt-4 mt-md-0 rounded-top"
              style={{ right: "0" }}
            >
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                All Services
              </Dropdown.Toggle>
            </Dropdown>
          </div>
        </Card.Body>
        <Card.Body className="p-1 px-4">
          <div className="d-flex justify-content-space-evenly align-items-center reservation-calendar pb-3">
            <Button
              className="svg-icon svg-icon-white p-1"
              onClick={() => onCalendarBtnClick(false)}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Angle-left.svg",
                )}
                width={23}
                height={23}
              />
            </Button>
            <div
              className="d-flex flex-row justify-content-space-around"
              style={{ width: "100%" }}
            >
              {weekArray.map(({ day, number }, index) => {
                const isActive = index === 3;
                return (
                  <div
                    key={`calendar-${day}`}
                    className={`d-flex flex-column align-items-center font-weight-bolder p-2 ${
                      isActive ? "active-calendar" : ""
                    }`}
                  >
                    <div
                      className="d-flex justify-content-center font-pickup-sm"
                      style={{
                        color: isActive ? "#fff" : "",
                        height: 25,
                        width: 30,
                      }}
                    >
                      {day}
                    </div>
                    <div
                      className={`d-flex justify-content-center font-pickup-sm-2 p-1 ${
                        isActive ? "active-calendar-day" : ""
                      }`}
                      style={{ height: 30, width: 30 }}
                    >
                      {number}
                    </div>
                  </div>
                );
              })}
            </div>
            <Button
              className="svg-icon svg-icon-white p-1"
              onClick={() => onCalendarBtnClick(true)}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Angle-right.svg",
                )}
                width={23}
                height={23}
              />
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ReservationsCalendarHeader;
