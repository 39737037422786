/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Portal } from "react-portal";
import {
  Layout,
  LayoutSplashScreen,
  LayoutWithSideNav,
} from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import Action from "./pages/client-admin/Action";
import ApprovalAction from "./pages/client-admin/ApprovalAction";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import BrandedWebsiteRoutes from "./pages/branded-website/Routes";
import { setStorage } from "../_metronic/_helpers";
import core from "./vendors/core-api/";
import { getUserByToken } from "./modules/Auth/_redux/authCrud";
import { actions } from "./modules/Auth/_redux/authRedux";

const pkg = require("../../package.json");
console.log("V" + pkg.version);

function SetupCoreAPI() {
  const { authToken = "" } = useSelector((state) => state.auth);
  core().setAuthToken(authToken);
  return <></>;
}

const { REACT_APP_CONSOLE_MODE = "0", REACT_APP_DOMAIN = "" } = process.env;

export function Routes(props) {
  const [isOnline, setIsOnline] = React.useState(true);

  const { hostname = "" } = props;
  const IS_BRAND_WEBSITE =
    REACT_APP_CONSOLE_MODE === "0" && hostname !== `app${REACT_APP_DOMAIN}`;

  // console.log(hostname, IS_BRAND_WEBSITE, REACT_APP_CONSOLE_MODE);

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual,
  );

  // console.log("IS_BRAND_WEBSITE", IS_BRAND_WEBSITE)

  setStorage("PICKUP_BW", IS_BRAND_WEBSITE ? "1" : "0");

  useEffect(() => {
    // Connectivity check
    window.addEventListener("offline", function (e) {
      setIsOnline(false);
    });

    window.addEventListener("online", function (e) {
      setIsOnline(true);
    });
  }, []);

  // console.log('isAuthorized', isAuthorized)

  return (
    <>
      <Portal>
        <div
          className={`${
            isOnline ? "d-none" : "d-block"
          } bg-danger text-white text-center position-fixed w-100 p-4`}
          style={{ top: "10vh" }}
        >
          {!isOnline && "Please connect your device to the internet"}
        </div>
      </Portal>

      {IS_BRAND_WEBSITE ? (
        <>
          <BrandedWebsiteRoutes {...props} />
        </>
      ) : (
        <Switch>
          <Route path="/action" component={Action} />
          <Route path="/approval-action" component={ApprovalAction} />
          <Route path="/logout" component={Logout} />
          {!isAuthorized ? (
            /*Render auth page when ujser at `/auth` and not authorized.*/
            <Route>
              <AuthPage />
            </Route>
          ) : /*Otherwise redirect to root page (`/`)*/
          window.ReactNativeWebView ? (
            <Redirect from="/auth" to="/webview/dashboard" />
          ) : (
            <Redirect from="/auth" to="/" />
          )}

          <Route path="/error" component={ErrorsPage} />

          {!isAuthorized ? (
            /*Redirect to `/auth` when user is not authorized*/
            <Redirect to="/auth/login" />
          ) : (
            <RenderBasePage
              Layout={Layout}
              LayoutWithSideNav={LayoutWithSideNav}
            />
          )}
        </Switch>
      )}
    </>
  );
}

function RenderBasePage(props) {
  const dispatch = useDispatch();

  const [isSetUpCoreAPIDone, setIsSetUpCoreAPIDone] = React.useState(false);
  const [isPrivilegeChecked, setIsPrivilegeChecked] = React.useState(false);
  const [privileges, setPrivileges] = React.useState([]);

  useEffect(() => {
    setIsSetUpCoreAPIDone(true);
  }, []);

  if (isSetUpCoreAPIDone && !isPrivilegeChecked) {
    getUserByToken()
      .then(({ data }) => {
        const { privileges = [] } = data;
        if (!privileges.includes("help")) {
          privileges.push("help");
        }
        setPrivileges(privileges);
        dispatch(actions.fulfillUser(data));
      })
      .catch((error) => {
        if (error.hasOwnProperty("response")) {
          const { data = {} } = error.response,
            { message = {} } = data;

          if (
            message.error === "TokenExpiredError" ||
            message.error === "UnauthorizedUserError"
          ) {
            window.location.href = "/logout";
          } else if (message.error === "JsonWebTokenError") {
            window.location.href = "/logout?multiple=true";
          }
          // else if(message.error === "UnauthorizedUserError") {
          //     window.location.href = "/error/error-v3";
          // }
        }
      })
      .finally(() => {
        setIsPrivilegeChecked(true);
      });
  }

  return (
    <>
      <SetupCoreAPI />
      {isPrivilegeChecked ? (
        <BasePage privileges={privileges} {...props} />
      ) : (
        <LayoutSplashScreen />
      )}
    </>
  );
}
