import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
// import helpertexts from "../../helpertexts";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import {
  HeaderTextWithActions,
  LoadingDiv,
  Block,
  Label,
} from "../../components/Base";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Row, Col, Tab, Nav, Card } from "react-bootstrap";
import moment from "moment";
import Datatable from "react-data-table-component";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";

const MENUS = {
  main: "main",
  menu: "menu",
  orders: "orders",
};

function FieldsRow({ fields }) {
  return (
    <Row>
      {fields.map((field, index) => {
        const { value = "N/A" } = field;
        return (
          <Col key={index}>
            <Block
              content={
                <>
                  <Label
                    text={
                      <>
                        <span className="va-middle text-capitalize">
                          {field.name}
                        </span>
                      </>
                    }
                  />
                  <div>{value}</div>
                </>
              }
            />
          </Col>
        );
      })}
    </Row>
  );
}

function Table({
  data,
  group_id = "",
  brand_id = "",
  progressPending = false,
}) {
  const [searchText, setSearchText] = React.useState("");
  const filteredItems =
    searchText !== ""
      ? data.filter((item) => {
          return (
            item.item_id.name
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1
          );
        })
      : data;

  return (
    <>
      <Datatable
        columns={[
          {
            name: "Item Name",
            selector: (row) => {
              return row.item_id.name;
            },
            format: (row) => {
              return row.item_id.name;
            },
            sortable: true,
            style: "font-weight-bold",
          },
          {
            name: "Category",
            selector: (row) => {
              return row.category;
            },
            sortable: true,
          },
          {
            name: "Price",
            selector: (row) => {
              return row.item_id.price;
            },
          },
        ]}
        data={filteredItems}
        pagination
        noHeader
        highlightOnHover
        subHeader
        subHeaderWrap={false}
        subHeaderComponent={
          <>
            <Row>
              <Col>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="flaticon-search"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </>
        }
        progressPending={progressPending}
      />
    </>
  );
}

export default function Page() {
  const { store_id = "", group_id = "", brand_id = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  return (
    <MainComponent
      id={store_id}
      brand_id={brand_id}
      group_id={group_id}
      user={user}
    />
  );
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Store",
    };

    this.api = core("pu").get();
    this.data_url = "/store/" + this.props.id;

    this.setActiveMenu = this.setActiveMenu.bind(this);
  }

  handleOnDataFetched = () => {
    const { data = {} } = this.state;
    this.setState({
      group_name: data.brand.client.name,
      brand_name: data.brand.name,
      store_name: data.name,
      group_id: data.brand.client._id,
      brand_id: data.brand._id,
      title: data.name,
      store_id: data._id,
    });
  };

  setActiveMenu = (activeMenuKey) => {
    this.setState({ activeMenuKey });
  };

  render() {
    const { data = {}, data_status = "" } = this.state,
      isLoading = data_status !== "fetched";
    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          <>
            <HeaderTextWithActions
              title={this.state.title}
              className="mb-8"
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item text="Groups" link={`/admin/groups`} />
                  <Breadcrumbs.Item
                    text={this.state.group_name}
                    link={`/admin/groups/${this.state.group_id}`}
                  />
                  <Breadcrumbs.Item
                    text={"Stores"}
                    link={`/admin/groups/${this.state.group_id}/stores`}
                  />
                  <Breadcrumbs.Item text={this.state.store_name} link={null} />
                </Breadcrumbs>
              }
            />

            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={MENUS.main}
              activeKey={this.state.activeMenuKey}
            >
              <Row>
                <Col sm={3}>
                  <Card>
                    <Card.Body>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.main}
                            onClick={() => this.setActiveMenu(MENUS.main)}
                          >
                            Store Information
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={MENUS.menu}
                            onClick={() => this.setActiveMenu(MENUS.menu)}
                          >
                            Menu
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey={MENUS.main}>
                      <Card>
                        <Card.Body>
                          {[
                            [{ name: "store name", value: data.name }],
                            [
                              {
                                name: "date created",
                                value: moment(data.date_created).format(
                                  "MMM D, YYYY hh:mm A",
                                ),
                              },
                            ],
                          ].map((fields) => {
                            return <FieldsRow fields={fields} />;
                          })}
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey={MENUS.menu}>
                      <Card>
                        <Card.Body>
                          <Table data={data.menu} progressPending={isLoading} />
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </>
        )}
      </>
    );
  }
}
