import React from "react";

import EmptyData from "../EmptyData";
import { toPesoAmount } from "../../helpers";
import PacmanWrapper from "./PacmanWrapper";
import useFetch from "../../hooks/useFetch";
import { isNil } from "lodash";

const CardList = ({
  params = {},
  header = "",
  mapFunction,
  className = "",
}) => {
  const reqObj = {
    url: "/dashboard/sales-by-payment/type",
    method: "post",
    body: params,
  };

  const { data, isLoading } = useFetch(reqObj, {});
  const items = data || [];

  return (
    <>
      {isLoading || data === null ? (
        <PacmanWrapper />
      ) : (
        <div className={`analytics-card-list h-100 font-size-md ${className}`}>
          {header && <h4 className="pt-8 px-8">{header}</h4>}

          <div className="card-body">
            {items.length <= 0 ? (
              <EmptyData className="text-center mt-12" />
            ) : (
              <>
                <ul className="mt-2 p-0">{items.map(mapFunction)}</ul>

                {params.isTotalVisible && (
                  <h5 className="text-right border-top border-dark-75 w-75 font-weight-bold ml-auto px-4 pt-4">
                    {toPesoAmount(
                      items.reduce((total, cur) => total + cur.sales, 0),
                    )}
                  </h5>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export const CardListByReferrer = ({
  params = {},
  header = "",
  mapFunction,
  className = "",
}) => {
  const reqObj = {
    url: "/dashboard/sales-by-referrer/type",
    method: "post",
    body: params,
  };

  const { data, isLoading } = useFetch(reqObj, {});
  const items = data || [];

  return (
    <>
      {isLoading || data === null ? (
        <PacmanWrapper />
      ) : (
        <div className={`analytics-card-list h-100 font-size-md ${className}`}>
          {header && <h4 className="pt-8 px-8">{header}</h4>}

          <div className="card-body">
            {items.length <= 0 ? (
              <EmptyData className="text-center mt-12" />
            ) : (
              <>
                <ul className="mt-2 p-0">{groupData(data).map(mapFunction)}</ul>

                {params.isTotalVisible && (
                  <h5 className="text-right border-top border-dark-75 w-75 font-weight-bold ml-auto px-4 pt-4">
                    {toPesoAmount(
                      items.reduce((total, cur) => total + cur.sales, 0),
                    )}
                  </h5>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );

  function groupData(data) {
    if (!data) return [];

    const groupMap = new Map();

    for (const item of data) {
      let groupKey;
      if (isNil(item._id)) {
        groupKey = "pickup.ph";
      } else {
        if (item._id.includes("fbclid")) {
          groupKey = "https://facebook.com";
        } else if (
          item._id.includes("pickup.ph") ||
          item._id === "" ||
          item._id === "null"
        ) {
          groupKey = "pickup.ph";
        } else {
          groupMap.set(item, item);
          continue;
        }
      }

      if (groupMap.has(groupKey)) {
        const existing = groupMap.get(groupKey);
        existing.sales += item.sales;
        existing.instance += item.instance;
      } else {
        groupMap.set(groupKey, {
          _id: groupKey,
          sales: item.sales,
          instance: item.instance,
        });
      }
    }

    // Convert the Map back to an array and sort by sales in descending order
    const sortedData = Array.from(groupMap.values()).sort(
      (a, b) => b.sales - a.sales,
    );
    return sortedData;
  }
};

export default CardList;
