import { useState, useEffect } from "react";
import { useHistory } from "react-router";

const AnalyticsRedirect = (user = {}, mode = "") => {
  const { iam_role = "", brand_urls = [], stores = [] } = user;
  const [value, setValue] = useState(iam_role);
  const history = useHistory();
  const webview_url = mode === "webview" ? `/${mode}/` : "";
  useEffect(() => {
    if (iam_role === "super_admin") {
      history.push(`${webview_url}/admin/analytics`);
    }
    if (iam_role === "brand_admin" && brand_urls.length === 1) {
      history.push(`${webview_url}brands/${brand_urls[0]}.pickup.ph/analytics`);
    }
    if (iam_role === "store_admin" && stores.length === 1) {
      history.push(
        `${webview_url}brands/${brand_urls[0]}.pickup.ph/stores/${stores[0]}/analytics`,
      );
    }
    if (iam_role === "console_user") {
      history.push(
        `${webview_url}brands/${brand_urls[0]}.pickup.ph/stores/${stores[0]}/analytics`,
      );
    }
  }, [value]);

  return value;
};

export default AnalyticsRedirect;
