import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
// import { FormItem, Label } from "../../components/Base";
import { Button, Col, Form, Row } from "react-bootstrap";
// import { GetDataFromEvent, GetSubmitClassList, Humanize } from "../../helpers";
import { checkIfCanUpdateField, GetSubmitClassList } from "../../helpers";
import {
  Block,
  InfoAlertHelper,
  InfoTooltip,
  Label,
} from "../../components/Base";
import { useSelector } from "react-redux";
import ht from "../../helpertexts";
// import { PAGES_PRIVILEGES } from "../../constants";

function DisplayText({ mode, type }) {
  return (
    <>
      This user is {mode ? <></> : <>not</>} receiving {type} emails.
    </>
  );
}

export default function UserEmailNotificationsForm(props) {
  const { user } = useSelector((state) => state.auth);
  return <UserEmailNotificationsFormClass {...props} currentUser={user} />;
}

class UserEmailNotificationsFormClass extends ProSourceForm {
  constructor(props) {
    super(props);

    const { user = {} } = this.props,
      {
        order_email_notification = false,
        refund_email_notification = false,
        active = false,
      } = user;

    this.state = {
      values: {
        order_email_notification,
        refund_email_notification,
        active,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      checkingEmail: false,
    };
  }

  onChange = (event) => {
    const target = event.target,
      checked = target.checked,
      name = target.name; //, value = target.value;

    let { values = {} } = this.state,
      state = {};

    if (
      name === "order_email_notification" ||
      name === "refund_email_notification"
    ) {
      values[name] = checked;
      state.values = values;
    }
    this.setState(state);
  };

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    // const actions = { handleFeedbackError: this.handleFeedbackError, isTouched: this.isTouched }, inputActions = { onChange: this.handleOnChange, onBlur: this.handleOnBlur }, { submitButton = "Save Changes", showBackButton = false } = this.props, { checkingEmail = false, values = {} } = this.state, { privileges = [] } = values;
    const inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      },
      { submitButton = "Save Changes", showBackButton = false } = this.props,
      { checkingEmail = false, values = {} } = this.state;

    const isClientAdmin = this.props.hasOwnProperty("user")
        ? this.props.user.hasOwnProperty("iam_role") &&
          this.props.user.iam_role === "group_admin"
        : false,
      isActive = values.active;

    return checkIfCanUpdateField(this.props.user, this.props.currentUser) ? (
      <Form onSubmit={this.handleOnSubmit}>
        <InfoAlertHelper
          text={
            <>
              You can manage the email notifications received by the user here.
            </>
          }
        />
        <Row>
          <Col>
            <div>
              <div className="form-group row mv-1rem">
                <label className="col-5 col-sm-5 col-xl-5 col-form-label font-weight-bolder text-dark mr-2">
                  Order/Reservation Emails:
                  {InfoTooltip(ht.users.order_email_notification)}
                </label>
                <div className="col-4">
                  <span className="switch switch-outline switch-icon switch-primary">
                    <label>
                      <input
                        type="checkbox"
                        name="order_email_notification"
                        checked={
                          this.state.values.order_email_notification
                            ? "checked"
                            : null
                        }
                        disabled={!isActive ? "disabled" : ""}
                        {...inputActions}
                      />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div>
              <div className="form-group row mv-1rem">
                <label className="col-5 col-sm-5 col-xl-5 col-form-label font-weight-bolder text-dark mr-2">
                  Refund Emails:
                  {InfoTooltip(ht.users.refund_email_notification)}
                </label>
                <div className="col-4">
                  <span className="switch switch-outline switch-icon switch-primary">
                    <label>
                      <input
                        type="checkbox"
                        name="refund_email_notification"
                        checked={
                          this.state.values.refund_email_notification
                            ? "checked"
                            : null
                        }
                        disabled={!isActive ? "disabled" : ""}
                        {...inputActions}
                      />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div className="d-flex flex-row justify-content-space-between">
          <div>
            {showBackButton ? (
              <Button
                type="button"
                variant="secondary"
                onClick={this.props.onClickBackBtn}
                className={GetSubmitClassList(
                  this.state.isSubmitting,
                  `btn btn-primary font-weight-bold px-9 py-4 my-3`,
                )}
              >
                <span>Back</span>
              </Button>
            ) : (
              <></>
            )}
          </div>
          <div className="text-right">
            {this.props.showDeleteButton && !isClientAdmin ? (
              <button
                type="button"
                disabled={this.props.deleteLoading}
                className={GetSubmitClassList(
                  this.props.deleteLoading,
                  "btn btn-danger font-weight-bold px-9 py-4 my-3 mr-2",
                )}
                onClick={this.props.handleOnClickDeleteBtn}
              >
                <span>Mark as {isActive ? <>inactive</> : <>active</>}</span>
              </button>
            ) : (
              <></>
            )}
            {isActive ? (
              <button
                type="submit"
                disabled={this.state.isSubmitting || checkingEmail}
                className={GetSubmitClassList(
                  this.state.isSubmitting,
                  `btn btn-primary font-weight-bold px-9 py-4 my-3`,
                )}
              >
                <span>{submitButton}</span>
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Form>
    ) : (
      <>
        <Row>
          <Col>
            <Block
              content={
                <>
                  <Label text="Order/Reservation Emails" />
                  <div>
                    <DisplayText
                      mode={this.state.values.order_email_notification}
                      type="order"
                    />
                  </div>
                </>
              }
            />
            <Block
              content={
                <>
                  <Label text="Refund Emails" />
                  <div>
                    <DisplayText
                      mode={this.state.values.refund_email_notification}
                      type="refund"
                    />
                  </div>
                </>
              }
            />
          </Col>
        </Row>
      </>
    );
  }
}
