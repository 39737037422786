import React from "react";
import { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

import APIComponent from "../../components/API";
import { FormItem } from "../../components/Base";
import core from "../../vendors/core-api";

export default function DisplayBrandsDropdown(props) {
  if (props.iam_role === "brand_admin") {
    return <DisplayBrandsDropdownAPI {...props} />;
  } else {
    return <></>;
  }
}

class DisplayBrandsDropdownAPI extends APIComponent {
  constructor(props) {
    super(props);

    this.api = core("pu").get();
    this.data_url = "/settings/get-brand-options";
  }

  async getData() {
    if (this.data_url !== "") {
      const { user = {} } = this.props,
        { iam_role = "", brands = [] } = user,
        showPreselected = iam_role === "brand_admin";

      this._isMounted && this.setState({ data_status: "fetching" });
      this.api &&
        this.api
          .get(this.data_url)
          .then(({ data }) => {
            const { data: responseData } = data;

            this._isMounted &&
              this.setState({
                data: responseData,
                data_status: "fetched",
                preselectedBrands: showPreselected
                  ? responseData.filter(({ _id = "" }) => {
                      return brands.includes(_id);
                    })
                  : [],
              });
            this.handleOnDataFetched();

            if (this.use_display_data) {
              this.setDisplayData();
            }
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });
    } else {
      this.afterComponentDidMount();
    }
  }

  render() {
    const { data_status = "", data = [], preselectedBrands = [] } = this.state,
      isLoading = data_status !== "fetched";
    return (
      <>
        {isLoading ? (
          <></>
        ) : (
          <FormItem
            customFormControl
            label={"Brands:"}
            className={"form-control-solid"}
            actions={this.props.actions}
            showRequired={true}
            name="brands"
            multiple
            custom={
              <>
                <Choices
                  data={data}
                  preselectedBrands={preselectedBrands}
                  {...this.props}
                />
              </>
            }
          />
        )}
      </>
    );
  }
}

const Choices = ({ data, action, preselectedBrands, onBlur }) => {
  const [multiSelections, setMultiSelections] = useState(preselectedBrands);

  return (
    <Typeahead
      placeholder={"Brands"}
      options={data}
      onChange={(selected) => {
        setMultiSelections(selected);
        action(selected);
      }}
      selected={multiSelections}
      multiple={true}
      labelKey="name"
      onBlur={() => {
        onBlur(multiSelections);
      }}
    />
  );
};
