import React, {useMemo} from "react";
import objectPath from "object-path";
// LayoutContext
import {useHtmlClassService} from "../_core/MetronicLayout";
// Import Layout components
import {Header} from "./header/Header";
import {HeaderMobile} from "./header-mobile/HeaderMobile";
import {Aside} from "./aside/Aside";
import {Footer} from "./footer/Footer";
import {LayoutInit} from "./LayoutInit";
import {SubHeader} from "./subheader/SubHeader";
import {QuickUser} from "./extras/offcanvas/QuickUser";
import {ScrollTop} from "./extras/ScrollTop";
// import {StickyToolbar} from "./extras/StickyToolbar";
import { useParams } from "react-router-dom";

export function Layout(props) {
    // const { children, type = "admin", logo, kt_content_style = {}, container_style = {}, showFooter = true, onClickCart, cart_counter = 0, showMenuItems = true, preview_token = "", finishedSetup = false, hideHeader = false, website_theme = {}, contentOnly = false, privileges = [], activeMenu = "", store_url = "" } = props;
    const { children, type = "admin", logo, kt_content_style = {}, container_style = {}, showFooter = true, onClickCart, cart_counter = 0, showMenuItems = true, preview_token = "", finishedSetup = false, hideHeader = false, website_theme = {}, contentOnly = false, privileges = [], activeMenu = "", isPageUnavailable = false } = props;
    const { store_url } = useParams();

    const propsToInherit = { type, logo, onClickCart, cart_counter, showMenuItems, preview_token, website_theme, activeMenu, store_url };

    const uiService = useHtmlClassService();
    // Layout settings (cssClasses/cssAttributes)
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            selfLayout: objectPath.get(uiService.config, "self.layout"),
            asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
            subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
            desktopHeaderDisplay: objectPath.get(
                uiService.config,
                "header.self.fixed.desktop"
            ),
            contentCssClasses: uiService.getClasses("content", true),
            contentContainerClasses: uiService.getClasses("content_container", true),
            contentExtended: objectPath.get(uiService.config, "content.extended")
        };
    }, [uiService]);

    return (
        <>
            {
                (type === "admin") ? <>
                    {
                        contentOnly ? <>
                            {/*begin::Content*/}
                            <div
                                id="kt_content"
                                className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
                            >
                                {layoutProps.subheaderDisplay && <SubHeader/>}
                                {/*begin::Entry*/}
                                {!layoutProps.contentExtended && (
                                    <div className="d-flex flex-column-fluid">
                                        {/*begin::Container*/}
                                        <div className={layoutProps.contentContainerClasses}>
                                            {children}
                                        </div>
                                        {/*end::Container*/}
                                    </div>
                                )}

                                {layoutProps.contentExtended && {children}}
                                {/*end::Entry*/}
                            </div>
                            {/*end::Content*/}
                        </> : <>
                            {/*begin::Main*/}
                            <HeaderMobile aside={false}/>
                            <div className="d-flex flex-column flex-root">
                                {/*begin::Page*/}
                                <div className="d-flex flex-row flex-column-fluid page">
                                    {layoutProps.asideDisplay && (<Aside finishedSetup={finishedSetup} privileges={privileges} activeMenu={activeMenu}/>)}
                                    {/*begin::Wrapper*/}
                                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                                        <Header/>
                                        {/*begin::Content*/}
                                        <div
                                            id="kt_content"
                                            className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
                                        >
                                            {layoutProps.subheaderDisplay && <SubHeader/>}
                                            {/*begin::Entry*/}
                                            {!layoutProps.contentExtended && (
                                                <div className="d-flex flex-column-fluid">
                                                    {/*begin::Container*/}
                                                    <div className={layoutProps.contentContainerClasses}>
                                                        {children}
                                                    </div>
                                                    {/*end::Container*/}
                                                </div>
                                            )}

                                            {layoutProps.contentExtended && {children}}
                                            {/*end::Entry*/}
                                        </div>
                                        {/*end::Content*/}
                                        {/* <Footer/> */}
                                    </div>
                                    {/*end::Wrapper*/}
                                </div>
                                {/*end::Page*/}
                            </div>
                            <QuickUser/>
                            <ScrollTop/>
                            {/* <StickyToolbar/> */}
                            {/*end::Main*/}
                            <LayoutInit />
                        </>
                    }

                </> : <></>
            }

            {
                (type === "branded-website") ? <>
                    {/*begin::Main*/}
                    { !hideHeader ? <HeaderMobile aside={false} {...propsToInherit} /> : <></> }
                    <div className="d-flex flex-column flex-root bw cover">
                        {/*begin::Page*/}
                        <div className="d-flex flex-row flex-column-fluid page">
                            {/* {layoutProps.asideDisplay && (<Aside/>)} */}
                            <Aside displayMobileOnly={true} {...propsToInherit} type={type}/>
                            {/*begin::Wrapper*/}
                            {!isPageUnavailable && ( <div className={`d-flex flex-column flex-row-fluid wrapper${hideHeader ? ' no-header': ''}`} id="kt_wrapper">
                                { !hideHeader ? <Header {...propsToInherit}/> : <></> }
                                {/*begin::Content*/}
                                <div id="kt_content" className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`} style={kt_content_style}>
                                    {layoutProps.subheaderDisplay && <SubHeader/>}
                                    {/*begin::Entry*/}
                                    {!layoutProps.contentExtended && (
                                        <div className="d-flex flex-column-fluid">
                                            {/*begin::Container*/}
                                            <div className={layoutProps.contentContainerClasses} style={container_style}>
                                                {children}
                                            </div>
                                            {/*end::Container*/}
                                        </div>
                                    )}

                                    {layoutProps.contentExtended && {children}}
                                    {/*end::Entry*/}
                                </div>
                                {/*end::Content*/}
                                { showFooter && <Footer/> }
                            </div> )}
                            {/*end::Wrapper*/}
                        </div>
                        {/*end::Page*/}
                    </div>
                    <ScrollTop/>
                    {/* <StickyToolbar/> */}
                    {/*end::Main*/}
                    <LayoutInit />
                </> : <></>
            }
        </>
    );
}
