import React from "react";
import { Modal, Form } from "react-bootstrap";
import { FormItem } from "../../../components/Base";
import ProSourceForm from "../../../components/ProSourceForm";

export default class AddNotesModal extends ProSourceForm {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        notes: "",
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };
  }

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const { show = false, onHide } = this.props;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };
    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header className="d-flex flex-column" closeButton>
          <Modal.Title>Add Notes</Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body className="px-5 py-2">
            <FormItem
              className="m-0"
              name={"notes"}
              inputProps={{
                name: "notes",
                placeholder: "Write a message here",
                value: this.state.values["notes"],
                rows: 3,
              }}
              inputActions={inputActions}
              actions={actions}
              type={"textarea"}
              normal
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex flex-row justify-content-center w-100">
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate w-100 mx-5"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                className="btn btn-primary btn-elevate w-100 mx-5"
                disabled={this.state.values.notes === ""}
              >
                Submit
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
