import React, { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function HelpImage({ src, alt }) {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && (
        <SkeletonTheme color="#cccccc" highlightColor="#f2f2f2">
          <Skeleton width={"50%"} height={210} className="skel-image1" />
        </SkeletonTheme>
      )}
      <LazyLoadImage
        className="help-image1"
        style={
          loading
            ? { position: "absolute", border: "0px", margin: "0 25%" }
            : null
        }
        src={src}
        alt={alt}
        onError={(ev) => {
          ev.target.onError = null;
        }}
        afterLoad={() => setLoading(false)}
      />
    </>
  );
}
