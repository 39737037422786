// import React, { Component } from "react";

import { BasicComponent } from "../Base";

class Timeline extends BasicComponent {
  constructor(props) {
    super(props);
    this.state = { defaultClass: "timeline" };
  }
}

class Sep extends BasicComponent {
  constructor(props) {
    super(props);
    this.state = { defaultClass: "timeline-sep" };
  }
}

class Item extends BasicComponent {
  constructor(props) {
    super(props);
    this.state = { defaultClass: "timeline-item" };
  }
}

class Label extends BasicComponent {
  constructor(props) {
    super(props);
    this.state = { defaultClass: "timeline-label" };
  }
}

class Badge extends BasicComponent {
  constructor(props) {
    super(props);
    this.state = { defaultClass: "timeline-badge" };
  }
}

class Content extends BasicComponent {
  constructor(props) {
    super(props);
    this.state = { defaultClass: "timeline-content" };
  }
}

Timeline.Sep = Sep;
Timeline.Item = Item;
Timeline.Label = Label;
Timeline.Badge = Badge;
Timeline.Content = Content;

export default Timeline;
