import React, { Component } from "react";
import { Card, Row, Col } from "react-bootstrap";
import {
  AnimateDiv,
  HeaderText,
  InfoAlertHelper,
  DFlex,
} from "../../components/Base";
import { useSelector } from "react-redux";
import {
  isObjectEmpty,
  filterObj,
  generatePickupPhLink,
  hashifyString,
} from "../../helpers";
import TitleHelmet from "../../partials/TitleHelmet";
import core from "../../vendors/core-api";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

import * as auth from "../../modules/Auth/_redux/authRedux";
import { connect } from "react-redux";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PRIMARY_COLOR } from "../../constants";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import { Redirect } from "react-router-dom";

import HelperText from "../../helpertexts";
import CardImageBanner from "../../components/widgets/CardImageBanner";
import BrandForm from "../../partials/forms/BrandForm";
import StoreForm from "../../partials/forms/StoreForm";
import { ItemsComponent } from "./PerStoreItems";
import { getUserByToken } from "../../modules/Auth/_redux/authCrud";
import { isEmpty } from "lodash";

const BRAND_KEY = "PICKUP_BRAND",
  STORE_KEY = "PICKUP_STORE",
  ITEM_KEY = "PICKUP_ADDED_ITEM";

const MySwal = withReactContent(Swal);

function PageFunc(props) {
  const { user } = useSelector((state) => state.auth);
  return <Page user={user} {...props} />;
}

class Page extends Component {
  constructor(props) {
    super(props);

    let brand = {},
      store = {},
      items = [],
      { user = {} } = this.props;
    if (user.brands.length > 0) {
      brand = user.brands[0];
    }

    if (user.stores.length > 0) {
      store = user.stores[0];

      if (store.menu) {
        items = store.menu;
      }
    }

    if (isObjectEmpty(brand) || typeof brand === "string") {
      const brand_str = localStorage.getItem(BRAND_KEY);
      brand = !isEmpty(brand_str) ? JSON.parse(brand_str) : {};
    }
    if (isObjectEmpty(store) || typeof store === "string") {
      const store_str = localStorage.getItem(STORE_KEY);
      store = !isEmpty(store_str) ? JSON.parse(store_str) : {};
    }

    if (items.length === 0) {
      const item_str = localStorage.getItem(ITEM_KEY);
      items = !isEmpty(item_str) ? JSON.parse(item_str)["items"] : [];
    }

    let redirectTo = ""; // TEST ? "" : ((!isObjectEmpty(brand) && !isObjectEmpty(store) && !(items.length === 0)) ? "/brands/" + brand._id : "");

    const hasBrand = !isObjectEmpty(brand),
      hasStore = !isObjectEmpty(store);

    this.state = {
      showSplashScreen: redirectTo !== "",
      redirectTo: redirectTo,

      showGettingStartedBanner: !hasBrand && !hasStore,
      showGettingStartedMenu: hasBrand || hasStore,
      showBrandForm: false,
      showStoreForm: false,
      showAddItemForm: false,
      mountFile: true,
      brand,
      store,
      items,
      data: {},
    };

    this.onSubmitBrand = this.onSubmitBrand.bind(this);
    this.onSubmitStore = this.onSubmitStore.bind(this);
    this.onSubmitItem = this.onSubmitItem.bind(this);

    this.onClickUploadLogo = this.onClickUploadLogo.bind(this);
    this.onClickBacktoHome = this.onClickBacktoHome.bind(this);
    this.onClickShowBrandForm = this.onClickShowBrandForm.bind(this);
    this.onClickShowStoreForm = this.onClickShowStoreForm.bind(this);
    this.onClickShowAddItemForm = this.onClickShowAddItemForm.bind(this);
    this.onClickFinishSetup = this.onClickFinishSetup.bind(this);
    this.onClickItemDelete = this.onClickItemDelete.bind(this);
    this.onClickGetStarted = this.onClickGetStarted.bind(this);

    this.onBlurBrandName = this.onBlurBrandName.bind(this);
    this.onBlurBrandURL = this.onBlurBrandURL.bind(this);

    this.fileUploadRef = React.createRef();

    this.handleError = this.handleError.bind(this);

    this.getHelperText = this.getHelperText.bind(this);
    this.getData = this.getData.bind(this);

    this.uploadLogo = this.uploadLogo.bind(this);
    this.uploadItemPhoto = this.uploadItemPhoto.bind(this);

    this.refreshUserRedux = this.refreshUserRedux.bind(this);

    this.setBusinesshandle = this.setBusinesshandle.bind(
      this.setBusinesshandle,
    );
    this.attachToBrandAndStore = this.attachToBrandAndStore.bind(this);

    this.api = core("pu").get();
    this.fileapi = core("puf").get();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    this.getData();
  }

  async getData() {
    const { brand = {}, store = {} } = this.state;
    if (!isObjectEmpty(store)) {
      let url = `/brand/setup-data?brand_id=${brand._id}&store_id=${store._id}`;

      this.api &&
        this.api
          .get(url)
          .then(({ data }) => {
            data.items = data.store.menu;
            this.setState(data);
          })
          .catch((error) => {
            this.handleError(error);
          });
    }
  }

  handleError = (error) => {
    if (error === "size-too-much") {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `File is too big! Maximum upload size is 200KB.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else if (error === "unsupported-file-extension") {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `File type unsupported. Please upload PNG, JPG and JPEG files only.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else if (error && error.hasOwnProperty("response")) {
      const { data = {} } = error.response,
        { message = {} } = data;

      if (message.error === "DuplicateItemError") {
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: `This brand url is now taken. Please use another one.`,
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      } else {
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: `Something went wrong`,
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      }
    } else {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `Something went wrong`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };

  onSubmitBrand = (values, setSubmitting) => {
    const { brand = {} } = this.state;
    if (!isObjectEmpty(brand)) {
      // Update brand
      this.api
        .put({
          url: "/brand/" + brand._id,
          data: values,
        })
        .then(({ data }) => {
          setSubmitting(false);
          this.setState({ brand: data });
          localStorage.setItem(BRAND_KEY, JSON.stringify(data));
          this.refreshUserRedux();

          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Next Step",
            showCancelButton: true,
            cancelButtonText: "Back to Home",
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              this.onClickShowStoreForm();
            } else {
              this.onClickBacktoHome();
            }
          });
        })
        .catch(() => {
          setSubmitting(false);
          this.handleError();
        });
    } else {
      // Create brand
      const {
        user: {
          client: { _id = "" },
        },
      } = this.props;

      values.client = _id;

      this.api
        .post({
          url: "/brand",
          data: values,
        })
        .then(({ data }) => {
          setSubmitting(false);
          this.setState({ brand: data });
          localStorage.setItem(BRAND_KEY, JSON.stringify(data));
          this.refreshUserRedux();

          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Next Step",
            showCancelButton: true,
            cancelButtonText: "Back to Home",
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              this.onClickShowStoreForm();
            } else {
              this.onClickBacktoHome();
            }
          });
        })
        .catch((error) => {
          setSubmitting(false);
          this.handleError(error);
        });
    }
  };

  onSubmitStore = (values, setSubmitting) => {
    const {
      brand: { _id = "" },
      store = {},
    } = this.state;

    if (!isObjectEmpty(store)) {
      // Update store
      this.api
        .put({
          url: "/store/" + store._id,
          data: values,
        })
        .then(({ data }) => {
          setSubmitting(false);
          this.setState({ store: data });
          localStorage.setItem(STORE_KEY, JSON.stringify(data));
          this.refreshUserRedux();

          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Next Step",
            showCancelButton: true,
            cancelButtonText: "Back to Home",
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              this.onClickShowAddItemForm();
            } else {
              this.onClickBacktoHome();
            }
          });
        })
        .catch(() => {
          setSubmitting(false);
          this.handleError();
        });
    } else {
      // Create store
      values.brand = _id;
      values.menu = [];

      this.api
        .post({
          url: "/brand/new-store",
          data: values,
        })
        .then(({ data }) => {
          setSubmitting(false);
          this.setState({ store: data });
          localStorage.setItem(STORE_KEY, JSON.stringify(data));
          this.refreshUserRedux();

          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Next Step",
            showCancelButton: true,
            cancelButtonText: "Back to Home",
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              this.onClickShowAddItemForm();
            } else {
              this.onClickBacktoHome();
            }
          });
        })
        .catch(() => {
          setSubmitting(false);
          this.handleError();
        });
    }
  };

  onSubmitItem = (values, setSubmitting, afterSubmit) => {
    const { brand = {}, store = {} } = this.state,
      { _id = "" } = values;

    if (_id !== "") {
      // Update item
      values.brand = brand._id;
      values.store_id = store._id;

      this.api
        .put({
          url: "/item/" + _id,
          data: filterObj(values, ["_id"]),
        })
        .then(() => {
          this.refreshUserRedux();
          afterSubmit();
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully updated an item!",
            timer: 1000,
          });

          setSubmitting(false);

          this.getData();
        })
        .catch(() => {
          setSubmitting(false);
          this.handleError();
        });
    } else {
      // Create item
      values.brand = brand._id;
      values.store_id = store._id;

      this.api
        .post({
          url: "/item",
          data: filterObj(values, ["_id"]),
        })
        .then(({ data }) => {
          setSubmitting(false);

          let { items = [] } = this.state;
          items.push(data);

          this.setState({ items });
          localStorage.setItem(ITEM_KEY, JSON.stringify({ items }));
          this.refreshUserRedux();
          afterSubmit();

          this.getData();

          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully added an item!",
            timer: 1000,
          });
        })
        .catch(() => {
          setSubmitting(false);
          this.handleError();
        });
    }
  };

  onClickItemDelete = (selectedItem, afterDelete) => {
    const { store = {} } = this.state;

    MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to delete ${selectedItem.item_id.name}?`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.api
          .post({
            url: "/store/" + store._id + "/delete-menu-item",
            data: {
              _id: selectedItem.item_id._id,
              deleteDb: true,
            },
          })
          .then(({ data }) => {
            if (data.result === "ok") {
              // this.getData();
              // this.setState({ showEditItemModal: false });

              MySwal.fire({
                icon: "success",
                title: "Success!",
                text: `${selectedItem.item_id.name} successfully removed from this menu.`,
                showCancelButton: false,
                timer: 1000,
              }).then(() => {
                // this.setState({ selectedItem: {}});
                afterDelete();
              });
            }
          })
          .catch((err) => {
            this.handleError(err);
          });
      }
    });
  };

  onClickUploadLogo = (e) => {
    e.preventDefault();

    this.setState({ mountFile: false });

    setTimeout(() => {
      this.setState({ mountFile: true });
      this.fileUploadRef.current.click();
    }, 50);
  };

  uploadLogo = (file, setFilePath, setSubmitting) => {
    const formData = new FormData();
    formData.append("file", file);

    this.fileapi
      .post({
        url: "/util/upload-to-aws/brands",
        data: formData,
      })
      .then(({ data }) => {
        const { filePath = "" } = data;
        setFilePath(filePath);
      })
      .catch(() => {
        this.handleError();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  uploadItemPhoto = (file, setFilePath, setSubmitting) => {
    const formData = new FormData();
    formData.append("file", file);

    this.fileapi
      .post({
        url: "/util/upload-to-aws/items",
        data: formData,
      })
      .then(({ data }) => {
        const { filePath = "" } = data;
        setFilePath(filePath);
      })
      .catch(() => {
        this.handleError();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  onClickBacktoHome = () => {
    this.setState({
      showGettingStartedMenu: true,
      showBrandForm: false,
      showStoreForm: false,
      showAddItemForm: false,
      showGettingStartedBanner: false,
    });
  };

  onClickShowBrandForm = () => {
    this.setState({
      showGettingStartedMenu: false,
      showBrandForm: true,
      showStoreForm: false,
      showAddItemForm: false,
      showGettingStartedBanner: false,
    });
  };

  onClickShowStoreForm = () => {
    const { brand = {} } = this.state;

    if (!isObjectEmpty(brand))
      this.setState({
        showGettingStartedMenu: false,
        showBrandForm: false,
        showStoreForm: true,
        showAddItemForm: false,
        showGettingStartedBanner: false,
      });
    else {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: "You have to setup your brand first!",
      });
    }
  };

  onClickShowAddItemForm = () => {
    const { store = {} } = this.state;

    if (!isObjectEmpty(store)) {
      this.setState({
        showGettingStartedMenu: false,
        showBrandForm: false,
        showStoreForm: false,
        showAddItemForm: true,
        showGettingStartedBanner: false,
      });
    } else {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: "You have to setup your store first!",
      });
    }
  };

  onClickFinishSetup = () => {
    this.setState({ showSplashScreen: true });

    getUserByToken()
      .then(({ data }) => {
        data.finished_setup = true;
        this.props.fulfillUser(data);
        this.attachToBrandAndStore(data);
        localStorage.setItem(BRAND_KEY, null);
        localStorage.setItem(STORE_KEY, null);
        localStorage.setItem(ITEM_KEY, null);
        setTimeout(() => {
          this.setState({
            redirectTo:
              "/brands/" +
              generatePickupPhLink(this.state.brand.brand_url, false),
          });
        }, 1000);
      })
      .catch((err) => {
        this.handleError(err);
      });
  };

  onClickGetStarted = () => {
    this.onClickBacktoHome();
  };

  attachToBrandAndStore = ({ _id = "" }) => {
    const { brand = {}, store = {} } = this.state;
    this.api
      .put({
        url: `/users/${_id}/initial-attach`,
        data: { brand_id: brand._id, store_id: store._id },
      })
      .then(() => {})
      .catch((err) => {
        this.handleError(err);
      });
  };

  getHelperText = (field) => {
    return HelperText["getting-started"][field];
  };

  onBlurBrandName = (value, callback) => {
    if (value !== "") {
      this.api
        .post({
          url: `brand/is-name-existing`,
          data: { name: value },
        })
        .then(({ data }) => {
          return callback(
            !data.existing,
          ); /* if it's not existing, it's valid */
        })
        .catch((err) => {
          this.handleError();
        });
    }
  };

  onBlurBrandURL = (value, callback) => {
    if (value !== "") {
      this.api
        .post({
          url: `brand/is-url-existing`,
          data: { brand_url: value },
        })
        .then(({ data }) => {
          const { existing = false, invalid = false } = data;
          return callback({ existing, invalid });
        })
        .catch((err) => {
          this.handleError();
        });
    } else {
      return callback({ invalid: true });
    }
  };

  setBusinesshandle = () => {
    const { _id = "", signup_form = {} } = this.props.user.client;
    const parsed_signup_form = JSON.parse(signup_form);

    this.api &&
      this.api
        .put({
          url: `/client/${_id}/business-handle`,
          data: {
            signup_form: {
              ...parsed_signup_form,
              business_handle:
                hashifyString(parsed_signup_form.business_name) + "-pickup",
            },
          },
        })
        .then(({ data }) => console.log(">>> data", data))
        .catch((err) => this.handleError(err));
  };

  refreshUserRedux = () => {
    getUserByToken()
      .then(({ data }) => {
        this.props.fulfillUser(data);
      })
      .catch((err) => {
        this.handleError(err);
      });
  };

  render() {
    const actions = {
      onClickBacktoHome: this.onClickBacktoHome,
      onClickShowBrandForm: this.onClickShowBrandForm,
      onClickShowStoreForm: this.onClickShowStoreForm,
      onClickShowAddItemForm: this.onClickShowAddItemForm,
      onClickFinishSetup: this.onClickFinishSetup,
      onClickGetStarted: this.onClickGetStarted,
      onError: this.handleError,
      getHelperText: this.getHelperText,
      onBlurBrandName: this.onBlurBrandName,
      onBlurBrandURL: this.onBlurBrandURL,
    };

    return (
      <>
        <TitleHelmet title={"Getting Started"} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        {this.state.showGettingStartedBanner ? (
          <GettingStartedBanner actions={actions} {...this.props} />
        ) : (
          <></>
        )}

        {this.state.showGettingStartedMenu ? (
          <GettingStartedMenu2
            actions={actions}
            state={this.state}
            {...this.props}
          />
        ) : (
          <></>
        )}

        {this.state.showBrandForm ? (
          <>
            <BrandFormDiv
              fileUploadRef={this.fileUploadRef}
              mountFile={this.state.mountFile}
              onSubmit={this.onSubmitBrand}
              onClickUploadLogo={this.onClickUploadLogo}
              uploadLogo={this.uploadLogo}
              brand={this.state.brand}
              actions={actions}
              {...this.props}
            />
          </>
        ) : (
          <></>
        )}

        {this.state.showStoreForm ? (
          <StoreFormDiv
            onSubmit={this.onSubmitStore}
            brand={this.state.brand}
            store={this.state.store}
            actions={actions}
            {...this.props}
          />
        ) : (
          <></>
        )}

        {this.state.showAddItemForm ? (
          <AddItemsForm
            onSubmit={this.onSubmitItem}
            refreshUserRedux={this.refreshUserRedux}
            uploadItemPhoto={this.uploadItemPhoto}
            onClickItemDelete={this.onClickItemDelete}
            brand={this.state.brand}
            store={this.state.store}
            items={this.state.items}
            actions={actions}
            {...this.props}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
}

class GettingStartedBanner extends Component {
  render() {
    const {
      user: { first_name = "" },
    } = this.props;

    return (
      <>
        <AnimateDiv>
          <DFlex className="flex-row-fluid">
            <DFlex className="flex-center flex-row-fluid">
              <CardImageBanner
                image={
                  <>
                    <img
                      src={toAbsoluteUrl("/media/banners/banner-2.jpg")}
                      alt="Getting Started"
                    />
                  </>
                }
                text={
                  <>
                    <div className="card-img-banner-body-text-title text-center">
                      Hello, {first_name}! <br />
                      Get started with telling us about your brand!
                    </div>
                  </>
                }
                actions={
                  <>
                    <button
                      type="button"
                      onClick={this.props.actions.onClickGetStarted}
                      className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    >
                      <span>Get started</span>
                    </button>
                  </>
                }
              />
            </DFlex>
          </DFlex>
        </AnimateDiv>
      </>
    );
  }
}

class GettingStartedMenu2 extends Component {
  render() {
    const items = [
      {
        title: "Your Brand",
        subtitle: "Tell something about your brand",
      },
      {
        title: "Your Store",
        subtitle: "Tag your store location and information",
      },
      {
        title: "Add your items",
        subtitle: "Setup your items",
      },
    ];

    const { brand = {}, store = {} } = this.props.state,
      hasBrand = !isObjectEmpty(brand),
      hasStore = !isObjectEmpty(store);

    const btnText = !hasBrand && !hasStore ? "Proceed" : "Continue";

    let btnOnClick = this.props.actions.onClickShowBrandForm();

    if (hasBrand) btnOnClick = this.props.actions.onClickShowStoreForm;
    if (hasStore) btnOnClick = this.props.actions.onClickShowAddItemForm;

    return (
      <>
        <AnimateDiv>
          <Card>
            <Card.Body>
              <Row>
                <Col sm={6}>
                  {items.map((item, index) => (
                    <div className="toc" key={index}>
                      <div className="toc-title">{item.title}</div>
                      <div className="toc-subtitle">{item.subtitle}</div>
                    </div>
                  ))}
                </Col>
                <Col sm={6}>
                  <div className="text-right">
                    <img
                      src={toAbsoluteUrl(
                        "/media/banners/pickup-banner-large2.png",
                      )}
                      alt="Getting Started"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    type="button"
                    className="btn btn-primary btn-lg font-weight-bold"
                    onClick={btnOnClick}
                  >
                    {btnText}
                    <span className="svg-icon svg-icon-lg ml-2">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Arrow-right.svg",
                        )}
                        title={btnText}
                      />
                    </span>
                  </button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </AnimateDiv>
      </>
    );
  }
}

const BackLinkDiv = (props) => (
  <div>
    <button type="button" className="btn btn-link px-0" onClick={props.onClick}>
      <span className="svg-icon svg-icon-lg svg-icon-primary">
        <SVG
          src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")}
          title={"Back to home"}
        />
      </span>
      Back
    </button>
  </div>
);

class BrandFormDiv extends Component {
  render() {
    return (
      <>
        <AnimateDiv>
          <BackLinkDiv onClick={this.props.actions.onClickBacktoHome} />

          <HeaderText
            title={"Your brand"}
            subtitle={"Tell something about your brand"}
          />

          <InfoAlertHelper
            text={
              "Your brand information is displayed on your branded Pickup.ph website."
            }
          />

          <Card>
            <Card.Body>
              <BrandForm
                saveButtonText={
                  <>
                    {`${
                      isObjectEmpty(this.props.brand) ? `Save` : `Update`
                    } and Proceed`}
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Arrow-right.svg",
                        )}
                        title={`${
                          isObjectEmpty(this.props.brand) ? `Save` : `Update`
                        } and Proceed`}
                      />
                    </span>
                  </>
                }
                {...this.props}
              />
            </Card.Body>
          </Card>
        </AnimateDiv>
      </>
    );
  }
}

class StoreFormDiv extends Component {
  render() {
    return (
      <>
        <AnimateDiv>
          <BackLinkDiv onClick={this.props.actions.onClickShowBrandForm} />

          <HeaderText
            title={"Your store"}
            subtitle={<>Tag your store location and information</>}
          />

          <InfoAlertHelper
            text={
              "Your store information is displayed on your branded Pickup.ph website."
            }
          />

          <Card>
            <Card.Body>
              <StoreForm
                saveButtonText={
                  <>
                    {`${
                      isObjectEmpty(this.props.store) ? `Save` : `Update`
                    } and Proceed`}
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Arrow-right.svg",
                        )}
                        title={`${
                          isObjectEmpty(this.props.store) ? `Save` : `Update`
                        } and Proceed`}
                      />
                    </span>
                  </>
                }
                {...this.props}
              />
            </Card.Body>
          </Card>
        </AnimateDiv>
      </>
    );
  }
}

class AddItemsForm extends Component {
  constructor(props) {
    super(props);

    const { items = [] } = this.props;

    this.state = {
      items,
      isSubmitting: false,
      showAddItemForm: false,
      selectedItem: {},
    };
  }

  render() {
    return (
      <AnimateDiv>
        <BackLinkDiv onClick={this.props.actions.onClickShowStoreForm} />

        <HeaderText title={"Add your items"} subtitle={<>Setup your items</>} />
        <ItemsComponent
          id={this.props.store._id}
          showHeaderText={false}
          origin={"getting_started"}
          refreshUserRedux={this.props.refreshUserRedux}
        />
        <div style={{ margin: "1rem 0" }}>
          <button
            type="button"
            className="btn btn-primary btn-lg font-weight-bold"
            onClick={this.props.actions.onClickFinishSetup}
          >
            Finish Setup
            <span className="svg-icon svg-icon-lg ml-2">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Arrow-right.svg",
                )}
                title={"Finish Setup"}
              />
            </span>
          </button>
        </div>
      </AnimateDiv>
    );
  }
}

export default connect(null, auth.actions)(PageFunc);
