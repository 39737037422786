import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import moment from "moment";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { KTUtil } from "../../_metronic/_assets/js/components/util";

export default class FullCalendarDiv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: this.props.events || [],
      calendarTitle: `${moment().format("MMMM")} ${moment().year()}`,
    };
  }

  renderEventContent = (eventInfo) => {
    return (
      <div className="p-1">
        <span>{eventInfo.timeText}</span>
        <span>{eventInfo.event.title}</span>
      </div>
    );
  };

  render() {
    const { customButtonTitle, customButtonClick, subTitle } = this.props;
    const { calendarTitle, events } = this.state;
    this.calendarRef = React.createRef();

    return (
      <Card className="mt-4">
        <Card.Body className="p-5">
          <Row className="mb-10 align-items-center">
            <Col>
              <Button
                variant={"primary"}
                className={"text-uppercase"}
                onClick={() => customButtonClick(new Date())}
              >
                {customButtonTitle}
              </Button>
            </Col>
            <Col style={{ textAlign: "center" }}>
              <div
                className={`font-weight-bold text-uppercase ${
                  KTUtil.isMobileDevice()
                    ? "font-pickup-sm-2"
                    : "font-pickup-lg"
                }`}
              >
                {calendarTitle}
              </div>
              {!KTUtil.isMobileDevice() && subTitle}
            </Col>
            <Col>
              <Row style={{ justifyContent: "flex-end" }} className="mr-1">
                <Button
                  variant={"primary"}
                  className={"mr-1"}
                  onClick={() => {
                    if (this.calendarRef) {
                      const {
                        calendar: { currentData },
                      } = this.calendarRef.current;
                      currentData.calendarApi.prev();
                      this.setState({
                        calendarTitle:
                          currentData.calendarApi.currentData.viewTitle,
                      });
                    }
                  }}
                >
                  <i className="fa fa-angle-left"></i>
                </Button>
                <Button
                  variant={"primary"}
                  onClick={() => {
                    if (this.calendarRef) {
                      const {
                        calendar: { currentData },
                      } = this.calendarRef.current;
                      currentData.calendarApi.next();
                      this.setState({
                        calendarTitle:
                          currentData.calendarApi.currentData.viewTitle,
                      });
                    }
                  }}
                >
                  <i className="fa fa-angle-right"></i>
                </Button>
              </Row>
            </Col>
          </Row>
          <FullCalendar
            {...this.props}
            ref={this.calendarRef}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            weekends={true}
            events={events}
            eventContent={this.renderEventContent}
          />
        </Card.Body>
      </Card>
    );
  }
}
