import React from "react";
import Chart from "react-apexcharts";
import EmptyData from "../../EmptyData";
import { GRAPH_COLORS } from "../../../constants";
import moment from "moment";
import { DB_DATE_FORMAT } from "../../../constants";
import { fetchData } from "../../../helpers";
import PacmanLoading from "../PacmanLoading/PacmanLoading";
import {
  getArrayTopXOthers,
  getDateRange,
  getPageFilterArray,
} from "./GAHelpers";

import { InfoTooltip } from "../../Base";
import GAReferrerListModal from "../../../partials/GAReferrerListModal";
import responsiveChartOption from "./pieChartResponsiveOption.json";

export default class PageViewsByReferrer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
      customOptions: {},
      date: moment().format(DB_DATE_FORMAT),
      chartData: [],
      chartDetails: [],
      isDataLoading: false,
      showDetailModal: false,
    };
  }

  async componentDidMount() {
    const { title, data, selectedFilter, selectedDateRange, brands } =
      this.props;
    let filter = selectedFilter;
    // console.log("pageviewsbydevice filter: ", filter)
    let allBrands = data.allBrands;
    let dateRange = {};
    let brandName = "";
    let allBrandStores = data.allBrandStores;

    let filterArray = [];
    let filterName = "";
    filterName = filter.attr;
    // filterArray = getPageFilterArray(filter, allBrands)

    // pageFilterArray = getPageFilterArray(filter, allBrands)
    this.setState({ isDataLoading: true });
    dateRange = getDateRange(selectedDateRange);

    const dateStart = dateRange.dateStart;
    const dateEnd = dateRange.dateEnd;

    if (brands.length > 0) {
      let brandUrl = `brand/get-brand/${brands[0]}`;
      let brandData = (await fetchData("get", brandUrl)).data;
      // console.log("pageviewsbyreferrer brandData: ", brandData)
      brandName = brandData.name;
    }

    allBrands = allBrandStores ? allBrandStores : allBrands;
    filterArray = getPageFilterArray(filter, allBrands, brandName);

    let apiUrl = "/googleanalytics/page_views/referrer2";
    let apiParams = {
      dateStart: dateStart, //"7daysAgo",
      dateEnd: dateEnd, //"today",
      // pageFilterArray: pageFilterArray,
      filterName: filterName,
      filterArray: filterArray,
    };
    // console.log("pageviewsbyreferrer apiParams: ", apiParams)
    // this.setState({isDataLoading: true})
    let response = (await fetchData("post", apiUrl, apiParams)).data;
    // console.log("pageviewsbyreferrer chartData: ", response)
    let newArr = getArrayTopXOthers(response, 10, "desc");
    // console.log("pageviewsbyreferrer newArr: ", newArr)
    let chartDetails = response;
    let chartData = newArr.newArray;
    let chartSeries = chartData.map(({ value }) => Number(value));
    let chartValueTotal = chartSeries.reduce((a, b) => a + b, 0);
    // console.log("pageviewsbyreferrer chartData: ", chartData)

    // let chartLabels = chartData

    let chartLabels = chartData.map(({ name, value }) => {
      name = name.replace("https://", "");
      name = name.replace("http://", "");
      if (name.length > 25) {
        // return name.slice(25,50) + '... - ' + (value/chartValueTotal*100).toFixed(2) + '%'
        // console.log("pageviewsbyreferrer labels: ", name.slice(0, 15) + '...' + name.slice(-10) + ' - ' + (Number(value)/chartValueTotal*100).toFixed(2) + '%')
        return (
          name.slice(0, 15) +
          "..." +
          name.slice(-10) +
          " - " +
          ((Number(value) / chartValueTotal) * 100).toFixed(2) +
          "%"
        );
      } else {
        // console.log("pageviewsbyreferrer labels: ", name?name:'(none) - ' + (Number(value)/chartValueTotal*100).toFixed(2) + '%')
        return name
          ? name +
              " - " +
              ((Number(value) / chartValueTotal) * 100).toFixed(2) +
              "%"
          : "(none) - " +
              ((Number(value) / chartValueTotal) * 100).toFixed(2) +
              "%";
      }
    });

    this.setState({ isDataLoading: false });

    // console.log("pageviewsbytitle chartLabels: ", chartLabels)
    // console.log("pageviewsbytitle chartSeries: ", chartSeries)
    let options = {
      chart: {
        // MAKE 'id' FIELD A PROP WHEN ANOTHER GRAPH COMPONENT USES THIS IN THE FUTURE
        id: "statPie",
        title: title,
        type: "donut",
        pointer: "help",
        // offsetX: '50%',
        // height: 150,
        events: {
          click: () => {
            this.setState({ showDetailModal: true });
            // console.log("pageviewsbyreferrer chart click state: ", this.state)
          },
          // legendClick: function(chartContext, seriesIndex, config) {
          //     const {series} = config.globals;

          //     let item = false
          //     if(config.globals.deleteItems){
          //         item= config.globals.deleteItems.find(el => el.index === seriesIndex)
          //     }

          //     if(item) {
          //         const {series} = item;
          //         config.globals.series.splice(seriesIndex, 1, series);
          //         config.globals.deleteItems= config.globals.deleteItems.filter(delItem => delItem.index !== seriesIndex);
          //         ApexCharts.exec('statPie', 'updateSeries', config.globals.series);

          //     }else{
          //         let deleteItem = series.splice(seriesIndex ,1, 0);
          //         deleteItem = {index: seriesIndex,series: deleteItem[0]};
          //         config.globals.deleteItems = config.globals.deleteItems? [...config.globals.deleteItems, deleteItem]: [deleteItem];

          //         ApexCharts.exec('statPie', 'updateSeries', config.globals.series);
          //     }

          //     const container = document.querySelector('.item-sold-by-category');
          //     const selectedLegend = container.querySelectorAll('.apexcharts-legend-series');

          //     config.globals.deleteItems.forEach((el) => {
          //         selectedLegend[el.index].classList.toggle('opacity-35')
          //     })
          // }
        },
      },
      tooltip: {
        y: {
          formatter: function (value, { config, globals, seriesIndex }) {
            // console.log("test tooltip")
            return value;
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: chartLabels,
      // labels: chartLabels.map((item) => item.name),
      // labels: chartLabels.map(({name, value}) => {
      //     name = name.replace("https://", "")
      //     name = name.replace("http://", "")
      //     if (name.length > 25) {
      //         return name.slice(0, 15) + '...' + name.slice(-10) + ' - ' + (Number(value)/chartValueTotal*100).toFixed(2) + '%'
      //     } else {
      //         return name?name + ' - ' + (Number(value)/chartValueTotal*100).toFixed(2) + '%':'(none) - ' + (Number(value)/chartValueTotal*100).toFixed(2) + '%'
      //     }
      // }),
      colors: GRAPH_COLORS,
      responsive: responsiveChartOption,
      plotOptions: {
        pie: {
          offsetX: 110,
          donut: {
            size: "70%",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "22px",
                offsetY: -5,
              },
              value: {
                show: true,
                fontSize: "16px",
                color: GRAPH_COLORS, //undefined,
                offsetY: +5,
                // formatter: function (val) {
                //     const total = chartSeries.reduce((total, item) => total+ item, 0)
                //     return ((val/total).toFixed(2)*100 || 0)+'%'
                // }
              },
              total: {
                show: true,
                label: "Total Referrers",
                color: "#ffa500",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
      legend: {
        show: true,
        width: 200,
        position: "left",
        floating: true,
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    };

    let customOptions = {
      colors: ["#50b585", "#795ecc"],
      tooltip: {
        y: {
          formatter: function (value, { config, globals, seriesIndex }) {
            const total = config.series.reduce((total, cur) => total + cur, 0);

            return `${value} orders, (${
              ((value / total) * 100).toFixed(1) || 0
            }%)`;
          },
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "90%",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "22px",
                offsetY: -5,
              },
              value: {
                show: true,
                fontSize: "16px",
                color: undefined,
                offsetY: +5,
              },
              total: {
                show: true,
                label: "Total Page Views",
                color: "#ffa500",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
    };

    this.setState({ chartData, chartDetails, options, customOptions });
    // console.log("componentdidmount state: ", this.state)
  }

  render() {
    const { className = "", header = "", height = 250, children } = this.props;
    const { isDataLoading } = this.state;
    // console.log("pageviewsbydevice state: ", this.state)
    // console.log("pageviewsbydevice props: ", this.props)
    // console.log("pageviewsbydevice chartData: ", this.state.chartData)
    let chartSeries = this.state.chartData.map(({ value }) => Number(value));
    // console.log("chartseries: ", chartSeries)
    return (
      <>
        <div
          className={`card card-custom bg-white ${className} d-flex flex-column align-items-center`}
        >
          {isDataLoading ? (
            <>
              <div className="d-flex justify-content-center align-items-center h-100">
                {/* <h1>Loading...</h1> */}
                <PacmanLoading />
              </div>
            </>
          ) : (
            <>
              {this.state.showDetailModal ? (
                <>
                  <GAReferrerListModal
                    show={this.state.showDetailModal}
                    onHide={() => this.setState({ showDetailModal: false })}
                    data={this.state.chartDetails}
                  />
                </>
              ) : (
                <></>
              )}

              <h4 className="card-spacer align-self-start">
                {this.props.title}{" "}
                <span>
                  {InfoTooltip(
                    "These are the url links the users redirected from. None means there are no referrer links due to several reasons including bookmarks and being the first page to load.",
                  )}
                </span>
              </h4>
              {/* Header */}
              {header && (
                <div className="card-header border-0 bg-primary py-5">
                  <h3 className="card-title font-weight-bolder text-white">
                    {header}
                  </h3>
                </div>
              )}

              {children}
              {/* Body */}
              {/* <div className="card-body align-self-stretch overflow-auto"> */}
              <div className="card-body align-self-stretch">
                {
                  chartSeries.length <= 0 ? (
                    <EmptyData className="text-center mt-12" />
                  ) : (
                    // <div style={{height:250}}>
                    <Chart
                      options={{ ...this.state.options, ...this.customOptions }}
                      series={chartSeries}
                      height={height}
                      type="donut"
                    />
                  )
                  // </div>
                }
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
