import React from "react";
import { Modal, Table } from "react-bootstrap";
import { DB_HOURS_FORMAT, TIME_OPTION_DISPLAY_FORMAT } from "../constants";
import moment from "moment";
function ViewOrdersModal({ show, onHide, preOrders }) {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header>
        <Modal.Title>Upcoming Orders Today</Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onHide}
        >
          <i aria-hidden="true" className="ki ki-close"></i>
        </button>
      </Modal.Header>

      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Order #</th>
              <th>Order Time</th>
            </tr>
          </thead>
          <tbody>
            {preOrders.length > 0 &&
              preOrders.map(({ transaction_number, order_time }, i) => {
                return (
                  <tr key={i}>
                    <td>{transaction_number}</td>
                    <td>
                      {moment(order_time, DB_HOURS_FORMAT).format(
                        TIME_OPTION_DISPLAY_FORMAT,
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}

export default ViewOrdersModal;
