export function isAccessAllowed(user = {}, { brand_url = "", store_id = "" }) {
  let allowed = true;

  const { brand_urls = [], stores = [] } = user;

  if (brand_url !== "") {
    brand_url = brand_url
      .replace(".pickup2.kloud.ai", "")
      .replace(".pickup.kloud.ai", "")
      .replace(".pickup.ph", "");
    allowed = brand_urls.indexOf(brand_url) !== -1;
  }

  if (allowed && store_id !== "") {
    allowed = stores.indexOf(store_id) !== -1;
  }

  return allowed;
}
