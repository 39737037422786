import React from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import { ErrorAlert } from "../../components/Base";
import ProSourceForm from "../../components/ProSourceForm";
import OrderDateDropdown from "./OrderDateDropdown";
import OrderTimeDropDown from "./OrderTimeDropdown";

export default class LimitsModal extends ProSourceForm {
  constructor(props) {
    super(props);

    const {
      order_date = "",
      order_time = "",
      order_type = "",
    } = this.props.cart;

    this.state = {
      values: {
        order_date,
        order_time,
        order_type,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      limitsModalError: <></>,
    };
  }

  onChange = (e) => {
    let name = e.target.name;
    let { values = {} } = this.state;

    if (name === "order_date") {
      values.order_time = "";
      this.setState({ mountOrderTimeDropdown: false, values });
      setTimeout(() => {
        this.setState({
          mountOrderTimeDropdown: true,
          limitsModalError: <></>,
        });
      }, 50);
    }
  };

  onSubmit = (values, setSubmitting) => {
    const { selected_item = {} } = this.props,
      {
        values: { order_date = "" },
      } = this.state;

    // console.log("limit item", selected_item);
    if (selected_item && Object.keys(selected_item).length !== 0) {
      if (selected_item.off_dates.includes(order_date)) {
        this.setState({
          limitsModalError: (
            <span className="text-danger">
              This item is unavailable on your selected date.
            </span>
          ),
        });
      } else {
        this.props.onSubmit(values, setSubmitting);
      }
    } else {
      this.props.onSubmit(values, setSubmitting);
    }
  };

  render() {
    const {
      show = false,
      onHide,
      brand_url = "",
      selected_store = {},
      cart = {},
      text = "",
      showForm = true,
    } = this.props;

    const {
      values = {},
      errors = {},
      mountOrderDateDropdown = true,
      mountOrderTimeDropdown = true,
      limitsModalError,
    } = this.state;

    const inputActions = {
      onChange: this.handleOnChange,
      onBlur: this.handleOnBlur,
    };

    const disabledProceed =
      values.order_time === "" ||
      values.order_date === "" ||
      values.order_type === "dine_in";

    const dineInLimitText =
      "Oops! That item is sold out at this time. Please try our other items.";

    let limitMessage = text;

    if (values.order_type === "dine_in") limitMessage = dineInLimitText;

    // console.log("limitsmodal render state: ", this.state)
    // console.log("limitsmodal render props: ", this.props)
    // console.log("limitsmodal render disabledProceed: ", disabledProceed)

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>Ooops!</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            <ErrorAlert message={limitMessage} />
            {showForm ? (
              <>
                {cart.order_type !== "dine_in" && !cart.isInStoreOrder ? (
                  <>
                    <Row>
                      <Col>
                        {mountOrderDateDropdown ? (
                          <OrderDateDropdown
                            brand_url={brand_url}
                            store={selected_store}
                            inputActions={inputActions}
                            hasError={errors.hasOwnProperty("order_date")}
                            error={errors["order_date"]}
                            order_type={values["order_type"]}
                            value={values["order_date"]}
                            setOrderDate={(order_date) => {
                              let { values = {} } = this.state;
                              values.order_date = order_date;
                              this.setState({ values });
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </Col>
                      <Col>
                        {mountOrderTimeDropdown ? (
                          <OrderTimeDropDown
                            brand_url={brand_url}
                            store={selected_store}
                            inputActions={inputActions}
                            order_date={values["order_date"]}
                            hasError={errors.hasOwnProperty("order_time")}
                            error={errors["order_time"]}
                            order_type={values["order_type"]}
                            value={values["order_time"]}
                            no_asap={true}
                          />
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            <div>{limitsModalError}</div>
          </Modal.Body>

          {showForm ? (
            <Modal.Footer>
              {values.order_type === "dine_in" ||
              this.props.cart.isInStoreOrder ? (
                <>
                  <Button
                    variant="outline-danger"
                    className="br-0 btn-block font-weight-bolder"
                    // type="submit"
                    // disabled={disabledProceed ? "disabled" : ""}
                    onClick={this.props.onHide}
                  >
                    Back
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="outline-primary"
                    className="br-0 btn-block font-weight-bolder"
                    type="submit"
                    disabled={disabledProceed ? "disabled" : ""}
                  >
                    Proceed
                  </Button>
                </>
              )}
            </Modal.Footer>
          ) : (
            <></>
          )}
        </Form>
      </Modal>
    );
  }
}
