import React, { Component } from "react";
import moment from "moment";
import qs from "qs";
import { Dropdown, InputGroup, FormControl } from "react-bootstrap";
import ReportsApi from "./ReportsApi";
import { HeaderTextWithActions, InfoTooltip } from "../../../components/Base";
import ReportsTable from "../../../components/ReportsTable";
import core from "../../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import TitleHelmet from "../../../partials/TitleHelmet";
import { useSelector } from "react-redux";
import { DB_DATE_FORMAT, DATERANGE_LIST } from "../../../constants";
import { capitalizeText } from "../../../../app/helpers";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ExportCSV from "../../../components/ExportCSV";
import PaginationBar from "../../../components/PaginationBar";
import { KTUtil } from "../../../../_metronic/_assets/js/components/util";
import PacmanWrapper from "../../../components/dashboard/PacmanWrapper";

const getIdsAndAttr = function (props, selectedView = {}) {
  return { id: props.id, attr: "store" };
};

export default function Page(props) {
  const { brands = [] } = useSelector((state) => state.auth.user);
  const { mode = "", store_id = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  const {
    filter = "",
    limit = 30,
    duration = "day",
    date = moment().format(DB_DATE_FORMAT),
  } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  return (
    <MainComponent
      id={store_id}
      brands={brands}
      user={user}
      limit={limit}
      filter={filter}
      duration={duration}
      date={date}
      webview={mode === "webview"}
    />
  );
}

class MainComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: true,
      redirectTo: "",
      brand_name: "Brands",
    };

    this.api = core("pu").get();

    this.getLoggedInUser = this.getLoggedInUser.bind(this);
  }

  getLoggedInUser = (field = "fullname") => {
    const { user = {} } = this.props;
    if (field === "fullname") {
      return user.first_name + " " + user.last_name;
    } else {
      return user[field];
    }
  };

  render() {
    const actions = {
      getLoggedInUser: this.getLoggedInUser,
      setBrandName: (brand_name = "") => {
        this.setState({ brand_name });
      },
      setSplashScreen: (showSplashScreen = true) => {
        this.setState({ showSplashScreen });
      },
      setRedirectTo: (redirectTo = "") => {
        this.setState({ redirectTo });
      },
    };

    return (
      <>
        <TitleHelmet title={this.state.brand_name} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <StoreComponent
          webview={this.props.webview}
          id={this.props.id}
          api={this.api}
          actions={actions}
          filter={this.props.filter}
          duration={this.props.duration}
          user={this.props.user}
          date={this.props.date}
          brands={this.props.brands}
          limit={Number(this.props.limit)}
        />
      </>
    );
  }
}

class StoreComponent extends ReportsApi {
  getReqBody() {
    // const {page = 1, limit= 30, search = '', isForExport= false, sortBy= {name: 'order_date', isReverse: false}} = this.state;
    const {
      page = 1,
      limit = 30,
      isForExport = false,
      sortBy = { name: "order_date", isReverse: false },
      showEndDatePicker = false,
      endDate = moment().add(1, "w").format(DB_DATE_FORMAT),
    } = this.state;
    const search = this.processSearchValue(this.state.search);
    let duration = this.state.duration || this.props.duration || "day";
    let date =
      this.state.date || this.props.date || moment().format(DB_DATE_FORMAT);
    const { filter = "" } = this.props;

    let view = this.state.selectedBrand || {
      id: this.props.id || this.props.stores[0],
      name: "",
      attr: "store",
    };

    const { role = "", brands = [] } = this.props;
    const { id = "", attr = "" } = getIdsAndAttr(this.props, view);

    if (this.props.filter && this.props.filter === "Orders Tomorrow") {
      date = moment().add(1, "days").format(DB_DATE_FORMAT);
      duration = "day";
    }

    if (duration === "allTime" && page >= 0) {
      date = moment().format(DB_DATE_FORMAT);
    }

    return {
      role: role,
      brands: brands,
      id,
      attr: attr || view.attr,
      date,
      dateRange: duration,
      filter,
      limit: Number(limit),
      page: page - 1,
      search,
      sortBy,
      isForExport,
      ...(showEndDatePicker && { endDate }),
    };
  }

  render() {
    const {
        data_status = "",
        data = {},
        duration = this.props.duration || "day",
        date = this.props.date || moment().format(DB_DATE_FORMAT),
        page = 1,
        limit = 30,
        showEndDatePicker = this.props.showEndDatePicker || false,
        endDate = moment().add(1, "w").format(DB_DATE_FORMAT),
      } = this.state,
      isLoading = data_status !== "fetched";

    const title = capitalizeText(this.props.filter) || "Orders";
    const { orders = [], totalResults = 0 } = data;

    const pagesArr = [];
    for (let i = 0; i * limit < totalResults; i++) {
      pagesArr.push(i);
    }

    return (
      <>
        <TitleHelmet title="Reports" />
        {this.props.webview && (
          <div className="row">
            <div className="col-md-9 col-sm-12">
              <Breadcrumbs>
                <Breadcrumbs.Item
                  text="Dashboard"
                  link={`/webview/dashboard`}
                  active="true"
                />
              </Breadcrumbs>
            </div>
          </div>
        )}
        <HeaderTextWithActions className="mb-0 mb-md-4" title={title} />

        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          <div className="d-flex flex-column">
            <div className="mb-8 position-sticky" style={{ left: 0 }}>
              <InputGroup className="mb-3 overflow-hidden">
                <InputGroup.Prepend>
                  <InputGroup.Text id="text-search-input">
                    Search
                    {window.ReactNativeWebView
                      ? InfoTooltip(
                          "You can search order#, customer name, email, and contact number",
                        )
                      : null}
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <FormControl
                  type="search"
                  aria-label="Search"
                  aria-describedby="inputGroup-sizing-default"
                  placeholder={
                    window.ReactNativeWebView
                      ? "Search Order Details"
                      : "Order#, customer name, email, and contact number"
                  }
                  value={this.state.search}
                  onChange={(e) => this.handleTextSearchInputChange(e)}
                  onKeyDown={this.keyPress}
                />

                <button
                  className="btn btn-default"
                  onClick={this.handleTextSearch}
                >
                  <i className="fa fa-search"></i>
                </button>
              </InputGroup>
            </div>

            <div
              className="row align-items-center mb-4 position-sticky d-flex flex-column flex-md-row"
              style={{ left: "0", zIndex: 1 }}
            >
              <div
                className={`${
                  showEndDatePicker ? "col-12 col-sm-7" : "col-12 col-md-6"
                } input-group position-sticky w-100
                `}
                style={{ left: "0" }}
                onClick={() => this.handleOnDateInputClick()}
              >
                <form
                  className={`input-group-prepend align-items-center mr-4 ${
                    KTUtil.isMobileDevice() && showEndDatePicker
                      ? "flex-wrap"
                      : ""
                  }`}
                >
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Order Date
                  </span>
                  <input
                    type="date"
                    className="form-control rounded-0 d-inline-block"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    onChange={this.handleDateInputChange}
                    value={
                      this.state.page !== 2
                        ? (title === "Orders Tomorrow" &&
                            moment().add(1, "days").format(DB_DATE_FORMAT)) ||
                          this.state.date ||
                          moment().format(DB_DATE_FORMAT)
                        : null
                    }
                    style={{ width: 155 }}
                  />
                  {showEndDatePicker && (
                    <div
                      className={`d-flex ${
                        KTUtil.isMobileDevice() ? "ml-6" : ""
                      }`}
                    >
                      <div className="d-flex p-3"> to </div>
                      <input
                        type="date"
                        name="endDate"
                        className="form-control rounded-0 d-inline-block"
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(e) =>
                          this.handleDateInputChange(e, "endDate")
                        }
                        min={this.state.date}
                        style={{ width: 155 }}
                        value={
                          endDate || moment().add(1, "w").format(DB_DATE_FORMAT)
                        }
                      />
                    </div>
                  )}
                  <button
                    className="btn btn-primary rounded-0"
                    onClick={this.handleSearchDate}
                  >
                    <i className="fa fa-search p-0" />
                  </button>
                </form>
                {/* <form className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Order Date</span>
                                <input 
                                    type="date" 
                                    className="form-control rounded-0 d-inline-block" 
                                    aria-label="Small" 
                                    aria-describedby="inputGroup-sizing-sm" 
                                    onChange= {this.handleDateInputChange}
                                    value= {(title==='Orders Tomorrow' && moment().add(1, 'days').format(DB_DATE_FORMAT))||this.state.date|| moment().format(DB_DATE_FORMAT)}
                                />
                                <button 
                                    className="btn btn-primary rounded-0" 
                                    onClick={this.handleSearchDate}
                                >
                                    <i className="fa fa-search p-0"/>
                                </button>
                            </form> 
                            {/* <form className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Order Date</span>
                                <input 
                                    type="date" 
                                    className="form-control rounded-0 d-inline-block" 
                                    aria-label="Small" 
                                    aria-describedby="inputGroup-sizing-sm" 
                                    // placeholder="YYYY-MM-DD"
                                    onChange= {this.handleDateInputChange}
                                    disabled= {title==='Orders Tomorrow'?true: false}
                                    value= {(title==='Orders Tomorrow' && moment().add(1, 'days').format(DB_DATE_FORMAT))||this.state.date|| moment().format(DB_DATE_FORMAT)}
                                />
                                <button 
                                    disabled= {title==='Orders Tomorrow'? true: false}
                                    className="btn btn-primary rounded-0" 
                                    onClick={this.handleSearchDate}
                                >
                                    <i className="fa fa-search p-0"/>
                                </button>
                            </form>     */}

                {/* Handle total margin left of export csv and place the margin right to the form */}
                <div className="d-flex" style={{ marginLeft: "-2rem" }}>
                  <ExportCSV
                    filename={`${title}-${this.handleDropdownText(
                      duration || this.props.duration,
                      date,
                    )}-${date}.csv`}
                    // headers={csvHeaders}
                    url="/dashboard/reports"
                    reqBody={this.getReqBody()}
                  />
                </div>
              </div>

              <div className="d-flex position-sticky ml-auto">
                <Dropdown className="mr-4 rounded-top" drop="right">
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {this.handleDropdownText(
                      duration || this.props.duration,
                      date,
                    ) || "Today"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu id={"daterange-dropdown"}>
                    {[
                      ...DATERANGE_LIST,
                      { date: null, name: "Date Range" },
                    ].map((item) => (
                      <Dropdown.Item
                        key={item.name}
                        onClick={(e) => {
                          const dropdownText = e.currentTarget.textContent;
                          if (dropdownText === "Date Range") {
                            this.setState({
                              date: moment().format(DB_DATE_FORMAT),
                              endDate: moment()
                                .add(1, "w")
                                .format(DB_DATE_FORMAT),
                              showEndDatePicker: true,
                              duration: "between",
                            });
                          } else {
                            this.handleDateRangeClick(e, item.date);
                          }
                        }}
                      >
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div style={{ overflowX: "auto" }}>
              <ReportsTable
                data={orders}
                onClickSort={this.handleChangeSort}
                levelId={this.props.brand}
                webview={this.props.webview}
              />

              {totalResults > 0 && (
                <div className="position-sticky" style={{ left: 0 }}>
                  <PaginationBar
                    pagesArr={pagesArr}
                    totalResults={totalResults}
                    rows={orders.length}
                    pageNum={page}
                    limit={limit}
                    onSelectPage={this.handleSelectPage}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}
