import React from "react";
import { Card, Col, Row, Button, Form, Modal } from "react-bootstrap";
import { Block, FormItem, Label } from "../components/Base";
import ProSourceForm from "../components/ProSourceForm";
import { ChromePicker } from "react-color";

function getStyle(font_type = "normal") {
  let style = { fontSize: "30px", fontWeight: 600 };

  if (font_type === "small") style.fontSize = "20px";
  else if (font_type === "large") style.fontSize = "40px";

  return style;
}

function getBtnVariant(condition = false) {
  return `btn btn-icon btn-${condition ? "primary" : "outline-primary"}`;
}

export default class StoreTitleFormDiv extends ProSourceForm {
  constructor(props) {
    super(props);

    const { data = {} } = this.props,
      { website_content = {} } = data,
      {
        store_title_text = "",
        store_title_font_size = "",
        store_title_font_colour = "",
        store_title_alignment = "",
      } = website_content;

    this.state = {
      values: {
        store_title_text,
        store_title_font_size:
          store_title_font_size === "" ? "normal" : store_title_font_size,
        store_title_font_colour,
        store_title_alignment,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
    };

    this.validate = {};
  }

  onClickOrientationChange = (store_title_alignment = "") => {
    let { values = {} } = this.state;
    values.store_title_alignment = store_title_alignment;

    this.setState({ values });
  };

  openColorPicker = (selected_name) => {
    const { values = {} } = this.state;
    this.setState({ mountModal: false });
    setTimeout(() => {
      this.setState({
        mountModal: true,
        showModal: true,
        selected_name,
        selected_color: values[selected_name],
      });
    }, 50);
  };

  setSelectedColorToValue = (e) => {
    e.preventDefault();
    let { values = {}, selected_color = "", selected_name = "" } = this.state;
    console.log({ selected_color, selected_name });
    values[selected_name] = selected_color;
    this.setState({ values, showModal: false });
  };

  onSubmit = (values, setSubmitting) => {
    let { data = {} } = this.props,
      { website_content = {} } = data;

    for (var key in values) {
      website_content[key] = values[key];
    }

    this.props.onSubmitWebsiteContentForm(website_content, setSubmitting);
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      },
      { store_title_alignment = "" } = this.state.values;

    const { mountModal, showModal, selected_color = "" } = this.state;

    return (
      <>
        {mountModal ? (
          <Modal
            size="sm"
            centered
            show={showModal}
            onHide={() => {
              this.setState({ showModal: false });
            }}
          >
            <Modal.Header>
              <Modal.Title>Pick Color</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <ChromePicker
                color={selected_color}
                onChangeComplete={(color) => {
                  this.setState({ selected_color: color.hex });
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={this.setSelectedColorToValue}>
                Select
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <></>
        )}
        <Card className="card-custom gutter-b">
          <Card.Header>
            <Card.Title>Homepage Title</Card.Title>
          </Card.Header>
          <Form onSubmit={this.handleOnSubmit}>
            <Card.Body>
              <FormItem
                label={"Homepage Title"}
                name={"store_title_text"}
                inputProps={{
                  name: "store_title_text",
                  placeholder: "Homepage Title",
                  value: this.state.values["store_title_text"],
                }}
                inputActions={inputActions}
                actions={actions}
                showRequired={false}
                normal
              />
              <Row>
                <Col>
                  <FormItem
                    label={"Font Size"}
                    name={"store_title_font_size"}
                    customFormControl
                    custom={
                      <>
                        <Form.Control
                          style={{ width: "50%" }}
                          as="select"
                          name={"store_title_font_size"}
                          custom="true"
                          {...inputActions}
                          className="text-capitalize"
                          value={this.state.values["store_title_font_size"]}
                        >
                          <option value="">Select type</option>
                          <option value="small" style={getStyle("small")}>
                            small
                          </option>
                          <option value="normal" style={getStyle("normal")}>
                            normal
                          </option>
                          <option value="large" style={getStyle("large")}>
                            large
                          </option>
                        </Form.Control>
                      </>
                    }
                    inputActions={inputActions}
                    actions={actions}
                    showRequired={false}
                    normal
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Block
                    content={
                      <>
                        <Label
                          text={
                            <>
                              <span className="va-middle">Font Colour</span>{" "}
                              <button
                                type="button"
                                className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle"
                                onClick={() => {
                                  this.openColorPicker(
                                    "store_title_font_colour",
                                  );
                                }}
                              >
                                <i className="far fa-edit text-primary" />
                              </button>
                            </>
                          }
                        />
                        <div>
                          <div
                            style={{
                              border: "1px black solid",
                              marginRight: "2px",
                              height: "1rem",
                              width: "1rem",
                              display: "inline-block",
                              backgroundColor:
                                this.state.values["store_title_font_colour"],
                            }}
                          ></div>
                          <div style={{ display: "inline-block" }}>
                            {this.state.values["store_title_font_colour"]}{" "}
                          </div>
                        </div>
                      </>
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Block
                    content={
                      <>
                        <div className="mv-1rem">
                          <Label text={<>Alignment</>} />
                          <div>
                            <div className="btn-group" role="group">
                              <button
                                type="button"
                                className={getBtnVariant(
                                  store_title_alignment === "left",
                                )}
                                onClick={() => {
                                  this.onClickOrientationChange(
                                    "left",
                                    "store_title_alignment",
                                  );
                                }}
                              >
                                <i className="fas fa-align-left"></i>
                              </button>
                              <button
                                type="button"
                                className={getBtnVariant(
                                  store_title_alignment === "center",
                                )}
                                onClick={() => {
                                  this.onClickOrientationChange(
                                    "center",
                                    "store_title_alignment",
                                  );
                                }}
                              >
                                <i className="fas fa-align-center"></i>
                              </button>
                              <button
                                type="button"
                                className={getBtnVariant(
                                  store_title_alignment === "right",
                                )}
                                onClick={() => {
                                  this.onClickOrientationChange(
                                    "right",
                                    "store_title_alignment",
                                  );
                                }}
                              >
                                <i className="fas fa-align-right"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            </Card.Footer>
          </Form>
        </Card>
      </>
    );
  }
}
