import React from "react";
import { SVGIcon } from "../components/Base";
import { generateStoreHoursDisplay, isOpen24Hours } from "../helpers";
import { Modal, Table } from "react-bootstrap";
import moment from "moment";
import { DB_HOURS_FORMAT, PRIMARY_COLOR } from "../constants";
import styled from "styled-components";

export default class StoreListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }
  render() {
    const {
        store = {},
        store_hours_display = "branded-website",
        hideName = false,
        custom_color = PRIMARY_COLOR,
        custom_link_color = PRIMARY_COLOR,
        isPreview = false,
      } = this.props,
      { showModal = false } = this.state;

    // const { off_dates = [] } = store, moment_today = moment(new Date()).format(DB_DATE_FORMAT);

    const ColoredIcon = styled.span`
      svg g [fill] {
        fill: ${custom_color};
      }
    `;

    return (
      <>
        <Modal
          show={showModal}
          onHide={() => {
            this.setState({ showModal: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>Store Hours</Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                this.setState({ showModal: false });
              }}
            >
              <i aria-hidden="true" className="ki ki-close"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Table>
              <thead>
                <tr>
                  <td>Day</td>
                  <td>Hours</td>
                </tr>
              </thead>
              <tbody>
                <DisplayStoreHourPerDay store_hours={store.store_hours} />
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>

        {hideName ? (
          <></>
        ) : (
          <div>
            <b>{store.name}</b>
          </div>
        )}
        <div>
          {" "}
          <ColoredIcon>
            <SVGIcon
              icon="Map/Marker1"
              title={"Location"}
              variant="custom"
              className="svg-icon-md svg-icon-gray-500 mr-1"
            />{" "}
          </ColoredIcon>{" "}
          {store.location}{" "}
        </div>
        <div>
          {" "}
          <ColoredIcon>
            <SVGIcon
              icon="Devices/Phone"
              title={"Contact Number"}
              variant="custom"
              className="svg-icon-md svg-icon-gray-500 mr-1"
            />
          </ColoredIcon>{" "}
          {store.contact_number}
        </div>
        <div>
          <ColoredIcon>
            <SVGIcon
              icon="Home/Clock"
              title={"Store Hours"}
              variant="custom"
              className="svg-icon-md svg-icon-gray-500 mr-1"
            />
          </ColoredIcon>{" "}
          {generateStoreHoursDisplay(store, store_hours_display)}{" "}
          {!isPreview && (
            <button
              type="button"
              className="btn btn-link px-0 py-0 font-weight-bolder text-capitalize va-baseline"
              style={{ color: custom_link_color }}
              onClick={() => {
                this.setState({ showModal: true });
              }}
            >
              see store hours
            </button>
          )}
          {isPreview && (
            <button
              type="button"
              className="btn btn-link px-0 py-0 font-weight-bolder text-capitalize va-baseline"
              style={{ color: custom_link_color, cursor: "default" }}
              onClick={null}
            >
              see store hours
            </button>
          )}
        </div>
      </>
    );
  }
}

function DisplayStoreHourPerDay({ store_hours }) {
  let days = [];
  for (let i = 0; i < 7; i++) {
    days[i] = store_hours.filter((store_hour) => {
      return store_hour.day === i.toString();
    });
  }

  return days.map((day_store_hours, index) => (
    <tr key={index}>
      <td>{moment(index, "d").format("dddd")}</td>
      <DisplayStoreHourPerDaySlot day_store_hours={day_store_hours} />
    </tr>
  ));
}

function DisplayStoreHourPerDaySlot({ day_store_hours }) {
  let text = [],
    isClosed = true;
  for (var i = 0; i < day_store_hours.length; i++) {
    const { opening = "", closing = "" } = day_store_hours[i];
    if (day_store_hours[i].isOpen) {
      isClosed = false;
      const is24hr = isOpen24Hours(opening, closing);
      text.push(
        is24hr
          ? "Open 24 hours"
          : `${moment(opening, DB_HOURS_FORMAT).format("hh:mm A")} to ${moment(
              closing,
              DB_HOURS_FORMAT,
            ).format("hh:mm A")}`,
      );
    }
  }

  return <td>{isClosed ? "CLOSED" : text.join("; ")}</td>;
}
