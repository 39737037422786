import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
// import { useParams, Redirect } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { LayoutSplashScreen } from "../../../_metronic/layout";
// import TitleHelmet from "../../partials/TitleHelmet";
import { HeaderTextWithActions, LoadingDiv } from "../../components/Base";
// import Breadcrumbs from "../../components/Breadcrumbs";
// import UserForm from "../../partials/forms/UserForm";
// import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import { roleDisplay } from "../../helpers";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import PacmanWrapper from "../../components/dashboard/PacmanWrapper";
import PacmanLoading from "../../components/widgets/PacmanLoading/PacmanLoading";

export default class UsersList extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "User Settings",
      password: "",
    };

    this.api = core("pu").get();
    this.data_url = "/users";
    this.use_display_data = true;

    this.onChangeFilterType = this.onChangeFilterType.bind(this);
    this.filterUsers = this.filterUsers.bind(this);
    this.onClickUser = this.onClickUser.bind(this);
    this.onClickNewUser = this.onClickNewUser.bind(this);
  }

  setDisplayData = () => {
    const { data = [] } = this.state;
    this.setState({ display_data: data, pagination_data: data });
    this.filterUsers("active");
  };

  onChangeFilterType = (e) => {
    const { value = "" } = e.target;
    this.filterUsers(value);
  };

  filterUsers = (value) => {
    this.setState({ showLoadingData: true, display_data: [] });

    const { data = [] } = this.state;
    let display_data = [];

    if (value === "") {
      display_data = data;
    } else if (value === "active") {
      display_data = data.filter(({ active }) => {
        return active;
      });
    } else if (value === "inactive") {
      display_data = data.filter(({ active }) => {
        return !active;
      });
    }

    this.setState({
      showLoadingData: false,
      display_data,
      selected_type: value,
    });
  };

  onClickNewUser = () => {
    this.props.history.push(
      `${this.props.webview ? "/webview" : ""}/settings/users/new`,
    );
  };

  onClickUser = (row) => {
    this.props.history.push(
      `${this.props.webview ? "/webview" : ""}/settings/users/${row._id}`,
    );
  };

  render() {
    const {
        display_data = [],
        data_status = "",
        selected_type = "",
      } = this.state,
      isLoading = data_status !== "fetched";

    return (
      <>
        {isLoading ? (
          <PacmanWrapper removeBackgroundColor />
        ) : (
          <>
            <HeaderTextWithActions title={this.state.title} />
            <Card className="card-custom">
              <Card.Body>
                <Row>
                  <Col sm={3} className="text-right">
                    <Form.Group>
                      <Form.Control
                        as="select"
                        onChange={this.onChangeFilterType}
                        value={selected_type}
                      >
                        <option value="">Show All</option>
                        <option value="active">Show Active Users</option>
                        <option value="inactive">Show Inactive Users</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col sm={9} className="text-right">
                    <Button variant="primary" onClick={this.onClickNewUser}>
                      Add
                    </Button>
                  </Col>
                </Row>
                <Table
                  data={display_data}
                  progressPending={isLoading}
                  onRowClicked={this.onClickUser}
                />
              </Card.Body>
            </Card>
          </>
        )}
      </>
    );
  }
}

function Table({ data, progressPending = false, onRowClicked }) {
  const [searchText, setSearchText] = React.useState("");
  const filteredItems =
    searchText !== ""
      ? data.filter((item) => {
          const name = item.first_name + " " + item.last_name;
          return name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
        })
      : data;

  return (
    <>
      <DataTable
        onRowClicked={onRowClicked}
        columns={[
          {
            name: "Name",
            selector: (row) => {
              return `${row.first_name} ${row.last_name}`;
            },
            sortable: true,
            style: {
              fontWeight: "bold",
              cursor: "pointer",
            },
          },
          {
            name: "Email",
            selector: (row) => {
              return row.email;
            },
          },
          {
            name: "Role",
            selector: (row) => {
              return roleDisplay(row.iam_role);
            },
            format: (row) => {
              return (
                <span className="text-capitalize">
                  {roleDisplay(row.iam_role)}
                </span>
              );
            },
          },
          {
            name: "Status",
            selector: (row) => {
              return row.active ? "Active" : "Inactive";
            },
          },
          // {
          //     name: "",
          //     selector: (row) => {
          //         return roleDisplay(row._id);
          //     },
          //     format: (row) => {
          //         return <Link to={"/settings/users/" + row._id + "/password"} className="btn btn-primary">Change Password</Link>;
          //     },
          // }
        ]}
        data={filteredItems}
        pagination
        noHeader
        highlightOnHover
        progressPending={progressPending}
      />
    </>
  );
}
