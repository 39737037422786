import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { Form, Row, Col } from "react-bootstrap";
import { FormItem, SVGIcon } from "../../components/Base";
import { GetSubmitClassList } from "../../helpers";
import { ITEM_DESCRIPTION_CHAR_LIMIT, DANGER_COLOR } from "../../constants";
import { Card, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const MySwal = withReactContent(Swal);
export default class ExtrasForm extends ProSourceForm {
  constructor(props) {
    super(props);
    const { item = {} } = this.props,
      {
        name = "",
        _id = "",
        description = "",
        minimum_number = 0,
        isRequired = false,
        maximum_number = 1,
        group_selections = [],
        store,
        brand,
      } = item;

    this.state = {
      values: {
        name,
        _id,
        minimum_number,
        maximum_number,
        isRequired,
        store,
        brand,
        description,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      file: null,
      group_selections: group_selections,
    };

    this.validate = {
      required: [
        "name",
        "price",
        "minimum_number",
        "maximum_number",
        "description",
      ],
    };

    this.afterSubmit = this.afterSubmit.bind(this);
    this.handleOnClickCancelBtn = this.handleOnClickCancelBtn.bind(this);
    this.handleOnBlurCategory = this.handleOnBlurCategory.bind(this);
    this.onClickExtraDelete = this.onClickExtraDelete.bind(this);
  }

  handleOnClickCancelBtn = () => {
    this.props.onClickCancelBtn && this.props.onClickCancelBtn();
  };

  onClickExtraDelete = () => {
    const extra_group_id = this.state.values._id;
    this.props.onClickExtraDelete(extra_group_id);
  };

  handleOnBlurCategory = () => {
    this.setStateKey("touched", "category", "true");
    this.validateForm();
  };

  handleOnChange = (event) => {
    const target = event.target,
      value = target.value,
      name = target.name;

    let { values } = this.state;

    if (value !== "" && value.length > ITEM_DESCRIPTION_CHAR_LIMIT) {
      // do nothing
    } else {
      values[name] = value;
      if (name === "minimum_number") {
        values[name] = Number(value);
        if (value > 0) {
          values["isRequired"] = true;
        } else {
          values["isRequired"] = false;
        }
      }
      if (name === "maximum_number") {
        values[name] = Number(value);
      }
      if (name === "isRequired") {
        if (target.checked === false) {
          values["minimum_number"] = 0;
        } else {
          values["minimum_number"] = 1;
        }
      }
      this.setState({
        values: values,
      });
    }

    this.setStateKey("touched", name, "true");
    this.validateForm();

    // checks if afterOnChange function exists
    this.onChange && this.onChange(event);
  };

  handleFormValidation = () => {
    let { errors = {}, values = {}, group_selections } = this.state;

    const { required = [] } = this.validate;

    for (var r in required) {
      const name = required[r],
        value = values[name];
      if (name === "maximum_number") {
        if (Number(value) <= 0) {
          errors[name] = "This field required 1 or more value";
        } else if (
          Number(value) !== 1 &&
          Number(value) < this.state.values.minimum_number
        ) {
          errors[name] = "Value should be greater than the minimum number";
        } else if (
          this.state.group_selections.length !== 0 &&
          Number(value) > this.state.group_selections.length
        ) {
          errors[name] = "Invalid value";
        } else if (value === "" && this.isTouched(name)) {
          errors[name] = "This field is required";
        } else if (errors[name] === "This field is required") {
          delete errors[name];
        } else if (errors[name] === "This field required 1 or more value") {
          delete errors[name];
        } else {
          delete errors[name];
        }
      } else if (name === "minimum_number") {
        if (Number(value) < 0) {
          errors[name] = "This field required 0 or more value";
        } else if (
          Number(value) !== 0 &&
          Number(value) > this.state.values.maximum_number
        ) {
          errors[name] = "Value should be less than the maximum number";
        } else if (value === "" && this.isTouched(name)) {
          errors[name] = "This field is required";
        } else if (errors[name] === "This field is required") {
          delete errors[name];
        } else if (errors[name] === "This field required 1 or more value") {
          delete errors[name];
        } else {
          delete errors[name];
        }
      } else {
        if (value === "" && this.isTouched(name)) {
          errors[name] = "This field is required";
        } else if (errors[name] === "This field is required") {
          delete errors[name];
        } else if (errors[name] === "This field required 1 or more value") {
          delete errors[name];
        }
      }
    }
    for (var i in group_selections) {
      const val = group_selections[i]["extra_id"],
        target_price = `price_f_${i}`,
        target_name = `name_f_${i}`;
      if (val["price"] === "") {
        errors[target_price] = "This field is required";
      } else if (errors[target_price] === "This field is required") {
        delete errors[target_price];
      }

      if (val["name"] === "") {
        errors[target_name] = "This field is required";
      } else if (errors[target_name] === "This field is required") {
        delete errors[target_name];
      }
    }
    return errors;
  };
  onChange = (event) => {
    const target = event.target,
      checked = target.checked,
      name = target.name;
    let { values = {} } = this.state,
      state = {};

    if (name === "isRequired") {
      values[name] = checked;
      state.values = values;
    }
    this.setState(state);
  };
  async handleOnSubmit(event) {
    event.preventDefault();
    await this.setAllFieldsToTouched();

    if (this.isFormOk()) {
      const {
        group_selections,
        values: { minimum_number },
      } = this.state;

      if (group_selections.length === 0) {
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: "You need to have atleast one Extra on your list to create an Extras Group",
          confirmButtonColor: DANGER_COLOR,
          confirmButtonText: "Okay",
        });
      } else if (group_selections.length < minimum_number) {
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: "Number of extra on your list is below the assigned minimum number please add more extra to proceed",
          confirmButtonColor: DANGER_COLOR,
          confirmButtonText: "Okay",
        });
      } else {
        this.setSubmitting();
        this.onSubmit &&
          this.onSubmit(
            this.state.values,
            this.setSubmitting,
            this.state.group_selections,
            this.state,
          );
      }
    }
  }
  onSubmit = (values, setSubmitting, group_selections, statevalue) => {
    delete values["_id"];
    const extra_group_val = {
      ...values,
      group_selections: group_selections,
    };

    this.props.onSubmit(extra_group_val, setSubmitting);
  };

  afterSubmit = () => {
    this.props.afterSubmit && this.props.afterSubmit();
  };
  addClick() {
    // this.setState(prevState => ({ extras: [...prevState.extras, '']}))
    this.setState(
      (prevState) => ({
        group_selections: [
          ...prevState.group_selections,
          { extra_id: { name: "", price: "", _id: "" } },
        ],
      }),
      () => {
        this.validateForm();
      },
    );
  }
  removeClick(i) {
    // let extras = [...this.state.extras];
    // extras.splice(i,1);
    // this.setState({ extras });
    let group_selections = [...this.state.group_selections];
    group_selections.splice(i, 1);
    let { errors = {} } = this.state,
      target_price = `price_f_${i}`,
      target_name = `name_f_${i}`;
    delete errors[target_price];
    delete errors[target_name];
    this.setState({ group_selections, errors }, () => {
      this.validateForm();
    });
  }
  handleExtrasChange(i, event) {
    const target = event.target,
      value = target.value,
      name = target.name;

    let group_selections = [...this.state.group_selections];
    group_selections[i]["extra_id"][name] = value;

    this.setStateKey("touched", name, "true");
    this.validateForm();

    // checks if afterOnChange function exists
    // this.onChange && this.onChange(event);
    this.setState({ group_selections });
  }
  ExtrasUI() {
    const actions = {
      handleFeedbackError: this.handleFeedbackError,
      isTouched: this.isTouched,
    };
    //     return <DragDropContext onDragEnd={this.onDragEnd}>
    //     <Droppable droppableId={"groups"} type="CATEGORY">
    //         {(provided, snapshot) => (
    //             <div ref={provided.innerRef}>
    //                 {
    //                      this.state.group_selections.map(({ extra_id: { name, price , _id } }, i) => {
    //                         return (
    //                             <Draggable
    //                                 key={_id}
    //                                 draggableId={"group-" + i}
    //                                 index={i}
    //                                 onDragEnd={()=>{console.log(i)}}
    //                                 type="CATEGORY"
    //                             >
    //                                 {(provided, snapshot) => (
    //                                     <div ref={provided.innerRef}  {...provided.draggableProps} {...provided.dragHandleProps}>

    //                                        <Row key={i}>
    //             <Col lg={8}>
    //                 <FormItem
    //                     name={`name_f_${i}`}
    //                     inputProps={{
    //                         name: `name`,
    //                         placeholder: "Name",
    //                         value: this.state.group_selections[i]["extra_id"]["name"],
    //                     }}
    //                     inputActions={{ onChange: this.handleExtrasChange.bind(this, i) }}
    //                     actions={actions}
    //                     type={"text"}
    //                     normal
    //                 />
    //             </Col>
    //             <Col lg={3}>
    //                 <FormItem
    //                     name={`price_f_${i}`}
    //                     inputProps={{
    //                         name: `price`,
    //                         placeholder: "price",
    //                         value: this.state.group_selections[i]["extra_id"]["price"],
    //                     }}
    //                     inputActions={{ onChange: this.handleExtrasChange.bind(this, i) }}
    //                     actions={actions}
    //                     type={"number"}
    //                     normal
    //                 />
    //             </Col>
    //             <Col lg={1}>
    //                 <div
    //                     className="d-block py-7 px-2 text-center bg-hover-light"
    //                     onClick={this.removeClick.bind(this, i)}
    //                 >
    //                     <SVGIcon icon={"General/Trash"} className="svg-icon-3x" />
    //                 </div>
    //             </Col>
    //         </Row>

    //                                     </div>
    //                                 )}
    //                             </Draggable>
    //                         )
    //                     })
    //                 }
    //                 {provided.placeholder}
    //             </div>
    //         )}
    //     </Droppable>
    // </DragDropContext>
    return this.state.group_selections.map(({ extra_id }, i) =>
      extra_id ? (
        <Row key={i}>
          <Col xs={5} sm={6}>
            <FormItem
              name={`name_f_${i}`}
              inputProps={{
                name: `name`,
                placeholder: "Name",
                value: this.state.group_selections[i]["extra_id"]["name"],
              }}
              inputActions={{ onChange: this.handleExtrasChange.bind(this, i) }}
              actions={actions}
              type={"text"}
              normal
            />
          </Col>
          <Col xs={4} sm={3}>
            <FormItem
              name={`price_f_${i}`}
              inputProps={{
                name: `price`,
                placeholder: "price",
                value: this.state.group_selections[i]["extra_id"]["price"],
              }}
              inputActions={{ onChange: this.handleExtrasChange.bind(this, i) }}
              actions={actions}
              type={"number"}
              normal
            />
          </Col>
          <Col xs={3}>
            <div
              className="d-block pt-5 px-2 text-center bg-hover-light"
              onClick={this.removeClick.bind(this, i)}
            >
              <SVGIcon icon={"General/Trash"} className="svg-icon-3x" />
            </div>
          </Col>
        </Row>
      ) : null,
    );
  }

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };
    return (
      <>
        <Form
          className="fv-plugins-bootstrap fv-plugins-framework"
          onSubmit={this.handleOnSubmit}
        >
          <Row>
            <Col lg={7}>
              <FormItem
                label={"Name"}
                name={"name"}
                inputProps={{
                  name: "name",
                  placeholder: "Name",
                  value: this.state.values["name"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"text"}
                showRequired={true}
                normal
              />
              <FormItem
                label={"Description"}
                name={"description"}
                inputProps={{
                  name: "description",
                  placeholder: "description",
                  value: this.state.values["description"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"text"}
                showRequired={true}
                normal
              />
            </Col>
            <Col lg={2}>
              <FormItem
                label={"Minimum Number"}
                name={"minimum_number"}
                inputProps={{
                  name: "minimum_number",
                  placeholder: "min number",
                  value: this.state.values["minimum_number"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"number"}
                showRequired={true}
                normal
                helperText={
                  <>
                    Set to <b>0</b> if this group is optional for your customers
                  </>
                }
              />
            </Col>
            <Col lg={2}>
              <FormItem
                label={"Maximum Number"}
                name={"maximum_number"}
                inputProps={{
                  name: "maximum_number",
                  placeholder: "Can select up to",
                  value: this.state.values["maximum_number"],
                }}
                inputActions={inputActions}
                actions={actions}
                type={"number"}
                showRequired={true}
                normal
                helperText={
                  <>
                    Set to <b>1</b> if you customer can only choose one
                  </>
                }
              />
            </Col>
            <Col lg={1}>
              <div class="form-group row">
                <label class="col-form-label font-weight-bolder text-dark py-0">
                  Required
                </label>
                <span class="switch switch-outline switch-icon switch-primary py-1">
                  <label>
                    <input
                      type="checkbox"
                      name="isRequired"
                      checked={this.state.values.isRequired ? "checked" : null}
                      {...inputActions}
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </Col>
            {/* <Col lg={4}>
                        <FormItem
                            label={"Can select up to"}
                            name={"maximum_number"}
                            inputProps={{
                                name: "maximum_number",
                                placeholder: "Can select up to",
                                value: this.state.values["maximum_number"],
                            }}
                            inputActions={inputActions}
                            actions={actions}
                            type={"number"}
                            showRequired={true}
                            normal
                            helperText={<>Set to <b>1</b> if you customer can only choose one</>}
                        />
                    </Col>
                  */}
          </Row>
          <Row>
            <Col lg={12}>
              <Card className="card-custom">
                <div className="card-header px-4 px-sm-9">
                  <div className="card-title">
                    <h3 className="card-label">Extras</h3>
                    <span className="text-muted mt-3 font-weight-bold font-size-sm">
                      add extras for your customer to pick
                    </span>
                  </div>
                  <div className="card-toolbar">
                    <Button
                      variant="primary"
                      onClick={() => {
                        this.addClick();
                      }}
                    >
                      Add Extra
                    </Button>
                  </div>
                </div>
                <Card.Body className="p-4 py-sm-8 px-sm-9">
                  <div className="modal-scrollable">{this.ExtrasUI()}</div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg={8} xs={5} />
            <Col lg={4} xs={7}>
              <div>
                <div className="float-right">
                  {this.props.showDeleteButton ? (
                    <button
                      type="button"
                      disabled={this.props.deleteLoading}
                      className={GetSubmitClassList(
                        this.props.deleteLoading,
                        "btn btn-danger font-weight-bold px-9 py-4 my-3 mr-2",
                      )}
                      onClick={this.onClickExtraDelete}
                    >
                      <span>Delete</span>
                    </button>
                  ) : (
                    <></>
                  )}

                  <button
                    type="submit"
                    disabled={this.state.isSubmitting}
                    className={GetSubmitClassList(
                      this.state.isSubmitting,
                      `btn btn-primary font-weight-bold px-9 py-4 my-3`,
                    )}
                  >
                    <span>
                      {this.state.values._id === ""
                        ? "Create Extras Group"
                        : "Save changes"}
                    </span>
                  </button>
                </div>
                <div>
                  {this.props.showCancelButton ? (
                    <button
                      type="button"
                      disabled={this.state.isSubmitting}
                      className={
                        "btn btn-secondary font-weight-bold px-9 py-4 my-3 ml-2"
                      }
                      onClick={this.handleOnClickCancelBtn}
                    >
                      <span>Cancel</span>
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}
