/** Collection of login partials */

import React, { Component } from "react";

import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Link } from "react-router-dom";
import { BasicComponent } from "./Base";

class Login extends BasicComponent {
  constructor(props) {
    super(props);
    this.state = { defaultClass: "login" };
  }
}

class Logo extends Component {
  render() {
    return (
      <>
        <Link to="/" className="login-logo text-center pt-10 pb-10">
          <img
            alt="Logo"
            className="max-h-70px"
            src={toAbsoluteUrl("/media/logos/logo-dark.png")}
          />
        </Link>
      </>
    );
  }
}

class Aside extends BasicComponent {
  constructor(props) {
    super(props);
    this.state = { defaultClass: "login-aside" };
  }
}

class Content extends BasicComponent {
  constructor(props) {
    super(props);
    this.state = { defaultClass: "login-content" };
  }
}

class Form extends BasicComponent {
  constructor(props) {
    super(props);
    this.state = { defaultClass: "login-form" };
  }
}

Login.Logo = Logo;
Login.Aside = Aside;
Login.Content = Content;
Login.Form = Form;

export default Login;
