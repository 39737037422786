import React from "react";
import { Card } from "react-bootstrap";

export default class CardImageBanner extends React.Component {
  render() {
    const { image = <></>, text = <></>, actions = <></> } = this.props;
    return (
      <Card className="card-img-banner">
        <Card.Body>
          <div className="card-img-banner-body">
            <div className="card-img-banner-body-img-wrapper">{image}</div>
            <div className="card-img-banner-body-text-wrapper">{text} </div>
            <div className="card-img-banner-body-actions-wrapper">
              {actions}
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
}
