import React from "react";
import APIComponent from "../../components/API";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import core from "../../vendors/core-api";
import TitleHelmet from "../../partials/TitleHelmet";
import { Redirect } from "react-router-dom";
import PacmanLoading from "../../components/widgets/PacmanLoading/PacmanLoading";
import qs from "qs";
import PageCenter from "../../components/PageCenter";
import { AnimateDiv } from "../../components/Base";
import { Card } from "react-bootstrap";

import axios from "axios";

const instance = axios.create({});
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => Promise.reject(err),
);

export default function Payments(props) {
  const { token = "" } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  return <MainComponent token={token} {...props} />;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    let { hostname = "", token = "" } = this.props;
    let brand_url = "";

    if (hostname === "localhost") hostname = process.env.REACT_APP_HOSTNAME;

    brand_url = hostname.split(".")[1] ? hostname.split(".")[0] : false;

    this.state = {
      brand_url,
      title: "Verifying payment...",
      redirectTo: "",
      token,
      checkCtr: 0,
      isDoubleChecking: false,
    };

    this.api = core("pu").get();

    this.proceedWithAction = this.proceedWithAction.bind(this);
  }

  async getData() {
    const { brand_url = "", token = "" } = this.state;
    const baseURL = this.api.getBaseURL();

    this._isMounted && this.setState({ data_status: "fetching" });
    instance
      .post(
        "/branded-website/payments/initial-check",
        { token },
        {
          headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
          baseURL,
        },
      )
      .then(({ data }) => {
        const { payment_id = "" } = data;
        this._isMounted &&
          this.setState({
            renderModals: false,
            data,
            data_status: "fetched",
            payment_id,
          });
        this.handleOnDataFetched();
      })
      .catch((error) => {
        this._isMounted && this.setState({ error: error, showSwalError: true });
        this.handleOnDataFetchError();
      });
  }

  handleOnDataFetched = () => {
    let { data = {}, checkCtr = 0 } = this.state;

    if (data.action === "proceed" || checkCtr === 2) {
      this.proceedWithAction();
    } else if (data.action === "retry") {
      checkCtr++;
      this.setState({ checkCtr, isDoubleChecking: true });
      setTimeout(() => {
        this.getData();
      }, 3000);
    }
  };

  async proceedWithAction() {
    const { token = "", brand_url = "", payment_id = "" } = this.state;

    const baseURL = this.api.getBaseURL();

    try {
      const verify_data = await instance.post(
        "/branded-website/payments/external-verify",
        { token },
        {
          headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
          baseURL,
        },
      );

      const { data = {} } = verify_data,
        {
          track_token = "",
          status = "",
          retry_token = "",
          pending_token = "",
        } = data;

      await instance.post(
        "/branded-website/payments/payment-logs",
        { message: JSON.stringify({ action: "verify-payment", data }) },
        {
          headers: { Authorization: `Basic ${window.btoa(payment_id)}` },
          baseURL,
        },
      );

      if (status === "success") {
        this.setState({
          title: "Payment verified! Redirecting you to tracking...",
        });
        setTimeout(() => {
          this.setState({ redirectTo: "/external-track?token=" + track_token });
        }, 1000);
      } else if (status === "failed") {
        this.setState({ redirectTo: "/external-failed?token=" + retry_token });
      } else if (status === "pending") {
        this.setState({
          redirectTo: "/external-pending?token=" + pending_token,
        });
      } else {
        this.setState({
          redirectTo: "/external-pending?token=" + window.btoa(payment_id),
        });
      }
    } catch (err) {
      this.setState({
        redirectTo: "/external-pending?token=" + window.btoa(payment_id),
      });
    }
  }

  render() {
    return (
      <>
        <TitleHelmet title={this.state.title} showWebsiteName={false} />
        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}
        {this.state.isDoubleChecking ? (
          <PageCenter>
            <AnimateDiv>
              <Card className="card-border-top-primary card-custom">
                <Card.Body style={{ width: "350px" }}>
                  <div style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "2rem" }}>Please wait...</div>
                    <div>Hold on, we are double checking your payment.</div>
                    <PacmanLoading />
                  </div>
                </Card.Body>
              </Card>
            </AnimateDiv>
          </PageCenter>
        ) : (
          <LayoutSplashScreen />
        )}
      </>
    );
  }
}
