import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import DatePicker from "react-datepicker";

export const CartValidity = ({ onDateChange, exp_date = null }) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(new Date(exp_date));
  }, [exp_date]);

  return (
    <>
      <Card.Title className="mb-4">
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex flex-row align-items-center justify-content-space-between ml-8 mt-8 mr-8 mb-0">
            <h3 className="font-weight-bolder">
              <i
                className="fas fa-calendar-week mr-2"
                style={{ color: "#14C49B" }}
              />
              Cart Expiration
            </h3>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <DatePicker
            wrapperClassName="w-100 px-6 py-2"
            selected={isEmpty(exp_date) ? null : selected}
            onChange={(date) => {
              setSelected(date);
              onDateChange(date);
            }}
            minDate={new Date()}
            className="form-control"
            dateFormat={"MMMM dd, yyyy"}
            placeholderText="Select cart expiration date"
          />
        </div>
      </Card.Title>
    </>
  );
};
